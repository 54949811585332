import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CoreModule} from '@smartencity/core';
import {ModalModule} from 'ngx-bootstrap/modal';
import {PopoverModule} from 'ngx-bootstrap/popover';
import {RouterModule} from '@angular/router';
import {MandateComponent} from './mandate.component';
import {NgxPermissionsModule} from 'ngx-permissions';
import {NgSelectModule} from '@ng-select/ng-select';
import {AddMandateComponent} from './add/add-mandate.component';
import { MandateTree } from './mandate-tree/mandate-tree.component';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import { MandateDetailsComponent } from './mandate-details/mandate-details.component';
import { MandateLogModalComponent } from './mandate-log-modal/mandate-log-modal.component';
import { MandatePersonConsentTreeComponent } from './mandate-person-consent-tree/mandate-person-consent-tree.component';
import { MandatePersonConsentsModalComponent } from './mandate-person-consents-modal/mandate-person-consents-modal.component';
import { EditMandateModal } from './mandate-edit-modal/edit-mandate-modal.component';
import { MandatePersonTreeComponent } from './mandate-person-tree/mandate-person-tree.component';
import {VirtualScrollerModule} from 'ngx-virtual-scroller';
import {LaddaModule} from 'angular2-ladda';
import { MandateLogListItemComponent } from './mandate-log-modal/mandate-log-list-item.component';
import { ConsentLogListItemComponent } from './mandate-log-modal/consent-log-list-item.component';

@NgModule({
  declarations: [
    MandateComponent,
    AddMandateComponent,
    MandateTree,
    MandateDetailsComponent,
    MandateLogModalComponent,
    MandatePersonConsentTreeComponent,
    MandatePersonConsentsModalComponent,
    EditMandateModal,
    MandatePersonTreeComponent,
    MandateLogListItemComponent,
    ConsentLogListItemComponent
  ],
  entryComponents: [],
  providers: [

  ],
  imports: [
    CommonModule,
    RouterModule.forChild([]),
    // MandateRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    ModalModule,
    NgxPermissionsModule.forChild(),
    PopoverModule,
    NgSelectModule,
    BsDropdownModule,
    VirtualScrollerModule,
    LaddaModule
  ],
  exports: [
    MandateComponent
  ]
})
export class MandateModule { }
