import {Component, Input, OnInit} from '@angular/core';
import {SeriesLocation} from '../../models/series-location';
import {SeriesLocationType} from '../../models/series-location-type.enum';

@Component({
  selector: 'solar-indicators',
  templateUrl: './solar-indicators.component.html'
})
export class SolarIndicatorsComponent implements OnInit {

  cityPortalTypes: string[] = ['SOLAR_PANELS_KW', 'SOLAR_PANELS_TEMP', 'SOLAR_PANELS_RADIANCE'];

  @Input()
  location: SeriesLocation;

  @Input()
  cityPortalTypeData: any;

  constructor() { }

  ngOnInit(): void {
  }

  showData(): boolean {
    if (!this.location || !this.location.type || this.location.type !== SeriesLocationType.LOCATION) {
      return false;
    }

    for (let cityPortalType of this.cityPortalTypes) {
      if (this.cityPortalTypeData[cityPortalType].series) {
        return true;
      }
    }

    return false;
  }

}
