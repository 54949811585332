import { Injectable } from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {NgxPermissionsObject, NgxPermissionsService} from 'ngx-permissions';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';
import {Preferences, PreferencesService} from '@smartencity/core';
import {Subscriber} from 'rxjs';

@Injectable()
export class QuestionnaireService {

  constructor(private permissionsService: NgxPermissionsService,
              private preferencesService: PreferencesService) { }

  canCreateQuestionnaire(): Observable<boolean> {

    return new Observable<boolean>((subscriber: Subscriber<boolean>) => {
      combineLatest([this.permissionsService.permissions$, this.preferencesService.preferences$])
        .subscribe(([permissions, preferences]: [NgxPermissionsObject, Preferences]) => {
          let result;
          if (permissions['JURIDICAL_PERSON']) {
            result = true;
          } else {
            result = preferences.questionnairesCreateEnabled;
          }
          subscriber.next(result);
        });
    });

  }
}
