import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "modal-nav",
  templateUrl: "./modal-nav.component.html",
})
export class ModalNavComponent implements OnInit {
  @Input()
  public size: number;

  @Input()
  public current: number;

  @Output()
  currentChange = new EventEmitter<number>();

  constructor() {}

  ngOnInit() {}

  previous(): void {
    if (this.current > 0) {
      this.current = this.current - 1;
      this.currentChange.emit(this.current);
    }
  }

  next(): void {
    if (this.current < this.size - 1) {
      this.current = this.current + 1;
      this.currentChange.emit(this.current);
    }
  }
}
