import {GenericUriBuilder} from '../../redirect/generic-uri-builder';
import {CoreConfig} from '../../core-config.model';
import {PkceUtil} from '../../utils/pkce-util';

export class NemLogInAuthUriBuilder extends GenericUriBuilder {

  private hostUrl: string;

  private config: CoreConfig;

  constructor(config: any, hostUrl: string) {
    super(config.apiUrl, "/nem-log-in/login");
    this.hostUrl = hostUrl;
    this.config = config;
  }

  processBuildParams(params?: any) {
    if (!params) {
      throw Error("Params missing");
    }

    const state = PkceUtil.generateState();
    const codeChallenge = PkceUtil.challenge(PkceUtil.generateCodeVerifier());

    // Lisab tagasisuunamise URL-ile loginMode, sest muidu rakendus võtab endiselt konfist vaikimisi loginMode
    let redirectUrl = this.hostUrl + "/auth?next=" + decodeURIComponent(this.buildForwardTo(params.targetUrlPath)) + "&loginMode=nem-log-in";

    this.setUrlParams({
      clientId: this.config.serviceName ? this.config.serviceName : 'default',
      state: state,
      codeChallenge: codeChallenge,
      codeChallengeMethod: 'S256',
      redirectUrl: redirectUrl,
      redirectTo: this.hostUrl,
      ...params.params
    });

  }

  private buildForwardTo(urlPath?: string): string {
    let url = this.hostUrl;
    if (urlPath) {
      url = this.hostUrl.indexOf(urlPath) == -1 ? this.hostUrl + urlPath : this.hostUrl;
    }

    return url;
  }

}
