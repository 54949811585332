<div class="modal-header">
  <h5 class="modal-title w-100" i18n>Edit datapoint</h5>
  <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
    <span class="fal fa-times"></span>
  </button>
</div>
<div class="modal-body" [formGroup]="form">
  <div *ngIf="(form.dirty || form.touched) && !form.valid && showErrors" class="alert alert-danger">
    <div i18n>Form is invalid</div>
  </div>

  <div class="form-group">
    <div class="form-row">
      <div class="col">
        <label i18n [attr.for]="id + '-name'">Name</label>
        <input type="text" class="form-control" [class.is-invalid]="invalid('name')" formControlName="name" [id]="id + '-name'" maxlength="255" minlength="3">
        <small *ngIf="invalid('name') && errors('name')?.required" class="invalid-feedback d-block" i18n>Required</small>
        <small *ngIf="invalid('name') && errors('name')?.minlength" class="invalid-feedback d-block" i18n>Must be longer than {{errors('name').minlength.requiredLength}} characters</small>
        <small *ngIf="invalid('name') && errors('name')?.maxlength" class="invalid-feedback d-block" i18n>Must be shorter than {{errors('name').maxlength.requiredLength}} characters</small>
      </div>
    </div>
  </div>

  <div class="form-group">
    <div class="form-row">
      <div class="col">
        <label i18n [attr.for]="id + '-name'">Description</label>
        <input type="text" class="form-control" [class.is-invalid]="invalid('description')" formControlName="description" [id]="id + '-description'">
        <small *ngIf="invalid('description') && errors('description')?.required" class="invalid-feedback d-block" i18n>Required</small>
      </div>
    </div>
  </div>

  <div class="form-group">
    <person-series-group-select formControlName="groups" [personSeriesPeriod]="sourceOwnerSeries?.lastPeriod"></person-series-group-select>
    <small *ngIf="invalid('groups') && errors('groups')?.required" class="invalid-feedback d-block" i18n>Required</small>
  </div>

  <div class="form-group">
    <div class="form-row">
      <div class="col">
        <label i18n>Series type</label>
        <ng-select placeholder="Series type" i18n-placeholder [class.is-invalid]="invalid('seriesType')" [items]="seriesTypes" bindValue="value" bindLabel="label" formControlName="seriesType"></ng-select>
        <small *ngIf="invalid('seriesType') && errors('seriesType')?.required" class="invalid-feedback d-block" i18n>Required</small>
      </div>
    </div>
  </div>


  <div class="form-group row" formGroupName="numberFormat">
    <div class="col-8 col-form-label text-left">
      <label for="maxFractionDigits" i18n>Number of digits after decimal point ({{defaultNumberFormat.minFractionDigits}}-{{defaultNumberFormat.maxFractionDigits}})</label>
    </div>

    <div class="col-4" *ngIf="editMaxFractionDigits">
      <input id="maxFractionDigits" type="number" class="form-control" [class.is-invalid]="invalid('maxFractionDigits', form.get('numberFormat'))" formControlName="maxFractionDigits"
             [min]="defaultNumberFormat.minFractionDigits"
             [max]="defaultNumberFormat.maxFractionDigits">
      <form-field-errors [control]="form.get('numberFormat').get('maxFractionDigits')"></form-field-errors>
    </div>
    <div class="col-4" *ngIf="!editMaxFractionDigits">
      <div class="input-group">
        <input type="text" readonly class="form-control-plaintext" formControlName="maxFractionDigits" />
        <button type="button" class="btn btn-sm btn-outline-primary mr-3" (click)="editMaxFractionDigits = !editMaxFractionDigits" i18n>Edit</button>
      </div>
    </div>
  </div>

  <div class="form-group">
    <label><b i18n>Location type</b></label>
    <div class="form-row">
      <div class="custom-control custom-radio custom-control-inline">
        <input type="radio" class="custom-control-input" [class.is-invalid]="invalid('locationType')" [id]="id + '-ltcheck-address'" value="ADDRESS" formControlName="locationType">
        <label class="custom-control-label" [attr.for]="id + '-ltcheck-address'" i18n>Address</label>
      </div>

      <div class="custom-control custom-radio custom-control-inline">
        <input type="radio" class="custom-control-input" [class.is-invalid]="invalid('locationType')" [id]="id + '-ltcheck-uuid'" value="UUID" formControlName="locationType">
        <label class="custom-control-label" [attr.for]="id + '-ltcheck-uuid'" i18n>UUID</label>
      </div>

      <div class="custom-control custom-radio custom-control-inline">
        <input type="radio" class="custom-control-input" [class.is-invalid]="invalid('locationType')" [id]="id + '-ltcheck-coords'" value="COORDS" formControlName="locationType">
        <label class="custom-control-label" [attr.for]="id + '-ltcheck-coords'" i18n>Coords</label>
      </div>
    </div>
    <small *ngIf="invalid('locationType') && errors('locationType')?.required" class="invalid-feedback d-block" i18n>Required</small>
  </div>

  <div class="form-group" [hidden]="form.get('locationType').value != 'UUID'">
    <div class="form-row">
      <div class="col">
        <label i18n [attr.for]="id + '-uuid'">UUID</label>
        <input type="text" class="form-control" [class.is-invalid]="invalid('uuid')" formControlName="uuid" [id]="id + '-uuid'" maxlength="255">
        <small *ngIf="invalid('uuid') && errors('uuid')?.required" class="invalid-feedback d-block" i18n>Required</small>
        <small *ngIf="invalid('uuid') && errors('uuid')?.minlength" class="invalid-feedback d-block" i18n>Must be longer than {{errors('uuid').minlength.requiredLength}} characters</small>
        <small *ngIf="invalid('uuid') && errors('uuid')?.maxlength" class="invalid-feedback d-block" i18n>Must be shorter than {{errors('uuid').maxlength.requiredLength}} characters</small>
      </div>
    </div>
  </div>
  <div class="form-group" [hidden]="form.get('locationType').value != 'COORDS'">
    <div class="form-row">
      <div class="col">
        <label i18n [attr.for]="id + '-lat'">Lat</label>
        <input type="text" class="form-control" [class.is-invalid]="invalid('lat')" formControlName="lat" [id]="id + '-lat'">
        <small *ngIf="invalid('lat') && errors('lat')?.required" class="invalid-feedback d-block" i18n>Required</small>
        <small *ngIf="invalid('lat') && errors('lat')?.invalidLatitude" class="invalid-feedback d-block" i18n>Invalid latitude, must be between
          [{{errors('lat')?.invalidLatitude.min|number:'.1':'en'}}, {{errors('lat')?.invalidLatitude.max|number:'.1':'en'}}]</small>
      </div>
    </div>
  </div>
  <div class="form-group" [hidden]="form.get('locationType').value != 'COORDS'">
    <div class="form-row">
      <div class="col">
        <label i18n [attr.for]="id + '-lng'">Lng</label>
        <input type="text" class="form-control" [class.is-invalid]="invalid('lng')" formControlName="lng" [id]="id + '-lng'">
        <small *ngIf="invalid('lng') && errors('lng')?.required" class="invalid-feedback d-block" i18n>Required</small>
        <small *ngIf="invalid('lng') && errors('lng')?.invalidLongitude" class="invalid-feedback d-block" i18n>Invalid longitude, must be between
          [{{errors('lng')?.invalidLongitude.min|number:'.1':'en'}}, {{errors('lng')?.invalidLongitude.max|number:'.1':'en'}}]</small>
      </div>
    </div>
  </div>

  <core-address *ngIf="form.get('locationType').value == 'ADDRESS'" [form]="form" [showErrors]="this.showErrors" [location]="sourceOwnerSeries?.personSeries"></core-address>

  <ng-container *ngIf="isCityUser">
    <div class="form-group">
      <div class="checkbox">
        <input type="checkbox" formControlName="showOnCityPortal" (click)="showOnCityPortal()">
        <label i18n>Show on city portal</label>
      </div>
      <button *ngIf="form.get('showOnCityPortal').value" class="btn btn-primary btn-xs" type="button" (click)="showMap()">Edit location</button>
    </div>

    <div class="form-group" *ngIf="form.get('showOnCityPortal').value">
      <div class="form-row">
        <div class="col">
          <label [attr.for]="id + '-city-portal-type'" i18n>City portal type</label>
          <ng-select placeholder="City portal type" i18n-placeholder [class.is-invalid]="invalid('cityPortalType')" [items]="cityPortalTypes" formControlName="cityPortalType"></ng-select>
          <small *ngIf="invalid('cityPortalType') && errors('cityPortalType')?.required" class="invalid-feedback d-block" i18n>Required</small>
        </div>
      </div>
    </div>

    <div class="form-group" *ngIf="form.get('showOnCityPortal').value">
      <div class="form-row">
        <div class="col">
          <label i18n>Aggregation type</label>
          <ng-select placeholder="Aggregation type" i18n-placeholder [class.is-invalid]="invalid('aggregationGroupingType')" [items]="aggregationGroupingTypeOptions" bindValue="value" bindLabel="label" formControlName="aggregationGroupingType"></ng-select>
          <small *ngIf="invalid('aggregationGroupingType') && errors('aggregationGroupingType')?.required" class="invalid-feedback d-block" i18n>Required</small>
        </div>
      </div>
    </div>

    <div class="form-group" *ngIf="form.get('showOnCityPortal').value">
      <div class="form-row">
        <div class="col">
          <label i18n>Readable object name</label>
          <input type="text" placeholder="Readable object name" i18n-placeholder class="form-control" [class.is-invalid]="invalid('readableAddress')" formControlName="readableAddress" [id]="id + '-readable-address'">
          <small *ngIf="invalid('readableAddress') && errors('readableAddress')?.required" class="invalid-feedback d-block" i18n>Required</small>
        </div>
      </div>
    </div>

    <div class="form-group" *ngIf="form.get('showOnCityPortal').value">
      <div class="form-row">
        <div class="col">
          <div class="checkbox mt-1">
            <input type="checkbox" formControlName="differentiate" [id]="id + '-differentiate-check'">
            <label [attr.for]="id + '-differentiate-check'" i18n>Differentiate</label>
          </div>
        </div>
      </div>
    </div>

    <div class="form-group" *ngIf="cityPortalCoordsChanged">
      <label><b i18n>You are about to change location and/or object name out of many datapoints on the same address - do you want to change only this one or all of them?</b></label>
      <div class="form-row">
        <div class="custom-control custom-radio custom-control-inline">
          <input type="radio" class="custom-control-input" [class.is-invalid]="invalid('bulkEditAgreement')" [id]="id + '-bulk-edit-false'"
                 [value]="false" formControlName="bulkEditAgreement">
          <label class="custom-control-label" [attr.for]="id + '-bulk-edit-false'" i18n>Only this</label>
        </div>

        <div class="custom-control custom-radio custom-control-inline">
          <input type="radio" class="custom-control-input" [class.is-invalid]="invalid('bulkEditAgreement')" [id]="id + '-bulk-edit-true'"
                 [value]="true" formControlName="bulkEditAgreement">
          <label class="custom-control-label" [attr.for]="id + '-bulk-edit-true'" i18n>All of them</label>
        </div>
      </div>
      <small *ngIf="invalid('bulkEditAgreement') && errors('bulkEditAgreement')?.required" class="invalid-feedback d-block" i18n>Required</small>
    </div>
  </ng-container>

</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary btn-sm" aria-label="Close" (click)="close()" i18n>Close</button>
  <button type="button" class="btn btn-primary btn-sm" (click)="save()" [ladda]="progress" i18n>Save</button>
</div>
