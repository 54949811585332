import {Inject, Injectable, OnDestroy} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SMARTENCITY_MYDATA_CONFIG} from '../../injection-tokens';
import {MyDataConfig} from '../../mydata-config.model';
import {forkJoin, Observable} from 'rxjs';
import {Widget, WidgetDataset, WidgetDatasetValue, WidgetType} from './widget';
import {map} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import {
  FirstValue,
  LatestValue,
  PersonParameter,
  PersonSeries,
  PersonSeriesHistoricalResponse,
  QuestionnaireResultsField,
  QuestionnaireResultsResponseField,
  WidgetDataService
} from '@smartencity/core';
import {Subject} from 'rxjs/internal/Subject';
import moment, {Moment} from 'moment';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';

@Injectable({
  providedIn: 'root'
})
export class WidgetApiDataService implements WidgetDataService, OnDestroy {
  private ngDestroy = new Subject<void>();

  constructor(
    private http: HttpClient,
    @Inject(SMARTENCITY_MYDATA_CONFIG) private config: MyDataConfig
  ) { }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  public getWidgetPersonSeriesValue(widget: Widget, widgetDataset: WidgetDataset, rangeFrom?: Moment, rangeTo?: Moment, aggregationGroupingType?: string): Observable<WidgetDatasetValue> {
    let dateFrom, dateTo;
    if (!rangeFrom && !rangeTo && (widget.type === WidgetType.TIME_FRAME_CHART || widget.type === WidgetType.VALUE_TABLE)) {
      dateFrom = moment();
      dateTo = dateFrom.clone();
      aggregationGroupingType = widget.aggregationGroupingType;

      if (widget.timeframeType === 'DATE_RANGE') {
        dateFrom = moment(widget.periodStartAt);
        dateTo = moment(widget.periodEndAt);
      } else {
        switch (widget.periodType) {
          case 'HOUR': {
            dateFrom.subtract(widget.periodCount, 'hours');
            break;
          } case 'DAY': {
            dateFrom.subtract(widget.periodCount, 'days');
            break;
          } case 'MONTH': {
            dateFrom.subtract(widget.periodCount, 'months');
            break;
          } case 'YEAR': {
            dateFrom.subtract(widget.periodCount, 'years');
            break;
          }
        }
      }
    } else {
      dateFrom = (rangeFrom ? rangeFrom.clone() : null);
      dateTo = (rangeTo ? rangeTo.clone() : null);
    }

    if (aggregationGroupingType == null) {
      aggregationGroupingType = widget.aggregationGroupingType;
    }

    if (aggregationGroupingType === 'LIVE') {
      aggregationGroupingType = null;
    }

    if (dateFrom) {
      dateFrom.startOf('day');
    }

    if (dateTo) {
      dateTo.startOf('day').add(1, 'days');
    }

    if (widget.type === WidgetType.TIME_FRAME_CHART) {
      return this.getPersonSeriesHistoricalMeasurements(widget, widgetDataset.personSeries, dateFrom, dateTo, aggregationGroupingType).pipe(map((response: PersonSeriesHistoricalResponse) => {
        const wvs = new WidgetDatasetValue();
        wvs.widgetDataset = widgetDataset;
        wvs.personSeriesValue = {
          lastValue: null,
          periodLastValue: null,
          measurement: response.measurement,
          thresholds: []
        };
        return wvs;
      }));
    } else if (widget.type === WidgetType.VALUE_TABLE) {
      return combineLatest([
        this.getPersonSeriesFirstValue(widget, widgetDataset.personSeries, dateFrom, dateTo, aggregationGroupingType),
        this.getPersonSeriesLatestValue(widget, widgetDataset.personSeries, dateFrom, dateTo, aggregationGroupingType)
      ]).pipe(map(([firstPeriodValue, periodLastValue]: [FirstValue, LatestValue]) => {
        const wvs = new WidgetDatasetValue();
        wvs.widgetDataset = widgetDataset;
        wvs.personSeriesValue = {
          lastValue: periodLastValue,
          periodLastValue: periodLastValue,
          periodFirstValue: firstPeriodValue,
          measurement: null,
          thresholds: []
        };
        return wvs;
      }));
    } else {
      return this.getPersonSeriesLatestValue(widget, widgetDataset.personSeries, dateFrom, dateTo, aggregationGroupingType).pipe(map((response: LatestValue) => {
        const wvs = new WidgetDatasetValue();
        wvs.widgetDataset = widgetDataset;
        wvs.personSeriesValue = {
          lastValue: response,
          periodLastValue: response,
          measurement: null,
          thresholds: []
        };
        return wvs;
      }));
    }
  }

  public getWidgetPersonParameterValue(widget: Widget, widgetDataset: WidgetDataset, rangeFrom?: Moment, rangeTo?: Moment, aggregationGroupingType?: string): Observable<WidgetDatasetValue> {

    return this.getPersonParameter(widget, widgetDataset.personParameterId).pipe(map((personParameter: PersonParameter) => {
      const wvs = new WidgetDatasetValue();
      wvs.widgetDataset = widgetDataset;
      wvs.personParameterValue = personParameter;
      return wvs;
    }));
  }

  public getWidgetQ11eFieldValue(widget: Widget, widgetDataset: WidgetDataset, rangeFrom?: Moment, rangeTo?: Moment, aggregationGroupingType?: string): Observable<WidgetDatasetValue> {
    if (widget.type === WidgetType.TIME_FRAME_CHART) {
      const wvs = new WidgetDatasetValue();
      wvs.widgetDataset = widgetDataset;
      return of(wvs);
    } else {
      return this.getQuestionnaireFieldResults(widget, widgetDataset.q11eFieldId).pipe(map((resultsField: QuestionnaireResultsField) => {
        const wvs = new WidgetDatasetValue();
        wvs.widgetDataset = widgetDataset;
        wvs.q11eFieldValue = resultsField;
        return wvs;
      }));
    }
  }

  public getWidgetQ11eResponseFieldValue(widget: Widget, widgetDataset: WidgetDataset, rangeFrom?: Moment, rangeTo?: Moment, aggregationGroupingType?: string): Observable<WidgetDatasetValue> {
    return this.getQuestionnaireResponseFieldResults(widget, widgetDataset.q11eResponseFieldId).pipe(map((resultsField: QuestionnaireResultsResponseField) => {
      const wvs = new WidgetDatasetValue();
      wvs.widgetDataset = widgetDataset;
      wvs.q11eResponseFieldValue = resultsField;
      return wvs;
    }));
  }

  public getWidgetValues(widget: Widget, rangeFrom?: Moment, rangeTo?: Moment, aggregationGroupingType?: string): Observable<WidgetDatasetValue[]> {
    const observables: Observable<WidgetDatasetValue>[] = widget.datasets.map((widgetDataset: WidgetDataset) => {
      switch (widgetDataset.type) {
        case 'PERSON_SERIES': {
          return this.getWidgetPersonSeriesValue(widget, widgetDataset, rangeFrom, rangeTo, aggregationGroupingType);
        } case 'PERSON_PARAMETER': {
          return this.getWidgetPersonParameterValue(widget, widgetDataset, rangeFrom, rangeTo, aggregationGroupingType);
        } case 'Q11E_FIELD': {
          return this.getWidgetQ11eFieldValue(widget, widgetDataset, rangeFrom, rangeTo, aggregationGroupingType);
        } case 'Q11E_RESPONSE_FIELD': {
          return this.getWidgetQ11eResponseFieldValue(widget, widgetDataset, rangeFrom, rangeTo, aggregationGroupingType);
        }
      }
    });
    return observables.length ? forkJoin(observables) : of([]);
  }


  private getPersonParameter(widget: Widget, personParameterId: number) {

    return this.http.get(this.config.apiUrl + "/widget/" + widget.id + "/person-parameter", {
      params: {
        personParameterId: personParameterId
      }
    });
  }

  private getPersonSeriesHistoricalMeasurements(widget: Widget, personSeries: PersonSeries, dateFrom: Date, dateTo: Date, aggregationGroupingType?: string) {
    const params: any = {
      dateFrom: dateFrom ? dateFrom.toISOString() : dateFrom,
      dateTo: dateTo ? dateTo.toISOString() : dateTo,
      personSeriesId: personSeries ? personSeries.id : null
    };

    if (aggregationGroupingType) {
      params.aggregationGroupingType = aggregationGroupingType;
    }

    return this.http.get(this.config.apiUrl + "/widget/" + widget.id + "/person-series/measurement", {
      params: params
    });
  }

  private getPersonSeriesFirstValue(widget: Widget, personSeries: PersonSeries, dateFrom: Date, dateTo: Date, aggregationGroupingType?: string) {
    const params: any = {
      personSeriesId: personSeries ? personSeries.id : null
    };

    if (dateFrom) {
      params.dateFrom = dateFrom.toISOString();
    }

    if (dateTo) {
      params.dateTo = dateTo.toISOString();
    }
    if (aggregationGroupingType) {
      params.aggregationGroupingType = aggregationGroupingType;
    }

    return this.http.get(this.config.apiUrl + "/widget/" + widget.id + "/person-series/measurement/first", {
      params: params
    });
  }

  private getPersonSeriesLatestValue(widget: Widget, personSeries: PersonSeries, dateFrom: Date, dateTo: Date, aggregationGroupingType?: string) {
    const params: any = {
      personSeriesId: personSeries ? personSeries.id : null
    };

    if (dateFrom) {
      params.dateFrom = dateFrom.toISOString();
    }

    if (dateTo) {
      params.dateTo = dateTo.toISOString();
    }
    if (aggregationGroupingType) {
      params.aggregationGroupingType = aggregationGroupingType;
    }

    return this.http.get(this.config.apiUrl + "/widget/" + widget.id + "/person-series/measurement/latest", {
      params: params
    });
  }

  private getQuestionnaireFieldResults(widget: Widget, q11eFieldId: number) {

    return this.http.get(this.config.apiUrl + "/widget/" + widget.id + "/questionnaire-field/" + q11eFieldId + "/result");
  }

  private getQuestionnaireResponseFieldResults(widget: Widget, q11eFieldResponseId: number) {

    return this.http.get(this.config.apiUrl + "/widget/" + widget.id + "/questionnaire-response-field/" + q11eFieldResponseId + "/result");
  }

}
