import {Component, EventEmitter, Inject, Input, LOCALE_ID, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {Widget, WidgetDatasetValue} from '../../widget';
import {Subject} from 'rxjs/internal/Subject';
import {takeUntil} from 'rxjs/operators';
import moment, {Moment} from 'moment';
import {Observable} from "rxjs";
import {ChartDataService, SeriesNumberFormat, WidgetDataService} from '@smartencity/core';
import {WidgetPeriodHelper} from '../../../../../../../core/src/lib/helpers/widget-period-helper';
import {ValueTableColumnTranslations, WidgetConstants} from '../../widget-constants';
import {ToastrService} from 'ngx-toastr';

export interface DateRange<T> {
  start: T;
  end: T;
}
@Component({
  selector: 'mydata-widget-value-table',
  templateUrl: './widget-value-table.component.html',
  styles: [':host {height: 100%}']
})
export class WidgetValueTableComponent implements OnInit, OnDestroy, OnChanges {
  private ngDestroy = new Subject<void>();

  public headerLabels: ValueTableColumnTranslations = WidgetConstants.valueTableColLabels;

  @Input()
  public widget: Widget;

  @Input()
  public doUpdate: Observable<any>;

  @Input()
  public rangeFrom: Moment;

  @Input()
  public rangeTo: Moment;

  @Input()
  public aggregationGroupingType: string;

  @Output()
  public viewReady = new EventEmitter<boolean>();

  public dateRange: DateRange<Moment>;

  public startAt: Date;

  public endAt: Date;

  public hasDatasets = false;
  public hasData = false;

  public values: {
    label: string;
    value: any;
    first?: any;
    last?: any;
    unit: string;
    numberFormat?: SeriesNumberFormat
  }[];

  constructor(public widgetDataService: WidgetDataService,
              private chartDataService: ChartDataService,
              private toastr: ToastrService,
              @Inject(LOCALE_ID) public locale: string) {
  }

  ngOnInit() {
    this.viewReady.emit(true);
    this.update();
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.widget || changes.rangeFrom || changes.rangeTo || changes.aggregationGroupingType) {
      this.update();
    }
  }

  private update() {
    this.updateDateRange(this.rangeFrom, this.rangeTo);
    this.widgetDataService.getWidgetValues(this.widget, moment(this.startAt), moment(this.endAt), this.aggregationGroupingType).pipe(takeUntil(this.ngDestroy)).subscribe((values: WidgetDatasetValue[]) => {
      this.setValues(values);
    });
  }

  private updateDateRange(rangeFrom: any, rangeTo: any): void {
    let startAt = moment();
    let endAt = startAt.clone();

    if (!rangeFrom && !rangeTo) {
      if (this.widget.timeframeType === 'DATE_RANGE') {
        if (this.widget.periodStartAt && this.widget.periodEndAt) {
          startAt = moment(this.widget.periodStartAt);
          endAt = moment(this.widget.periodEndAt);
        }
      } else {
        startAt = WidgetPeriodHelper.calculateStartAt(startAt, this.widget.periodType, this.widget.periodCount);
      }

    } else {
      startAt = rangeFrom;
      endAt = rangeTo;
    }

    this.startAt = startAt.toDate();
    this.endAt = endAt.toDate();
  }

  private setValues(values: WidgetDatasetValue[]) {
    this.hasDatasets = !!values.length;

    let hasData = false;

    this.values = [];
    for (const wdv of values) {
      switch (wdv.widgetDataset.type) {
        case 'PERSON_SERIES': {
          if (wdv.personSeriesValue.measurement?.series?.truncated) {
            this.toastr.warning($localize`Displayed period for data point (${wdv.widgetDataset?.personSeries?.name}) was truncated to 5000 values. Please limit time period.`);
          }

          let firstValue = null;
          let firstValueTime = null;
          let lastValue = null;
          let value = null;
          let unit = null;

          if (wdv.personSeriesValue.periodFirstValue && wdv.personSeriesValue.periodFirstValue.measurement) {
            firstValueTime = moment(wdv.personSeriesValue.periodFirstValue.measurement.time).valueOf();
            firstValue = wdv.personSeriesValue.periodFirstValue.measurement.value;
            unit = wdv.personSeriesValue.periodFirstValue.measurement.unit;
          }

          if (wdv.personSeriesValue.periodLastValue && wdv.personSeriesValue.periodLastValue.measurement) {
            let lastValueTime = moment(wdv.personSeriesValue.periodLastValue.measurement.time).valueOf();
            if (unit == null) {
              unit = wdv.personSeriesValue.periodLastValue.measurement.unit;
            }

            if (firstValueTime == null || firstValueTime < lastValueTime) {
              lastValue = wdv.personSeriesValue.periodLastValue.measurement.value;
            }
          }

          if (firstValue != null || lastValue != null) {
            hasData = true;
            if (wdv.widgetDataset.differentiate) {
              if (firstValue != null && lastValue != null) {
                value = (lastValue - firstValue);
              }
            }

            if (value == null) {
              value = lastValue ? lastValue : firstValue;
            }

            this.values.push({
              label: wdv.widgetDataset.name,
              value: value,
              first: firstValue,
              last: lastValue,
              unit: unit,
              numberFormat: wdv.widgetDataset.numberFormat
            });
          }

          // Varasem loogika, kui oli vaja kuvada latest väärtust
          // if (wdv.personSeriesValue.lastValue || wdv.personSeriesValue.periodLastValue) {
          //   hasData = true;
          // }
          // if (wdv.personSeriesValue.periodLastValue && wdv.personSeriesValue.periodLastValue.measurement) {
          //   this.values.push({
          //     label: wdv.widgetDataset.name,
          //     value: wdv.personSeriesValue.periodLastValue.measurement.value,
          //     unit: wdv.personSeriesValue.periodLastValue.measurement.unit
          //   });
          // } else {
          //   this.values.push({
          //     label: wdv.widgetDataset.name,
          //     value: null,
          //     unit: null
          //   });
          // }
          break;
        } case 'PERSON_PARAMETER': {
          hasData = true;
          if (wdv.personParameterValue.value) {
            this.values.push({
              label: wdv.widgetDataset.name,
              value: wdv.personParameterValue.value,
              unit: wdv.personParameterValue.unit,
              numberFormat: wdv.widgetDataset.numberFormat
            });
          } else {
            this.values.push({
              label: wdv.widgetDataset.name,
              value: null,
              unit: null
            });
          }
          break;
        } case 'Q11E_FIELD': {
          const fieldValueFrequency = {};
          for (const response of wdv.q11eFieldValue.responses) {
            let value = null;
            if (response.type === 'TEXT') {
              value = response.text;
            } else if (response.type === 'VALUE') {
              value = response.value;
            } else if (response.type === 'SELECT') {
              value = response.option;
            }
            if (value) {
              if (fieldValueFrequency[value] || fieldValueFrequency[value] === 0) {
                fieldValueFrequency[value]++;
              } else {
                fieldValueFrequency[value] = 1;
              }
            }
          }

          for (const value of Object.keys(fieldValueFrequency)) {
            hasData = true;
            this.values.push({
              label: wdv.widgetDataset.name + ': ' + value,
              value: fieldValueFrequency[value],
              unit: 'Responses'
            });
          }
          break;
        } case 'Q11E_RESPONSE_FIELD': {
          hasData = true;
          let value = null;
          switch (wdv.q11eResponseFieldValue.type) {
            case 'PERSON_SERIES': {
              value = wdv.q11eResponseFieldValue?.personSeries?.name;
              break;
            }
            case 'PERSON_PARAMETER': {
              value = wdv.q11eResponseFieldValue?.personParameter?.name;
              break;
            }
            case 'VALUE': {
              value = wdv.q11eResponseFieldValue?.value;
              break;
            }
            case 'TEXT': {
              value = wdv.q11eResponseFieldValue?.text;
              break;
            }
            case 'SELECT': {
              value = wdv.q11eResponseFieldValue?.option;
              break;
            }
          }
          this.values.push({
            label: wdv.widgetDataset.name,
            value: value,
            unit: wdv.q11eResponseFieldValue.unit
          });
          break;
        }
      }
    }

    console.log("has data", hasData);

    this.hasData = hasData;
  }

}
