import {Inject, Injectable, NgZone} from '@angular/core';
import {TallinnService} from './tallinn.service';
import {ReplaySubject} from 'rxjs';
import {DisplaySettings} from '../model/display-settings';
import {SMARTENCITY_TALLINN_CONFIG} from '../injection-tokens';
import {TallinnConfig} from '../tallinn-config.model';
import {ThroughputPoint} from '../model/traffic';
import {MyLocationButton} from '@smartencity/core';
import {WaterTap} from '../model/water-tap';
import {WifiAccessPoint} from '../model/wifi-access-point';
import { AirQualityStation } from '../model/air-quality-station';
import { AirQualityStationsGmapLayer } from '../layers/air-quality-station-gmap-layer';
import {WifiAccessPointsGmapLayer} from '../layers/wifi-access-points-gmap-layer';
import {WaterTapsGmapLayer} from '../layers/water-taps-gmap-layer';
import {TrafficThroughputGmapLayer} from '../layers/traffic-throughput-gmap-layer';

@Injectable()
export class TallinnGmapService {
  private map;

  public trafficThroughputLayer: TrafficThroughputGmapLayer = null;
  public waterTapsLayer: WaterTapsGmapLayer = null;
  public wifiAccessPointsLayer: WifiAccessPointsGmapLayer = null;
  public airQualityStationsLayer: AirQualityStationsGmapLayer = null;

  displaySettings = new DisplaySettings();

  constructor(
    @Inject(SMARTENCITY_TALLINN_CONFIG) private config: TallinnConfig,
    public tallinnService: TallinnService,
    private ngZone: NgZone
  ) { }

  createMap(gmapElement) {
    const mapProp = {
      center: this.config.map.center,
      zoom: this.config.map.zoom,
      mapTypeControl: false,
      fullscreenControl: false,
      clickableIcons: false,
      styles: [
        <google.maps.MapTypeStyle>{
          featureType: 'transit.station.bus',
          stylers: [
            <google.maps.MapTypeStyler>{visibility: 'off'}
          ]
        }
      ]
    };

    this.map = new google.maps.Map(gmapElement.nativeElement, mapProp);

    const style = [
      {
        stylers: [
          {saturation: -80},
        ]
      },
      <google.maps.MapTypeStyle>{
        featureType: 'transit.station.bus',
        stylers: [
          <google.maps.MapTypeStyler>{visibility: 'off'}
        ]
      },
      <google.maps.MapTypeStyle>{
        featureType: 'poi.business',
        stylers: [
          <google.maps.MapTypeStyler>{visibility: 'on'}
        ]
      }
    ];
    this.map.mapTypes.set('map-style', new google.maps.StyledMapType(style, {}));
    this.map.setMapTypeId('map-style');

    // this.tallinnService.throughputPoints$.subscribe((points: ThroughputPoint[]) => {
    //   if (this.trafficThroughputLayer) {
    //     this.trafficThroughputLayer.setMap(null);
    //   }
    //
    //   this.trafficThroughputLayer = new TrafficThroughputGmapLayer(points);
    // });

    this.tallinnService.waterTaps$.subscribe((waterTaps: WaterTap[]) => {
      if (this.waterTapsLayer) {
        this.waterTapsLayer.setMap(null);
      }
      this.waterTapsLayer = new WaterTapsGmapLayer(waterTaps);
      if (this.displaySettings.waterTaps) {
        this.waterTapsLayer.setMap(this.map);
      }
    });

    this.tallinnService.wifiAccessPoints$.subscribe((accessPoints: WifiAccessPoint[]) => {
      if (this.wifiAccessPointsLayer) {
        this.wifiAccessPointsLayer.setMap(null);
      }
      this.wifiAccessPointsLayer = new WifiAccessPointsGmapLayer(accessPoints);
      if (this.displaySettings.wifiAccessPoints) {
        this.wifiAccessPointsLayer.setMap(this.map);
      }
    });

    this.tallinnService.airQualityStationPoints$.subscribe((airQualityStations: AirQualityStation[]) => {
      if (this.airQualityStationsLayer) {
        this.airQualityStationsLayer.setMap(null);
      }

      this.airQualityStationsLayer = new AirQualityStationsGmapLayer(airQualityStations, this.tallinnService);
      if (this.displaySettings.airQualityStations) {
        this.airQualityStationsLayer.setMap(this.map);
      }
    });

    this.tallinnService.airQualityStationSelected$.subscribe((airQualityStation: AirQualityStation) => {
      this.airQualityStationsLayer.setAirQualityStation(airQualityStation);
    });

    this.tallinnService.displaySettings$.subscribe(value => {
      this.displaySettings = value;
      this.updateDisplay();
    });

    new MyLocationButton(this.map);

    this.tallinnService.loadWaterTaps();
    this.tallinnService.loadWifiAccessPoints();
    this.tallinnService.loadAirQualityStations();
    // this.tallinnService.loadTrafficThroughPut();

    return this.map;
  }

  public setCenter(lat: number, lng: number) {
    this.map.panTo(new google.maps.LatLng(lat, lng));
  }

  updateDisplay() {
    // this.trafficThroughputLayer.setMap(this.displaySettings.trafficThroughput ? this.map : null);
    this.waterTapsLayer.setMap(this.displaySettings.waterTaps ? this.map : null);
    this.wifiAccessPointsLayer.setMap(this.displaySettings.wifiAccessPoints ? this.map : null);
    this.airQualityStationsLayer.setMap(this.displaySettings.airQualityStations ? this.map : null);
  }
}
