import {Component, Inject, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Subject} from 'rxjs/internal/Subject';
import {AuthService, PersonSeriesApiService, SeriesTargetError, Tenant, UserService} from '@smartencity/core';
import {MyDataConfig} from '../../../mydata-config.model';
import {ToastrService} from 'ngx-toastr';
import {NotificationService} from '../notification.service';
import {SMARTENCITY_MYDATA_CONFIG} from '../../../injection-tokens';

@Component({
  selector: 'mydata-error-row',
  templateUrl: './error-row.component.html',
  encapsulation: ViewEncapsulation.None
})
export class ErrorRowComponent implements OnInit, OnDestroy {
  private ngDestroy = new Subject<void>();
  public progress: string = null;

  @Input()
  public parentProgress: string = null;

  @Input()
  public item: SeriesTargetError;

  public tenant: Tenant = null;

  constructor(
    @Inject(SMARTENCITY_MYDATA_CONFIG) private config: MyDataConfig,
    public authService: AuthService,
    public personSeriesService: PersonSeriesApiService,
    public userService: UserService,
    public notificationService: NotificationService,
    private toastr: ToastrService
  ) {

  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  private stateChanged() {
    this.notificationService.loadPendingPendingSeries();
    this.notificationService.loadPendingMappingErrors();
    this.userService.confirmationListChanged();
  }

  acknowledgeError(item: SeriesTargetError) {
    this.personSeriesService.acknowledgeError(item).subscribe(
      (data: any) => {
        this.stateChanged();
      }, (error) => {
        this.progress = null;
        console.error(error);
        this.toastr.error($localize`Error submitting acknowledgement`);
      }, () => {
        this.progress = null;
      }
    );

  }
}
