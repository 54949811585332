import {Component, OnDestroy, OnInit} from '@angular/core';
import {BsModalService} from 'ngx-bootstrap/modal';
import {NotificationsModalComponent} from '../notifications-modal/notifications-modal.component';
import {Subject} from 'rxjs/internal/Subject';
import {takeUntil} from 'rxjs/operators';
import {NotificationService} from '../notification.service';
import {PageResponse, PendingPersonSeriesGroup, PendingSeries} from '@smartencity/core';
import { ListResponse, PendingPersonSeriesCancellation } from '@smartencity/core';

@Component({
  selector: 'mydata-notifications-btn',
  templateUrl: './notifications-btn.component.html',
  styleUrls: ['./notifications-btn.component.css']
})
export class NotificationsBtnComponent implements OnInit, OnDestroy {
  private ngDestroy = new Subject<void>();

  public totalCount = 0;
  public personSeriesStats: {
    pendingCount: number;
    cancelledCount: number;
    totalCount: number;
  } = { cancelledCount: 0, pendingCount: 0, totalCount: 0 };
  public metaStats: { count: number; } = {count: 0};
  public errorStats: { count: number; } = {count: 0};
  public cancellationStats: { count: number; } = {count: 0};
  public personSeriesGroupStats: {
    pendingCount: number;
    cancelledCount: number;
    totalCount: number;
  } = { cancelledCount: 0, pendingCount: 0, totalCount: 0 };
  constructor(
    private modalService: BsModalService,
    private notificationService: NotificationService
  ) {

    this.notificationService.pendingSeries$.pipe(takeUntil(this.ngDestroy)).subscribe((page: PageResponse<PendingSeries>) => {
      this.personSeriesStats.totalCount = page.totalElements;
      this.updateTotalCount();
    });

    this.notificationService.pendingSeriesMeta$.pipe(takeUntil(this.ngDestroy)).subscribe((data) => {
      this.metaStats.count = data.length;
      this.updateTotalCount();
    });

    this.notificationService.pendingSeriesTargetErrors$.pipe(takeUntil(this.ngDestroy)).subscribe((data) => {
      this.errorStats.count = data.length;
      this.updateTotalCount();
    });

    this.notificationService.pendingSeriesRemove$.subscribe((pendingSeries: PendingSeries) => {
      this.personSeriesStats.totalCount--;
      this.updateTotalCount();
    });

    this.notificationService.pendingSeriesGroups$.pipe(takeUntil(this.ngDestroy)).subscribe((page: PageResponse<PendingPersonSeriesGroup>) => {
      this.personSeriesGroupStats.totalCount = page.totalElements;
      this.updateTotalCount();
    });

    this.notificationService.pendingCancellations$.pipe(takeUntil(this.ngDestroy)).subscribe((list: PendingPersonSeriesCancellation[]) => {
      this.cancellationStats.count = list.length;
      this.updateTotalCount();
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  showNotifications(): void {
    if (this.totalCount) {
      this.modalService.show(NotificationsModalComponent, {
        ignoreBackdropClick: false,
        class: 'modal-xl-half',
        initialState: {}
      });
    }
  }

  updateTotalCount() {
    this.totalCount = this.personSeriesGroupStats.totalCount +
                      this.personSeriesStats.totalCount +
                      this.metaStats.count +
                      this.errorStats.count +
                      this.cancellationStats.count;
  }
}
