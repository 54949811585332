import OverlayView = google.maps.OverlayView;
import { EvCharger } from '../model/ev-charger';

export class EvChargersLayer extends OverlayView {

  private div: any;
  public evChargers: EvCharger[] = [];
  private popoverTimeout = null;
  private selectedEvCharger: EvCharger;

  constructor(evChargers: EvCharger[]) {
    super();
    this.evChargers = evChargers;
  }

  onAdd() {
    const panes = this.getPanes();
    if (panes == null) {
      return;
    }

    this.div = document.createElement('DIV');
    this.div.style.position = 'absolute';
    this.div.style.cursor = 'pointer';
    this.div.classList.add('ev-chargers-layer');
    this.div.style.paddingLeft = '0px';
    panes.overlayMouseTarget.appendChild(this.div);
  }

  onRemove() {
    if (this.div) {
      this.div.parentNode.removeChild(this.div);
      this.div = null;
    }
  }

  draw() {
    if (!this.div) {
      return;
    }

    this.div.innerHTML = this.getLayerHtml();
    this.resetPopovers();
  }

  private getLayerHtml() {
    let html = '';
    if (!this.evChargers) {
      return html;
    }

    this.evChargers.forEach((evCharger: EvCharger) => {
      const point = this.getProjection().fromLatLngToDivPixel(new google.maps.LatLng(evCharger.lat, evCharger.lng));
      const x = point.x - 16;
      const y = point.y - 16;
      const isSelected = this.selectedEvCharger == evCharger ? ' active' : '';
      let className = 'pointer-charger';
      let popoverContent = '<strong>' + evCharger.name + '</strong>';
      evCharger.series.forEach((evChargerSeries) => {
        popoverContent += '<br />' + evChargerSeries.value + ' ' + evChargerSeries.unit;
      });
      html += '<div charger-id="' + evCharger.id + '" class="' + className + isSelected + '" style="left: ' + x + 'px; top: ' + y + 'px" data-toggle="popover" data-placement="top" data-trigger="hover" ' +
        'data-html="true" ' +
        'data-content="' + popoverContent + '"></div>';
    });

    return html;
  }

  setMap(map) {
    super.setMap(map);
  }

  private resetPopovers() {
    ($(this.div).find('[data-toggle="popover"]') as any)
      .popover('dispose')
      .popover({trigger: 'hover'});
  }

  setSelected(evCharger: EvCharger): void {
    this.selectedEvCharger = evCharger;
  }
}
