<ng-container *ngIf="item">
<div class="row">
  <div class="col-md-8">
    <p i18n="@@mydata.error-message.mainmessage" >Series has issues with mapping. Has the target device been deleted?</p>

    <dl class="row">
      <dt class="col-sm-3" i18n="@@mydata.error-message.seriesname">Name</dt>
      <dd class="col-sm-9">{{item.personSeries.name}}</dd>

      <dt class="col-sm-3" i18n="@@mydata.error-message.seriesid">Person Series ID</dt>
      <dd class="col-sm-9">{{ item.personSeries.id}}</dd>

      <dt class="col-sm-3" i18n="@@mydata.error-message.seriecreatedby">Created by:</dt>
      <dd class="col-sm-9">{{ item.personSeries.createdByPerson.displayName}} ({{ item.personSeries.createdByPerson.countryCode}}{{ item.personSeries.createdByPerson.registrationNumber}})</dd>

      <dt class="col-sm-3" i18n="@@mydata.error-message.errorcount">Error count</dt>
      <dd class="col-sm-9">{{item.errorCount}}</dd>

      <dt class="col-sm-3" i18n="@@mydata.error-message.lastmsg">Last Message ({{item.lastErrorAt  | date : 'short' }}):</dt>
      <dd class="col-sm-9">{{item.lastErrorMessage}}</dd>
    </dl>
  </div>

  <div class="action-buttons text-right col-md-4">
    <ng-container >
      <button type="button" class="btn btn-sm btn-outline-primary" (click)="acknowledgeError(item)" [disabled]="progress || parentProgress" i18n>Mark as read</button>
    </ng-container>
  </div>
</div>
</ng-container>
