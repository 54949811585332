<ng-container *ngIf="item?.period?.confirmationStatus == 'PENDING' || item?.period?.confirmationStatus=='CANCELLED'">
  <div class="d-flex flex-grow-1 flex-column" *ngIf="item.period.ownerType == 'OWNER' || item.period.ownerType == 'MANAGER'" i18n>{{assignerName}} is connecting <b>{{datapointName}} with {{location}}</b><br><small class="help-text">{{comment}}</small></div>
  <div class="d-flex flex-grow-1 flex-column" *ngIf="item.period.ownerType == 'DATA_OWNER'" i18n>{{assignerName}} you as data owner for <b>{{datapointName}} at {{location}}</b><br><small class="help-text">{{comment}}</small></div>
  <div class="d-flex flex-grow-1 flex-column" *ngIf="item.period.ownerType == 'CONSENT'" i18n>{{assignerName}} shares data from <b>{{datapointName}}at {{location}}</b><br><small class="help-text">{{comment}}</small></div>
</ng-container>
<div class="action-buttons text-right">
  <ng-container *ngIf="item?.period?.confirmationStatus == 'PENDING'">
    <button type="button" class="btn btn-sm btn-danger ml-2 mb-2" (click)="declinePersonSeries(item)" [disabled]="progress || parentProgress" i18n>Decline</button>
    <button type="button" class="btn btn-sm btn-success ml-2 mb-2" (click)="acceptPersonSeries(item)" [disabled]="progress || parentProgress" i18n>Accept</button>
  </ng-container>
  <ng-container *ngIf="item?.period?.confirmationStatus == 'CANCELLED'">
    <button type="button" class="btn btn-sm btn-outline-primary" (click)="acknowledgePersonSeries(item)" [disabled]="progress || parentProgress" i18n>Mark as read</button>
    <p class="mb-0 help-text text-nowrap">(This request has been cancelled)</p>
  </ng-container>
</div>
