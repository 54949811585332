import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {SMARTENCITY_CORE_CONFIG} from '../injection-tokens';
import {CoreConfig} from '../core-config.model';
import {UrlParamsHelper} from '../helpers/url-params-helper';
import {PageResponse} from '../models/page-response';
import {AuditLog} from '../models/audit-log';


@Injectable({
  providedIn: 'root'
})
export class AuditLogService {

  constructor(
    @Inject(SMARTENCITY_CORE_CONFIG) private config: CoreConfig,
    private http: HttpClient) { }


  getLogs(params: any): Observable<PageResponse<AuditLog>> {
    if (!params) params = {};

    return this.http.get<PageResponse<AuditLog>>(this.config.apiUrl + '/audit-log/search', {
      params: UrlParamsHelper.cleanParams(params)
    });
  }

}
