import {Injectable, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs/internal/Subject';

@Injectable()
export class FormulaTemplateService implements OnDestroy {
  private ngDestroy = new Subject<void>();
  private updateSource = new Subject<void>();
  public update$ = this.updateSource.asObservable();

  constructor() { }

  ngOnDestroy(): void {
    this.updateSource.complete();
    this.ngDestroy.complete();
  }
}
