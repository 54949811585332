import {Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {MandateData, MandateTreeService} from '../mandate-tree.service';
import {Subject} from 'rxjs/internal/Subject';
import {take, takeUntil} from 'rxjs/operators';
import {BsModalService} from 'ngx-bootstrap/modal';
import {EditMandateModal} from '../mandate-edit-modal/edit-mandate-modal.component';
import {AuthService, Mandate, UserService} from '@smartencity/core';
import {FormControl, FormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {MandatePersonConsentsModalComponent} from '../mandate-person-consents-modal/mandate-person-consents-modal.component';
import {AddMandateComponent} from '../add/add-mandate.component';
import {NavbarService} from '../../navbar/navbar.service';
import {MandateLogModalComponent} from '../mandate-log-modal/mandate-log-modal.component';
import {Observable} from 'rxjs/internal/Observable';
import {MandateNavbarService} from '../mandate-navbar.service';

@Component({
  selector: 'mandate-tree',
  templateUrl: './mandate-tree.component.html',
  providers: [MandateTreeService]
})
export class MandateTree implements OnInit, OnDestroy {
  private ngDestroy = new Subject<void>();

  @ViewChild('navActions', {static: true})
  public navTemplate: TemplateRef<any>;

  public showLogsAction$: Observable<TemplateRef<any>> = this.mandateNavbarService.showLogsAction$;

  public selectedData: any;

  @Input()
  view = 'mandate-tree';

  data: any = [];

  private currentMandate: Mandate;

  filterForm: FormGroup = new FormGroup({
    onlyActive: new FormControl(true)
  });

  constructor(private mandateTreeService: MandateTreeService,
              private modalService: BsModalService,
              private authService: AuthService,
              private userService: UserService,
              private router: Router,
              private navbarService: NavbarService,
              private mandateNavbarService: MandateNavbarService) { }

  public list = [];

  ngOnInit(): void {
    // d3.csv(
    //   'https://raw.githubusercontent.com/bumbeishvili/sample-data/main/org.csv'
    // ).then((data) => {
    //   this.data = data;
    // })

    this.navbarService.setActionsTemplate(this.navTemplate);

    this.authService.currentMandate$.pipe(takeUntil(this.ngDestroy)).subscribe((mandate: Mandate) => {
      this.currentMandate = mandate;
      this.loadMandates();
    });


    this.mandateTreeService.nodeSelected$.pipe(takeUntil(this.ngDestroy)).subscribe((data: any) => {
      this.selectedData = data;
    });

    this.filterForm.get('onlyActive').valueChanges.subscribe((value) => {
      this.loadMandates();
    });
  }

  private loadMandates(): void {
    this.userService.getMandates({
      onlyActive: this.filterForm.get("onlyActive").value,
      withConsents: this.view != null && this.view == 'mandate-consent-tree' ? true : null
    }).pipe(takeUntil(this.ngDestroy)).subscribe((mandates: Mandate[]) => {
      this.list = [this.mandateTreeService.mapToTree(this.currentMandate, mandates)];
    });
  }

  create() {
    const modalRef = this.modalService.show(AddMandateComponent);
    modalRef.content.savedEmitter.pipe(takeUntil(this.ngDestroy), take(1)).subscribe((saved) => {
      this.loadMandates();
    });
  }


  getNodeClass(node: MandateData): string {
    const classes: string[] = [];


    if (node.self) {
      classes.push('me');
    } else if (node.type == 'ROOT') {
      classes.push('root-node');
    } else if (node.type == 'NODE') {
      classes.push('mandate-node');
    } else if (node.type == 'CONSENT') {
      classes.push('consent-node');
    }

    if (node.data?.mandate?.endAt) {
      classes.push('inactive');
    }

    return (classes.length > 0 ? classes.join(' ') : null);

  }

  getMandateIcon(node: MandateData): string {
    const mandate = node.data.mandate;
    if (!mandate) {
      return '';
    }

    return (node.type != 'ROOT' && mandate && (mandate.type == 'OWNER' || mandate.permissions.filter(permission => permission.key == 'EDIT_MANDATES').length > 0) ? 'icon-person-group' : 'icon-person');
  }

  viewNode(node: MandateData): void {
    if (node.type == 'CONSENT') {
      return this.viewConsents(node);
    }

    this.editMandate(node);
  }

  editMandate(node: MandateData): void {
    if (node.type == 'ROOT' || node.type == 'CONSENT' || this.view == 'mandate-consent-tree') {
      return;
    }

    const mandate: Mandate = this.currentMandate;
    if (mandate.type != 'OWNER' && mandate.permissions.filter(permission => permission.key == 'EDIT_MANDATES').length == 0) {
      return;
    }

    const modalRef = this.modalService.show(EditMandateModal, {
      ignoreBackdropClick: true,
      class: 'modal-lg',
      initialState: {
        mandateData: node,
        currentUserMandate: this.currentMandate
      }
    });

    modalRef.content.savedEmitter.subscribe((result) => {
      this.loadMandates();
    });

    modalRef.content.cancelledEmitter.subscribe(async (mandate) => {
      await this.handleMandateCancel(mandate);
    });
  }

  viewConsents(node: MandateData): void {
    if (this.view !== 'mandate-consent-tree') {
      return;
    }

    const parentNode = this.mandateTreeService.getNode(node.parentId);
    if (!parentNode) {
      return;
    }

    const modalRef = this.modalService.show(MandatePersonConsentsModalComponent,
      {
        backdrop: true,
        ignoreBackdropClick: true,
        class: 'modal-lg',
        initialState: {
          consent: node.data.consent
        }
      }
    );

    modalRef.content.noResultClose.subscribe(() => {
      this.loadMandates();
    });
  }

  isLeaf(node: MandateData): boolean {
    return node.children.length === 0;
  }

  isRoot(node: MandateData): boolean {
    return node.type == 'ROOT';
  }

  getChildren(node: MandateData): MandateData[] {
    return node.children.filter(c => !c.hidden);
  }

  toggleNodeChildren(node: MandateData): void {
    node.expanded = !node.expanded;
    for (const child of node.children) {
      this.doToggleHide(child);
    }
  }

  private doToggleHide(node: MandateData): void {
    node.hidden = !node.hidden;
    for (const child of node.children) {
      this.doToggleHide(child);
    }
  }

  onNodeSelect(key: string): void {
    this.mandateTreeService.selecteNode(key);
  }

  ngOnDestroy() {
    this.ngDestroy.next();
    this.ngDestroy.complete();
    this.navbarService.setActionsTemplate(null);
  }

  private async handleMandateCancel(mandate: Mandate): Promise<void> {
    this.loadMandates();

    const oldProfile = this.authService.profileData;

    if (mandate.user.countryCode === this.authService.profileData.user.countryCode && mandate.user.personalId === this.authService.profileData.user.personalId) {
      await this.authService.reloadProfile();
    }

    if (mandate.user.countryCode === oldProfile.mandate.person.countryCode &&
      mandate.user.personalId === oldProfile.mandate.person.registrationNumber ||
      mandate.user.personalId === oldProfile.user.personalId) {
      await this.authService.selectRole(this.authService.profileData.availablePersons[0]);
      this.router.navigate(['/mydata']);
    }
  }
}
