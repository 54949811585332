<div class="modal-header">
  <h5 class="modal-title w-100">{{sourceOwnerSeries.personSeries.name}}&nbsp;<span i18n>data</span></h5>
  <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
    <i class="fal fa-times"></i>
  </button>
</div>
<div class="modal-body">

  <div *ngIf="confirmed && !form.valid" class="alert alert-danger">
    <div i18n>Form is invalid</div>
    <div *ngIf="form.get('values')?.controls.length == 0">
      <div i18n>Add data</div>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col text-right">
      <div class="file-field d-inline-block mr-2 mb-2 mb-md-0">
      <button class="btn btn-sm btn-outline-primary mr-2 mb-2 mb-md-0" [popover]="exampleTemplate" popoverTitle="Csv import format" i18n-popoverTitle>
        <i class="fal fa-info"></i>&nbsp;
        <ng-container i18n>Csv import format</ng-container>
      </button>
      <ng-template #exampleTemplate>
        <p i18n>CSV data must be in the following format:</p>
        <pre class="card card-body text-left">2019-01-01,2445
2019-02-01,2342
2019-03-01,1943
2019-04-01,1843
2019-05-01,1143
2019-06-01,1238
2019-07-01,1094
2019-08-01,1014
2019-09-01,1123
2019-10-01,1701
2019-11-01,1923
2019-12-01,2291</pre>
        <div>
          <button (click)="downloadExampleCsv()" class="btn btn-sm btn-outline-primary">
            <i class="fal fa-download"></i>&nbsp;
            <ng-container i18n>Download example csv</ng-container>
          </button>
        </div>
      </ng-template>

        <div class="btn btn-sm btn-primary position-relative mr-2">
          <i class="fal fa-upload"></i>&nbsp;<span i18n>Import from csv</span>
          <input (change)="uploadCsv($event)" #uploadInput type="file">
        </div>
      </div>
      <button (click)="addRow()" type="button" class="btn btn-sm btn-primary mr-3 mr-md-0">
        <i class="fal fa-plus"></i>&nbsp;<span i18n>Add new value</span>
      </button>
    </div>
  </div>

  <table [formGroup]="form" class="table table-responsive-md btn-table modal-table">
    <thead>
    <tr>
      <td class="w-250p" i18n>Date</td>
      <td class="w-150p" i18n>Value</td>
      <td></td>
    </tr>
    </thead>
    <tbody>
      <tr *ngFor="let rowForm of valuesArray.controls; let i = index" [formGroup]="rowForm">
        <td class="w-250p align-top">
          <datepicker-wrapper [control]="rowForm.get('time')"></datepicker-wrapper>
          <small *ngIf="invalid('time', rowForm) && errors('time', rowForm)?.required" class="invalid-feedback d-block" i18n>Required</small>
        </td>
        <td class="align-top">
          <input type="text" name="value" formControlName="value" class="form-control" [class.is-invalid]="invalid('value', rowForm)" placeholder="Value" i18n-placeholder>
          <small *ngIf="invalid('value', rowForm) && errors('value', rowForm)?.required" class="invalid-feedback d-block" i18n>Required</small>
          <small *ngIf="invalid('value', rowForm) && errors('value', rowForm)?.pattern" class="invalid-feedback d-block" i18n>Invalid</small>
        </td>
        <td class="text-right align-top">
          <button (click)="removeRow(i)" type="button" class="btn btn-secondary btn-sm mt-1"><i class="fal fa-times"></i>&nbsp;<span i18n>Remove</span></button>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<div class="modal-footer">
  <button type="button" class="waves-light btn btn-secondary btn-sm" aria-label="Close" (click)="close()" mdbWavesEffect i18n>Cancel</button>
  <button type="button" class="waves-light btn btn-primary btn-sm" aria-label="Close" (click)="confirm()" mdbWavesEffect [ladda]="saving" i18n>Save</button>
</div>
