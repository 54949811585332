<div class="modal-header">
  <h5 class="modal-title w-100" i18n>Edit location</h5>
  <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
    <span class="fal fa-times"></span>
  </button>
</div>
<div class="modal-body" >
  <input #pacinput id="pac-input" class="controls form-control" type="text" placeholder="Search" i18n-placeholder style="top: 10px;">
  <div class="col-md-12" #gmap style="width: 100%; height: 500px"></div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary btn-sm" (click)="confirm()">Ok</button>
</div>
