import {Stop} from '../stop';
import {Bus} from '../bus';
import {ThroughputPoint} from '../traffic';
import {EvCharger} from '../ev-charger';
import {BicycleStation} from '../../component/bicycle-stations-heatmap/bicylce-stations.model';
import {EvChargerDk} from '../ev-charger-dk';

export class MobilitySearchResults {
  public buses: Bus[] = [];
  public stops: Stop[] = [];
  public throughputPoints: ThroughputPoint[] = [];
  public evChargers: EvCharger[] = [];
  public evChargersDk: EvChargerDk[] = [];
  public bicycleStations: BicycleStation[] = [];
}
