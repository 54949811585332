import {ErrorHandler, Injectable} from '@angular/core';
import {LoggerService} from './logger.service';
import {AuthService} from '../auth/auth.service';

@Injectable()
export class ErrorHandlerService implements ErrorHandler {
  constructor(private logger: LoggerService, private authService: AuthService) { }

  handleError(error: Error) {
    console.error(error);
    let personInfo = (this.authService.profileData ? this.getPersonInfo(this.authService.profileData) : "ANONYMOUS");
    this.logger.error(personInfo, error.message, error.stack);
  }

  private getPersonInfo(profileData: any): string {
    return "[" + profileData.user.countryCode + profileData.user.personalId + "," + profileData.mandate.person.countryCode + profileData.mandate.person.registrationNumber + "]";
  }

}
