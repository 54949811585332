import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SMARTENCITY_CORE_CONFIG} from '../../injection-tokens';
import {CoreConfig, MapsConfig} from '../../core-config.model';
import {PersonSeriesHistoricalResponse} from '../../models/series';

export class ShowCityPortalRequest {
  showOnCityPortal: boolean;
  cityPortalType: string;
  aggregationGroupingType: string;
  differentiate: boolean;
  readableAddress: string;

  constructor(props?: any) {
    if (!props) {
      return;
    }
    this.showOnCityPortal = props.showOnCityPortal;
    this.cityPortalType = props.cityPortalType;
    this.aggregationGroupingType = props.aggregationGroupingType;
    this.differentiate = props.differentiate;
    this.readableAddress = props.readableAddress;
  }
}


@Injectable({
  providedIn: 'root'
})
export class CityApiService {

  public comparisonBuildings: any[] = [];

  constructor(
    @Inject(SMARTENCITY_CORE_CONFIG) private config: CoreConfig,
    private http: HttpClient
  ) {
  }

  getMapsConfig(): MapsConfig {
    return this.config.map;
  }

  addComparisonBuilding(building: any) {
    if (!this.isInComparison(building)) {
      this.comparisonBuildings.push(building);
    }
  }

  public isInComparison(building: any) {
    if (!building) {
      return false;
    }
    for (const i in this.comparisonBuildings) {
      if (this.comparisonBuildings[i].id === building.id) {
        return true;
      }
    }
    return false;
  }

  removeComparisonBuilding(building: any) {
    this.comparisonBuildings.splice(this.comparisonBuildings.indexOf(building), 1);
  }

  getBuildings(): Observable<any> {
    return this.http.get(this.config.cityApiUrl + '/building', {});
  }

  getWeather(): Observable<any> {
    return this.http.get(this.config.cityApiUrl + '/weather', {});
  }

  // Mingi legacy endpoint vist. Kunagi näidati ilmaalla hetke ilma ja ennustust. Millegi pärast jäänud ilma näitamiseks kasutusse "forecast"
  getForecast(): Observable<any> {
    return this.http.get(this.config.cityApiUrl + '/forecast', {});
  }

  getBusesCount(): Observable<any> {
    return this.http.get(this.config.cityApiUrl + '/mobility/bus-count', {});
  }

  getLitCount(): Observable<any> {
    return this.http.get(this.config.cityApiUrl + '/person-series/light/lit-count', {});
  }

  getServicesStats(): Observable<any> {
    return this.http.get(this.config.cityApiUrl + '/city-services/stats', {});
  }

  getBuildingsStats(): Observable<any> {
    return this.http.get(this.config.cityApiUrl + '/buildings/stats', {});
  }

  getCityPersonSeries(personSeriesId: number): Observable<any> {
    return this.http.get(this.config.cityApiUrl + '/person-series/' + personSeriesId , {});
  }

  updateShowCityPersonSeriesOnCityPortal(personSeriesId: number, showOnCityPortal: boolean): Observable<any> {
    return new Observable((observer) => {

      this.getCityPersonSeries(personSeriesId).subscribe((cityPersonSeries) => {

        const showCityPortalRequest = new ShowCityPortalRequest({
          showOnCityPortal: showOnCityPortal,
          cityPortalType: cityPersonSeries.cityPortalType,
          aggregationGroupingType: cityPersonSeries.aggregationGroupingType,
          differentiate: cityPersonSeries.differentiate,
          readableAddress: cityPersonSeries.readableAddress
        });

        this.setShowCityPersonSeriesOnCityPortal(personSeriesId, showCityPortalRequest).subscribe(() => {
          observer.next();
          observer.complete();
        }, (err) => {
          // if (err.status == 404 && !showOnCityPortal) {
          //   console.log("Person series has already been removed from city server");
          //   observer.next();
          //   observer.complete();
          // } else {
          //   observer.error(err);
          // }

          observer.error(err);
        });
      }, (err) => {
        if (err.status == 404 && !showOnCityPortal) {
          console.log("Person series has already been removed from city server");
          observer.next();
          observer.complete();
        } else {
          observer.error(err);
        }
      });

    });
  }

  setShowCityPersonSeriesOnCityPortal(personSeriesId: number, body: ShowCityPortalRequest): Observable<any> {
    return this.http.put(this.config.cityApiUrl + '/person-series/' + personSeriesId + '/show-on-city-portal', body);
  }

  updateCityPersonSeriesMeta(personSeriesId: number): Observable<any> {
    return this.http.put(this.config.cityApiUrl + '/person-series/' + personSeriesId + '/update-meta', {});
  }

  getPersonSeriesHistoricalMeasurements(id: number, dateFrom: any, dateTo: any, aggregationGroupingType: string): Observable<PersonSeriesHistoricalResponse> {
    const params: any = {
      dateFrom: dateFrom,
      dateTo: dateTo
    };
    if (aggregationGroupingType) {
      params.aggregationGroupingType = aggregationGroupingType;
    }

    return this.http.get(this.config.cityApiUrl + '/person-series/' + id + '/historical/measurement', {
      params: params
    }) as Observable<PersonSeriesHistoricalResponse>;
  }
}
