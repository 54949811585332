import {Inject, Injectable, OnDestroy} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SMARTENCITY_MYDATA_CONFIG} from '../../injection-tokens';
import {MyDataConfig} from '../../mydata-config.model';
import {forkJoin, Observable} from 'rxjs';
import {Widget, WidgetDataset, WidgetDatasetValue, WidgetType} from './widget';
import {takeUntil} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import {
  PersonParameter,
  QuestionnaireResultsField,
  QuestionnaireResultsResponseField, WidgetDataService,
} from '@smartencity/core';
import {Subject} from 'rxjs/internal/Subject';
import moment, {Moment} from 'moment';
import {WidgetPeriodHelper} from '../../../../../core/src/lib/helpers/widget-period-helper';

@Injectable({
  providedIn: 'root'
})
export class WidgetRandomDataService implements WidgetDataService, OnDestroy {
  private ngDestroy = new Subject<void>();

  public widgets$ = new Subject<Widget[]>();

  constructor(
    private http: HttpClient,
    @Inject(SMARTENCITY_MYDATA_CONFIG) private config: MyDataConfig
  ) { }

  ngOnDestroy(): void {
    this.widgets$.complete();
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  public loadWidgets() {
    alert('LoadWidgets');
    this.getWidgets().pipe(takeUntil(this.ngDestroy)).subscribe((widgets: Widget[]) => {
      this.widgets$.next(widgets);
    });
  }

  public getWidgets(): Observable<Widget[]> {
    return this.http.get<Widget[]>(this.config.apiUrl + '/widget');
  }

  public save(widget: Widget): Observable<Widget> {
    return this.http.post<Widget>(this.config.apiUrl + '/widget', widget);
  }

  public update(widget: Widget): Observable<Widget> {
    return this.http.put<Widget>(this.config.apiUrl + '/widget/' + widget.id, widget);
  }

  public delete(widget: Widget): any {
    return this.http.delete(this.config.apiUrl + '/widget/' + widget.id);
  }

  public getWidgetValues(widget: Widget, rangeFrom?: Moment, rangeTo?: Moment, aggregationGroupingType?: string): Observable<WidgetDatasetValue[]> {
    const observables: Observable<WidgetDatasetValue>[] = widget.datasets.map((widgetDataset: WidgetDataset) => {
      return this.getWidgetDatasetValue(widgetDataset, widget, rangeFrom, rangeTo, aggregationGroupingType);
    });
    return observables.length ? forkJoin(observables) : of([]);
  }

  private valuesCache: any = {}

  private getWidgetDatasetValue(widgetDataset: WidgetDataset, widget: Widget, rangeFrom?: Moment, rangeTo?: Moment, aggregationGroupingType?: string): Observable<WidgetDatasetValue>{

    const key = this.getCacheKey(widget, widgetDataset, rangeFrom, rangeTo, aggregationGroupingType)
    if(this.valuesCache[key]){
      const value = this.valuesCache[key] as WidgetDatasetValue;
      value.widgetDataset = widgetDataset
      return of(value);
    }

    let value;
    switch (widgetDataset.type) {
      case 'PERSON_SERIES': {
        value = this.getWidgetPersonSeriesValue(widget, widgetDataset, rangeFrom, rangeTo, aggregationGroupingType);
        break;
      } case 'PERSON_PARAMETER': {
        value = this.getWidgetPersonParameterValue(widget, widgetDataset, rangeFrom, rangeTo, aggregationGroupingType);
        break;
      } case 'Q11E_FIELD': {
        value = this.getWidgetQ11eFieldValue(widget, widgetDataset, rangeFrom, rangeTo, aggregationGroupingType);
        break;
      } case 'Q11E_RESPONSE_FIELD': {
        value = this.getWidgetQ11eResponseFieldValue(widget, widgetDataset, rangeFrom, rangeTo, aggregationGroupingType);
        break;
      }
    }

    this.valuesCache[key] = value;
    return of(value);
  }

  private getCacheKey(widget: Widget, widgetDataset: WidgetDataset, rangeFrom?: Moment, rangeTo?: Moment, aggregationGroupingType?: string){
    return widgetDataset.type
      +widgetDataset.personSeriesId
      +aggregationGroupingType
      +widget.type
      +widget.periodCount
      +widget.aggregationGroupingType
      +widget.periodType
      +(rangeFrom ? rangeFrom.toISOString():'-')
      +(rangeTo ? rangeTo.toISOString():'-');
  }

  private getWidgetPersonSeriesValue(widget: Widget, widgetDataset: WidgetDataset, rangeFrom?: Moment, rangeTo?: Moment, aggregationGroupingType?: string): WidgetDatasetValue {

    if (!rangeFrom && !rangeTo && (widget.type === WidgetType.TIME_FRAME_CHART || widget.type === WidgetType.VALUE_TABLE)) {
      rangeFrom = moment();
      rangeTo = rangeFrom.clone();
      aggregationGroupingType = widget.aggregationGroupingType;
      rangeFrom = WidgetPeriodHelper.calculateStartAt(rangeFrom, widget.periodType, widget.periodCount);
    }

    if (widget.type === WidgetType.TIME_FRAME_CHART || widget.type === WidgetType.VALUE_TABLE) {
      const measurement = {
        seriesName: 'item',
        series: {
          values: {},
          series: [
            {
              'unit': widgetDataset.unit,
              'name': 'item',
              'type': 'measure'
            }
          ],
          truncated: false
        }
      };
      let t = rangeFrom;
      while (t.isBefore(rangeTo) && Object.keys(measurement.series.values).length < 300) {
        const value = this.getRandomValue();
        measurement.series.values[t.toISOString()] = [{'min': value, 'max': value}];
        switch (aggregationGroupingType) {
          case 'DAILY': {
            t = t.add(1, 'days');
            break;
          } case 'MONTHLY': {
            t = t.add(1, 'months');
            break;
          } case 'YEARLY': {
            t = t.add(1, 'years');
            break;
          } default: {
            t = t.add(1, 'hours');
            break;
          }
        }
      }
      const wvs = new WidgetDatasetValue();
      wvs.widgetDataset = widgetDataset;
      wvs.personSeriesValue = {
        lastValue: null,
        periodLastValue: null,
        measurement: measurement,
        thresholds: []
      };
      return wvs;
    } else {
      const lastValue = {
        event: null,
        measurement: {
          time: moment().toISOString(),
          value: this.getRandomValue(),
          unit: widgetDataset.unit
        }
      };
      const wvs = new WidgetDatasetValue();
      wvs.widgetDataset = widgetDataset;
      wvs.personSeriesValue = {
        lastValue: lastValue,
        periodLastValue: lastValue,
        measurement: null,
        thresholds: []
      };
      return wvs;
    }
  }

  private getWidgetPersonParameterValue(widget: Widget, widgetDataset: WidgetDataset, rangeFrom?: Moment, rangeTo?: Moment, aggregationGroupingType?: string): WidgetDatasetValue {
    const wvs = new WidgetDatasetValue();
    wvs.widgetDataset = widgetDataset;
    wvs.personParameterValue = {
      unit: widgetDataset?.unit,
      value: this.getRandomValue()
    } as PersonParameter;
    return wvs;
  }

  private getWidgetQ11eFieldValue(widget: Widget, widgetDataset: WidgetDataset, rangeFrom?: Moment, rangeTo?: Moment, aggregationGroupingType?: string): WidgetDatasetValue {
    if (widget.type === WidgetType.TIME_FRAME_CHART) {
      const wvs = new WidgetDatasetValue();
      wvs.widgetDataset = widgetDataset;
      return wvs;
    } else {
      const wvs = new WidgetDatasetValue();
      wvs.widgetDataset = widgetDataset;
      wvs.q11eFieldValue = {
        responses: [
          {
            type: 'TEXT',
            text: 'Response'
          }
        ]
      } as QuestionnaireResultsField;
      return wvs;
    }
  }

  private getWidgetQ11eResponseFieldValue(widget: Widget, widgetDataset: WidgetDataset, rangeFrom?: Moment, rangeTo?: Moment, aggregationGroupingType?: string): WidgetDatasetValue {
    const wvs = new WidgetDatasetValue();
    wvs.widgetDataset = widgetDataset;
    wvs.q11eResponseFieldValue = {
      type: 'VALUE',
      value: this.getRandomValue(),
      unit: widgetDataset.unit
    } as QuestionnaireResultsResponseField;
    return wvs;
  }

  private getRandomValue() {
    return Math.round(Math.random() * 1000.0) / 100;
  }
}
