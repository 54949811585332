import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';

@Component({
  selector: 'mydata-advice-page',
  templateUrl: './advice-page.component.html'
})
export class AdvicePageComponent implements OnInit, OnDestroy {
  private ngDestroy = new Subject<void>();

  constructor() {

  }

  ngOnInit() {

  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }
}
