<div class="page-title-bar p-4" *ngIf="questionnaire">
  <div class="d-flex">
    <div class="col-3 pl-0">
      <a routerLink=".." class="text-nowrap"><i class="fal fa-angle-left"></i>&nbsp;<ng-container i18n>Back</ng-container></a>
    </div>
    <div class="col-6 d-flex flex-column justify-content-center align-items-center">
      <h2 class="mb-1" *ngIf="questionnaire.id">{{questionnaire.name}}</h2>
      <h2 class="mb-1" *ngIf="!questionnaire.id && type !== 'FORMULA_SOURCE_TEMPLATE'" i18n>New questionnaire</h2>
      <h2 class="mb-1" *ngIf="!questionnaire.id && type === 'FORMULA_SOURCE_TEMPLATE'" i18n>New formula template</h2>
    </div>
    <div class="col-3 pr-0 d-flex justify-content-end">
      <ng-container *ngIf="questionnaire?.id">
        <label class="mr-2" i18n>Status</label>
        <span class="small-text">{{questionnaire?.status}}</span>
      </ng-container>
    </div>
  </div>
  <ul class="navbar-nav navbar-wizard justify-content-start justify-content-md-center overflow-scroll-mobile">
    <li class="nav-item">
      <a class="nav-link" [class.active]="view === 'info'" (click)="setView('info')" i18n>Info</a>
    </li>
    <li class="nav-item">
      <span *ngIf="questionnaire.id">
        <a class="nav-link" [class.active]="view === 'fields'" (click)="setView('fields')" *ngIf="type !== 'FORMULA_SOURCE_TEMPLATE'" i18n>Questions</a>
        <a class="nav-link" [class.active]="view === 'fields'" (click)="setView('fields')" *ngIf="type === 'FORMULA_SOURCE_TEMPLATE'" i18n>Formulas</a>
      </span>
      <span *ngIf="!questionnaire.id">
        <a class="nav-link disabled" *ngIf="type !== 'FORMULA_SOURCE_TEMPLATE'" i18n>Questions</a>
        <a class="nav-link disabled" *ngIf="type === 'FORMULA_SOURCE_TEMPLATE'" i18n>Formulas</a>
      </span>
    </li>
    <li class="nav-item" *ngIf="type !== 'FORMULA_SOURCE_TEMPLATE'">
      <a class="nav-link" [ngClass]="{'active': view === 'participants', 'disabled': !questionnaire.id}" (click)="setView('participants')" i18n>Participants</a>
    </li>
  </ul>
</div>

<div class="container-fluid px-0">
  <div class="form-content p-4">
    <app-questionnaire-info [hidden]="view !== 'info'" [type]="type"></app-questionnaire-info>
    <app-questionnaire-fields #fields [hidden]="view !== 'fields'"
                              [type]="type" [questionnaire]="questionnaire" [partialEdit]="partialEdit"></app-questionnaire-fields>
    <app-questionnaire-participants #participants [hidden]="view !== 'participants'"
                                    *ngIf="type !== 'FORMULA_SOURCE_TEMPLATE'"></app-questionnaire-participants>
  </div>
  <div class="form-footer" *ngIf="type !== 'FORMULA_SOURCE_TEMPLATE'">
    <button class="btn btn-primary mb-1 m-md-auto ml-1" (click)="saveAndContinue()" i18n *ngIf="view == 'info' || view == 'fields' && manageService.fields.length > 0">Continue</button>
<!--    <button class="btn btn-primary mb-1 m-md-auto" (click)="saveParticipants()" i18n *ngIf="view === 'participants'">Save</button>-->
    <button class="btn btn-success mb-1 mb-md-0 ml-1" (click)="confirmParticipants()" i18n *ngIf="view === 'participants'"
        [disabled]="!participantConfirmEnabled">Send out the questionnaire</button>
  </div>

  <div class="form-footer" *ngIf="type === 'FORMULA_SOURCE_TEMPLATE'">
    <button class="btn btn-primary mb-1 mb-md-0" (click)="saveAndContinue()" i18n *ngIf="view !== 'fields'">Continue</button>
    <button class="btn btn-primary mb-1 mb-md-0" (click)="save()" i18n *ngIf="view === 'fields'">Save</button>
    <button class="btn btn-success ml-1 mb-1 mb-md-0" (click)="saveAndRespond()" i18n *ngIf="view === 'fields'">Save and create datapoints</button>
  </div>
</div>
