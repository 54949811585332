<div class="table-responsive" *ngIf="data">
    <h5 class="text-uppercase">{{layerTitle}}</h5>
    <table class="table w-100">
        <tbody>
            <ng-container *ngFor="let item of data | keyvalue">
              <tr *ngIf="item.value.value && item.value.value !== ' '">
                <td class="text-uppercase" style="max-width: 180px;">
                  {{item.value.label}}
                </td>
                <td class="text-break">
                  <span *ngIf="!isUrl(item.value.value)">{{item.value.value}}</span>
                  <span *ngIf="isUrl(item.value.value)"><a href="{{item.value.value}}" target="_BLANK">{{item.value.value}}</a></span>
                </td>
              </tr>
            </ng-container>
        </tbody>
      </table>
  </div>
