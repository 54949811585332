<ng-template #navActions>
  <div class="btn-group">
    <button type="button" class="btn btn-primary btn-sm" (click)="create()">
      <i class="fal fa-plus"></i>&nbsp;<ng-container i18n>Add new</ng-container>
    </button>
  </div>
</ng-template>

<div class="container-fluid p-4">
  <ngx-datatable
    class="material"
    [draggable]="false"
    [columnMode]="'flex'"
    [headerHeight]="30"
    [footerHeight]="50"
    [reorderable]="false"
    rowHeight="auto"
    [scrollbarH]="false"
    [scrollbarV]="false"
    [rows]="rows"
    [externalPaging]="false"
    [externalSorting]="false"
  >
    <ngx-datatable-column name="Group name" i18n-name prop="name" [flexGrow]="1" [resizeable]="false" [sortable]="true">
      <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>{{value}}</ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Description" i18n-name prop="description" [flexGrow]="1" [resizeable]="false" [sortable]="false">
      <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>{{value}}</ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Advices" i18n-name prop="adviceList.length" [flexGrow]="1" [resizeable]="false" [sortable]="false">
      <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>{{value}}</ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Published" i18n-name prop="published" [flexGrow]="1" [resizeable]="false" [sortable]="false">
      <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>{{value | date:'dd. MMMM yyyy'}}</ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Last updated" i18n-name prop="updatedAt" [flexGrow]="1" [resizeable]="false" [sortable]="true">
      <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>{{value | date:'dd. MMMM yyyy'}}</ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="" [flexGrow]="1" [resizeable]="false" [sortable]="false" [cellClass]="'justify-content-end'">
      <ng-template let-row="row" ngx-datatable-cell-template>
        <div class="btn-group btn-more" dropdown>
          <button type="button" class="btn btn-icon dropdown-toggle" dropdownToggle><i class="far fa-ellipsis-v"></i></button>
          <div class="dropdown-menu dropdown-menu-right" *dropdownMenu>
            <a class="dropdown-item" (click)="edit(row)"><i class="fal fa-copy"></i> <ng-container i18n>Edit</ng-container></a>
            <a class="dropdown-item" (click)="delete(row)"><i class="fal fa-trash"></i> <ng-container i18n>Delete</ng-container></a>
          </div>
        </div>
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
</div>
