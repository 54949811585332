
import {MapsGridArea} from '../model/crowd-insights/maps-grid-area';
import Data = google.maps.Data;
import Feature = google.maps.Data.Feature;
import {CrowdInsightsGridAreaGmapMarker} from './crowd-insights-grid-area-gmap-marker';
import {Subject} from 'rxjs/internal/Subject';
import {CrowdInsightsAreaPopover} from './crowd-insights-area-popover';
import LatLng = google.maps.LatLng;

export class CrowdInsightsGridAreasGmapLayer {

  public areas: MapsGridArea[];

  private selectedGridArea: MapsGridArea;

  private dataLayer: Data;

  private markers: CrowdInsightsGridAreaGmapMarker[] = [];

  private gridAreaClick$: Subject<MapsGridArea>;

  private div: any;

  private popoverTimeout: any;

  private map: google.maps.Map;

  constructor(areas: MapsGridArea[], gridAreaClick$: Subject<MapsGridArea>) {
    this.init(areas);
    this.gridAreaClick$ = gridAreaClick$;
  }

  setMap(map: google.maps.Map) {
    this.map = map;
    this.dataLayer.setMap(map);
  }

  private init(items: MapsGridArea[]): void {
    this.areas = items;
    this.dataLayer = new Data();

    let features: Feature[] = [];

    let markers: CrowdInsightsGridAreaGmapMarker[] = [];

    for (let item of items) {

      const result: Feature[] = this.dataLayer.addGeoJson({
        type: "Feature",
        geometry: item.geojson,
        properties: {
          area: item,
        }
      });

      let bounds = new google.maps.LatLngBounds();

      result.forEach((feature: Feature) => {
        let geo = feature.getGeometry();
        let innerBounds = new google.maps.LatLngBounds();
        geo.forEachLatLng((latLng: LatLng) => {
          innerBounds.extend(latLng);
        });
        bounds.union(innerBounds);
      });

      features.push(...result);

      markers.push(new CrowdInsightsGridAreaGmapMarker(this, item, result, bounds.getCenter()));

    }

    this.dataLayer.setStyle({
      fillOpacity: 0.1,
     // strokeColor: '#0065B1',
      strokeColor: '#333333',
      strokeOpacity: 0.7,
      strokeWeight: 2
    });

    this.markers = markers;

    let areaPopover = new CrowdInsightsAreaPopover();

    this.dataLayer.addListener('click', (event) => {
      this.gridAreaClick$.next(event.feature.getProperty("area"));
    });

    this.dataLayer.addListener('mouseover', (event) => {
      if (!areaPopover.isOpen()) {
        let marker = this.getAreaMarker(event.feature.getProperty('area'));
        if (!marker) {
          return;
        }

        areaPopover.set('position', marker.position);
        areaPopover.set('content', marker.area.name);
        areaPopover.open(this.map, null);
      }
    });

    this.dataLayer.addListener('mouseout', (event) => {
      if (areaPopover.isOpen()) {
        areaPopover.close();
      }
    });
  }

  private getAreaMarker(area: MapsGridArea): CrowdInsightsGridAreaGmapMarker {

    return this.markers.find(m => m.area === area);

  }


}
