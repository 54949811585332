import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ManagePersonSeriesComponent} from './manage-person-series.component';
import {PersonSeriesListComponent} from './person-series-list.component';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CoreModule} from '@smartencity/core';
import {NgxPermissionsModule} from 'ngx-permissions';
import {ModalModule} from 'ngx-bootstrap/modal';
import {PopoverModule} from 'ngx-bootstrap/popover';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {PaginationModule} from 'ngx-bootstrap/pagination';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {NgSelectModule} from '@ng-select/ng-select';

@NgModule({
  declarations: [
    ManagePersonSeriesComponent,
    PersonSeriesListComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild([]),
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    NgxPermissionsModule.forChild(),
    ModalModule,
    //TypeaheadModule,will make bugs or multiple implementation used
    PopoverModule,
    TooltipModule,
    PaginationModule,
    NgxDatatableModule,
    BsDropdownModule,
    NgSelectModule
  ]
})
export class ManagePersonSeriesModule { }
