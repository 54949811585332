import {Component, ElementRef, Inject, Input, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {
  CoreConfig, CsvDataService, PersonSeriesApiService,
  SMARTENCITY_CORE_CONFIG, SourceOwnerSeries
} from '@smartencity/core';
import {FormArray, FormControl, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {BsModalRef} from 'ngx-bootstrap/modal';
import moment from 'moment';
import Papa from 'papaparse';
import {ToastrService} from 'ngx-toastr';
import {SourceApiService} from '../../../../http/source-api.service';

@Component({
  selector: 'mydata-series-add-data',
  templateUrl: './add-data-modal.component.html',
})
export class AddDataModalComponent implements OnInit {
  @Input()
  public callback: (result: boolean) => void;

  @ViewChild('uploadInput', {static: true})
  uploadInput: ElementRef;

  public sourceOwnerSeries: SourceOwnerSeries;

  public valuesArray = new FormArray([new FormGroup({
    time: new FormControl(null, Validators.required),
    value: new FormControl(null, [Validators.required, Validators.pattern(/^\-?\d+([\.\,]\d+)?$/)])
  })], Validators.required);
  public form = new FormGroup({
    values: this.valuesArray
  });

  confirmed = false;
  saving = false;

  constructor(
    private vcRef: ViewContainerRef,
    @Inject(SMARTENCITY_CORE_CONFIG) private config: CoreConfig,
    public modalRef: BsModalRef,
    public toastr: ToastrService,
    private personSeriesService: PersonSeriesApiService,
    public toastService: ToastrService,
    private sourceApi: SourceApiService
  ) {
  }

  ngOnInit() {
  }

  close() {
    if (this.callback) {
      this.callback(false);
    }
    this.modalRef.hide();
  }

  downloadExampleCsv() {
      CsvDataService.exportToCsv('data import example', [
        ['2019-01-01', '2445'],
        ['2019-02-01', '2342'],
        ['2019-03-01', '1943'],
        ['2019-04-01', '1843'],
        ['2019-05-01', '1143'],
        ['2019-06-01', '1238'],
        ['2019-07-01', '1094'],
        ['2019-08-01', '1014'],
        ['2019-09-01', '1123'],
        ['2019-10-01', '1701'],
        ['2019-11-01', '1923'],
        ['2019-12-01', '2291']
      ], {includeHeader: false});
  }

  uploadCsv(event) {
    const inputEl = this.uploadInput.nativeElement;
    if (inputEl.files.length === 0) {
      return;
    }

    const files: FileList = inputEl.files;

    for (let i = 0; i < files.length; i++) {
      if (files[i].name.endsWith('.csv')) {
        this.processCsv(files[i]);
      }
    }

    inputEl.value = null;
  }

  processCsv(entry: any) {
    const reader = new FileReader();
    reader.readAsText(entry);
    reader.onload = () => {
      const data = reader.result;

      const csv = Papa.parse(data, {dynamicTyping: true});
      let validRows = 0;
      if (csv && csv.data.length) {
        this.valuesArray.clear();
        for (const row of csv.data) {
          if (row.length !== 2) {
            continue;
          }
          const date = moment(row[0], 'YYYY-MM-DD');
          const value = row[1];
          if (date.isValid() && typeof(value) === 'number') {
            this.valuesArray.push(new FormGroup({
              time: new FormControl(date, Validators.required),
              value: new FormControl(value, Validators.required)
            }));
            validRows++;
          }
        }
      }

      if (validRows) {
        this.toastService.success($localize`Imported ${validRows} rows`);
      } else {
        this.toastService.error($localize`No valid rows found`);
      }
    };
    return;
  }

  addRow() {
    this.valuesArray.push(new FormGroup({
      time: new FormControl(null, Validators.required),
      value: new FormControl('', [Validators.required, Validators.pattern(/^\-?\d+([\.\,]\d+)?$/)])
    }));
  }

  removeRow(i: number) {
    this.valuesArray.removeAt(i);
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  confirm() {
    if (this.saving) {
      return;
    }

    this.confirmed = true;
    this.saving = true;

    this.markFormGroupTouched(this.form);
    this.form.updateValueAndValidity();
    if (!this.form.valid) {
      console.error('form invalid', this.form);
      this.saving = false;
      return;
    }

    this.sourceApi.addData(this.sourceOwnerSeries.source.id,  {
      values: this.form.value.values.map((e) => ({
        time: e.time,
        value: e.value && typeof(e.value) === 'string' ? Number(e.value.trim().replace(',', '.')) : e.value
      }))
    }).subscribe(value => {
      this.toastr.success('Data added');
      this.modalRef.hide();
      if (this.callback) {
        this.callback(true);
      }
    }, error1 => {
      this.saving = false;
      this.toastr.error('Data insert failed');
    });

  }

  control(name?: string) {
    if (!name) {
      return this.form;
    }
    return this.form.get(name);
  }

  invalid(name: string, formGroup?) {
    if (!formGroup) {
      formGroup = this.form;
    }
    const control = formGroup.get(name);
    return control && control.invalid && (control.dirty || control.touched);
  }

  errors(name: string, formGroup?): ValidationErrors {
    if (!formGroup) {
      formGroup = this.form;
    }
    const control = formGroup.get(name);
    return control ? control.errors : null;
  }
}
