import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SMARTENCITY_PEOPLE_CONFIG} from './injection-tokens';
import {IPeopleConfig, MapsConfig} from './people-config.model';
import {TowerPage, TowersHistogram, TowersState} from './people.model';
import {Observable} from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class PeopleService {

  constructor(
    private http: HttpClient,
    @Inject(SMARTENCITY_PEOPLE_CONFIG) private config: IPeopleConfig
  ) { }

  getMapsConfig(): MapsConfig {
    return this.config.map;
  }

  getTowers(params: any): Observable<TowerPage> {
    params.cityPortalType = 'PEOPLE';
    return new Observable(observer => {
      this.http.get(this.config.cityApiUrl + '/person-series', {
        params: params
      }).subscribe(
        (data: TowerPage) => {
          return observer.next(data);
        }, err => {
          return observer.error(err);
        });
    });
  }

  getTowersState(params: any): Observable<TowersState> {
    params.cityPortalType = 'PEOPLE';
    return new Observable(observer => {
      this.http.get(this.config.cityApiUrl + '/person-series/state', {
        params: params
      }).subscribe(
        (data: TowersState) => {
          return observer.next(data);
        }, err => {
          return observer.error(err);
        });
    });
  }

  getTowersHistogram(): Observable<TowersHistogram> {
    return new Observable(observer => {
      this.http.get(this.config.cityApiUrl + '/person-series/people/histogram', {
        params: {}
      }).subscribe(
        (data: TowersHistogram) => {
          return observer.next(data);
        }, err => {
          return observer.error(err);
        });
    });
  }
}
