import {Inject, Injectable} from '@angular/core';
import {ServicesConfig} from './config.model';
import {SMARTENCITY_SERVICES_CONFIG} from './injection-tokens';
import {HttpClient} from '@angular/common/http';
import {Service, Services} from './models/services.model';
import {ReplaySubject} from 'rxjs/internal/ReplaySubject';
import {MapsConfig} from '@smartencity/core';

@Injectable({
  providedIn: 'root'
})
export class ServicesService {
  public services$ = new ReplaySubject<Services>(1);

  public selectedService$ = new ReplaySubject<Service>(1);

  constructor(
    @Inject(SMARTENCITY_SERVICES_CONFIG) private config: ServicesConfig,
    private http: HttpClient
  ) { }

  getMapsConfig(): MapsConfig {
    return this.config.map;
  }

  loadServices() {
    this.http.get(this.config.cityApiUrl + '/city-services').subscribe(
    (data: any) => {
      const services: Services = {
        toilets: data.toilets,
        parcelTerminals: data.parcelTerminals,
        evChargers: data.evChargers.map((e: any) => ({
          id: e.id,
          uuid: e.uuid,
          lat: e.lat,
          lng: e.lng,
          address: e.address,
          name: e.name,
          status: e.status,
          nConnectors: e.connectors ? e.connectors.length : 0,
          nAvailable: e.connectors ? e.connectors.reduce((a: number, v: any) => a + (v.status === 'AVAILABLE' ? 1 : 0), 0) : 0
        })),
        windTurbines: data.windTurbines
      };
      this.services$.next(services);
    });
  }
}
