import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import { Observable } from 'rxjs';
import {QuestionnaireService} from '../../services/questionnaire.service';

@Injectable()
export class CanCreateQuestionnaireGuard implements CanActivate {

  constructor(private questionnaireService: QuestionnaireService, private router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    return new Observable<boolean>((subscriber) => {
      this.questionnaireService.canCreateQuestionnaire().subscribe((result) => {
        if (!result) {
          this.router.navigate(['mydata/questionnaires/my-questionnaires']);
          return;
        }
        subscriber.next(true);
      })
    });
  }

}
