import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subject} from 'rxjs/internal/Subject';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute} from '@angular/router';
import {SMARTENCITY_MYDATA_CONFIG} from '../../injection-tokens';
import {MyDataConfig} from '../../mydata-config.model';
import {BsModalService} from 'ngx-bootstrap/modal';
import {GroupsEditModalComponent} from './edit-modal/groups-edit-modal.component';
import {
  PageResponse,
  SeriesType,
  SCREEN_SIZE,
  ResizeService,
  GroupSourceListItem,
  GroupSourceDataService,
  GroupSourcePersonSeries, GroupSource, SamplingType
} from '@smartencity/core';
import {FormGroup} from '@angular/forms';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  shareReplay,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import {merge, throwError, combineLatest} from 'rxjs';
import {DatatableComponent} from '@swimlane/ngx-datatable';
import {ConfirmModalComponent} from '../../../../../core/src/lib/components/confirm-modal/confirm-modal.component';
import {ToastrService} from 'ngx-toastr';
import {CreateSourcesService} from '../../services/create-sources.service';
import {DatapointGroupUpdateErrorsModalComponent} from './datapoint-group-update-errors-modal/datapoint-group-update-errors-modal.component';
import {SourceUpdateErrorService} from '../../services/source-update-error.service';
import {GroupSourceUpdateErrorService} from '../../services/group-source-update-error.service';
import {ComponentLoaderFactory} from 'ngx-bootstrap/component-loader';
import moment from 'moment';

const selector = 'mydata-groups';
let nextId = 0;

@Component({
  selector: selector,
  templateUrl: './groups.component.html',
  providers: [
    BsModalService,
    ComponentLoaderFactory,
    {
      provide: SourceUpdateErrorService,
      useClass: GroupSourceUpdateErrorService
    }
  ]
})
export class GroupsComponent implements OnInit, OnDestroy {
  id = `${selector}-${nextId++}`;
  private ngDestroy = new Subject<void>();

  @ViewChild('table') table: DatatableComponent;

  public columns = {
    name: true,
    location: true,
    type: true,
    unit: true,
    datapoints: true,
    createdAt: true,
    actions: true,
    detailToggler: false,
    errors: true
  };

  public page: PageResponse<GroupSourceListItem>;
  public treePageLimit = 0; // Hack to make ngx-datatable external paging work with tree view
  public treePageCount = 0; // Hack to make ngx-datatable external paging work with tree view
  public defaultLimit = 40;
  public defaultSorts = [{prop: 'createdAt', dir: 'desc'}];

  public filterForm: FormGroup = this.createSourcesService.filterForm;
  public filter$ = this.filterForm.valueChanges.pipe(distinctUntilChanged(), debounceTime(250), startWith({}));
  public page$: Subject<any> = new Subject<any>();
  public sorts$: Subject<any> = new Subject<any>();
  public limit$: Subject<any> = new Subject<any>();

  public filterSortsLimit$ = combineLatest([
    this.filter$,
    this.sorts$.pipe(startWith(this.defaultSorts)),
    this.limit$.pipe(startWith(this.defaultLimit))
  ]);

  public queryParams$ = merge(
    this.filterSortsLimit$.pipe(map(([filter, sorts, limit]: [any, any[], number]) => [filter, sorts, limit, {offset: 0, limit: limit}])),
    this.page$.pipe(
      withLatestFrom(this.filterSortsLimit$),
      map(([page, [filter, sorts, limit]]: [any, [any, any[], number]]) => [filter, sorts, limit, page])
    )
  ).pipe(
    map(([filter, sorts, limit, page]) => this.mapQueryParams(filter, sorts, limit, page)),
    takeUntil(this.ngDestroy),
    shareReplay(1)
  );
  public reload$: Subject<void> = new Subject();
  public rows$ = combineLatest([this.queryParams$, this.reload$.pipe(startWith(null))]).pipe(
    switchMap(([queryParams, ]: [any, any]) => this.fetchPage(queryParams)),
    map((data: PageResponse<GroupSourceListItem>) => this.mapPageResultsToRows(data)),
    takeUntil(this.ngDestroy)
  );

  rows: any[];

  public seriesTypes = SeriesType.types.map((type: string) => ({value: type, label: type}));

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    @Inject(SMARTENCITY_MYDATA_CONFIG) private config: MyDataConfig,
    private modalService: BsModalService,
    private resizeService: ResizeService,
    private toastr: ToastrService,
    private createSourcesService: CreateSourcesService,
    private groupSourceDataService: GroupSourceDataService
  ) {
    this.rows$.pipe(takeUntil(this.ngDestroy)).subscribe((rows) => {
      this.rows = rows;
    });

    this.resizeService.onResize$.pipe(takeUntil(this.ngDestroy)).subscribe(this.adjustColumns.bind(this));
  }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
    this.reload$.complete();
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  getRowClass = (row: any) => {
    return 'sub-row';
  }

  mapQueryParams(filter: any, sorts: any[], limit: number, page: any) {
    const queryParams: any = {};
    if (filter) {
      if (filter.seriesTypes) {
        queryParams.seriesTypes = filter.seriesTypes.join(',');
      }
    }
    if (filter.query) {
      queryParams.query = filter.query;
    }
    if (page) {
      queryParams.page = page.offset;
      queryParams.size = limit;
    }
    if (limit) {
      queryParams.size = limit;
    }
    if (sorts && sorts.length > 0) {
      queryParams.sortFields = sorts.reduce((accumulator, currentValue) => {
        if (currentValue.dir === 'desc') {
          return accumulator + encodeURIComponent('-' + currentValue.prop);
        } else {
          return accumulator + '+' + currentValue.prop;
        }
      }, '');
    }

    return queryParams;
  }

  fetchPage(queryParams: any) {
    const params = Object.assign({}, queryParams);
    for (const i of Object.keys(params)) {
      if (params[i] == null) {
        delete params[i];
      }
    }

    return this.http.get(this.config.apiUrl + '/person-series-group-source/', {
      params: params
    }).pipe(catchError((err) => {
      return throwError(err);
    }));
  }

  mapPageResultsToRows(data: PageResponse<GroupSourceListItem>) {
    this.page = data;
    const rows = [];
    for (const group of data.content) {
      rows.push({
        id: group.id,
        group: group,
        rowId: 'group-' + group.id,
        parentRowId: null,
        treeStatus: 'collapsed',
        name: group.metaProperties?.name,
        location: group.metaProperties?.address,
        type: group.metaProperties?.seriesType,
        unit: group.unit,
        datapoints: group.personSeriesCount,
        createdAt: group.createdAt,
        updateErrorCount: group.updateErrorCount
      });
    }

    this.treePageLimit = rows.length;
    this.treePageCount = this.page.totalElements - this.page.content.length + rows.length;
    return rows;
  }

  createGroup(): void {
    const modalRef = this.modalService.show(GroupsEditModalComponent, {
      ignoreBackdropClick: true,
      class: 'modal-xxl'
    });
    modalRef.content.savedEmitter.pipe(takeUntil(this.ngDestroy), take(1)).subscribe((saved) => {
      this.reload();
    });
  }

  reload(): void {
    this.reload$.next();
  }

  onTreeAction(event: any): void {
    const index = event.rowIndex;
    const row = event.row;

    if (row.treeStatus === 'collapsed') {
      if (!row.childrenLoaded) {
        const rows = [];
        row.treeStatus = 'loading';
        this.groupSourceDataService.getGroupSourcePersonSeries(row.group.id, {
          withLastValue: true
        }).subscribe((personSeriesList: GroupSourcePersonSeries[]) => {
          for (const ps of personSeriesList) {
            rows.push({
              id: null,
              group: null,
              rowId: 'ps-' + ps.id,
              parentRowId: 'group-' + row.group.id,
              treeStatus: 'collapsed',
              name: ps.personSeries.name,
              location: ps.personSeries.address,
              type: ps.personSeries.seriesType,
              unit: ps.unit,
              datapoints: null,
              createdAt: null,
              latestValue: ps.personSeries.latestValue,
              parentGroup: row.group,
              latestValueOverdue: ps.overdue
            });
          }

          this.rows = [...this.rows, ...rows];
          this.treePageLimit = this.rows.length;
          this.treePageCount = this.page.totalElements + this.rows.length;
          row.childrenLoaded = true;
          row.treeStatus = 'expanded';
        });
      } else {
        row.treeStatus = 'expanded';
        this.rows = [...this.rows];
      }

    } else {
      row.treeStatus = 'collapsed';
      this.rows = [...this.rows];
    }
  }

  edit(groupRef): void {

    this.groupSourceDataService.getGroupSource(groupRef.id).subscribe((group) => {
      const modalRef = this.modalService.show(GroupsEditModalComponent, {
        ignoreBackdropClick: true,
        class: 'modal-xxl',
        initialState: {
          groupSource: group
        }
      });
      modalRef.content.savedEmitter.pipe(takeUntil(this.ngDestroy), take(1)).subscribe((saved) => {
        this.reload();
      });
    })

  }

  delete(group): void {
    const modalRef = this.modalService.show(ConfirmModalComponent, {
      ignoreBackdropClick: true,
      initialState: {
        description: $localize`Are you sure you want to delete the datapoint group?`,
        callback: (confirm: boolean) => {
          if (confirm) {
            this.groupSourceDataService.deleteGroupSource(group)
              .pipe(takeUntil(this.ngDestroy))
              .subscribe(() => {
                this.toastr.success($localize`Datapoint group deleted`);
                this.reload();
              }, (error) => {
                this.toastr.error($localize`Datapoint group deletion failed`);
                console.error(error);
              });
          }
        }
      }
    });
  }

  setPage(event: any) {
    const page = {
      offset: event.page - 1,
      limit: event.pageSize
    };
    this.page$.next(page);
  }

  setSorts(event: any) {
    this.sorts$.next(event.sorts);
  }

  toggleExpandRow(row) {
    this.table.rowDetail.toggleExpandRow(row);
  }

  showUpdateErrors(groupSource: GroupSource): void {
    const modalRef = this.modalService.show(DatapointGroupUpdateErrorsModalComponent, {
      initialState: {
        groupSource: groupSource
      }
    });

    modalRef.content.savedEmitter.subscribe(() => {
      this.reload();
    });
  }

  private adjustColumns(size): void {
    if (size != null && size < SCREEN_SIZE.MD) {
      this.columns.name = true;
      this.columns.location = false;
      this.columns.type = false;
      this.columns.unit = false;
      this.columns.datapoints = false;
      this.columns.createdAt = false;
      this.columns.actions = false;
      this.columns.detailToggler = true;
    } else {
      this.columns.name = true;
      this.columns.location = true;
      this.columns.type = true;
      this.columns.unit = true;
      this.columns.datapoints = true;
      this.columns.createdAt = true;
      this.columns.actions = true;
      this.columns.detailToggler = false;
    }
    if (this.table) {
      for (const row of this.rows) {
        row.treeStatus = 'collapsed';
      }
      this.rows = [...this.rows];
      this.table.rowDetail.collapseAllRows();
    }
  }

}
