<ng-template let-location="location" #ownerInfo>
  <ng-template #sharedByInfo>
    <span i18n>Shared by {{location.createdByPerson?.displayName}}</span>
  </ng-template>
  <div class="group-icon shared" *ngIf="location?.groupOwnerType === 'CONSENT'" [tooltip]="sharedByInfo">
    <i class="icon-s icon-shared"></i>
  </div>

  <div class="group-icon owner" *ngIf="location?.groupOwnerType === 'OWNER'" i18n-tooltip tooltip="I am the owner">
    <i class="icon-s icon-owner"></i>
  </div>
</ng-template>

<div class="row justify-content-center justify-content-lg-start">
  <i *ngIf="(filteredLocations$ | async) && (filteredLocations$ | async)?.length === 0" i18n>No data</i>

  <div class="card-wrapper d-flex align-items-stretch" *ngFor="let location of filteredLocations$ | async">
    <div class="card" (click)="showMore(location);">
      <ng-container [ngTemplateOutlet]="ownerInfo"
                    [ngTemplateOutletContext]="{location: location}"></ng-container>

      <div class="view" [ngClass]="{'shared': location.groupOwnerType === 'CONSENT', 'groupless': !location.group.name}">
        <i class="icon colorful"
           [ngClass]="{
              'icon-building':  location.type == 'BUILDINGS',
              'icon-mobility': location.type == 'MOBILITY',
              'icon-lights': location.type == 'LIGHTS',
              'icon-weather': location.type == 'WEATHER',
              'icon-people': location.type == 'PEOPLE',
              'icon-data': location.type == 'DATA',
              'icon-electric-meter': location.type == 'ELECTRICITY_METER',
              'icon-electric-vehicle-charger': location.type == 'ELECTRIC_VEHICLE_CHARGER',
              'icon-electric-bicycle-station': location.type == 'ELECTRIC_BICYCLE_STATION',
              'icon-statistics': location.type == 'STATISTICS',
              'icon-other': location.type == 'OTHER',
              'icon-no-group': location.type == null
            }"
        ></i>
      </div>

      <div class="card-body">
        <h2 tooltip="{{location.getName()}}">{{location.getName()}}</h2>
        <span class="text-light">
           <span *ngIf="location?.ownerPersonSeriesCount > 0 || location?.sharedPersonSeriesCount == 0">
             <span i18n>{location?.ownerPersonSeriesCount, plural, =1 {{{location?.ownerPersonSeriesCount}} datapoint} other {{{location?.ownerPersonSeriesCount}} datapoints}}</span>
           </span>
           <span *ngIf="location?.ownerPersonSeriesCount > 0 && location?.sharedPersonSeriesCount > 0">, </span>
           <span *ngIf="location?.sharedPersonSeriesCount > 0">
             <span i18n>{location?.sharedPersonSeriesCount, plural, =1 {{{location?.sharedPersonSeriesCount}} shared datapoint} other {{{location?.sharedPersonSeriesCount}} shared datapoints}}</span>
           </span>
        </span>

        <div class="buttons-wrapper">
          <div class="buttons">
            <button *ngIf="location?.group?.id && location.groupOwnerType === 'OWNER'" type="button" class="btn btn-sm btn-circle btn-primary"
                    tooltip="Edit" i18n-tooltip
                    (click)="$event.stopPropagation();editLocation(location);">
              <i class="fal fa-edit"></i>
            </button>
            <button *ngIf="location?.group?.id && location.groupOwnerType === 'OWNER'" type="button" class="btn btn-sm btn-circle btn-primary"
                    tooltip="Share" i18n-tooltip
                    (click)="$event.stopPropagation();shareLocation(location);">
              <i class="fal fa-share"></i>
            </button>
            <button *ngIf="location?.group?.id && location.groupOwnerType === 'OWNER'" type="button" class="btn btn-sm btn-circle btn-primary"
                    tooltip="Delete" i18n-tooltip
                    (click)="$event.stopPropagation();deleteLocation(location);">
              <i class="fal fa-trash"></i>
            </button>
            <button *ngIf="location?.group?.id && location.groupOwnerType === 'CONSENT'" type="button" class="btn btn-sm btn-circle btn-primary"
                    tooltip="Decline" i18n-tooltip
                    (click)="$event.stopPropagation();declineLocation(location);">
              <i class="fal fa-times"></i>
            </button>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
