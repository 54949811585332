<div class="page-title-bar p-4">
  <div class="d-flex">
    <div class="col-auto pl-0">
      <a routerLink=".." class="text-nowrap"><i class="fal fa-angle-left"></i>&nbsp;<ng-container i18n>Back</ng-container></a>
    </div>
    <div class="col d-flex flex-column justify-content-center align-items-center">
      <h2 class="mb-1">{{location?.getName()}}&nbsp;<i class="fal fa-angle-right"></i>&nbsp;<ng-container i18n>Manage</ng-container></h2>
      <span class="text-light">
        <ng-container i18n>{datapointCount, plural, =1 {{{datapointCount}} datapoint} other {{{datapointCount}} datapoints}}</ng-container>
      </span>
    </div>
    <div class="col-auto pr-0"></div>
  </div>
</div>
<div class="container-fluid p-4">
  <mydata-location-person-series-list></mydata-location-person-series-list>
</div>
