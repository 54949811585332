import {UnitPipe} from '@smartencity/core';

export class EecBuildingGmapMarker extends google.maps.OverlayView {
  eecbuilding: any;
  energyClass: string;
  latlng: any;
  div: HTMLElement;

  private unitPipe: UnitPipe;

  constructor(building, latlng,  map, unitPipe, energyClass) {
    super();

    this.eecbuilding = building;
    this.latlng = latlng;
    this.unitPipe = unitPipe;
    this.energyClass = energyClass;
    this.setMap(map);
  }

  onAdd() {

    this.div = document.createElement('DIV');
    this.div.title = '';
    this.div.style.cursor = 'pointer';
    this.div.classList.add('pointer-energy-class-'+this.eecbuilding.energyClass.toLowerCase());
    this.div.setAttribute('data-toggle', 'popover');
    this.div.setAttribute('data-content', this.eecbuilding.address);
    this.div.setAttribute('data-trigger', 'hover');
    this.div.setAttribute('data-placement', 'top');

    if(this.energyClass === this.eecbuilding.energyClass) {
      this.div.classList.add('active');
    } else {
      // this.div.classList.remove('active');
    }

    const marker = this.div;

    marker.addEventListener('mouseover', (event) => {
        ($('[data-toggle="popover"]') as any).popover();
    });

    const panes = this.getPanes();
    panes.overlayMouseTarget.appendChild(this.div);
  }

  onRemove() {
    this.div.parentNode.removeChild(this.div);
  }

  draw() {
    const point = this.getProjection().fromLatLngToDivPixel(this.latlng);
    if (point) {
      this.div.style.left = (point.x - 22) + 'px';
      this.div.style.top = (point.y - 45) + 'px';
    }
  }
}
