<div class="d-flex no-gutters w-100 position-relative">
  <div id="tooltip"></div>
  <div class="map-container" style="width: 100%;">
    <div id="map" class="map"></div>
  </div>
  <div class="floating-wrapper flex-column">
    <mobility-layer-filters [displaySettings]="displaySettings"
                            [displayArcGisSettings]="arcGisDisplaySettings"
                            (displaySettingsChange)="updateDisplayItems($event)"
                            (arcGisDisplaySettingsChange)="updateArcGisDisplayItems($event)"></mobility-layer-filters>
    <div class="position-relative">
      <mobility-objects-modal [objects]="objects" (modalClose)="onModalClose()"></mobility-objects-modal>
    </div>
  </div>
</div>
