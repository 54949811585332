<div class="modal-header">
  <h5 class="modal-title w-100">{{sourceOwnerSeries.personSeries.name}}&nbsp;<span i18n>thresholds</span></h5>
  <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
    <i class="fal fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <div class="row mb-3">
    <div class="col text-right">
      <button (click)="addRow()" type="button" class="btn btn-sm btn-primary">
        <i class="fal fa-plus"></i>&nbsp;<span i18n>Add new</span>
      </button>
    </div>
  </div>

  <table class="table table-responsive-md btn-table modal-table">
    <thead>
    <tr>
      <td class="w-200p" i18n>Name</td>
      <td class="w-150p" i18n>Value</td>
      <td i18n>Relation</td>
      <td i18n>Enabled</td>
      <td class="text-nowrap"></td>
    </tr>
    </thead>
    <tbody>
      <mydata-threshold-row *ngFor="let row of rows; let i = index" [threshold]="row" (remove)="removeRow(i)" (save)="saveRow(i)"></mydata-threshold-row>
    </tbody>
  </table>
</div>
