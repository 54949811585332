
    <h5>{{roadClosure?.meta?.pohjuseSelgitus}}</h5>
    <p>{{roadClosure.startsAt|date:'dd.MM.yyyy HH:mm'}} - {{roadClosure.endsAt|date:'dd.MM.yyyy HH:mm'}}</p>
    <p>Menetlus: <a href="https://opinfo.tallinn.ee/menetlus/{{roadClosure.procedureId}}" target="_blank">https://opinfo.tallinn.ee/menetlus/{{roadClosure.procedureId}}</a></p>
    <p>Töö liik: {{roadClosure.meta.tooLiik}}</p>
    <h3>Kirjeldus</h3>
    <p>{{roadClosure.meta.kirjeldus}}</p>
    <ng-container *ngIf="roadClosure.meta.tellija && roadClosure.meta.tellija.length > 0">
      <h3>Tellija</h3>
      <p>
      <span *ngFor="let tellija of roadClosure.meta.tellija">
        {{tellija}}<br />
      </span>
      </p>
    </ng-container>
    <ng-container *ngIf="roadClosure.meta.teostaja && roadClosure.meta.teostaja.length > 0">
      <h3>Teostaja</h3>
      <p><span *ngFor="let teostaja of roadClosure.meta.teostaja">{{teostaja}}</span><br /></p>
    </ng-container>
    <ng-container *ngIf="roadClosure.meta.toimumiskoht && roadClosure.meta.toimumiskoht.length > 0">
      <h3>Toimumiskohad</h3>
      <table>
        <tr>
          <th>Liik</th>
          <th>Ala</th>
          <th>Protsent</th>
        </tr>
        <tr *ngFor="let toimumiskoht of roadClosure.meta.toimumiskohad.toimumiskoht">
          <td>{{toimumiskoht.liik}}</td>
          <td>{{toimumiskoht.ala}}</td>
          <td>{{toimumiskoht.protsent}}</td>
        </tr>
      </table>
    </ng-container>