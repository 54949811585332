import {Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
  selector: 'input[type=text] [core-uppercase]',
})
export class InputUppercaseDirective {
  constructor(private ref: ElementRef) {
  }

  @HostListener('input', ['$event.target.value'])
  onInput(value) {
    this.ref.nativeElement.value = value.toUpperCase();
  }
}
