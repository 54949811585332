import {Component, OnInit} from '@angular/core';
import {DisplaySettings} from '../model/display-settings';
import {AirQualityStation} from '../model/air-quality-station';
import {TallinnOlMapService} from '../service/tallinn-ol-map.service';
import {TallinnService} from '../service/tallinn.service';
import {TallinnArcGisMapService} from 'projects/arc-gis/src/lib/tallinn-arc-gis-map.service';
import {FeatureSelectEvent, OlMapWrapper} from 'projects/core/src/public-api';

@Component({
  selector: 'ol-tallinn',
  templateUrl: './ol-tallinn.component.html',
  styles: [
    `
      .map {
        width: 100%;
        height: 100vh;
      }
    `,
  ],
})
export class OlTallinnComponent implements OnInit {
  displaySettings: DisplaySettings = new DisplaySettings();
  airQualityStation: AirQualityStation;
  map: OlMapWrapper;

  public objects: any[] = [];

  constructor(
    public tallinnService: TallinnService,
    private tallinnMapService: TallinnOlMapService,
    private tallinnArcGisMapService: TallinnArcGisMapService
  ) {
  }

  ngOnInit(): void {
    this.onModalClose();
    this.tallinnMapService.createMap();

    this.tallinnMapService.map.mapLoaded$.subscribe(() => {
      this.tallinnMapService.map.getMap().on('click', () => {
        this.resetLocations();
      });

      this.tallinnMapService.map.featureSelect$.subscribe(
        (o: FeatureSelectEvent) => {
          let newLocations = [];
          for (let f of o.selected) {
            if (f.layer.get('layerName') == 'toilets') {
              continue;
            }

            newLocations.push({
              layerName: f.layer.get('layerName'),
              data: this.tallinnMapService.map.getFeatureProperties(f.feature),
            });
            if (f.layer.get('layerName') == 'cityDistricts' || f.layer.get('layerName') == 'residentialAreas') {
              f.feature.setStyle(this.tallinnArcGisMapService.highlightStyle);
            }
          }

          for (let f of o.deselected) {
            f.feature.setStyle(null);
          }

          const layerPosition = newLocations.findIndex((o) => o.layerName == 'cityDistricts');
          const lastLayer = newLocations.splice(layerPosition, 1);
          newLocations = newLocations.concat(lastLayer);

          this.tallinnService.selectLocations(newLocations);
        }
      );
    });

  }

  displayItemsChanged(items: any) {
    this.tallinnService.displaySettingsSource.next(
      Object.assign(this.displaySettings, items)
    );
  }

  onModalClose(): void {
    this.resetLocations();
  }

  private resetLocations(): void {
    this.clearFeaturesStyles();
    this.tallinnService.locationsSelectedSource.next(null);
    this.tallinnService.airQualityStationSelectedSource.next(null);
  }

  private clearFeaturesStyles(): void {
    if (this.tallinnMapService.map) {
      this.tallinnMapService.map.clearFeatureStyles();
    }
  }

}
