class Enums {
  aggregationGroupingType: string[];
  locationType: string[];
  ownerType: string[];
  confirmationStatus: string[];
  permissionKey: string[];
  predefinedGroupingTag: string[];
  sourceType: string[];
  seriesType: string[];
  targetType: string[];
  tokenType: string[];
  userPersonType: string[];
  groupType: string[];
}

const aggregationGroupingTypeSymbolMap = {
  null: '',
  HOURLY: 'H',
  DAILY: 'D',
  MONTHLY: 'M',
  YEARLY: 'Y',
};
export {Enums, aggregationGroupingTypeSymbolMap};
