<h5 class="text-uppercase">{{airQualityStation?.readableAddress ? airQualityStation?.readableAddress : airQualityStation?.address}}</h5>
<table class="table table-bordered air-quality-station-data">
  <thead>
    <tr>
      <th scope="col"><span i18n>Indicator</span></th>
      <th scope="col"><span i18n>Value</span></th>
      <th scope="col"><span i18n>Updated at</span></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let series of airQualityStation?.series; let index = index">
      <td><h3 class="mb-0"><span class="badge bg-primary p-2 w-100 color-white ">{{series.indicator}}</span></h3></td>
      <td><div class="d-flex"><h3 class="mr-1 mb-0">{{ series.value | number : '1.1-2'}}</h3> <smaller>{{series.unit}}</smaller></div></td>
      <td>{{series.updatedAt | date:'dd.MM.yyyy HH:mm'}}</td>
    </tr>
  </tbody>
</table>

