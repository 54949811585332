import {AbstractRedirectStrategy} from '../../redirect/abstract-redirect-strategy';
import {NemLogInLogoutUriBuilder} from './nem-log-in-logout-uri-builder';

export class NemLogInLogoutRedirectStrategy extends AbstractRedirectStrategy {

  constructor(baseUrl: string) {
    super();
    this.setRedirectUriBuilder(new NemLogInLogoutUriBuilder(baseUrl));
  }

}
