import { Component, OnInit } from '@angular/core';
import {AuthService, PersonApplication, UserService} from '@smartencity/core';

import moment from 'moment';
import {BsModalService} from 'ngx-bootstrap/modal';
import {ApplicationTokenModalComponent} from './application-token-modal.component';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'mydata-services',
  templateUrl: './services.component.html'
})
export class ServicesComponent implements OnInit {
  public applications: PersonApplication[] = [];

  constructor(
    public authService: AuthService,
    public userService: UserService,
    private toastr: ToastrService,
    private modalService: BsModalService
  ) { }

  ngOnInit() {
    this.reloadApplications();
  }


  private reloadApplications() {
    this.userService.getApplications().subscribe((value: PersonApplication[]) => {
      this.applications = value;
    });
  }

  startApplication(application: PersonApplication) {
    if (application.application.confirmationType === 'TOKEN') {
      const modalRef = this.modalService.show(ApplicationTokenModalComponent, {
        ignoreBackdropClick: true,
        initialState: {
          application: application.application,
          callback: (token: string) => {
            if (token) {
              this.userService.updateApplicationConfirmation(application, 'WAITING_RESOLUTION', token).subscribe(value => {
                this.reloadApplications();
              });
            }
          }
        }
      });
    } else {
      if (!application.application.id) {
        application.confirmationStatus = 'ACCEPTED';
        application.updatedAt = moment().format();
        return;
      }

      this.userService.updateApplicationConfirmation(application, 'WAITING_RESOLUTION', null).subscribe(value => {
        this.reloadApplications();
      });
    }
  }

  declineApplication(application: PersonApplication) {
    if (!application.application.id) {
      application.confirmationStatus = 'DECLINED';
      application.updatedAt = moment().format();
      return;
    }

    this.userService.updateApplicationConfirmation(application, 'DECLINED', null).subscribe(value => {
      this.reloadApplications();
    });
  }
}
