export class ArrayHelper {

  public static valuesEqual(a: any[], b: any[]): boolean {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    for (let i = 0; i < a.length; i++) {
      if (!this.objectsEqual(a[i], b[i])) {
        return false;
      }
    }

    return true;
  }

  private static objectsEqual(a: any, b: any): boolean {
    return JSON.stringify(a) === JSON.stringify(b);
  }

}
