<div class="modal-header">
  <h5 class="modal-title w-100" i18n>Connected services</h5>
</div>
<div class="modal-body">
  <p i18n>Show external services data:</p>
  <ul>
    <li *ngFor="let item of pendingApplications">{{item.application.name}}</li>
  </ul>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-light btn-sm" aria-label="Close" (click)="decline()" i18n>Not now</button>
  <button type="button" class="btn btn-primary btn-sm" (click)="acceptAll()" i18n>Yes, agree</button>
</div>
