<div class="list-item draggable">
  <div class="form-row">
    <div class="col-auto w-100">
      <div class="d-flex">
        <span class="title">{{personSeries.name}}</span>
        <div class="ml-auto pl-3">
          <div class="btn-remove-wrapper">
            <button type="button" class="close" (click)="remove()" tooltip="Remove" i18n-tooltip  placement="auto">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <span class="col-auto">
      <label class="light mr-2 mb-0" i18n>Type</label>
      <span class="text-light">
        {{personSeries.seriesType | label}}
      </span>
    </span>
    <span class="col-auto">
      <label class="light mr-2 mb-0" i18n>Location</label>
      <span class="text-light">{{personSeries.location}}</span>
    </span>
  </div>
</div>
