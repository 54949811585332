import { Inject, Injectable } from '@angular/core';
import {AuthService, Tenant, TenantPage} from '@smartencity/core';
import {HttpClient} from '@angular/common/http';
import {TenantContractComponent} from '../components/modal/tenant-contract.component';
import {SelectTenantComponent} from '../components/modal/select-tenant.component';
import {SMARTENCITY_MYDATA_CONFIG} from '../injection-tokens';
import {MyDataConfig} from '../mydata-config.model';
import {Observable} from 'rxjs/internal/Observable';
import {BsModalService} from 'ngx-bootstrap/modal';

@Injectable()
export class TenantSelectService {
  constructor(
    @Inject(SMARTENCITY_MYDATA_CONFIG) private config: MyDataConfig,
    public authService: AuthService,
    private http: HttpClient,
    private modalService: BsModalService
  ) { }

  selectTenant(optional?: boolean): Observable<Tenant> {
    return new Observable(observer => {
      this.http.get(this.config.apiUrl + '/tenant', {}).subscribe(
        (data: TenantPage) => {
          const tenants = data.content;
          if (tenants.length === 0) {
            if (!optional) {
              this.createContract(observer);
            } else {
              observer.next(null);
              observer.complete();
            }
          } else if (tenants.length === 1) {
            observer.next(tenants[0]);
            observer.complete();
          } else {
            this.chooseTenant(tenants, observer);
          }
        }, err => {
          return observer.error(err);
        });
    });
  }

  private chooseTenant(tenants: Tenant[], observer) {
    const modalRef = this.modalService.show(SelectTenantComponent, {
      ignoreBackdropClick: true,
      class: 'modal-lg',
      backdrop: 'static',
      initialState: {
        tenants: tenants,
        callback: (tenant: Tenant) => {
          if (tenant) {
            observer.next(tenant);
          } else {
            observer.error('No selection');
          }
          observer.complete();
        }
      }
    });
  }

  private createContract(observer) {
    const modalRef = this.modalService.show(TenantContractComponent, {
      ignoreBackdropClick: true,
      class: 'modal-lg',
      backdrop: 'static',
      initialState: {
        callback: (tenant: Tenant) => {
          if (tenant) {
            observer.next(tenant);
          } else {
            observer.error('No selection');
          }
          observer.complete();
        }
      }
    });
  }
}
