import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {WeatherStation} from '../../model/weather-station';
import moment from 'moment';
import {WeatherStationConstants} from '../../model/weather-station-constants';

@Component({
  selector: '[weather-station-modal]',
  templateUrl: './weather-station-modal.component.html'
})
export class WeatherStationModalComponent implements OnInit {

  @Input()
  public weatherStation: WeatherStation;

  public updatedAt: Date;

  public data: { cols: { label: string; value: any, unit: string }[] }[] = []

  constructor() {

  }

  ngOnInit(): void {
    this.updatedAt = moment(this.weatherStation.updatedAt)
      .startOf('hour') // rounding to full hour
      .toDate();

    this.populateTable();
  }

  private populateTable(): void {
    let i = 0;
    let row = {
      cols: []
    };
    let data = [];

    for (let indicator of WeatherStationConstants.weatherStationIndicators) {
      let seriesMatch = null;
      for (let series of this.weatherStation.series) {
        if (series.type === indicator.key) {
          i = i + 1;
          seriesMatch = series;
        }
      }

      if (seriesMatch) {
        row.cols.push({
          label: indicator.label,
          value: seriesMatch.value,
          unit: seriesMatch.unit
        });

        if (i % 2 == 0) {
          data.push(row);
          row = {
            cols: []
          };
        }
      }
    }

    this.data = data;
  }

}
