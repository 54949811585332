import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Subject} from 'rxjs/internal/Subject';
import {NavbarService} from '../navbar/navbar.service';
import {GroupsComponent} from '../groups/groups.component';
import {GroupsService} from '../groups/groups.service';
import {GroupsEditModalComponent} from '../groups/edit-modal/groups-edit-modal.component';
import {BsModalService} from 'ngx-bootstrap/modal';
import {FormulaTemplateService} from '../formula-template/formula-template.service';
import {QuestionnairesListService} from '../questionnaires/questionnaires-list.service';
import {ComponentLoaderFactory} from 'ngx-bootstrap/component-loader';
import {take, takeUntil} from 'rxjs/operators';
import {TenantSelectService} from '../../services/tenant-select.service';
import {ActivatedRoute, Router} from '@angular/router';
import {CreateSourcesService} from '../../services/create-sources.service';
import {CreateSeriesModalService} from '../../services/create-series-modal.service';

@Component({
  selector: 'mydata-create-sources',
  templateUrl: './create-sources.component.html',
  styleUrls: ['./create-sources.component.css'],
  providers: [BsModalService, ComponentLoaderFactory, GroupsService, FormulaTemplateService, QuestionnairesListService]
})
export class CreateSourcesComponent implements OnInit, OnDestroy {
  private ngDestroy = new Subject<void>();

  @ViewChild('navActions', {static: true})
  public navTemplate: TemplateRef<any>;

  @ViewChild(GroupsComponent) groupsComponent: GroupsComponent;

  filterForm = this.createSourcesService.filterForm;
  searchControl = this.filterForm.get('query');

  constructor(
    private navbarService: NavbarService,
    private modalService: BsModalService,
    private tenantSelectService: TenantSelectService,
    private router: Router,
    private route: ActivatedRoute,
    private createSourcesService: CreateSourcesService,
    private createSeriesModalService: CreateSeriesModalService
  ) {
  }

  ngOnDestroy(): void {
    this.navbarService.setActionsTemplate(null);
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  ngOnInit(): void {
    this.navbarService.setActionsTemplate(this.navTemplate);
  }

  createGroup(): void {
    const modalRef = this.modalService.show(GroupsEditModalComponent, {
      ignoreBackdropClick: true,
      class: 'modal-xxl'
    });
    modalRef.content.savedEmitter.pipe(takeUntil(this.ngDestroy), take(1)).subscribe((saved) => {
      this.groupsComponent.reload();
    });
  }

  createFormula(): void {
    this.router.navigate(['formula-template', 'add'], {relativeTo: this.route});
  }

  clearSearch(): void {
    this.searchControl.setValue('');
  }

  public createSeries() {
    this.createSeriesModalService.createSeriesAndNavigate();
  }

}
