<div class="col-xl-8 col-lg-10 col-md-12 mx-auto my-5" [formGroup]="formGroup">
  <div class="form-group row">
    <label class="col-md-3 col-form-label" [attr.for]="id + '-name'" *ngIf="type !== 'FORMULA_SOURCE_TEMPLATE'" i18n>Questionnaire title</label>
    <label class="col-md-3 col-form-label" [attr.for]="id + '-name'" *ngIf="type === 'FORMULA_SOURCE_TEMPLATE'" i18n>Title</label>
    <div class="col-md-9">
      <input type="text" class="form-control" [class.is-invalid]="invalid('name')" [id]="id + '-name'" *ngIf="type !== 'FORMULA_SOURCE_TEMPLATE'" formControlName="name" placeholder="Questionnaire title" i18n-placeholder>
      <input type="text" class="form-control" [class.is-invalid]="invalid('name')" [id]="id + '-name'" *ngIf="type === 'FORMULA_SOURCE_TEMPLATE'" formControlName="name" placeholder="Title" i18n-placeholder>
      <small *ngIf="invalid('name') && errors('name')?.required" class="invalid-feedback d-block" i18n>Required</small>
    </div>
  </div>

  <div class="form-group row">
    <label class="col-md-3 col-form-label" [attr.for]="id + '-name'" i18n>Description</label>
    <div class="col-md-9">
      <textarea class="form-control" rows="3" [class.is-invalid]="invalid('description')" [id]="id + '-description'" *ngIf="type !== 'FORMULA_SOURCE_TEMPLATE'" formControlName="description" placeholder="Add a description for the questionnaire" i18n-placeholder></textarea>
      <textarea class="form-control" rows="3" [class.is-invalid]="invalid('description')" [id]="id + '-description'" *ngIf="type === 'FORMULA_SOURCE_TEMPLATE'" formControlName="description" placeholder="Add a description for the template" i18n-placeholder></textarea>
      <small *ngIf="invalid('description') && errors('description')?.required" class="invalid-feedback d-block" i18n>Required</small>
    </div>
  </div>

  <div class="form-group row" *ngIf="type != 'FORMULA_SOURCE_TEMPLATE'">
    <label class="col-md-3 col-form-label col-form-label-other" i18n>Attachments</label>
    <div class="col-md-9">
      <label class="btn btn-link p-0 mr-4" type="file" [attr.for]="id + '-upload'" i18n>Add files</label>
      <input [id]="id + '-upload'" (change)="uploadFile($event)" #uploadInput type="file" hidden multiple>
      <span class="help-text" i18n>Here you can use file attachments to provide additional information for the participants.</span>

      <div class="files">
        <div class="d-flex align-items-center mt-2" *ngFor="let fileForm of filesArray.controls; let i=index" [formGroup]="fileForm">
          <i class="fal fa-file-check icon-md mr-3"></i>
          <span>{{fileForm.get('fileRef').value.name}}</span>
          <button class="btn btn-sm btn-remove ml-3" (click)="downloadFile(i)" *ngIf="fileForm.get('id').value"><i class="fal fa-download"></i>&nbsp;<ng-container i18n>Download</ng-container></button>
          <button class="btn btn-sm btn-remove ml-3" (click)="removeFile(i)"><i class="fal fa-times"></i>&nbsp;<ng-container i18n>Delete</ng-container></button>
        </div>
      </div>
    </div>
  </div>

  <div class="form-group row" [formGroup]="formGroup" *ngIf="type != 'FORMULA_SOURCE_TEMPLATE'">
    <label i18n class="col-md-3 col-form-label col-form-label-other">Settings</label>
    <div class="col-md-9">
      <div class="custom-control custom-switch mb-2">
        <input type="checkbox" class="custom-control-input" [id]="id + '-check-em'" formControlName="typeEnergyManager">
        <label class="custom-control-label mr-4" [attr.for]="id + '-check-em'" i18n>Energy manager questionnaire</label>
        <span class="help-text" i18n>The questionnaire will be categorised and displayed under the Energy manager</span>
      </div>
      <div class="custom-control custom-switch mb-2" *ngIf="false"><!-- TODO not implemented in dal-server -->
        <input type="checkbox" class="custom-control-input" id="customSwitch2" checked>
        <label class="custom-control-label mr-4" for="customSwitch2">Avalik templiit</label>
        <span class="help-text">Küsimustikust luuakse avalik templiit</span>
      </div>
      <div class="custom-control custom-switch mb-2" *ngIf="false"><!-- TODO not implemented in dal-server -->
        <input type="checkbox" class="custom-control-input" id="customSwitch3" checked>
        <label class="custom-control-label mr-4" for="customSwitch3">Korduv küsimustik</label>
        <span class="help-text">Automaatselt väljasaadetav küsimustik määratud perioodi tagant</span>
      </div>
    </div>
  </div>
</div>
