<div class="modal-header">
  <h5 class="modal-title w-100">{{title}}</h5>
</div>
<div class="modal-body">
  {{description}}
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-light btn-sm" aria-label="Close" (click)="close()" >{{cancelLabel}}</button>
  <button type="button" class="btn btn-primary btn-sm" (click)="confirm()">{{okLabel}}</button>
</div>
