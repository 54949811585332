import {Component, OnInit, ViewChild} from '@angular/core';
import {BuildingsService} from '../../service/buildings.service';
import {MapsGridArea} from '../../model/crowd-insights/maps-grid-area';
import {CrowdInsightsDataService} from '../../service/crowd-insights-data.service';
import {ActivityDaily} from '../../model/crowd-insights/activity-daily';
import {Observable} from 'rxjs';
import {Subject} from 'rxjs/internal/Subject';
import {TabsetComponent} from 'ngx-bootstrap/tabs';

@Component({
  selector: 'crowd-insights-grid-area-modal',
  templateUrl: './crowd-insights-grid-area-modal.component.html',
  styleUrls: ['./crowd-insights-grid-area-modal.scss']
})
export class CrowdInsightsGridAreaModalComponent implements OnInit {

  @ViewChild('staticTabs', { static: false }) staticTabs?: TabsetComponent;

  gridArea: MapsGridArea;

  activities: ActivityDaily[];

  loading = true;

  total = 0;

  closeChild = false;

  closeSubject: Subject<void> = new Subject<void>();

  close$: Observable<void>  = this.closeSubject.asObservable();

  constructor(private buildingsService: BuildingsService,
              private crowdInsightsDataService: CrowdInsightsDataService) { }

  ngOnInit(): void {

    this.loading = true;

    this.buildingsService.crowdInsightsGridAreaSelected$.subscribe((gridArea: MapsGridArea) => {
      this.closeChild = false;
      this.loading = false;
      this.gridArea = gridArea;

      this.reset();
    });

  }

  close(): void {
    this.closeChild = true;
    this.buildingsService.selectCrowdInsightGridArea(null);

    this.reset();
  }

  private reset(): void {
    this.selectTab(0);
  }

  selectTab(tabId: number): void {
    if (this.staticTabs?.tabs[tabId]) {
      this.staticTabs.tabs[tabId].active = true;
    }
  }

}
