import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import {AddressService} from '../address.service';

const selector = 'core-address-apartment';
let nextId = 0;

@Component({
  selector: selector,
  templateUrl: './address-apartment.component.html',
  styles: [':host {display: block}', ':host.address-input-group {display: flex}']
})
export class AddressApartmentComponent implements OnInit {
  id = `${selector}-${nextId++}`;

  @Input()
  showErrors = true;

  formControl: AbstractControl = this.addressService.getApartmentControl();

  apartmentTypeahead = this.addressService.apartmentTypeahead;

  constructor(private addressService: AddressService) { }

  ngOnInit() {
  }
}
