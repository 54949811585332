import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpSentEvent,
  HttpHeaderResponse, HttpProgressEvent, HttpResponse, HttpUserEvent, HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError, from } from 'rxjs';
import { catchError, finalize, map, shareReplay, switchMap, take } from 'rxjs/operators';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {

  private tokenRefresh$: Observable<string> = null;

  static addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
    return req.clone({ setHeaders: { 'X-Auth-token': token } });
  }

  constructor(private authService: AuthService) {
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<any | HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
    const token = this.authService.getToken();
    if (!token) {
      return next.handle(request);
    }

    return next.handle(RequestInterceptor.addToken(request, token)).pipe(catchError((error) => {
      if (error instanceof HttpErrorResponse) {
        switch ((<HttpErrorResponse>error).status) {
          case 401:
            return this.handle401Error(error, request, next);
          default:
            return throwError(error);
        }
      }

      return throwError(error);
    }));
  }

  handle401Error(error: HttpErrorResponse, req: HttpRequest<any>, next: HttpHandler) {
    if (!this.tokenRefresh$) {
      this.tokenRefresh$ = from(this.authService.authenticate({ refresh: true })).pipe(
        map((authentication) => {
          return authentication ? this.authService.getToken() : null;
        }),
        shareReplay(1),
        finalize(() => this.tokenRefresh$ = null));
    }
    return this.tokenRefresh$.pipe(
      take(1),
      switchMap(token => {
        if (!token) {
          return throwError(error);
        }
        return next.handle(RequestInterceptor.addToken(req, token));
      })
    );
  }
}
