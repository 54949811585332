import { Injectable } from '@angular/core';
import {Subject} from 'rxjs/internal/Subject';
import {ActivityDaily} from '../../model/crowd-insights/activity-daily';
import {active} from 'd3';
import {Observable, of, switchMap} from 'rxjs';
import {CrowdInsightsDataService} from '../../service/crowd-insights-data.service';
import {MapsGridArea} from '../../model/crowd-insights/maps-grid-area';
import {ActivityType} from '../../model/crowd-insights/activity-type';
import {DurationGroupType} from '../../model/crowd-insights/duration-group-type';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';
import {map, startWith} from 'rxjs/operators';

@Injectable()
export class CrowdInsightsGridAreaModalService {



  // activitiesFilterSource: Subject<ActivityFilter> = new Subject<ActivityFilter>();
  //
  // activityFilter$: Observable<ActivityFilter> = this.activitiesFilterSource.asObservable().pipe(startWith(CrowdInsightsGridAreaModalService.getDefaultFilter()));
  //
  // private activitiesSource: Subject<ActivityDaily[]> = new Subject<ActivityDaily[]>();
  //
  // public activities$ = this.activityFilter$.pipe(
  //   switchMap((filter: ActivityFilter) => {
  //     return this.getAcitivites(filter);
  //   })
  // );
  //
  // private getAcitivites(filter: ActivityFilter): Observable<ActivityDaily[]> {
  //   return of([]);
  // }
  //
  // public static getDefaultFilter(): ActivityFilter {
  //   {
  //     return {
  //       activityType: 'WORK',
  //       durationGroupType: '60-120'
  //     };
  //   }
  //
  // }
  //
  // constructor(private crowdInsightsDataService: CrowdInsightsDataService) { }
  //
  //
  // public searchActivitiesByAreaAndActivityType(area: MapsGridArea, activityType: ActivityType): void {
  //   this.activityFilter = {
  //     ...this.activityFilter,
  //     activityType: activityType
  //   }
  //   this.loadActivities(area);
  // }
  //
  // public searchActivitiesByAreaAndDurationGroupType(area: MapsGridArea, durationGroupType: DurationGroupType): void {
  //   this.activityFilter = {
  //     ...this.activityFilter,
  //     durationGroupType: durationGroupType
  //   }
  //   this.loadActivities(area);
  // }
  //
  // public loadActivities(area: MapsGridArea): void {
  //   this.crowdInsightsDataService.getDailyActivities(area, this.activityFilter).subscribe((result) => {
  //     this.activitiesSource.next(result);
  //   });
  // }

}
