import {Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation} from '@angular/core';
import {
  AfterLoginRedirectStateService,
  AuthService, CompareService,
  CoreConfig,
  EnumsService,
  SMARTENCITY_CORE_CONFIG
} from '@smartencity/core';
import {ApplicationService} from './services/application.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs/internal/Subject';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {HttpClient} from '@angular/common/http';
import {BsModalService} from 'ngx-bootstrap/modal';

@Component({
  selector: 'mydata-main',
  templateUrl: './mydata.component.html',
  encapsulation: ViewEncapsulation.None
})
export class MydataComponent implements OnInit, OnDestroy {
  private ngDestroy = new Subject<void>();

  @ViewChild('compareFooterButton', {static: true})
  public compareFooterButton: TemplateRef<any>;

  constructor (
    public authService: AuthService,
    public applicationService: ApplicationService,
    private router: Router,
    private route: ActivatedRoute,
    private compareService: CompareService,
    public toastr: ToastrService,
    private enumService: EnumsService,
    private http: HttpClient,
    @Inject(SMARTENCITY_CORE_CONFIG) private config: CoreConfig,
    private afterLoginRedirectStateService: AfterLoginRedirectStateService,
    private modalService: BsModalService
  ) {
    authService.authenticationResult$.pipe(takeUntil(this.ngDestroy)).subscribe((result) => {
      if (!result.result && !result.skipRedirect) {
        this.router.navigate(['/']).then(() => {
          window.location.reload();
        });
      }
    });

    authService.currentMandate$.pipe(takeUntil(this.ngDestroy)).subscribe((data: any) => {
      applicationService.checkApplications();
    });

    authService.roleChange$.pipe(takeUntil(this.ngDestroy)).subscribe((person) => {
      //this.closeAllModals();
      this.afterLoginRedirectStateService.doAfterLoginRedirect = true;
      this.router.navigate(['/mydata']).then(() => {
        window.location.reload();
      });
    });

  }

  ngOnInit(): void {
    this.compareService.setFooterTemplate(this.compareFooterButton);
    this.enumService.loadEnums();
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  private closeAllModals(): void {
    const ids: number[] = this.modalService['loaders'].map((l) => l.instance.id);
    for (const id of ids) {
      this.modalService.hide(id);
    }
  }

}
