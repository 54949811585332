import {BicycleStation, BicycleStationCounts} from '../component/bicycle-stations-heatmap/bicylce-stations.model';
import {UuidHelper} from '@smartencity/core';

export class BicycleStationsLayer extends google.maps.OverlayView {
  private div: any;

  public items: BicycleStationCounts[];
  private popoverTimeout = null;
  private selectedStation: BicycleStation;

  constructor(
    items: BicycleStationCounts[]
  ) {
    super();
    this.items = items;
  }

  draw() {
    if (!this.div) {
      const panes = this.getPanes();
      if (panes == null) {
        return;
      }

      this.div = document.createElement('DIV');
      this.div.style.position = 'absolute';
      this.div.style.cursor = 'pointer';
      this.div.style.position = 'absolute';
      this.div.classList.add('bicycle-station-layer');
      this.div.style.paddingLeft = '0px';
      panes.overlayMouseTarget.appendChild(this.div);
    }

    this.div.innerHTML = this.getLayerHtml();

    this.resetPopovers();
  }

  private resetPopovers() {
    ($(this.div).find('[data-toggle="popover"]') as any)
      .popover('dispose')
      .popover({trigger: 'hover'});
  }

  private getLayerHtml() {
    let html = '';

    if (!this.items) {
      return html;
    }

    this.items.forEach(item => {
      const point = this.getProjection().fromLatLngToDivPixel(new google.maps.LatLng(item.lat, item.lng));
      const x = point.x - 12;
      const y = point.y - 39;


      let dataContentParts: string[] = [];
      dataContentParts.push(item.name);
      dataContentParts.push("<br><span class=\'small\'>");
      dataContentParts.push($localize`Free docks count: ${item.freeDocksCount}`);
      dataContentParts.push("</span><br><span class=\'small\'>");
      dataContentParts.push($localize`Availability:`);
      dataContentParts.push(" ");

      const availabilityParts = [];
      if (item.primaryLockedCount) {
        let availabilityPart = '';
        if (item.primaryLockedCount == 1) {
          availabilityPart += $localize`<span class=\'text-primary\'>${item.primaryLockedCount}</span> electric bicycles`;
        } else {
          availabilityPart += $localize`<span class=\'text-primary\'>${item.primaryLockedCount}</span> electric bicycle`;
        }
        availabilityParts.push(availabilityPart);
      }

      if (item.secondaryLockedCount) {
        let availabilityPart = '';
        if (item.secondaryLockedCount == 1) {
          availabilityPart += $localize`<span class=\'text-primary\'>${item.secondaryLockedCount}</span> non-electric bicycles`;
        } else {
          availabilityPart += $localize`<span class=\'text-primary\'>${item.secondaryLockedCount}</span> non-electric bicycle`;
        }
        availabilityParts.push(availabilityPart);
      }

      if (!availabilityParts.length) {
        dataContentParts.push($localize`None`);
      } else {
        dataContentParts.push(availabilityParts.join(', '));
      }

      dataContentParts.push("</span>");


      const isSelected = (this.selectedStation && UuidHelper.extractFromStart(this.selectedStation.uuid) == item.uuid ? ' active' : '');
      html += '<div class="pointer-bicycle-station' + isSelected + '" style="left: ' + x + 'px; top: ' + y + 'px" data-trigger="hover" data-toggle="popover" data-placement="top" data-html="true" ' +
        'data-content="' + dataContentParts.join("") + '"></div>';

    });

    return html;
  }

  setMap(map) {
    super.setMap(map);
  }

  remove() {
    this.setMap(null);
    if (this.div && this.div.parentNode) {
      this.div.parentNode.removeChild(this.div);
      this.div = null;
    }
  }

  showStation(station: BicycleStation): void {
    this.setSelected(station);
    this.draw();
  }

  setSelected(station: BicycleStation): void {
    this.selectedStation = station;
  }

}
