import {Component, Inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {Subject} from 'rxjs/internal/Subject';
import {Location, LocationService, Questionnaire, QuestionnaireField, QuestionnaireResults, QuestionnaireResultsField} from '@smartencity/core';
import {MyDataConfig} from '../../../mydata-config.model';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {SMARTENCITY_MYDATA_CONFIG} from '../../../injection-tokens';

@Component({
  selector: 'mydata-view-results-summary',
  templateUrl: './view-results-summary.component.html',
  styleUrls: ['./view-results-summary.component.css']
})
export class ViewResultsSummaryComponent implements OnInit, OnDestroy, OnChanges {
  private ngDestroy = new Subject<void>();

  @Input()
  questionnaire: Questionnaire;
  results: QuestionnaireResults;
  resultFields: QuestionnaireResultsField[];
  submitCount = 0;
  fieldValueFrequency: any = {};
  fieldAnswersCountMapping: any = {};

  constructor(
    @Inject(SMARTENCITY_MYDATA_CONFIG) private config: MyDataConfig,
    private http: HttpClient,
    private router: Router,
    private modalRef: BsModalRef,
    private locationService: LocationService
  ) { }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  ngOnInit(): void {
    this.loadResults();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadResults();
  }

  loadResults() {
    if (this.questionnaire) {
      this.http.get(this.config.apiUrl + '/questionnaire/' + this.questionnaire.id + '/results', {}).subscribe((data: QuestionnaireResults) => {
        this.results = data;
        this.submitCount = data.submitCount;
        this.resultFields = [];
        this.fieldValueFrequency = {};
        this.fieldAnswersCountMapping = {};

        for (const resultField of data.resultFields) {
          /*if (resultField.field.responseType === 'COMMON' || resultField.field.type === 'FORMULA') {
            continue;
          }*/
          this.resultFields.push(resultField);
          this.fieldValueFrequency[resultField.field.id] = {};
          if (resultField.field.valueType === 'SELECT') {
            for (const option of resultField.field.selectOptions) {
              this.fieldValueFrequency[resultField.field.id][option.name] = 0;
            }
          }

          let answersCount = 0;
          for (const response of resultField.responses) {
            let value = null;
            if (response.type === 'TEXT') {
              value = response.text;
            } else if (response.type === 'VALUE') {
              value = response.value;
            } else if (response.type === 'SELECT') {
              value = response.option;
            }
            if (value) {
              answersCount++;
              if (this.fieldValueFrequency[resultField.field.id][value] || this.fieldValueFrequency[resultField.field.id][value] === 0) {
                this.fieldValueFrequency[resultField.field.id][value]++;
              } else {
                this.fieldValueFrequency[resultField.field.id][value] = 1;
              }
            }
          }

          this.fieldAnswersCountMapping[resultField.field.id] = {
            count: answersCount
          };
        }
      });
    }
  }

  public getVariableList(formula: QuestionnaireField) {
    return (formula.variables ? formula.variables : []).join(', ');
  }

  public viewDatapoints(resultField) {
    this.modalRef.hide();

    console.log(this.questionnaire);
    this.locationService.navigate(Location.ofQ11e(this.questionnaire));
  }
}
