import {ChangeDetectorRef, Component, Inject, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Subject} from 'rxjs/internal/Subject';
import {SeriesEditModalComponent} from '../../datapoint-list-manage/edit-modal/series-edit-modal.component';
import {DatatableComponent} from '@swimlane/ngx-datatable';
import {
  AuthService, ConfirmModalComponent, ErrorResponseHelper, Location, LocationService,
  PageResponse,
  Person, PersonSeriesApiService, PersonSeriesCompareService, PersonSeriesGroup, PreventCloseModalService,
  ResizeService, SCREEN_SIZE, SeriesService,
  SourceOwnerSeries,
  SourceOwnerSeriesListItem
} from '@smartencity/core';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {SMARTENCITY_MYDATA_CONFIG} from '../../../injection-tokens';
import {MyDataConfig} from '../../../mydata-config.model';
import {TenantSelectService} from '../../../services/tenant-select.service';
import {ToastrService} from 'ngx-toastr';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {SharedKeywordStore} from '../../../services/shared-search-keyword-store.service';
import {PersonSeriesManageService} from '../../../services/person-series-manage.service';
import {NavbarService} from '../../navbar/navbar.service';
import {take, takeUntil} from 'rxjs/operators';
import {
  DatapointGroupSelectModalComponent
} from '../../datapoint-groups-management/datapoint-group-select-modal/datapoint-group-select-modal.component';
import {
  CreateConsentBulkModalComponent
} from '../../consumption/person-series/create-consent-bulk-modal/create-consent-bulk-modal.component';
import {
  EditDatapointGroupModalComponent
} from '../../datapoint-groups-management/edit-datapoint-group-modal/edit-datapoint-group-modal.component';
import {SeriesBulkEditModalComponent} from '../../datapoint-list-manage/bulk-edit-modal/series-bulk-edit-modal.component';
import {Observable} from 'rxjs/internal/Observable';
import {forkJoin} from 'rxjs/internal/observable/forkJoin';
import {of} from 'rxjs/internal/observable/of';
import {ConsentModalComponent} from '../../consumption/person-series/consent/consent-modal.component';
import {SetOwnerModalComponent} from '../../managed-sources/set-owner-modal/set-owner-modal.component';
import {FormControl} from '@angular/forms';
import {DatapointGroupsIndexNavbarService} from '../datapoint-groups-index-navbar.service';
import { RowAction, RowActionType, TableAction, TableActionType } from '../../datapoint-list-manage/datapoint-table/datapoint-table.component';
import {DatapointGroupsSeriesListService} from '../../../services/datapoints-groups-series-list/datapoint-groups-series-list.service';

const selector = 'datapoint-groups-series-list';
let nextId = 0;

export enum BulkAction {
  BULK_EDIT = 'bulk-edit',
  BULK_ARCHIVE = 'bulk-archive',
  BULK_DECLINE = 'bulk-decline',
  ADD_TO_GROUP = 'add-to-group',
  SHARE = 'share'
}


@Component({
  selector: selector,
  templateUrl: './datapoint-groups-series-list.component.html'
})
export class DatapointGroupsSeriesListComponent implements OnInit {

  id = `${selector}-${nextId++}`;
  private ngDestroy = new Subject<void>();

  @ViewChild('editSeriesModal', {static: true}) editSeriesModal: SeriesEditModalComponent;

  @ViewChild('table') table: DatatableComponent;
  public columns = {
    detailToggler: false,
    check: true,
    name: true,
    address: true,
    owner: true,
    activityDate: true,
    status: true,
    actions: true
  };

  shareable = false;

  public actionsEnabled: { [key in BulkAction]: boolean } = {
    [BulkAction.BULK_EDIT]: false,
    [BulkAction.BULK_ARCHIVE]: false,
    [BulkAction.BULK_DECLINE]: false,
    [BulkAction.ADD_TO_GROUP]: false,
    [BulkAction.SHARE]: false
  }

  public bulkActionEnum = BulkAction;

  public isBulkActionEnabled(action: BulkAction): boolean {
    return this.actionsEnabled[action];
  }

  public me: Person;
  public filterForm = this.seriesListService.filterForm;

  public page: PageResponse<SourceOwnerSeries>;
  public page$: Subject<any> = this.seriesListService.page$;
  public sorts$: Subject<any> = this.seriesListService.sorts$;
  public limit$: Subject<any> = this.seriesListService.limit$;

  public pageResponse$ = this.seriesListService.pageResponse$;
  public rows$: Observable<SourceOwnerSeriesListItem[]> = this.seriesListService.rows$;

  public rows: SourceOwnerSeriesListItem[];
  public selected: SourceOwnerSeriesListItem[] = [];
  public selectedEditable: SourceOwnerSeriesListItem[] = [];
  public selectedDeclineable: SourceOwnerSeriesListItem[] = [];
  public selectedArchiveable: SourceOwnerSeriesListItem[] = [];
  public selectedShareable: SourceOwnerSeriesListItem[] = [];

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    @Inject(SMARTENCITY_MYDATA_CONFIG) private config: MyDataConfig,
    private tenantSelectService: TenantSelectService,
    private toastr: ToastrService,
    private modalService: BsModalService,
    private preventCloseModalService: PreventCloseModalService,
    private authService: AuthService,
    private personSeriesService: PersonSeriesApiService,
    private resizeService: ResizeService,
    private personSeriesCompareService: PersonSeriesCompareService,
    private sharedKeywordStore: SharedKeywordStore,
    private personSeriesManageService: PersonSeriesManageService,
    private locationService: LocationService,
    private seriesService: SeriesService,
    private navbarService: NavbarService,
    private datapointGroupsIndexNavbarService: DatapointGroupsIndexNavbarService,
    private seriesListService: DatapointGroupsSeriesListService,
    private cd: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    this.me = this.authService.profileData.mandate.person;

    this.pageResponse$.pipe(takeUntil(this.ngDestroy)).subscribe((result: PageResponse<SourceOwnerSeries>) => {
      this.page = result;
      this.selected = [];
      this.selectedEditable = [];
      this.selectedDeclineable = [];
      this.selectedArchiveable = [];
    });

    this.rows$.subscribe((rows: SourceOwnerSeriesListItem[]) => {
      this.rows = rows;
    });

    this.resizeService.onResize$.pipe(takeUntil(this.ngDestroy)).subscribe(this.adjustColumns.bind(this));
  }

  ngOnDestroy() {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  searchGroup(): void {
    const modalRef = this.preventCloseModalService.show(DatapointGroupSelectModalComponent);
    modalRef.content.selectedEmitter.pipe(takeUntil(this.ngDestroy), take(1)).subscribe((personSeriesGroup: PersonSeriesGroup) => {
      this.openPersonSeriesGroupModal(personSeriesGroup);
    });
  }

  // getConsentPersons(series: SourceOwnerSeries): Person[] {
  //   return series.consents.map(c => c.person);
  // }

  createConsents(): void {
    if (this.selectedShareable.length == 0) {
      return;
    }

    const modalRef = this.preventCloseModalService.show(CreateConsentBulkModalComponent, {
      class: 'modal-lg',
      initialState: {
        seriesList: this.selectedShareable.map(r => r.series)
      }
    });
  }

  openPersonSeriesGroupModal(personSeriesGroup: PersonSeriesGroup): void {
    const modalRef = this.preventCloseModalService.show(EditDatapointGroupModalComponent, {
      class: 'modal-lg',
      initialState: {
        group: personSeriesGroup,
        isCreate: !personSeriesGroup.id,
        selectedPersonSeriesList: this.selectedEditable.map(s => s.series.personSeries)
      }
    });

    modalRef.content.saveEmitter.pipe(takeUntil(this.ngDestroy), take(1)).subscribe((group: PersonSeriesGroup) => {
      this.locationService.navigate(new Location({
        group: group
      }));
    });
  }

  clearSearch() {
    this.filterForm.get('query').setValue('');
  }

  setPage(event: any) {
    this.page$.next(event);
  }

  setSorts(event: any) {
    this.sorts$.next(event.sorts);
  }

  editSeries(item: SourceOwnerSeries) {
    if (this.seriesListService.isLoading()) {
      return;
    }
    const modalRef: BsModalRef = this.preventCloseModalService.show(SeriesEditModalComponent, {
      initialState: {
        sourceOwnerSeries: item,
      }
    });
    modalRef.content.savedEmitter.pipe(takeUntil(this.ngDestroy), take(1)).subscribe((saved) => {
      this.reloadList();
    });
  }

  bulkEdit() {
    const modalRef: BsModalRef = this.preventCloseModalService.show(SeriesBulkEditModalComponent, {
      initialState: {
        rows: this.selected
      }
    });
    modalRef.content.savedEmitter.pipe(takeUntil(this.ngDestroy), take(1)).subscribe((saved) => {
      this.reloadList();
    });
  }

  acceptOwnership(series: SourceOwnerSeries) {
    this.personSeriesManageService.acceptOwnerShip(series).subscribe((data) => {
      this.toastr.success($localize`Ownership accepted`);
      this.reloadList();
    }, (error) => {
      const msg = $localize`Error accepting ownership`;
      this.handlePersonSeriesManageError(error, msg);
    });
  }

  declineOwnership(series: SourceOwnerSeries) {
    this.personSeriesManageService.declineOwnerShip(series.personSeries).subscribe((confirm) => {
      if (confirm) {
        this.toastr.success($localize`Ownership declined`);
        this.reloadList();
      }
    }, (error) => {
      const msg = $localize`Error declining ownership`;
      this.handlePersonSeriesManageError(error, msg);
    });
  }

  declineOwnershipAndArchive(series: SourceOwnerSeries) {
    this.personSeriesManageService.declineOwnerShipAndArchive(series.personSeries).subscribe((confirm) => {
      if (confirm) {
        this.toastr.success($localize`Datapoint archived`);
        this.reloadList();
      }
    }, (error) => {
      const msg = $localize`Error archiving series`;
      this.handlePersonSeriesManageError(error, msg);
    });
  }

  archiveSeries(series: SourceOwnerSeries) {
    this.personSeriesManageService.archivePersonSeries(series.personSeries).subscribe((confirm) => {
      if (confirm) {
        this.toastr.success($localize`Datapoint archived`);
        this.reloadList();
      }
    }, (error) => {
      const msg = $localize`Error archiving series`;
      this.handlePersonSeriesManageError(error, msg);
    });
  }

  toggleExpandRow(row) {
    this.table.rowDetail.toggleExpandRow(row);
  }

  showConsents(series: SourceOwnerSeries): void {
    const modalRef = this.preventCloseModalService.show(ConsentModalComponent, {
      class: 'modal-lg',
      initialState: {
        lastDataOwnerPeriod: series.lastDataOwnerPeriod,
        personSeries: series.personSeries
      }
    });
  }

  setRowDataOwner(series: SourceOwnerSeries): void {
    const modalRef = this.modalService.show(SetOwnerModalComponent, {
      initialState: {
        rows: [{
          checkControl: new FormControl(true),
          series: series
        }]
      }
    });
    modalRef.content.savedEmitter.pipe(takeUntil(this.ngDestroy)).subscribe(() => {
      this.reloadList();
    });
  }

  cancelOwnership(series: SourceOwnerSeries): void {
    this.seriesService.cancelOwnerShip(series).pipe(takeUntil(this.ngDestroy)).subscribe(() => {
      this.toastr.success('Cancelled');
      this.reloadList();
    });
  }

  isOwner(series: SourceOwnerSeries): boolean {
    return series?.lastPeriod?.ownerType == 'OWNER' || series?.lastPeriod?.ownerType == 'MANAGER';
  }

  isGroupConsent(series: SourceOwnerSeries): boolean {
    return series?.lastPeriod?.ownerType == 'GROUP_CONSENT';
  }

  private reloadList(): void {
    this.seriesListService.reload();
  }

  private handlePersonSeriesManageError(error: any, msg: string): void {
    console.error(error);
    const errorReason = ErrorResponseHelper.getErrorReason(error);
    if (errorReason) {
      msg += '<br />' + errorReason;
    }
    this.toastr.error(msg, null, {  enableHtml: true, disableTimeOut: true });
  }

  private adjustColumns(size: number): void {
    if (size != null && size < SCREEN_SIZE.MD) {
      this.columns.detailToggler = true;
      this.columns.check = false;
      this.columns.address = false;
      this.columns.owner = false;
      this.columns.activityDate = false;
      this.columns.status = false;
      this.columns.actions = false;
    } else {
      this.columns.detailToggler = false;
      this.columns.check = true;
      this.columns.address = true;
      this.columns.owner = true;
      this.columns.activityDate = true;
      this.columns.status = true;
      this.columns.actions = true;
      if (this.table?.rowDetail) {
        this.table.rowDetail.collapseAllRows();
      }
    }
  }

  onRowsSelect(selected: SourceOwnerSeriesListItem[]) {
    this.selected = [];
    this.selected.push(...selected);
    this.selectedEditable = this.selected.filter(r => r.confirmationStatus !== 'PENDING' && r.ownerType !== 'GROUP_CONSENT');
    this.selectedDeclineable = this.selected.filter(r => r.confirmationStatus === 'ACCEPTED' || r.confirmationStatus === 'PENDING');
    this.selectedArchiveable = this.selected.filter(r => r.confirmationStatus === 'DECLINED' || r.confirmationStatus === 'CANCELLED');
    this.selectedShareable = this.selected.filter(r => {
      return this.isOwner(r.series) && r.confirmationStatus === 'ACCEPTED';
    });

    this.updateBulkActionsEnabled();
  }

  private updateBulkActionsEnabled(): void {
    this.actionsEnabled[BulkAction.BULK_EDIT] = this.selectedEditable.length > 0;
    this.actionsEnabled[BulkAction.ADD_TO_GROUP] = this.selectedEditable.length > 0;
    this.actionsEnabled[BulkAction.BULK_DECLINE] = this.selectedDeclineable.length > 0;
    this.actionsEnabled[BulkAction.BULK_ARCHIVE] = this.selectedArchiveable.length > 0;
    this.actionsEnabled[BulkAction.SHARE] = this.selectedShareable.length > 0;
    this.cd.detectChanges();
  }

  public onRowAction(action: RowAction) {
    switch(action.type) {
      case RowActionType.EDIT_SERIES:
      this.editSeries(action.data);
      break;

      case RowActionType.DECLINE_OWNERSHIP:
      this.declineOwnership(action.data);
      break;

      case RowActionType.ACCEPT_OWNERSHIP:
      this.acceptOwnership(action.data);
      break;

      case RowActionType.ARCHIVE_SERIES:
      this.archiveSeries(action.data);
      break;

      case RowActionType.DECLINE_AND_ARCHIVE:
      this.declineOwnershipAndArchive(action.data);
      break;

      case RowActionType.SET_NEW_OWNER:
      this.setRowDataOwner(action.data);
      break;

      case RowActionType.SHOW_CONSENTS:
      this.showConsents(action.data);
      break;
    }
  }

  public onTableAction(action: TableAction) {
    switch(action.type) {
      case TableActionType.SET_PAGE:
      this.setPage(action.event);
      break;

      case TableActionType.SET_SORTS:
      this.setSorts(action.event);
      break;
    }
  }

  bulkDecline() {
    if (!this.selectedDeclineable.length) {
      return;
    }
    const modalRef = this.modalService.show(ConfirmModalComponent, {
      ignoreBackdropClick: true,
      initialState: {
        description: $localize`Are you sure you want to decline the selected datapoints?`,
        callback: (confirm: boolean) => {
          if (confirm) {
            this.personSeriesService.updateStatusBulk(
              this.selectedDeclineable.map(value => {
                  this.personSeriesCompareService.remove(value.series.personSeries);
                  return value.series.personSeries
              }
              ),
              'DECLINED',
              null
            ).subscribe((data: any) => {
                this.toastr.success($localize`Datapoints declined`);
                this.reloadList();
                // this.notificationService.loadPendingPendingSeries();
              },
              (error) => {
                console.error(error);
                this.toastr.error($localize`Error updating datapoints`);
              }
            );
          }
        }
      }
    });
  }

  bulkArchive() {
    if (!this.selectedArchiveable.length) {
      return;
    }

    const modalRef = this.modalService.show(ConfirmModalComponent, {
      ignoreBackdropClick: true,
      initialState: {
        description: $localize`Are you sure you want to archive the selected datapoints?`,
        callback: (confirm: boolean) => {
          if (confirm) {
            const toForkJoin: Observable<any>[] = this.selectedArchiveable.map(r => {
              this.personSeriesCompareService.remove(r.series.personSeries);
              return this.personSeriesService.deletePeriods(r.series.personSeries);
            });

            forkJoin(toForkJoin.length ? toForkJoin : of([])).subscribe((data: any[]) => {
                this.toastr.success($localize`Datapoints archived`);
                this.reloadList();
                // this.notificationService.loadPendingPendingSeries();
              },
              (error) => {
                console.error(error);
                let msg = $localize`Error archiving datapoints`;
                const errorReason = ErrorResponseHelper.getErrorReason(error);
                if (errorReason) {
                  msg += '<br />' + errorReason;
                }
                this.toastr.error(msg, null, {  enableHtml: true, disableTimeOut: true });
              }
            );
          }
        }
      }
    });
  }
}
