import { Component, EventEmitter, Inject, Input, OnInit, Output, SimpleChanges, OnDestroy, OnChanges } from '@angular/core';
import { PeopleTrafficCounter } from '../../model/people-traffic-counter';
import {Subject} from 'rxjs';
import { MobilityConfig } from '../../mobility-config.model';
import { SMARTENCITY_MOBILITY_CONFIG } from '../../injection-tokens';
import moment, { Moment } from 'moment/moment';
import {
  ChartDataService,
  CommonHelper,
} from '@smartencity/core';
import { PeopleTrafficService } from '../../service/people-traffic.service';

interface ChartValue {
  title: string;
  height: number;
}


@Component({
  selector: '[people-traffic-modal]',
  templateUrl: './people-traffic-modal.component.html',
})
export class PeopleTrafficModalComponent implements OnInit, OnChanges, OnDestroy {
  private ngDestroy = new Subject<void>();

  @Input()
  peopleTraffic: any;

  @Output('modalClose')
  modalClose: EventEmitter<void> = new EventEmitter<void>();

  locationName: string;
  chartYLabel: string;
  chartValues: ChartValue[] = [];
  lastUpdatedAt: Date;
  dateNow: Date = new Date();

  constructor(
    @Inject(SMARTENCITY_MOBILITY_CONFIG) private config: MobilityConfig,
    private chartDataService: ChartDataService,
    private peopleTrafficService: PeopleTrafficService) {
  }

  ngOnInit() {
    this.updateSeries(this.peopleTraffic);
    this.locationName = this.peopleTraffic.readableAddress ? this.peopleTraffic.readableAddress : this.peopleTraffic.name;
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  close() {
    this.modalClose.next();
  }

  private updateSeries(counter: PeopleTrafficCounter): void {
    if (counter) {

      const dateTo = moment().subtract(1, 'hour');
      const dateFrom = moment(dateTo).subtract(25, 'hours');

      let params = {
        dateFrom: dateFrom.toISOString(),
        dateTo: dateTo.toISOString(),
        aggregationGroupingType: 'HOURLY'
      };

      this.peopleTrafficService.loadSeries(counter, params).subscribe((result: any) => {
        this.mapChartData(result, 'SUM', params, counter.differentiate);
      });
    } else {
      this.chartValues = [];
      this.lastUpdatedAt = null;
    }
  }

  private mapChartData(data: any, aggregationType: string, params: any, differentiate?: boolean) {
    this.lastUpdatedAt = null;

    if (!data) {
      this.chartValues = [];
      return;
    }

    const aggregationGroupingType = (params ? params.aggregationGroupingType : null);
    const mappedData = this.chartDataService.mapChartData(data, aggregationType, aggregationGroupingType, null, differentiate);

    // Last 12h
    const lastHoursData = mappedData.data.slice(Math.max(mappedData.data.length - 12, 0));

    let latestValue = null;
    if (lastHoursData.length > 0) {
      latestValue =  lastHoursData[lastHoursData.length - 1];
    }

    if (latestValue) {
      this.lastUpdatedAt = moment(latestValue.t).toDate();
    }

    this.replaceMissingAggregationValuesWithNulls(params, mappedData);

    const suggestedMin = (mappedData.min < 0 ? mappedData.min * 2 : 0);
    this.chartValues = lastHoursData.map(e => {
      let value = e.y;
      if (value != null) {
        value = Math.ceil(value);
      }

      let valueAsString = null;
      if (value != null) {
        valueAsString = CommonHelper.replaceThousandsSeparator(value.toString());
      }

      return {
        title: valueAsString + ' ' + moment(e.t).format('DD.MM.YYYY HH:mm'),
        time: moment(e.t).format('HH:mm'),
        peopleCount: valueAsString,
        height: 100 * (value - suggestedMin) / (mappedData.max - suggestedMin)
      };
    });

    if (this.chartValues.length === 1) {
      this.chartValues[0].height = 100;
    }
  }

  private replaceMissingAggregationValuesWithNulls(params: any, mappedData: any) {
    if (!params.aggregationGroupingType) {
      return;
    }

    let dateFrom = params.dateFrom;
    let dateTo = params.dateTo;

    // Difference tõttu +2 tundi algusest, sest taustal võetakse 1. väärtuse jaoks -1 tunni andmed
    let runningDate = moment(dateFrom).startOf('hour').add(2, 'hour'),
      endDate = moment(dateTo).startOf('hour');


    let runningIdx = 0;
    while (!runningDate.isAfter(endDate)) {
      let hasDataOnCurrentDate = false;
      mappedData.data.forEach((item, idx) => {
        if (runningDate.valueOf() <= item.t && item.t < runningDate.clone().add(1, 'hour').valueOf()) {
          hasDataOnCurrentDate = true;
          return;
        }
      });

      runningIdx = runningIdx + 1;

      if (!hasDataOnCurrentDate) {
        mappedData.data.splice(runningIdx, 0, {
          t: runningDate.valueOf(),
          y: null
        });
      }

      runningDate = runningDate.add(1, 'hour');
    }
  }

}
