<div #modalContent class="jumbotron floating-jumbotron content-wrapper" [class.active]="objects.length > 0">
  <div *ngIf="objects && objects.length > 0" class="modal-content-wapper">
    <div class="modal-header">
      <h5 class="modal-title w-100 d-flex align-items-center">
        <modal-nav [size]="objects.length" [(current)]="currentIndex"></modal-nav>
      </h5>
      <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
        <i class="fal fa-times"></i>
      </button>
    </div>

    <div class="modal-body">
      <div [class.wide]="objects[i].type == 'peopleTraffic'" class="object-wrapper" *ngFor="let series of objects; let i = index" [class.active]="i == currentIndex">
        <div class="object-modal-wrapper" *ngIf="objects[i].type == 'parkingLots'" arc-gis-feature-fields [data]="objects[i].data" layerTitle="Parkinglot" i18n-layerTitle></div>
        <div class="object-modal-wrapper" *ngIf="objects[i].type == 'parkingZones'" arc-gis-feature-fields [data]="objects[i].data" layerTitle="Parking zone" i18n-layerTitle></div>
        <div class="object-modal-wrapper" *ngIf="objects[i].type == 'healthTrails'" arc-gis-feature-fields [data]="objects[i].data" layerTitle="Health trail" i18n-layerTitle></div>
        <div class="object-modal-wrapper" *ngIf="objects[i].type == 'roadClosure'" road-closure-modal [roadClosure]="objects[i].data" layerTitle="Road closure" i18n-layerTitle></div>
        <div class="object-modal-wrapper" *ngIf="objects[i].type == 'peopleTraffic'" people-traffic-modal [peopleTraffic]="objects[i].data" layerTitle="People traffic" i18n-layerTitle></div>
        <div class="object-modal-wrapper" *ngIf="objects[i].type == 'residentialAreas'" arc-gis-feature-fields [data]="objects[i].data" layerTitle="Residential area" i18n-layerTitle></div>
        <div class="object-modal-wrapper" *ngIf="objects[i].type == 'cityDistricts'" arc-gis-feature-fields [data]="objects[i].data" layerTitle="City district" i18n-layerTitle></div>
      </div>
    </div>
  </div>
</div>
