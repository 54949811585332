<div class="list-item draggable" cdkDrag [formGroup]="row.control">
  <ng-container *ngIf="editName">
    <div class="form-row">
      <div class="col-6 d-flex align-items-center">
        <input class="form-control form-control-sm" formControlName="name">
      </div>
      <div class="col-auto">
        <button class="btn btn-icon" (click)="toggleEditName()"><i class="fal fa-save"></i></button>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!editName">
    <div class="form-row">
      <div class="col-auto w-100 pr-5 d-flex justify-content-between align-items-center">
        <div class="title-wrapper">
          <span class="title">{{row.control.get('name').value}}</span>
          <button class="btn btn-icon" (click)="toggleEditName()"><i class="fal fa-pencil"></i></button>
        </div>
      </div>
    </div>
  </ng-container>
  <div class="row">
    <span class="col-auto">
      <label class="light mr-2 mb-0" i18n>Type</label>
      <span class="text-light">
        <ng-container *ngIf="row.dataset.type == 'PERSON_SERIES'"><span i18n>Datapoint</span>&nbsp;
          <i *ngIf="row.dataset?.personSeries?.name !== null" class="fa fa-info-circle" tooltip="{{row.dataset.personSeries.name}}"></i></ng-container>
        <ng-container *ngIf="row.dataset.type == 'PERSON_PARAMETER'"><span i18n>Parameter</span>&nbsp;
          <i *ngIf="row.dataset?.personParameter?.name !== null" class="fa fa-info-circle" tooltip="{{row.dataset.personParameter.name}}"></i></ng-container>
        <ng-container *ngIf="row.dataset.type == 'Q11E_FIELD'"><span i18n>Question field</span>&nbsp;
          <i *ngIf="row.dataset?.q11eField?.name !== null" class="fa fa-info-circle" tooltip="{{row.dataset.q11eField.name}}"></i></ng-container>
        <ng-container *ngIf="row.dataset.type == 'Q11E_RESPONSE_FIELD'"><span i18n>Question response</span>&nbsp;
          <i *ngIf="row.dataset?.q11eResponseField?.field?.name !== null" class="fa fa-info-circle" tooltip="{{row.dataset.q11eResponseField.field.name}}"></i></ng-container>
      </span>
    </span>
    <span class="col-auto">
      <label class="light mr-2 mb-0" i18n>Location</label>
      <span class="text-light">
        <ng-container *ngIf="row.dataset.type == 'PERSON_SERIES'">{{row.dataset.location}}</ng-container>
        <ng-container *ngIf="row.dataset.type == 'Q11E_FIELD' || row.dataset.type == 'Q11E_RESPONSE_FIELD'">{{row.dataset?.q11eName}}</ng-container>
      </span>
    </span>
  </div>
  <div class="form-row mt-2" >
    <span class="col-auto d-flex">
      <label class="light mr-2 mb-0 d-flex align-items-center"><i class="fal fa-cubes"></i></label>
      <core-custom-dropdown-control [options]="aggregationTypeOptions" formControlName="aggregationType" placeholder="Aggregation type" i18n-placeholder></core-custom-dropdown-control>
    </span>

    <ng-container *ngIf="(widgetType == widgetTypeType.VALUE_TABLE || widgetType === widgetTypeType.TIME_FRAME_CHART) && row.dataset.type == 'PERSON_SERIES'">
      <span class="col-auto d-flex checkbox">
        <input type="checkbox" formControlName="differentiate" />
        <label i18n>Differentiate</label>
      </span>
    </ng-container>

    <ng-container *ngIf="widgetType == widgetTypeType.TIME_FRAME_CHART || widgetType == widgetTypeType.VALUE_CHART">
          <span class="col-auto d-flex" *ngIf="widgetType !== widgetTypeType.VALUE_CHART">
      <label class="light mr-2 mb-0 d-flex align-items-center"><i class="fal fa-chart-area"></i></label>
      <core-custom-dropdown-control [options]="graphTypeOptions" formControlName="graphType" placeholder="Graph type" i18n-placeholder></core-custom-dropdown-control>
    </span>
    <span class="col-auto d-flex checkbox" *ngIf="widgetType === widgetTypeType.VALUE_CHART && chartType === chartTypeType.BAR">
      <input type="checkbox" [formControl]="stackControl" />
      <label i18n>Stack</label>
    </span>
    <span class="col-auto d-flex">
      <label class="light mr-2 mb-0 d-flex align-items-center"><i class="fal fa-cubes"></i></label>
      <i class="fa fa-info-circle mt-1 mr-2 btn-tooltip" popover="Specify a group name to group multiple series to use a shared unit y-axis. To display multiple series as a stacked bar graph, you must specify a group for stacking." i18n-popover placement="top" triggers="mouseenter:mouseleave" ></i>
      <input type="text" class="form-control form-control-sm" placeholder="Group name" i18n-placeholder formControlName="groupName">
    </span>
      <span class="col-auto d-flex">
      <label class="light mr-2 mb-0 d-flex align-items-center"><i class="fal fa-ruler-combined"></i></label>
      <div class="custom-dropdown" dropdown [autoClose]="true" (click)="preventClose($event)">
        <button type="button" class="btn btn-link btn-sm dropdown-toggle" dropdownToggle i18n>
          <ng-container *ngIf="row.control.get('scaleMin').value == null && row.control.get('scaleMax').value == null">Set custom scale</ng-container>
          <ng-container *ngIf="row.control.get('scaleMin').value != null || row.control.get('scaleMax').value != null">Scale: {{row.control.get('scaleMin').value != null ? row.control.get('scaleMin').value : '...'}}&nbsp;-&nbsp;{{row.control.get('scaleMax').value != null ? row.control.get('scaleMax').value : '...'}}</ng-container>
        </button>
        <div class="dropdown-menu px-3 py-2" *dropdownMenu>
          <div class="form-group">
            <label [attr.for]="id+'-min'" i18n>Min</label>
            <input type="text" class="form-control" [class.is-invalid]="invalid('scaleMin')" formControlName="scaleMin" [id]="id+'-min'" placeholder="Min" i18n-placeholder>
          </div>
          <div class="form-group">
            <label [attr.for]="id+'-max'" i18n>Max</label>
            <input type="text" class="form-control" [class.is-invalid]="invalid('scaleMax')" formControlName="scaleMax" [id]="id+'-max'"  placeholder="Max" i18n-placeholder>
          </div>
        </div>
      </div>
    </span>
      <span class="col-auto d-flex">
      <label class="light mr-2 mb-0 d-flex align-items-center"><i class="fal fa-fill-drip"></i></label>
      <span [hidden]="!color" [style.background]="color" colorPicker (colorPickerChange)="colorPickerChange($event)" class="d-inline-block align-bottom" style="height: 20px; width: 20px;"></span>
      <button [hidden]="!color" class="btn btn-icon" (click)="clearCustomColor()"><i class="fal fa-times"></i></button>
      <button *ngIf="!color" type="button" class="btn btn-link btn-sm p-0" (click)="setCustomColor()">Set custom color</button>
    </span>
    </ng-container>

  </div>
  <span class="drag-handler" cdkDragHandle></span>
  <span class="remove-button">
     <button type="button" class="close" (click)="removeWidgetDataset()" tooltip="Remove" i18n-tooltip placement="auto">
      <span aria-hidden="true">&times;</span>
    </button>
  </span>
</div>
