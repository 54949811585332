import {UriBuilder} from '../../redirect/uri-builder';

export class TaraAuthLogoutUriBuilder implements UriBuilder {

  private baseUrl: string;

  private token: string;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  build(params: any): string {

    return `${this.baseUrl}/tara/logout?token=${params.token}&redirectTo=${decodeURIComponent(window.location.protocol + "//" + window.location.host)}`
  }

  setUrlParams(params: any): void {
  }

}
