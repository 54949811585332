import {OlMapWrapper, OlOverlayWrapper, OverlayWrapperOptions} from '@smartencity/core';
import { RoadClosure } from '../model/road-closure';
import { MobilityService } from '../service/mobility.service';

export class RoadClosureOlMarkerWrapper extends OlOverlayWrapper {

  public selected = false;
  private roadClosure: any;
  private mobilityService: MobilityService;

  constructor(roadClosure: RoadClosure, map: OlMapWrapper, opts: OverlayWrapperOptions, mobilityService: MobilityService) {
    super(opts);
    this.roadClosure = roadClosure;
    this.setMap(map.getMap());
    this.mobilityService = mobilityService;
  }

  public draw() {
    if (!this.div) {
      this.div = document.createElement('DIV');
      this.div.classList.add("pointer-road-closure");
      this.div.style.cursor = 'pointer';
      this.div.addEventListener('click', (e) => {
        this.mobilityService.selectRoadClosure(this.roadClosure, 'marker-select');
      });
    }


    if (this.selected) {
      this.div.classList.add("active");
    } else {
      this.div.classList.remove("active");
    }
  }
}
