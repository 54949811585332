import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SMARTENCITY_BUILDINGS_CONFIG} from '../injection-tokens';
import {BuildingsConfig, BuildingsDisplaySettings, MapsConfig} from '../buildings-config.model';
import {Subject} from 'rxjs/internal/Subject';
import {Building} from '../model/building';
import {BuildingsService} from './buildings.service';
import {WindTurbinesGmapLayer} from '../layers/wind-turbines-gmap-layer';
import {BuildingsGmapLayer} from '../layers/buildings-gmap-layer';
import {BuildingsCompareService, MyLocationButton, Position, UnitPipe} from '@smartencity/core';
import LatLngBounds = google.maps.LatLngBounds;
import {WindTurbine} from '../model/wind-turbine';
import {switchMap, first, Observable, withLatestFrom} from 'rxjs';
import {distinctUntilChanged, map, takeUntil} from 'rxjs/operators';
import {MapsGridArea} from '../model/crowd-insights/maps-grid-area';
import {CrowdInsightsGridAreasGmapLayer} from '../layers/crowd-insights-grid-areas-gmap-layer';

export interface GmapState {
  buildingsLoaded: boolean;
  layersLoaded: boolean;
}

// export function waitFor<T>(signal: Observable<any>) {
//   return (source: Observable<T>) => signal.pipe(
//     first(),
//     switchMap(_ =>  source)
//   );
// }

@Injectable()
export class BuildingsGmapService {

  private finalize$ = new Subject<void>();

  windTurbinesLayer: WindTurbinesGmapLayer = null;

  crowdInsightsGridAreasLayer: CrowdInsightsGridAreasGmapLayer = null;

  buildingsLayer: BuildingsGmapLayer = null;

  private map: google.maps.Map;

  public displaySettings: BuildingsDisplaySettings = <BuildingsDisplaySettings>{};

  private currentType: string;

  public locationSelect$ = new Subject<void>();

  private stateSource = new Subject<GmapState>();

  public stateDispatch$ = this.stateSource.asObservable().pipe(takeUntil(this.finalize$));

  public layersLoaded$ = this.stateDispatch$.pipe(map(state => state.layersLoaded), distinctUntilChanged());

  public buildingsLoaded$ = this.stateDispatch$.pipe(map(state => state.buildingsLoaded), distinctUntilChanged());

  private _state: GmapState = BuildingsGmapService.getDefaultState();

  private crowdInsightsGridAreaClickSource = new Subject<MapsGridArea>();


  private static getDefaultState(): GmapState {
    return {
      buildingsLoaded: false,
      layersLoaded: false
    }
  }

  private isLayersLoaded(): boolean {
    return this.buildingsLayer != null && this.windTurbinesLayer != null;
  }

  constructor(
    @Inject(SMARTENCITY_BUILDINGS_CONFIG) private config: BuildingsConfig,
    private buildingsService: BuildingsService,
    private http: HttpClient,
    private unitPipe: UnitPipe,
    public buildingsCompareService: BuildingsCompareService
  ) {
  }

  getMapsConfig(): MapsConfig {
    return this.config.map;
  }

  public reset(): void {
    this.finalize$.next();
    this._state = BuildingsGmapService.getDefaultState();
  }

  createMap(gmapElement: any): google.maps.Map {
    this.displaySettings = this.buildingsService.getDisplaySettings();
    const conf = this.getMapsConfig();
    const mapProp = {
      center: conf.center,
      zoom: conf.zoom,
      mapTypeControl: false,
      fullscreenControl: false,
      clickableIcons: false,
    };

    this.map = new google.maps.Map(gmapElement.nativeElement, mapProp);

    const style = [
      {
        stylers: [
          { saturation: -99 }
        ]
      }
    ];

    this.map.mapTypes.set('map-style', new google.maps.StyledMapType(style, {}));
    this.map.setMapTypeId('map-style');

    new MyLocationButton(this.map);

    this.initListeners();

    return this.map;
  }

  private initListeners(): void {
    this.buildingsService.buildings$.pipe(withLatestFrom(this.buildingsService.seriesTypeSelect$)).subscribe(([buildings, selectedSeriesType]: [Building[], string]) => {

      if (this.buildingsLayer == null) {
        this.buildingsLayer = new BuildingsGmapLayer({
          seriesType: selectedSeriesType,
          seriesValueFormatFn: (series: any) => {
            return (Math.round(series.value * 100) / 100) + ' ' + this.unitPipe.transform(series.unit, null);
          }
        }, this, this.buildingsCompareService);

        this.checkAndPublishLayersLoaded();
      }

      this.buildingsLayer.updateBuildings(buildings);
      this.buildingsLayer.showMarkers(this.map);

      if (!this._state.buildingsLoaded && buildings.length > 0) {
        this.setBuildingsLoaded(true);
      }
    });

    this.buildingsService.windTurbines$.subscribe((windTurbines: WindTurbine[]) => {
      if (this.windTurbinesLayer) {
        this.windTurbinesLayer.setMap(null);
        this.windTurbinesLayer = null;
      }
      this.windTurbinesLayer = new WindTurbinesGmapLayer(windTurbines);
      if (this.displaySettings.windTurbines?.active) {
        this.windTurbinesLayer.setMap(this.map);
      }

      this.checkAndPublishLayersLoaded();
    });

    this.buildingsService.crowdInsightGridAreas.subscribe((areas: MapsGridArea[]) => {
      if (this.crowdInsightsGridAreasLayer) {
        //TODO: clear??
        this.crowdInsightsGridAreasLayer.setMap(null);
        this.crowdInsightsGridAreasLayer = null;
      }

      this.crowdInsightsGridAreasLayer = new CrowdInsightsGridAreasGmapLayer(areas, this.crowdInsightsGridAreaClickSource);
      if (this.displaySettings.crowdInsights?.active) {
        this.crowdInsightsGridAreasLayer.setMap(this.map);
      }
    });

    this.crowdInsightsGridAreaClickSource.subscribe((gridArea: MapsGridArea) => {
      this.buildingsService.selectCrowdInsightGridArea(gridArea);
    });

    this.buildingsService.displaySettings$.subscribe((displaySettings: BuildingsDisplaySettings) => {
      this.displaySettings = displaySettings;
      this.updateDisplay();
    });

    this.buildingsService.buildingSelected$.subscribe((building: Building) => {
      //console.log('buildingSelected$');
      this.showBuilding(building);
    });

    this.buildingsService.windTurbineSelected$.subscribe((windTurbine: WindTurbine) => {
      //console.log('windTurbineSelected$');
      this.showWindTurbine(windTurbine);
    });

    this.buildingsService.seriesTypeSelect$.subscribe((selectedSeriesType: string) => {
      //console.log("seriesTypeSelect$", selectedSeriesType);
      this.buildingsLayer.updateSeriesType(selectedSeriesType);
    });

    this.buildingsLoaded$.subscribe(() => {
      this.panMap();
    });

    // this.layersLoaded$.subscribe(() => {
    //   console.log("layers loaded");
    // });
  }

  updateDisplay(): void {
    if (this.windTurbinesLayer) {
      this.windTurbinesLayer.setMap(this.displaySettings.windTurbines?.active ? this.map : null);
    }

    if (this.crowdInsightsGridAreasLayer) {
      this.buildingsService.selectCrowdInsightGridArea(null); //kas õige koht?
      this.crowdInsightsGridAreasLayer.setMap(this.displaySettings.crowdInsights?.active ? this.map : null);
    }
  }

  showBuilding(building: Building): void {
    this.buildingsLayer.showBuilding(building);
    this.focusMarker(building);
  }

  showWindTurbine(windTurbine: WindTurbine): void {
    this.windTurbinesLayer.showWindTurbine(windTurbine);
    this.focusMarker(windTurbine);
  }

  focusMarker(position: Position) {
    if (position) {
      this.map.setCenter(new google.maps.LatLng(position.lat, position.lng));
      this.map.setZoom(12);
    }
  }

  panMap() {
    if (this.buildingsLayer.buildings.length == 0) {
      return;
    }

    let latMin = 180;
    let latMax = -180;
    let lngMin = 180;
    let lngMax = -180;
    for (const building of this.buildingsLayer.buildings) {
      latMin = building.lat < latMin ? building.lat : latMin;
      latMax = building.lat > latMax ? building.lat : latMax;
      lngMin = building.lng < lngMin ? building.lng : lngMin;
      lngMax = building.lng > lngMax ? building.lng : lngMax;
    }
    this.map.fitBounds(new LatLngBounds(new google.maps.LatLng(latMin, lngMin), new google.maps.LatLng(latMax, lngMax)), 35);
  }

  onBuildingClick(building: Building): void {
    this.buildingsService.selectBuilding(building);
    this.locationSelect$.next();
  }

  private checkAndPublishLayersLoaded(): void {
    if (this.isLayersLoaded()) {
      this.setLayersLoadedState(true);
    }
  }

  private setLayersLoadedState(layersLoaded: boolean): void {
    this.stateSource.next(this._state = {
      ...this._state,
      layersLoaded
    });
  }

  private setBuildingsLoaded(buildingsLoaded: boolean): void {
    this.stateSource.next(this._state = {
      ...this._state,
      buildingsLoaded
    });
  }

}
