import {Component, OnDestroy, OnInit} from '@angular/core';
import {SearchResults} from '../models/search-results.model';
import {ServicesService} from '../services.service';
import {FormControl} from '@angular/forms';
import {debounceTime, distinctUntilChanged, map, shareReplay, takeUntil} from 'rxjs/operators';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';
import {Service, Services} from '../models/services.model';
import {Subject} from 'rxjs/internal/Subject';

@Component({
  selector: 'services-search',
  templateUrl: './search.component.html'
})
export class SearchComponent implements OnInit, OnDestroy {
  private ngDestroy = new Subject<void>();

  public searchControl = new FormControl(null);
  public results$ = combineLatest([this.servicesService.services$, this.searchControl.valueChanges.pipe(
    takeUntil(this.ngDestroy),
    distinctUntilChanged(),
    debounceTime(250)
  )]).pipe(map(([services, search]: [Services, string]) => {
    if (services == null || !search) {
      return null;
    }
    const results = new SearchResults();
    results.toilets = [];
    results.parcelTerminals = [];
    results.evChargers = [];
    results.windTurbines = [];

    for (const item of services.toilets) {
      if (item.street.toLowerCase().search(search.toLowerCase()) > -1) {
        results.toilets.push(item);
      }
    }

    for (const item of services.parcelTerminals) {
      if (item.name.toLowerCase().search(search.toLowerCase()) > -1 || item.address1.toLowerCase().search(search.toLowerCase()) > -1) {
        results.parcelTerminals.push(item);
      }
    }

    for (const item of services.evChargers) {
      if (item.uuid.toLowerCase().search(search.toLowerCase()) > -1 || item.name.toLowerCase().search(search.toLowerCase()) > -1 || item.address.toLowerCase().search(search.toLowerCase()) > -1) {
        results.evChargers.push(item);
      }
    }

    for (const item of services.windTurbines) {
      if (item.owner.toLowerCase().search(search.toLowerCase()) > -1 || item.id.toLowerCase().search(search.toLowerCase()) > -1) {
        results.windTurbines.push(item);
      }
    }

    if (!results.toilets.length && !results.parcelTerminals.length && !results.windTurbines.length) {
      return null;
    }
    return results;
  }), shareReplay(1));

  constructor(private servicesService: ServicesService) {
  }

  clear() {
    this.searchControl.setValue(null);
  }

  ngOnInit() {}

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  selectMarker(service: Service) {
    this.clear();
    this.servicesService.selectedService$.next(service);
  }
}
