import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationsBtnComponent } from './notifications-btn/notifications-btn.component';
import { NotificationsModalComponent } from './notifications-modal/notifications-modal.component';
import { PersonSeriesRowComponent } from './person-series-row/person-series-row.component';
import { PersonSeriesMetaRowComponent } from './person-series-meta-row/person-series-meta-row.component';
import { ErrorRowComponent } from './error-row/error-row.component';
import {NotificationService} from './notification.service';
import {LaddaModule} from 'angular2-ladda';
import {ModalModule} from 'ngx-bootstrap/modal';
import {RouterModule} from '@angular/router';
import {PendingGroupConsentRow} from './pending-group-consent-row/pending-group-consent-row.component';
import { CancellationRowComponent } from './cancellation-row/cancellation-row.component';


@NgModule({
  declarations: [
    NotificationsBtnComponent,
    NotificationsModalComponent,
    PersonSeriesRowComponent,
    PersonSeriesMetaRowComponent,
    ErrorRowComponent,
    PendingGroupConsentRow,
    CancellationRowComponent
  ],
  providers: [
    NotificationService
  ],
  imports: [
    CommonModule,
    RouterModule,
    ModalModule,
    LaddaModule,
  ],
  exports: [
    NotificationsBtnComponent
  ]
})
export class NotificationModule { }
