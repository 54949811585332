import {RedirectStrategy} from '../redirect/redirect-strategy';
import {NoopRedirectStrategy} from '../redirect/noop-redirect-strategy';
import {AuthParams} from '@smartencity/core';

export abstract class IdpAuthService {

  protected loginRedirectStrategy: RedirectStrategy;

  protected logoutRedirectStrategy: RedirectStrategy;

  protected constructor() { }

  public abstract login(params: AuthParams): Promise<boolean>;

  public abstract logout(params?: any): Promise<boolean>;

  public abstract getName(): string;

  protected doLogin(params?: any): Promise<boolean> {
    if (this.loginRedirectStrategy) {
      return this.loginRedirectStrategy.redirect(params);
    }

    return new NoopRedirectStrategy().redirect(params);
  }

  protected doLogout(params?: any): Promise<boolean> {
    if (this.logoutRedirectStrategy) {
      return this.logoutRedirectStrategy.redirect(params);
    }

    return new NoopRedirectStrategy().redirect(params);
  }

}
