import {ActivityType} from './activity-type';
import {DurationGroupType} from './duration-group-type';

export class VisitDaily {
  batchDate: Date;
  aohAreaCode: number
  aohGridName: string;
  people: number;
  activityType: ActivityType;
  areaCode: number;
  gridName: string;
  adminLevel1: string;
  adminLevel1Code: string;

  //TODO: adminLevel2 etc

  constructor(props?: {
    aohAreaCode: number,
    aohGridName: string,
    areaCode: number,
    gridName: string;
    batchDate: Date,
    people: number;
    activityType: ActivityType,
    adminLevel1: string,
    adminLevel1Code: string
  }) {
    if (!props) return;
    this.aohAreaCode = props.aohAreaCode;
    this.aohGridName = props.aohGridName;
    this.areaCode = props.areaCode;
    this.gridName = props.gridName;
    this.batchDate = props.batchDate;
    this.people = props.people;
    this.activityType = props.activityType;
    this.adminLevel1 = props.adminLevel1;
    this.adminLevel1Code = props.adminLevel1Code;

  }
}
