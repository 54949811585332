import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {CoreModule} from '@smartencity/core';
import {PublicDataComponent} from './publicdata.component';
import { HttpClientModule } from '@angular/common/http';
import { GridsterModule } from 'angular-gridster2';
import { DashboardModule } from 'projects/mydata/src/lib/components/dashboard/dashboard.module';
import { RouterModule } from '@angular/router';
import { PublicDataService } from './publicdata.service';
import {SharedModule} from '@smartencity/shared';

@NgModule({
  declarations: [PublicDataComponent],
  imports: [
    HttpClientModule,
    CommonModule,
    FormsModule,
    GridsterModule,
    DashboardModule,
    RouterModule,
    CoreModule,
    SharedModule
  ],
  providers: [
    PublicDataService
  ],
  exports: [PublicDataComponent]
})
export class PublicDataModule { }
