import { Injectable } from '@angular/core';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import {PersonSeriesGroup} from '@smartencity/core';
import { Observable, of } from 'rxjs';
import {PersonSeriesGroupApiService} from '../http/person-series-group-api.service';

@Injectable()
export class PersonSeriesGroupResolver implements Resolve<PersonSeriesGroup> {

  constructor(private personSeriesGroupService: PersonSeriesGroupApiService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<PersonSeriesGroup> {
    return this.personSeriesGroupService.getGroup(+route.paramMap.get("groupId"));
  }

}
