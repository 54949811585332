import {Inject, Injectable, OnDestroy} from '@angular/core';
import {AuthService, ContractInfo, Tenant, TenantPage} from '@smartencity/core';
import {HttpClient} from '@angular/common/http';
import {SMARTENCITY_MYDATA_CONFIG} from '../injection-tokens';
import {MyDataConfig} from '../mydata-config.model';
import {Observable} from 'rxjs/internal/Observable';
import {Subject} from 'rxjs/internal/Subject';
import {shareReplay, takeUntil} from 'rxjs/operators';
import {TenantContractRequest} from '../models/tenant/tenant-contract-request';

@Injectable()
export class TenantService implements OnDestroy {
  private ngDestroy = new Subject<void>();

  private tenantsSource = new Subject<Tenant[]>();

  public tenants$ = this.tenantsSource.asObservable().pipe(shareReplay(1));

  constructor(
    @Inject(SMARTENCITY_MYDATA_CONFIG) private config: MyDataConfig,
    public authService: AuthService,
    private http: HttpClient,
  ) {
    this.tenants$.pipe(takeUntil(this.ngDestroy)).subscribe();
    authService.currentMandate$.pipe(takeUntil(this.ngDestroy)).subscribe((data: any) => {
      this.loadTenants();
    });
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  loadTenants() {
    this.http.get(this.config.apiUrl + '/tenant', {}).subscribe(((data: TenantPage) => {
      this.tenantsSource.next(data.content);
    }));
  }

  getContractInfo(): Observable<ContractInfo> {
    const params: any = {};
    return this.http.get<ContractInfo>(this.config.apiUrl + '/tenant/contract-info', {params: params});
  }

  createContractTenant(requestBody: TenantContractRequest): Observable<Tenant> {
    return this.http.post<Tenant>(this.config.apiUrl + '/tenant/contract-tenant', requestBody);
  }

  createTenantRequest(): Observable<void> {
    const params: any = {};
    return this.http.post<void>(this.config.apiUrl + '/tenant/request-tenant', {params: params});
  }
}
