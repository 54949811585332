import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {NgSelectModule} from '@ng-select/ng-select';
import {PersonSeriesGroupSelectComponent} from './person-series-group-select/person-series-group-select.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

@NgModule({
  declarations: [
    PersonSeriesGroupSelectComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule
  ],
  exports: [
    PersonSeriesGroupSelectComponent
  ]
})
export class MyDataSharedModule { }
