<td *ngIf="config.modules.publicdata && isCityUser" class="p-0 col-1 d-flex justify-content-start align-items-center">
  <div class="checkbox" *ngIf="personDashboard.ownerType === OwnerType.OWNER">
    <input type="checkbox" [attr.disabled]="!isEdit ? true : null" class="form-control mb-0 mt-2 h-auto mr-2" [formControl]="publicDashboardFormControl" />
  </div>
</td>
<td *ngIf="config.modules.publicdata && isCityUser" class="p-0" style="width: 50px">
  <div class="position-relative d-flex justify-content-center align-items-center">
    <div class="checkbox mt-2" *ngIf="personDashboard.ownerType === OwnerType.OWNER">
      <input type="checkbox" [attr.disabled]="!isEdit ? true : null" class="form-control mb-0 mt-1 h-auto mr-2" [formControl]="publicDashboardAllowDownloadFormControl" />
    </div>
  </div>
</td>
<td class="p-0 w-50">
  <div class="d-flex align-items-start mb-3">
    <dashboard-icon-owner tooltip="Owner" i18n-tooltip *ngIf="personDashboard.dashboard.id && personDashboard.ownerType === OwnerType.OWNER || !personDashboard.dashboard.id && isEdit"></dashboard-icon-owner>
    <dashboard-icon-shared tooltip="Shared by {{personDashboard.ownerPerson?.displayName}}" i18n-tooltip *ngIf="personDashboard.ownerType === OwnerType.SHARED"></dashboard-icon-shared>

    <div class="dashboard-name-wrapper w-100">
      <input 
        *ngIf="isEdit" 
        [class.is-invalid]="submitted && nameFormControl.errors && nameFormControl.errors.required" 
        type="text" 
        class="form-control" 
        [formControl]="nameFormControl"
      >
      <small *ngIf="submitted && nameFormControl.errors && nameFormControl.errors.required" class="invalid-feedback"i18n>Required</small>
      <ng-container *ngIf="!isEdit"><span>{{personDashboard.dashboard.name}}</span></ng-container>
    </div>
  </div>
</td>
<td class="p-0 text-right">
  <share-person-item-list (click)="share()" class="d-inline-block" *ngIf="dashboardConsents" [persons]="dashboardConsents" tooltip="Shared data" i18n-tooltip></share-person-item-list>
</td>
<td class="p-0 text-right d-flex justify-content-end">
  <button *ngIf="isEdit" class="btn btn-success btn-sm btn-circle shadow-none" (click)="save()" [disabled]="progress" tooltip="Save" i18n-tooltip>
    <i class="fal fa-check"></i>
  </button>
  <button *ngIf="!isEdit && personDashboard.ownerType === OwnerType.OWNER && personDashboard.dashboard.id" class="btn btn-sm btn-circle btn-primary shadow-none" (click)="edit()" tooltip="Edit" i18n-tooltip>
    <i class="fal fa-pencil"></i>
  </button> 
  <button *ngIf="personDashboard.dashboard.id && personDashboard.ownerType === OwnerType.OWNER" class="btn btn-sm btn-circle btn-primary shadow-none" (click)="share()" tooltip="Share" i18n-tooltip>
    <i class="fal fa-share"></i>
  </button>  
  <button *ngIf="personDashboard.ownerType === OwnerType.OWNER" (click)="remove()" type="button" class="btn btn-sm btn-circle btn-secondary mt-1 shadow-none" tooltip="Remove" i18n-tooltip>
    <i class="fa fa-times"></i>
  </button>
</td>
