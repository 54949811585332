<mat-form-field class="ngx-mat-drp-date-display">
  <input class="ngx-mat-drp-date-input"
         matInput [placeholder]="options.placeholder"
         [value]="selectedDateRange"
         [matTooltip]="selectedDateRange"
         (click)="openCalendar($event)"
         readonly
         #calendarInput>
  <div matSuffix class="suffix-icon active" style="display: inline-block;" (click)="clear()" *ngIf="options?.clearable && range?.fromDate && range?.toDate">
    <i class="fal fa-times"></i>
  </div>
  <div matSuffix class="suffix-icon active" style="display: inline-block;" (click)="openCalendar($event)">
    <i class="fal fa-calendar-alt"></i>
  </div>
</mat-form-field>
