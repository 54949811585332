import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dashboard-icon-owner',
  templateUrl: './icon-owner.component.svg'
})
export class IconOwnerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
