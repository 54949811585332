import {Component, Inject, Input, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {ToastrService} from 'ngx-toastr';
import {Dashboard, DashboardType, OwnerType, PersonDashboard} from '../dashboard';
import {AuthService, CoreConfig, Mandate, SMARTENCITY_CORE_CONFIG} from '@smartencity/core';
import { DashboardApiService } from '../../../http/dashboard-api.service';
import { takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs/internal/Subject';

@Component({
  selector: 'app-dashboard-management',
  templateUrl: './dashboard-management.component.html'
})
export class DashboardManagementComponent implements OnInit {
  private ngDestroy = new Subject<void>();

  @Input()
  public callback: (result: boolean) => void;

  public personDashboards: PersonDashboard[] = null;
  public dashboardsUpdated = false;

  public isCityUser = false;
  public loading = false;

  constructor(
    public modalRef: BsModalRef,
    public toastr: ToastrService,
    private authService: AuthService,
    private dashboardApiService: DashboardApiService,
    @Inject(SMARTENCITY_CORE_CONFIG) public config: CoreConfig,
  ) {
    this.authService.currentMandate$.pipe(takeUntil(this.ngDestroy)).subscribe((mandate: Mandate) => {
      this.isCityUser = this.config.cityRegistrationCode === mandate.person.registrationNumber;
    });
  }

  ngOnInit(): void {
    this.loading = true;

    this.dashboardApiService.getDashboardOptions().subscribe((value) => {
      this.personDashboards = value.filter(value1 => value1.dashboard.type == DashboardType.CUSTOM);
      this.loading = false;
    });
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  addDashboard() {
    const personDashboard = new PersonDashboard();
    personDashboard.dashboard = new Dashboard();
    personDashboard.ownerType = OwnerType.OWNER;
    this.personDashboards.push(personDashboard);
  }

  dashboardRemoved(dashboard: PersonDashboard) {
      this.personDashboards.splice(this.personDashboards.indexOf(dashboard),1);
      this.dashboardsUpdated = true;
  }

  dashboardSaved(dashboard: PersonDashboard) {
    this.dashboardsUpdated = true;
  }

  close() {
    if (this.callback) {
      this.callback(this.dashboardsUpdated);
    }
    this.modalRef.hide();
  }
}
