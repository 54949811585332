import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs/internal/Subject';
import {AuthService, UserService} from '@smartencity/core';
import {ToastrService} from 'ngx-toastr';
import {BsModalRef} from 'ngx-bootstrap/modal';

const selector = 'mydata-restore-modal';
let nextId = 0;

@Component({
  selector: selector,
  templateUrl: './restore-modal.component.html',
  styleUrls: ['./restore-modal.component.css']
})
export class RestoreModalComponent implements OnInit, OnDestroy {
  id = `${selector}-${nextId++}`;
  private ngDestroy = new Subject<void>();

  public restoreForm = new FormGroup({
    restoreToken: new FormControl('', [Validators.required])
  });

  constructor(
    private modalRef: BsModalRef,
    private userService: UserService,
    private toastr: ToastrService,
    public authService: AuthService
  ) { }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  ngOnInit(): void {
  }

  close(): void {
    this.modalRef.hide();
  }

  confirmAccountRestore() {
    this.restoreForm.updateValueAndValidity();
    if (this.restoreForm.valid) {
      this.userService.restoreAccount(this.restoreForm.getRawValue()).subscribe(() => {
        this.toastr.success('Account data restored!', 'Success');
        this.modalRef.hide();
      }, (err) => {
        this.toastr.success(err, 'Error');
      });
    } else {
      console.log('invalid', this.restoreForm);
    }
  }
}
