import {AbstractControl, FormGroup} from '@angular/forms';
import {Observable} from 'rxjs/internal/Observable';
import {Injectable} from '@angular/core';

@Injectable()
export abstract class AddressService {
  inputFormatter;
  resultFormatter;
  searching$: Observable<boolean>;
  addressSelect$: Observable<any>;
  abstract initForm(form: FormGroup);
  abstract getForm(): FormGroup;
  abstract getAddressControl(): AbstractControl;
  abstract getApartmentControl(): AbstractControl;
  abstract getRoomControl(): AbstractControl;
  abstract addressTypeahead(text$: Observable<string>);
  abstract apartmentTypeahead(text$: Observable<string>);
  abstract selectAddress(addressItem: any);
}
