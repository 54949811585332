import {Component, Inject, Input, ViewChild} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SMARTENCITY_MYDATA_CONFIG} from '../../injection-tokens';
import {MyDataConfig} from '../../mydata-config.model';
import {BsModalRef} from 'ngx-bootstrap/modal';

@Component({
  selector: 'mydata-owner-history-modal',
  templateUrl: './owner-history-modal.component.html'
})
export class OwnerHistoryModalComponent {
  epoch = new Date(0);

  @Input()
  list = [];

  constructor(
    private http: HttpClient,
    @Inject(SMARTENCITY_MYDATA_CONFIG) private config: MyDataConfig,
    private modalRef: BsModalRef
  ) {

  }

  public close() {
    this.modalRef.hide();
  }

  public isEpoch(dateStr: string) {
    const date = new Date(dateStr);
    if (date.getTime() === this.epoch.getTime()) {
      return true;
    }
    return false;
  }
}
