import {PeopleTrafficCounter} from '../model/people-traffic-counter';
import {Observable} from 'rxjs';
import {ListResponse} from '@smartencity/core';

export abstract class PeopleTrafficDataService {

  abstract loadSeries(counter: PeopleTrafficCounter, params?: any): Observable<any>;

  abstract getPeopleTraffic(): Observable<ListResponse<PeopleTrafficCounter>>;

}
