<div class="modal-header">
  <h5 class="modal-title w-100" i18n>Access grants to this data</h5>
  <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
    <i class="fal fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <div [formGroup]="consentForm">
    <div class="row" *ngIf="errors.length > 0">
      <div class="col">
        <div class="alert alert-danger">
          <ul>
            <li *ngFor="let errorMessage of errors">{{errorMessage}}</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="d-block">
        <div *ngIf="consentForm.get('person').invalid && showValidation" class="alert alert-danger">
          <div *ngIf="consentForm.get('person').errors['required']">
            <span i18n>Personal identification or registration code</span>&nbsp;<span i18n>is required</span>
          </div>
          <div *ngIf="consentForm.get('person').errors['personRegistrationNumber']">
            <span i18n>Personal identification or registration code</span>&nbsp;<span i18n>is invalid</span>
          </div>
        </div>

        <div class="form-group d-block d-md-flex">
          <div class="col">
            <ng-select
              class="custom"
              [clearable]="true"
              [searchable]="true"
              placeholder="Personal identification or registration code"
              i18n-placeholder
              appendTo="body"
              formControlName="person"
              [typeahead]="personTypeahead$"
              [items]="personItems$ | async"
              [addTag]="createPerson"
              [selectOnTab]="true"
              addTagText="New person with registration number"
              i18n-addTagText
            >
              <ng-template ng-label-tmp let-item="item">
                <span>{{(item.displayName ? '' + item.displayName + ' ' : '') + '(' + (item | regNo) + ')'}}</span>
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
                <span>{{(item.displayName ? '' + item.displayName + ' ' : '') + '(' + (item | regNo) + ')'}}</span>
              </ng-template>
            </ng-select>
          </div>
        </div>
      </div>
    </div>

    <div class="row my-2">
      <ng-container>
        <div class="col-4">
          <datepicker-wrapper [id]="'last-data-owner-start'"
                              [control]="consentForm.get('customStartAt')"></datepicker-wrapper>
        </div>

      </ng-container>
      <div class="col-auto d-flex align-items-center mt-2 mt-md-0">
        <button class="btn btn-sm btn-primary" (click)="createConsents()">
          <i class="fal fa-share"></i>&nbsp;<ng-container i18n>Share</ng-container>
        </button>
      </div>
    </div>

    <div class="row">
      <ul>
        <li *ngFor="let series of seriesList">
          {{series.personSeries.name}},
          <ng-container *ngIf="series.personSeries.locationType == 'UUID'">
            {{series.personSeries.uuid}}
          </ng-container>
          <ng-container *ngIf="series.personSeries.locationType == 'COORDS'">
            {{series.personSeries.lat}}&nbsp;&nbsp;{{series.personSeries.lng}}
          </ng-container>
          <ng-container *ngIf="series.personSeries.locationType == 'ADDRESS'">
            {{series.personSeries.address}} {{series.personSeries.apartment}} {{series.personSeries.room}}
          </ng-container>
        </li>
      </ul>
    </div>
  </div>
</div>

