<ng-template #navMenu>
  <ul class="navbar-nav mr-auto">
    <li class="nav-item"><a routerLink="/mydata/datapoints" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" i18n class="nav-link">Data points</a></li>
    <li class="nav-item" *ngIf="(componentsConfig.canLoad('managed-sources')|async)"><a routerLink="/mydata/datapoints/managed-sources" routerLinkActive="active" i18n class="nav-link">Managed sources</a></li>
    <li class="nav-item" *ngIf="(componentsConfig.canLoad('datapoints.parameters')|async)"><a routerLink="/mydata/datapoints/parameters" routerLinkActive="active"  i18n class="nav-link">Manage parameters</a></li>
    <li class="nav-item" *ngIf="(componentsConfig.canLoad('datapoints.create')|async)"><a routerLink="/mydata/datapoints/create" routerLinkActive="active" i18n class="nav-link">Create datapoints</a></li>
    <!--<li class="nav-item"><a routerLink="/mydata/groups" routerLinkActive="active" class="dropdown-item" i18n>Datapoint groups</a></li>-->
    <!--<li class="nav-item"><a routerLink="/mydata/formula-template" routerLinkActive="active" class="dropdown-item" i18n>Formula templates</a></li>-->
  </ul>
</ng-template>
<router-outlet></router-outlet>
