<div class="series" *ngIf="showData()">
  <span *ngFor="let cityPortalType of cityPortalTypes">
    <span class="series-type" [ngClass]="cityPortalTypeData[cityPortalType].style" *ngIf="cityPortalTypeData[cityPortalType].series"
            [tooltip]="cityPortalTypeData[cityPortalType].series.description">
      <h4>{{cityPortalTypeData[cityPortalType].label}}</h4>
      <span class="icon" [ngClass]="cityPortalTypeData[cityPortalType].iconStyle"></span>
      <span>{{cityPortalTypeData[cityPortalType].series.value | number:'.0-0'}} {{cityPortalTypeData[cityPortalType].series.unit}}</span>
    </span>
  </span>
</div>
