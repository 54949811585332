import { AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { TallinnArcGisMapService } from 'projects/arc-gis/src/lib/tallinn-arc-gis-map.service';
import { Subject } from 'rxjs/internal/Subject';
import { AirQualityStation } from '../../model/air-quality-station';
import { TallinnService } from '../../service/tallinn.service';
import { takeUntil } from 'rxjs/operators';
import { TallinnOlMapService } from "../../service/tallinn-ol-map.service";
import PerfectScrollbar from 'perfect-scrollbar';

@Component({
  selector: 'tallinn-objects-modal',
  templateUrl: './tallinn-objects-modal.component.html',
})
export class TallinnObjectsModalComponent implements OnInit, AfterViewInit {

  private ngDestroy = new Subject<void>();

  @ViewChild('modalContent') modalContent: ElementRef;

  public objects: any[] = [];

  airQualityStation: AirQualityStation;

  @Output('modalClose')
  modalClose: EventEmitter<void> = new EventEmitter<void>();

  public currentIndex: number = 0;

  constructor(
    private tallinnArcGisMapService: TallinnArcGisMapService,
    private tallinnMapService: TallinnOlMapService,
    public tallinnService: TallinnService) {
  }

  ngOnInit() {
    this.tallinnService.airQualityStationSelected$.subscribe((station: AirQualityStation) => {
      this.airQualityStation = station;
      let index = this.objects.findIndex((o) => o.type == 'airQualityStation');
      if (index > -1) {
        this.objects.splice(index, 1);
      }
      if (this.airQualityStation !== null) {
        this.objects.push({ type: 'airQualityStation', data: this.airQualityStation });
      }
      this.resetIndex();
    });

    this.tallinnService.locationsSelected$.pipe(takeUntil(this.ngDestroy)).subscribe((locations: any[]) => {
      //layers that are in every module e.g residentialAreas and cityDistricts
      let layerTypes = Array.from(this.tallinnArcGisMapService.layersMap.keys());
      //additional layers only applied to this module
      let newLayerTypes = Array.from(this.tallinnMapService.arcGisMapLayers.keys());

      if (newLayerTypes.length > 0) {
        layerTypes = layerTypes.concat(newLayerTypes);
      }

      layerTypes.forEach((layerType) => {
        let idx = this.objects.findIndex((o) => o.type == layerType);
        if (idx > -1) {
          this.objects.splice(idx, 1);
        }
      });

      if (locations) {
        for (let location of locations) {
          this.objects.push({ type: location.layerName, data: location.data });
        }
      }
      this.resetIndex();
    });
  }

  resetIndex() {
    this.currentIndex = 0;
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  close() {
    this.modalClose.next();
  }

  ngAfterViewInit() {
    new PerfectScrollbar(this.modalContent.nativeElement);
  }
}
