import { Injectable } from '@angular/core';
import {NavigationEnd, Router, RouterEvent} from '@angular/router';
import {filter} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AfterLoginRedirectStateService {

  doAfterLoginRedirect = false;

  private urlPaths: string[] = [
    '/mydata/dashboard',
    '/mydata/energy-manager'
  ];

  constructor(private router: Router) {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((result: any) => {
      let contains = false;
      for (const urlPath of this.urlPaths) {
        if (result.urlAfterRedirects.includes(urlPath)) {
          contains = true;
        }
      }

      if (!contains) {
        this.doAfterLoginRedirect = false;
      }

    });
  }
}
