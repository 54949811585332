import {Inject, Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {SMARTENCITY_CORE_CONFIG} from '../injection-tokens';
import {CoreConfig} from '../core-config.model';
import { MandateConsent } from '../models/profile';

@Injectable({
  providedIn: 'root'
})
export class ConsentService {

  constructor(
    @Inject(SMARTENCITY_CORE_CONFIG) private config: CoreConfig,
    private http: HttpClient
  ) {
  }

  setConsent(personSeriesId: number, setConsentDto: any) {
    return this.http.post(this.config.apiUrl + '/person-series/' + personSeriesId + '/consent', setConsentDto);
  }

  endConsent(consent: {personSeriesId: number, id: number}, updateConsentDto?: { endAt: Date }) {
    if (!updateConsentDto) updateConsentDto = { endAt: new Date() };

    return new Observable(observer => {
      this.http.put(this.config.apiUrl + '/person-series/' + consent.personSeriesId + '/consent/' + consent.id, updateConsentDto).subscribe(
        (data: any) => {
          return observer.next(data);
        }, err => {
          return observer.error(err);
        });
    });
  }

  public endBulkDatapointConsents(data: {
    mandate: MandateConsent,
    consents: {id: number, periodId: number}[],
    isExcludeIds?: boolean
  }): Observable<any> {
    let request = {
      mandate: data.mandate,
      endAt: new Date(),
      personSeriesList: data.consents,
      isExcludeIds: data.isExcludeIds
    };
    return this.http.put<any>(this.config.apiUrl + '/person-series/consent/status', request);
  }

}
