import { AbstractMapLayerMarker, OlMapWrapper } from '@smartencity/core';
import { PeopleTrafficCounter } from '../model/people-traffic-counter';
import { PeopleTrafficOlMarkerWrapper } from './people-traffic-ol-marker-wrapper';
import {PeopleTrafficService} from '../service/people-traffic.service';

export class PeopleTrafficOlMarker extends AbstractMapLayerMarker<OlMapWrapper, PeopleTrafficCounter>{

  private marker: PeopleTrafficOlMarkerWrapper;

  constructor(peopleTrafficPointer: PeopleTrafficCounter, map: OlMapWrapper, peopleTrafficService: PeopleTrafficService) {
    super(peopleTrafficPointer);
    this.marker = new PeopleTrafficOlMarkerWrapper(peopleTrafficPointer, map, peopleTrafficService);
  }

  draw() {
    this.marker.draw();
  }

  setMap(map: OlMapWrapper) {
    super.setMap(map);
    const olMap = (map ? map.getMap() : null);
    this.marker.setMap(olMap);
  }

  setSelected(selected: boolean): void {
    this.marker.selected = selected;
  }

  isSelected(): boolean {
    return this.marker.selected;
  }
}
