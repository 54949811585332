import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, ReplaySubject} from 'rxjs';
import {CoreConfig, KeywordMatcher, PageResponse, SMARTENCITY_CORE_CONFIG} from '@smartencity/core';
import {Subject} from 'rxjs/internal/Subject';
import { BuildingsConfig, BuildingsDisplaySettings } from '../buildings-config.model';
import { EecBuilding } from '../model/eec-building';
import { SMARTENCITY_BUILDINGS_CONFIG } from '../injection-tokens';
import {BuildingDataPoint} from '../model/building-data-point';
import {WindTurbine} from '../model/wind-turbine';
import {distinctUntilChanged, map, takeUntil, shareReplay} from 'rxjs/operators';
import {Building} from '../model/building';
import {MapsGridArea} from '../model/crowd-insights/maps-grid-area';
import {CrowdInsightsDataService} from './crowd-insights-data.service';


export interface BuildingsMapState {
  displaySettings: BuildingsDisplaySettings;
  seriesTypeMap: any;
  buildings: Building[],
  windTurbines: WindTurbine[],
  selectedSeriesType: string,
  seriesTypeFilterInitialized: boolean,
  crowdInsightGridAreas: MapsGridArea[]
}

@Injectable({
  providedIn: 'root'
})
export class BuildingsService {
  private finalize$ = new Subject<void>();

  public eecBuildings: EecBuilding[] = [];

  public eecBuildingsSource = new ReplaySubject<EecBuilding[]>();
  public eecBuildings$ = this.eecBuildingsSource.asObservable().pipe(takeUntil(this.finalize$));

  private displaySettings: BuildingsDisplaySettings = <BuildingsDisplaySettings>{};

  public buildingSelectedSource = new ReplaySubject<Building>(1);
  public buildingSelected$ = this.buildingSelectedSource.asObservable().pipe(takeUntil(this.finalize$));

  public windTurbineSelectedSource = new ReplaySubject<WindTurbine>(1);
  public windTurbineSelected$ = this.windTurbineSelectedSource.asObservable().pipe(takeUntil(this.finalize$));

  public locationsSelectedSource = new ReplaySubject<any[]>(1);

  public locationsSelected$ = this.locationsSelectedSource.asObservable().pipe(takeUntil(this.finalize$));

  private crowdInsightGridAreaSelectedSource = new ReplaySubject<any>(1);

  public crowdInsightsGridAreaSelected$ = this.crowdInsightGridAreaSelectedSource.asObservable().pipe(takeUntil(this.finalize$));

  public energyType : Array<Record<"energyClass", string>>;

  private stateSource = new Subject<BuildingsMapState>()

  public stateDispatch$ = this.stateSource.asObservable().pipe(takeUntil(this.finalize$));

  public buildings$ = this.stateDispatch$.pipe(map(state => state.buildings), distinctUntilChanged());

  public displaySettings$ = this.stateDispatch$.pipe(map(state => state.displaySettings), distinctUntilChanged());

  public seriesTypeMap$ = this.stateDispatch$.pipe(map(state => state.seriesTypeMap), distinctUntilChanged());

  public windTurbines$ = this.stateDispatch$.pipe(map(state => state.windTurbines), distinctUntilChanged());

  public seriesTypeSelect$ = this.stateDispatch$.pipe(map(state => state.selectedSeriesType), distinctUntilChanged());

  public crowdInsightGridAreas = this.stateDispatch$.pipe(map(state => state.crowdInsightGridAreas), distinctUntilChanged());

  private _state: BuildingsMapState = BuildingsService.getDefaultState();

  private static DEFAULT_SERIES_TYPE = 'ENERGY_PRODUCTION';

  private static getDefaultState(): BuildingsMapState {
    return {
      buildings: [],
      windTurbines: [],
      seriesTypeMap: {
        'ENERGY_PRODUCTION': false,
        'HEAT': false,
        'ENERGY_CONSUMPTION': false,
        'WATER': false
      },
      selectedSeriesType: this.DEFAULT_SERIES_TYPE,
      displaySettings: <BuildingsDisplaySettings>{},
      seriesTypeFilterInitialized: false,
      crowdInsightGridAreas: []
    }
  }

  constructor(
    private http: HttpClient,
    @Inject(SMARTENCITY_CORE_CONFIG) private config: CoreConfig,
    @Inject(SMARTENCITY_BUILDINGS_CONFIG) private buildingsConfig: BuildingsConfig,
    private crowdInsightDataService: CrowdInsightsDataService
  ) {
    this.energyType = [
      {"energyClass" : "A" },
      {"energyClass" : "B" },
      {"energyClass" : "C" },
      {"energyClass" : "D" },
      {"energyClass" : "E" },
      {"energyClass" : "F" },
      {"energyClass" : "G" },
      {"energyClass" : "H" },
    ];
  }

  clear(): void {
    this.finalize$.next();
    this.buildingSelectedSource.next(null);
    this.windTurbineSelectedSource.next(null);
    this.locationsSelectedSource.next(null);
    this._state = BuildingsService.getDefaultState();
  }

  public selectLocations(obj: any[]): void {
    this.locationsSelectedSource.next(obj);
  }

  public updateDisplaySettings(displaySettings: BuildingsDisplaySettings) {
    this.stateSource.next(this._state = {
      ...this._state,
      displaySettings
    });
  }

  public updateSeriesType(selectedSeriesType: string): void {
    const seriesTypeMap = this._state.seriesTypeMap;

    for (const i in seriesTypeMap) {
      seriesTypeMap[i] = false;
    }
    seriesTypeMap[selectedSeriesType] = true;

    this.stateSource.next(this._state = {
      ...this._state,
      seriesTypeMap,
      selectedSeriesType
    });
  }

  public searchBuildingsByKeyword(keyword: string): Building[] {
    const filtered: any[] = [];
    this._state.buildings.forEach(building => {
      if (KeywordMatcher.matches(building.address, keyword)) {
        filtered.push(building);
      }
    });

    return filtered;
  }

  public searchWindTurbinesByKeyword(keyword: string): WindTurbine[] {
    const filtered: any[] = [];
    this._state.windTurbines.forEach(windTurbine => {
      if (KeywordMatcher.matches(windTurbine.owner, keyword)) {
        filtered.push(windTurbine);
      }
    });

    return filtered;
  }

  getDisplaySettings(): BuildingsDisplaySettings {
    if (!this.buildingsConfig.displaySettings) {
      return this.displaySettings;
    }

    const displaySettings = Object.create(this.buildingsConfig.displaySettings);

    this.stateSource.next(this._state = {
      ...this._state,
      displaySettings
    });

    this.displaySettings = displaySettings;

    return displaySettings;
  }

  loadBuildings(): void {
    this.getBuildings().subscribe( (buildings: Building[]) => {
      this.initSeriesTypeFilter(buildings);

      this.stateSource.next(this._state = {
        ...this._state,
        buildings,
      });

    });
  }

  getBuildings(): Observable<Building[]>{
    return this.http.get(this.config.cityApiUrl + '/person-series', {
      params: {
        cityPortalTypes: ['BUILDINGS', 'POWER_STATION'],
        size: '2000'
      }
    }).pipe(map((data: PageResponse<BuildingDataPoint>) => {
      return this.mapDataPointsToBuildings(data.content);
    }));
  }

  getEecBuildings(): Observable<any> {
    return this.http.get(this.config.cityApiUrl + '/data/tallinn-eec-buildings.json');
  }

  loadEecBuildings(): void {
    this.getEecBuildings().subscribe({
      next: (data: EecBuilding[]) => {
        this.eecBuildings = data;
        this.eecBuildingsSource.next(data);
      }
    });
  }

  getWindTurbines(): Observable<WindTurbine[]> {
    return this.http.get(this.config.cityApiUrl + '/city-services').pipe(map((data: { windTurbines: WindTurbine[] }) => {
      return data.windTurbines;
    }));
  }

  getCrowdInsightAreas(): Observable<MapsGridArea[]> {
    return this.crowdInsightDataService.getMapsGridAreas();
  }

  loadWindTurbines(): void {
    this.getWindTurbines().subscribe((windTurbines: WindTurbine[]) => {
      this.stateSource.next(this._state = {
        ...this._state,
        windTurbines: windTurbines
      })
    });
  }

  loadCrowdInsightAreas(): void {
    this.getCrowdInsightAreas().subscribe((areas: MapsGridArea[]) => {

      this.stateSource.next(this._state = {
        ...this._state,
        crowdInsightGridAreas: areas
      });
    });
  }

  selectBuilding(building: Building): void {
    this.buildingSelectedSource.next(building);
  }

  selectWindTurbine(windTurbine: WindTurbine): void {
    this.windTurbineSelectedSource.next(windTurbine);
  }

  selectCrowdInsightGridArea(gridArea: MapsGridArea): void {
    this.crowdInsightGridAreaSelectedSource.next(gridArea);
  }

  clearSelected(): void {
    this.buildingSelectedSource.next(null);
    this.windTurbineSelectedSource.next(null);
  }

  private mapDataPointsToBuildings(data: BuildingDataPoint[]): Building[] {
    const buildingsMap: any = {};

    data.forEach((value: BuildingDataPoint) => {
      const cityPortalType = value.cityPortalType ? value.cityPortalType : 'BUILDINGS';
      const key = (this.buildingsConfig.locationType === 'COORDS') ? (value.lat + '-' + value.lng + '-' + cityPortalType) : (value.address + '-' + cityPortalType);

      let building = buildingsMap[key];
      if (!building) {
        building = new Building({
          id: key,
          series: [],
          address: value.address,
          lat: value.lat,
          lng: value.lng,
          cityPortalType: cityPortalType
        });
        buildingsMap[key] = building;
      }

      if (!building.readableAddress && value.readableAddress) {
        building.readableAddress = value.readableAddress;
      }
      building.series.push(value);
    });

    const result: Building[] = [];
    for (const key of Object.keys(buildingsMap)) {
      result.push(buildingsMap[key]);
    }

    return result;
  }


  private initSeriesTypeFilter(buildings: Building[]) {
    if (this._state.seriesTypeFilterInitialized) {
      return;
    }

    let activeSeriesTypes = [];
    for (let building of buildings) {
      if (building.series) {
        for (let series of building.series) {
          if (series && activeSeriesTypes.indexOf(series.seriesType) === -1) {
            activeSeriesTypes.push(series.seriesType);
          }
        }
      }
    }

    let selectedSeriesType = this.getActiveSeriesTypeFromConfig();

    if (!selectedSeriesType) {
      for (let k in this._state.seriesTypeMap) {
        if (activeSeriesTypes.indexOf(k) !== -1) {
          selectedSeriesType = k;
          break;
        }
      }
    }

    if (!selectedSeriesType) {
      selectedSeriesType = BuildingsService.DEFAULT_SERIES_TYPE;
    }

    let seriesTypeMap = this._state.seriesTypeMap;
    seriesTypeMap[selectedSeriesType] = true;

    this._state = {
      ...this._state,
      seriesTypeMap,
      selectedSeriesType
    };

    this._state.seriesTypeFilterInitialized = true;
  }

  private getActiveSeriesTypeFromConfig() {
    let firstSeriesType = null;

    let config = this.buildingsConfig;
    let state = this._state;

    if (config.displaySettings?.seriesTypeFilter) {
      for (let k in config.displaySettings.seriesTypeFilter) {
        let filterEnabled = config.displaySettings.seriesTypeFilter[k];
        if (state.seriesTypeMap[k] !== undefined && filterEnabled?.active) {
          state.seriesTypeMap[k] = true;
          if (firstSeriesType == null) {
            firstSeriesType = k;
          }
        }
      }
    }

    return firstSeriesType;
  }

}
