import {NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgSelectModule} from '@ng-select/ng-select';
import {BusInfoModalComponent} from './component/modal/bus-info-modal.component';
import {StopInfoModalComponent} from './component/modal/stop-info-modal.component';
import {SearchComponent} from './component/search/search.component';
import {RouterModule} from '@angular/router';
import {BicycleStationsHeatmapComponent} from './component/bicycle-stations-heatmap/bicycle-stations-heatmap.component';
import {MatSliderModule} from '@angular/material/slider';
import {CoreModule, SMARTENCITY_CORE_CONFIG} from '@smartencity/core';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {PeopleTowersHeatmapComponent} from './component/people-towers-heatmap/people-towers-heatmap.component';
import {RoadClosureModalComponent} from './component/road-closure-modal/road-closure-modal.component';
import {MobilityRoutingModule} from './mobility-routing.model';
import {MobilityComponent} from './component/mobility.component';
import {GmapMobilityComponent} from './component/gmap-mobility.component';
import {OlMobilityComponent} from './component/ol-mobility.component';
import {MobilityLayerFiltersComponent} from './mobility-layer-filters/mobility-layer-filters.component';
import {ArcGISModule} from 'projects/arc-gis/src/public-api';
import {MobilityObjectsModalComponent} from './component/mobility-objects-modal/mobility-objects-modal.component';
import {TallinnModule} from 'projects/tallinn/src/public-api';
import {MobilityApiService} from './http/mobility-api.service';
import {PeopleTrafficModalComponent} from './component/people-traffic-modal/people-traffic-modal.component';
import {PeopleTrafficDataService} from './service/people-traffic-data.service';
import {HttpClient} from '@angular/common/http';
import {peopleTrafficServiceFactory} from './service/people-traffic-data-service-provider';
import {PeopleTrafficService} from './service/people-traffic.service';
import { BusInfoLegendComponent } from './component/modal/bus-info-legend.component';

@NgModule({
  imports: [
    MobilityRoutingModule,
    CommonModule,
    RouterModule,
    FormsModule,
    CoreModule,
    NgSelectModule,
    MatSliderModule,
    TooltipModule,
    ArcGISModule,
    TallinnModule,
    ReactiveFormsModule
  ],
  declarations: [
    MobilityComponent,
    GmapMobilityComponent,
    OlMobilityComponent,
    BusInfoModalComponent,
    StopInfoModalComponent,
    SearchComponent,
    BicycleStationsHeatmapComponent,
    PeopleTowersHeatmapComponent,
    RoadClosureModalComponent,
    MobilityLayerFiltersComponent,
    MobilityObjectsModalComponent,
    PeopleTrafficModalComponent,
    BusInfoLegendComponent
  ],
  providers: [
    DatePipe,
    MobilityApiService,
    {
      provide: PeopleTrafficDataService,
      useFactory: peopleTrafficServiceFactory,
      deps: [SMARTENCITY_CORE_CONFIG, HttpClient]
    },
    PeopleTrafficService
  ],
  exports: [MobilityComponent],
})
export class MobilityModule {
}
