import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'tallinn-vision-modal',
  templateUrl: './tallinn-vision-modal.component.html',
  styleUrls: ['./tallinn-vision-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TallinnVisionModalComponent implements OnInit {

  @Input()
  public show: boolean;

  @Output()
  public onModalHide: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void {
  }

  close() {
    this.show = false;
    this.onModalHide.next();
  }

}
