export class RoadWeatherSiteIndicator {
  key: string;
  label: string;
}

export class RoadWeatherSiteConstants {
  public static roadWeatherIndicators: RoadWeatherSiteIndicator[] = [
    {
      key: "air-temp",
      label: $localize`Air temp`,
    },
    {
      key: "precip",
      label: $localize`Precipitation`,
    },
    {
      key: "precip-type",
      label: $localize`Precipitation type`,
    },
    {
      key: "wind-speed",
      label: $localize`Wind speed`,
    },
    {
      key: "wind-speed-max",
      label: $localize`Wind speed max`,
    },
    {
      key: "relative-humidity",
      label: $localize`Relative humidity`,
    }
  ];

  public static roadConditionIndicators: RoadWeatherSiteIndicator[] = [
    {
      key: "road-condition",
      label: $localize`Road condition`,
    },
    {
      key: "road-temp",
      label: $localize`Road temp`,
    },
    {
      key: "visiblity",
      label: $localize`Visibility`,
    },
    {
      key: "dew-point",
      label: $localize`Dew point`,
    }
  ];

  public static timeTableIndicators = {
    "current":       {
      key: "current",
      label: $localize`Current`,
    },
    "plus-1-hour":   {
      key: "plus-1-hour",
      label: $localize`+1 hour`,
    },
    "plus-3-hours": {
      key: "plus-3-hours",
      label: $localize`+3 hours`,
    },
    "plus-6-hours": {
      key: "plus-6-hours",
      label: $localize`+6 hours`,
    },
    "plus-12-hours":   {
      key: "plus-12-hours",
      label: $localize`+12 hours`,
    }
  }

  // https://socket.tik.teeilm.ee/api/docs#tag--Parameters
  public static roadConditionCodeMap = {
    1: {
      label: $localize`dry`,
      color: 'green'
    },
    2: {
      label: $localize`damp`, // damp
      color: 'yellow'
    },
    3: {
      label: $localize`dry`,
      color: 'green'
    },
    4: {
      label: $localize`wet + salt`,
      color: 'yellow'
    },
    5: {
      label: $localize`frost`,
      color: 'red'
    },
    6: {
      label: $localize`snowy`,
      color: 'yellow'
    },
    7: {
      label: $localize`icy`,
      color: 'red'
    },
    8: {
      label: $localize`damp + salt`,
      color: 'yellow'
    },
    9: {
      label: $localize`sleet`,
      color: 'yellow'
    },
    11: {
      label: $localize`dry`,
      color: 'green'
    },
    12: {
      label: $localize`damp`,
      color: 'yellow'
    },
    13: {
      label: $localize`wet`,
      color: 'yellow'
    },
    14: {
      label: $localize`wet + salt`,
      color: 'yellow'
    },
    16: {
      label: $localize`snowy`,
      color: 'yellow'
    },
    18: {
      label: $localize`damp + salt`,
      color: 'yellow'
    },
    21: {
      label: $localize`dry`,
      color: 'green'
    },
    22: {
      label: $localize`damp`,
      color: 'yellow'
    },
    23: {
      label: $localize`wet`,
      color: 'yellow'
    },
    24: {
      label: $localize`wet + salt`,
      color: 'yellow'
    },
    25: {
      label: $localize`frost`,
      color: 'red'
    },
    26: {
      label: $localize`snowy`,
      color: 'yellow'
    },
    28: {
      label: $localize`damp + salt`,
      color: 'yellow'
    },
    31: {
      label: $localize`dry`,
      color: 'green'
    },
    32: {
      label: $localize`damp`,
      color: 'yellow'
    },
    33: {
      label: $localize`wet`,
      color: 'yellow'
    },
    34: {
      label: $localize`wet + salt`,
      color: 'yellow'
    },
    35: {
      label: $localize`frost`,
      color: 'red'
    },
    36: {
      label: $localize`snowy`,
      color: 'yellow'
    },
    38: {
      label: $localize`damp + salt`,
      color: 'yellow'
    },
    101: {
      label: $localize`dry`,
      color: 'green'
    },
    102: {
      label: $localize`damp`,
      color: 'yellow'
    },
    103: {
      label: $localize`wet`,
      color: 'yellow'
    },
    105: {
      label: $localize`frost`,
      color: 'red'
    },
    106: {
      label: $localize`snowy`,
      color: 'yellow'
    },
    107: {
      label: $localize`icy`,
      color: 'red'
    },
    109: {
      label: $localize`sleet`,
      color: 'yellow'
    },


    111: {
      label: $localize`dry`,
      color: 'green'
    },
    112: {
      label: $localize`damp`,
      color: 'yellow'
    },
    113: {
      label: $localize`wet`,
      color: 'yellow'
    },
    114: {
      label: $localize`wet + salt`,
      color: 'yellow'
    },
    115: {
      label: $localize`frost`,
      color: 'red'
    },
    116: {
      label: $localize`snowy`,
      color: 'yellow'
    },
    117: {
      label: $localize`icy`,
      color: 'red'
    },
    118: {
      label: $localize`damp + salt`,
      color: 'yellow'
    },
    121: {
      label: $localize`dry`,
      color: 'green'
    },
    122: {
      label: $localize`damp`,
      color: 'yellow'
    },
    123: {
      label: $localize`wet`,
      color: 'yellow'
    },
    124: {
      label: $localize`wet + salt`,
      color: 'yellow'
    },
    125: {
      label: $localize`frost`,
      color: 'red'
    },
    126: {
      label: $localize`snowy`,
      color: 'yellow'
    },
    127: {
      label: $localize`icy`,
      color: 'red'
    },
    128: {
      label: $localize`damp + salt`,
      color: 'yellow'
    },

    131: {
      label: $localize`dry`,
      color: 'green'
    },
    132: {
      label: $localize`damp`,
      color: 'yellow'
    },
    133: {
      label: $localize`wet`,
      color: 'yellow'
    },
    134: {
      label: $localize`wet + salt`,
      color: 'yellow'
    },
    135: {
      label: $localize`frost`,
      color: 'red'
    },
    136: {
      label: $localize`snowy`,
      color: 'yellow'
    },
    137: {
      label: $localize`icy`,
      color: 'red'
    },
    138: {
      label: $localize`damp + salt`,
      color: 'yellow'
    },

    201: {
      label: $localize`icy`,
      color: 'red'
    },
    203: {
      label: $localize`wet`,
      color: 'yellow'
    },
    205: {
      label: $localize`frost`,
      color: 'red'
    },
    206: {
      label: $localize`snowy`,
      color: 'yellow'
    },
    207: {
      label: $localize`icy`,
      color: 'red'
    },
    209: {
      label: $localize`sleet`,
      color: 'yellow'
    },

    211: {
      label: $localize`dry`,
      color: 'green'
    },
    213: {
      label: $localize`wet`,
      color: 'yellow'
    },
    216: {
      label: $localize`snowy`,
      color: 'yellow'
    },
    217: {
      label: $localize`icy`,
      color: 'red'
    },
    221: {
      label: $localize`dry`,
      color: 'green'
    },
    222: {
      label: $localize`damp`,
      color: 'yellow'
    },
    225: {
      label: $localize`frost`,
      color: 'red'
    },
    226: {
      label: $localize`snowy`,
      color: 'yellow'
    },
    227: {
      label: $localize`icy`,
      color: 'red'
    },
    228: {
      label: $localize`damp + salt`,
      color: 'yellow'
    },
    231: {
      label: $localize`dry`,
      color: 'green'
    },
    235: {
      label: $localize`frost`,
      color: 'red'
    },
    236: {
      label: $localize`snowy`,
      color: 'yellow'
    },
    237: {
      label: $localize`icy`,
      color: 'red'
    },
    238: {
      label: $localize`damp + salt`,
      color: 'yellow'
    },

    311: {
      label: $localize`damp + salt`,
      color: 'yellow'
    },
    312: {
      label: $localize`damp`,
      color: 'yellow'
    },
    315: {
      label: $localize`frost`,
      color: 'red'
    },
    316: {
      label: $localize`snowy`,
      color: 'yellow'
    },
    317: {
      label: $localize`icy`,
      color: 'red'
    },
    318: {
      label: $localize`damp + salt`,
      color: 'yellow'
    },
    321: {
      label: $localize`frost`,
      color: 'red'
    },
    322: {
      label: $localize`damp`,
      color: 'yellow'
    },
    324: {
      label: $localize`frost`,
      color: 'red'
    },
    325: {
      label: $localize`frost`,
      color: 'red'
    },
    326: {
      label: $localize`snowy`,
      color: 'yellow'
    },
    327: {
      label: $localize`icy`,
      color: 'red'
    },
    328: {
      label: $localize`damp + salt`,
      color: 'yellow'
    },
    329: {
      label: $localize`frost`,
      color: 'red'
    },
    331: {
      label: $localize`frost`,
      color: 'red'
    },
    332: {
      label: $localize`damp`,
      color: 'yellow'
    },
    334: {
      label: $localize`frost`,
      color: 'red'
    },
    335: {
      label: $localize`frost`,
      color: 'red'
    },
    336: {
      label: $localize`snowy`,
      color: 'yellow'
    },
    337: {
      label: $localize`icy`,
      color: 'red'
    },
    338: {
      label: $localize`damp + salt`,
      color: 'yellow'
    },

    411: {
      label: $localize`dry`,
      color: 'green'
    },
    412: {
      label: $localize`damp`,
      color: 'yellow'
    },
    413: {
      label: $localize`wet`,
      color: 'yellow'
    },
    414: {
      label: $localize`wet + salt`,
      color: 'yellow'
    },
    416: {
      label: $localize`snowy`,
      color: 'yellow'
    },
    417: {
      label: $localize`icy`,
      color: 'yellow'
    },
    418: {
      label: $localize`damp + salt`,
      color: 'yellow'
    },

    421: {
      label: $localize`dry`,
      color: 'green'
    },
    422: {
      label: $localize`damp`,
      color: 'yellow'
    },
    423: {
      label: $localize`wet`,
      color: 'yellow'
    },
    424: {
      label: $localize`wet + salt`,
      color: 'yellow'
    },
    426: {
      label: $localize`snowy`,
      color: 'yellow'
    },
    428: {
      label: $localize`damp + salt`,
      color: 'yellow'
    },

    431: {
      label: $localize`dry`,
      color: 'green'
    },
    432: {
      label: $localize`damp`,
      color: 'yellow'
    },
    433: {
      label: $localize`wet`,
      color: 'yellow'
    },
    434: {
      label: $localize`wet + salt`,
      color: 'yellow'
    },
    438: {
      label: $localize`snowy`,
      color: 'yellow'
    },

    501: {
      label: $localize`dry`,
      color: 'green'
    },
    502: {
      label: $localize`wet`,
      color: 'yellow'
    },
    503: {
      label: $localize`ice/snow`,
      color: 'red'
    },
    504: {
      label: $localize`sleet`,
      color: 'yellow'
    },
    505: {
      label: $localize`damp`,
      color: 'yellow'
    },
    506: {
      label: $localize`sleet`,
      color: 'yellow'
    },
    507: {
      label: $localize`frost`,
      color: 'red'
    },
    508: {
      label: $localize`glaze ice`,
      color: 'red'
    },

    601: {
      label: $localize`dry`,
      color: 'green',
    },
    602: {
      label: $localize`wet`,
      color: 'yellow',
    },
    603: {
      label: $localize`ice/snow`,
      color: 'red',
    },
    604: {
      label: $localize`sleet`,
      color: 'yellow',
    },
    605: {
      label: $localize`damp`,
      color: 'yellow',
    },
    606: {
      label: $localize`melted snow`,
      color: 'yellow',
    },
    607: {
      label: $localize`frost`,
      color: 'yellow',
    },
    608: {
      label: $localize`glaze ice`, //jäide
      color: 'red',
    },
    609: {
      label: $localize`damp`,
      color: 'yellow',
    },
    610: {
      label: $localize`frost`,
      color: 'yellow',
    },
    611: {
      label: $localize`damp`,
      color: 'yellow',
    },

    700: {
      label: $localize`error`,
      color: 'gray',
    },
    701: {
      label: $localize`dry`,
      color: 'green',
    },
    702: {
      label: $localize`wet`,
      color: 'yellow',
    },
    703: {
      label: $localize`ice/snow`,
      color: 'red',
    },
    704: {
      label: $localize`wet + salt`,
      color: 'yellow',
    },
    705: {
      label: $localize`frost`,
      color: 'red',
    },
    706: {
      label: $localize`snowy`,
      color: 'yellow',
    },
    707: {
      label: $localize`icy`,
      color: 'red',
    },
    708: {
      label: $localize`damp + salt`,
      color: 'yellow',
    },

    801: {
      label: $localize`icy`,
      color: 'red',
    },
    802: {
      label: $localize`snowy`,
      color: 'yellow',
    },
    803: {
      label: $localize`dry`,
      color: 'green',
    },
    806: {
      label: $localize`glaze ice`,
      color: 'red',
    },
    807: {
      label: $localize`snowy`,
      color: 'yellow',
    },
    808: {
      label: $localize`glaze ice`,
      color: 'red',
    },
    809: {
      label: $localize`icy`,
      color: 'red',
    },


    811: {
      label: $localize`icy`,
      color: 'red',
    },
    812: {
      label: $localize`icy`,
      color: 'red',
    },
    813: {
      label: $localize`snowy`,
      color: 'yellow',
    },
    814: {
      label: $localize`damp`,
      color: 'yellow',
    },
    816: {
      label: $localize`snowy`,
      color: 'yellow',
    },
    817: {
      label: $localize`frost`,
      color: 'red',
    },


    820: {
      label: $localize`icy`,
      color: 'red',
    },
    821: {
      label: $localize`icy`,
      color: 'red',
    },
    822: {
      label: $localize`snowy`,
      color: 'yellow',
    },
    823: {
      label: $localize`damp`,
      color: 'yellow',
    },
    826: {
      label: $localize`snowy`,
      color: 'yellow',
    },
    828: {
      label: $localize`frost`,
      color: 'red',
    },
    829: {
      label: $localize`frost`,
      color: 'red',
    },
    830: {
      label: $localize`frost`,
      color: 'red',
    },

    901: {
      label: $localize`dry`,
      color: 'green',
    },
    902: {
      label: $localize`damp`,
      color: 'yellow',
    },
    903: {
      label: $localize`wet`,
      color: 'yellow',
    },
    904: {
      label: $localize`sleet`,
      color: 'yellow',
    },
    905: {
      label: $localize`ice`,
      color: 'red',
    },
    906: {
      label: $localize`snow`,
      color: 'yellow',
    },



  }

  public static weatherConditionCodeMap = {
    0: {
      label: $localize`not raining`,
      color: 'green',
    },
    1: {
      label: $localize`not raining`,
      color: 'green',
    },
    2: {
      label: $localize`rain`,
      color: 'yellow',
    },
    3: {
      label: $localize`rain`,
      color: 'yellow',
    },
    4: {
      label: $localize`snow`,
      color: 'yellow',
    },
    5: {
      label: $localize`snow`,
      color: 'yellow',
    },
    6: {
      label: $localize`sleet`,
      color: 'yellow',
    },
    7: {
      label: $localize`blizzard`,
      color: 'red',
    },
    8: {
      label: $localize`hail`,
      color: 'red',
    },
    9: {
      label: $localize`unknown`,
      color: 'gray',
    },
    11: {
      label: $localize`ice rain`,
      color: 'red',
    },
    12: {
      label: $localize`ice rain`,
      color: 'red',
    },
    21: {
      label: $localize`drizzle`,
      color: 'yellow',
    },
    22: {
      label: $localize`ice rain`,
      color: 'red',
    },
    37: {
      label: $localize`not raining`,
      color: 'green',
    },
    38: {
      label: $localize`unknown`,
      color: 'gray',
    },
    39: {
      label: $localize`drizzle`,
      color: 'yellow',
    },
    40: {
      label: $localize`rain`,
      color: 'yellow',
    },
    41: {
      label: $localize`snow`,
      color: 'yellow',
    },
    42: {
      label: $localize`sleet`,
      color: 'yellow',
    },
    43: {
      label: $localize`sleet`,
      color: 'yellow',
    },
    44: {
      label: $localize`hail`,
      color: 'red',
    },
    45: {
      label: $localize`ice crystals`,
      color: 'red',
    },
    46: {
      label: $localize`snow`,
      color: 'yellow',
    },
    47: {
      label: $localize`snow`,
      color: 'yellow',
    },
    48: {
      label: $localize`ice rain`,
      color: 'red',
    },
    49: {
      label: $localize`ice rain`,
      color: 'red',
    },
    99: {
      label: $localize`sleet`,
      color: 'yellow',
    },
  }
}
