import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import { MobilityComponent } from './component/mobility.component';

const routes: Routes = [
  {
    path: '',
    component: MobilityComponent,
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MobilityRoutingModule {
}
