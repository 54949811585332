<div class="modal-header">
  <h5 class="modal-title w-100" i18n>Please confirm your name</h5>
</div>
<div class="modal-body" [formGroup]="form">
  <p i18n>As it is your first time logging on to the site, we kindly ask you to type in your CPR-number. You will not be asked to do so again.</p>

  <div class="md-form">
    <input
      type="text"
      class="form-control"
      formControlName="cpr"
      [id]="id + '-cpr'">
    <label i18n [attr.for]="id + '-cpr'">CPR</label>
    <!-- validation error -->
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-light btn-sm" aria-label="Cancel" (click)="close()" i18n>Cancel</button>
  <button type="button" class="btn btn-primary btn-sm" (click)="save()" i18n>Save</button>
</div>
