import {AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';
import {PersonCodeValidator} from './person-code-validator';

export class CustomFormValidators {

  public static uniqueOptions(): ValidatorFn | any {

    return (control: AbstractControl) => {
      if (!(control instanceof FormArray)) {
        return null;
      }
      const nameList = (control as FormArray).controls.map(el => el.get('name').value).filter(n => n);
      const namesSet = new Set<any>(nameList);
      if (namesSet.size !== nameList.length) {
        return {
          uniqueOptions: 'uniqueOptions'
        };
      }
      return null;
    }

  }

  public static minArrayLength(min: number): ValidatorFn | any {
    return (control: AbstractControl) => {
      if (!(control instanceof FormArray)) return;
      return control.length < min ? { minLength: true } : null;
    };
  }

  public static personalId = (countryCode: string) => {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const code = control.value;
      if (PersonCodeValidator.validate(code, countryCode)) {
        return null;
      }

      return {
        invalidPersonCode: {
          value: control.value
        }
      }
    }
  }

  public static latitude(control: AbstractControl): ValidationErrors | null {
    const min = -90.0, max = 90.0, value = control.value;
    if (value == null || value >= min && value <= max) {
      return null;
    }

    return {
      invalidLatitude: {
        value: value,
        min: min,
        max: max
      }
    };
  };

  public static longitude(control: AbstractControl): ValidationErrors | null {
    const min = -180.0, max = 180.0, value = control.value;
    if (value == null || value >= min && value <= max) {
      return null;
    }

    return {
      invalidLongitude: {
        value: value,
        min: min,
        max: max
      }
    };
  };

  public static uuidMinLength = (minLength: number): ValidatorFn | any => {

    return (control: AbstractControl): ValidationErrors | null => {
      const input = <FormControl>control;
      const parent = <FormGroup>input.parent;

      if (!parent) {
        return null;
      }

      if (parent.get('locationType').value !== 'UUID') {
        return null;
      }

      const value = input.value;

      return value != null && value.length > 0 && value.length < minLength ? {
        minlength: {
          requiredLength: minLength,
          actualLength: value.length
        }
      } : null;

    }
  }

}
