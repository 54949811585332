<ng-template #navMenu>
  <ul class="navbar-nav w-100">
    <li class="nav-item"><a routerLink="/mydata/settings" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" i18n class="nav-link">Notification addresses</a></li>
    <li class="nav-item"><a routerLink="/mydata/settings/preferences" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" i18n class="nav-link">Preferences</a></li>
    <li class="nav-item" *ngIf="currentMandate"><a routerLink="/mydata/settings/dal-api-keys" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" i18n class="nav-link">DAL API Keys</a></li>
    <li class="nav-item" *ngIf="currentMandate"><a routerLink="/mydata/settings/your-city-portal-info" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" i18n class="nav-link">Your City Portal Information</a></li>
    <li class="ml-auto d-flex align-items-center">
      <button type="button" (click)="viewTos()" class="btn btn-sm btn-outline-primary" i18n>View terms and conditions</button>
    </li>
  </ul>
</ng-template>
<router-outlet></router-outlet>