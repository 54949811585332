<div class="modal-header">
  <h5 class="modal-title w-100" i18n>Access grants to this data groups</h5>
  <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
    <i class="fal fa-times"></i>
  </button>
</div>

<div class="modal-body">
  <table *ngIf="consents && consents.length > 0" class="table table-responsive-md btn-table modal-table">
    <thead>
    <tr>
      <th i18n>Name</th>
      <th i18n>Owner reg no</th>
      <th i18n>Validity period</th>
      <th i18n>Status</th>
      <th></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let consent of consents">
      <td>{{consent.person.displayName}}</td>
      <td>{{consent.person | regNo}}</td>
      <td>{{consent.startAt | date:'dd.MM.yyyy'}} -
        <ng-container *ngIf="consent.endAt">{{consent.endAt | date:'dd.MM.yyyy'}}</ng-container>
        <ng-container *ngIf="!consent.endAt">...</ng-container>
      </td>
      <td> 
        <span [ngClass]="{'badge-warning': consent.confirmationStatus == 'PENDING', 'badge-success': consent.confirmationStatus == 'ACCEPTED', 'badge-danger': consent.confirmationStatus == 'CANCELLED'}" class="w-100 d-flex justify-content-center p-2 badge rounded-pill" i18n>{{consent.confirmationStatus}}</span>
    </td>
      <td>
        <button *ngIf="(consent.confirmationStatus == 'PENDING' || consent.confirmationStatus == 'ACCEPTED') && !consent.endAt" (click)="endConsent(consent)" type="button" class="btn btn-sm btn-link">
          <i class="fa fa-edit"></i>&nbsp;<ng-container i18n>End consent</ng-container>
        </button>
      </td>
    </tr>
    </tbody>
  </table>
  <div *ngIf="consents && consents.length == 0" class="alert alert-info" role="alert" i18n>Data is not shared</div>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-secondary btn-sm" i18n (click)="close()">Close</button>
</div>

