import {Component, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, AsyncValidatorFn, FormArray, FormControl, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {InputFieldRow, ManageService} from '../manage.service';
import {debounceTime, distinctUntilChanged, filter, map, startWith, switchMap, takeUntil} from 'rxjs/operators';
import {of, timer, Subject, EMPTY} from 'rxjs';
import {Expression, QuestionnaireDetail, QuestionnaireField} from '@smartencity/core';
import {HttpClient, HttpParameterCodec, HttpParams} from '@angular/common/http';
import {MyDataConfig} from '../../../mydata-config.model';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';
import {SMARTENCITY_MYDATA_CONFIG} from '../../../injection-tokens';
import {CustomFormValidators} from '../../../../../../core/src/lib/validators/custom-form-validators';

export function requireForOptionalField(control: AbstractControl) {
  const input = <FormControl>control;
  const parent = <FormGroup>input.parent;

  if (!parent) {
    return null;
  }

  if (!parent.get('optional').value) {
    return null;
  }

  if (!input.value) {
    return {
      required: true
    };
  }

  return null;
}

function sortedIndex(array, value) {
  let low = 0, high = array.length;

  while (low < high) {
    const mid = (low + high) >>> 1;
    if (array[mid] < value) {
      low = mid + 1;
    } else {
      high = mid;
    }
  }
  return low;
}

class CustomEncoder implements HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }

  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }

  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }

  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }
}

const selector = 'mydata-formula-expression-form';
let nextId = 0;

@Component({
  selector: selector,
  templateUrl: './formula-expression-form.component.html',
  styleUrls: ['./formula-expression-form.component.css']
})
export class FormulaExpressionFormComponent implements OnInit, OnDestroy {
  private ngDestroy = new Subject<void>();
  id = `${selector}-${nextId++}`;

  @Input()
  questionnaire: QuestionnaireDetail;

  @Input()
  q11eType: string;

  @Input()
  partialEdit = false;

  field: QuestionnaireField;
  fields: InputFieldRow[] = [];
  field$ = this.manageService.field$;
  inputFields: InputFieldRow[] = [];
  inputFieldFormArray = new FormArray([]);

  formGroup: FormGroup;

  constructor(
    @Inject(SMARTENCITY_MYDATA_CONFIG) private config: MyDataConfig,
    private http: HttpClient,
    private manageService: ManageService
  ) {
  }

  ngOnInit(): void {
    this.field$.pipe(takeUntil(this.ngDestroy)).subscribe((field => {
      if (field.type === 'FORMULA') {
        this.field = field;
        this.formGroup = this.manageService.getCurrentFiledFormGroup();
        if (this.formGroup) {
          this.formGroup.get('expression').setAsyncValidators(this.createExpressionValidator(this.formGroup));
        }
      } else {
        this.field = null;
        this.formGroup = null;
      }
    }));
    this.field$.pipe(filter(f => f.type === 'FORMULA'), takeUntil(this.ngDestroy)).subscribe(value => this.fieldChange(this.formGroup, value));
    this.field$.pipe(
      filter(f => f.type === 'FORMULA'),
      map(f => this.manageService.getCurrentFiledFormGroup()),
      switchMap(fg => (fg ? fg.get('name').valueChanges.pipe(distinctUntilChanged(), debounceTime(1000)) : EMPTY)),
      takeUntil(this.ngDestroy)
    ).subscribe(value => this.formulaNameChange(this.formGroup, value));
    this.field$.pipe(
      filter(f => f.type === 'FORMULA'),
      map(f => this.manageService.getCurrentFiledFormGroup()),
      switchMap(fg => (fg ? fg.get('variables').valueChanges : EMPTY)),
      takeUntil(this.ngDestroy)
    ).subscribe(value => this.formulaVariablesChange(this.formGroup, value));
  }

  ngOnDestroy(): void {
    for (const inputField of this.inputFields) {
      inputField.destroy.next();
      inputField.destroy.complete();
    }
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  control(name?: string) {
    if (!name) {
      return this.formGroup;
    }
    return this.formGroup.get(name);
  }

  invalid(name: string, formGroup?) {
    if (!formGroup) {
      formGroup = this.formGroup;
    }
    const control = formGroup.get(name);
    return control && control.invalid && (control.dirty || control.touched);
  }

  errors(name: string): ValidationErrors {
    const control = this.formGroup.get(name);
    return control ? control.errors : null;
  }

  isInputActive(inputField): boolean {
    return this.manageService.getCurrentChildField() === inputField.field;
  }

  public toggleSymbol() {
    if (this.formGroup.get('symbol').value === null) {
      this.formGroup.get('symbol').setValue('');
    } else {
      this.formGroup.get('symbol').setValue(null);
    }
  }

  public addDescription() {
    this.formGroup.get('description').setValue('', {onlySelf: false, emitEvent: true});
  }

  public removeDescription() {
    this.formGroup.get('description').setValue(null, {onlySelf: false, emitEvent: true});
  }

  public createExpressionValidator(formulaGroup: FormGroup): AsyncValidatorFn {
    return (control: FormControl) => {
      /*if (formulaGroup.get('formulaType').value !== 'EXPRESSION') {
        return of(null);
      }*/

      const expression = control.value;
      if (!expression) {
        return of({
          required: 'required'
        });
      }

      return timer(250).pipe(
        takeUntil(this.ngDestroy),
        switchMap(() => {
          return this.http.get<Expression>(
            this.config.apiUrl + '/expression',
            {params: new HttpParams({encoder: new CustomEncoder()}).set('expression', expression)}
          ).pipe(
            takeUntil(this.ngDestroy),
            map((result) => {
              if (result.syntaxStatus) {
                formulaGroup.get('variables').setValue(result.variables);
                return null;
              } else {
                return {
                  syntax: result.errorMessage
                    .replace(/[\u00A0-\u9999<>\&]/gim, function(i) {
                      return '&#' + i.charCodeAt(0) + ';';
                    })
                    .replace(/(?:\r\n|\r|\n)/g, '<br>')
                };
              }
            })
          );
        })
      );
    };
  }

  public fieldChange(formulaGroup: FormGroup, field: any) {
    this.updateFields();
  }

  public formulaNameChange(formulaGroup: FormGroup, name: string) {
    this.updateFields();
  }

  public formulaVariablesChange(formulaGroup: FormGroup, variables: string[]) {
    this.updateFields();
  }

  public updateFields() {
    const varsTemp = [];
    varsTemp.push.apply(varsTemp, this.formGroup.get('variables').value);

    /*
    // variables used by all existing formulas
    for (const control of this.inputFieldFormArray.controls) {
      if (control.get('type').value === 'FORMULA') {
        varsTemp.push.apply(varsTemp, control.get('variables').value);
      }
    }
    */

    const variableSet = new Set<string>(varsTemp.sort());
    // remove subformula variables
    for (const control of this.inputFieldFormArray.controls) {
      if (control.get('type').value === 'FORMULA') {
        variableSet.delete(control.get('name').value);
      }
    }

    // get existing fields form manage service by symbols

    const inputFieldIndicesToRemove: number[] = [];
    for (const [i, inputField] of this.inputFields.entries()) {
      const variable: string = inputField.symbol;
      if (!variableSet.has(variable)) {
        inputFieldIndicesToRemove.push(i);
      } else {
        variableSet.delete(variable); // here variableSet effectively becomes "variableSetToAdd"
      }
    }

    for (const i of inputFieldIndicesToRemove.sort().reverse()) {
      this.removeInputFieldAtIndex(i);
    }

    const inputSymbols: string[] = this.inputFields.map(s => s.symbol).sort();

    for (const variable of variableSet) {
      const inputField = this.manageService.getOrCreateSymbol(variable);
      if (inputField.type === 'FORMULA_INPUT') {
        const selectOptionsArray = new FormArray([], [CustomFormValidators.uniqueOptions]);
        if (inputField.valueType === 'SELECT') {
          for (const option of inputField.selectOptions) {
            selectOptionsArray.push(this.createSelectOption(selectOptionsArray, option.name, option.value));
          }
          if (!selectOptionsArray.controls.length) {
            selectOptionsArray.push(this.createSelectOption(selectOptionsArray, '', null));
          }
        }

        const fieldControl = new FormGroup({
          symbol: new FormControl(inputField.symbol),
          name: new FormControl(inputField.name, [Validators.required]),
          description: new FormControl(inputField.description, []),
          type: new FormControl('FORMULA_INPUT', [Validators.required]),
          responseTypeCommon: new FormControl(inputField.responseType === 'COMMON', [Validators.required]),
          valueType: new FormControl(inputField.valueType, [Validators.required]),
          selectOptions: selectOptionsArray,
          unit: new FormControl(inputField.unit, []),
          value: new FormControl(inputField.value, []),
          personParameter: new FormControl(inputField.personParameter, []),
          personSeries: new FormControl(inputField.personSeries, []),
          windowOperationType: new FormControl(inputField.windowOperationType ? inputField.windowOperationType : 'LATEST', []),
          windowPeriodType: new FormControl(inputField.windowPeriodType, []),
          periodCount: new FormControl(inputField.periodCount, []),
          optional: new FormControl(inputField.required !== true, []),
          defaultValue: new FormControl(inputField.defaultValue, [requireForOptionalField, Validators.pattern(/^\d+([\.\,]\d+)?$/)]),
        });


        if (this.partialEdit) {
          fieldControl.disable();
        }

        const insertIndex = sortedIndex(inputSymbols, variable);
        inputSymbols.splice(insertIndex, 0, variable);
        this.insertInputFieldAtIndex(inputField, fieldControl, insertIndex);
      }
    }
  }

  private createSelectOption(selectOptions: FormArray, name?: string, value?: number) {
    return new FormGroup({
      name: new FormControl(name, [Validators.required]),
      value: new FormControl(value, [Validators.required, Validators.pattern(/^\d+([\.\,]\d+)?$/)])
    });
  }

  private insertInputFieldAtIndex(field, formGroup: FormGroup, index) {
    this.inputFieldFormArray.insert(index, formGroup);
    const fieldInstance = {
      symbol: field.symbol,
      field: field,
      formGroup: formGroup,
      destroy: new Subject<void>()
    };
    combineLatest([
      formGroup.get('responseTypeCommon').valueChanges.pipe(startWith(formGroup.get('responseTypeCommon').value), distinctUntilChanged()),
      formGroup.get('valueType').valueChanges.pipe(startWith(formGroup.get('valueType').value), distinctUntilChanged())
    ]).pipe(takeUntil(this.ngDestroy)).subscribe(([responseTypeCommon, valueType]) => {
      if (responseTypeCommon) {
        if (valueType === 'VALUE') {
          formGroup.get('value').setValidators([Validators.required, Validators.pattern(/^\d+([\.\,]\d+)?$/)]);
          formGroup.get('personParameter').setValidators([]);
          formGroup.get('personSeries').setValidators([]);
        } else if (valueType === 'PERSON_PARAMETER') {
          formGroup.get('value').setValidators([]);
          formGroup.get('personParameter').setValidators([Validators.required]);
          formGroup.get('personSeries').setValidators([]);
        } else if (valueType === 'PERSON_SERIES') {
          formGroup.get('value').setValidators([]);
          formGroup.get('personParameter').setValidators([]);
          formGroup.get('personSeries').setValidators([Validators.required]);
        }

        if (valueType === 'SELECT') {
          formGroup.get('valueType').setValue('VALUE');
        }
      } else {
        const selectOptionArray = (formGroup.get('selectOptions') as FormArray);
        if (valueType === 'SELECT') {
          if (selectOptionArray.length < 1) {
            selectOptionArray.push(this.createSelectOption(selectOptionArray, '', null));
          }
        } else {
          selectOptionArray.clear();
        }
        formGroup.get('value').setValidators([]);
        formGroup.get('personParameter').setValidators([]);
        formGroup.get('personSeries').setValidators([]);
      }
      formGroup.get('value').updateValueAndValidity();
      formGroup.get('personParameter').updateValueAndValidity();
      formGroup.get('personSeries').updateValueAndValidity();
    });
    formGroup.get('valueType').valueChanges.pipe(takeUntil(fieldInstance.destroy)).subscribe(valueType => {
      if (valueType === 'PERSON_SERIES') {
        formGroup.get('windowOperationType').setValidators([Validators.required]);
      } else {
        formGroup.get('windowOperationType').setValidators([]);
      }
    });
    formGroup.get('windowOperationType').valueChanges.pipe(takeUntil(fieldInstance.destroy)).subscribe(windowOperationType => {
      if (windowOperationType !== 'LATEST') {
        formGroup.get('windowPeriodType').setValidators([Validators.required]);
        formGroup.get('periodCount').setValidators([Validators.required, Validators.pattern(/^\d+$/)]);
      } else {
        formGroup.get('windowPeriodType').setValidators([]);
        formGroup.get('periodCount').setValidators([]);
        formGroup.get('windowPeriodType').setValue(null);
        formGroup.get('periodCount').setValue(null);
      }
    });
    formGroup.get('optional').valueChanges.pipe(takeUntil(fieldInstance.destroy)).subscribe(optional => {
      if (!optional) {
        formGroup.get('defaultValue').setValue(null);
      }
      formGroup.get('defaultValue').markAsTouched();
      formGroup.get('defaultValue').updateValueAndValidity();
    });
    this.inputFields.splice(index, 0, fieldInstance);
    return fieldInstance;
  }

  private removeInputFieldAtIndex(index: number) {
    const fieldInstance = this.inputFields[index];
    if (fieldInstance) {
      fieldInstance.destroy.next();
      fieldInstance.destroy.complete();
    }
    this.inputFields.splice(index, 1);
    this.inputFieldFormArray.removeAt(index);
  }

  async save(): Promise<void> {
    this.formGroup.markAllAsTouched();
    this.formGroup.updateValueAndValidity();
    this.inputFieldFormArray.markAllAsTouched();
    this.inputFieldFormArray.updateValueAndValidity();

    if (this.formGroup.invalid || this.inputFieldFormArray.invalid) {
      console.error('invalid', this.formGroup, this.inputFieldFormArray);
      throw new Error('FormulaExpressionFormComponent form invalid');
    }

    // TODO update fields in service
    // Validate unique symbols

    const value = this.formGroup.getRawValue();

    const inputFields = [...this.inputFields];

    this.manageService.saveField(this.field, Object.assign(
      {
        type: 'FORMULA',
        formulaType: 'EXPRESSION'
      },
      this.manageService.mapFieldFormValueToField(value)
    ));

    for (const inputField of inputFields) {
      const inputValue = inputField.formGroup.getRawValue();
      this.manageService.saveField(inputField.field, Object.assign(inputField.field, this.manageService.mapFieldFormValueToField(inputValue)));
    }

    this.manageService.save();
  }

  async cancel() {
    try {
      await this.manageService.addField();
    } catch (e) {
      //
    }
  }

}
