import {EventEmitter, Inject, Injectable, OnDestroy} from '@angular/core';
import {
  AuthService,
  Mandate, PageResponse, PendingPersonSeriesGroup,
  PendingSeries,
  PendingSeriesMeta,
  SeriesTargetError,
  UserService,
  PendingPersonSeriesCancellation, PersonSeriesApiService
} from '@smartencity/core';
import {HttpClient} from '@angular/common/http';
import {MyDataConfig} from '../../mydata-config.model';
import {SMARTENCITY_MYDATA_CONFIG} from '../../injection-tokens';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';
import {map, startWith, switchMap, takeUntil} from 'rxjs/operators';
import {of, interval, Subject, Observable, ReplaySubject} from "rxjs";
import {PersonSeriesGroupApiService} from '../../http/person-series-group-api.service';

@Injectable()
export class NotificationService implements OnDestroy {
  private ngDestroy = new Subject<void>();

  private pendingSeriesSource: ReplaySubject<PageResponse<PendingSeries>> = new ReplaySubject<PageResponse<PendingSeries>>(1);
  private pendingSeriesMetaSource: ReplaySubject<PendingSeriesMeta[]> = new ReplaySubject<PendingSeriesMeta[]>(1);
  private pendingSeriesTargetErrorsSource: ReplaySubject<SeriesTargetError[]> = new ReplaySubject<SeriesTargetError[]>(1);
  private pendingSeriesGroupSource: ReplaySubject<PageResponse<PendingPersonSeriesGroup>> = new ReplaySubject<PageResponse<PendingPersonSeriesGroup>>(1);
  private pendingCancellationSource: ReplaySubject<PendingPersonSeriesCancellation[]> = new ReplaySubject<PendingPersonSeriesCancellation[]>(1);

  public pendingSeries$: Observable<PageResponse<PendingSeries>> = this.pendingSeriesSource.asObservable();
  public pendingSeriesMeta$: Observable<PendingSeriesMeta[]> = this.pendingSeriesMetaSource.asObservable();
  public pendingSeriesTargetErrors$: Observable<SeriesTargetError[]> = this.pendingSeriesTargetErrorsSource.asObservable();
  public pendingSeriesGroups$: Observable<PageResponse<PendingPersonSeriesGroup>> = this.pendingSeriesGroupSource.asObservable();
  public pendingCancellations$: Observable<PendingPersonSeriesCancellation[]> = this.pendingCancellationSource.asObservable();

  public pendingSeriesRemove$: EventEmitter<PendingSeries> = new EventEmitter<PendingSeries>();

  public pendingSeriesFilter: any = {
    page: 0,
    size: 100
  };

  constructor(
    @Inject(SMARTENCITY_MYDATA_CONFIG) private config: MyDataConfig,
    private http: HttpClient,
    private personSeriesService: PersonSeriesApiService,
    private authService: AuthService,
    private userService: UserService,
    private personSeriesGroupService: PersonSeriesGroupApiService
  ) {
    combineLatest([authService.currentMandate$, this.userService.focus$]).pipe(
      switchMap(([mandate, focus]: [Mandate, boolean]) => {
        if (focus) {
          return interval(60000).pipe(
            startWith(mandate),
            map(() => mandate)
          );
        } else {
          return of();
        }
      }),
      takeUntil(this.ngDestroy),
    ).subscribe(() => {
      this.updateRequests();
    });

    authService.authenticationResult$.subscribe(value => {
      if (value.result == false) {
        this.ngOnDestroy();
      }
    });
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  updateRequests() {
    this.loadPendingPendingSeries();
    this.loadPendingPendingSeriesMeta();
    this.loadPendingMappingErrors();
    this.loadPendingSeriesGroups();
    this.loadPendingCancellationRequests();
  }

  loadPendingPendingSeries(pageNumber?: number) {
    if (!pageNumber) pageNumber = 0;
    this.pendingSeriesFilter.page = pageNumber;
    this.personSeriesService.getPendingSeries(this.pendingSeriesFilter).subscribe(
      (page: PageResponse<PendingSeries>) => {
        this.pendingSeriesSource.next(page);
      }
    );
  }

  loadPendingSeriesGroups(pageNumber?: number) {
    if (!pageNumber) pageNumber = 0;
    this.personSeriesGroupService.getPendingConsents(pageNumber).subscribe((page: PageResponse<PendingPersonSeriesGroup>) => {
      this.pendingSeriesGroupSource.next(page);
    });
  }

  loadPendingPendingSeriesMeta() {
    this.personSeriesService.getPendingSeriesMeta().subscribe(
      (data: PendingSeriesMeta[]) => {
        this.pendingSeriesMetaSource.next(data);
      }
    );
  }

  loadPendingMappingErrors() {
    this.personSeriesService.getPendingSeriesTargetErrors().subscribe(
      (data: SeriesTargetError[]) => {
        this.pendingSeriesTargetErrorsSource.next(data);
      }
    );
  }

  loadPendingCancellationRequests() {
    this.personSeriesService.getPendingCancellations().subscribe(
      (data: PendingPersonSeriesCancellation[]) => {
        this.pendingCancellationSource.next(data);
      }
    );
  }
}
