import {Inject, Injectable} from '@angular/core';
import {CanLoad, Route, Router, UrlSegment, UrlTree} from '@angular/router';
import { Observable } from 'rxjs';
import {CoreConfig, SMARTENCITY_CORE_CONFIG} from '@smartencity/core';

@Injectable()
export class AppModulesLoadGuard implements CanLoad {


  constructor(@Inject(SMARTENCITY_CORE_CONFIG) public config: CoreConfig,
              private router: Router) {
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const modules = this.config.modules;

    return new Observable<boolean>((observer) => {
      let result = true;
      if (!modules || modules.names?.indexOf(route.path) == -1) {
        result = false;
      }

      if (!result) {
        this.router.navigate(['/']);
        return;
      }
      observer.next(result);
    });

  }


}
