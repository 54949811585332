<div class="list-item" [ngClass]="{'shared': lastPeriod?.ownerType == 'CONSENT' || lastPeriod?.ownerType == 'GROUP_CONSENT'}">
  <div class="row">
    <div class="col d-flex align-items-center pl-1">
      <ng-template #sharedByInfo>
        <span i18n>Shared by {{personSeries?.createdByPerson?.displayName}}</span>
      </ng-template>
      <div class="group-icon shared mr-3" *ngIf="lastPeriod?.ownerType === 'CONSENT' || lastPeriod?.ownerType == 'GROUP_CONSENT'" [tooltip]="sharedByInfo" placement="right">
        <i class="icon-s icon-shared"></i>
      </div>
      <div>
        <h3 class="mb-1">{{personSeries?.name}}<ng-container *ngIf="showOnMap">&nbsp;<i class="fa fa-map-marker-check"></i></ng-container>
          <span class="value"
                [hidden]="latestValue == null || latestValue.measurement == null">{{latestValue?.measurement?.value|numberFormat:personSeries?.numberFormat:locale}}
            <span [innerHTML]="latestValue?.measurement?.unit|displayUnit:'10'|safeHtml"></span>
          </span>

          <span *ngIf="personSeries?.description">&nbsp;<i class="fa fa-info-circle" [tooltip]="personSeries?.description"></i></span></h3>
        <div class="row">
          <div class="col">
            <span class="text-light text-uppercase">{{location?.getLocalName()}}</span>
        </div>
      </div>
      </div>
      </div>
      <div class="col-auto">
        <div class="d-flex flex-row justify-content-center align-middle" dropdown>
          <div class="align-self-center" *ngIf="lastPeriod?.ownerType != 'CONSENT' && lastPeriod?.ownerType != 'GROUP_CONSENT'" (click)="showConsents()" tooltip="Shared data" i18n-tooltip>
            <share-person-item-list [persons]="getConsentPersons()"></share-person-item-list>
          </div>
          <div class="ml-3 d-flex align-items-center">
            <button class="btn btn-icon icon-md" tooltip="Manage thresholds" i18n-tooltip *ngIf="lastPeriod?.ownerType != 'CONSENT'" [class.active]="activeThresholds?.length" (click)="manageThresholds()"><i class="fal fa-bell"></i></button>
            <button class="btn btn-icon icon-md" tooltip="Add to comparison" i18n-tooltip [class.active]="isInComparison" (click)="addToComparison()" *ngIf="latestValue?.measurement != null && !isInComparison"><i class="fal fa-retweet"></i></button>
            <button class="btn btn-icon icon-md" tooltip="Remove from comparison" i18n-tooltip [class.active]="isInComparison" (click)="removeFromComparison()" *ngIf="latestValue?.measurement != null && isInComparison"><i class="fal fa-retweet"></i></button>
            <!-- download -->

            <button class="btn btn-icon icon-md" tooltip="Manage consents" i18n-tooltip
                    *ngIf="lastPeriod?.ownerType != 'CONSENT' && lastPeriod?.ownerType != 'GROUP_CONSENT'" [class.active]="activeConsents?.length" (click)="showConsents()"><i class="fal fa-share"></i></button>

            <div class="btn-group button-more mb-0" *ngIf="lastPeriod?.ownerType != 'CONSENT' && lastPeriod?.ownerType != 'GROUP_CONSENT' && (personSeries?.sourceUrl || personSeries?.targetUrl)">
              <button type="button" class="btn btn-icon icon-md dropdown-toggle no-caret" dropdownToggle><i class="fal fa-ellipsis-v-alt"></i></button>
              <div class="dropdown-menu dropdown-menu-right" *dropdownMenu>
                <a *ngIf="personSeries.sourceUrl" href="{{personSeries.sourceUrl}}" target="_blank" class="dropdown-item" i18n><i class="fal fa-external-link-alt"></i> Source device</a>
                <a *ngIf="personSeries.targetUrl" href="{{personSeries.targetUrl}}" target="_blank" class="dropdown-item" i18n><i class="fal fa-external-link-alt"></i> Target device</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>
