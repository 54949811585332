import SHA256 from 'crypto-js/sha256';
import * as CryptoJS from 'crypto-js';
import {RandomStringGenerator} from './random-string-generator';

export class PkceUtil {

  public static challenge(codeVerifier: string): string {

    return this.cleanBase64String(SHA256(codeVerifier).toString(CryptoJS.enc.Base64));
  }

  public static base64URLEncode(str: string): string {

    return this.cleanBase64String(CryptoJS.enc.Utf8.parse(str).toString(CryptoJS.enc.Base64));
  }

  private static cleanBase64String(str: string): string {

    return str.replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=/g, '');
  }

  public static generateCodeVerifier(): string {
    const str = this.randomString(128);
    const verifier = this.base64URLEncode(str);

    localStorage.setItem('code_verifier', verifier);

    return verifier;
  }

  public static getCodeVerifier(): string {
    return localStorage.getItem("code_verifier");
  }

  public static getState(): string {
    return localStorage.getItem("state");
  }

  public static generateState(): string {
    const state = this.randomString(40);
    localStorage.setItem("state", state);

    return state;
  }

  private static randomString(length: number) {

    return RandomStringGenerator.randomString(length);
  }

}
