<div class="d-flex">
  <div class="row d-flex no-gutters w-100 position-relative">
    <div id="tooltip"></div>
    <div id="cityDistrictPopover"></div>

    <div class="map-container" style="width: 100%;">

      <div id="map" class="map" #map></div>

      <div style="position: absolute; bottom: 0; right: 0; margin: 20px; background: rgba(255,255,255, 0.7);z-index:9999" *ngIf="layers">
        <div style="display: inline-block" *ngFor="let layer of layers">
          <div style="padding: 10px; display: flex; align-items: center; ">
            <span style="margin: 0 5px;">{{layer.layerName}}</span>
            <img src="{{'data:image/png;base64,' + layer.legend[0].imageData}}" width="20" height="20" alt="{{layer.layerName}}" />
          </div>

        </div>
      </div>

    </div>

    <div class="floating-wrapper">

      <div class="position-relative">
      </div>

      <div class="floating-jumbotron layer-filter">
        <label (click)="toggleLayer({toilets: !displaySettings.toilets})" [class.active]="displaySettings.toilets" tooltip="Show public toilets" i18n-tooltip>
          <i class="icon-s icon-toilets"></i>
        </label>
        <label (click)="toggleLayer({cityDistricts: !displaySettings.cityDistricts})" [class.active]="displaySettings.cityDistricts" tooltip="Show city districts" i18n-tooltip>
          <i class="icon-s icon-city-disctrict"></i>
        </label>
        <label (click)="toggleLayer({residentialAreas: !displaySettings.residentialAreas})" [class.active]="displaySettings.residentialAreas" tooltip="Show residential areas" i18n-tooltip>
          <i class="icon-s icon-city-disctrict"></i>
        </label>
      </div>

    </div>
  </div>
</div>


