import { NgModule } from '@angular/core';
import {CompareButtonComponent} from './components/compare-button/compare-button.component';
import {CompareModalComponent} from './components/compare-modal/compare-modal.component';
import {NgxMatDrpModule} from './modules/ngx-mat-drp/ngx-mat-drp.module';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CustomDropdownControlComponent} from './components/custom-dropdown-control/custom-dropdown-control.component';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';

@NgModule({
  declarations: [
    CompareButtonComponent,
    CompareModalComponent,
    CustomDropdownControlComponent
  ],
  imports: [
    CommonModule,
    NgxMatDrpModule,
    FormsModule,
    ReactiveFormsModule,
    BsDropdownModule
  ],
  exports: [
    CompareButtonComponent,
    CompareModalComponent,
    CustomDropdownControlComponent
  ]
})
export class SharedModule { }
