<div class="modal-header">
  <h5 class="modal-title w-100" i18n>Add parameter</h5>
  <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
    <span class="fal fa-times"></span>
  </button>
</div>
<div class="modal-body" *ngIf="form" [formGroup]="form">
  <div *ngIf="(form.dirty || form.touched) && !form.valid && showErrors" class="alert alert-danger">
    <div i18n>Form is invalid</div>
  </div>

  <div class="form-group">
    <div class="form-row">
      <div class="col">
        <label [attr.for]="id + '-name'" i18n>Name</label>
        <input type="text" class="form-control" [class.is-invalid]="invalid('name')" [id]="id + '-name'" formControlName="name" placeholder="Name" i18n-placeholder>
        <small *ngIf="invalid('name') && errors('name')?.required" class="invalid-feedback d-block" i18n>Required</small>
      </div>
    </div>
  </div>
  <div class="form-group">
    <div class="form-row">
      <div class="col">
        <label i18n [attr.for]="id + '-value'">Value</label>
        <input type="text" class="form-control text-right" [class.is-invalid]="invalid('value')" formControlName="value" [id]="id + '-value'" placeholder="Value" i18n-placeholder>
        <small *ngIf="invalid('value') && errors('value')?.required" class="invalid-feedback d-block" i18n>Required</small>
        <small *ngIf="invalid('value') && errors('value')?.pattern" class="invalid-feedback d-block" i18n>Invalid</small>
      </div>
      <div class="col">
        <label i18n [attr.for]="id + '-unit'">Unit</label>
        <input type="text"  class="form-control" [class.is-invalid]="invalid('unit')" formControlName="unit" [id]="id + '-unit'" placeholder="Unit" i18n-placeholder>
        <small *ngIf="invalid('unit') && errors('unit')?.required" class="invalid-feedback d-block" i18n>Required</small>
      </div>
    </div>
  </div>

  <div class="form-group row" formGroupName="numberFormat">
    <div class="col-8 col-form-label text-left">
      <label for="maxFractionDigits" i18n>Number of digits after decimal point ({{defaultNumberFormat.minFractionDigits}}-{{defaultNumberFormat.maxFractionDigits}})</label>
    </div>

    <div class="col-4">
      <input id="maxFractionDigits" type="number" class="form-control" [class.is-invalid]="invalid('maxFractionDigits', form.get('numberFormat'))" formControlName="maxFractionDigits"
             [min]="defaultNumberFormat.minFractionDigits"
             [max]="defaultNumberFormat.maxFractionDigits">
      <form-field-errors [control]="form.get('numberFormat').get('maxFractionDigits')"></form-field-errors>
    </div>
  </div>

  <div class="form-group" *ngIf="location?.group">
    <div class="form-row">
      <div class="col">
        <label i18n [attr.for]="id + '-groups'">Group</label>
        <ng-select
          class="custom"
          [class.is-invalid]="invalid('groups')"
          [clearable]="false"
          [searchable]="true"
          placeholder="Group"
          i18n-placeholder
          appendTo="body"
          formControlName="groups"
          [typeahead]="groupTypeahead$"
          [items]="group$ | async"
          [selectOnTab]="true"
          [multiple]="true"
          [id]="id + '-groups'"
        >
          <ng-template ng-label-tmp let-item="item" let-clear="clear">
            <span class="ng-value-icon left" *ngIf="item.editable" (click)="clear(item)" aria-hidden="true">×</span>
            <span class="ng-value-label">{{item.group.name}}</span>
          </ng-template>
          <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
            <span>{{item.group.name}}</span>
          </ng-template>
        </ng-select>
      </div>
    </div>
    <small *ngIf="invalid('groups') && errors('groups')?.required" class="invalid-feedback d-block" i18n>Required</small>
  </div>

  <div class="form-group">
    <label i18n>Location type</label>

    <div class="form-row">
      <div class="col">
        <div class="custom-control custom-radio custom-control-inline">
          <input type="radio" class="custom-control-input" [class.is-invalid]="invalid('locationType')" [id]="id + '-ltcheck-none'" [value]="null" formControlName="locationType">
          <label class="custom-control-label" [attr.for]="id + '-ltcheck-none'" i18n>None</label>
        </div>

        <div class="custom-control custom-radio custom-control-inline">
          <input type="radio" class="custom-control-input" [class.is-invalid]="invalid('locationType')" [id]="id + '-ltcheck-address'" value="ADDRESS" formControlName="locationType">
          <label class="custom-control-label" [attr.for]="id + '-ltcheck-address'" i18n>Address</label>
        </div>

        <div class="custom-control custom-radio custom-control-inline">
          <input type="radio" class="custom-control-input" [class.is-invalid]="invalid('locationType')" [id]="id + '-ltcheck-uuid'" value="UUID" formControlName="locationType">
          <label class="custom-control-label" [attr.for]="id + '-ltcheck-uuid'" i18n>UUID</label>
        </div>

        <div class="custom-control custom-radio custom-control-inline">
          <input type="radio" class="custom-control-input" [class.is-invalid]="invalid('locationType')" [id]="id + '-ltcheck-coords'" value="COORDS" formControlName="locationType">
          <label class="custom-control-label" [attr.for]="id + '-ltcheck-coords'" i18n>Coords</label>
        </div>
      </div>
    </div>

    <small *ngIf="invalid('locationType') && errors('locationType')?.required" class="invalid-feedback d-block" i18n>Required</small>
  </div>

  <div class="form-group" [hidden]="form.get('locationType').value != 'UUID'">
    <div class="form-row">
      <div class="col">
        <label i18n [attr.for]="id + '-uuid'">UUID</label>
        <input type="text" class="form-control" [class.is-invalid]="invalid('uuid')" formControlName="uuid" [id]="id + '-uuid'">
        <small *ngIf="invalid('uuid') && errors('uuid')?.required" class="invalid-feedback d-block" i18n>Required</small>
      </div>
    </div>
  </div>
  <div class="form-group" [hidden]="form.get('locationType').value != 'COORDS'">
    <div class="form-row">
      <div class="col">
        <label i18n [attr.for]="id + '-lat'">Lat</label>
        <input type="text" class="form-control" [class.is-invalid]="invalid('lat')" formControlName="lat" [id]="id + '-lat'">
        <small *ngIf="invalid('lat') && errors('lat')?.required" class="invalid-feedback d-block" i18n>Required</small>
      </div>
    </div>
  </div>
  <div class="form-group" [hidden]="form.get('locationType').value != 'COORDS'">
    <div class="form-row">
      <div class="col">
        <label i18n [attr.for]="id + '-lng'">Lng</label>
        <input type="text" class="form-control" [class.is-invalid]="invalid('lng')" formControlName="lng" [id]="id + '-lng'">
        <small *ngIf="invalid('lng') && errors('lng')?.required" class="invalid-feedback d-block" i18n>Required</small>
      </div>
    </div>
  </div>

  <core-address *ngIf="form.get('locationType').value == 'ADDRESS'" [form]="form" [location]="location" [showErrors]="showErrors"></core-address>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-light btn-sm" aria-label="Close" (click)="close()" i18n>Close</button>
  <button type="button" class="btn btn-primary btn-sm" (click)="save()" i18n>Save</button>
</div>
