import {Inject, Injectable} from '@angular/core';
import {Logger, LogLevel} from '../models/logger';
import {HttpClient} from '@angular/common/http';
import {stringify} from 'querystring-es3';
import {CoreConfig} from '../core-config.model';
import {SMARTENCITY_CORE_CONFIG} from '../injection-tokens';

@Injectable({providedIn: 'root'})
export class LoggerService implements Logger {
  isDebugMode = false;
  logLevel: LogLevel;

  public static stringify(obj: any) {
    if (typeof obj !== 'object') {
      return obj;
    }
    // Note: cache should not be re-used by repeated calls to JSON.stringify.

    const cache = [];
    return JSON.stringify(obj, (key, value) => {
      if (typeof value === 'object' && value !== null) {
        if (cache.indexOf(value) !== -1) {
          // Duplicate reference found, discard key
          return;
        }
        // Store value in our collection
        cache.push(value);
      }
      return value;
    });
  }

  constructor(
    @Inject(SMARTENCITY_CORE_CONFIG) private config: CoreConfig,
    private http: HttpClient
  ) {
    this.isDebugMode = !config.production;

    const logLevel = this.config.logLevel;

    if (logLevel) {
      switch (logLevel) {
        case 'OFF':
          this.logLevel = LogLevel.OFF;
          break;
        case 'ERROR':
          this.logLevel = LogLevel.ERROR;
          break;
        case 'WARN':
          this.logLevel = LogLevel.WARN;
          break;
        case 'INFO':
          this.logLevel = LogLevel.INFO;
          break;
        case 'DEBUG':
          this.logLevel = LogLevel.DEBUG;
          break;
        case 'TRACE':
          this.logLevel = LogLevel.TRACE;
          break;
        case 'ALL':
          this.logLevel = LogLevel.ALL;
          break;
      }
    }
  }

  trace(...args: any[]) {
    if (this.isDebugMode) {
      return console.trace(args);
    }
    if (this.logLevel > LogLevel.TRACE) {
      return;
    }
    this.http.post(this.config.apiUrl + '/log', {level: 'trace', message: args.length > 1 ? args : args[0]}, {withCredentials: true}).subscribe();
  }

  debug(...args: any[]) {
    if (this.isDebugMode) {
      return console.debug(args);
    }
    if (this.logLevel > LogLevel.DEBUG) {
      return;
    }
    this.http.post(this.config.apiUrl + '/log', {level: 'debug', message: args.length > 1 ? args : args[0]}, {withCredentials: true}).subscribe();
  }

  info(...args: any[]) {
    if (this.isDebugMode) {
      return console.info(args);
    }
    if (this.logLevel > LogLevel.INFO) {
      return;
    }
    this.http.post(this.config.apiUrl + '/log', {level: 'info', message: args.length > 1 ? args : args[0]}, {withCredentials: true}).subscribe();
  }

  warn(...args: any[]) {
    if (this.isDebugMode) {
      return console.warn(args);
    }
    if (this.logLevel > LogLevel.WARN) {
      return;
    }
    this.http.post(this.config.apiUrl + '/log', {level: 'warn', message: args.length > 1 ? args : args[0]}, {withCredentials: true}).subscribe();
  }

  error(...args: any[]) {
    // if (this.isDebugMode) {
    //   return console.error(args);
    // }
    // if (this.logLevel > LogLevel.ERROR) {
    //   return;
    // }
    this.http.post('/log/error', {level: 'error', message: args.length > 1 ? args.map(LoggerService.stringify) : stringify(args[0])}, {withCredentials: true}).subscribe();
  }
}
