import { NgModule } from '@angular/core';
import { ArcGISComponent } from './arc-gis.component';
import {ArcGISRoutingModule} from './arc-gis-routing.module';
import {CoreModule} from '@smartencity/core';
import {CommonModule} from '@angular/common';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {ArcGisComponentMapService} from './arc-gis-component-map.service';
import { ArcGisFeatureFieldsComponent } from './arc-gis-feature-fields.component';


@NgModule({
  declarations: [
    ArcGISComponent,
    ArcGisFeatureFieldsComponent
  ],
  imports: [
    CommonModule,
    CoreModule,
    ArcGISRoutingModule,
    TooltipModule
  ],
  exports: [
    ArcGISComponent,
    ArcGisFeatureFieldsComponent
  ],
  providers: [
    ArcGisComponentMapService
  ]
})
export class ArcGISModule { }
