import {
  Dataset, FirstValue,
  PersonParameter,
  PersonSeries,
  QuestionnaireField,
  QuestionnaireReference,
  QuestionnaireResponseField,
  QuestionnaireResultsResponseField,
  ResponseRef,
  SeriesNumberFormat,
  Threshold
} from '@smartencity/core';
import {Dashboard, DashboardType} from './dashboard';

export class Widget implements IWidget {
  id: number;
  name: string;
  dashboard: Dashboard;
  type: WidgetType = WidgetType.VALUE_TABLE;
  component: string;
  chartType: ChartType = ChartType.BAR;
  chartColorScheme = '';
  aggregationGroupingType = 'HOURLY';
  periodType = 'HOUR';
  periodCount = 1;
  differentiate = false;
  timeframeType = 'PERIOD';
  periodStartAt: Date;
  periodEndAt: Date;
  sortOrder = 0;
  position: WidgetPosition = new WidgetPosition(0,0, 5,5);
  showLegend = false;
  showYAxes = false;
  showDataLabels = false;
  showAverageLine = false;
  datasets: WidgetDataset[] = [];
  templateId: number;
}

export interface IWidget {
  id: number;
  name: string;
  dashboard: Dashboard;
  type: WidgetType;
  component: string;
  chartType: ChartType;
  chartColorScheme: string;
  aggregationGroupingType: string;
  periodType: string;
  periodCount: number;
  differentiate: boolean;
  timeframeType: string;
  periodStartAt: Date;
  periodEndAt: Date;
  sortOrder: number;
  position: WidgetPosition;
  showLegend: boolean;
  showYAxes: boolean;
  showDataLabels: boolean;
  showAverageLine: boolean;
  datasets: IWidgetDataset[];
}

export interface IWidgetDataset {
  id: number;
  sortOrder: number;
  type: string;
  personParameterId: number;
  personSeriesId: number;
  personSeries: PersonSeries;
  personParameter: PersonParameter;
  q11eField: QuestionnaireField;
  q11eResponseField: QuestionnaireResponseField;
  q11eName: string;
  q11eFieldId: number;
  q11eResponseFieldId: number;
  name: string;
  location: string;
  aggregationType: string;
  graphType: string;
  groupName: string;
  scaleMin: number;
  scaleMax: number;
  color: string;
  unit: string;
  createdAt: Date;
  differentiate: boolean;
  numberFormat: SeriesNumberFormat;
}

export interface WidgetTemplatePersonSeriesObject {
  id: number;
  name: string;
  location: string;
}

export class WidgetTemplate implements IWidget {
  id: number;
  name: string;
  dashboard: Dashboard;
  type: WidgetType = WidgetType.VALUE_TABLE;
  component: string;
  chartType: ChartType = ChartType.BAR;
  chartColorScheme = '';
  aggregationGroupingType = 'HOURLY';
  periodType = 'HOUR';
  periodCount = 1;
  differentiate = false;
  timeframeType = 'PERIOD';
  periodStartAt: Date;
  periodEndAt: Date;
  sortOrder = 0;
  position: WidgetPosition = new WidgetPosition(0,0, 5,5);
  showLegend = false;
  showYAxes = false;
  showDataLabels = false;
  showAverageLine = false;
  datasets: WidgetTemplateDataset[] = [];
}

export class DashboardWidgetTemplate {
  id: number;
  name: string;
  q11e: QuestionnaireReference;
  response: ResponseRef;
}

export class WidgetTemplateDataset implements IWidgetDataset {
  id: number;
  sortOrder: number;
  type: string;
  personParameterId: number;
  personSeries: PersonSeries;
  personParameter: PersonParameter;
  q11eField: QuestionnaireField;
  q11eResponseField: QuestionnaireResponseField;
  personSeriesId: number;
  templateFieldId: number;
  q11eName: string;
  q11eFieldId: number;
  q11eResponseFieldId: number;
  name: string;
  location: string;
  aggregationType: string;
  graphType: string;
  groupName: string;
  scaleMin: number;
  scaleMax: number;
  color: string;
  unit: string;
  templateField: boolean;
  createdAt: Date;
  differentiate = false;
  numberFormat: SeriesNumberFormat;

  constructor(ds?: Dataset) {
    if (ds) {
      this.id = null;
      this.type = ds.type;
      if (ds.personSeriesId) {
        this.personSeriesId = ds.personSeriesId;
        const personSeries = new PersonSeries();
        personSeries.id = ds.personSeriesId;
        personSeries.name = ds.name;
        this.personSeries = personSeries;
      }

      if (ds.personParameterId) {
        this.personParameterId = ds.personSeriesId;
        const personParameter = new PersonParameter();
        personParameter.id = ds.personParameterId;
        personParameter.name = ds.name;
        this.personParameter = personParameter;
      }

      let q11eField = null;
      if (ds.q11eFieldId) {
        this.q11eFieldId = ds.q11eFieldId;
        q11eField = new QuestionnaireField();
        q11eField.id = ds.q11eFieldId;
        q11eField.name = ds.name;
      }
      this.q11eField = q11eField;

      if (ds.q11eResponseFieldId && q11eField) {
        this.q11eResponseFieldId = ds.q11eResponseFieldId;
        const q11eResponseField = new QuestionnaireResponseField();
        q11eResponseField.id = ds.q11eResponseFieldId;
        q11eResponseField.field = q11eField;
        this.q11eResponseField = q11eResponseField;
      }

      this.name = ds.name;
      this.location = ds.type === 'PERSON_SERIES' ? ds.location : ds.q11eName;
      this.color = ds.color;
      this.unit = ds.unit;
      this.createdAt = ds.createdAt;
    }
  }
}

export class WidgetPosition {
  x: number;
  y: number;
  cols: number;
  rows: number;

  constructor(x: number, y: number, cols: number, rows: number) {
    this.x = x;
    this.y = y;
    this.cols = cols;
    this.rows = rows;
  }
}

export class WidgetDataset implements IWidgetDataset{
  id: number;
  sortOrder: number;
  type: string;
  personParameterId: number;
  personSeriesId: number;
  personSeries: PersonSeries;
  personParameter: PersonParameter;
  q11eField: QuestionnaireField;
  q11eResponseField: QuestionnaireResponseField
  q11eName: string;
  q11eFieldId: number;
  q11eResponseFieldId: number;
  name: string;
  location: string;
  aggregationType: string;
  graphType: string;
  groupName: string;
  scaleMin: number;
  scaleMax: number;
  color: string;
  unit: string;
  createdAt: Date;
  differentiate = false;
  numberFormat: SeriesNumberFormat;

  constructor(props?: any) {
    if (!props) return;

    this.id = props.id;
    this.sortOrder = props.sortOrder ?? null;
    this.type = props.type;

    if (props.personSeriesId) {
      this.personSeriesId = props.personSeriesId;
      const personSeries = new PersonSeries();
      personSeries.id = props.personSeriesId;
      personSeries.name = props.name;
      this.personSeries = personSeries;
    }

    if (props.personParameterId) {
      this.personParameterId = props.personParameterId;
      const personParameter = new PersonParameter();
      personParameter.id = props.personParameterId;
      personParameter.name = props.name;
      this.personParameter = personParameter;
    }

    let q11eField = null;
    if (props.q11eFieldId) {
      this.q11eFieldId = props.q11eFieldId;
      q11eField = new QuestionnaireField();
      q11eField.id = props.q11eFieldId;
      q11eField.name = props.name;
    }

    this.q11eField = q11eField;

    if (props.q11eResponseFieldId && q11eField) {
      this.q11eResponseFieldId = props.q11eResponseFieldId;
      const q11eResponseField = new QuestionnaireResponseField();
      q11eResponseField.id = props.q11eResponseFieldId;
      q11eResponseField.field = q11eField;
      this.q11eResponseField = q11eResponseField;
    }

    this.name = props.name;
    this.location = props.location;
    this.color = props.color;
    this.unit = props.unit;
    this.createdAt = props.createdAt;
    this.aggregationType = props.aggregationType;
    this.graphType = props.graphType;
  }
}

export class WidgetPersonSeries {
  id: number;
  location: string;
  name: string;
}

export enum WidgetType {
  TIME_FRAME_CHART = 'TIME_FRAME_CHART',
  VALUE_CHART = 'VALUE_CHART',
  VALUE_TABLE = 'VALUE_TABLE',
  COMPONENT = 'COMPONENT'
}

export enum ChartType {
  LINE = 'LINE',
  BAR = 'BAR',
  DOUGHNUT = 'DOUGHNUT',
  PIE = 'PIE',
  POLAR_AREA = 'POLAR_AREA',
  RADAR = 'RADAR'
}

export class WidgetPersonSeriesValue {
  lastValue: LastValue;
  periodFirstValue?: FirstValue;
  periodLastValue: LastValue;
  measurement: MeasurementHistoricalValue;
  thresholds: Threshold[];
}

export class WidgetPersonParameterValue {
  name: string;
  value: number;
  unit: string;
}

export class WidgetQ11eFieldValue {
  field: QuestionnaireField;
  responses: QuestionnaireResultsResponseField[];
  text: string;
  value: number;
  option: string;
  personParameter: PersonParameter;
  personSeries: PersonSeries;
}

export class WidgetQ11eResponseFieldValue {
  id: number;
  participantId: number;
  type: string;
  text: string;
  value: number;
  option: string;
  personParameter: PersonParameter;
  personSeries: PersonSeries;
  unit: string;
  coefficient: number;
  timeExponent: number;
  deltaReading: number;
}

export class LastValue {
  event: any;
  measurement: Measurement;
}

export class MeasurementHistoricalValue {
  series: SeriesReading;
}

export class SeriesReading {
  values: any;
  series: any[];
  truncated: boolean;
}

export class Measurement {
  time: any;
  unit: string;
  value: any;
}

export class WidgetDatasetValue {
  widgetDataset: WidgetDataset;
  personSeriesValue: WidgetPersonSeriesValue;
  personParameterValue: WidgetPersonParameterValue;
  q11eFieldValue: WidgetQ11eFieldValue;
  q11eResponseFieldValue: WidgetQ11eResponseFieldValue;
  thresholds: Threshold[];
}

export class WidgetFromQuestionnaireResponseBulk {
  dashboardType: DashboardType;
  templateIds: number[] = [];
  responseId: number;
}

export class WidgetFromQuestionnaireResponse {
  dashboardType: DashboardType;
  templateId: number;
  responseId: number;
}

export class CreateFromWidgetTemplateRequest {
  q11eResponseId: number;
  widgetTemplateId: number;
  position: WidgetPosition;
  dashboardType: DashboardType;
  sortOrder?: number = 0;
}
