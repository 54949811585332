<div class="modal-header d-flex align-items-center">
  <div class="d-flex align-items-stretch flex-column">
    <h5 class="modal-title w-100">{{consent.person.displayName}}</h5>
    <small i18n>Shared data</small>
  </div>
  <button type="button" class="close pull-right" i18n-aria-label aria-label="Close" (click)="close()">
    <i class="fal fa-times"></i>
  </button>
</div>
<div class="modal-body" style="min-height: 700px" #scrollingBlock>
  <div class="row" [formGroup]="filterForm">
    <div class="col-6">
      <div class="form-group">
        <input type="text" name="query" formControlName="query" class="form-control" placeholder="Shared data name" i18n-placeholder autocomplete="off">
      </div>
    </div>
    <div class="col-6">
<!--      <div class="form-group">-->
<!--        <ng-select placeholder="Status" i18n-placeholder formControlName="isOwnerPersonSeries" [items]="ownerPersonSeriesOptions" bindValue="value" bindLabel="label"></ng-select>-->
<!--      </div>-->
    </div>
  </div>

  <div class="row mb-3" *ngIf="items.length > 1">
    <div class="col-md-6 d-flex align-items-center">
      <div class="checkbox"><input type="checkbox" id="checkAll" class="checkbox" [formControl]="checkAllControl" #checkAllEl /> <label for="checkAll" i18n>Select all</label></div>
    </div>
    <div class="col-md-6 d-flex justify-content-end" *ngIf="cancelCount > 0">
      <button (click)="endSelectedConsents()" type="button" class="btn btn-sm btn-primary"><ng-container i18n>End selected consents ({{cancelCount}})</ng-container></button>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <virtual-scroller #listScroll [items]="items" [parentScroll]="scrollingBlock" (vsChange)="chartVsChange($event)">
        <div class="list" #container>
          <div class="list-item" *ngFor="let item of listScroll.viewPortItems">
            <div class="row d-flex align-items-center" *ngIf="item">
              <div class="col-md-1" *ngIf="items.length > 1">
                <div class="checkbox"><input type="checkbox" class="checkbox" [formControl]="item.checked"/></div>
              </div>
              <div class="col">
                <span i18n *ngIf="item.consent.type == 'GROUP_CONSENT'">Data point group</span>
                <span i18n *ngIf="item.consent.type != 'GROUP_CONSENT'">Data point</span>
              </div>
              <div class="col text-break">{{item.consent.name}}</div>
              <div class="col text-break text-right"> 
                <button *ngIf="!item.consent.endAt &&
                (item.consent.type == 'CONSENT' || item.consent.type == 'GROUP_CONSENT')" (click)="endConsent(item.consent)" type="button" class="btn btn-sm btn-secondary">
                  <i class="fa fa-times"></i>&nbsp;<ng-container i18n>End consent</ng-container>
                </button>
              </div>
            </div>
          </div>
        </div>
      </virtual-scroller>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary btn-sm" aria-label="Close" (click)="close()" i18n>Close</button>
</div>

