<div class="modal-header">
  <h5 class="modal-title w-100">{{personAdviceGroup?.name}}</h5>
  <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
    <span class="fal fa-times"></span>
  </button>
</div>
<div class="modal-body">
  <div class="info-block p-3 mb-3 w-100" [hidden]="!personAdviceGroup?.description">
    {{personAdviceGroup?.description}}
  </div>

  <table class="table table-striped table-sm">
    <thead>
    <tr>
      <th i18n>Category</th>
      <th i18n>Description</th>
      <th class="text-right" style="white-space: nowrap" i18n>Mark as done</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let row of rows">
      <td class="text-uppercase" style="white-space: nowrap"><strong>{{row.personAdvice.category}}</strong></td>
      <td><h5 class="text-uppercase">{{row.personAdvice.name}}</h5><p *ngIf="row.personAdvice.description">{{row.personAdvice.description}}</p></td>
      <td class="text-right">
        <div class="custom-checkbox">
          <!-- checkbos row.checkControl -->
        </div>
      </td>
    </tr>
    </tbody>
  </table>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-light btn-sm" aria-label="Close" i18n (click)="close()">Close</button>
  <button type="button" class="btn btn-primary btn-sm" i18n (click)="save()" [disabled]="progress && true">Save</button>
</div>
