<ng-template #navActions>
  <ng-container *ngIf="myQuestionnairesEnabled">
    <button type="button" class="btn btn-sm btn-primary d-none d-md-inline-block" routerLink="add">
      <i class="fal fa-plus"></i>&nbsp;<span i18n>Create new</span>
    </button>
    <!--mobile-->
    <div class="btn-floating-mobile-container">
      <button type="button" class="btn btn-sm btn-primary btn-floating-mobile" routerLink="add">
        <i class="fal fa-plus fa-2x"></i>
      </button>
    </div>
  </ng-container>
</ng-template>

<div class="container-fluid p-4">

  <div class="row justify-content-center mb-5">
    <div class="col-sm-12 col-md-6 col-lg-4">
      <div class="input-group input-group-lg form-search" [formGroup]="filterForm">
        <input type="text" class="form-control form-control-lg" placeholder="Type to search..." i18n-placeholder formControlName="query">
        <div class="input-group-append">
          <span class="clear-search" (click)="clearSearch()">
            <i class="fal fa-times"></i>
          </span>
          <div class="custom-dropdown filter-dropdown" dropdown [autoClose]="true" (click)="$event.stopImmediatePropagation();">
            <button type="button" class="btn dropdown-toggle" dropdownToggle i18n>Filter</button>
            <div class="dropdown-menu dropdown-menu-right" *dropdownMenu>
              <div class="accordion-group"  [ngClass]="'show'">
                <div class="accordion-header">
                  <i class="fal fa-angle-down"></i>
                  <ng-container i18n>Status</ng-container>
                </div>
                <div class="accordion-content">
                  <div class="checkbox">
                    <input type="checkbox" formControlName="statusDraft">
                    <label>{{'DRAFT' | label}}</label>
                  </div>
                  <div class="checkbox">
                    <input type="checkbox" formControlName="statusSent">
                    <label>{{'SENT' | label}}</label>
                  </div>
                  <div class="checkbox">
                    <input type="checkbox" formControlName="statusEdited">
                    <label>{{'EDITED' | label}}</label>
                  </div>
                  <div class="checkbox">
                    <input type="checkbox" formControlName="statusCompleted">
                    <label>{{'COMPLETED' | label}}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <span class="input-group-text">
            <i class="fal fa-search"></i>
          </span>
        </div>
      </div>
    </div>
    <div class="col-12 mt-2">
      <div class="d-flex flex-wrap flex-md-nowrap justify-content-center" *ngIf="filterForm.get('statusDraft').value ||
      filterForm.get('statusSent').value ||
      filterForm.get('statusCompleted').value || filterForm.get('statusEdited').value">
        <label i18n>Filter</label>
        <span class="filter-tag" *ngIf="filterForm.get('statusDraft').value">{{'DRAFT' | label}} <i class="fal fa-times" (click)="filterForm.get('statusDraft').setValue(false)"></i></span>
        <span class="filter-tag" *ngIf="filterForm.get('statusSent').value">{{'SENT' | label}} <i class="fal fa-times" (click)="filterForm.get('statusSent').setValue(false)"></i></span>
        <span class="filter-tag" *ngIf="filterForm.get('statusEdited').value">{{'EDITED' | label}} <i class="fal fa-times" (click)="filterForm.get('statusEdited').setValue(false)"></i></span>
        <span class="filter-tag" *ngIf="filterForm.get('statusCompleted').value">{{'COMPLETED' | label}} <i class="fal fa-times" (click)="filterForm.get('statusCompleted').setValue(false)"></i></span>
      </div>
    </div>
  </div>

  <section class="mb-4">
    <h2 class="section-title"><ng-container i18n>Active</ng-container>&nbsp;({{rows?.length || 0}})</h2>
    <div class="row">
      <div class="col-12">
        <ngx-datatable
          #table
          class="material expandable"
          [draggable]="false"
          [columnMode]="'flex'"
          [headerHeight]="30"
          [footerHeight]="50"
          [reorderable]="false"
          rowHeight="auto"
          [scrollbarH]="false"
          [scrollbarV]="false"
          [rows]="rows"
          [externalPaging]="false"
          [externalSorting]="false"
          [count]="page?.totalElements"
          [limit]="page?.size"
          [sorts]="defaultSorts"
          (sort)="setSorts($event)"
        >
          <ngx-datatable-row-detail [rowHeight]="175" #detailRow>
            <ng-template
              let-row="row"
              let-participantCount="row.participantCount"
              let-completedCount="row.completedCount"
              let-name="row.name"
              let-questionnaire="row.questionnaire"
              let-status="row.status"
              let-updatedAt="row.updatedAt"
              ngx-datatable-row-detail-template
            >
              <div style="font-size:14px; padding: 0.7rem">

                <div class="row">
                  <div class="col-6"><label i18n>Results</label></div>
                  <div class="col-6">
                    <button class="btn btn-link btn-xs p-0" *ngIf="questionnaire.status !== 'DRAFT' && questionnaire.personSeriesGroup && row.containsFormulas" (click)="viewDatapoints(questionnaire)" i18n>View</button>
                  </div>
                </div>

                <div class="row">
                  <div class="col-6"><label i18n>Status</label></div>
                  <div class="col-6">
                    <span class="status" [ngSwitch]="status">
                      <i class="icon-status status-pending" *ngSwitchCase="'DRAFT'"></i>
                      <i class="icon-status status-pending" *ngSwitchCase="'SENT'"></i>
                      <i class="icon-status status-pending" *ngSwitchCase="'EDITED'"></i>
                      <i class="icon-status status-success" *ngSwitchCase="'COMPLETED'"></i>
                      {{status | label:'Q11E_STATUS_TYPE'}}
                    </span>
                  </div>
                </div>

                <div class="row">
                  <div class="col-6"><label i18n>Updated at</label></div>
                  <div class="col-6">{{updatedAt | date:'dd. MMMM yyyy'}}</div>
                </div>

                <div class="row pt-3">
                  <button type="button" class="btn btn-sm btn-link no-caret" (click)="copyQuestionnaire(row.questionnaire)" *ngIf="row.questionnaire">
                    <i class="fal fa-copy mr-2"></i>
                    <ng-container i18n>Copy</ng-container>
                  </button>
                  <button type="button" class="btn btn-sm btn-link no-caret" (click)="deleteQuestionnaire(row.questionnaire)" *ngIf="row.questionnaire && !row.participantCount">
                    <i class="fal fa-trash mr-2"></i>
                    <ng-container i18n>Delete</ng-container>
                  </button>
                </div>
              </div>
            </ng-template>
          </ngx-datatable-row-detail>

          <ngx-datatable-column *ngIf="columns.name" name="Name" i18n-name prop="name" [flexGrow]="2" [resizeable]="false" [sortable]="true">
            <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
              <a [routerLink]="null" class="title" (click)="openQuestionnaire(row.questionnaire)">{{value}}</a>
              <span class="text-light text-uppercase"><ng-container i18n>Participants</ng-container>: {{row.completedCount}} / {{row.participantCount}} <i class="icon-sm icon-reanswered ml-1"></i></span>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column *ngIf="columns.results" name="Results" i18n-name [flexGrow]="1" [resizeable]="false" [sortable]="false">
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
              <button class="btn btn-link btn-xs p-0" *ngIf="row.questionnaire.status !== 'DRAFT' && row.questionnaire.personSeriesGroup && row.containsFormulas" (click)="viewDatapoints(row.questionnaire)" i18n>View</button>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column *ngIf="columns.status" name="Status" i18n-name prop="status" [flexGrow]="1" [resizeable]="false" [sortable]="false" [cellClass]="'justify-content-center'"  [headerClass]="'text-center'">
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
              <span class="status" [ngSwitch]="value">
                <i class="icon-status status-pending" *ngSwitchCase="'DRAFT'"></i>
                <i class="icon-status status-pending" *ngSwitchCase="'SENT'"></i>
                <i class="icon-status status-pending" *ngSwitchCase="'EDITED'"></i>
                <i class="icon-status status-success" *ngSwitchCase="'COMPLETED'"></i>
                {{value | label:'Q11E_STATUS_TYPE'}}
              </span>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column *ngIf="columns.updatedAt" name="Updated" i18n-name prop="updatedAt" [flexGrow]="1" [resizeable]="false" [sortable]="true">
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>{{value | date:'dd. MMMM yyyy'}}</ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column *ngIf="columns.actions" [flexGrow]="2" [resizeable]="false" [sortable]="false" [cellClass]="'justify-content-end'">
            <ng-template let-row="row" ngx-datatable-cell-template>
              <div class="btn-group btn-more" dropdown>
                <button type="button" class="btn btn-icon dropdown-toggle" dropdownToggle><i class="far fa-ellipsis-v"></i></button>
                <div class="dropdown-menu dropdown-menu-right" *dropdownMenu>
                  <a class="dropdown-item" (click)="edit(row.questionnaire)" *ngIf="row.questionnaire"><i class="fal fa-edit"></i> <ng-container i18n>Edit</ng-container></a>
                  <a class="dropdown-item" (click)="copyQuestionnaire(row.questionnaire)" *ngIf="row.questionnaire && myQuestionnairesEnabled"><i class="fal fa-copy"></i> <ng-container i18n>Copy</ng-container></a>
                  <a class="dropdown-item" *ngIf="row.questionnaire && !row.participantCount" (click)="deleteQuestionnaire(row.questionnaire)"><i class="fal fa-trash"></i> <ng-container i18n>Delete</ng-container></a>
                </div>
              </div>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            *ngIf="columns.detailToggler"
            [width]="50"
            [resizeable]="false"
            [sortable]="false"
            [draggable]="false"
            [canAutoResize]="false"
            headerClass="justify-content-end"
            cellClass="justify-content-end"
          >
            <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
              <a
                [routerLink]="null"
                [class.datatable-icon-right]="!expanded"
                [class.datatable-icon-down]="expanded"
                title="Expand/Collapse Row"
                (click)="toggleExpandRow(row)"
              >
              </a>
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>
      </div>
    </div>
  </section>
</div>
