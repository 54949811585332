import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SMARTENCITY_MYDATA_CONFIG } from '../../../injection-tokens';
import { MyDataConfig } from '../../../mydata-config.model';
import { FormControl, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged, map, switchMap, takeUntil } from 'rxjs/operators';
import { Person, PersonRegistrationNumberValidator, UserService } from '@smartencity/core';
import { Subject } from 'rxjs/internal/Subject';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

const selector = 'mydata-set-owner-modal';
let nextId = 0;

@Component({
  selector: selector,
  templateUrl: './set-owner-modal.component.html',
})
export class SetOwnerModalComponent implements OnDestroy, OnInit {
  id = `${selector}-${nextId++}`;
  private ngDestroy: Subject<void> = new Subject();
  public progress = false;
  public showErrors = false;

  @Output('saved')
  savedEmitter: EventEmitter<any> = new EventEmitter<any>();

  rows: any[];
  person: FormControl;

  personTypeahead$ = new Subject<string>();
  personItems$ = this.personTypeahead$.pipe(
    takeUntil(this.ngDestroy),
    distinctUntilChanged(),
    debounceTime(250),
    switchMap((term: string) => this.userService.searchPersons(term)),
    map((persons: Person[]) => {
      return persons.map((e) => ({
        countryCode: e.countryCode,
        registrationNumber: e.registrationNumber,
        displayName: e.displayName,
        juridical: e.juridical,
        idPrivacyType: e.idPrivacyType,
        dob: e.dob
      }));
    })
  );

  public createPerson = (registrationNumber: string) => {
    return {
      countryCode: this.config.defaultCountryCode,
      registrationNumber: registrationNumber,
      displayName: null
    };
  };

  constructor(
    private modalRef: BsModalRef,
    private http: HttpClient,
    @Inject(SMARTENCITY_MYDATA_CONFIG) private config: MyDataConfig,
    private userService: UserService,
    public personRegistrationNumberValidator: PersonRegistrationNumberValidator,
    private toastr: ToastrService
  ) {
    this.person = new FormControl(null, [Validators.required, this.personRegistrationNumberValidator.check.bind(this.personRegistrationNumberValidator)]);

    this.personTypeahead$.pipe(takeUntil(this.ngDestroy)).subscribe((value) => {
      if (value) {
        const person = this.createPerson(value);
        this.person.setValue(person);
      }
    });
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  ngOnInit(): void {
  }

  close() {
    this.modalRef.hide();
  }

  save() {
    this.person.markAsTouched();
    if (!this.person.valid) {
      this.showErrors = true;
      return;
    }
    this.showErrors = false;

    const selectedRows = this.rows.filter((r) => r.checkControl.value);
    if (!selectedRows.length) {
      return;
    }

    const statusDto = {
      seriesIds: selectedRows.map((row: any) => row.series.id),
      personCountryCode: this.person.value.countryCode ? this.person.value.countryCode : this.config.defaultCountryCode,
      personRegistrationNumber: this.person.value.registrationNumber ? this.person.value.registrationNumber.trim() : this.person.value.registrationNumber
    };

    this.progress = true;
    this.http.put(this.config.apiUrl + '/series/data-owner', statusDto).subscribe((data: any) => {
      this.rows = [];
      this.modalRef.hide();
      this.savedEmitter.emit();
      this.toastr.success($localize`Owner changed`);
    }, (error) => {
      this.progress = false;
      console.error(error);
      this.toastr.error($localize`Changing owner failed`);
    }, () => this.progress = false);
  }
}
