export class ModuleLabelHelper {

  private static moduleNameMap: any = {
    'buildings': $localize`Buildings`,
    'energy': $localize`Energy`,
    'people': $localize`People`,
    'weather': $localize`Weather`,
    'lights': $localize`Lights`,
    'services': $localize`City services`,
    'mobility': $localize`Mobility`,
    'gas': $localize`All stations`,
    'tallinn': $localize`Environment`,
    'my-data': $localize`My data`,
    'meteorology': $localize`Meteorology`,
    'publicdata': $localize`Public data`
  }

  public static getLabel(siteName: string, moduleName: string, defaultLabel: string): string {
    if (siteName == 'Sønderborg' && moduleName == 'buildings') {
      return this.moduleNameMap['energy'];
    }

    return this.moduleNameMap[moduleName] ? this.moduleNameMap[moduleName] : defaultLabel;
  }

}
