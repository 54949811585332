<core-navbar></core-navbar>
<div class="d-flex">
  <div class="row d-flex no-gutters w-100 position-relative">
    <div class="map-container" style="width: 100%;">
      <div id="citymapContainer" class="citymap map" #gmap style="height: 100%;"> </div>
    </div>

    <div class="floating-wrapper">
      <div class="position-relative mr-3">
        <services-search></services-search>
      </div>

      <div class="floating-jumbotron layer-filter mr-3" [formGroup]="layersGroup">
        <label (click)="toggleLayer('toilets')" [class.active]="this.layersGroup.get('toilets').value" tooltip="Show public toilets" i18n-tooltip>
          <i class="icon-s icon-toilets"></i>
        </label>
        <label (click)="toggleLayer('parcelTerminals')" [class.active]="this.layersGroup.get('parcelTerminals').value" tooltip="Show parchel terminals" i18n-tooltip>
          <i class="icon-s icon-parchel-terminal"></i>
        </label>
        <label (click)="toggleLayer('evChargers')" [class.active]="this.layersGroup.get('evChargers').value" tooltip="Show electric vehicle charger stations" i18n-tooltip>
          <i class="icon-s icon-charger-station"></i>
        </label>
        <label (click)="toggleLayer('windTurbines')" [class.active]="this.layersGroup.get('windTurbines').value" tooltip="Show wind turbines" i18n-tooltip>
          <i class="icon-s icon-wind-turbine"></i>
        </label>
      </div>
    </div>
  </div>
</div>

