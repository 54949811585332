import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { LayerDisplaySettings } from 'projects/arc-gis/src/lib/layer-display-settings';
import { TallinnArcGisMapService } from 'projects/arc-gis/src/lib/tallinn-arc-gis-map.service';
// import { MobilityDisplaySettings } from '../mobility-config.model';
import { DisplaySettings } from '../model/display-settings';

@Component({
  selector: 'mobility-layer-filters',
  templateUrl: './mobility-layer-filters.component.html'
})
export class MobilityLayerFiltersComponent implements OnInit {

  private mapService: TallinnArcGisMapService;

  constructor(
    mapService: TallinnArcGisMapService
  ) {
    this.mapService = mapService;
  }

  @Input()
  displaySettings: DisplaySettings;

  @Input()
  displayArcGisSettings: LayerDisplaySettings;

  @Output('displaySettingsChange')
  displaySettingsChange: EventEmitter<any> = new EventEmitter<any>();


  @Output('arcGisDisplaySettingsChange')
  arcGisDisplaySettingsChange: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit(): void {
  }

  displayItemsChanged(items: any) {
    this.displaySettingsChange.next(items);
  }

  public toggleLayer(displaySettings: any): void {
    this.arcGisDisplaySettingsChange.next(displaySettings);
  }

}
