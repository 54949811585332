import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {ComponentsConfigService} from '../services/components-config.service';
import {Observable} from 'rxjs';
import {Preferences, PreferencesService} from '@smartencity/core';

@Injectable()
export class ComponentsEnabledGuard implements CanActivate {

  constructor(private preferencesService: PreferencesService, private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

    const componentName = route.data.componentName;

    return new Observable<boolean>((observer) => {
      this.preferencesService.preferences$.subscribe((preferences: Preferences) => {
        let result = true;
        if (componentName == 'energy-manager') {
          result = preferences.emDashboardEnabled;
        } else if (componentName == 'questionnaires') {
          result = preferences.questionnairesEnabled;
        }

        if (!result) {
          this.router.navigate(['mydata/datapoints']);
          return;
        }
        observer.next(result);
      }, (error) => {
        observer.error(error);
      });
    });
  }

}
