import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {Subject} from 'rxjs/internal/Subject';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {
  ConfirmModalComponent,
  PreventCloseAware,
  QuestionnaireField,
  QuestionnaireReference,
  QuestionnaireResponseDetail, ResponseRef,
} from '@smartencity/core';
import {MyDataConfig} from '../../../mydata-config.model';
import {HttpClient} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';
import {ResponseFieldsComponent} from '../response-fields/response-fields.component';
import {ResponseService} from '../response.service';
import {SMARTENCITY_MYDATA_CONFIG} from '../../../injection-tokens';

const selector = 'mydata-response-modal';
let nextId = 0;

@Component({
  selector: selector,
  templateUrl: './response-modal.component.html',
  providers: [ResponseService]
})
export class ResponseModalComponent implements OnInit, OnDestroy, OnChanges, PreventCloseAware {
  id = `${selector}-${nextId++}`;
  private ngDestroy = new Subject<void>();

  @ViewChild('fields')
  responseFieldsComponent: ResponseFieldsComponent;

  public view = 'fields';

  public showErrors = false;
  public progress: string = null;

  @Input()
  questionnaireId: number;

  @Input()
  participantId: number;

  @Input()
  responseId: number;

  @Input()
  responses: ResponseRef[];

  @Output('saved')
  savedEmitter: EventEmitter<any> = new EventEmitter<any>();

  questionnaire: QuestionnaireReference;
  response: QuestionnaireResponseDetail;
  historyResponse: QuestionnaireResponseDetail;

  constructor(
    @Inject(SMARTENCITY_MYDATA_CONFIG) private config: MyDataConfig,
    private http: HttpClient,
    private toastr: ToastrService,
    private modalRef: BsModalRef,
    private modalService: BsModalService,
    private responseService: ResponseService
  ) { }

  ngOnInit(): void {
    this.loadResponse();
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadResponse();
  }

  close(): void {
    this.modalRef.hide();
  }

  setView(view: string) {
    this.view = view;
  }

  loadResponse() {
    if (this.questionnaireId) {
      let endpoint = this.config.apiUrl + '/questionnaire-participation/' + this.questionnaireId + '/response';
      if (this.participantId) {
        endpoint = this.config.apiUrl + '/questionnaire/' + this.questionnaireId + '/participants/' + this.participantId + '/response';
      }
      if (this.responseId) {
        endpoint = this.config.apiUrl + '/questionnaire-response/' + this.responseId;
      }

      this.http.get(endpoint, {}).subscribe((data: QuestionnaireResponseDetail) => {
        this.response = data;
        this.questionnaire = this.response.questionnaire;
      });
    }
  }

  delete(): void {
    const modalRef = this.modalService.show(ConfirmModalComponent, {
      ignoreBackdropClick: true,
      initialState: {
        description: $localize`Are you sure you want to delete the response?`,
        callback: (confirm: boolean) => {
          if (confirm) {
            this.progress = 'decline';
            this.http.delete<QuestionnaireResponseDetail>(this.config.apiUrl + '/questionnaire-response/' + this.responseId).subscribe(() => {
              this.modalRef.hide();
              this.savedEmitter.emit();
              this.toastr.success($localize`Response deleted`);
            }, (error) => {
              this.progress = null;
              this.toastr.error($localize`Deleting response failed`);
            }, () => this.progress = null);
          }
        }
      }
    });
  }

  decline(): void {
    const modalRef = this.modalService.show(ConfirmModalComponent, {
      ignoreBackdropClick: true,
      initialState: {
        description: $localize`Are you sure you want to decline responding to the questionnaire?`,
        callback: (confirm: boolean) => {
          if (confirm) {
            this.progress = 'decline';
            this.http.post<QuestionnaireResponseDetail>(this.config.apiUrl + '/questionnaire-participation/' + this.questionnaireId + '/decline', null).subscribe(() => {
              this.modalRef.hide();
              this.savedEmitter.emit();
              this.toastr.success($localize`Questionnaire response declined`);
            }, (error) => {
              this.progress = null;
              this.toastr.error($localize`Saving response failed`);
            }, () => this.progress = null);
          }
        }
      }
    });
  }

  async save(): Promise<any> {
    this.view = 'fields';
    this.progress = 'save';
    try {
      await this.responseFieldsComponent.save();
      this.toastr.success($localize`Questionnaire response saved`);
      this.savedEmitter.emit();
      this.modalRef.hide();
    } catch (e) {
      this.toastr.error($localize`Saving response failed`);
    }
    this.progress = null;
  }

  async submit(): Promise<any> {
    this.view = 'fields';
    this.progress = 'save';
    try {
      await this.responseFieldsComponent.submit();
      this.toastr.success($localize`Questionnaire response saved`);
      this.savedEmitter.emit();
      this.modalRef.hide();
    } catch (e) {
      this.toastr.error($localize`Saving response failed`);
    }
    this.progress = null;
  }

  async saveAsNewResponse(): Promise<any> {
    this.view = 'fields';
    this.progress = 'save';
    try {
      const saveResult = await this.responseFieldsComponent.saveAsNewResponse();
      this.toastr.success($localize`Questionnaire response saved`);
      this.savedEmitter.emit(saveResult);
      this.modalRef.hide();
    } catch (e) {
      this.toastr.error($localize`Saving response failed`);
    }
    this.progress = null;
  }

  public getVariableList(formula: QuestionnaireField) {
    return (formula.variables ? formula.variables : []).join(', ');
  }

  public viewResponse(responseRef: ResponseRef){
    if(responseRef.id == this.responseId || (this.historyResponse && this.historyResponse.id == responseRef.id )){
      this.historyResponse = null;
      return;
    }
    this.http.get(this.config.apiUrl + '/questionnaire-response/' + responseRef.id, {}).subscribe((data: QuestionnaireResponseDetail) => {
      console.log(data.responseFields[0].text);
      this.historyResponse = data;
    });
  }

  isPreventClose(): boolean {
    return this.responseFieldsComponent.isPreventClose();
  }
}
