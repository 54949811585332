import {CoreConfig} from '@smartencity/core';
import {HttpClient} from '@angular/common/http';
import {PeopleTrafficMockDataService} from './people-traffic-mock-data.service';
import {PeopleTrafficApiDataService} from '../http/people-traffic-api-data.service';

export function peopleTrafficServiceFactory(config: CoreConfig, httpClient: HttpClient) {
  if (!config.production) return new PeopleTrafficMockDataService();

  return new PeopleTrafficApiDataService(config, httpClient);
}
