import {ActivityType} from '../../../model/crowd-insights/activity-type';

export class ActivityTypeFilterOption {
  value: ActivityType;
  label: string;

  constructor(props?: any) {
    if (!props) return;
    this.value = props.value;
    this.label = props.label;
  }

  static HOME = new ActivityTypeFilterOption({
    value: 'HOME',
    label: $localize`Home`
  });

  static WORK = new ActivityTypeFilterOption({
    value: 'WORK',
    label: $localize`Work`
  });

  static OTHER = new ActivityTypeFilterOption({
    value: 'OTHER',
    label: $localize`Other`
  });

  static TOTAL = new ActivityTypeFilterOption({
    value: 'TOTAL',
    label: $localize`Total`
  });

}
