import {Inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import { Observable } from 'rxjs';
import {CoreConfig, SMARTENCITY_CORE_CONFIG} from '@smartencity/core';

@Injectable()
export class AppModulesActivateGuard implements CanActivate {

  constructor(@Inject(SMARTENCITY_CORE_CONFIG) public config: CoreConfig,
              private router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {

    const modules = this.config.modules;

    return new Observable<boolean>((observer) => {
      let result = true;
      if (!modules || modules.names?.indexOf(route.routeConfig.path) == -1) {
        result = false;
      }

      if (!result) {
        this.router.navigate(['/']);
        return;
      }
      observer.next(result);
    });
  }



}
