import Map = google.maps.Map;
import {WaterTapsOverlayView} from './water-taps-overlay-view';
import {WaterTap} from '../model/water-tap';
import {AbstractMapLayer} from '@smartencity/core';

export class WaterTapsGmapLayer extends AbstractMapLayer<Map, WaterTap>{

  private layer: WaterTapsOverlayView;

  constructor(waterTaps: WaterTap[]) {
    super();
    this.layer = new WaterTapsOverlayView(waterTaps);
  }

  setMap(map: google.maps.Map) {
    super.setMap(map);
    this.layer.setMap(map);
  }

}
