import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Subject} from 'rxjs/internal/Subject';
import {Preferences, PreferencesService} from '@smartencity/core';
import {takeUntil} from 'rxjs/operators';
import {NgxPermissionsObject, NgxPermissionsService} from 'ngx-permissions';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';
import {NavbarService} from '../navbar/navbar.service';
import {DatatableComponent} from '@swimlane/ngx-datatable';

@Component({
  selector: 'mydata-questionnaires',
  templateUrl: './questionnaires-page.component.html',
  providers: []
})
export class QuestionnairesPageComponent implements OnInit, OnDestroy {
  private ngDestroy = new Subject<void>();

  @ViewChild('navMenu', {static: true})
  public navTempalte: TemplateRef<any>;

  constructor(
    private navbarService: NavbarService,
  ) {

  }

  ngOnDestroy(): void {
    this.navbarService.setMenuTemplate(null);
  }

  ngOnInit(): void {
    this.navbarService.setMenuTemplate(this.navTempalte);
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }
}
