import {Inject, Injectable} from '@angular/core';
import {SourceUpdateErrorService} from './source-update-error.service';
import {HttpClient} from '@angular/common/http';
import {CoreConfig, SMARTENCITY_CORE_CONFIG} from '@smartencity/core';

@Injectable()
export class GroupSourceUpdateErrorService implements SourceUpdateErrorService {

  constructor(private http: HttpClient,
              @Inject(SMARTENCITY_CORE_CONFIG) private config: CoreConfig) {
  }

  loadErrors(sourceId: number) {
    return this.http.get<any>(this.config.apiUrl + '/person-series-group-source/' + sourceId + '/update-error');
  }

  resetErrors(sourceId: number) {
    return this.http.get<void>(this.config.apiUrl + '/person-series-group-source/' + sourceId + '/update-error/reset');
  }

}
