import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import { Dashboard, PersonDashboard } from '../components/dashboard/dashboard';
import { MyDataConfig } from '../mydata-config.model';
import { SMARTENCITY_MYDATA_CONFIG } from '../injection-tokens';

@Injectable({
  providedIn: 'root'
})
export class DashboardApiService {

constructor(
  private http: HttpClient,
  @Inject(SMARTENCITY_MYDATA_CONFIG) private config: MyDataConfig
) { }

  public getDashboardOptions(): Observable<PersonDashboard[]> {
    return this.http.get<PersonDashboard[]>(this.config.apiUrl + '/dashboard');
  }

  public saveDashboard(dashboard: Dashboard): Observable<Dashboard> {
    return this.http.post<Dashboard>(this.config.apiUrl + '/dashboard', dashboard);
  }

  public updateDashboard(dashboard: Dashboard): Observable<Dashboard> {
    return this.http.put<Dashboard>(this.config.apiUrl + '/dashboard/' + dashboard.id, dashboard);
  }

  public deleteDashboard(dashboard: Dashboard): Observable<any>  {
    return this.http.delete(this.config.apiUrl + '/dashboard/' + dashboard.id);
  }

  public getConsents(dashboardId: number): Observable<any>  {
    return this.http.get(this.config.apiUrl + '/dashboard/' + dashboardId + '/share');
  }

  public addConsent(dashboardId: number, consentData: any): Observable<any>  {
    return this.http.post(this.config.apiUrl + '/dashboard/' + dashboardId + '/share', consentData);
  }

  public endConsent(dashboardId: number, shareId: number): Observable<any>  {
    return this.http.delete(this.config.apiUrl + '/dashboard/' + dashboardId + '/share/' + shareId);
  }
}
