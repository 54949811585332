import {Building} from '../model/building';
import {BuildingGmapMarker} from './building-gmap-marker';
import {BuildingsGmapService} from '../service/buildings-gmap.service';
import {BuildingsCompareService} from '@smartencity/core';

export type SeriesValueFormatFn =  (series: any) => string;

export class BuildingsGmapLayerProperties {
  seriesType: string;
  seriesValueFormatFn: SeriesValueFormatFn = (series: any) => {
    return series.value + ' ' + series.unit;
  };
}

export class BuildingsGmapLayer extends google.maps.OverlayView {

  private selectedBuilding: Building;

  private markers: BuildingGmapMarker[] = [];

  public buildings: Building[] = [];

  private seriesType: string;

  private map: google.maps.Map;

  div: HTMLElement;

  constructor(
    private props: BuildingsGmapLayerProperties,
    private buildingsMapService: BuildingsGmapService,
    public buildingsCompareService: BuildingsCompareService) {
    super();

    this.seriesType = props.seriesType;
  }

  public setSelected(building: Building): void {
    this.selectedBuilding = building;
  }

  public updateBuildings(buildings: Building[]): void {
    this.buildings = buildings;
    this.resetMarkers();
  }

  public resetMarkers(): void {
    this.hideMarkers();
    this.markers = [];


    if (this.selectedBuilding) {
      this.selectedBuilding = this.buildings.find((building: Building) => building.id === this.selectedBuilding.id);
    }

    for (const building of this.buildings) {
      let series = this.getFirstSeriesByType(building, this.seriesType);
      if (series == null) {
        continue;
      }

      const newMarker = new BuildingGmapMarker(
        this,
        building,
        series,
        this.seriesType,
        this.buildingsMapService,
        this.props.seriesValueFormatFn,
        this.buildingsCompareService
      );
      newMarker.setSelected(this.selectedBuilding == building);
      this.markers.push(newMarker);
    }

    this.showMarkers()

  }

  public hideMarkers(): void {
    this.markers.forEach(marker => marker.setMap(null));
  }

  setMap(map: google.maps.Map) {
    super.setMap(map);
    this.map = map;
  }

  public showMarkers(map?: google.maps.Map) {
    if (map) {
      this.setMap(map);
    }
    this.markers.forEach(marker => marker.setMap(this.map));
  }

  onAdd() {
    if (!this.div) {
      this.div = document.createElement('DIV');
      this.div.style.position = 'absolute';
      this.div.style.cursor = 'pointer';
      this.div.style.position = 'absolute';
      this.div.classList.add('buildings-layer');
      this.div.style.paddingLeft = '0px';
    }

    const panes = this.getPanes();
    panes.overlayMouseTarget.appendChild(this.div);
  }

  onRemove() {
    this.div.parentNode.removeChild(this.div);
  }

  getFirstSeriesByType(building: Building, seriesType: string): any[] {
    return building.series.find((series: any) => series.seriesType === seriesType);
  }

  showBuilding(building: Building): void {
    this.selectedBuilding = building;
    this.markers.forEach(marker => {
      marker.setSelected(marker.building === building);
      marker.draw();
    });
  }

  updateSeriesType(seriesType: string): void {
    this.seriesType = seriesType;
    this.resetMarkers();
    this.showMarkers();
  }

}
