import { Injectable } from '@angular/core';
import { RoadWeatherSiteConstants, RoadWeatherSiteIndicator } from '../model/road-weather-site-constants';
import { RoadWeatherDataService } from './road-weather-data.service';

@Injectable()
export class RoadWeatherConditionDataService extends RoadWeatherDataService {
    
    constructor() { 
        super();
    }
    
    getIndicators(): RoadWeatherSiteIndicator[] {
        return RoadWeatherSiteConstants.roadWeatherIndicators;
    }
}
