import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {AuthService, PersonAdvice, PersonAdviceGroup, PersonParameter, UserService} from '@smartencity/core';
import {Subject} from 'rxjs/internal/Subject';
import {HttpClient} from '@angular/common/http';
import {SMARTENCITY_MYDATA_CONFIG} from '../../injection-tokens';
import {MyDataConfig} from '../../mydata-config.model';
import {debounceTime, switchMap, take, takeUntil, tap} from 'rxjs/operators';
import {BsModalService} from 'ngx-bootstrap/modal';
import {AdviceModalComponent} from './advice-modal/advice-modal.component';
import {FormArray, FormControl, FormGroup} from '@angular/forms';
import {Subscription} from 'rxjs/internal/Subscription';
import {ToastrService} from 'ngx-toastr';

const selector = 'mydata-advice-list';
let nextId = 0;

export class AdviceGroupRow {
  expanded: boolean;
  personAdviceGroup: PersonAdviceGroup;
  adviceRows: AdviceRow[];
}

export class AdviceRow {
  personAdvice: PersonAdvice;
  control: FormControl;
}

@Component({
  selector: selector,
  templateUrl: './advice-list.component.html'
})
export class AdviceListComponent implements OnInit, OnDestroy {
  id = `${selector}-${nextId++}`;
  private ngDestroy = new Subject<void>();

  public personAdviceGroupRows: AdviceGroupRow[] = [];
  public checkArray: FormArray;
  public activeGroupRow = null;
  private checkArraySub: Subscription;

  constructor(
    public authService: AuthService,
    public userService: UserService,
    private toastr: ToastrService,
    private http: HttpClient,
    @Inject(SMARTENCITY_MYDATA_CONFIG) private config: MyDataConfig,
    private modalService: BsModalService
  ) {
  }

  ngOnInit() {
    this.loadAdviceGroups();
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  loadAdviceGroups(): void {
    this.http.get<PersonAdviceGroup[]>(this.config.apiUrl + '/person-advice').pipe(takeUntil(this.ngDestroy)).subscribe(
      (data: PersonAdviceGroup[]) => {
        if (this.checkArraySub) {
          this.checkArraySub.unsubscribe();
          this.checkArraySub = null;
        }

        const controls = [];

        this.personAdviceGroupRows = [];
        for (const personAdviceGroup of data) {
          const adviceGroupRow = {
            expanded: false,
            personAdviceGroup: personAdviceGroup,
            adviceRows: []
          };
          this.personAdviceGroupRows.push(adviceGroupRow);
          for (const personAdvice of personAdviceGroup.personAdviceList) {
            const control = new FormGroup({
              adviceId: new FormControl(personAdvice.adviceId),
              checked: new FormControl(personAdvice.checked)
            });
            controls.push(control);
            const adviceRow = {
              personAdvice: personAdvice,
              control: control
            };
            adviceGroupRow.adviceRows.push(adviceRow);
          }
        }

        if (this.personAdviceGroupRows.length) {
          this.activeGroupRow = this.personAdviceGroupRows[0];
        } else {
          this.activeGroupRow = 0;
        }

        this.checkArray = new FormArray(controls);
        this.checkArraySub = this.checkArray.valueChanges.pipe(takeUntil(this.ngDestroy), debounceTime(500), switchMap((value) => {
          const postDto = value;

          const ret = this.http.put<any>(this.config.apiUrl + '/person-advice', postDto);
          return ret.pipe(tap(() => {
            this.toastr.success($localize`Advices saved`);
          }));
        })).subscribe();
      }, err => {
        return err;
      }
    );
  }

  setGroup(groupRow) {
    this.activeGroupRow = groupRow;
  }

  expandRow(row: {expanded: boolean; personAdviceGroup: PersonAdviceGroup; }) {
    row.expanded = !row.expanded;
  }

  viewAdviceGroup(personAdviceGroup: PersonAdviceGroup): void {
    const modalRef = this.modalService.show(AdviceModalComponent, {
      ignoreBackdropClick: true,
      class: 'modal-xl',
      initialState: {
        personAdviceGroup: personAdviceGroup
      }
    });

    modalRef.content.savedEmitter.pipe(takeUntil(this.ngDestroy), take(1)).subscribe((personParameter: PersonParameter) => {
      this.loadAdviceGroups();
    });
  }
}
