<div class="modal-header">
  <h5 class="modal-title w-100" i18n>Compare</h5>
  <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
    <i class="fal fa-times"></i>
  </button>
</div>

<div class="modal-body">
  <div class="row">
    <div class="col-12 col-md-6">
      <ngx-mat-drp (selectedDateRangeChanged)="updateRange($event)" [options]="drpOptions" #dateRangePicker></ngx-mat-drp>
    </div>
    <div class="col-12 col-md-6 text-md-right">
      <ul class="nav nav-links nav-links-small justify-content-end mt-2">
        <li class="nav-item">
          <a class="nav-link" [class.active]="!(aggregationGroupingType$ | async)" (click)="aggregationGroupingType(null)" i18n>Live</a>
        </li>
        <li class="nav-item separator"><span></span></li>
        <li class="nav-item">
          <a class="nav-link" [class.active]="(aggregationGroupingType$ | async) == 'HOURLY'" (click)="aggregationGroupingType('HOURLY')" [class.disabled]="!aggTypes['HOURLY']" i18n>Hourly</a>
        </li>
        <li class="nav-item separator"><span></span></li>
        <li class="nav-item">
          <a class="nav-link" [class.active]="(aggregationGroupingType$ | async) == 'DAILY'" (click)="aggregationGroupingType('DAILY')" [class.disabled]="!aggTypes['DAILY']" i18n>Daily</a>
        </li>
        <li class="nav-item separator"><span></span></li>
        <li class="nav-item">
          <a class="nav-link" [class.active]="(aggregationGroupingType$ | async) == 'MONTHLY'" (click)="aggregationGroupingType('MONTHLY')" [class.disabled]="!aggTypes['MONTHLY']" i18n>Monthly</a>
        </li>
        <li class="nav-item separator"><span></span></li>
        <li class="nav-item">
          <a class="nav-link" [class.active]="(aggregationGroupingType$ | async) == 'YEARLY'" (click)="aggregationGroupingType('YEARLY')" [class.disabled]="!aggTypes['YEARLY']" i18n>Yearly</a>
        </li>
      </ul>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <hr/>
    </div>
  </div>
  <div class="row" *ngIf="buildings.length">
    <div class="col-12 pt-2 pb-3 mb-2 d-block d-md-flex align-items-center">
      <label class="mr-2 w100p" i18n>Buildings: </label>
        <div class="chip-remove d-flex align-items-center mr-3 pr-3 mb-2 mb-md-0" *ngFor="let buildingCompareItem of compareBuildings; let i = index;" [class.border-right]="i !== (buildings.length - 1)">
          <span class="chip m-0 text-center" [class.active]="buildingCompareItem.active" (click)="toggleCompareBuilding(buildingCompareItem)">{{buildingCompareItem?.building?.address}}</span>
          <i (click)="removeBuilding(buildingCompareItem)" class="btn btn-link close fal fa-times ml-3 p-0" tooltip="Remove" i18n-tooltip></i>
        </div>
    </div>
  </div>
  <div class="row" *ngIf="locations.length">
    <div class="col-12">
      <label class="mr-2 w100p" i18n>Locations: </label>
      <div class="chip chip-primary chip-remove" *ngFor="let location of locations" (click)="removeLocation(location)">
        <span>{{location?.address}}</span>
        <i class="close fal fa-times"></i>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="buildings.length">
    <div class="col-12">
      <label class="mr-2 w100p" i18n>Show: </label>
      <div class="chip chip-secondary" [class.active]="seriesType.compareItem.active" *ngFor="let seriesType of seriesTypes" (click)="toggleSeriesType(seriesType)">{{seriesType.name}}</div>
    </div>
  </div>

  <div class="row" *ngIf="locations.length">
    <div class="col-12">
      <label class="mr-2 w100p" i18n>Show: </label>
      <div class="chip" [class.active]="itemType.active" *ngFor="let itemType of itemTypes" (click)="toggleItemType(itemType)">{{itemType.name ? itemType.name : itemType.key}}</div>
    </div>
  </div>

  <div class="mt-3" *ngIf="chartDatasets.length" style="height: 50vh">
    <canvas #chartCanvas responsive="true"></canvas>
  </div>

  <table class="table table-striped table-responsive-md btn-table modal-table mt-5" [hidden]="buildingsCompareItems.length < 1">
    <thead>
    <tr>
      <th i18n>Name</th>
      <th i18n>Unit</th>
      <th i18n>Aggregation type</th>
      <th i18n>Graph type</th>
      <th i18n>Group scales</th>
      <th></th>
    </tr>
    </thead>

    <tbody>
      <ng-container *ngFor="let item of locationsCompareItems">
        <tr *ngIf="item.active && item.datasets.size">
          <td>{{item.name}}</td>
          <td>{{item.displayUnit}}</td>
          <td>
            <core-custom-dropdown-control [options]="aggregationTypeOptions" [formControl]="item.aggregationTypeControl"></core-custom-dropdown-control>
          </td>
          <td>
            <core-custom-dropdown-control [options]="graphTypeOptions" [formControl]="item.graphTypeControl"></core-custom-dropdown-control>
          </td>
          <td>
            <div class="custom-checkbox">
              <!-- checkbox item.groupByUnit -->
            </div>
          </td>
          <td></td>
        </tr>
      </ng-container>
      <ng-container *ngFor="let item of buildingsCompareItems">
        <tr *ngIf="item.active && item.datasets.size">
          <td>{{item.name}}</td>
          <td>{{item.displayUnit}}</td>
          <td>
            <core-custom-dropdown-control [options]="aggregationTypeOptions" [formControl]="item.aggregationTypeControl"></core-custom-dropdown-control>
          </td>
          <td>
            <core-custom-dropdown-control [options]="graphTypeOptions" [formControl]="item.graphTypeControl"></core-custom-dropdown-control>
          </td>
          <td>
            <div class="col-6">
              <input type="text" [formControl]="item.groupNameControl" class="form-control form-control-sm" placeholder="Group name" i18n-placeholder />
              <!-- checkbox item.groupByUnit -->
            </div>
          </td>
          <td></td>
        </tr>
      </ng-container>
      <ng-container *ngFor="let item of psCompareItems">
        <tr *ngIf="item.active && item.datasets.size">
          <td>{{item.name}}</td>
          <td>{{item.displayUnit}}</td>
          <td>
            <core-custom-dropdown-control [options]="aggregationTypeOptions" [formControl]="item.aggregationTypeControl"></core-custom-dropdown-control>
          </td>
          <td>
            <core-custom-dropdown-control [options]="graphTypeOptions" [formControl]="item.graphTypeControl"></core-custom-dropdown-control>
          </td>
          <td>
            <div class="custom-checkbox">
              <!-- checkbox item.groupByUnit -->
            </div>
          </td>
          <td>
            <button type="button" class="btn btn-secondary btn-sm" (click)="removePersonSeries(item)">&nbsp;<i class="fa fa-times mr-2"></i><span i18n>Remove</span></button>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <div class="loader-overlay" [hidden]="loadingCount === 0">
    <!--Big blue-->
    <div class="preloader-wrapper active">
      <div class="spinner-layer spinner-blue-only">
        <div class="circle-clipper left">
          <div class="circle"></div>
        </div>
        <div class="gap-patch">
          <div class="circle"></div>
        </div>
        <div class="circle-clipper right">
          <div class="circle"></div>
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngIf="footerTemplate">
    <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
  </ng-container>
</div>
