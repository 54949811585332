import {Injectable} from '@angular/core';
import {AddressService} from './address.service';
import {AbstractControl, FormGroup} from '@angular/forms';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {Subject} from 'rxjs/internal/Subject';

@Injectable()
export class InputAddressService implements AddressService {
  public form: FormGroup;
  public searching$ = new BehaviorSubject<boolean>(false);
  public addressSelect$ = new Subject<any>();
  public addressTypeahead = undefined;
  public apartmentTypeahead = undefined;

  inputFormatter = undefined;
  resultFormatter = undefined;

  constructor() {}

  public initForm(form: FormGroup): void {
    this.form = form;
  }

  public getForm(): FormGroup {
    return this.form;
  }

  public getAddressControl(): AbstractControl {
    return this.form.get('address');
  }

  public getApartmentControl(): AbstractControl {
    return this.form.get('apartment');
  }

  public getRoomControl(): AbstractControl {
    return this.form.get('room');
  }

  public selectAddress(addressItem: any): void {
    this.addressSelect$.next(addressItem);
  }

}
