
<div class="floating-jumbotron layer-filter mr-3 ml-0 mb-3">
  <label class="layer-filter-toggle" (click)="toggleLayer({cityDistricts: !displayArcGisSettings.cityDistricts});" [class.active]="displayArcGisSettings.cityDistricts" tooltip="Show city districts" i18n-tooltip data-filter-name="city-district">
    <i class="icon-s icon-city-disctrict"></i>
  </label>
  <label class="layer-filter-toggle" (click)="toggleLayer({residentialAreas: !displayArcGisSettings.residentialAreas});" [class.active]="displayArcGisSettings.residentialAreas" tooltip="Show residential areas" i18n-tooltip data-filter-name="residential-area">
    <i class="icon-s icon-city-disctrict"></i>
  </label>
  <div *ngIf="displaySettings.eecBuildings">
      <div class="energy-class-filter ml-2">
        <div class="btn-group">
          <button (click)="toggleAllEnergyClasses(!displaySettings.eecBuildings.active);" type="button" class="btn btn-secondary toggle-btn d-flex align-items-center layer-filter-toggle"
                  data-filter-name="energy-efficient-category-building-all"
                  [class.active]="this.displaySettings.eecBuildings?.active"> <i class="icon-s icon-energy-class"></i> <span class="mr-1" i18n>Energy class</span></button>
          <button type="button" class="btn btn-secondary dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-expanded="false" id="chooseEnergyClass">
          </button>
            <ul class="dropdown-menu" aria-labelledby="chooseEnergyClass">
              <li class="layer-filter-toggle" (click)="toggleEecBuildingEnergyClass('A');" [class.active]="this.displayEnergyTypeSettings.eecBuildings?.A" data-filter-name="energy-efficient-category-building-a">
                <span class="dropdown-item">A</span>
              </li>
              <li class="layer-filter-toggle" (click)="toggleEecBuildingEnergyClass('B')" [class.active]="this.displayEnergyTypeSettings.eecBuildings?.B" data-filter-name="energy-efficient-category-building-b">
                <span class="dropdown-item">B</span>
              </li>
              <li class="layer-filter-toggle" (click)="toggleEecBuildingEnergyClass('C')" [class.active]="this.displayEnergyTypeSettings.eecBuildings?.C" data-filter-name="energy-efficient-category-building-c">
                <span class="dropdown-item">C</span>
              </li>
              <li class="layer-filter-toggle" (click)="toggleEecBuildingEnergyClass('D')" [class.active]="this.displayEnergyTypeSettings.eecBuildings?.D" data-filter-name="energy-efficient-category-building-d">
                <span class="dropdown-item">D</span>
              </li>
              <li class="layer-filter-toggle" (click)="toggleEecBuildingEnergyClass('E')" [class.active]="this.displayEnergyTypeSettings.eecBuildings?.E" data-filter-name="energy-efficient-category-building-e">
                <span class="dropdown-item">E</span>
              </li>
              <li class="layer-filter-toggle" (click)="toggleEecBuildingEnergyClass('F')" [class.active]="this.displayEnergyTypeSettings.eecBuildings?.F" data-filter-name="energy-efficient-category-building-f">
                <span  class="dropdown-item">F</span>
              </li>
              <li class="layer-filter-toggle" (click)="toggleEecBuildingEnergyClass('G')" [class.active]="this.displayEnergyTypeSettings.eecBuildings?.G" data-filter-name="energy-efficient-category-building-g">
                <span  class="dropdown-item">G</span>
              </li>
              <li class="layer-filter-toggle" (click)="toggleEecBuildingEnergyClass('H')"  [class.active]="this.displayEnergyTypeSettings.eecBuildings?.H" data-filter-name="energy-efficient-category-building-h">
                <span class="dropdown-item">H</span>
              </li>
          </ul>
        </div>
      </div>
    </div>
    <label class="layer-filter-toggle" (click)="toggleLayer({cityServiceOffices: !displayArcGisSettings.cityServiceOffices});"
           [class.active]="displayArcGisSettings.cityServiceOffices" tooltip="Show city service offices" i18n-tooltip data-filter-name="city-service-offices">
      <i class="icon-s icon-service-office"></i>
    </label>
</div>
