<ng-template #navActions>
  <button type="button" class="btn btn-sm btn-primary d-lg-inline-block d-none" (click)="setDataOwner()" [disabled]="checkedCount() == 0">
    <i class="fal fa-user"></i>&nbsp;<ng-container i18n>Set owner</ng-container>
  </button>
  <div class="btn-floating-mobile-container" [ngClass]="{'d-none': checkedCount() == 0}">
    <button type="button" class="btn btn-primary btn-sm btn-floating-mobile" (click)="setDataOwner()" [disabled]="checkedCount() == 0">
      <i class="fal fa-plus fa-2x"></i>
    </button>
  </div>

</ng-template>
<div class="row justify-content-center mb-3 mb-lg-5" [formGroup]="filterForm">
  <div class="col-sm-12 col-md-6 col-lg-4">
    <div class="input-group input-group-lg form-search">
      <input formControlName="query" type="text" class="form-control form-control-lg" placeholder="Type to search..." i18n-placeholder>
      <div class="input-group-append">
        <span class="clear-search" (click)="clearSearch()" [ngClass]="{'active': filterForm.get('query')?.value?.length > 0 }">
          <i class="fal fa-times"></i>
        </span>
        <!-- <div class="custom-dropdown filter-dropdown" dropdown [autoClose]="true" (click)="$event.stopImmediatePropagation();">
          <button type="button" class="btn dropdown-toggle" dropdownToggle i18n>Filter</button>
          <div class="dropdown-menu dropdown-menu-right" *dropdownMenu>
            <div class="accordion-group"  [ngClass]="'show'">
              <div class="accordion-header">
                <ng-container i18n>Some filterable data?</ng-container>
              </div>
              <div class="accordion-content">
              </div>
            </div>
          </div>
        </div> -->
        <span class="input-group-text">
          <i class="fal fa-search"></i>
        </span>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-12">
    <loading-spinner *ngIf="!rows"></loading-spinner>
    <ngx-datatable
      *ngIf="rows"
      #table
      class="material expandable"
      [draggable]="false"
      [columnMode]="'flex'"
      [headerHeight]="30"
      [footerHeight]="50"
      [reorderable]="false"
      rowHeight="auto"
      [scrollbarH]="false"
      [scrollbarV]="false"
      [rows]="rows"
      [externalPaging]="true"
      [externalSorting]="true"
      [count]="page?.totalElements"
      [limit]="page?.size"
      [sorts]="defaultSorts"
      [selected]="selected"
      [selectionType]="'checkbox'"
      (page)="setPage($event)"
      (sort)="setSorts($event)"
      (select)="onSelect($event)"
      [messages]="{emptyMessage: ''}"
    >
      <ngx-datatable-row-detail [rowHeight]="250" #detailRow>
        <ng-template
          let-row="row"
          let-ownerType="row.ownerType"
          let-ps="row.series.personSeries"
          let-confirmationStatus="row.confirmationStatus"
          let-expanded="expanded" ngx-datatable-row-detail-template
        >
          <div style="font-size:14px; padding: 0.7rem">

            <div class="row">
              <div class="col-6"><label i18n>Datapoint Name</label></div>
              <div class="col-6">{{row.name}}</div>
            </div>

            <div class="row">
              <div class="col-6"><label i18n>Address</label></div>
              <div class="col-6">
                <ng-container *ngIf="row.series.personSeries.locationType == 'ADDRESS'">
                  {{row.series.personSeries.address}}<ng-container *ngIf="row.series.personSeries.apartment"> - {{row.series.personSeries.apartment}}</ng-container>
                </ng-container>
                <ng-container *ngIf="row.series.personSeries.locationType == 'COORDS'">
                  {{row.series.personSeries.lat}}-{{row.series.personSeries.lng}}
                </ng-container>
                <ng-container *ngIf="row.series.personSeries.locationType == 'UUID'">
                  {{row.series.personSeries.uuid}}
                </ng-container>
              </div>
            </div>

            <div class="row">
              <div class="col-6"><label i18n>Data owner</label></div>
              <div class="col-6">
                <ng-container *ngIf="row.series.lastPeriod?.person?.countryCode == me.countryCode && row.series.lastPeriod?.person?.registrationNumber == me.registrationNumber">
                  <span class="text-nowrap" *ngIf="ownerType == 'OWNER'" i18n>Myself</span>
                </ng-container>
                <ng-container *ngIf="row.series.lastPeriod?.person?.countryCode != me.countryCode || row.series.lastPeriod?.person?.registrationNumber != me.registrationNumber">
                  <span class="text-nowrap">{{row.series.lastPeriod?.person?.displayName}}</span> ({{row.series.lastPeriod?.person | regNo}})
                </ng-container>
              </div>
            </div>

            <div class="row">
              <div class="col-6"><label i18n>History</label></div>
              <div class="col-6"><a class="btn btn-sm btn-light" (click)="showHistory(row.series)">Show</a></div>
            </div>

            <div class="row">
              <div class="col-6"><label i18n>Activity Date</label></div>
              <div class="col-6">{{row.updatedAt | date:'dd. MMMM yyyy'}}</div>
            </div>

            <div class="row">
              <div class="col-6"><label i18n>Status</label></div>
              <div class="col-6">
                <span *ngIf="confirmationStatus == 'PENDING'" class="text-info" i18n>Pending</span>
                <span *ngIf="confirmationStatus == 'ACCEPTED'" class="text-success" i18n>Accepted</span>
                <span *ngIf="confirmationStatus == 'DECLINED'" class="text-warning" i18n>Declined</span>
                <span *ngIf="confirmationStatus == 'CANCELLED'" class="text-warning" i18n>Cancelled</span>
              </div>
            </div>
            <div class="row pt-3">
              <button type="button" class="btn btn-sm btn-link no-caret" (click)="showConsents(row.series)">
                <i class="fal fa-share mr-2"></i>
                <ng-container i18n>Share data</ng-container>
              </button>
              <button type="button" class="btn btn-sm btn-link no-caret" (click)="setRowDataOwner(row)">
                <i class="fal fa-edit mr-2"></i>
                <ng-container i18n>Set new data owner</ng-container>
              </button>
              <button type="button" class="btn btn-sm btn-link no-caret" (click)="cancelOwnership(row.series)" *ngIf="row.series.lastPeriod.confirmationStatus == 'PENDING'">
                <i class="fal fa-trash mr-2"></i>
                <ng-container i18n>Cancel</ng-container>
              </button>
            </div>
          </div>
        </ng-template>
      </ngx-datatable-row-detail>

      <ngx-datatable-column
        [width]="30"
        [sortable]="false"
        [canAutoResize]="false"
        [draggable]="false"
        [resizeable]="false"
        *ngIf="columns.check"
      >
        <ng-template
          ngx-datatable-header-template
          let-value="value"
          let-allRowsSelected="allRowsSelected"
          let-selectFn="selectFn"
        >
          <div class="checkbox position-absolute">
            <input type="checkbox" [ngModel]="allRowsSelected" (change)="selectFn(!allRowsSelected)"/>
          </div>
        </ng-template>
        <ng-template
          ngx-datatable-cell-template
          let-value="value"
          let-isSelected="isSelected"
          let-onCheckboxChangeFn="onCheckboxChangeFn"
        >
          <div class="checkbox">
            <input type="checkbox" [ngModel]="isSelected" (change)="onCheckboxChangeFn($event)"/>
          </div>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column *ngIf="columns.name" name="Datapoint Name" i18n-name prop="name" [flexGrow]="2" [resizeable]="false" [sortable]="true">
        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>{{value}}</ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column *ngIf="columns.address" name="Address" i18n-name [flexGrow]="1" [resizeable]="false" [sortable]="false">
        <ng-template let-ps="row.series.personSeries" ngx-datatable-cell-template>
          <ng-container *ngIf="ps.locationType == 'ADDRESS'">
            {{ps.address}}<ng-container *ngIf="ps.apartment"> - {{ps.apartment}}</ng-container>
          </ng-container>
          <ng-container *ngIf="ps.locationType == 'COORDS'">
            {{ps.lat}}-{{ps.lng}}
          </ng-container>
          <ng-container *ngIf="ps.locationType == 'UUID'">
            {{ps.uuid}}
          </ng-container>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column *ngIf="columns.owner" name="Data owner" i18n-name [flexGrow]="1" [resizeable]="false" [sortable]="false">
        <ng-template let-ownerType="row.ownerType" let-lastPeriod="row.series.lastPeriod" ngx-datatable-cell-template>
          <ng-container *ngIf="lastPeriod?.person?.countryCode == me.countryCode && lastPeriod?.person?.registrationNumber == me.registrationNumber">
            <span class="text-nowrap" *ngIf="ownerType == 'OWNER'" i18n>Myself</span>
          </ng-container>
          <ng-container *ngIf="lastPeriod?.person?.countryCode != me.countryCode || lastPeriod?.person?.registrationNumber != me.registrationNumber">
            <span class="text-nowrap">{{lastPeriod?.person?.displayName}}</span> ({{lastPeriod?.person | regNo}})
          </ng-container>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column *ngIf="columns.history" name="History" i18n-name [flexGrow]="1" [resizeable]="false" [sortable]="false" [cellClass]="'justify-content-center'"  [headerClass]="'text-center'">
        <ng-template let-series="row.series" ngx-datatable-cell-template>
          <a class="btn btn-sm btn-light" (click)="showHistory(series)">Show</a>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column *ngIf="columns.activityDate" name="Activity Date" i18n-name prop="updatedAt" [flexGrow]="1" [resizeable]="false" [sortable]="true">
        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>{{value | date:'dd. MMMM yyyy'}}</ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column *ngIf="columns.status" name="Status" i18n-name prop="confirmationStatus" [flexGrow]="1" [resizeable]="false" [sortable]="false">
        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
          <span *ngIf="row.series.lastPeriod.confirmationStatus == 'PENDING'" class="badge badge-warning rounded-pill p-2" i18n>Pending</span>
          <span *ngIf="row.series.lastPeriod.confirmationStatus == 'ACCEPTED'" class="badge badge-success rounded-pill p-2" i18n>Accepted</span>
          <span *ngIf="row.series.lastPeriod.confirmationStatus == 'DECLINED'" class="badge badge-danger rounded-pill p-2" i18n>Declined</span>
          <span *ngIf="row.series.lastPeriod.confirmationStatus == 'CANCELLED'" class="badge badge-dark rounded-pill p-2" i18n>Cancelled</span>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column *ngIf="columns.actions" name="" [flexGrow]="1" [resizeable]="false" [sortable]="false" [cellClass]="'justify-content-end'">
        <ng-template let-row="row" ngx-datatable-cell-template>
          <div class="btn-group btn-more" dropdown>
            <button type="button" class="btn btn-icon dropdown-toggle" dropdownToggle><i class="far fa-ellipsis-v"></i></button>
            <div class="dropdown-menu dropdown-menu-right" *dropdownMenu>
              <a class="dropdown-item" (click)="showConsents(row.series)">&nbsp;<ng-container i18n>Share data</ng-container></a>
              <a class="dropdown-item" (click)="setRowDataOwner(row)"><ng-container i18n>Set new data owner</ng-container></a>
              <a class="dropdown-item" *ngIf="row.series.lastPeriod.confirmationStatus == 'PENDING'" (click)="cancelOwnership(row.series)"><ng-container i18n>Cancel</ng-container></a>
            </div>
          </div>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column
        *ngIf="columns.detailToggler"
        [width]="50"
        [resizeable]="false"
        [sortable]="false"
        [draggable]="false"
        [canAutoResize]="false"
        headerClass="justify-content-end"
        cellClass="justify-content-end"
      >
        <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
          <a
            [routerLink]="null"
            [class.datatable-icon-right]="!expanded"
            [class.datatable-icon-down]="expanded"
            title="Expand/Collapse Row"
            (click)="toggleExpandRow(row)"
          >
          </a>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>
</div>
