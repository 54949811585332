<div class="box" *ngFor="let resultField of resultFields">
  <label class="question mb-0"><ng-container *ngIf="resultField?.field.symbol">{{resultField?.field.symbol}}:&nbsp;</ng-container>{{resultField?.field?.name}}</label>
  <div class="row mb-3">
    <div class="col-auto">
      <span class="font-weight-bolder small-text help-text" *ngIf="resultField?.field.responseType === 'RESPONSE' && resultField?.field.type !== 'FORMULA_INPUT'">
        <span class="font-weight-bolder small-text help-text" i18n>
          {fieldAnswersCountMapping[resultField.field.id]?.count, plural, =1 {{{fieldAnswersCountMapping[resultField.field.id]?.count}} participant} other {{{fieldAnswersCountMapping[resultField.field.id]?.count}} participants}} out of {results?.questionnaire?.participants?.length, plural, =1 {{{results?.questionnaire?.participants?.length}} participant} other {{{results?.questionnaire?.participants?.length}} participants}} answered this question
        </span>
      </span>
      <span class="font-weight-bolder small-text help-text" *ngIf="resultField?.field.responseType !== 'RESPONSE'">
        <span class="font-weight-bolder small-text help-text" i18n>
          {submitCount, plural, =1 {{{submitCount}} participant} other {{{submitCount}} participants}} out of {results?.questionnaire?.participants?.length, plural, =1 {{{results?.questionnaire?.participants?.length}} participant} other {{{results?.questionnaire?.participants?.length}} participants}} answered this question
        </span>
      </span>
    </div>
  </div>
  <ng-container *ngIf="(resultField?.field?.valueType == 'SELECT' || resultField?.field?.valueType == 'VALUE') && (fieldValueFrequency[resultField?.field?.id] | keyvalue)?.length">
    <div class="form-row w-100 mb-2" *ngFor="let item of fieldValueFrequency[resultField?.field?.id] | keyvalue">
      <div class="col-2 text-right d-flex justify-content-end align-items-center">{{item.value / resultField?.responses?.length * 100.0 | number:'1.0-1'}}%</div>
      <div class="col-8">
        <div class="custom-bar-chart">
          <div class="bar" [ngStyle]="{'width': (item.value / resultField?.responses?.length * 100.0 | number:'1.0-0') + '%'}"></div>
          <div class="d-flex align-items-center w-100">
            <div class="col-8">
              <span class="text-truncate">{{item.key}}</span>
            </div>
            <div class="col-4 text-right">
              <span class="text-truncate" i18n>{item.value, plural, =1 {{{item.value}} response} other {{{item.value}} responses}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="resultField?.field?.valueType == 'TEXT'">
    <div class="highlight" *ngFor="let response of resultField?.responses">
      <p class="mb-0">{{response.text}}</p>
    </div>
  </ng-container>
  <ng-container *ngIf="resultField?.field?.valueType == 'PERSON_SERIES'">
    <div class="highlight" *ngFor="let response of resultField?.responses">
      <p class="mb-0">{{ response?.personSeries?.name + (response?.personSeries?.locationRoot != '-' ? (' - ' + response?.personSeries?.locationRoot) : '') }}</p>
    </div>
  </ng-container>
  <ng-container *ngIf="resultField?.field?.valueType == 'PERSON_PARAMETER'">
    <div class="highlight" *ngFor="let response of resultField?.responses">
      <p class="mb-0">{{ response?.personParameter?.name + (response?.personParameter?.locationRoot != '-' ? (' - ' + response?.personParameter?.locationRoot) : '') }}</p>
    </div>
  </ng-container>
  <ng-container *ngIf="resultField?.field?.type == 'FORMULA' && resultField?.field?.formulaType == 'EXPRESSION'">
    <div class="row">
      <div class="col-auto">
        <label class="m-0 mr-2" i18n>Formula expression</label>
        <button class="btn btn-sm btn-link p-0"><ng-container *ngIf="resultField?.field?.symbol">{{resultField?.field.symbol}}&nbsp;=&nbsp;</ng-container>{{resultField?.field?.expression}}</button>
      </div>
    </div>
    <button class="btn btn-sm btn-primary mt-2" (click)="viewDatapoints(resultField)" i18n>View datapoint</button>
  </ng-container>
  <ng-container *ngIf="resultField?.field?.type == 'FORMULA' && resultField?.field?.formulaType == 'MAPPING'">
    <div class="row">
      <div class="col-auto">
        <label class="m-0 mr-2" i18n>Mapping</label>
        <button class="btn btn-sm btn-link p-0"><ng-container *ngIf="resultField?.field?.symbol">{{resultField?.field.symbol}}&nbsp;=&nbsp;</ng-container>&fnof;({{getVariableList(resultField?.field)}})</button>
      </div>
    </div>
    <button class="btn btn-sm btn-primary mt-2" *ngIf="resultField?.responses?.length" (click)="viewDatapoints(resultField)" i18n>View datapoint</button>
  </ng-container>
</div>
