export class UrlBuilder {

  public static build(baseUrl: string, contextPath?: string, params?: any): string {

    return baseUrl + (contextPath ? contextPath : '') + (
      params
        ? "?" + Object.entries(params).map(([key, value]) => {
        return (key + '=' + value);
      }).join('&')
        : '');
  }

}
