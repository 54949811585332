<div *ngIf="widget" class="dashboard-item">
  <div class="widget-titlebar d-flex justify-content-between">
    <h2 class="text-truncate">{{widget.name}}</h2>
    <div class="widget-actions">
       <div *ngIf="!isPublic && isOwner" class="widget-menu">
         <div class="btn-group btn-more gridster-item-content" dropdown>
           <button type="button" class="btn btn-icon dropdown-toggle" dropdownToggle><i class="far fa-ellipsis-v"></i></button>
           <div class="dropdown-menu dropdown-menu-right" *dropdownMenu>
             <a class="dropdown-item" (click)="editWidget()"><i class="fal fa-cog"></i>&nbsp;<span i18n>Edit</span></a>
             <a *ngIf="pngEnabled" class="dropdown-item" (click)="downloadPng()">
               <i class="fal fa-download"></i>&nbsp;<span i18n>PNG</span>
             </a>
             <a *ngIf="xlsEnabled" class="dropdown-item" (click)="downloadXls()">
               <i class="fal fa-download"></i>&nbsp;<span i18n>Excel</span>
             </a>
             <a class="dropdown-item" (click)="deleteWidget()"><i class="fal fa-trash"></i>&nbsp;<span i18n>Remove</span></a>
           </div>
         </div>
       </div>       
       <div *ngIf="isPublic && isAllowedDownloads" class="widget-menu">
         <div class="btn-group btn-more gridster-item-content" dropdown>
           <button type="button" class="btn btn-icon dropdown-toggle" dropdownToggle><i class="far fa-ellipsis-v"></i></button>
           <div class="dropdown-menu dropdown-menu-right" *dropdownMenu>
             <a *ngIf="pngEnabled" class="dropdown-item" (click)="downloadPng()">
               <i class="fal fa-download"></i>&nbsp;<span i18n>PNG</span>
             </a>
             <a *ngIf="xlsEnabled" class="dropdown-item" (click)="downloadXls()">
               <i class="fal fa-download"></i>&nbsp;<span i18n>Excel</span>
             </a>
           </div>
         </div>
       </div>
       <span *ngIf="!isPublic && isOwner" class="drag-handler"></span>
    </div>
  </div>
  <mydata-widget-value-table class="widget-body gridster-item-content" *ngIf="widget.type == 'VALUE_TABLE'" [widget]="widget" [rangeFrom]="rangeFrom" [rangeTo]="rangeTo" [aggregationGroupingType]="aggregationGroupingType" (viewReady)="widgetTypeViewReady($event)"></mydata-widget-value-table>
  <mydata-widget-time-frame-chart class="widget-body gridster-item-content" *ngIf="widget.type == 'TIME_FRAME_CHART'" [widget]="widget" [rangeFrom]="rangeFrom" [rangeTo]="rangeTo" [aggregationGroupingType]="aggregationGroupingType" [loadPng]="loadPng" (viewReady)="widgetTypeViewReady($event)"></mydata-widget-time-frame-chart>
  <mydata-widget-value-chart class="widget-body gridster-item-content" *ngIf="widget.type == 'VALUE_CHART'" [widget]="widget" [rangeFrom]="rangeFrom" [rangeTo]="rangeTo" [aggregationGroupingType]="aggregationGroupingType" [loadPng]="loadPng" (viewReady)="widgetTypeViewReady($event)"></mydata-widget-value-chart>
</div>
