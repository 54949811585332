import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dashboard-icon-shared',
  templateUrl: './icon-shared.component.svg'
})
export class IconSharedComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
