import { Injectable } from '@angular/core';
import {IdpAuthService} from '../idp-auth.service';
import {AuthParams} from '@smartencity/core';

@Injectable()
export class DemoAuthService extends IdpAuthService{

  constructor() {
    super();
  }

  getName(): string {
    return 'demo';
  }

  login(params: AuthParams): Promise<boolean> {
    return Promise.resolve(true);
  }

  logout(params?: any): Promise<boolean> {
    window.location.href = window.location.protocol + "//" + window.location.host;
    return Promise.resolve(true);
  }

}
