import { HttpClient, HttpXhrBackend } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { SMARTENCITY_MYDATA_CONFIG } from '@smartencity/mydata';
import { Widget, WidgetDatasetValue } from 'projects/mydata/src/lib/components/dashboard/widget';
import { MyDataConfig } from 'projects/mydata/src/lib/mydata-config.model';
import { Observable } from 'rxjs';
import { PublicDataService } from './publicdata.service';
import {WidgetDataService} from '@smartencity/core';

@Injectable()
export class PublicWidgetDataService extends WidgetDataService {

  private http: HttpClient;

  constructor(
    private httpBackend: HttpXhrBackend,
    @Inject(SMARTENCITY_MYDATA_CONFIG) private config: MyDataConfig,
    private publicDataService: PublicDataService
    ) {
    super();
    this.http = new HttpClient(httpBackend);
  }

  getWidgetValues(widget: Widget): Observable<WidgetDatasetValue[]> {
    return this.http.get<WidgetDatasetValue[]>(this.config.cityApiUrl + '/dashboard/' + this.publicDataService.dashboardFilter.dashboardId + '/widget/' + widget.id + '/dataset/data');
  }
}
