import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'icon-mydata-template',
  templateUrl: './icon-mydata-template.component.svg'
})
export class IconMydataTemplateComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
