<div class="modal-header">
  <div style="display: flex; align-items: stretch; flex-direction: column;">
    <h5 class="modal-title w-100" i18n>Shared history</h5>
  </div>
  <button type="button" class="close pull-right" i18n-aria-label aria-label="Close" (click)="close()">
    <i class="fal fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <div class="col-12">
    <table class="table table-responsive-md btn-table modal-table">
      <thead>
      <tr>
        <td><span i18n>Person</span></td>
        <td><span i18n>Assigned at</span></td>
        <td><span i18n>Revoked at</span></td>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let person of history.persons">
        <td>
          <span *ngIf="person.person.assignType === 'EMAIL'">{{person.person.email}}</span>
          <span *ngIf="person.person.assignType === 'REG_NO'">{{person.person.registrationNumber}}</span>
          <span *ngIf="person.person.assignType === 'PERSON'">{{person.person.person.displayName}} ({{person.person.person.registrationNumber}})</span>
        </td>
        <td>
          <span *ngIf="person.person.assignedAt">{{person.person.assignedAt|date:'dd.MM.yyyy HH:mm'}}</span>
        </td>
        <td>
          <span *ngIf="person.revokedAt">{{person.revokedAt|date:'dd.MM.yyyy HH:mm'}}</span>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary btn-sm" i18n (click)="close()">Close</button>
</div>
