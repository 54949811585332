import {Component, Inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {Subject} from 'rxjs/internal/Subject';
import {QuestionnaireResponseDetail} from '@smartencity/core';
import {SMARTENCITY_MYDATA_CONFIG} from '../../../injection-tokens';
import {MyDataConfig} from '../../../mydata-config.model';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'mydata-view-response',
  templateUrl: './view-response.component.html',
  styleUrls: ['./view-response.component.scss']
})
export class ViewResponseComponent implements OnInit, OnChanges, OnDestroy {
  private ngDestroy = new Subject<void>();

  @Input()
  questionnaireId: number;

  @Input()
  participantId: number;

  @Input()
  responseId: number;

  public response: QuestionnaireResponseDetail;

  constructor(
    @Inject(SMARTENCITY_MYDATA_CONFIG) private config: MyDataConfig,
    private http: HttpClient
  ) { }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadResponse();
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  loadResponse() {
    if (this.questionnaireId && this.responseId) {
      const endpoint = this.config.apiUrl + '/questionnaire/' + this.questionnaireId + '/response/' + this.responseId;

      this.http.get(endpoint, {}).subscribe((data: QuestionnaireResponseDetail) => {
        this.response = data;
      });
    }
  }
}
