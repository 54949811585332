import {Injectable, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs/internal/Subject';
import {Observable} from 'rxjs';
import {MobilitySearchResults} from '../model/search/mobility-search-results';
import {
  takeUntil,
  distinctUntilChanged,
} from 'rxjs/operators';

import {
  combineLatest,
  switchMap,
  of,
  startWith
} from 'rxjs';

import {MobilityDisplaySettings} from '../mobility-config.model';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {MobilitySearchCriteria} from '../model/search/mobility-search-criteria';
import {MobilityService} from './mobility.service';
import {MobilityGmapService} from './mobility-gmap.service';


@Injectable()
export class MobilitySearchService implements OnDestroy {
  private ngDestroy = new Subject<void>();

  private criteriaSubject = new BehaviorSubject<MobilitySearchCriteria>(null);

  private criteria$ = this.criteriaSubject.asObservable();

  private searchResultsSubject = new BehaviorSubject<MobilitySearchResults>(new MobilitySearchResults());

  private searchResults$: Observable<MobilitySearchResults> = this.searchResultsSubject.asObservable();

  constructor(private mobilityService: MobilityService, private mobilityMapService: MobilityGmapService) {
  }

  ngOnDestroy(): void {
    this.ngDestroy.complete();
  }

  public initSearch(keywordSearch$: Observable<string>): Observable<MobilitySearchResults> {
    combineLatest([keywordSearch$, this.criteria$.pipe(distinctUntilChanged()), this.mobilityMapService.stateDispatch$.pipe(startWith(null))]).pipe(
      switchMap(([keyword, criteria,]: [string, MobilitySearchCriteria, void]) => {
        return of(this.searchAll(keyword, criteria));
      }),
      takeUntil(this.ngDestroy)
    ).subscribe((results: MobilitySearchResults) => {
      this.updateSearchResults(results);
    });

    return this.searchResults$;
  }

  private updateSearchResults(results: MobilitySearchResults): void {
    this.searchResultsSubject.next(results);
  }

  private searchAll(keyword: string, criteria: MobilitySearchCriteria): MobilitySearchResults {
    const result: MobilitySearchResults = new MobilitySearchResults();
    if (criteria == null || !keyword || keyword.length < 3) {
      return result;
    }

    if (criteria.buses) {
      result.buses = this.mobilityService.searchBusesByKeyword(keyword);
    }

    if (criteria.stops) {
      result.stops = this.mobilityService.searchStopsByKeyword(keyword);
    }

    if (criteria.throughputPoint) {
      result.throughputPoints = this.mobilityService.searchTrafficPointsByKeyword(keyword)
    }

    if (criteria.electricVehicleCharger) {
      result.evChargers = this.mobilityService.searchEvChargersByKeyword(keyword)
    }

    if (criteria.evChargerDk) {
      result.evChargersDk = this.mobilityService.searchEvChargersDkByKeyword(keyword)
    }

    if (criteria.bicycleStation) {
      result.bicycleStations = this.mobilityService.searchBicycleStationsByKeyword(keyword)
    }

    return result;
  }

  setUpdateDisplay(displaySettings: MobilityDisplaySettings) {
    this.criteriaSubject.next({
      buses: displaySettings.buses?.active,
      stops: displaySettings.stops?.active,
      throughputPoint: displaySettings.trafficThroughput?.active,
      electricVehicleCharger: displaySettings.electricVehicleCharger?.active,
      bicycleStation: displaySettings.bicycleStation?.active,
      evChargerDk: displaySettings.evChargerDk?.active
    });
  }

}
