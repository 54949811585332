import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mobility-bus-info-legend',
  templateUrl: './bus-info-legend.component.html'
})
export class BusInfoLegendComponent implements OnInit {

  public movingBusIcon = 'assets/img/icons/bus__moving.svg';
  public idleBusIcon = 'assets/img/icons/bus__idle.svg';
  public selectedBusIcon = 'assets/img/icons/bus__selected.svg';

  constructor() { }

  ngOnInit() {
  }

}
