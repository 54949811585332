import {Inject, Injectable} from '@angular/core';
import {SourceUpdateErrorService} from './source-update-error.service';
import {HttpClient} from '@angular/common/http';
import {CoreConfig, SMARTENCITY_CORE_CONFIG} from '@smartencity/core';
import {Observable} from 'rxjs';
import {SourceUpdateError} from '../models/source-update-error';

@Injectable()
export class FormulaSourceUpdateErrorService implements SourceUpdateErrorService{

  constructor(private http: HttpClient,
              @Inject(SMARTENCITY_CORE_CONFIG) private config: CoreConfig) {
  }

  loadErrors(sourceId: number): Observable<SourceUpdateError[]> {
    return this.http.get<any>(this.config.apiUrl + '/questionnaire/' + sourceId + '/formula-update-error');
  }

  resetErrors(sourceId: number): Observable<void> {
    return this.http.get<void>(this.config.apiUrl + '/questionnaire/' + sourceId + '/formula-update-error/reset');
  }

}
