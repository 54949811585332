import {Inject, Injectable} from '@angular/core';
import {NemLogInRedirectStrategy} from './nem-log-in-redirect-strategy';
import {NemLogInLogoutRedirectStrategy} from './nem-log-in-logout-redirect-strategy';
import {IdpAuthService} from '../idp-auth.service';
import { AuthParams } from '../auth.service';
import {CoreConfig, LocationService, SMARTENCITY_CORE_CONFIG} from '@smartencity/core';


@Injectable()
export class NemLogInAuthService extends IdpAuthService {

  constructor(@Inject(SMARTENCITY_CORE_CONFIG) private config: CoreConfig, private locationService: LocationService) {
    super();
  }

  getName(): string {
    return 'nem-log-in';
  }

  public login(params: AuthParams): Promise<boolean> {
    if (!this.loginRedirectStrategy) {
      this.loginRedirectStrategy = new NemLogInRedirectStrategy(this.config, this.locationService.getCityPortalHost());
    }

    return this.doLogin(params);
  }

  public logout(params?: any): Promise<boolean> {
    //lisab runtimes, sest kompileerimise hetkel ei ole konf laetud veel
    if (!this.logoutRedirectStrategy) {
      this.logoutRedirectStrategy = new NemLogInLogoutRedirectStrategy(this.config.apiUrl);
    }

    return this.doLogout(params);
  }

}
