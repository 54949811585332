import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ActivityType} from '../../../model/crowd-insights/activity-type';
import {ActivityTypeFilterOption} from './activity-type-filter-option';

@Component({
  selector: 'ci-activity-type-filter',
  templateUrl: './activity-type-filter.component.html'
})
export class ActivityTypeFilterComponent implements OnInit {

  @Input()
  public defaultSelectedValue: ActivityType;

  @Input()
  public filterOptions: ActivityTypeFilterOption[] = [];

  @Output()
  public optionSelected: EventEmitter<ActivityTypeFilterOption> = new EventEmitter<ActivityTypeFilterOption>();

  public selectedValue: ActivityType;

  constructor() { }

  ngOnInit(): void {
    this.selectedValue = this.defaultSelectedValue;
  }

  selectActivityType(option: any): void {
    this.selectedValue = option.value;
    this.optionSelected.next(option);
  }

}
