import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';

import {HttpClient} from '@angular/common/http';
import {Subject} from 'rxjs/internal/Subject';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {Location, NotificationAddress, UserService} from '@smartencity/core';
import {FormControl, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {takeUntil} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
const selector = 'mydata-notification-address-edit-modal';
let nextId = 0;

@Component({
  templateUrl: './notification-address-edit-modal.component.html',
  providers: [],
})
export class NotificationAddressEditModalComponent implements OnInit, OnDestroy, OnChanges {
  id = `${selector}-${nextId++}`;
  private ngDestroy = new Subject<void>();

  public progress = false;

  public location: Location;

  public showErrors = false;

  @Input()
  notificationAddress: NotificationAddress;

  @Output('saved')
  savedEmitter: EventEmitter<any> = new EventEmitter<any>();

  form: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
  });

  constructor(
    private userService: UserService,
    private http: HttpClient,
    private toastr: ToastrService,
    private modalRef: BsModalRef
  ) {}

  ngOnInit(): void {
    if (this.notificationAddress) {
      this.updateFormContent();
    }
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['notificationAddress']) {
      this.updateFormContent();
    }
  }

  control(name?: string) {
    if (!name) {
      return this.form;
    }
    return this.form.get(name);
  }

  invalid(name: string, formGroup?) {
    if (!formGroup) {
      formGroup = this.form;
    }
    const control = formGroup.get(name);
    return control && control.invalid && (control.dirty || control.touched);
  }

  errors(name: string): ValidationErrors {
    const control = this.form.get(name);
    return control ? control.errors : null;
  }

  private updateFormContent() {
    this.form.patchValue({
      name: this.notificationAddress ? this.notificationAddress.name : null,
      email: this.notificationAddress ? this.notificationAddress.email : null,
    }, {onlySelf: true, emitEvent: false});
  }

  public close(): void {
    this.modalRef.hide();
  }

  save() {
    this.form.markAsDirty();

    if (!this.form.valid) {
      this.showErrors = true;

      console.log(this.form);

      return;
    }

    const value = this.form.getRawValue();
    this.progress = true;

    const notificationAddress = new NotificationAddress();
    notificationAddress.id = this.notificationAddress.id;
    notificationAddress.name = value.name;
    notificationAddress.email = value.email;

    let saver: Observable<NotificationAddress>;

    if (this.notificationAddress && this.notificationAddress.id) {
      saver = this.userService.updateNotificationAddress(this.notificationAddress.id, notificationAddress);
    } else {
      saver = this.userService.addNotificationAddress(notificationAddress);
    }

    saver.pipe(takeUntil(this.ngDestroy)).subscribe((na: NotificationAddress) => {
      this.modalRef.hide();
      this.savedEmitter.emit();
      this.toastr.success($localize`Notification address saved`);
    }, (error) => {
      this.progress = false;
      this.toastr.error($localize`Saving notification address failed`);
    }, () => this.progress = false);
  }
}
