import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {IndexComponent} from '@smartencity/core';
import {WeatherComponent} from '@smartencity/weather';
import {MobilityComponent} from '@smartencity/mobility';
import {LightsComponent} from '@smartencity/lights';
import {BuildingsComponent} from '@smartencity/buildings';
import {PeopleComponent} from '@smartencity/people';
import {ServicesComponent} from '@smartencity/services';
import {AuthGuard, MainComponent} from '@smartencity/core';
import {NotFoundComponent} from './not-found/not-found.component';
import {AuthRedirectComponent} from '../../projects/core/src/lib/old-components/auth/auth-redirect.component';
import {AppModulesLoadGuard} from './app-modules-load.guard';
import {AppModulesActivateGuard} from './app-modules-activate.guard';
import {PublicDataComponent} from '@smartencity/publicdata';
import { MeteorologyComponent } from '@smartencity/meteorology';

const routes: Routes = [
  {path: '', component: IndexComponent },
  {path: 'home', component: IndexComponent },
  {
    path: '',
    component: MainComponent,
    children: [
      {
        path: 'tallinn', loadChildren: () => import('@smartencity/tallinn').then(m => m.TallinnModule),
        canLoad: [AppModulesLoadGuard]
      },
      {
        path: 'buildings', component: BuildingsComponent, canActivate: [AppModulesActivateGuard]

      },
      {
        path: 'gas', loadChildren: () => import('@smartencity/gas').then(m => m.GasModule),
        canLoad: [AppModulesLoadGuard]
      },
      {
        path: 'arcgis', loadChildren: () => import('@smartencity/arcgis').then(m => m.ArcGISModule),
        canLoad: [AppModulesLoadGuard]
      },
      {
        path: 'services', component: ServicesComponent, canActivate: [AppModulesActivateGuard]
      },
      {
        path: 'mobility', component: MobilityComponent, canActivate: [AppModulesActivateGuard]
      },
      {
        path: 'lights', component: LightsComponent, canActivate: [AppModulesActivateGuard]
      },
      {
        path: 'people', component: PeopleComponent, canActivate: [AppModulesActivateGuard]
      },
      {
        path: 'weather', component: WeatherComponent, canActivate: [AppModulesActivateGuard]
      },
      {
        path: 'publicdata', component: PublicDataComponent, canActivate: [AppModulesActivateGuard]
      },
      {
        path: 'meteorology', component: MeteorologyComponent, canActivate: [AppModulesActivateGuard]
      },
      {
        path: 'mydata', loadChildren: () => import('@smartencity/mydata').then(m => m.MydataModule), canActivate: [AuthGuard]
      }
    ]
  },
  {
    path: 'auth',
    component: AuthRedirectComponent
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule {
}
