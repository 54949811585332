import {NoiseLevel} from '../component/noise-level-heatmap/noise-level-heatmap.model';

export class NoiseLevelOverlayView extends google.maps.OverlayView {
  private div: any;

  public items: NoiseLevel[];
  private popoverTimeout = null;

  constructor(items: NoiseLevel[]) {
    super();
    this.items = items;
  }

  draw() {
    if (!this.div) {
      const panes = this.getPanes();
      if (panes == null) {
        return;
      }

      this.div = document.createElement('DIV');
      this.div.style.position = 'absolute';
      this.div.style.cursor = 'pointer';
      this.div.style.position = 'absolute';
      this.div.classList.add('custom-marker-layer');
      this.div.style.paddingLeft = '0px';
      panes.overlayMouseTarget.appendChild(this.div);
    }

    this.div.innerHTML = this.getLayerHtml();

    this.resetPopovers();
  }

  private resetPopovers() {
    if (this.popoverTimeout != null) {
      clearTimeout(this.popoverTimeout);
    }
    this.popoverTimeout = setTimeout(() => {
      $('.popover.show').remove();
      ($('[data-toggle="popover"]') as any).popover({
        trigger: 'hover'
      });

    }, 400);
  }

  private getLayerHtml() {
    let html = '';
    if (!this.items) {
      return html;
    }
    this.items.forEach(item => {
      const point = this.getProjection().fromLatLngToDivPixel(new google.maps.LatLng(item.lat, item.lng));
      const classNames = [
        'pointer',
        'heatmap-value'
      ];
      if (item.value >= 75) {
        classNames.push('threshold-indicator');
      }
      const x = point.x - 8;
      const y = point.y - 8;
      const className = classNames.join(' ');
      const popoverContent = '<strong>' + item.name + '</strong><br />' + item.value + ' dB';
      html += '<div class="' + className + '" style="left: ' + x + 'px; top: ' + y + 'px" data-content="' + popoverContent + '">' + (item.value != null ? item.value : '') + '</div>';
    });
    return html;
  }

  setMap(map) {
    super.setMap(map);
  }

  remove() {
    this.setMap(null);
    if (this.div && this.div.parentNode) {
      this.div.parentNode.removeChild(this.div);
      this.div = null;
    }
  }
}
