import {AbstractMapLayerMarker, OlMapWrapper} from '@smartencity/core';
import { BicycleStation } from '../component/bicycle-stations-heatmap/bicylce-stations.model';
import { BicycleStationOlMarkerWrapper } from './bicycle-stations-ol-marker-wrapper';

export class BicycleStationOlMarker extends AbstractMapLayerMarker<OlMapWrapper, BicycleStation>{

  private marker: BicycleStationOlMarkerWrapper;

  private selected = false;

  constructor(bicycleStation: BicycleStation, map: OlMapWrapper) {
    super(bicycleStation);
    this.marker = new BicycleStationOlMarkerWrapper(bicycleStation, map, {
      title: bicycleStation.name,
      position: [bicycleStation.lng, bicycleStation.lat]
    });
  }

  draw() {
    this.marker.draw();
  }

  setMap(map: OlMapWrapper) {
    super.setMap(map);
    const olMap = (map ? map.getMap() : null);
    this.marker.setMap(olMap);
  }

  isSelected(): boolean {
    return this.selected;
  }

  setSelected(selected: boolean): void {
    this.selected = selected;
  }
}
