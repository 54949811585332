<div class="mb-3">
  <div class="row justify-content-center">
    <div class="col-sm-12 col-md-6 col-lg-4">
      <div class="input-group input-group-lg form-search" [formGroup]="filterForm">
        <input type="text" class="form-control form-control-lg" placeholder="Type to search..." i18n-placeholder formControlName="query">
        <div class="input-group-append">
          <span class="clear-search" (click)="clearSearch()">
            <i class="fal fa-times"></i>
          </span>
          <div class="custom-dropdown filter-dropdown" dropdown [autoClose]="true" (click)="$event.stopImmediatePropagation();">
            <button type="button" class="btn dropdown-toggle" dropdownToggle i18n>Filter</button>
            <div class="dropdown-menu dropdown-menu-right" *dropdownMenu>
              <div class="accordion-group"  [ngClass]="'show'">
                <div class="accordion-header">
                  <i class="fal fa-angle-down"></i>
                  <ng-container i18n>Status</ng-container>
                </div>
                <div class="accordion-content">
                  <div class="checkbox">
                    <input type="checkbox" formControlName="confirmationStatusPending">
                    <label i18n>Pending</label>
                  </div>
                  <div class="checkbox">
                    <input type="checkbox" formControlName="confirmationStatusAccepted">
                    <label i18n>Accepted</label>
                  </div>
                  <div class="checkbox">
                    <input type="checkbox" formControlName="confirmationStatusDeclined">
                    <label i18n>Declined</label>
                  </div>
                </div>
              </div>
              <div class="accordion-group"  [ngClass]="'show'">
                <div class="accordion-header" *ngIf="isCityUser">
                  <i class="fal fa-angle-down"></i>
                  <ng-container i18n>Show on city portal</ng-container>
                </div>
                <div class="accordion-content" *ngIf="isCityUser">
                  <div class="checkbox">
                    <input type="checkbox" formControlName="showOnCityPortalTrue">
                    <label i18n>Yes</label>
                  </div>
                  <div class="checkbox">
                    <input type="checkbox" formControlName="showOnCityPortalFalse">
                    <label i18n>No</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <span class="input-group-text"><i class="fal fa-search"></i></span>
        </div>
      </div>
    </div>
    <div class="col-12 mt-2">
      <div class="d-flex justify-content-center flex-wrap flex-md-nowrap" *ngIf="filterForm.get('confirmationStatusPending').value || filterForm.get('confirmationStatusAccepted').value || filterForm.get('confirmationStatusDeclined').value">
        <label i18n>Filter</label>
        <span class="filter-tag" *ngIf="filterForm.get('confirmationStatusPending').value">{{'PENDING' | label}} <i class="fal fa-times" (click)="filterForm.get('confirmationStatusPending').setValue(false)"></i></span>
        <span class="filter-tag" *ngIf="filterForm.get('confirmationStatusAccepted').value">{{'ACCEPTED' | label}} <i class="fal fa-times" (click)="filterForm.get('confirmationStatusAccepted').setValue(false)"></i></span>
        <span class="filter-tag" *ngIf="filterForm.get('confirmationStatusDeclined').value">{{'DECLINED' | label}} <i class="fal fa-times" (click)="filterForm.get('confirmationStatusDeclined').setValue(false)"></i></span>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col mt-2 text-right">
      <button type="button" class="btn btn-sm btn-primary mr-2" (click)="bulkEdit()" [hidden]="checkedEditableCount() == 0"><i class="fa fa-edit"></i>&nbsp;<ng-container i18n>Bulk edit</ng-container>&nbsp;({{checkedEditableCount()}})</button>
      <button type="button" class="btn btn-sm btn-danger mr-2" (click)="bulkDecline()" [hidden]="checkedDeclinableCount() == 0"><i class="fa fa-times"></i>&nbsp;<ng-container i18n>Decline selected</ng-container>&nbsp;({{checkedDeclinableCount()}})</button>
      <button type="button" class="btn btn-sm btn-outline-danger mr-2" (click)="bulkArchive()" [hidden]="checkedArchivableCount() == 0"><i class="fa fa-trash"></i>&nbsp;<ng-container i18n>Archive selected</ng-container>&nbsp;({{checkedArchivableCount()}})</button>
    </div>
  </div>
 </div>

 <div class="row">
  <div class="col-12">
    <datapoint-table [rows]="rows" [page]="page" (rowAction)="onRowAction($event)" (tableAction)="onTableAction($event)" (rowsSelect)="onRowsSelect($event)"></datapoint-table>
  </div>
 </div>
