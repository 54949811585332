export class CookieSettings {
  analytics = false;
  version: number;

  constructor(args?: { analytics: boolean; version: number }) {
    if (!args) {
      return;
    }
    this.analytics = args.analytics;
    this.version = args.version;
  }

}
