import {Component, ElementRef, Inject, LOCALE_ID, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '../../auth/auth.service';
import {CityApiService} from '../../http/city-api/city-api.service';
import {CoreConfig} from '../../core-config.model';
import {SMARTENCITY_CORE_CONFIG} from '../../injection-tokens';
import {AnimationOptions} from 'ngx-lottie';
import {SiteLocationNameHelper} from '../../helpers/site-location-name-helper';

@Component({
  selector: 'core-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit, OnDestroy {

  public logoImgSrc = 'assets/img/city_portal_logo.svg';
  public bgVideoSrc = 'assets/img/tartu/bg-tartu.mp4';
  public bgVideoSrcWebM = 'assets/img/tartu/bg-tartu-webm.webm'

  public temperature: number = null;
  public temperatureTime: Date = null;
  public buses = null;
  public lights = null;
  public location: string = this.config.locationName;
  public video;
  public lottieOptions: AnimationOptions;

  public evChargers = null;

  private demoUserLoginClickTimeoutHandler;

  public buildingsStats = {
    energyConsumption: { value: 'N/A' },
    energyProduction: { value: 'N/A' },
    heatConsumption: { value: 'N/A' },
  };

  public cityServicesStats = null;

  constructor (
    public authService: AuthService,
    private router: Router,
    private renderer: Renderer2,
    private cityService: CityApiService,
    @Inject(SMARTENCITY_CORE_CONFIG) public config: CoreConfig,
    @Inject(LOCALE_ID) public locale: string
  ) {

    if (config.theme === 'tartu') {
      this.logoImgSrc = 'assets/img/tartu/tartu_logo_color.svg';
      this.lottieOptions = {
        path: 'assets/img/tartu/bg.json',
        renderer: 'svg',
        rendererSettings: {
          progressiveLoad: false,
          preserveAspectRatio: 'xMidYMid slice'
        }
      };
    } else if (config.theme === 'greengas') {
      this.logoImgSrc = 'assets/img/gaas/eesti_gaas_logo_color.svg';
      this.lottieOptions = {
        path: 'assets/img/gaas/gaas_bg.json',
        renderer: 'svg',
        rendererSettings: {
          progressiveLoad: false,
          preserveAspectRatio: 'xMidYMid slice'
        }
      };
    } else if (config.theme === 'tallinn') {
      this.logoImgSrc = 'assets/img/tallinn/tallinn_logo_color.svg';
      this.lottieOptions = {
        path: 'assets/img/tallinn/tallinn_bg.json',
        renderer: 'svg',
        rendererSettings: {
          progressiveLoad: false,
          preserveAspectRatio: 'xMidYMid slice'
        }
      };
    } else if (config.theme === 'mainor') {
      this.logoImgSrc = 'assets/img/mainor/ylemiste-logo-black.svg';
    } else if (config.theme === 'elering') {
      this.logoImgSrc = 'assets/img/elering/elering_logo_default.svg';
    }
    this.renderer.addClass(document.body, 'landing-page');
    this.location = SiteLocationNameHelper.getInLocationPhrase(this.location, this.locale);
  }

  public model: any;

  ngOnInit () {
    if (this.config.noLandingPageText) {
      return;
    }

    if (this.config.weatherService === 'weather') {
      this.cityService.getWeather().subscribe(
        (data: any) => {
          if (!data?.temperature) {
            return;
          }
          this.temperature = data.temperature.value;
          this.temperatureTime = new Date(data.temperature.time);
        },
        (err: any) => {
        }
      );
    } else {
      this.cityService.getForecast().subscribe(
        (data: any) => {
          if (!data?.current) {
            return;
          }
          this.temperature = data.current.temperature;
        },
        (err: any) => {
        }
      );
    }

    this.cityService.getBusesCount().subscribe(
      (data: any) => {
        if (!data.count) {
          return;
        }
        this.buses = data.count;
      },
      (err: any) => {
      }
    );

    this.cityService.getLitCount().subscribe(
      (data: any) => {
        if (!data.count) {
          return;
        }
        this.lights = data.count;
      },
      (err: any) => {
      }
    );

    this.cityService.getServicesStats().subscribe((stats: any) => {
      this.cityServicesStats = stats;
    });

    this.cityService.getBuildingsStats().subscribe((result: any) => {
      if (result) {
        if (result.energyConsumption?.value) {
          this.buildingsStats.energyConsumption = result.energyConsumption;
        }

        if (result.energyProduction?.value) {
          this.buildingsStats.energyProduction = result.energyProduction;
        }

        if (result.heatConsumption?.value) {
          this.buildingsStats.heatConsumption = result.heatConsumption;
        }
      }
    });

  }

  public cancelHoldToLoginAsDemoUser(): void {
    if (this.demoUserLoginClickTimeoutHandler) {
      clearInterval(this.demoUserLoginClickTimeoutHandler);
      this.demoUserLoginClickTimeoutHandler = null;
    }
  }

  public holdToLoginAsDemoUser(event: any): void {
    if (this.authService.isAuthenticated) {
      return;
    }

    this.demoUserLoginClickTimeoutHandler = setTimeout(() => {
      this.authService.setLoginMode("demo", true);
    }, 1000);
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'landing-page');
  }

}
