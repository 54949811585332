import {Person} from './person';
import {Tenant} from './tenant';
import {Consent} from './consent';
import {NumberFormatConstants} from '../constants/number-format-constants';

export class PendingSeries {
  assignerPerson: Person;
  assignerComment: string;
  personSeries: PersonSeries;
  period: PersonSeriesPeriod;
}

export class PendingPersonSeriesCancellation {
  id: number;
  comment: string;
  personSeries: PersonSeries;
}

export class PendingSeriesMeta {
  assignerPerson: Person;
  assignerComment: string;
  personSeries: PersonSeries;
  personSeriesMeta: PersonSeriesMeta;
  period: any;
}

export class PersonSeriesResponse {
  content: PersonSeries[];
  page: number;
  size: number;
  totalElements: number;
  totalPages: number;
}

export enum LocationType {
  ADDRESS = 'ADDRESS',
  UUID = 'UUID',
  COORDS = 'COORDS'
}

export class PersonSeries {
  id: number;
  name: string;
  description: string;
  groupingTag: string;//todo remove
  groupingTags: string[] = [];
  seriesType: string;
  person: Person;
  createdByPerson: Person;
  locationType: LocationType;
  adsOid: string;
  address: string;
  apartment: string;
  room: string;
  lat: number;
  lng: number;
  uuid: string;
  updatedAt: string;
  location: string;
  locationRoot: string;
  latestValue: LatestValue;
  showOnCityPortal: boolean;
  cityPortalType: string;
  readableAddress: string;
  sourceAssignedPerson: Person;
  lastPeriod: PersonSeriesPeriod;
  sourceUrl: string;
  targetUrl: string;
  numberFormat: SeriesNumberFormat;
}

export class SeriesNumberFormat {
  maxFractionDigits: number = NumberFormatConstants.DEFAULT_NUMBER_FORMAT.defaultFractionDigits;
}

export class PersonSeriesMeta {
  id: number;
  name: string;
  description: string;
  groupingTag: string;
  seriesType: string;
  person: Person;
  locationType: LocationType;
  adsOid: string;
  address: string;
  apartment: string;
  room: string;
  lat: number;
  lng: number;
  uuid: string;
  comment: string;
  updatedAt: string;
}

export class PersonSeriesPeriod {
  id: string;
  confirmationStatus: string;
  ownerType: OwnerType;
  startAt: string;
  endAt: string;
  updatedAt: string;
  person: Person;
  consentGroupId: number;
}

export class Threshold {
  id: number;
  personSeriesId: number;
  name: string;
  value: number;
  comparisonOperator: string;
  enabled: boolean;
  createdAt: string;
  updatedAt: string;
}

export enum OwnerType {
  OWNER = 'OWNER',
  MANAGER = 'MANAGER',
  DATA_OWNER = 'DATA_OWNER',
  CONSENT = 'CONSENT',
  GROUP_CONSENT = 'GROUP_CONSENT'
}

export class SourceOwnerSeriesResponse {
  content: SourceOwnerSeries[];
  page: number;
  size: number;
  totalElements: number;
  totalPages: number;
}

export class SourceOwnerSeries {
  id: number;
  updatedAt: string;
  lastDataOwnerPeriod: PersonSeriesPeriod;
  lastPeriod: PersonSeriesPeriod;
  personSeries: PersonSeries;
  latestValue: LatestValue;
  source: SourceInfo;
  thresholds: Threshold[];
  consents: Consent[];
  groups: PersonSeriesGroup[];
}

export class SourceOwnerSeriesListItem {
  id: number;
  series: SourceOwnerSeries;
  name: string;
  description: string;
  updatedAt: string;
  ownerType: string;
  confirmationStatus: string;
  showOnCityPortal: boolean;
  sourceType: string;
}

export class SourceReference {
  id: number;
  type: SourceType;
}

export class SourceInfo {
  id: number;
  type: SourceType;
  samplingType: SamplingType;
}

export enum SourceType {
  TENANT_MEASUREMENT = 'TENANT_MEASUREMENT',
  TENANT_EVENT = 'TENANT_EVENT',
  API_DATA = 'API_DATA',
  PERSON_DATA = 'PERSON_DATA',
  GROUP = 'GROUP',
  FORMULA = 'FORMULA'
}

export enum SamplingType {
  HOURLY = 'HOURLY',
  DAILY = 'DAILY',
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY'
}

export class SeriesTypesResponse {
  content: string[];
}


export class LatestValue {
  event: any;
  measurement: any;
}

export class FirstValue {
  event: any;
  measurement: any;
}

export class SeriesType {
  public static types = [];
}

export class GroupingTags {
  public static tags = [
    'STREET_LIGHTS',
    'BUSES',
    'BUS_STOPS',
    'BUILDINGS',
    'TRAFFIC_LIGHTS',
    'CELL_TOWERS',
    'SOLAR_PANELS',
    'WIND_TURBINES',
    'WEV_CHARGERS',
    'BICYCLES',
    'WEATHER',
    'PEOPLE',
    'DATA'
  ];
}

export class PersonSeriesHistoricalResponse {
  event: EventHistory;
  measurement: Measurement;
}

export class Measurement {
  series: SeriesHistory;
}

export class SeriesHistory {
  series: Series[];
  values: any;
  truncated: boolean;
}

export class Series {
  name: string;
  type: string;
  unit: string;
}

export class EventHistory {
  events: any[];
  truncated: boolean;
}

export class SeriesTargetError {
  seriesErrorId: number;
  targetId: number;
  name: string;
  personSeries: PersonSeries;
  errorCount: number;
  lastErrorMessage: String;
  lastErrorAt: Date;
  tenant: Tenant;
  createdAt: string;
  updatedAt: string;
}

export class PersonSeriesGroup {
  id: number;
  name: string;
  type: string;
  groupingTag: string;
  person: Person;
  personSeriesCount: number = 0;
  ownerType: OwnerType;
  lastPeriod: PersonSeriesGroupPeriodRef;
  preset = false;
}

export class PersonSeriesGroupListItem {
  groupId: number;
  name: string;
  type: string;
  personSeriesCount: number = 0;
  shared = false;
  preset = false;
}

export class PersonSeriesGroupRef {
  id: number;
  name: string;
}

export class PersonSeriesGroupPeriodRef {
  id: number;
  ownerType: OwnerType;
  startAt: Date;
  endAt: Date;
  confirmationStatus: string;
  person: Person;
}

export class PersonSeriesGroupPeriod {
  id: number;
  personSeriesGroup: PersonSeriesGroup;
  ownerType: OwnerType;
  startAt: Date;
  endAt: Date;
  confirmationStatus: string;
  person: Person;
}

export class PersonSeriesGroupConsent {
  ownerGroup: PersonSeriesGroup;
  consent: PersonSeriesGroupPeriod;
}

export class PendingPersonSeriesGroup {
  assignerPerson: Person;
  assignerComment: string;
  personSeriesGroup: PersonSeriesGroup
  period: PersonSeriesGroupPeriodRef;
}
