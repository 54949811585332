import { NgModule } from '@angular/core';
import { GasComponent } from './gas.component';
import { SearchComponent } from './search/search.component';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {GasRoutingModule} from './gas-routing.module';
import { LocationModalComponent } from './location-modal/location-modal.component';
import {CoreModule, UnitPipe} from '@smartencity/core';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import { EnvIndicatorsComponent } from './location-modal/env-indicators/env-indicators.component';
import { SolarIndicatorsComponent } from './location-modal/solar-indicators/solar-indicators.component';
import {GasMapService} from './gas-map.service';
import {SharedModule} from '@smartencity/shared';


@NgModule({
  declarations: [GasComponent, SearchComponent, LocationModalComponent, EnvIndicatorsComponent, SolarIndicatorsComponent],
  imports: [
    CommonModule,
    GasRoutingModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    TooltipModule,
    SharedModule
  ],
  providers: [
    GasMapService,
    UnitPipe
  ],
  exports: [GasComponent]
})
export class GasModule { }
