<h5 class="text-uppercase">{{weatherStation.name}}</h5>
<table class="table">
  <tbody>
    <tr class="w-100" *ngFor="let row of data">
      <ng-container *ngFor="let col of row.cols; let i = index;">
        <td class="pl-0">{{col.label}}</td>
        <td class="col-md-5">{{col.value}} {{col.unit}}</td>
      </ng-container>
    </tr>
    <tr>
      <td colspan="4" class="pl-0 text-center"><span i18n>Updated at</span>: {{this.updatedAt|date:'dd.MM.yyyy HH:mm'}}</td>
    </tr>
    <tr>
      <td colspan="4" class="pl-0 text-center"><span i18n>Data from: </span> <a href="https://www.ilmateenistus.ee/"
                                                                                target="_BLANK">https://www.ilmateenistus.ee/</a>
      </td>
    </tr>
  </tbody>
</table>
