import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {SMARTENCITY_CORE_CONFIG, CoreConfig, WidgetDataService} from '@smartencity/core';
import { PublicDataService } from './publicdata.service';
import { Dashboard } from 'projects/mydata/src/lib/components/dashboard/dashboard';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import { DashboardWidgetTemplate, Widget } from 'projects/mydata/src/lib/components/dashboard/widget';
import {CompactType, GridsterComponent, GridsterConfig, GridsterItem, GridType} from 'angular-gridster2';
import { DecimalPipe } from '@angular/common';
import { PublicWidgetDataService } from './publicwidgetdata.service';

@Component({
  selector: 'publicdata-main',
  templateUrl: './publicdata.component.html',
  providers: [
    { provide: WidgetDataService, useClass: PublicWidgetDataService },
    DecimalPipe
  ]
})
export class PublicDataComponent implements OnInit, OnDestroy {
  private ngDestroy = new Subject<void>();

  public dashboards: Dashboard[] = null;
  public widgets$ = new Subject<Widget[]>();
  public widgetTemplates: DashboardWidgetTemplate[] = [];

  @ViewChild('grid')
  public grid: GridsterComponent;

  options: GridsterConfig;
  dashboard: Array<GridsterItem> = [];

  public currentDashboard: Dashboard;

  constructor(
    @Inject(SMARTENCITY_CORE_CONFIG) public config: CoreConfig,
    private publicDataService: PublicDataService
  ) {

    this.publicDataService.getDashboards().pipe(takeUntil(this.ngDestroy)).subscribe((dashboards) => {
      this.handleDashboards(dashboards);
    });

    this.publicDataService.widgetItems$.pipe(takeUntil(this.ngDestroy)).subscribe((items) => {
      this.dashboard = items;
    });
  }

  private handleDashboards(dashboards: Dashboard[]): void {
    this.dashboards = dashboards;

    if (dashboards.length > 0) {

      if(this.publicDataService.getCurrentDashboard() === null) {

        this.currentDashboard = dashboards[0];
        this.publicDataService.setCurrentDashboard(this.currentDashboard);
      }
    }
    this.publicDataService.initialize();
  }

  selectDashboard(dashboard: Dashboard): void {
    this.currentDashboard = dashboard;
    this.publicDataService.setCurrentDashboard(dashboard);
    this.publicDataService.reload();
  }

  ngOnInit() {
    this.options = {
      keepFixedHeightInMobile: true,
      gridType: GridType.VerticalFixed,
      setGridSize: true,
      fixedRowHeight: 150,
      compactType: CompactType.CompactLeftAndUp,
      minCols: 6,
      maxCols: 12,
      minRows: 1,
      minItemCols: 1,
      minItemRows: 2,
      maxItemCols: 12,
      maxItemRows: 12,
      margin: 20,
      pushItems: true,
    };
  }

  downloadDashboardPdf(): void {
    this.publicDataService.downloadDashboardPdf();
  }

  downloadDashboardExcel(): void {
    this.publicDataService.downloadDashboardExcel();
  }

  loadWidgetExcel(widget: Widget): void {
    this.publicDataService.downloadWidgetExcel(widget.id);
  }

  loadWidgetPng(widget: Widget): void {
    this.publicDataService.downloadWidgetPng(widget, widget.type);
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }
}
