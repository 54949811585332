import { Pipe, PipeTransform } from '@angular/core';
import * as _h from 'he';

@Pipe({ name: 'htmlEscape' })
export class HtmlEscapePipe implements PipeTransform {
  transform(value: string, options?: any): string {
    if (!value) {
      return value;
    }
    return _h.escape(value);
  }
}
