<ng-container *ngIf="apartmentTypeahead">
  <label i18n [attr.for]="id + '-control-typeahead'">Apartment</label>
  <input
    type="text"
    class="form-control"
    [formControl]="formControl"
    [coreValidate]="showErrors"
    [ngbTypeahead]="apartmentTypeahead"
    [id]="id + '-control-typeahead'"
  >
</ng-container>
<ng-container *ngIf="!apartmentTypeahead">
  <label i18n [attr.for]="id + '-control'">Apartment</label>
  <input
    type="text"
    class="form-control"
    [formControl]="formControl"
    [coreValidate]="showErrors"
    [id]="id + '-control'"
  >
</ng-container>
