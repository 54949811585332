<div class="container-fluid">
  <div class="row">
    <div class="col-md-12 mt-4">
      <div class="block mb-3">
        <div class="row title-bar">
          <div class="col">
            <h3 class="section-title text-black mb-3" i18n>Notification addresses</h3>
          </div>
          <div class="col-auto">
            <button type="button" class="btn btn-sm btn-outline-primary float-right" [routerLink]="null" (click)="add()">
              <i class="fal fa-plus"></i>&nbsp;<span i18n>Add new address</span>
            </button>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <ngx-datatable
              class="material w-100"
              [draggable]="false"
              [columnMode]="'flex'"
              [headerHeight]="30"
              [footerHeight]="50"
              [reorderable]="false"
              rowHeight="auto"
              [scrollbarH]="false"
              [scrollbarV]="false"
              [rows]="notificationAddresses"
              [externalPaging]="false"
              [externalSorting]="false"
            >
              <ngx-datatable-column name="Name" i18n-name prop="name" [flexGrow]="1" [resizeable]="false" [sortable]="false">
                <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>{{value}}</ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="E-mail" i18n-name prop="email" [flexGrow]="1" [resizeable]="false" [sortable]="false">
                <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>{{value}}</ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="" [flexGrow]="1" [resizeable]="false" [sortable]="false" [cellClass]="'justify-content-end'">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <div class="btn-group btn-more" dropdown>
                    <button type="button" class="btn btn-icon dropdown-toggle" dropdownToggle><i class="far fa-ellipsis-v"></i></button>
                    <div class="dropdown-menu dropdown-menu-right dropdown-menu-left-mobile " *dropdownMenu>
                      <a class="dropdown-item" (click)="edit(row)"><i class="fal fa-edit"></i> <ng-container i18n>Edit</ng-container></a>
                      <a class="dropdown-item" (click)="delete(row)"><i class="fal fa-trash"></i> <ng-container i18n>Delete</ng-container></a>
                    </div>
                  </div>
                </ng-template>
              </ngx-datatable-column>

            </ngx-datatable>

            <div class="text-center" *ngIf="notificationAddresses && notificationAddresses.length == 0">
              <i i18n>No addresses</i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
