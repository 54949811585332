export class BicycleStationCounts {
  name: string;
  uuid: string;
  locationName: string;
  lat: number;
  lng: number;
  primaryLockedCount: number;
  secondaryLockedCount: number;
  freeDocksCount: number;
  freeSpacesCount: number;
  primaryLockedCyclePsId: number;
  secondaryLockedCyclePsId: number;
  freeDocksPsId: number;
  freeSpacesPsId: number;
}

export class BicycleStation {
  name: string;
  id: number;
  lat: number;
  lng: number;
  uuid: string;
  value: number;
  address: string;
}

export class BicycleStationsPage {
  content: BicycleStation[];
  page: number;
  size: number;
  totalElements: number;
  totalPages: number;
}


export class BicycleStationState {
  state: any;
}

export class BicycleStationsHistogram {
  content: HistogramItem[];
}

export class HistogramItem {
  date: string;
  value: number;
}
