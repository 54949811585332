import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ServicesService} from './services.service';
import {FormControl, FormGroup} from '@angular/forms';
import {distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {ToiletsLayer} from './layers/toilets-layer';
import {WindTurbinesLayer} from './layers/wind-turbines-layer';
import {Services} from './models/services.model';
import {ParcelTerminalsLayer} from './layers/parcel-terminals-layer';
import {Subject} from 'rxjs/internal/Subject';
import {MyLocationButton} from '@smartencity/core';
import {EvChargersLayer} from './layers/ev-chargers-layer';

@Component({
  selector: 'services-services',
  templateUrl: './services.component.html',
  styles: []
})
export class ServicesComponent implements OnInit, OnDestroy {
  private ngDestroy = new Subject<void>();

  layersGroup = new FormGroup({
    toilets: new FormControl(true),
    parcelTerminals: new FormControl(false),
    evChargers: new FormControl(false),
    windTurbines: new FormControl(false)
  });

  @ViewChild('gmap', {static: true})
  gmapElement: any;
  map: google.maps.Map;
  toiletsLayer;
  parcelTerminalsLayer;
  evChargersLayer;
  windTurbinesLayer;

  constructor(
    private servicesService: ServicesService
  ) {
  }

  ngOnInit() {
    const conf = this.servicesService.getMapsConfig();

    const mapProp = {
      center: conf.center,
      zoom: conf.zoom,
      mapTypeControl: false,
      fullscreenControl: false,
      clickableIcons: false
    };
    this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);

    const style = [
      {
        stylers: [
          {saturation: -99}
        ]
      }
    ];

    this.map.mapTypes.set('map-style', new google.maps.StyledMapType(style, {}));
    this.map.setMapTypeId('map-style');

    this.servicesService.loadServices();

    this.servicesService.services$.pipe(takeUntil(this.ngDestroy)).subscribe((services: Services) => {
      if (this.toiletsLayer) {
        this.toiletsLayer.setMap(null);
      }
      this.toiletsLayer = new ToiletsLayer(services.toilets);
      if (this.layersGroup.get('toilets').value) {
        this.toiletsLayer.setMap(this.map);
      }

      if (this.parcelTerminalsLayer) {
        this.parcelTerminalsLayer.setMap(null);
      }
      this.parcelTerminalsLayer = new ParcelTerminalsLayer(services.parcelTerminals);
      if (this.layersGroup.get('parcelTerminals').value) {
        this.parcelTerminalsLayer.setMap(this.map);
      }

      if (this.evChargersLayer) {
        this.evChargersLayer.setMap(null);
      }
      this.evChargersLayer = new EvChargersLayer(services.evChargers);
      if (this.layersGroup.get('evChargers').value) {
        this.evChargersLayer.setMap(this.map);
      }

      if (this.windTurbinesLayer) {
        this.windTurbinesLayer.setMap(null);
      }
      this.windTurbinesLayer = new WindTurbinesLayer(services.windTurbines);
      if (this.layersGroup.get('windTurbines').value) {
        this.windTurbinesLayer.setMap(this.map);
      }
    });

    this.servicesService.selectedService$.pipe(takeUntil(this.ngDestroy)).subscribe((service) => {
      this.map.panTo(new google.maps.LatLng(service.lat, service.lng));
    });

    this.layersGroup.valueChanges.pipe(takeUntil(this.ngDestroy), distinctUntilChanged()).subscribe((layers) => {
      this.toiletsLayer.setMap(layers.toilets ? this.map : null);
      this.parcelTerminalsLayer.setMap(layers.parcelTerminals ? this.map : null);
      this.evChargersLayer.setMap(layers.evChargers ? this.map : null);
      this.windTurbinesLayer.setMap(layers.windTurbines ? this.map : null);
    });
    new MyLocationButton(this.map);
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  toggleLayer(layer: string) {
    this.layersGroup.get(layer).setValue(!this.layersGroup.get(layer).value);
  }

}
