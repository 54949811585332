import {BusRoute} from "./bus-route";
import {Shape} from "../model/shape";
import {MobilityService} from '../service/mobility.service';

export class RoutesLayer {


  public busRoutes: BusRoute[] = [];
  private mobilityService: MobilityService;
  private map: google.maps.Map;

  constructor(map: google.maps.Map, mobilityService: MobilityService) {

    this.mobilityService = mobilityService;
    this.map = map;
  }

  setMap(map: any){
    this.map = map;
    this.busRoutes.forEach(value => {
        value.setMap(this.map);
    });
  }

  addShape(shape: Shape) {
    let busRoute = new BusRoute({
      strokeColor: this.busRoutes.length > 0 ? "#" + ((1 << 24) * Math.random() | 0).toString(16) : '#0000FF',
      map: this.map
    }, shape);

    this.busRoutes.push(busRoute);
  }

  clearRoutes() {
    this.busRoutes.forEach(value => {
      value.setMap(null);
    });
    this.busRoutes.length = 0;
  }

  highLight(shapeId: string) {
    this.busRoutes.forEach(value => {
      if (value.shape.id === shapeId){
        value.pullUp();
      } else {
        value.pullBack();
      }
    });
  }

  reset(){
    this.busRoutes.forEach(value => {
        value.reset();
    });
  }
}
