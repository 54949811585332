import {Stop} from '../model/stop';
import {MobilityService} from '../service/mobility.service';
import {MobilityGmapService} from '../service/mobility-gmap.service';

export class StopsLayer extends google.maps.OverlayView {

  private mobilityService: MobilityService;
  private mobilityMapService: MobilityGmapService;
  private div: any;

  public stops: Stop[];
  private selectedStop: Stop = null;

  private popoverTimeout = null;
  private userLargerIcons = false;

  constructor(map,
              mobilityService: MobilityService,
              mobilityMapService: MobilityGmapService) {
    super();
    this.mobilityService = mobilityService;
    this.mobilityMapService = mobilityMapService;
    this.setMap(map);
  }

  public setUseLargerIcons(larger: boolean) {
    this.userLargerIcons = larger;
  }

  public setSelectedStop(selectedStop) {
    this.selectedStop = selectedStop;
  }

  onAdd() {
    const panes = this.getPanes();
    if (panes == null) {
      return;
    }

    this.div = document.createElement('DIV');
    this.div.style.position = 'absolute';
    this.div.style.cursor = 'pointer';
    this.div.style.position = 'absolute';
    this.div.classList.add('custom-marker-layer');
    this.div.style.paddingLeft = '0px';
    panes.overlayMouseTarget.appendChild(this.div);
  }

  onRemove() {
    if (this.div && this.div.parentNode) {
      $('.custom-marker-layer .custom-marker').off('click');
      this.div.parentNode.removeChild(this.div);
      this.div = null;
    }
  }

  draw() {
    if (!this.div) {
      return;
    }
    this.div.innerHTML = this.getStopsHtml();

    $('.pointer-bus-stop, .pointer-bus-stop-larger').on('click', (e) => {
      let stop = this.stops.find(value => <any>value.id === $(e.target).attr('stop-id'));
      this.mobilityMapService.onStopClick(stop);
    });

    this.resetPopovers();
  }

  private resetPopovers() {
    if (this.popoverTimeout != null) {
      clearTimeout(this.popoverTimeout);
    }
    this.popoverTimeout = setTimeout(() => {
      this.removePopovers();
      ($(this.div).find('[data-toggle="popover"]') as any).popover({
        trigger: 'hover'
      });
    }, 200);
  }

  private removePopovers(): void{
    $('.popover.show').remove();
  }

  private getStopsHtml() {
    let html = '';

    if (!this.stops) {
      return html;
    }

    this.stops.forEach(stop => {
      const point = this.getProjection().fromLatLngToDivPixel(new google.maps.LatLng(stop.lat, stop.lng));
      let title = stop.title;
      if (stop.description) {
        title += ' (' + stop.description + ')';
      }

      let className;
      let x = point.x - 12;
      let y = point.y - 39;
      if (this.selectedStop && this.selectedStop.id === stop.id) {
        className = 'pointer-bus-stop-active';
      } else {
        className = this.userLargerIcons ? 'pointer-bus-stop-larger' : 'pointer-bus-stop';
      }
      html += '<div class="' + className + '" stop-id="' + stop.id + '" style="left: ' + x + 'px; top: ' + y + 'px" data-trigger="hover" data-toggle="popover" data-placement="top" data-content="' + title + '"></div>';
    });
    return html;
  }

  setMap(map) {
    super.setMap(map);
  }

  showStops(ids?: string[]) {
    if (!ids) {
      this.setUseLargerIcons(false);
    } else {
      this.setUseLargerIcons(true);
    }
    this.stops = this.mobilityService.stops.filter(value => {
      return ids == null || ids.indexOf(value.id) > -1;
    });

    this.removePopovers();
    this.draw();
  }

  public clearSelection() {
    this.selectedStop = null;
    this.showStops(null);
  }

}
