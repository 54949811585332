import {Component, ElementRef, HostListener, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subject} from 'rxjs/internal/Subject';
import {CurrentLocationService} from '../../services/current-location.service';
import {take, takeUntil} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {
  EnumsService,
  Location,
  LocationService,
  SeriesType,
  Mandate,
  AuthService,
  SMARTENCITY_CORE_CONFIG,
  CoreConfig,
  PersonSeriesGroup,
  Person,
  PageResponse,
  PersonSeriesGroupPeriod,
  PreventCloseModalService, SCREEN_SIZE, ResizeService
} from '@smartencity/core';
import {HttpClient} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';
import {MyDataConfig} from '../../mydata-config.model';
import {DatapointListService} from '../../services/datapoint-list.service';
import {FormControl} from '@angular/forms';
import moment from 'moment';
import {Moment} from 'moment-timezone';
import {AddDataModalComponent} from '../consumption/person-series/add-data/add-data-modal.component';
import {BsModalService} from 'ngx-bootstrap/modal';
import {SMARTENCITY_MYDATA_CONFIG} from '../../injection-tokens';
import {SharedKeywordStore} from '../../services/shared-search-keyword-store.service';
import {
  DatapointGroupConsentsModalComponent
} from '../datapoint-groups-management/datapoint-group-consents-modal/datapoint-group-consents-modal.component';
import {
  DatapointGroupShareModalComponent
} from '../datapoint-groups-management/datapoint-group-share-modal/datapoint-group-share-modal.component';
import {
  DatapointGroupSharePersonModalComponent
} from '../datapoint-groups-management/datapoint-group-share-person-modal/datapoint-group-share-person-modal.component';
import {
  EditDatapointGroupModalComponent
} from '../datapoint-groups-management/edit-datapoint-group-modal/edit-datapoint-group-modal.component';
import {GroupShareData} from '../../models/datapoint-group/group-share-data';
import {NgxDrpOptions, Range} from '@smartencity/shared';
import {PersonSeriesGroupApiService} from '../../http/person-series-group-api.service';

@Component({
  selector: 'group-datapoint-list',
  templateUrl: './group-datapoint-list.component.html',
  styleUrls: ['./group-datapoint-list.component.scss'],
  providers: [CurrentLocationService, DatapointListService]
})
export class GroupDatapointListComponent implements OnInit, OnDestroy {
  private ngDestroy = new Subject<void>();

  dateRangePickerOptions: NgxDrpOptions;
  public rangeControl: FormControl = this.datapointListService.rangeControl;
  public range$ = this.datapointListService.range$;

  public globalAggregationGroupingType$ = this.datapointListService.globalApplicationGroupingType$;

  public currentAggregationGroupingType = null;

  public isCityUser = false;
  public seriesTypeOptions = this.enumsService.getOptionsList(SeriesType.types);
  public filterForm = this.datapointListService.filterForm;
  public searchControl = this.filterForm.get('query');
  public pageResponse$ = this.datapointListService.pageResponse$;
  public totalElements = 0;
  public location: Location;
  public locationPath: Location[];
  public view = 'chart-list';

  public subLocations$ = this.currentLocationService.subLocations$;
  public group: PersonSeriesGroup;
  public groupConsentPersons: Person[] = [];

  public aggTypes = {
    'HOURLY': true,
    'DAILY': true,
    'MONTHLY': true,
    'YEARLY': true
  };

  @ViewChild('fixedNav') menuElement: ElementRef;

  sticky = false;

  isMobile = false;

  elementPosition: any;

  @HostListener('window:scroll', ['$event'])
  handleScroll() {
    const windowScroll = window.scrollY;
    this.sticky = windowScroll >= this.elementPosition;
  }


  constructor(
    private enumsService: EnumsService,
    @Inject(SMARTENCITY_MYDATA_CONFIG) private config: MyDataConfig,
    @Inject(SMARTENCITY_CORE_CONFIG) private coreConfig: CoreConfig,
    private authService: AuthService,
    private locationService: LocationService,
    private currentLocationService: CurrentLocationService,
    private datapointListService: DatapointListService,
    private router: Router,
    private http: HttpClient,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private modalService: BsModalService,
    private preventCloseModalService: PreventCloseModalService,
    private sharedKeywordStore: SharedKeywordStore,
    private personSeriesGroupService: PersonSeriesGroupApiService,
    private resizeService: ResizeService
  ) {
    this.setupDrpOptions();
    this.group = <PersonSeriesGroup>this.route.snapshot.data.group;

    this.currentLocationService.currentLocation$.pipe(takeUntil(this.ngDestroy)).subscribe((location: Location) => {
      if (!location) {
        this.router.navigate(['/mydata']);
      }

      this.location = location;
      this.locationPath = location.getPath();
      this.locationPath.pop();
    }, (err: any) => {
      this.toastr.error(JSON.stringify(err), 'Error loading datapoints');
    });

    this.pageResponse$.pipe(takeUntil(this.ngDestroy)).subscribe(pr => {
      this.totalElements = pr.totalElements;
    });

    const navigationState = this.router.getCurrentNavigation()?.extras?.state;
    if (navigationState && navigationState.sourceOwnerSeries && navigationState.addData) {
      const modalRef = this.modalService.show(AddDataModalComponent, {
        ignoreBackdropClick: true,
        class: 'modal-lg',
        initialState: {
          sourceOwnerSeries: navigationState.sourceOwnerSeries,
          callback: (result) => {
            this.datapointListService.load();
          }
        }
      });
    }

    if (navigationState && navigationState.search) {
      this.searchControl.setValue(navigationState.search);
    }

    this.authService.currentMandate$.pipe(takeUntil(this.ngDestroy)).subscribe((mandate: Mandate) => {
      this.isCityUser = this.config.cityRegistrationCode === mandate.person.registrationNumber;
    });

    this.subLocations$.pipe(takeUntil(this.ngDestroy)).subscribe((subLocations: Location[]) => {
      if (coreConfig.appName && coreConfig.appName.includes("nuti") && subLocations.length > 0) {
        this.view = 'locations';
      }
    });

    this.loadGroupConsents();
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  ngOnInit(): void {
    this.datapointListService.resetResults();
  }

  loadGroupConsents(): void {
    if (this.group) {
      this.personSeriesGroupService.getPersonSeriesGroupConsents(this.group.id).subscribe((result: PageResponse<PersonSeriesGroupPeriod>) => {
        this.groupConsentPersons = result.content.map(c => c.person);
      });
    }
  }

  toggleAggregationGroupType(aggregationGroupingType: string): void {
    if (aggregationGroupingType !== this.currentAggregationGroupingType) {
      this.currentAggregationGroupingType = aggregationGroupingType;
    } else {
      this.currentAggregationGroupingType = null;
    }

    this.globalAggregationGroupingType$.next(this.currentAggregationGroupingType);
  }

  public setView(view: string) {
    if (view === 'chart-list' || view === 'item-list') {
      this.datapointListService.load();
    }
    this.view = view;
  }

  public back() {
    this.router.navigate(['..']);
  }

  public navigate(location: Location) {
    this.locationService.navigate(location);
  }

  clearSearch() {
    this.searchControl.setValue('');
    this.sharedKeywordStore.setState('');
  }

  private getDefaultRange(): {from: Moment, to: Moment, aggTypes: any} {
    const start = moment();
    const momentUnit: moment.unitOfTime.Base = 'day';
    const myRange = {
      from: start.clone().startOf(momentUnit).subtract(2, momentUnit),
      to: start.clone().startOf(momentUnit).add(1, momentUnit)
    };
    const to = moment().isBefore(moment(myRange.to)) ? moment() : moment(myRange.to).subtract(1);
    const aggTypes = {
      'HOURLY': !moment(myRange.from).isAfter(to.clone().startOf('hour')),
      'DAILY': !moment(myRange.from).isAfter(to.clone().startOf('day')),
      'MONTHLY': !moment(myRange.from).isAfter(to.clone().startOf('month')),
      'YEARLY': !moment(myRange.from).isAfter(to.clone().startOf('year'))
    };
    // return {from: myRange.from, to: myRange.to, aggTypes: aggTypes};
    return {from: null, to: null, aggTypes: null};
  }

  setupDrpOptions() {
    const defaultRange = this.getDefaultRange();

    const today = moment().startOf('day');

    const minus1 = today.clone().subtract(1, 'days');
    const minus7 = today.clone().subtract(7, 'days');
    const currMonthStart = today.clone().startOf('month');
    const currMonthEnd = today.clone().startOf('month').add(1, 'months').subtract(1, 'days');
    const lastMonthStart = today.clone().startOf('month').subtract(1, 'months');
    const lastMonthEnd = today.clone().startOf('month').subtract(1, 'days');
    const currYearStart = today.clone().startOf('year');
    const currYearEnd = today.clone().startOf('year').add(1, 'year').subtract(1, 'days');
    const lastYearStart = today.clone().startOf('year').subtract(1, 'year');
    const lastYearEnd = today.clone().startOf('year').subtract(1, 'days');

    this.dateRangePickerOptions = {
      placeholder: $localize`Choose period`,
      presets: [
        {
          presetLabel: $localize`Today`,
          range: {fromDate: today.clone(), toDate: today.clone()}
        },
        {
          presetLabel: $localize`Yesterday`,
          range: {fromDate: minus1.clone(), toDate: minus1.clone()}
        },
        {
          presetLabel: $localize`Last 7 Days`,
          range: {fromDate: minus7.clone(), toDate: today.clone().subtract(1, 'days')}
        },
        {
          presetLabel: $localize`This Month`,
          range: {fromDate: currMonthStart.clone(), toDate: currMonthEnd.clone()}
        },
        {
          presetLabel: $localize`Last Month`,
          range: {fromDate: lastMonthStart.clone(), toDate: lastMonthEnd.clone()}
        },
        {
          presetLabel: $localize`This year`,
          range: {fromDate: currYearStart.clone(), toDate: currYearEnd.clone()}
        },
        {
          presetLabel: $localize`Last year`,
          range: {fromDate: lastYearStart.clone(), toDate: lastYearEnd.clone()}
        }
      ],
      format: 'dd.MM.yyyy',
      range: {
        fromDate: defaultRange.from,
        toDate: defaultRange.to ? moment(defaultRange.to).subtract(1, 'days') : null
      },
      /*fromMinMax: {fromDate: undefined, toDate: today},
      toMinMax: {fromDate: undefined, toDate: today},*/
      applyLabel: $localize`Apply`,
      enforceToAfterFrom: true,
      calendarOverlayConfig: {
        shouldCloseOnBackdropClick: true,
        hasBackdrop: true
      },
      cancelLabel: $localize`Cancel`
    };
  }

  updateRange(range: Range) {
    if (range && range.fromDate && range.toDate) {
      const myRange = {
        from: range.fromDate.clone(),
        to: moment(range.toDate).add(1, 'days')
      };
      const to = moment().isBefore(moment(myRange.to)) ? moment() : moment(myRange.to).subtract(1);
      const aggTypes = {
        'HOURLY': !moment(myRange.from).isAfter(to.clone().startOf('hour')),
        'DAILY': !moment(myRange.from).isAfter(to.clone().startOf('day')),
        'MONTHLY': !moment(myRange.from).isAfter(to.clone().startOf('month')),
        'YEARLY': !moment(myRange.from).isAfter(to.clone().startOf('year'))
      };
      this.rangeControl.setValue({from: myRange.from, to: myRange.to, aggTypes: aggTypes});
    } else {

    }
  }

  clearSeriesTypeFilter(seriesType): void {
    const seriesTypes = this.filterForm.get('seriesTypes').value;
    const index = seriesTypes.indexOf(seriesType);
    if (index !== -1) {
      seriesTypes.splice(index, 1);
    }
    this.filterForm.get('seriesTypes').setValue(seriesTypes);
  }

  addSeriesTypeFilter(seriesType): void {
    const seriesTypes = this.filterForm.get('seriesTypes').value;
    const index = seriesTypes.indexOf(seriesType);
    if (index === -1) {
      seriesTypes.push(seriesType);
    }
    this.filterForm.get('seriesTypes').setValue(seriesTypes);
  }

  isTypeFiltered(type): boolean {
    return this.filterForm.get('seriesTypes').value.includes(type);
  }

  seriesTypeCheckboxChanged(label: string, event: any): void {
    if (event.currentTarget.checked) {
      this.addSeriesTypeFilter(label);
    } else {
      this.clearSeriesTypeFilter(label);
    }
  }

  showGroupConsents(): void {
    if (this.group?.lastPeriod.ownerType != 'OWNER') {
      return;
    }
    //
    const modalRef = this.modalService.show(DatapointGroupConsentsModalComponent, {
      class: 'modal-lg',
      initialState: {
        group: this.group
      }
    });

  }

  shareGroup(): void {
    if (this.group?.lastPeriod.ownerType != 'OWNER') {
      return;
    }
    //
    const modalRef = this.modalService.show(DatapointGroupShareModalComponent, {
      class: 'modal-lg',
      initialState: {
        group: this.group
      }
    });

    modalRef.content.saveEmitter.pipe(takeUntil(this.ngDestroy), take(1)).subscribe((data: any) => {
      this.shareGroupToPerson(data);
    });

  }

  private shareGroupToPerson(data: GroupShareData): void {
    const modalRef = this.preventCloseModalService.show(DatapointGroupSharePersonModalComponent, {
      class: 'modal-lg',
      initialState: {
        group: this.group,
        shareData: data
      }
    });

    modalRef.content.saveEmitter.pipe(takeUntil(this.ngDestroy)).subscribe(() => {
      this.datapointListService.load();
      this.loadGroupConsents();
    });
  }

  editGroup(): void {
    const modalRef = this.preventCloseModalService.show(EditDatapointGroupModalComponent,
      {
        class: 'modal-lg',
        initialState: {
          group: this.group
        }
      }
    );
    modalRef.content.saveEmitter.pipe(takeUntil(this.ngDestroy), take(1)).subscribe((data: any) => {
      this.datapointListService.load();
    });
  }

  ngAfterViewInit(){
    this.elementPosition = this.menuElement.nativeElement.offsetTop;

    this.resizeService.onResize$.subscribe((size) => {
      this.isMobile = size != null && size < SCREEN_SIZE.MD;
    });

  }
}
