import { NgModule } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { NgbTypeaheadModule } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgxPermissionsModule } from "ngx-permissions";
import { SeriesIconDirective } from "./directives/SeriesIcon";
import { LogOutLinkDirective } from "./directives/LogOutLink";
import { WeatherIconDirective } from "./directives/WeatherIcon";
import { SidenavComponent } from "./components/sidenav/sidenav.component";
import { KphToMsPipe } from "./pipes/kph-to-ms.pipe";
import {
  DateDayPipe,
  LabelPipe,
  PersonSeriesLocationLabel,
} from "./pipes/LabelPipe";
import { RequestInterceptor } from "./interceptors/request.interceptor";
import { SpinnerComponent } from "./old-components/spinner/spinner.component";
import { TermsModalComponent } from "./old-components/terms/terms-modal.component";
import { InputLowercaseDirective } from "./directives/input-lowercase.directive";
import { InputUppercaseDirective } from "./directives/input-uppercase.directive";
import { LanguageSelectComponent } from "./old-components/language-select/language-select.component";
import { AuthService } from "./auth/auth.service";
import { DateAdapter } from "@angular/material/core";
import { MyDateAdapter } from "./services/my-date-adapter";

import { Chart } from "chart.js";
import { CprModalComponent } from "./old-components/cpr-modal/cpr-modal.component";
import { TypeaheadModule } from "ngx-bootstrap/typeahead";
import { AddressTemplateDirective } from "./old-components/address/address-template.directive";
import { AddressComponent } from "./old-components/address/address.component";
import { AddressStreetComponent } from "./old-components/address/address-street/address-street.component";
import { AddressRoomComponent } from "./old-components/address/address-room/address-room.component";
import { AddressApartmentComponent } from "./old-components/address/address-apartment/address-apartment.component";
import { LaddaModule } from "angular2-ladda";
import { CoreValidateDirective } from "./directives/validate.directive";
import { RegNoPipe } from "./pipes/reg-no.pipe";
import { PersonalIdPipe } from "./pipes/personal-id.pipe";
import { NgProgressModule } from "ngx-progressbar";
import { LinkyPipe } from "./pipes/linky.pipe";
import { HtmlEscapePipe } from "./pipes/html-escape.pipe";
import { MainComponent } from "./components/main/main.component";
import { IndexComponent } from "./components/index/index.component";
import { VideoModalComponent } from "./components/video-modal/video-modal.component";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { MatIconModule } from "@angular/material/icon";
import { ScreenSizeDetectorComponent } from "./components/screen-size-detector/screen-size-detector.component";
import { NavbarComponent } from "./components/navbar/navbar.component";
import { LottieModule } from "ngx-lottie";
import { DatepickerWrapperModule } from "./components/datepicker/datepicker-wrapper.module";
import { AuthRedirectComponent } from "./old-components/auth/auth-redirect.component";
import { D3OrgChartComponent } from "./components/d3-org-chart/d3-org-chart.component";
import { NodeDropDownMenu } from "./components/d3-org-chart/node-drop-down-menu.component";
import { TosComponent } from "./components/tos/tos.component";
import { TosModalComponent } from "./components/tos-modal/tos-modal.component";
import { PersonSeriesGroupResolver } from "../../../mydata/src/lib/resolvers/person-series-group.resolver";
import { SharePersonItemListComponent } from "./components/share-person-item-list/share-person-item-list.component";
import { IconManagerComponent } from "./components/icon-manager/icon-manager.component";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { ModuleLabelPipe } from "./pipes/module-label.pipe";
import { SafeHtmlPipe } from "./pipes/safe-html.pipe";
import { ModalNavComponent } from "./components/modal-nav/modal-nav.component";
import { CookieConsentComponent } from "./components/cookie-consent/cookie-consent.component";
import { CookieModule } from "ngx-cookie";
import { LoadingSpinnerComponent } from "./components/loading-spinner/loading-spinner.component";
import {UnitPipe} from './pipes/unit.pipe';
import { FormFieldErrorsComponent } from './components/form-field-errors/form-field-errors.component';
import {NumberFormatPipe} from './pipes/number-format.pipe';
import { IconWeatherComponent } from './components/index/icon/icon-weather/icon-weather.component';
import { IconPeopleComponent } from './components/index/icon/icon-people/icon-people.component';
import { IconLightsComponent } from './components/index/icon/icon-lights/icon-lights.component';
import { IconServicesComponent } from './components/index/icon/icon-services/icon-services.component';
import { IconMobilityComponent } from './components/index/icon/icon-mobility/icon-mobility.component';
import { IconSolarparkComponent } from './components/index/icon/icon-solarpark/icon-solarpark.component';
import { IconMydataComponent } from './components/index/icon/icon-mydata/icon-mydata.component';
import { BottomNavComponent } from './components/bottom-nav/bottom-nav.component';
import { TallinnVisionModalComponent } from './components/tallinn-vision-modal/tallinn-vision-modal.component';
import { IconBuildingsComponent } from './components/index/icon/icon-buildings/icon-buildings.component';
import { IconTallinnComponent } from './components/index/icon/icon-tallinn/icon-tallinn.component';
import { TallinnBottomNavComponent } from './components/bottom-nav/tallinn-bottom-nav/tallinn-bottom-nav.component';
import { IconBuildingsTemplateComponent } from './components/index/icon/icon-buildings/icon-buildings-template.component';
import { IconMobilityTemplateComponent } from './components/index/icon/icon-mobility/icon-mobility-template.component';
import { IconWeatherTemplateComponent } from './components/index/icon/icon-weather/icon-weather-template.component';
import { IconMydataTemplateComponent } from './components/index/icon/icon-mydata/icon-mydata-template.component';
import {cacheServiceProvider} from './cache/cache-service.provider';
import {ConfirmModalComponent} from './components/confirm-modal/confirm-modal.component';
import { IconPublicDataComponent } from "./components/index/icon/icon-public-data/icon-public-data.component";
import {TaraAuthService} from './auth/tara/tara-auth.service';
import {NemLogInAuthService} from './auth/nem-log-in/nem-log-in-auth.service';
import {DemoAuthService} from './auth/demo/demo-auth.service';
import {PersonSeriesApiService} from './http/person-series-api.service';

Chart.defaults.global.elements.rectangle.borderWidth = 1;
Chart.defaults.global.elements.line.borderWidth = 1;
Chart.defaults.global.elements.point.borderWidth = 1;

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbTypeaheadModule,
    NgSelectModule,
    NgxPermissionsModule,
    TypeaheadModule.forRoot(),
    LaddaModule,
    NgProgressModule,
    BsDropdownModule,
    MatIconModule,
    LottieModule,
    DatepickerWrapperModule,
    TooltipModule,
    CookieModule.forRoot(),
  ],
  exports: [
    IndexComponent,
    MainComponent,
    SidenavComponent,
    SpinnerComponent,
    TermsModalComponent,
    LogOutLinkDirective,
    SeriesIconDirective,
    WeatherIconDirective,
    InputUppercaseDirective,
    InputLowercaseDirective,
    UnitPipe,
    LabelPipe,
    DateDayPipe,
    PersonSeriesLocationLabel,
    KphToMsPipe,
    DatePipe,
    PersonalIdPipe,
    RegNoPipe,
    CprModalComponent,
    AddressTemplateDirective,
    AddressComponent,
    AddressStreetComponent,
    AddressApartmentComponent,
    AddressRoomComponent,
    CoreValidateDirective,
    LinkyPipe,
    HtmlEscapePipe,
    SafeHtmlPipe,
    ScreenSizeDetectorComponent,
    NavbarComponent,
    D3OrgChartComponent,
    TosModalComponent,
    SharePersonItemListComponent,
    IconManagerComponent,
    ModalNavComponent,
    CookieConsentComponent,
    LoadingSpinnerComponent,
    FormFieldErrorsComponent,
    NumberFormatPipe
  ],
  declarations: [
    IndexComponent,
    MainComponent,
    SidenavComponent,
    SpinnerComponent,
    TermsModalComponent,
    LogOutLinkDirective,
    SeriesIconDirective,
    WeatherIconDirective,
    LanguageSelectComponent,
    InputUppercaseDirective,
    InputLowercaseDirective,
    UnitPipe,
    LabelPipe,
    DateDayPipe,
    PersonSeriesLocationLabel,
    KphToMsPipe,
    CprModalComponent,
    AddressTemplateDirective,
    AddressComponent,
    AddressStreetComponent,
    AddressApartmentComponent,
    AddressRoomComponent,
    CoreValidateDirective,
    RegNoPipe,
    PersonalIdPipe,
    LinkyPipe,
    HtmlEscapePipe,
    VideoModalComponent,
    ScreenSizeDetectorComponent,
    NavbarComponent,
    AuthRedirectComponent,
    D3OrgChartComponent,
    NodeDropDownMenu,
    TosComponent,
    TosModalComponent,
    SharePersonItemListComponent,
    IconManagerComponent,
    ModuleLabelPipe,
    SafeHtmlPipe,
    ModalNavComponent,
    CookieConsentComponent,
    LoadingSpinnerComponent,
    FormFieldErrorsComponent,
    NumberFormatPipe,
    IconWeatherComponent,
    IconPeopleComponent,
    IconLightsComponent,
    IconServicesComponent,
    IconMobilityComponent,
    IconSolarparkComponent,
    IconMydataComponent,
    IconPublicDataComponent,
    BottomNavComponent,
    TallinnVisionModalComponent,
    IconBuildingsComponent,
    IconTallinnComponent,
    TallinnBottomNavComponent,
    IconBuildingsTemplateComponent,
    IconMobilityTemplateComponent,
    IconWeatherTemplateComponent,
    IconMydataTemplateComponent,
    ConfirmModalComponent,
  ],
  entryComponents: [],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      deps: [AuthService],
      multi: true,
    },
    {provide: DateAdapter, useClass: MyDateAdapter},
    cacheServiceProvider,
    LabelPipe,
    RegNoPipe,
    PersonalIdPipe,
    PersonSeriesGroupResolver,
    UnitPipe,
    TaraAuthService,
    NemLogInAuthService,
    DemoAuthService,
    PersonSeriesApiService
  ],
})
export class CoreModule {}
