import {Component, OnDestroy, OnInit} from '@angular/core';
import { BuildingsService } from '../service/buildings.service';
import { BuildingsOlMapService } from '../service/buildings-ol.service';
import { DisplaySettings } from '../model/display-settings';
import { TallinnArcGisMapService } from 'projects/arc-gis/src/lib/tallinn-arc-gis-map.service';
import { FeatureSelectEvent, OlMapWrapper } from '@smartencity/core';
import {Subject} from 'rxjs/internal/Subject';

@Component({
  selector: 'ol-buildings',
  templateUrl: './ol-buildings.component.html',
  styles: [
    `.map {
      width: 100%;
      height: 100vh;
    }
    `
  ],
})
export class OlBuildingsComponent implements OnInit, OnDestroy {

  private onDestroy: Subject<void> = new Subject<void>();

  map: OlMapWrapper;
  displaySettings: DisplaySettings = <DisplaySettings>{};

  public objects: Record<string, any>[] = [];

  constructor(
    public buildingsService: BuildingsService,
    private buildingsOlMapService: BuildingsOlMapService,
    private tallinnArcGisMapService: TallinnArcGisMapService,) {
  }

  ngOnDestroy(): void {
    this.buildingsService.clear();
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  ngOnInit(): void {
    this.onModalClose();
    this.buildingsOlMapService.createMap();


    this.buildingsOlMapService.map.mapLoaded$.subscribe(() => {
      this.buildingsOlMapService.map.getMap().on('click', () => {
        this.resetLocations();
      });

      this.buildingsOlMapService.map.featureSelect$.subscribe((o: FeatureSelectEvent) => {
        let newLocations = [];
        for (let f of o.selected) {
          newLocations.push({
            layerName: f.layer.get('layerName'),
            data: this.buildingsOlMapService.map.getFeatureProperties(f.feature)
          });
          if (f.layer.get('layerName') == 'cityDistricts' || f.layer.get('layerName') == 'residentialAreas') {
            f.feature.setStyle(this.tallinnArcGisMapService.highlightStyle);
          }
        }

        for (let f of o.deselected) {
          f.feature.setStyle(null);
        }

        const layerPosition = newLocations.findIndex((o) => o.layerName == 'cityDistricts');
        const lastLayer = newLocations.splice(layerPosition, 1);
        newLocations = newLocations.concat(lastLayer);

        this.buildingsService.selectLocations(newLocations);
      });
    });

  }

  displayItemsChanged(items: any) {
    this.buildingsService.updateDisplaySettings(Object.assign(this.displaySettings, items));
  }

  onModalClose(): void {
    this.resetLocations();
  }

  private resetLocations(): void {
    this.clearFeaturesStyles();
    this.buildingsService.locationsSelectedSource.next(null);
  }

  private clearFeaturesStyles(): void {
    if (this.buildingsOlMapService.map) {
      this.buildingsOlMapService.map.clearFeatureStyles();
    }
  }

}
