import { OlMapWrapper, OlOverlayWrapper } from '@smartencity/core';
import { PeopleTrafficCounter } from '../model/people-traffic-counter';
import {PeopleTrafficService} from '../service/people-traffic.service';

export class PeopleTrafficOlMarkerWrapper extends OlOverlayWrapper {

  public selected = false;
  private peopleTrafficPointer: PeopleTrafficCounter;
  private peopleTrafficService: PeopleTrafficService;

  constructor(peopleTrafficPointer: PeopleTrafficCounter, map: OlMapWrapper, peopleTrafficService: PeopleTrafficService) {
    super({
      title: peopleTrafficPointer.name,
      position: [peopleTrafficPointer.lng, peopleTrafficPointer.lat],
      className: 'people-traffic-marker-wrapper',
      stopEvent: false
    });
    this.peopleTrafficPointer = peopleTrafficPointer;
    this.peopleTrafficService = peopleTrafficService;
    this.setMap(map.getMap());
  }


  public draw() {
    if (!this.div) {
      this.div = document.createElement('DIV');
      this.div.title = this.peopleTrafficPointer.name;
      this.div.style.cursor = 'pointer';
      this.div.classList.add('pointer-people-traffic');

      this.div.addEventListener('click', () => {
        this.peopleTrafficService.selectPeopleTrafficCounter(this.peopleTrafficPointer);
      });
    }

    if (this.selected) {
      this.div.classList.add("active");
    } else {
      this.div.classList.remove("active");
    }
  }
}
