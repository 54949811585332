<div class="filter">
  <div class="filter-btn-row">
    <ci-activity-type-filter [filterOptions]="filterOptions" [defaultSelectedValue]="defaultActivityType" (optionSelected)="activityTypeOptionSelected($event)"></ci-activity-type-filter>
  </div>
  <div class="filter-btn-row">
    <div class="duration-type">
      <span class="label" i18n>Duration group type: </span>
      <a class="filter-btn" [ngClass]="{'active': filter.durationGroupType === '5-20'}" (click)="filterByDurationType('5-20')">5-20</a> |
      <a class="filter-btn" [ngClass]="{'active': filter.durationGroupType === '20-60'}" (click)="filterByDurationType('20-60')">20-60</a> |
      <a class="filter-btn" [ngClass]="{'active': filter.durationGroupType === '60-120'}" (click)="filterByDurationType('60-120')">60-120</a> |
      <a class="filter-btn" [ngClass]="{'active': filter.durationGroupType === '120-300'}" (click)="filterByDurationType('120-300')">120-300</a>|
      <a class="filter-btn" [ngClass]="{'active': filter.durationGroupType === '300+'}" (click)="filterByDurationType('300+')">300+</a>
    </div>
  </div>
</div>
<div class="pointer-info mt-3 pb-5">
  <div class="timeline">
    <div class="graph graph-bar">
      <div class="title" i18n>People count</div>
      <div class="grid">
        <div class="bar" [ngStyle]="{'height': value.height + 'px'}" [tooltip]="value.title" *ngFor="let value of chartValues">
          <span class="bar-title smaller">{{value.time|date:'dd.MM.yyyy'}}</span>
          <span class="bar-value smaller">{{value.value}}</span>
        </div>
      </div>
    </div>
  </div>
</div>
