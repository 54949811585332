export interface ValueTableColumnTranslations {
  name: string;
  value: string;
  unit: string;
}

export class WidgetConstants {

  public static valueTableColLabels: ValueTableColumnTranslations = {
    name: $localize`Name`,
    value: $localize`Value`,
    unit: $localize`Unit`
  };

}
