import {Inject, Injectable, OnDestroy} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import {of, throwError, Subject, ReplaySubject} from 'rxjs';
import {SMARTENCITY_CORE_CONFIG} from '../injection-tokens';
import {CompareService} from './compare.service';
import {CityApiService} from '../http/city-api/city-api.service';
import {CoreConfig} from '../core-config.model';
import {ToastrService} from 'ngx-toastr';
import {CompareItemStoreService, StoredCompareItem} from './compare-item-store.service';
import {BuildingsService} from '../../../../buildings/src/lib/service/buildings.service';
import {Building} from '@smartencity/buildings';

export class BuildingCompareItem {
  building: Building;
  seriesDatasetGroups: { [key: string]: BuildingCompareSeriesDatasetGroup };
  active = true;

  constructor(props?: { building: Building; seriesDatasetGroups: { [key: string]: BuildingCompareSeriesDatasetGroup }; active: boolean }) {
    if (!props) {
      return;
    }
    this.building = props.building;
    this.seriesDatasetGroups = props.seriesDatasetGroups;
    this.active = props.active;
  }
}

export interface SeriesTypeFilter {
  code: string;
  name: string;
  compareItem?: any;
  active: boolean;
}

export class BuildingCompareSeriesDatasetGroup {
  seriesType: string;
  datasets: any[];

  constructor(props?: { seriesType: string; datasets: any[] }) {
    if (!props) return;
    this.seriesType = props.seriesType;
    this.datasets = props.datasets;
  }
}

@Injectable({
  providedIn: 'root'
})
export class BuildingsCompareService implements OnDestroy {
  private ngDestroy = new Subject<void>();

  public changes$ = new Subject<void>();
  public count$ = new ReplaySubject<number>(1);
  public compareBuildings = new Map<string, BuildingCompareItem>();

  public seriesTypeFilters: { [key: string]: SeriesTypeFilter } = {
    WATER: {
      code: 'WATER',
      name: $localize`Water consumption`,
      compareItem: null,
      active: false
    },
    HOT_WATER: {
      code: 'HOT_WATER',
      name: $localize`Hot water consumption`,
      compareItem: null,
      active: false
    },
    ENERGY_CONSUMPTION: {
      code: 'ENERGY_CONSUMPTION',
      name: $localize`Electricity consumption`,
      compareItem: null,
      active: false
    },
    GAS: {
      code: 'GAS',
      name: $localize`Gas consumption`,
      compareItem: null,
      active: false
    },
    HEAT: {
      code: 'HEAT',
      name: $localize`Heat consumption`,
      compareItem: null,
      active: false
    },
    ENERGY_PRODUCTION: {
      code: 'ENERGY_PRODUCTION',
      name: $localize`Solar energy production`,
      compareItem: null,
      active: false
    }
  };

  constructor(
    @Inject(SMARTENCITY_CORE_CONFIG) private config: CoreConfig,
    private http: HttpClient,
    private compareService: CompareService,
    private cityService: CityApiService,
    private toastr: ToastrService,
    private compareItemStore: CompareItemStoreService,
    private buildingsService: BuildingsService
  ) {
    for (const seriesType of Object.keys(this.seriesTypeFilters)) {
      this.seriesTypeFilters[seriesType].compareItem = this.compareService.createCompareItem(this.seriesTypeFilters[seriesType].name);
    }

    this.changes$.subscribe(() => {
      this.count$.next(this.compareBuildings.size);
    });

    this.count$.next(0);

    this.buildingsService.getBuildings().subscribe((buildings: any[]) => {
      for (const building of buildings) {
        this.compareItemStore.getStoredCompareItemsByType('BUILDING').forEach((item: StoredCompareItem) => {
          if (building.address == item.id) {
            this.add(building);
          }
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.changes$.complete();
    this.count$.complete();
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  public toggleItem(seriesTypeFilter: SeriesTypeFilter) {
    seriesTypeFilter.active = !seriesTypeFilter.compareItem.active;

    for (let compareBuilding of Array.from(this.compareBuildings.values())) {
      if (compareBuilding.active) {
        let seriesDatasetGroup = compareBuilding.seriesDatasetGroups[seriesTypeFilter.code];
        if (!seriesDatasetGroup) {
          continue;
        }

        for (let dataset of seriesDatasetGroup.datasets) {
          dataset.item.active = seriesTypeFilter.active;
          dataset.chartDataset.hidden = !seriesTypeFilter.active;
        }
      }
    }

    this.compareService.chartChanges$.next();
  }

  /**
   *
   * a) kas kasutaja ise on kinni klikkinud
   *
   * b) või on peidetud, sest ei aktiivset ehitist sellise seeriatüübiga
   */

  public toggleBuildingInComparison(selectedCompareBuilding: BuildingCompareItem): void {
    if (selectedCompareBuilding) {
      selectedCompareBuilding.active = !selectedCompareBuilding.active;

      let otherBuildingActiveSeriesTypes = [];
      if (!selectedCompareBuilding.active) {
        for (let compareBuilding of Array.from(this.compareBuildings.values())) {
          if (!compareBuilding.active || compareBuilding == selectedCompareBuilding) {
            continue;
          }

          for (let seriesType in selectedCompareBuilding.seriesDatasetGroups) {
            if (compareBuilding.seriesDatasetGroups[seriesType]) {
              otherBuildingActiveSeriesTypes.push(seriesType);
            }
          }
        }
      }

      for (let seriesType in selectedCompareBuilding.seriesDatasetGroups) {
        let seriesDatasetGroup = selectedCompareBuilding.seriesDatasetGroups[seriesType];
        for (let dataset of seriesDatasetGroup.datasets) {
          dataset.chartDataset.hidden = !selectedCompareBuilding.active;
          let datasetItemActive = selectedCompareBuilding.active;
          if (!selectedCompareBuilding.active && otherBuildingActiveSeriesTypes.includes(seriesType)) {
            datasetItemActive = true;
          }
          dataset.item.active = datasetItemActive;
        }
      }

      this.changes$.next();
      this.compareService.chartChanges$.next();
    }
  }

  public clear(): void {
    for (const compareBuilding of Array.from(this.compareBuildings.values())) {
      this.deleteCompareBuildingDatasets(compareBuilding);
    }
    this.compareBuildings.clear();
  }

  public contains(building: any) {
    if (!building) {
      return false;
    }

    return this.compareBuildings.has(building.address);
  }

  public add(building: Building): boolean {
    //TODO: sisend ei ole enam Building
    return this.doAdd(building);
  }

  private doAdd(building: Building): boolean {
    if (this.compareBuildings.has(building.address)) {
      return false;
    }

    const max = 3;
    if (this.compareBuildings.size >= max) {
      this.toastr.error($localize`Maximum number of compare buildings (${max}) exceeded.`);
      return false;
    }

    this.addCompareItemToStore(building);

    const datasetGroupMap = {};

    const buildingCompareItem = new BuildingCompareItem({
      building: building,
      seriesDatasetGroups: datasetGroupMap,
      active: true
    });

    this.compareBuildings.set(building.address, buildingCompareItem);

    for (const series of building.series) {
      const seriesTypeFilter = this.seriesTypeFilters[series.seriesType];
      if (!seriesTypeFilter || !seriesTypeFilter.compareItem) {
        continue;
      }

      seriesTypeFilter.active = true;
      seriesTypeFilter.compareItem.active = true;

      if (!buildingCompareItem.seriesDatasetGroups[series.seriesType]) {
        buildingCompareItem.seriesDatasetGroups[series.seriesType] = new BuildingCompareSeriesDatasetGroup({
          seriesType: series.seriesType,
          datasets: []
        });
      }

      let seriesDatasetGroup =  buildingCompareItem.seriesDatasetGroups[series.seriesType];

      // take unit from series
      seriesTypeFilter.compareItem.unit = series.unit;

      const compareItemDataset = this.compareService.createCompareItemDataset(seriesTypeFilter.compareItem, series.personSeriesId, seriesTypeFilter.name + ' (' + building.address + ')', seriesTypeFilter.active, (filters) => {
        let result;
        if (!filters.range.from || !filters.range.to) {
          result = throwError($localize`No period specified`);
        } else {
          result = this.cityService.getPersonSeriesHistoricalMeasurements(
            series.personSeriesId,
            filters.range.from.toISOString(),
            filters.range.to.toISOString(),
            filters.aggregationGroupingType
          );
        }
        return result.pipe(
          catchError((e: any) => {
            console.error('Error loading historical data', e);
            return of({
              event: null,
              measurement: null
            });
          }),
          map((historicalResponse) => {
            return {
              filters: filters,
              historicalResponse: historicalResponse
            };
          })
        );
      });

      seriesDatasetGroup.datasets.push(compareItemDataset);
    }

    this.changes$.next();

    return true;
  }

  public removeBuildingCompareItemByBuilding(building: Building): void {
    let compareBuilding = this.getBuildingCompareItemByBuilding(building);
    if (!compareBuilding) {
      return;
    }

    this.removeBuildingCompareItem(compareBuilding);
  }

  public getBuildingCompareItemByBuilding(building: Building): BuildingCompareItem {
    return this.compareBuildings.get(building.address);
  }

  public removeBuildingCompareItem(compareBuilding: BuildingCompareItem): void {
    let otherBuildingActiveSeriesTypes = [];
    for (let otherCompareBuilding of Array.from(this.compareBuildings.values())) {
      if (otherCompareBuilding == compareBuilding) {
        continue;
      }

      for (let seriesType in compareBuilding.seriesDatasetGroups) {
        if (otherCompareBuilding.seriesDatasetGroups[seriesType]) {
          otherBuildingActiveSeriesTypes.push(seriesType);
        }
      }
    }

    for (let seriesType in compareBuilding.seriesDatasetGroups) {
      let seriesDatasetGroup = compareBuilding.seriesDatasetGroups[seriesType];
      for (let dataset of seriesDatasetGroup.datasets) {

        if (!otherBuildingActiveSeriesTypes.includes(seriesType)) {
          dataset.item.active = false;
        }
      }
    }

    this.compareBuildings.delete(compareBuilding.building.address);
    this.deleteCompareBuildingDatasets(compareBuilding);

    this.changes$.next();

    this.removeCompareItemFromStore(compareBuilding.building);
  }

  private deleteCompareBuildingDatasets(compareBuilding: BuildingCompareItem): void {
    for (const seriesDatasetGroup of Object.values(compareBuilding.seriesDatasetGroups)) {
      for (const dataset of seriesDatasetGroup.datasets) {
        this.compareService.deleteCompareItemDataset(dataset);
      }
    }
  }

  private addCompareItemToStore(building: Building): void {
    this.compareItemStore.addCompareItemToStore('BUILDING', building.address);
  }

  private removeCompareItemFromStore(building: Building): void {
    this.compareItemStore.removeCompareItemFromStore('BUILDING', building.address);
  }

}
