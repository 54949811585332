import {Injectable, OnDestroy} from '@angular/core';
import {Location, LocationService, PersonSeriesGroup, SourceOwnerSeries, Tenant} from '@smartencity/core';
import {SeriesCreateModalComponent} from '../components/datapoint-list-manage/create-modal/series-create-modal.component';
import {take, takeUntil} from 'rxjs/operators';
import {TenantSelectService} from './tenant-select.service';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {Subject} from 'rxjs/internal/Subject';
import {
  PreventCloseModalService
} from '@smartencity/core';

@Injectable()
export class CreateSeriesModalService implements OnDestroy {

  private ngDestroy = new Subject<void>();

  private createSeriesModal: BsModalRef;

  constructor(private tenantSelectService: TenantSelectService,
              private preventCloseModalService: PreventCloseModalService,
              private locationService: LocationService) { }

  public ngOnDestroy() {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  public createSeriesAndNavigate(group?: PersonSeriesGroup): void {

    this.tenantSelectService.selectTenant().subscribe((tenant: Tenant) => {

      this.createSeriesModal = this.preventCloseModalService.show(SeriesCreateModalComponent,  {
        backdrop: true,
        initialState: {
          group: group,
          tenant: tenant
        }
      });

      this.createSeriesModal.content.saveEmitter.pipe(takeUntil(this.ngDestroy), take(1)).subscribe((data: {sourceOwnerSeries: SourceOwnerSeries, addData: boolean}) => {
        const personSeriesData = Object.assign({}, data.sourceOwnerSeries.personSeries, {
          groups: data.sourceOwnerSeries.groups
        });

        this.locationService.navigate(new Location(personSeriesData), {sourceOwnerSeries: data.sourceOwnerSeries, addData: data.addData});
      });
    });
  }
}
