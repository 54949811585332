import { Person } from "@smartencity/core";

export class Dashboard {
  id: number;
  name: string;
  publicDashboard: boolean;
  type: DashboardType = DashboardType.DEFAULT;
  publicDownloadAllowed: boolean;
}

export class PersonDashboard {
  ownerType: OwnerType = OwnerType.OWNER;
  dashboard: Dashboard;
}

export enum DashboardType {
  ENERGY_MANAGER = 'ENERGY_MANAGER',
  DEFAULT = 'DEFAULT',
  CUSTOM = 'CUSTOM'
}

export class DashboardFilter {
  dashboardId: number;
  dashboardType: DashboardType = DashboardType.DEFAULT;

  constructor(dashboardType: DashboardType) {
    this.dashboardType = dashboardType;
  }
}

export interface DashboardParams {
  dashboard_id: number;
}

export enum OwnerType {
  OWNER = 'OWNER',
  SHARED = 'SHARED'
}

export interface ConsentData {
  dashboard: Dashboard;
  id: number;
  person: Person;
}
