export class AuthConstants {

  public static readonly TOKEN_KEY = 'x-auth-token';

  public static readonly ROLE_KEY = 'user_person';

  public static readonly PROFILE_KEY = 'current_session_state';

  public static readonly LOGIN_MODE_KEY = 'login_mode';

}
