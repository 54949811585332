import {Inject, Injectable} from '@angular/core';
import {SMARTENCITY_CORE_CONFIG} from '../../../../core/src/lib/injection-tokens';
import {CoreConfig} from '../../../../core/src/lib/core-config.model';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {QuestionnaireResponseDetail} from '../../../../core/src/lib/models/questionnaire';

@Injectable()
export class QuestionnaireResponseApiService {

  constructor(@Inject(SMARTENCITY_CORE_CONFIG) private config: CoreConfig,
              private http: HttpClient) {
  }

  getResponse(questionnaireId: number): Observable<QuestionnaireResponseDetail> {

    return this.http.get<QuestionnaireResponseDetail>(this.config.apiUrl + '/questionnaire-participation/' + questionnaireId + '/response');
  }

  createResponse(data: any): Observable<QuestionnaireResponseDetail> {

    return this.http.post<QuestionnaireResponseDetail>(this.config.apiUrl + '/questionnaire-response', data);
  }

  extendResponse(id: number, data: any): Observable<QuestionnaireResponseDetail> {

    return this.http.put<QuestionnaireResponseDetail>(this.config.apiUrl + '/questionnaire-response/' + id + '/extend', data);
  }

}
