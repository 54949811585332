import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {PersonSeriesGroup} from '@smartencity/core';
import {ToastrService} from 'ngx-toastr';
import {PersonSeriesGroupApiService} from '../../../http/person-series-group-api.service';

@Component({
  selector: 'app-delete-datapoint-group-confirm-modal',
  templateUrl: './delete-datapoint-group-confirm-modal.component.html'
})
export class DeleteDatapointGroupConfirmModalComponent implements OnInit {

  @Input()
  group: PersonSeriesGroup;

  @Output('saved')
  savedEmitter: EventEmitter<void> = new EventEmitter<void>();

  deleteWithDataGroupsProgress = false;
  progress = false;

  constructor(private modalRef: BsModalRef,
              private personSeriesGroupService: PersonSeriesGroupApiService,
              private toastr: ToastrService) { }

  ngOnInit(): void {
  }

  close(): void {
    this.modalRef.hide();
  }

  confirm(withDataPoints = false): void {
    if (this.progress) {
      return;
    }

    this.deleteWithDataGroupsProgress = withDataPoints;
    this.progress = true;

    this.personSeriesGroupService.deleteGroup(this.group.id, withDataPoints).subscribe(() => {
      this.toastr.success($localize`Data group deleted`);
      this.savedEmitter.next();
      this.modalRef.hide();
    }, () => {
      this.toastr.error($localize`Something went wrong!`);
      this.progress = false;
      this.deleteWithDataGroupsProgress = false;
    });

  }

}
