export class MapsGridArea {
  areaCode: number;
  name: string;
  geojson: string; //TODO translate to geojson

  constructor(props?: {
    areaCode: number,
    name: string,
    geojson: any
  }) {
    if (!props) return;
    this.areaCode = props.areaCode;
    this.name = props.name;
    this.geojson = props.geojson;
  }
}
