<form (ngSubmit)="saveForm()">
  <div class="modal-header">
    <div style="display: flex; align-items: stretch; flex-direction: column;">
      <h5 class="modal-title w-100" i18n *ngIf="group?.id || !isCreate">Edit data group</h5>
      <h5 class="modal-title w-100" i18n *ngIf="!group?.id && isCreate">Create data group</h5>
      <small i18n *ngIf="group">{{group.name}} data group</small>
    </div>
    <button type="button" class="close pull-right" i18n-aria-label aria-label="Close" (click)="close()">
      <i class="fal fa-times"></i>
    </button>
  </div>

  <div class="modal-body" [formGroup]="form">

    <div *ngIf="form.invalid && confirmed" class="alert alert-danger">
      <div i18n>Form is invalid</div>
    </div>

    <div class="form-group">
      <label i18n [attr.for]="id + '-name'">Name</label>
      <input type="text" class="form-control" [class.is-invalid]="invalid('name')" formControlName="name" [id]="id + '-name'" minlength="3" maxlength="64">
      <small *ngIf="invalid('name') && errors('name')?.required" class="invalid-feedback d-block" i18n>Required</small>
      <small *ngIf="invalid('name') && errors('name')?.minlength" class="invalid-feedback d-block" i18n>Must be longer than {{errors('name').minlength.requiredLength}} characters</small>
      <small *ngIf="invalid('name') && errors('name')?.maxlength" class="invalid-feedback d-block" i18n>Must be shorter than {{errors('name').maxlength.requiredLength}} characters</small>
    </div>

    <div class="form-group">
      <label i18n>Choose group type</label>
      <div class="data-group-type-wrapper">
        <div class="data-group-type" [ngClass]="{'active': this.form.get('type').value == typeOption.value, 'is-invalid': invalid('type')}" *ngFor="let typeOption of groupTypeOptions" (click)="toggleType(typeOption.value)">
          <i class="icon-sm" [class]="typeOption.iconClass" [ngClass]="{'active': this.form.get('type').value == typeOption.value, 'is-invalid': invalid('type')}"  tooltip="{{typeOption.label}}" appendTo="body"></i>
        </div>
      </div>
    </div>

    <section>
      <h3 i18n>Datapoints</h3>
      <div class="selectable-list">
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="filter-bar">
              <div class="input-group form-search">
                <input type="text" class="form-control" [formControl]="searchControl" placeholder="Type to search..." i18n-placeholder>
                <div class="input-group-append">
                <span class="clear-search" (click)="clearSearch()" [ngClass]="{'active': searchControl?.value?.length > 0 }">
                  <i class="fal fa-times"></i>
                </span>
                <div class="custom-dropdown filter-dropdown" dropdown [autoClose]="true" (click)="$event.stopImmediatePropagation();">
                  <button type="button" class="btn dropdown-toggle" dropdownToggle i18n>Filter</button>
                  <div class="dropdown-menu dropdown-menu-right" *dropdownMenu>
                    <div class="accordion-group"  [ngClass]="'show'">
                      <div class="accordion-header">
                        <ng-container i18n>Some filterable data?</ng-container>
                      </div>
                      <div class="accordion-content">
                      </div>
                    </div>
                  </div>
                </div>
                  <span class="input-group-text">
                  <i class="fal fa-search"></i>
                </span>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <button type="button" class="btn-sm btn-link p-0" (click)="addAll()" i18n>Add all</button>
                </div>
                <div class="col text-right">
                  <span class="text-light" i18n>{pageResponse?.totalElements, plural, =1 {{{pageResponse?.totalElements}} result} other {{{pageResponse?.totalElements}} results}}</span>
                </div>
              </div>
            </div>
            <div class="custom-list">
              <div class="list-item" [class.selected]="isSelected(personSeries)" *ngFor="let personSeries of pageResponse?.content" (click)="addItem(personSeries)">
                <span class="title">{{personSeries.name}}</span>
                <div class="row">
                  <span class="col-auto">
                    <label class="light mr-2 mb-0" i18n>Series type</label>
                    <span class="text-light">{{personSeries.seriesType | label}}</span>
                  </span>
                  <span class="col-auto">
                  <label class="light mr-2 mb-0">Asukoht</label>
                  <span class="text-light">
                    {{personSeries.location}}
                  </span>
                </span>
                </div>
              </div>
            </div>
            <div class="footer-bar">
              <div class="row">
                <div class="col">
                  <pagination
                    class="pagination-borderless"
                    *ngIf="pageResponse?.totalPages > 1"
                    [totalItems]="pageResponse?.totalElements"
                    [itemsPerPage]="pageResponse?.size"
                    [maxSize]="5"
                    [rotate]="true"
                    [formControl]="pageControl"
                    previousText="&lsaquo;"
                    nextText="&rsaquo;"
                    firstText="&laquo;"
                    lastText="&raquo;"
                  >
                  </pagination>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-1 d-flex justify-content-center  p-0">
          <span class="exchange-icon">
            <i class="fa fa-exchange"></i>
          </span>
          </div>
          <div class="col-12 col-md-5">
            <div class="filter-bar">
              <h3 i18n>Selected data</h3>
              <div class="row">
                <div class="col">
                  <button type="button" class="btn-sm btn-link p-0" *ngIf="addedPersonSeriesList.length" (click)="removeAll()" i18n>Remove all</button>
                </div>
                <div class="col text-right">
                  <span class="text-light" i18n>{addedPersonSeriesList.length, plural, =1 {{{addedPersonSeriesList.length}} item} other {{{addedPersonSeriesList.length}} items}}</span>
                </div>
              </div>
            </div>
            <div class="custom-list">
              <datapoint-group-row *ngFor="let row of addedPersonSeriesList" [personSeries]="row" (remove)="removeItem(row)"></datapoint-group-row>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
  <div class="modal-footer">
    <button type="submit" class="btn btn-primary btn-sm" [ladda]="saveInProgress" i18n>Save</button>
  </div>
</form>
