import {Injectable} from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import {AuthService} from '../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.getAuthentication(state.url, this.router.url, next.queryParams['token']).then((isAuthenticated: boolean) => {
        if (!isAuthenticated) {
          console.log('not authenticated')
          resolve(false);
          this.router.navigateByUrl(this.router.url);
          return;
        }
        console.log('authenticated')
        resolve(true);
      }).catch((reason: any) => {
        reject(reason);
      });
    });
  }

  getAuthentication(targetUrlPath: string, urlPath: string, token?: string): Promise<boolean> {
    return this.authService.getAuthenticationStatus({targetUrlPath: targetUrlPath, currentUrlPath: urlPath, token: token});
  }

}
