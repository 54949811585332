import {Component, Inject} from '@angular/core';
import { Router } from '@angular/router';
import {SMARTENCITY_CORE_CONFIG} from '../../injection-tokens';
import {CoreConfig} from '../../core-config.model';

@Component({
  selector: 'core-language-select',
  templateUrl: './language-select.component.html'
})
export class LanguageSelectComponent {
  i18n: {name: string; baseUrl: string}[] = this.config.i18n;

  constructor(
    @Inject(SMARTENCITY_CORE_CONFIG) private config: CoreConfig,
    public router: Router
  ) {}
}
