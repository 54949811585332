import {AbstractMapLayer, OlMapWrapper} from '@smartencity/core';
import { EecBuilding } from '../model/eec-building';
import { EecBuildingOlMarker } from './eec-buildings-ol-marker';

export class EecBuildingsOlLayer extends AbstractMapLayer<OlMapWrapper, EecBuilding>{

  private eecBuildings: EecBuilding[];

  constructor(
    eecBuildings: EecBuilding[]) {
    super();
    this.eecBuildings = eecBuildings;
  }
  
  setMap(map: OlMapWrapper): void {
    super.setMap(map);
    this.resetMarkers();
    if (map) {
      this.createMarkers();
    }
  }
  
  public resetMarkers(): void {
    let markers = this.getMarkers();
    if (markers.length > 0) {
      for (const marker of markers) {
        marker.setMap(null);
      }
    }
    this.clearMarkers();
  }

  public setEecBuildings(eecBuildings: EecBuilding[]): void {
    const markers = this.getMarkers();
    let addedMarkers = [];
    for(let eecBuilding of eecBuildings) {
      let matchedMarker = null;
      for(let marker of markers) {
        if(marker.getData() == eecBuilding) {
          matchedMarker = marker;
          break;
        }
      }
      if(matchedMarker == null) {
        matchedMarker = new EecBuildingOlMarker(eecBuilding, this.map);
        this.addMarker(matchedMarker);
      } else {
        if( matchedMarker.getMap() == null ) {
          matchedMarker.setMap(this.map);
        }
      }
      addedMarkers.push(matchedMarker);
    }
    for(let marker of markers) {
      let added = false;
      for(let addedMarker of addedMarkers) {
        if( marker == addedMarker) {
          added = true;
          break;
        }
      }
      if(!added) {
        marker.setMap(null);
      }
    }
  }
  
  public createMarkers(): void {
    this.setEecBuildings(this.eecBuildings);
  }
}
