<div class="modal-header d-flex">
  <div style="display: flex; align-items: stretch; flex-direction: column;">
    <h5 class="modal-title w-100" i18n>Data sharing</h5>
    <small i18n>{{group.name}} data group</small>
  </div>
  <button type="button" class="close pull-right" i18n-aria-label aria-label="Close" (click)="close()">
    <i class="fal fa-times"></i>
  </button>
</div>
<div class="modal-body" style="min-height: 700px" #scrollingBlock>
  <div class="row">
    <div class="col">
      <div class="alert alert-light">
        <span *ngIf="!checkAllControlIndeterminate">
          <span *ngIf="!allChecked">Sharing no data points out of {{data.count}} data point(s).</span>
          <span *ngIf="allChecked">Sharing all data points (<span><b>{{data.sharedCount}}</b></span> out of {{data.count}} data point(s)).</span>
        </span>
        <span *ngIf="checkAllControlIndeterminate">
             Sharing <span><b>{{data.sharedCount}}</b></span> out of {{data.count}} data point(s). <button type="button" class="btn btn-sm btn-outline-primary" (click)="reset()" i18n>Reset</button>
        </span>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <div class="input-group input-group-lg form-search" [formGroup]="filterForm">
        <input type="text" name="query" formControlName="query" class="form-control" placeholder="Datapoint name" i18n-placeholder autocomplete="off">
        <div class="input-group-append">
        <div class="custom-dropdown filter-dropdown" dropdown>
          <button type="button" class="btn dropdown-toggle" dropdownToggle i18n>Filter</button>
          <div class="dropdown-menu dropdown-menu-right" *dropdownMenu>
            <div class="accordion-group"  [ngClass]="'show'">
              <div class="accordion-header">
                <i class="fal fa-angle-down"></i>
                <ng-container i18n>Status</ng-container>
              </div>
              <div class="accordion-content">
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" value="true" class="custom-control-input" [id]="'status-allowed'" formControlName="isOwnerPersonSeries">
                  <label class="custom-control-label" [attr.for]="'status-allowed'" i18n>Allowed</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" value="false" class="custom-control-input" [id]="'status-not-allowed'" formControlName="isOwnerPersonSeries">
                  <label class="custom-control-label" [attr.for]="'status-not-allowed'" i18n>Not allowed</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <span class="input-group-text">
          <i class="fal fa-search"></i>
        </span>
        </div>
      </div>
    </div>
    <div class="col-md-12 text-right mt-2" *ngIf="filterForm.get('query')?.value?.length > 0 || filterForm.get('isOwnerPersonSeries')?.value?.length > 0">
      <span class="clear-search btn btn-secondary btn-sm active" (click)="clearSearch()">
        <i class="fal fa-times mr-2"></i> <span i18n>Clear search</span>
      </span>
    </div>
  </div>

  <!-- <div class="row mt-3 mb-3">
    <div class="col">
      <div class="checkbox">
        <input type="checkbox" class="checkbox" [formControl]="checkAllControl" #checkAllEl /> <label>Vali kõik</label>
      </div>
    </div>
  </div> -->

  <div class="row p-3 flex-nowrap">
    <div class="col-md-1">
        <div class="checkbox pl-3">
           <input type="checkbox" class="checkbox" [formControl]="checkAllControl" #checkAllEl />
        </div>
     </div>
    <div class="col-md-8 pl-4"><span i18n>Datapoint name</span></div>
    <div class="col-md-3"><span i18n>Status</span></div>
  </div>
  <div class="row">
    <div class="col">
      <virtual-scroller #listScroll [items]="items" [parentScroll]="scrollingBlock" [bufferAmount]="bufferRows * 2" (vsChange)="chartVsChange($event)">
        <div class="list" #container>
          <div class="list-item" *ngFor="let item of listScroll.viewPortItems">
            <div class="row flex-nowrap" *ngIf="item">
              <div class="col-md-1">
                <div class="checkbox" *ngIf="item.shareAllowed">
                   <input type="checkbox" class="checkbox" [formControl]="item.checked"/>
                </div>
               </div>
              <div class="col-md-8">{{item.personSeries.name}}</div>
              <div class="col-md-3">
                <span class="w-100 d-flex justify-content-center p-2 badge badge-success rounded-pill" *ngIf="item.shareAllowed" i18n>Allowed</span>
                <span class="w-100 d-flex justify-content-center p-2 badge badge-danger rounded-pill" *ngIf="!item.shareAllowed" i18n>Not allowed</span>
              </div>
            </div>
          </div>
        </div>
      </virtual-scroller>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary btn-sm mr-2" i18n (click)="close()">Close</button>
  <button type="button" class="btn btn-primary btn-sm" i18n (click)="closeAndReturnResult()" [disabled]="!data.sharedCount">Continue</button>
</div>
