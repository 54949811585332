import {Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {Subject} from 'rxjs/internal/Subject';
import {FormArray, FormControl, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {SMARTENCITY_MYDATA_CONFIG} from '../../../../injection-tokens';
import {MyDataConfig} from '../../../../mydata-config.model';
import {Threshold} from '@smartencity/core';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'mydata-threshold-row',
  templateUrl: './threshold-row.component.html',
  styles: [':host {display: table-row}'],
  providers: []
})
export class ThresholdRowComponent implements OnInit, OnDestroy, OnChanges {
  private ngDestroy = new Subject<void>();

  public progress = false;
  public editMode = false;

  @Input()
  threshold: Threshold;

  @Input()
  commonUnit: string;

  @Output('remove')
  removeEmitter = new EventEmitter<void>();

  @Output('save')
  saveEmitter = new EventEmitter<void>();

  form: FormGroup = new FormGroup({
    id: new FormControl(null, []),
    name: new FormControl('', [Validators.required]),
    value: new FormControl(null, [Validators.required, Validators.pattern(/^\-?\d+([\.\,]\d+)?$/)]),
    comparisonOperator: new FormControl('', [Validators.required]),
    enabled: new FormControl(false, [Validators.required])
  });

  public comparisonOperators = {
    'EQ': $localize`Equal`,
    'NE': $localize`Not equal`,
    'GT': $localize`Greater than`,
    'LT': $localize`Less than`,
    'GE': $localize`Greater than or equal to`,
    'LE': $localize`Less than or equal to`
  };

  public comparisonOperatorOptions = Object.entries(this.comparisonOperators).map(([key, value]: [string, string]) => ({value: key, label: value}));

  constructor(
    private http: HttpClient,
    @Inject(SMARTENCITY_MYDATA_CONFIG) private config: MyDataConfig,
    public toastService: ToastrService
  ) {

  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['threshold']) {
      this.form = new FormGroup({
        id: new FormControl(this.threshold.id, []),
        personSeriesId: new FormControl(this.threshold.personSeriesId, [Validators.required]),
        name: new FormControl(this.threshold.name, [Validators.required]),
        value: new FormControl(this.threshold.value, [Validators.required, Validators.pattern(/^\-?\d+([\.\,]\d+)?$/)]),
        comparisonOperator: new FormControl(this.threshold.comparisonOperator, [Validators.required]),
        enabled: new FormControl(this.threshold.enabled, [Validators.required])
      });
      if (!this.threshold.id) {
        this.editMode = true;
      }
    }
  }

  control(name?: string) {
    if (!name) {
      return this.form;
    }
    return this.form.get(name);
  }

  invalid(name: string, formGroup?) {
    if (!formGroup) {
      formGroup = this.form;
    }
    const control = formGroup.get(name);
    return control && control.invalid && (control.dirty || control.touched);
  }

  errors(name: string): ValidationErrors {
    const control = this.form.get(name);
    return control ? control.errors : null;
  }

  remove(): void {
    const id = this.form.get('id').value;
    if (id) {
      this.http.delete(this.config.apiUrl + '/person-series-thresholds/' + id).subscribe(() => {
        this.form.reset();
        this.removeEmitter.emit();
        this.toastService.success($localize`Threshold removed`);
      }, (error) => {
        this.progress = false;
        console.error(error);
        this.toastService.error($localize`Error removing threshold`);
      }, () => {
        this.progress = false;
      });
    } else {
      this.removeEmitter.emit();
    }
  }

  edit() {
    this.form.enable();
    this.editMode = true;
  }

  cancel() {
    if (this.form.get('id').value) {
      this.editMode = false;
    } else {
      this.form.reset();
      this.removeEmitter.emit();
    }
  }

  save() {
    this.setAsTouched(this.form);
    this.form.updateValueAndValidity();
    if (!this.form.valid) {
      console.error('Form invalid');
      return;
    }

    this.form.disable();

    if (!this.form.touched) {
      this.editMode = false;
      return;
    }

    const value = this.form.getRawValue();
    this.progress = true;

    const body = {
      id: value.id,
      personSeriesId: value.personSeriesId,
      name: value.name,
      value: value.value && typeof(value.value) === 'string' ? value.value.trim().replace(',', '.') : value.value,
      comparisonOperator: value.comparisonOperator,
      enabled: value.enabled
    };
    const cb = (result: Threshold) => {
      this.threshold.id = result.id;
      this.threshold.personSeriesId = result.personSeriesId;
      this.threshold.name = result.name;
      this.threshold.value = result.value;
      this.threshold.comparisonOperator = result.comparisonOperator;
      this.threshold.enabled = result.enabled;
      this.threshold.createdAt = result.createdAt;
      this.threshold.updatedAt = result.updatedAt;
      this.form.reset({
        id: result.id,
        personSeriesId: result.personSeriesId,
        name: result.name,
        value: result.value,
        comparisonOperator: result.comparisonOperator,
        enabled: result.enabled
      });
      this.toastService.success($localize`Threshold saved`);
      this.saveEmitter.emit();
    };

    if (body.id) {
      this.http.put(this.config.apiUrl + '/person-series-thresholds/' + body.id, body).subscribe(cb, (error) => {
        this.progress = false;
        console.error(error);
        this.toastService.error($localize`Error saving threshold`);
      }, () => {
        this.editMode = false;
        this.progress = false;
      });
    } else {
      this.http.post(this.config.apiUrl + '/person-series-thresholds', body).subscribe(cb, (error) => {
        this.progress = false;
        console.error(error);
        this.toastService.error($localize`Error saving threshold`);
      }, () => {
        this.editMode = false;
        this.progress = false;
      });
    }
  }

  setAsTouched(group: FormGroup | FormArray) {
    group.markAsTouched({onlySelf: true});
    group.markAsDirty({onlySelf: true});
    for (const i in group.controls) {
      if (group.controls[i] instanceof FormControl) {
        group.controls[i].markAsTouched({onlySelf: true});
        group.controls[i].markAsDirty({onlySelf: true});
      } else {
        this.setAsTouched(group.controls[i]);
      }
    }
  }
}
