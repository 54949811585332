import {AbstractMapLayerMarker, OlMapWrapper} from '@smartencity/core';
import { RoadClosure } from '../model/road-closure';
import { MobilityService } from '../service/mobility.service';
import { RoadClosureOlMarkerWrapper } from './road-closures-ol-marker-wrapper';
import GeoJSON from 'ol/format/GeoJSON';
import * as Extent from 'ol/extent';
import Feature, {FeatureLike} from 'ol/Feature';

export class RoadClosureOlMarker extends AbstractMapLayerMarker<OlMapWrapper, RoadClosure>{

  private marker: RoadClosureOlMarkerWrapper;
  private selected = false;

  constructor(roadClosure: RoadClosure, map: OlMapWrapper, mobilityService: MobilityService) {
    super(roadClosure);
    this.marker = new RoadClosureOlMarkerWrapper(roadClosure, map, {
      title: roadClosure.meta,
      position: this.getPosition(roadClosure),
      className: 'road-closure-marker-wrapper',
      stopEvent: false
    },
    mobilityService);
  }

  draw() {
    this.marker.draw();
  }

  setMap(map: OlMapWrapper) {
    super.setMap(map);
    const olMap = (map ? map.getMap() : null);
    this.marker.setMap(olMap);
  }

  isSelected(): boolean {
    return this.selected;
  }

  setSelected(selected: boolean): void {
    this.marker.selected = selected;
  }

  private getPosition(roadClosure: RoadClosure): number[] {
    let feature: FeatureLike = new GeoJSON().readFeature({
      type: "Feature",
      geometry: roadClosure.geojson
    });

    return Extent.getCenter(feature.getGeometry().getExtent());
  }

}
