import {Inject, Injectable} from '@angular/core';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  shareReplay,
  startWith,
  switchMap,
  takeUntil,
  withLatestFrom
} from 'rxjs/operators';
import {AuthService, Location, PageResponse, SourceOwnerSeries, SourceOwnerSeriesListItem} from '@smartencity/core';
import {Subject} from 'rxjs/internal/Subject';
import {merge} from 'rxjs/internal/observable/merge';
import {Observable} from 'rxjs';
import {FormControl, FormGroup} from '@angular/forms';
import {CurrentLocationService} from '../../services/current-location.service';
import {SMARTENCITY_MYDATA_CONFIG} from '../../injection-tokens';
import {MyDataConfig} from '../../mydata-config.model';
import {HttpClient} from '@angular/common/http';
import {throwError} from 'rxjs/internal/observable/throwError';
import {SharedKeywordStore} from '../../services/shared-search-keyword-store.service';

@Injectable()
export class ManagePersonSeriesService {

  private ngDestroy = new Subject<void>();

  public page: PageResponse<SourceOwnerSeries>;
  public defaultLimit = 25;
  public defaultSorts = [{prop: 'name', dir: 'asc'}];

  public filterForm: FormGroup = new FormGroup({
    query: new FormControl(),
    confirmationStatusPending: new FormControl(false),
    confirmationStatusAccepted: new FormControl(false),
    confirmationStatusDeclined: new FormControl(false),
    showOnCityPortalTrue: new FormControl(false),
    showOnCityPortalFalse: new FormControl(false),
    returnConsents: new FormControl(true)
  });

  public filter$ = combineLatest([
    this.currentLocationService.currentLocation$,
    this.filterForm.valueChanges.pipe(distinctUntilChanged(), debounceTime(250), startWith({}))
  ]).pipe(map(([location, filter]: [Location, any]) => {
    return Object.assign({location: location}, filter)
  }));

  public page$: Subject<any> = new Subject<any>();
  public sorts$: Subject<any> = new Subject<any>();
  public limit$: Subject<any> = new Subject<any>();

  public filterSortsLimit$ = combineLatest([
    this.filter$,
    this.sorts$.pipe(startWith(this.defaultSorts)),
    this.limit$.pipe(startWith(this.defaultLimit))
  ]).pipe(shareReplay(1));

  public queryParams$ = merge(
    this.filterSortsLimit$.pipe(map(([filter, sorts, limit]: [any, any[], number]) => [filter, sorts, limit, {offset: this.page ? this.page.page : 0, limit: limit}])),
    this.page$.pipe(
      withLatestFrom(this.filterSortsLimit$),
      map(([page, [filter, sorts, limit]]: [any, [any, any[], number]]) => [filter, sorts, limit, page])
    )
  ).pipe(
    map(([filter, sorts, limit, page]) => this.mapQueryParams(filter, sorts, limit, page)),
    takeUntil(this.ngDestroy),
    shareReplay(1)
  );
  public reloadSubject: Subject<void> = new Subject();
  public reload$ = this.reloadSubject.asObservable();
  public pageResponse$ = combineLatest([this.queryParams$, this.reload$.pipe(startWith(null))]).pipe(
    takeUntil(this.ngDestroy),
    switchMap(([queryParams, ]: [any, any]) => this.fetchPage(queryParams)),
    shareReplay(1)
  );

  public rows$: Observable<SourceOwnerSeriesListItem[]> = this.pageResponse$.pipe(
    map((data: PageResponse<SourceOwnerSeries>) => this.mapPageResultsToRows(data)),
    shareReplay(1)
  );

  constructor(
    @Inject(SMARTENCITY_MYDATA_CONFIG) private config: MyDataConfig,
    private http: HttpClient,
    private authService: AuthService,
    private currentLocationService: CurrentLocationService,
    private sharedKeywordStore: SharedKeywordStore
  ) {
    this.filterForm.valueChanges.pipe(takeUntil(this.ngDestroy)).subscribe((filter) => {
      if (filter.query !== this.sharedKeywordStore.state) {
        this.sharedKeywordStore.setState(filter.query);
      }
    });
  }

  ngOnDestroy(): void {
    this.reloadSubject.complete();
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  resetResults(): void {
    this.filterForm.patchValue({
      query: this.sharedKeywordStore.state
    });
  }

  mapQueryParams(filter: any, sorts: any[], limit: number, page: any) {
    let location: Location = filter.location;

    const queryParams: any = {
      groupingTag: location.groupingTag != null ? location.groupingTag : null,
      groupId: location.group ? location.group.id : '',
      locationType: location.locationType ? location.locationType : '',
      q11eType: location.q11eType ? location.q11eType : '',
      q11eId: location.q11eId ? location.q11eId : '',
      address: location.address ? location.address : '',
      lat: location.lat ? location.lat : '',
      lng: location.lng ? location.lng : '',
      uuid: location.uuid ? location.uuid : '',
      returnDataOwnerPerson: true,
      returnConsents: true,
      returnGroups: true
    };

    if (filter) {
      const confirmationStatuses = [];
      if (filter.confirmationStatusPending) {
        confirmationStatuses.push('PENDING');
      }
      if (filter.confirmationStatusAccepted) {
        confirmationStatuses.push('ACCEPTED');
      }
      if (filter.confirmationStatusDeclined) {
        confirmationStatuses.push('DECLINED');
      }
      if (confirmationStatuses.length) {
        queryParams.confirmationStatuses = confirmationStatuses;
      }

      if (filter.confirmationStatuses) {
        queryParams.confirmationStatuses = filter.confirmationStatuses.join(',');
      }
      if (filter.showOnCityPortalTrue && !filter.showOnCityPortalFalse) {
        queryParams.showOnCityPortal = true;
      }
      if (!filter.showOnCityPortalTrue && filter.showOnCityPortalFalse) {
        queryParams.showOnCityPortal = false;
      }
      if (filter.query) {
        queryParams.query = filter.query;
      }
    }
    if (page) {
      queryParams.page = page.offset;
      queryParams.size = limit;
    }
    if (limit) {
      queryParams.size = limit;
    }
    if (sorts && sorts.length > 0) {
      queryParams.sortFields = sorts.reduce((accumulator, currentValue) => {
        if (currentValue.dir === 'desc') {
          return accumulator + encodeURIComponent('-' + currentValue.prop);
        } else {
          return accumulator + '+' + currentValue.prop;
        }
      }, '');
    }

    return queryParams;
  }

  fetchPage(queryParams: any): Observable<PageResponse<SourceOwnerSeries>> {
    const params = Object.assign({}, queryParams);

    for (const i of Object.keys(params)) {
      if (params[i] == null) {
        delete params[i];
      }
    }

    return this.http.get<PageResponse<SourceOwnerSeries>>(this.config.apiUrl + '/data-owner-series', {
      params: params
    }).pipe(catchError((err) => {
      return throwError(err);
    }));
  }

  mapPageResultsToRows(data: PageResponse<SourceOwnerSeries>): SourceOwnerSeriesListItem[] {
    this.page = data;
    const rows = [];
    for (const series of data.content) {
      rows.push((<SourceOwnerSeriesListItem>{
        id: series.id,
        series: series,
        name: series.personSeries.name,
        description: series.personSeries.description,
        updatedAt: series.personSeries.updatedAt,
        ownerType: series.lastPeriod.ownerType,
        confirmationStatus: series.lastPeriod.confirmationStatus,
        showOnCityPortal: series.personSeries.showOnCityPortal,
        sourceType: series.source?.type
      }));
    }

    return rows;
  }

  public resetLists(): void {
    this.reloadSubject.next();
  }

}
