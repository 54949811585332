import {Inject, Injectable, LOCALE_ID} from '@angular/core';
import {TaraAuthRedirectStrategy} from './tara-auth-redirect-strategy';
import {TaraLogoutRedirectStrategy} from './tara-logout-redirect-strategy';
import {IdpAuthService} from '../idp-auth.service';
import {AuthParams, CoreConfig, LocationService, SMARTENCITY_CORE_CONFIG} from '@smartencity/core';

@Injectable()
export class TaraAuthService extends IdpAuthService {

  constructor(@Inject(SMARTENCITY_CORE_CONFIG) private config: CoreConfig,
              @Inject(LOCALE_ID) public locale: string,
              private locationService: LocationService) {
    super();
  }

  getName(): string {
    return 'tara';
  }

  login(authParams: AuthParams): Promise<boolean> {
    if (!this.loginRedirectStrategy) {
      this.loginRedirectStrategy = new TaraAuthRedirectStrategy(this.config, this.locationService.getCityPortalHost());
    }

    let urlParams = {
      locale: this.locale
    };

    if (this.config.serviceName) {
      urlParams['serviceName'] = this.config.serviceName;
    }

    let redirectParams = {
      targetUrlPath: authParams.targetUrlPath,
      currentUrlPath: authParams.currentUrlPath,
      params: urlParams
    }

    return this.doLogin(redirectParams);
  }

  logout(params?: any): Promise<boolean> {
    if (!this.logoutRedirectStrategy) {
      this.logoutRedirectStrategy = new TaraLogoutRedirectStrategy(this.config);
    }

    return this.doLogout(params);
  }

}
