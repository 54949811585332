import {EventEmitter, Inject, Injectable} from '@angular/core';
import {
  OlMapWrapper,
  OlMapWrapperOptions,
  ArcGisRestService,
  CoreConfig,
  LegendLayerInfo,
  OlLayerDisplaySettings,
  SMARTENCITY_CORE_CONFIG
} from '@smartencity/core';
import {LayerDisplaySettings} from './layer-display-settings';
import {FeatureLike} from 'ol/Feature';
import {Select} from 'ol/interaction';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { createStyleFunctionFromUrl, createStyleFunction } from 'ol-esri-style';
import Overlay from 'ol/Overlay';
import * as Extent from 'ol/extent';
import {HttpClient} from '@angular/common/http';
import { TallinnArcGisMapService } from './tallinn-arc-gis-map.service';

@Injectable()
export class ArcGisComponentMapService {

  public legendLoaded$: EventEmitter<any> = new EventEmitter<any>();

  private layersInfo: OlLayerDisplaySettings = {
    toilets: {
      name: 'toilets',
      serviceItemId: '3f183e4a060b40898065bf628f0d75ce',
      layerId: 0
    },
    residentialAreas: {
      serviceItemId: 'cf3a072b3e064393a6142808967b10d3',
      name: 'residentialAreas',
      layerId: 5,
      drawingInfo: {
        'renderer': {
          'type': 'simple',
          'symbol': {
            'type': 'esriSFS',
            'style': 'esriSFSSolid',
            'color': [
              212,
              252,
              239,
              0
            ],
            'outline': {
              'type': 'esriSLS',
              'style': 'esriSLSSolid',
              'color': [
                0,
                112,
                255,
                255
              ],
              'width': 1
            }
          }
        },
        'scaleSymbols': true,
        'transparency': 0,
        'labelingInfo': null
      }
    },
    cityDistricts: {
      name: 'toilets',
      serviceItemId: 'cf3a072b3e064393a6142808967b10d3',
      layerId: 6
    }
  };

  private select: Select;

  private olMapWrapper: OlMapWrapper;

  protected displaySettings: LayerDisplaySettings;

  constructor(@Inject(SMARTENCITY_CORE_CONFIG) private config: CoreConfig,
              private arcGisClient: ArcGisRestService,
              private http: HttpClient,
              private tallinnArcGisMapService: TallinnArcGisMapService) { }

  public loadMap(displaySettings: LayerDisplaySettings) {

    // this.displaySettings = displaySettings;

    // const baseUrl = this.config.cityApiUrl + "/tallinn/map-server-proxy";
    // const opts = new OlMapWrapperOptions();
    // opts.baseMapServerUrl = baseUrl + '/rest/services/aluskaart_yldine/MapServer';

    // opts.getFeatureStyle = ((layerUrl, layerId) => {
    //   return this.getLayerStyleFunction(layerId, layerUrl);
    // });
    // opts.layerFilter = ((layerId) => {
    //   return this.getDisplayLayersInfo(layerId) != null;
    // });

    // const arcGisMap = new OlMapWrapper(this.arcGisClient, opts);
    // this.olMapWrapper = arcGisMap;

    // this.tallinnArcGisMapService.addCityLayers(this.olMapWrapper);

    // this.olMapWrapper.addFeatureLayer()

    // this.olMapWrapper.addMapListener('loadend', () => {
    //   this.onDisplaySettingsChange();
    //   arcGisMap.getLegendInfo().subscribe((legendInfos: LegendLayerInfo[]) => {
    //     this.legendLoaded$.next(legendInfos)
    //   });
    // });

    // this.olMapWrapper.mapLoaded$.subscribe(this.afterMapLoaded.bind(this));

  }

  afterMapLoaded(): void {
    let olMap = this.olMapWrapper.getMap();

    const tooltipOverlay = new Overlay({
      element: document.getElementById('tooltip')
    });

    const popoverOverlay = new Overlay({
      element: document.getElementById('cityDistrictPopover')
    });

    olMap.addOverlay(tooltipOverlay);
    olMap.addOverlay(popoverOverlay);

    const displayTooltip = (evt) => {
      const el = tooltipOverlay.getElement();
      const pixel = evt.pixel;
      let featureLayer = null;
      const feature = olMap.forEachFeatureAtPixel(pixel, (feature, layer) => {
        featureLayer = layer;
        return feature;
      }, {
        layerFilter: (l) => {
          return l == this.olMapWrapper.getFeatureLayersMapping().get('toilets');
        }
      });

      ($(el) as any).popover('dispose');
      if (feature && featureLayer) {
        let center = Extent.getCenter(feature.getGeometry().getExtent());
        tooltipOverlay.setPosition(center);

        ($(el) as any).popover({
          placement: 'top',
          animation: false,
          container: 'body',
          html: true,
          content: `${this.getObjectName(feature, featureLayer)}`
        });

        ($(el) as any).popover('show');

      }

    };

    olMap.on('pointermove', displayTooltip);
  }

  public onDisplaySettingsChange(): void {
    for (let key in this.displaySettings) {
      if (this.layersInfo[key]) {
        this.olMapWrapper.setVisibility(this.layersInfo[key].name, this.displaySettings[key]);
      }
    }


    if (!this.displaySettings.cityDistricts) {
      // this.selectLocations(null);
      this.select.getFeatures().clear();
    }
  }

  public updateDisplaySettings(displaySettings: LayerDisplaySettings) {
    this.displaySettings = displaySettings;
    this.onDisplaySettingsChange();
  };

  private getLayerStyleFunction(layerId: number, url: string): Promise<Function> {
    let layerInfo = this.getDisplayLayersInfo(layerId);
    if (!layerInfo || !layerInfo.drawingInfo) {
      return createStyleFunctionFromUrl(url + '/' + layerId);
    }

    return createStyleFunction({
      drawingInfo: layerInfo.drawingInfo
    });
  }


  private getObjectName(feature: FeatureLike, layer: VectorLayer<VectorSource>) {
    let nameProp = 'name';
    if (layer.get("layerInfo").id == 0) {
      nameProp = 'nimi';
    }
    return feature.get(nameProp);
  }

  private getDisplayLayersInfo(layerId: number): OlLayerDisplaySettings {
    let result = null;
    for (let layerInfo of Object.values(this.layersInfo)) {
      if (layerInfo.layerId === layerId) {
        result = layerInfo;
        break;
      }
    }

    return result;
  }
}
