<form [formGroup]="contractForm" (ngSubmit)="submitOrder()">
  <div class="modal-header">
    <h5 class="modal-title" i18n>Create contract</h5>
    <button type="button" class="close" aria-label="Close" (click)="cancel()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="errorCode" class="alert alert-danger" role="alert" i18n>
      Error on placing tenant order
    </div>
    <div class="row col-md-12">
      <div class="md-form w-100" i18n>
        Place a request for a personal tenant. A tenant will be created and configured automatically for you.
      </div>
    </div>
    <div class="row col-md-12 mt-2">
        <label for="email" i18n>Tenant contract e-mail</label>
        <input id="email" type="text" class="form-control" [class.is-invalid]="invalid('email')" formControlName="email" name="email" placeholder="E-mail" i18n-placeholder>
        <form-field-errors [control]="contractForm.get('email')"></form-field-errors>
    </div>
  </div>
  <div class="modal-footer">
    <button [disabled]="loading" type="button" class="btn btn-default" (click)="cancel()">Cancel</button>
    <button [disabled]="loading" type="submit" class="btn btn-primary" i18n>Confirm order</button>
  </div>
</form>

