import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {MydataComponent} from './mydata.component';
import {DatapointListManageComponent} from './components/datapoint-list-manage/datapoint-list-manage.component';
import {GroupsComponent} from './components/groups/groups.component';
import {DatapointListParameters} from './components/parameters/datapoint-list-parameters.component';
import {QuestionnairesPageComponent} from './components/questionnaires/questionnaires-page.component';
import {ServicesComponent} from './components/services/services.component';
import {ManagedSourcesComponent} from './components/managed-sources/managed-sources.component';
import {AdviceManagementComponent} from './components/advice-management/advice-management.component';
import {EnergyManagerComponent} from './components/energy-manager/energy-manager.component';
import {DashboardPageComponent} from './components/dashboard/dashboard-page.component';
import {AdvicePageComponent} from './components/advice/advice-page.component';
import {CreateSourcesComponent} from './components/create-sources/create-sources.component';
import {Breadcrumb} from './components/navbar/breadcrumb';
import {GroupDatapointListComponent} from './components/group-datapoint-list/group-datapoint-list.component';
import {IncomingQuestionnairesComponent} from './components/questionnaires/incoming-questionnaires.component';
import {MyQuestionnairesComponent} from './components/questionnaires/my-questionnaires.component';
import {QuestionnaireComponent} from './components/questionnaires/questionnaire/questionnaire.component';
import {SettingsComponent} from './components/settings/settings.component';
import {InviteComponent} from './components/invite/invite.component';
import {CanDeactivateQuestionnaireGuard} from './components/questionnaires/can-deactivate-questionnaire.guard';
import {CanCreateQuestionnaireGuard} from './components/questionnaires/can-create-questionnaire.guard';
import {ComponentsGuard} from './guards/components.guard';
import {ComponentsEnabledGuard} from './guards/component-enabled.guard';
import {MandatePersonConsentTreeComponent} from './components/mandate/mandate-person-consent-tree/mandate-person-consent-tree.component';
import {PersonSeriesGroupResolver} from './resolvers/person-series-group.resolver';
import {ManagePersonSeriesComponent} from './components/manage-person-series/manage-person-series.component';
import {MandateRootComponent} from './components/mandate-root/mandate-root.component';
import {MandatePersonTreeComponent} from './components/mandate/mandate-person-tree/mandate-person-tree.component';
import {GlobalParametersComponent} from './components/parameters/global-parameters.component';
import {DatapointGroupsIndex} from './components/datapoints-groups-index/datapoint-groups-index.component';
import { NotificationAddressesComponent } from './components/settings/notification-addresses/notification-addresses.component';
import { PreferencesComponent } from './components/settings/preferences/preferences.component';
import { DalApiTokenComponent } from './components/settings/dal-api-token/dal-api-token.component';
import { CityPortalInfoComponent } from './components/settings/city-portal-info/city-portal-info/city-portal-info.component';
import {DatapointListRoot} from './components/datapoint-list-root/datapoint-list-root.component';

const routes: Routes = [
  {
    path: '',
    component: MydataComponent,
    children: [
      {path: '', redirectTo: 'energy-manager'},
      {path: 'invite/:inviteuuid', component: InviteComponent},
      {
        path: 'datapoints',
        component: DatapointListRoot,
        data: {breadcrumb: new Breadcrumb($localize`Datapoints`)},
        children: [
          {path: '', component: DatapointGroupsIndex},

          {path: 'list', component: ManagePersonSeriesComponent},

          // Parameters & Manage
          {
            path: 'grouping-tag/parameters', component: DatapointListParameters, data: {
              groupingTag: ''
            }
          },
          {
            path: 'grouping-tag/manage', component: DatapointListManageComponent, data: {
              groupingTag: ''
            }
          },
          {
            path: 'group/manage', component: DatapointListManageComponent
          },
          {
            path: 'group/parameters', component: DatapointListParameters
          },

          {path: 'grouping-tag/:groupingTag/parameters', component: DatapointListParameters},
          {path: 'grouping-tag/:groupingTag/manage', component: DatapointListManageComponent},
          {path: 'grouping-tag/:groupingTag/address/:address/parameters', component: DatapointListParameters, data: {locationType: 'ADDRESS'}},
          {path: 'grouping-tag/:groupingTag/address/:address/manage', component: DatapointListManageComponent, data: {locationType: 'ADDRESS'}},
          {path: 'grouping-tag/:groupingTag/address/:address/:apartment/parameters', component: DatapointListParameters, data: {locationType: 'ADDRESS'}},
          {path: 'grouping-tag/:groupingTag/address/:address/:apartment/manage', component: DatapointListManageComponent, data: {locationType: 'ADDRESS'}},
          {path: 'grouping-tag/:groupingTag/address/:address/:apartment/:room/parameters', component: DatapointListParameters, data: {locationType: 'ADDRESS'}},
          {path: 'grouping-tag/:groupingTag/address/:address/:apartment/:room/manage', component: DatapointListManageComponent, data: {locationType: 'ADDRESS'}},
          {path: 'grouping-tag/:groupingTag/coords/:lat/:lng/parameters', component: DatapointListParameters, data: {locationType: 'COORDS'}},
          {path: 'grouping-tag/:groupingTag/coords/:lat/:lng/manage', component: DatapointListManageComponent, data: {locationType: 'COORDS'}},
          {path: 'grouping-tag/:groupingTag/uuid/:uuid/parameters', component: DatapointListParameters, data: {locationType: 'UUID'}},
          {path: 'grouping-tag/:groupingTag/uuid/:uuid/manage', component: DatapointListManageComponent, data: {locationType: 'UUID'}},

          {
            path: 'group/:groupId/parameters', component: DatapointListParameters, resolve: {
              group: PersonSeriesGroupResolver
            }
          },
          {
            path: 'group/:groupId/manage', component: DatapointListManageComponent, resolve: {
              group: PersonSeriesGroupResolver
            }
          },
          {
            path: 'group/:groupId/address/:address/parameters', component: DatapointListParameters, data: {locationType: 'ADDRESS'}, resolve: {
              group: PersonSeriesGroupResolver
            }
          },
          {
            path: 'group/:groupId/address/:address/manage',
            component: DatapointListManageComponent,
            data: {locationType: 'ADDRESS'},
            resolve: {
              group: PersonSeriesGroupResolver
            }
          },
          {
            path: 'group/:groupId/address/:address/:apartment/parameters',
            component: DatapointListParameters,
            data: {locationType: 'ADDRESS'},
            resolve: {
              group: PersonSeriesGroupResolver
            }
          },
          {
            path: 'group/:groupId/address/:address/:apartment/manage',
            component: DatapointListManageComponent,
            data: {locationType: 'ADDRESS'},
            resolve: {
              group: PersonSeriesGroupResolver
            }
          },
          {
            path: 'group/:groupId/address/:address/:apartment/:room/parameters',
            component: DatapointListParameters,
            data: {locationType: 'ADDRESS'},
            resolve: {
              group: PersonSeriesGroupResolver
            }
          },
          {
            path: 'group/:groupId/address/:address/:apartment/:room/manage',
            component: DatapointListManageComponent,
            data: {locationType: 'ADDRESS'},
            resolve: {
              group: PersonSeriesGroupResolver
            }
          },
          {
            path: 'group/:groupId/coords/:lat/:lng/parameters', component: DatapointListParameters, data: {locationType: 'COORDS'}, resolve: {
              group: PersonSeriesGroupResolver
            }
          },
          {
            path: 'group/:groupId/coords/:lat/:lng/manage',
            component: DatapointListManageComponent,
            data: {locationType: 'COORDS'},
            resolve: {
              group: PersonSeriesGroupResolver
            }
          },
          {
            path: 'group/:groupId/q11e/:q11eId/manage', data: {locationType: 'Q11E'}, component: DatapointListManageComponent, resolve: {
              group: PersonSeriesGroupResolver
            }
          },
          {
            path: 'group/:groupId/uuid/:uuid/parameters', component: DatapointListParameters, data: {locationType: 'UUID'}, resolve: {
              group: PersonSeriesGroupResolver
            }
          },
          {
            path: 'group/:groupId/uuid/:uuid/manage', component: DatapointListManageComponent, data: {locationType: 'UUID'}, resolve: {
              group: PersonSeriesGroupResolver
            }
          },

          {path: 'address/:address/parameters', component: DatapointListParameters, data: {locationType: 'ADDRESS'}},
          {path: 'address/:address/manage', component: DatapointListManageComponent, data: {locationType: 'ADDRESS'}},
          {path: 'address/:address/:apartment/parameters', component: DatapointListParameters, data: {locationType: 'ADDRESS'}},
          {path: 'address/:address/:apartment/manage', component: DatapointListManageComponent, data: {locationType: 'ADDRESS'}},
          {path: 'address/:address/:apartment/:room/parameters', component: DatapointListParameters, data: {locationType: 'ADDRESS'}},
          {path: 'address/:address/:apartment/:room/manage', component: DatapointListManageComponent, data: {locationType: 'ADDRESS'}},
          {path: 'coords/:lat/:lng/parameters', component: DatapointListParameters, data: {locationType: 'COORDS'}},
          {path: 'coords/:lat/:lng/manage', component: DatapointListManageComponent, data: {locationType: 'COORDS'}},
          {path: 'uuid/:uuid/parameters', component: DatapointListParameters, data: {locationType: 'UUID'}},
          {path: 'uuid/:uuid/manage', component: DatapointListManageComponent, data: {locationType: 'UUID'}},
          {path: 'questionnaire/:q11eType/manage', data: {locationType: 'Q11E'}, component: DatapointListManageComponent},
          {path: 'questionnaire/:q11eType/:q11eId/manage', data: {locationType: 'Q11E'}, component: DatapointListManageComponent},

          // Datapoints
          {path: 'grouping-tag', component: GroupDatapointListComponent},
          {path: 'grouping-tag/:groupingTag', component: GroupDatapointListComponent},
          {path: 'grouping-tag/:groupingTag/address', redirectTo: 'grouping-tag/:groupingTag'},
          {path: 'grouping-tag/:groupingTag/address/:address', data: {locationType: 'ADDRESS'}, component: GroupDatapointListComponent},
          {
            path: 'grouping-tag/:groupingTag/address/:address/:apartment',
            data: {locationType: 'ADDRESS'},
            component: GroupDatapointListComponent
          },
          {
            path: 'grouping-tag/:groupingTag/address/:address/:apartment/:room',
            data: {locationType: 'ADDRESS'},
            component: GroupDatapointListComponent
          },
          {path: 'grouping-tag/:groupingTag/coords', redirectTo: 'grouping-tag/:groupingTag'},
          {path: 'grouping-tag/:groupingTag/coords/:lat', redirectTo: 'grouping-tag/:groupingTag'},
          {path: 'grouping-tag/:groupingTag/coords/:lat/:lng', data: {locationType: 'COORDS'}, component: GroupDatapointListComponent},
          {path: 'grouping-tag/:groupingTag/uuid', redirectTo: 'grouping-tag/:groupingTag'},
          {path: 'grouping-tag/:groupingTag/uuid/:uuid', data: {locationType: 'UUID'}, component: GroupDatapointListComponent},

          {path: 'group', component: GroupDatapointListComponent},
          {
            path: 'group/:groupId', component: GroupDatapointListComponent, resolve: {
              group: PersonSeriesGroupResolver
            }
          },
          {
            path: 'group/:groupId/address', redirectTo: 'group/:groupId', resolve: {
              group: PersonSeriesGroupResolver
            }
          },
          {
            path: 'group/:groupId/address/:address', data: {locationType: 'ADDRESS'}, component: GroupDatapointListComponent, resolve: {
              group: PersonSeriesGroupResolver
            }
          },
          {
            path: 'group/:groupId/address/:address/:apartment', data: {locationType: 'ADDRESS'}, component: GroupDatapointListComponent, resolve: {
              group: PersonSeriesGroupResolver
            }
          },
          {
            path: 'group/:groupId/address/:address/:apartment/:room',
            data: {locationType: 'ADDRESS'},
            component: GroupDatapointListComponent,
            resolve: {
              group: PersonSeriesGroupResolver
            }
          },
          {path: 'group/:groupId/coords', redirectTo: 'group/:groupId'},
          {path: 'group/:groupId/coords/:lat', redirectTo: 'group/:groupId'},
          {
            path: 'group/:groupId/coords/:lat/:lng', data: {locationType: 'COORDS'}, component: GroupDatapointListComponent, resolve: {
              group: PersonSeriesGroupResolver
            }
          },
          {path: 'group/:groupId/uuid', redirectTo: 'group/:groupId'},
          {
            path: 'group/:groupId/uuid/:uuid', data: {locationType: 'UUID'}, component: GroupDatapointListComponent, resolve: {
              group: PersonSeriesGroupResolver
            }
          },
          {
            path: 'group/:groupId/q11e/:q11eId', data: {locationType: 'Q11E'}, component: GroupDatapointListComponent, resolve: {
              group: PersonSeriesGroupResolver
            }
          },
          {path: 'address', redirectTo: ''},
          {path: 'address/:address', data: {locationType: 'ADDRESS'}, component: GroupDatapointListComponent},
          {path: 'address/:address/:apartment', data: {locationType: 'ADDRESS'}, component: GroupDatapointListComponent},
          {path: 'address/:address/:apartment/:room', data: {locationType: 'ADDRESS'}, component: GroupDatapointListComponent},
          {path: 'coords', redirectTo: ''},
          {path: 'coords/:lat', redirectTo: ''},
          {path: 'coords/:lat/:lng', data: {locationType: 'COORDS'}, component: GroupDatapointListComponent},
          {path: 'uuid', redirectTo: ''},
          {path: 'uuid/:uuid', data: {locationType: 'UUID'}, component: GroupDatapointListComponent},
          {path: 'questionnaire', redirectTo: ''},
          {path: 'questionnaire/:q11eType', data: {locationType: 'Q11E'}, component: GroupDatapointListComponent},
          {path: 'questionnaire/:q11eType/:q11eId', data: {locationType: 'Q11E'}, component: GroupDatapointListComponent},
          {
            path: 'parameters', data: {
              componentsPath: 'datapoints.parameters'
            },
            component: GlobalParametersComponent,
            canActivate: [ComponentsGuard]
          },
          {path: 'managed-sources', component: ManagedSourcesComponent},
          {
            path: 'create',
            data: {
              componentsPath: 'datapoints.create'
            },
            canActivate: [ComponentsGuard],
            children: [
              {path: '', component: CreateSourcesComponent},
              {path: 'formula-template', redirectTo: ''},
              {
                path: 'formula-template/add',
                component: QuestionnaireComponent,
                data: {
                  breadcrumb: new Breadcrumb($localize`Formula templates`),
                  type: 'FORMULA_SOURCE_TEMPLATE'
                }
              }, {
                path: 'formula-template/:id',
                component: QuestionnaireComponent,
                data: {
                  breadcrumb: new Breadcrumb($localize`Formula templates`),
                  type: 'FORMULA_SOURCE_TEMPLATE'
                }}
            ]
          }
        ],
      },
      {
        path: 'energy-manager', component: EnergyManagerComponent, data: {
          componentName: 'energy-manager',
          breadcrumb: new Breadcrumb($localize`Energy manager`)
        },
        canActivate: [ComponentsEnabledGuard],
      },
      {path: 'dashboard', component: DashboardPageComponent, data: {breadcrumb: new Breadcrumb($localize`Dashboard`)}},
      {
        component: QuestionnairesPageComponent, data: {
          componentName: 'questionnaires',
          breadcrumb: new Breadcrumb($localize`Questionnaires`)
        },
        path: 'questionnaires',
        canActivate: [ComponentsEnabledGuard],
        children: [
          {path: '', component: IncomingQuestionnairesComponent},
          {
            path: 'my-questionnaires',
            children: [
              {path: '', component: MyQuestionnairesComponent},
              {
                path: 'add', component: QuestionnaireComponent,
                canActivate: [CanCreateQuestionnaireGuard],
                canDeactivate: [CanDeactivateQuestionnaireGuard]
              },
              {path: ':id', component: QuestionnaireComponent, canDeactivate: [CanDeactivateQuestionnaireGuard]}
            ]
          },
        ]
      },
      {
        path: 'mandates',
        component: MandateRootComponent,
        children: [
          {
            path: '',
            component: MandatePersonTreeComponent,
            data: {breadcrumb: new Breadcrumb($localize`Mandates`)}
          },
          {
            path: 'consents',
            component: MandatePersonConsentTreeComponent,
            data: {breadcrumb: new Breadcrumb($localize`Mandates`)}
          },
        ]
      },
      {path: 'services', component: ServicesComponent, data: {breadcrumb: new Breadcrumb($localize`Services`)}},
      {
        path: 'settings',
        component: SettingsComponent,
        data: {breadcrumb: new Breadcrumb($localize`Settings`)},
        children: [
          {
            path: '',
            component: NotificationAddressesComponent
          },
          {
            path: 'preferences',
            component: PreferencesComponent
          },
          {
            path: 'dal-api-keys',
            component: DalApiTokenComponent
          },
          {
            path: 'your-city-portal-info',
            component: CityPortalInfoComponent
          },
        ]
      },
      {path: 'groups', component: GroupsComponent, data: {breadcrumb: new Breadcrumb($localize`Groups`)}},
      {
        path: 'manage-advices',
        component: AdviceManagementComponent,
        data: {
          permissions: {
            only: [ 'MANAGE_CITY' ]
          },
          breadcrumb: new Breadcrumb($localize`Manage advices`)
        }
      },
      {path: 'advices', component: AdvicePageComponent, data: {breadcrumb: new Breadcrumb($localize`Advices`)}}
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MydataRoutingModule {
}
