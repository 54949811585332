import {Component, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {CoreConfig, SMARTENCITY_CORE_CONFIG} from '@smartencity/core';
import {LayerDisplaySettings} from './layer-display-settings';
import {Subject} from 'rxjs/internal/Subject';
import {takeUntil} from 'rxjs/operators';
import {ArcGisComponentMapService} from './arc-gis-component-map.service';
import { TallinnArcGisMapService } from './tallinn-arc-gis-map.service';
@Component({
  selector: 'lib-ArcGIS',
  templateUrl: './arc-gis.component.html',
  styles: [
    `.map {
      width: 100%;
      height: 100vh;
    }
    `
  ],
  encapsulation: ViewEncapsulation.None
})
export class ArcGISComponent implements OnInit, OnDestroy {

  private ngDestroy = new Subject<void>();

  @ViewChild('map', {static: true})
  mapEl: any;

  public location: any = null;

  public map: google.maps.Map;

  public layers: any[];

  public cityDistrict: any;

  public displaySettings: LayerDisplaySettings = new LayerDisplaySettings();

  constructor(private http: HttpClient,
              private mapService: ArcGisComponentMapService,
              @Inject(SMARTENCITY_CORE_CONFIG) public config: CoreConfig,
              private tallinnArcGisMapService: TallinnArcGisMapService) { }

  ngOnInit(): void {
    this.mapService.loadMap(this.displaySettings);
    this.mapService.legendLoaded$.pipe(takeUntil(this.ngDestroy)).subscribe((legendInfo: any) => {
      this.layers = legendInfo;
    });

    // this.tallinnArcGisMapService.locationsSelected$.pipe(takeUntil(this.ngDestroy)).subscribe((cityDistrict: any) => {
    //   this.cityDistrict = cityDistrict;
    // });
  }

  toggleLayer(displaySettings: any): void {
    this.displaySettings = Object.assign(this.displaySettings, displaySettings);
    this.mapService.updateDisplaySettings(this.displaySettings);
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }
}

