import moment, {Moment} from 'moment';

export class WidgetPeriodHelper {

  public static calculateStartAtFromNow(periodType: string, periodCount: number): Moment {
    const now = moment();

    return this.calculateStartAt(now, periodType, periodCount);
  }

  public static calculateStartAt(startAt: Moment, periodType: string, periodCount: number): Moment {

    switch (periodType) {
      case 'HOUR': {
        startAt.subtract(periodCount, 'hours');
        break;
      } case 'DAY': {
        startAt.subtract(periodCount, 'days');
        break;
      } case 'MONTH': {
        startAt.subtract(periodCount, 'months');
        break;
      } case 'YEAR': {
        startAt.subtract(periodCount, 'years');
        break;
      }
    }

    return startAt;
  }

}
