<h5>{{roadWeather.name}}</h5>
<table class="table table-hover road-weather-site-table">
  <thead>
    <tr>
      <td>&nbsp;</td>
      <ng-container *ngFor="let indicator of roadWeatherIndicators; let i = index;">
        <td class="text-center">{{indicator.label}}</td>
      </ng-container>
    </tr>
  </thead>
  <tbody>
    <tr class="w-100" *ngFor="let row of data.rows; let i = index;">
      <ng-container *ngFor="let field of filterFields(row.fields); ">
        <td class="text-center">

          <span *ngIf="field.value != null && field.value != 0 && field.code == 'precip'">
            {{field.value}} {{field.unit}}

            <span *ngIf="rowsFieldsMappings[i].get('precip-type')?.value != null">
              <br />
              {{weatherConditionCodeMap[rowsFieldsMappings[i].get('precip-type').value]?.label}}
            </span>
          </span>
          <span *ngIf="field.value == 0 && field.code == 'precip'">
            {{weatherConditionCodeMap[rowsFieldsMappings[i].get('precip-type').value]?.label}}
          </span>
          <span *ngIf="field.value != null && field.code == 'wind-speed'">
            {{field.value}} {{field.unit}}
            <span *ngIf="rowsFieldsMappings[i].get('wind-speed-max')?.value != null">
              <br />
              (max {{rowsFieldsMappings[i].get('wind-speed-max').value}} {{rowsFieldsMappings[i].get('wind-speed-max').unit}})
            </span>
          </span>
          <span *ngIf="field.value != null && field.code !== 'precip' && field.code !== 'wind-speed'">{{field.value}} {{field.unit}}</span>
          <span *ngIf="field.value == null">-</span>
        </td>
      </ng-container>
    </tr>
  </tbody>
  <tfoot>
    <tr >
      <td colspan=3 class="text-left"><span *ngIf="updatedAt"><span i18n>Updated at</span>: {{this.updatedAt|date:'dd.MM.yyyy HH:mm'}}</span></td>
      <td colspan=2 class="text-right"><span i18n>Data from: </span> <a href="https://tik.teeilm.ee/" target="_BLANK"title="TIK">https://tik.teeilm.ee/</a></td>
    </tr>
  </tfoot>
</table>


