import {Component, Input, OnInit} from '@angular/core';
import {AuditLog, LabelPipe} from '@smartencity/core';

@Component({
  selector: 'consent-log-list-item',
  templateUrl: './consent-log-list-item.component.html'
})
export class ConsentLogListItemComponent implements OnInit {

  @Input()
  item: AuditLog;

  constructor(private labelPipe: LabelPipe) {
  }

  ngOnInit(): void {
  }

}
