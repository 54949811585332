<div class="modal-header">
  <h5 class="modal-title w-100" i18n>Set owner</h5>
  <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
    <span class="fal fa-times"></span>
  </button>
</div>
<div class="modal-body">

  <div *ngIf="person.invalid && showErrors" class="alert alert-danger">
    <div *ngIf="person.errors['required']">
      <span i18n>Personal identification or registration code</span>&nbsp;<span i18n>is required</span>
    </div>
    <div *ngIf="person.errors['personRegistrationNumber']">
      <span i18n>Personal identification or registration code</span>&nbsp;<span i18n>is invalid</span>
    </div>
  </div>
  <h3 i18n>Data sources</h3>
  <div class="form-group" *ngFor="let row of rows">
    <div class="checkbox">
      <input type="checkbox" [formControl]="row.checkControl" [id]="id + '-check'">
      <label [attr.for]="id + '-check'">{{row.series.personSeries.name}}</label>
    </div>
  </div>

  <div class="form-group">
    <label i18n>Owner</label>
    <div class="form-row">
        <ng-select
          class="custom"
          [clearable]="true"
          [searchable]="true"
          placeholder="Personal identification or registration code"
          i18n-placeholder
          appendTo="body"
          [formControl]="person"
          [typeahead]="personTypeahead$"
          [items]="personItems$ | async"
          [addTag]="createPerson"
          addTagText="New person with registration number"
          i18n-addTagText
        >
          <ng-template ng-label-tmp let-item="item">
            <span>{{(item.displayName ? '' + item.displayName + ' ' : '') + '(' + (item | regNo) + ')'}}</span>
          </ng-template>
          <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
            <span>{{(item.displayName ? '' + item.displayName + ' ' : '') + '(' + (item | regNo) + ')'}}</span>
          </ng-template>
        </ng-select>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-light btn-sm" aria-label="Close" (click)="close()" i18n>Close</button>
  <button type="button" class="btn btn-primary btn-sm"  (click)="save()" i18n>Save</button>
</div>
