<div class="modal-header">
  <h5 class="modal-title w-100" i18n>Grant access to dashboard</h5>
  <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
    <i class="fal fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <div [formGroup]="consentForm">
    <div class="row" *ngIf="errors.length > 0">
      <div class="col">
        <div class="alert alert-danger">
          <ul>
            <li *ngFor="let errorMessage of errors">{{errorMessage}}</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row">
        <div *ngIf="consentForm.get('person').invalid && showValidation" class="alert alert-danger">
          <div *ngIf="consentForm.get('person').errors['required']">
            <span i18n>Personal identification or registration code</span>&nbsp;<span i18n>is required</span>
          </div>
          <div *ngIf="consentForm.get('person').errors['personRegistrationNumber']">
            <span i18n>Personal identification or registration code</span>&nbsp;<span i18n>is invalid</span>
          </div>
        </div>

        <div class="col-12 form-group">
          <div class="row">
            <div class="col-8">
              <ng-select
                class="custom"
                [clearable]="true"
                [searchable]="true"
                placeholder="Personal identification or registration code"
                i18n-placeholder
                appendTo="body"
                formControlName="person"
                [typeahead]="personTypeahead$"
                [items]="personItems$ | async"
                [addTag]="createPerson"
                [selectOnTab]="true"
                addTagText="New person with registration number"
                i18n-addTagText
              >
                <ng-template ng-label-tmp let-item="item">
                  <span>{{(item.displayName ? '' + item.displayName + ' ' : '') + '(' + (item | regNo) + ')'}}</span>
                </ng-template>
                <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
                  <span>{{(item.displayName ? '' + item.displayName + ' ' : '') + '(' + (item | regNo) + ')'}}</span>
                </ng-template>
              </ng-select>
            </div>
            <div class="col-4 d-flex align-items-center mt-2 mt-md-0">
              <button class="btn btn-sm btn-primary" (click)="createConsents()">
                <i class="fal fa-share"></i>&nbsp;<ng-container i18n>Share</ng-container>
              </button>
            </div>
          </div>
        </div>
    </div>
  </div>

  <div class="row my-2" *ngIf="dashboardConsents.length > 0">
    <div class="col-12">
      <table class="table table-responsive-md modal-table">
        <thead>
          <tr>
            <th i18n>Name</th>
            <th i18n>Registration number</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let consent of dashboardConsents; index as i;">
            <td class="col-4">{{consent.person.displayName}}</td>
            <td class="col-6">{{consent.person.registrationNumber}}</td>
            <td class="col-2">
              <button class="btn btn-danger btn-sm" i18n (click)="endConsent(consent.id)">End sharing</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

