<core-navbar></core-navbar>
<nav class="navbar navbar-expand-lg border-bottom py-3">
  <div class="container-fluid">
    <span class="navbar-brand fw-bold text-uppercase mr-0" i18n>Public data</span>
    <button class="px-4 navbar-toggler" type="button" data-toggle="collapse" data-target="#publicDataNav" aria-controls="publicDataNav" aria-expanded="false" aria-label="Toggle navigation">
      <i class="far fa-bars fa-lg"></i>
    </button>
    <div class="collapse navbar-collapse" id="publicDataNav">
      <ul class="nav mr-auto nav-pills">
        <li class="nav-item active" *ngFor="let dashboardItem of dashboards; let i = index">
          <span [ngClass]="{'active': dashboardItem.id == this.currentDashboard.id}" (click)="selectDashboard(dashboardItem)" class="nav-link">
            <ng-container>{{dashboardItem.name}}</ng-container>
          </span>
        </li>
      </ul>
    </div>
   <div *ngIf="currentDashboard && currentDashboard.publicDownloadAllowed">
     <button class="btn btn-secondary btn-icon btn-sm mr-2 px-3" type="button" (click)="downloadDashboardPdf()" i18n>Download PDF</button>
     <button class="btn btn-secondary btn-icon btn-sm px-3" type="button" (click)="downloadDashboardExcel()" i18n>Download XLS</button>
   </div>
  </div>
</nav>
<div class="container-fluid mt-3">
  <gridster [options]="options" #grid>
    <gridster-item [item]="item" *ngFor="let item of dashboard">
      <mydata-dashboard-widget
        *ngIf="item.itemType == 'DASHBOARD'"
        [widget]="item.widget"
        [rangeFrom]="(range$ | async)?.from"
        [rangeTo]="(range$ | async)?.to"
        [aggregationGroupingType]="aggregationGroupingType$ | async"
        [reloadWidgets]="reloadWidgets"
        [isPublic]="true"
        (loadXls)="loadWidgetExcel(item.widget)"
        (loadPng)="loadWidgetPng(item.widget)"
        [isAllowedDownloads]="currentDashboard && currentDashboard.publicDownloadAllowed"
      ></mydata-dashboard-widget>
    </gridster-item>
  </gridster>
</div>
