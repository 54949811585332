import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { AuthService } from '../../auth/auth.service';
import { NavigationEnd, Router } from '@angular/router';
import { SMARTENCITY_CORE_CONFIG } from '../../injection-tokens';
import { CoreConfig } from '../../core-config.model';
import { GasService } from '../../../../../gas/src/lib/gas.service';
import PerfectScrollbar from 'perfect-scrollbar';
import {ResizeService} from '../../services/resize-service';
import {SCREEN_SIZE} from '../../models/resize';

@Component({
  selector: 'core-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit, OnDestroy, AfterViewInit {
  private ngDestroy = new Subject<void>();

  public isCollapsed = false;
  public basePathPart = '';
  public logoImgSrc = 'assets/img/city_portal_logo.svg';

  @ViewChild('sidebar') sidebar: ElementRef;

  private ps: PerfectScrollbar = null;

  constructor(
    public authService: AuthService,
    public router: Router,
    @Inject(SMARTENCITY_CORE_CONFIG) public config: CoreConfig,
    private gasService: GasService,
    private resizeService: ResizeService
  ) {
    if (config.theme === 'tartu') {
      this.logoImgSrc = 'assets/img/tartu/tartu_logo.svg';
    } else if (config.theme === 'greengas') {
      this.logoImgSrc = 'assets/img/gaas/green_gas.svg';
    } else if (config.theme === 'tallinn') {
      this.logoImgSrc = 'assets/img/tallinn/tallinn_logo.svg';
    } else if (config.theme === 'mainor') {
      this.logoImgSrc = 'assets/img/mainor/ylemiste-logo-black.svg';
    } else if (config.theme === 'elering') {
      this.logoImgSrc = 'assets/img/elering/elering_logo_white.svg';
    }
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const url = event.url.startsWith('/') ? event.url.substring(1) : event.url;
        const parts = url.split('/');
        if (parts) {
          this.basePathPart = parts[0];
        } else {
          this.basePathPart = '';
        }

        this.close();
      }
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  close(): void {
    (<any>$('#sidebar')).collapse('hide');
  }

  public resizeMap(): void {
    this.gasService.resizeMap$.next();
  }

  ngAfterViewInit() {
    this.resizeService.onResize$.subscribe((size) => {
      let isMobile = size != null && size < SCREEN_SIZE.MD;
      if (!isMobile) {
        if (!this.ps) {
          this.ps = new PerfectScrollbar(this.sidebar.nativeElement);
        }

      } else {
        if (this.ps) {
          this.ps.destroy();
          this.ps = null;
        }
      }
    });
  }

}
