import { MeteorologyService } from '../service/meteorology.service';
import { AbstractMapLayer, OlMapWrapper } from '@smartencity/core';
import { WeatherStationOlMarker } from './weather-station-ol-marker';
import { WeatherStation } from '../model/weather-station';

export class WeatherStationOlLayer extends AbstractMapLayer<OlMapWrapper, WeatherStation>{

  public weatherStations: WeatherStation[] = [];

  private meteorologyService: MeteorologyService;

  constructor(weatherStations: WeatherStation[], meteorologyService: MeteorologyService) {
    super();
    this.weatherStations = weatherStations;
    this.meteorologyService = meteorologyService;
  }

  setMap(map: OlMapWrapper): void {
    super.setMap(map);
    this.resetMarkers();
    if (map) {
      this.createMarkers();
    }
  }

  setWeatherStation(selectedStation: WeatherStation): void {
    this.getMarkers().forEach((marker: WeatherStationOlMarker) => {
      marker.setSelected(marker.getData() == selectedStation);
      marker.draw();
    });
  }

  protected resetMarkers(): void {
    let markers = this.getMarkers();
    if (markers.length > 0) {
      for (const marker of markers) {
        marker.setMap(null);
      }
    }
    this.clearMarkers();
  }

  protected createMarkers(): void {
    for (const weatherStation of this.weatherStations) {
      this.addMarker(new WeatherStationOlMarker(weatherStation, this.map, this.meteorologyService));
    }
  }

}
