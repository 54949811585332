<div class="modal-header">
  <h5 class="modal-title w-100" i18n>Notifications</h5>
  <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
    <i class="fal fa-times"></i>
  </button>
</div>
<div class="modal-body pt-0">
  <ul class="navbar-nav navbar-tabs mb-3" *ngIf="!isMobile">
    <li class="nav-item" *ngIf="availableViews.has('person-series')">
      <a class="nav-link" [class.active]="view === 'person-series'" (click)="setView('person-series')"><ng-container i18n>Datapoints</ng-container>&nbsp;({{personSeriesStats.totalCount}})</a>
    </li>
    <li class="nav-item" *ngIf="availableViews.has('person-series-groups')">
      <a class="nav-link" [class.active]="view === 'person-series-groups'" (click)="setView('person-series-groups')"><ng-container i18n>Datapoint groups</ng-container>&nbsp;({{personSeriesGroupStats.totalCount}})</a>
    </li>
    <li class="nav-item" *ngIf="availableViews.has('person-series-meta')">
      <a class="nav-link" [class.active]="view === 'person-series-meta'" (click)="setView('person-series-meta')"><ng-container i18n>Metadata</ng-container>&nbsp;({{metaStats.count}})</a>
    </li>
    <li class="nav-item" *ngIf="availableViews.has('errors')">
      <a class="nav-link" [class.active]="view === 'errors'" (click)="setView('errors')"><ng-container i18n>Errors</ng-container>&nbsp;({{errorStats.count}})</a>
    </li>    
    <li class="nav-item" *ngIf="availableViews.has('cancellations')">
      <a class="nav-link" [class.active]="view === 'cancellations'" (click)="setView('cancellations')"><ng-container i18n>Series cancellations</ng-container>&nbsp;({{pendingCancellationsStats.count}})</a>
    </li>
  </ul>

  <div *ngIf="view === 'person-series' && !isMobile">
    <ul class="list-group d-inline-flex list-group-flush w-100">
      <li *ngFor="let item of pendingSeries" class="list-group-item d-flex justify-content-between">
        <mydata-person-series-row class="d-flex w-100" [item]="item" [parentProgress]="psProgress"></mydata-person-series-row>
      </li>
    </ul>

    <div class="text-center mt-3" *ngIf="(pendingPersonSeriesPage.page + 1) < pendingPersonSeriesPage.totalPages && !isMobile">
      <button type="button" class="btn btn-sm btn-primary" (click)="loadMorePendingSeries()">
        <ng-container i18n>Load more ({{pendingPersonSeriesPage.totalElements - (pendingPersonSeriesPage.page + 1) * pendingPersonSeriesPage.size}})</ng-container>
      </button>
    </div>
  </div>
  <div *ngIf="view === 'person-series-groups' && !isMobile">
    <ul class="list-group d-inline-flex list-group-flush w-100">
      <li *ngFor="let item of pendingSeriesGroups" class="list-group-item d-flex justify-content-between">
        <mydata-pending-group-consent-row class="d-flex w-100" [item]="item" [parentProgress]="psProgress"></mydata-pending-group-consent-row>
      </li>
    </ul>
  </div>
  <div *ngIf="view === 'person-series-meta' && !isMobile">
    <ul class="list-group d-inline-flex list-group-flush w-100">
      <li *ngFor="let item of pendingSeriesMeta" class="list-group-item d-flex justify-content-between">
        <mydata-person-series-meta-row class="d-flex w-100" [item]="item" [parentProgress]="psMetaProgress"></mydata-person-series-meta-row>
      </li>
    </ul>
  </div>
  <div *ngIf="view === 'errors' && !isMobile">
    <ul class="list-group d-inline-flex list-group-flush w-100">
      <li *ngFor="let item of pendingErrors" class="list-group-item">
        <mydata-error-row class="d-flex w-100" [item]="item" [parentProgress]="errorProgress"></mydata-error-row>
      </li>
    </ul>
  </div>  
  <div *ngIf="view === 'cancellations' && !isMobile">
    <ul class="list-group d-inline-flex list-group-flush w-100">
      <li *ngFor="let item of pendingCancellations" class="list-group-item d-flex justify-content-between">
        <mydata-cancellation-row class="d-flex w-100" [item]="item" [parentProgress]="psProgress"></mydata-cancellation-row>
      </li>
    </ul>
  </div>

  <div id="notifications" class="mt-3" *ngIf="isMobile">
    <div class="card" *ngIf="availableViews.has('person-series')" id="personSeriesHeading">
      <div class="card-header">
        <h5 class="mb-0">
          <a class="collapsed" data-toggle="collapse" data-target="#personSeries" aria-expanded="false" aria-controls="personSeries" (click)="setView('person-series')"><ng-container i18n>Datapoints</ng-container>&nbsp;({{personSeriesStats.totalCount}})</a>
        </h5>
      </div>
      <div id="personSeries" class="collapse" aria-labelledby="personSeriesHeading" data-parent="#notifications">
        <div class="card-body">
            <ul class="list-group d-inline-flex list-group-flush w-100">
              <li *ngFor="let item of pendingSeries" class="list-group-item d-flex justify-content-between">
                <mydata-person-series-row class="d-flex w-100" [item]="item" [parentProgress]="psProgress"></mydata-person-series-row>
              </li>
            </ul>
        
            <div class="text-center mt-3" *ngIf="(pendingPersonSeriesPage.page + 1) < pendingPersonSeriesPage.totalPages && !isMobile">
              <button type="button" class="btn btn-sm btn-primary" (click)="loadMorePendingSeries()">
                <ng-container i18n>Load more ({{pendingPersonSeriesPage.totalElements - (pendingPersonSeriesPage.page + 1) * pendingPersonSeriesPage.size}})</ng-container>
              </button>
            </div>
        </div>
      </div>
    </div>
    <div class="card" *ngIf="availableViews.has('person-series-groups')">
      <div class="card-header" id="datapointGroupsHeading">
        <h5 class="mb-0">
          <a class="collapsed" data-toggle="collapse" data-target="#datapointGroups" aria-expanded="false" aria-controls="datapointGroups" (click)="setView('person-series-groups')"><ng-container i18n>Datapoint groups</ng-container>&nbsp;({{personSeriesGroupStats.totalCount}})</a>
        </h5>
      </div>
      <div id="datapointGroups" class="collapse" aria-labelledby="datapointGroupsHeading" data-parent="#notifications">
        <div class="card-body">
            <ul class="list-group d-inline-flex list-group-flush w-100">
              <li *ngFor="let item of pendingSeriesGroups" class="list-group-item d-flex justify-content-between">
                <mydata-pending-group-consent-row class="d-flex w-100" [item]="item" [parentProgress]="psProgress"></mydata-pending-group-consent-row>
              </li>
            </ul>
        </div>
      </div>
    </div>
    <div class="card" *ngIf="availableViews.has('person-series-meta')">
      <div class="card-header" id="personSeriesMetaHeading">
        <h5 class="mb-0">
          <a class="collapsed" data-toggle="collapse" data-target="#personSeriesMeta" aria-expanded="false" aria-controls="personSeriesMeta" (click)="setView('person-series-meta')"><ng-container i18n>Metadata</ng-container>&nbsp;({{metaStats.count}})</a>
        </h5>
      </div>
      <div id="personSeriesMeta" class="collapse" aria-labelledby="personSeriesMetaHeading" data-parent="#notifications">
        <div class="card-body">
            <ul class="list-group d-inline-flex list-group-flush w-100">
              <li *ngFor="let item of pendingSeriesMeta" class="list-group-item d-flex justify-content-between">
                <mydata-person-series-meta-row class="d-flex w-100" [item]="item" [parentProgress]="psMetaProgress"></mydata-person-series-meta-row>
              </li>
            </ul>
        </div>
      </div>
    </div>
    <div class="card" *ngIf="availableViews.has('errors')">
      <div class="card-header" id="errorsHeading">
        <h5 class="mb-0">
          <a class="collapsed" data-toggle="collapse" data-target="#errors" aria-expanded="false" aria-controls="errors" (click)="setView('errors')"><ng-container i18n>Errors</ng-container>&nbsp;({{errorStats.count}})</a>
        </h5>
      </div>
      <div id="errors" class="collapse" aria-labelledby="errorsHeading" data-parent="#notifications">
        <div class="card-body">
            <ul class="list-group d-inline-flex list-group-flush w-100">
              <li *ngFor="let item of pendingErrors" class="list-group-item d-flex justify-content-between">
                <mydata-error-row class="d-flex w-100" [item]="item" [parentProgress]="errorProgress"></mydata-error-row>
              </li>
            </ul>
        </div>
      </div>
    </div>
    <div class="card" *ngIf="availableViews.has('cancellations')">
      <div class="card-header" id="cancellationsHeading">
        <h5 class="mb-0">
          <a class="collapsed" data-toggle="collapse" data-target="#cancellations" aria-expanded="false" aria-controls="cancellations" (click)="setView('cancellations')"><ng-container i18n>Series cancellations</ng-container>&nbsp;({{pendingCancellationsStats.count}})</a>
        </h5>
      </div>
      <div id="cancellations" class="collapse" aria-labelledby="cancellationsHeading" data-parent="#notifications">
        <div class="card-body">
          <ul class="list-group d-inline-flex list-group-flush w-100">
            <li *ngFor="let item of pendingCancellations" class="list-group-item d-flex justify-content-between">
              <mydata-cancellation-row class="d-flex w-100" [item]="item" [parentProgress]="psProgress"></mydata-cancellation-row>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer" *ngIf="view === 'person-series'">
  <button *ngIf="hasCancelledPersonSeries" (click)="acknowledgeAllPersonSeries()" type="button" class="btn btn-sm btn-danger float-right" [ladda]="psProgress != null" i18n>
    <span class="ladda-label">Mark all as read</span>
  </button>
  <button type="button" class="btn btn-sm btn-danger float-right" (click)="declineAllPersonSeries()" [ladda]="psProgress != null" i18n>Decline all</button>
  <button type="button" class="btn btn-sm btn-success float-right" (click)="acceptAllPersonSeries()" [ladda]="psProgress != null" i18n>Accept all</button>
</div>
<div class="modal-footer" *ngIf="view === 'person-series-meta'">
  <button type="button" class="btn btn-rounded btn-sm btn-danger" (click)="declineAllPersonSeriesMeta()" [ladda]="psMetaProgress != null" i18n>Decline all</button>
  <button type="button" class="btn btn-rounded btn-sm btn-success" (click)="acceptAllPersonSeriesMeta()" [ladda]="psMetaProgress != null" i18n>Accept all</button>
</div>
<div class="modal-footer" *ngIf="view === 'cancellations'">
  <button type="button" class="btn btn-rounded btn-sm btn-secondary" (click)="declineAllPendingCancellations()" [ladda]="psProgress != null" i18n>Decline all cancellations</button>
  <button type="button" class="btn btn-rounded btn-sm btn-primary" (click)="acceptAllPendingCancellations()" [ladda]="psProgress != null" i18n>Accept all cancellations</button>
</div>
<div class="modal-footer" *ngIf="view === 'errors'">
  <button (click)="acknowledgeAllErrors()" type="button" class="btn btn-sm btn-danger float-right" [ladda]="errorProgress != null" i18n>Mark all as read</button>
</div>
