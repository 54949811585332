import Map = google.maps.Map;
import { AirQualityStation } from "../model/air-quality-station";
import {AirQualityStationGmapMarkerWrapper} from './air-quality-station-gmap-marker-wrapper';
import {TallinnService} from '../service/tallinn.service';
import {AbstractMapLayer} from '@smartencity/core';

export class AirQualityStationsGmapLayer extends AbstractMapLayer<Map, AirQualityStation>{

  public airQualityStations: AirQualityStation[] = [];

  private tallinnService: TallinnService;

  constructor(airQualityStations: AirQualityStation[], tallinnService: TallinnService) {
    super();
    this.airQualityStations = airQualityStations;
    this.tallinnService = tallinnService;
  }

  setMap(map: Map): void {
    super.setMap(map);
    this.resetMarkers();
    if (map) {
      this.createMarkers();
    }
  }

  setAirQualityStation(selectedStation: AirQualityStation): void {
    this.getMarkers().forEach((marker: AirQualityStationGmapMarkerWrapper) => {
      marker.setSelected(marker.getData() == selectedStation);
      marker.draw();
    });
  }

  protected resetMarkers(): void {
    let markers = this.getMarkers();
    if (markers.length > 0) {
      for (const marker of markers) {
        marker.setMap(null);
      }
    }
    this.clearMarkers();
  }

  protected createMarkers(): void {
    for (const airQualityStation of this.airQualityStations) {
      this.addMarker(new AirQualityStationGmapMarkerWrapper(
        airQualityStation,
        this.map,
        null,
        this.tallinnService
      ));
    }
  }

}
