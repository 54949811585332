import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {Subject} from 'rxjs/internal/Subject';
import {GroupSourcePersonSeriesItem} from '../groups-edit.service';
import {ValidationErrors} from '@angular/forms';
import {SourceUpdateOptions} from '../../../models/source-update-options';

@Component({
  selector: 'mydata-groups-person-series-row',
  templateUrl: './groups-person-series-row.component.html',
  providers: []
})
export class GroupsPersonSeriesRowComponent implements OnInit, OnDestroy, OnChanges {
  private ngDestroy = new Subject<void>();

  @Input()
  groupSourcePersonSeriesItem: GroupSourcePersonSeriesItem;

  @Input()
  commonUnit: string;

  @Output('remove')
  removeEmitter = new EventEmitter<void>();

  public windowOperationTypeOptions = SourceUpdateOptions.groupSourceWindowOperationTypeOptions;

  constructor() {}

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['groupSourcePersonSeriesItem']) {
      const latestValue = this.groupSourcePersonSeriesItem.personSeries.latestValue;
      if (latestValue && latestValue.measurement) {
        this.groupSourcePersonSeriesItem.rowFormGroup.get('unit').setValue(latestValue.measurement.unit);
      }
    }

    if (this.groupSourcePersonSeriesItem.rowFormGroup.get('unit').value && this.groupSourcePersonSeriesItem.rowFormGroup.get('unit').value === this.commonUnit) {
      this.groupSourcePersonSeriesItem.rowFormGroup.get('coefficient').setValue(1);
    }
  }

  remove(): void {
    this.removeEmitter.emit();
  }

  control(name?: string) {
    if (!name) {
      return this.groupSourcePersonSeriesItem.rowFormGroup;
    }
    return this.groupSourcePersonSeriesItem.rowFormGroup.get(name);
  }

  invalid(name: string, formGroup?) {
    if (!formGroup) {
      formGroup = this.groupSourcePersonSeriesItem.rowFormGroup;
    }
    const control = formGroup.get(name);
    return control && control.invalid && (control.dirty || control.touched);
  }

  errors(name: string): ValidationErrors {
    const control = this.groupSourcePersonSeriesItem.rowFormGroup.get(name);
    return control ? control.errors : null;
  }

}
