import {NgModule} from '@angular/core';
import {PeopleComponent} from './people.component';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {MatSliderModule} from '@angular/material/slider';
import {CoreModule} from '../../../core/src/lib/core.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    CoreModule,
    MatSliderModule
  ],
  declarations: [
    PeopleComponent
  ]
})
export class PeopleModule { }
