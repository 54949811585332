<div class="row d-flex" *ngIf="item">
  <div class="col text-break">{{item.person.displayName}} <span *ngIf="item.person.registrationNumber != item.user.personalId">({{item.user.displayName}})</span></div>
  <div class="col">{{'ACTION_' + item.operation|label}}</div>
  <div class="col text-break">{{item.targetUser.displayName ? item.targetUser.displayName : item.targetUser.personalId}}</div>
  <div class="col">{{item.loggedAt|date:'dd.MM.yyyy HH:mm:ss'}}</div>
  <div class="col">
    <span *ngIf="item.meta?.createdByUser"><span i18n>Mandated by {{item.meta.createdByUser.displayName}}</span></span>
    <span *ngIf="item.meta?.permissions"><span i18n>Permissions:</span> {{getPermissions(item.meta.permissions)}}</span>
  </div>
</div>
