import {LocationType, PersonSeries} from './series';

export class PersonSeriesMetaProperties {
  name: string;
  groupingTag: string;
  seriesType: string;
  locationType: LocationType;
  adsOid: string;
  address: string;
  apartment: string;
  room: string;
  lat: number;
  lng: number;
  uuid: string;
}

export class GroupSourcePersonSeries {
  id: number;
  personSeries: PersonSeries;
  unit: string;
  coefficient: number;
  createdAt: string;
  updatedAt: string;
  overdue = false;
  windowOperationType = 'OFFSET';
}

export class GroupSource {
  id: number;
  metaProperties: PersonSeriesMetaProperties;
  personSeriesList: GroupSourcePersonSeries[];
  unit: string;
  targetTenantId: number;
  samplingType: string;
  aggregationType: string;
  generateHistory: boolean;
  createdAt: string;
  updatedAt: string;
  personSeriesId: number;
}

export class GroupSourceListItem {
  id: number;
  metaProperties: PersonSeriesMetaProperties;
  personSeriesCount: number;
  unit: string;
  targetTenantId: number;
  samplingType: string;
  aggregationType: string;
  generateHistory: boolean;
  createdAt: string;
  updatedAt: string;
  personSeriesId: number;
  updateErrorCount: number;
}
