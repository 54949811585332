import {GenericUriBuilder} from './generic-uri-builder';

export class NoopRedirectUriBuilder extends GenericUriBuilder {

  constructor() {
    super('[no url]');
  }

  processBuildParams(params?: any) {
  }

}
