<div class="container-fluid p-4">
  <div class="row">
    <div *ngFor="let application of applications" class="card-wrapper d-flex align-items-stretch card-simple">
      <div class="card">
        <div class="card-body">
          <h4>{{application.application.name}}</h4>
          <strong class="mb-3">{{application.application.description}}</strong>
          <p class="mb-2">
            <small class="text-muted">Status:
              <span *ngIf="application.confirmationStatus == 'PENDING'" class="text-muted" i18n>Available</span>
              <span *ngIf="application.confirmationStatus == 'ACCEPTED'" class="text-success" i18n>Active</span>
              <span *ngIf="application.confirmationStatus == 'DECLINED'" class="text-muted" i18n>Cancelled</span>
              <span *ngIf="application.confirmationStatus == 'WAITING_RESOLUTION'" class="text-green" i18n>Waiting resolution</span>
              <span *ngIf="application.confirmationStatus == 'REJECTED'" class="text-danger" i18n>Error</span>

              <ng-container *ngIf="application.confirmationStatus == 'REJECTED' && application.reason">
                <ng-template #tooltip>
                  <span i18n>{{application.reason}}</span>
                </ng-template>
                <button class="btn btn-icon mr-1" [tooltip]="tooltip">
                  <i class="fa fa-info-circle btn-tooltip"></i>
                </button>
              </ng-container>
            </small>
          </p>
          <p class="mb-2"><small class="text-muted">
            <span i18n>Updated at:</span> {{application.updatedAt | date: 'd.MM.yyyy'}}</small></p>
        </div>

        <div *ngIf="application.confirmationStatus == 'PENDING' || application.confirmationStatus == 'DECLINED' || application.confirmationStatus == 'REJECTED'" class="rounded-bottom text-center">
          <button (click)="startApplication(application)" class="btn btn-primary btn-sm" type="button" i18n>Connect</button>
        </div>

        <div (click)="startApplication(application)" *ngIf="application.confirmationStatus == 'PENDING'" class="card-ribbon"><span i18n>Connect</span></div>
        <div (click)="declineApplication(application)" *ngIf="application.confirmationStatus == 'ACCEPTED'" class="rounded-bottom text-center">
          <button class="btn btn-primary btn-sm" type="button" i18n>Cancel service</button>
        </div>
      </div>
    </div>
  </div>
</div>
