<div class="container-fluid">
  <div class="row">
    <div class="col-md-12 mt-4">
      <form *ngIf="!loading" class="block mb-4" [formGroup]="form" (ngSubmit)="save()">
        <div class="row">
          <div class="col-12">
            <h3 class="section-title text-black mb-3" i18n>Preferences</h3>
          </div>
        </div>

        <div class="row" *ngIf="cityUserMandate">
          <div class="col-3">
            <div class="form-group">
              <div class="form-row">
                <div class="col">
                  <label for="email" i18n>Feedback e-mail</label>
                  <input id="email" type="text" class="form-control" [class.is-invalid]="invalid('email')" formControlName="email" name="email" placeholder="E-mail" i18n-placeholder>
                  <small *ngIf="invalid('email') && errors('email')?.required" class="invalid-feedback d-block" i18n>Required</small>
                  <small *ngIf="invalid('email') && errors('email')?.email" class="invalid-feedback d-block" i18n>Invalid e-mail</small>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="m-2">
              <div class="custom-control custom-switch mt-2">
                <input type="checkbox" class="custom-control-input" [id]="id + '-enable-questionnaires-checkbox'" formControlName="questionnairesEnabled">
                <label class="custom-control-label mr-4" [attr.for]="id + '-enable-questionnaires-checkbox'" i18n>Enable questionnaires</label>
              </div>
            </div>
          </div>
          <div class="col-12" *ngIf="form.get('questionnairesEnabled').value">
            <div class="m-2">
              <div class="custom-control custom-switch mt-2">
                <input type="checkbox" class="custom-control-input" [id]="id + '-enable-create-questionnaires-checkbox'" formControlName="questionnairesCreateEnabled">
                <label class="custom-control-label mr-4" [attr.for]="id + '-enable-create-questionnaires-checkbox'" i18n>Enable creation of questionnaires</label>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="m-2">
              <div class="custom-control custom-switch mt-2">
                <input type="checkbox" class="custom-control-input" [id]="id + '-enable-em-dashboard-checkbox'" formControlName="emDashboardEnabled">
                <label class="custom-control-label mr-4" [attr.for]="id + '-enable-em-dashboard-checkbox'" i18n>Enable energy manager dashboard</label>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-12">
            <button type="submit" class="btn btn-primary btn-sm" i18n>Save</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>