import {Component, EventEmitter, Inject, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {
  CoreConfig,
  LoggerService,
  Person,
  PersonRegistrationNumberValidator,
  PersonSeriesGroup, PersonSeriesGroupConsent,
  SMARTENCITY_CORE_CONFIG,
  UserService,
  ErrorResponseHelper, PersonSeriesGroupConsentRequest, PreventCloseAware
} from '@smartencity/core';
import {Subject} from 'rxjs/internal/Subject';
import {debounceTime, distinctUntilChanged, map, switchMap, takeUntil} from 'rxjs/operators';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {ToastrService} from 'ngx-toastr';
import moment from 'moment';
import {GroupShareData} from '../../../models/datapoint-group/group-share-data';
import {PersonSeriesGroupApiService} from '../../../http/person-series-group-api.service';

@Component({
  selector: 'app-datapoint-group-share-person-modal',
  templateUrl: './datapoint-group-share-person-modal.component.html'
})
export class DatapointGroupSharePersonModalComponent implements OnInit, PreventCloseAware {

  private ngDestroy = new Subject<void>();

  @Input()
  public group: PersonSeriesGroup;

  @Input()
  public shareData: GroupShareData;

  @ViewChild('regNoTemplate', {static: true})
  regNoTemplate: TemplateRef<any>;

  @ViewChild('cprDobTemplate', {static: true})
  cprDobTemplate: TemplateRef<any>;

  personTemplate = null;

  consentForm: FormGroup;

  personTypeahead$ = new Subject<string>();

  public showValidation = false;

  addConsentProgress = false;

  personItems$ = this.personTypeahead$.pipe(
    takeUntil(this.ngDestroy),
    distinctUntilChanged(),
    debounceTime(250),
    switchMap((term: string) => this.userService.searchPersons(term)),
    map((persons: Person[]) => {
      return persons.map((e) => ({
        id: e.id,
        countryCode: e.countryCode,
        registrationNumber: e.registrationNumber,
        displayName: e.displayName,
        juridical: e.juridical,
        idPrivacyType: e.idPrivacyType,
        dob: e.dob
      }));
    })
  );

  public createPerson = (registrationNumber: string) => {
    return {
      id: null,
      countryCode: this.config.defaultCountryCode,
      registrationNumber: registrationNumber,
      displayName: null
    };
  };

  @Output('save')
  saveEmitter: EventEmitter<any> = new EventEmitter<any>();

  constructor(@Inject(SMARTENCITY_CORE_CONFIG) private config: CoreConfig,
              public modalRef: BsModalRef,
              public toastr: ToastrService,
              private personSeriesGroupService: PersonSeriesGroupApiService,
              private userService: UserService,
              private loggerService: LoggerService,
              public personRegistrationNumberValidator: PersonRegistrationNumberValidator) {
    this.personTemplate = this.regNoTemplate;
  }

  ngOnInit(): void {
    this.buildForm();
    if (this.config.personRegNoTemplate === 'cpr-dob') {
      this.personTemplate = this.cprDobTemplate;
    }

    this.personTypeahead$.pipe(takeUntil(this.ngDestroy)).subscribe((value) => {
      this.consentForm.markAsDirty();
      if (value) {
        const person = this.createPerson(value);
        this.consentForm.get('person').setValue(person);
      }
    });
  }


  addConsent(): void {
    if (this.addConsentProgress) {
      return;
    }

    this.addConsentProgress = true;
    this.consentForm.markAsDirty();
    if (!this.consentForm.valid) {
      console.error('Form is invalid', this.consentForm);
      this.showValidation = true;
      this.addConsentProgress = false;
      return;
    }

    let formData = this.consentForm.getRawValue();
    const data: PersonSeriesGroupConsentRequest = {
      id: formData.person.id,
      groupId: this.group ? this.group.id : null,
      countryCode: formData.person.countryCode ? formData.person.countryCode.trim() : this.config.defaultCountryCode,
      registrationNumber: formData.person.registrationNumber ? formData.person.registrationNumber.trim() : null,
      personSeriesIds: this.shareData.personSeriesIds,
      excludePersonSeriesIds: this.shareData.excludePersonSeriesIds,
      startAt: null, // UNMAPPED_TIME ja võtab kogu ajaloo
      customStartAt: formData.customStartAt,
      periodStartType: (formData.startFromLastDataOwnerPeriod ? 'UNMAPPED_TIME' : 'CUSTOM_TIME'),
      confirmSendUpdates: this.shareData.confirmSendUpdates
    };

    this.personSeriesGroupService.addConsent(data).subscribe(
      (consentResponse: PersonSeriesGroupConsent) => {
        this.group = consentResponse.ownerGroup;
        this.toastr.success($localize`Consent added`);
        this.showValidation = false;
        this.resetFormState();
        this.close();
      },
      (err: any) => {
        let errorMessage = ErrorResponseHelper.getErrorReason(err);
        if (errorMessage) {
          this.toastr.error(errorMessage);
        } else {
          this.loggerService.error(err);
          this.toastr.error($localize`Error saving consent!`);
        }

        this.addConsentProgress = false;
      });
  }

  close(): void {
    this.modalRef.hide();
  }

  isPreventClose(): boolean {
    return this.consentForm && this.consentForm.dirty;
  }

  private buildForm(): void {
    this.consentForm = new FormGroup({
      person: new FormControl(null, [Validators.required, this.personRegistrationNumberValidator.check.bind(this.personRegistrationNumberValidator)]),
      customStartAt: new FormControl(moment().startOf('day').toDate()),
      startFromLastDataOwnerPeriod: new FormControl(false)
    });
  }

  private resetFormState(): void {
    this.consentForm.markAsPristine();
    this.consentForm.markAsUntouched();
  }

}
