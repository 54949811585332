import { NgModule } from "@angular/core";
import { GmapTallinnComponent } from "./component/gmap-tallinn.component";
import { CommonModule, DatePipe } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { SearchComponent } from "./component/search/search.component";
import { NoiseLevelHeatmapComponent } from "./component/noise-level-heatmap/noise-level-heatmap.component";
import { MatSliderModule } from "@angular/material/slider";
import { CoreModule } from "@smartencity/core";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { TallinnRoutingModule } from "./tallinn-routing.module";
import { AirQualityStationModalComponent } from "./component/air-quality-station-modal/air-quality-station-modal.component";
import { TallinnLayerFiltersComponent } from "./component/tallinn-layer-filters/tallinn-layer-filters.component";
import { TallinnComponent } from "./component/tallinn.component";
import { OlTallinnComponent } from "./component/ol-tallinn.component";
import { ArcGISModule } from "projects/arc-gis/src/public-api";
import { TallinnObjectsModalComponent } from "./component/tallinn-objects-modal/tallinn-objects-modal.component";

@NgModule({
  imports: [
    CommonModule,
    TallinnRoutingModule,
    FormsModule,
    CoreModule,
    NgSelectModule,
    MatSliderModule,
    TooltipModule,
    ArcGISModule,
  ],
  declarations: [
    SearchComponent,
    NoiseLevelHeatmapComponent,
    AirQualityStationModalComponent,
    TallinnLayerFiltersComponent,
    TallinnComponent,
    GmapTallinnComponent,
    OlTallinnComponent,
    TallinnObjectsModalComponent
  ],
  providers: [DatePipe],
})
export class TallinnModule {}
