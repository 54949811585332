import { Inject, OnDestroy, Injectable } from '@angular/core';
import {PersonApplication, AuthService, UserService} from '@smartencity/core';
import {HttpClient} from '@angular/common/http';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {SMARTENCITY_MYDATA_CONFIG} from '../injection-tokens';
import {MyDataConfig} from '../mydata-config.model';
import {AcceptApplicationsComponent} from '../components/modal/accept-applications.component';
import {Subject} from 'rxjs/internal/Subject';
import {takeUntil} from 'rxjs/operators';

@Injectable()
export class ApplicationService implements OnDestroy {
  private ngDestroy = new Subject<void>();

  public modalRef: NgbModalRef = null;

  constructor(
    @Inject(SMARTENCITY_MYDATA_CONFIG) private config: MyDataConfig,
    public authService: AuthService,
    public userService: UserService,
    private http: HttpClient,
    private modalService: NgbModal
  ) {

  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  checkApplications() {
    this.userService.getPendingApplications().pipe(takeUntil(this.ngDestroy)).subscribe(
      (data: PersonApplication[]) => {
        const applications = data.filter((e) => e.application.confirmationType === 'SIMPLE');
        if (applications.length > 0) {
          this.showModal(data.filter((e) => e.application.confirmationType === 'SIMPLE'));
        }
      },
      (err: any) => {
        console.error(err);
      }
    );
  }

  public showModal(applications: any) {
    if (this.modalRef == null) {
      this.modalRef = this.modalService.open(AcceptApplicationsComponent, { backdrop: true});
      this.modalRef.result.then((tenant) => {
        this.modalRef = null;
      }, (reason) => {
        this.modalRef = null;
      });
    }

    this.modalRef.componentInstance.pendingApplications = applications;
  }
}
