<div class="fixed-action-btn mx-2 d-none d-md-block"  *ngIf="count$ | async">
  <a class="btn btn-sm btn-success" style="pointer-events: all;" (click)="compare()">
    <span class="text-center strong mr-2">{{(locationsCount$ | async) + (buildingsCount$ | async) + (personSeriesCount$ | async)}}</span>
    <span i18n>Compare</span>
  </a>
</div>


<div class="d-block d-md-none" *ngIf="count$ | async">
  <a class="btn btn-sm btn-success  btn-floating-mobile" style="pointer-events: all;" (click)="compare()">
    <span class="text-center strong">{{(locationsCount$ | async) + (buildingsCount$ | async) + (personSeriesCount$ | async)}}</span>
  </a>
</div>