<div class="row">
  <div class="col mt-2 mb-2 text-right">
    <button type="button" class="btn btn-sm btn-primary mr-2" (click)="bulkEdit()" [hidden]="!isBulkActionEnabled(bulkActionEnum.BULK_EDIT)">
      <i class="fa fa-edit"></i>&nbsp;<ng-container i18n>Bulk edit</ng-container>&nbsp;({{selectedEditable.length}})
    </button>
    <button type="button" class="btn btn-sm btn-danger mr-2" (click)="bulkDecline()" [hidden]="!isBulkActionEnabled(bulkActionEnum.BULK_DECLINE)">
      <i class="fa fa-times"></i>&nbsp;<ng-container i18n>Decline selected</ng-container>&nbsp;({{selectedDeclineable.length}})
    </button>
    <button type="button" class="btn btn-sm btn-outline-danger mr-2" (click)="bulkArchive()" [hidden]="!isBulkActionEnabled(bulkActionEnum.BULK_ARCHIVE)">
      <i class="fa fa-trash"></i>&nbsp;<ng-container i18n>Archive selected</ng-container>&nbsp;({{selectedArchiveable.length}})
    </button>
    <button type="button" class="btn btn-sm btn-secondary mr-2" (click)="searchGroup()" [hidden]="!isBulkActionEnabled(bulkActionEnum.ADD_TO_GROUP)"><i class="fal fa-plus"></i>&nbsp;
      <ng-container i18n>Add to datagroup</ng-container>&nbsp;({{selectedEditable.length}})
    </button>
    <button type="button" class="btn btn-sm btn-secondary mr-2" (click)="createConsents()" *ngIf="this.isBulkActionEnabled(bulkActionEnum.SHARE)">
      <ng-container i18n>Share data points</ng-container>&nbsp;({{selectedShareable.length}})
    </button>
  </div>
</div>
<div class="row">
  <div class="col-12">
    <loading-spinner *ngIf="!rows"></loading-spinner>
    <datapoint-table [rows]="rows" [page]="page" (rowAction)="onRowAction($event)" (tableAction)="onTableAction($event)" (rowsSelect)="onRowsSelect($event)"></datapoint-table>
  </div>
</div>
