import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, ErrorHandler, InjectionToken, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

import {NgSelectModule} from '@ng-select/ng-select';
import {NgxPermissionsModule} from 'ngx-permissions';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {environment} from '../environments/environment';
import {
  CoreModule,
  SMARTENCITY_CORE_CONFIG,
  LoggerService,
  ErrorHandlerService,
  ResizeService,
  CoreConfig,
  AuthService
} from '@smartencity/core';
import {BuildingsModule, SMARTENCITY_BUILDINGS_CONFIG} from '@smartencity/buildings';
import {MobilityModule, SMARTENCITY_MOBILITY_CONFIG} from '@smartencity/mobility';
import {LightsModule, SMARTENCITY_LIGHTS_CONFIG} from '@smartencity/lights';
import {PeopleModule, SMARTENCITY_PEOPLE_CONFIG} from '@smartencity/people';
import {SMARTENCITY_GAS_CONFIG} from '@smartencity/gas';
import {SMARTENCITY_TALLINN_CONFIG} from '@smartencity/tallinn';
import {WeatherModule} from '@smartencity/weather';
import {MeteorologyModule, SMARTENCITY_METEOROLOGY_CONFIG} from '@smartencity/meteorology';

import {CommonModule} from '@angular/common';
import {SMARTENCITY_MYDATA_CONFIG} from '@smartencity/mydata';

import {ServicesModule, SMARTENCITY_SERVICES_CONFIG} from '@smartencity/services';
import {ModalModule} from 'ngx-bootstrap/modal';
import {PopoverModule} from 'ngx-bootstrap/popover';
import {TabsModule} from 'ngx-bootstrap/tabs';
import {TypeaheadModule} from 'ngx-bootstrap/typeahead';
import * as moment from 'moment';
import 'moment-timezone';
import {LaddaModule} from 'angular2-ladda';
import {ToastrModule} from 'ngx-toastr';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {ButtonsModule} from 'ngx-bootstrap/buttons';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import { LocalizeExtractsComponent } from './localize-extracts/localize-extracts.component';
import { NotFoundComponent } from './not-found/not-found.component';
import {ConfigService} from './config.service';
import {SMARTENCITY_APP_CONFIG} from './injection-tokens';
import player from 'lottie-web';
import {LottieModule} from 'ngx-lottie';
import {AppModulesActivateGuard} from './app-modules-activate.guard';
import {AppModulesLoadGuard} from './app-modules-load.guard';
import {MyDataSharedModule} from '../../projects/mydata/src/lib/shared/my-data-shared.module';
import { PublicDataModule } from '@smartencity/publicdata';

export const configFactory = (configService: ConfigService) => {
  return () => configService.loadConfig().toPromise().then(config => {
    moment.tz.setDefault(config.timezone);
  });
};

// ngx-lottie
// Note we need a separate function as it's required
// by the AOT compiler.
export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [
    AppComponent,
    LocalizeExtractsComponent,
    NotFoundComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbModule,
    BrowserAnimationsModule,
    NgSelectModule,
    NgxPermissionsModule.forRoot(),
    AppRoutingModule,
    CoreModule,
    BuildingsModule,
    MobilityModule,
    LightsModule,
    PeopleModule,
    WeatherModule,
    ServicesModule,
    MeteorologyModule,
    MyDataSharedModule,
    PublicDataModule,
    ModalModule.forRoot(),
    TypeaheadModule.forRoot(),
    PopoverModule.forRoot(),
    TooltipModule.forRoot(),
    TabsModule.forRoot(),
    BsDropdownModule.forRoot(),
    LaddaModule.forRoot({
      style: 'expand-left',
      spinnerSize: 20,
      spinnerColor: 'white'
    }),
    ToastrModule.forRoot(),
    ButtonsModule.forRoot(),
    LottieModule.forRoot({ player: playerFactory })
  ],
  providers: [
    ConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: configFactory,
      deps: [ConfigService],
      multi: true
    }, {
      provide: SMARTENCITY_APP_CONFIG,
      useValue: Object.assign({}, environment)
    }, {
      provide: SMARTENCITY_CORE_CONFIG,
      useValue: ConfigService.mapCore(environment)
    }, {
      provide: SMARTENCITY_MOBILITY_CONFIG,
      useValue: ConfigService.mapMobility(environment)
    }, {
      provide: SMARTENCITY_BUILDINGS_CONFIG,
      useValue: ConfigService.mapBuildings(environment)
    }, {
      provide: SMARTENCITY_LIGHTS_CONFIG,
      useValue: ConfigService.mapLights(environment)
    }, {
      provide: SMARTENCITY_PEOPLE_CONFIG,
      useValue: ConfigService.mapPeople(environment)
    }, {
      provide: SMARTENCITY_MYDATA_CONFIG,
      useValue: ConfigService.mapMyData(environment)
    }, {
      provide: ErrorHandler,
      useClass: ErrorHandlerService,
      deps: [LoggerService, AuthService]
    }, {
      provide: SMARTENCITY_SERVICES_CONFIG,
      useValue: ConfigService.mapServices(environment)
    }, {
      provide: SMARTENCITY_GAS_CONFIG,
      useValue: ConfigService.mapGas(environment)
    }, {
      provide: SMARTENCITY_TALLINN_CONFIG,
      useValue: ConfigService.mapTallinn(environment)
    }, {
      provide: SMARTENCITY_METEOROLOGY_CONFIG,
      useValue: ConfigService.mapMeteorology(environment)
    },
    ResizeService,
    AppModulesActivateGuard,
    AppModulesLoadGuard
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {
}
