import {Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';

import {SMARTENCITY_MYDATA_CONFIG} from '../../../injection-tokens';
import {MyDataConfig} from '../../../mydata-config.model';
import {Subject} from 'rxjs/internal/Subject';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {
  EnumsService,
  Location,
  NumberFormatConstants,
  PersonParameter,
  PreventCloseAware
} from '@smartencity/core';
import {FormControl, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {debounceTime, distinctUntilChanged, map, startWith, switchMap, takeUntil} from 'rxjs/operators';
import {RequireAddress} from '../../../validators/require-address';
import {ToastrService} from 'ngx-toastr';
import {RequireUuid} from '../../../validators/require-uuid';
import {RequireCoords} from '../../../validators/require-coords';
import {PersonParameterApiService} from '../../../http/person-parameter-api.service';
import {PersonSeriesGroupApiService} from '../../../http/person-series-group-api.service';

const selector = 'mydata-parameters-edit-modal';
let nextId = 0;


export class GroupItem {
  group: { id: number; name: string; };
  editable = true;
}

@Component({
  selector: selector,
  templateUrl: './parameters-edit-modal.component.html'
})
export class ParametersEditModalComponent implements OnInit, OnDestroy, OnChanges, PreventCloseAware {
  id = `${selector}-${nextId++}`;
  private ngDestroy = new Subject<void>();

  public progress = false;

  public showErrors = false;

  public groupTypeahead$ = new Subject<string>();
  public group$ = this.groupTypeahead$.pipe(
    takeUntil(this.ngDestroy),
    startWith(''),
    distinctUntilChanged(),
    debounceTime(250),
    switchMap((term: string) => this.personSeriesGroupService.searchGroups({query: term, ownerTypes: ['OWNER']})),
    map(groups => groups.map(this.toGroupItem.bind(this)))
  );


  @Input()
  personParameter: PersonParameter;

  @Input()
  location: Location;

  @Output('saved')
  savedEmitter: EventEmitter<any> = new EventEmitter<any>();

  defaultNumberFormat = NumberFormatConstants.DEFAULT_NUMBER_FORMAT;

  public static formDefault(): FormGroup {
    return new FormGroup({
      name: new FormControl(null, [Validators.required]),
      value: new FormControl(null, [Validators.required, Validators.pattern(/^\-?\d+([\.\,]\d+)?$/)]),
      unit: new FormControl(null),
      type: new FormControl(null),
      groups: new FormControl([], []),
      locationType: new FormControl(null),
      uuid: new FormControl('', [RequireUuid]),
      lat: new FormControl('', [RequireCoords]),
      lng: new FormControl('', [RequireCoords]),
      address: new FormControl(null, [RequireAddress]),
      apartment: new FormControl(null),
      room: new FormControl(null),
      adsOid: new FormControl(null),
      numberFormat: new FormGroup({
        maxFractionDigits: new FormControl(NumberFormatConstants.DEFAULT_NUMBER_FORMAT.defaultFractionDigits, [
          Validators.required,
          Validators.min(NumberFormatConstants.DEFAULT_NUMBER_FORMAT.minFractionDigits),
          Validators.max(NumberFormatConstants.DEFAULT_NUMBER_FORMAT.maxFractionDigits)
        ]),
      }),
    });
  }

  form: FormGroup;

  constructor(
    private enumsService: EnumsService,
    @Inject(SMARTENCITY_MYDATA_CONFIG) private config: MyDataConfig,
    private toastr: ToastrService,
    private modalRef: BsModalRef,
    private personParameterApi: PersonParameterApiService,
    private personSeriesGroupService: PersonSeriesGroupApiService
  ) {

  }

  ngOnInit(): void {
    this.buildForm();

    if (this.personParameter) {
      this.updateFormContent();
    }

    const value = this.form.getRawValue();

    let location = this.location;
    if (location && (location.locationType || location.group)) {
      // Teeb reset ainult siis, kui URL-lt tuleb asukoha tüüp kaasa, muidu on parameeter üldisest nimekirjast
      this.form.reset({
        groups: value.groups,
        locationType: location?.locationType ? location?.locationType : value.locationType,
        address: location?.address ? location.address : value.address,
        apartment: location?.apartment ? location.apartment : value.apartment,
        room: location?.room ? location.room : value.room,
        adsOid: location?.adsOid ? location.adsOid : value.adsOid,
        lat: location?.lat ? location.lat : value.lat,
        lng: location?.lng ? location.lng : value.lng,
        uuid: location?.uuid ? location.uuid : value.uuid,
        name: value.name,
        value: value.value,
        unit: value.unit,
        numberFormat: value.numberFormat
      }, {onlySelf: true, emitEvent: false});
    }


    this.form.get('locationType').valueChanges.pipe(takeUntil(this.ngDestroy)).subscribe(() => {
      this.form.get('address').updateValueAndValidity();
      this.form.get('uuid').updateValueAndValidity();
      this.form.get('lat').updateValueAndValidity();
      this.form.get('lng').updateValueAndValidity();
    });

  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['personParameter']) {
      this.updateFormContent();
    }
  }

  control(name?: string) {
    if (!name) {
      return this.form;
    }
    return this.form.get(name);
  }

  invalid(name: string, formGroup?) {
    if (!formGroup) {
      formGroup = this.form;
    }
    const control = formGroup.get(name);
    return control && control.invalid && (control.dirty || control.touched);
  }

  errors(name: string): ValidationErrors {
    const control = this.form.get(name);
    return control ? control.errors : null;
  }

  private buildForm() {
    this.form = ParametersEditModalComponent.formDefault();
  }

  private updateFormContent() {
    const groups = this.getGroups();
    const groupRefs: GroupItem[] = groups.map(this.toGroupItem.bind(this));

    this.form.patchValue({
      name: this.personParameter ? this.personParameter.name : null,
      value: this.personParameter ? this.personParameter.value : null,
      unit: this.personParameter ? this.personParameter.unit : null,
      type: this.personParameter ? this.personParameter.type : null,
      groups: groupRefs,
      locationType: this.personParameter ? this.personParameter.locationType : null,
      address: this.personParameter ? this.personParameter.address : null,
      apartment: this.personParameter ? this.personParameter.apartment : null,
      room: this.personParameter ? this.personParameter.room : null,
      adsOid: this.personParameter ? this.personParameter.adsOid : null,
      uuid: this.personParameter ? this.personParameter.uuid : null,
      lat: this.personParameter ? this.personParameter.lat : null,
      lng: this.personParameter ? this.personParameter.lng : null,
      numberFormat: this.personParameter && this.personParameter.numberFormat ? this.personParameter.numberFormat : null
    }, {onlySelf: true, emitEvent: false});

    if (this.personParameter && this.personParameter.type) {
      this.form.get('name').disable();
    } else {
      this.form.get('name').enable();
    }
  }

  public close(): void {
    this.modalRef.hide();
  }

  save() {
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();

    if (!this.form.valid) {
      console.log('form invalid', this.form);
      this.showErrors = true;
      return;
    }

    const value = this.form.getRawValue();

    this.progress = true;

    let formValue = ParametersEditModalComponent.prepareFormValue(value);
    if (this.personParameter && this.personParameter.id) {
      formValue.id = this.personParameter.id;
      this.personParameterApi.updatePersonParameter(this.personParameter.id, formValue).pipe(takeUntil(this.ngDestroy)).subscribe((personParameter: PersonParameter) => {
        this.resetFormState();

        this.modalRef.hide();
        this.savedEmitter.emit(personParameter);
        this.toastr.success($localize`Parameter saved`);
      }, (error) => {
        this.progress = false;
        this.toastr.error($localize`Saving parameter failed`);
      }, () => this.progress = false);
    } else {
      this.personParameterApi.createPersonParameter(formValue).pipe(takeUntil(this.ngDestroy)).subscribe((personParameter: PersonParameter) => {
        this.resetFormState();

        this.modalRef.hide();
        this.savedEmitter.emit(personParameter);
        this.toastr.success($localize`Parameter saved`);
      }, (error) => {
        this.progress = false;
        this.toastr.error($localize`Saving parameter failed`);
      }, () => this.progress = false);
    }
  }

  private static prepareFormValue(value): any {

    return {
      name: value.name ? value.name.trim() : value.name,
      value: value.value && typeof(value.value) === 'string' ? value.value.trim().replace(',', '.') : value.value,
      unit: value.unit ? value.unit.trim() : value.unit,
      type: value.type,
      groups: value.groups ? value.groups.map(g => g.group) : [],
      locationType: value.locationType ? value.locationType.trim() : null,
      address: value.address ? value.address.trim() : value.address,
      apartment: value.apartment ? value.apartment.trim() : value.apartment,
      room: value.room ? value.room.trim() : value.room,
      uuid: value.uuid ? value.uuid.trim() : value.uuid,
      lat: value.lat,
      lng: value.lng,
      numberFormat: value.numberFormat
    }
  }

  isPreventClose(): boolean {
    return this.form && (this.form.dirty || this.form.touched);
  }

  private resetFormState(): void {
    this.form.markAsUntouched();
    this.form.markAsPristine();
  }

  private getGroups(): any[] {
    let groups = (this.personParameter && this.personParameter.groups && this.personParameter.groups.length > 0
      ? this.personParameter.groups
      : []);

    if (groups.length === 0 && this.location?.group) {
      groups = [{
        id: this.location.group.id,
        name: this.location.group.name
      }];
    }

    return groups;
  }

  private toGroupItem(group: { id: number; name: string }): GroupItem {

    return {
      group: group,
      editable: (this.location?.group.id != group.id)
    };
  }

}
