import { Inject, Injectable } from "@angular/core";
import { DisplaySettings } from "../model/display-settings";
import { AirQualityStation } from "../model/air-quality-station";
import { SMARTENCITY_TALLINN_CONFIG } from "../injection-tokens";
import { TallinnConfig } from "../tallinn-config.model";
import { TallinnService } from "./tallinn.service";
import { WaterTap } from "../model/water-tap";
import {
  ArcGisRestService,
  OlMapWrapper,
  OlMapWrapperOptions
} from "@smartencity/core";
import { AirQualityStationOlLayer } from "../layers/air-quality-station-ol-layer";
import { WaterTapsOlLayer } from "../layers/water-taps-ol-layer";
import { WifiAccessPointsOlLayer } from "../layers/wifi-access-points-ol-layer";
import { WifiAccessPoint } from "../model/wifi-access-point";
import { NoiseLevelOlLayer } from "../layers/noise-level-ol-layer";
import { NoiseLevel } from "../component/noise-level-heatmap/noise-level-heatmap.model";
import { TallinnArcGisMapService } from "projects/arc-gis/src/lib/tallinn-arc-gis-map.service";
import { createStyleFunctionFromUrl } from "ol-esri-style";

@Injectable()
export class TallinnOlMapService {
  public map: OlMapWrapper;

  private waterTapsLayer: WaterTapsOlLayer = null;
  private wifiAccessPointsLayer: WifiAccessPointsOlLayer = null;
  private airQualityStationsLayer: AirQualityStationOlLayer = null;
  private noiseLevelLayer: NoiseLevelOlLayer = null;

  displaySettings = new DisplaySettings();

  public arcGisMapLayers: Map<string, any> = new Map<string, any>([
    [
      "toilets",
      {
        layerId: 0,
        name: "toilets",
        getFeatureStyle: (url, layerId): Promise<Function> => {
          return createStyleFunctionFromUrl(url + "/" + layerId);
        },
      },
    ],
    [
      "recyclingStations",
      {
        layerId: 8,
        name: "recyclingStations",
        getFeatureStyle: (url, layerId): Promise<Function> => {
          return createStyleFunctionFromUrl(url + "/" + layerId);
        },
      },
    ],
  ]);

  constructor(
    @Inject(SMARTENCITY_TALLINN_CONFIG) private config: TallinnConfig,
    public tallinnService: TallinnService,
    private arcGisClient: ArcGisRestService,
    private tallinnArcGisMapService: TallinnArcGisMapService
  ) {}

  createMap(): void {

    this.tallinnService.loadAirQualityStations();
    this.tallinnService.loadWaterTaps();
    this.tallinnService.loadWifiAccessPoints();
    this.tallinnService.loadNoiseLevels({
      page: 0,
    });
    const baseUrl = this.config.cityApiUrl + "/tallinn/map-server-proxy";
    const opts = new OlMapWrapperOptions();
    opts.baseMapServerUrl =
      baseUrl + "/rest/services/aluskaart_yldine/MapServer";
    opts.center = [this.config.map.center.lng, this.config.map.center.lat];

    this.map = new OlMapWrapper(this.arcGisClient, opts);
    this.tallinnArcGisMapService.addCityLayers(this.map);

    this.map.addFeatureServer(baseUrl + "/rest/services/Andmed_Tallinn", {
      layers: Array.from(this.arcGisMapLayers.values()),
      zIndexOffset: 500,
    });

    this.map.mapLoaded$.subscribe(() => {
      this.tallinnService.airQualityStationPoints$.subscribe(
        (airQualityStations: AirQualityStation[]) => {
          if (this.airQualityStationsLayer) {
            this.airQualityStationsLayer.setMap(null);
          }
          this.airQualityStationsLayer = new AirQualityStationOlLayer(airQualityStations, this.tallinnService);
          if (this.displaySettings.airQualityStations) {
            this.airQualityStationsLayer.setMap(this.map);
          }

          this.tallinnService.airQualityStationSelected$.subscribe(
            (airQualityStation: AirQualityStation) => {
              this.airQualityStationsLayer.setAirQualityStation(airQualityStation);
            }
          );
        }
      );

      this.tallinnService.waterTaps$.subscribe((waterTaps: WaterTap[]) => {
        if (this.waterTapsLayer) {
          this.waterTapsLayer.setMap(null);
        }
        this.waterTapsLayer = new WaterTapsOlLayer(waterTaps);
        if (this.displaySettings.waterTaps) {
          this.waterTapsLayer.setMap(this.map);
        }
      });

      this.tallinnService.wifiAccessPoints$.subscribe(
        (accessPoints: WifiAccessPoint[]) => {
          if (this.wifiAccessPointsLayer) {
            this.wifiAccessPointsLayer.setMap(null);
          }
          this.wifiAccessPointsLayer = new WifiAccessPointsOlLayer(
            accessPoints
          );
          if (this.displaySettings.wifiAccessPoints) {
            this.wifiAccessPointsLayer.setMap(this.map);
          }
        }
      );

      this.tallinnService.noiseLevels$.subscribe(
        (noiseLevels: NoiseLevel[]) => {
          if (this.noiseLevelLayer) {
            this.noiseLevelLayer.setMap(null);
          }
          this.noiseLevelLayer = new NoiseLevelOlLayer(noiseLevels);
          if (this.displaySettings.noiseLevel) {
            this.noiseLevelLayer.setMap(this.map);
          }
        }
      );

      this.tallinnArcGisMapService.afterMapLoaded();
    });

    this.tallinnService.displaySettings$.subscribe((value) => {
      this.displaySettings = value;
      this.updateDisplay();
    });
  }

  updateDisplay() {
    this.waterTapsLayer.setMap(
      this.displaySettings.waterTaps ? this.map : null
    );
    this.wifiAccessPointsLayer.setMap(
      this.displaySettings.wifiAccessPoints ? this.map : null
    );
    this.airQualityStationsLayer.setMap(
      this.displaySettings.airQualityStations ? this.map : null
    );
    this.noiseLevelLayer.setMap(
      this.displaySettings.noiseLevel ? this.map : null
    );
  }
}
