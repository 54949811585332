import {Component, Input, OnInit} from '@angular/core';

import {Tenant} from '@smartencity/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'mydata-select-tenant',
  templateUrl: './select-tenant.component.html'
})
export class SelectTenantComponent implements OnInit {
  public tenantId: number;
  public tenants: Tenant[];

  @Input()
  public callback: (result: Tenant) => void;

  constructor (
    public modalRef: BsModalRef,
    public toastr: ToastrService
  ) { }

  ngOnInit () { }

  continue () {
    if (!this.tenantId) {
      this.toastr.error('Make selection');
      return;
    }
    if (this.callback) {
      this.callback(this.tenants.find((value) => value.id == this.tenantId));
    }
    this.modalRef.hide();
  }

  cancel () {
    if (this.callback) {
      this.callback(null);
    }
    this.modalRef.hide();
  }
}
