import {Component, EventEmitter, Inject, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs/internal/Subject';
import {
  LocationService,
  Person,
  PersonRegistrationNumberValidator,
  PreventCloseModalService,
  QuestionnaireDetail,
  QuestionnaireEmailInvitationMeta,
  QuestionnaireParticipantMeta, QuestionnaireParticipation,
  UserService
} from '@smartencity/core';
import {debounceTime, distinctUntilChanged, map, switchMap, take, takeUntil} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {MyDataConfig} from '../../../mydata-config.model';
import {ToastrService} from 'ngx-toastr';
import {Observable} from 'rxjs/internal/Observable';
import {of} from 'rxjs/internal/observable/of';
import {ActivatedRoute, Router} from '@angular/router';
import {SMARTENCITY_MYDATA_CONFIG} from '../../../injection-tokens';
import {ManageService} from '../manage.service';
import {NgxPermissionsService} from 'ngx-permissions';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';
import {QuestionnaireParticipationApiService} from '../../../http/questionnaire-participation-api.service';
import {WidgetTemplate} from '../../dashboard/widget';
import {WidgetTemplateModalComponent} from '../../dashboard/widget-template-modal/widget-template-modal.component';
import {WidgetTemplateApiService} from '../../../http/widget-template-api.service';
import {BsModalService} from 'ngx-bootstrap/modal';
import {forkJoin} from 'rxjs/internal/observable/forkJoin';

const selector = 'app-questionnaire-participants';
let nextId = 0;
@Component({
  selector: selector,
  templateUrl: './questionnaire-participants.component.html',
  styleUrls: ['./questionnaire-participants.component.css']
})
export class QuestionnaireParticipantsComponent implements OnInit, OnDestroy {
  id = `${selector}-${nextId++}`;
  private ngDestroy = new Subject<void>();

  questionnaire: QuestionnaireDetail;
  public type: string;
  public juridicalPerson = false;

  @Output('saved')
  savedEmitter: EventEmitter<any> = new EventEmitter<any>();

  @Output("participantsChanges")
  participants$: EventEmitter<QuestionnaireParticipantMeta[]> = new EventEmitter<QuestionnaireParticipantMeta[]>();

  @Output("emailInvitesChanges")
  emailInvites$: EventEmitter<QuestionnaireEmailInvitationMeta[]> = new EventEmitter<QuestionnaireEmailInvitationMeta[]>();

  participants: QuestionnaireParticipantMeta[] = [];
  emailInvites: QuestionnaireEmailInvitationMeta[] = [];

  widgetTemplates: WidgetTemplate[] = [];

  public itemCount = 0;
  participationLoaded = false;

  formGroup: FormGroup;
  publicParticipationControl: FormControl = this.manageService.publicParticipationControl;

  personTypeahead$ = new Subject<string>();
  personItems$ = this.personTypeahead$.pipe(
    takeUntil(this.ngDestroy),
    distinctUntilChanged(),
    debounceTime(250),
    switchMap((term: string) => this.userService.searchPersons(term)),
    map((persons: Person[]) => {
      return persons.map((e) => ({
        countryCode: e.countryCode,
        registrationNumber: e.registrationNumber,
        displayName: e.displayName,
        juridical: e.juridical,
        idPrivacyType: e.idPrivacyType,
        dob: e.dob
      }));
    })
  );

  constructor(
    private http: HttpClient,
    @Inject(SMARTENCITY_MYDATA_CONFIG) private config: MyDataConfig,
    private toastr: ToastrService,
    private userService: UserService,
    private personRegistrationNumberValidator: PersonRegistrationNumberValidator,
    private router: Router,
    private route: ActivatedRoute,
    private manageService: ManageService,
    private permissionService: NgxPermissionsService,
    private questionnaireParticipationApi: QuestionnaireParticipationApiService,
    private locationService: LocationService,
    private widgetTemplateApi: WidgetTemplateApiService,
    private modalService: BsModalService,
    private preventCloseModalService: PreventCloseModalService
  ) {
    this.personTypeahead = this.personTypeahead.bind(this);
    this.formGroup = new FormGroup({
      participants: new FormControl(null)
    });

    combineLatest([this.permissionService.permissions$, this.manageService.questionneire$]).subscribe(([permissions, q11e]) => {
      if (permissions['JURIDICAL_PERSON']) {
        this.juridicalPerson = true;
      }
      this.questionnaire = q11e;
      this.type = q11e.type;
      this.loadQuestionnaireParticipation();
      this.loadWidgetTemplates();
    });

  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  ngOnInit(): void {}

  loadWidgetTemplates(): void {

    this.widgetTemplateApi.getWidgetTemplatesByQ11eId(this.questionnaire.id).subscribe((widgetTemplates: WidgetTemplate[]) => {
      this.widgetTemplates = widgetTemplates;
    });

  }

  openWidgetTemplate(): void {
    this.preventCloseModalService.show(WidgetTemplateModalComponent, {
      class: 'modal-xxl',
      initialState: {
        widgets: this.widgetTemplates,
        q11e: this.questionnaire,
        onHide: (widget: WidgetTemplate) => {
          // this.questionnaire.widgetTemplateId = widget.id;

          this.loadWidgetTemplates();
        }
      }
    });
  }

  removeAllWidgets(): void {

    let toForkJoin: Observable<void>[] = [];
    for (let widgetTemplate of this.widgetTemplates) {
      toForkJoin.push(this.widgetTemplateApi.removeWidgetTemplate(widgetTemplate.id));
    }

    forkJoin(toForkJoin).subscribe(() => {
      this.loadWidgetTemplates();
    });
  }

  removeWidget(widgetTemplate: WidgetTemplate): void {
    this.widgetTemplateApi.removeWidgetTemplate(widgetTemplate.id).subscribe(() => {
      this.loadWidgetTemplates();
    });
  }

  public personTypeahead(text$: Observable<string>) {
    return text$.pipe(
      debounceTime(250),
      distinctUntilChanged(),
      switchMap((term: string) => {
        if (term.length < 4) {
          return of([]);
        }
        return this.userService.searchPersons(term);
      })
    );
  }

  participantsAdded(value) {
    let formValue = this.formGroup.get('participants').value;
    if (formValue == null) {
      formValue = [];
    }

    if (typeof value !== 'string' && value['label']) {
      value = value['label'];
    }

    let addedValues = [];
    if (typeof value === 'string') {
      let vParts = value.split(/[\r\n, ]+/).map(e => e.trim());
      for (let i in vParts) {
        if (!Validators.email(new FormControl(vParts[i]))) {
          addedValues.push(vParts[i]);
        }
      }
      formValue.splice(formValue.indexOf(value), 1);
    }

    this.formGroup.get('participants').setValue(formValue.concat(addedValues));
    this.formGroup.get('participants').updateValueAndValidity();
  }

  public isString(value): boolean {
    return typeof value === 'string';
  }

  addParticipants() {

    let fValues = this.formGroup.get('participants').value;
    let invalidValues = [];
    for(let i in fValues){
      let value = fValues[i];
      if(typeof value === 'string'){
        if(!Validators.email(new FormControl(value))){
          this.addEmail(value);
        } else {
          invalidValues.push(value);
        }
      } else {
        this.addPerson(value)
      }
    }

    this.formGroup.get('participants').setValue(invalidValues);
    this.formGroup.updateValueAndValidity();
    this.updateItemCount();

    this.participants$.emit(this.participants);

    if (invalidValues.length === 0) {
      this.save(false);
    }
  }

  addPerson(value) {
    const countryCode = typeof(value.countryCode) === 'string' ? value.countryCode.trim() : value.countryCode;
    const registrationNumber = typeof(value.registrationNumber) === 'string' ? value.registrationNumber.trim() : value.registrationNumber;
    const displayName = value.displayName;
    const juridical = value.juridical;

    let index = -1;
    for (const [i, p] of this.participants.entries()) {
      if (p.person.countryCode === countryCode && p.person.registrationNumber === registrationNumber) {
        index = i;
      }
    }

    if (index === -1) {
      this.participants.push({
        person: {
          countryCode: countryCode,
          registrationNumber: registrationNumber,
          displayName: displayName,
          juridical: juridical
        }
      } as QuestionnaireParticipantMeta);
    }
  }

  addEmail(email: string) {
      const alreadyExists = this.emailInvites.findIndex(value => value.email === email) !== -1;
      if (email.length > 0 && !alreadyExists) {
        this.emailInvites.push({
          email: email,
          participant: null
        } as QuestionnaireEmailInvitationMeta);
      }
  }

  removeAll() {
    this.participants.splice(0, this.participants.length);
    this.participants$.emit(this.participants);
    this.emailInvites.splice(0, this.emailInvites.length);
    this.emailInvites$.emit(this.emailInvites);
    this.updateItemCount();

    this.save(false);
  }

  remove(participant: QuestionnaireParticipantMeta) {
    const index = this.participants.indexOf(participant);
    if (index !== -1) {
      this.participants.splice(index, 1);
    }
    this.updateItemCount();
    this.participants$.emit(this.participants);

    this.save(false);
  }

  loadQuestionnaireParticipation() {
    if (this.questionnaire?.id && !this.participationLoaded) {
      this.questionnaireParticipationApi.getByQuestionnaire(this.questionnaire).subscribe((participation: QuestionnaireParticipation) => {
        this.manageService.setQuestionnaireParticipation(participation);
        this.setEmailInvites(participation.emailInvites);
        this.setParticipants(participation.participants);
        this.updateItemCount();
        this.participationLoaded = true;
      });
    }
  }

  private setEmailInvites(emailInvites: QuestionnaireEmailInvitationMeta[]): void {
    this.emailInvites = emailInvites;
    this.emailInvites$.emit(this.emailInvites);
  }

  private setParticipants(participants: QuestionnaireParticipantMeta[]): void {
    this.participants = participants;
    this.participants$.emit(this.participants);
  }

  removeEmailInvitation(emailInvitation: QuestionnaireEmailInvitationMeta) {
    const index = this.emailInvites.findIndex(value => value.email === emailInvitation.email);
    if (index !== -1) {
      this.emailInvites.splice(index, 1);
    }
    this.updateItemCount();
    this.emailInvites$.emit(this.emailInvites);

    this.save(false);
  }

  updateItemCount() {
    this.itemCount = this.participants.length + this.emailInvites.length;
  }

  async save(confirm: boolean) {
    this.formGroup.get('participants').setValue(null);

    const body: any = {
      questionnaireId: this.questionnaire.id,
      participants: [],
      emailInvites: [],
      cityPortalHost: this.locationService.getCityPortalHost(),
      publicParticipation: this.publicParticipationControl.value
    };

    for (const p of this.participants) {
      body.participants.push({
        countryCode: p.person.countryCode,
        registrationNumber: p.person.registrationNumber,
      });
    }

    for (const i of this.emailInvites) {
      body.emailInvites.push({ email : i.email });
    }

    if (!this.questionnaire.status || this.questionnaire.status === 'DRAFT') {
      this.questionnaire = await this.manageService.save();
      body.questionnaireId = this.questionnaire.id;
    }

    let result;
    if (confirm) {
      result = this.questionnaireParticipationApi.confirm(this.questionnaire, body);
    } else {
      result = this.questionnaireParticipationApi.save(this.questionnaire, body);
    }

    result
      .pipe(takeUntil(this.ngDestroy))
      .subscribe((participation: QuestionnaireParticipation) => {
        this.manageService.setQuestionnaireParticipation(participation);
        const participantCount = participation.participants?.length + participation.emailInvites?.length;
        if (confirm) {
          if (participantCount === 1) {
            this.toastr.success($localize`The questionnaire was sent to 1 participant`);
          } else {
            this.toastr.success($localize`The questionnaire was sent to ${participantCount}:participantCount: participants`);
          }
          this.savedEmitter.emit();
          this.router.navigate(['..'], {relativeTo: this.route});
        } else {
          this.toastr.success($localize`Participants saved`);
        }
      }, (e) => {
        if (e.error && e.error.errorCode) {
          this.toastr.error($localize`Participants save failed (${e.error.errorCode})`);
        } else {
          this.toastr.error($localize`Participants save failed`);
        }
      });
  }
}
