import {AbstractMapLayer, OlMapWrapper} from '@smartencity/core';
import { BicycleStation } from '../component/bicycle-stations-heatmap/bicylce-stations.model';
import { BicycleStationOlMarker } from './bicycle-stations-ol-marker';

export class BicycleStationOlLayer extends AbstractMapLayer<OlMapWrapper, BicycleStation>{

  private bicycleStations: BicycleStation[];

  constructor(bicycleStations: any) {
    super();
    this.bicycleStations = bicycleStations;
  }
  
  setMap(map: OlMapWrapper): void {
    super.setMap(map);
    this.resetMarkers();
    if (map) {
      this.createMarkers();
    }
  }
  
  protected resetMarkers(): void {
    let markers = this.getMarkers();
    if (markers.length > 0) {
      for (const marker of markers) {
        marker.setMap(null);
      }
    }
    this.clearMarkers();
  }
  
  protected createMarkers(): void {
    for (const bicycleStation of this.bicycleStations) {
      this.addMarker(new BicycleStationOlMarker(bicycleStation, this.map));
    }
  }
  
}
