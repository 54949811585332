import {Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output} from '@angular/core';

import {HttpClient} from '@angular/common/http';
import {SMARTENCITY_MYDATA_CONFIG} from '../../../injection-tokens';
import {MyDataConfig} from '../../../mydata-config.model';
import {FormControl, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {
  SeriesType,
  AuthService,
  Tenant, EnumsService, SourceOwnerSeries, PersonSeriesGroup, PreventCloseAware
} from '@smartencity/core';
import {takeUntil} from 'rxjs/operators';
import {RequireCoords} from '../../../validators/require-coords';
import {RequireUuid} from '../../../validators/require-uuid';
import {RequireAddress} from '../../../validators/require-address';
import {Subject} from 'rxjs/internal/Subject';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {ToastrService} from 'ngx-toastr';
import {GroupingTagService} from "../../../services/grouping-tag.service";
import {SourceApiService} from '../../../http/source-api.service';

const selector = 'mydata-series-create-modal';
let nextId = 0;

@Component({
  selector: selector,
  templateUrl: './series-create-modal.component.html',
  providers: [GroupingTagService]
})
export class SeriesCreateModalComponent implements OnInit, OnDestroy, PreventCloseAware {
  id = `${selector}-${nextId++}`;
  private ngDestroy = new Subject<void>();
  public progress = false;
  public addDataProgress = false;
  public showErrors = false;

  @Input()
  public group?: PersonSeriesGroup;

  @Input()
  public tenant: Tenant;

  @Output('save')
  saveEmitter: EventEmitter<{sourceOwnerSeries: SourceOwnerSeries, addData: boolean}> = new EventEmitter<{sourceOwnerSeries: SourceOwnerSeries, addData: boolean}>();

  confirmed = false;

  form: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
    seriesType: new FormControl('OTHER', [Validators.required]),
    locationType: new FormControl('ADDRESS', [Validators.required]),
    uuid: new FormControl('', [RequireUuid]),
    lat: new FormControl('', [RequireCoords]),
    lng: new FormControl('', [RequireCoords]),
    adsOid: new FormControl(''),
    address: new FormControl('', [RequireAddress]),
    apartment: new FormControl(''),
    room: new FormControl(''),
    unit: new FormControl('', [Validators.required]),
    groups: new FormControl([], [Validators.required])
  });

  public seriesTypes: {value,label}[] = [];

  constructor(
    private http: HttpClient,
    @Inject(SMARTENCITY_MYDATA_CONFIG) private config: MyDataConfig,
    private toastr: ToastrService,
    public authService: AuthService,
    private modalRef: BsModalRef,
    private enumsService: EnumsService,
    public groupingTagService: GroupingTagService,
    private sourceApi: SourceApiService
  ) {
    this.seriesTypes = this.enumsService.getOptionsList(SeriesType.types);
  }

  ngOnInit(): void {
    this.form.get('locationType').valueChanges.pipe(takeUntil(this.ngDestroy)).subscribe(() => {
      this.form.get('address').updateValueAndValidity();
      this.form.get('apartment').updateValueAndValidity();
      this.form.get('lat').updateValueAndValidity();
      this.form.get('lng').updateValueAndValidity();
      this.form.get('uuid').updateValueAndValidity();
    });

    if (this.group) {
      this.form.get('groups').setValue([this.group]);
    }
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
    this.groupingTagService.ngDestroy.next();
  }

  control(name?: string) {
    if (!name) {
      return this.form;
    }
    return this.form.get(name);
  }

  invalid(name: string, formGroup?) {
    if (!formGroup) {
      formGroup = this.form;
    }
    const control = formGroup.get(name);
    return control && control.invalid && (control.dirty || control.touched);
  }

  errors(name: string): ValidationErrors {
    const control = this.form.get(name);
    return control ? control.errors : null;
  }

  close() {
    this.modalRef.hide();
  }

  save(addData: boolean) {
    if (this.progress) {
      return;
    }

    this.confirmed = true;
    this.form.markAsTouched();
    this.markFormGroupTouched(this.form);
    this.form.updateValueAndValidity();

    if (!this.form.valid) {
      this.showErrors = true;
      return;
    }

    this.progress = true;
    this.addDataProgress = addData;
    const value = this.form.value;
    const groups = (value.groups ? value.groups.map(group => {
      if (!group.id) {
        group.groupingTag = group.name;
      }
      return group;
    }) : []);

    this.sourceApi.create({
      tenantId: this.tenant.id,
      unit: value.unit,
      metaFields: {
        name: value.name ? value.name.trim() : value.name,
        groupingTags: groups.map(group => group.groupingTag.trim().toUpperCase()),
        groups: groups,
        seriesType: value.seriesType ? value.seriesType.trim() : value.seriesType,
        locationType: value.locationType ? value.locationType.trim() : value.locationType,
        adsOid: value.adsOid ? value.adsOid.trim() : value.adsOid,
        address: value.address ? value.address.trim() : value.address,
        apartment: value.apartment ? value.apartment.trim() : value.apartment,
        room: value.room ? value.room.trim() : value.room,
        uuid: value.uuid ? value.uuid.trim() : value.uuid,
        lat: value.lat,
        lng: value.lng
      }
    }).subscribe((sourceOwnerSeries: SourceOwnerSeries) => {
      this.modalRef.hide();
      this.toastr.success('Source created');
      this.saveEmitter.emit({
        sourceOwnerSeries: sourceOwnerSeries,
        addData: addData
      });
    }, (error) => {
      this.progress = false;
      this.addDataProgress = false;
      this.toastr.error('Source creation failed');
    }, () => {
    });
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  isPreventClose(): boolean {
    if(!this.confirmed) {
      return this.form && this.form.dirty;
    }
  }
}
