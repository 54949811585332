import {GenericUriBuilder} from '../../redirect/generic-uri-builder';

export class TaraAuthUriBuilder extends GenericUriBuilder {

  private hostUrl: string = '';

  constructor(apiUrl: string, hostUrl: string) {
    super(apiUrl, "/tara/authorize");
    this.hostUrl = hostUrl;
  }

  processBuildParams(params?: any) {
    if (!params) {
      throw Error("Params missing");
    }

    this.setUrlParams({
      fallbackUrl: decodeURIComponent(this.buildRedirectTo(params.currentUrlPath)),
      redirectAuthUrl: this.hostUrl + "/auth",
      redirectTo: decodeURIComponent(this.buildRedirectTo(params.targetUrlPath)),
      ...params.params
    });
  }

  private buildRedirectTo(urlPath?: string): string {
    let url = this.hostUrl;
    if (urlPath) {
      url = this.hostUrl.indexOf(urlPath) == -1 ? this.hostUrl + urlPath : this.hostUrl;
    }

    // Lisab tagasisuunamise URL-ile loginMode, sest muidu rakendus võtab endiselt konfist vaikimisi loginMode
    const loginModeParam = "loginMode=tara";
    if (url.indexOf(loginModeParam) == -1) {
      url += (url.indexOf("?") != -1 ? "&" : "?") + loginModeParam;
    }

    return url;
  }

}
