export class WeatherStationIndicator {
  key: string;
  label: string;
}

export class WeatherStationConstants {

  public static weatherStationIndicators: WeatherStationIndicator[] = [
    {
      key: "air-temperature",
      label: $localize`Air temperature`,
    },
    {
      key: "air-pressure",
      label: $localize`Air pressure`,
    },
    {
      key: "visibility",
      label: $localize`Visibility`,
    },
    {
      key: "precip",
      label: $localize`Precipitation`,
    },
    {
      key: "relative-humidity",
      label: $localize`Humidity`,
    },
    {
      key: "wind-direction",
      label: $localize`Wind direction`,
    },
    {
      key: "wind-speed",
      label: $localize`Wind speed`,
    },
    {
      key: "wind-speed-max",
      label: $localize`Wind speed max`,
    },
    {
      key: "water-level",
      label: $localize`Water level`,
    },
    {
      key: "water-level-eh-2000",
      label: $localize`Water level EH2000`,
    },
    {
      key: "water-temperature",
      label: $localize`Water temperature`,
    },
    {
      key: "uv-index",
      label: $localize`UV Index`,
    },
  ]

}
