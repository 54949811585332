import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {MyDataConfig} from '../../mydata-config.model';
import {SMARTENCITY_MYDATA_CONFIG} from '../../injection-tokens';
import {Subject} from 'rxjs/internal/Subject';

@Component({
  selector: 'mydata-managed-series',
  templateUrl: './managed-sources.component.html',
  providers: []
})
export class ManagedSourcesComponent implements OnInit, OnDestroy {
  private ngDestroy = new Subject<void>();

  constructor(
    private http: HttpClient,
    private router: Router,
    @Inject(SMARTENCITY_MYDATA_CONFIG) private config: MyDataConfig
  ) {
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }
}
