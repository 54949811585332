import {Inject, Injectable, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs/internal/Subject';
import {FormControl, FormGroup} from '@angular/forms';
import {SMARTENCITY_MYDATA_CONFIG} from '../../injection-tokens';
import {MyDataConfig} from '../../mydata-config.model';
import {SharedKeywordStore} from '../../services/shared-search-keyword-store.service';

@Injectable()
export class DatapointGroupsIndexService implements OnDestroy {
  private ngDestroy = new Subject<void>();

  public filterForm = new FormGroup({
    query: new FormControl(''),
    seriesTypes: new FormControl([]),
    confirmationStatusPending: new FormControl(false),
    confirmationStatusAccepted: new FormControl(false),
    confirmationStatusDeclined: new FormControl(false),
    showOnCityPortalTrue: new FormControl(false),
    showOnCityPortalFalse: new FormControl(false),
  });

  constructor(@Inject(SMARTENCITY_MYDATA_CONFIG) private config: MyDataConfig, private sharedKeyWordStore: SharedKeywordStore) {
    this.filterForm.get('query').valueChanges.subscribe((value) => {
      if (value != this.sharedKeyWordStore.state) {
        this.sharedKeyWordStore.setState(value);
      }
    });
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
    this.filterForm.get("query").setValue('', { emitEvent: false });
  }

  public resetLists(): void {
    this.filterForm.patchValue({
      query: this.sharedKeyWordStore.state
    });
  }

}
