import {Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {
  Location, Person,
  SourceOwnerSeries, AuthService, PageResponse, PersonSeriesPeriod, SCREEN_SIZE, ResizeService, SeriesService, PreventCloseModalService
} from '@smartencity/core';
import {HttpClient} from '@angular/common/http';
import {MyDataConfig} from '../../mydata-config.model';
import {SMARTENCITY_MYDATA_CONFIG} from '../../injection-tokens';
import {FormControl, FormGroup} from '@angular/forms';
import {Subject} from 'rxjs/internal/Subject';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  shareReplay,
  startWith,
  switchMap,
  takeUntil,
  withLatestFrom
} from 'rxjs/operators';
import {BsModalService} from 'ngx-bootstrap/modal';
import {ToastrService} from 'ngx-toastr';
import {NavbarService} from '../navbar/navbar.service';
import {SetOwnerModalComponent} from './set-owner-modal/set-owner-modal.component';
import {OwnerHistoryModalComponent} from './owner-history-modal.component';
import {ConsentModalComponent} from '../consumption/person-series/consent/consent-modal.component';
import {of, throwError, merge, combineLatest} from 'rxjs';
import {DatatableComponent} from '@swimlane/ngx-datatable';

const selector = 'mydata-managed-series-list';
let nextId = 0;

@Component({
  selector: selector,
  templateUrl: './managed-series-list.component.html'
})
export class ManagedSeriesListComponent implements OnInit, OnDestroy {
  id = `${selector}-${nextId++}`;
  private ngDestroy = new Subject<void>();

  @ViewChild('navActions', {static: true})
  public navTempalte: TemplateRef<any>;

  @ViewChild('table') table: DatatableComponent;
  public columns = {
    detailToggler: false,
    check: true,
    name: true,
    address: true,
    owner: true,
    history: true,
    activityDate: true,
    status: true,
    actions: true
  };

  public me: Person;

  public page: PageResponse<SourceOwnerSeries>;
  public defaultLimit = 40;
  public defaultSorts = [{prop: 'name', dir: 'asc'}];

  public filterForm: FormGroup = new FormGroup({
    query: new FormControl(),
    confirmationStatuses: new FormControl()
  });

  public filter$ = combineLatest([
    of(null), // this.currentLocationService.currentLocation$,
    this.filterForm.valueChanges.pipe(distinctUntilChanged(), debounceTime(250), startWith({}))
  ]).pipe(map(([location, filter]: [Location, any]) => Object.assign({location: location}, filter)));
  public page$: Subject<any> = new Subject<any>();
  public sorts$: Subject<any> = new Subject<any>();
  public limit$: Subject<any> = new Subject<any>();

  public filterSortsLimit$ = combineLatest([
    this.filter$,
    this.sorts$.pipe(startWith(this.defaultSorts)),
    this.limit$.pipe(startWith(this.defaultLimit))
  ]).pipe(shareReplay(1));

  public queryParams$ = merge(
    this.filterSortsLimit$.pipe(map(([filter, sorts, limit]: [any, any[], number]) => [filter, sorts, limit, {offset: 0, limit: limit}])),
    this.page$.pipe(
      withLatestFrom(this.filterSortsLimit$),
      map(([page, [filter, sorts, limit]]: [any, [any, any[], number]]) => [filter, sorts, limit, page])
    )
  ).pipe(
    map(([filter, sorts, limit, page]) => this.mapQueryParams(filter, sorts, limit, page)),
    takeUntil(this.ngDestroy),
    shareReplay(1)
  );
  public reload$ = new Subject<void>();
  public rows$ = combineLatest([this.queryParams$, this.reload$.pipe(startWith(null as void))]).pipe(
    switchMap(([queryParams, ]: [any, any]) => this.fetchPage(queryParams)),
    map((data: PageResponse<SourceOwnerSeries>) => this.mapPageResultsToRows(data)),
    takeUntil(this.ngDestroy)
  );

  public rows: SourceOwnerSeries[];
  public selected = [];
  public spinnersCount = 0;

  statusOptions: any[] = [
    {value: 'ACCEPTED', label: $localize`Accepted`},
    {value: 'DECLINED', label: $localize`Declined`},
    {value: 'PENDING', label: $localize`Pending`},
  ];

  constructor(
    private http: HttpClient,
    @Inject(SMARTENCITY_MYDATA_CONFIG) private config: MyDataConfig,
    private navbarService: NavbarService,
    private toastr: ToastrService,
    private authService: AuthService,
    private modalService: BsModalService,
    private preventCloseModalService: PreventCloseModalService,
    private resizeService: ResizeService,
    private seriesService: SeriesService
  ) {
    this.rows$.subscribe((rows) => {
      this.selected = [];
      this.rows = rows;
    });

    this.resizeService.onResize$.pipe(takeUntil(this.ngDestroy)).subscribe(this.adjustColumns.bind(this));
  }

  ngOnInit() {
    this.navbarService.setActionsTemplate(this.navTempalte);
    this.me = this.authService?.profileData?.mandate?.person;
  }

  ngOnDestroy() {
    this.navbarService.setActionsTemplate(null);
    this.reload$.complete();
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  mapQueryParams(filter: any, sorts: any[], limit: number, page: any) {
    const queryParams: any = {
      groupingTag: filter.location ? filter.location.groupingTag : null,
      locationType: filter.location ? filter.location.locationType : null,
      address: filter.location ? filter.location.address : null,
      lat: filter.location ? filter.location.lat : null,
      lng: filter.location ? filter.location.lng : null,
      uuid: filter.location ? filter.location.uuid : null
    };
    if (filter) {
      if (filter.confirmationStatuses) {
        queryParams.confirmationStatuses = filter.confirmationStatuses.join(',');
      }
      if (filter.query) {
        queryParams.query = filter.query;
      }
    }
    if (page) {
      queryParams.page = page.offset;
      queryParams.size = limit;
    }
    if (limit) {
      queryParams.size = limit;
    }
    if (sorts && sorts.length > 0) {
      queryParams.sortFields = sorts.reduce((accumulator, currentValue) => {
        if (currentValue.dir === 'desc') {
          return accumulator + encodeURIComponent('-' + currentValue.prop);
        } else {
          return accumulator + '+' + currentValue.prop;
        }
      }, '');
    }

    return queryParams;
  }

  fetchPage(queryParams: any) {
    const params = Object.assign({}, queryParams);
    for (const i of Object.keys(params)) {
      if (params[i] == null) {
        delete params[i];
      }
    }

    this.spinnersCount++;
    return this.http.get(this.config.apiUrl + '/person-series/source-owner-series/', {
      params: params
    }).pipe(catchError((err) => {
      this.spinnersCount--;
      return throwError(err);
    }));
  }

  mapPageResultsToRows(data: PageResponse<SourceOwnerSeries>) {
    this.page = data;
    const rows = [];
    for (const series of data.content) {
      rows.push({
        id: series.id,
        series: series,
        name: series.personSeries.name,
        updatedAt: series.updatedAt,
        ownerType: series.lastPeriod.ownerType,
        confirmationStatus: series.lastPeriod.confirmationStatus
      });
    }

    return rows;
  }

  checkedCount(): number {
    return this.selected.length;
  }

  setPage(event: any) {
    this.page$.next(event);
  }

  setSorts(event: any) {
    this.sorts$.next(event.sorts);
  }

  onSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }

  setDataOwner() {
    const modalRef = this.modalService.show(SetOwnerModalComponent, {initialState: {rows: this.selected.map((r) => ({
      checkControl: new FormControl(true),
      series: r.series
    }))}});
    modalRef.content.savedEmitter.pipe(takeUntil(this.ngDestroy)).subscribe(() => {
      this.reload$.next();
    });
  }

  setRowDataOwner(row: any) {
    const modalRef = this.modalService.show(SetOwnerModalComponent, {initialState: {rows: [row].map((r) => ({
      checkControl: new FormControl(true),
      series: r.series
    }))}});
    modalRef.content.savedEmitter.pipe(takeUntil(this.ngDestroy)).subscribe(() => {
      this.reload$.next();
    });
  }

  showConsents(series: SourceOwnerSeries) {
    const modalRef = this.preventCloseModalService.show(ConsentModalComponent, {
      class: 'modal-lg',
      initialState: {
        lastDataOwnerPeriod: series.lastDataOwnerPeriod,
        personSeries: series.personSeries
      }
    });
  }

  cancelOwnership(series: SourceOwnerSeries) {
    this.seriesService.cancelOwnerShip(series).pipe(takeUntil(this.ngDestroy)).subscribe(() => {
      this.toastr.success('Cancelled');
      this.reload$.next();
    });
  }

  showHistory(series: SourceOwnerSeries) {
    this.seriesService.getPeriods(series).pipe(takeUntil(this.ngDestroy)).subscribe((list: PersonSeriesPeriod[]) => {
      this.modalService.show(OwnerHistoryModalComponent, {initialState: {list: list}});
    });
  }

  ownerSaved() {
    this.toastr.success('Owner set');
    this.reload$.next();
  }

  clearSearch() {
    this.filterForm.get('query').setValue('');
  }

  toggleExpandRow(row) {
    this.table.rowDetail.toggleExpandRow(row);
  }

  private adjustColumns(size): void {
    if (size != null && size < SCREEN_SIZE.MD) {
      this.columns.detailToggler = true;
      this.columns.check = false;
      this.columns.address = false;
      this.columns.owner = false;
      this.columns.history = false;
      this.columns.activityDate = false;
      this.columns.status = false;
      this.columns.actions = false;
    } else {
      this.columns.detailToggler = false;
      this.columns.check = true;
      this.columns.address = true;
      this.columns.owner = true;
      this.columns.history = true;
      this.columns.activityDate = true;
      this.columns.status = true;
      this.columns.actions = true;
      if (this.table) {
        this.table.rowDetail.collapseAllRows();
      }
    }
  }

}
