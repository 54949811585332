import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../auth/auth.service';
import {Subject} from 'rxjs/internal/Subject';
import {takeUntil} from 'rxjs/operators';
import {CoreConfig, SMARTENCITY_CORE_CONFIG} from '@smartencity/core';

@Component({
  selector: 'app-auth-redirect',
  templateUrl: './auth-redirect.component.html'
})
export class AuthRedirectComponent implements OnInit, OnDestroy {

  private ngDestroy = new Subject<void>();

  constructor(@Inject(SMARTENCITY_CORE_CONFIG) private config: CoreConfig,
              private route: ActivatedRoute,
              private authService: AuthService,
              private router: Router) { }

  ngOnInit(): void {

    this.authService.urlAuthResult$.pipe(takeUntil(this.ngDestroy)).subscribe(result => {
      if (result.result && result.redirectTo) {
        window.location.href = result.redirectTo;
      } else {
        this.router.navigateByUrl("/");
      }
    });

    this.route.queryParams.subscribe((urlParams: any) => {
      if (urlParams['loginMode']) {
        this.authService.setLoginMode(urlParams['loginMode'], true);
      }
      this.authService.handleAuthorization(urlParams);
    });
  }


  ngOnDestroy() {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }


}
