export class SiteLocationNameHelper {

  public static getInLocationPhrase(locationName: string, locale: string): any {
    if (locationName === 'Tallinn' && locale === 'et') {
      return 'Tallinnas';
    }

    return locationName;
  }

}
