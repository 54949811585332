import {Injectable} from '@angular/core';
import {ReplaySubject} from 'rxjs';
import {UnitPipe} from '@smartencity/core';

@Injectable()
export class GasMapService {

  public solarParkSelectedSource = new ReplaySubject<any>();
  public solarParkSelected$ = this.solarParkSelectedSource.asObservable();

  constructor(private unitPipe: UnitPipe) {
  }

  public transformUnit(value: string, exponent: string): string {
    return this.unitPipe.transform(value, exponent);
  }

}
