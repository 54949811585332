import {NgModule} from '@angular/core';
import {LightsComponent} from './lights.component';
import {CommonModule} from '@angular/common';
import {MatSliderModule} from '@angular/material/slider';
import {FormsModule} from '@angular/forms';
import {CoreModule} from '@smartencity/core';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    CoreModule,
    MatSliderModule
  ],
  declarations: [
    LightsComponent
  ]
})
export class LightsModule { }
