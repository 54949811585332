import {AbstractMapLayerMarker, OlMapWrapper} from '@smartencity/core';
import { EecBuilding } from '../model/eec-building';
import { EecBuildingOlMarkerWrapper } from './eec-building-ol-marker-wrapper';

export class EecBuildingOlMarker extends AbstractMapLayerMarker<OlMapWrapper, EecBuilding> {

  private marker: EecBuildingOlMarkerWrapper;
  private selected = false;

  constructor(eecBuilding: EecBuilding, map: OlMapWrapper) {
    super(eecBuilding);
    this.marker = new EecBuildingOlMarkerWrapper(eecBuilding, map, {
      title: eecBuilding.address,
      position: [eecBuilding.lng, eecBuilding.lat]
    });
  }

  draw() {
    this.marker.draw();
  }

  setMap(map: OlMapWrapper) {
    super.setMap(map);
    const olMap = (map ? map.getMap() : null);
    this.marker.setMap(olMap);
  }

  isSelected(): boolean {
    return this.selected;
  }

  setSelected(selected: boolean): void {
    this.selected = selected;
  }
}
