import { Component, OnInit } from '@angular/core';
import { Stop } from '../../model/stop';
import { Bus } from '../../model/bus';
import { MobilitySearchResults } from '../../model/search/mobility-search-results';
import { MobilityGmapService } from '../../service/mobility-gmap.service';
import { MobilitySearchService } from '../../service/mobility-search.service';
import {FormControl} from '@angular/forms';
import {debounceTime, distinctUntilChanged, takeUntil, tap} from 'rxjs/operators';
import {ThroughputPoint} from '../../model/traffic';
import {EvCharger} from '../../model/ev-charger';
import {BicycleStation} from '../bicycle-stations-heatmap/bicylce-stations.model';
import {EvChargerDk} from '../../model/ev-charger-dk';
import {Subject} from 'rxjs/internal/Subject';
import {MobilityService} from '../../service/mobility.service';

@Component({
  selector: 'mobility-search',
  templateUrl: './search.component.html'
})
export class SearchComponent implements OnInit {
  private ngDestroy = new Subject<void>();

  private searching = false;
  public buses: Bus[] = [];
  public stops: Stop[] = [];
  public throughputPoints: ThroughputPoint[] = [];
  public evChargers: EvCharger[] = [];
  public evChargersDk: EvChargerDk[] = [];
  public bicycleStations: BicycleStation[] = [];

  //TODO: hoia gmapservice state-i ja uuenda seda

  public keywordControl = new FormControl();
  public keywordSearch$ = this.keywordControl.valueChanges.pipe(
    tap((value) => {
      if (!this.searching && value !== '') {
        this.searching = true;
        this.mobilityService.clearSelected();
      }

      if (this.searching && value === '') {
        this.searching = false;
      }
    }),
    debounceTime(300),
    distinctUntilChanged()
  );

  constructor(
    private mobilityMapService: MobilityGmapService,
    private mobilityService: MobilityService,
    private mobilitySearchService: MobilitySearchService
  ) {
  }

  ngOnInit() {
    this.mobilitySearchService.initSearch(this.keywordSearch$).pipe(takeUntil(this.ngDestroy)).subscribe((results: MobilitySearchResults) => {
      this.buses = results.buses;
      this.stops = results.stops;
      this.throughputPoints = results.throughputPoints;
      this.evChargers = results.evChargers;
      this.bicycleStations = results.bicycleStations;
      this.evChargersDk = results.evChargersDk;
    });

    this.mobilityMapService.locationSelect$.subscribe(() => {
      this.clear();
    });
  }

  selectStop(stop: Stop) {
    this.clear();
    this.mobilityService.selectStop(stop);
  }

  selectBus(bus: Bus) {
    this.clear();
    this.mobilityService.selectBus(bus);
  }

  selectEvCharger(evCharger: EvCharger): void {
    this.clear();
    this.mobilityMapService.panToByCoords(evCharger.lat, evCharger.lng);
    this.mobilityService.selectEvCharger(evCharger);
  }

  selectEvChargerDk(evCharger: EvChargerDk): void {
    this.clear();
    this.mobilityMapService.panToByCoords(evCharger.lat, evCharger.lng);
    this.mobilityService.selectEvChargerDk(evCharger);
  }

  selectThroughputPoint(point: ThroughputPoint): void {
    this.clear();
    this.mobilityMapService.panToByCoords(point.lat, point.lng);
    this.mobilityService.selectThroughputPoint(point);
  }

  selectBicycleStation(station: BicycleStation): void {
    this.clear();
    this.mobilityMapService.panToByCoords(station.lat, station.lng);
    this.mobilityService.selectBicycleStation(station);
  }

  clear() {
    this.buses = [];
    this.stops = [];
    this.throughputPoints = [];
    this.evChargers = [];
    this.bicycleStations = [];
    this.evChargersDk = [];
    this.keywordControl.setValue('');
  }

}
