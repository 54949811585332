<div class="container-fluid p-4">
  <div class="row justify-content-center mb-5">
    <div class="col-sm-12 col-md-6 col-lg-4">
      <div class="input-group input-group-lg form-search" [formGroup]="filterForm">
        <input type="text" class="form-control form-control-lg" placeholder="Type to search..." i18n-placeholder formControlName="query">
        <div class="input-group-append">
          <span class="clear-search" (click)="clearSearch()">
            <i class="fal fa-times"></i>
          </span>
          <div class="custom-dropdown filter-dropdown" dropdown [autoClose]="true" (click)="$event.stopImmediatePropagation();">
            <button type="button" class="btn dropdown-toggle" dropdownToggle i18n>Filter</button>
            <div class="dropdown-menu dropdown-menu-right" *dropdownMenu>
              <div class="accordion-group"  [ngClass]="'show'">
                <div class="accordion-header">
                  <i class="fal fa-angle-down"></i>
                  <ng-container i18n>Status</ng-container>
                </div>
                <div class="accordion-content">
                  <div class="checkbox">
                    <input type="checkbox" formControlName="statusPending">
                    <label i18n>{{'PENDING' | label}}</label>
                  </div>
                  <div class="checkbox">
                    <input type="checkbox" formControlName="statusInProgress">
                    <label i18n>{{'IN_PROGRESS' | label}}</label>
                  </div>
                  <div class="checkbox">
                    <input type="checkbox" formControlName="statusCompleted">
                    <label>{{'COMPLETED' | label}}</label>
                  </div>
                  <div class="checkbox">
                    <input type="checkbox" formControlName="statusDeclined">
                    <label>{{'DECLINED' | label}}</label>
                  </div>
                </div>
              </div>
              <div class="accordion-group show">
                <div class="accordion-header">
                  <i class="fal fa-angle-down"></i>
                  <ng-container i18n>Questioner</ng-container>
                </div>
                <div class="accordion-content">
                  <input class="form-control" formControlName="personQuery">
                </div>
              </div>
            </div>
          </div>
          <span class="input-group-text">
            <i class="fal fa-search"></i>
          </span>
        </div>
      </div>
    </div>
    <div class="col-12 mt-2">
      <div class="d-flex flex-wrap flex-md-nowrap justify-content-center" *ngIf="filterForm.get('statusPending').value || filterForm.get('statusInProgress').value || filterForm.get('personQuery').value">
        <label i18n>Filter</label>
        <span class="filter-tag" *ngIf="filterForm.get('statusPending').value">{{'PENDING' | label}} <i class="fal fa-times" (click)="filterForm.get('statusPending').setValue(false)"></i></span>
        <span class="filter-tag" *ngIf="filterForm.get('statusInProgress').value">{{'IN_PROGRESS' | label}} <i class="fal fa-times" (click)="filterForm.get('statusInProgress').setValue(false)"></i></span>
        <span class="filter-tag" *ngIf="filterForm.get('statusCompleted').value">{{'COMPLETED' | label}} <i class="fal fa-times" (click)="filterForm.get('statusCompleted').setValue(false)"></i></span>
        <span class="filter-tag" *ngIf="filterForm.get('statusDeclined').value">{{'DECLINED' | label}} <i class="fal fa-times" (click)="filterForm.get('statusDeclined').setValue(false)"></i></span>
        <span class="filter-tag" *ngIf="filterForm.get('personQuery').value"><ng-container i18n>Questioner</ng-container>: {{filterForm.get('personQuery').value}} <i class="fal fa-times" (click)="filterForm.get('personQuery').setValue('')"></i></span>
      </div>
    </div>
  </div>




  <section class="mb-4">
    <h2 class="section-title"><ng-container i18n>Active</ng-container>&nbsp;({{(activeRows$ | async)?.length || 0}})</h2>
    <div class="row">
      <div class="col-12">
        <ngx-datatable
          #tableActive
          class="material expandable"
          [draggable]="false"
          [columnMode]="'flex'"
          [headerHeight]="30"
          [footerHeight]="50"
          [reorderable]="false"
          rowHeight="auto"
          [scrollbarH]="false"
          [scrollbarV]="false"
          [rows]="activeRows$ | async"
          [externalPaging]="false"
          [externalSorting]="false"
        >
          <ngx-datatable-row-detail [rowHeight]="120" #detailRow>
            <ng-template
              let-row="row"
              let-name="row.questionnaire.name"
              let-status="row.status"
              let-createdAt="row.createdAt"
              ngx-datatable-row-detail-template
            >
              <div style="font-size:14px; padding: 0.7rem">
                <div class="row">
                  <div class="col-6"><label i18n>Status</label></div>
                  <div class="col-6">
                    <span class="status" [ngSwitch]="status">
                      <i class="icon-status status-pending" *ngSwitchCase="'PENDING'"></i>
                      <i class="icon-status status-cancel" *ngSwitchCase="'CANCELLED'"></i>
                      <i class="icon-status status-cancel" *ngSwitchCase="'DECLINED'"></i>
                      <i class="icon-status status-in-progress" *ngSwitchCase="'IN_PROGRESS'"></i>
                      <i class="icon-status status-success" *ngSwitchCase="'COMPLETED'"></i>
                      {{status | label}}
                    </span>
                  </div>
                </div>

                <div class="row">
                  <div class="col-6"><label i18n>Received at</label></div>
                  <div class="col-6">{{createdAt | date:'dd. MMMM yyyy'}}</div>
                </div>
              </div>
            </ng-template>
          </ngx-datatable-row-detail>

          <ngx-datatable-column *ngIf="columns.name" name="Name" i18n-name prop="questionnaire.name" [flexGrow]="2" [resizeable]="false" [sortable]="true">
            <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
              <a [routerLink]="null" class="title" (click)="viewResponse(row)">{{value}}</a>
              <span class="help-text">
                {{(row?.questionnaire?.person?.displayName ? '' + row?.questionnaire?.person?.displayName + ' ' : '')}}
                <i *ngIf="row?.responses.length > 1" class="icon-sm icon-reanswered ml-1"></i>
              </span>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column *ngIf="columns.status" name="Status" i18n-name prop="status" [flexGrow]="1" [resizeable]="false" [sortable]="false" [cellClass]="'justify-content-center'"  [headerClass]="'text-center'">
            <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
              <span class="status" [ngSwitch]="value">
                <i class="icon-status status-pending" *ngSwitchCase="'PENDING'"></i>
                <i class="icon-status status-cancel" *ngSwitchCase="'CANCELLED'"></i>
                <i class="icon-status status-cancel" *ngSwitchCase="'DECLINED'"></i>
                <i class="icon-status status-in-progress" *ngSwitchCase="'IN_PROGRESS'"></i>
                <i class="icon-status status-success" *ngSwitchCase="'COMPLETED'"></i>
                {{value | label}}
              </span>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column *ngIf="columns.createdAt" name="Received at" i18n-name prop="createdAt" [flexGrow]="1" [resizeable]="false" [sortable]="false">
            <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>{{value | date:'dd. MMMM yyyy'}}</ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            *ngIf="columns.detailToggler"
            [width]="50"
            [resizeable]="false"
            [sortable]="false"
            [draggable]="false"
            [canAutoResize]="false"
            headerClass="justify-content-end"
            cellClass="justify-content-end"
          >
            <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
              <a
                [routerLink]="null"
                [class.datatable-icon-right]="!expanded"
                [class.datatable-icon-down]="expanded"
                title="Expand/Collapse Row"
                (click)="toggleExpandRow(tableActive, row)"
              >
              </a>
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>
      </div>
    </div>
  </section>

  <section class="mb-4">
    <h2 class="section-title"><ng-container i18n>Inactive</ng-container>&nbsp;({{(inactiveRows$ | async)?.length || 0}})</h2>
    <div class="row">
      <div class="col-12">
        <ngx-datatable
          #tableInactive
          class="material expandable"
          [draggable]="false"
          [columnMode]="'flex'"
          [headerHeight]="30"
          [footerHeight]="50"
          [reorderable]="false"
          rowHeight="auto"
          [scrollbarH]="false"
          [scrollbarV]="false"
          [rows]="inactiveRows$ | async"
          [externalPaging]="false"
          [externalSorting]="false"
        >
          <ngx-datatable-row-detail [rowHeight]="140" #detailRow>
            <ng-template
              let-row="row"
              let-name="row.questionnaire.name"
              let-status="row.status"
              let-createdAt="row.createdAt"
              ngx-datatable-row-detail-template
            >
              <div style="font-size:14px; padding: 0.7rem">

                <div class="row">
                  <div class="col-6"><label i18n>Status</label></div>
                  <div class="col-6">
                    <span class="status" [ngSwitch]="status">
                      <i class="icon-status status-pending" *ngSwitchCase="'PENDING'"></i>
                      <i class="icon-status status-cancel" *ngSwitchCase="'CANCELLED'"></i>
                      <i class="icon-status status-cancel" *ngSwitchCase="'DECLINED'"></i>
                      <i class="icon-status status-in-progress" *ngSwitchCase="'IN_PROGRESS'"></i>
                      <i class="icon-status status-success" *ngSwitchCase="'COMPLETED'"></i>
                      {{status | label}}
                    </span>
                  </div>
                </div>

                <div class="row">
                  <div class="col-6"><label i18n>Received at</label></div>
                  <div class="col-6">{{createdAt | date:'dd. MMMM yyyy'}}</div>
                </div>

                <div class="row">
                  <div class="col-6"><label i18n>Completed at</label></div>
                  <div class="col-6">{{updatedAt | date:'dd. MMMM yyyy'}}</div>
                </div>
              </div>
            </ng-template>
          </ngx-datatable-row-detail>

          <ngx-datatable-column *ngIf="columns.name" name="Name" i18n-name prop="questionnaire.name" [flexGrow]="3" [resizeable]="false" [sortable]="true">
            <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
              <a [routerLink]="null" class="title" (click)="viewResponse(row)">{{value}}</a>
              <span class="help-text">{{(row?.questionnaire?.person?.displayName ? '' + row?.questionnaire?.person?.displayName + ' ' : '')}}
                <i *ngIf="row?.responses.length > 1" class="icon-sm icon-reanswered ml-1"></i>
              </span>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column *ngIf="columns.status" name="Status" i18n-name prop="status" [flexGrow]="1" [resizeable]="false" [sortable]="false" [cellClass]="'justify-content-center'"  [headerClass]="'text-center'">
            <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
              <span class="status" [ngSwitch]="value">
                <i class="icon-status status-pending" *ngSwitchCase="'PENDING'"></i>
                <i class="icon-status status-cancel" *ngSwitchCase="'CANCELLED'"></i>
                <i class="icon-status status-cancel" *ngSwitchCase="'DECLINED'"></i>
                <i class="icon-status status-in-progress" *ngSwitchCase="'IN_PROGRESS'"></i>
                <i class="icon-status status-success" *ngSwitchCase="'COMPLETED'"></i>
                {{value | label}}
              </span>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column *ngIf="columns.createdAt" name="Received at" i18n-name prop="createdAt" [flexGrow]="1" [resizeable]="false" [sortable]="false">
            <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>{{value | date:'dd. MMMM yyyy'}}</ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column *ngIf="columns.updatedAt" name="Completed at" i18n-name prop="updatedAt" [flexGrow]="1" [resizeable]="false" [sortable]="false">
            <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>{{value | date:'dd. MMMM yyyy'}}</ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column *ngIf="columns.actions" [flexGrow]="1" [resizeable]="false" [sortable]="false" [cellClass]="'justify-content-end'">
            <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
              <div class="btn-group btn-more" dropdown>
                <button type="button" class="btn btn-icon dropdown-toggle" dropdownToggle><i class="far fa-ellipsis-v"></i></button>
                <div class="dropdown-menu dropdown-menu-right" *dropdownMenu>
                  <a class="dropdown-item" (click)="addQuestonnaireGraphsToDashboard(row)" *ngIf="row.widgetTemplates && row.widgetTemplates.length > 0">
                    <i class="fal fa-plus-square"></i>&nbsp;<ng-container i18n>Add questionnaire graph to dashboard
                  </ng-container></a>
                  <a class="dropdown-item" (click)="viewResponse(row)"><i class="fal fa-edit"></i>&nbsp;<ng-container i18n>Create a new response</ng-container></a>
                </div>
              </div>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            *ngIf="columns.detailToggler"
            [width]="50"
            [resizeable]="false"
            [sortable]="false"
            [draggable]="false"
            [canAutoResize]="false"
            headerClass="justify-content-end"
            cellClass="justify-content-end"
          >
            <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
              <a
                [routerLink]="null"
                [class.datatable-icon-right]="!expanded"
                [class.datatable-icon-down]="expanded"
                title="Expand/Collapse Row"
                (click)="toggleExpandRow(tableInactive, row)"
              >
              </a>
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>
      </div>
    </div>
  </section>
</div>
