<div class="modal-header">
  <h5 class="modal-title w-100" i18n>Owner history</h5>
  <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
    <span class="fal fa-times"></span>
  </button>
</div>
<div class="modal-body">
  <table class="table table-striped">
    <thead>
    <tr>
      <th scope="col" i18n>Owner</th>
      <th scope="col" i18n>Start date</th>
      <th scope="col" i18n>End date</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let row of list">
      <th scope="row">{{row.person | regNo}}</th>
      <td *ngIf="!isEpoch(row.startAt)">{{row.startAt | date:'dd.MM.yyyy'}}</td>
      <td *ngIf="isEpoch(row.startAt)">-</td>
      <td>{{row.endAt | date:'dd.MM.yyyy'}}</td>
    </tr>
    </tbody>
  </table>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-light btn-sm" aria-label="Close" (click)="close()" i18n>Close</button>
</div>
