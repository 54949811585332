import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs/internal/Subject';
import {QuestionnaireReference} from '@smartencity/core';

@Component({
  selector: 'mydata-view-results',
  templateUrl: './view-results.component.html',
  styleUrls: ['./view-results.component.css']
})
export class ViewResultsComponent implements OnInit, OnDestroy {
  private ngDestroy = new Subject<void>();
  public view = 'summary';

  @Input()
  public questionnaire: QuestionnaireReference;

  constructor() { }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  ngOnInit(): void {
  }

  setView(view: string) {
    this.view = view;
  }
}
