<div class="modal-header align-items-center">
  <div class="d-flex align-items-stretch flex-column">
    <h5 class="modal-title w-100" i18n>Logs</h5>
  </div>
  <button type="button" class="close pull-right" i18n-aria-label aria-label="Close" (click)="close()">
    <i class="fal fa-times"></i>
  </button>
</div>
<div class="modal-body" style="min-height: calc(100vh - 100px)" #scrollingBlock>
    <div class="row mb-5">
      <div class="col-sm-12 col-md-12 offset-lg-3 col-lg-6">
        <div class="input-group input-group-lg form-search" [formGroup]="filterForm">
          <input type="text" class="form-control form-control-lg" placeholder="Type to search..." i18n-placeholder formControlName="query">
          <div class="input-group-append">
          <span class="clear-search" (click)="clearSearch()">
            <i class="fal fa-times"></i>
          </span>
            <div class="custom-dropdown filter-dropdown" dropdown [autoClose]="true" (click)="$event.stopImmediatePropagation();">
              <button type="button" class="btn dropdown-toggle" dropdownToggle i18n>Filter</button>
              <div class="dropdown-menu dropdown-menu-right" *dropdownMenu>
                <div class="accordion-group show">
                  <div class="accordion-header">
                    <i class="fal fa-angle-down"></i>
                    <ng-container i18n>Search term specification</ng-container>
                  </div>
                  <div class="accordion-content" formArrayName="queryCriteriaList">
                    <div class="checkbox"  *ngFor="let criteria of queryCriteriaListOptions; let i = index;">
                      <input type="checkbox" [formControlName]="i">
                      <label>{{criteria.label}}</label>
                    </div>
                  </div>
                </div>
                <div class="accordion-group show" *ngIf="type == auditLogTypeEnum.MANDATE">
                  <div class="accordion-header">
                    <i class="fal fa-angle-down"></i>
                    <ng-container i18n>Action</ng-container>
                  </div>
                  <div class="accordion-content" formArrayName="operations">
                    <div class="checkbox"  *ngFor="let operation of operationsOptions; let i = index;">
                      <input type="checkbox" [formControlName]="i">
                      <label>{{operation.label}}</label>
                    </div>
                  </div>
                </div>
                <ng-container *ngIf="type == auditLogTypeEnum.CONSENT">
                  <div class="accordion-group show" >
                    <div class="accordion-header">
                      <i class="fal fa-angle-down"></i>
                      <ng-container i18n>Consent type</ng-container>
                    </div>
                    <div class="accordion-content" formArrayName="consentTypes">
                      <div class="checkbox"  *ngFor="let consentType of consentTypesOptions; let i = index;">
                        <input type="checkbox" [formControlName]="i">
                        <label>{{consentType.label}}</label>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-group show" >
                    <div class="accordion-header">
                      <i class="fal fa-angle-down"></i>
                      <ng-container i18n>Consent status type</ng-container>
                    </div>
                    <div class="accordion-content" formArrayName="consentStatusTypes">
                      <div class="checkbox"  *ngFor="let statusType of consentStatusTypesOptions; let i = index;">
                        <input type="checkbox" [formControlName]="i">
                        <label>{{statusType.label}}</label>
                      </div>
                    </div>
                  </div>
                </ng-container>

              </div>
            </div>
            <span class="input-group-text">
            <i class="fal fa-search"></i>
          </span>
          </div>
        </div>
      </div>
      <div class="col-6">
        <!--      <div class="form-group">-->
        <!--        <ng-select placeholder="Status" i18n-placeholder formControlName="isOwnerPersonSeries" [items]="ownerPersonSeriesOptions" bindValue="value" bindLabel="label"></ng-select>-->
        <!--      </div>-->
      </div>
    </div>
    <div class="row" *ngIf="loading">Loading...</div>

    <div class="row list" *ngIf="!loading && items.length">
      <ng-container *ngIf="type == auditLogTypeEnum.MANDATE">
        <div class="col">
          <div class="list-header-item">
            <div class="row d-flex">
              <div class="col"><span i18n>Person</span></div>
              <div class="col"><span i18n>Action</span></div>
              <div class="col"><span i18n>To whom</span></div>
              <div class="col"><span i18n>Date</span></div>
              <div class="col"><span i18n>Change</span></div>
            </div>
          </div>
          <virtual-scroller #listScroll [items]="items" [parentScroll]="scrollingBlock" (vsChange)="chartVsChange($event)">
            <div #container>
              <div class="list-item" *ngFor="let log of listScroll.viewPortItems">
                <mandate-log-list-item [item]="log"></mandate-log-list-item>
              </div>
            </div>
          </virtual-scroller>
        </div>
      </ng-container>

      <ng-container *ngIf="type == auditLogTypeEnum.CONSENT">
        <div class="col">
          <div class="list-header-item">
            <div class="row d-flex">
              <div class="col"><span i18n>Person</span></div>
              <div class="col"><span i18n>Action</span></div>
              <div class="col"><span i18n>Consent type</span></div>
              <div class="col"><span i18n>Name</span></div>
              <div class="col"><span i18n>To whom</span></div>
              <div class="col"><span i18n>Date</span></div>
            </div>
          </div>
          <virtual-scroller #listScroll [items]="items" [parentScroll]="scrollingBlock" (vsChange)="chartVsChange($event)">
            <div #container>
              <div class="list-item" *ngFor="let log of listScroll.viewPortItems">
                <consent-log-list-item [item]="log"></consent-log-list-item>
              </div>
            </div>
          </virtual-scroller>
        </div>
      </ng-container>
    </div>
    <div class="row" *ngIf="!loading && !items.length">
      <div class="col" i18n>No logs</div>
    </div>


</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary btn-sm" aria-label="Close" (click)="close()" i18n>Close</button>
</div>
