import { Injectable } from '@angular/core';
import {GlobalBroadcastEventType} from '../models/global-broadcast-event-type';
import {GlobalBroadcastEvent} from '../models/global-broadcast-event';

@Injectable({
  providedIn: 'root'
})
export class GlobalBroadcastService {

  private channels = {};

  constructor() {
  }

  public subscribeToEvent(event: GlobalBroadcastEventType, callback: ((message: MessageEvent<GlobalBroadcastEvent>) => void)): void {
    this.createChannel(event).onmessage = callback;
  }

  public sendEvent(type: GlobalBroadcastEventType, event: GlobalBroadcastEvent): void {
    this.createChannel(type).postMessage(event);
  }

  private createChannel(type: GlobalBroadcastEventType): BroadcastChannel {
    let channel = this.channels[type];
    if (channel) {
      return channel;
    }

    channel = new BroadcastChannel(type);
    this.channels[channel] = channel;
    return channel;
  }
}
