<div class="container-fluid p-5">
  <ol class="breadcrumb">
    <li class="breadcrumb-item">
      <a routerLink=".." i18n>My data</a>
    </li>
    <li class="breadcrumb-item active">
      <a [routerLink]="" i18n>Advices</a>
    </li>
  </ol>
  <ng-container>
    <h1 i18n>Advices</h1>
  </ng-container>
  <div class="block mb-5">
    <mydata-advice-list></mydata-advice-list>
  </div>
</div>
