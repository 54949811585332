<div class="jumbotron floating-jumbotron content-wrapper" [class.active]="building">
  <div class="modal-header">
    <h5 class="modal-title w-100">{{building?.readableAddress ? building?.readableAddress : building?.address}}</h5>
    <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
      <i class="fal fa-times"></i>
    </button>
  </div>

  <div class="modal-body building-info">
    <div class="d-flex justify-content-center">
      <div class="bulding-chart position-relative">
        <div [ngClass]="building?.cityPortalType == 'POWER_STATION' ? 'power-station' : ''" class="building"></div>
        <ng-container *ngFor=" let series of building?.series">
          <div *ngIf="series.seriesType == 'WATER'"
               class="building-item building-item-cold-water align-items-center"
               style="top: 180px;left: 10px;">
            <div class="building-icon icon-cold-water">
              <span class="sr-only">{{aggregationGroupingTypeSymbolMap[series?.aggregationGroupingType]}}</span>
              <span class="value">{{series.value | number:'1.0-2'}} {{series.unit | displayUnit:'10'}}</span>
            </div>
            <div class="path" [ngClass]="{'animate': isAnimated(series)}">
              <svg height="10" width="50" class="line">
                <line class="dash-move-opposite" x1="0" y1="0" x2="50" y2="0"/>
              </svg>
            </div>
          </div>

          <div *ngIf="series.seriesType == 'HOT_WATER'"
               class="building-item building-item-hot-water align-items-center"
               style="top: 180px; right: 10px;">
            <div class="building-icon icon-hot-water">
              <span>{{aggregationGroupingTypeSymbolMap[series?.aggregationGroupingType]}}</span>
              <span class="value">{{series.value | number:'1.0-2'}} {{series.unit | displayUnit:'10'}}</span>
            </div>
            <div class="path" [ngClass]="{'animate': isAnimated(series)}">
              <svg height="10" width="50" class="line">
                <line class="dash-move " x1="0" y1="0" x2="50" y2="0"/>
              </svg>
            </div>
          </div>

          <div *ngIf="series.seriesType == 'ENERGY_CONSUMPTION'"
               class="building-item building-item-electricity align-items-center"
               style="bottom: 35px; right: 90px;">
            <div class="building-icon icon-electricity">
              <span class="sr-only">{{aggregationGroupingTypeSymbolMap[series?.aggregationGroupingType]}}</span>
              <span class="value bottom">{{series.value | number:'1.0-2'}} {{series.unit | displayUnit:'10'}}</span>
            </div>
            <div class="path" [ngClass]="{'animate': isAnimated(series)}">
              <svg height="10" width="50" class="line">
                <line class="dash-move " x1="0" y1="0" x2="50" y2="0"/>
              </svg>
            </div>
          </div>

          <div *ngIf="series.seriesType == 'GAS'"
               class="building-item building-item-gas align-items-center"
               style="bottom: 35px; left: 90px;">
            <div class="building-icon icon-gas">
              <span class="sr-only">{{aggregationGroupingTypeSymbolMap[series?.aggregationGroupingType]}}</span>
              <span class="value bottom">{{series.value | number:'1.0-2'}} {{series.unit | displayUnit:'10'}}</span>
            </div>
            <div class="path" [ngClass]="{'animate': isAnimated(series)}">
              <svg height="10" width="50" class="line">
                <line class="dash-move-opposite" x1="0" y1="0" x2="50" y2="0"/>
              </svg>
            </div>
          </div>

          <div *ngIf="series.seriesType == 'HEAT'"
               class="building-item building-item-heat align-items-center"
               style="top: 55px; right: 40px;">
            <div class="building-icon icon-heat">
              <span class="sr-only">{{aggregationGroupingTypeSymbolMap[series?.aggregationGroupingType]}}</span>
              <span class="value">{{series.value | number:'1.0-2'}} {{series.unit | displayUnit:'10'}}</span>
            </div>
            <div class="path" [ngClass]="{'animate': isAnimated(series)}">
              <svg height="10" width="50" class="line">
                <line [ngClass]="building?.cityPortalType == 'POWER_STATION' ? 'dash-move-opposite' : 'dash-move'" x1="0" y1="0" x2="50" y2="0"/>
              </svg>
            </div>
          </div>

          <div *ngIf="series.seriesType == 'ENERGY_PRODUCTION'"
               class="building-item building-item-solar align-items-center"
               style="top: 30px; left: 105px;">
            <div class="building-icon icon-solar">
              <span class="sr-only">{{aggregationGroupingTypeSymbolMap[series?.aggregationGroupingType]}}</span>
              <span class="value">{{series.value | number:'1.0-2'}} {{series.unit | displayUnit:'10'}}</span>
            </div>
            <div class="path" [ngClass]="{'animate': isAnimated(series)}">
              <svg height="50" width="10" class="line">
                <line class="dash-move-opposite " x1="0" y1="0" x2="0" y2="50"/>
              </svg>
            </div>
          </div>          
          
          <div *ngIf="series.seriesType == 'REACTIVE_POWER'"
               class="building-item building-item-reactive-power align-items-center"
               style="top: 60px; left: 45px;">
            <div class="building-icon icon-energy">
              <span class="sr-only">{{aggregationGroupingTypeSymbolMap[series?.aggregationGroupingType]}}</span>
              <span class="value">{{series.value | number:'1.0-2'}} {{series.unit | displayUnit:'10'}}</span>
            </div>
            <div class="path" [ngClass]="{'animate': isAnimated(series)}">
              <svg height="50" width="10" class="line">
                <line class="dash-move " x1="0" y1="0" x2="0" y2="50"/>
              </svg>
            </div>
          </div>
          
          <div *ngIf="series.seriesType == 'TRUE_POWER'"
               class="building-item building-item-true-power align-items-center"
               style="top: 110px; right: 10px;">
            <div class="building-icon icon-energy">
              <span class="sr-only">{{aggregationGroupingTypeSymbolMap[series?.aggregationGroupingType]}}</span>
              <span class="value">{{series.value | number:'1.0-2'}} {{series.unit | displayUnit:'10'}}</span>
            </div>
            <div class="path" [ngClass]="{'animate': isAnimated(series)}">
              <svg height="50" width="10" class="line">
                <line class="dash-move-opposite " x1="0" y1="0" x2="0" y2="50"/>
              </svg>
            </div>
          </div>          
          
          <div *ngIf="series.seriesType == 'APPARENT_POWER'"
               class="building-item building-item-apparent-power align-items-center"
               style="bottom: 65px; left: 30px;">
            <div class="building-icon icon-energy">
              <span class="sr-only">{{aggregationGroupingTypeSymbolMap[series?.aggregationGroupingType]}}</span>
              <span class="value">{{series.value | number:'1.0-2'}} {{series.unit | displayUnit:'10'}}</span>
            </div>
            <div class="path" [ngClass]="{'animate': isAnimated(series)}">
              <svg height="50" width="10" class="line">
                <line class="dash-move-opposite " x1="0" y1="0" x2="0" y2="50"/>
              </svg>
            </div>
          </div> 
          
          <div *ngIf="series.seriesType == 'VOLTAGE'"
               class="building-item building-item-voltage align-items-center"
               style="bottom: 65px; right: 30px;">
            <div class="building-icon icon-energy">
              <span class="sr-only">{{aggregationGroupingTypeSymbolMap[series?.aggregationGroupingType]}}</span>
              <span class="value">{{series.value | number:'1.0-2'}} {{series.unit | displayUnit:'10'}}</span>
            </div>
            <div class="path" [ngClass]="{'animate': isAnimated(series)}">
              <svg height="50" width="10" class="line">
                <line class="dash-move-opposite " x1="0" y1="0" x2="0" y2="50"/>
              </svg>
            </div>
          </div>         
          
          <div *ngIf="series.seriesType == 'CURRENT'"
               class="building-item building-item-current align-items-center"
               style="top: 30px; right: 105px;">
            <div class="building-icon icon-energy">
              <span class="sr-only">{{aggregationGroupingTypeSymbolMap[series?.aggregationGroupingType]}}</span>
              <span class="value">{{series.value | number:'1.0-2'}} {{series.unit | displayUnit:'10'}}</span>
            </div>
            <div class="path" [ngClass]="{'animate': isAnimated(series)}">
              <svg height="50" width="10" class="line">
                <line class="dash-move " x1="0" y1="0" x2="0" y2="50"/>
              </svg>
            </div>
          </div>          
          
          <div *ngIf="series.seriesType == 'POWER_TOTAL'"
               class="building-item building-item-power-total align-items-center"
               style="top: 110px; left: 20px;">
            <div class="building-icon icon-energy">
              <span class="sr-only">{{aggregationGroupingTypeSymbolMap[series?.aggregationGroupingType]}}</span>
              <span class="value">{{series.value | number:'1.0-2'}} {{series.unit | displayUnit:'10'}}</span>
            </div>
            <div class="path" [ngClass]="{'animate': isAnimated(series)}">
              <svg height="50" width="10" class="line">
                <line class="dash-move-opposite " x1="0" y1="0" x2="0" y2="50"/>
              </svg>
            </div>
          </div>

        </ng-container>

      </div>
    </div>
  </div>

  <div class="modal-footer justify-content-center">
    <button type="button" [hidden]="isInComparison" (click)="addToComparison()" class="btn btn-primary" i18n>Add to comparison</button>
    <button type="button" [hidden]="!isInComparison" (click)="removeFromComparison()" class="btn btn-primary" i18n>Remove from comparison</button>
  </div>
</div>
