<h3 class="section-title" i18n>{totalCount, plural, =1 {{{completedCount}} / {{totalCount}} participant} other {{{completedCount}} / {{totalCount}} participants}}</h3>

<ngx-datatable
  class="material"
  [draggable]="false"
  [columnMode]="'flex'"
  [headerHeight]="30"
  [footerHeight]="50"
  [reorderable]="false"
  rowHeight="auto"
  [scrollbarH]="false"
  [scrollbarV]="false"
  [rows]="rows"
  [externalPaging]="false"
  [externalSorting]="false"
>
  <ngx-datatable-column name="Name" i18n-name prop="person" [flexGrow]="1" [resizeable]="false" [sortable]="false">
    <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
      <ng-container *ngIf="value">
        <div>{{value.displayName}}</div>
      </ng-container>
      <ng-container *ngIf="row.inviteEmail">
        <span class="text-light text-uppercase">{{row.inviteEmail}}</span>
      </ng-container>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Status" i18n-name prop="status" [flexGrow]="1" [resizeable]="false" [sortable]="false">
    <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
      <span class="status" *ngIf="row.participant" [ngSwitch]="value">
        <i class="icon-status status-pending" *ngSwitchCase="'PENDING'"></i>
        <i class="icon-status status-in-progress" *ngSwitchCase="'IN_PROGRESS'"></i>
        <i class="icon-status status-success" *ngSwitchCase="'COMPLETED'"></i>
        <i class="icon-status status-cancel" *ngSwitchCase="'DECLINED'"></i>
        {{value | label}}
      </span>
      <span class="text-danger" *ngIf="row.invitation && value == 'NO_USER'" i18n>Pending (waiting login)</span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Sent at" i18n-name prop="createdAt" [flexGrow]="1" [resizeable]="false" [sortable]="false">
    <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>{{value | date:'dd. MMMM yyyy'}}</ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Updated at" i18n-name prop="updatedAt" [flexGrow]="1" [resizeable]="false" [sortable]="false">
    <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>{{value | date:'dd. MMMM yyyy'}}</ng-template>
  </ngx-datatable-column>
</ngx-datatable>
