import {Chart} from 'chart.js';
import 'chartjs-plugin-colorschemes';

const chartjsColorschemes = [];

for (const vendorKey in (Chart as any).colorschemes) {
  if ((Chart as any).colorschemes.hasOwnProperty(vendorKey)) {
    const schemes = (Chart as any).colorschemes[vendorKey];
    for (const schemeKey in schemes) {
      if (schemes.hasOwnProperty(schemeKey)) {
        chartjsColorschemes.push({
          value: vendorKey + '.' + schemeKey,
          colors: schemes[schemeKey]
        });
      }
    }
  }
}

export {chartjsColorschemes};
