import { Injectable } from '@angular/core';
import {Store} from '../../../../core/src/lib/services/store';

export interface KeywordWrapper {
  keyword: string;
}

@Injectable({
  providedIn: 'root'
})
export class SharedKeywordStore extends Store<string>{

  constructor() {
    super('');
  }

}
