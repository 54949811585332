import {Component, Inject, OnInit} from '@angular/core';
import {BaseMapType, CoreConfig, SMARTENCITY_CORE_CONFIG} from '@smartencity/core';
import { TallinnGmapService } from '../service/tallinn-gmap.service';
import { TallinnOlMapService } from '../service/tallinn-ol-map.service';
import { TallinnService } from '../service/tallinn.service';

@Component({
  selector: 'tallinn-main',
  templateUrl: './tallinn.component.html',
  providers: [TallinnService, TallinnGmapService, TallinnOlMapService]
})
export class TallinnComponent implements OnInit {

  mapType: BaseMapType;

  constructor(@Inject(SMARTENCITY_CORE_CONFIG) private config: CoreConfig) {
    this.mapType = (config.map.mapType ? config.map.mapType : 'gmap')
    // this.mapType = 'ol';
  }

  ngOnInit(): void {
  }

}
