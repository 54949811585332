import {AbstractControl, FormControl, FormGroup} from '@angular/forms';
import {LocationType} from '@smartencity/core';

export function RequireAddress(control: AbstractControl) {
  const input = <FormControl>control;
  const parent = <FormGroup>input.parent;

  if (!parent) {
    return null;
  }

  if (parent.get('locationType').value !== LocationType.ADDRESS) {
    return null;
  }

  if (!input.value) {
    return {
      required: true
    };
  }
}
