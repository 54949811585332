import {ChartType, IWidget, IWidgetDataset, WidgetDataset, WidgetType} from './widget';
import {chartjsColorschemes, Dataset, PageResponse, PreventCloseAware} from '@smartencity/core';
import { Dashboard, DashboardFilter, PersonDashboard} from './dashboard';
import {WidgetDatasetRow} from './widget/widget-model';
import {AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {debounceTime, distinctUntilChanged, startWith, switchMap, takeUntil, tap} from 'rxjs/operators';
import {WidgetService} from './widget.service';
import {Injectable, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';
import {Observable} from 'rxjs/internal/Observable';
import {ToastrService} from 'ngx-toastr';
import moment, {Moment} from 'moment';
import { WidgetApiService } from '../../http/widget-api.service';
import { DashboardApiService } from '../../http/dashboard-api.service';
import {NgxDrpOptions} from '@smartencity/shared';

@Injectable()
export abstract class AbstractWidgetModal implements OnDestroy, PreventCloseAware {

  protected ngDestroy = new Subject<void>();
  public dashboardFilter: DashboardFilter;
  public widget: IWidget;
  public previewWidget: IWidget;
  public rows: WidgetDatasetRow[] = [];
  public form: FormGroup;
  public widgetDatasetArray: FormArray;
  public selectedDatasetRowMap = new Map<string, WidgetDatasetRow>();
  public mode: string;
  public isLoading: boolean;
  private previousPeriodType: string;
  validated = false;

  filterForm: FormGroup = new FormGroup({
    query: new FormControl(''),
    datasetTypes: new FormControl([]),
  });

  searchControl = this.filterForm.get('query');

  public page: PageResponse<Dataset>;
  public pageSize = 12;
  public pageFilter: any = {
    page: 1,
    size: this.pageSize,
    query: '',
    datasetTypes: [],
    q11eId: null
  };

  pageControl = new FormControl(1);

  rangeControl = new FormControl({from: null, to: null});

  dateRangePickerOptions: NgxDrpOptions;

  public abstract afterUpdateSelectedSeriesIdSet(): void;

  constructor(
    protected widgetService: WidgetService,
    protected toastr: ToastrService,
    protected widgetApi: WidgetApiService,
    protected dashboardApiService: DashboardApiService
  ) {

    this.filterForm.valueChanges.pipe(
      startWith({
        query: '',
        datasetTypes: []
      }),
      takeUntil(this.ngDestroy),
      distinctUntilChanged(),
      debounceTime(250),
      tap(() => this.isLoading = true),
      switchMap((searchFilter: any) => {
        this.pageFilter.page = 1;
        this.pageFilter.size = this.pageSize;
        this.pageFilter.query = searchFilter.query;
        this.pageFilter.datasetTypes = searchFilter.datasetTypes;
        this.preparePageFilter(this.pageFilter);
        return this.getSearch();
      }),
      tap(() => this.isLoading = false)
    ).subscribe((result: PageResponse<Dataset>) => {
      this.page = result;
    });
  }

  public abstract filterSelectedDatasetTypes(selected: string[]): string[];

  public widgetTypeOptions = [
    {value: WidgetType.TIME_FRAME_CHART, label: $localize`Time frame chart`},
    {value: WidgetType.VALUE_CHART, label: $localize`Value chart`},
    {value: WidgetType.VALUE_TABLE, label: $localize`Value table`}
  ];

  public chartTypeOptions = [
    {value: ChartType.LINE, label: $localize`Line`},
    {value: ChartType.BAR, label: $localize`Bar`},
    {value: ChartType.DOUGHNUT, label: $localize`Doughnut`},
    {value: ChartType.PIE, label: $localize`Pie`},
    {value: ChartType.POLAR_AREA, label: $localize`Polar Area`},
    {value: ChartType.RADAR, label: $localize`Radar`}
  ];

  public chartColorSchemeOptions = chartjsColorschemes;

  public periodTypeOptions = [
    {value: 'HOUR', label: $localize`Hour`},
    {value: 'DAY', label: $localize`Day`},
    {value: 'MONTH', label: $localize`Month`},
    {value: 'YEAR', label: $localize`Year`}
  ];

  public timeframeTypeOptions = [
    {value: 'PERIOD', label: $localize`Period`},
    {value: 'DATE_RANGE', label: $localize`Date range`},
  ];

  public aggregationGroupingTypeOptions = [
    {value: null, label: $localize`Live`},
    {value: 'HOURLY', label: $localize`Hourly`},
    {value: 'DAILY', label: $localize`Daily`},
    {value: 'MONTHLY', label: $localize`Monthly`},
    {value: 'YEARLY', label: $localize`Yearly`}
  ];

  public dashboardOptions: PersonDashboard[] = [];

  public buildForm(isTemplate = false): void {
    if (!this.widget.id) {
      this.mode = 'add-datasets';
    }

    this.previewWidget = Object.assign({}, this.widget);

    this.rows = [];
    this.widgetDatasetArray = new FormArray([]);
    for (const widgetDataset of this.widget.datasets) {
      const control = this.getWidgetDatasetFormGroup(widgetDataset);
      this.widgetDatasetArray.push(control);

      this.rows.push({
        dataset: widgetDataset,
        control: control,
      });
    }

    this.form = new FormGroup({
      name: new FormControl(this.widget.name, [Validators.required]),
      type: new FormControl(this.widget.type, [Validators.required]),
      dashboardId: new FormControl(this.widget.dashboard?.id, !isTemplate ? [Validators.required] : null),
      chartType: new FormControl(this.widget.chartType),
      showLegend: new FormControl(this.widget.showLegend),
      showYAxes: new FormControl(this.widget.showYAxes),
      showDataLabels: new FormControl(this.widget.showDataLabels),
      showAverageLine: new FormControl(this.widget.showAverageLine),
      aggregationGroupingType: new FormControl(this.widget.aggregationGroupingType),
      chartColorScheme: new FormControl(this.widget.chartColorScheme ? this.widget.chartColorScheme : null),
      periodType: new FormControl(this.widget.periodType),
      periodCount: new FormControl(this.widget.periodCount, [Validators.pattern(/^\-?\d+([\.\,]\d+)?$/)]),
      widgetDatasets: this.widgetDatasetArray,
      differentiate: new FormControl(this.widget.differentiate),
      timeframeType: new FormControl(this.widget.timeframeType),
      periodStartAt: new FormControl(this.widget.periodStartAt),
      periodEndAt: new FormControl(this.widget.periodEndAt)
    });

    this.updateSelectedSeriesIdSet();

    if (this.widget.periodStartAt && this.widget.periodEndAt) {
      this.rangeControl.setValue({
        from: moment(this.widget.periodStartAt),
        to: moment(this.widget.periodEndAt),
      });
    }

    this.form.valueChanges.pipe(takeUntil(this.ngDestroy)).subscribe((value) => {
      this.previewWidget = Object.assign(
        {},
        this.widget,
        value,
        {datasets: this.rows.map((e) => {
            const controlValue = e.control.getRawValue();
            return {...e.dataset, ...controlValue, scaleMin: parseInt(controlValue.scaleMin, 10), scaleMax: parseInt(controlValue.scaleMax, 10)};
          })}
      );
    });

    this.form.get('type').valueChanges.subscribe((type) => {
      if (type === 'VALUE_TABLE') {
        this.form.get('aggregationGroupingType').setValue('HOURLY');
        this.form.get('aggregationGroupingType').updateValueAndValidity();
      }
      if (type === 'TIME_FRAME_CHART') {
        this.form.get('timeframeType').setValue('PERIOD');
        this.form.get('timeframeType').updateValueAndValidity();
      }
    });

    this.form.get('timeframeType').valueChanges.subscribe((timeframeType) => {
      if (timeframeType === 'DATE_RANGE') {
        this.previousPeriodType = (this.form.get('periodType').value !== 'CUSTOM' ? this.form.get('periodType').value : null);
        this.form.get('periodType').setValue('CUSTOM');
        this.form.get('periodType').updateValueAndValidity();
      } else if (this.form.get('periodType').value === 'CUSTOM') {
        this.form.get('periodType').setValue(this.previousPeriodType ? this.previousPeriodType : 'HOUR');
        this.form.get('periodType').updateValueAndValidity();
      }
    });

    this.updateDashboardOptions();
    this.setupDrpOptions();
  }

  public async save() {
    this.validated = true;
    this.form.markAllAsTouched();
    this.markFormGroupTouched(this.form);
    this.form.updateValueAndValidity();
    if (!this.form.valid) {
      console.error('form invalid', this.form);
      console.log(this.form);
      return;
    }

    const value = this.form.getRawValue();
    const widget = Object.assign({}, this.widget, this.mapFormValueToWidget(value));
    widget.datasets = this.rows.map((row, i) => Object.assign({}, row.dataset, row.control.getRawValue(), {sortOrder: i}));
    this.isLoading = true;
    if (widget && widget.id) {
      this.saveWidget(widget).pipe(takeUntil(this.ngDestroy)).subscribe(() => {
        this.isLoading = false;

        this.resetFormState();

        this.close();
      }, (e: any) => {
        this.isLoading = false;
        this.toastr.error(JSON.stringify(e));
      });
    } else {
      this.createWidget(widget).pipe(takeUntil(this.ngDestroy)).subscribe(() => {
        this.isLoading = false;

        this.resetFormState();

        this.close();
      }, (e: any) => {
        this.isLoading = false;
        this.toastr.error(JSON.stringify(e));
      });
    }
  }

  public abstract mapFormValueToWidget(value: any): any;

  public abstract close(): void;

  public abstract createWidget(widget: any): Observable<any>;

  public abstract saveWidget(widget: any): Observable<any>;

  public abstract createWidgetDataset(dataset: Dataset): IWidgetDataset;

  public abstract preparePageFilter(filter: any): void;

  toggleDataSetSelect(dataset: Dataset) {
    if (this.isSelected(dataset)) {
      const row = this.selectedDatasetRowMap.get(this.datasetKey(dataset));
      if (row) {
        this.removeWidgetDatasetRow(row);
      }
      return;
    }

    this.addDataSet(dataset);
  }

  addDataSet(dataset: Dataset) {
    if (this.isSelected(dataset)) {
      return;
    }

    const wd = this.createWidgetDataset(dataset);
    const control = this.getWidgetDatasetFormGroup(wd);
    this.rows.push({
      dataset: wd,
      control: control,
    });
    this.widgetDatasetArray.push(control);

    this.updateSelectedSeriesIdSet();
  }


  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  private updateDashboardOptions() {
    this.dashboardApiService.getDashboardOptions().subscribe((personDashboards: PersonDashboard[]) => {
      this.dashboardOptions = personDashboards;
      if (!this.widget.id && this.dashboardFilter) {
        const personDashboard = new PersonDashboard();
        personDashboard.dashboard = new Dashboard();

        if(this.dashboardFilter.dashboardId){
          personDashboard.dashboard.id = Number(this.dashboardFilter.dashboardId);
        } else {
          personDashboard.dashboard.id = personDashboards.find(personDashboard => personDashboard.dashboard.type == this.dashboardFilter.dashboardType).dashboard.id;
        }
        this.widget.dashboard = personDashboard.dashboard;
        this.form.get('dashboardId').setValue(this.widget.dashboard.id);
        this.form.get('dashboardId').updateValueAndValidity();
      }
    });
  }

  updateSelectedSeriesIdSet() {
    this.selectedDatasetRowMap = new Map<string, WidgetDatasetRow>();
    for (const row of this.rows) {
      const ds = row.dataset;
      this.selectedDatasetRowMap.set(this.datasetKey(ds), row);
    }

    this.afterUpdateSelectedSeriesIdSet();

  }

  getWidgetDatasetFormGroup(widgetDataset: IWidgetDataset): FormGroup {
    return new FormGroup({
      name: new FormControl(widgetDataset.name),
      aggregationType: new FormControl(widgetDataset.aggregationType ? widgetDataset.aggregationType : 'AVERAGE'),
      graphType: new FormControl(widgetDataset.graphType ? widgetDataset.graphType : 'line'),
      groupName: new FormControl(widgetDataset.groupName),
      scaleMin: new FormControl(widgetDataset.scaleMin, [Validators.pattern(/^\-?\d+([\.\,]\d+)?$/),
        (control: AbstractControl): any => {
          const value = control.value;
          if (control.parent && control.parent.get('scaleMax').value && value) {
            if (Number(control.parent.get('scaleMax').value) <= Number(control.value)) {
              return {
                maxLessThanMin: true
              };
            }
          }
          return null;
        }]),
      scaleMax: new FormControl(widgetDataset.scaleMax, [Validators.pattern(/^\-?\d+([\.\,]\d+)?$/),
        (control: AbstractControl): any => {
          if (control.parent) {
            control.parent.get('scaleMin').updateValueAndValidity();
          }
          return null;
        }
      ]),
      color: new FormControl(widgetDataset.color),
      differentiate: new FormControl(widgetDataset.differentiate)
    });
  }

  datasetKey(ds: Dataset | WidgetDataset) {
    return ds.type + '-' + ds.personSeriesId + '-' + ds.personParameterId + '-' + ds.q11eFieldId + '-' + ds.q11eResponseFieldId;
  }

  removeWidgetDatasetRow(row: WidgetDatasetRow) {
    this.rows.splice(this.rows.indexOf(row), 1);
    this.widgetDatasetArray.removeAt(this.widgetDatasetArray.controls.indexOf(row.control));
    this.updateSelectedSeriesIdSet();
  }

  isSelected(ds: Dataset) {
    return this.selectedDatasetRowMap.has(this.datasetKey(ds));
  }

  removeAll() {
    this.rows = [];
    this.widgetDatasetArray.clear();
    this.selectedDatasetRowMap.clear();
  }

  invalid(name: string, formGroup?) {
    if (!formGroup) {
      formGroup = this.form;
    }
    const control = formGroup.get(name);
    return control && control.invalid && (control.dirty || control.touched);
  }

  errors(name: string): ValidationErrors {
    const control = this.form.get(name);
    return control ? control.errors : null;
  }

  public getSearch() {
    const filter: any = {
      page: (this.pageFilter.page - 1) + '',
      size: this.pageFilter.size + '',
      query: this.pageFilter.query,
      datasetTypes: this.pageFilter.datasetTypes,
    };

    if (this.pageFilter.q11eId) {
      filter.q11eId = this.pageFilter.q11eId;
    }

    if (this.widget && this.widget.dashboard) {
      filter.dashboardId = this.widget.dashboard.id;
    }

    return this.widgetApi.getWidgetDatasets(filter).pipe(takeUntil(this.ngDestroy));
  }

  pageChanged(event: any) {
    this.pageFilter.page = event.page;
    this.getSearch().subscribe((result: PageResponse<Dataset>) => {
      this.page = result;
    });
  }

  clearSearch() {
    this.searchControl.setValue('');
  }

  isTypeFiltered(type): boolean {
    return this.filterForm.get('datasetTypes').value.includes(type);
  }

  clearDatasetTypeFilter(datasetType: string): void {
    const datasetTypes = this.filterForm.get('datasetTypes').value;
    const index = datasetTypes.indexOf(datasetType);
    if (index !== -1) {
      datasetTypes.splice(index, 1);
    }
    this.filterForm.get('datasetTypes').setValue(datasetTypes);
  }

  addDatasetTypeFilter(datasetType: string): void {
    const datasetTypes = this.filterForm.get('datasetTypes').value;
    const index = datasetTypes.indexOf(datasetType);
    if (index === -1) {
      datasetTypes.push(datasetType);
    }
    this.filterForm.get('datasetTypes').setValue(datasetTypes);
  }

  datasetTypeCheckboxChanged(value: string, event: any): void {
    if (event.currentTarget.checked) {
      this.addDatasetTypeFilter(value);
    } else {
      this.clearDatasetTypeFilter(value);
    }
  }

  updateRange(event: { fromDate: Moment, toDate: Moment }): void {
    let fromDate = null, toDate = null;
    if (event) {
      // fromDate = event.fromDate.utc(true).toDate();
      fromDate = event.fromDate.utc(true).toDate();
      toDate = event.toDate.utc(true).toDate();
    }
    this.form.get('periodStartAt').setValue(fromDate);
    this.form.get('periodEndAt').setValue(toDate);
  }

  setupDrpOptions() {
    const today = moment().startOf('day');

    const minus1 = today.clone().subtract(1, 'days');
    const minus7 = today.clone().subtract(7, 'days');
    const currMonthStart = today.clone().startOf('month');
    const currMonthEnd = today.clone().startOf('month').add(1, 'months').subtract(1, 'days');
    const lastMonthStart = today.clone().startOf('month').subtract(1, 'months');
    const lastMonthEnd = today.clone().startOf('month').subtract(1, 'days');
    const currYearStart = today.clone().startOf('year');
    const currYearEnd = today.clone().startOf('year').add(1, 'year').subtract(1, 'days');
    const lastYearStart = today.clone().startOf('year').subtract(1, 'year');
    const lastYearEnd = today.clone().startOf('year').subtract(1, 'days');

    this.dateRangePickerOptions = {
      placeholder: $localize`Choose period`,
      presets: [
        {
          presetLabel: $localize`Today`,
          range: {fromDate: today.clone(), toDate: today.clone()}
        },
        {
          presetLabel: $localize`Yesterday`,
          range: {fromDate: minus1.clone(), toDate: minus1.clone()}
        },
        {
          presetLabel: $localize`Last 7 Days`,
          range: {fromDate: minus7.clone(), toDate: today.clone().subtract(1, 'days')}
        },
        {
          presetLabel: $localize`This Month`,
          range: {fromDate: currMonthStart.clone(), toDate: currMonthEnd.clone()}
        },
        {
          presetLabel: $localize`Last Month`,
          range: {fromDate: lastMonthStart.clone(), toDate: lastMonthEnd.clone()}
        },
        {
          presetLabel: $localize`This year`,
          range: {fromDate: currYearStart.clone(), toDate: currYearEnd.clone()}
        },
        {
          presetLabel: $localize`Last year`,
          range: {fromDate: lastYearStart.clone(), toDate: lastYearEnd.clone()}
        }
      ],
      format: 'dd.MM.yyyy',
      range: {
        fromDate: this.rangeControl.value.from,
        toDate: this.rangeControl.value.to
      },
      /*fromMinMax: {fromDate: undefined, toDate: today},
      toMinMax: {fromDate: undefined, toDate: today},*/
      applyLabel: $localize`Apply`,
      enforceToAfterFrom: true,
      calendarOverlayConfig: {
        shouldCloseOnBackdropClick: true,
        hasBackdrop: true
      },
      cancelLabel: $localize`Cancel`,
      clearable: true
    };
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }


  isPreventClose(): boolean {
    return this.form && this.form.dirty;
  }

  protected resetFormState(): void {
    this.form.markAsUntouched();
    this.form.markAsPristine();
  }

}
