<ng-container *ngIf="formGroup" [formGroup]="formGroup">
  <div class="form-group row">
    <label class="col-md-3 col-form-label" [attr.for]="id + '-name'"><ng-container i18n>Question</ng-container></label>
    <div class="col-md-9">
      <input type="text" class="form-control" [class.is-invalid]="invalid('name')" [id]="id + '-name'" formControlName="name" placeholder="Question" i18n-placeholder>
      <small *ngIf="invalid('name') && errors('name')?.required" class="invalid-feedback d-block" i18n>Required</small>
    </div>
  </div>

  <div class="form-group row">
    <label class="col-md-3 col-form-label" [attr.for]="id + '-description'"><ng-container i18n>Description</ng-container><span class="optional" i18n>Optional</span></label>
    <div class="col-md-9">
      <textarea class="form-control" rows="3" [class.is-invalid]="invalid('description')" [id]="id + '-description'" formControlName="description" placeholder="Additional information for context" i18n-placeholder></textarea>
      <small *ngIf="invalid('description') && errors('description')?.required" class="invalid-feedback d-block" i18n>Required</small>
    </div>
  </div>

  <div class="form-group row">
    <label class="col-md-3 col-form-label"><ng-container i18n>Optional</ng-container></label>
    <div class="col-md-9">
      <div class="custom-control custom-switch mt-2">
        <input type="checkbox" class="custom-control-input" [class.is-invalid]="invalid('optional')" [id]="id + '-optional'" formControlName="optional">
        <label class="custom-control-label mr-4" [attr.for]="id + '-optional'" i18n>Response is optional</label>
      </div>
    </div>
  </div>

  <span class="separator"></span>

  <div class="form-group row">
    <label i18n class="col-md-3 col-form-label col-form-label-other">Answer type</label>
    <div class="col-md-9">
      <div class="btn-group btn-group-sm btn-separate" [class.is-invalid]="invalid('valueType')" role="group" btnRadioGroup formControlName="valueType">
        <button type="button" class="btn btn-outline-lighter" [btnRadio]="'TEXT'" i18n [disabled]="questionnaire.status !== 'DRAFT'">Text</button>
        <button type="button" class="btn btn-outline-lighter" [btnRadio]="'VALUE'" i18n [disabled]="questionnaire.status !== 'DRAFT'">Value</button>
        <button type="button" class="btn btn-outline-lighter" [btnRadio]="'SELECT'" i18n [disabled]="questionnaire.status !== 'DRAFT'">Multiple choice</button>
      </div>
      <small *ngIf="invalid('valueType') && errors('valueType')?.required" class="invalid-feedback d-block" i18n>Required</small>
    </div>
  </div>

  <div class="form-group row" *ngIf="formGroup.get('valueType').value === 'SELECT'">
    <label i18n class="col-md-3 col-form-label col-form-label-other">Multiple choice</label>
    <div class="col-md-9">
      <div class="form-group row" *ngFor="let optionGroup of formGroup.get('selectOptions').controls; let i = index" [formGroup]="optionGroup">
        <div class="col-4">
          <input type="text" class="form-control" formControlName="name" placeholder="Name" i18n-placeholder>
          <small *ngIf="invalid('name', optionGroup) && errors('name', optionGroup)" class="invalid-feedback d-block" i18n>Required</small>
        </div>
        <div class="col-auto p-0 d-flex">
          <button class="btn btn-sm btn-remove p-0" (click)="removeSelectOption(formGroup.get('selectOptions'), i)" *ngIf="optionGroup.get('name').enabled"><i class="fal fa-times"></i>&nbsp;<ng-container i18n>Remove</ng-container></button>
        </div>
      </div>
      <small *ngIf="invalid('selectOptions') && errors('selectOptions')?.uniqueOptions" class="invalid-feedback d-block" i18n>Option names must be unique</small>
      <small *ngIf="invalid('selectOptions') && errors('selectOptions')?.minLength" class="invalid-feedback d-block" i18n>At least 1 option must be added</small>
      <button type="button" class="btn btn-sm btn-link p-0" (click)="addSelectOption(formGroup.get('selectOptions'))"><i class="fal fa-plus"></i>&nbsp; <ng-container i18n>Add more</ng-container></button>
    </div>
  </div>

  <div class="d-flex justify-content-center mt-4">
    <button type="button" class="btn btn-outline-secondary" (click)="cancel()" i18n>Cancel</button>
    <button type="button" class="btn btn-outline-primary ml-2" (click)="save()" i18n>Save</button>
  </div>
</ng-container>
