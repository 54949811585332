import { OlMapWrapper, OlOverlayWrapper } from "@smartencity/core";
import {MeteorologyService} from '../service/meteorology.service';
import {RoadWeatherSite} from '../model/road-weather-site';

export class RoadWeatherOlMarkerWrapper extends OlOverlayWrapper {
  public selected = false;
  private roadWeather: RoadWeatherSite;
  public div: any;
  public meteorologyService: MeteorologyService;

  constructor(roadWeather: RoadWeatherSite, map: OlMapWrapper, meteorologyService: MeteorologyService) {
    super({
      title: roadWeather.name,
      position: [roadWeather.lng, roadWeather.lat],
      stopEvent: false,
    });
    this.roadWeather = roadWeather;
    this.meteorologyService = meteorologyService;
    this.setMap(map.getMap());
  }

  public draw() {
    if (!this.div) {
      this.div = document.createElement("DIV");
      this.div.title = this.roadWeather.name;
      this.div.style.cursor = "pointer";
      this.div.classList.add("pointer-road-weather");

      this.div.addEventListener('click', () => {
        this.meteorologyService.selectRoadWeather(this.roadWeather);
      });
    }

    if (this.selected) {
      this.div.classList.add("active");
    } else {
      this.div.classList.remove("active");
    }
  }

}
