<div class="filter">
  <div class="filter-btn-row">
    <ci-activity-type-filter [filterOptions]="filterOptions" [defaultSelectedValue]="defaultActivityType" (optionSelected)="activityTypeOptionSelected($event)"></ci-activity-type-filter>
  </div>

  <div class="filter-btn-row">
    <div class="form-inline">
      <div class="form-group">
        <span class="label mr-2 mb-0" i18n>Select date:</span>
        <select class="form-control browser-default" [(ngModel)]="visitDate" (ngModelChange)="filterByDate($event)"
                [compareWith]="compareDateFn">
          <option [ngValue]="dateOption" *ngFor="let dateOption of dateOptions">{{dateOption|date:'dd.MM.yyyy'}}</option>
        </select>
      </div>
    </div>
  </div>
</div>

<div class="table-responsive">
  <table class="table w-100">
    <thead>
      <tr>
        <th i18n>County</th>
        <th i18n>People count</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let visit of visits">
        <td>{{visit.adminLevel1}}</td>
        <td>{{visit.people}}</td>
      </tr>
    </tbody>
  </table>
</div>
