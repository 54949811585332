<ng-template #dashboardDownload>
  <mydata-dashboard-download></mydata-dashboard-download>
</ng-template>

<ng-template #navActions>
  <mydata-dashboard-actions></mydata-dashboard-actions>
</ng-template>

<ng-template #advices>
  <mydata-energy-manager-advices (hide)="hideAdvices()"></mydata-energy-manager-advices>
</ng-template>
<ng-template #incomingQuestionnaires>
  <mydata-energy-manager-incoming-questionnaires (hide)="hideIncomingQuestionnaires()"></mydata-energy-manager-incoming-questionnaires>
</ng-template>

<loading-spinner *ngIf="loading"></loading-spinner>

<div *ngIf="dashboard.length > 0">
<div class="row mb-4 justify-content-center">
  <div class="col-md-auto col-sm-12">
    <ngx-mat-drp class="rounded" (selectedDateRangeChanged)="updateRange($event)" [options]="dateRangePickerOptions" #dateRangePicker></ngx-mat-drp>
  </div>
  <div class="col-auto" *ngIf="rangeControl?.value?.from && rangeControl?.value?.to">
    <ul class="nav nav-links justify-content-end">
      <li class="nav-item">
        <a class="nav-link" [class.active]="(aggregationGroupingType$ | async) == 'LIVE'" (click)="toggleAggregationType('LIVE')" i18n>Live</a>
      </li>
      <li class="nav-item separator"><span></span></li>
      <li class="nav-item">
        <a class="nav-link" [class.active]="(aggregationGroupingType$ | async) == 'HOURLY'" (click)="toggleAggregationType('HOURLY')" [class.disabled]="!aggTypes['HOURLY']" i18n>Hourly</a>
      </li>
      <li class="nav-item separator"><span></span></li>
      <li class="nav-item">
        <a class="nav-link" [class.active]="(aggregationGroupingType$ | async) == 'DAILY'" (click)="toggleAggregationType('DAILY')" [class.disabled]="!aggTypes['DAILY']" i18n>Daily</a>
      </li>
      <li class="nav-item separator"><span></span></li>
      <li class="nav-item">
        <a class="nav-link" [class.active]="(aggregationGroupingType$ | async) == 'MONTHLY'" (click)="toggleAggregationType('MONTHLY')" [class.disabled]="!aggTypes['MONTHLY']" i18n>Monthly</a>
      </li>
      <li class="nav-item separator"><span></span></li>
      <li class="nav-item">
        <a class="nav-link" [class.active]="(aggregationGroupingType$ | async) == 'YEARLY'" (click)="toggleAggregationType('YEARLY')" [class.disabled]="!aggTypes['YEARLY']" i18n>Yearly</a>
      </li>
    </ul>
  </div>
</div>
<gridster [options]="options" #grid>
  <gridster-item [item]="item" *ngFor="let item of dashboard">
    <mydata-dashboard-widget
      *ngIf="item.itemType == 'DASHBOARD'"
      [widget]="item.widget"
      [rangeFrom]="(range$ | async)?.from"
      [rangeTo]="(range$ | async)?.to"
      [aggregationGroupingType]="aggregationGroupingType$ | async"
      [reloadWidgets]="reloadWidgets"
      (loadXls)="loadXls(item.widget)"
      [isOwner]="item.ownerType == OwnerType.OWNER"
    ></mydata-dashboard-widget>

    <div class="widget-body-gridster-item-content" *ngIf="item.itemType == 'ENERGY_MANAGER'">
      <ng-container *ngTemplateOutlet="item.componentTemplate"></ng-container>
      <ng-container *ngIf="!item.componentTemplate">No content</ng-container>
    </div>
  </gridster-item>
</gridster>
</div>

<div *ngIf="!loading && dashboard.length == 0">
  <div class="text-center fst-italic" i18n>No data to display</div>
</div>
