<div class="d-flex">
  <div class="row d-flex no-gutters w-100 position-relative">
    <div class="map-container" style="width: 100%;">
      <div id="citymapContainer" class="citymap map" #gmap style="height: 100%;"> </div>
    </div>

    <div class="floating-wrapper">
      <div class="position-relative">
        <buildings-search></buildings-search>

        <div buildings-building-modal></div>
        <crowd-insights-grid-area-modal></crowd-insights-grid-area-modal>
      </div>

      <div class="floating-jumbotron layer-filter">
        <ng-container>

          <label class="layer-filter-toggle" *ngIf="displaySettings.windTurbines" (click)="displayItemsChanged('windTurbines', !displaySettings.windTurbines.active)"
                 [class.active]="this.displaySettings.windTurbines?.active"
                 tooltip="Show wind turbines" i18n-tooltip data-filter-name="wind-turbine">
            <i class="icon-s icon-wind-turbine"></i>
          </label>

          <label class="layer-filter-toggle" *ngIf="displaySettings.crowdInsights" tooltip="Show crowd insights" (click)="displayItemsChanged('crowdInsights', !displaySettings.crowdInsights.active)"
                 [class.active]="this.displaySettings.crowdInsights?.active"
                 i18n-tooltip
                 data-filter-name="crowd-insights">
            <i class="icon-s icon-crowd-insights"></i>
          </label>

          <label class="layer-filter-toggle" [class.active]="typesSelectMap.ENERGY_PRODUCTION === true"
                 (click)="showSeries('ENERGY_PRODUCTION')"
                 tooltip="Show solar energy production" i18n-tooltip
                 data-filter-name="energy-production">
            <i class="icon-s icon-solar"></i>
          </label>

          <label class="layer-filter-toggle" [class.active]="typesSelectMap.HEAT === true"
                 (click)="showSeries('HEAT')"
                 tooltip="Show heat consumption" i18n-tooltip
                 data-filter-name="heat">
            <i class="icon-s icon-heat"></i>
          </label>

          <label class="layer-filter-toggle" [class.active]="typesSelectMap.ENERGY_CONSUMPTION === true"
                 (click)="showSeries('ENERGY_CONSUMPTION')"
                 tooltip="Show electricity consumption" i18n-tooltip
                 data-filter-name="energy-consumption">
            <i class="icon-s icon-electricity"></i>
          </label>

          <label class="layer-filter-toggle" [class.active]="typesSelectMap.WATER === true"
                 (click)="showSeries('WATER')"
                 tooltip="Show water consumption" i18n-tooltip data-filter-name="water">
            <i class="icon-s icon-water"></i>
          </label>
        </ng-container>

        <div *ngIf="displaySettings.eecBuildings">
          <div class="energy-class-filter">
            <div class="btn-group">
              <button (click)="toggleAllEnergyClasses('eecBuildings', !displaySettings.eecBuildings.active)" type="button"
                      class="btn btn-secondary d-flex align-items-center layer-filter-toggle" [class.active]="this.displaySettings.eecBuildings?.active" data-filter-name="energy-efficient-category-building-all">
                <i class="icon-s icon-energy-class"></i> <span class="mr-1" i18n>Energy class</span>
              </button>
              <button type="button" class="btn btn-secondary dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-expanded="false" id="chooseEnergyClass">
              </button>
                <ul class="dropdown-menu" aria-labelledby="chooseEnergyClass">
                  <li class="layer-filter-toggle" (click)="toggleEecBuildingEnergyClass('A')"  [class.active]="this.displayEnergyTypeSettings.eecBuildings?.A" data-filter-name="energy-efficient-category-building-a">
                    <span class="dropdown-item">A</span>
                  </li>
                  <li class="layer-filter-toggle" (click)="toggleEecBuildingEnergyClass('B')"  [class.active]="this.displayEnergyTypeSettings.eecBuildings?.B" data-filter-name="energy-efficient-category-building-b">
                    <span class="dropdown-item">B</span>
                  </li>
                  <li class="layer-filter-toggle" (click)="toggleEecBuildingEnergyClass('C')" [class.active]="this.displayEnergyTypeSettings.eecBuildings?.C" data-filter-name="energy-efficient-category-building-c">
                    <span class="dropdown-item">C</span>
                  </li>
                  <li class="layer-filter-toggle" (click)="toggleEecBuildingEnergyClass('D')" [class.active]="this.displayEnergyTypeSettings.eecBuildings?.D" data-filter-name="energy-efficient-category-building-d">
                    <span class="dropdown-item">D</span>
                  </li>
                  <li class="layer-filter-toggle" (click)="toggleEecBuildingEnergyClass('E')"  [class.active]="this.displayEnergyTypeSettings.eecBuildings?.E" data-filter-name="energy-efficient-category-building-e">
                    <span class="dropdown-item">E</span>
                  </li>
                  <li class="layer-filter-toggle" (click)="toggleEecBuildingEnergyClass('F')"  [class.active]="this.displayEnergyTypeSettings.eecBuildings?.F" data-filter-name="energy-efficient-category-building-f">
                    <span class="dropdown-item">F</span>
                  </li>
                  <li class="layer-filter-toggle" (click)="toggleEecBuildingEnergyClass('G')" [class.active]="this.displayEnergyTypeSettings.eecBuildings?.G" data-filter-name="energy-efficient-category-building-g">
                    <span class="dropdown-item">G</span>
                  </li>
                  <li class="layer-filter-toggle" (click)="toggleEecBuildingEnergyClass('H')" [class.active]="this.displayEnergyTypeSettings.eecBuildings?.H" data-filter-name="energy-efficient-category-building-h">
                    <span class="dropdown-item">H</span>
                  </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <core-compare-button></core-compare-button>
    </div>
  </div>
</div>


