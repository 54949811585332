import {CoreConfig} from '../../core-config.model';
import {UriBuilder} from '../../redirect/uri-builder';
import {TaraAuthUriBuilder} from './tara-auth-uri-builder';
import {AbstractRedirectStrategy} from '../../redirect/abstract-redirect-strategy';

export class TaraAuthRedirectStrategy extends AbstractRedirectStrategy {

  constructor(private config: CoreConfig, private hostUrl: string) {
    super();
    this.setRedirectUriBuilder(new TaraAuthUriBuilder(config.apiUrl, hostUrl));
  }

  getRedirectUriBuilder(): UriBuilder {
    return this.redirectUriBuilder;
  }

}
