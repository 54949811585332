import {MapLayer} from './map-layer';
import {MapLayerMarker} from './map-layer-marker';

export abstract class AbstractMapLayer<T, U> implements MapLayer<T> {

  protected map: T;

  private markers: MapLayerMarker<T, U>[] = [];

  protected clearMarkers(): void {
    this.markers = [];
  }

  protected getMarkers(): MapLayerMarker<T, U>[] {
    return this.markers;
  }

  protected addMarker(marker: MapLayerMarker<T, U>) {
    marker.setMap(this.map);
    this.markers.push(marker);
  }

  setMap(map: T) {
    this.map = map;
  }

}
