import {AbstractRedirectStrategy} from '../../redirect/abstract-redirect-strategy';
import {CoreConfig} from '../../core-config.model';
import {NemLogInAuthUriBuilder} from './nem-log-in-auth-uri-builder';

export class NemLogInRedirectStrategy extends AbstractRedirectStrategy {

  constructor(private config: CoreConfig, private hostUrl: string) {
    super();
    this.setRedirectUriBuilder(new NemLogInAuthUriBuilder(config, hostUrl));
  }

}
