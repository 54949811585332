<div class="modal-header">
  <h5 class="modal-title w-100">{{name}}</h5>
  <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
    <span class="fal fa-times"></span>
  </button>
</div>
<div class="modal-body">
  <span *ngIf="event">
      {{event?.time|date:'dd.MM.yyyy HH:mm:ss'}}
  </span>
  <span *ngIf="!event" i18n>
    Filtered period contains no event.
  </span>
  <div>
    <pre>{{event | json}}</pre>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary btn-sm" (click)="close()" i18n>OK</button>
</div>
