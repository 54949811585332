import OverlayView = google.maps.OverlayView;
import Feature = google.maps.Data.Feature;
import LatLng = google.maps.LatLng;
import LatLngBounds = google.maps.LatLngBounds;
import LineString = google.maps.Data.LineString;
import { RoadClosuresGmapLayer } from './road-closures-gmap-layer';
import {MobilityService} from '../service/mobility.service';

export enum Position {
  START = 'START',
  END = 'END'
}

export class RoadClosuresGmapMarker extends OverlayView {
  private div: any;
  public selected = false;
  public feature: Feature;
  popoverTimeout = null;
  public center: LatLng;
  private layer: RoadClosuresGmapLayer;
  private position: Position;
  public pointerLocation: LatLng = null;

  private mobilityService: MobilityService;

  constructor(layer: RoadClosuresGmapLayer, mobilityService: MobilityService, feature: Feature, position?: Position) {
    super();
    this.layer = layer;
    this.feature = feature;
    this.position = position;
    this.mobilityService = mobilityService;
    this.setMap(layer.map);
  }

  onAdd() {
    const panes = this.getPanes();
    if (panes == null) {
      return;
    }

    this.div = document.createElement('DIV');
    this.div.style.cursor = 'pointer';
    this.div.style.position = 'absolute';
    this.div.classList.add('pointer-road-closure');
    panes.overlayMouseTarget.appendChild(this.div);

    let bounds = new LatLngBounds();
    this.feature.getGeometry().forEachLatLng((latLng: LatLng) => {
      bounds.extend(latLng);
    });
    this.center = bounds.getCenter();

    this.pointerLocation = null;
    if (this.feature.getGeometry() instanceof LineString) {
      let line = <LineString> this.feature.getGeometry();
      if (this.position) {
        switch (this.position) {
          case Position.START:
            this.pointerLocation = line.getAt(0);
            break;
          case Position.END:
            this.pointerLocation = line.getAt(line.getLength() - 1);
            break;
        }
      }
    }

    if (this.pointerLocation === null) {
      this.pointerLocation = this.center;
    }

    this.div.addEventListener('click', () => {
      this.layer.map.setZoom(15);
      this.layer.map.panTo(this.center);
      this.mobilityService.selectedRoadClosureSource.next(this.feature.getProperty("roadClosure"));
    });

  }

  draw() {
    const roadClosure = this.feature.getProperty("roadClosure");

    if (roadClosure) {
      const popoverContent = '<span>' + roadClosure.meta.tooLiik + ' - ' + roadClosure.meta.aadress.tekst +  '</span><br />' + roadClosure.meta.pohjus;
      this.div.innerHTML = '<span data-trigger="hover" data-toggle="popover" data-html="true" data-placement="top" data-content="' + popoverContent + '"></span>'

      if (this.selected) {
        this.div.classList.add("active");
      } else {
        this.div.classList.remove("active");
      }
    }

    const point = this.getProjection().fromLatLngToDivPixel(this.pointerLocation);
    if (point) {

      let x, y;

      if (this.selected) {
        x = point.x - 29.5;
        y = point.y - 53;
      } else {

        x = point.x - 22;
        y = point.y - 45;

      }

      this.div.style.left = x + 'px';
      this.div.style.top = y + 'px';


    }
    this.resetPopovers();
  }

  private resetPopovers() {
    if (this.popoverTimeout != null) {
      clearTimeout(this.popoverTimeout);
    }
    this.popoverTimeout = setTimeout(() => {
      this.removePopovers();
      ($(this.div).find('[data-toggle="popover"]') as any).popover({
        trigger: 'hover'
      });
    }, 200);
  }

  private removePopovers(): void{
    $('.popover.show').remove();
  }

  onRemove() {
    this.div.parentNode.removeChild(this.div);
  }


  setMap(map: any) {
    super.setMap(map);
  }
}
