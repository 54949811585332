import {Component, Input, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';

@Component({
  selector: 'mydata-confirm-modal',
  templateUrl: 'confirm-modal.component.html',
})
export class ConfirmModalComponent implements OnInit {
  @Input()
  public title = $localize`Confirm`;

  @Input()
  public description = $localize`Are you sure?`;

  @Input()
  public cancelLabel = $localize`Cancel`;

  @Input()
  public okLabel = $localize`Ok`;

  @Input()
  public callback: (result: boolean) => void;

  constructor (
    public modalRef: BsModalRef
  ) { }

  ngOnInit () { }

  close() {
    if (this.callback) {
      this.callback(false);
    }
    this.modalRef.hide();
  }

  confirm() {
    if (this.callback) {
      this.callback(true);
    }
    this.modalRef.hide();
  }
}
