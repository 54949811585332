<div class="variable" *ngIf="formGroup" [formGroup]="formGroup" #anchor>
  <div class="header">
    <div class="col-auto d-flex">
      <span class="marker mr-2">{{field.symbol}}</span>
    </div>
    <div class="col-auto d-flex align-items-center">
      <label class="m-0 mr-2" i18n>Unit</label>
      <button class="btn btn-link p-0">{{formGroup.get('unit').value}}</button>
    </div>
    <div class="col d-flex justify-content-end align-items-center" *ngIf="q11eType !== 'FORMULA_SOURCE_TEMPLATE'">
      <span class="small-text" i18n>Set value</span>
      <button class="btn btn-icon tooltip-icon"><i class="fal fal-info"></i></button>
      <div class="custom-control custom-switch">
        <input type="checkbox" class="custom-control-input" [id]="id + '-type-response'" formControlName="responseTypeCommon">
        <label class="custom-control-label" [attr.for]="id + '-type-response'"></label>
      </div>
    </div>
  </div>
  <div class="content">
    <div class="form-group row">
      <label class="col-md-3 col-form-label" [attr.for]="id + '-name'" i18n>Name</label>
      <div class="col-md-9">
        <div class="row">
          <div class="col-4">
            <input type="text" class="form-control" [class.is-invalid]="invalid('name')" formControlName="name" [id]="id + '-name'" placeholder="Name" i18n-placeholder>
            <small *ngIf="invalid('name') && errors('name')?.required" class="invalid-feedback d-block" i18n>Required</small>
          </div>
          <div class="col-8 p-0">
            <button class="btn btn-link btn-sm p-0 mt-2" (click)="toggleDescription()" *ngIf="this.formGroup.get('description').value === null" i18n>Set description</button>
            <button class="btn btn-link btn-sm p-0 mt-2" (click)="toggleDescription()" *ngIf="this.formGroup.get('description').value !== null" i18n>Remove description</button>
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row" *ngIf="this.formGroup.get('description').value !== null">
      <label class="col-md-3 col-form-label" [attr.for]="id + '-description'"><ng-container i18n>Description</ng-container><span class="optional" i18n>Optional</span></label>
      <div class="col-md-9">
        <textarea class="form-control" rows="3" [class.is-invalid]="invalid('description')" [id]="id + '-description'" formControlName="description" placeholder="Additional information for context" i18n-placeholder></textarea>
        <small *ngIf="invalid('description') && errors('description')?.required" class="invalid-feedback d-block" i18n>Required</small>
      </div>
    </div>

    <div class="form-group row" *ngIf="!formGroup.get('responseTypeCommon').value && formGroup.get('type').value === 'FORMULA_INPUT'">
      <label class="col-md-3 col-form-label"><ng-container i18n>Optional</ng-container></label>
      <div class="col-md-9">
        <div class="custom-control custom-switch mt-2">
          <input type="checkbox" class="custom-control-input" [class.is-invalid]="invalid('optional')" [id]="id + '-optional'" formControlName="optional">
          <label class="custom-control-label mr-4" [attr.for]="id + '-optional'" i18n>Response is optional</label>
        </div>
      </div>
    </div>

    <div class="form-group row" *ngIf="!formGroup.get('responseTypeCommon').value && formGroup.get('type').value === 'FORMULA_INPUT' && formGroup.get('optional').value">
      <label class="col-md-3 col-form-label" [attr.for]="id + '-default-value'"><ng-container>Default value</ng-container> <span class="optional" i18n>Default value for optional response</span></label>
      <div class="col-md-9">
        <div class="row">
          <div class="col-4">
            <input type="text" class="form-control text-right" [class.is-invalid]="invalid('defaultValue')" formControlName="defaultValue" [id]="id + '-default-value'" placeholder="Numeric value" i18n-placeholder>
            <small *ngIf="invalid('defaultValue') && errors('defaultValue')?.required" class="invalid-feedback d-block" i18n>Required</small>
            <small *ngIf="invalid('defaultValue') && errors('defaultValue')?.pattern" class="invalid-feedback d-block" i18n>Invalid</small>
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-md-3 col-form-label col-form-label-other" i18n>Value type</label>
      <div class="col-md-9">
        <div class="btn-group btn-group-sm btn-separate" [class.is-invalid]="invalid('valueType')" role="group" btnRadioGroup formControlName="valueType">
          <button type="button" class="btn btn-outline-lighter" [btnRadio]="'VALUE'" i18n [disabled]="partialEdit">Value</button>
          <button type="button" class="btn btn-outline-lighter" [btnRadio]="'SELECT'" *ngIf="q11eType !== 'FORMULA_SOURCE_TEMPLATE' && !formGroup.get('responseTypeCommon').value" i18n
                  [disabled]="partialEdit">Multiple choice</button>
          <button type="button" class="btn btn-outline-lighter" [btnRadio]="'PERSON_SERIES'" i18n [disabled]="partialEdit">Datapoint</button>
          <button type="button" class="btn btn-outline-lighter" [btnRadio]="'PERSON_PARAMETER'" i18n [disabled]="partialEdit">Parameter</button>
        </div>
        <small *ngIf="invalid('valueType') && errors('valueType')?.required" class="invalid-feedback d-block" i18n>Required</small>
      </div>
    </div>

    <div class="form-group row" *ngIf="formGroup.get('valueType').value == 'PERSON_SERIES'">
      <label class="col-md-3 col-form-label" i18n>Period specification</label>
      <div class="col-md-9">
        <div class="row">
          <div class="col-4">
            <ng-select placeholder="Select period type" i18n-placeholder [class.is-invalid]="invalid('windowOperationType')" formControlName="windowOperationType" [items]="windowOperationTypeOptions" bindValue="value" bindLabel="label"></ng-select>
            <small *ngIf="invalid('windowOperationType') && errors('windowOperationType')?.required" class="invalid-feedback d-block" i18n>Required</small>
          </div>
        </div>
        <ng-container *ngIf="formGroup.get('windowOperationType').value !== 'LATEST'">
          <span class="help-text d-block mt-2" *ngIf="formGroup.get('windowOperationType').value !== 'LATEST'" i18n>Specify the timeframe type (unit of the period) and the period count (period length in timeframe units).</span>

          <div class="form-group row mt-3">
            <div class="col-4">
              <label i18n>Timeframe type</label>
              <ng-select placeholder="Timeframe type" i18n-placeholder [class.is-invalid]="invalid('windowPeriodType')" formControlName="windowPeriodType" [items]="windowPeriodTypeOptions" bindValue="value" bindLabel="label"></ng-select>
              <small *ngIf="invalid('windowPeriodType') && errors('windowPeriodType')?.required" class="invalid-feedback d-block" i18n>Required</small>
            </div>
            <div class="col-4">
              <label [attr.for]="id + '-period-count'" i18n>Period count</label>
              <input type="text" class="form-control" [class.is-invalid]="invalid('periodCount')" formControlName="periodCount" [id]="id + '-period-count'" placeholder="Period count" i18n-placeholder>
              <small *ngIf="invalid('periodCount') && errors('periodCount')?.required" class="invalid-feedback d-block" i18n>Required</small>
            </div>
          </div>
        </ng-container>
      </div>
    </div>

    <div class="form-group row" *ngIf="formGroup.get('valueType').value == 'SELECT'">
      <label class="col-md-3 col-form-label" i18n>Multiple choice</label>
      <div class="col-md-9">
        <div class="form-group row" *ngFor="let optionGroup of formGroup.get('selectOptions')?.controls; let i = index" [formGroup]="optionGroup">
          <div class="col-4">
            <input type="text" class="form-control" [class.is-invalid]="invalid('name')" formControlName="name" placeholder="Name" i18n-placeholder>
            <small *ngIf="invalid('name', optionGroup) && errors('name', optionGroup)?.required" class="invalid-feedback d-block" i18n>Required</small>
          </div>
          <div class="col-4">
            <input type="text" class="form-control" [class.is-invalid]="invalid('value', optionGroup)" formControlName="value" placeholder="Value" i18n-placeholder>
            <small *ngIf="invalid('value', optionGroup) && errors('value', optionGroup)?.required" class="invalid-feedback d-block" i18n>Required</small>
            <small *ngIf="invalid('value', optionGroup) && errors('value', optionGroup)?.pattern" class="invalid-feedback d-block" i18n>Invalid</small>
          </div>
          <div class="col-auto p-0 d-flex">
            <button class="btn btn-sm btn-remove p-0" (click)="removeSelectOption(formGroup.get('selectOptions'), i)" *ngIf="optionGroup.get('name').enabled"><i class="fal fa-times"></i>&nbsp;<ng-container i18n>Remove</ng-container></button>
          </div>
        </div>
        <small *ngIf="invalid('selectOptions', formGroup) && errors('selectOptions', formGroup)?.uniqueOptions" class="invalid-feedback d-block" i18n>Option names must be unique</small>
        <button type="button" class="btn btn-sm btn-link p-0" (click)="addSelectOption(formGroup.get('selectOptions'))"><i class="fal fa-plus"></i>&nbsp; <ng-container i18n>Add more</ng-container></button>
      </div>
    </div>

    <div class="form-group row" *ngIf="formGroup.get('responseTypeCommon').value" [ngSwitch]="formGroup.get('valueType').value">
      <label class="col-md-3 col-form-label" i18n>Set value</label>
      <div class="col-md-9">
        <div class="row">
          <div class="col-7">
            <ng-container *ngSwitchCase="'VALUE'">
              <input type="text" class="form-control text-right" [class.is-invalid]="invalid('value')" formControlName="value" [id]="id + '-value'" placeholder="Numeric value" i18n-placeholder>
              <small *ngIf="invalid('value') && errors('value')?.required" class="invalid-feedback d-block" i18n>Required</small>
              <small *ngIf="invalid('value') && errors('value')?.pattern" class="invalid-feedback d-block" i18n>Invalid</small>
            </ng-container>
            <ng-container *ngSwitchCase="'SELECT'">
              <input type="text" class="form-control text-right" [class.is-invalid]="invalid('value')" formControlName="value" [id]="id + '-value'" placeholder="Numeric value" i18n-placeholder>
              <small *ngIf="invalid('value') && errors('value')?.required" class="invalid-feedback d-block" i18n>Required</small>
              <small *ngIf="invalid('value') && errors('value')?.pattern" class="invalid-feedback d-block" i18n>Invalid</small>
            </ng-container>
            <ng-container *ngSwitchCase="'PERSON_PARAMETER'">
              <ng-select
                [class.is-invalid]="invalid('personParameter')"
                bindLabel="name"
                [clearable]="true"
                [searchable]="true"
                placeholder="Select parameter"
                i18n-placeholder
                appendTo="body"
                formControlName="personParameter"
                [typeahead]="personParameterTypeahead$"
                [items]="personParameterItems$ | async"
              >
                <ng-template ng-label-tmp let-item="item">
                  <span>{{ item.name + (item.locationRoot != '-' ? (' - ' + item.locationRoot) : '') }}</span>
                </ng-template>
                <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
                  <span >{{ item.name + (item.locationRoot != '-' ? (' - ' + item.locationRoot) : '') }}</span>
                </ng-template>
              </ng-select>
              <small *ngIf="invalid('personParameter') && errors('personParameter')?.required" class="invalid-feedback d-block" i18n>Required</small>
            </ng-container>
            <ng-container *ngSwitchCase="'PERSON_SERIES'">
              <ng-select
                [class.is-invalid]="invalid('personSeries')"
                bindLabel="name"
                [clearable]="true"
                [searchable]="true"
                placeholder="Select datapoint"
                i18n-placeholder
                appendTo="body"
                formControlName="personSeries"
                [typeahead]="personSeriesTypeahead$"
                [items]="personSeriesItems$ | async"
              >
                <ng-template ng-label-tmp let-item="item">
                  <span>{{ item.name + (item.locationRoot != '-' ? (' - ' + item.locationRoot) : '') }}</span>
                </ng-template>
                <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
                  <span >{{ item.name + (item.locationRoot != '-' ? (' - ' + item.locationRoot) : '') }}</span>
                </ng-template>
              </ng-select>
              <small *ngIf="invalid('personSeries') && errors('personSeries')?.required" class="invalid-feedback d-block" i18n>Required</small>
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row" *ngIf="!formGroup.get('responseTypeCommon').value">
      <label i18n class="col-md-3 col-form-label" [attr.for]="id + '-unit'">Unit</label>
      <div class="col-md-9">
        <div class="row">
          <div class="col-4">
            <input type="text" class="form-control" [class.is-invalid]="invalid('unit')" formControlName="unit" [id]="id + '-unit'" placeholder="Unit" i18n-placeholder>
            <small *ngIf="invalid('unit') && errors('unit')?.required" class="invalid-feedback d-block" i18n>Required</small>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
