import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {BsModalService} from 'ngx-bootstrap/modal';
import {CompareModalComponent} from '../compare-modal/compare-modal.component';
import {BuildingsCompareService, CompareService, LocationsCompareService, PersonSeriesCompareService} from '@smartencity/core';

@Component({
  selector: 'core-compare-button',
  templateUrl: './compare-button.component.html',
  providers: [
  ]
})
export class CompareButtonComponent implements OnInit, OnDestroy {
  private ngDestroy = new Subject<void>();

  public count$ = this.compareService.count$;
  public locationsCount$ = this.locationsCompareService.count$;
  public buildingsCount$ = this.buildingsCompareService.count$;
  public personSeriesCount$ = this.personSeriesCompareService.count$;

  constructor(
    private compareService: CompareService,
    private locationsCompareService: LocationsCompareService,
    private buildingsCompareService: BuildingsCompareService,
    private personSeriesCompareService: PersonSeriesCompareService,
    private modalService: BsModalService
  ) {

  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  compare() {
    this.modalService.show(CompareModalComponent, {class: 'modal-xxl'});
  }

  clear() {
    this.compareService.clear();
  }
}
