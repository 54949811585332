import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {MandateNavbarService} from '../mandate-navbar.service';
import {MandateLogModalComponent} from '../mandate-log-modal/mandate-log-modal.component';
import {BsModalService} from 'ngx-bootstrap/modal';
import {AuditLogType} from '@smartencity/core';

@Component({
  selector: 'app-mandate-person-tree',
  templateUrl: './mandate-person-tree.component.html'
})
export class MandatePersonTreeComponent implements OnInit, OnDestroy {

  @ViewChild("logs", {static: true})
  logsTemplate: TemplateRef<any>;

  constructor(private mandateNavbarService: MandateNavbarService,
              private modalService: BsModalService) { }

  ngOnInit(): void {
    this.mandateNavbarService.setShowLogsActionTemplate(this.logsTemplate);
  }

  ngOnDestroy() {
    this.mandateNavbarService.setShowLogsActionTemplate(null);
  }

  showLogHistory(): void {
    const modalRef: any = this.modalService.show(MandateLogModalComponent, {
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-xxl',
      initialState: {
        type: AuditLogType.MANDATE
      }
    });
  }

}
