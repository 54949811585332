<!--<app-mandate-details [data]="selectedData"></app-mandate-details>-->
<!--<app-d3-org-chart [data]="data" (nodeSelected)="onNodeSelect($event)"></app-d3-org-chart>-->
<ng-template #navActions>
  <!--  <div class="btn-group mr-5">-->
  <!--    <button type="button" class="btn btn-icon" (click)="showLogHistory()"><i class="fal fa-history"></i> Volituste ajalugu</button>-->
  <!--  </div>-->
  <div class="float-right">
    <ng-container *ngIf="showLogsAction$ | async">
      <ng-container *ngTemplateOutlet="showLogsAction$ | async"></ng-container>
    </ng-container>

    <button type="button" class="btn btn-primary btn-sm d-none d-md-inline-block" (click)="create()" *ngxPermissionsOnly="['EDIT_MANDATES','OWNER']">
      <i class="fal fa-plus"></i>&nbsp;<ng-container i18n>Add new mandate</ng-container>
    </button>
  </div>
  <div class="btn-floating-mobile-container">
    <button type="button" class="btn btn-sm btn-primary btn-floating-mobile" (click)="create()" *ngxPermissionsOnly="['EDIT_MANDATES','OWNER']">
      <i class="fal fa-plus fa-2x"></i>
    </button>
  </div>
</ng-template>

<div class="container-fluid">
  <div [formGroup]="filterForm" class="row">
    <div class="col">
      <div class="custom-control custom-switch mt-2 float-right">
        <input type="checkbox" class="custom-control-input" id="only-active" formControlName="onlyActive">
        <label class="custom-control-label" for="only-active" i18n>Only active</label>
      </div>
    </div>
  </div>

  <div class="tree">
    <div style="margin: 10px auto;">
      <ng-template #recursiveList let-list>
        <li class="tree-item" *ngFor="let item of list">
          <div class="tree-node-wrapper">
            <div class="circle-wrapper" *ngIf="!isLeaf(item)" (click)="$event.stopPropagation();toggleNodeChildren(item)">
              <div class="circle" [ngClass]="{'expanded': item.expanded}">
              </div>
            </div>

            <div class="tree-node" [ngClass]="getNodeClass(item)" (click)="viewNode(item);">
              <div class="content">
                <div [ngClass]="getMandateIcon(item)"></div>
                <div class="info">
                  <span class="title">{{item.title}}</span>
                  <span class="position">{{item.position}}</span>
                </div>
              </div>
            </div>
          </div>
          <ul class="tree-child-item" *ngIf="getChildren(item).length > 0">
            <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: getChildren(item) }"></ng-container>
          </ul>
        </li>
      </ng-template>
      <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: list }"></ng-container>
    </div>
  </div>
</div>


