<div class="floating-jumbotron layer-filter mr-3 ml-0 mb-3">
  <label class="layer-filter-toggle" (click)="toggleLayer({ cityDistricts: !displayArcGisSettings.cityDistricts })"
    [class.active]="displayArcGisSettings.cityDistricts"
    tooltip="Show city districts"
    i18n-tooltip
         data-filter-name="city-district"
  >
    <i class="icon-s icon-city-disctrict"></i>
  </label>
  <label class="layer-filter-toggle" (click)="toggleLayer({ residentialAreas: !displayArcGisSettings.residentialAreas });"
    [class.active]="displayArcGisSettings.residentialAreas"
    tooltip="Show residential areas"
    i18n-tooltip
         data-filter-name="residential-area"
  >
    <i class="icon-s icon-city-disctrict"></i>
  </label>
  <label class="layer-filter-toggle" (click)="toggleLayer({ toilets: !displayArcGisSettings.toilets });"
    [class.active]="displayArcGisSettings.toilets"
    tooltip="Show public toilets"
    i18n-tooltip
         data-filter-name="public-toilet"
  >
    <i class="icon-s icon-toilets"></i>
  </label>
  <label class="layer-filter-toggle" (click)="displayItemsChanged({ waterTaps: !displaySettings.waterTaps });"
    [class.active]="displaySettings.waterTaps"
    tooltip="Show water taps"
    i18n-tooltip
         data-filter-name="water-tap"
  >
    <i class="icon-s icon-water-tap"></i>
  </label>
  <label class="layer-filter-toggle" (click)="displayItemsChanged({ noiseLevel: !displaySettings.noiseLevel });"
    [class.active]="displaySettings.noiseLevel"
    tooltip="Show sound pressure"
    i18n-tooltip
         data-filter-name="sound-pressure"
  >
    <i class="icon-s icon-noise"></i>
  </label>
  <label class="layer-filter-toggle" (click)="displayItemsChanged({ wifiAccessPoints: !displaySettings.wifiAccessPoints });"
    [class.active]="displaySettings.wifiAccessPoints"
    tooltip="Show Wi-Fi access points"
    i18n-tooltip
         data-filter-name="wifi-access-point"
  >
    <i class="icon-s icon-wifi"></i>
  </label>
  <label class="layer-filter-toggle" (click)="displayItemsChanged({ airQualityStations: !displaySettings.airQualityStations });"
    [class.active]="displaySettings.airQualityStations"
    tooltip="Show air quality stations"
    i18n-tooltip
         data-filter-name="air-quality-station"
  >
    <i class="icon-s icon-weather-station"></i>
  </label>
  <label class="layer-filter-toggle" (click)="toggleLayer({ recyclingStations: !displayArcGisSettings.recyclingStations });"
    [class.active]="displayArcGisSettings.recyclingStations"
    tooltip="Show recycling stations"
    i18n-tooltip
         data-filter-name="recycling-station"
  >
    <i class="icon-s icon-recycling-station"></i>
  </label>
</div>
