import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {
  PendingPersonSeriesGroup,
  PersonSeries,
  PersonSeriesGroup,
  PersonSeriesGroupListItem, PersonSeriesGroupConsent, PersonSeriesGroupPeriod
} from '../../../../core/src/lib/models/series';

import {SMARTENCITY_MYDATA_CONFIG} from '../injection-tokens';
import {MyDataConfig} from '../mydata-config.model';
import {PageResponse} from '../../../../core/src/lib/models/page-response';
import {Person} from '../../../../core/src/lib/models/person';
import {UrlParamsHelper} from '../../../../core/src/lib/helpers/url-params-helper';
import { MandateConsent } from '../../../../core/src/lib/models/profile';
import {PersonSeriesGroupConsentRequest} from '../../../../core/src/lib/models/http/person-series-group-consent-request';

@Injectable()
export class PersonSeriesGroupApiService {

  constructor(
    @Inject(SMARTENCITY_MYDATA_CONFIG) private config: MyDataConfig,
    private http: HttpClient) { }


  searchGroups(params: { query?: string, personSeriesId?: number, ownerTypes?: string[] }): Observable<PersonSeriesGroup[]> {
    if (!params) params = {};

    return this.http.get<PersonSeriesGroup[]>(this.config.apiUrl + '/person-series-group/search', {
      params: UrlParamsHelper.cleanParams(params)
    });
  }

  counts(id: number): Observable<any> {

    return this.http.get<any>(this.config.apiUrl + '/person-series-group/' + id + '/counts');
  }

  public getGroup(id: number, params?: any): Observable<PersonSeriesGroup> {
    if (!params) params = {};

    return this.http.get<PersonSeriesGroup>(this.config.apiUrl + '/person-series-group/' + id, {
      params: UrlParamsHelper.cleanParams(params)
    });
  }

  public getGroupList(): Observable<PageResponse<PersonSeriesGroupListItem>> {
    return this.http.get<PageResponse<PersonSeriesGroupListItem>>(this.config.apiUrl + '/person-series-group');
  }

  public getGroupPersonSeriesPage(params?: any): Observable<PageResponse<PersonSeries>> {
    if (!params) params = {};

    return this.http.get<PageResponse<PersonSeries>>(this.config.apiUrl + '/person-series-group/person-series', {
      params: UrlParamsHelper.cleanParams(params)
    });
  }

  public createGroup(data: any): Observable<PersonSeriesGroup> {

    return this.http.post<PersonSeriesGroup>(this.config.apiUrl + '/person-series-group', data);
  }

  public createGroupFromGroupingTag(groupingTag: string) {

    return this.http.post<PersonSeriesGroup>(this.config.apiUrl + '/person-series-group/grouping-tag/' + groupingTag, {});
  }

  public updateGroup(groupId: number, data: any): Observable<PersonSeriesGroup> {

    return this.http.put<PersonSeriesGroup>(this.config.apiUrl + '/person-series-group/' + groupId, data);
  }

  public deleteGroup(groupId: number, withChildren = false): Observable<void> {

    return this.http.delete<void>(this.config.apiUrl + '/person-series-group/' + groupId + (withChildren ? "?withChildren=true": ''));
  }

  public addConsent(data: PersonSeriesGroupConsentRequest): Observable<PersonSeriesGroupConsent> {

    return this.http.post<PersonSeriesGroupConsent>(this.config.apiUrl + '/person-series-group/consent', data);
  }

  public endConsent(groupId: number, periodId: number): Observable<any> {

    return this.http.put<any>(this.config.apiUrl + '/person-series-group/' + groupId + '/consent/' + periodId, {
      status: 'CANCELLED'
    });
  }

  public endBulkDatagroupConsents(data: {
    mandate: MandateConsent,
    groups: {id: number, periodId: number}[],
    isExcludeIds?: boolean
  }): Observable<any> {

    let request = {
      mandate: data.mandate,
      groups: data.groups,
      isExcludeIds: data.isExcludeIds,
      status: 'CANCELLED'
    };

    return this.http.put<any>(this.config.apiUrl + '/person-series-group/consent/status', request);
  }

  getOwnerPersonSeriesForConsentPerson(consentPerson: Person): Observable<PageResponse<PersonSeriesGroupListItem>> {
    const params: any = {
      personId: consentPerson.id
    }

    return this.http.get<PageResponse<PersonSeriesGroupListItem>>(this.config.apiUrl + '/person-series-group/consent', {
      params: params
    });
  }

  public getPendingConsents(pageNumber: number): Observable<PageResponse<PendingPersonSeriesGroup>> {
    const params: any = {
      page: pageNumber
    };

    return this.http.get<PageResponse<PendingPersonSeriesGroup>>(this.config.apiUrl + '/person-series-group/consent/pending', {
      params: params
    });
  }

  public updatePendingConsents(data: {periodIds: number[], status: string}): Observable<void> {
    return this.http.put<void>(this.config.apiUrl + '/person-series-group/consent/pending/status', data);
  }

  public getPersonSeriesGroupConsents(groupId: number): Observable<PageResponse<PersonSeriesGroupPeriod>> {

    return this.http.get<PageResponse<PersonSeriesGroupPeriod>>(this.config.apiUrl + '/person-series-group/' + groupId + '/consent');
  }

  public updateConsentStatus(groupId: number, status: string): Observable<any> {

    return this.http.put(this.config.apiUrl + '/person-series-group/consent/' + groupId + '/status', {
      status: status
    });
  }

  public deletePendingGroupConsent(id: number, groupPeriodId: number): Observable<void> {
    return this.http.delete<void>(this.config.apiUrl + '/person-series-group/' + id + '/consent/' + groupPeriodId);
  }

}
