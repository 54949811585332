<ng-template #playbackTemplate>
  <div class="jumbotron floating-jumbotron playback-wrapper">
    <div class="play-button" (click)="togglePlay()" [ngClass]="isPlaying ? 'pause' : 'play'">
      <i class="fa fa-play"></i>
    </div>
    <div class="playback">
      <ul *ngIf="histogram" class="bar-graph">
        <li *ngFor="let item of histogram.content" class="bar" [style.height]="(item.value != null ? item.value / 75.0 * 100.0 : 0) + '%'"></li>
      </ul>
      <div class="range">
        <div class="range-bar">
          <mat-slider class="w-100" min="0" max="47" step="1" [ngModel]="playValue" (ngModelChange)="playChanged($event)"></mat-slider>
        </div>
        <ul *ngIf="histogram" class="range-labels">
          <ng-container *ngFor="let item of histogram.content; let idx = index">
            <li *ngIf="idx%4 == 0" class="time">{{item.date | date: 'HH:mm'}}</li>
            <li *ngIf="idx%4 != 0"></li>
          </ng-container>
        </ul>
      </div>
    </div>
  </div>
</ng-template>
