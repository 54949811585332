import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subject} from 'rxjs/internal/Subject';
import {ManageService} from '../manage.service';
import {FieldFormComponent} from '../field-form/field-form.component';
import {QuestionnaireDetail} from '@smartencity/core';

const selector = 'app-questionnaire-fields';
let nextId = 0;

@Component({
  selector: selector,
  templateUrl: './questionnaire-fields.component.html',
  styleUrls: ['./questionnaire-fields.component.css']
})
export class QuestionnaireFieldsComponent implements OnInit, OnDestroy {
  id = `${selector}-${nextId++}`;
  private ngDestroy = new Subject<void>();

  @Input()
  public questionnaire: QuestionnaireDetail;

  @Input()
  public partialEdit = false;

  @Input()
  public type: string;

  @ViewChild('fieldForm')
  private fieldForm: FieldFormComponent;

  constructor(private manageService: ManageService) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  async save(): Promise<void> {
    await this.fieldForm.save();
  }
}
