import {Inject, Injectable} from '@angular/core';
import {PeopleTrafficDataService} from '../service/people-traffic-data.service';
import {HttpClient} from '@angular/common/http';
import {PeopleTrafficCounter} from '../model/people-traffic-counter';
import {Observable} from 'rxjs';
import {of} from 'rxjs/internal/observable/of';
import {CoreConfig, ListResponse, SMARTENCITY_CORE_CONFIG} from '@smartencity/core';
import moment from 'moment';

@Injectable()
export class PeopleTrafficApiDataService extends PeopleTrafficDataService {

  constructor(@Inject(SMARTENCITY_CORE_CONFIG) private config: CoreConfig,
              private http: HttpClient) {
    super();
  }

  loadSeries(counter: PeopleTrafficCounter, params?: any): Observable<any> {
    if (params) {
      return this.http.get<any[]>(this.config.cityApiUrl + '/person-series/' + counter.personSeriesId + '/historical/measurement', {params: params});
    } else {
      return of(null);
    }
  }

  getPeopleTraffic(): Observable<ListResponse<PeopleTrafficCounter>> {

    let date = moment().subtract(12, 'hours');

    return this.http.get<ListResponse<PeopleTrafficCounter>>(this.config.cityApiUrl + '/person-series', {
      params: {
        cityPortalType: 'PEOPLE_COUNT',
        size: '200',
        lastUpdatedTo: date.toISOString()
      }
    });
  }

}
