import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { GmapBuildingsComponent} from './component/gmap-buildings.component';
import {CoreModule} from '@smartencity/core';
import {NgbModalModule} from '@ng-bootstrap/ng-bootstrap';
import {SearchComponent} from './component/search/search.component';
import {TooltipModule} from "ngx-bootstrap/tooltip";
import { BuildingModalComponent } from './component/building-modal/building-modal.component';
import { BuildingsComponent } from './component/buildings.component';
import { OlBuildingsComponent } from './component/ol-buildings.component';
import { BuildingsRoutingModule } from './buildings-routing.module';
import { BuildingLayerFiltersComponent } from './building-layer-filters/building-layer-filters.component';
import { ArcGISModule } from 'projects/arc-gis/src/public-api';
import { BuildingsObjectsModalComponent } from './component/buildings-objects-modal/buildings-objects-modal.component';
import { BuildingsGmapService } from './service/buildings-gmap.service';
import { BuildingsOlMapService } from './service/buildings-ol.service';
import { BuildingsSearchService } from './service/buildings-search.service';
import {NgxMatDrpModule, SharedModule} from '@smartencity/shared';
import {CrowdInsightsDemoDataService} from './service/crowd-insights-demo-data.service';
import {CrowdInsightsDataService} from './service/crowd-insights-data.service';
import { CrowdInsightsGridAreaModalComponent } from './component/crowd-insights-grid-area-modal/crowd-insights-grid-area-modal.component';
import { GridAreaDailyActivitiesComponent } from './component/crowd-insights-grid-area-modal/grid-area-daily-activities/grid-area-daily-activities.component';
import { GridAreaDailyVisitsComponent } from './component/crowd-insights-grid-area-modal/grid-area-daily-visits/grid-area-daily-visits.component';
import {CrowdInsightsGridAreaModalService} from './component/crowd-insights-grid-area-modal/crowd-insights-grid-area-modal.service';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {TabsModule} from 'ngx-bootstrap/tabs';
import { ActivityTypeFilterComponent } from './component/crowd-insights-grid-area-modal/activity-type-filter/activity-type-filter.component';

@NgModule({
  declarations: [
    BuildingsComponent,
    GmapBuildingsComponent,
    OlBuildingsComponent,
    BuildingModalComponent,
    SearchComponent,
    BuildingLayerFiltersComponent,
    BuildingsObjectsModalComponent,
    CrowdInsightsGridAreaModalComponent,
    GridAreaDailyActivitiesComponent,
    GridAreaDailyVisitsComponent,
    ActivityTypeFilterComponent
  ],
  entryComponents: [
  ],
    imports: [
        BuildingsRoutingModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        CoreModule,
        NgbModalModule,
        NgxMatDrpModule,
        TooltipModule,
        ArcGISModule,
        SharedModule,
        NgxDatatableModule,
      TabsModule
    ],
  providers: [
    // BuildingsService,
    BuildingsOlMapService,
    BuildingsGmapService,
    BuildingsSearchService,
    {
      provide: CrowdInsightsDataService, useClass: CrowdInsightsDemoDataService
    },
    CrowdInsightsGridAreaModalService
  ],
  exports: [BuildingsComponent]
})
export class BuildingsModule { }
