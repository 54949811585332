import { Injectable, OnDestroy } from '@angular/core';
import { BuildingsSearchResults } from '../model/search/buildings-search-results';
import {Observable, BehaviorSubject, combineLatest, startWith, switchMap, of,} from 'rxjs';
import {
  takeUntil,
  distinctUntilChanged
} from 'rxjs/operators';
import {Subject} from 'rxjs/internal/Subject';
import {BuildingsGmapService, GmapState} from './buildings-gmap.service';
import {BuildingsSearchCriteria} from '../model/search/buildings-search-criteria';
import {BuildingsDisplaySettings} from '../buildings-config.model';
import {BuildingsService} from './buildings.service';

@Injectable()
export class BuildingsSearchService implements OnDestroy {

  private ngDestroy = new Subject<void>();

  private criteriaSubject = new BehaviorSubject<BuildingsSearchCriteria>(null);

  private criteria$ = this.criteriaSubject.asObservable();

  private searchResultsSubject = new BehaviorSubject<BuildingsSearchResults>(new BuildingsSearchResults());

  private searchResults$: Observable<BuildingsSearchResults> = this.searchResultsSubject.asObservable();

  constructor(
    private buildingService: BuildingsService,
    public buildingsGmapService: BuildingsGmapService
  ) {
  }

  public initSearch(keywordSearch$: Observable<string>): Observable<BuildingsSearchResults> {
    combineLatest([keywordSearch$, this.criteria$.pipe(distinctUntilChanged()), this.buildingsGmapService.stateDispatch$.pipe(startWith(null))]).pipe(
      switchMap(([keyword, criteria,]: [string, BuildingsSearchCriteria, GmapState]) => {
        return of(this.searchAll(keyword, criteria));
      }),
      takeUntil(this.ngDestroy)
    ).subscribe((results: BuildingsSearchResults) => {
      this.updateSearchResults(results);
    });

    return this.searchResults$;
  }

  private updateSearchResults(results: BuildingsSearchResults): void {
    this.searchResultsSubject.next(results);
  }

  private searchAll(keyword: string, criteria: BuildingsSearchCriteria): BuildingsSearchResults {
    const result = new BuildingsSearchResults();
    if (!keyword || keyword.length < 3) {
      return result;
    }

    if (criteria.buildings) {
      result.buildings = this.buildingService.searchBuildingsByKeyword(keyword);
    }

    if (criteria.windTurbines) {
      result.windTurbines = this.buildingService.searchWindTurbinesByKeyword(keyword);
    }

    return result;
  }

  ngOnDestroy(): void {
    this.ngDestroy.complete();
  }

  setUpdateDisplay(displaySettings: BuildingsDisplaySettings) {
    this.criteriaSubject.next({
      buildings: true,
      windTurbines: !!displaySettings.windTurbines?.active
    });
  }

}
