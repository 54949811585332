import {Inject, Injectable, OnInit} from '@angular/core';
import {Subject} from 'rxjs/internal/Subject';
import {SMARTENCITY_MYDATA_CONFIG} from '../../injection-tokens';
import {MyDataConfig} from '../../mydata-config.model';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class MyQuestionnairesService implements OnInit {
  private ngDestroy = new Subject<void>();

  public constructor(
    @Inject(SMARTENCITY_MYDATA_CONFIG) private config: MyDataConfig,
    private http: HttpClient
  ) {
  }

  ngOnInit(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  public acceptInvite(inviteUuid) {
    return this.http.post(this.config.apiUrl + '/questionnaire-participation/invite-uuid/' + inviteUuid + '/accept', {});
  }
}
