<ng-container [formGroup]="adviceGroup">
  <div class="col-4">
    <label i18n [attr.for]="id + '-category'">Category</label>
    <input type="text" class="form-control" [class.is-invalid]="invalid('category')" formControlName="category" [id]="id + '-category'">
    <small *ngIf="invalid('category') && errors('category')?.required" class="invalid-feedback d-block" i18n>Required</small>
  </div>
  <div class="col-8">
    <label i18n [attr.for]="id + '-name'">Title</label>
    <input type="text" class="form-control" [class.is-invalid]="invalid('name')" formControlName="name" [id]="id + '-name'">
    <small *ngIf="invalid('name') && errors('name')?.required" class="invalid-feedback d-block" i18n>Required</small>
    <div [hidden]="adviceGroup.get('description').value === null">
      <textarea type="text" class="form-control mt-4" formControlName="description" placeholder="Description" i18n-placeholder rows="1"></textarea>
    </div>
    <a [routerLink]="null" class="float-right small-text mt-2" [hidden]="adviceGroup.disabled || adviceGroup.get('description').value !== null" (click)="addDescription()">&#43;&nbsp;<ng-container i18n>Add description</ng-container></a>
    <a [routerLink]="null" class="float-right small-text mt-2" [hidden]="adviceGroup.disabled || adviceGroup.get('description').value === null" (click)="removeDescription()">&#8722;&nbsp;<ng-container i18n>Remove description</ng-container></a>
  </div>
</ng-container>
