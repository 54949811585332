<div id="smoke">
  <span class="s0"></span>
  <span class="s1"></span>
  <span class="s2"></span>
  <span class="s3"></span>
  <span class="s4"></span>
  <span class="s5"></span>
  <span class="s6"></span>
  <span class="s7"></span>
  <span class="s8"></span>
  <span class="s9"></span>
</div>
