<div class="d-flex">
  <div class="row d-flex no-gutters w-100 position-relative">
    <div class="map-container" style="width: 100%">
      <div class="citymap map" #gmap style="height: 100%"></div>
    </div>

    <div *ngIf="displaySettings?.buses" class="bus-info-legend__wrapper">
      <mobility-bus-info-legend></mobility-bus-info-legend>
    </div>

    <div class="floating-wrapper">
      <div class="position-relative mr-lg-3">
        <ng-container>
          <mobility-search *ngIf="displaySettings?.search"></mobility-search>
          <mobility-stop-info-modal #stopInfoModal></mobility-stop-info-modal>
          <mobility-bus-info-modal #busInfoModal></mobility-bus-info-modal>
        </ng-container>
      </div>

      <div class="floating-jumbotron layer-filter mr-3">
        <label *ngIf="displaySettings?.buses" class="layer-filter-toggle" (click)="displayItemsChanged('buses', !displaySettings.buses.active)"
          [class.active]="displaySettings?.buses.active"
          tooltip="Show buses"
          data-filter-name="bus"
          i18n-tooltip
        >
          <i class="icon-s icon-bus"></i>
        </label>
        <label *ngIf="displaySettings?.stops" class="layer-filter-toggle" (click)="displayItemsChanged('stops', !displaySettings?.stops.active)"
          [class.active]="displaySettings?.stops.active"
          tooltip="Show bus stops" data-filter-name="bus-stop" i18n-tooltip
        >
          <i class="icon-s icon-bustop"></i>
        </label>
        <label *ngIf="displaySettings?.routes" class="layer-filter-toggle" (click)="displayItemsChanged('routes', !displaySettings?.routes.active)"
          [class.active]="displaySettings.routes.active"
          tooltip="Show bus routes" data-filter-name="bus-route" i18n-tooltip
        >
          <i class="icon-s icon-route"></i>
        </label>
        <label *ngIf="displaySettings?.traffic" class="layer-filter-toggle" (click)="displayItemsChanged('traffic', !displaySettings?.traffic.active)"
          [class.active]="displaySettings?.traffic.active"
          tooltip="Show traffic" data-filter-name="traffic" i18n-tooltip
        >
          <i class="icon-s icon-traffic"></i>
        </label>
        <label *ngIf="displaySettings?.trafficThroughput" class="layer-filter-toggle" (click)="displayItemsChanged('trafficThroughput',!displaySettings?.trafficThroughput.active)"
          [class.active]="displaySettings?.trafficThroughput.active"
          tooltip="Show traffic monitoring" data-filter-name="traffic-throughput" i18n-tooltip
        >
          <i class="icon-s icon-traffic-sensor"></i>
        </label>
        <label *ngIf="displaySettings?.electricVehicleCharger" class="layer-filter-toggle" (click)="displayItemsChanged('electricVehicleCharger', !displaySettings?.electricVehicleCharger.active)"
          [class.active]="displaySettings?.electricVehicleCharger.active"
          tooltip="Show electric vehicle charger stations"
               data-filter-name="electric-vehicle-charging-station"
          i18n-tooltip
        >
          <i class="icon-s icon-charger-station"></i>
        </label>
        <label *ngIf="displaySettings?.evChargerDk" class="layer-filter-toggle" (click)="displayItemsChanged('evChargerDk', !displaySettings?.evChargerDk.active)"
          [class.active]="displaySettings?.evChargerDk.active"
          tooltip="Show electric vehicle charger stations"
               data-filter-name="electric-vehicle-charging-station"
          i18n-tooltip
        >
          <i class="icon-s icon-charger-station"></i>
        </label>
        <label *ngIf="displaySettings?.bicycleStation" class="layer-filter-toggle" (click)="displayItemsChanged('bicycleStation',!displaySettings?.bicycleStation.active)"
          [class.active]="displaySettings?.bicycleStation.active"
          tooltip="Show electric bicycle stations"
               data-filter-name="electric-bicycle-station"
          i18n-tooltip
        >
          <i class="icon-s icon-bicycle-station"></i>
        </label>

        <label *ngIf="displaySettings?.bicycleParkingStation" class="layer-filter-toggle" (click)="displayItemsChanged('bicycleParkingStation', !displaySettings.bicycleParkingStation.active)"
          [class.active]="displaySettings?.bicycleParkingStation.active"
               data-filter-name="bicycle-parking-station"
          tooltip="Show bicycle stations"
          i18n-tooltip
        >
          <i class="icon-s icon-bicycle-parking-station"></i>
        </label>
        <label *ngIf="displaySettings?.people" class="layer-filter-toggle" (click)="displayItemsChanged('people', !displaySettings?.people.active)"
          [class.active]="displaySettings?.people.active"
          tooltip="Show people"
               data-filter-name="people"
          i18n-tooltip
        >
          <i class="icon-s icon-mobility-people"></i>
        </label>

        <label *ngIf="displaySettings?.roadClosures" class="layer-filter-toggle" (click)="displayItemsChanged('roadClosures', !displaySettings?.roadClosures.active)"
          [class.active]="displaySettings?.roadClosures.active"
          tooltip="Show road closures"
               data-filter-name="road-closure"
          i18n-tooltip
        >
          <i class="icon-s icon-road-closure"></i>
        </label>
      </div>

      <ng-container
        *ngIf="
          displaySettings?.bicycleStation?.active &&
          bicycleStationsHeatmapComponent?.playbackTemplate
        "
      >
        <ng-template
          *ngTemplateOutlet="bicycleStationsHeatmapComponent.playbackTemplate"
        ></ng-template>
      </ng-container>

      <ng-container *ngIf="displaySettings?.people?.active">
        <ng-template
          *ngTemplateOutlet="peopleTowersHeatmapComponent?.playbackTemplate"
        ></ng-template>
      </ng-container>
    </div>
    <mobility-bicycle-stations-heatmap
      [map]="map"
      *ngIf="displaySettings?.bicycleStation?.active"
      #bicycleStationsHeatmapComponent
    ></mobility-bicycle-stations-heatmap>
    <mobility-people-towers-heatmap
      [map]="map"
      *ngIf="displaySettings?.people?.active"
      #peopleTowersHeatmapComponent
    ></mobility-people-towers-heatmap>
    <mobility-bicycle-parking-stations-heatmap
      [map]="map"
      *ngIf="displaySettings?.bicycleParkingStation?.active"
      #bicycleParkingStationsHeatmapComponent
    ></mobility-bicycle-parking-stations-heatmap>
  </div>
</div>
