<div class="modal-header">
  <h5 class="modal-title w-100" i18n *ngIf="!adviceGroup?.id">Add advices</h5>
  <h5 class="modal-title w-100" i18n *ngIf="adviceGroup?.id">Edit advices</h5>
  <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
    <span class="fal fa-times"></span>
  </button>
</div>
<div class="modal-body" [formGroup]="form">
  <div *ngIf="(form.dirty || form.touched) && !form.valid && showErrors" class="alert alert-danger">
    <div i18n>Form is invalid</div>
  </div>

  <div class="form-group">
    <div class="form-row">
      <div class="col">
        <label i18n [attr.for]="id + '-name'">Name</label>
        <input type="text"  class="form-control" [class.is-invalid]="invalid('name')" formControlName="name" [id]="id + '-name'"  [coreValidate]="this.showErrors">
        <small *ngIf="invalid('name') && errors('name')?.required" class="invalid-feedback d-block" i18n>Required</small>
      </div>
    </div>
  </div>
  <div class="form-group">
    <div class="form-row">
      <div class="col">
        <label i18n [attr.for]="id + '-description'">Description</label>
        <input type="text"  class="form-control" [class.is-invalid]="invalid('description')" formControlName="description" [id]="id + '-description'"  [coreValidate]="this.showErrors">
        <small *ngIf="invalid('description') && errors('description')?.required" class="invalid-feedback d-block" i18n>Required</small>
      </div>
    </div>
  </div>
  <div class="form-group">
    <div class="form-row">
      <div class="col">
        <div class="checkbox">
          <input type="checkbox" formControlName="published" [id]="id + '-published'">
          <label [attr.for]="id + '-published'" i18n>Published</label>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-6">
      <h3 i18n class="mt-2">Advice</h3>
    </div>
    <div class="col-6"></div>
  </div>

  <div class="mb-3" *ngFor="let adviceGroup of adviceArray.controls; let i=index" [formGroup]="adviceGroup">
    <ul class="nav nav-links nav-links-small justify-content-end">
      <li class="nav-item" [hidden]="i < 1">
        <a [routerLink]="" class="nav-link" (click)="sortOrderUp(i)"><i class="fa fa-arrow-up"></i>&nbsp;<ng-container i18n>Move up</ng-container></a>
      </li>
      <li class="nav-item separator" [hidden]="i < 1">
        <span></span>
      </li>
      <li class="nav-item" [hidden]="i >= adviceArray.length - 1">
        <a [routerLink]="null" class="nav-link" (click)="sortOrderDown(i)"><i class="fa fa-arrow-down"></i>&nbsp;<ng-container i18n>Move down</ng-container></a>
      </li>
      <li class="nav-item separator" [hidden]="i >= adviceArray.length - 1">
        <span></span>
      </li>
      <li class="nav-item">
        <a [routerLink]="" class="nav-link" (click)="removeField(i)" [hidden]="adviceArray.length < 2"><i class="fa fa-times"></i>&nbsp;<ng-container i18n>Remove</ng-container></a>
      </li>
    </ul>
    <mydata-advice-row class="row bg-light-gray" [adviceGroup]="adviceGroup"></mydata-advice-row>
  </div>

  <button class="btn btn-outline-primary btn-sm mr-3" type="button" (click)="addAdvice()">
    <i class="fal fa-plus"></i>&nbsp;<ng-container i18n>Add advice</ng-container>
  </button>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-light btn-sm" aria-label="Close" (click)="close()" i18n>Close</button>
  <button type="button" class="btn btn-primary btn-sm" (click)="save()" i18n>Save</button>
</div>
