import {AirQualityStation} from '../model/air-quality-station';
import {TallinnService} from '../service/tallinn.service';
import {AbstractMapLayer, OlMapWrapper} from '@smartencity/core';
import {AirQualityStationOlMarker} from './air-quality-station-ol-marker';

export class AirQualityStationOlLayer extends AbstractMapLayer<OlMapWrapper, AirQualityStation>{

  public airQualityStations: AirQualityStation[] = [];

  private tallinnService: TallinnService;

  constructor(airQualityStations: AirQualityStation[], tallinnService: TallinnService) {
    super();
    this.airQualityStations = airQualityStations;
    this.tallinnService = tallinnService;
  }

  setMap(map: OlMapWrapper): void {
    super.setMap(map);
    this.resetMarkers();
    if (map) {
      this.createMarkers();
    }
  }

  setAirQualityStation(selectedStation: AirQualityStation): void {
    this.getMarkers().forEach((marker: AirQualityStationOlMarker) => {
      marker.setSelected(marker.getData() == selectedStation);
      marker.draw();
    });
  }

  protected resetMarkers(): void {
    let markers = this.getMarkers();
    if (markers.length > 0) {
      for (const marker of markers) {
        marker.setMap(null);
      }
    }
    this.clearMarkers();
  }

  protected createMarkers(): void {
    for (const airQualityStation of this.airQualityStations) {
      this.addMarker(new AirQualityStationOlMarker(airQualityStation, this.map, this.tallinnService));
    }
  }

}
