import {Component, OnDestroy, OnInit} from '@angular/core';
import {Location, LocationService} from '@smartencity/core';
import {CurrentLocationService} from '../../services/current-location.service';
import {Subject} from 'rxjs/internal/Subject';
import {Router} from '@angular/router';

@Component({
  selector: 'location-datapoints-locations',
  templateUrl: './datapoints-locations.component.html',
  styleUrls: ['./datapoints-locations.component.css']
})
export class DatapointsLocationsComponent implements OnDestroy, OnInit {
  private ngDestroy = new Subject<void>();

  currentLocation$ = this.currentLocationService.currentLocation$;
  locations$ = this.currentLocationService.subLocations$;

  constructor(
    private currentLocationService: CurrentLocationService,
    private locationService: LocationService,
    private router: Router
  ) {

  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  ngOnInit(): void {
  }

  showMore(location: Location) {
    console.log(location);
    this.locationService.navigate(location);
  }
}
