<ng-template #navActions>

  <core-compare-button class="d-none d-lg-inline-block"></core-compare-button>
  <button type="button" class="btn btn-sm btn-primary d-lg-inline-block d-none" (click)="createGroup()">
    <i class="fal fa-plus"></i>&nbsp;<span i18n>Create new datagroup</span>
  </button>

  <div class="btn-floating-mobile-container">
    <button type="button" class="btn btn-sm btn-primary btn-floating-mobile" (click)="createGroup()">
      <i class="fal fa-plus fa-2x"></i>
    </button>

    <core-compare-button></core-compare-button>
  </div>
</ng-template>

<div class="container-fluid p-4">
  <div class="row mb-3 mb-lg-5">
    <div class="col-md-4 col-sm-12 mb-sm-3">
      <div class="btn-group" role="group">
        <button type="button" [class.active]="view === 'datapoint-groups-list'" class="btn btn-filter" (click)="setView('datapoint-groups-list')"><span i18n>Datagroups</span></button>
        <button type="button" [class.active]="view === 'datapoint-groups-series-list'" class="btn btn-filter" (click)="setView('datapoint-groups-series-list')"><span i18n>Datapoints</span></button>
      </div>
    </div>
    <div class="col-lg-4 col-md-8 col-sm-12">
      <div class="input-group input-group-lg form-search" [formGroup]="filterForm">
        <input formControlName="query" type="text" class="form-control form-control-lg" placeholder="Type to search..." i18n-placeholder>
        <div class="input-group-append">
        <span class="clear-search active" (click)="clearSearch()" *ngIf="filterForm.get('query')?.value?.length > 0">
          <i class="fal fa-times"></i>
        </span>
        <div *ngIf="view == 'datapoint-groups-series-list'" class="custom-dropdown filter-dropdown" dropdown [autoClose]="true" (click)="$event.stopImmediatePropagation();">
          <button type="button" class="btn dropdown-toggle" dropdownToggle i18n>Filter</button>
          <div class="dropdown-menu dropdown-menu-right" *dropdownMenu>
            <div class="accordion-group"  [ngClass]="'show'">
              <div class="accordion-header">
                <i class="fal fa-angle-down"></i>
                <ng-container i18n>Status</ng-container>
              </div>
              <div class="accordion-content">
                <div class="checkbox">
                  <input type="checkbox" formControlName="confirmationStatusPending">
                  <label i18n>Pending</label>
                </div>
                <div class="checkbox">
                  <input type="checkbox" formControlName="confirmationStatusAccepted">
                  <label i18n>Accepted</label>
                </div>
                <div class="checkbox">
                  <input type="checkbox" formControlName="confirmationStatusDeclined">
                  <label i18n>Declined</label>
                </div>
              </div>
            </div>
            <div class="accordion-group"  [ngClass]="'show'">
              <div class="accordion-header" *ngIf="isCityUser">
                <i class="fal fa-angle-down"></i>
                <ng-container i18n>Show on city portal</ng-container>
              </div>
              <div class="accordion-content" *ngIf="isCityUser">
                <div class="checkbox">
                  <input type="checkbox" formControlName="showOnCityPortalTrue">
                  <label i18n>Yes</label>
                </div>
                <div class="checkbox">
                  <input type="checkbox" formControlName="showOnCityPortalFalse">
                  <label i18n>No</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <span class="input-group-text">
          <i class="fal fa-search"></i>
        </span>
        </div>
      </div>
    </div>
    <div class="col-4 text-right">
    </div>
  </div>

  <div *ngIf="view == 'datapoint-groups-list'">
    <datapoint-groups-list></datapoint-groups-list>
  </div>
  <div *ngIf="view == 'datapoint-groups-series-list'">
    <datapoint-groups-series-list></datapoint-groups-series-list>
  </div>

</div>
