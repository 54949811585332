<div class="d-flex flex-grow-1 flex-column">
  <div class="d-block" i18n>{{assignerName}} is updating <b>{{datapointName}}</b> metadata as follows:</div>
  <div>
    <div><b><ng-container i18n>Name</ng-container>:</b> {{updatedName}}</div>
    <div><b><ng-container i18n>Grouping tag</ng-container>:</b> {{updatedGroupingTag}}</div>
    <ng-container *ngIf="item.personSeriesMeta.locationType == 'ADDRESS'">
      <div><b><ng-container i18n>Address</ng-container>:</b> {{updatedAddress}}</div>
      <div><b><ng-container i18n>Apartment</ng-container>:</b> {{updatedApartment}}</div>
      <div><b><ng-container i18n>Room</ng-container>:</b> {{updatedRoom}}</div>
    </ng-container>
    <ng-container *ngIf="item.personSeriesMeta.locationType == 'UUID'">
      <div><b><ng-container i18n>UUID</ng-container>:</b> {{updatedUuid}}</div>
    </ng-container>
    <ng-container *ngIf="item.personSeriesMeta.locationType == 'COORDS'">
      <div><b><ng-container i18n>Lat</ng-container>:</b> {{updatedLat}}</div>
      <div><b><ng-container i18n>Lng</ng-container>:</b> {{updatedLng}}</div>
    </ng-container>
  </div>
  <br>
  <small class="help-text">{{item.assignerComment}}</small>
</div>

<div class="action-buttons text-right">
  <button
    (click)="declinePersonSeriesMeta(item)"
    type="button"
    class="btn btn-rounded btn-sm btn-danger ml-2 mb-2"
    i18n
    [disabled]="progress || parentProgress"
  >Decline</button>
  <button
    (click)="acceptPersonSeriesMeta(item)"
    type="button"
    class="btn btn-rounded btn-sm btn-success ml-2 mb-2"
    i18n
    [disabled]="progress || parentProgress"
  >Accept</button>
</div>

