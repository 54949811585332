import Map = google.maps.Map;
import {WifiAccessPoint} from '../model/wifi-access-point';
import {WifiAccessPointsOverlayView} from './wifi-access-points-overay-view';
import {AbstractMapLayer} from '@smartencity/core';

export class WifiAccessPointsGmapLayer extends AbstractMapLayer<Map, WifiAccessPoint>{

  private layer: WifiAccessPointsOverlayView;

  constructor(accessPoints: WifiAccessPoint[]) {
    super();
    this.layer = new WifiAccessPointsOverlayView(accessPoints);
  }

  setMap(map: google.maps.Map) {
    super.setMap(map);
    this.layer.setMap(map);
  }

}
