import {Injectable} from '@angular/core';
import {CrowdInsightsDataService} from './crowd-insights-data.service';
import {MapsGridArea} from '../model/crowd-insights/maps-grid-area';
import {ActivityDaily} from '../model/crowd-insights/activity-daily';
import {Observable, of} from 'rxjs';
import {VisitDaily} from '../model/crowd-insights/visit-daily';
import {ActivityDailyFilter} from '../model/crowd-insights/activity-daily-filter';
import {VisitDailyFilter} from '../model/crowd-insights/visit-daily-filter';
import {MunicipalityArea} from '../model/crowd-insights/municipality-area';

@Injectable()
export class CrowdInsightsDemoDataService extends CrowdInsightsDataService {

  private activities: any[][] = [
    ['Kesklinn V', 'WORK', '2023-04-10', 549, '60-120'],
    ['Kesklinn V', 'WORK', '2023-04-10', 921, '120-300'],
    ['Kesklinn V', 'WORK', '2023-04-10', 2136, '300+'],
    ['Kesklinn V', 'WORK', '2023-04-11', 651, '60-120'],
    ['Kesklinn V', 'WORK', '2023-04-11', 1071, '120-300'],
    ['Kesklinn V', 'WORK', '2023-04-11', 1968, '300+'],
    ['Kesklinn V', 'WORK', '2023-04-12', 769, '60-120'],
    ['Kesklinn V', 'WORK', '2023-04-12', 1224, '120-300'],
    ['Kesklinn V', 'WORK', '2023-04-12', 2102, '300+'],
    ['Kesklinn V', 'WORK', '2023-04-13', 905, '60-120'],
    ['Kesklinn V', 'WORK', '2023-04-13', 1352, '120-300'],
    ['Kesklinn V', 'WORK', '2023-04-13', 2046, '300+'],
    ['Kesklinn V', 'WORK', '2023-04-14', 768, '60-120'],
    ['Kesklinn V', 'WORK', '2023-04-14', 1234, '120-300'],
    ['Kesklinn V', 'WORK', '2023-04-14', 2098, '300+'],
    ['Kesklinn V', 'WORK', '2023-04-15', 0, '60-120'],
    ['Kesklinn V', 'WORK', '2023-04-15', 0, '120-300'],
    ['Kesklinn V', 'WORK', '2023-04-15', 0, '300+'],
    ['Kesklinn V', 'WORK', '2023-04-16', 0, '60-120'],
    ['Kesklinn V', 'WORK', '2023-04-16', 0, '120-300'],
    ['Kesklinn V', 'WORK', '2023-04-16', 0, '300+'],
    ['Kesklinn V', 'HOME', '2023-04-10', 11, '60-120'],
    ['Kesklinn V', 'HOME', '2023-04-10', 45, '120-300'],
    ['Kesklinn V', 'HOME', '2023-04-10', 943, '300+'],
    ['Kesklinn V', 'HOME', '2023-04-11', 8, '60-120'],
    ['Kesklinn V', 'HOME', '2023-04-11', 48, '120-300'],
    ['Kesklinn V', 'HOME', '2023-04-11', 970, '300+'],
    ['Kesklinn V', 'HOME', '2023-04-12', 11, '60-120'],
    ['Kesklinn V', 'HOME', '2023-04-12', 41, '120-300'],
    ['Kesklinn V', 'HOME', '2023-04-12', 1027, '300+'],
    ['Kesklinn V', 'HOME', '2023-04-13', 6, '60-120'],
    ['Kesklinn V', 'HOME', '2023-04-13', 53, '120-300'],
    ['Kesklinn V', 'HOME', '2023-04-13', 996, '300+'],
    ['Kesklinn V', 'HOME', '2023-04-14', 42, '120-300'],
    ['Kesklinn V', 'HOME', '2023-04-14', 938, '300+'],
    ['Kesklinn V', 'HOME', '2023-04-15', 8, '20-60'],
    ['Kesklinn V', 'HOME', '2023-04-15', 11, '60-120'],
    ['Kesklinn V', 'HOME', '2023-04-15', 89, '120-300'],
    ['Kesklinn V', 'HOME', '2023-04-15', 1042, '300+'],
    ['Kesklinn V', 'HOME', '2023-04-16', 6, '20-60'],
    ['Kesklinn V', 'HOME', '2023-04-16', 8, '60-120'],
    ['Kesklinn V', 'HOME', '2023-04-16', 92, '120-300'],
    ['Kesklinn V', 'HOME', '2023-04-16', 1114, '300+'],
    ['Kesklinn V', 'OTHER', '2023-04-10', 4810, '5-20'],
    ['Kesklinn V', 'OTHER', '2023-04-10', 3172, '20-60'],
    ['Kesklinn V', 'OTHER', '2023-04-10', 1685, '60-120'],
    ['Kesklinn V', 'OTHER', '2023-04-10', 1738, '120-300'],
    ['Kesklinn V', 'OTHER', '2023-04-10', 283, '300+'],
    ['Kesklinn V', 'OTHER', '2023-04-11', 4839, '5-20'],
    ['Kesklinn V', 'OTHER', '2023-04-11', 3178, '20-60'],
    ['Kesklinn V', 'OTHER', '2023-04-11', 1765, '60-120'],
    ['Kesklinn V', 'OTHER', '2023-04-11', 1077, '120-300'],
    ['Kesklinn V', 'OTHER', '2023-04-11', 287, '300+'],
    ['Kesklinn V', 'OTHER', '2023-04-12', 5062, '5-20'],
    ['Kesklinn V', 'OTHER', '2023-04-12', 1690, '60-120'],
    ['Kesklinn V', 'OTHER', '2023-04-12', 1522, '120-300'],
    ['Kesklinn V', 'OTHER', '2023-04-12', 290, '300+'],
    ['Kesklinn V', 'OTHER', '2023-04-13', 4819, '5-20'],
    ['Kesklinn V', 'OTHER', '2023-04-13', 3236, '20-60'],
    ['Kesklinn V', 'OTHER', '2023-04-13', 1730, '60-120'],
    ['Kesklinn V', 'OTHER', '2023-04-13', 1281, '120-300'],
    ['Kesklinn V', 'OTHER', '2023-04-13', 279, '300+'],
    ['Kesklinn V', 'OTHER', '2023-04-14', 5209, '5-20'],
    ['Kesklinn V', 'OTHER', '2023-04-14', 3651, '20-60'],
    ['Kesklinn V', 'OTHER', '2023-04-14', 1752, '60-120'],
    ['Kesklinn V', 'OTHER', '2023-04-14', 1732, '120-300'],
    ['Kesklinn V', 'OTHER', '2023-04-14', 631, '300+'],
    ['Kesklinn V', 'OTHER', '2023-04-15', 3724, '5-20'],
    ['Kesklinn V', 'OTHER', '2023-04-15', 2721, '20-60'],
    ['Kesklinn V', 'OTHER', '2023-04-15', 2126, '60-120'],
    ['Kesklinn V', 'OTHER', '2023-04-15', 2634, '120-300'],
    ['Kesklinn V', 'OTHER', '2023-04-15', 1701, '300+'],
    ['Kesklinn V', 'OTHER', '2023-04-16', 2749, '5-20'],
    ['Kesklinn V', 'OTHER', '2023-04-16', 2194, '20-60'],
    ['Kesklinn V', 'OTHER', '2023-04-16', 1787, '60-120'],
    ['Kesklinn V', 'OTHER', '2023-04-16', 1905, '120-300'],
    ['Kesklinn V', 'OTHER', '2023-04-16', 912, '300+'],

/*    ['Kesklinn V', 'TOTAL', '2023-04-10', 559, '60-120'],
    ['Kesklinn V', 'TOTAL', '2023-04-10', 936, '120-300'],
    ['Kesklinn V', 'TOTAL', '2023-04-10', 2436, '300+'],
    ['Kesklinn V', 'TOTAL', '2023-04-11', 655, '60-120'],
    ['Kesklinn V', 'TOTAL', '2023-04-11', 1072, '120-300'],
    ['Kesklinn V', 'TOTAL', '2023-04-11', 2257, '300+'],
    ['Kesklinn V', 'TOTAL', '2023-04-12', 769, '60-120'],
    ['Kesklinn V', 'TOTAL', '2023-04-12', 1225, '120-300'],
    ['Kesklinn V', 'TOTAL', '2023-04-12', 2402, '300+'],
    ['Kesklinn V', 'TOTAL', '2023-04-13', 915, '60-120'],
    ['Kesklinn V', 'TOTAL', '2023-04-13', 1367, '120-300'],
    ['Kesklinn V', 'TOTAL', '2023-04-13', 2346, '300+'],
    ['Kesklinn V', 'TOTAL', '2023-04-14', 769, '60-120'],
    ['Kesklinn V', 'TOTAL', '2023-04-14', 1249, '120-300'],
    ['Kesklinn V', 'TOTAL', '2023-04-14', 2341, '300+'],
    ['Kesklinn V', 'TOTAL', '2023-04-15', 0, '60-120'],
    ['Kesklinn V', 'TOTAL', '2023-04-15', 4, '120-300'],
    ['Kesklinn V', 'TOTAL', '2023-04-15', 114, '300+'],
    ['Kesklinn V', 'TOTAL', '2023-04-16', 2, '60-120'],
    ['Kesklinn V', 'TOTAL', '2023-04-16', 0, '120-300'],
    ['Kesklinn V', 'TOTAL', '2023-04-16', 123, '300+'],*/
  ];

  private visits: any[][] = [
    ['2365', 'Kesklinn V', 'WORK', '2023-04-10', 10, 'Harju maakond', '0037', 'Tallinn', '0784', 'Kristiine linnaosa', '339', '270', 'Endla'],
    ['2365', 'Kesklinn V', 'WORK', '2023-04-10', 7, 'Pärnu maakond', '0068', 'Pärnu linn', '0624', 'Aruvälja küla', '1394', '1838', 'Põhara'],
    ['2365', 'Kesklinn V', 'WORK', '2023-04-10', 6, 'Harju maakond', '0037', 'Suurupi küla', '0198', 'Suurupi küla', '7870', '39', 'Tempo AK'],
    ['2365', 'Kesklinn V', 'WORK', '2023-04-11', 76, 'Harju maakond', '0037', 'Saue vald', '0726', 'Laitse küla', '4075', '843', 'Laitse tee'],
    ['2365', 'Kesklinn V', 'HOME', '2023-04-11', 11, 'Tartu maakond', '0079', 'Tartu linn', '0793', 'Tartu linn', '8151', '2444', 'Nõlvaku'],
    ['2365', 'Kesklinn V', 'HOME', '2023-04-12', 32, 'Valga maakond', '0081', 'Valga vald', '0855', 'Valga linn', '8918', '2701', 'no_name'],
    ['2365', 'Kesklinn V', 'HOME', '2023-04-12', 3, 'Lääne-Viru maakond', '0060', 'Rakvere linn', '0663', 'Rakvere linn', '0663', '1147', 'Mäe'],
    ['2365', 'Kesklinn V', 'WORK', '2023-04-12', 6, 'Lääne-Viru maakond', '0060', 'Väike-Maarja vald', '0928', 'Kellamäe küla', '2934', '1403', 'Rakke'],
    ['2365', 'Kesklinn V', 'WORK', '2023-04-13', 7, 'Ida-Viru maakond', '0045', 'Narva linn', '0928', 'Narva linn', '0511', '1657', 'Narva'],
    ['2365', 'Kesklinn V', 'HOME', '2023-04-13', 11, 'Järva maakond', '0052', 'Järva vald', '0255', 'Vetepere küla', '9243', '1288', 'Järva-Madise'],
    ['2365', 'Kesklinn V', 'HOME', '2023-04-13', 12, 'Viljandi maakond', '0084', 'Põhja-Sakala vald', '0615', 'Metsküla', '4925', '2101', 'Alustre'],

  ];

  // {
  //   "type": "Polygon",
  //   "coordinates": [
  //     [
  //       [
  //         26.7136222950898,
  //         58.370842806491
  //       ],
  //       [
  //         26.7139679144564,
  //         58.3753283435938
  //       ],
  //       [
  //         26.7054292658655,
  //         58.3755096434394
  //       ],
  //       [
  //         26.7050847315189,
  //         58.3710240833159
  //       ],
  //       [
  //         26.7136222950898,
  //         58.370842806491
  //       ]
  //     ]
  //   ]
  // }

  private areasData: any[][] = [
    [
      2363,
      'Tartu',
      {
        'type': 'Polygon',
        'coordinates': [
          [
            [
              26.7136222950898,
              58.370842806491
            ],
            [
              26.7139679144564,
              58.3753283435938
            ],
            [
              26.7054292658655,
              58.3755096434394
            ],
            [
              26.7050847315189,
              58.3710240833159
            ],
            [
              26.7136222950898,
              58.370842806491
            ]
          ]
        ]
      }
    ],
    [
      2365,
      'Kesklinn V',
      {
        'type': 'Polygon',
        'coordinates': [
          [
            [
              26.7310449473555,
              58.3749640309448
            ],
            [
              26.7313928252147,
              58.3794495194678
            ],
            [
              26.7228532676759,
              58.3796319844523
            ],
            [
              26.7225064750449,
              58.3751464727593
            ],
            [
              26.7310449473555,
              58.3749640309448
            ]
          ]
        ]
      }
    ],
    [
      2357,
      'Vene',
      {
        "type": "Polygon",
        "coordinates": [
          [
            [
              26.7317407916608,
              58.3839350056418
            ],
            [
              26.7320888467276,
              58.3884204894396
            ],
            [
              26.7235471179062,
              58.3886030007787
            ],
            [
              26.7232001486184,
              58.3841174938013
            ],
            [
              26.7317407916608,
              58.3839350056418
            ]
          ]
        ]
      }
    ],
    [
      2425,
      'Raatuse',
      {
        "type": "Polygon",
        "coordinates": [
          [
            [
              26.7399322941665,
              58.3792664834398
            ],
            [
              26.7402813460823,
              58.3837519463666
            ],
            [
              26.7317407916608,
              58.3839350056418
            ],
            [
              26.7313928252147,
              58.3794495194678
            ],
            [
              26.7399322941665,
              58.3792664834398
            ]
          ]
        ]
      }
    ],
    [
      2364,
      'Tartu Kunstimuuseum',
      {
        'type': 'Polygon',
        'coordinates': [
          [
            [
              26.7225064750449,
              58.3751464727593
            ],
            [
              26.7228532676759,
              58.3796319844523
            ],
            [
              26.7143136218256,
              58.3798138783845
            ],
            [
              26.7139679144564,
              58.3753283435938
            ],
            [
              26.7225064750449,
              58.3751464727593
            ]
          ]
        ]
      }]
  ];


  private mockActivities: ActivityDaily[] = [];

  private mockVisits: VisitDaily[] = [];

  private areas: MapsGridArea[] = [];

  constructor() {
    super();
    this.setupData();
  }

  public setupData(): void {

    for (let i = 0; i < this.areasData.length; i++){
      let item: any[] = this.areasData[i];

      this.areas.push(new MapsGridArea({
        areaCode: item[0],
        name: item[1],
        geojson: item[2]
      }));
    }


    let tartuKesklinn = this.areas[1];

    let activitiesByBatchDateAndDurationGroupType: {[key: string]: ActivityDaily} = {};

    for (let i = 0; i < this.activities.length; i++) {
      let item = this.activities[i];

      let dateStr = item[2];

      let groupIdx = dateStr + '-' + item[4];

      this.mockActivities.push(new ActivityDaily({
        areaCode: tartuKesklinn.areaCode,
        gridName: item[0],
        activityType: item[1],
        batchDate: new Date(dateStr),
        people: item[3],
        durationGroupType: item[4]
      }));

      if (!activitiesByBatchDateAndDurationGroupType[groupIdx]) {
        activitiesByBatchDateAndDurationGroupType[groupIdx] = new ActivityDaily({
          areaCode: tartuKesklinn.areaCode,
          gridName: item[0],
          activityType: 'TOTAL',
          batchDate: new Date(dateStr),
          people: 0,
          durationGroupType: item[4]
        });
      }
      activitiesByBatchDateAndDurationGroupType[groupIdx].people += item[3];
    }

    for (let k in activitiesByBatchDateAndDurationGroupType) {
      this.mockActivities.push(activitiesByBatchDateAndDurationGroupType[k]);
    }

    // currently group by admin level 1, in the future more precise
    let visitsGrouped: {[key: string]: VisitDaily} = {};
    for (let i = 0; i < this.visits.length; i++) {
      let item = this.visits[i];
      let aohAreaCode = item[0];
      let dateStr = item[3];
      let people = item[4];
      let countyCode = item[6];

      let key = aohAreaCode + '-' + countyCode + '-' + dateStr;

      if (!visitsGrouped[key]) {
        visitsGrouped[key] = new VisitDaily({
          aohAreaCode: +aohAreaCode,
          aohGridName: item[1],
          activityType: item[2],
          batchDate: new Date(dateStr),
          people: 0,
          adminLevel1: item[5],
          adminLevel1Code: item[6],
          areaCode: +item[11],
          gridName: item[12],
        });
      }

      visitsGrouped[key].people += people;
    }

    for (let k in visitsGrouped) {
      this.mockVisits.push(visitsGrouped[k]);
    }

  }

  getDailyActivities(filter: ActivityDailyFilter): Observable<ActivityDaily[]> {
    let filtered: ActivityDaily[] = this.mockActivities.filter(a => {
      let match = true;

      if (filter) {
        if (filter.activityType) {
          match = match && (a.activityType === filter.activityType);
        }

        if (filter.durationGroupType) {
          match = match && (a.durationGroupType === filter.durationGroupType);
        }

        if (filter.from) {
          match = match && filter.from.getTime() <= a.batchDate.getTime();
        }

        if (filter.to) {
          match = match && filter.to.getTime() > a.batchDate.getTime();
        }
      }


    //  return a.areaCode === filter.gridArea.areaCode && match;
      return match;
    });

    return of(filtered);
  }

  getMapsGridAreas(filter?: any): Observable<MapsGridArea[]> {

    return of(this.areas);
  }

  getDailyVisits(filter: VisitDailyFilter): Observable<VisitDaily[]> {
    let filtered: VisitDaily[] = this.mockVisits.filter(visit => {
      let match = true;

      if (filter) {
        if (filter.activityType) {
          match = match && (visit.activityType === filter.activityType);
        }

        if (filter.batchDate) {
          match = match && filter.batchDate.getTime() === visit.batchDate.getTime();
        }
      }

    //  return visit.aohAreaCode === filter.aohAreaCode && match;

      return match;
    });

    return of(filtered);
  }

  getMunicipalityAreas(): Observable<MunicipalityArea[]> {
    return undefined;
  }

}
