<div class="dashboard-item dashboard-item-other">
  <div class="widget-titlebar">
    <span class="drag-handler"></span>
    <div class="gridster-item-content widget-menu">
      <span class="close" (click)="close()">
        <i class="fal fa-times"></i>
        <span i18n>Close</span>
      </span>
    </div>
    <div class="row">
      <div class="col-auto pr-0">
        <span class="icon-dashboard questionnaires"></span>
      </div>
      <div class="col">
        <h2 class="text-truncate" i18n>Energy manager questionnaires</h2>
        <p class="small-text" i18n>Guidelines for optimising energy consumption.</p>
      </div>
    </div>
  </div>
  <div class="gridster-item-content content">
    <ul class="list-unstyled mb-0">
      <li class="d-flex" *ngFor="let row of rows">
        <div class="flex-grow-1 col">
          <h5>{{row.questionnaire.name}}</h5>
          <span class="help-text">{{row.questionnaire.person.displayName}}, <ng-template i18n>Received at</ng-template> {{row?.createdAt | date:'dd. MMMM yyyy'}}</span>
        </div>
        <span class="col-auto m-auto">
          <button class="btn btn-secondary btn-sm mr-1" *ngIf="row.widgetTemplates && row.widgetTemplates.length > 0 && row.status == 'COMPLETED'" (click)="addWidget(row)"><span i18n>Add questionnaire graph</span></button>
          <button class="btn btn-primary btn-sm" (click)="answer(row)">
            <span *ngIf="row.status === 'IN_PROGRESS'" i18n>Respond</span>
            <span *ngIf="row.status !== 'IN_PROGRESS'" i18n>View</span>
          </button>
        </span>
      </li>
    </ul>
  </div>
</div>
