import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SMARTENCITY_MYDATA_CONFIG} from '../injection-tokens';
import {MyDataConfig} from '../mydata-config.model';
import {Observable} from 'rxjs/internal/Observable';

export class Feedback {
  message: string;
  pageUrl: string;
  cityPortalPerson?: { countryCode: string, registrationNumber: string }
}

@Injectable()
export class SupportService {

  constructor(
    @Inject(SMARTENCITY_MYDATA_CONFIG) private config: MyDataConfig,
    private http: HttpClient
  ) { }

  public sendFeedback(feedback: Feedback): Observable<void> {

    return this.http.post<void>(this.config.apiUrl + '/support/feedback', feedback);
  }

}
