import { Injectable } from '@angular/core';
import {PeopleTrafficDataService} from './people-traffic-data.service';
import {PeopleTrafficCounter} from '../model/people-traffic-counter';
import {Observable, of} from 'rxjs';
import {ListResponse} from '@smartencity/core';
import moment from 'moment';

export class MockMeasurementResponse {
  measurement: MockMeasurement;
}

export class MockMeasurement {
  seriesName = 'series';
  series: MockMeasurementSeries;
}

export class MockMeasurementSeries {
  series: any[];
  values: any;
  truncated = false;
}

@Injectable()
export class PeopleTrafficMockDataService extends PeopleTrafficDataService {

  private static MIN_VALUE = 0.0;
  private static MAX_VALUE = 1000.0;

  constructor() {
    super();
  }

  loadSeries(counter: PeopleTrafficCounter, params?: any): Observable<any> {

    return of(this.getRandomDataResponse(params));
  }

  getPeopleTraffic(): Observable<ListResponse<PeopleTrafficCounter>> {

    return of(this.mockPeopleTrafficResponse);
  }

  private getRandomDataResponse(params?: any): MockMeasurementResponse {
    let dateFrom = params.dateFrom;
    let dateTo = params.dateTo;


    let response = new MockMeasurementResponse();
    let measurement = new MockMeasurement();
    response.measurement = measurement;

    let measurementSeries = new MockMeasurementSeries();
    measurementSeries.series = [
      {
        'unit': '',
        'name': 'series_max',
        'type': 'dal_hourly'
      },
      {
        'unit': '',
        'name': 'series_count',
        'type': 'dal_hourly'
      },
      {
        'unit': '',
        'name': 'series',
        'type': 'dal_hourly'
      },
      {
        'unit': '',
        'name': 'series_min',
        'type': 'dal_hourly'
      }
    ];

    let now = moment();
    let runningDate = moment(now).subtract(12, 'hour').startOf('hour');
    let endDate = moment(now).subtract(1, 'hours').startOf('hour');

    let values = { };

    while (!runningDate.isAfter(endDate)) {
      let randomNumber = this.generateRandomInt(PeopleTrafficMockDataService.MIN_VALUE, PeopleTrafficMockDataService.MAX_VALUE);

      values[runningDate.toISOString()] = [{
        min: randomNumber,
        max: randomNumber
      }, {
        min: 1,
        max: 1
      }, {
        min: randomNumber,
        max: randomNumber
      }, {
        min: randomNumber,
        max: randomNumber
      }];

      runningDate = runningDate.add(1, 'hour');
    }

    measurementSeries.values = values;

    measurement.series = measurementSeries;

    return response;
  }

  private generateRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min);
  }

  public mockPeopleTrafficResponse: ListResponse<PeopleTrafficCounter> = {
    "content": [
      {
        "id": 1492,
        "location": "[0] Harju 13",
        "locationType": "COORDS",
        "address": null,
        "apartment": null,
        "room": null,
        "uuid": null,
        "lat": 59.43466,
        "lng": 24.743447,
        "personSeriesId": 73893,
        "name": "[0] Harju 13",
        "description": null,
        "seriesType": "OTHER",
        "unit": null,
        "value": null,
        "data": null,
        "createdAt": null,
        "updatedAt": null,
        "showOnCityPortal": true,
        "cityPortalType": "PEOPLE",
        "readableAddress": null,
        "aggregationGroupingType": null,
        "differentiate": null
      },
      {
        "id": 1490,
        "location": "[0] Nunne/Suur-Kloostri",
        "locationType": "COORDS",
        "address": null,
        "apartment": null,
        "room": null,
        "uuid": null,
        "lat": 59.439343,
        "lng": 24.740139,
        "personSeriesId": 73898,
        "name": "[0] Nunne/Suur-Kloostri",
        "description": null,
        "seriesType": "OTHER",
        "unit": null,
        "value": null,
        "data": null,
        "createdAt": null,
        "updatedAt": null,
        "showOnCityPortal": true,
        "cityPortalType": "PEOPLE",
        "readableAddress": null,
        "aggregationGroupingType": null,
        "differentiate": null
      },
      {
        "id": 1495,
        "location": "[0] Pikk 72 - Suur Rannavärav",
        "locationType": "COORDS",
        "address": null,
        "apartment": null,
        "room": null,
        "uuid": null,
        "lat": 59.442445,
        "lng": 24.749192,
        "personSeriesId": 73891,
        "name": "[0] Pikk 72 - Suur Rannavärav",
        "description": null,
        "seriesType": "OTHER",
        "unit": null,
        "value": null,
        "data": null,
        "createdAt": null,
        "updatedAt": null,
        "showOnCityPortal": true,
        "cityPortalType": "PEOPLE",
        "readableAddress": null,
        "aggregationGroupingType": null,
        "differentiate": null
      },
      {
        "id": 1496,
        "location": "[0] Suur-Karja 18",
        "locationType": "COORDS",
        "address": null,
        "apartment": null,
        "room": null,
        "uuid": null,
        "lat": 59.434809,
        "lng": 24.746682,
        "personSeriesId": 73907,
        "name": "[0] Suur-Karja 18",
        "description": null,
        "seriesType": "OTHER",
        "unit": null,
        "value": null,
        "data": null,
        "createdAt": null,
        "updatedAt": null,
        "showOnCityPortal": true,
        "cityPortalType": "PEOPLE",
        "readableAddress": null,
        "aggregationGroupingType": null,
        "differentiate": null
      },
      {
        "id": 1491,
        "location": "[0] Suur-Karja 20-22",
        "locationType": "COORDS",
        "address": null,
        "apartment": null,
        "room": null,
        "uuid": null,
        "lat": 59.43496,
        "lng": 24.747095,
        "personSeriesId": 73895,
        "name": "[0] Suur-Karja 20-22",
        "description": null,
        "seriesType": "OTHER",
        "unit": null,
        "value": null,
        "data": null,
        "createdAt": null,
        "updatedAt": null,
        "showOnCityPortal": true,
        "cityPortalType": "PEOPLE",
        "readableAddress": null,
        "aggregationGroupingType": null,
        "differentiate": null
      },
      {
        "id": 1500,
        "location": "[0] Suurtüki/Laboratooriumi",
        "locationType": "COORDS",
        "address": null,
        "apartment": null,
        "room": null,
        "uuid": null,
        "lat": 59.441108,
        "lng": 24.745174,
        "personSeriesId": 73902,
        "name": "[0] Suurtüki/Laboratooriumi",
        "description": null,
        "seriesType": "OTHER",
        "unit": null,
        "value": null,
        "data": null,
        "createdAt": null,
        "updatedAt": null,
        "showOnCityPortal": true,
        "cityPortalType": "PEOPLE",
        "readableAddress": null,
        "aggregationGroupingType": null,
        "differentiate": null
      },
      {
        "id": 1498,
        "location": "[0] Toompea/Falgi tee/Komandandi tee",
        "locationType": "COORDS",
        "address": null,
        "apartment": null,
        "room": null,
        "uuid": null,
        "lat": 59.435356,
        "lng": 24.738764,
        "personSeriesId": 73896,
        "name": "[0] Toompea/Falgi tee/Komandandi tee",
        "description": null,
        "seriesType": "OTHER",
        "unit": null,
        "value": null,
        "data": null,
        "createdAt": null,
        "updatedAt": null,
        "showOnCityPortal": true,
        "cityPortalType": "PEOPLE",
        "readableAddress": null,
        "aggregationGroupingType": null,
        "differentiate": null
      },
      {
        "id": 1502,
        "location": "[0] Uus - Väike Rannavärav",
        "locationType": "COORDS",
        "address": null,
        "apartment": null,
        "room": null,
        "uuid": null,
        "lat": 59.441466,
        "lng": 24.749782,
        "personSeriesId": 73899,
        "name": "[0] Uus - Väike Rannavärav",
        "description": null,
        "seriesType": "OTHER",
        "unit": null,
        "value": null,
        "data": null,
        "createdAt": null,
        "updatedAt": null,
        "showOnCityPortal": true,
        "cityPortalType": "PEOPLE",
        "readableAddress": null,
        "aggregationGroupingType": null,
        "differentiate": null
      },
      {
        "id": 1487,
        "location": "[0] Väike-Karja 12",
        "locationType": "COORDS",
        "address": null,
        "apartment": null,
        "room": null,
        "uuid": null,
        "lat": 59.435508,
        "lng": 24.747755,
        "personSeriesId": 73901,
        "name": "[0] Väike-Karja 12",
        "description": null,
        "seriesType": "OTHER",
        "unit": null,
        "value": null,
        "data": null,
        "createdAt": null,
        "updatedAt": null,
        "showOnCityPortal": true,
        "cityPortalType": "PEOPLE",
        "readableAddress": null,
        "aggregationGroupingType": null,
        "differentiate": null
      },
      {
        "id": 1489,
        "location": "[0] Valli 4",
        "locationType": "COORDS",
        "address": null,
        "apartment": null,
        "room": null,
        "uuid": null,
        "lat": 59.435907,
        "lng": 24.749589,
        "personSeriesId": 73897,
        "name": "[0] Valli 4",
        "description": null,
        "seriesType": "OTHER",
        "unit": null,
        "value": null,
        "data": null,
        "createdAt": null,
        "updatedAt": null,
        "showOnCityPortal": true,
        "cityPortalType": "PEOPLE",
        "readableAddress": null,
        "aggregationGroupingType": null,
        "differentiate": null
      },
      {
        "id": 1485,
        "location": "[0] Vana-Viru 12",
        "locationType": "COORDS",
        "address": null,
        "apartment": null,
        "room": null,
        "uuid": null,
        "lat": 59.437354,
        "lng": 24.751621,
        "personSeriesId": 73894,
        "name": "[0] Vana-Viru 12",
        "description": null,
        "seriesType": "OTHER",
        "unit": null,
        "value": null,
        "data": null,
        "createdAt": null,
        "updatedAt": null,
        "showOnCityPortal": true,
        "cityPortalType": "PEOPLE",
        "readableAddress": null,
        "aggregationGroupingType": null,
        "differentiate": null
      },
      {
        "id": 1499,
        "location": "[0] Viru tänav 27",
        "locationType": "COORDS",
        "address": null,
        "apartment": null,
        "room": null,
        "uuid": null,
        "lat": 59.436582,
        "lng": 24.751844,
        "personSeriesId": 73903,
        "name": "[0] Viru tänav 27",
        "description": null,
        "seriesType": "OTHER",
        "unit": null,
        "value": null,
        "data": null,
        "createdAt": null,
        "updatedAt": null,
        "showOnCityPortal": true,
        "cityPortalType": "PEOPLE",
        "readableAddress": null,
        "aggregationGroupingType": null,
        "differentiate": null
      },
      {
        "id": 1497,
        "location": "[1] Nunne/Suur-Kloostri",
        "locationType": "COORDS",
        "address": null,
        "apartment": null,
        "room": null,
        "uuid": null,
        "lat": 59.439451,
        "lng": 24.740325,
        "personSeriesId": 73905,
        "name": "[1] Nunne/Suur-Kloostri",
        "description": null,
        "seriesType": "OTHER",
        "unit": null,
        "value": null,
        "data": null,
        "createdAt": null,
        "updatedAt": null,
        "showOnCityPortal": true,
        "cityPortalType": "PEOPLE",
        "readableAddress": null,
        "aggregationGroupingType": null,
        "differentiate": null
      },
      {
        "id": 1488,
        "location": "[1] Suur-Karja 18",
        "locationType": "COORDS",
        "address": null,
        "apartment": null,
        "room": null,
        "uuid": null,
        "lat": 59.435017,
        "lng": 24.746421,
        "personSeriesId": 73906,
        "name": "[1] Suur-Karja 18",
        "description": null,
        "seriesType": "OTHER",
        "unit": null,
        "value": null,
        "data": null,
        "createdAt": null,
        "updatedAt": null,
        "showOnCityPortal": true,
        "cityPortalType": "PEOPLE",
        "readableAddress": null,
        "aggregationGroupingType": null,
        "differentiate": null
      },
      {
        "id": 1494,
        "location": "[1] Suur-Karja 20-22",
        "locationType": "COORDS",
        "address": null,
        "apartment": null,
        "room": null,
        "uuid": null,
        "lat": 59.434837,
        "lng": 24.747079,
        "personSeriesId": 73890,
        "name": "[1] Suur-Karja 20-22",
        "description": null,
        "seriesType": "OTHER",
        "unit": null,
        "value": null,
        "data": null,
        "createdAt": null,
        "updatedAt": null,
        "showOnCityPortal": true,
        "cityPortalType": "PEOPLE",
        "readableAddress": null,
        "aggregationGroupingType": null,
        "differentiate": null
      },
      {
        "id": 1486,
        "location": "[1] Toompea/Falgi tee/Komandandi tee",
        "locationType": "COORDS",
        "address": null,
        "apartment": null,
        "room": null,
        "uuid": null,
        "lat": 59.43532,
        "lng": 24.738927,
        "personSeriesId": 73900,
        "name": "[1] Toompea/Falgi tee/Komandandi tee",
        "description": null,
        "seriesType": "OTHER",
        "unit": null,
        "value": null,
        "data": null,
        "createdAt": null,
        "updatedAt": null,
        "showOnCityPortal": true,
        "cityPortalType": "PEOPLE",
        "readableAddress": null,
        "aggregationGroupingType": null,
        "differentiate": null
      },
      {
        "id": 1493,
        "location": "[1] Vana-Viru 12",
        "locationType": "COORDS",
        "address": null,
        "apartment": null,
        "room": null,
        "uuid": null,
        "lat": 59.437415,
        "lng": 24.751511,
        "personSeriesId": 73892,
        "name": "[1] Vana-Viru 12",
        "description": null,
        "seriesType": "OTHER",
        "unit": null,
        "value": null,
        "data": null,
        "createdAt": null,
        "updatedAt": null,
        "showOnCityPortal": true,
        "cityPortalType": "PEOPLE",
        "readableAddress": null,
        "aggregationGroupingType": null,
        "differentiate": null
      },
      {
        "id": 1501,
        "location": "[1] Viru tänav 27",
        "locationType": "COORDS",
        "address": null,
        "apartment": null,
        "room": null,
        "uuid": null,
        "lat": 59.436727,
        "lng": 24.751733,
        "personSeriesId": 73904,
        "name": "[1] Viru tänav 27",
        "description": null,
        "seriesType": "OTHER",
        "unit": null,
        "value": null,
        "data": null,
        "createdAt": null,
        "updatedAt": null,
        "showOnCityPortal": true,
        "cityPortalType": "PEOPLE",
        "readableAddress": null,
        "aggregationGroupingType": null,
        "differentiate": null
      }
    ],
    page: 0,
    size: 2000,
    totalElements: 18,
    totalPages: 1
  }
}
