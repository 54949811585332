import {Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef} from '@angular/core';
import {DashboardService} from './dashboard.service';
import {Subject} from 'rxjs/internal/Subject';
import {NavbarService} from '../navbar/navbar.service';
import {ReportFileExtension} from './dashboard-report-types';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'mydata-dashboard-download',
  templateUrl: './dashboard-download.component.html',
})
export class DashboardDownloadComponent implements OnInit, OnDestroy {
  private ngDestroy = new Subject<void>();

  @ViewChild('noRootTemplate', {static: true})
  noRootTemplate: TemplateRef<any>;

  public downloadInProgress = false;

  private downloadInProgressToastrRef: any;

  constructor(private vcRef: ViewContainerRef,
              public dashboardService: DashboardService,
              public navbarService: NavbarService,
              private toastr: ToastrService) {
  }

  ngOnInit(): void {
    this.vcRef.createEmbeddedView(this.noRootTemplate);
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  public downloadPdf(): void {
    this.startDownloading();
    this.dashboardService.downloadPdf().subscribe(() => {
      this.stopDownloading();
    }, (error) => {
      this.stopDownloading(error);
    });
  }


  public downloadDashboard(extension: ReportFileExtension): void {
    this.startDownloading();
    this.dashboardService.downloadDashboard(extension).subscribe(() => {
      this.stopDownloading();
    }, (error) => {
      this.stopDownloading(error);
    });
  }

  private startDownloading(): void {
    this.downloadInProgressToastrRef = this.toastr.info($localize`Downloading...`, null, {
      disableTimeOut: true
    });

    this.downloadInProgress = true;
  }

  private stopDownloading(error?: any): void {
    if (!this.downloadInProgressToastrRef) {
      return;
    }

    this.toastr.clear(this.downloadInProgressToastrRef.toastId);
    if (error) {
      this.toastr.error($localize`Something went wrong`);
      console.log(error);
    }
    this.downloadInProgress = false;
  }

}
