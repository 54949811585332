import {Component, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {IWidgetDataset, WidgetTemplate, WidgetTemplateDataset, WidgetType} from '../widget';
import {DashboardFilter} from '../dashboard';
import {ConfirmModalComponent, Dataset, QuestionnaireDetail, WidgetDataService} from '@smartencity/core';
import {HttpClient} from '@angular/common/http';
import {SMARTENCITY_MYDATA_CONFIG} from '../../../injection-tokens';
import {MyDataConfig} from '../../../mydata-config.model';
import {ToastrService} from 'ngx-toastr';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {WidgetService} from '../widget.service';
import {moveItemInArray} from '@angular/cdk/drag-drop';
import {WidgetRandomDataService} from '../widget-random-data.service';
import {AbstractWidgetModal} from '../abstract-widget-modal';
import {Observable} from 'rxjs/internal/Observable';
import {WidgetTemplateApiService} from '../../../http/widget-template-api.service';
import { DashboardService } from '../dashboard.service';
import { WidgetApiService } from '../../../http/widget-api.service';
import { DashboardApiService } from '../../../http/dashboard-api.service';

const selector = 'mydata-widget-template-modal';
let nextId = 0;
@Component({
  selector: 'widget-template-modal',
  templateUrl: './widget-template-modal.component.html',
  providers: [
    { provide: WidgetDataService, useClass: WidgetRandomDataService },
  ]
})
export class WidgetTemplateModalComponent extends AbstractWidgetModal implements OnInit, OnDestroy {
  id = `${selector}-${nextId++}`;

  public widgetTypeType = WidgetType;

  @Input()
  public widgets: WidgetTemplate[];

  public widget: WidgetTemplate;

  @Input()
  public dashboardFilter: DashboardFilter;

  @Input()
  public template = false;

  @Input()
  public q11e: QuestionnaireDetail;

  public onHide: (widget: WidgetTemplate) => void;

  public datasetTypeOptions: any[] = [];

  public datasetsInitialLoad = true;

  constructor(
    private http: HttpClient,
    @Inject(SMARTENCITY_MYDATA_CONFIG) private config: MyDataConfig,
    protected toastr: ToastrService,
    private modalRef: BsModalRef,
    public widgetService: WidgetService,
    protected widgetTemplateApi: WidgetTemplateApiService,
    private modalService: BsModalService,
    protected widgetApi: WidgetApiService,
    public dashboardService: DashboardService,
    protected dashboardApiService: DashboardApiService,
  ) {
    super(widgetService, toastr, widgetApi, dashboardApiService);
  }

  ngOnInit(): void {
    this.datasetTypeOptions = this.widgetService.datasetTypeOptions.filter(datasetType => {
      return datasetType.value === 'PERSON_SERIES' || datasetType.value === 'Q11E_FIELD';
    });

    if (this.widgets && this.widgets.length > 0) {
      this.widget = this.widgets[0];
    } else {
      this.widget = new WidgetTemplate();
      this.widgets.push(this.widget);
    }

    this.buildForm(true);
  }

  addData() {
    this.mode = 'add-datasets';
  }

  editData() {
    this.mode = 'edit-datasets';
  }

  addCurrentPageDatasets(): void {
    if (!this.page) {
      return;
    }

    for (const dataset of this.page.content) {
      this.addDataSet(dataset);
    }
  }

  createWidgetDataset(dataset: Dataset): IWidgetDataset {
    const wd = new WidgetTemplateDataset(dataset);
    wd.groupName = 'default';
    wd.sortOrder = this.widget.datasets.length + 1;
    wd.personParameterId = dataset.personParameterId;
    wd.personSeriesId = dataset.personSeriesId;
    wd.q11eResponseFieldId = dataset.q11eResponseFieldId;
    wd.q11eFieldId = dataset.q11eFieldId;
    wd.templateField = dataset.q11eId === this.q11e.id && dataset.type === 'Q11E_FIELD';

    return wd;
  }

  createWidget(widget: any): Observable<any> {
    return this.widgetTemplateApi.createWidgetTemplate(this.q11e.id, widget);
  }

  saveWidget(widget: any): Observable<any> {
    return this.widgetTemplateApi.updateWidgetTemplate(widget.id, widget);
  }

  public close(): void {
    this.modalRef.hide();
    this.onHide(this.widget);
  }

  public confirmAndSave() {
    let containsPersonSeries = false;

    for (const row of this.rows) {
      if (row.dataset.type === 'PERSON_SERIES') {
        containsPersonSeries = true;
        break;
      }
    }

    if (!containsPersonSeries) {
      this.save();
      return;
    }

    const modalRef = this.modalService.show(ConfirmModalComponent, {
      ignoreBackdropClick: true,
      initialState: {
        description: $localize`Are you sure you want to share your data with participants on a widget?`,
        callback: (confirm: boolean) => {
          if (confirm) {
            this.save();
          }
        }
      }
    });

  }

  drop(event) {
    moveItemInArray(this.rows, event.previousIndex, event.currentIndex);
    moveItemInArray(this.widgetDatasetArray.controls, event.previousIndex, event.currentIndex);
  }

  mapFormValueToWidget(value: any): any {
    return {
      name: value.name,
      type: value.type,
      dashboard: {id: value.dashboardId},
      component: null,
      chartType: value.chartType,
      showLegend: value.showLegend,
      showYAxes: value.showYAxes,
      showDataLabels: value.showDataLabels,
      showAverageLine: value.showAverageLine,
      aggregationGroupingType: value.aggregationGroupingType,
      chartColorScheme: value.chartColorScheme,
      periodType: value.periodType,
      periodCount: value.periodCount,
      differentiate: value.differentiate,
      timeframeType: value.timeframeType,
      periodStartAt: value.periodStartAt,
      periodEndAt: value.periodEndAt
    };
  }

  isCurrentQuestionnaire(ds: Dataset): boolean {
    return ds.q11eId === this.q11e.id && ds.type === 'Q11E_FIELD';
  }

  public hasPendingWidgets(): boolean {
    const pendingWidgets = this.widgets.filter(widget => {
      return !widget.id;
    });

    return pendingWidgets.length > 0;
  }

  addNew(): void {
    if (this.hasPendingWidgets()) {
      return;
    }

    this.form.updateValueAndValidity();
    this.validateBeforeContinue().subscribe(() => {
      this.widget = new WidgetTemplate();
      this.buildForm(true);
      this.widgets.push(this.widget);

      this.resetFormState();
    }, () => {
    });
  }

  selectWidget(w: WidgetTemplate): void {
    if (w.id === this.widget.id) {
      return;
    }

    this.datasetsInitialLoad = true;
    this.form.updateValueAndValidity();
    this.validateBeforeContinue().subscribe(() => {
      this.widget = w;
      this.buildForm(true);
      this.resetFormState();
    }, () => {
    });

  }

  validateBeforeContinue(): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      if (this.form.dirty) {
        const modalRef = this.modalService.show(ConfirmModalComponent, {
          ignoreBackdropClick: true,
          initialState: {
            description: $localize`This form has unsaved changes. Do you wish to proceed?`,
            callback: (confirm: boolean) => {
              if (confirm) {
                observer.next(true);
              } else {
                observer.error();
              }
            }
          }
        });
      } else {
        observer.next(true);
      }
    });
  }

  filterSelectedDatasetTypes(selected: string[]): string[] {
    if (!selected || selected.length == 0) {
      return ['PERSON_SERIES', 'Q11E_FIELD'];
    }
    return selected;
  }

  preparePageFilter(filter: any): void {
    if (!filter.datasetTypes || filter.datasetTypes.length == 0) {
      filter.datasetTypes = ['PERSON_SERIES', 'Q11E_FIELD'];
    }
    filter.q11eId = this.q11e.id;
  }

  afterUpdateSelectedSeriesIdSet(): void {
    if (!this.datasetsInitialLoad) {
      this.form.markAsDirty();
    }

    if (this.datasetsInitialLoad) {
      this.datasetsInitialLoad = false;
    }
  }

  isPreventClose(): boolean {
    return super.isPreventClose() || this.hasPendingWidgets();
  }

  // private getSearch() {
  //   const filter: any = {
  //     page: (this.pageFilter.page - 1) + '',
  //     size: this.pageFilter.size + '',
  //     query: this.pageFilter.query,
  //     q11eId: this.q11e.id,
  //     datasetTypes: ['PERSON_SERIES', 'Q11E_FIELD']
  //   };
  //
  //   return this.http.get(this.config.apiUrl + '/widget/dataset', {params: filter}).pipe(takeUntil(this.ngDestroy));
  // }

}
