import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {
  ConfirmModalComponent,
  ConsentService,
  CoreConfig,
  Person, PersonRegistrationNumberValidator,
  SMARTENCITY_CORE_CONFIG,
  UserService
} from '@smartencity/core';
import {Subject} from 'rxjs/internal/Subject';
import { debounceTime, distinctUntilChanged, map, switchMap, takeUntil} from 'rxjs/operators';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {ToastrService} from 'ngx-toastr';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import moment from 'moment';
import { DashboardService } from '../../dashboard.service';
import { DashboardApiService } from 'projects/mydata/src/lib/http/dashboard-api.service';

@Component({
  selector: 'dashboard-consent-modal',
  templateUrl: './dashboard-consent-modal.component.html'
})
export class DashBoardConsentModal implements OnInit {
  private ngDestroy = new Subject<void>();

  dashboardId: number;
  progress = false;

  @Output('saved')
  savedEmitter: EventEmitter<any> = new EventEmitter<any>();

  personTypeahead$ = new Subject<string>();
  personItems$ = this.personTypeahead$.pipe(
    takeUntil(this.ngDestroy),
    distinctUntilChanged(),
    debounceTime(250),
    switchMap((term: string) => this.userService.searchPersons(term)),
    map((persons: Person[]) => {
      return persons.map((e) => ({
        id: e.id,
        countryCode: e.countryCode,
        registrationNumber: e.registrationNumber,
        displayName: e.displayName,
        juridical: e.juridical,
        idPrivacyType: e.idPrivacyType,
        dob: e.dob
      }));
    })
  );

  public showValidation = false;

  public consentForm: FormGroup;

  public errors: string[] = [];

  public dashboardConsents: any[] = [];

  public createPerson = (registrationNumber: string) => {
    return {
      id: null,
      countryCode: this.config.defaultCountryCode,
      registrationNumber: registrationNumber,
      displayName: null
    };
  };

  constructor(
    @Inject(SMARTENCITY_CORE_CONFIG) private config: CoreConfig,
    public modalRef: BsModalRef,
    private modalService: BsModalService,
    public toastr: ToastrService,
    public consentService: ConsentService,
    public personRegistrationNumberValidator: PersonRegistrationNumberValidator,
    private userService: UserService,
    private dashboardService: DashboardService,
    private dashboardApiService: DashboardApiService
    ) {
  }

  ngOnInit(): void {
    this.getConsents();

    this.consentForm = new FormGroup({
      person: new FormControl(null, [Validators.required, this.personRegistrationNumberValidator.check.bind(this.personRegistrationNumberValidator)]),
      customStartAt: new FormControl(moment().startOf('day').toDate(), [Validators.required])
    });

    this.personTypeahead$.pipe(takeUntil(this.ngDestroy)).subscribe((value) => {
      if (value) {
        const person = this.createPerson(value);
        this.consentForm.get('person').setValue(person);
      }
    });
  }

  getConsents(): void {
    this.dashboardApiService.getConsents(this.dashboardId).subscribe((consentData) => {
      this.dashboardConsents = consentData;
    });
  }

  createConsents(): void {
    this.consentForm.markAsDirty();
    if (!this.consentForm.valid) {
      console.error('Form is invalid', this.consentForm);
      this.showValidation = true;
      return;
    }

    const consentData = {
      countryCode: this.consentForm.value.person.countryCode ? this.consentForm.value.person.countryCode.trim() : this.config.defaultCountryCode,
      personCode: this.consentForm.value.person.registrationNumber ? this.consentForm.value.person.registrationNumber.trim() : null
    };

    if (consentData) {

      this.dashboardApiService.addConsent(this.dashboardId, consentData).subscribe(
        (data: any) => {
          this.toastr.success($localize`Saved`);
          this.getConsents();
          this.savedEmitter.emit('changed');
        },
        (err: any) => {
          this.toastr.error($localize`Adding dashboard consent failed`);
        }
      );
    }
  }

  endConsent(shareId: number): void {
    if (shareId) {
      const modalRef = this.modalService.show(ConfirmModalComponent, {
        ignoreBackdropClick: true,
        class: 'modal-sm',
        initialState: {
          description: $localize`Are you sure you want to remove the dashboard consent?`,
          callback: (confirm: boolean) => {
            if (confirm) {
              this.dashboardApiService.endConsent(this.dashboardId, shareId).subscribe(
                (data: any) => {
                  this.toastr.success($localize`Dashboard consent removed`);
                  this.getConsents();
                  this.savedEmitter.emit('changed');
                },
                (err: any) => {
                  this.toastr.error($localize`Removing dashboard consent failed`);
                }
              );
            }
          }
      }});
    }
  }

  close(): void {
    this.modalRef.hide();
  }
}
