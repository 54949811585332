import {Inject, Injectable, LOCALE_ID} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {
  LatestValue,
  PendingPersonSeriesCancellation,
  PendingSeries,
  PendingSeriesMeta,
  PersonSeries,
  PersonSeriesHistoricalResponse,
  PersonSeriesMeta, PersonSeriesPeriod, SeriesHistory, SeriesNumberFormat, SeriesTargetError
} from '../models/series';
import {Tenant} from '../models/tenant';
import {SMARTENCITY_CORE_CONFIG} from '../injection-tokens';
import {CoreConfig} from '../core-config.model';
import {DatePipe, formatDate} from '@angular/common';
import {catchError, map} from 'rxjs/operators';
import {PageResponse} from '../models/page-response';
import {Person} from '../models/person';
import { ListResponse } from '../models/list-response';

type PageResponseType = PageResponse<PersonSeries>;

export interface PersonSeriesUpdate {
  name: string;
  description: string;
  groupingTags: string[];
  groups: any[];
  seriesType: string;
  locationType: string;
  adsOid: string;
  address: string;
  apartment: string;
  room: string;
  uuid: string;
  lat: number;
  lng: number;
  showOnCityPortal: boolean;
  cityPortalType: string;
  readableAddress: string;
  numberFormat: SeriesNumberFormat
}

export interface PersonSeriesCoordsBulkUpdate {
  ids: number[];
  lat: number;
  lng: number;
  readableAddress: string;
}

@Injectable()
export class PersonSeriesApiService {

  constructor(
    @Inject(SMARTENCITY_CORE_CONFIG) private config: CoreConfig,
    @Inject(LOCALE_ID) public locale: string,
    private http: HttpClient,
    public datePipe: DatePipe
  ) {
  }

  getPersonSeriesPage(queryParams: any): Observable<PageResponseType> {
    const params = Object.assign({}, queryParams);
    for (const i of Object.keys(params)) {
      if (params[i] == null) {
        delete params[i];
      }
    }

    return this.http.get<PageResponseType>(this.config.apiUrl + '/person-series', {
      params: params
    }).pipe(catchError((err) => {
      return throwError(err);
    }));
  }

  getPersonSeries(id: number): Observable<PersonSeries> {
    return this.http.get<PersonSeries>(this.config.apiUrl + '/person-series/' + id);
  }

  updatePersonSeries(id: number, body: PersonSeriesUpdate): Observable<PersonSeries> {

    return this.http.put<PersonSeries>(this.config.apiUrl + '/person-series/' + id, body);
  }

  updateCoordsBulk(body: PersonSeriesCoordsBulkUpdate): Observable<any> {

    return this.http.put(this.config.apiUrl + '/person-series/coords', body);
  }

  getPersonSeriesLatestValue(id: number, dateFrom?: any, dateTo?: any, aggregationGroupingType?: string): Observable<LatestValue> {
    const params: any = {};
    if (dateFrom) {
      params.dateFrom = dateFrom;
    }
    if (dateTo) {
      params.dateTo = dateTo;
    }
    if (aggregationGroupingType) {
      params.aggregationGroupingType = aggregationGroupingType;
    }
    return this.http.get<LatestValue>(this.config.apiUrl + '/person-series/' + id + '/latest', {
      params: params
    });
  }

  getPersonSeriesHistoricalMeasurements(id: number, dateFrom: any, dateTo: any, aggregationGroupingType: string): Observable<PersonSeriesHistoricalResponse> {
    const params: any = {
      dateFrom: dateFrom,
      dateTo: dateTo
    };
    if (aggregationGroupingType) {
      params.aggregationGroupingType = aggregationGroupingType;
    }

    return this.http.get(this.config.apiUrl + '/person-series/' + id + '/historical/measurement', {
      params: params
    }) as Observable<PersonSeriesHistoricalResponse>;
  }

  getPersonSeriesHistoricalEvents(id: number, dateFrom: any, dateTo: any): Observable<PersonSeriesHistoricalResponse> {
    const params: any = {
      dateFrom: dateFrom,
      dateTo: dateTo
    };
    return this.http.get(this.config.apiUrl + '/person-series/' + id + '/historical/event', {
      params: params
    }) as Observable<PersonSeriesHistoricalResponse>;
  }

  getPersonSeriesLatestEvent(id: number, dateFrom?: any, dateTo?: any): Observable<any> {
    let params: any = {};
    if (dateFrom) {
      params.dateFrom = dateFrom;
    }

    if (dateTo) {
      params.dateTo = dateTo;
    }

    return this.http.get(this.config.apiUrl + '/person-series/' + id + '/latest/event', {
      params: params
    });
  }

  getSeriesConsents(id: number) {
    return this.http.get<PageResponse<any>>(this.config.apiUrl + '/person-series/' + id + '/consent').pipe(map((data) => data.content));
  }

  getOwnerPersonSeriesForConsentPerson(consentPerson: Person): Observable<PageResponse<PersonSeries>> {
    const params: any = {
      personId: consentPerson.id
    }

    return this.http.get<PageResponse<PersonSeries>>(this.config.apiUrl + '/person-series/consent', {
      params: params
    });
  }

  getPendingSeries(params: any): Observable<PageResponse<PendingSeries>> {
    return this.http.get<PageResponse<PendingSeries>>(this.config.apiUrl + '/person-series/pending', {
      params: params
    });
  }

  getPendingSeriesMeta(): Observable<PendingSeriesMeta[]> {
    return this.http.get<ListResponse<PendingSeriesMeta>>(this.config.apiUrl + '/person-series/pending-meta').pipe(map((data) => data.content));
  }

  getPendingSeriesTargetErrors(): Observable<SeriesTargetError[]> {
    return this.http.get<PageResponse<SeriesTargetError>>(this.config.apiUrl + '/series-error').pipe(map((data) => data.content));
  }

  getPendingCancellations(): Observable<PendingPersonSeriesCancellation[]> {
    return this.http.get<ListResponse<PendingPersonSeriesCancellation>>(this.config.apiUrl + '/person-series/pending-person-series-cancel').pipe(map((data) => data.content));
  }

  acknowledgeError(item: SeriesTargetError) {
    const acknowledgeDto = {
      seriesErrorId : item.seriesErrorId,
      seriesTargetId : item.targetId,
      acknowledgeDate: formatDate(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSS", this.locale),
    };
    return this.http.put(this.config.apiUrl + '/series-error/' + item.seriesErrorId , acknowledgeDto);
  }


  updateStatus(personSeries: PersonSeries, status: string, tenant: Tenant) {
    const statusDto = {
      status: status,
      tenantId: tenant ? tenant.id : null
    };

    return this.http.put(this.config.apiUrl + '/person-series/' + personSeries.id + '/status', statusDto);
  }

  updateStatusBulk(personSeries: PersonSeries[], status: string, tenant: Tenant) {
    const statusDto = {
      personSeriesIds: personSeries.map(value => value.id),
      status: status,
      tenantId: tenant ? tenant.id : null
    };

    return this.http.put(this.config.apiUrl + '/person-series/status', statusDto);
  }

  updatePendingStatusBulk(status: string, tenant: Tenant) {
    const statusDto = {
      status: status,
      tenantId: tenant ? tenant.id : null
    };

    return this.http.put(this.config.apiUrl + '/person-series/pending/status', statusDto);
  }

  updateMeta(personSeriesMeta: PersonSeriesMeta, status: string) {
    const statusDto = {
      status: status
    };

    return this.http.put(this.config.apiUrl + '/person-series/meta/' + personSeriesMeta.id, statusDto);
  }

  updateMetaBulk(personSeriesMeta: PersonSeriesMeta[], status: string) {
    const statusDto = {
      personSeriesMetaIds: personSeriesMeta.map(value => value.id),
      status: status,
    };

    return this.http.put(this.config.apiUrl + '/person-series/meta', statusDto);
  }

  updateCancellationStatus(cancellation: PendingPersonSeriesCancellation, status: string) {
    const statusDto = {
      status: status
    };

    return this.http.put(this.config.apiUrl + '/person-series/person-series-cancel/' + cancellation.id + '/status', statusDto);
  }

  updateCancellationStatusBulk(cancellations: PendingPersonSeriesCancellation[], status: string) {
    const statusDto = {
      cancelIds: cancellations.map(value => value.id),
      status: status,
    };

    return this.http.put(this.config.apiUrl + '/person-series/person-series-cancel/status', statusDto);
  }

  public mapToFlatList(data: PersonSeriesHistoricalResponse): object[] {

    let res;
    if (data.measurement && data.measurement.series) {
      res = PersonSeriesApiService.mapMeasurementsHistoryToFlatList(data.measurement.series);
    } else if (data.event && data.event.events) {
      res = PersonSeriesApiService.mapEventsHistoryToFlatList(data.event.events);
    } else {
      return [];
    }

    res.sort(function (a, b) {
      const x = a.time;
      const y = b.time;
      return x < y ? -1 : x > y ? 1 : 0;
    });

    res.forEach(item => {
      item.time = this.datePipe.transform(item.time, 'dd.MM.yyyy HH:mm');
    });
    return res;
  }

  deletePeriods(personSeries: PersonSeries): Observable<void> {
    return this.http.delete<void>(this.config.apiUrl + '/person-series/' + personSeries.id + '/periods');
  }

  acknowledgePeriodCancel(personSeries: PersonSeries, personSeriesPeriod: PersonSeriesPeriod): Observable<void> {
    return this.http.put<void>(this.config.apiUrl + '/person-series/' + personSeries.id + '/periods/' + personSeriesPeriod.id + '/acknowledge', {
      acknowledgedAt: new Date()
    });
  }

  deletePeriod(personSeries: PersonSeries, personSeriesPeriod: PersonSeriesPeriod): Observable<void> {
    return this.http.delete<void>(this.config.apiUrl + '/person-series/' + personSeries.id + '/periods/' + personSeriesPeriod.id);
  }

  private static mapMeasurementsHistoryToFlatList(data: SeriesHistory): object[] {
    let res: any[] = [];
    for (let datetime in data.values) {
      let vals: any[] = data.values[datetime];
      var row: any = {
        time: datetime
      };
      for (let index in data.series) {
        row[data.series[index].name + "_min"] = vals[index].min;
        row[data.series[index].name + "_max"] = vals[index].max;
        row[data.series[index].name + "_type"] = data.series[index].type;
        row[data.series[index].name + "_unit"] = data.series[index].unit;
      }
      res.push(row);
    }

    return res;
  }

  private static mapEventsHistoryToFlatList(data: any[]): object[] {

    let res: any[] = [];
    for (let index in data) {
      let event = data[index];
      var row: any = {
        time: event.time,
        text: event.text,
        type: event.type
      };

      for (let i in event) {
        if (['time', 'text', 'type'].indexOf(i) > -1) {
          continue;
        }
        let eventProperty = event[i];
        if (typeof eventProperty !== 'object') {
          row[i] = eventProperty;
        } else {
          for (let j in eventProperty) {
            let eventSubProperty = eventProperty[j];
            if (typeof eventSubProperty !== 'object') {
              row[i + "_" + j] = eventSubProperty;
            } else {
              row[i + "_" + j] = JSON.stringify(eventSubProperty);
            }
          }
        }
      }
      res.push(row);
    }
    return res;
  }

}
