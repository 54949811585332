import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {AuthService, Mandate, UserService} from '@smartencity/core';
import {BsModalService} from 'ngx-bootstrap/modal';
import {TosModalComponent} from '../../../../../core/src/lib/components/tos-modal/tos-modal.component'
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs/internal/Subject';
import { ScreenSizeConstants } from 'projects/core/src/lib/constants/screen-size-constants';
import { NavbarService } from '../navbar/navbar.service';

const selector = 'mydata-settings';
let nextId = 0;

@Component({
  selector: selector,
  templateUrl: './settings.component.html'
})
export class SettingsComponent implements OnInit, OnDestroy {
  private ngDestroy = new Subject<void>();

  id = `${selector}-${nextId++}`;

  currentMandate: Mandate;

  public isMobile: boolean;

  @ViewChild('navMenu', {static: true})
  public navTemplate: TemplateRef<any>;

  constructor(
    public authService: AuthService,
    public userService: UserService,
    private modalService: BsModalService,
    private navbarService: NavbarService
  ) {
    if (window.innerWidth < ScreenSizeConstants.SMALL_DEVICE_SCREEN_WIDTH_MAX) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }

  ngOnInit() {
    this.authService.currentMandate$.pipe(takeUntil(this.ngDestroy)).subscribe((mandate: Mandate) => {
      this.currentMandate = mandate;
    });
    this.navbarService.setMenuTemplate(this.navTemplate);
  }

  viewTos(): void {
    const modalRef = this.modalService.show(TosModalComponent, {
      class: 'modal-lg'
    });
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
    this.navbarService.setMenuTemplate(null);
  }
}
