import {AirQualityStation} from '../model/air-quality-station';
import {TallinnService} from '../service/tallinn.service';
import {AbstractMapLayerMarker, OlMapWrapper} from '@smartencity/core';
import {AirQualityStationOlMarkerWrapper} from './air-quality-station-ol-marker-wrapper';

export class AirQualityStationOlMarker extends AbstractMapLayerMarker<OlMapWrapper, AirQualityStation>{

  private selected = false;

  private tallinnService: TallinnService;

  private marker: AirQualityStationOlMarkerWrapper = null;

  constructor(airQualityStation: AirQualityStation, map: OlMapWrapper, tallinnService: TallinnService) {
    super(airQualityStation);
    this.tallinnService = tallinnService;
    this.marker = new AirQualityStationOlMarkerWrapper(airQualityStation, map, tallinnService);
  }

  setMap(map: OlMapWrapper) {
    super.setMap(map);
    const olMap = (map ? map.getMap() : null);
    this.marker.setMap(olMap);
  }

  setSelected(selected: boolean): void {
    this.marker.selected = selected;
  }

  draw() {
    this.marker.draw();
  }

  isSelected(): boolean {
    return this.selected;
  }

}
