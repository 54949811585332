import {Inject, Injectable, NgZone} from '@angular/core';
import {MeteorologyService,} from './meteorology.service';
import {DisplaySettings} from '../model/display-settings';
import {SMARTENCITY_METEOROLOGY_CONFIG} from '../injection-tokens';
import {MeteorologyConfig} from '../meteorology-config.model';

@Injectable()
export class MeteorologyGmapService {
  private map;

  displaySettings = new DisplaySettings();

  constructor(
    @Inject(SMARTENCITY_METEOROLOGY_CONFIG) private config: MeteorologyConfig,
    public meteorologyService: MeteorologyService,
    private ngZone: NgZone
  ) { }

  createMap(gmapElement) {
    const mapProp = {
      center: this.config.map.center,
      zoom: this.config.map.zoom,
      mapTypeControl: false,
      fullscreenControl: false,
      clickableIcons: false,
      styles: [
        <google.maps.MapTypeStyle>{
          featureType: 'transit.station.bus',
          stylers: [
            <google.maps.MapTypeStyler>{visibility: 'off'}
          ]
        }
      ]
    };

    this.map = new google.maps.Map(gmapElement.nativeElement, mapProp);

    const style = [
      {
        stylers: [
          {saturation: -80},
        ]
      },
      <google.maps.MapTypeStyle>{
        featureType: 'transit.station.bus',
        stylers: [
          <google.maps.MapTypeStyler>{visibility: 'off'}
        ]
      },
      <google.maps.MapTypeStyle>{
        featureType: 'poi.business',
        stylers: [
          <google.maps.MapTypeStyler>{visibility: 'on'}
        ]
      }
    ];
    this.map.mapTypes.set('map-style', new google.maps.StyledMapType(style, {}));
    this.map.setMapTypeId('map-style');

    return this.map;
  }

  public setCenter(lat: number, lng: number) {
    this.map.panTo(new google.maps.LatLng(lat, lng));
  }

  updateDisplay() {
    // this.weatherStationsLayer.setMap(this.displaySettings.weatherStations ? this.map : null);
  }
}
