import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {
  ConfirmModalComponent,
  GroupLocation,
  Location,
  LocationService,
  PersonSeriesGroup, PreventCloseModalService
} from '@smartencity/core';
import {
  DatapointGroupSharePersonModalComponent
} from '../../datapoint-groups-management/datapoint-group-share-person-modal/datapoint-group-share-person-modal.component';
import {debounceTime, map, shareReplay, startWith, switchMap, take, takeUntil, tap} from 'rxjs/operators';
import {
  DeleteDatapointGroupConfirmModalComponent
} from '../../datapoint-groups-management/delete-datapoint-group-confirm-modal/delete-datapoint-group-confirm-modal.component';
import {
  EditDatapointGroupModalComponent
} from '../../datapoint-groups-management/edit-datapoint-group-modal/edit-datapoint-group-modal.component';
import {
  DatapointGroupShareModalComponent
} from '../../datapoint-groups-management/datapoint-group-share-modal/datapoint-group-share-modal.component';
import {LocationListService} from '../../../services/location-list.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {BsModalService} from 'ngx-bootstrap/modal';
import {TenantSelectService} from '../../../services/tenant-select.service';
import {MyQuestionnairesService} from '../../questionnaires/my-questionnaires.service';
import {NavbarService} from '../../navbar/navbar.service';
import {SharedKeywordStore} from '../../../services/shared-search-keyword-store.service';
import {Subject} from 'rxjs/internal/Subject';
import {DatapointGroupsIndexService} from '../datapoint-groups-index.service';
import {Subscription} from 'rxjs/internal/Subscription';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';
import {GroupShareData} from '../../../models/datapoint-group/group-share-data';
import {TenantContractComponent} from '../../modal/tenant-contract.component';
import {PersonSeriesGroupApiService} from '../../../http/person-series-group-api.service';

@Component({
  selector: 'datapoint-groups-list',
  templateUrl: './datapoint-groups-list.component.html'
})
export class DatapointGroupsListComponent implements OnInit, OnDestroy {

  private ngDestroy = new Subject<void>();

  @Input()
  view = 'datapoint-groups-list';

  public groupLocations: GroupLocation[] = [];

  public reloadSubject = new Subject<void>();

  public reload$ = this.reloadSubject.asObservable();

  public filterForm = this.datapointGroupsIndexService.filterForm;

  public filter$ = this.filterForm.valueChanges.pipe(debounceTime(500), startWith(this.filterForm.getRawValue())).pipe(tap((filter) => {
    // this.sharedKeywordStore.setState(filter.query);
    return filter;
  }));

  public filteredLocations$ = combineLatest([
    this.filter$,
    this.reload$.pipe(startWith(null))
  ]).pipe(
    switchMap(([filter,]) => {
      return this.locationService.getGroupLocations(filter);
    }),
    takeUntil(this.ngDestroy),
    map((data: Location[]) => {
      const locations = data.map(l => new GroupLocation(l));
      locations.forEach((l) => Location.locationName(l));
      return locations;
    }),
    shareReplay(1)
  );

  groupLocationsSubscription: Subscription;

  constructor(
    private locationService: LocationService,
    private locationListService: LocationListService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    public router: Router,
    private modalService: BsModalService,
    private preventCloseModalService: PreventCloseModalService,
    private tenantSelectService: TenantSelectService,
    private myQuestionnairesService: MyQuestionnairesService,
    private navbarService: NavbarService,
    private sharedKeywordStore: SharedKeywordStore,
    private personSeriesGroupService: PersonSeriesGroupApiService,
    private datapointGroupsIndexService: DatapointGroupsIndexService
  ) {
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  ngOnInit(): void {
    this.filter$.subscribe();
    this.groupLocationsSubscription = this.filteredLocations$.pipe(takeUntil(this.ngDestroy)).subscribe((result) => {
      this.groupLocations = result;
    });
  }

  private resetLists() {
    this.datapointGroupsIndexService.resetLists();
  }

  showMore(location: Location) {
    this.locationService.navigate(location);
  }

  public editLocation(location: Location): void {
    if (!location.group) {
      return;
    }

    this.personSeriesGroupService.getGroup(location.group.id).subscribe((group: PersonSeriesGroup) => {
      const modalRef = this.preventCloseModalService.show(EditDatapointGroupModalComponent, {
        class: 'modal-lg',
        initialState: {
          group: group
        }
      });
      modalRef.content.saveEmitter.pipe(takeUntil(this.ngDestroy), take(1)).subscribe((data: any) => {
        // this.locationService.navigate(new Location(data.sourceOwnerSeries.personSeries), {sourceOwnerSeries: data.sourceOwnerSeries, addData: data.addData});
        //
        this.resetLists();

        //TODO: andmepunkti nimekiri + navigeerida andmegrupi nimekirja

        // this.locationService.navigate(new Location({
        //   groupingTag: group.groupingTag
        // }));
      });
    });

  }

  public shareLocation(location: Location): void {
    if (!location.group && location.groupOwnerType !== 'OWNER') {
      return;
    }

    this.personSeriesGroupService.getGroup(location.group.id).subscribe((group: PersonSeriesGroup) => {

      const modalRef = this.modalService.show(DatapointGroupShareModalComponent,
        {
          backdrop: true,
          ignoreBackdropClick: true,
          class: 'modal-lg',
          initialState: {
            group: group
          }
        }
      );

      modalRef.content.saveEmitter.pipe(takeUntil(this.ngDestroy), take(1)).subscribe((data: any) => {
        this.shareGroupToPerson(group, data);
      });
    });
  }



  private shareGroupToPerson(group: PersonSeriesGroup, data: GroupShareData): void {
    const modalRef = this.preventCloseModalService.show(DatapointGroupSharePersonModalComponent, {
      class: 'modal-lg',
      initialState: {
        group: group,
        shareData: data
      }
    });

    modalRef.content.saveEmitter.pipe(takeUntil(this.ngDestroy), take(1)).subscribe((data: any) => {
      this.resetLists();
    });
  }

  public deleteLocation(location: Location): void {
    if (!location.group) {
      return;
    }

    this.personSeriesGroupService.getGroup(location.group.id).subscribe((group: PersonSeriesGroup) => {

      const modalRef = this.modalService.show(DeleteDatapointGroupConfirmModalComponent,
        {
          backdrop: true,
          ignoreBackdropClick: true,
          class: 'modal-lg',
          initialState: {
            group: group
          }
        }
      );

      modalRef.content.savedEmitter.pipe(takeUntil(this.ngDestroy), take(1)).subscribe((data: any) => {
        this.resetLists();
      });
    });

  }

  public declineLocation(location: Location): void {
    if (!location.group && location.groupOwnerType !== 'CONSENT') {
      return;
    }

    this.modalService.show(ConfirmModalComponent, {
      ignoreBackdropClick: true,
      initialState: {
        description: $localize`Are you sure you want to decline data group?`,
        callback: (confirm: boolean) => {
          if (confirm) {
            this.personSeriesGroupService.updateConsentStatus(location.group.id, 'DECLINED').subscribe(() => {
              this.resetLists();
              this.toastr.success($localize`Data group declined`);
            }, (err) => {
              console.error(err);
              this.toastr.error($localize`Error occurred when declining data group`);
            });
          }
        }
      }
    });

  }

}
