import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {PageResponse, Token, TokenPersonHistory} from '@smartencity/core';
import {SMARTENCITY_MYDATA_CONFIG} from '../injection-tokens';
import {MyDataConfig} from '../mydata-config.model';

@Injectable()
export class PersonTokenApiService {

  constructor( @Inject(SMARTENCITY_MYDATA_CONFIG) public config: MyDataConfig,
               private http: HttpClient) { }

  public getTokens(params: any): Observable<PageResponse<Token>> {

    return this.http.get<PageResponse<Token>>(this.config.apiUrl + '/person/token', {
      params: params
    });
  }

  public createToken(token: Token): Observable<Token> {

    return this.http.post<Token>(this.config.apiUrl + '/person/token', token);
  }

  public getTokenPersonHistory(token: Token): Observable<TokenPersonHistory> {

    return this.http.get<TokenPersonHistory>(this.config.apiUrl + '/person/token/' + token.id + '/history');
  }

}
