import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'icon-people',
  templateUrl: './icon-people.component.html',
  styles: [
    `
      :host {
        display: flex;
        min-width: 0;
      }
  `
  ]
})
export class IconPeopleComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
