<div class="dashboard-item dashboard-item-other">
  <div class="widget-titlebar">
    <span class="drag-handler"></span>
    <div class="gridster-item-content widget-menu">
      <span class="close" (click)="close()">
        <i class="fal fa-times"></i>
        <span i18n>Close</span>
      </span>
    </div>
    <div class="row">
      <div class="col-auto pr-0">
        <span class="icon-dashboard advices"></span>
      </div>
      <div class="col">
        <h2 class="text-truncate" i18n>Advices</h2>
        <p class="small-text" i18n>Guidelines for optimising energy consumption.</p>
      </div>
    </div>
  </div>
  <div class="gridster-item-content">
    <div class="gridster-item-content d-flex justify-content-center mt-3">
      <div role="group" class="btn-group btn-group-sm btn-separate">
        <button type="button" class="btn btn-outline-primary" *ngFor="let groupRow of this.personAdviceGroupRows" (click)="setGroup(groupRow)">{{groupRow.personAdviceGroup.name}}</button>
      </div>
    </div>
  </div>
  <div class="gridster-item-content" *ngIf="activeGroupRow">
    <div class="d-flex justify-content-center flex-column align-items-center">
      <span class="title">{{activeGroupRow.personAdviceGroup.name}}</span>
      <p class="help-text">{{activeGroupRow.personAdviceGroup.description}}</p>
    </div>
  </div>
  <div class="gridster-item-content content" *ngIf="activeGroupRow">
    <ul class="list-unstyled mb-0">
      <li class="d-flex" *ngFor="let adviceRow of activeGroupRow.adviceRows">
        <span class="icon-bullet"></span>
        <div class="flex-grow-1">
          <h5>{{adviceRow.personAdvice.name}}</h5>
          <span class="help-text">{{adviceRow.personAdvice.category}}</span>
          <p class="small-text mb-0 mt-2">{{adviceRow.personAdvice.description}}</p>
        </div>
        <span class="status-button w-150p" style="cursor: pointer;" *ngIf="!adviceRow.control.get('checked').value" (click)="markAsDone(adviceRow)">
          <span class="icon-checked mt-1"></span>
          <button class="btn btn-link btn-sm" i18n>Mark as done</button>
        </span>
        <span class="status-button w-150p status-done" style="cursor: pointer;" *ngIf="adviceRow.control.get('checked').value" (click)="markAsDone(adviceRow)">
          <span class="icon-checked mt-1"></span>
          <button class="btn btn-link btn-sm" i18n>Done</button>
        </span>
      </li>
    </ul>
  </div>
</div>
