<form (ngSubmit)="selectGroup()">
  <div class="modal-header">
    <h5 class="modal-title w-100" i18n>Select data group</h5>
    <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
      <span class="fal fa-times"></span>
    </button>
  </div>
  <div class="modal-body" [formGroup]="form">
    <div *ngIf="form.invalid && confirmed" class="alert alert-danger">
      <div i18n>Form is invalid</div>
    </div>


    <div class="form-group">
      <div class="form-row">
        <div class="col">
          <label i18n [attr.for]="id + '-group'">Data group</label>
          <ng-select
            [id]="id + '-group'"
            [class.is-invalid]="invalid('group')"
            [clearable]="true"
            [searchable]="true"
            appendTo="body"
            placeholder="Data group name"
            i18n-placeholder
            addTagText="New data group"
            i18n-addTagText
            [typeahead]="groupTypeahead"
            [items]="groups"
            [selectOnTab]="true"
            [addTag]="createGroup"
            formControlName="group"
            id="person-series-group"
            class="custom"
            autofocus
          >
            <ng-template ng-option-tmp let-item="item">
              <span>{{item.name}}</span>
            </ng-template>
            <ng-template ng-label-tmp let-item="item" let-search="searchTerm" let-index="index">
              <span>{{item.name}}</span>
            </ng-template>
          </ng-select>

          <small *ngIf="invalid('group') && errors('group')?.required" class="invalid-feedback d-block" i18n>Required</small>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="submit" class="btn btn-primary btn-sm" i18n>Continue</button>
  </div>
</form>
