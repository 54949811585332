import { Component, Input, OnInit } from '@angular/core';
import { AuthService, Mandate, UserService } from '@smartencity/core';
import { ToastrService } from 'ngx-toastr';
import { DeleteModalComponent } from '../../delete-modal/delete-modal.component';
import { RestoreModalComponent } from '../../restore-modal/restore-modal.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs/internal/Subject';

@Component({
  selector: 'app-city-portal-info',
  templateUrl: './city-portal-info.component.html'
})
export class CityPortalInfoComponent implements OnInit {

  private ngDestroy = new Subject<void>();

  @Input()
  public currentMandate: Mandate;

  constructor(
    public authService: AuthService,
    private toastr: ToastrService,
    private modalService: BsModalService,
    public userService: UserService
  ) { }

  ngOnInit() {
    this.authService.currentMandate$.pipe(takeUntil(this.ngDestroy)).subscribe((mandate: Mandate) => {
      this.currentMandate = mandate;
    });
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  deleteAccount() {
    if (this.authService.profileData.mandate.type != 'OWNER') {
      this.toastr.error('Delete is forbidden', 'Error');
      return;
    }

    this.userService.deleteAccount().subscribe((data: any) => {
      this.modalService.show(DeleteModalComponent, {initialState: {deletionData: data}});
    });
  }

  restoreAccount() {
    if (this.authService.profileData.mandate.type != 'OWNER') {
      this.toastr.error('Restore is forbidden', 'Error');
      return;
    }

    this.modalService.show(RestoreModalComponent);
  }
}
