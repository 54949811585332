<div class="modal-header">
  <h5 class="modal-title w-100" i18n>Send feedback</h5>
  <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
    <span class="fal fa-times"></span>
  </button>
</div>
<form [formGroup]="formGroup" (ngSubmit)="send()">
  <div class="modal-body" >
    <div class="row">
      <div class="col">
        <span class="feedback-modal_page-url">{{pageUrl}}</span>
      </div>
    </div>

    <div class="form-group">
      <div class="form-row">
        <div class="col">
          <label [attr.for]="id + '-message'" i18n>Message</label>
          <textarea class="form-control"
                    [class.is-invalid]="invalid('message')"
                    [id]="id + '-message'" formControlName="message"
                    minlength="3"
                    maxlength="500"
                    cdkTextareaAutosize
                    #autosize="cdkTextareaAutosize"
                    cdkAutosizeMinRows="5"
                    cdkAutosizeMaxRows="10"></textarea>
          <form-field-errors [control]="formGroup.get('message')"></form-field-errors>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light btn-sm" aria-label="Close" (click)="close()" i18n>Close</button>
    <button type="submit" class="btn btn-primary btn-sm" i18n>Submit</button>
  </div>
</form>

