<div class="container-fluid p-4">
  <div class="block mb-5">
    <mydata-dashboard #dashboard></mydata-dashboard>
  </div>
</div>

<div class="floating-button questionnaire-button" [hidden]="incomingQuestionnairesVisible" (click)="showIncomingQuestionnaires()">
  <span class="icon-dashboard questionnaires"></span>
</div>

<div class="floating-button advice-button" [hidden]="advicesVisible" (click)="showAdvices()">
  <span class="icon-dashboard advices"></span>
</div>
