import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges, OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { MatCalendar } from '@angular/material/datepicker';
import { ConfigStoreService } from '../services/config-store.service';
import {Moment} from 'moment';

@Component({
  selector: 'calendar-wrapper',
  templateUrl: './calendar-wrapper.component.html',
  styleUrls: ['./calendar-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CalendarWrapperComponent implements OnInit, OnChanges, AfterViewInit {
  private viewInit = false;

  @ViewChild(MatCalendar, { static: true })
  matCalendar: MatCalendar<Moment>;

  @Output()
  readonly selectedDateChange: EventEmitter<Moment> = new EventEmitter<Moment>();

  updateActiveDate = false;
  dateFormat: string;
  private fromDateTruncated;
  private toDateTruncated;
  @Input() fromDate: Moment;
  @Input() toDate: Moment;
  @Input() activeDate: Moment;
  @Input() displayDate: Moment;
  @Input() prefixLabel: string;
  @Input() minDate: Moment;
  @Input() maxDate: Moment;
  @Input() name?: string = 'default';
  weekendFilter = (d: Moment) => true;
  dateClass = (d: Moment) => {
    // Reset to midnight, because of Moment timezone difference, can't use Moment
    const compareDate: Date = new Date(d.toDate().getTime());
    compareDate.setHours(0, 0, 0, 0);
    if (this.fromDateTruncated && this.toDateTruncated && (compareDate > this.fromDateTruncated && compareDate < this.toDateTruncated)) {
      return 'mat-calendar-body-cell-range-in';
    } else if (this.fromDateTruncated && compareDate.getTime() === this.fromDateTruncated.getTime() && (!this.toDateTruncated || compareDate.getTime() < this.toDateTruncated.getTime())) {
      return 'mat-calendar-body-cell-range-from';
    } else if (this.toDateTruncated && compareDate.getTime() === this.toDateTruncated.getTime() && (!this.fromDateTruncated || compareDate.getTime() > this.fromDateTruncated.getTime())) {
      return 'mat-calendar-body-cell-range-to';
    } else if (this.fromDateTruncated && this.toDateTruncated && compareDate.getTime() === this.fromDateTruncated.getTime() && this.fromDateTruncated.getTime() === this.toDateTruncated.getTime()) {
      return 'mat-calendar-body-cell-range-is';
    }

    return '';
  };

  constructor(private configStore: ConfigStoreService) {
    this.dateFormat = configStore.ngxDrpOptions.format;
    if (configStore.ngxDrpOptions.excludeWeekends) {
      this.weekendFilter = (d: Moment): boolean => {
        const day = d.toDate().getDay();
        return day !== 0 && day !== 6;
      };
    }
  }

  ngOnInit(): void {
    this.updateRange();


  }


  ngOnChanges(changes: SimpleChanges) {
    if (changes.activeDate || changes.fromDate || changes.toDate) {
      this.updateRange();
    }
  }

  ngAfterViewInit(): void {
    this.viewInit = true;

    this.matCalendar.stateChanges.subscribe(() => {
      if (this.updateActiveDate) {
        this.updateActiveDate = false;
        this.matCalendar.activeDate = this.activeDate;
      }
    });

    this.matCalendar.activeDate = this.activeDate;
    this.matCalendar.updateTodaysDate(); // Necessary to force view refresh
  }

  updateRange() {
    if (this.fromDate) {
      this.fromDateTruncated = new Date(this.fromDate.toDate().getTime());
      this.fromDateTruncated.setHours(0, 0, 0, 0);
    } else {
      this.fromDateTruncated = null;
    }
    if (this.toDate) {
      this.toDateTruncated = new Date(this.toDate.toDate().getTime());
      this.toDateTruncated.setHours(0, 0, 0, 0);
    } else {
      this.toDateTruncated = null;
    }
    if (this.viewInit) {
      // We are going to update active date in MatCalendar state change callback, because of race condition.
      // When setting active date manually on component change, then it is set before min and max dates are updated in MatCalendar, which results in wrong
      // active date in MatCalendar due to clamp date function
      // https://github.com/angular/components/blob/59002e1649123922df3532f4be78c485a73c5bc1/src/material/datepicker/calendar.ts#L326
      this.updateActiveDate = true;
      this.matCalendar.updateTodaysDate(); // Necessary to force view refresh
    }
  }

  onSelectedChange(date) {
    this.selectedDateChange.emit(date);
  }

  onMonthSelected(e) {}

  onYearSelected(e) {}

  onUserSelection(e) {}
}
