import {Component, Input, OnInit} from '@angular/core';
import {AuditLog, LabelPipe} from '@smartencity/core';

@Component({
  selector: 'mandate-log-list-item',
  templateUrl: './mandate-log-list-item.component.html'
})
export class MandateLogListItemComponent implements OnInit {

  @Input()
  item: AuditLog;

  constructor(private labelPipe: LabelPipe) {
  }

  ngOnInit(): void {
  }


  getPermissions(permissionKeys: string[]): string {
    return permissionKeys.map(k => this.labelPipe.transform(k)).join(', ');
  }

}
