import {Component, Input, OnInit} from '@angular/core';
import {SeriesLocation} from '../../models/series-location';

@Component({
  selector: 'env-indicators',
  templateUrl: './env-indicators.component.html'
})
export class EnvIndicatorsComponent implements OnInit {

  seriesCityPortalTypes: any = {
    GENERAL: ['SOLAR_PANELS_TREES_ESTONIA', 'SOLAR_PANELS_CO2_ESTONIA','SOLAR_PANELS_KM_ESTONIA'],
    SOLAR_PANELS_TOTAL: ['SOLAR_PANELS_TREES_TOTAL', 'SOLAR_PANELS_CO2_TOTAL', 'SOLAR_PANELS_KM_TOTAL'],
    LOCATION: ['SOLAR_PANELS_TREES', 'SOLAR_PANELS_CO2', 'SOLAR_PANELS_KM']
  }

  @Input()
  location: SeriesLocation;

  @Input()
  cityPortalTypeData: any;

  constructor() { }

  ngOnInit(): void {

  }

  showData(): boolean {
    if (!this.location || !this.location.type) {
      return false;
    }

    for (let cityPortalType of this.seriesCityPortalTypes[this.location.type]) {
      if (this.cityPortalTypeData[cityPortalType].series) {
        return true;
      }
    }

    return false;
  }

}
