import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {ConsentData, Dashboard, OwnerType, PersonDashboard} from "../../dashboard";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Observable} from "rxjs";
import {ToastrService} from "ngx-toastr";
import {BsModalService} from "ngx-bootstrap/modal";
import {ConfirmModalComponent, CoreConfig, Person, SMARTENCITY_CORE_CONFIG} from '@smartencity/core';
import { DashBoardConsentModal } from '../dashboard-consent-modal/dashboard-consent-modal.component';
import {Subject} from 'rxjs/internal/Subject';
import { takeUntil} from 'rxjs/operators';
import { DashboardApiService } from 'projects/mydata/src/lib/http/dashboard-api.service';

@Component({
  selector: '[app-dashboard-management-row]',
  templateUrl: './dashboard-management-row.component.html'
})
export class DashboardManagementRowComponent implements OnInit {
  private ngDestroy = new Subject<void>();

  nameFormControl: FormControl;
  publicDashboardFormControl: FormControl;
  publicDashboardAllowDownloadFormControl: FormControl;
  public isEdit = false;
  progress = false;
  submitted = false;

  @Input()
  personDashboard: PersonDashboard

  @Input()
  isCityUser: boolean

  @Output('remove')
  removeEmitter = new EventEmitter<void>();

  @Output('save')
  saveEmitter = new EventEmitter<void>();

  public consentForm: FormGroup;
  public showValidation = false;

  public OwnerType = OwnerType;

  public dashboardConsents: Person[] = [];

  constructor(
    private toastr: ToastrService,
    private modalService: BsModalService,
    @Inject(SMARTENCITY_CORE_CONFIG) public config: CoreConfig,
    private dashboardApiService: DashboardApiService
  ) {
    this.nameFormControl = new FormControl(null, [Validators.required]);
    this.publicDashboardFormControl = new FormControl(false);
    this.publicDashboardAllowDownloadFormControl = new FormControl(false);
  }

  ngOnInit(): void {
    this.isEdit = !this.personDashboard.dashboard.name;
    this.nameFormControl.setValue(this.personDashboard.dashboard.name);
    this.publicDashboardFormControl.setValue(this.personDashboard.dashboard.publicDashboard);
    this.publicDashboardAllowDownloadFormControl.setValue(this.personDashboard.dashboard.publicDownloadAllowed);
    if(this.personDashboard.ownerType == OwnerType.OWNER && this.personDashboard.dashboard.id) {
      this.loadConsents();
    }
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  loadConsents(): void {
    this.dashboardConsents = [];
    this.dashboardApiService.getConsents(this.personDashboard.dashboard.id).subscribe((consentData: ConsentData[]) => {
      consentData.find((consents) => {
        if(consents.dashboard.id == this.personDashboard.dashboard.id) {
          this.dashboardConsents.push(consents.person);
        }
      });
    });
  }

  remove() {
    if (this.personDashboard.dashboard.id) {
      this.modalService.show(ConfirmModalComponent, {
        ignoreBackdropClick: true,
        initialState: {
          description: 
            this.dashboardConsents.length < 1 ? $localize`Are you sure you want to delete the dashboard?` : $localize`This dashboard has been shared to other people, are you sure you want to delete this dashboard?`,
          callback: (confirm: boolean) => {
            if (confirm) {
              this.dashboardApiService.deleteDashboard(this.personDashboard.dashboard).subscribe(() => {
                this.removeEmitter.emit();
                this.toastr.success($localize`Dashboard deleted`);
              }, error => {
                this.progress = false;
                this.toastr.error($localize`Deleting dashboard failed`);
              });
            }
          }
        }
      });
    } else {
      this.removeEmitter.emit();
    }
  }

  edit() {
    this.isEdit = true;
  }

  save() {
    this.submitted = true;
    if (this.nameFormControl.errors) {
      return;
    }

    this.personDashboard.dashboard.name = this.nameFormControl.value;
    this.personDashboard.dashboard.publicDashboard = this.publicDashboardFormControl.value;
    this.personDashboard.dashboard.publicDownloadAllowed = this.publicDashboardAllowDownloadFormControl.value;

    this.progress = true;
    let observable: Observable<Dashboard>;
    if (this.personDashboard.dashboard.id) {
      observable = this.dashboardApiService.updateDashboard(this.personDashboard.dashboard);
    } else {
      observable = this.dashboardApiService.saveDashboard(this.personDashboard.dashboard);
    }

    observable.subscribe(value => {
      this.personDashboard.dashboard = value;
      this.personDashboard.ownerType = OwnerType.OWNER;
      this.progress = false;
      this.saveEmitter.next();
      this.isEdit = false;
      this.toastr.success($localize`Saved`);
    }, error => {
      this.progress = false;
      this.toastr.error($localize`Saving dashboard failed`);
    });
  }

  share(): void {
    const modalRef = this.modalService.show(DashBoardConsentModal, {
      ignoreBackdropClick: true,
      class: 'modal-lg',
      initialState: {
        dashboardId: this.personDashboard.dashboard.id
      }
    });

    modalRef.content.savedEmitter.pipe(takeUntil(this.ngDestroy)).subscribe((content) => {
      if(content) {
        this.loadConsents();
      }
    });
  }

  removeSharing(shareId: number): void {
    if (shareId) {
      const modalRef = this.modalService.show(ConfirmModalComponent, {
        ignoreBackdropClick: true,
        class: 'modal-sm',
        initialState: {
          description: $localize`Are you sure you want to remove the dashboard consent?`,
          callback: (confirm: boolean) => {
            if (confirm) {
              this.dashboardApiService.endConsent(this.personDashboard.dashboard.id, shareId).subscribe(
                (data: any) => {
                  this.toastr.success($localize`Dashboard removed`);
                },
                (err: any) => {
                  this.toastr.error($localize`Removing dashboard consent failed`);
                }
              );
            }
          }
      }});
    }
  }
}
