import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { TallinnArcGisMapService } from 'projects/arc-gis/src/lib/tallinn-arc-gis-map.service';
import { Subject } from 'rxjs/internal/Subject';
import { MobilityService } from '../../service/mobility.service';
import { takeUntil } from 'rxjs/operators';
import { RoadClosure, RoadClosureClickEvent } from '../../model/road-closure';
import { MobilityOlMapService } from '../../service/mobility-ol.service';
import PerfectScrollbar from 'perfect-scrollbar';
import { PeopleTrafficCounter } from '../../model/people-traffic-counter';
import { PeopleTrafficService } from '../../service/people-traffic.service';

@Component({
  selector: 'mobility-objects-modal',
  templateUrl: './mobility-objects-modal.component.html'
})
export class MobilityObjectsModalComponent implements OnInit, AfterViewInit {

  private ngDestroy = new Subject<void>();

  @ViewChild('modalContent') modalContent: ElementRef;

  roadClosure: RoadClosure;
  peopleTraffic: PeopleTrafficCounter;

  @Input()
  public objects: any[];

  @Output('modalClose')
  modalClose: EventEmitter<void> = new EventEmitter<void>();

  public currentIndex: number = 0;

  constructor(
    public mobilityService: MobilityService,
    private mobilityOlMapService: MobilityOlMapService,
    private peopleTrafficService: PeopleTrafficService,
    private tallinnArcGisMapService: TallinnArcGisMapService) { }

  ngOnInit(): void {

    this.mobilityService.roadClosuresClickEvent$.pipe(takeUntil(this.ngDestroy)).subscribe((clickEvent: RoadClosureClickEvent) => {
      this.resetIndex();
      this.roadClosure = (clickEvent.roadClosure);
      this.removeByType('roadClosure');
      if (clickEvent.type !== 'deselect') {
        this.objects.push({ type: 'roadClosure', data: this.roadClosure });
      }
    });

    this.peopleTrafficService.selectedPeopleTrafficCounter$.subscribe((peopleTraffic: PeopleTrafficCounter) => {
      this.peopleTraffic = peopleTraffic;
      this.removeByType('peopleTraffic');
      if (this.peopleTraffic !== null) {
        this.objects.push({ type: 'peopleTraffic', data: this.peopleTraffic });
      }
      this.resetIndex();
    });

    this.mobilityService.locationsSelected$.pipe(takeUntil(this.ngDestroy)).subscribe((locations: any[]) => {
      this.resetIndex();
      //layers that are in every module e.g residentialAreas and cityDistricts
      let layerTypes = Array.from(this.tallinnArcGisMapService.layersMap.keys());
      //additional layers only applied to this module
      let newLayerTypes = Array.from(this.mobilityOlMapService.arcGisMapLayers.keys());

      if (newLayerTypes.length > 0) {
        layerTypes = [].concat(newLayerTypes, layerTypes);
      }

      layerTypes.forEach((layerType) => {
        this.removeByType(layerType);
      });

      if (locations) {
        for (let location of locations) {
          this.objects.push({ type: location.layerName, data: location.data });
        }
      }
    });

    this.mobilityService.locationsDeselected$.pipe(takeUntil(this.ngDestroy)).subscribe((locationType: string) => {
      this.resetIndex();
      this.removeByType(locationType);
    });
  }

  private removeByType(locationType: string): void {
    this.objects = this.objects.filter((o) => o.type != locationType);
  }

  resetIndex() {
    this.currentIndex = 0;
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  close() {
    this.objects = [];
    this.modalClose.next();
  }

  ngAfterViewInit(): void {
    new PerfectScrollbar(this.modalContent.nativeElement);
  }
}
