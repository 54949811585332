import {Observable} from 'rxjs/internal/Observable';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';

export class Store<T> {

  state$: Observable<T>;
  private stateSubject: BehaviorSubject<T>;

  constructor(initialValue: T) {
    this.stateSubject = new BehaviorSubject<T>(initialValue);
    this.state$ = this.stateSubject.asObservable();
  }

  get state(): T {
    return this.stateSubject.getValue();
  }

  setState(value: T): void {
    this.stateSubject.next(value);
  }

}
