import {Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {
  ConfirmModalComponent,
  CoreConfig,
  PageResponse,
  PersonRegistrationNumberValidator, PersonSeries,
  PersonSeriesGroup,
  SMARTENCITY_CORE_CONFIG
} from '@smartencity/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs/internal/Subject';
import {debounceTime, distinctUntilChanged, map, shareReplay, startWith, switchMap, takeUntil, withLatestFrom} from 'rxjs/operators';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {ToastrService} from 'ngx-toastr';
import {DatapointGroupSeriesListService} from '../datapoint-group-series-list.service';
import {VirtualScrollerComponent} from 'ngx-virtual-scroller';
import {IPageInfo} from 'ngx-virtual-scroller/virtual-scroller';
import {PaginationComponent} from 'ngx-bootstrap/pagination';
import {Observable} from 'rxjs/internal/Observable';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';
import {merge} from 'rxjs/internal/observable/merge';
import {GroupShareData} from '../../../models/datapoint-group/group-share-data';
import {PersonSeriesGroupApiService} from '../../../http/person-series-group-api.service';


export class DatapointGroupDatapointsItem {
  checked: FormControl;
  personSeries: PersonSeries;
  shareAllowed = false;

  constructor(personSeries: PersonSeries, checked: boolean, cb: (item: DatapointGroupDatapointsItem, value: boolean) => void) {
    this.personSeries = personSeries;
    this.shareAllowed = ['OWNER', 'DATA_OWNER', 'MANAGER'].find(ownerType => personSeries.lastPeriod.ownerType == ownerType) != null;
    this.checked = new FormControl(checked);

    this.checked.valueChanges.subscribe((value) => {
      cb(this, value);
    });
  }
}
@Component({
  selector: 'datapoint-group-share-modal',
  templateUrl: './datapoint-group-share-modal.component.html',
  providers: [DatapointGroupSeriesListService]
})
export class DatapointGroupShareModalComponent implements OnInit {
  private ngDestroy = new Subject<void>();

  private pageSize = 50;

  @ViewChild('listScroll')
  private listScroll: VirtualScrollerComponent;

  @ViewChild('listPaginationComponent')
  private listPaginationComponent: PaginationComponent

  @Input()
  group?: PersonSeriesGroup = null;

  @Input()
  groupingTag?: string;

  @ViewChild('checkAllEl', {static: true})
  checkAllEl: ElementRef<any>;

  items: DatapointGroupDatapointsItem[];

  bufferRows = 5;

  private fetchedPages = new Set<number>();

  consentForm: FormGroup;

  allChecked = true;

  checkAllControlIndeterminate = false;

  checkAllControl: FormControl = new FormControl(this.allChecked);

  counts: any = {};

  public data = {
    sharedCount: 0,
    count: 0
  }

  public filterForm: FormGroup = new FormGroup({
    query: new FormControl(null),
    isOwnerPersonSeries: new FormControl(null)
  });

  public filter$ = this.filterForm.valueChanges.pipe(distinctUntilChanged(), debounceTime(250), startWith({}));

  public pageSubject: Subject<number> = new Subject<number>();
  public page$: Subject<any> = new Subject<any>();
  public limit$: Subject<any> = new Subject<any>();

  public filterLimit$ = combineLatest([
    this.filter$,
    this.limit$.asObservable().pipe(startWith(this.pageSize))
  ]);

  public queryParams$ = merge(
    this.filterLimit$.pipe(map(([filter, limit]: [any, number]) => [filter, limit, {page: 0, limit: limit}])),
    this.page$.pipe(
      withLatestFrom(this.filterLimit$),
      map(([page, [filter, limit]]: [any, [any, number]]) => [filter, limit, page])
    )
  ).pipe(
    map(([filter, limit, page]) => this.mapQueryParams(filter, page, limit)),
    takeUntil(this.ngDestroy)
  );

  public pageResponse$ = this.queryParams$.pipe(switchMap(queryParams => {
      return this.fetchPage(queryParams);
    }),
    shareReplay(1),
    takeUntil(this.ngDestroy)
  );

  private mapQueryParams(filter: any, pageNumber: number, limit: number) {
    const params: any = {
      page: pageNumber,
      size: limit,
      query: null,
      ownerPersonSeries: null,
      groupId: this.group.id,
      statuses: ['ACCEPTED']
    };

    if (filter.query) {
      params.query = filter.query;
    }

    if (filter.isOwnerPersonSeries != null) {
      params.ownerPersonSeries = filter.isOwnerPersonSeries;
    }

    return params;
  }

  public ownerPersonSeriesOptions = [
    // {
    //   value: null,
    //   label: $localize`Status`
    // },
    {
      value: true,
      label: $localize`Allowed`,
    }, {
      value: false,
      label: $localize`Not allowed`,
    }
  ];


  pageResponse: PageResponse<PersonSeries>;

  public affectedPersonSeriesMap: Map<number, PersonSeries> = new Map<number, PersonSeries>();

  @Output('save')
  saveEmitter: EventEmitter<GroupShareData> = new EventEmitter<GroupShareData>();

  constructor(
    @Inject(SMARTENCITY_CORE_CONFIG) private config: CoreConfig,
    public modalRef: BsModalRef,
    private modalService: BsModalService,
    public toastr: ToastrService,
    private personSeriesGroupService: PersonSeriesGroupApiService,
    public personRegistrationNumberValidator: PersonRegistrationNumberValidator) {
  }

  ngOnInit(): void {
    this.consentForm = new FormGroup({
      person: new FormControl(null, [Validators.required, this.personRegistrationNumberValidator.check.bind(this.personRegistrationNumberValidator)]),
      customStartAt: new FormControl(null)
    });

    combineLatest([
      this.personSeriesGroupService.counts(this.group.id),
      this.pageResponse$
    ]).pipe(takeUntil(this.ngDestroy)).subscribe(([counts, pageResponse]: [any, PageResponse<PersonSeries>]) => {
      this.counts = counts;
      this.data.sharedCount = this.counts.ownerSeriesCount;
      this.data.count = this.counts.count;

      this.pageResponse = pageResponse;
      if (!this.items || this.items.length != pageResponse.totalElements) {
        this.items = Array.from({length:pageResponse.totalElements});
      }
      this.items.splice(pageResponse.page * pageResponse.size, pageResponse.size, ...pageResponse.content.map(ps => new DatapointGroupDatapointsItem(ps, this.allChecked != this.affectedPersonSeriesMap.has(ps.id), this.onItemCheckChange.bind(this))));
      if (!this.fetchedPages) {
        this.fetchedPages = new Set<number>();
      }

      if (pageResponse.totalPages < this.fetchedPages.size) {
        this.fetchedPages.clear();
      }
      this.fetchedPages.add(pageResponse.page);
    });

    this.checkAllControl.valueChanges.pipe(takeUntil(this.ngDestroy)).subscribe((value) => {
      this.toggleAll(value);
    });
  }


  chartVsChange(event: IPageInfo) {
    if (event.startIndex < 0 || event.endIndex < 0) {
      return;
    }

    if (event.startIndex <= 1) {
      if (this.listPaginationComponent) {
        this.listPaginationComponent.selectPage(0);
      }
    }

    let startPage = Math.floor((event.startIndex / this.pageSize));
    let endPage = Math.floor((event.endIndex / this.pageSize));
    if (startPage < 0) {
      startPage = 0;
    }
    if (endPage < 0) {
      endPage = 0;
    }

    for (let i = startPage; i <= endPage; i++) {
      if (this.fetchedPages.has(i)) {
        continue;
      }

      this.page$.next(i);
    }
  }

  private toggleAll(value: boolean): void {
    this.allChecked = value;
    for (let item of this.items) {
      if (item && item.checked) {
        item.checked.setValue(value);
      }
    }

    this.affectedPersonSeriesMap.clear();
    this.updateSharedCounts();
  }

  reset(): void {
    for (let item of this.items) {
      if (item && item.checked) {
        item.checked.setValue(this.allChecked);
      }
    }

    this.affectedPersonSeriesMap.clear();
    this.updateSharedCounts();
  }

  closeAndReturnResult(): void {
    const personSeriesIds = Array.from(this.affectedPersonSeriesMap.values()).map(ps => ps.id);
    const allChecked = this.allChecked;
    if (allChecked && personSeriesIds.length == 0) {

      const modalRef = this.modalService.show(ConfirmModalComponent, {
        ignoreBackdropClick: true,
        initialState: {
          description: $localize`The entire group is about to be shared. Do you wish to also share all the future new data points?`,
          cancelLabel: $localize`Skip`,
          okLabel: $localize`Accept`,
          callback: (confirm: boolean) => {
            this.doCloseAndReturnResult({
              personSeriesIds: personSeriesIds,
              allChecked: allChecked,
              confirmSendUpdates: confirm
            });
          }
        }
      });

    } else {
      this.doCloseAndReturnResult({
        personSeriesIds: personSeriesIds,
        allChecked: allChecked,
        confirmSendUpdates: false
      });
    }

  }

  private doCloseAndReturnResult(sharedData: {
    personSeriesIds: number[],
    allChecked: boolean,
    confirmSendUpdates: boolean
  }) {

    this.modalRef.hide();
    this.saveEmitter.next({
      personSeriesIds: sharedData.personSeriesIds,
      excludePersonSeriesIds: sharedData.allChecked,
      sharedCount: this.data.sharedCount,
      count: this.data.count,
      confirmSendUpdates: sharedData.confirmSendUpdates
    });
  }

  close(): void {
    this.modalRef.hide();
  }

  private fetchPage(params: any): Observable<PageResponse<PersonSeries>> {
      return this.personSeriesGroupService.getGroupPersonSeriesPage(params);
  }

  private onItemCheckChange(item: DatapointGroupDatapointsItem, value: boolean) {
    if (value && !this.allChecked || !value && this.allChecked) {
      this.addToAffectedPersonSeriesMap(item.personSeries);
    } else {
      this.removeFromExcludeSeriesMap(item.personSeries);
    }

    this.checkAllControlIndeterminate = this.affectedPersonSeriesMap.size > 0;
    this.checkAllEl.nativeElement.indeterminate = this.checkAllControlIndeterminate;
  }

  private removeFromExcludeSeriesMap(personSeries: PersonSeries): void {
    if (this.affectedPersonSeriesMap.has(personSeries.id)) {
      this.affectedPersonSeriesMap.delete(personSeries.id);
      this.updateSharedCounts();
    }
  }

  private addToAffectedPersonSeriesMap(personSeries: PersonSeries): void {
    if (!this.affectedPersonSeriesMap.has(personSeries.id)) {
      this.affectedPersonSeriesMap.set(personSeries.id, personSeries);
      this.updateSharedCounts();
    }
  }

  private updateSharedCounts(): void {
    if (this.affectedPersonSeriesMap.size > 0) {
      this.data.sharedCount = (!this.allChecked ? this.affectedPersonSeriesMap.size : this.counts.ownerSeriesCount - this.affectedPersonSeriesMap.size);
    } else {
      this.data.sharedCount = (this.allChecked ? this.counts.ownerSeriesCount : 0);
    }
  }

  clearSearch() {
    this.filterForm.get('query').setValue('');
    this.filterForm.get('isOwnerPersonSeries').setValue(null);
  }
}
