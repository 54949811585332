import {Component, Input, OnInit} from '@angular/core';
import {ErrorStateMatcher} from '@angular/material/core';
import {FormControl, FormGroupDirective, NgForm} from '@angular/forms';
import moment from 'moment';

class DateErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return control.invalid && control.touched;
  }
}

@Component({
  selector: 'datepicker-wrapper',
  templateUrl: './datepicker-wrapper.component.html'
})
export class DatepickerWrapper implements OnInit {

  @Input()
  id: string;

  @Input('control')
  control: FormControl;

  @Input()
  min?: Date;

  @Input()
  max?: Date;

  minDate: Date = moment(new Date(1970, 1, 1)).toDate();

  maxDate: Date;


  errorStateMatcher = new DateErrorStateMatcher();

  constructor() {
  }

  ngOnInit(): void {
    if (this.min) {
      this.minDate = this.min;
    }

    if (this.max) {
      this.maxDate = this.max;
    } else {
      this.maxDate = moment().add(100, 'year').toDate();
    }

  }

}
