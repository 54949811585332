import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CoreModule} from '@smartencity/core';
import {ModalModule} from 'ngx-bootstrap/modal';
import {PaginationModule} from 'ngx-bootstrap/pagination';
import {NgSelectModule} from '@ng-select/ng-select';
import {NgxPermissionsModule} from 'ngx-permissions';
import {DashboardComponent} from './dashboard.component';
import {WidgetModalComponent} from './widget-modal/widget-modal.component';
import {WidgetComponent} from './widget/widget.component';
import {WidgetValueTableComponent} from './widget/widget-value-table/widget-value-table.component';
import {WidgetValueChartComponent} from './widget/widget-value-chart/widget-value-chart.component';
import {WidgetTimeFrameChartComponent} from './widget/widget-time-frame-chart/widget-time-frame-chart.component';
import {WidgetCompareFooterComponent} from './widget-compare-footer.component';
import {WidgetPreviewComponent} from './widget-modal/widget-preview/widget-preview.component';
import {GridsterModule} from 'angular-gridster2';
import {DashboardActionsComponent} from './dashboard-actions.component';
import {DashboardPageComponent} from './dashboard-page.component';
import {WidgetRowComponent} from './widget-modal/widget-row.component';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {ButtonsModule} from 'ngx-bootstrap/buttons';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatButtonModule} from '@angular/material/button';
import {AdviceModule} from '../advice/advice.module';
import {PopoverModule} from 'ngx-bootstrap/popover';
import {AdvicesComponent} from './advices/advices.component';
import {IncomingQuestionnairesComponent} from './incoming-questionnaires/incoming-questionnaires.component';
import {ColorPickerModule} from 'ngx-color-picker';
import { DashboardManagementComponent } from './dashboard-management/dashboard-management.component';
import { DashboardManagementRowComponent } from './dashboard-management/dashboard-management-row/dashboard-management-row.component';
import {WidgetTemplateModalComponent} from './widget-template-modal/widget-template-modal.component';
import { WidgetTemplateSelectModalComponent } from './widget-template-select-modal/widget-template-select-modal.component';
import { DashboardWidgetTemplateListModalComponent } from './dashboard-widget-template-list-modal/dashboard-widget-template-list-modal.component';
import { DashboardDownloadComponent } from './dashboard-download.component';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import { DashBoardConsentModal } from './dashboard-management/dashboard-consent-modal/dashboard-consent-modal.component';
import { IconOwnerComponent } from './dashboard-management/dashboard-management-row/icons/icon-owner/icon-owner.component';
import { IconSharedComponent } from './dashboard-management/dashboard-management-row/icons/icon-shared/icon-shared.component';
import {LaddaModule} from 'angular2-ladda';
import {NgxMatDrpModule, SharedModule} from '@smartencity/shared';
import {PersonParameterApiService} from '../../http/person-parameter-api.service';

@NgModule({
  declarations: [
    DashboardComponent,
    WidgetModalComponent,
    WidgetTemplateModalComponent,
    WidgetComponent,
    WidgetValueTableComponent,
    WidgetValueChartComponent,
    WidgetTimeFrameChartComponent,
    WidgetCompareFooterComponent,
    WidgetPreviewComponent,
    WidgetRowComponent,
    DashboardActionsComponent,
    DashboardPageComponent,
    AdvicesComponent,
    IncomingQuestionnairesComponent,
    DashboardManagementComponent,
    DashboardManagementRowComponent,
    WidgetTemplateSelectModalComponent,
    DashboardWidgetTemplateListModalComponent,
    DashboardDownloadComponent,
    DashBoardConsentModal,
    IconOwnerComponent,
    IconSharedComponent
  ],
  providers: [
    PersonParameterApiService
  ],
  imports: [
    CommonModule,
    RouterModule.forChild([]),
    ReactiveFormsModule,
    CoreModule,
    AdviceModule,
    NgxPermissionsModule.forChild(),
    NgxMatDrpModule,
    ModalModule,
    BsDropdownModule,
    PaginationModule,
    NgSelectModule,
    GridsterModule,
    ButtonsModule,
    DragDropModule,
    MatButtonModule,
    PopoverModule,
    ColorPickerModule,
    FormsModule,
    TooltipModule,
    LaddaModule,
    SharedModule
  ],
  exports: [
    DashboardComponent,
    DashboardActionsComponent,
    WidgetCompareFooterComponent,
    WidgetComponent
  ]
})
export class DashboardModule { }
