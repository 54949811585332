<ng-container *ngIf="addressTypeahead">
  <label i18n [attr.for]="id + '-control-typeahead'">Address</label>
  <input
    type="text"
    class="form-control" [class.is-invalid]="invalid('address')"
    [formControl]="formControl"
    [coreValidate]="showErrors" [coreValidateControl]="form.get('address')"
    [ngbTypeahead]="addressTypeahead"
    [inputFormatter]="inputFormatter"
    [resultFormatter]="resultFormatter"
    [id]="id + '-control-typeahead'"
    (selectItem)="onItemSelect($event)"
    maxlength="255"
  >
  <small *ngIf="invalid('address') && errors('address')?.required" class="invalid-feedback d-block" i18n>Required</small>
  <div class="input-group-append" *ngIf="false">
    <!-- TODO searching spinner -->
  </div>
</ng-container>
<ng-container *ngIf="!addressTypeahead">
  <label i18n [attr.for]="id + '-control'">Address</label>
  <input
    type="text"
    class="form-control"
    [formControl]="formControl"
    [coreValidate]="showErrors"
    [id]="id + '-control'"
  >
  <!-- validation error -->
</ng-container>
