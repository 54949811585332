import {Inject, Injectable} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {catchError, debounceTime, distinctUntilChanged, map, shareReplay, startWith, switchMap, takeUntil} from 'rxjs/operators';
import {Subject, combineLatest, throwError} from 'rxjs';
import {GroupSource, PageResponse, Questionnaire, QuestionnaireTypes} from '@smartencity/core';
import {HttpClient} from '@angular/common/http';
import {SMARTENCITY_MYDATA_CONFIG} from '../injection-tokens';
import {MyDataConfig} from '../mydata-config.model';

@Injectable()
export class CreateSourcesService {

  public q11eTypes: string[] = Object.values(QuestionnaireTypes);

  private ngDestroy = new Subject<void>();

  public page: PageResponse<Questionnaire>;
  public defaultLimit = 40;
  public defaultSorts = [{prop: 'createdAt', dir: 'desc'}];

  public filterForm: FormGroup = new FormGroup({
    query: new FormControl(),
    seriesTypes: new FormControl()
  });

  public filter$ = this.filterForm.valueChanges.pipe(distinctUntilChanged(), debounceTime(250), startWith({}));

  public sorts$: Subject<any> = new Subject<any>();

  public filterSorts$ = combineLatest([
    this.filter$,
    this.sorts$.pipe(startWith(this.defaultSorts))
  ]);

  public queryParams$ = this.filterSorts$.pipe(
    map(([filter, sorts]) => this.mapQueryParams(filter, sorts)),
    takeUntil(this.ngDestroy)
  );

  public load$ = new Subject();

  public questionnaires$ = combineLatest([this.queryParams$, this.load$]).pipe(
    switchMap(([queryParams,]: [any, any]) => {
      return this.fetchPage(queryParams);
    }),
    shareReplay(1),
    takeUntil(this.ngDestroy)
  );

  constructor(@Inject(SMARTENCITY_MYDATA_CONFIG) private config: MyDataConfig,
              private http: HttpClient) { }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  fetchPage(queryParams: any) {
    const params = Object.assign({types: this.q11eTypes}, queryParams);
    for (const i of Object.keys(params)) {
      if (params[i] == null) {
        delete params[i];
      }
    }

    return this.http.get<PageResponse<Questionnaire>>(this.config.apiUrl + '/questionnaire', {
      params: params
    }).pipe(catchError((err) => {
      return throwError(err);
    }));
  }

  mapQueryParams(filter: any, sorts: any[]) {
    const queryParams: any = {};
    if (filter.query) {
      queryParams.query = filter.query;
    }

    if (sorts && sorts.length > 0) {
      queryParams.sortFields = sorts.reduce((accumulator, currentValue) => {
        if (currentValue.dir === 'desc') {
          return accumulator + encodeURIComponent('-' + currentValue.prop);
        } else {
          return accumulator + '+' + currentValue.prop;
        }
      }, '');
    }

    return queryParams;
  }

}
