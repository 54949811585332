import {Component, OnInit, OnDestroy, ViewChild, NgZone, HostListener, ChangeDetectorRef, AfterViewInit} from '@angular/core';
import {combineLatest, Observable} from 'rxjs';
import {StopInfoModalComponent} from './modal/stop-info-modal.component';
import {BusInfoModalComponent} from './modal/bus-info-modal.component';
import {Bus, BusResponse} from '../model/bus';
import {Stop, StopTime} from '../model/stop';
import {MobilityGmapService} from '../service/mobility-gmap.service';
import {Trip} from '../model/trip';
import {VehicleStopArrival} from '../model/vehicle';
import {BicycleStationsHeatmapComponent} from './bicycle-stations-heatmap/bicycle-stations-heatmap.component';
import {PeopleTowersHeatmapComponent} from './people-towers-heatmap/people-towers-heatmap.component';
import {RoadClosureModalComponent} from './road-closure-modal/road-closure-modal.component';
import {MobilityDisplaySettings} from '../mobility-config.model';
import {ServicesService} from '../../../../services/src/lib/services.service';
import { MobilitySearchService } from '../service/mobility-search.service';
import {MobilityService} from '../service/mobility.service';
import {MobilityApiService} from '../http/mobility-api.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {PeopleTrafficModalComponent} from './people-traffic-modal/people-traffic-modal.component';



@Component({
  selector: 'gmap-mobility',
  templateUrl: './gmap-mobility.component.html',
  providers: [
    MobilityService,
    MobilityGmapService,
    MobilitySearchService
  ]
})
export class GmapMobilityComponent implements OnInit, OnDestroy, AfterViewInit {

  private ngDestroy = new Subject<void>();

  @ViewChild('gmap', {static: true})
  gmapElement: any;

  @ViewChild('stopInfoModal', {static: true}) public stopInfoModal: StopInfoModalComponent;
  @ViewChild('busInfoModal', {static: true}) public busInfoModal: BusInfoModalComponent;
  @ViewChild('roadClosureModal', {static: true}) public roadClosureModal: RoadClosureModalComponent;
  @ViewChild('bicycleStationsHeatmapComponent', {static: false}) public bicycleStationsHeatmapComponent: BicycleStationsHeatmapComponent;
  @ViewChild('peopleTowersHeatmapComponent', {static: false}) public peopleTowersHeatmapComponent: PeopleTowersHeatmapComponent;

  map: google.maps.Map;

  public buses$: Observable<Bus[]>;

  public busesTimeout: any = null;

  public trafficTimeout: any = null;

  public displaySettings: MobilityDisplaySettings = <MobilityDisplaySettings>{};

  private currentZoomLevel = null;

  private _state: any = {
    busesLoaded: false,
    evChargersLoaded: false
  }

  @HostListener('document:visibilitychange', ['$event'])
  onFocus(event: FocusEvent): void {
    if (document.visibilityState === 'visible') {
      this.mobilityMapService.repositionBuses();
    }
  }

  private mapLoadedSource: Subject<void> = new Subject<void>();
  private mapLoaded$ = this.mapLoadedSource.asObservable().pipe(takeUntil(this.ngDestroy));

  constructor(private mobilityService: MobilityService,
              private mobilityMapService: MobilityGmapService,
              private mobilitySearchService: MobilitySearchService,
              private mobilityApiService: MobilityApiService,
              private servicesService: ServicesService,
              private zone: NgZone,
              private cdr: ChangeDetectorRef) {
    this.buses$ = this.mobilityService.buses$;
  }

  ngOnInit() {
    this.displaySettings = this.mobilityService.getDisplaySettings();
    this.map = this.mobilityMapService.createMap(this.gmapElement);
    this.mobilitySearchService.setUpdateDisplay(this.displaySettings);

    this.currentZoomLevel = this.map.getZoom();

    google.maps.event.addListenerOnce(this.map, 'idle', () => {
      setTimeout(() => {
        this.mapLoadedSource.next();
      }, 300);
    });

    this.initEventListeners();
    this.mobilityService.visibleBusesSource.next(null);
  }


  pollBuses() {
    if (this.busesTimeout != null) {
      clearTimeout(this.busesTimeout);
    }

    this.busesTimeout = setTimeout(() => {
      this.mobilityService.loadBuses(() => {
        this.pollBuses();
      });
    }, 3000);
  }

  pollTraffic() {
    if (this.trafficTimeout != null) {
      clearTimeout(this.trafficTimeout);
    }
    this.trafficTimeout = setTimeout(() => {
      this.mobilityService.loadTrafficThroughput();
    }, 30000);
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();

    if (this.busesTimeout) {
      clearTimeout(this.busesTimeout);
    }
    if (this.trafficTimeout) {
      clearTimeout(this.trafficTimeout);
    }

    this.mobilityService.clear();
    this.mobilityMapService.clear();
  }

  private initEventListeners() {
    this.mobilityService.loadBuses(() => {
      this.mobilityMapService.updateBuses();
      this.pollBuses();
    });

    this.mobilityService.stops$.subscribe(() => {
      this.mobilityMapService.showStops();
    });

    this.mobilityService.busesClear$.subscribe(() => {
      this.busInfoModal.clear();
    });

    this.mobilityService.stopsClear$.subscribe(() => {
      this.stopInfoModal.clear();
    });

    this.mobilityService.throughputPoints$.subscribe(() => {
      this.pollTraffic();
    });

    this.mobilityService.selectedStop$.subscribe(stop => {
      if (stop) {
        this.showStop(stop);
      }
    });

    this.mobilityService.selectedBus$.subscribe(bus => {
      if (bus) {
        this.showBus(bus);
      }
    });

    this.mobilityService.selectedCleared$.subscribe(value => {
      this.busInfoModal.hide();
      this.stopInfoModal.hide();
      this.mobilityService.visibleStopsSource.next(null);
      this.mobilityService.visibleBusesSource.next(null);
      this.mobilityMapService.clearShapes();
    });

    this.onDisplaySettingsUpdate();
  }

  private onDisplaySettingsUpdate(): void {
    combineLatest([this.mobilityService.displaySettings$, this.mapLoaded$]).subscribe(([displaySettings,]: [MobilityDisplaySettings, void]) => {
      this.displaySettings = displaySettings;

      if (this.displaySettings.stops) {
        this.mobilityService.loadStops();
      }

      if (this.displaySettings.buses) {
        if (!this._state.busesLoaded) {
          this._state.busesLoaded = true;
          this.mobilityService.loadBuses();
        }
      }

      if (this.displaySettings.traffic) {
        this.mobilityService.loadTrafficThroughput();
      }

      if (this.displaySettings.electricVehicleCharger) {
        if (!this._state.evChargersLoaded) {
          this._state.evChargersLoaded = true;
          this.mobilityService.loadElectricVehicleChargers();
        }
      }

      if (this.displaySettings.evChargerDk) {
        this.mobilityService.loadEvChargersDk();
      }

      if (this.displaySettings.roadClosures) {
        this.mobilityService.loadRoadClosures();
      }

    });
  }

  showStop(stop: Stop) {
    this.mobilityMapService.panToByCoords(stop.lat, stop.lng);
    this.busInfoModal.hide();
    this.mobilityService.visibleStopsSource.next([stop.id]);
    this.mobilityMapService.clearShapes();
    this.mobilityApiService.getStopTimes(stop.id).subscribe((stopTimes: StopTime[]) => {
      this.stopInfoModal.setStopTimes(stopTimes);

      const tripIds = Array.from(new Set(stopTimes.map(value => value.tripId)));

      let stopIds: string[] = [];
      tripIds.forEach(tripId => {
        this.mobilityApiService.getTrip(tripId).subscribe((trip: Trip) => {
          this.mobilityMapService.addShape(trip.shape);
          stopIds = stopIds.concat(trip.stops.map(stopTime => stopTime.id));
          this.mobilityService.visibleStopsSource.next(stopIds);
        });
      });

      const buses = this.mobilityService.buses.filter(bus => tripIds.indexOf(bus.tripId) > -1);
      this.mobilityService.visibleBusesSource.next(buses.map(bus => bus.id));

    });
    this.mobilityApiService.getStopArrivals(stop.id).subscribe((stopArrivals: VehicleStopArrival[]) => {
      this.stopInfoModal.setStopArrivals(stopArrivals);
    });
  }

  showBus(bus: Bus) {
    this.mobilityMapService.panToByCoords(bus.lat, bus.lng);
    this.stopInfoModal.hide();
    this.mobilityMapService.clearShapes();

    const busIds = this.mobilityService.buses.filter(value => value.route === bus.route).map(value => value.id);
    this.mobilityService.visibleBusesSource.next(busIds);

    this.mobilityApiService.getTrip(bus.tripId).subscribe((trip: Trip) => {
      this.mobilityMapService.addShape(trip.shape);
      this.mobilityService.visibleStopsSource.next(trip.stops.map(s => s.id));
    });
  }

  displayItemsChanged(key: string, active: boolean) {
    let items = { [key]: {active: active} };
    if (items.people?.active) {
      items.bicycleParkingStation = { active: false };
    }
    if (items.bicycleParkingStation?.active) {
      items.people = { active: false };
    }

    const newDisplaySettings = Object.assign(this.displaySettings, items);
    this.mobilityService.updateDisplaySettings(newDisplaySettings)
    this.mobilitySearchService.setUpdateDisplay(newDisplaySettings);
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

}
