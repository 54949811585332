import { Inject, Injectable, NgZone } from '@angular/core';
import { StopsLayer } from '../layers/stops-layer';
import { BusesLayer } from '../layers/buses-layer';
import { RoutesLayer } from '../layers/routes-layer';
import { Shape } from '../model/shape';
import { SMARTENCITY_MOBILITY_CONFIG } from '../injection-tokens';
import { MobilityConfig, MobilityDisplaySettings } from '../mobility-config.model';
import { TrafficThroughputLayer } from '../layers/traffic-layer';
import { ThroughputPoint } from '../model/traffic';
import { EvChargersLayer } from '../layers/ev-chargers-layer';
import { EvCharger } from '../model/ev-charger';
import { RoadClosuresGmapLayer } from '../layers/road-closures-gmap-layer';
import { RoadClosure } from '../model/road-closure';
import { EvChargerDk } from '../model/ev-charger-dk';
import { MyLocationButton } from '@smartencity/core';
import LatLng = google.maps.LatLng;
import { EvChargersDkLayer } from '../layers/ev-chargers-dk-gmap-layer';
import { ServicesService } from '../../../../services/src/lib/services.service';
import {MobilityService} from './mobility.service';
import {Subject} from 'rxjs/internal/Subject';
import {Bus} from '../model/bus';
import {Stop} from '../model/stop';

@Injectable()
export class MobilityGmapService {
  private map;

  public trafficLayer = new google.maps.TrafficLayer();
  public trafficThroughputLayer: TrafficThroughputLayer = null;
  public evChargersLayer: EvChargersLayer = null;
  public evChargersDkLayer: EvChargersDkLayer = null;
  public stopsLayer: StopsLayer = null;
  public busesLayer: BusesLayer = null;
  public shapesLayer: RoutesLayer = null;
  public roadClosuresLayer: RoadClosuresGmapLayer = null;

  displaySettings: MobilityDisplaySettings;
  displaySettings$ = this.mobilityService.displaySettings$;

  public stateDispatch$ = new Subject<void>();

  public locationSelect$ = new Subject<void>();

  constructor(
    @Inject(SMARTENCITY_MOBILITY_CONFIG) private config: MobilityConfig,
    public mobilityService: MobilityService,
    public servicesService: ServicesService,
    private ngZone: NgZone
  ) { }

  createMap(gmapElement) {

    const mapProp = {
      center: this.config.map.center,
      zoom: this.config.map.zoom,
      mapTypeControl: false,
      fullscreenControl: false,
      clickableIcons: false,
      styles: [
        <google.maps.MapTypeStyle>{
          featureType: 'transit.station.bus',
          stylers: [
            <google.maps.MapTypeStyler>{ visibility: 'off' }
          ]
        }
      ]
    };

    this.map = new google.maps.Map(gmapElement.nativeElement, mapProp);

    const style = [
      {
        stylers: [
          { saturation: -80 },
        ]
      },
      <google.maps.MapTypeStyle>{
        featureType: 'transit.station.bus',
        stylers: [
          <google.maps.MapTypeStyler>{ visibility: 'off' }
        ]
      },
      <google.maps.MapTypeStyle>{
        featureType: 'poi.business',
        stylers: [
          <google.maps.MapTypeStyler>{ visibility: 'on' }
        ]
      }
    ];
    this.map.mapTypes.set('map-style', new google.maps.StyledMapType(style, {}));
    this.map.setMapTypeId('map-style');

    this.stopsLayer = new StopsLayer(this.map, this.mobilityService, this);
    this.trafficThroughputLayer = new TrafficThroughputLayer(null, this.mobilityService);
    this.busesLayer = new BusesLayer(this.map, this.mobilityService, this, this.ngZone);
    this.shapesLayer = new RoutesLayer(this.map, this.mobilityService);

    this.mobilityService.visibleStops$.subscribe((ids: string[]) => {
      this.showStops(ids);
    });

    this.mobilityService.visibleBuses$.subscribe((ids?: number[]) => {
      this.busesLayer.showBuses(ids);
    });

    this.mobilityService.buses$.subscribe(() => {
      const ids = this.busesLayer.busMarkers.map(value => {
        return value.bus.id;
      });
      this.busesLayer.showBuses(ids);
    });

    this.mobilityService.throughputPoints$.subscribe((points: ThroughputPoint[]) => {
      this.trafficThroughputLayer.showPoints(points);
      this.updateState();
    });

    this.mobilityService.displaySettings$.subscribe(value => {
      this.displaySettings = value;
      this.updateDisplay();
    });

    this.mobilityService.selectedStop$.subscribe(stop => {
      this.busesLayer.clearSelection();
      this.stopsLayer.setSelectedStop(stop);
      if (stop == null) {
        this.stopsLayer.clearSelection();
        this.clearShapes();
        this.mobilityService.visibleBusesSource.next(null);
      }
    });

    this.mobilityService.selectedBus$.subscribe(bus => {
      this.busesLayer.clearSelection();
      this.busesLayer.selectBus(bus);
      if (bus == null) {
        this.stopsLayer.clearSelection();
        this.clearShapes();
        this.mobilityService.visibleBusesSource.next(null);
      }
    });

    this.mobilityService.busesClear$.subscribe(() => {
      this.clearShapes();
    });

    this.mobilityService.stopsClear$.subscribe(() => {
      this.clearShapes();
    });

    this.mobilityService.selectedEvCharger$.subscribe((evCharger: EvCharger) => {
      if (this.evChargersLayer) {
        this.evChargersLayer.setSelected(evCharger);

        if (this.displaySettings.electricVehicleCharger?.active) {
          this.evChargersLayer.setMap(this.map);
        }
      }
    });

    this.mobilityService.selectedEvChargerDk$.subscribe((evCharger: EvChargerDk) => {
      if (this.evChargersDkLayer) {
        this.evChargersDkLayer.setSelected(evCharger);

        if (this.displaySettings.evChargerDk?.active) {
          this.evChargersDkLayer.setMap(this.map);
        }
      }
    });

    this.mobilityService.selectedRoadClosure$.subscribe((roadClosure: RoadClosure) => {
      if (this.roadClosuresLayer) {
        this.roadClosuresLayer.selectRoadClosure(roadClosure);
      }
    });

    this.mobilityService.roadClosures$.subscribe((roadClosures: RoadClosure[]) => {
      if (!this.roadClosuresLayer) {
        this.roadClosuresLayer = new RoadClosuresGmapLayer(this.map, this.mobilityService, roadClosures);
      } else {
        this.roadClosuresLayer.setRoadClosures(roadClosures);
      }
    });

    this.mobilityService.evChargersDk$.subscribe((evChargersDk: EvChargerDk[]) => {
      if (this.evChargersDkLayer) {
        this.evChargersDkLayer.setMap(null);
      }
      this.evChargersDkLayer = new EvChargersDkLayer(evChargersDk);
      if (this.displaySettings.evChargerDk?.active) {
        this.evChargersDkLayer.setMap(this.map);
      }
    });

    this.mobilityService.evChargers$.subscribe((evChargers: EvCharger[]) => {
      if (this.evChargersLayer) {
        this.evChargersLayer.setMap(null);
      }
      this.evChargersLayer = new EvChargersLayer(evChargers);
      if (this.displaySettings.electricVehicleCharger?.active) {
        this.evChargersLayer.setMap(this.map);
      }
    });

    this.mobilityService.allBicycleStations$.subscribe(() => {
      this.updateState();
    });

    this.servicesService.selectedService$.subscribe((service) => {
      this.map.panTo(new google.maps.LatLng(service.lat, service.lng));
    });

    new MyLocationButton(this.map);

    return this.map;
  }

  public panToByCoords(lat: number, lng: number) {
    this.panTo(new google.maps.LatLng(lat, lng));
  }

  public panTo(latLng: LatLng): void {
    this.map.panTo(latLng);
  }

  private clearStopSelection() {
    this.stopsLayer.setSelectedStop(null);
  }

  updateDisplay() {
    this.trafficLayer.setMap(this.displaySettings.traffic?.active ? this.map : null);
    this.trafficThroughputLayer.setMap(this.displaySettings.trafficThroughput?.active ? this.map : null);

    this.shapesLayer.setMap(this.displaySettings.routes?.active ? this.map : null);
    if (this.evChargersDkLayer) {
      this.evChargersDkLayer.setMap(this.displaySettings.evChargerDk?.active ? this.map : null);
    }
    if (this.evChargersLayer) {
      this.evChargersLayer.setMap(this.displaySettings.electricVehicleCharger?.active ? this.map : null);
    }

    this.toggleRoadClosures();
    this.toggleBuses();
    this.toggleStops();
  }

  private toggleBuses(): void {
    this.busesLayer.setMap(this.displaySettings.buses?.active ? this.map : null);
    if (!this.displaySettings.buses?.active) {
      this.mobilityService.hideBuses();
    }
  }

  private toggleStops(): void {

    this.stopsLayer.setMap(this.displaySettings.stops?.active ? this.map : null);
    if (!this.displaySettings.stops?.active) {
      this.mobilityService.hideStops();
    }
  }


  clear(): void {
    this.trafficLayer.setMap(null);
    this.trafficThroughputLayer.setMap(null);
    this.busesLayer.setMap(null);
    this.stopsLayer.setMap(null);
    this.shapesLayer.setMap(null);
    if (this.evChargersDkLayer) {
      this.evChargersDkLayer.setMap(null);
    }
    if (this.evChargersLayer) {
      this.evChargersLayer.setMap(null);
    }
    if (this.roadClosuresLayer) {
      this.roadClosuresLayer.clear();
    }
    this.clearShapes();
  }

  private toggleRoadClosures(): void {
    if (this.displaySettings.roadClosures) {
      if (this.roadClosuresLayer) {
        if (!this.displaySettings.roadClosures?.active) {
          this.roadClosuresLayer.clear();
        } else {
          this.roadClosuresLayer.setRoadClosures(this.mobilityService.roadClosures);
        }
      } else {
        this.mobilityService.loadRoadClosures();
      }
    }
  }

  showStops(ids?: string[]) {
    this.stopsLayer.showStops(ids);
  }

  updateBuses() {
    this.busesLayer.showBuses();
  }

  repositionBuses() {
    this.busesLayer.repositionBuses();
  }

  addShape(shape: Shape) {
    this.shapesLayer.addShape(shape);
  }

  clearShapes() {
    this.shapesLayer.clearRoutes();
  }

  highlightShape(shapeId: string) {
    this.shapesLayer.highLight(shapeId);
  }

  resetShapes() {
    this.shapesLayer.reset();
  }

  public onBusClick(bus: Bus) {
    this.mobilityService.selectBus(bus);
    this.locationSelect$.next();
  }

  public onStopClick(stop: Stop) {
    this.mobilityService.selectStop(stop);
    this.locationSelect$.next();
  }

  public updateState(): void {
    this.stateDispatch$.next();
  }

}
