<div class="modal-content">
  <div class="modal-header header-border flex-row d-flex align-items-center p-3">
    <div>
      <h5 class="modal-title w-100">
        {{questionnaire?.name}}
        <small class="help-text mb-0 mt-3">{{questionnaire?.description}}</small>
      </h5>
      <span class="help-text font-light">{{questionnaire?.updatedAt | date: 'dd. MMMM yyyy'}}</span>
    </div>
    <div class="col-auto ml-auto">
      <button type="button" class="btn btn-sm btn-primary" (click)="edit()" tooltip="Edit questionnaire" placement="auto" i18n-tooltip i18n style="position:relative; top: -4px;">Edit</button>
    </div>
    <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
      <span class="fal fa-times"></span>
    </button>
  </div>
  <div class="modal-body">
    <ul class="navbar-nav navbar-tabs justify-content-start justify-content-md-center overflow-scroll-mobile mb-3">
      <li class="nav-item">
        <a class="nav-link" [class.active]="view === 'fields'" (click)="setView('fields')"><ng-container i18n>Questions</ng-container>&nbsp;({{questionnaire?.fields.length}})</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [class.active]="view === 'files'" (click)="setView('files')"><ng-container i18n>Files</ng-container>&nbsp;({{questionnaire?.files.length}})</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [class.active]="view === 'participants'" (click)="setView('participants')"><ng-container i18n>Participants</ng-container>&nbsp;({{participantCount}})</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [class.active]="view === 'results'" (click)="setView('results')"><ng-container i18n>Results</ng-container>&nbsp;({{questionnaire?.fields.length}})</a>
      </li>
    </ul>
    <mydata-view-fields *ngIf="view === 'fields'" [questionnaire]="questionnaire"></mydata-view-fields>
    <mydata-view-files *ngIf="view === 'files'" [questionnaire]="questionnaire"></mydata-view-files>
    <mydata-view-participants *ngIf="view === 'participants'" [participants]="participants" [emailInvites]="emailInvites"></mydata-view-participants>
    <mydata-view-results *ngIf="view === 'results'" [questionnaire]="questionnaire"></mydata-view-results>
  </div>
</div>
