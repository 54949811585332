import {Injectable} from '@angular/core';
import {Moment} from 'moment';
import {MomentDateAdapter} from '@angular/material-moment-adapter';

@Injectable()
export class MyDateAdapter extends MomentDateAdapter {
  parse(value: any): Moment | null {
    return super.parse(value, 'DD.MM.YYYY');
  }

  getFirstDayOfWeek(): number {
    return 1;
  }
}
