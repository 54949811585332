import {Component, Inject, OnInit} from '@angular/core';
import {SMARTENCITY_CORE_CONFIG} from '../../injection-tokens';
import {CoreConfig} from '../../core-config.model';

@Component({
  selector: 'core-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  public logoImgSrc = 'assets/img/city_portal_logo_h.svg';

  constructor(
    @Inject(SMARTENCITY_CORE_CONFIG) public config: CoreConfig
  ) {
    if (config.theme === 'tartu') {
      this.logoImgSrc = 'assets/img/tartu/tartu_logo.svg';
    } else if (config.theme === 'greengas') {
      this.logoImgSrc = 'assets/img/gaas/green_gas.svg';
    } else if (config.theme === 'tallinn') {
      this.logoImgSrc = 'assets/img/tallinn/tallinn_logo.svg';
    }
  }

  ngOnInit(): void {
  }

}
