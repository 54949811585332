import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BsModalService} from 'ngx-bootstrap/modal';
import {ComponentLoaderFactory} from 'ngx-bootstrap/component-loader';
import {Location, PageResponse, Questionnaire, RegNoPipe, SCREEN_SIZE, ResizeService, QuestionnaireTypes} from '@smartencity/core';
import {Subject} from 'rxjs/internal/Subject';
import {HttpClient} from '@angular/common/http';
import {SMARTENCITY_MYDATA_CONFIG} from '../../injection-tokens';
import {MyDataConfig} from '../../mydata-config.model';
import {catchError, debounceTime, distinctUntilChanged, map, shareReplay, startWith, switchMap, takeUntil} from 'rxjs/operators';
import {ConfirmModalComponent} from '../../../../../core/src/lib/components/confirm-modal/confirm-modal.component';
import {TenantSelectService} from '../../services/tenant-select.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FormulaTemplateService} from './formula-template.service';
import {ToastrService} from 'ngx-toastr';
import {DatatableComponent} from '@swimlane/ngx-datatable';
import {CreateSourcesService} from '../../services/create-sources.service';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';
import {throwError} from 'rxjs/internal/observable/throwError';
import {FormulaUpdateErrorsModal} from './formula-update-errors-modal/formula-update-errors-modal.component';
import {SourceUpdateErrorService} from '../../services/source-update-error.service';
import {FormulaSourceUpdateErrorService} from '../../services/formula-source-update-error.service';

const selector = 'mydata-template-list';
let nextId = 0;

@Component({
  selector: selector,
  templateUrl: './template-list.component.html',
  providers: [
    BsModalService,
    ComponentLoaderFactory,
    {
      provide: SourceUpdateErrorService,
      useClass: FormulaSourceUpdateErrorService
    }
  ]
})
export class TemplateListComponent implements OnInit, OnDestroy {
  id = `${selector}-${nextId++}`;
  private ngDestroy = new Subject<void>();

  @ViewChild('table') table: DatatableComponent;
  public columns = {
    detailToggler: false,
    name: true,
    updatedAt: true,
    errors: true,
    actions: true
  };

  public location: Location;

  public filterForm = this.createSourcesService.filterForm;

  public page: PageResponse<Questionnaire>;
  public defaultLimit = 40;
  public defaultSorts = [{prop: 'createdAt', dir: 'desc'}];

  public q11eTypes: string[] = Object.values(QuestionnaireTypes);

  public filter$ = this.filterForm.valueChanges.pipe(distinctUntilChanged(), debounceTime(250), startWith({}));
  public sorts$: Subject<any> = new Subject<any>();

  public filterSorts$ = combineLatest([
    this.filter$,
    this.sorts$.pipe(startWith(this.defaultSorts))
  ]);

  public queryParams$ = this.filterSorts$.pipe(
    map(([filter, sorts]) => this.mapQueryParams(filter, sorts)),
    takeUntil(this.ngDestroy)
  );
  public load$: Subject<void> = new Subject();

  public questionnaires$ = combineLatest([this.queryParams$, this.load$]).pipe(
    switchMap(([queryParams,]: [any, any]) => {
      return this.fetchPage(queryParams);
    }),
    shareReplay(1),
    takeUntil(this.ngDestroy)
  );

  rows$ = this.questionnaires$.pipe(
    map((data: PageResponse<Questionnaire>) => this.mapPageResultsToRows(data)),
    takeUntil(this.ngDestroy)
  );

  rows: any[];

  constructor(
    @Inject(SMARTENCITY_MYDATA_CONFIG) private config: MyDataConfig,
    private http: HttpClient,
    private formulaTemplateService: FormulaTemplateService,
    private modalService: BsModalService,
    private toastr: ToastrService,
    private tenantSelectService: TenantSelectService,
    private router: Router,
    private regNoPipe: RegNoPipe,
    private route: ActivatedRoute,
    private resizeService: ResizeService,
    private createSourcesService: CreateSourcesService
  ) {
    this.rows$.pipe(takeUntil(this.ngDestroy)).subscribe((rows) => {
      this.rows = rows;
    });

    this.load();
    this.formulaTemplateService.update$.pipe(takeUntil(this.ngDestroy)).subscribe(() => {
      this.load();
    });

    this.resizeService.onResize$.pipe(takeUntil(this.ngDestroy)).subscribe(this.adjustColumns.bind(this));
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  ngOnInit() {
  }

  mapPageResultsToRows(data: PageResponse<Questionnaire>) {
    this.page = data;
    const rows = [];
    for (const questionnaire of data.content) {
      let completedCount = 0;
      for (const participant of questionnaire.participants) {
        if (participant.status === 'COMPLETED') {
          completedCount++;
        }
        rows.push({
          id: null,
          questionnaireId: questionnaire.id,
          questionnaire: null,
          participant: participant,
          rowId: 'p-' + participant.id,
          parentRowId: 'q11e-' + questionnaire.id,
          treeStatus: null,
          name: participant.person.displayName + ' (' + this.regNoPipe.transform(participant.person) + ')',
          participantCount: null,
          completedCount: null,
          status: participant.status,
          updatedAt: participant.createdAt,
          formulaUpdateErrorCount: 0
        });
      }
      rows.push({
        id: questionnaire.id,
        questionnaireId: questionnaire.id,
        questionnaire: questionnaire,
        participant: null,
        rowId: 'q11e-' + questionnaire.id,
        parentRowId: null,
        treeStatus: 'collapsed',
        name: questionnaire.name,
        participantCount: questionnaire.participants.length,
        completedCount: completedCount,
        status: questionnaire.status,
        updatedAt: questionnaire.updatedAt,
        formulaUpdateErrorCount: questionnaire.formulaUpdateErrorCount
      });
    }

    return rows;
  }

  setSorts(event: any) {
    this.sorts$.next(event.sorts);
  }

  editQuestionnaire(questionnaire: Questionnaire): void {
    this.router.navigate(['formula-template', questionnaire.id], { relativeTo: this.route });
  }

  copyQuestionnaire(questionnaire: Questionnaire): void {
    this.router.navigate(['formula-template', 'add'], {relativeTo: this.route, state: {copyFromId: questionnaire.id}});
  }

  deleteQuestionnaire(questionnaire: Questionnaire): void {
    const modalRef = this.modalService.show(ConfirmModalComponent, {
      ignoreBackdropClick: true,
      initialState: {
        description: $localize`Are you sure you want to delete the formula template?`,
        callback: (confirm: boolean) => {
          if (confirm) {
            this.http
              .delete(this.config.apiUrl + '/questionnaire/' + questionnaire.id)
              .pipe(takeUntil(this.ngDestroy))
              .subscribe(() => {
                this.toastr.success($localize`Formula template deleted`);
                this.load();
              }, (error) => {
                this.toastr.error($localize`Formula template deletion failed`);
                console.error(error);
              });
          }
        }
      }
    });
  }

  toggleExpandRow(row) {
    this.table.rowDetail.toggleExpandRow(row);
  }

  mapQueryParams(filter: any, sorts: any[]) {
    const queryParams: any = {};
    if (filter) {
      if (filter.seriesTypes) {
        queryParams.seriesTypes = filter.seriesTypes.join(',');
      }
    }

    if (filter.query) {
      queryParams.query = filter.query;
    }

    if (sorts && sorts.length > 0) {
      queryParams.sortFields = sorts.reduce((accumulator, currentValue) => {
        if (currentValue.dir === 'desc') {
          return accumulator + encodeURIComponent('-' + currentValue.prop);
        } else {
          return accumulator + '+' + currentValue.prop;
        }
      }, '');
    }

    return queryParams;
  }

  fetchPage(queryParams: any) {
    const params = Object.assign({types: ['FORMULA_SOURCE_TEMPLATE']}, queryParams);
    for (const i of Object.keys(params)) {
      if (params[i] == null) {
        delete params[i];
      }
    }

    return this.http.get<PageResponse<Questionnaire>>(this.config.apiUrl + '/questionnaire', {
      params: params
    }).pipe(catchError((err) => {
      return throwError(err);
    }));
  }

  public setTypes(q11eTypes: string[]) {
    this.q11eTypes = q11eTypes;
  }

  public load() {
    this.load$.next();
  }

  public showFormulaUpdateErrors(questionnaire: Questionnaire): void {

    const modalRef = this.modalService.show(FormulaUpdateErrorsModal, {
      initialState: {
        q11e: questionnaire
      }
    });

    modalRef.content.savedEmitter.subscribe(() => {
      this.load();
    });

  }

  private adjustColumns(size): void {
    if (size != null && size < SCREEN_SIZE.MD) {
      this.columns.detailToggler = true;
      this.columns.name = true;
      this.columns.updatedAt = false;
      this.columns.actions = false;
      this.columns.errors = false;
    } else {
      this.columns.detailToggler = false;
      this.columns.name = true;
      this.columns.updatedAt = true;
      this.columns.actions = true;
      this.columns.errors = true;
      if (this.table) {
        this.table.rowDetail.collapseAllRows();
      }
    }
  }

}
