import {Component, Input, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-person-series-event-modal',
  templateUrl: './person-series-event-modal.component.html'
})
export class PersonSeriesEventModalComponent implements OnInit {

  @Input() name?: string;
  @Input() event?: any;

  constructor (
    public modalRef: BsModalRef
  ) { }

  ngOnInit(): void {
  }

  close() {
    this.modalRef.hide();
  }
}
