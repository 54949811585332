import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter, Inject,
  Input, LOCALE_ID,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {ChartType, Widget, WidgetDataset, WidgetDatasetValue} from '../../widget';
import {WidgetService} from '../../widget.service';
import {Chart} from 'chart.js';
import 'chartjs-plugin-colorschemes';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {SCREEN_SIZE, ResizeService, NumberFormatConstants, WidgetDataService, ChartDataService} from '@smartencity/core';
import {Subject} from 'rxjs/internal/Subject';
import {takeUntil} from 'rxjs/operators';
import {Moment} from 'moment';
import {DecimalPipe} from '@angular/common';
import {BsModalService} from 'ngx-bootstrap/modal';
import {ToastrService} from 'ngx-toastr';

export class GraphDataItem {
  groupId: string;
  groupName: string;
  name: string;
  unit: string;
  stackId?: string;
  value: number;
  widgetDatasetValue: WidgetDatasetValue
}

class GroupInfo {
  id: string;
  name: string;
  unit: string;
  min: number;
  max: number;
  yAxisName: string;
  xAx: any;
  yAx: any;
}

class AxisMeta {
  name: string;
  unit: string;
}

@Component({
  selector: 'mydata-widget-value-chart',
  templateUrl: './widget-value-chart.component.html',
  styles: [':host {height: 100%}']
})
export class WidgetValueChartComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  private ngDestroy = new Subject<void>();

  @Input()
  public widget: Widget;

  @Input()
  public rangeFrom: Moment;

  @Input()
  public rangeTo: Moment;

  @Input()
  public aggregationGroupingType: string;

  @ViewChild('chartCanvas')
  public chartCanvas: ElementRef;

  @Input()
  public loadPng: Subject<void>;

  @Output()
  public viewReady = new EventEmitter<boolean>();

  public previousSizeLtBpValue = null;
  public showLegendOverride = null;
  public showAxesOverride = null;

  public chart: Chart;

  public nextPointStyleIndex = 0;
  public nextXAxisId = 0;
  public nextYAxisId = 0;
  public nextNogroupId = 0;
  public nextNostackId = 0;
  public chartLabels: Array<any> = [];
  public chartDatasets: Array<any> = [];
  public chartYAxes: Array<any> = [];
  public chartXAxes: any = [{
    stacked: true
  }];
  public groups = new Map<string, GroupInfo>(); // key = name + unit

  public chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 0
    },
    legend: {
      display: undefined,
      onClick: (e) => e.stopPropagation()
    },
    scales: {
      xAxes: this.chartXAxes,
      yAxes: this.chartYAxes
    },
    plugins: {
      colorschemes: {
        scheme: 'brewer.RdPu7'
      }
    },
    chartArea: {
      backgroundColor: 'rgba(255, 255, 255, 1.0)'
    },
    tooltips: {
      position: 'custom',
      callbacks: {
        label: (tooltipItem, data) => {
          let value = null;
          if (this.widget.chartType === ChartType.BAR) {
            if (isNaN(tooltipItem.yLabel)) {
              return tooltipItem;
            } else {
              value = tooltipItem.yLabel;
            }
          } else {
            value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          }

          let maxFractionDigits = data.datasets[tooltipItem.datasetIndex].numberFormat?.maxFractionDigits;
          if (maxFractionDigits == null) {
            maxFractionDigits = NumberFormatConstants.DEFAULT_NUMBER_FORMAT.defaultFractionDigits;
          }

          return new Intl.NumberFormat(this.locale, {
            maximumFractionDigits: maxFractionDigits,
            useGrouping: false
          }).format(value);
        },
        title: (tooltipItems, data) => {
          if (this.widget.chartType === ChartType.BAR) {
            return tooltipItems.map(e => {
              return data.datasets[e.datasetIndex].label;
            }).join(', ');
          } else {
            return tooltipItems.map(e => {
              return data.labels[e.index];
            }).join(', ');
          }
        }
      }

    }
  };

  public hasDatasets = false;
  public hasData = false;

  public valueStatsByUnitAndYAxis: Map<string, any> = new Map<string, any>();

  constructor(
    public widgetService: WidgetService,
    public widgetDataService: WidgetDataService,
    public chartDataService: ChartDataService,
    private decimalPipe: DecimalPipe,
    private resizeService: ResizeService,
    private modalService: BsModalService,
    private toastr: ToastrService,
    @Inject(LOCALE_ID) public locale: string,
  ) {

    Chart.Tooltip.positioners.custom = (elements, eventPosition) => {
      return {
        x: eventPosition.x,
        y: eventPosition.y
      };
    };

    this.resizeService.onResize$.pipe(takeUntil(this.ngDestroy)).subscribe(this.updateWidgetOnResize);
  }

  ngOnInit() {
    if (this.loadPng) {
      this.loadPng.pipe(takeUntil(this.ngDestroy)).subscribe(value => {
        if (!this.chart) {
          this.toastr.info($localize`No data`);
          return;
        }
        this.widgetService.downloadPng(this.widget, this.chart);
      });
    }
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
    if(this.chart){
      this.chart.destroy();
    }
  }

  ngAfterViewInit(): void {
    this.updateChart();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.widget || changes.rangeFrom || changes.rangeTo || changes.aggregationGroupingType) {
      this.widgetDataService.getWidgetValues(this.widget, this.rangeFrom, this.rangeTo, this.aggregationGroupingType).pipe(takeUntil(this.ngDestroy)).subscribe((values: WidgetDatasetValue[]) => {
        this.updateChartDatasets(values);
      });
    }
  }

  updateChartDatasets(values: WidgetDatasetValue[]) {
    const type = this.chartDataService.chartMapping[this.widget.chartType];

    if (values.length) {
      this.hasDatasets = true;
    } else {
      this.hasDatasets = false;
    }
    let hasData = false;

    this.groups.clear();
    this.chartLabels.splice(0, this.chartLabels.length);
    this.chartDatasets.splice(0, this.chartDatasets.length);
    this.chartXAxes.splice(1, this.chartXAxes.length);
    this.chartYAxes.splice(0, this.chartYAxes.length);

    this.nextPointStyleIndex = 0;
    this.nextXAxisId = 0;
    this.nextYAxisId = 0;
    this.nextNogroupId = 0;
    this.nextNostackId = 0;

    this.valueStatsByUnitAndYAxis.clear();

    if (type === 'bar') {
      // bar
      const graphDataItems: Array<GraphDataItem> = [];
      for (let i = 0; i < values.length; i++) {
        const wdv: WidgetDatasetValue = values[i];

        switch (wdv.widgetDataset.type) {
          case 'PERSON_SERIES': {
            if (wdv.personSeriesValue.periodLastValue && wdv.personSeriesValue.periodLastValue.measurement) {
              const value = wdv.personSeriesValue.periodLastValue.measurement.value;
              if (value != null) {
                const wd = wdv.widgetDataset;
                const unit = wdv.personSeriesValue.periodLastValue.measurement.unit;
                const groupInfo = this.getOrCreateGroup(wd.groupName, unit);
                graphDataItems.push({
                  groupId: groupInfo.id,
                  groupName: wd.groupName,
                  name: wd.name ? wd.name : '',
                  unit: unit,
                  stackId: wd.groupName && wd.graphType === 'stack' ? 'stack-' + groupInfo.id : 'nostack-' + this.nextNostackId++,
                  value: value,
                  widgetDatasetValue: wdv
                });

                this.increaseValueStatsByUnit(groupInfo, value);
              }
            }
            break;
          }
          case 'PERSON_PARAMETER': {
            const value = wdv.personParameterValue.value;
            if (value != null) {
              const wd = wdv.widgetDataset;
              const unit = wdv.personParameterValue.unit;
              const groupInfo = this.getOrCreateGroup(wd.groupName, unit);
              graphDataItems.push({
                groupId: groupInfo.id,
                groupName: wd.groupName,
                name: wd.name ? wd.name : '',
                unit: unit,
                stackId: wd.groupName && wd.graphType === 'stack' ? 'stack-' + groupInfo.id : 'nostack-' + this.nextNostackId++,
                value: value,
                widgetDatasetValue: wdv
              });

              this.increaseValueStatsByUnit(groupInfo, value);
            }
            break;
          }
          case 'Q11E_FIELD': {
            const fieldValueFrequency = {};
            for (const response of wdv.q11eFieldValue.responses) {
              let value = null;
              if (response.type === 'TEXT') {
                value = response.text;
              } else if (response.type === 'VALUE') {
                value = response.value;
              } else if (response.type === 'SELECT') {
                value = response.option;
              }
              if (value != null) {
                if (fieldValueFrequency[value] || fieldValueFrequency[value] === 0) {
                  fieldValueFrequency[value]++;
                } else {
                  fieldValueFrequency[value] = 1;
                }
              }
            }

            const unit = '#';
            const wd = wdv.widgetDataset;
            const groupInfo = this.getOrCreateGroup(wd.groupName, unit);
            for (const value of Object.keys(fieldValueFrequency)) {
              const fq = fieldValueFrequency[value];
              const name = (wd.name ? wd.name + ': ' : '') + value;

              graphDataItems.push({
                groupId: groupInfo.id,
                groupName: groupInfo.name,
                name: name,
                unit: unit,
                stackId: wd.groupName && wd.graphType === 'stack' ? 'stack-' + groupInfo.id : 'nostack-' + this.nextNostackId++,
                value: fq,
                widgetDatasetValue: wdv
              });

              this.increaseValueStatsByUnit(groupInfo, fq);
            }
            break;
          }
          case 'Q11E_RESPONSE_FIELD': {
            if (wdv.q11eResponseFieldValue.type === 'VALUE') {
              const value = wdv.q11eResponseFieldValue.value;
              if (value != null) {
                const wd = wdv.widgetDataset;
                const unit = wdv.q11eResponseFieldValue.unit;
                const groupInfo = this.getOrCreateGroup(wd.groupName, unit);
                graphDataItems.push({
                  groupId: groupInfo.id,
                  groupName: wd.groupName,
                  name: wd.name ? wd.name : '',
                  unit: unit,
                  stackId: wd.groupName && wd.graphType === 'stack' ? 'stack-' + groupInfo.id : 'nostack-' + this.nextNostackId++,
                  value: value,
                  widgetDatasetValue: wdv
                });

                this.increaseValueStatsByUnit(groupInfo, value);
              }
            }
            break;
          }
        }
      }

      const groupData = new Map<string, number[]>();
      const groupIdLabelIdMap = new Map<string, number>();
      const labels = new Array<string>();

      for (let graphDataItem of graphDataItems) {
        const wdv: WidgetDatasetValue = graphDataItem.widgetDatasetValue;
        const wd: WidgetDataset = wdv.widgetDataset;
        if (!groupIdLabelIdMap.has(graphDataItem.groupId)) {
          groupIdLabelIdMap.set(graphDataItem.groupId, labels.length);
          labels.push(graphDataItem.unit ? wd.groupName + ', ' + graphDataItem.unit : graphDataItem.groupName);
        }
        this.getOrCreateGroup(graphDataItem.groupName, graphDataItem.unit);
      }

      for (let graphDataItem of graphDataItems) {
        hasData = true;

        const wdv: WidgetDatasetValue = graphDataItem.widgetDatasetValue;
        const wd: WidgetDataset = wdv.widgetDataset;

        const mapping = {
          data: [],
          unit: graphDataItem.unit,
          min: graphDataItem.value,
          max: graphDataItem.value
        };
        mapping.data[groupIdLabelIdMap.get(graphDataItem.groupId)] = graphDataItem.value;
        const dataset = this.createChartDataset(graphDataItem.groupId, graphDataItem.name, mapping, wd.scaleMin, wd.scaleMax, graphDataItem.stackId, wd.color);
      }

      for (const i of labels.keys()) {
        const v = labels[i];
        if (v && v.length > 32) {
          labels[i] = v.substr(0, 29) + '...';
        }
      }

      this.chartLabels = labels;
    } else {
      // line, pie, doughnut, radar, polarArea
      const graphDataItems: Array<GraphDataItem> = [];

      for (let i = 0; i < values.length; i++) {
        const wdv: WidgetDatasetValue = values[i];
        switch (wdv.widgetDataset.type) {
          case 'PERSON_SERIES': {
            if (wdv.personSeriesValue.periodLastValue && wdv.personSeriesValue.periodLastValue.measurement) {
              const value = wdv.personSeriesValue.periodLastValue.measurement.value;
              if (value != null) {
                const unit = wdv.personSeriesValue.periodLastValue.measurement.unit;
                const groupInfo = this.getOrCreateGroup(wdv.widgetDataset.groupName, unit);
                graphDataItems.push({
                  groupId: groupInfo.id,
                  groupName: groupInfo.name,
                  name: wdv.widgetDataset.name ? wdv.widgetDataset.name : '',
                  unit: unit,
                  value: value,
                  widgetDatasetValue: wdv
                });

                this.increaseValueStatsByUnit(groupInfo, value);
              }
            }
            break;
          }
          case 'PERSON_PARAMETER': {
            const value = wdv.personParameterValue.value;
            if (value != null) {
              const unit = wdv.personParameterValue.unit;
              const groupInfo = this.getOrCreateGroup(wdv.widgetDataset.groupName, unit);
              graphDataItems.push({
                groupId: groupInfo.id,
                groupName: groupInfo.name,
                name: wdv.widgetDataset.name ? wdv.widgetDataset.name : '',
                unit: unit,
                value: value,
                widgetDatasetValue: wdv
              });
              this.increaseValueStatsByUnit(groupInfo, value);
            }
            break;
          }
          case 'Q11E_FIELD': {
            const fieldValueFrequency = {};
            for (const response of wdv.q11eFieldValue.responses) {
              let value = null;
              if (response.type === 'TEXT') {
                value = response.text;
              } else if (response.type === 'VALUE') {
                value = response.value;
              } else if (response.type === 'SELECT') {
                value = response.option;
              }
              if (value) {
                if (fieldValueFrequency[value] || fieldValueFrequency[value] === 0) {
                  fieldValueFrequency[value]++;
                } else {
                  fieldValueFrequency[value] = 1;
                }
              }
            }

            const unit = '#';
            const wd = wdv.widgetDataset;
            const groupInfo = this.getOrCreateGroup(wd.groupName, unit);
            for (const value of Object.keys(fieldValueFrequency)) {
              const fq = fieldValueFrequency[value];
              const name = (wd.name ? wd.name + ': ' : '') + value;

              graphDataItems.push({
                groupId: groupInfo.id,
                groupName: groupInfo.name,
                name: name,
                unit: unit,
                value: fq,
                widgetDatasetValue: wdv
              });

              this.increaseValueStatsByUnit(groupInfo, fq);
            }
            break;
          }
          case 'Q11E_RESPONSE_FIELD': {
            if (wdv.q11eResponseFieldValue.type === 'VALUE') {
              const value = wdv.q11eResponseFieldValue.value;
              if (value != null) {
                const unit = wdv.q11eResponseFieldValue.unit;
                const groupInfo = this.getOrCreateGroup(wdv.widgetDataset.groupName, unit);
                graphDataItems.push({
                  groupId: groupInfo.id,
                  groupName: groupInfo.name,
                  name: wdv.widgetDataset.name ? wdv.widgetDataset.name : '',
                  unit: unit,
                  value: value,
                  widgetDatasetValue: wdv
                });

                this.increaseValueStatsByUnit(groupInfo, value);
              }
            }

            break;
          }
        }
      }


      const groupData = new Map<string, number[]>();
      const labels = [];
      let idx = 0;
      for (let graphDataItem of graphDataItems) {
        let groupValues = groupData.get(graphDataItem.groupId);
        if (!groupValues) {
          groupValues = [];
          for (let j = 0; j < graphDataItems.length; j++) {
            groupValues.push(0);
          }
          groupData.set(graphDataItem.groupId, groupValues);
        }
        labels.push(graphDataItem.name);
        groupValues[idx] = graphDataItem.value;
        idx++;
      }

      for (const [key, value] of groupData) {
        hasData = true;
        const groupId = key;
        const groupInfo = this.groups.get(groupId);
        if (groupInfo == null) {
          throw new Error('Axis groupInfo key "' + groupId + '" not found!');
        }
        const yAx = groupInfo.yAx;

        const dataset = {
          data: value,
          label: groupInfo.yAxisName,
          yAxisID: yAx.id,
          fill: false,
          lineTension: 0,
          pointStyle: this.chartDataService.pointStyles[this.nextPointStyleIndex++],
          minBarLength: 5
        };
        this.chartDatasets.push(dataset);
      }

      for (const [i, v] of labels.entries()) {
        if (v.length > 32) {
          labels[i] = v.substr(0, 29) + '...';
        }
      }

      this.chartLabels = labels;

      if (type !== 'line') {
        this.chartXAxes[0].display = false;
      }
    }

    this.hasData = hasData;
    this.updateChart();

    this.viewReady.emit(true);
  }

  getOrCreateGroup(groupName: string, unit: string): any {
    const groupId = this.createGroupId(groupName, unit);
    let groupInfo = this.groups.get(groupId);
    if (groupInfo == null) {
      const yAxisName = unit ? groupName + ', ' + unit : groupName;

      groupInfo = {
        id: groupId,
        name: groupName,
        unit: unit,
        min: null,
        max: null,
        yAxisName: yAxisName,
        xAx: this.createXAxis(yAxisName),
        yAx: this.createYAxis(yAxisName)
      };
      this.groups.set(groupId, groupInfo);
    }

    return groupInfo;
  }

  increaseValueStatsByUnit(groupInfo: any, value: number): void {
    const id = groupInfo.yAx.id;

    if (!this.valueStatsByUnitAndYAxis.has(id)) {
      this.valueStatsByUnitAndYAxis.set(id, {
        count: 0,
        sum: 0,
        yAxisName: groupInfo.yAxisName
      });
    }

    this.valueStatsByUnitAndYAxis.get(id).count++;
    this.valueStatsByUnitAndYAxis.get(id).sum += value;
  }

  getOrCreateYAxisName(groupName: string, unit: string, name: string): any {
    let yAxisName;

    const groupId = this.createGroupId(groupName, unit);
    let groupInfo = this.groups.get(groupId);
    if (groupInfo == null) {
      if (groupName) {
        yAxisName = unit ? groupName + ', ' + unit : groupName;
      } else {
        yAxisName = name;
      }

      groupInfo = {
        id: groupId,
        name: groupName,
        unit: unit,
        min: null,
        max: null,
        yAxisName: yAxisName,
        yAx: null,
        xAx: null
      };
      this.groups.set(groupId, groupInfo);
    } else {
      yAxisName = groupInfo.yAxisName;
    }

    return yAxisName;
  }

  createChartDataset(groupId: string, name: string, mapping: any, scaleMin: number, scaleMax: number, stackId: string, color?: string): any {
    const groupInfo = this.groups.get(groupId);
    if (groupInfo == null) {
      throw new Error('Axis groupInfo key "' + groupId + '" not found!');
    }
    const xAx = groupInfo.xAx;
    const yAx = groupInfo.yAx;
    const xAxId = xAx?.id;
    const yAxId = yAx?.id;

    if (groupInfo.min === null || mapping.min < groupInfo.min) {
      groupInfo.min = mapping.min;
      yAx.ticks['suggestedMin'] = groupInfo.min * 2;
    }

    if (groupInfo.max === null || mapping.max > groupInfo.max) {
      groupInfo.max = mapping.max;
      yAx.ticks['suggestedMax'] = groupInfo.max * 2;
    }

    if (scaleMin != null && !isNaN(scaleMin)) {
      if (yAx.ticks['min'] == null || scaleMin < yAx.ticks['min']) {
        yAx.ticks['min'] = scaleMin;
      }
    }
    if (scaleMax != null && !isNaN(scaleMax)) {
      if (yAx.ticks['max'] == null || scaleMax > yAx.ticks['max']) {
        yAx.ticks['max'] = scaleMax;
      }
    }

    if (!isNaN(yAx.ticks.suggestedMin) && yAx.ticks.suggestedMin === yAx.ticks.suggestedMax) {
      if (yAx.ticks.suggestedMin > 0) {
        yAx.ticks.suggestedMin = 0;
      } else {
        yAx.ticks.suggestedMax = 0;
      }
    }

    const dataset: any = {
      mapping: mapping,
      data: mapping.data.length ? mapping.data : null,
      label: name,
      // type: wdv.widgetDataset.graphType ? mapping.data.length > 1 ? wdv.widgetDataset.graphType !== 'stack' ? wdv.widgetDataset.graphType : 'bar' : 'bar' : 'line',
      fill: false,
      yAxisID: yAxId,
      xAxisID: xAxId,
      lineTension: 0,
      pointStyle: this.chartDataService.pointStyles[this.nextPointStyleIndex++],
      stack: stackId,
      minBarLength: 5
    };
    if (color != null) {
      dataset.borderColor = color;
      dataset.backgroundColor = color;
    }
    this.chartDatasets.push(dataset);

    return dataset;
  }

  createXAxis(labelString: string): any {
    const xAxId = 'x-axis-ci-' + this.nextXAxisId++;

    const xAx = {
      id: xAxId,
      display: false,
      gridLines: {
        drawOnChartArea: false
      },
      offset: true,
      stacked: true
    };

    this.chartXAxes.push(xAx);

    return xAx;
  }

  createYAxis(labelString: string): any {
    const yAxId = 'y-axis-ci-' + this.nextYAxisId++;

    const yAx = {
      id: yAxId,
      scaleLabel: {
        display: 'auto',
        labelString: labelString ? labelString : '',
        labels: {
          show: true
        }
      },
      display: this.widget.showYAxes ? 'auto' : false,
      ticks: {},
      position: 'left',
      gridLines: {
        drawOnChartArea: false
      },
      offset: false,
      stacked: true
    };

    this.chartYAxes.push(yAx);

    return yAx;
  }

  updateChart() {
    if (!this.chartCanvas) {
      return;
    }

    if (this.chartDatasets.length) {
      if (!this.chart || true) {

        if (this.widget.chartColorScheme) {
          this.chartOptions.plugins.colorschemes.scheme = this.widget.chartColorScheme;
        }
        this.chartOptions.legend.display = this.showLegendOverride != null ? this.showLegendOverride : this.widget.showLegend;

        const type = this.chartDataService.chartMapping[this.widget.chartType];
        const options = {
          type: type,
          data: {
            labels: this.chartLabels,
            datasets: this.chartDatasets
          },
          plugins: [],
          options: this.chartOptions
        };

        if (this.widget.showDataLabels) {
          options.options.plugins['datalabels'] = {
            anchor: 'end',
            align: 'end',
            display: 'auto',
            formatter: (value, context) => {
              if (isNaN(value)) {
                return '';
              } else {
                return this.decimalPipe.transform(value, '1.0-2');
              }
            }
          };
          options.plugins.push(ChartDataLabels);
        }

        const annotations = [];
        if (this.widget.showAverageLine) {
          for (const [yAxisID, stats] of this.valueStatsByUnitAndYAxis) {
            if (stats.count < 2) {
              continue;
            }
            const avg = (stats.sum / stats.count);

            annotations.push({
              type: 'line',
              mode: 'horizontal',
              scaleID: yAxisID,
              value: avg,
              borderColor: 'rgb(75, 192, 192)',
              borderWidth: 2,
              label: {
                enabled: true,
                content: 'avg ' + String((Math.round(avg * 100) / 100)).replace('.', ',') + ' (' + stats.yAxisName + ')' ,
                fontStyle: 'none',
                position: 'left',
                fontColor: 'black',
                backgroundColor: 'rgb(0, 0, 0, 0)',
                yAdjust: -10
              }
            });
          }
        }

        this.chartOptions['annotation'] = {
          annotations:annotations
        }

        this.chart = new Chart(this.chartCanvas.nativeElement, options);
      }

      for (const yAx of this.chartYAxes) {
        yAx.display = this.showLegendOverride != null ? this.showLegendOverride : this.widget.showYAxes ? 'auto' : false;
      }

      this.chart.options.legend.display = this.showLegendOverride != null ? this.showLegendOverride : this.widget.showLegend;
      const scaleMerge = Chart.helpers.scaleMerge(Chart.defaults.scale, {xAxes: this.chartXAxes, yAxes: this.chartYAxes});
      this.chart.options.scales.xAxes = scaleMerge.xAxes;
      this.chart.options.scales.yAxes = scaleMerge.yAxes;

      this.chart.update();

      this.viewReady.emit(true);
    } else {
      if (this.chart) {
        this.chart.destroy();
        this.chart = null;
      }
      this.viewReady.emit(false);
    }
  }

  public createGroupId(groupName: string, unit: string): string {
    if (groupName) {
      return 'group-' + groupName + '-' + unit;
    } else {
      return 'nogroup-' + this.nextNogroupId++;
    }
  }


  private updateWidgetOnResize(size): void {
    const sizeLtBpValue = size != null && size < SCREEN_SIZE.MD;
    if (sizeLtBpValue) {
      this.showLegendOverride = false;
      this.showAxesOverride = false;
    } else {
      this.showLegendOverride = null;
      this.showAxesOverride = null;
    }
    if (this.previousSizeLtBpValue != null && this.previousSizeLtBpValue !== sizeLtBpValue) {
      this.updateChart();
    }
    this.previousSizeLtBpValue = sizeLtBpValue;
  }

}
