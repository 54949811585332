import {PersonSeriesGroupRef} from './series';
import {Person} from './person';

export class LocationResponse {
  content: Location[];
}

export class Location {
  groupingTag: string;
  group: PersonSeriesGroupRef;
  groupOwnerType: string;
  locationType: string;
  address: string;
  apartment: string;
  room: string;
  adsOid: string;
  lat: number;
  lng: number;
  uuid: string;
  q11eType: string;
  q11eId: number;
  q11eName: string;
  personSeriesCount: number;
  sharedPersonSeriesCount: number;
  ownerPersonSeriesCount: number;
  personParameterCount: number;
  locationName: string;
  parent: Location;

  static ofGroupingTag(groupingTag: string): Location {
    return new Location({
      groupingTag: groupingTag
    });
  }

  static ofGroup(group: PersonSeriesGroupRef): Location {
    return new Location({
      group: group
    });
  }

  static ofAddress(group: PersonSeriesGroupRef, groupingTag: string, address: string, apartment: string, room: string): Location {
    return new Location({
      group: group,
      locationType: 'ADDRESS',
      groupingTag: groupingTag,
      address: address,
      apartment: apartment,
      room: room
    });
  }

  static ofCoords(group: PersonSeriesGroupRef, groupingTag: string, lat: number, lng: number): Location {
    return new Location({
      group: group,
      locationType: 'COORDS',
      groupingTag: groupingTag,
      lat: lat,
      lng: lng
    });
  }

  static ofUuid(group: PersonSeriesGroupRef, groupingTag: string, uuid: string): Location {
    return new Location({
      group: group,
      locationType: 'UUID',
      groupingTag: groupingTag,
      uuid: uuid
    });
  }

  static ofQ11e(group: PersonSeriesGroupRef, q11e?: any): Location {
    return new Location({
      group: group,
      locationType: 'Q11E',
      q11eType: q11e?.type,
      q11eId: q11e?.id,
      q11eName: q11e?.name
    });
  }

  public constructor(l?: any) {
    if(l?.groupingTag != null) {
      this.groupingTag = l.groupingTag;
    } else if (l?.groups && l?.groups.length > 0) {
      this.group = l.groups[0];
    } else if(l?.groupingTags && l?.groupingTags.length > 0){
      this.groupingTag = l.groupingTags[0];
    }

    if (!this.group && l?.group) {
      this.group = l?.group;
    }
    this.groupOwnerType = l?.groupOwnerType;
    this.locationType = l?.locationType;
    this.address = l?.address;
    this.apartment = l?.apartment;
    this.room = l?.room;
    this.lat = l?.lat;
    this.lng = l?.lng;
    this.uuid = l?.uuid;
    this.q11eType = l?.q11eType;
    this.q11eId = l?.q11eId;
    this.q11eName = l?.q11eName;
    this.personSeriesCount = l?.personSeriesCount;
    this.ownerPersonSeriesCount = l?.ownerPersonSeriesCount;
    this.sharedPersonSeriesCount = l?.sharedPersonSeriesCount;
    this.personParameterCount = l?.personParameterCount;
    this.parent = null;

    if (this.locationType) {
      if (this.locationType === 'ADDRESS') {
        if (this.room && (this.address || this.apartment)) {
          this.parent = Location.ofAddress(this.group, this.groupingTag, this.address, this.apartment, null);
        } else if (this.apartment && this.address) {
          this.parent = Location.ofAddress(this.group, this.groupingTag, this.address, null, null);
        } else if (this.groupingTag) {
          this.parent = Location.ofGroupingTag(this.groupingTag);
        } else if (this.group) {
          this.parent = Location.ofGroup(this.group);
        }
      } else if (this.locationType === 'COORDS') {
        if (this.group) {
          this.parent = Location.ofGroup(this.group);
        } else if (this.groupingTag) {
          this.parent = Location.ofGroupingTag(this.groupingTag);
        }
      } else if (this.locationType === 'UUID') {
        if (this.group) {
          this.parent = Location.ofGroup(this.group);
        } else if (this.groupingTag) {
          this.parent = Location.ofGroupingTag(this.groupingTag);
        }
      } else if (this.locationType === 'Q11E') {
          if (this.group) {
            this.parent = Location.ofGroup(this.group);
          }
      }/* else if (this.locationType === 'Q11E') {
        if (this.q11eId) {
          this.parent = Location.ofQ11e({
            type: this.q11eType
          });
        }
      }*/
    }
  }

  public static locationName(l: Location): void {
    if (l.group) {
      l.locationName = l.group.name;
    } else if (l.groupingTag) {
      l.locationName = l.groupingTag;
    } else {
      if (l.locationType === 'ADDRESS') {
        l.locationName = l.address;
      } else if (l.locationType === 'COORDS') {
        l.locationName = l.lat + ' - ' + l.lng;
      } else if (l.locationType === 'UUID') {
        l.locationName = l.uuid;
      }/* else if (l.locationType === 'Q11E') {
        if (l.q11eId) {
          l.locationName = l.q11eName;
        } else {
          switch (l.q11eType) {
            case 'ENERGY_MANAGER':
              l.locationName = $localize`Energy manager`;
              break;
            case 'FORMULA_SOURCE_TEMPLATE':
              l.locationName = $localize`Formulas`;
              break;
            default:
              l.locationName = $localize`Questionnaires`;
              break;
          }
        }
      }*/
    }
  }

  public getPath(): Location[] {
    const path = [];
    let l: Location = this;
    while (l) {
      path.push(l);
      l = l.parent;
    }
    return path.reverse();
  }

  public getName(): string {
    return this.getPath().map(e => e.getLocalName()).join(', ');
  }

  public getLocalName(): string {
    switch (this.locationType) {
      case 'ADDRESS': {
        if (this.room) {
          return this.room;
        }
        if (this.apartment) {
          return this.apartment;
        }
        if (this.address) {
          return this.address;
        }
        return '';
      }
      case 'COORDS':
        return this.lat + '-' + this.lng;
      case 'UUID':
        return this.uuid;
      case 'Q11E':
        return this.q11eName;
      /*case 'Q11E':
        if (this.q11eId) {
          return this.q11eName;
        } else {
          switch (this.q11eType) {
            case 'ENERGY_MANAGER':
              return $localize`Energy manager`;
            case 'FORMULA_SOURCE_TEMPLATE':
              return $localize`Formulas`;
            default:
              return $localize`Questionnaires`;
          }
        }*/
      default:
        if (this.group && this.group.id) {
          return this.group.name;
        }

        return $localize`No group`;

        // if (!this.groupingTag) {
        //   return $localize`No group`;
        // }
        //
        // if(this.groupingTag == 'QUESTIONNAIRES'){
        //   return $localize`Questionnaires`;
        // } else if(this.groupingTag == 'FORMULAS'){
        //   return $localize`Formulas`;
        // }
        // return this.groupingTag;
    }
  }
}

export class GroupLocation extends Location {
  type: string;
  createdByPerson: Person;

  constructor(l?: any) {
    super(l);
    if (l?.type) {
      this.type = l.type;
    }
    this.createdByPerson = l.createdByPerson;
  }
}


