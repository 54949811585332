import {Component, EventEmitter, Inject, OnDestroy, OnInit, Output} from '@angular/core';
import {ReplaySubject} from 'rxjs/internal/ReplaySubject';
import {
  AuthService,
  PreventCloseModalService,
  QuestionnaireParticipant,
  QuestionnaireParticipationPage,
  WidgetDataService
} from '@smartencity/core';
import {HttpClient} from '@angular/common/http';
import {MyDataConfig} from '../../../mydata-config.model';
import {BsModalService} from 'ngx-bootstrap/modal';
import {take, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs/internal/Subject';
import {ResponseModalComponent} from '../../questionnaires/response-modal/response-modal.component';
import {SMARTENCITY_MYDATA_CONFIG} from '../../../injection-tokens';
import {WidgetApiDataService} from '../widget-api-data.service';
import {DashboardService} from '../dashboard.service';
import {WidgetFromQuestionnaireResponseBulk} from '../widget';
import {DashboardType} from '../dashboard';

@Component({
  selector: 'mydata-energy-manager-incoming-questionnaires',
  templateUrl: './incoming-questionnaires.component.html',
  styleUrls: ['./incoming-questionnaires.component.css'],
  providers: [
    { provide: WidgetDataService, useClass: WidgetApiDataService },
  ]
})
export class IncomingQuestionnairesComponent implements OnInit, OnDestroy {
  private ngDestroy = new Subject<void>();

  public rows: QuestionnaireParticipant[] = [];

  @Output('hide')
  hideEmitter: EventEmitter<void> = new EventEmitter<void>();

  @Output('saved')
  savedEmitter: EventEmitter<any> = new EventEmitter<any>();

  private participationsSource = new ReplaySubject<QuestionnaireParticipant[]>(1);
  participations$ = this.participationsSource.asObservable();

  constructor(
    private http: HttpClient,
    @Inject(SMARTENCITY_MYDATA_CONFIG) private config: MyDataConfig,
    private authService: AuthService,
    private modalService: BsModalService,
    private preventCloseModalService: PreventCloseModalService,
    private dashboardService: DashboardService
  ) {
    this.participations$.pipe(takeUntil(this.ngDestroy)).subscribe((participations) => {
      this.rows = participations;
    });

    this.authService.currentMandate$.pipe(takeUntil(this.ngDestroy)).subscribe(() => {
      this.loadParticipations();
    });
  }

  ngOnDestroy(): void {
    this.participationsSource.complete();
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  ngOnInit(): void {
  }

  close(): void {
    this.hideEmitter.emit();
  }

  public answer(participation: QuestionnaireParticipant) {
    const modalRef = this.preventCloseModalService.show(ResponseModalComponent, {
      class: 'modal-xl',
      initialState: {
        questionnaireId: participation.questionnaire.id,
        responseId: participation.responses.length > 0 ? participation.responses[0].id : null
      }
    });

    modalRef.content.savedEmitter.pipe(takeUntil(this.ngDestroy), take(1)).subscribe((saved) => {
      this.savedEmitter.emit(saved);
      this.reload();
    });
  }

  public reload() {
    this.loadParticipations();
  }

  public loadParticipations() {
    this.http.get(this.config.apiUrl + '/questionnaire-participation', {
      params: {
        q11eType: 'ENERGY_MANAGER'
      }
    }).subscribe(
      (data: QuestionnaireParticipationPage) => {
        this.participationsSource.next(data.content);
      }, err => {
        return err;
      }
    );
  }

  public addWidget(row: QuestionnaireParticipant): void {
    if (!row.widgetTemplates || !row.responses.length) {
      return;
    }

    const latestResponse = row.responses[0];

    const request = new WidgetFromQuestionnaireResponseBulk();
    request.templateIds = row.widgetTemplates.map(template => {
      return template.id
    });
    request.responseId = latestResponse.id;
    request.dashboardType = DashboardType.ENERGY_MANAGER;
    this.dashboardService.addFromQuestionnaireResponseBulk(request).subscribe(() => {
      // added
    });


    // if (row.widgetTemplates.length === 1) {
    //   this.dashboardService.addFromQuestionnaireResponse(row.widgetTemplates[0].id, latestResponse.id);
    // } else {
    //   const modalRef = this.modalService.show(WidgetTemplateSelectModalComponent, {
    //     ignoreBackdropClick: true,
    //     class: 'modal-lg',
    //     backdrop: 'static',
    //     initialState: {
    //       widgetTemplates: row.widgetTemplates
    //     }
    //   });
    //   modalRef.content.result.pipe(takeUntil(this.ngDestroy)).subscribe((selectedWidgetTemplateId: number) => {
    //     this.dashboardService.addFromQuestionnaireResponse(selectedWidgetTemplateId, latestResponse.id);
    //   });
    // }

  }

}
