import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {DashboardWidgetTemplate, WidgetTemplate} from '../components/dashboard/widget';
import {SMARTENCITY_CORE_CONFIG} from '../../../../core/src/lib/injection-tokens';
import {CoreConfig} from '../../../../core/src/lib/core-config.model';

@Injectable()
export class WidgetTemplateApiService {

  constructor(@Inject(SMARTENCITY_CORE_CONFIG) private config: CoreConfig,
              private http: HttpClient) { }

  public getWidgetTemplate(id: number): Observable<WidgetTemplate> {

    return this.http.get<WidgetTemplate>(this.config.apiUrl + '/widget-template/' + id);
  }

  public getWidgetTemplatesByQ11eId(q11eId: number): Observable<WidgetTemplate[]> {

    return this.http.get<WidgetTemplate[]>(this.config.apiUrl + '/widget-template/questionnaire/' + q11eId);
  }

  public createWidgetTemplate(q11eId: number, data: any): Observable<any> {

    return this.http.post<any>(this.config.apiUrl + '/widget-template/questionnaire/' + q11eId, data);
  }

  public updateWidgetTemplate(id: number, data: any): Observable<any> {

    return this.http.put<any>(this.config.apiUrl + '/widget-template/' + id, data);
  }

  public removeWidgetTemplate(id: number): Observable<void> {

    return this.http.delete<void>(this.config.apiUrl + '/widget-template/' + id);
  }

  public dashboardSearch(q11eType: string): Observable<DashboardWidgetTemplate[]>  {
    return this.http.get<DashboardWidgetTemplate[]>(this.config.apiUrl + '/widget-template/dashboard-search', {
      params: {
        q11eType: q11eType
      }
    });
  }

}
