import { Component, Input, OnDestroy, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import {
  ConfirmModalComponent,
  PendingPersonSeriesCancellation, PersonSeriesApiService,
  PersonSeriesLocationLabel,
  UserService
} from '@smartencity/core';
import { Subject } from 'rxjs';
import { NotificationService } from '../notification.service';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'mydata-cancellation-row',
  templateUrl: './cancellation-row.component.html',
  encapsulation: ViewEncapsulation.None
})
export class CancellationRowComponent implements OnInit, OnDestroy {

  private ngDestroy = new Subject<void>();
  public progress: string = null;

  @Input()
  public parentProgress: string = null;

  @Input()
  public item: PendingPersonSeriesCancellation;

  public assignerName;
  public datapointName;
  public location;
  public comment;

  constructor(
    public userService: UserService,
    public notificationService: NotificationService,
    private toastr: ToastrService,
    private personSeriesLocationLabel: PersonSeriesLocationLabel,
    public personSeriesService: PersonSeriesApiService,
    private modalService: BsModalService
  ) { }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.item) {
      let assignerPerson = this.item.personSeries.createdByPerson;
      let sourceAssignedPerson = this.item.personSeries.sourceAssignedPerson;
      let assignerName = assignerPerson.displayName;
      if (sourceAssignedPerson && (assignerPerson.registrationNumber !== sourceAssignedPerson.registrationNumber
        || assignerPerson.countryCode !== sourceAssignedPerson.countryCode)) {
        assignerName = `${this.item.personSeries.sourceAssignedPerson.displayName} (${assignerPerson.displayName})`;
      }
      this.assignerName = assignerName;
      this.datapointName = this.item.personSeries.name;
      this.location = this.personSeriesLocationLabel.transform(this.item.personSeries);
      this.comment = this.item.comment;
    }
  }

  acceptCancellation(item: PendingPersonSeriesCancellation) {
    this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: $localize`Are you sure?`,
        description: $localize`You will not receive data of this device anymore.`,
        callback: (result: boolean) => {
          if (result) {
            this.progress = 'accept';
            this.submitCancellationStatus(item, 'ACCEPTED');
          }
        }
      }
    });
  }

  declineCancellation(item: PendingPersonSeriesCancellation) {
    this.modalService.show(ConfirmModalComponent, {
      initialState: {
        description: $localize`Are you sure you want to decline cancellation?`,
        callback: (result: boolean) => {
          if (result) {
            this.progress = 'decline';
            this.submitCancellationStatus(item, 'REJECTED');
          }
        }
      }
    });
  }

  private submitCancellationStatus(item: PendingPersonSeriesCancellation, status: string) {
    this.personSeriesService.updateCancellationStatus(item, status).subscribe(
      (data: any) => {
        this.stateChanged(item);
      }, (error) => {
        this.progress = null;
        console.error(error);
        this.toastr.error($localize`Error submitting cancellation confirmation`);
      }, () => {
        this.progress = null;
      }
    );
  }

  private stateChanged(item: PendingPersonSeriesCancellation) {
    this.notificationService.loadPendingCancellationRequests();
    this.userService.confirmationListChanged();
  }
}
