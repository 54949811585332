<div class="modal-header">
  <h5 class="modal-title w-100" i18n>Access grants to this data</h5>
  <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
    <i class="fal fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <div [formGroup]="consentForm">
    <div class="row">
      <div class="col-12">
        <div *ngIf="consentForm.get('person').invalid && showValidation" class="alert alert-danger">
          <div *ngIf="consentForm.get('person').errors['required']">
            <span i18n>Personal identification or registration code</span>&nbsp;<span i18n>is required</span>
          </div>
          <div *ngIf="consentForm.get('person').errors['personRegistrationNumber']">
            <span i18n>Personal identification or registration code</span>&nbsp;<span i18n>is invalid</span>
          </div>
        </div>

       <div class="row">
         <div class="col-md-6 col-sm-12">
           <div class="form-group">
              <ng-select
                class="custom"
                [clearable]="true"
                [searchable]="true"
                placeholder="Personal identification or registration code"
                i18n-placeholder
                appendTo=".modal"
                formControlName="person"
                [typeahead]="personTypeahead$"
                [items]="personItems$ | async"
                [addTag]="createPerson"
                [selectOnTab]="true"
                addTagText="New person with registration number"
                i18n-addTagText
              >
                <ng-template ng-label-tmp let-item="item">
                  <span>{{(item.displayName ? '' + item.displayName + ' ' : '') + '(' + (item | regNo) + ')'}}</span>
                </ng-template>
                <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
                  <span>{{(item.displayName ? '' + item.displayName + ' ' : '') + '(' + (item | regNo) + ')'}}</span>
                </ng-template>
              </ng-select>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row my-2 align-items-center">
      <ng-container>
        <div class="col-12 col-lg-5" *ngIf="lastDataOwnerPeriod">
          <div class="checkbox">
            <input id="last-data-owner-start" type="checkbox" formControlName="startFromLastDataOwnerPeriod" />
            <label class="mb-0" [attr.for]="'last-data-owner-start'" i18n>From the last data owner period start</label>
          </div>

        </div>
        <div class="col-12 col-lg-4 mt-3 mt-lg-0" *ngIf="!consentForm.get('startFromLastDataOwnerPeriod').value || !isStartOfTime">
          <datepicker-wrapper [id]="'last-data-owner-start'"
                              [control]="consentForm.get('customStartAt')" [min]="minDate"></datepicker-wrapper>
        </div>


      </ng-container>
      <div class="col-auto d-flex align-items-center mt-2 mt-md-0">
        <button class="btn btn-sm btn-primary" (click)="addConsent()">
          <i class="fal fa-share"></i>&nbsp;<ng-container i18n>Share</ng-container>
        </button>
      </div>
    </div>
    <hr/>

  <table *ngIf="consents && consents.length > 0" class="table table-responsive-md btn-table modal-table">
    <thead>
    <tr>
      <th i18n>Name</th>
      <th i18n>Consent type</th>
      <th i18n>Owner reg no</th>
      <th i18n>Validity period</th>
      <th i18n>Status</th>
      <th></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let consent of consents">
      <td>{{consent.person.displayName}}</td>
      <td>{{'CONSENT_TYPE_' + consent.ownerType|label}}</td>
      <td>{{consent.person | regNo}}</td>
      <td>{{consent.startAt | date:'dd.MM.yyyy'}} -
        <ng-container *ngIf="consent.endAt">{{consent.endAt | date:'dd.MM.yyyy'}}</ng-container>
        <ng-container *ngIf="!consent.endAt">...</ng-container>
      </td>
      <td>
        <span [ngClass]="{'badge-warning': consent.confirmationStatus == 'PENDING', 'badge-success': consent.confirmationStatus == 'ACCEPTED', 'badge-danger': consent.confirmationStatus == 'CANCELLED'}" class="w-100 d-flex justify-content-center p-2 badge rounded-pill" i18n>{{consent.confirmationStatus}}</span>
      </td>
      <td>
        <button *ngIf="(consent.confirmationStatus == 'PENDING' || consent.confirmationStatus == 'ACCEPTED') && !consent.endAt &&
        (consent.ownerType == 'CONSENT' || consent.ownerType == 'GROUP_CONSENT')" (click)="endConsent(consent)" type="button" class="btn btn-sm btn-link">
          <i class="fa fa-times"></i>&nbsp;<ng-container i18n>End consent</ng-container>
        </button>
      </td>
    </tr>
    </tbody>
  </table>
  <div *ngIf="consents && consents.length == 0" class="alert alert-info" role="alert" i18n>Data is not shared</div>
</div>
</div>
