import {AfterViewInit, Component, ElementRef, HostListener} from '@angular/core';
import {SCREEN_SIZE} from '../../models/resize';
import {ResizeService} from '../../services/resize-service';

@Component({
  selector: 'core-screen-size-detector',
  templateUrl: './screen-size-detector.component.html'
})
export class ScreenSizeDetectorComponent implements AfterViewInit {
  prefix = 'is-';
  sizes = [
    {
      id: SCREEN_SIZE.XS, name: 'xs', css: `d-block d-sm-none`
    },
    {
      id: SCREEN_SIZE.SM, name: 'sm', css: `d-none d-sm-block d-md-none`
    },
    {
      id: SCREEN_SIZE.MD, name: 'md', css: `d-none d-md-block d-lg-none`
    },
    {
      id: SCREEN_SIZE.LG, name: 'lg', css: `d-none d-lg-block d-xl-none`
    },
    {
      id: SCREEN_SIZE.XL, name: 'xl', css: `d-none d-xl-block`
    },
  ];

  currentSize: SCREEN_SIZE;

  constructor(private elementRef: ElementRef, private resizeSvc: ResizeService) { }

  @HostListener('window:resize', [])
  private onResize() {
    this.detectScreenSize();
  }

  ngAfterViewInit() {
    this.detectScreenSize();
  }

  private detectScreenSize() {
    // const currentSize = this.sizes.find(x => {
    //   // get the HTML element
    //   const el = this.elementRef.nativeElement.querySelector(`.${this.prefix}${x.id}`);

    //   // check its display property value
    //   return window.getComputedStyle(el).display !== 'none';
    // });

    // this.resizeSvc.onResize(currentSize.id);

    if (window.innerWidth < 576) {
      this.currentSize = SCREEN_SIZE.XS;
    } else if (window.innerWidth >= 576 && window.innerWidth < 768) {
      this.currentSize = SCREEN_SIZE.SM;
    } else if (window.innerWidth >= 768 && window.innerWidth < 992) {
      this.currentSize = SCREEN_SIZE.MD;
    } else if (window.innerWidth >= 992 && window.innerWidth < 1200) {
      this.currentSize = SCREEN_SIZE.LG;
    } else if (window.innerWidth >= 1200 && window.innerWidth < 1600) {
      this.currentSize = SCREEN_SIZE.XL;
    } else {
      this.currentSize = SCREEN_SIZE.XXL;
    }

    this.resizeSvc.onResize(this.currentSize);
  }
}
