import { ArcGisRestClient } from "./arc-gis-rest-client";
import { register } from "ol/proj/proj4";
import proj4 from "proj4";
import { get as getProjection, transform } from "ol/proj";
import TileGrid from "ol/tilegrid/TileGrid";
import TileLayer from "ol/layer/WebGLTile";
import XYZ from "ol/source/XYZ";
import OlMap from "ol/Map";
import View from "ol/View";

import { forkJoin, Observable, Subject, ReplaySubject } from "rxjs";
import {map, takeUntil} from 'rxjs/operators';
import { ListenerFunction } from "ol/events";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import GeoJSON from "ol/format/GeoJSON";
import { bbox } from "ol/loadingstrategy";
import Style, { StyleFunction } from "ol/style/Style";
import Overlay from "ol/Overlay";
import Feature, { FeatureLike } from "ol/Feature";
import Layer from "ol/layer/Layer";
import { Geometry } from 'ol/geom';

const EPSG_3301: string = "EPSG:3301";
const EPSG_4326: string = "EPSG:4326";

proj4.defs(
  EPSG_3301,
  "+proj=lcc +lat_1=59.33333333333334 +lat_2=58 +lat_0=57.51755393055556 +lon_0=24 +x_0=500000 +y_0=6375000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs"
);
register(proj4);

type LayerFilterFn = (layerId) => boolean;

type LayerStyleFn = (layerUrl: string, layerId: number) => Promise<Function>;

type MapLoadedFn = (map: OlMap) => void;

export type FeatureSelectEvent = {
  selected: FeatureSelect[];
  deselected: FeatureSelect[];
};

export enum LayerProperties {
  LAYER_NAME = 'sc_layerName',
  LAYER_ID = 'sc_layerId'
}

export type FeatureSelect = { feature: Feature; layer: Layer };

export class OlMapWrapper {

  private finalize$ = new Subject<void>();

  private map: OlMap;

  private mapServerInfo: MapServerInfo;

  private client: ArcGisRestClient;

  private opts: OlMapWrapperOptions;

  private mapListeners: Map<string, ListenerFunction> = new Map<string, ListenerFunction>();

  private featureLayerListeners: Map<string, ListenerFunction> = new Map<string, ListenerFunction>();

  private featureLayersMapping: Map<string, VectorLayer<VectorSource>> =
    new Map<string, VectorLayer<VectorSource>>();

  private featureLayerInfoMapping: Map<string, FeatureLayerInfo> = new Map<string, FeatureLayerInfo>();

  private mapLoadedSource = new ReplaySubject<boolean>(1);
  public mapLoaded$ = this.mapLoadedSource.asObservable().pipe(takeUntil(this.finalize$));

  private featureSelectSource: Subject<FeatureSelectEvent> = new Subject<FeatureSelectEvent>();
  public featureSelect$ = this.featureSelectSource.asObservable();

  private zIndex = 1000;

  private listeners: Map<string, ListenerFunction> = new Map<string, ListenerFunction>();

  private selected: FeatureSelect[] = [];
  private deselected: FeatureSelect[] = [];

  constructor(client: ArcGisRestClient, opts?: OlMapWrapperOptions) {
    this.client = client;
    this.opts = opts ? opts : new OlMapWrapperOptions();
    this.loadMap();
  }

  public getMapServerInfo(): MapServerInfo {
    return this.mapServerInfo;
  }

  public getMap(): OlMap {
    return this.map;
  }

  public getFeatureLayersMapping(): Map<string, VectorLayer<VectorSource>> {
    return this.featureLayersMapping;
  }

  public onRemove(): void {
    this.finalize$.next();
  }

  public clearFeatureStyles(): void {
    this.map.getAllLayers().forEach((layer) => {
      if (layer instanceof VectorLayer) {
        let source: VectorSource = layer.getSource();
        //source.clear();
        source.getFeatures().forEach((feature: Feature<Geometry>) => {
          feature.setStyle(null);
        });
      }
    });

    this.selected = [];
    this.deselected = [];
  }

  private loadMap() {

    this.client.getMapServerInfo(this.opts.baseMapServerUrl)
      .subscribe((mapServerInfo: MapServerInfo) => {
        this.mapServerInfo = mapServerInfo;

        let extent: number[] = this.getExtent(mapServerInfo.fullExtent);
        let tileInfo: TileInfo = mapServerInfo.tileInfo;
        let proj = getProjection(EPSG_3301);
        proj.setExtent(extent);

        const tilGrid = new TileGrid({
          extent: extent,
          origin: [
            tileInfo.origin.x,
            tileInfo.origin.y, // -34999000, 4.5894099999999985E7
          ],
          resolutions: tileInfo.lods.map((t) => t.resolution),
        });

        const baseLayer = new TileLayer({
          cacheSize: 128,
          className: "baseLayer",
          source: new XYZ({
            crossOrigin: "anonymous",
            url: this.opts.baseMapServerUrl + "/tile/{z}/{y}/{x}",
            projection: proj,
            tileGrid: tilGrid,
            tileSize: 256,
          }),
        });

        this.map = new OlMap({
          target: this.opts.target,
          layers: [baseLayer],
          pixelRatio: 1,
          view: new View({
            center: transform(this.opts.center, EPSG_4326, EPSG_3301),
            extent: extent,
            projection: proj,
            zoom: this.opts.zoom,
          }),
        });

        this.mapListeners.forEach((value: ListenerFunction, event: any) => {
          this.map.on(event, value);
        });

        this.map.on("singleclick", (e) => {
          let currentlySelected: FeatureSelect[] = Array.from(this.selected);
          this.selected = [];
          this.deselected = [];
          this.map.forEachFeatureAtPixel(
            e.pixel,
            (f: Feature, layer: Layer) => {
              this.selected.push({
                feature: f,
                layer: layer,
              });
            }
          );

          for (let current of currentlySelected) {
            const curIndex = this.selected.findIndex(
              (c: FeatureSelect) => {

                let key = c.feature.get(LayerProperties.LAYER_NAME) + c.feature.get(LayerProperties.LAYER_ID);
                let featureLayerInfo = this.featureLayerInfoMapping.get(key);
                let objectIdField = null;

                if (featureLayerInfo) {
                  objectIdField = featureLayerInfo.objectIdField;
                }

                if (!objectIdField) {
                  objectIdField = 'tar_id';
                }

                return c.feature.get(objectIdField) == current.feature.get(objectIdField);
              }
            );
            if (curIndex == -1) {
              this.deselected.push(current);
            }
          }
          this.featureSelectSource.next({
            selected: this.selected,
            deselected: this.deselected,
          });
        });

        this.map.on('pointermove', function (e) {
          var hit = this.forEachFeatureAtPixel(e.pixel, function (feature, layer) {
            return true;
          });
          if (hit) {
            this.getTargetElement().style.cursor = 'pointer';
          } else {
            this.getTargetElement().style.cursor = '';
          }
        });

        this.mapLoadedSource.next(true);
      });
  }

  public getLegendInfo(): Observable<LegendLayerInfo[]> {
    return forkJoin(
      this.opts.serviceUrls.map((serverUrl) =>
        this.client.getLegend(serverUrl + "/MapServer")
      )
    ).pipe(
      map((legendInfos: LegendInfo[]) => {
        let result: LegendLayerInfo[] = [];
        for (let legendInfo of legendInfos) {
          for (let layer of legendInfo.layers) {
            if (!this.filterLayer(layer.layerId)) {
              continue;
            }
            result.push(layer);
          }
        }

        return result;
      })
    );
  }

  public addFeatureServer(
    serviceUrl: string,
    opts: FeatureServerOptions
  ): void {
    const featureServerUrl = serviceUrl + "/FeatureServer";

    const layersMap = new Map<number, any>();
    for (let layer of opts.layers) {
      layersMap.set(layer.layerId, {
        layer: layer,
        getFeatureStyle: layer.getFeatureStyle,
        getHighLightStyle: layer.getHighLightStyle,
      });
    }

    this.client
      .getMapServerInfo(featureServerUrl)
      .subscribe((mapServerInfo: MapServerInfo) => {
        let idx = 0;
        for (let layer of mapServerInfo.layers) {
          if (!layersMap.has(layer.id)) {
            continue;
          }

          const zIndex = opts.zIndexOffset ? opts.zIndexOffset + idx : null;

          idx++;

          let featureLayerInfo = layersMap.get(layer.id);

          this.client
            .getFeatureLayerInfo(featureServerUrl, layer.id)
            .subscribe((layerInfo: FeatureLayerInfo) => {
              this.addFeatureLayer(
                featureLayerInfo.layer.name,
                featureServerUrl,
                layerInfo,
                {
                  zIndex: zIndex,
                  getFeatureStyle: featureLayerInfo.getFeatureStyle,
                  getHighlightStyle: featureLayerInfo.getHighLightStyle,
                }
              );
            });
        }
      });
  }

  public setVisibility(name: string, visible: boolean): void {
    if (this.featureLayersMapping.has(name)) {
      this.featureLayersMapping.get(name).setVisible(visible);
    }
  }

  private addFeatureLayer(
    name: string,
    featureServerUrl: string,
    layer: FeatureLayerInfo,
    opts?: FeatureLayerOptions
  ): void {

    let key = name + layer.id;

    if (!this.featureLayerInfoMapping.has(key)) {
      this.featureLayerInfoMapping.set(key, layer);
    }

    const vectorSource = new VectorSource({
      format: new GeoJSON(),
      loader: (extent, resolution, projection, success, failure) => {
        let proj = projection.getCode();
        let url =
          featureServerUrl +
          "/" +
          layer.id +
          `/query?where=${encodeURIComponent('1=1')}&f=geojson&outFields=*&bbox=` +
          extent.join(",");
        let xhr = new XMLHttpRequest();
        xhr.open("GET", url);
        let onError = function () {
          vectorSource.removeLoadedExtent(extent);
          failure();
        };
        xhr.onerror = onError;
        xhr.onload = () => {
          if (xhr.status == 200) {
            vectorSource.clear();
            const json = JSON.parse(xhr.responseText);
            if (json.error) {
              success([]);
              return;
            }

            let features = (<GeoJSON>vectorSource.getFormat()).readFeatures(
              json,
              {
                featureProjection: proj,
              }
            );

            for (let feature of features) {
              if (
                opts.getHighlightStyle &&
                this.selected.findIndex((c) => c.feature.get(layer.objectIdField) == feature.get(layer.objectIdField)) > -1
              ) {
                feature.setStyle(opts.getHighlightStyle);
              }

              feature.set(LayerProperties.LAYER_ID, layer.id, true);
              feature.set(LayerProperties.LAYER_NAME, name, true);
            }

            vectorSource.addFeatures(features);

            success(features);
          } else {
            onError();
          }
        };
        xhr.send();
      },
      // url: 'https://gis.tallinn.ee/arcgis/rest/services/Andmed_Tallinn/FeatureServer/0/query',
      // url: ((extent, resolution, projection): string => {
      //   return this.featuresServerUrl + '/' + layer.id  + '/query?f=geojson&bbox=' + extent.join(",");
      // }),
      strategy: bbox,
    });

    if (this.featureLayerListeners.size > 0) {
      this.featureLayerListeners.forEach((fn: ListenerFunction, event: any) => {
        vectorSource.on(event, fn);
      });
    }

    const zIndex = opts && opts.zIndex != null ? opts.zIndex : this.zIndex--;
    const vectorLayer = new VectorLayer({
      // className: "layer-" + zIndex,
      source: vectorSource,
      properties: {
        layerInfo: layer,
        layerName: name,
      },
      visible: true,
      zIndex: zIndex,
    });

    this.map.addLayer(vectorLayer);

    this.featureLayersMapping.set(name, vectorLayer);

    if (opts.getFeatureStyle) {
      opts
        .getFeatureStyle(featureServerUrl, layer.id)
        .then((styleFunction: StyleFunction) => {
          vectorLayer.setStyle(styleFunction);
        });
    }
  }

  public onMapLoaded(): void {
    // after map load
  }

  public addMapListener(event: string, fn: ListenerFunction): void {
    this.mapListeners.set(event, fn);
  }

  public registerFeatureLayerListener(
    event: string,
    fn: ListenerFunction
  ): void {
    this.featureLayerListeners.set(event, fn);
  }

  private filterLayer(layerId: number): boolean {
    return this.opts.layerFilter ? this.opts.layerFilter(layerId) : true;
  }

  private getExtent(fullExtent: MapExtent): Array<number> {
    return [fullExtent.xmin, fullExtent.ymin, fullExtent.xmax, fullExtent.ymax];
  }

  public getFeatureProperties(feature: FeatureLike): FeatureProperties {
    let layer = this.featureLayersMapping.get(feature.get(LayerProperties.LAYER_NAME));
    let layerInfo: FeatureLayerInfo = layer.get("layerInfo");
    let result = new FeatureProperties();
    for (let prop in feature.getProperties()) {

      if (prop.startsWith('Shape__')) {
        continue;
      }

      let value = feature.get(prop);
      let field = null;
      for (let fieldInfo of layerInfo.fields) {
        if (fieldInfo.name == prop) {
          field = fieldInfo;
        }
      }


      if (field) {
        let codedValue = null;

        if (field.domain?.codedValues) {
          field.domain.codedValues.forEach(element => {
            if (element.code == value) {
              codedValue = element.name;
            }
          });
        }

        result[field.name] = {
          name: field.name,
          value: codedValue ? codedValue : value,
          label: field.alias,
          domain: field.domain
        };
      }
    }

    return result;
  }
}

export class OlMapWrapperOptions {
  target: string = "map";
  baseMapServerUrl: string;
  serviceUrls: string[] = [];
  center?: number[] = [24.753574, 59.436962];
  zoom?: number = 6;
  layerFilter: LayerFilterFn = (layerId: number) => true;
  getFeatureStyle: LayerStyleFn;
  mapLoaded: MapLoadedFn;
}

export class LayerInfo {
  id: number;
  name: string;
  type: string;
}

export class FeatureLayerInfo extends LayerInfo {
  drawingInfo: LayerDrawingInfo;
  fields: FieldInfo[] = [];
  objectIdField: string;
}

export class FeatureProperties {
  [key: string]: { name: string; value: any; label: string; domain: any };
}

export class FieldInfo {
  name: string;
  type: string;
  alias: string;
  domain: string;
}

export class LayerDrawingInfo {
  renderer: any;
}

export class Legend {
  label: string;
  url: string;
  imageData: string;
  contentType: string;
  height: number;
  width: number;
}

export class LegendInfo {
  layers: LegendLayerInfo[] = [];
}

export class LegendLayerInfo {
  layerId: number;
  layerName: string;
  legend: Legend[] = [];
}

export class MapExtent {
  xmin: number;
  ymin: number;
  xmax: number;
  ymax: number;
  spatialReference: any;
}

export class MapServerInfo {
  layers: LayerInfo[] = [];
  fullExtent: MapExtent;
  tileInfo: TileInfo;
}

export class TileInfo {
  origin: { x: number; y: number };
  lods: { level: number; resolution: number; scale: number }[] = [];
}

export class FeatureServerOptions {
  layers: {
    name: string;
    layerId: number;
    getFeatureStyle?: LayerStyleFn;
    getHighLightStyle: Style;
  }[] = [];
  zIndexOffset: number = 0;
}

export class FeatureLayerOptions {
  getFeatureStyle?: LayerStyleFn;
  zIndex: number | null;
  getHighlightStyle?: Style;
}

export class OlOverlayWrapper {
  private overlay: Overlay;

  protected div: HTMLElement;

  private opts: OverlayWrapperOptions;

  private map: OlMap;

  constructor(opts: OverlayWrapperOptions) {
    this.opts = opts;
  }

  setMap(map: OlMap): void {
    if (map) {
      this.map = map;
      this.onAdd();
      this.map.addOverlay(this.overlay);
    } else {
      if (this.map) {
        this.onRemove();
      }
      this.map = null;
    }
  }

  protected onAdd(): void {
    this.draw();
    this.overlay = new Overlay({
      // className: this.opts.className,
      stopEvent: this.opts.stopEvent,
      element: this.div,
      position: transform(this.opts.position, EPSG_4326, EPSG_3301),
    });
  }

  public draw(): void {
    if (!this.div) {
      this.div = document.createElement("div");
      // div.title = this.opts.title;
      this.div.style.cursor = "pointer";
      this.div.classList.add("pointer-water-tap");
      this.div.innerHTML =
        '<div data-trigger="hover" data-toggle="popover" data-placement="top" data-html="true" data-content="' +
        this.opts.title +
        '"></div>';

      ($(this.div) as any).popover({
        trigger: "hover",
        content: "test",
        placement: "top",
      });
    }
  }

  protected onRemove(): void {
    if (this.overlay && this.map) {
      this.map.removeOverlay(this.overlay);
      this.overlay = null;
    }
  }
}

export class OverlayWrapperOptions {
  className?: string;
  positioning?: string;
  title: string;
  position: number[];
  stopEvent?: boolean = false;
}
