import {Observable} from 'rxjs';
import {Widget, WidgetDatasetValue} from '../../../../mydata/src/lib/components/dashboard/widget';
import {Moment} from 'moment';
import {Injectable} from '@angular/core';

export abstract class WidgetDataService {

  abstract getWidgetValues(widget: Widget, rangeFrom?: Moment, rangeTo?: Moment, aggregationGroupingType?: string): Observable<WidgetDatasetValue[]>;

}
