import {Observable} from 'rxjs';
import {ActivityDaily} from '../model/crowd-insights/activity-daily';
import {MapsGridArea} from '../model/crowd-insights/maps-grid-area';
import {VisitDaily} from '../model/crowd-insights/visit-daily';
import {ActivityDailyFilter} from '../model/crowd-insights/activity-daily-filter';
import {VisitDailyFilter} from '../model/crowd-insights/visit-daily-filter';
import {MunicipalityArea} from '../model/crowd-insights/municipality-area';

export abstract class CrowdInsightsDataService {

  constructor() { }

  public abstract getMapsGridAreas(filter?: any): Observable<MapsGridArea[]>;

  public abstract getDailyActivities(filter: ActivityDailyFilter): Observable<ActivityDaily[]>;

  public abstract getDailyVisits(filter: VisitDailyFilter): Observable<VisitDaily[]>;

  public abstract getMunicipalityAreas(): Observable<MunicipalityArea[]>;

}
