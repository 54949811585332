import {
  Component,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import {
  AuthService, ConfirmModalComponent,
  OwnerType,
  PendingSeries, PersonSeriesApiService,
  PersonSeriesLocationLabel,
  Tenant,
  UserService
} from '@smartencity/core';
import { MyDataConfig } from '../../../mydata-config.model';
import { TenantSelectService } from '../../../services/tenant-select.service';
import { ToastrService } from 'ngx-toastr';
import { NotificationService } from '../notification.service';
import { SMARTENCITY_MYDATA_CONFIG } from '../../../injection-tokens';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'mydata-person-series-row',
  templateUrl: './person-series-row.component.html',
  encapsulation: ViewEncapsulation.None
})
export class PersonSeriesRowComponent implements OnInit, OnDestroy, OnChanges {
  private ngDestroy = new Subject<void>();
  public progress: string = null;

  @Input()
  public parentProgress: string = null;

  @Input()
  public item: PendingSeries;

  public assignerName;
  public datapointName;
  public location;
  public comment;

  public tenant: Tenant = null;

  constructor(
    @Inject(SMARTENCITY_MYDATA_CONFIG) private config: MyDataConfig,
    public authService: AuthService,
    public personSeriesService: PersonSeriesApiService,
    public userService: UserService,
    public tenantSelectService: TenantSelectService,
    public notificationService: NotificationService,
    private toastr: ToastrService,
    private personSeriesLocationLabel: PersonSeriesLocationLabel,
    private modalService: BsModalService
  ) {
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.item) {
      let assignerPerson = this.item.assignerPerson;
      let sourceAssignedPerson = this.item.personSeries.sourceAssignedPerson;
      let assignerName = this.item.assignerPerson.displayName;
      if (sourceAssignedPerson && (assignerPerson.registrationNumber !== sourceAssignedPerson.registrationNumber
        || assignerPerson.countryCode !== sourceAssignedPerson.countryCode)) {
        assignerName = `${this.item.personSeries.sourceAssignedPerson.displayName} (${this.item.assignerPerson.displayName})`;
      }
      this.assignerName = assignerName;
      this.datapointName = this.item.personSeries.name;
      this.location = this.personSeriesLocationLabel.transform(this.item.personSeries);
      this.comment = this.item.assignerComment;
    }
  }

  acceptPersonSeries(item: PendingSeries) {
    const optional = !(item.period.ownerType === OwnerType.OWNER);

    this.progress = 'accept';

    this.tenantSelectService.selectTenant(optional).subscribe((tenant: Tenant) => {
      this.modalService.show(ConfirmModalComponent, {
        initialState: {
          description: $localize`Are you sure you want to accept person series?`,
          callback: (result: boolean) => {
            if (result) {
              this.tenant = tenant;
              this.submitPersonSeriesStatus(item, 'ACCEPTED');
            }
          }
        }
      });
    }, (error) => {
      this.progress = null;
      console.error(error);
      this.toastr.error($localize`Tenant selection error`);
    }, () => this.progress = null);
  }

  declinePersonSeries(item: PendingSeries) {
    this.modalService.show(ConfirmModalComponent, {
      initialState: {
        description: $localize`Are you sure you want to decline person series?`,
        callback: (result: boolean) => {
          if (result) {
            this.progress = 'decline';
            this.submitPersonSeriesStatus(item, 'DECLINED');
          }
        }
      }
    });
  }

  archivePersonSeries(item: PendingSeries) {
    this.progress = 'archive';
    this.personSeriesService.deletePeriod(item.personSeries, item.period).subscribe(
      (data: any) => {
        this.toastr.success($localize`Acknowledged`);
        this.stateChanged(item);
      }, (error) => {
        this.progress = null;
        console.error(error);
        this.toastr.error($localize`Error archiving series`);
      }, () => this.progress = null
    );
  }

  acknowledgePersonSeries(item: PendingSeries) {
    this.progress = 'acknowledge';
    this.personSeriesService.acknowledgePeriodCancel(item.personSeries, item.period).subscribe(
      (data: any) => {
        this.toastr.success($localize`Acknowledged`);
        this.stateChanged(item);
      }, (error) => {
        this.progress = null;
        console.error(error);
        this.toastr.error($localize`Error acknowledging series`);
      }, () => this.progress = null
    );
  }

  submitPersonSeriesStatus(item: PendingSeries, status: string) {
    this.personSeriesService.updateStatus(item.personSeries, status, this.tenant).subscribe(
      (data: any) => {
        this.stateChanged(item);
      }, (error) => {
        this.progress = null;
        console.error(error);
        this.toastr.error($localize`Error submitting confirmation`);
      }, () => {
        this.progress = null;
      }
    );
  }

  private stateChanged(item: PendingSeries) {
    // this.notificationService.loadPendingPendingSeries();
    this.notificationService.pendingSeriesRemove$.next(item);
    this.userService.confirmationListChanged();
  }

}
