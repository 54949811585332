import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs/internal/Subject';
import {aggregationGroupingTypeSymbolMap, ChartDataService, CommonHelper} from '@smartencity/core';
import {map, startWith, switchMap, takeUntil} from 'rxjs/operators';
import {SMARTENCITY_GAS_CONFIG} from '../injection-tokens';
import {GasConfig} from '../config.model';
import {HttpClient} from '@angular/common/http';
import moment from 'moment';
import {FormControl} from '@angular/forms';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';
import {of} from 'rxjs/internal/observable/of';
import {SeriesLocationType} from '../models/series-location-type.enum';
import {GasMapService} from '../gas-map.service';

@Component({
  selector: '[gas-item-modal]',
  templateUrl: './location-modal.component.html',
  styleUrls: ['./location-modal.component.css']
})
export class LocationModalComponent implements OnInit, OnDestroy {
  private ngDestroy = new Subject<void>();

  location: any;

  public radius = 120;
  public radiansPerSeries = 0;
  public seriesPositions: {x: number; y: number; }[] = [];
  public aggregationGroupingTypeSymbolMap = aggregationGroupingTypeSymbolMap;

  public cityPortalTypeData = {
    'SOLAR_PANELS_ACTIVE': {style: undefined, iconStyle: undefined, label: '', series: null},
    'SOLAR_PANELS_CO2': {style: 'green', iconStyle: 'icon-co2', series: null},
    'SOLAR_PANELS_KM': {style: 'green', iconStyle: 'icon-car', series: null},
    'SOLAR_PANELS_KW': {style: undefined, iconStyle: 'icon-power', label: 'Tootmisvõimsus', series: null},
    'SOLAR_PANELS_ENERGY': {style: undefined, iconStyle: undefined, series: null},
    'SOLAR_PANELS_PERCENT': {style: undefined, iconStyle: undefined, series: null},
    'SOLAR_PANELS_RADIANCE': {style: undefined, iconStyle: 'icon-sun', label: 'Päikesekiirgus', series: null},
    'SOLAR_PANELS_TEMP': {style: undefined, iconStyle: 'icon-temp', label: 'Temperatuur', series: null},
    'SOLAR_PANELS_TREES': {style: 'green', iconStyle: 'icon-trees', series: null},
    'SOLAR_PANELS_CO2_TOTAL': {style: 'green', iconStyle: 'icon-co2', series: null},
    'SOLAR_PANELS_KM_TOTAL': {style: 'green', iconStyle: 'icon-car', series: null},
    'SOLAR_PANELS_KW_TOTAL': {style: undefined, iconStyle: 'icon-power', label: 'Tootmisvõimsus', series: null},
    'SOLAR_PANELS_ENERGY_TOTAL': {style: undefined, iconStyle: undefined, series: null},
    'SOLAR_PANELS_PERCENT_TOTAL': {style: undefined, iconStyle: undefined, series: null},
    'SOLAR_PANELS_RADIANCE_TOTAL': {style: undefined, iconStyle: 'icon-sun', label: 'Päikesekiirgus', series: null},
    'SOLAR_PANELS_TEMP_TOTAL': {style: undefined, iconStyle: 'icon-temp', label: 'Temperatuur', series: null},
    'SOLAR_PANELS_TREES_TOTAL': {style: 'green', iconStyle: 'icon-trees', series: null},
    'SOLAR_PANELS_CO2_ESTONIA': {style: 'green', iconStyle: 'icon-co2', series: null},
    'SOLAR_PANELS_KM_ESTONIA': {style: 'green', iconStyle: 'icon-car', series: null},
    'SOLAR_PANELS_TREES_ESTONIA': {style: 'green', iconStyle: 'icon-trees', series: null},
  };

  public currentValueBarValue = 0.0;
  public currentValueBarStrokeDashoffset = 1106.28;
  public periodTypeControl = new FormControl('week');
  public chartValues: any[] = [];
  public chartYLabel = 'Energia kWh';
  public chartSeries = new Subject<any>();

  public seriesLocationTypeEnum = SeriesLocationType;

  constructor(
    @Inject(SMARTENCITY_GAS_CONFIG) private config: GasConfig,
    private http: HttpClient,
    private chartDataService: ChartDataService,
    private gasMapService: GasMapService
  ) {


  }

  ngOnInit(): void {
    this.gasMapService.solarParkSelected$.subscribe((location) => {
      this.location = location;
      this.onLocationChange(location);
    });

    combineLatest([this.chartSeries, this.periodTypeControl.valueChanges.pipe(
      takeUntil(this.ngDestroy),
      startWith('week'),
    )]).pipe(
      switchMap(([series, periodType]) => {
        const offset = (series && series.differentiate ? 1 : 0);
        let aggregationGroupingType = null;
        const dateTo = moment().subtract(1, 'days');
        let dateFrom = moment(dateTo).subtract(1, 'days');
        if (periodType === 'week') {
          aggregationGroupingType = 'DAILY';
          dateFrom = moment(dateTo).subtract(7 + offset, 'days');
        }
        if (periodType === 'month') {
          aggregationGroupingType = 'DAILY';
          dateFrom = moment(dateTo).subtract(30 + offset, 'days');
        }

        if (periodType === 'year') {
          aggregationGroupingType = 'MONTHLY';
          dateFrom = moment(dateTo).subtract(12 + offset, 'months');
        }

        let params =  {
          dateFrom: dateFrom.toISOString(),
          dateTo: dateTo.toISOString(),
          aggregationGroupingType: aggregationGroupingType
        };

        if (series) {
          return this.http.get(this.config.cityApiUrl + '/person-series/' + series.personSeriesId + '/historical/measurement', {params: params})
            .pipe(map(data => [series, data, params]));
        } else {
          return of([null, null, null]);
        }
      })
    ).subscribe(([series, data, params]) => {
      this.mapChartData(data, null, params, (series ? series.differentiate : false));
    });
  }

  ngOnDestroy(): void {
    this.chartSeries.complete();
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  private onLocationChange(location): void {
    if (location) {
      for (const key in this.cityPortalTypeData) {
        if (this.cityPortalTypeData[key].series) {
          this.cityPortalTypeData[key].series = null;
        }
      }

      if (this.location?.series) {
        for (const series of this.location?.series) {
          const key = series.cityPortalType;
          if (this.cityPortalTypeData[key]) {
            this.cityPortalTypeData[key].series = series;
          }
        }
      }

      if (this.cityPortalTypeData['SOLAR_PANELS_PERCENT'].series && this.cityPortalTypeData['SOLAR_PANELS_PERCENT'].series.value) {
        this.currentValueBarValue = this.cityPortalTypeData['SOLAR_PANELS_PERCENT'].series.value;
        this.currentValueBarStrokeDashoffset = 1106.28 * (this.currentValueBarValue / 100.0 * -1.0 + 1.0);
      } else if (this.cityPortalTypeData['SOLAR_PANELS_PERCENT_TOTAL'].series && this.cityPortalTypeData['SOLAR_PANELS_PERCENT_TOTAL'].series.value) {
        this.currentValueBarValue = this.cityPortalTypeData['SOLAR_PANELS_PERCENT_TOTAL'].series.value;
        this.currentValueBarStrokeDashoffset = 1106.28 * (this.currentValueBarValue / 100.0 * -1.0 + 1.0);
      } else {
        this.currentValueBarValue = 0.0;
        this.currentValueBarStrokeDashoffset = 1106.28;
      }

      if (this.location?.type === SeriesLocationType.SOLAR_PANELS_TOTAL) {
        this.chartSeries.next(this.cityPortalTypeData['SOLAR_PANELS_ENERGY_TOTAL'].series);
      } else {
        this.chartSeries.next(this.cityPortalTypeData['SOLAR_PANELS_ENERGY'].series);
      }

      if (this.location) {
        this.radiansPerSeries = 2 * Math.PI / this.location.series.length;
        for (let i = 0; i < this.location.series.length; i++) {
          const series = this.location.series;
          this.seriesPositions.push({
            x: 152 + (Math.sin(i * this.radiansPerSeries) * this.radius),
            y: 175 - (Math.cos(i * this.radiansPerSeries) * this.radius)
          });
        }
      } else {
        this.radiansPerSeries = 0;
        this.seriesPositions = [];
      }
    }
  }

  close() {
    this.gasMapService.solarParkSelectedSource.next(null);
    this.location = null;
  }

  public isAnimated(series): boolean {
    return (new Date().getTime() - new Date(series.updatedAt).getTime()) < 3600000;
  }

  public setPeriodType(periodType: string) {
    this.periodTypeControl.setValue(periodType);
  }

  private mapChartData(data: any, aggregationType: string, params: any, differentiate?: boolean) {

    this.chartYLabel = 'Energia kWh';

    if (!data) {
      this.chartValues = [];
      return;
    }

    const aggregationGroupingType = (params ? params.aggregationGroupingType : null);

    const mappedData = this.chartDataService.mapChartData(data, aggregationType, aggregationGroupingType, null, differentiate);

    this.replaceMissingAggregationValuesWithNulls(params, mappedData);

    const suggestedMin = (mappedData.min < 0 ? mappedData.min * 2 : 0);
    this.chartValues = mappedData.data.map(e => {
      return {
        title: CommonHelper.replaceThousandsSeparator(Math.round(e.y).toString()) + mappedData.unit +'  '+ moment(e.t).format(aggregationGroupingType == 'MONTHLY' ? "MM.YYYY" : "DD.MM.YYYY"),
        height: 100 * (e.y - suggestedMin) / (mappedData.max - suggestedMin)
      };
    });
    if (this.chartValues.length === 1) {
      this.chartValues[0].height = 100;
    }
  }

  private replaceMissingAggregationValuesWithNulls(params: any, mappedData: any) {
    if (!params.aggregationGroupingType) {
      return;
    }

    // Difference tõttu +2 päeva algusest, sest taustal võetakse 1. väärtuse jaoks -1 päeva andmed
    let runningDate = moment(params.dateFrom).startOf('day').add(2, 'day'),
      endDate = moment(params.dateTo).startOf('day');

    const incrementUnit = (params.aggregationGroupingType === 'DAILY' ? 'day' : 'month');

    let runningIdx = 0;
    while (!runningDate.isAfter(endDate)) {
      let hasDataOnCurrentDate = false;
      mappedData.data.forEach((item, idx) => {
        if (runningDate.valueOf() <= item.t && item.t < runningDate.clone().add(1, incrementUnit).valueOf()) {
          hasDataOnCurrentDate = true;
          return;
        }
      });

      if (!hasDataOnCurrentDate) {
        mappedData.data.splice(runningIdx, 0, {
          t: runningDate.valueOf(),
          y: null
          }
        );
      }

      runningIdx = runningIdx + 1;
      runningDate = runningDate.add(1, incrementUnit);
    }


  }
}
