import {Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {Subject} from 'rxjs/internal/Subject';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {PersonAdvice, PersonAdviceGroup} from '@smartencity/core';
import {HttpClient} from '@angular/common/http';
import {SMARTENCITY_MYDATA_CONFIG} from '../../../injection-tokens';
import {MyDataConfig} from '../../../mydata-config.model';
import {Observable} from 'rxjs/internal/Observable';
import {takeUntil, tap} from 'rxjs/operators';
import {AbstractControl, FormControl} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';

const selector = 'mydata-advice-modal';
let nextId = 0;

@Component({
  selector: selector,
  templateUrl: './advice-modal.component.html',
  providers: [],
})
export class AdviceModalComponent implements OnInit, OnDestroy, OnChanges {
  id = `${selector}-${nextId++}`;
  private ngDestroy = new Subject<void>();

  public progress: string = null;

  adviceGroupId: number;

  @Input()
  personAdviceGroup: PersonAdviceGroup;

  @Output('saved')
  savedEmitter: EventEmitter<any> = new EventEmitter<any>();

  rows: { personAdvice: PersonAdvice, checkControl: AbstractControl }[] = [];

  public constructor(
    @Inject(SMARTENCITY_MYDATA_CONFIG) private config: MyDataConfig,
    private http: HttpClient,
    private toastr: ToastrService,
    private modalRef: BsModalRef
  ) {

  }

  ngOnInit(): void {
    this.loadAdviceGroup();
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadAdviceGroup();
  }

  loadAdviceGroup() {
    const adviceGroupId = this.personAdviceGroup ? this.personAdviceGroup.adviceGroupId : null;
    if (adviceGroupId) {
      this.adviceGroupId = adviceGroupId;
      this.http.get<PersonAdviceGroup>(this.config.apiUrl + '/person-advice/' + adviceGroupId, {})
        .subscribe((data: PersonAdviceGroup) => {
          this.rows = data.personAdviceList.map((personAdvice) => ({
            personAdvice: personAdvice,
            checkControl: new FormControl(personAdvice.checked ? personAdvice.checked : false)
          }));
        });
    }
  }

  close(): void {
    this.modalRef.hide();
  }

  save(): void {
    const saveResult = this.doSave();
    if (!saveResult) {
      return;
    }

    this.progress = 'save';
    saveResult.pipe(takeUntil(this.ngDestroy)).subscribe((response: any) => {
      this.modalRef.hide();
    }, (error) => {
      this.progress = null;
      this.toastr.error($localize`Saving response failed`);
    }, () => this.progress = null);
  }

  submit(): void {
    const saveResult =  this.doSave();

    this.progress = 'send';
    saveResult.pipe(takeUntil(this.ngDestroy)).subscribe((response: any) => {
      this.modalRef.hide();
    }, (error) => {
      this.progress = null;
      this.toastr.error($localize`Saving response failed`);
    }, () => this.progress = null);
  }

  doSave(): Observable<any> {
    const postDto = {
      adviceGroupId: this.personAdviceGroup.adviceGroupId,
      personAdviceList: this.rows.map((row) => {
        return {
          adviceId: row.personAdvice.adviceId,
          checked: row.checkControl.value
        };
      })
    };

    const ret = this.http.put<any>(this.config.apiUrl + '/person-advice/' + this.adviceGroupId, postDto);
    return ret.pipe(tap(() => {
      this.savedEmitter.emit();
      this.toastr.success($localize`Advices saved`);
    }));
  }
}
