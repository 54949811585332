import { Injectable } from '@angular/core';
import moment from 'moment';
import {SeriesNumberFormat} from '../models/series';

@Injectable({
  providedIn: 'root'
})
export class ChartDataService {

  public pointStyles: string[] = [
    'circle',
    'cross',
    'crossRot',
    'dash',
    'line',
    'rect',
    'rectRounded',
    'rectRot',
    'star',
  ];

  public chartMapping = {
    'LINE' : 'line',
    'BAR' : 'bar',
    'POLAR_AREA': 'polarArea',
    'PIE': 'pie',
    'DOUGHNUT': 'doughnut',
    'RADAR': 'radar'
  };

  public static agt2MomentUnit(aggregationGroupingType): moment.unitOfTime.DurationConstructor {
    if (aggregationGroupingType === 'DAILY') {
      return 'day';
    } else if (aggregationGroupingType === 'MONTHLY') {
      return  'month';
    } else if (aggregationGroupingType === 'YEARLY') {
      return  'year';
    }
    return 'hour';
  }

  constructor() { }

  public mapChartData(data: any, aggregationType: string, aggregationGroupingType: string, numberFormat?: SeriesNumberFormat, differentiate?: boolean) {
    const datasetValues = [];

    if (!data.measurement || !data.measurement.series) {
      return {data: datasetValues, label: '', unit: null};
    }

    const valueIndices = [];
    const minIndices = [];
    const maxIndices = [];
    let countIndex = null;
    let minValue = Infinity;
    let maxValue = -Infinity;
    const seriesName = data.measurement.seriesName;
    data.measurement.series.series.forEach((seriesDesc, index) => {
      if (seriesDesc.name === seriesName) {
        valueIndices.push(index);
      }
      if (seriesDesc.name === seriesName + '_min') {
        minIndices.push(index);
      }
      if (seriesDesc.name === seriesName + '_max') {
        maxIndices.push(index);
      }
      if (seriesDesc.name === seriesName + '_count') {
        countIndex = index;
      }
    });
    const series = data.measurement.series.series;
    const values: any = data.measurement.series.values;

    let valuesArray = [];

    if (aggregationType === 'MIN' && !minIndices.length) {
      aggregationType = null;
    }
    if (aggregationType === 'MAX' && !maxIndices.length) {
      aggregationType = null;
    }
    switch (aggregationType) {
      case 'SUM': {
        for (const i in values) {
          if (!values.hasOwnProperty(i)) {
            continue;
          }
          const item = values[i];
          for (const valueIndex of valueIndices) {
            if (!item[valueIndex]) {
              continue;
            }
            valuesArray.push({
              date: i,
              value: (item[valueIndex].min + item[valueIndex].max) / 2
            });
          }
        }
        break;
      } case 'MIN': {
        for (const i in values) {
          if (!values.hasOwnProperty(i)) {
            continue;
          }
          const item = values[i];
          for (const minIndex of minIndices) {
            if (!item[minIndex]) {
              continue;
            }
            valuesArray.push({
              date: i,
              value: (item[minIndex].min + item[minIndex].max) / 2
            });
          }
        }
        break;
      } case 'MAX': {
        for (const i in values) {
          if (!values.hasOwnProperty(i)) {
            continue;
          }
          const item = values[i];
          for (const maxIndex of maxIndices) {
            if (!item[maxIndex]) {
              continue;
            }
            valuesArray.push({
              date: i,
              value: (item[maxIndex].min + item[maxIndex].max) / 2
            });
          }
        }
        break;
      } default: {
        for (const i in values) {
          if (!values.hasOwnProperty(i)) {
            continue;
          }
          const item = values[i];
          for (const valueIndex of valueIndices) {
            if (!item[valueIndex]) {
              continue;
            }
            let value = (item[valueIndex].min + item[valueIndex].max) / 2;
            if (item[countIndex]) {
              value /= (item[countIndex].min + item[countIndex].max) / 2;
            }
            valuesArray.push({
              date: i,
              value: value
            });
          }
        }
      }
    }

    valuesArray.sort(function(a, b) {
      const x = a.date;
      const y = b.date;
      return x < y ? -1 : x > y ? 1 : 0;
    });

    let valueTotal = null;
    if (differentiate) {
      if (valuesArray.length > 0) {
        valueTotal = valuesArray[valuesArray.length - 1].value - valuesArray[0].value;
        for (let i = valuesArray.length - 1; i > 0; i--) {
          valuesArray[i].value = valuesArray[i].value - valuesArray[i - 1].value;
        }
        valuesArray = valuesArray.slice(1);
      }
    } else {
      let total = 0.0;
      for (let i = 0; i < valuesArray.length; i++) {
        total += valuesArray[i].value;
      }
      valueTotal = total;
    }

    for (const item of valuesArray) {
      datasetValues.push({t: moment(item.date).valueOf(), y: item.value});
      if (item.value < minValue) {
        minValue = item.value;
      }
      if (item.value > maxValue) {
        maxValue = item.value;
      }
    }

    let unit = null;
    for (const valueIndex of valueIndices) {
      if (series[valueIndex] && series[valueIndex].unit) {
        unit = series[valueIndex].unit;
      }
    }
    if (unit === undefined) {
      unit = null;
    }

    return {
      data: datasetValues,
      unit: unit,
      min: minValue,
      max: maxValue,
      valueTotal: valueTotal,
      numberFormat: numberFormat
    };
  }
}
