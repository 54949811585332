import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CoreModule} from '@smartencity/core';
import {NgxPermissionsModule} from 'ngx-permissions';
import {GroupsComponent} from './groups.component';
import {GroupsEditModalComponent} from './edit-modal/groups-edit-modal.component';
import {GroupsCreateStepMetadataComponent} from './create-step-metadata/groups-create-step-metadata.component';
import {GroupsPersonSeriesRowComponent} from './groups-person-series-row/groups-person-series-row.component';
import {GroupsRowComponent} from './row/groups-row.component';
import {ModalModule} from 'ngx-bootstrap/modal';
import {PaginationModule} from 'ngx-bootstrap/pagination';
import {TypeaheadModule} from 'ngx-bootstrap/typeahead';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {NgSelectModule} from '@ng-select/ng-select';
import {ButtonsModule} from 'ngx-bootstrap/buttons';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatButtonModule} from '@angular/material/button';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {TooltipModule} from "ngx-bootstrap/tooltip";
import {LaddaModule} from 'angular2-ladda';
import { DatapointGroupUpdateErrorsModalComponent } from './datapoint-group-update-errors-modal/datapoint-group-update-errors-modal.component';
import {PopoverModule} from 'ngx-bootstrap/popover';
import {SharedModule} from '@smartencity/shared';

@NgModule({
  declarations: [
    GroupsComponent,
    GroupsEditModalComponent,
    GroupsCreateStepMetadataComponent,
    GroupsPersonSeriesRowComponent,
    GroupsRowComponent,
    DatapointGroupUpdateErrorsModalComponent
  ],
  entryComponents: [],
  providers: [

  ],
  imports: [
    CommonModule,
    RouterModule.forChild([]),
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    NgxPermissionsModule.forChild(),
    ModalModule,
    TypeaheadModule,
    PaginationModule,
    NgxDatatableModule,
    NgSelectModule,
    ButtonsModule,
    DragDropModule,
    MatButtonModule,
    BsDropdownModule,
    TooltipModule,
    LaddaModule,
    PopoverModule,
    SharedModule
  ],
  exports: [
    GroupsComponent
  ]
})
export class GroupsModule { }
