import {Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {Subject} from 'rxjs/internal/Subject';
import { OwnerType, PersonDashboard } from './dashboard';
import {DashboardService} from './dashboard.service';

@Component({
  selector: 'mydata-dashboard-actions',
  templateUrl: './dashboard-actions.component.html'
})
export class DashboardActionsComponent implements OnInit, OnDestroy {
  private ngDestroy = new Subject<void>();

  @ViewChild('noRootTemplate', {static: true})
  noRootTemplate: TemplateRef<any>;

  public isOwner = true;

  constructor(
    private vcRef: ViewContainerRef,
    public dashboardService: DashboardService,
    private route: ActivatedRoute,
  ) {
  }
  
  ngOnInit(): void {
    this.vcRef.createEmbeddedView(this.noRootTemplate);

    this.dashboardService.currentDashboard$.subscribe((currentDashboard: PersonDashboard) => {
      this.isOwner = currentDashboard.ownerType == OwnerType.OWNER ? true : false;
    });
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }
}
