import {Component, HostListener, Inject, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {AuthService, EnumsService, UserService} from '@smartencity/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import { Chart } from 'chart.js';
import {Subject} from 'rxjs/internal/Subject';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {DOCUMENT} from '@angular/common';
import {SMARTENCITY_APP_CONFIG} from './injection-tokens';
import {Title} from '@angular/platform-browser';
import {filter} from 'rxjs/operators';
import {BsModalService} from 'ngx-bootstrap/modal';
import {RandomStringGenerator} from '../../projects/core/src/lib/utils/random-string-generator';

Chart.plugins.unregister(ChartDataLabels);
Chart.pluginService.register({
  beforeDraw: function (chart, easing) {
    if ((chart.config.options as any).chartArea && (chart.config.options as any).chartArea.backgroundColor) {
      const ctx = chart.ctx;
      const chartArea = chart.chartArea;

      ctx.save();
      ctx.fillStyle = (chart.config.options as any).chartArea.backgroundColor;
      ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);
      ctx.restore();
    }
  }
});

declare var gtag;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit, OnDestroy {
  private ngDestroy = new Subject<void>();
  private hidden = false;

  private focusChange = (evt) => {
    if (this.document.visibilityState) {
      this.userService.setFocus(this.document.visibilityState === 'visible');
    } else {
      const v = 'visible', h = 'hidden',
      evtMap = {
        focus: v, focusin: v, pageshow: v, blur: h, focusout: h, pagehide: h
      };

      evt = evt || window.event;
      if (evt.type in evtMap) {
        this.document.body.className = evtMap[evt.type];
        this.userService.setFocus(evtMap[evt.type] === 'visible');
      } else {
        this.document.body.className = this.hidden ? 'hidden' : 'visible';
        this.userService.setFocus(!this.hidden);
      }
    }
  };

  constructor (
    @Inject(DOCUMENT) private document: Document,
    @Inject(SMARTENCITY_APP_CONFIG) private config: any,
    private enumsService: EnumsService,
    private route: ActivatedRoute,
    private router: Router,
    public authService: AuthService,
    public userService: UserService,
    private titleService: Title,
    private modalService: BsModalService
  ) {
    this.titleService.setTitle(this.config.title);
    //Temporarily override loginMode for NemID auth
    let override = this.authService.getLoginMode() === 'nem-id';
    this.authService.setLoginMode(this.config.loginMode, override);

    let idpProviders = this.authService.getIdpProviders();
    this.route.queryParams.subscribe(params => {
      if (params['loginMode']) {
        if (idpProviders.indexOf(params['loginMode']) !== -1) {
          this.authService.setLoginMode(params['loginMode'], true);
        }
      }

      if (params['token'] || params['loginMode']) {
        // this.authService.setApiToken(params['token']);
        this.router.navigate([], {
          queryParams: {
            token: null,
            loginMode: null
          },
          queryParamsHandling: 'merge'
        });
      }
    });

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: any) => {
      gtag('event', 'page_view', {
        page_path: event.urlAfterRedirects
      });
    });
  }


  ngOnInit(): void {
    const themeName = this.config?.theme;

    let hidden = 'hidden';

    // Standards:
    if (hidden in this.document) {
      this.document.addEventListener('visibilitychange', this.focusChange);
    } else if ((hidden = 'mozHidden') in document) {
      this.document.addEventListener('mozvisibilitychange', this.focusChange);
    } else if ((hidden = 'webkitHidden') in document) {
      this.document.addEventListener('webkitvisibilitychange', this.focusChange);
    } else if ((hidden = 'msHidden') in document) {
      this.document.addEventListener('msvisibilitychange', this.focusChange);
    } else { // All others:
      window.onpageshow = window.onpagehide = window.onfocus = window.onblur = this.focusChange;
    }

    // set the initial state (but only if browser supports the Page Visibility API)
    if (this.document[hidden] !== undefined) {
      this.focusChange({type: this.document[hidden] ? 'blur' : 'focus'});
    }

    let tabId = sessionStorage.getItem('tabId'),
      tabState = sessionStorage.getItem('tabState');

    if (tabId && !tabState) {
      // console.log("same tab");
    } else {
      // unloadi ei tehta tab-i kopeerimisel
      // seega teab, et tab on eelnevalt juba laetud ja peab uue tab id genereerima
      tabId = RandomStringGenerator.randomString(18);
    }

    sessionStorage.setItem('tabId', tabId);
    sessionStorage.setItem('tabState', 'loaded');
  }

  @HostListener("window:beforeunload")
  public unload() {
    sessionStorage.setItem('tabState', '');
  }

  ngOnDestroy(): void {
    let hidden = 'hidden';

    if (hidden in this.document) {
      this.document.removeEventListener('visibilitychange', this.focusChange);
    } else if ((hidden = 'mozHidden') in this.document) {
      this.document.removeEventListener('mozvisibilitychange', this.focusChange);
    } else if ((hidden = 'webkitHidden') in this.document) {
      this.document.removeEventListener('webkitvisibilitychange', this.focusChange);
    } else if ((hidden = 'msHidden') in this.document) {
      this.document.removeEventListener('msvisibilitychange', this.focusChange);
    } else { // All others:
      window.onpageshow = window.onpagehide = window.onfocus = window.onblur = null;
    }

    this.ngDestroy.next();
    this.ngDestroy.complete();
  }


}
