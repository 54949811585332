import { Injectable, OnDestroy, TemplateRef } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { FormControl } from '@angular/forms';
import moment, { Moment } from 'moment';
import {
  debounceTime,
  distinctUntilChanged,
  shareReplay,
  startWith,
  switchMap,
  takeUntil, tap
} from 'rxjs/operators';
import { BehaviorSubject, ReplaySubject, combineLatest } from 'rxjs';
import { ChartDataService } from './chart-data.service';

export interface CompareItemDataset {
  id: number;
  item: CompareItem;
  axis: CompareAxis;
  min: number;
  max: number;
  chartDataset: any;
}

export interface CompareAxis {
  id: string;
  unit: string;
  name: string;
  min: number;
  max: number;
  datasets: Set<CompareItemDataset>;
  chartYAxis: any;
}

export interface CompareItem {
  name: string;
  unit: string;
  displayUnit: string;
  aggregationTypeControl: FormControl;
  graphTypeControl: FormControl;
  groupByUnit: FormControl;
  groupNameControl: FormControl;
  datasets: Set<CompareItemDataset>;
  axes: Map<string, CompareAxis>;
  active: boolean;
  destory$: Subject<void>;
}

@Injectable({ providedIn: 'root' })
export class CompareService implements OnDestroy {
  private ngDestroy = new Subject<void>();

  private nextYAxisId = 0;

  public nextPointStyleIndex = 0;

  public rangeControl: FormControl = new FormControl(this.getDefaultRange());
  public range$ = this.rangeControl.valueChanges.pipe(takeUntil(this.ngDestroy), startWith(this.getDefaultRange()));
  public aggregationGroupingType$ = new BehaviorSubject('HOURLY');
  public filtersSubject = new Subject<{ range: { from: Moment, to: Moment, aggTypes: any }; aggregationGroupingType: string; }>();
  public filters$ = this.filtersSubject.asObservable().pipe(takeUntil(this.ngDestroy), debounceTime(250), shareReplay(1));
  public loadingCount = 0;
  public loadingCount$ = new Subject<number>();

  public chartDatasets: Array<any> = [];
  public chartYAxes: Array<any> = [];
  public chartChanges$: Subject<void> = new Subject();
  public compareItems: CompareItem[] = [];
  public count$ = new ReplaySubject<number>(1);
  public compareState$ = new Subject<any>();
  public footerTemplate: TemplateRef<any>;
  public footerTemplate$ = new ReplaySubject<TemplateRef<any>>(1);
  public closeEvent$ = new Subject<void>();

  private axesByUnit = new Map<string, Set<CompareAxis>>();

  constructor(public chartDataService: ChartDataService) {
    combineLatest([
      this.range$,
      this.aggregationGroupingType$.pipe(startWith('HOURLY'), distinctUntilChanged())
    ]).pipe(
      takeUntil(this.ngDestroy)
    ).subscribe(([range, aggregationGroupingType]: [{ from: Moment, to: Moment, aggTypes: any }, string]) => {
      const origGroupingType = aggregationGroupingType;

      if (aggregationGroupingType === 'YEARLY' && !range.aggTypes['YEARLY']) {
        aggregationGroupingType = 'MONTHLY';
      }
      if (aggregationGroupingType === 'MONTHLY' && !range.aggTypes['MONTHLY']) {
        aggregationGroupingType = 'DAILY';
      }
      if (aggregationGroupingType === 'DAILY' && !range.aggTypes['DAILY']) {
        aggregationGroupingType = 'HOURLY';
      }
      if (aggregationGroupingType === 'HOURLY' && !range.aggTypes['HOURLY']) {
        aggregationGroupingType = null;
      }
      if (aggregationGroupingType !== origGroupingType) {
        this.aggregationGroupingType$.next(aggregationGroupingType);
      } else {
        this.filtersSubject.next({
          range: range,
          aggregationGroupingType: aggregationGroupingType
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.clear();
    this.chartChanges$.complete();
    this.loadingCount$.complete();
    this.footerTemplate$.complete();
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  private getDefaultRange(): { from: Moment, to: Moment, aggTypes: any } {
    const myRange: { from: Moment; to: Moment } = {
      from: moment().startOf('day').subtract(2, 'days'),
      to: moment().startOf('day').add(1, 'days')
    };
    const to = moment().isBefore(moment(myRange.to)) ? moment() : moment(myRange.to).subtract(1);
    const aggTypes = {
      'HOURLY': !moment(myRange.from).isAfter(to.clone().startOf('hour')),
      'DAILY': !moment(myRange.from).isAfter(to.clone().startOf('day')),
      'MONTHLY': !moment(myRange.from).isAfter(to.clone().startOf('month')),
      'YEARLY': !moment(myRange.from).isAfter(to.clone().startOf('year'))
    };
    return { from: myRange.from, to: myRange.to, aggTypes: aggTypes };
  }

  public createCompareItem(name: string): CompareItem {
    const item: CompareItem = {
      name: name,
      unit: '',
      displayUnit: '',
      aggregationTypeControl: new FormControl('AVERAGE'),
      graphTypeControl: new FormControl('line'),
      groupByUnit: new FormControl(true),
      groupNameControl: new FormControl(null),
      datasets: new Set(),
      axes: new Map(),
      active: false,
      destory$: new Subject<void>()
    };

    this.compareItems.push(item);

    this.updateCount();

    return item;
  }

  public deleteCompareItem(item: CompareItem) {
    const index = this.compareItems.indexOf(item);
    if (index >= 0) {
      this.compareItems.splice(index, 1);
      for (const dataset of Array.from(item.datasets.values())) {
        const chartDatasetIndex = this.chartDatasets.indexOf(dataset.chartDataset);
        if (chartDatasetIndex >= 0) {
          this.chartDatasets.splice(chartDatasetIndex, 1);
        }
      }
      for (const unitAxis of Array.from(item.axes.values())) {
        const unitAxes = this.axesByUnit.get(unitAxis.unit);
        if (unitAxes) {
          unitAxes.delete(unitAxis);
          if (unitAxes.size < 1) {
            this.axesByUnit.delete(unitAxis.unit);
          }
        }

        const chartYAxisIndex = this.chartYAxes.indexOf(unitAxis.chartYAxis);
        if (chartYAxisIndex >= 0) {
          this.chartYAxes.splice(chartYAxisIndex, 1);
        }
        // TODO set new left axis and update grid lines
        /*
        if (!axisIndex && this.compareItems.length) {
            this.compareItems[0].chartYAxis.position = 'left';
            this.compareItems[0].chartYAxis.gridLines.drawOnChartArea = true;
          }
         */
      }
      if (!this.compareItems.length) {
        this.rangeControl.setValue(this.getDefaultRange());
        this.aggregationGroupingType$.next('HOURLY');
      }
      item.destory$.next();
      item.destory$.complete();
      this.chartChanges$.next();
    }

    this.updateCount();
  }

  public createCompareItemDataset(item: CompareItem, id: number, name: string, active: boolean, loadFn: any) {
    const initialGraphType = item.graphTypeControl.value ? item.graphTypeControl.value : 'line';
    const dataset: CompareItemDataset = {
      id: id,
      item: item,
      axis: null,
      min: Infinity,
      max: -Infinity,
      chartDataset: {
        data: [],
        label: name,
        type: initialGraphType,
        fill: false,
        yAxisID: null,
        hidden: !active,
        lineTension: 0,
        pointStyle: this.chartDataService.pointStyles[this.nextPointStyleIndex],
      }
    };


    this.nextPointStyleIndex = (this.nextPointStyleIndex + 1) % this.chartDataService.pointStyles.length;

    item.datasets.add(dataset);
    this.chartDatasets.push(dataset.chartDataset);

    combineLatest([
      this.filters$.pipe(switchMap((filters) => {
        dataset.min = Infinity;
        dataset.max = -Infinity;

        this.updateUnitsAndAxes(dataset, dataset.item.unit, dataset.item.groupByUnit.value, dataset.item.groupNameControl.value);

        dataset.chartDataset.data = null;

        this.chartChanges$.next();

        this.incrementLoaders();

        return loadFn(filters);
      }), tap(() => {
        this.decrementLoaders();
      })),
      item.aggregationTypeControl.valueChanges.pipe(startWith('AVERAGE')),
      item.graphTypeControl.valueChanges.pipe(startWith(initialGraphType as string)),
      item.groupByUnit.valueChanges.pipe(startWith(true)),
      item.groupNameControl.valueChanges.pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        startWith(null))
    ]).pipe(takeUntil(item.destory$), takeUntil(this.ngDestroy))
      .subscribe(([result, aggregationType, graphType, groupByUnit, groupName]: [any, string, string, boolean, string]) => {
        const mapping = this.chartDataService.mapChartData(
          result.historicalResponse,
          aggregationType,
          result.filters.aggregationGroupingType
        );

        if (!mapping.unit) {
          mapping.unit = item.unit;
        }

        dataset.min = mapping.min;
        dataset.max = mapping.max;

        const unit = mapping.unit;

        this.updateUnitsAndAxes(dataset, unit, groupByUnit, groupName);

        dataset.chartDataset.data = mapping.data.length ? mapping.data : null;
        dataset.chartDataset.type = graphType;

        this.chartChanges$.next();
      });

    this.updateCount();

    return dataset;
  }

  deleteCompareItemDataset(dataset: CompareItemDataset) {
    const item = dataset.item;
    if (item.datasets.delete(dataset)) {
      const chartDatasetIndex = this.chartDatasets.indexOf(dataset.chartDataset);
      if (chartDatasetIndex >= 0) {
        this.chartDatasets.splice(chartDatasetIndex, 1);
      }
      if (dataset.axis) {
        this.removeDatasetFromAxis(dataset);
      }

      this.chartChanges$.next();

      this.updateCount();
    }
  }

  public updateCount() {
    this.count$.next(this.compareItems.filter((e) => e.datasets.size).length);
  }

  updateUnitsAndAxes(dataset: CompareItemDataset, unit: string, groupByUnit: boolean, groupName: string) {
    const item = dataset.item;

    let compareAxis = null;

    let updateItemUnitLabel = false;

    let axisId = groupName + '-' + unit;
    if (dataset.axis) {
      if (dataset.axis.id === axisId) {
        compareAxis = dataset.axis;
      } else {
        this.removeDatasetFromAxis(dataset);
        updateItemUnitLabel = true;
      }
    }

    if (!compareAxis) {
      compareAxis = item.axes.get(axisId);
      if (!compareAxis) {
        compareAxis = {
          id: axisId,
          unit: unit,
          name: groupName,
          min: Infinity,
          max: -Infinity,
          chartYAxis: {
            id: (groupName ? groupName : 'y-axis-ci-' + this.nextYAxisId++),
            scaleLabel: {
              display: 'auto',
              labelString: (groupName ? groupName : item.name) + " (" + unit + ")", //FIXME: first dataset unit, although item can have multiple datasets with different unit
              labels: {
                show: true
              }
            },
            display: 'auto',
            ticks: {},
            position: 'left', // this.compareItems.length ? 'right' : 'left',
            gridLines: {
              drawOnChartArea: !this.compareItems.length
            }
          },
          datasets: new Set<CompareItemDataset>()
        };
        this.chartYAxes.push(compareAxis.chartYAxis);
        item.axes.set(axisId, compareAxis);
        updateItemUnitLabel = true;
      }

      dataset.axis = compareAxis;
      dataset.chartDataset.yAxisID = compareAxis.chartYAxis.id;
      compareAxis.datasets.add(dataset);
    }

    compareAxis.min = Infinity;
    compareAxis.max = -Infinity;
    for (const e of Array.from(compareAxis.datasets)) {
      const axisDataset: CompareItemDataset = e as CompareItemDataset;
      if (axisDataset.min < compareAxis.min) {
        compareAxis.min = axisDataset.min;
      }
      if (axisDataset.max > compareAxis.max) {
        compareAxis.max = axisDataset.max;
      }
    }

    if (unit) {
      let unitAxes = this.axesByUnit.get(unit);
      if (!unitAxes) {
        unitAxes = new Set<CompareAxis>();
        this.axesByUnit.set(unit, unitAxes);
      }

      if (groupByUnit) {
        unitAxes.add(compareAxis);
      } else {
        unitAxes.delete(compareAxis);

        compareAxis.chartYAxis.ticks.suggestedMin = compareAxis.min;
        compareAxis.chartYAxis.ticks.suggestedMax = compareAxis.max;
      }

      let min = Infinity;
      let max = -Infinity;
      for (const axis of Array.from(unitAxes)) {
        if (axis.min < min) {
          min = axis.min;
        }
        if (axis.max > max) {
          max = axis.max;
        }
      }
      for (const axis of Array.from(unitAxes)) {
        axis.chartYAxis.ticks.suggestedMin = min;
        axis.chartYAxis.ticks.suggestedMax = max;
      }
    } else {
      compareAxis.chartYAxis.ticks.suggestedMin = compareAxis.min;
      compareAxis.chartYAxis.ticks.suggestedMax = compareAxis.max;
    }

    if (updateItemUnitLabel) {
      item.displayUnit = Array.from(item.axes.values()).map((e) => e.unit).join(', ');
    }

    return compareAxis;
  }

  removeDatasetFromAxis(dataset: CompareItemDataset) {
    const item = dataset.item;

    const axis = dataset.axis;

    // datasets of null
    axis.datasets.delete(dataset);
    axis.min = Infinity;
    axis.max = -Infinity;

    if (!axis.datasets.size) {
      item.axes.delete(axis.id);

      const axisIndex = this.chartYAxes.indexOf(axis.chartYAxis);
      if (axisIndex >= 0) {
        this.chartYAxes.splice(axisIndex, 1);
        // TODO set new left axis and update grid lines
        /*if (!axisIndex && this.compareItems.length) {
          this.compareItems[0].chartYAxis.position = 'left';
          this.compareItems[0].chartYAxis.gridLines.drawOnChartArea = true;
        }*/
      }
    } else {
      for (const d of Array.from(axis.datasets.values())) {
        if (d.min < axis.min) {
          axis.min = d.min;
        }
        if (d.max > axis.max) {
          axis.max = d.max;
        }
      }
    }

    dataset.axis = null;
    dataset.chartDataset.yAxisID = undefined;
  }

  public incrementLoaders() {
    this.loadingCount++;
    this.loadingCount$.next(this.loadingCount);
  }

  public decrementLoaders() {
    this.loadingCount--;
    this.loadingCount$.next(this.loadingCount);
  }

  public toggleItem(item: CompareItem) {
    item.active = !item.active;
    for (const dataset of Array.from(item.datasets.values())) {
      dataset.chartDataset.hidden = !item.active;
    }
    this.chartChanges$.next();
  }

  public clear(): void {
    for (const item of this.compareItems) {
      item.destory$.next();
      item.destory$.complete();
    }
    this.compareItems.splice(0, this.compareItems.length);
    this.chartDatasets.splice(0, this.chartDatasets.length);
    this.chartYAxes.splice(0, this.chartYAxes.length);
    this.rangeControl.setValue(this.getDefaultRange());
    this.aggregationGroupingType$.next('HOURLY');
  }

  public close() {
    this.closeEvent$.next();
  }

  public setFooterTemplate(footerTemplate: TemplateRef<any>) {
    this.footerTemplate = footerTemplate;
    this.footerTemplate$.next(footerTemplate);
  }
}
