import {
  Component,
  OnInit,
  NgZone,
  Input,
  OnDestroy,
  OnChanges,
  SimpleChanges,
  ViewChild,
  TemplateRef,
  AfterViewInit,
  ChangeDetectorRef
} from '@angular/core';
import {BicycleStationsService} from './bicycle-stations.service';
import {BicycleStationsHistogram, BicycleStation, BicycleStationsPage, BicycleStationCounts} from './bicylce-stations.model';
import {DatePipe} from '@angular/common';
import MapsEventListener = google.maps.MapsEventListener;
import {BicycleStationsLayer} from '../../layers/bicycle-stations-gmap-layer';
import {MobilityService} from '../../service/mobility.service';
import {UuidHelper} from '@smartencity/core';
import {MobilityApiService} from '../../http/mobility-api.service';

@Component({
  selector: 'mobility-bicycle-stations-heatmap',
  templateUrl: './bicycle-stations-heatmap.component.html',
  providers: [
    BicycleStationsService,
    DatePipe
  ]
})
export class BicycleStationsHeatmapComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input()
  map: google.maps.Map;

  @ViewChild('playbackTemplate', {static: true})
  public playbackTemplate: TemplateRef<any>;

  public bicycleStationsLayer: BicycleStationsLayer;

  zoomMap: any = {
    1: 1, 2: 1, 3: 1,
    4: 2, 5: 2,
    6: 4, 7: 4,
    8: 5, 9: 5,
    10: 7, 11: 7,
    12: 9,
    13: 12, 14: 12,
    15: 15, 16: 15,
    17: 20, 18: 20,
    19: 38, 20: 40
  };

  heatmap: google.maps.visualization.HeatmapLayer;

  personSeriesList: BicycleStation[] = [];
  stationCountsMap = new Map<string, BicycleStationCounts>();

  public pageSize = 150;
  public currentPage;
  public totalPages;

  public histogram: BicycleStationsHistogram = null;
  public playValue = 48;
  public isPlaying = false;

  public mapEventIdle: MapsEventListener;
  public mapEventZoom: MapsEventListener;

  constructor(
    public bicycleStationsService: BicycleStationsService,
    public mobilityService: MobilityService,
    public zone: NgZone,
    private cdr: ChangeDetectorRef,
    private mobilityApiService: MobilityApiService
  ) {
  }

  ngOnInit() {
    const conf = this.bicycleStationsService.getMapsConfig();

    this.heatmap = new google.maps.visualization.HeatmapLayer({
      data: [],
      radius: 70,
      maxIntensity: 1000,
      map: this.map
    });

    this.loadHistogram();

    this.loadBicycleStations(0);

    // TODO: move bicycle stations layer and data logic from component into separate service
    this.mobilityService.loadAllBicycleStations();

    this.mobilityService.selectedBicycleStation$.subscribe((station: BicycleStation) => {
      if (this.bicycleStationsLayer) {
        this.bicycleStationsLayer.showStation(station);
      }
    });

  }

  ngOnDestroy(): void {
    this.setMap(null);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.map) {
      this.setMap(this.map);
    }
  }

  setMap(map: google.maps.Map) {
    if (this.mapEventIdle) {
      this.mapEventIdle.remove();
    }
    if (this.mapEventZoom) {
      this.mapEventZoom.remove();
    }
    if (this.heatmap) {
      this.heatmap.setMap(map);
    }
    if (this.bicycleStationsLayer) {
      this.bicycleStationsLayer.setMap(map);
    }
    if (map) {
      this.setHeatmapRadius();
      this.mapEventZoom = google.maps.event.addListener(this.map, 'zoom_changed', () => {
        this.zone.run(() => {
          this.setHeatmapRadius();
        });
      });
    }
  }

  setHeatmapRadius() {
    if (this.heatmap) {
      const zoom = this.map.getZoom();
      this.heatmap.set('radius', this.zoomMap[zoom] * 5);
    }
  }

  public loadBicycleStations(iPage: number) {


    this.mobilityApiService.getBicycleStations({size: this.pageSize, page: iPage, sort: 'personSeriesId'}).subscribe(
      (page: BicycleStationsPage) => {
        this.totalPages = page.totalPages;
        this.currentPage = page.page;

        this.personSeriesList = this.personSeriesList.concat(page.content);

        if (this.totalPages > this.currentPage) {
          this.loadBicycleStations(this.currentPage + 1);
        } else {
          this.updateStations();
        }

        this.updateHeatmap();
      },
      (err: any) => {
      }
    );
  }

  updateStations() {
    this.stationCountsMap = new Map<string, BicycleStationCounts>();
    for (const ps of this.personSeriesList) {
      let uuid: string;
      let key: string;
      if (ps.uuid.endsWith('primary-locked-cycle-count')) {
        key = 'primary-locked-cycle-count';
        uuid = UuidHelper.extractFromStart(ps.uuid);
      } else if (ps.uuid.endsWith('secondary-locked-cycle-count')) {
        key = 'secondary-locked-cycle-count';
        uuid = UuidHelper.extractFromStart(ps.uuid);
      } else if (ps.uuid.endsWith('free-docks-count')) {
        key = 'free-docks-count';
        uuid = UuidHelper.extractFromStart(ps.uuid);
      } else if (ps.uuid.endsWith('free-spaces-count')) {
        key = 'free-spaces-count';
        uuid = UuidHelper.extractFromStart(ps.uuid);
      }
      if (uuid) {
        let stationCounts: BicycleStationCounts = this.stationCountsMap.get(uuid);
        if (!stationCounts) {
          const nameParts: string[] = ps.name.split(' - ');
          stationCounts = new BicycleStationCounts();
          stationCounts.name = nameParts.length > 1 ? nameParts.slice(0, -1).join(' - ') : nameParts[0];
          stationCounts.locationName = nameParts.length > 1 ? nameParts.slice(0, -1)[0].split('_')[2] : nameParts[0];
          stationCounts.lat = ps.lat;
          stationCounts.lng = ps.lng;
          stationCounts.primaryLockedCount = 0;
          stationCounts.freeDocksCount = 0;
          stationCounts.freeSpacesCount = 0;
          stationCounts.uuid = uuid;
          this.stationCountsMap.set(uuid, stationCounts);
        }
        if (key === 'primary-locked-cycle-count') {
          stationCounts.primaryLockedCyclePsId = ps.id;
          stationCounts.primaryLockedCount = ps.value;
        } else if (key === 'secondary-locked-cycle-count') {
          stationCounts.secondaryLockedCyclePsId = ps.id;
          stationCounts.secondaryLockedCount = ps.value;
        } else if (key === 'free-docks-count') {
          stationCounts.freeDocksPsId = ps.id;
          stationCounts.freeDocksCount = ps.value;
        } else if (key === 'free-spaces-count') {
          stationCounts.freeSpacesPsId = ps.id;
          stationCounts.freeSpacesCount = ps.value;
        }
      }
    }

    if (this.bicycleStationsLayer) {
      this.bicycleStationsLayer.setMap(null);
    }
    this.bicycleStationsLayer = new BicycleStationsLayer(Array.from(this.stationCountsMap.values()));
    this.bicycleStationsLayer.setMap(this.map);
  }

  updateHeatmap() {
    const maxValue = 1.0;
    const heatmapData = Array.from(this.stationCountsMap.values()).map((stationCounts: BicycleStationCounts) => {
      return {
        location: new google.maps.LatLng(stationCounts.lat, stationCounts.lng),
        weight: (stationCounts.freeDocksCount) / (stationCounts.primaryLockedCount + stationCounts.freeDocksCount),
        id: 1
      };
    });
    this.heatmap.set('maxIntensity', maxValue * 0.75);
    this.heatmap.setData(heatmapData);
  }

  loadHistogram() {
    this.bicycleStationsService.getBicycleStationsHistogram().subscribe(
      (data: BicycleStationsHistogram) => {
        this.histogram = data;
      }
    );
  }

  togglePlay() {
    if (this.isPlaying === true) {
      this.isPlaying = false;
    } else {
      this.isPlaying = true;
      if (this.playValue === 48) {
        this.playValue = 0;
      }
      this.playFrame();
    }
  }

  playFrame() {
    this.loadFrame(this.playValue, () => {
      setTimeout(() => {
        if (this.isPlaying) {
          this.playValue = this.playValue + 1;
          if (this.playValue === 48) {
            this.isPlaying = false;
          } else {
            this.playFrame();
          }
        }
      }, 500);
    });
  }

  playChanged(index) {
    this.playValue = index;
    this.loadFrame(this.playValue, () => {
    });
  }

  loadFrame(index, cb) {
    const histItem = this.histogram.content[index];
    this.bicycleStationsService.getBicycleStationsState({date: histItem.date}).subscribe(stateResponse => {
      const state = stateResponse.state;
      Array.from(this.stationCountsMap.values()).forEach((stationCounts: BicycleStationCounts) => {
        if (stationCounts.primaryLockedCyclePsId) {
          stationCounts.primaryLockedCount = state[stationCounts.primaryLockedCyclePsId];
        } else {
          stationCounts.primaryLockedCount = 0;
        }
        if (stationCounts.secondaryLockedCyclePsId) {
          stationCounts.secondaryLockedCount = state[stationCounts.secondaryLockedCyclePsId];
        } else {
          stationCounts.secondaryLockedCount = 0;
        }
        if (stationCounts.freeDocksPsId) {
          stationCounts.freeDocksCount = state[stationCounts.freeDocksPsId];
        } else {
          stationCounts.freeDocksCount = 0;
        }
        if (stationCounts.freeSpacesPsId) {
          stationCounts.freeSpacesCount = state[stationCounts.freeSpacesPsId];
        } else {
          stationCounts.freeSpacesCount = 0;
        }
      });
      this.updateHeatmap();
      cb();
    });
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }
}
