import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {AuditLog, AuditLogService, AuditLogType, EnumsService, LabelPipe, PageResponse, UserService} from '@smartencity/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {VirtualScrollerComponent} from 'ngx-virtual-scroller';
import {PaginationComponent} from 'ngx-bootstrap/pagination';
import {FormArray, FormControl, FormGroup} from '@angular/forms';
import {debounceTime, distinctUntilChanged, map, shareReplay, startWith, switchMap, takeUntil, withLatestFrom} from 'rxjs/operators';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';
import {IPageInfo} from 'ngx-virtual-scroller/virtual-scroller';
import {merge, Observable, Subject} from 'rxjs';

export class AuditLogFilterValues {

  public static queryCriteriaList: string[] = ['NAME', 'PERSON', 'TARGET_PERSON'];

  public static operations: string[] = ['CREATE', 'UPDATE', 'DELETE'];

  public static consentTypes: string[] = ['PERSON_SERIES', 'PERSON_SERIES_GROUP'];

  public static consentStatusTypes: string[] = ['PENDING', 'ACCEPTED', 'DECLINED', 'CANCELLED'];

}

@Component({
  selector: 'app-mandate-log-modal',
  templateUrl: './mandate-log-modal.component.html'
})
export class MandateLogModalComponent implements OnInit {

  private ngDestroy = new Subject<void>();

  @Input()
  public type: AuditLogType;

  public auditLogTypeEnum = AuditLogType;

  public loading = true;

  @ViewChild('listScroll')
  private listScroll: VirtualScrollerComponent;

  @ViewChild('listPaginationComponent')
  private listPaginationComponent: PaginationComponent

  public items: AuditLog[] = [];

  private pageSize = 100;

  bufferRows = 10;

  queryCriteriaListOptions: {value,label}[] = [];

  operationsOptions: {value,label}[] = [];

  consentTypesOptions: {value,label}[] = [];

  consentStatusTypesOptions: {value,label}[] = [];

  private fetchedPages = new Set<number>();

  public filterForm: FormGroup = new FormGroup({
    query: new FormControl(null),
    queryCriteriaList: new FormArray([]),
    operations: new FormArray([]),
    consentTypes: new FormArray([]),
    consentStatusTypes: new FormArray([])
  });

  public filter$ = this.filterForm.valueChanges.pipe(distinctUntilChanged(), debounceTime(250), startWith({}));

  public pageSubject: Subject<number> = new Subject<number>();
  public page$: Subject<any> = new Subject<any>();
  public limit$: Subject<any> = new Subject<any>();

  public filterLimit$ = combineLatest([
    this.filter$,
    this.limit$.asObservable().pipe(startWith(this.pageSize))
  ]);

  public queryParams$ = merge(
    this.filterLimit$.pipe(map(([filter, limit]: [any, number]) => [filter, limit, {page: 0, limit: limit}])),
    this.page$.pipe(
      withLatestFrom(this.filterLimit$),
      map(([page, [filter, limit]]: [any, [any, number]]) => [filter, limit, page])
    )
  ).pipe(
    map(([filter, limit, page]) => this.mapQueryParams(filter, page, limit)),
    shareReplay(1)
  );

  public pageResponse$ = this.queryParams$.pipe(switchMap(queryParams => {
      return this.fetchPage(queryParams);
    }),
    shareReplay(1),
    takeUntil(this.ngDestroy)
  );

  private mapQueryParams(filter: any, pageNumber: number, limit: number) {
    const params: any = {
      page: pageNumber,
      size: limit,
      query: '',
      type: this.type
    };

    if (filter.query) {
      params.query = filter.query;
    }

    if (filter.queryCriteriaList && filter.queryCriteriaList.length > 0) {
      filter.queryCriteriaList.forEach((checked, i) => {
        if (checked) {
          if (!params.queryCriteriaList) {
            params.queryCriteriaList = [];
          }
          params.queryCriteriaList.push(this.queryCriteriaListOptions[i].value);
        }
      });
    }

    if (filter.operations && filter.operations.length > 0) {
      filter.operations.forEach((checked, i) => {
        if (checked) {
          if (!params.operations) {
            params.operations = [];
          }
          params.operations.push(this.operationsOptions[i].value);
        }
      });
    }

    if (filter.consentTypes && filter.consentTypes.length > 0) {
      filter.consentTypes.forEach((checked, i) => {
        if (checked) {
          if (!params.consentTypes) {
            params.consentTypes = [];
          }
          params.consentTypes.push(this.consentTypesOptions[i].value);
        }
      });
    }

    if (filter.consentStatusTypes && filter.consentStatusTypes.length > 0) {
      filter.consentStatusTypes.forEach((checked, i) => {
        if (checked) {
          if (!params.consentStatusTypes) {
            params.consentStatusTypes = [];
          }
          params.consentStatusTypes.push(this.consentStatusTypesOptions[i].value);
        }
      });
    }

    return params;
  }

  pageResponse: PageResponse<any>;

  constructor (
    public modalRef: BsModalRef,
    private userService: UserService,
    private labelPipe: LabelPipe,
    public auditLogService: AuditLogService,
    private enumService: EnumsService,
  ) { }

  initForm(): void {
    this.queryCriteriaListOptions = this.enumService.getOptionsList(AuditLogFilterValues.queryCriteriaList
      .filter(c => this.type != AuditLogType.MANDATE || c != 'NAME'), 'LOG_QUERY_CRITERIA_');
    this.operationsOptions = this.enumService.getOptionsList(AuditLogFilterValues.operations, 'ACTION_');
    this.consentTypesOptions = this.enumService.getOptionsList(AuditLogFilterValues.consentTypes, 'CONSENT_LOG_TYPE_');
    this.consentStatusTypesOptions = this.enumService.getOptionsList(AuditLogFilterValues.consentStatusTypes);
    this.queryCriteriaListOptions.forEach(() => this.queryCriteriaListControl.push(new FormControl(false)));
    this.operationsOptions.forEach(() => this.operationsControl.push(new FormControl(false)));
    this.consentTypesOptions.forEach(() => this.consentTypesControl.push(new FormControl(false)));
    this.consentStatusTypesOptions.forEach(() => this.consentStatusTypesControl.push(new FormControl(false)));
  }

  get queryCriteriaListControl() {
    return this.filterForm.get("queryCriteriaList") as FormArray;
  }

  get operationsControl() {
    return this.filterForm.get("operations") as FormArray;
  }

  get consentTypesControl() {
    return this.filterForm.get("consentTypes") as FormArray;
  }

  get consentStatusTypesControl() {
    return this.filterForm.get("consentStatusTypes") as FormArray;
  }

  ngOnInit(): void {
    this.initForm();

    this.pageResponse$.pipe(takeUntil(this.ngDestroy)).subscribe((pageResponse: PageResponse<AuditLog>) => {
      this.pageResponse = pageResponse;
      if (!this.items || this.items.length != pageResponse.totalElements) {
        this.items = Array.from({length:pageResponse.totalElements});
      }
      this.items.splice(pageResponse.page * pageResponse.size, pageResponse.size, ...pageResponse.content);
      if (!this.fetchedPages) {
        this.fetchedPages = new Set<number>();
      }

      if (pageResponse.totalPages < this.fetchedPages.size) {
        this.fetchedPages.clear();
      }
      this.fetchedPages.add(pageResponse.page);

      this.loading = false;
    });
  }

  chartVsChange(event: IPageInfo) {
    if (event.startIndex < 0 || event.endIndex < 0) {
      return;
    }

    if (event.startIndex <= 1) {
      if (this.listPaginationComponent) {
        this.listPaginationComponent.selectPage(0);
      }
    }

    let startPage = Math.floor((event.startIndex / this.pageSize));
    let endPage = Math.floor((event.endIndex / this.pageSize));
    if (startPage < 0) {
      startPage = 0;
    }
    if (endPage < 0) {
      endPage = 0;
    }

    for (let i = startPage; i <= endPage; i++) {
      if (this.fetchedPages.has(i)) {
        continue;
      }

      this.page$.next(i);
    }
  }

  clearSearch(): void {
    this.filterForm.get('query').setValue('');
  }

  private fetchPage(params: any): Observable<PageResponse<AuditLog>> {
    return this.auditLogService.getLogs(params);
  }

  close(): void {
    this.modalRef.hide();
  }

}
