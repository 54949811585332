import {Inject, Injectable} from '@angular/core';
import {SMARTENCITY_MYDATA_CONFIG} from '../../../../mydata/src/lib/injection-tokens';
import {MyDataConfig} from '../../../../mydata/src/lib/mydata-config.model';
import {HttpClient} from '@angular/common/http';
import {PageResponse} from '../models/page-response';
import {SourceOwnerSeries} from '../models/series';
import {Observable} from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class DataOwnerSeriesService {

  constructor(@Inject(SMARTENCITY_MYDATA_CONFIG) private config: MyDataConfig, private http: HttpClient) { }

  public getDataOwnerSeriesPage(queryParams: any): Observable<PageResponse<SourceOwnerSeries>> {

    return this.http.get<PageResponse<SourceOwnerSeries>>(this.config.apiUrl + '/data-owner-series/', {
      params: DataOwnerSeriesService.prepareQueryParams(queryParams)
    })
  }

  private static prepareQueryParams(queryParams: any): any {
    const params = Object.assign({}, queryParams);
    for (const i of Object.keys(params)) {
      if (params[i] == null) {
        delete params[i];
      }
    }

    return params;
  }

}
