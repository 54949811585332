import {Inject, Injectable, OnDestroy} from '@angular/core';
import {HttpClient, HttpResponse, HttpXhrBackend} from '@angular/common/http';
import { Observable} from 'rxjs';
import { takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs/internal/Subject';
import { Widget } from 'projects/mydata/src/lib/components/dashboard/widget';
import { MyDataConfig } from 'projects/mydata/src/lib/mydata-config.model';
import { SMARTENCITY_MYDATA_CONFIG } from 'projects/mydata/src/public-api';
import { Dashboard, DashboardFilter, DashboardType } from 'projects/mydata/src/lib/components/dashboard/dashboard';
import {ReplaySubject} from 'rxjs/internal/ReplaySubject';
import { GridsterItem } from 'angular-gridster2';
import {saveAs} from 'file-saver';
import {ChartService} from '@smartencity/core';

@Injectable()
export class PublicDataService implements OnDestroy {
  private ngDestroy = new Subject<void>();

  public widgets$ = new Subject<Widget[]>();
  public widgetItems$ = new ReplaySubject <Array<GridsterItem>>(1);
  public widgetItems: Array<GridsterItem> = [];

  private componentItems = new Map<string, any>();
  private componentItemsSubject = new Subject<Map<string, any>>();
  public componentItems$ = this.componentItemsSubject.asObservable();

  private currentDashboard: Dashboard = null;
  public dashboardFilter: DashboardFilter = null;

  private http: HttpClient;

  constructor(
    private httpBackend: HttpXhrBackend,
    @Inject(SMARTENCITY_MYDATA_CONFIG) private config: MyDataConfig,
  ) {
   this.http = new HttpClient(httpBackend);
    this.widgets$.subscribe((widgets: Widget[]) => {
      for (const widgetItem of this.widgetItems) {
        widgetItem.update.complete();
      }
      this.widgetItems.length = 0;
      this.componentItems.clear();
      for (const widget of widgets) {
        let item;
        if (widget.position) {
          item = {
            cols: widget.position.cols,
            rows: widget.position.rows,
            y: widget.position.y,
            x: widget.position.x,
            itemType: 'DASHBOARD',
            componentTemplate: null,
            widget: widget,
            update: new Subject<void>()
          };
        }
        this.widgetItems.push(item);
      }
      this.widgetItems$.next(this.widgetItems);
      this.componentItemsSubject.next(this.componentItems);
    });

  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
    this.componentItemsSubject.complete();
  }

  public getDashboards(): Observable<Dashboard[]> {
    return this.http.get<Dashboard[]>(this.config.cityApiUrl + '/dashboard');
  }

  public setCurrentDashboard(dashboard: Dashboard) {
    this.currentDashboard = dashboard;
  }

  public getCurrentDashboard(): Dashboard {
    return this.currentDashboard;
  }

  public initialize(){
      this.reload();
  }

  public reload(): void {
    if(!this.currentDashboard) {
      return;
    }

    const dashboardFilter = new DashboardFilter(DashboardType.CUSTOM);
    dashboardFilter.dashboardId = this.currentDashboard.id;
    this.dashboardFilter = dashboardFilter;
    this.loadWidgets(dashboardFilter);
  }

  loadWidgets(dashBoardFilter: DashboardFilter) {
    this.getWidgets(dashBoardFilter).pipe(takeUntil(this.ngDestroy)).subscribe((widgets: Widget[]) => {
      this.widgets$.next(widgets);
    });
  }

  getWidgets(dashBoardFilter: DashboardFilter): Observable<Widget[]> {
    const params: any = {};
    return this.http.get<Widget[]>(this.config.cityApiUrl + '/dashboard/' + dashBoardFilter.dashboardId + '/widget', {params: params});
  }

  downloadDashboardPdf(): void {

    this.http.post(this.config.cityApiUrl + '/dashboard/pdf', this.dashboardFilter, {
      observe: 'response',
      responseType: 'blob'
    }).subscribe((response: HttpResponse<Blob>) => {
      const fileName = 'dashboard.pdf';
      const blob = new Blob([response.body], {type: 'application/octet-stream'});
      saveAs(blob, fileName);
    }, (error) => {
      console.error(error);
    })
  }

  downloadDashboardExcel(): void {

    this.http.post(this.config.cityApiUrl + '/dashboard/excel', this.dashboardFilter, {
      observe: 'response',
      responseType: 'blob'
    }).subscribe((response: HttpResponse<Blob>) => {
      const fileName = 'widget.xlsx';
      const blob = new Blob([response.body], {type: 'application/octet-stream'});
      saveAs(blob, fileName);
    });
  }

  downloadWidgetExcel(widgetId: number): void {

    this.http.post(this.config.cityApiUrl + '/dashboard/widget/excel?widgetId=' + widgetId, this.dashboardFilter, {
      observe: 'response',
      responseType: 'blob'
    }).subscribe((response: HttpResponse<Blob>) => {
      const fileName = 'widget.xlsx';
      const blob = new Blob([response.body], {type: 'application/octet-stream'});
      saveAs(blob, fileName);
    });
  }

  downloadWidgetPng(widget: Widget, chart): void {
    ChartService.downloadPng(chart, widget.name);
  }
}
