import {Component, OnInit} from '@angular/core';
import {BuildingsSearchResults} from '../../model/search/buildings-search-results';
import { BuildingsSearchService } from '../../service/buildings-search.service';
import {FormControl} from '@angular/forms';
import {debounceTime, distinctUntilChanged, takeUntil, tap} from 'rxjs/operators';
import {WindTurbine} from '../../model/wind-turbine';
import {BuildingsService} from '../../service/buildings.service';
import {Subject} from 'rxjs/internal/Subject';
import {BuildingsGmapService} from '../../service/buildings-gmap.service';
import {Building} from '../../model/building';

@Component({
  selector: 'buildings-search',
  templateUrl: './search.component.html'
})
export class SearchComponent implements OnInit {

  private ngDestroy = new Subject<void>();

  private searching = false;

  public keywordControl = new FormControl();
  public keywordSearch$ = this.keywordControl.valueChanges.pipe(
    tap((value: string) => {
      if (!this.searching && value !== '') {
        this.searching = true;
        this.buildingsService.clearSelected();
      }
      if (this.searching && value === '') {
        this.searching = false;
      }
    }),
    debounceTime(300),
    distinctUntilChanged()
  );

  public buildings: Building[] = [];
  public windTurbines: WindTurbine[] = [];

  constructor(private buildingsSearchService: BuildingsSearchService,
              private buildingsService: BuildingsService,
              private buildingMapService: BuildingsGmapService) {
  }

  clear() {
    this.buildings = [];
    this.windTurbines = [];
    this.keywordControl.setValue('');
  }

  ngOnInit() {

    this.buildingsSearchService.initSearch(this.keywordSearch$).pipe(takeUntil(this.ngDestroy)).subscribe((result: BuildingsSearchResults) => {
      this.buildings = result.buildings;
      this.windTurbines = result.windTurbines;
    });

    this.buildingMapService.locationSelect$.subscribe(() => {
      this.clear();
    });

  }

  selectBuilding(building: Building) {
    this.clear();
    this.buildingsService.selectBuilding(building);
  }

  selectWindTurbine(windTurbine: WindTurbine) {
    this.clear();
    this.buildingsService.selectWindTurbine(windTurbine);
  }

}
