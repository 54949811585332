import {Component, Input, OnDestroy} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {PersonSeriesGroup, PersonSeriesPeriod} from '@smartencity/core';
import {Subject} from 'rxjs/internal/Subject';
import {catchError, debounceTime, distinctUntilChanged, startWith, switchMap, takeUntil, tap} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import {concat} from 'rxjs';
import {PersonSeriesGroupApiService} from '../../http/person-series-group-api.service';

const selector = 'person-series-group-select';
let nextId = 0;
@Component({
  selector: selector,
  templateUrl: './person-series-group-select.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: PersonSeriesGroupSelectComponent,
      multi: true
    }
  ]
})
export class PersonSeriesGroupSelectComponent implements ControlValueAccessor, OnDestroy {

  id = `${selector}-${nextId++}`;

  public ngDestroy = new Subject<void>();

  public groupTypeahead$ = new Subject<string>();

  public groupsLoading = false;

  @Input()
  personSeriesPeriod?: PersonSeriesPeriod;

  public groups$ = concat(
    of([]),
    this.groupTypeahead$.pipe(
      takeUntil(this.ngDestroy),
      startWith(''),
      distinctUntilChanged(),
      tap(() => this.groupsLoading = true),
      debounceTime(250),
      switchMap((term: string) => this.personSeriesGroupService.searchGroups({query: term, ownerTypes: ['OWNER']}).pipe(
        catchError(() => of([])), // empty list on error
        tap(() => this.groupsLoading = false)
      ))
    )
  );

  _onChange: any;
  _onTouched: any;
  _disabled: boolean;
  _value: any;

  constructor(private personSeriesGroupService: PersonSeriesGroupApiService) {  }

  ngOnInit(): void {
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  trackByFn(obj): any {
    return obj.id;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this._disabled = isDisabled;
  }

  writeValue(obj: any): void {
    this._value = obj;
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  isInvalid(): boolean{
    return false;
  }

  compareGroups(a: any, b: any): boolean {
    return a && b && a.id === b.id;
  }

  isDisabled(item: PersonSeriesGroup): boolean {
    return this.personSeriesPeriod && this.personSeriesPeriod.consentGroupId === item.id;
  }

}
