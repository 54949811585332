import {Observable} from 'rxjs/internal/Observable';
import {Injectable} from '@angular/core';
import {HttpBackend, HttpClient} from '@angular/common/http';
import {ArcGisRestClient} from './arc-gis-rest-client';
import {FeatureLayerInfo, LegendInfo, MapServerInfo} from './ol-map-wrapper';

@Injectable({
  providedIn: 'root'
})
export class ArcGisRestService implements ArcGisRestClient {

  private http: HttpClient;

  constructor(private handler: HttpBackend) {
    this.http = new HttpClient(handler);
  }

  public getMapServerInfo(mapServerUrl: string): Observable<MapServerInfo> {

    return this.http.get<MapServerInfo>(mapServerUrl + "?f=json");
  }

  public getLegend(mapServerUrl: string): Observable<LegendInfo> {

    return this.http.get<LegendInfo>(mapServerUrl + "/legend?f=json");
  }

  public getFeatureLayerInfo(featureServerUrl: string, layerId: number): Observable<FeatureLayerInfo> {

    return this.http.get<FeatureLayerInfo>(featureServerUrl + "/" + layerId + "?f=json");
  }


}
