import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ParametersService {

  public typeLabels: any;

  constructor() {
    this.typeLabels = {
      CLOSED_NET_AREA: $localize`Net closed area`,
      HEATED_AREA: $localize`Heated area`,
      DWELLINGS_AREA: $localize`Surface of dwellings`,
      CONSTRUCTION_YEAR: $localize`Year of construction`,
      DEGREE_DAYS_AREA: $localize`Degree days area`,
      POPULATION: $localize`Population`,
      FLOORS_COUNT: $localize`Number of floors`,
      APARTMENTS_COUNT: $localize`Number of apartments`,
      USAGE_TYPE: $localize`Use of the building`,
      STAIRWELLS_COUNT: $localize`Number of stairwells`,
      ENERGY_PERFORMANCE_LABEL: $localize`Energy performance label`,
      ENERGY_PERFORMANCE_NR: $localize`Energy label number`,
      BALANCE_TEMPERATURE: $localize`Equilibrium temple`,
      HEATING_SYSTEM: $localize`Heating system`,
      TYPE_OF_WATER_SUPPLY: $localize`Type of water supply`
    };
  }

  public getTypeLabel(key: any) {
    return this.typeLabels[key];
  }
}
