import {EvChargerDk} from '../model/ev-charger-dk';

export class EvChargersDkLayer extends google.maps.OverlayView {
  private div: any;

  public items: EvChargerDk[];
  private popoverTimeout = null;
  private selectedEvCharger: EvChargerDk;

  constructor(items: EvChargerDk[]) {
    super();
    this.items = items;
  }

  onAdd() {
    const panes = this.getPanes();
    if (panes == null) {
      return;
    }

    this.div = document.createElement('DIV');
    this.div.style.position = 'absolute';
    this.div.style.cursor = 'pointer';
    this.div.style.position = 'absolute';
    this.div.classList.add('ev-charger-dk-layer');
    this.div.style.paddingLeft = '0px';
    panes.overlayMouseTarget.appendChild(this.div);
  }

  onRemove() {
    if (this.div) {
      this.div.parentNode.removeChild(this.div);
      this.div = null;
    }
  }

  resetSelected(): void {
    this.selectedEvCharger = null;
  }

  draw() {
    if (!this.div) {
      return;
    }

    this.div.innerHTML = this.getLayerHtml();

    this.resetPopovers();
  }

  private resetPopovers() {
    $('.popover').remove();
    ($(this.div).find('[data-toggle="popover"]') as any)
      .popover('dispose')
      .popover({trigger: 'hover'});
  }

  private getLayerHtml() {
    let html = '';

    if (!this.items) {
      return html;
    }

    this.items.forEach((item) => {
      const point = this.getProjection().fromLatLngToDivPixel(new google.maps.LatLng(item.lat, item.lng));
      const x = point.x - 12;
      const y = point.y - 39;
      html += this.getChargerPointerHtml(item, x, y, (this.selectedEvCharger === item));
    });

    return html;
  }

  setMap(map) {
    super.setMap(map);
    if (map == null) {
      this.resetSelected();
    }
  }

  setSelected(evCharger: EvChargerDk): void {
    this.selectedEvCharger = evCharger
  }

  private getChargerPointerHtml(charger: EvChargerDk, x: number, y: number, selected = false): string {
    let className = 'pointer-charger';
    if (charger.status !== 'AVAILABLE') {
      className = 'pointer-charger-busy';
    }
    if (selected) {
      className += ' active';
    }

    const title = this.getChargerTitleHtml(charger);

    return `<div class="${className}" style="left: ${x}px; top: ${y}px" data-trigger="hover" data-toggle="popover" data-placement="top" data-html="true" data-content="${title}"></div>`;
  }

  private getChargerTitleHtml(charger: EvChargerDk): string {
    let titleParts: string[] = [
      charger.name ? charger.name : charger.address,
      '<br>',
      '<span class=\'small\'>',
      $localize`Availability:`,
      ' '
    ];
    if (charger.status === 'AVAILABLE') {
      titleParts.push(
        '<span class=\'text-success\'>',
        $localize`Available (${charger.nAvailable}/${charger.nConnectors})`,
        '</span>'
      );
    } else {
      titleParts.push(
        '<span class=\'text-warning\'>',
        $localize`Unavailable (${charger.nAvailable}/${charger.nConnectors})`,
        '</span>'
      );
    }

    titleParts.push('</span>');

    return titleParts.join('');
  }

}
