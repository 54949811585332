import { Injectable } from '@angular/core';
import {CacheService} from './cache.service';

@Injectable({
  providedIn: 'root'
})
export class LocalStoreCacheService extends CacheService {

  constructor() {
    super();
  }

  getValue(key: string): any {
    return localStorage.getItem(key);
  }

  setValue(key: string, value: any) {
    return localStorage.setItem(key, value);
  }

  removeValue(key: string) {
    localStorage.removeItem(key);
  }

}
