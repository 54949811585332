import {Component, Input, OnDestroy, OnInit, TemplateRef} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {SMARTENCITY_CORE_CONFIG} from '../../injection-tokens';
import {CoreConfig} from '../../core-config.model';
import {AddressService} from './address.service';
import {AdsAddressService} from './ads-address.service';
import {HttpXhrBackend, JsonpClientBackend} from '@angular/common/http';
import {DawaAddressService} from './dawa-address.service';
import {InputAddressService} from './input-address.service';
import {takeUntil} from 'rxjs/operators';
import {GoogleMapsApiService} from '../../services/google-maps-api.service';
import {Subject} from 'rxjs/internal/Subject';

export function addressServiceFactory(config: CoreConfig, jsonpBackend: JsonpClientBackend, xhrBackend: HttpXhrBackend) {
  if (config.addressControl === 'inads') {
    return new AdsAddressService(jsonpBackend);
  } else if (config.addressControl === 'dawa') {
    return new DawaAddressService(xhrBackend);
  } else {
    return new InputAddressService();
  }
}

@Component({
  selector: 'core-address',
  templateUrl: './address.component.html',
  providers: [
    {
      provide: AddressService,
      useFactory: addressServiceFactory,
      deps: [SMARTENCITY_CORE_CONFIG, JsonpClientBackend, HttpXhrBackend]
    }
  ]
})
export class AddressComponent implements OnInit, OnDestroy {

  @Input()
  form: FormGroup;

  @Input()
  location: Location;

  @Input()
  template: TemplateRef<any>;

  @Input()
  showErrors = true;

  private ngDestroy = new Subject<void>();

  constructor(
    private addressService: AddressService,
    private googleMapsApi: GoogleMapsApiService
  ) {

  }

  ngOnInit() {
    this.addressService.initForm(this.form);

    this.addressService.addressSelect$.subscribe((address) => {
      if (!address) {
        return;
      }

      this.googleMapsApi.getAddressCoordinates(address)
        .pipe(takeUntil(this.ngDestroy))
        .subscribe((result) => {
          if (result) {
            this.form.get('lat').setValue(result.lat, {onlySelf: true});
            this.form.get('lng').setValue(result.lng, {onlySelf: true});
          }
        });
    });

  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

}
