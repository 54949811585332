<i class="icon icon-lights">
  <svg width="115" height="115" viewBox="-10.5 -24 115 115" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" id="el_IsPr2mhFg">
    <g id="el_1JbLZ-OYp4" fill-rule="evenodd">
      <g id="el_zIGFh0l_5l">
        <g id="el_qAhbQuG1E9">
          <g id="el_GC4xzfRPSa_an_GfLQTQ6h-" data-animator-group="true" data-animator-type="2"><polygon id="el_GC4xzfRPSa" points="68.0244 54.2595 6.0244 54.2595 28.0244 22.2595 46.0244 22.2595"/></g>
          <g id="el_P6yc3ws3tZ">
            <path d="M50.9501,16.0456 C47.9691,16.0456 43.4971,11.1476 43.4971,4.3326 C43.4971,1.5646 45.5201,0.6066 47.7561,0.6066 C52.4411,0.6066 58.1911,3.6936 58.1911,7.6336 C58.1911,10.7216 54.0381,16.0456 50.9501,16.0456" id="el_zrd9GJUSPo"/>
            <path d="M63.5878,16.0456 C66.0438,16.0456 69.7268,12.0116 69.7268,6.3996 C69.7268,4.1196 68.0608,3.3306 66.2188,3.3306 C62.3598,3.3306 57.6248,5.8736 57.6248,9.1176 C57.6248,11.6606 61.0448,16.0456 63.5878,16.0456" id="el_EWOx9Ne1LO"/>
            <path d="M47.0019,18.2204 C44.5469,18.2204 40.8639,14.1874 40.8639,8.5744 C40.8639,6.2944 42.5299,5.5054 44.3709,5.5054 C48.2299,5.5054 52.9649,8.0484 52.9649,11.2924 C52.9649,13.8354 49.5449,18.2204 47.0019,18.2204" id="el_zxEbvrd6Fx"/>
            <path d="M56.7128,14.6833 C59.4978,14.6833 63.6758,10.1073 63.6758,3.7413 C63.6758,1.1553 61.7858,0.2593 59.6968,0.2593 C55.3198,0.2593 49.9478,3.1443 49.9478,6.8253 C49.9478,9.7093 53.8278,14.6833 56.7128,14.6833" id="el_vWVAzyY9xD"/>
            <path d="M77.0244,19.2595 L3.0244,19.2595 C1.3684,19.2595 0.0244,17.9165 0.0244,16.2595 C0.0244,14.6025 1.3684,13.2595 3.0244,13.2595 L77.0244,13.2595 C78.6814,13.2595 80.0244,14.6025 80.0244,16.2595 C80.0244,17.9165 78.6814,19.2595 77.0244,19.2595" id="el_hlV0F5lewi"/>
          </g>
          <g id="el_0yvw7-j7XA">
            <path d="M1.5029,58.7595 C0.6749,58.7595 0.0029,58.0875 0.0029,57.2595 L0.0029,10.8925 C0.0029,4.9125 4.8669,0.0485 10.8469,0.0485 C16.8249,0.0485 21.6899,4.9125 21.6899,10.8925 C21.6899,11.7205 21.0189,12.3925 20.1899,12.3925 C19.3609,12.3925 18.6899,11.7205 18.6899,10.8925 C18.6899,6.5675 15.1709,3.0485 10.8469,3.0485 C6.5219,3.0485 3.0029,6.5675 3.0029,10.8925 L3.0029,57.2595 C3.0029,58.0875 2.3319,58.7595 1.5029,58.7595" id="el_VnndDQPK1_"/>
            <path d="M11.9043,22.1364 C11.4833,22.1364 11.1493,21.7754 11.1863,21.3554 C11.5823,16.7774 15.4243,13.1854 20.1043,13.1854 C24.7853,13.1854 28.6263,16.7774 29.0223,21.3554 C29.0583,21.7754 28.7243,22.1364 28.3033,22.1364 L11.9043,22.1364 Z" id="el_wrszl3_09c"/>
          </g>
          <path d="M39.6972,23.0026 C39.8312,23.0026 39.9362,23.1166 39.9252,23.2496 C39.8002,24.6976 38.5852,25.8336 37.1042,25.8336 C35.6242,25.8336 34.4082,24.6976 34.2832,23.2496 C34.2712,23.1166 34.3772,23.0026 34.5102,23.0026 L39.6972,23.0026 Z" id="el_VeGnLVB6ew"/>
          <g id="el_-3hi4Sivsm_an_jyd34iKXf" data-animator-group="true" data-animator-type="1"><g id="el_-3hi4Sivsm_an_KCUaS9O-M4" data-animator-group="true" data-animator-type="2"><g id="el_-3hi4Sivsm">
            <path d="M4.0244,8.2595 C3.4724,8.2595 3.0244,7.8125 3.0244,7.2595 L3.0244,1.2595 C3.0244,0.7065 3.4724,0.2595 4.0244,0.2595 C4.5774,0.2595 5.0244,0.7065 5.0244,1.2595 L5.0244,7.2595 C5.0244,7.8125 4.5774,8.2595 4.0244,8.2595" id="el_IwIG9kzjwch"/>
            <path d="M7.0244,5.2595 L1.0244,5.2595 C0.4724,5.2595 0.0244,4.8125 0.0244,4.2595 C0.0244,3.7065 0.4724,3.2595 1.0244,3.2595 L7.0244,3.2595 C7.5774,3.2595 8.0244,3.7065 8.0244,4.2595 C8.0244,4.8125 7.5774,5.2595 7.0244,5.2595" id="el_gYw17-4UFa_"/>
          </g></g></g>
          <g id="el_L6U-auiTP_U_an_2o0Fkd9gr" data-animator-group="true" data-animator-type="1"><g id="el_L6U-auiTP_U_an_BvsrtXJwO" data-animator-group="true" data-animator-type="2"><g id="el_L6U-auiTP_U">
            <path d="M4.0244,8.2595 C3.4724,8.2595 3.0244,7.8125 3.0244,7.2595 L3.0244,1.2595 C3.0244,0.7065 3.4724,0.2595 4.0244,0.2595 C4.5774,0.2595 5.0244,0.7065 5.0244,1.2595 L5.0244,7.2595 C5.0244,7.8125 4.5774,8.2595 4.0244,8.2595" id="el_sLgQkva28l8"/>
            <path d="M7.0244,5.2595 L1.0244,5.2595 C0.4724,5.2595 0.0244,4.8125 0.0244,4.2595 C0.0244,3.7065 0.4724,3.2595 1.0244,3.2595 L7.0244,3.2595 C7.5774,3.2595 8.0244,3.7065 8.0244,4.2595 C8.0244,4.8125 7.5774,5.2595 7.0244,5.2595" id="el_CTxOfVbR8qo"/>
          </g></g></g>
          <g id="el_vwWt2_DTwrl_an_OfAGH7aZ2" data-animator-group="true" data-animator-type="1"><g id="el_vwWt2_DTwrl_an_JPnMVqiNj" data-animator-group="true" data-animator-type="2"><g id="el_vwWt2_DTwrl">
            <path d="M4.0244,8.2595 C3.4724,8.2595 3.0244,7.8125 3.0244,7.2595 L3.0244,1.2595 C3.0244,0.7065 3.4724,0.2595 4.0244,0.2595 C4.5774,0.2595 5.0244,0.7065 5.0244,1.2595 L5.0244,7.2595 C5.0244,7.8125 4.5774,8.2595 4.0244,8.2595" id="el_VmqAu496c4I"/>
            <path d="M7.0244,5.2595 L1.0244,5.2595 C0.4724,5.2595 0.0244,4.8125 0.0244,4.2595 C0.0244,3.7065 0.4724,3.2595 1.0244,3.2595 L7.0244,3.2595 C7.5774,3.2595 8.0244,3.7065 8.0244,4.2595 C8.0244,4.8125 7.5774,5.2595 7.0244,5.2595" id="el_3-onAsgay7H"/>
          </g></g></g>
        </g>
      </g>
    </g>
  </svg>
</i>
