import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {UserService} from '@smartencity/core';

@Component({
  selector: 'mydata-accept-application',
  templateUrl: 'accept-applications.component.html'
})
export class AcceptApplicationsComponent implements OnInit {
  public pendingApplications: any[] = [];

  constructor (
    public activeModal: NgbActiveModal,
    public userService: UserService
  ) { }

  ngOnInit () { }

  cancel () {
    this.activeModal.dismiss('Close click');
  }

  decline() {
    this.pendingApplications.forEach(value => {
      this.userService.updateApplicationConfirmation(value, 'DECLINED', null).subscribe();
    });
    this.activeModal.close('Declined');
  }

  acceptAll() {
    this.pendingApplications.forEach(value => {
      this.userService.updateApplicationConfirmation(value, 'WAITING_RESOLUTION', null).subscribe();
    });
    this.activeModal.close('Accepted');
  }
}
