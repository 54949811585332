<div class="modal-header">
  <h5 class="modal-title w-100" i18n>Terms and conditions</h5>
</div>
<div class="modal-body">
  <p i18n>Welcome!<br><br>You have logged in to the City Portal. You need agree to the terms of service to continue.</p>
  <terms-of-service></terms-of-service>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary btn-sm" (click)="accept()" i18n>Agree and continue</button>
</div>
