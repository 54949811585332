import {OlMapWrapper, OlOverlayWrapper, OverlayWrapperOptions} from '@smartencity/core';

export class BicycleStationOlMarkerWrapper extends OlOverlayWrapper {

  public selected = false;

  private bicycleParkingStation: any;

  constructor(bicycleParkingStation: any, map: OlMapWrapper, opts: OverlayWrapperOptions) {
    super(opts);
    this.bicycleParkingStation = bicycleParkingStation;
    this.setMap(map.getMap());
  }

  public draw() {
    if (!this.div) {

      
      this.div = document.createElement('DIV');
      this.div.style.cursor = 'pointer';
      let title: string = this.bicycleParkingStation.name;

      const availabilityParts = [];
      if (this.bicycleParkingStation.freeDocksCount != null && this.bicycleParkingStation.primaryLockedCount != null) {
        availabilityParts.push("<span class='small'>" + $localize`Free docks count: ${this.bicycleParkingStation.freeDocksCount}` + "</span>");
        availabilityParts.push("<span class='small'>" + $localize`Total: ${this.bicycleParkingStation.primaryLockedCount + this.bicycleParkingStation.freeDocksCount}` + "</span>");
      }

      if (!availabilityParts.length) {
        title += $localize`Data missing`;
      } else {
        title += '<br/>' + availabilityParts.join('<br/>');
      }

      this.div.innerHTML = '<div class="pointer-bicycle-parking-station" data-trigger="hover" data-toggle="popover" data-placement="top" data-html="true" data-content="' + title + '"></div>';
      $('.popover.show').remove();
      ($('[data-toggle="popover"]') as any).popover({
        trigger: 'hover'
      });
    }

    if (this.selected) {
      this.div.classList.add("active");
    } else {
      this.div.classList.remove("active");
    }
  }
}
