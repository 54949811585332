import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {NoiseLevelsPage, NoiseLevelsHistogram} from './noise-level-heatmap.model';
import {Observable} from 'rxjs/internal/Observable';
import {SMARTENCITY_TALLINN_CONFIG} from '../../injection-tokens';
import {MapsConfig, TallinnConfig} from '../../tallinn-config.model';

@Injectable({
  providedIn: 'root'
})
export class NoiseLevelHeatmapService {

  constructor(
    private http: HttpClient,
    @Inject(SMARTENCITY_TALLINN_CONFIG) private config: TallinnConfig
  ) { }

  getMapsConfig(): MapsConfig {
    return this.config.map;
  }

  getNoiseLevels(params: any): Observable<NoiseLevelsPage> {
    params.cityPortalType = 'NOISE_LEVEL';

    // return of(NoiseLevelData.mockData);

    return new Observable(observer => {
      this.http.get(this.config.cityApiUrl + '/person-series', {
        params: params
      }).subscribe(
        (data: NoiseLevelsPage) => {
          return observer.next(data);
        }, err => {
          return observer.error(err);
        });
    });
  }

  getNoiseLevelsHistogram(): Observable<NoiseLevelsHistogram> {
    return new Observable(observer => {
      this.http.get(this.config.cityApiUrl + '/person-series/noise-level/histogram', {
        params: {}
      }).subscribe(
        (data: NoiseLevelsHistogram) => {
          return observer.next(data);
        }, err => {
          return observer.error(err);
        });
    });
  }

  getNoiseLevelState(params: any): Observable<any> {
    params.cityPortalType = 'NOISE_LEVEL';
    return new Observable(observer => {
      this.http.get(this.config.cityApiUrl + '/person-series/state', {
        params: params
      }).subscribe(
        (data: any) => {
          return observer.next(data);
        }, err => {
          return observer.error(err);
        });
    });
  }

}
