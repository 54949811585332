import {AbstractMapLayerMarker, OlMapWrapper} from '@smartencity/core';
import {NoiseLevel} from '../component/noise-level-heatmap/noise-level-heatmap.model';
import {NoiseLevelOlMarkerWrapper} from './noise-level-ol-marker-wrapper';

export class NoiseLevelOlMarker extends AbstractMapLayerMarker<OlMapWrapper, NoiseLevel> {

  private marker: NoiseLevelOlMarkerWrapper;

  private selected = false;

  constructor(noiseLevel: NoiseLevel, map: OlMapWrapper) {
    super(noiseLevel);
    this.marker = new NoiseLevelOlMarkerWrapper(noiseLevel, map);
  }

  setMap(map: OlMapWrapper) {
    super.setMap(map);
    const olMap = (map ? map.getMap() : null);
    this.marker.setMap(olMap);
  }

  draw() {
    this.marker.draw();
  }

  isSelected(): boolean {
    return this.selected;
  }

  setSelected(selected: boolean): void {
    this.selected = selected;
  }

}
