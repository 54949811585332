import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {CoreModule} from '@smartencity/core';
import {AdviceListComponent} from './advice-list.component';
import {ModalModule} from 'ngx-bootstrap/modal';
import {RouterModule} from '@angular/router';
import {AdviceModalComponent} from './advice-modal/advice-modal.component';
import {AdviceManagementComponent} from '../advice-management/advice-management.component';
import {AdviceEditModalComponent} from '../advice-management/edit-modal/advice-edit-modal.component';
import {AdviceRowComponent} from '../advice-management/edit-modal/advice-row.component';
import {AdvicePageComponent} from './advice-page.component';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';

@NgModule({
  declarations: [
    AdviceListComponent,
    AdviceModalComponent,
    AdviceManagementComponent,
    AdviceEditModalComponent,
    AdviceRowComponent,
    AdvicePageComponent
  ],
  entryComponents: [],
  providers: [

  ],
  imports: [
    CommonModule,
    RouterModule.forChild([]),
    // AdviceRoutingModule,
    ReactiveFormsModule,
    CoreModule,
    ModalModule,
    NgxDatatableModule,
    BsDropdownModule
  ],
  exports: [
    AdviceListComponent
  ]
})
export class AdviceModule { }
