import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {AuthService, CoreConfig, LoggerService, Mandate, Preferences, PreferencesService, SMARTENCITY_CORE_CONFIG} from '@smartencity/core';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {FormControl, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {SMARTENCITY_MYDATA_CONFIG} from '../../../injection-tokens';
import {MyDataConfig} from '../../../mydata-config.model';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';

const selector = 'mydata-preferences';
let nextId = 0;

@Component({
  selector: selector,
  templateUrl: './preferences.component.html'
})
export class PreferencesComponent implements OnInit, OnDestroy {
  id = `${selector}-${nextId++}`;
  private ngDestroy = new Subject<void>();
  public progress = false;

  public formControl = new FormControl();
  public form: FormGroup;

  public loading = true;

  public cityUserMandate: Mandate;

  constructor(
    private preferencesService: PreferencesService,
    private toastr: ToastrService,
    private loggerService: LoggerService,
    @Inject(SMARTENCITY_CORE_CONFIG) private config: CoreConfig,
    private authService: AuthService
  ) {
  }

  ngOnInit() {

    combineLatest([this.preferencesService.preferences$, this.authService.currentMandate$]).pipe(takeUntil(this.ngDestroy)).subscribe(([preferences, mandate]: [Preferences, Mandate]) => {
      if (this.config.cityRegistrationCode === mandate.person.registrationNumber) {
        this.cityUserMandate = mandate;
      }
      this.buildForm(preferences);
    });
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  private buildForm(preferences: Preferences): void {
    const personEmail = (this.cityUserMandate ? preferences.email : null);
    const emailValidator = (this.cityUserMandate ? [Validators.email] : []);
    this.form = new FormGroup({
      questionnairesEnabled: new FormControl(preferences.questionnairesEnabled),
      questionnairesCreateEnabled: new FormControl(preferences.questionnairesCreateEnabled),
      emDashboardEnabled: new FormControl(preferences.emDashboardEnabled),
      email: new FormControl(personEmail, emailValidator)
    });

    this.loading = false;
  }

  save() {
    if (!this.form.valid) {
      console.error("Form invalid!");
      return;
    }
    this.progress = true;
    this.preferencesService.patchPreferences(this.form.getRawValue()).subscribe((preferences: Preferences) => {
      this.preferencesService.setPreferences(preferences);
      this.toastr.success($localize`Preferences updated`);
    }, (error) => {
      this.progress = false;
      this.loggerService.error(error);
      this.toastr.error($localize`Error saving preferences`);
    }, () => this.progress = false);
  }

  control(name?: string) {
    if (!name) {
      return this.form;
    }
    return this.form.get(name);
  }

  invalid(name: string, formGroup?) {
    if (!formGroup) {
      formGroup = this.form;
    }
    const control = formGroup.get(name);
    return control && control.invalid && (control.dirty || control.touched);
  }

  errors(name: string): ValidationErrors {
    const control = this.form.get(name);
    return control ? control.errors : null;
  }
}
