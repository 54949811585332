import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {ReactiveFormsModule} from '@angular/forms';
import {CoreModule} from '@smartencity/core';
import {NgxPermissionsModule} from 'ngx-permissions';
import {EnergyManagerComponent} from './energy-manager.component';
import {DashboardModule} from '../dashboard/dashboard.module';
import {QuestionnaireModule} from '../questionnaires/questionnaire.module';
import {AdviceModule} from '../advice/advice.module';
// import { AdvicesComponent } from './advices/advices.component';
// import { IncomingQuestionnairesComponent } from './incoming-questionnaires/incoming-questionnaires.component';

@NgModule({
  declarations: [
    EnergyManagerComponent/*,
    AdvicesComponent,
    IncomingQuestionnairesComponent*/
  ],
  entryComponents: [

  ],
  providers: [

  ],
  imports: [
    CommonModule,
    RouterModule.forChild([]),
    ReactiveFormsModule,
    CoreModule,
    NgxPermissionsModule.forChild(),
    QuestionnaireModule,
    DashboardModule,
    AdviceModule
  ],
  exports: [

  ]
})
export class EnergyManagerModule { }
