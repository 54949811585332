import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {Subject} from 'rxjs/internal/Subject';
import {takeUntil} from 'rxjs/operators';
import {aggregationGroupingTypeSymbolMap, BuildingsCompareService} from '@smartencity/core';
import {BuildingsService} from '../../service/buildings.service';
import {Building} from '../../model/building';

@Component({
  selector: '[buildings-building-modal]',
  templateUrl: './building-modal.component.html'
})
export class BuildingModalComponent implements OnInit, OnDestroy {
  private ngDestroy = new Subject<void>();

  public building: Building;

  public aggregationGroupingTypeSymbolMap = aggregationGroupingTypeSymbolMap;

  public isInComparison = false;

  constructor(
    public buildingsCompareService: BuildingsCompareService,
    private buildingsService: BuildingsService
  ) {
    this.buildingsCompareService.changes$.pipe(takeUntil(this.ngDestroy)).subscribe(() => {
      this.isInComparison = this.buildingsCompareService.contains(this.building);
    });
  }

  ngOnInit() {
    this.buildingsService.buildingSelected$.pipe(takeUntil(this.ngDestroy)).subscribe((building: Building) => {
      this.building = building;
      this.isInComparison = this.buildingsCompareService.contains(building);
    });

  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  close() {
    this.buildingsService.selectBuilding(null);
  }

  addToComparison() {
    if (this.buildingsCompareService.add(this.building)) {
      this.isInComparison = true;
    }
  }

  removeFromComparison() {
    this.buildingsCompareService.removeBuildingCompareItemByBuilding(this.building);
    this.isInComparison = false;
  }

  public isAnimated(series): boolean {
    return (new Date().getTime() - new Date(series.updatedAt).getTime()) < 3600000;
  }
}
