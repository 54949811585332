import {Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output} from '@angular/core';

import {HttpClient, HttpErrorResponse, HttpXhrBackend} from '@angular/common/http';
import {SMARTENCITY_MYDATA_CONFIG} from '../../../injection-tokens';
import {MyDataConfig} from '../../../mydata-config.model';
import {FormControl, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {
  CityApiService,
  Mandate,
  Person,
  PersonSeries,
  SeriesType,
  SourceOwnerSeries,
  AuthService,
  EnumsService,
  CoreConfig,
  SMARTENCITY_CORE_CONFIG,
  LocationType,
  CustomFormValidators,
  ShowCityPortalRequest,
  NumberFormatConstants,
  MandatePerson, PreventCloseAware
} from '@smartencity/core';
import {catchError, distinctUntilChanged, map, switchMap, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs/internal/Subject';
import {Observable} from 'rxjs/internal/Observable';
import {forkJoin} from 'rxjs/internal/observable/forkJoin';
import {throwError} from 'rxjs/internal/observable/throwError';
import {of} from 'rxjs/internal/observable/of';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {ToastrService} from 'ngx-toastr';
import {GroupingTagService} from "../../../services/grouping-tag.service";
import {ArrayHelper} from '../../../../../../core/src/lib/helpers/array-helper';
import {GoogleMapsApiService} from '../../../../../../core/src/lib/services/google-maps-api.service';

const selector = 'mydata-series-bulk-edit-modal';
let nextId = 0;

@Component({
  selector: selector,
  templateUrl: './series-bulk-edit-modal.component.html',
  providers: [GroupingTagService],
})
export class SeriesBulkEditModalComponent implements OnInit, OnDestroy, PreventCloseAware {
  id = `${selector}-${nextId++}`;
  private ngDestroy = new Subject<void>();

  public progress = false;
  private currentPerson: MandatePerson;

  rows: any[];

  showOnCityPortalValues: any[] = [];

  locationTypeEnum = LocationType;

  public showErrors = false;

  public isCityUser = false;

  @Output('saved')
  savedEmitter: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  sourceOwnerSeries: SourceOwnerSeries;

  form: FormGroup = new FormGroup({
    groupingTag: new FormControl(''),
    seriesType: new FormControl(null),
    numberFormat: new FormGroup({
      maxFractionDigits: new FormControl(null, [
        Validators.min(NumberFormatConstants.DEFAULT_NUMBER_FORMAT.minFractionDigits),
        Validators.max(NumberFormatConstants.DEFAULT_NUMBER_FORMAT.maxFractionDigits)
      ]),
    }),
    showOnCityPortal: new FormControl(''),
    cityPortalType: new FormControl(null),
    aggregationGroupingType: new FormControl(null),
    differentiate: new FormControl(null),
    locationType: new FormControl(null),
    room: new FormControl(null),
    apartment: new FormControl(null),
    uuid: new FormControl(null),
    lat: new FormControl(null, [CustomFormValidators.latitude]),
    lng: new FormControl(null, [CustomFormValidators.longitude]),
    adsOid: new FormControl(null),
    address: new FormControl(null),
    groups: new FormControl(null)
  });

  public seriesTypes: any[] = [];
  public cityPortalTypes: any[] = [];
  public aggregationGroupingTypeOptions = [
    {value: null, label: $localize`Live`},
    {value: 'HOURLY', label: $localize`Hourly`},
    {value: 'DAILY', label: $localize`Daily`},
    {value: 'MONTHLY', label: $localize`Monthly`},
    {value: 'YEARLY', label: $localize`Yearly`}
  ];

  defaultNumberFormat = NumberFormatConstants.DEFAULT_NUMBER_FORMAT;

  seriesTypeFilter(text$: Observable<string>) {
    return text$.pipe(
      distinctUntilChanged(),
      map((text: string) => {
        if (text == null || text === '') {
          return SeriesType.types;
        }
        return SeriesType.types.filter((data: any) => {
          return data.toString().toLowerCase().includes(text.toString().toLowerCase());
        });
      })
    );
  }

  constructor(
    private http: HttpClient,
    @Inject(SMARTENCITY_MYDATA_CONFIG) private config: MyDataConfig,
    @Inject(SMARTENCITY_CORE_CONFIG) private coreConfig: CoreConfig,
    private toastr: ToastrService,
    public authService: AuthService,
    public cityService: CityApiService,
    public modalRef: BsModalRef,
    private enumsService: EnumsService,
    public groupingTagService: GroupingTagService,
    private googleMapsApi: GoogleMapsApiService
  ) {
    const showOnCityPortal = this.coreConfig.defaultPreferences ? this.coreConfig.defaultPreferences.showOnCityPortal : true;
    this.authService.currentMandate$.pipe(takeUntil(this.ngDestroy)).subscribe((mandate: Mandate) => {
      this.currentPerson = mandate.person;
      this.isCityUser = showOnCityPortal && this.config.cityRegistrationCode === this.currentPerson.registrationNumber;
    });

    this.seriesTypes = this.enumsService.getOptionsList(SeriesType.types);
    this.cityPortalTypes = this.config.cityPortalTypes;
  }

  ngOnInit(): void {
    const toForkJoin: Observable<any>[] = [];
    if (this.isCityUser) {
      for (const row of this.rows) {
        toForkJoin.push(this.cityService.getCityPersonSeries(row.series.personSeries.id).pipe(catchError((error: any) => {
          if (error instanceof HttpErrorResponse) {
            switch ((<HttpErrorResponse>error).status) {
              case 404:
                return of(false);
              default:
                return throwError(error);
            }
          }
        })));
      }

      this.form.get('showOnCityPortal').valueChanges.pipe(takeUntil(this.ngDestroy)).subscribe(value => {
        if (value) {
          this.form.get('cityPortalType').setValidators([Validators.required]);
        } else {
          this.form.get('cityPortalType').setValidators([]);
        }
        this.form.get('cityPortalType').updateValueAndValidity();
      });
    }

    this.form.get('locationType').valueChanges.pipe(takeUntil(this.ngDestroy)).subscribe(() => {
      this.form.get('address').updateValueAndValidity();
      this.form.get('uuid').updateValueAndValidity();
      this.form.get('lat').updateValueAndValidity();
      this.form.get('lng').updateValueAndValidity();
    });

    forkJoin(toForkJoin.length ? toForkJoin : of([])).pipe(catchError(error => {
      this.toastr.warning($localize`Failed to load "show on map" status values for bulk edit`);
      return of(null);
    })).subscribe((cityPersonSeriesList: any[]) => {
      this.showOnCityPortalValues = cityPersonSeriesList;

      const initialValue = {
        groupingTags: this.rows.length ? this.rows[0].series.personSeries.groupingTags : null,
        groups: this.rows.length ? this.rows[0].series.groups : null,
        seriesType: this.rows.length ? this.rows[0].series.personSeries.seriesType : null,
        numberFormat: this.rows.length ? this.rows[0].series.personSeries.numberFormat : null,
        showOnCityPortal: cityPersonSeriesList?.length ? cityPersonSeriesList[0] && cityPersonSeriesList[0].showOnCityPortal : null,
        cityPortalType: cityPersonSeriesList?.length ? cityPersonSeriesList[0].cityPortalType : null,
        aggregationGroupingType: cityPersonSeriesList?.length ? cityPersonSeriesList[0].aggregationGroupingType : null,
        differentiate: cityPersonSeriesList?.length ? cityPersonSeriesList[0].differentiate : null,
        locationType: this.rows.length ? this.rows[0].series.personSeries.locationType : null,
        address: this.rows.length ? this.rows[0].series.personSeries.address : null,
        apartment: this.rows.length ? this.rows[0].series.personSeries.apartment : null,
        adsOid: this.rows.length ? this.rows[0].series.personSeries.adsOid : null,
        uuid: this.rows.length ? this.rows[0].series.personSeries.uuid : null,
        lat: this.rows.length ? this.rows[0].series.personSeries.lat : null,
        lng: this.rows.length ? this.rows[0].series.personSeries.lng : null,
        room: this.rows.length ? this.rows[0].series.personSeries.room : null
      };

      for (let i = 0; i < this.rows.length; i++) {
        const row = this.rows[i];

        const rowPersonSeries = row.series.personSeries;

        if (!ArrayHelper.valuesEqual(rowPersonSeries.groupingTags, initialValue.groupingTags)) {
          initialValue.groupingTags = [];
        }

        if (!ArrayHelper.valuesEqual(row.series.groups, initialValue.groups)) {
          initialValue.groups = [];
        }

        if (rowPersonSeries.seriesType !== initialValue.seriesType) {
          initialValue.seriesType = null;
        }

        if (rowPersonSeries.numberFormat && initialValue.numberFormat && (rowPersonSeries.numberFormat.maxFractionDigits !== initialValue.numberFormat.maxFractionDigits)) {
          initialValue.numberFormat = {
            maxFractionDigits: null
          }
        }

        if (cityPersonSeriesList != null && cityPersonSeriesList[i] && cityPersonSeriesList[i].showOnCityPortal !== initialValue.showOnCityPortal) {
          initialValue.showOnCityPortal = false;
        }
        if (cityPersonSeriesList != null && cityPersonSeriesList[i] && cityPersonSeriesList[i].cityPortalType !== initialValue.cityPortalType) {
          initialValue.cityPortalType = null;
        }
        if (cityPersonSeriesList != null && cityPersonSeriesList[i] && cityPersonSeriesList[i].aggregationGroupingType !== initialValue.aggregationGroupingType) {
          initialValue.aggregationGroupingType = null;
        }
        if (cityPersonSeriesList != null && cityPersonSeriesList[i] && cityPersonSeriesList[i].differentiate !== initialValue.differentiate) {
          initialValue.differentiate = null;
        }

        if (rowPersonSeries.locationType !== initialValue.locationType
          || rowPersonSeries.address !== initialValue.address
          || rowPersonSeries.lat !== initialValue.lat
          || rowPersonSeries.lng !== initialValue.lng) {
          initialValue.locationType = null;
          initialValue.address = null;
          initialValue.apartment = null;
          initialValue.adsOid = null;
          initialValue.uuid = null;
          initialValue.room = null;
          initialValue.lat = null;
          initialValue.lng = null;
        }

        if (rowPersonSeries.room !== initialValue.room) {
          initialValue.room = null;
        }

        if (rowPersonSeries.apartment !== initialValue.apartment) {
          initialValue.apartment = null;
          initialValue.room = null;
        }

        if (rowPersonSeries.uuid !== initialValue.uuid) {
          initialValue.uuid = null;
        }

      }

      this.form.patchValue({
        groups: initialValue.groups,
        seriesType: initialValue.seriesType,
        showOnCityPortal: initialValue.showOnCityPortal,
        cityPortalType: initialValue.cityPortalType,
        aggregationGroupingType: initialValue.aggregationGroupingType,
        differentiate: initialValue.differentiate,
        locationType: initialValue.locationType,
        address: initialValue.address,
        room: initialValue.room,
        apartment: initialValue.apartment,
        uuid: initialValue.uuid,
        lat: initialValue.lat,
        lng: initialValue.lng,
        numberFormat: initialValue.numberFormat
      });
    });
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
    this.groupingTagService.ngDestroy.next();
  }

  control(name?: string) {
    if (!name) {
      return this.form;
    }
    return this.form.get(name);
  }

  invalid(name: string, formGroup?) {
    if (!formGroup) {
      formGroup = this.form;
    }
    const control = formGroup.get(name);
    return control && control.invalid && (control.dirty || control.touched);
  }

  errors(name: string): ValidationErrors {
    const control = this.form.get(name);
    return control ? control.errors : null;
  }

  close() {
    this.modalRef.hide();
  }

  toggleLocationTypeCheck(locationType: any): void {
    const reset = this.form.get('locationType').value === locationType;
    if (reset) {
      this.form.get('locationType').reset();
    } else {
      this.form.get('locationType').setValue(locationType);
    }
  }

  save() {
    this.form.markAllAsTouched();
    if (!this.form.valid) {
      console.log('invalid', this.form);
      this.showErrors = true;
      return;
    }

    const formValue = this.form.value;

    const updates: Observable<{row: any, metadata: any, showOnCityPortal: boolean}>[] = [];

    for (let i = 0; i < this.rows.length; i++) {
      const row = this.rows[i];

      const originalUpdateValue = {
        row: row,
        metadata: {
          name: row.series.personSeries.name,
          groups: row.series.groups,
          groupingTags: row.series.personSeries.groupingTags,
          seriesType: row.series.personSeries.seriesType,
          locationType: row.series.personSeries.locationType,
          adsOid: row.series.personSeries.adsOid,
          address: row.series.personSeries.address,
          apartment: row.series.personSeries.apartment,
          room: row.series.personSeries.room,
          uuid: row.series.personSeries.uuid,
          lat: row.series.personSeries.lat,
          lng: row.series.personSeries.lng,
          showOnCityPortal: row.series.personSeries.showOnCityPortal,
          cityPortalType: row.series.personSeries.cityPortalType,
          readableAddress: row.series.personSeries.readableAddress,
          numberFormat: row.series.personSeries.numberFormat,
        },
        showOnCityPortal: this.showOnCityPortalValues ? this.showOnCityPortalValues[i] && this.showOnCityPortalValues[i].showOnCityPortal : null,
        cityPortalType: this.showOnCityPortalValues ? this.showOnCityPortalValues[i] && this.showOnCityPortalValues[i].cityPortalType : null,
        aggregationGroupingType: this.showOnCityPortalValues ? this.showOnCityPortalValues[i] && this.showOnCityPortalValues[i].aggregationGroupingType : null,
        differentiate: this.showOnCityPortalValues ? this.showOnCityPortalValues[i] && this.showOnCityPortalValues[i].differentiate : null
      };

      const optionalData: any = {};
      if (formValue.locationType) {
        switch (formValue.locationType) {
          case LocationType.ADDRESS:
            if (formValue.address) {
              optionalData.locationType = formValue.locationType;
              optionalData.address = formValue.address;
              optionalData.apartment = formValue.apartment;
              optionalData.room = formValue.room;
              optionalData.lat = formValue.lat;
              optionalData.lng = formValue.lng;
            }
            break;
          case LocationType.UUID:
            if (formValue.uuid) {
              optionalData.locationType = formValue.locationType;
              optionalData.uuid = formValue.uuid;
            }
            break;
          case LocationType.COORDS:
            if (formValue.lat != null && formValue.lng != null) {
              optionalData.locationType = formValue.locationType;
              optionalData.lat = formValue.lat;
              optionalData.lng = formValue.lng;
            }
            break;
        }
      }

      if (formValue.seriesType) {
          optionalData.seriesType = formValue.seriesType.trim().toUpperCase();
      }

      if (formValue.numberFormat && formValue.numberFormat.maxFractionDigits != null) {
        optionalData.numberFormat = formValue.numberFormat;
      }

      if (formValue.groups && formValue.groups.length > 0) {
        let groups = formValue.groups;
        optionalData.groups = groups.map(group => {
          if (!group.id) {
            group.groupingTag = group.name;
          }
          return group;
        });
        optionalData.groupingTags = groups.map(group => group.groupingTag.trim().toUpperCase());
      }

      const update$ = of({
        ...originalUpdateValue,
        metadata: {
          ...originalUpdateValue.metadata,
          ...optionalData,
          showOnCityPortal: formValue.showOnCityPortal,
          cityPortalType: formValue.cityPortalType != null && formValue.showOnCityPortal ? formValue.cityPortalType.trim().toUpperCase() : null
        },
        showOnCityPortal: formValue.showOnCityPortal != null ? formValue.showOnCityPortal : null,
        cityPortalType: formValue.cityPortalType != null && formValue.showOnCityPortal ? formValue.cityPortalType.trim().toUpperCase() : null,
        aggregationGroupingType: formValue.aggregationGroupingType != null ? formValue.aggregationGroupingType : null,
        differentiate: formValue.differentiate != null ? formValue.differentiate : null
      }).pipe(
        switchMap(update => {
          const metadata = update.metadata;
          const needsGeocoding = this.isCityUser && update.showOnCityPortal
            && update.metadata.locationType !== 'UUID'
            && (metadata.lat == null || metadata.lat === '' || metadata.lng == null || metadata.lng === '');
          if (needsGeocoding) {

            return this.googleMapsApi.searchByAddress(metadata.address)
              .pipe(map(response => {
                const results = response.results;
                if (results && results.length && results[0] && results[0].geometry && results[0].geometry.location) {
                  return {
                    ...update,
                    metadata: {...metadata, ...metadata, lat: results[0].geometry.location.lat, lng: results[0].geometry.location.lng},
                    showOnCityPortal: formValue.showOnCityPortal,
                    cityPortalType: formValue.cityPortalType,
                    aggregationGroupingType: formValue.aggregationGroupingType,
                    differentiate: formValue.differentiate
                  };
                }
                this.toastr.warning($localize`Geocoding failed for ` + metadata.name);
                if (this.isCityUser) {
                  return {...update, showOnCityPortal: false, cityPortalType: formValue.cityPortalType};
                } else {
                  return update;
                }
              }));
          }
          return of(update);
        }),
        switchMap(update => {
          const originalMetadata = originalUpdateValue.metadata;
          const metadata = update.metadata;
          const metadataChanged = metadata.groupingTags !== originalMetadata.groupingTags
            || metadata.groups !== originalMetadata.groups
            || metadata.seriesType !== originalMetadata.seriesType
            || metadata.lat !== originalMetadata.lat
            || metadata.lng !== originalMetadata.lng
            || metadata.uuid !== originalMetadata.uuid
            || metadata.showOnCityPortal !== originalMetadata.showOnCityPortal
            || metadata.cityPortalType !== originalMetadata.cityPortalType;
          if (metadataChanged) {

            return this.http.put<PersonSeries>(this.config.apiUrl + '/person-series/' + update.row.series.personSeries.id, metadata).pipe(map(personSeries => ({
              ...update,
              metadata: {
                ...metadata,
                groupingTags: personSeries.groupingTags,
                seriesType: personSeries.seriesType
              }
            })));
          }
          return of(update);
        }),
        switchMap(update => {
          if (this.isCityUser && (update.showOnCityPortal !== originalUpdateValue.showOnCityPortal || update.cityPortalType !== originalUpdateValue.cityPortalType)) {

            return this.cityService.setShowCityPersonSeriesOnCityPortal(
              update.row.series.personSeries.id,
              new ShowCityPortalRequest({
                showOnCityPortal: update.showOnCityPortal,
                cityPortalType: update.cityPortalType,
                aggregationGroupingType: update.aggregationGroupingType,
                differentiate: update.differentiate,
              })
            ).pipe(map((ps: any) => ({
              ...update,
              showOnCityPortal: ps ? ps.showOnCityPortal : false,
              cityPortalType: ps ? ps.cityPortalType : null,
              aggregationGroupingType: ps ? ps.aggregationGroupingType : null,
              differentiate: ps ? ps.differentiate : null
            }))) as Observable<any>;
          }
          return of(update);
        })
      );
      updates.push(update$);
    }

    this.progress = true;

    (updates.length ? forkJoin(updates) : of([])).subscribe(updateResults => {
      for (const update of updateResults) {
        update.row.groupingTags = update.metadata.groupingTags;
        update.row.seriesType = update.metadata.seriesType;
      }

      this.modalRef.hide();
      this.savedEmitter.emit();
      this.toastr.success('Datapoints saved');
    }, (error) => {
      this.progress = false;
      this.toastr.error('Saving datapoints failed');
    }, () => {
      this.progress = false;
    });
  }

  isPreventClose(): boolean {
    return this.form && this.form.dirty;
  }

}
