<div class="row mb-3 mb-md-0" *ngIf="(currentLocation$ | async)?.q11eId == null">
  <div class="card-wrapper card-wrapper-small-light d-flex align-items-stretch" *ngFor="let location of locations$ | async">
    <div class="card">
      <div class="view">
        <i class="icon icon-apartment"></i>
      </div>
      <div class="card-body" (click)="showMore(location)">
        <h2>{{location.getLocalName()}}</h2>
        <span class="text-light" i18n>{location?.personSeriesCount, plural, =1 {{{location?.personSeriesCount}} datapoint} other {{{location?.personSeriesCount}} datapoints}}</span>
      </div>
    </div>
  </div>
</div>
<div class="list" *ngIf="(currentLocation$ | async)?.q11eId != null">
  <div class="list-item" *ngFor="let location of locations$ | async" style="cursor: pointer" (click)="showMore(location)">
    <div class="row">
      <div class="col">
        <h3 class="mb-1">{{location.getLocalName()}}</h3>
        <span class="text-light text-uppercase">{location?.personSeriesCount, plural, =1 {{{location?.personSeriesCount}} datapoint} other {{{location?.personSeriesCount}} datapoints}}</span>
      </div>
    </div>
  </div>
</div>
