import {MeteorologyService} from '../service/meteorology.service';
import {AbstractMapLayer, OlMapWrapper} from '@smartencity/core';
import { RoadWeatherOlMarker } from './road-weather-ol-marker';
import {RoadWeatherSite} from '../model/road-weather-site';

export class RoadWeatherOlLayer extends AbstractMapLayer<OlMapWrapper, any>{

  public roadWeathers: RoadWeatherSite[] = [];

  private meteorologyService: MeteorologyService;

  constructor(roadWeathers: RoadWeatherSite[], meteorologyService: MeteorologyService) {
    super();
    this.roadWeathers = roadWeathers;
    this.meteorologyService = meteorologyService;
  }

  setMap(map: OlMapWrapper): void {
    super.setMap(map);
    this.resetMarkers();
    if (map) {
      this.createMarkers();
    }
  }

  setRoadWeather(selectedStation: any): void {
    this.getMarkers().forEach((marker: any) => {
      marker.setSelected(marker.getData() == selectedStation);
      marker.draw();
    });
  }

  protected resetMarkers(): void {
    let markers = this.getMarkers();
    if (markers.length > 0) {
      for (const marker of markers) {
        marker.setMap(null);
      }
    }
    this.clearMarkers();
  }

  protected createMarkers(): void {
    for (const roadWeather of this.roadWeathers) {
      this.addMarker(new RoadWeatherOlMarker(roadWeather, this.map, this.meteorologyService));
    }
  }
}
