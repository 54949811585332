import {OlMapWrapper, OlOverlayWrapper, OverlayWrapperOptions} from '@smartencity/core';
import { EecBuilding } from '../model/eec-building';

export class EecBuildingOlMarkerWrapper extends OlOverlayWrapper {

  private eecBuilding: EecBuilding;

  constructor(eecBuilding: any, map: OlMapWrapper, opts: OverlayWrapperOptions) {
    super(opts);
    this.eecBuilding = eecBuilding;
    this.setMap(map.getMap());
  }

  public draw() {
    if (!this.div) {
      this.div = document.createElement('DIV');
      this.div.title = this.eecBuilding.address;
      this.div.style.cursor = 'pointer';
      this.div.innerHTML = '<div class="active pointer-energy-class-'+ this.eecBuilding.energyClass.toLowerCase() +'" style="-40px;" data-trigger="hover" data-toggle="popover" data-placement="top" data-html="true" data-content="' + this.eecBuilding.address + '"></div>';
      $('.popover.show').remove();
      ($('[data-toggle="popover"]') as any).popover({
        trigger: 'hover'
      });
    }

  }

}
