import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';

import {CrowdInsightsGridAreaModalService} from '../crowd-insights-grid-area-modal.service';
import {ActivityType} from '../../../model/crowd-insights/activity-type';
import {DurationGroupType} from '../../../model/crowd-insights/duration-group-type';
import {MapsGridArea} from '../../../model/crowd-insights/maps-grid-area';
import {ActivityDaily} from '../../../model/crowd-insights/activity-daily';
import moment from 'moment';
import {CrowdInsightsDataService} from '../../../service/crowd-insights-data.service';
import {ActivityTypeFilterOption} from '../activity-type-filter/activity-type-filter-option';
import {ActivityDailyFilter} from '../../../model/crowd-insights/activity-daily-filter';
import {Observable} from 'rxjs';

@Component({
  selector: 'ci-grid-area-daily-activities',
  templateUrl: './grid-area-daily-activities.component.html',
  styleUrls: ['./grid-area-daily-activities.component.scss']
})
export class GridAreaDailyActivitiesComponent implements OnInit, OnChanges, OnDestroy {

  private static DEFAULT_ACTIVITY_TYPE: ActivityType = 'WORK';

  private static DEFAULT_DURATION_GROUP_TYPE: DurationGroupType = '60-120';

  @Input()
  gridArea: MapsGridArea;

  @Input()
  close: Observable<void>;

  activities: ActivityDaily[];

  loading = true;

  total = 0;

  chartValues: any[] = [];

  defaultActivityType: ActivityType = GridAreaDailyActivitiesComponent.DEFAULT_ACTIVITY_TYPE;

  defaultDurationGroupType: DurationGroupType = GridAreaDailyActivitiesComponent.DEFAULT_DURATION_GROUP_TYPE;

  filterOptions: ActivityTypeFilterOption[] = [
    ActivityTypeFilterOption.HOME,
    ActivityTypeFilterOption.WORK,
    ActivityTypeFilterOption.OTHER,
    ActivityTypeFilterOption.TOTAL
  ]

  filter: ActivityDailyFilter = GridAreaDailyActivitiesComponent.getDefault();

  private static getDefault(): ActivityDailyFilter {

    return {
      gridArea: null,
      activityType: GridAreaDailyActivitiesComponent.DEFAULT_ACTIVITY_TYPE,
      durationGroupType: GridAreaDailyActivitiesComponent.DEFAULT_DURATION_GROUP_TYPE,
      from: new Date('2023-04-10'),
      to: new Date('2023-04-17')
    }
  }

  constructor(private crowdInsightsGridAreaModalService: CrowdInsightsGridAreaModalService,
              private crowdInsightsDataService: CrowdInsightsDataService) { }


  ngOnInit(): void {
    this.init();

    this.close.subscribe(() => {
      this.init();
    });
  }

  private init(): void {
    this.defaultActivityType = GridAreaDailyActivitiesComponent.DEFAULT_ACTIVITY_TYPE;
    this.defaultDurationGroupType = GridAreaDailyActivitiesComponent.DEFAULT_DURATION_GROUP_TYPE;
    this.filter = GridAreaDailyActivitiesComponent.getDefault();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.gridArea?.currentValue) {
      this.filter.gridArea = changes.gridArea.currentValue;
      this.loadActivities();
    }
  }

  ngOn;

  private loadActivities(): void {
    this.loading = true;
    this.crowdInsightsDataService.getDailyActivities(this.filter).subscribe((activities: ActivityDaily[]) => {
      this.activities = activities;
      this.total = this.countPeopleTotal(activities);
      this.chartValues = this.getGraphValues(activities);
      this.loading = false;
    });

  }

  public activityTypeOptionSelected(option: ActivityTypeFilterOption): void {
    this.filterByActivityType(option.value);

  }

  public filterByActivityType(activityType: ActivityType): void {
    this.filter.activityType = activityType;
    this.loadActivities();
  }

  public filterByDurationType(durationGroupType: DurationGroupType) {
    this.filter.durationGroupType = durationGroupType;
    this.loadActivities();
  }

  private countPeopleTotal(activities: ActivityDaily[]): number {
    return activities.reduce((acc: number, activity: ActivityDaily) => {
      return acc + activity.people;
    }, 0);
  }

  private getGraphValues(activities: ActivityDaily[]): any[] {

    let dateTo = moment(this.filter.to)
      .startOf('day')
      .toDate();

    let endDate = moment(dateTo).startOf('day'),
      runningDate = moment(endDate).subtract(7, 'day');


    let graphItems: any[] = [];
    let suggestedMin = Infinity, suggestedMax = -Infinity;

    // start day <= running date && running date < end date
    while (runningDate.isBefore(endDate)) {

      let activityMatch: ActivityDaily = null;

      activities.forEach((activity: ActivityDaily) => {
        if (runningDate.valueOf() === moment(activity.batchDate).startOf('day').valueOf()) {
          activityMatch = activity;
          return;
        }
      });

      if (!activityMatch) {
        graphItems.push({
          value: null,
          time: runningDate.toDate()
        })
      } else {

        graphItems.push({
          value: activityMatch.people,
          time: activityMatch.batchDate
        });

        if (activityMatch.people > suggestedMax) {
          suggestedMax = activityMatch.people;
        }

        if (activityMatch.people < suggestedMin) {
          suggestedMin = activityMatch.people;
        }
      }

      runningDate = runningDate.add(1, 'day');
    }

    suggestedMin = (suggestedMin < 0 ? suggestedMin * 2 : 0);

    let result = graphItems.map((item) => {
      return {
        value: item.value,
        time: item.time,
        title: item.value + ' ' + moment(item.time).format('DD.MM.YYYY'),
        height: 100 * (item.value - suggestedMin) / (suggestedMax - suggestedMin)
      }
    });

    if (result.length === 1) {
      result[0].height = 100;
    }

    return result;
  }

  ngOnDestroy(): void {
    console.log("destroy");
  }

}
