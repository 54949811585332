import {Component, Inject, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {
  CoreConfig,
  PageResponse,
  PersonSeriesGroup,
  PersonSeriesGroupPeriod,
  SMARTENCITY_CORE_CONFIG
} from '@smartencity/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {ToastrService} from 'ngx-toastr';
import {DatapointGroupSeriesListService} from '../datapoint-group-series-list.service';
import {PersonSeriesGroupApiService} from '../../../http/person-series-group-api.service';

@Component({
  selector: 'datapoint-group-consents-modal',
  templateUrl: './datapoint-group-consents-modal.component.html',
  providers: [DatapointGroupSeriesListService]
})
export class DatapointGroupConsentsModalComponent implements OnInit {

  @Input()
  group: PersonSeriesGroup = null;

  @ViewChild('regNoTemplate', {static: true})
  regNoTemplate: TemplateRef<any>;

  @ViewChild('cprDobTemplate', {static: true})
  cprDobTemplate: TemplateRef<any>;

  personTempalte = null;

  consents: PersonSeriesGroupPeriod[] = [];

  constructor(
    @Inject(SMARTENCITY_CORE_CONFIG) private config: CoreConfig,
    public modalRef: BsModalRef,
    public toastr: ToastrService,
    private personSeriesGroupService: PersonSeriesGroupApiService) {
    this.personTempalte = this.regNoTemplate;
  }

  ngOnInit(): void {
    if (this.config.personRegNoTemplate === 'cpr-dob') {
      this.personTempalte = this.cprDobTemplate;
    }

    this.loadConsents();
  }

  endConsent(period: PersonSeriesGroupPeriod): void {
    this.personSeriesGroupService.endConsent(period.personSeriesGroup.id, period.id).subscribe(() => {
      this.loadConsents();
    });
  }

  close(): void {
    this.modalRef.hide();
  }

  private loadConsents(): void {
    if (this.group) {
      this.personSeriesGroupService.getPersonSeriesGroupConsents(this.group.id).subscribe((response: PageResponse<PersonSeriesGroupPeriod>) => {
        this.consents = response.content;
      });
    }
  }


}
