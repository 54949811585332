import {AbstractControl, FormControl} from '@angular/forms';
import {Inject, Injectable} from '@angular/core';
import {SMARTENCITY_CORE_CONFIG} from '../injection-tokens';
import {CoreConfig} from '../core-config.model';
import {PersonCodeValidator} from '../validators/person-code-validator';

@Injectable({
  providedIn: 'root'
})
export class PersonRegistrationNumberValidator {

  constructor(@Inject(SMARTENCITY_CORE_CONFIG) private config: CoreConfig) {
  }

  check(control: AbstractControl): any {

    const input = <FormControl>control;
    if (!input.value) {
      return;
    }

    let value;
    let cc;
    if (typeof(input.value) === 'string') {
        value = input.value;
        cc = this.config.defaultCountryCode;
    } else {
        value = input.value.registrationNumber;
        cc = input.value.countryCode;
    }

    if (PersonCodeValidator.validate(value, cc)) {
      return null;
    }

    return {
      personRegistrationNumber: true
    };

  }
}
