import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs/internal/Subject';
import {HttpClient} from '@angular/common/http';
import {SMARTENCITY_MYDATA_CONFIG} from '../../../injection-tokens';
import {MyDataConfig} from '../../../mydata-config.model';
import {Observable} from 'rxjs/internal/Observable';
import {AuthService, Mandate, PageResponse, Person, Token, TokenPerson, TokenPersonHistory} from '@smartencity/core';
import {map, takeUntil} from 'rxjs/operators';
import {BsModalService} from 'ngx-bootstrap/modal';
import {TokenPersonsModalComponent} from './token-persons-modal/token-persons-modal.component';
import {PersonTokenApiService} from '../../../http/person-token-api.service';
import {TokenPersonHistoryModalComponent} from './token-person-history-modal/token-person-history-modal.component';
import {forkJoin} from 'rxjs/internal/observable/forkJoin';

export class TokenItem {
    token: Token;
    history: TokenPersonHistory
}

@Component({
  selector: 'mydata-dal-api-token',
  templateUrl: './dal-api-token.component.html'
})
export class DalApiTokenComponent implements OnInit, OnDestroy {
  private ngDestroy = new Subject<void>();

  tokens: Token[] = [];

  items: TokenItem[] = [];

  public documentationUrl = this.config.apiUrl + '/swagger-ui.html';

  public documentationLabel = $localize`API Documentation`;

  public currentMandate: Mandate;

  constructor(
    @Inject(SMARTENCITY_MYDATA_CONFIG) public config: MyDataConfig,
    private http: HttpClient,
    private modalService: BsModalService,
    public authService: AuthService,

    private personTokenApi: PersonTokenApiService
  ) { }

  ngOnInit() {
    this.loadPage();
    this.authService.currentMandate$.pipe(takeUntil(this.ngDestroy)).subscribe((mandate: Mandate) => {
      this.currentMandate = mandate;
    });
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  loadPage() {
    this.getTokens({
      page: 1,
      size: 2000
    }).subscribe({
      next: (data: PageResponse<Token>) => {
        let tokens = [];
        if (data.content != null && data.content.length > 0) {
          tokens = data.content;
        }

        let observables = [];
        for (let token of tokens) {
          observables.push(this.mapToTokenItem(token));
        }

        forkJoin(observables).subscribe((tokenItems: TokenItem[]) => {

          this.items = tokenItems;

          this.tokens = tokens;
          if (!this.hasPrimaryToken(tokens)) {
            this.createToken();
          }

        });


      },
      error: (err) => {
        console.error(err);
      }
    });
  }

  private mapToTokenItem(token: Token): Observable<TokenItem> {
    return this.personTokenApi.getTokenPersonHistory(token).pipe(map((history: TokenPersonHistory) => {
        return {
          token: token,
          history: history
        };
      }));
  }

  getTokens(filter: any): Observable<PageResponse<Token>> {
    const params: any = {
      page: filter.page - 1,
      size: filter.size
    };

    return this.personTokenApi.getTokens(params);
  }

  mapTokenPersonToPersons(tokenPersons: TokenPerson[]): Person[] {

    return tokenPersons.map(tokenPerson => tokenPerson.person);
  }


  createToken() {
    const token = new Token();
    token.name = 'API key';

    this.personTokenApi.createToken(token).subscribe((result: Token) => {
      this.tokens = [result];
    });

  }

  isMyToken(token): boolean {
    const currentPerson = this.currentMandate.person;

    return currentPerson.registrationNumber === token.ownerPerson.registrationNumber
      && currentPerson.countryCode === token.ownerPerson.countryCode;
  }

  showTokenPersons(token: Token): void {
    if (!token.tokenPersons.length) {
      return;
    }

    this.modalService.show(TokenPersonsModalComponent, {
      class: 'modal-lg',
      initialState: {
        tokenPersons: token.tokenPersons
      }
    })
  }

  showHistory(item: TokenItem): void {

    this.modalService.show(TokenPersonHistoryModalComponent, {
      class: 'modal-lg',
      initialState: {
        history: item.history
      }
    });
  }

  private hasPrimaryToken(tokens: Token[]): boolean {
    let primaryTokens = tokens.filter(t => t.primary);

    return primaryTokens.length > 0;
  }

}
