export class UrlHelper {

    public static isUrl(value: string): boolean {
        let url;
  
        try {
          url = new URL(value);
        } catch (e) {
          return false;  
        }
      
        return url.protocol === "http:" || url.protocol === "https:";
    }
}
