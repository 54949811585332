<table style="width: 100%;" class="table table-sm mb-0" [hidden]="!hasDatasets || !hasData">
  <thead>
  <tr>
    <th>{{headerLabels.name}}</th>
    <th>{{startAt|date:'dd.MM.yyyy'}}</th>
    <th>{{endAt|date:'dd.MM.yyyy'}}</th>
    <th class="w-25">{{headerLabels.value}}</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let row of values">
    <td>{{row.label}}</td>
    <td>
      <span *ngIf="row.first != null">{{row.first|numberFormat:row.numberFormat:locale}} {{row.unit}}</span>
    </td>
    <td>
      <span *ngIf="row.last != null">{{row.last|numberFormat:row.numberFormat:locale}} {{row.unit}}</span>
    </td>
    <td class="w-25">
      <ng-container *ngIf="row.value != null">
        <span>{{row?.value|numberFormat:row.numberFormat:locale}} {{row.unit}}</span>
      </ng-container>
    </td>
  </tr>
  </tbody>
</table>
<div style="width: 100%;display: flex;justify-content: center;align-items: center;" *ngIf="hasDatasets && !hasData">
  <div class="text-center">
    <h3 class="text-light" i18n>Waiting for data...</h3>
    <div class="spinner-grow text-light" style="width: 3rem; height: 3rem;" role="status"></div>
  </div>
</div>
<div style="width: 100%;display: flex;justify-content: center;align-items: center;" *ngIf="!hasDatasets">
  <div class="text-center">
    <h3 class="text-light" i18n>Empty</h3>
  </div>
</div>
