import {Person} from './person';
import {LocationType, PersonSeriesGroupRef, SeriesNumberFormat} from './series';

export class PersonParameter {
  id: number;
  name: string;
  description: string;
  type: string;
  value: number;
  unit: string;
  groupingTags: string[] = [];
  locationType: LocationType;
  adsOid: string;
  address: string;
  apartment: string;
  room: string;
  lat: number;
  lng: number;
  uuid: string;
  person: Person;
  createdByPerson: Person;
  updatedAt: string;
  groups: PersonSeriesGroupRef[] = [];
  numberFormat: SeriesNumberFormat
}

export class PersonParameterResponse {
  content: PersonParameter[];
  page: number;
  size: number;
  totalElements: number;
  totalPages: number;
}

export enum ParameterType {
  CLOSED_NET_AREA = 'CLOSED_NET_AREA',
  HEATED_AREA = 'HEATED_AREA',
  DWELLINGS_AREA = 'DWELLINGS_AREA',
  CONSTRUCTION_YEAR = 'CONSTRUCTION_YEAR',
  DEGREE_DAYS_AREA = 'DEGREE_DAYS_AREA',
  POPULATION = 'POPULATION',
  FLOORS_COUNT = 'FLOORS_COUNT',
  APARTMENTS_COUNT = 'APARTMENTS_COUNT',
  USAGE_TYPE = 'USAGE_TYPE',
  STAIRWELLS_COUNT = 'STAIRWELLS_COUNT',
  ENERGY_PERFORMANCE_LABEL = 'ENERGY_PERFORMANCE_LABEL',
  ENERGY_PERFORMANCE_NR = 'ENERGY_PERFORMANCE_NR',
  BALANCE_TEMPERATURE = 'BALANCE_TEMPERATURE',
  HEATING_SYSTEM = 'HEATING_SYSTEM',
  TYPE_OF_WATER_SUPPLY = 'TYPE_OF_WATER_SUPPLY'
}

export class ConstantParams {

  public static buildingParams: ParameterType[] = [
    ParameterType.CLOSED_NET_AREA,
    ParameterType.HEATED_AREA,
    ParameterType.DWELLINGS_AREA,
    ParameterType.CONSTRUCTION_YEAR,
    ParameterType.DEGREE_DAYS_AREA,
    ParameterType.POPULATION,
    ParameterType.FLOORS_COUNT,
    ParameterType.APARTMENTS_COUNT,
    ParameterType.USAGE_TYPE,
    ParameterType.STAIRWELLS_COUNT,
    ParameterType.ENERGY_PERFORMANCE_LABEL,
    ParameterType.ENERGY_PERFORMANCE_NR,
    ParameterType.BALANCE_TEMPERATURE,
    ParameterType.HEATING_SYSTEM,
    ParameterType.TYPE_OF_WATER_SUPPLY
  ];

  public static requiredForBuilding: ParameterType[] = [
    ParameterType.CLOSED_NET_AREA,
    ParameterType.CONSTRUCTION_YEAR,
    ParameterType.DEGREE_DAYS_AREA,
    ParameterType.POPULATION,
    ParameterType.FLOORS_COUNT,
    ParameterType.APARTMENTS_COUNT,
    ParameterType.USAGE_TYPE,
    ParameterType.HEATING_SYSTEM,
    ParameterType.TYPE_OF_WATER_SUPPLY
  ];

  public static isRequiredBuildingParam(key: ParameterType){
    return ConstantParams.requiredForBuilding.indexOf(key) > -1;
  }
}
