<div class="jumbotron floating-jumbotron content-wrapper location-modal" [class.active]="location">
  <div class="modal-header">

    <div class="w-100">
      <span>Päikeseenergia tootmine<br /></span>
      <span>
        <span class="text-uppercase font-weight-bold">
          <span *ngIf="location?.type === seriesLocationTypeEnum.SOLAR_PANELS_TOTAL">KÕIK JAAMAD</span>
          <span *ngIf="location?.type === seriesLocationTypeEnum.LOCATION">{{location.readableAddress ? location.readableAddress : location.address}}</span>
        </span>
        <span>, alates 06.2019</span>
      </span>
    </div>


<!--    <h5 class="modal-title w-100" *ngIf="location?.type === seriesLocationTypeEnum.LOCATION">-->
<!--      {{location.readableAddress ? location.readableAddress : location.address}}-->
<!--    </h5>-->
<!--    <h5 class="modal-title w-100" *ngIf="location?.type === seriesLocationTypeEnum.SOLAR_PANELS_TOTAL">-->
<!--      PÄIKESEENERGIA-->
<!--    </h5>-->
<!--    <h5 class="modal-title w-100" *ngIf="location?.type === seriesLocationTypeEnum.GENERAL">-->
<!--      ÜLD-->
<!--    </h5>-->
    <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
      <i class="fal fa-times"></i>
    </button>
  </div>

  <div class="modal-body">
    <div class="d-flex justify-content-center">
      <div class="pointer-info position-relative">

        <div *ngIf="location?.type !== seriesLocationTypeEnum.GENERAL">
          <div class="current-value-bar">
            <svg width="180" height="180" viewBox="-25 -25 400 400">
              <circle stroke="#FBC748" cx="176" cy="176" r="176" transform="rotate(-9.899459 176 176)" stroke-width="45" fill="none" style="stroke-dasharray: 20, 9.899459;"></circle>
              <circle stroke="#FBC748" transform="rotate(-87 176 176)" cx="176" cy="176" r="176" stroke-dasharray="1106.28" stroke-width="45" stroke-dashoffset="1106.28" fill="none" [style.stroke-dashoffset]="currentValueBarStrokeDashoffset"></circle>
            </svg>
            <div class="chart-icon icon-solarpark"></div>
            <span>{{currentValueBarValue | number:'.0-0'}}%</span>
          </div>
          <div class="timeline">
            <ul class="nav justify-content-center">
              <li class="nav-item">
                <a class="nav-link" [class.active]="periodTypeControl.value === 'week'" (click)="setPeriodType('week')">7 päeva</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" [class.active]="periodTypeControl.value === 'month'" (click)="setPeriodType('month')">30 päeva</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" [class.active]="periodTypeControl.value === 'year'" (click)="setPeriodType('year')">aasta</a>
              </li>
            </ul>
            <div class="graph graph-bar">
              <span class="title">{{chartYLabel}}</span>
              <div class="grid">
                <span class="bar" [ngStyle]="{height: value.height + '%'}" [tooltip]="value.title" *ngFor="let value of chartValues"></span>
              </div>
            </div>
          </div>
        </div>

        <solar-indicators [cityPortalTypeData]="cityPortalTypeData" [location]="location"></solar-indicators>
        <env-indicators [cityPortalTypeData]="cityPortalTypeData" [location]="location"></env-indicators>
      </div>
    </div>
  </div>
</div>
