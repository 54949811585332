import { NgModule } from '@angular/core';
import { ServicesComponent } from './services.component';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgSelectModule} from '@ng-select/ng-select';
import {SearchComponent} from './search/search.component';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {CoreModule} from '../../../core/src/lib/core.module';

@NgModule({
  declarations: [ServicesComponent, SearchComponent],
    imports: [
      CommonModule,
      RouterModule,
      FormsModule,
      ReactiveFormsModule,
      CoreModule,
      NgSelectModule,
      TooltipModule
    ],
  exports: [ServicesComponent]
})
export class ServicesModule { }
