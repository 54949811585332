<ng-container [formGroup]="form">
  <td>
    <ng-container *ngIf="!editMode">{{form.get('name').value}}</ng-container>
    <ng-container *ngIf="editMode">
      <div class="position-relative">
        <input type="text" class="form-control" [class.is-invalid]="invalid('name')" formControlName="name" placeholder="Name" i18n-placeholder>
        <small *ngIf="invalid('name') && errors('name')?.required" class="invalid-feedback d-block" i18n>Required</small>
      </div>
    </ng-container>
  </td>
  <td>
    <ng-container *ngIf="!editMode">{{form.get('value').value}}</ng-container>
    <ng-container *ngIf="editMode">
      <div class="position-relative">
        <input type="text" class="form-control" [class.is-invalid]="invalid('value')" formControlName="value" placeholder="Value" i18n-placeholder>
        <small *ngIf="invalid('value') && errors('value')?.required" class="invalid-feedback d-block" i18n>Required</small>
      </div>
    </ng-container>
  </td>
  <td>
    <ng-container *ngIf="!editMode">{{comparisonOperators[form.get('comparisonOperator').value]}}</ng-container>
    <ng-container *ngIf="editMode">
      <div class="position-relative" style="padding-bottom: .8rem">
        <ng-select class="threshold-select" placeholder="Period" i18n-placeholder [class.is-invalid]="invalid('comparisonOperator')" formControlName="comparisonOperator" [items]="comparisonOperatorOptions" bindValue="value" bindLabel="label" [appendTo]="'body'"></ng-select>
        <small *ngIf="invalid('comparisonOperator') && errors('comparisonOperator')?.required" class="invalid-feedback d-block" i18n>Required</small>
      </div>
    </ng-container>
  </td>
  <td class="text-center">
    <ng-container *ngIf="!editMode && form.get('enabled').value" i18n>Enabled</ng-container>
    <ng-container *ngIf="!editMode && !form.get('enabled').value" i18n>Disabled</ng-container>
    <div class="position-relative" *ngIf="editMode">
      <div class="checkbox">
        <input type="checkbox" [class.is-invalid]="invalid('enabled')" formControlName="enabled">
      </div>
      <small *ngIf="invalid('enabled') && errors('enabled')?.required" class="invalid-feedback d-block" i18n>Required</small>
    </div>
  </td>
  <td class="text-right text-nowrap">
    <button type="button" class="btn btn-link btn-sm mr-2" [hidden]="editMode" (click)="remove()" [disabled]="progress"><i class="fal fa-trash"></i>&nbsp;<ng-container i18n>Remove</ng-container></button>
    <button type="button" class="btn btn-link btn-sm mr-2" [hidden]="editMode" (click)="edit()" [disabled]="progress"><i class="fal fa-edit"></i>&nbsp;<ng-container i18n>Edit</ng-container></button>
    <button type="button" class="btn btn-sm btn-light mr-2" [hidden]="!editMode" (click)="cancel()" [disabled]="progress" i18n>Cancel</button>
    <button type="button" class="btn btn-sm btn-primary" [hidden]="!editMode" (click)="save()" i18n>Save</button>
  </td>
</ng-container>
