import OverlayView = google.maps.OverlayView;
import {WifiAccessPoint} from '../model/wifi-access-point';

export class WifiAccessPointsOverlayView extends OverlayView {

  public accessPoints: WifiAccessPoint[] = [];

  private div: any;
  private popoverTimeout = null;

  constructor(accessPoints: WifiAccessPoint[]) {
    super();
    this.accessPoints = accessPoints;
  }

  draw() {
    if (!this.div) {
      const panes = this.getPanes();
      if (panes == null) {
        return;
      }

      this.div = document.createElement('DIV');
      this.div.style.position = 'absolute';
      this.div.style.cursor = 'pointer';
      this.div.style.position = 'absolute';
      this.div.classList.add('custom-marker-layer');
      this.div.style.paddingLeft = '0px';
      panes.overlayMouseTarget.appendChild(this.div);
    }

    this.div.innerHTML = this.getLayerHtml();

    this.resetPopovers();
  }

  private getLayerHtml() {
    let html = '';

    if (!this.accessPoints) {
      return html;
    }

    this.accessPoints.forEach(item => {
      const point = this.getProjection().fromLatLngToDivPixel(new google.maps.LatLng(item.lat, item.lng));
      const x = point.x - 22;
      const y = point.y - 46;
      let title: string = "<span>" + item.name;
      if (item.connectedCount != null) {
        title += "<br />";
        title += $localize`Connections: ${item.connectedCount}`;
      } else {
        title += "<br />";
        title += $localize`No connections`;
      }
      title += "</span>"
      html += '<div class="pointer-wifi-ap" style="left: ' + x + 'px; top: ' + y + 'px" data-trigger="hover" data-toggle="popover" data-placement="top" data-html="true" data-content="' + title + '"></div>';
    });

    return html;
  }

  setMap(map) {
    super.setMap(map);
  }

  private resetPopovers() {
    if (this.popoverTimeout != null) {
      clearTimeout(this.popoverTimeout);
    }
    this.popoverTimeout = setTimeout(() => {
      $('.popover.show').remove();
      ($(this.div).find('[data-toggle="popover"]') as any).popover({
        trigger: 'hover'
      });

    }, 400);
  }

  onRemove() {
    if (this.div) {
      this.div.parentNode.removeChild(this.div);
      this.div = null;
    }
  }

}
