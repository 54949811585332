import {Shape, ShapePoint} from '../model/shape';
import PolylineOptions = google.maps.PolylineOptions;

export class BusRoute extends google.maps.Polyline {

  public shape: Shape;
  public color: string;

  constructor(opts: PolylineOptions, shape: Shape) {

    opts.strokeOpacity = opts.strokeOpacity ? opts.strokeOpacity : 1.0;
    opts.strokeWeight = opts.strokeWeight ? opts.strokeWeight : 5;

    super(opts);
    this.color = opts.strokeColor;
    this.shape = shape;
    this.generatePath();
  }

  pullBack() {
    this.setVisible(false);
  }

  pullUp() {
    this.setOptions({
      strokeOpacity: 1,
      strokeWeight: 5,
      strokeColor: '#0000FF',
      visible: true
    });
  }

  reset() {
    this.setOptions({
      strokeOpacity: 1,
      strokeWeight: 5,
      strokeColor: this.color,
      zIndex: 10,
      visible: true
    });
  }

  generatePath() {
    this.setPath(this.shape.shapePoints.sort((a, b) => {
      if (a.s < b.s) {
        return -1;
      }
      if (a.s > b.s) {
        return 1;
      }
      return 0;
    }).map((shapePoint: ShapePoint) => {
      return new google.maps.LatLng(shapePoint.lat, shapePoint.lng);
    }));
  }
}

