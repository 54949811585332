<div class="jumbotron floating-jumbotron search-wrapper">
  <div class="input-group input-group-lg">
    <input [formControl]="keywordControl" type="text" class="form-control">
    <div class="input-group-append">

      <span (click)="clear()" class="clear-search" [ngClass]="{'active': keywordControl.value?.length > 0 }">
        <i class="fal fa-times"></i>
      </span>

      <span class="input-group-text">
        <i class="fal fa-search"></i>
      </span>
    </div>
  </div>
</div>

<div class="jumbotron floating-jumbotron content-wrapper search-results" id="search-results"
     [ngClass]="{'active': buildings.length > 0 || windTurbines.length > 0}">
  <ul class="list-group">
    <ng-container *ngIf="buildings.length > 0">
      <li class="list-group-item title">
        <ng-container i18n>Building</ng-container>
      </li>
      <li class="list-group-item" *ngFor="let building of buildings"
          (click)="selectBuilding(building)">{{building.address}}</li>
    </ng-container>
    <ng-container *ngIf="windTurbines.length > 0">
      <li class="list-group-item title">
        <ng-container i18n>Wind turbine</ng-container>
      </li>
      <li class="list-group-item" *ngFor="let windTurbine of windTurbines"
          (click)="selectWindTurbine(windTurbine)">{{windTurbine.owner}}</li>
    </ng-container>
  </ul>
</div>
