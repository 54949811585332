<ng-template #navActions>
  <core-compare-button class="d-none d-lg-inline-block"></core-compare-button>
  <button type="button" class="btn btn-sm btn-primary d-none d-lg-inline-block" (click)="createSeries()">
    <i class="fal fa-plus"></i>&nbsp;<span i18n>Create new</span>
  </button>
  <div class="btn-floating-mobile-container">
    <button type="button" class="btn btn-sm btn-primary btn-floating-mobile" (click)="createSeries()">
      <i class="fal fa-plus fa-2x"></i>
    </button>

    <core-compare-button></core-compare-button>
  </div>
</ng-template>

<div class="mb-3">
  <div class="row justify-content-center">
    <div class="col-sm-12 col-md-6 col-lg-4">
      <div class="input-group input-group-lg form-search" [formGroup]="filterForm">
        <input type="text" class="form-control form-control-lg" placeholder="Type to search..." i18n-placeholder formControlName="query">
        <div class="input-group-append">
          <span class="clear-search" (click)="clearSearch()">
            <i class="fal fa-times"></i>
          </span>
<!--          <div class="custom-dropdown filter-dropdown" dropdown [autoClose]="true" (click)="$event.stopImmediatePropagation();">-->
<!--            <button type="button" class="btn dropdown-toggle" dropdownToggle i18n>Filter</button>-->
<!--            <div class="dropdown-menu dropdown-menu-right" *dropdownMenu>-->
<!--              <div class="accordion-group"  [ngClass]="'show'">-->
<!--                <div class="accordion-header">-->
<!--                  <i class="fal fa-angle-down"></i>-->
<!--                  <ng-container i18n>Status</ng-container>-->
<!--                </div>-->
<!--                <div class="accordion-content">-->
<!--                  <div class="checkbox">-->
<!--                    <input type="checkbox" formControlName="confirmationStatusPending">-->
<!--                    <label i18n>Pending</label>-->
<!--                  </div>-->
<!--                  <div class="checkbox">-->
<!--                    <input type="checkbox" formControlName="confirmationStatusAccepted">-->
<!--                    <label i18n>Accepted</label>-->
<!--                  </div>-->
<!--                  <div class="checkbox">-->
<!--                    <input type="checkbox" formControlName="confirmationStatusDeclined">-->
<!--                    <label i18n>Declined</label>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="accordion-group"  [ngClass]="'show'">-->
<!--                <div class="accordion-header" *ngIf="isCityUser">-->
<!--                  <i class="fal fa-angle-down"></i>-->
<!--                  <ng-container i18n>Show on city portal</ng-container>-->
<!--                </div>-->
<!--                <div class="accordion-content" *ngIf="isCityUser">-->
<!--                  <div class="checkbox">-->
<!--                    <input type="checkbox" formControlName="showOnCityPortalTrue">-->
<!--                    <label i18n>Yes</label>-->
<!--                  </div>-->
<!--                  <div class="checkbox">-->
<!--                    <input type="checkbox" formControlName="showOnCityPortalFalse">-->
<!--                    <label i18n>No</label>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
          <span class="input-group-text"><i class="fal fa-search"></i></span>
        </div>
      </div>
    </div>
    <div class="col-12 mt-2">
      <div class="d-flex justify-content-center flex-wrap flex-md-nowrap" *ngIf="filterForm.get('confirmationStatusPending').value || filterForm.get('confirmationStatusAccepted').value || filterForm.get('confirmationStatusDeclined').value">
        <label i18n>Filter</label>
        <span class="filter-tag" *ngIf="filterForm.get('confirmationStatusPending').value">{{'PENDING' | label}} <i class="fal fa-times" (click)="filterForm.get('confirmationStatusPending').setValue(false)"></i></span>
        <span class="filter-tag" *ngIf="filterForm.get('confirmationStatusAccepted').value">{{'ACCEPTED' | label}} <i class="fal fa-times" (click)="filterForm.get('confirmationStatusAccepted').setValue(false)"></i></span>
        <span class="filter-tag" *ngIf="filterForm.get('confirmationStatusDeclined').value">{{'DECLINED' | label}} <i class="fal fa-times" (click)="filterForm.get('confirmationStatusDeclined').setValue(false)"></i></span>
      </div>
    </div>
  </div>
<!--  <div class="row">-->
<!--    <div class="col mt-2 text-right">-->
<!--      <button type="button" class="btn btn-sm btn-primary mr-2" (click)="bulkEdit()" [hidden]="checkedEditableCount() == 0"><i class="fa fa-edit"></i>&nbsp;<ng-container i18n>Bulk edit</ng-container>&nbsp;({{checkedEditableCount()}})</button>-->
<!--      <button type="button" class="btn btn-sm btn-danger mr-2" (click)="bulkDecline()" [hidden]="checkedDeclinableCount() == 0"><i class="fa fa-times"></i>&nbsp;<ng-container i18n>Decline selected</ng-container>&nbsp;({{checkedDeclinableCount()}})</button>-->
<!--      <button type="button" class="btn btn-sm btn-outline-danger mr-2" (click)="bulkArchive()" [hidden]="checkedArchivableCount() == 0"><i class="fa fa-trash"></i>&nbsp;<ng-container i18n>Archive selected</ng-container>&nbsp;({{checkedArchivableCount()}})</button>-->
<!--    </div>-->
<!--  </div>-->

  <div class="row">
    <div class="col mt-2 text-right">
      <button type="button" class="btn btn-sm btn-primary mr-2" (click)="searchGroup()" [hidden]="checkedEditableCount() == 0"><i class="fal fa-plus"></i>&nbsp;
        <ng-container i18n>Add to datagroup</ng-container>&nbsp;({{checkedEditableCount()}})
      </button>

      <button type="button" class="btn btn-sm btn-primary mr-2" (click)="createConsents()" *ngIf="selectedShareable.length > 0">
        <ng-container i18n>Share data points</ng-container>&nbsp;({{selectedShareable.length}})
      </button>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-12">
    <ngx-datatable
      #table
      class="material expandable"
      [draggable]="false"
      [columnMode]="'flex'"
      [headerHeight]="30"
      [footerHeight]="50"
      [reorderable]="false"
      rowHeight="auto"
      [scrollbarH]="false"
      [scrollbarV]="false"
      [rows]="rows"
      [externalPaging]="true"
      [externalSorting]="true"
      [limit]="page?.size"
      [count]="page?.totalElements"
      [sorts]="defaultSorts"
      [selected]="selected"
      [selectionType]="'checkbox'"
      (page)="setPage($event)"
      (sort)="setSorts($event)"
      (select)="onSelect($event)"
    >
      <ngx-datatable-row-detail [rowHeight]="210" #detailRow>
        <ng-template
          let-row="row"
          let-ownerType="row.ownerType"
          let-series="row.series"
          let-ps="row.series.personSeries"
          let-confirmationStatus="row.confirmationStatus"
          let-expanded="expanded" ngx-datatable-row-detail-template>
          <div style="font-size:14px; padding: 0.7rem">

            <div class="row">
              <div class="col-6"><label i18n>Datapoint Name</label></div>
              <div class="col-6">{{row.name}}<span *ngIf="row?.description">&nbsp;<i class="fa fa-info-circle" [tooltip]="row?.description"></i></span></div>
            </div>

            <div class="row">
              <div class="col-6"><label i18n>Owner</label></div>
              <div class="col-6">
                <div>
                  <ng-container *ngIf="ownerType == 'OWNER'" i18n>Myself</ng-container>
                  <ng-container *ngIf="ownerType != 'OWNER'">
                    <span class="text-nowrap">{{ps?.dataOwnerPerson?.displayName}}</span>&nbsp;({{ps?.dataOwnerPerson | regNo}})
                  </ng-container>
                </div>
              </div>
            </div>

<!--            <div class="row">-->
<!--              <div class="col-6"><label i18n>Activity Date</label></div>-->
<!--              <div class="col-6">{{row.updatedAt | date:'dd. MMMM yyyy'}}</div>-->
<!--            </div>-->

            <div class="row">
              <div class="col-6"><label i18n>Status</label></div>
              <div class="col-6">
                <span *ngIf="confirmationStatus == 'PENDING'" class="text-info" i18n>Pending</span>
                <span *ngIf="confirmationStatus == 'ACCEPTED'" class="text-success" i18n>Accepted</span>
                <span *ngIf="confirmationStatus == 'DECLINED'" class="text-warning" i18n>Declined</span>
                <span *ngIf="confirmationStatus == 'CANCELLED'" class="text-warning" i18n>Cancelled</span>
              </div>
            </div>
            <div class="row pt-3">
              <button type="button" class="btn btn-sm btn-link no-caret" *ngIf="isOwner(series)" (click)="showConsents(series)">&nbsp;
                <ng-container i18n>Share data</ng-container>
              </button>
              <button type="button" class="btn btn-sm btn-link no-caret" *ngIf="isOwner(series)" (click)="setRowDataOwner(series)">
                <ng-container i18n>Set new data owner</ng-container>
              </button>
              <button type="button" class="btn btn-sm btn-link no-caret" *ngIf="isOwner(series) && series.lastPeriod.confirmationStatus == 'PENDING'" (click)="cancelOwnership(series)">
                <ng-container i18n>Cancel</ng-container>
              </button>

              <button type="button" class="btn btn-sm btn-link no-caret" (click)="editSeries(row.series)"
                      *ngIf="row.confirmationStatus != 'PENDING'">
                <i class="fal fa-edit mr-2"></i>
                <ng-container i18n>Edit</ng-container>
              </button>
              <button type="button" class="btn btn-sm btn-link no-caret" (click)="declineOwnership(row.series)"
                      *ngIf="row.confirmationStatus == 'PENDING'">
                <i class="fal fa-times-circle mr-2"></i>
                <ng-container i18n>Decline</ng-container>
              </button>
              <button type="button" class="btn btn-sm btn-link no-caret" (click)="acceptOwnership(row.series)" *ngIf="row.confirmationStatus == 'PENDING'">
                <i class="fal fa-check-circle mr-2"></i>
                <ng-container i18n>Accept</ng-container>
              </button>
              <button type="button" class="btn btn-sm btn-link no-caret" (click)="declineOwnership(row.series)" *ngIf="row.confirmationStatus == 'ACCEPTED'">
                <i class="fal fa-trash mr-2"></i>
                <ng-container i18n>Cancel</ng-container>
              </button>
              <button type="button" class="btn btn-sm btn-link no-caret" (click)="archiveSeries(row.series)" *ngIf="row.confirmationStatus == 'CANCELLED'">
                <i class="fal fa-trash mr-2"></i>
                <ng-container i18n>Acknowledge</ng-container>
              </button>
              <button type="button" class="btn btn-sm btn-link no-caret" (click)="archiveSeries(row.series)" *ngIf="row.confirmationStatus == 'DECLINED'">
                <i class="fal fa-trash mr-2"></i>
                <ng-container i18n>Archive</ng-container>
              </button>
            </div>
          </div>
        </ng-template>
      </ngx-datatable-row-detail>

      <ngx-datatable-column
        *ngIf="columns.check"
        [width]="50"
        [sortable]="false"
        [canAutoResize]="false"
        [draggable]="false"
        [resizeable]="false"
      >
        <ng-template
          ngx-datatable-header-template
          let-value="value"
          let-allRowsSelected="allRowsSelected"
          let-selectFn="selectFn"
        >
          <div class="checkbox position-absolute">
            <input type="checkbox" [ngModel]="allRowsSelected" (change)="selectFn(!allRowsSelected)"/>
          </div>
        </ng-template>
        <ng-template
          ngx-datatable-cell-template
          let-value="value"
          let-isSelected="isSelected"
          let-onCheckboxChangeFn="onCheckboxChangeFn"
        >
          <div class="checkbox">
            <input type="checkbox" [ngModel]="isSelected" (change)="onCheckboxChangeFn($event)"/>
          </div>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column *ngIf="columns.name" name="Datapoint Name" i18n-name prop="name" [flexGrow]="1" [resizeable]="false" [sortable]="true">
        <ng-template let-value="value" let-showOnCityPortal="row.showOnCityPortal" let-row="row" ngx-datatable-cell-template>
          <span>{{value}}</span>
          <span *ngIf="isCityUser && showOnCityPortal">&nbsp;<i class="fa fa-map-marker-check"></i></span>
          <span *ngIf="row?.description">&nbsp;<i class="fa fa-info-circle" [tooltip]="row?.description"></i></span>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column *ngIf="columns.address" name="Address" i18n-name [flexGrow]="1" [resizeable]="false" [sortable]="false">
        <ng-template let-ps="row.series.personSeries" ngx-datatable-cell-template>
          <ng-container *ngIf="ps.locationType == 'ADDRESS'">
            {{ps.address}}<ng-container *ngIf="ps.apartment"> - {{ps.apartment}}</ng-container>
          </ng-container>
          <ng-container *ngIf="ps.locationType == 'COORDS'">
            {{ps.lat}}-{{ps.lng}}
          </ng-container>
          <ng-container *ngIf="ps.locationType == 'UUID'">
            {{ps.uuid}}
          </ng-container>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column *ngIf="columns.owner" name="Owner" i18n-name [flexGrow]="1" [resizeable]="false" [sortable]="false">
        <ng-template let-ownerType="row.ownerType" let-series="row.series" ngx-datatable-cell-template>
          <ng-template #creatorPopover>
            <ng-container *ngIf="ownerType != 'CONSENT'">
              <h6 i18n>Created by</h6>
              {{series?.personSeries?.createdByPerson?.displayName}}
            </ng-container>
            <ng-container *ngIf="ownerType == 'CONSENT'">
              <h6 i18n>Shared by</h6>
              {{series?.dataOwnerPerson?.displayName}}&nbsp;({{series?.dataOwnerPerson | regNo}})
            </ng-container>
          </ng-template>
          <a [routerLink]="null" [popover]="creatorPopover" triggers="hover" container="body">
            <ng-container *ngIf="ownerType == 'OWNER' || ownerType == 'DATA_OWNER'" i18n>Myself</ng-container>
            <ng-container *ngIf="ownerType != 'OWNER' && ownerType != 'DATA_OWNER'">
              <span class="text-nowrap">{{series?.dataOwnerPerson?.displayName}}</span>&nbsp;({{series?.dataOwnerPerson | regNo}})
            </ng-container>
          </a>
        </ng-template>
      </ngx-datatable-column>
<!--      <ngx-datatable-column *ngIf="columns.activityDate" name="Activity Date" i18n-name prop="updatedAt" [flexGrow]="1" [resizeable]="false" [sortable]="true">-->
<!--        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>-->
<!--          {{value | date:'dd. MMMM yyyy'}}-->
<!--        </ng-template>-->
<!--      </ngx-datatable-column>-->
      <ngx-datatable-column *ngIf="columns.status" name="Status" i18n-name prop="confirmationStatus" [flexGrow]="1" [resizeable]="false" [sortable]="false">
        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
          <span *ngIf="value == 'PENDING'" class="badge badge-warning rounded-pill p-2" i18n>Pending</span>
          <span *ngIf="value == 'ACCEPTED'" class="badge badge-success rounded-pill p-2" i18n>Accepted</span>
          <span *ngIf="value == 'DECLINED'" class="badge badge-danger rounded-pill p-2" i18n>Declined</span>
          <span *ngIf="value == 'CANCELLED'" class="badge badge-dark rounded-pill p-2" i18n>Cancelled</span>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column *ngIf="columns.actions" name="" [flexGrow]="2" [resizeable]="false" [sortable]="false" headerClass="justify-content-end" cellClass="justify-content-end">
        <ng-template let-ownerType="row.ownerType" let-confirmationStatus="row.confirmationStatus" let-series="row.series" let-row="row" ngx-datatable-cell-template>
          <div class="d-flex flex-row justify-content-center align-middle">
            <div style="display: inline-block; position: relative;" class="align-self-center" *ngIf="isOwner(series)" (click)="showConsents(series)" tooltip="Shared data" i18n-tooltip>
              <share-person-item-list [persons]="getConsentPersons(series)"></share-person-item-list>
            </div>

            <div class="btn-group btn-more align-self-center" dropdown>
              <button type="button" class="btn btn-icon dropdown-toggle" dropdownToggle><i class="far fa-ellipsis-v"></i></button>
              <div class="dropdown-menu dropdown-menu-right" *dropdownMenu>
                <a class="dropdown-item" *ngIf="isOwner(series)" (click)="showConsents(series)"><i class="fal fa-share"></i> <ng-container i18n>Share data</ng-container></a>
                <a class="dropdown-item" *ngIf="isOwner(series)" (click)="setRowDataOwner(series)"><ng-container i18n>Set new data owner</ng-container></a>
                <a class="dropdown-item" *ngIf="isOwner(series) && series.lastPeriod.confirmationStatus == 'PENDING'" (click)="cancelOwnership(series)"><ng-container i18n>Cancel</ng-container></a>
                <a class="dropdown-item" *ngIf="confirmationStatus != 'PENDING'" (click)="editSeries(series)"><i class="fal fa-edit"></i>&nbsp;<ng-container i18n>Edit</ng-container></a>
                <a *ngIf="confirmationStatus == 'PENDING'" class="dropdown-item" (click)="declineOwnership(series)"><i class="fal fa-times-circle"></i>&nbsp;<ng-container i18n>Decline</ng-container></a>
                <a *ngIf="confirmationStatus == 'PENDING'" class="dropdown-item" (click)="acceptOwnership(series)"><i class="fal fa-check-circle"></i>&nbsp;<ng-container i18n>Accept</ng-container></a>
                <a *ngIf="confirmationStatus == 'ACCEPTED'" class="dropdown-item " (click)="declineOwnership(series)"><i class="fal fa-trash"></i>&nbsp;<ng-container i18n>Cancel data</ng-container></a>
                <a *ngIf="confirmationStatus == 'ACCEPTED'" class="dropdown-item " (click)="declineOwnershipAndArchive(series)"><i class="fal fa-trash"></i>&nbsp;<ng-container i18n>Cancel and archive all data</ng-container></a>
                <a *ngIf="confirmationStatus == 'CANCELLED'" class="dropdown-item" (click)="archiveSeries(series)"><i class="fal fa-trash"></i>&nbsp;<ng-container i18n>Acknowledge</ng-container></a>
                <a *ngIf="confirmationStatus == 'DECLINED'" class="dropdown-item" (click)="archiveSeries(series)"><i class="fal fa-trash"></i>&nbsp;<ng-container i18n>Archive</ng-container></a>
              </div>
            </div>
          </div>

        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column
        *ngIf="columns.detailToggler"
        [width]="50"
        [resizeable]="false"
        [sortable]="false"
        [draggable]="false"
        [canAutoResize]="false"
        headerClass="justify-content-end"
        cellClass="justify-content-end"
      >
        <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
          <a
            [routerLink]="null"
            [class.datatable-icon-right]="!expanded"
            [class.datatable-icon-down]="expanded"
            title="Expand/Collapse Row"
            (click)="toggleExpandRow(row)"
          >
          </a>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>
</div>
