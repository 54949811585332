import { MeteorologyService } from "../service/meteorology.service";
import { AbstractMapLayerMarker, OlMapWrapper } from "@smartencity/core";
import { RoadWeatherOlMarkerWrapper } from "./road-weather-ol-marker-wrapper";
import {RoadWeatherSite} from '../model/road-weather-site';

export class RoadWeatherOlMarker extends AbstractMapLayerMarker<OlMapWrapper, any> {
  private selected = false;
  private marker: any = null;

  constructor(
    roadWeather: RoadWeatherSite,
    map: OlMapWrapper,
    meteorologyService: MeteorologyService
  ) {
    super(roadWeather);
    this.marker = new RoadWeatherOlMarkerWrapper(roadWeather, map, meteorologyService);
  }

  setMap(map: OlMapWrapper) {
    super.setMap(map);
    const olMap = map ? map.getMap() : null;
    this.marker.setMap(olMap);
  }

  setSelected(selected: boolean): void {
    this.marker.selected = selected;
  }

  draw() {
    this.marker.draw();
  }

  isSelected(): boolean {
    return this.selected;
  }
}
