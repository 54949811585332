import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {CoreModule} from '@smartencity/core';
import {WeatherComponent} from './weather.component';

@NgModule({
  declarations: [WeatherComponent],
  imports: [
    CommonModule,
    FormsModule,
    CoreModule
  ],
  exports: [WeatherComponent]
})
export class WeatherModule { }
