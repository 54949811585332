<div class="modal-header">
  <h5 class="modal-title w-100" i18n>Restore My Account Data</h5>
</div>
<div class="modal-body" [formGroup]="restoreForm">
  <p class="text-info"><strong i18n>This will restore previously deleted account data for ({{authService.profileData.mandate.person | regNo}}).</strong></p>

  <div class="md-form">
    <input type="text" class="form-control" formControlName="restoreToken" [id]="id + '-modal-restore-token'">
    <label i18n [attr.for]="id + '-modal-restore-token'" i18n>Restore token</label>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-light btn-sm" (click)="close()" i18n>Cancel</button>
  <button type="button" class="btn btn-primary btn-sm" (click)="confirmAccountRestore()" i18n>Restore</button>
</div>
