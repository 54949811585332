import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {Injectable} from '@angular/core';
import {ModalOptions} from 'ngx-bootstrap/modal/modal-options.class';
import {PreventCloseAware} from '../models/prevent-close-aware';
import {CoreModule} from '../core.module';
import {ConfirmModalComponent} from '../components/confirm-modal/confirm-modal.component';

type Clazz<T extends PreventCloseAware> = {
  new (...args: any[]): T
}

@Injectable({
  providedIn: CoreModule
})
export class PreventCloseModalService {

  constructor(private modalService: BsModalService) {
  }

  public show<T extends PreventCloseAware>(classRef: Clazz<T>, config?: ModalOptions<T>): BsModalRef<T> {
    let closeConfirm: () => void;
    let closeReject: () => void;
    let isPreventClose: () => boolean;

    const closeInterceptor = () => {
      const promise = new Promise<void>((resolve, reject) => {
        closeConfirm = resolve;
        closeReject = reject;
      });

      if (isPreventClose()) {
        const modalRef: BsModalRef = this.modalService.show(ConfirmModalComponent, {
          ignoreBackdropClick: true,
          initialState: {
            description: $localize`This form has unsaved changes. Do you wish to proceed?`,
            callback: (confirm: boolean) => {
              if (confirm) {
                closeConfirm();
              } else {
                closeReject();
              }
              modalRef.hide();
            }
          }
        });
      } else {
        closeConfirm();
      }

      return promise;
    }

    const modal = this.modalService.show(classRef, {
      ...config,
      closeInterceptor: closeInterceptor
    });

    isPreventClose = () => {
      return modal.content.isPreventClose();
    };

    return modal;
  }
}
