import { Component, Inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import {AuthService, ConfirmModalComponent, PendingSeriesMeta, PersonSeriesApiService, UserService} from '@smartencity/core';
import { MyDataConfig } from '../../../mydata-config.model';
import { NotificationService } from '../notification.service';
import { ToastrService } from 'ngx-toastr';
import { SMARTENCITY_MYDATA_CONFIG } from '../../../injection-tokens';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'mydata-person-series-meta-row',
  templateUrl: './person-series-meta-row.component.html',
  encapsulation: ViewEncapsulation.None
})
export class PersonSeriesMetaRowComponent implements OnInit, OnDestroy, OnChanges {
  private ngDestroy = new Subject<void>();
  public progress: string = null;

  @Input()
  public parentProgress: string = null;

  @Input()
  public item: PendingSeriesMeta;

  public assignerName;
  public datapointName;
  public comment;
  public updatedName;
  public updatedGroupingTag;
  public updatedAddress;
  public updatedApartment;
  public updatedRoom;
  public updatedLat;
  public updatedLng;
  public updatedUuid;

  constructor(
    @Inject(SMARTENCITY_MYDATA_CONFIG) private config: MyDataConfig,
    public authService: AuthService,
    public personSeriesService: PersonSeriesApiService,
    public userService: UserService,
    public notificationService: NotificationService,
    private toastr: ToastrService,
    private modalService: BsModalService
  ) {

  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.item) {
      this.assignerName = this.item.assignerPerson.displayName;
      this.datapointName = this.item.personSeries.name;
      this.comment = this.item.assignerComment;
      this.updatedName = this.item.personSeriesMeta.name;
      this.updatedGroupingTag = this.item.personSeriesMeta.groupingTag;
      this.updatedAddress = this.item.personSeriesMeta.address;
      this.updatedApartment = this.item.personSeriesMeta.apartment;
      this.updatedRoom = this.item.personSeriesMeta.room;
      this.updatedLat = this.item.personSeriesMeta.lat;
      this.updatedLng = this.item.personSeriesMeta.lng;
      this.updatedUuid = this.item.personSeriesMeta.uuid;
    }
  }

  acceptPersonSeriesMeta(item: PendingSeriesMeta) {
    this.modalService.show(ConfirmModalComponent, {
      initialState: {
        description: $localize`Are you sure you want to accept person series meta?`,
        callback: (result: boolean) => {
          if (result) {
            this.progress = 'accept';
            this.submitPersonSeriesMeta(item, 'ACCEPTED');
          }
        }
      }
    });
  }

  declinePersonSeriesMeta(item: PendingSeriesMeta) {
    this.modalService.show(ConfirmModalComponent, {
      initialState: {
        description: $localize`Are you sure you want to decline person series meta?`,
        callback: (result: boolean) => {
          if (result) {
            this.progress = 'decline';
            this.submitPersonSeriesMeta(item, 'DECLINED');
          }
        }
      }
    });
  }

  submitPersonSeriesMeta(item: PendingSeriesMeta, status: string) {
    this.personSeriesService.updateMeta(item.personSeriesMeta, status).subscribe(
      (data: any) => {
        this.stateChanged();
      },
      (error) => {
        this.progress = null;
        console.error(error);
        this.toastr.error($localize`Error submitting confirmation`);
      }, () => this.progress = null
    );
  }

  private stateChanged() {
    this.notificationService.loadPendingPendingSeriesMeta();
    this.userService.confirmationListChanged();
  }
}
