import {Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';

import {HttpClient} from '@angular/common/http';
import {SMARTENCITY_MYDATA_CONFIG} from '../../../injection-tokens';
import {MyDataConfig} from '../../../mydata-config.model';
import {Subject} from 'rxjs/internal/Subject';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {GroupsEditService, GroupSourcePersonSeriesItem} from '../groups-edit.service';
import {EnumsService, GroupSource, PageResponse, PersonSeries, PersonSeriesApiService, SeriesType} from '@smartencity/core';
import {ToastrService} from 'ngx-toastr';
import {FormControl, FormGroup, ValidationErrors} from '@angular/forms';
import {Observable} from 'rxjs/internal/Observable';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  shareReplay,
  startWith,
  switchMap,
  takeUntil,
  withLatestFrom
} from 'rxjs/operators';
import {merge} from 'rxjs/internal/observable/merge';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';
import {SourceUpdateOptions} from '../../../models/source-update-options';

type PageResponseType = PageResponse<PersonSeries>;

const selector = 'mydata-groups-create-modal';
let nextId = 0;

@Component({
  selector: selector,
  templateUrl: './groups-edit-modal.component.html',
  providers: [GroupsEditService],
})
export class GroupsEditModalComponent implements OnInit, OnDestroy, OnChanges {
  id = `${selector}-${nextId++}`;
  private ngDestroy = new Subject<void>();

  public progress = false;

  @Input()
  groupSource: GroupSource;

  @Output('saved')
  savedEmitter: EventEmitter<any> = new EventEmitter<any>();

  public showErrors = false;

  form: FormGroup = this.groupsEditService.form;
  itemsList$: Observable<GroupSourcePersonSeriesItem[]> = this.groupsEditService.itemsList$;
  targetUnit$ = this.groupsEditService.targetUnit$;

  public groupingTags: Observable<string[]> = this.http.get<string[]>(this.config.apiUrl + '/grouping-tag').pipe(takeUntil(this.ngDestroy));
  public seriesTypes = this.enumsService.getOptionsList(SeriesType.types);
  public samplingTypeOptions = [
    {value: 'MINUTELY_15', label: $localize`15 minutes`},
    {value: 'MINUTELY_30', label: $localize`30 minutes`},
    {value: 'HOURLY', label: $localize`Hourly`},
    {value: 'DAILY', label: $localize`Daily`},
    {value: 'MONTHLY', label: $localize`Monthly`},
    {value: 'YEARLY', label: $localize`Yearly`}
  ];

  public windowOperationTypeOptions = SourceUpdateOptions.groupSourceWindowOperationTypeOptions;

  searchControl = new FormControl();
  pageControl = new FormControl(1);
  pageResponse: PageResponseType;

  public defaultLimit = 10;
  public defaultSorts = [{prop: 'name', dir: 'asc'}];
  public filter$ = this.searchControl.valueChanges.pipe(
    map(value => ({query: value})),
    startWith({}));
  public page$ = this.pageControl.valueChanges.pipe(map(page => page - 1));
  public sorts$: Subject<any> = new Subject<any>();
  public limit$: Subject<any> = new Subject<any>();

  public filterSortsLimit$ = combineLatest([
    this.filter$,
    this.sorts$.pipe(startWith(this.defaultSorts)),
    this.limit$.pipe(startWith(this.defaultLimit))
  ]).pipe(shareReplay(1));

  public queryParams$ = merge(
    this.filterSortsLimit$.pipe(map(([filter, sorts, limit]: [any, any[], number]) => [filter, sorts, limit, {offset: 0, limit: limit}])),
    this.page$.pipe(
      withLatestFrom(this.filterSortsLimit$),
      map(([page, [filter, sorts, limit]]: [any, [any, any[], number]]) => [filter, sorts, limit, {offset: page, limit: limit}])
    )
  ).pipe(
    distinctUntilChanged(),
    debounceTime(250),
    map(([filter, sorts, limit, page]) => this.mapQueryParams(filter, sorts, limit, page)),
    takeUntil(this.ngDestroy),
    shareReplay(1)
  );

  public reload$ = new Subject();
  public pageResponse$ = combineLatest([this.queryParams$, this.reload$.pipe(startWith(null as void))]).pipe(
    switchMap(([queryParams, ]: [any, any]) => this.fetchPage(queryParams)),
    shareReplay(1)
  );

  rows = [];
  rows$ = this.groupsEditService.itemsList$;

  constructor(
    private http: HttpClient,
    @Inject(SMARTENCITY_MYDATA_CONFIG) private config: MyDataConfig,
    private toastr: ToastrService,
    private modalRef: BsModalRef,
    private groupsEditService: GroupsEditService,
    private enumsService: EnumsService,
    private personSeriesService: PersonSeriesApiService
  ) {
    this.pageResponse$.subscribe((pageResponse) => {
      this.pageResponse = pageResponse;
    });
    this.rows$.subscribe((rows) => {
      this.rows = rows;
    });
  }

  ngOnInit(): void {
    this.groupsEditService.setGroupSource(this.groupSource);

    this.windowOperationTypeFormControl.valueChanges.pipe(takeUntil(this.ngDestroy)).subscribe((value) => {
      this.groupsEditService.updateItemsWindowOperationType(value);
    });
  }

  ngOnDestroy(): void {
    this.sorts$.complete();
    this.limit$.complete();
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['groupSource']) {
      this.groupsEditService.setGroupSource(this.groupSource);
    }
  }

  control(name?: string) {
    if (!name) {
      return this.form;
    }
    return this.form.get(name);
  }

  invalid(name: string, formGroup?) {
    if (!formGroup) {
      formGroup = this.form;
    }
    const control = formGroup.get(name);
    return control && control.invalid && (control.dirty || control.touched);
  }

  errors(name: string): ValidationErrors {
    const control = this.form.get(name);
    return control ? control.errors : null;
  }

  clearSearch() {
    this.searchControl.setValue('');
  }

  isSelected(ps: PersonSeries) {
    return this.groupsEditService.containsItem(ps.id);
  }

  addAll() {
    this.groupsEditService.addItems(this.pageResponse.content);
  }

  removeAll() {
    this.groupsEditService.removeAllItems();
  }

  windowOperationTypeFormControl: FormControl = new FormControl(null);

  addItem(ps: PersonSeries): void {
    this.groupsEditService.addItems([ps]);
  }

  removeItem(item: GroupSourcePersonSeriesItem): void {
    this.groupsEditService.removeItem(item.personSeries.id);
  }

  public close(): void {
    this.modalRef.hide();
  }

  public async save() {
    try {
      this.progress = true;
      await this.groupsEditService.save();
      this.savedEmitter.emit();
      this.close();
      this.toastr.success($localize`Datapoint group saved`);
    } catch (e) {
      console.error(e);
      if (e.name === 'DATAPOINT_LIST_EMPTY') {
        this.toastr.error($localize`At least 1 datapoint must be selected`)
      } else {
        this.toastr.error($localize`Error saving datapoint group`);
      }

    } finally {
      this.progress = false;
    }
  }

  mapQueryParams(filter: any, sorts: any[], limit: number, page: any) {
    const queryParams: any = {
      sourceTypes: 'TENANT_MEASUREMENT,API_DATA,PERSON_DATA,GROUP',
      returnLatestValue: 'true'
    };
    if (filter) {
      if (filter.query) {
        queryParams.query = filter.query;
      }
    }
    if (page) {
      queryParams.page = page.offset;
      queryParams.size = limit;
    }
    if (limit) {
      queryParams.size = limit;
    }

    if (this.groupSource?.personSeriesId) {
      queryParams.excludeIds = [this.groupSource.personSeriesId];
    }

    if (sorts && sorts.length > 0) {
      queryParams.sortFields = sorts.reduce((accumulator, currentValue) => {
        if (currentValue.dir === 'desc') {
          return accumulator + encodeURIComponent('-' + currentValue.prop);
        } else {
          return accumulator + '+' + currentValue.prop;
        }
      }, '');
    }

    return queryParams;
  }

  fetchPage(queryParams: any): Observable<PageResponseType>{
    return this.personSeriesService.getPersonSeriesPage(queryParams);
  }

}
