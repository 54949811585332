import {Inject, Injectable, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs/internal/Subject';
import {FormControl, FormGroup} from '@angular/forms';
import {MyDataConfig} from '../mydata-config.model';
import {SMARTENCITY_MYDATA_CONFIG} from '../injection-tokens';

@Injectable()
export class LocationListService implements OnDestroy {
  private ngDestroy: Subject<void> = new Subject();

  public filterForm = new FormGroup({
    query: new FormControl(''),
    seriesTypes: new FormControl([])
  });

  constructor(
    @Inject(SMARTENCITY_MYDATA_CONFIG) private config: MyDataConfig
  ) {

  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }
}
