import {GasMapService} from '../gas-map.service';

export class LocationMarker extends google.maps.OverlayView {
  building: any;
  series: any;
  seriesType: any;
  latlng: any;
  div: HTMLElement;
  selected = false;
  private gasMapService: GasMapService;

  constructor(building, latlng,  map, series, seriesType, gasMapService: GasMapService) {
    super();

    this.building = building;
    this.series = series;
    this.latlng = latlng;
    this.seriesType = seriesType;
    this.gasMapService = gasMapService;
    this.setMap(map);
  }

  onAdd() {

    this.div = document.createElement('DIV');
    this.div.title = this.building.address;
    this.div.style.cursor = 'pointer';
    this.div.classList.add('pointer-solarpark');

    this.div.addEventListener('click', () => {
      // this.div.classList.add('active');
      // this.selected = true;
      // this.draw();
      // google.maps.event.trigger(this.getMap(), 'click', this.building);
      //

      // this.getMap().setZoom(15);
      // this.getMap().panTo(this.center);
      this.gasMapService.solarParkSelectedSource.next(this.building);
    });

    const panes = this.getPanes();
    panes.overlayMouseTarget.appendChild(this.div);
  }

  onRemove() {
    this.div.parentNode.removeChild(this.div);
  }

  draw() {
    let value = '-';
    let valueClass = '';
    if (this.series != null) {

      value = (Math.round(this.series.value * 100) / 100)  + ' ' + this.gasMapService.transformUnit(this.series.unit, null);

      if (this.seriesType === 'TEMPERATURE') {
        valueClass = 'value-heat';
      } else if (this.seriesType === 'HEAT') {
        valueClass = 'value-heat';
      } else if (this.seriesType === 'WATER') {
        valueClass = 'value-cold-water';
      } else if (this.seriesType === 'ENERGY_CONSUMPTION') {
        valueClass = 'value-electricity';
      } else if (this.seriesType === 'ENERGY_PRODUCTION') {
        valueClass = 'value-solar-panel';
      }

      this.div.innerHTML = '<span class="value ' + valueClass + '" style="top:-40px;" title="' + this.building.address + '">' + value + '</span>';
    }

    const point = this.getProjection().fromLatLngToDivPixel(this.latlng);
    if (point) {

      let x;
      let y;

      if (this.selected) {
        this.div.classList.add("active");
        // 51x70
        x = point.x - 29.5;
        y = point.y - 53;
      } else {
        this.div.classList.remove("active");
        //43x58
        x = point.x - 22;
        y = point.y - 45;
      }

      this.div.style.left = x + 'px';
      this.div.style.top = y + 'px';
      //
      // this.div.style.left = (point.x - 22) + 'px';
      // this.div.style.top = (point.y - 45) + 'px';
    }
  }

}
