import {Inject, Injectable, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs/internal/Subject';
import {FormControl, FormGroup} from '@angular/forms';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  shareReplay,
  startWith,
  switchMap,
  takeUntil,
  withLatestFrom
} from 'rxjs/operators';
import {merge} from 'rxjs/internal/observable/merge';
import {PageResponse, SourceOwnerSeries, Mandate} from '@smartencity/core';
import {throwError} from 'rxjs/internal/observable/throwError';
import {MyDataConfig} from '../mydata-config.model';
import {CurrentLocationService} from './current-location.service';
import {HttpClient} from '@angular/common/http';
import {SMARTENCITY_MYDATA_CONFIG} from '../injection-tokens';
import {of} from 'rxjs/internal/observable/of';
import {AuthService} from '../../../../core/src/lib/auth/auth.service';
import {SharedKeywordStore} from './shared-search-keyword-store.service';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class DatapointListService implements OnDestroy {
  private ngDestroy: Subject<void> = new Subject();

  public isCityUser$ = this.authService.currentMandate$.pipe(
    takeUntil(this.ngDestroy),
    map((mandate: Mandate) => {
      return this.config.cityRegistrationCode === mandate.person.registrationNumber;
    }),
    shareReplay(1)
  );

  public defaultLimit = 10;
  public defaultSorts = [{prop: 'name', dir: 'asc'}];

  public rangeControl: FormControl = new FormControl();

  public range$ = this.rangeControl.valueChanges.pipe(takeUntil(this.ngDestroy), distinctUntilChanged(), shareReplay(1));

  public globalApplicationGroupingType$: Subject<string> = new BehaviorSubject(null);

  public filterForm = new FormGroup({
    query: new FormControl(''),
    seriesTypes: new FormControl([]),
    showOnCityPortalTrue: new FormControl(false),
    showOnCityPortalFalse: new FormControl(false)
  });

  public filter$ = combineLatest([
    this.currentLocationService.currentLocation$,
    this.filterForm.valueChanges.pipe(distinctUntilChanged(), debounceTime(250))
  ]).pipe(map(([location, filter]: [Location, any]) => {
    return Object.assign({location: location}, filter);
  }));

  public page = new Subject<number>();
  public page$ = this.page.asObservable().pipe(startWith(0));
  public sorts: Subject<any> = new Subject<any>();
  public limit: Subject<any> = new Subject<any>();

  public filterSortsLimit$ = combineLatest([
    this.filter$,
    this.sorts.pipe(startWith(this.defaultSorts)),
    this.limit.pipe(startWith(this.defaultLimit))
  ]).pipe(shareReplay(1));

  public queryParams$ = merge(
    this.filterSortsLimit$.pipe(map(([filter, sorts, limit]: [any, any[], number]) => [filter, sorts, limit, {page: 0, limit: limit}])),
    this.page$.pipe(
      withLatestFrom(this.filterSortsLimit$),
      map(([page, [filter, sorts, limit]]: [any, [any, any[], number]]) => [filter, sorts, limit, page])
    )
  ).pipe(
    map(([filter, sorts, limit, page]) => this.mapQueryParams(filter, sorts, limit, page)),
    shareReplay(1)
  );

  public reload = new Subject<void>();
  public pageResponse$ = combineLatest([this.queryParams$, this.reload.pipe(startWith(null as void))]).pipe(
    switchMap(([queryParams, ]: [any, any]) => this.fetchPage(queryParams)),
    shareReplay(1),
    takeUntil(this.ngDestroy)
  );
  public pageResponseWithCityData$ = combineLatest([this.pageResponse$, this.isCityUser$]).pipe(
    switchMap(([data, isCityUser]: [PageResponse<SourceOwnerSeries>, boolean]) => {
      if (!isCityUser) {
        return of([data, []]);
      }
      return this.http.get(this.config.cityApiUrl + '/person-series/', {params: {size: '' + data.size, ids: data.content.map(e => '' + e.personSeries?.id)}}).pipe(
        map((cityResponse: any) => cityResponse.content),
        catchError(() => of([])),
        map(cityData => ([data, cityData]))
      );
    }),
    shareReplay(1)
  );
  public rows$ = this.pageResponseWithCityData$.pipe(
    map(([data, cityData]: [PageResponse<SourceOwnerSeries>, any[]]) => this.mapPageResultsToRows(data, cityData)),
    shareReplay(1)
  );

  constructor(
    @Inject(SMARTENCITY_MYDATA_CONFIG) private config: MyDataConfig,
    private http: HttpClient,
    private currentLocationService: CurrentLocationService,
    private authService: AuthService,
    private sharedKeywordStore: SharedKeywordStore
  ) {
    this.range$.subscribe();

    this.filterForm.valueChanges.pipe(takeUntil(this.ngDestroy)).subscribe(filter => {
      if (filter.query !== this.sharedKeywordStore.state) {
        this.sharedKeywordStore.setState('');
      }
    });
  }

  ngOnDestroy(): void {
    this.page.complete();
    this.sorts.complete();
    this.limit.complete();
    this.reload.complete();
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  resetResults(): void {
    this.filterForm.patchValue({
      query: this.sharedKeywordStore.state
    });
  }

  load(): void {
    this.reload.next();
  }

  public fetchPage(queryParams: any) {
    const params = Object.assign({}, queryParams);
    for (const i of Object.keys(params)) {
      if (params[i] == null) {
        delete params[i];
      }
    }

    return this.http.get<PageResponse<SourceOwnerSeries>>(this.config.apiUrl + '/data-owner-series', {
      params: params
    }).pipe(catchError((err) => {
      return throwError(err);
    }));
  }

  mapQueryParams(filter: any, sorts: any[], limit: number, page: any) {
    const queryParams: any = {
      returnThresholds: true,
      returnConsents: true,
      groupId: filter.location && filter.location.group ? filter.location.group.id : null,
      groupingTag: filter.location?.groupingTag ? filter.location.groupingTag : null,
     // locationType: filter.location ? filter.location.locationType : null, // not used anymore
      address: filter.location ? filter.location.address : null,
      apartment: filter.location ? filter.location.apartment : null,
      room: filter.location ? filter.location.room : null,
      lat: filter.location ? filter.location.lat : null,
      lng: filter.location ? filter.location.lng : null,
      uuid: filter.location ? filter.location.uuid : null,
      q11eType: filter.location.q11eType ? filter.location.q11eType : null,
      q11eId: filter.location.q11eId ? filter.location.q11eId : null,
      withoutSeriesGroup: !filter.location?.group?.id
    };

    if (filter) {
      if (filter.seriesTypes && filter.seriesTypes.length > 0) {
        queryParams.seriesTypes = filter.seriesTypes.join(',');
      }
      if (filter.confirmationStatuses) {
        queryParams.confirmationStatuses = filter.confirmationStatuses.join(',');
      }
      if (filter.showOnCityPortalTrue && !filter.showOnCityPortalFalse) {
        queryParams.showOnCityPortal = true;
      }
      if (!filter.showOnCityPortalTrue && filter.showOnCityPortalFalse) {
        queryParams.showOnCityPortal = false;
      }
      if (filter.query) {
        queryParams.query = filter.query;
      }
    }
    if (page) {
      queryParams.page = page.page;
      queryParams.size = page.limit;
    }
    if (limit) {
      queryParams.size = limit;
    }
    if (sorts && sorts.length > 0) {
      queryParams.sortFields = sorts.reduce((accumulator, currentValue) => {
        if (currentValue.dir === 'desc') {
          return accumulator + encodeURIComponent('-' + currentValue.prop);
        } else {
          return accumulator + '+' + currentValue.prop;
        }
      }, '');
    }

    return queryParams;
  }

  mapPageResultsToRows(data: PageResponse<SourceOwnerSeries>, cityData: any[]) {
    const cityDataByPersonSeriesId = new Map(cityData.map(e => [e.personSeriesId, e]));
    return data.content.map(e => {
      const cityRow = cityDataByPersonSeriesId.get(e.personSeries.id);
      return {...e, showOnMap: cityRow ? cityRow.showOnCityPortal : false};
    });
  }

  setPage(page) {
    this.page.next(page);
  }

  setFormData(data?: any) {
    this.filterForm.patchValue(data);
    this.triggerFormUpdate();
  }

  triggerFormUpdate(): void {
    this.filterForm.updateValueAndValidity();
  }

}
