<form autocomplete="off" [formGroup]="mandateForm" (ngSubmit)="saveForm()">
  <div class="modal-header d-flex align-items-center">
    <div style="display: flex; align-items: stretch; flex-direction: column;">
      <h5 class="modal-title w-100">{{mandate.user.displayName}}</h5>
      <small *ngIf="mandate.createdByUser" i18n>Mandated by {{mandate.createdByUser.displayName}}, {{mandate.startAt|date:'dd.MM.yyyy HH:mm'}}</small>
    </div>
    <button type="button" class="close pull-right" i18n-aria-label aria-label="Close" (click)="close()">
      <i class="fal fa-times"></i>
    </button>
  </div>
  <div class="modal-body">
    <div class="row" *ngIf="mandateData.children.length > 0">
      <div class="col">
        <h5>Edasivolitused</h5>
        <div style="display: table">
          <div *ngFor="let child of mandateData.children" style="display: table-row">
            <div style="display: table-cell; padding: 5px 5px 5px 0;">
              {{child.data.mandate.startAt|date:'dd.MM.yyyy HH:mm'}}
            </div>
            <div style="display: table-cell; padding: 5px 5px 5px 0;">
              {{child.data.mandate.user.displayName}}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row" *ngIf="isMandatePermissionsEditAllowed()">
      <div class="col">
        <div class="custom-control custom-switch mt-2" *ngFor="let permission of permissions.controls; let i=index">
          <input type="checkbox" class="custom-control-input" [id]="id + '-check-' + i" [formControl]="permission" [value]="permissionsList[i].value">
          <label class="custom-control-label mr-4" [attr.for]="id + '-check-' + i">{{permissionsList[i].label}}</label>
        </div>

        <div class="custom-control custom-switch mt-2" *ngIf="mandateData.children.length > 0">
          <input type="checkbox" class="custom-control-input" [class.is-invalid]="invalid('replaceMandate')" [id]="id + '-replace-mandate'" formControlName="replaceMandate">
          <label class="custom-control-label" [attr.for]="id + '-replace-mandate'" i18n>Replace mandate</label>
        </div>
      </div>
    </div>

    <div class="form-group" *ngIf="mandateForm.get('replaceMandate').value">
      <div class="form-row">
        <div class="col">
          <label i18n [attr.for]="id + '-user'">Person</label>
          <ng-select
            [id]="id + '-user'"
            [class.is-invalid]="invalid('user')"
            [clearable]="true"
            [searchable]="true"
            appendTo="body"
            placeholder="Personal identification code"
            i18n-placeholder
            addTagText="New person with registration number"
            i18n-addTagText
            [typeahead]="userTypeahead"
            [items]="users"
            [addTag]="createUser"
            [selectOnTab]="true"
            formControlName="user"
            id="personal-identification"
            class="custom"
            autofocus
          >
            <ng-template ng-label-tmp let-item="item">
              <span>{{(item.displayName ? '' + item.displayName + ' ' : '') + '(' + (item | personalId) + ')'}}</span>
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
              <span>{{(item.displayName ? '' + item.displayName + ' ' : '') + '(' + (item | personalId) + ')'}}</span>
            </ng-template>
          </ng-select>
          <small *ngIf="invalid('user') && errors('user')?.required" class="invalid-feedback d-block" i18n>Required</small>
          <small *ngIf="invalid('user') && errors('user')?.invalidPersonCode" class="invalid-feedback d-block" i18n>Invalid person code</small>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button *ngIf="isCancelAllowed()" type="button" class="btn btn-secondary btn-sm" i18n (click)="cancelMandate()">Cancel delegation</button>
    <button type="submit" class="btn btn-primary btn-sm" i18n>Save</button>
  </div>
</form>

