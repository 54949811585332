import { Component, OnInit } from '@angular/core';
import {CookieConsentService} from '../../services/cookie/cookie-consent.service';

@Component({
  selector: 'cookie-consent',
  templateUrl: './cookie-consent.component.html',
  styleUrls: ['./cookie-consent.component.scss']
})
export class CookieConsentComponent implements OnInit {

  constructor(private cookieConsentService: CookieConsentService) { }

  public cookieConfirmationRequired = true;

  ngOnInit(): void {
    this.checkAnalyticsCookieConfirmation();
  }

  public allowCookies(): void {
    this.cookieConsentService.allowAnalytics();
    this.checkAnalyticsCookieConfirmation();
  }

  public declineCookies(): void {
    this.cookieConsentService.declineAnalytics();
    this.checkAnalyticsCookieConfirmation();
  }

  public checkAnalyticsCookieConfirmation(): void {
    this.cookieConfirmationRequired = this.cookieConsentService.isAnalyticsCookieConfirmationRequired();
  }

}
