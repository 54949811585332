const nav = (window.navigator as any);

export class CsvDataService {
  static exportToCsv(filename: string, rows: object[], options?: {includeHeader: boolean}) {
    const _options: any = Object.assign({
      includeHeader: true,
    }, options);
    if (!rows || !rows.length) {
      return;
    }
    const separator = ',';
    const keys = Object.keys(rows[0]);
    let csvContent = '\ufeff';
    if (_options.includeHeader) {
      csvContent += keys.join(separator) + '\n';
    }
    csvContent += rows.map(row => {
      return keys.map(k => {
        let cell = row[k] === null || row[k] === undefined ? '' : row[k];
        cell = cell instanceof Date
            ? cell.toLocaleString()
            : cell.toString().replace(/"/g, '""');
        if (cell.search(/("|,|\n)/g) >= 0) {
          cell = `"${cell}"`;
        }
        return cell;
      }).join(separator);
    }).join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    if (nav && nav.msSaveBlob) { // IE 10+
      nav.msSaveBlob(blob, filename + '.csv');
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename + '.csv');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }
}
