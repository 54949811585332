import {Injectable, OnDestroy} from '@angular/core';
import {AddressService} from './address.service';
import {AbstractControl, FormControl, FormGroup} from '@angular/forms';
import {HttpClient, JsonpClientBackend} from '@angular/common/http';
import {catchError, debounceTime, distinctUntilChanged, map, switchMap, takeUntil, tap} from 'rxjs/operators';
import {of, ReplaySubject, BehaviorSubject, Subject, Observable, combineLatest} from "rxjs";

@Injectable()
export class AdsAddressService implements AddressService, OnDestroy {
  private ngDestroy = new Subject<void>();
  private jsonpClient: HttpClient;
  public form: FormGroup;
  public searching$ = new BehaviorSubject<boolean>(false);
  public addressSelect$ = new Subject<any>();
  public adsAddress = new FormControl();
  public adsApartment = new FormControl(null);
  private apartments$ = new ReplaySubject(1);

  inputFormatter = (input: any) => {
    if ( input.ipikkaadress !== undefined ) {
      return input.ipikkaadress;
    } else {
      return input;
    }
  };
  resultFormatter = (result: any) => result.ipikkaadress;

  constructor(
    private httpBackend: JsonpClientBackend
  ) {
    this.jsonpClient = new HttpClient(httpBackend);
    this.addressTypeahead = this.addressTypeahead.bind(this);
    this.apartmentTypeahead = this.apartmentTypeahead.bind(this);

    this.adsAddress.markAsTouched();
    this.adsApartment.markAsTouched();
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  public initForm(form: FormGroup): void {
    this.form = form;

    this.adsAddress.valueChanges.pipe(takeUntil(this.ngDestroy)).subscribe((address) => {
      if (address && address.ads_oid) {
        this.form.get('address').setValue(address.ipikkaadress);
        this.form.get('adsOid').setValue(address.ads_oid);
        this.apartments$.next(address.appartments);
      } else {
        this.form.get('address').setValue(address);
        this.form.get('adsOid').setValue(null);
        this.apartments$.next([]);
      }
    });

    this.adsApartment.valueChanges.pipe(takeUntil(this.ngDestroy)).subscribe((apartment) => {
      this.form.get('apartment').setValue(apartment);
    });

    const formValue = form.getRawValue();
    if (formValue) {
      if (formValue.adsOid) {
        this.adsAddress.setValue({
          ads_oid: formValue.adsOid,
          ipikkaadress: formValue.address
        });
      } else {
        this.adsAddress.setValue(formValue.address);
      }
      this.adsApartment.setValue(formValue.apartment);
    }
    this.apartments$.next([]);
  }

  public getForm(): FormGroup {
    return this.form;
  }

  public getAddressControl(): AbstractControl {
    return this.adsAddress;
  }

  public getApartmentControl(): AbstractControl {
    return this.adsApartment;
  }

  public getRoomControl(): AbstractControl {
    return this.form.get('room');
  }

  public addressTypeahead(text$: Observable<string>) {
    return text$.pipe(
      debounceTime(350),
      distinctUntilChanged(),
      tap(() => this.searching$.next(true)),
      switchMap((text: string) => {
        if (text != null && text.trim().length > 0) {
          return this.jsonpClient.jsonp(
            'https://inaadress.maaamet.ee/inaadress/gazetteer?results=5&appartment=1&features=EHITISHOONE&address='
            + encodeURI(text), 'callback');
        }
        return of();
      }),
      catchError((e) => {
        return [];
      }),
      map((results: any) => {
        if (!results.addresses) {
          return [];
        }
        return results.addresses;
      }),
      tap(() => this.searching$.next(false))
    );
  }

  public apartmentTypeahead(text$: Observable<string>) {
    return combineLatest([text$.pipe(distinctUntilChanged()), this.apartments$]).pipe(
      tap(() => this.searching$.next(true)),
      map(([text, apartments]: [string, any[]]) => {
        if (!apartments || apartments.length < 1) {
          return [];
        }
        return apartments
          .map((apartment) => apartment.tahis)
          .filter(tahis => tahis.toLowerCase().indexOf(text.toLowerCase()) > -1)
          .slice(0, 10);
      }),
      tap(() => this.searching$.next(false))
    );
  }

  public selectAddress(addressItem: any) {
    this.addressSelect$.next(addressItem && addressItem.ipikkaadress ? addressItem.ipikkaadress : null);
  }

}
