<ngx-datatable
  #table
  class="material expandable"
  [draggable]="false"
  [columnMode]="'flex'"
  [headerHeight]="30"
  [footerHeight]="50"
  [reorderable]="false"
  rowHeight="auto"
  [treeFromRelation]="'parentRowId'"
  [treeToRelation]="'rowId'"
  [scrollbarH]="false"
  [scrollbarV]="false"
  [rows]="rows"
  [externalPaging]="false"
  [externalSorting]="false"
  [count]="page?.totalElements"
  [limit]="page?.size"
  [sorts]="defaultSorts"
  (sort)="setSorts($event)"
>
  <ngx-datatable-row-detail [rowHeight]="190" #detailRow>
    <ng-template
      let-row="row"
      let-name="row.name"
      let-updatedAt="row.updatedAt"
      ngx-datatable-row-detail-template
    >
      <div style="font-size:14px; padding: 0.7rem">
        <div class="row">
          <div class="col-6"><label i18n>Name</label></div>
          <div class="col-6">{{name}}</div>
        </div>

        <div class="row">
          <div class="col-6"><label i18n>Updated</label></div>
          <div class="col-6">{{updatedAt | date:'dd. MMMM yyyy'}}</div>
        </div>

        <div class="row pt-3">
          <button type="button" class="btn btn-sm btn-link no-caret"  (click)="copyQuestionnaire(row.questionnaire)" *ngIf="row.questionnaire">
            <i class="fal fa-copy mr-2"></i>
            <ng-container i18n>Copy</ng-container>
          </button>
          <button type="button" class="btn btn-sm btn-link no-caret" (click)="deleteQuestionnaire(row.questionnaire)" *ngIf="row.questionnaire">
            <i class="fal fa-trash mr-2"></i>
            <ng-container i18n>Delete</ng-container>
          </button>
        </div>
      </div>
    </ng-template>
  </ngx-datatable-row-detail>

  <ngx-datatable-column *ngIf="columns.name" name="Name" i18n-name prop="name" [flexGrow]="2" [resizeable]="false" [sortable]="true">
    <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
      <a [routerLink]="null" class="title" (click)="editQuestionnaire(row.questionnaire)">{{value}}</a>
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column *ngIf="columns.updatedAt" name="Updated" i18n-name prop="updatedAt" [flexGrow]="1" [resizeable]="false" [sortable]="true">
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>{{value | date:'dd. MMMM yyyy'}}</ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column *ngIf="columns.errors" name="Formula update errors" i18n-name prop="formulaUpdateErrorCount" [flexGrow]="1" [resizeable]="false" [sortable]="true">
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>

      <ng-container *ngIf="value > 0">
        <ng-template #tooltip>
          <span i18n>Number of errors: {{value}}</span>
        </ng-template>
        <button class="btn btn-icon text-warning mr-1" [tooltip]="tooltip" (click)="showFormulaUpdateErrors(row.questionnaire)">
          <i class="fa fa-info-circle"></i>
        </button>
      </ng-container>
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column *ngIf="columns.actions" [flexGrow]="2" [resizeable]="false" [sortable]="false" [cellClass]="'justify-content-end'">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <div class="btn-group btn-more" dropdown>
        <button type="button" class="btn btn-icon dropdown-toggle" dropdownToggle><i class="far fa-ellipsis-v"></i></button>
        <div class="dropdown-menu dropdown-menu-right" *dropdownMenu>
          <a class="dropdown-item" (click)="editQuestionnaire(row.questionnaire)" *ngIf="row.questionnaire"><i class="fal fa-edit"></i><ng-container i18n>Edit</ng-container></a>
          <a class="dropdown-item" (click)="copyQuestionnaire(row.questionnaire)" *ngIf="row.questionnaire"><i class="fal fa-copy"></i> <ng-container i18n>Copy</ng-container></a>
          <a class="dropdown-item" (click)="deleteQuestionnaire(row.questionnaire)" *ngIf="row.questionnaire"><i class="fal fa-trash"></i> <ng-container i18n>Delete</ng-container></a>
        </div>
      </div>
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column
    *ngIf="columns.detailToggler"
    [width]="50"
    [resizeable]="false"
    [sortable]="false"
    [draggable]="false"
    [canAutoResize]="false"
    headerClass="justify-content-end"
    cellClass="justify-content-end"
  >
    <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
      <a
        [routerLink]="null"
        [class.datatable-icon-right]="!expanded"
        [class.datatable-icon-down]="expanded"
        title="Expand/Collapse Row"
        (click)="toggleExpandRow(row)"
      >
      </a>
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>
