import { DataPoint } from "@smartencity/core";
import { PersonSeries } from "projects/core/src/lib/models/http/public/person-series";

export class EvChargersResponse {
  content: EvChargerResponse[] = [];
}

export class EvChargerResponse extends DataPoint<any> {
}

export class EvCharger {
  id: number;
  lat: number;
  lng: number;
  name: string;
  time: string;
  unit: string;
  value: number;
  series: {
    unit: string,
    value: number
  }[] = [];
}
