import { Component, OnDestroy, OnInit } from '@angular/core';
import { TallinnArcGisMapService } from 'projects/arc-gis/src/lib/tallinn-arc-gis-map.service';
import { MobilityOlMapService } from '../service/mobility-ol.service';
import { MobilityService } from '../service/mobility.service';
import { FeatureSelectEvent, OlMapWrapper } from '@smartencity/core';
import { combineLatest } from 'rxjs';
import { MobilityDisplaySettings } from '../mobility-config.model';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs/internal/Subject';
import { MobilityGmapService } from '../service/mobility-gmap.service';
import { MobilitySearchService } from '../service/mobility-search.service';
import { PeopleTrafficCounter } from '../model/people-traffic-counter';
import {PeopleTrafficService} from '../service/people-traffic.service';
import { LayerDisplaySettings } from 'projects/arc-gis/src/lib/layer-display-settings';

@Component({
  selector: 'ol-mobility',
  templateUrl: './ol-mobility.component.html',
  styles: [
    `.map {
      width: 100%;
      height: 100vh;
    }
    `
  ],
  providers: [
    MobilityService,
    MobilityGmapService,
    MobilitySearchService,
    MobilityOlMapService
  ]
})
export class OlMobilityComponent implements OnInit, OnDestroy {

  private ngDestroy = new Subject<void>();

  public displaySettings: MobilityDisplaySettings = <MobilityDisplaySettings>{};

  public arcGisDisplaySettings: LayerDisplaySettings = new LayerDisplaySettings();

  public objects: any[] = [];

  private olMapWrapper: OlMapWrapper;

  constructor(
    public mobilityService: MobilityService,
    private mobilityOlMapService: MobilityOlMapService,
    private tallinnArcGisMapService: TallinnArcGisMapService,
    private peopleTrafficService: PeopleTrafficService) {
  }

  ngOnInit(): void {
    this.resetLocations();
    this.displaySettings = this.mobilityService.getDisplaySettings();

    this.mobilityOlMapService.createMap().pipe(takeUntil(this.ngDestroy)).subscribe((olMapWrapper: OlMapWrapper) => {
      this.olMapWrapper = olMapWrapper;
      this.olMapWrapper.mapLoaded$.subscribe(() => {
        this.olMapWrapper.getMap().on('click', () => {
          this.resetLocations();
        });

        this.olMapWrapper.featureSelect$.subscribe((o: FeatureSelectEvent) => {
          let newLocations = [];
          for (const f of o.selected) {
            newLocations.push({
              layerName: f.layer.get('layerName'),
              data: this.mobilityOlMapService.map.getFeatureProperties(f.feature)
            });

            if (f.layer.get('layerName') == 'cityDistricts' || f.layer.get('layerName') == 'residentialAreas') {
              f.feature.setStyle(this.tallinnArcGisMapService.highlightStyle);
            }

            if (f.layer.get('layerName') == 'parkingZones') {
              f.feature.setStyle(this.mobilityOlMapService.highlightStyle);
            }
          }

          for (const f of o.deselected) {
            f.feature.setStyle(null);
          }

          const layerPosition = newLocations.findIndex((o) => o.layerName == 'cityDistricts');
          const lastLayer = newLocations.splice(layerPosition, 1);
          newLocations = newLocations.concat(lastLayer);

          this.mobilityService.locationsSelectedSource.next(newLocations);
        });
      });

      this.initEventListeners();

    });
  }

  private initEventListeners() {
    combineLatest([this.mobilityService.displaySettings$, this.olMapWrapper.mapLoaded$]).pipe(takeUntil(this.ngDestroy)).subscribe(([displaySettings, mapLoaded]: [MobilityDisplaySettings, boolean]) => {
      this.displaySettings = displaySettings;
      if (this.displaySettings.roadClosures) {
        this.mobilityService.loadRoadClosures();
      }

      if (this.displaySettings.bicycleParkingStation) {
        this.mobilityService.loadBicycleStations();
      }

      if (this.displaySettings.peopleTraffic) {
        this.peopleTrafficService.loadPeopleTraffic();
      }
    });

    this.mobilityService.roadClosures$.pipe(takeUntil(this.ngDestroy)).subscribe((roadClosures) => {
      this.mobilityOlMapService.showRoadClosures(roadClosures);
    });

    this.mobilityService.bicycleStations$.subscribe((bicycleStations: any[]) => {
      this.mobilityOlMapService.updateParkingStations(bicycleStations);
    });

    this.peopleTrafficService.peopleTraffic$.subscribe((counters: PeopleTrafficCounter[]) => {
      this.mobilityOlMapService.updatePeopleTraffic(counters);
    });
  }

  updateDisplayItems(items: any) {
    this.mobilityService.updateDisplaySettings(Object.assign(this.displaySettings, items));
  }

  updateArcGisDisplayItems(items: any): void {
    const prev = Object.create(this.arcGisDisplaySettings);
    const newDisplaySettings = Object.assign({}, this.arcGisDisplaySettings, items);

    if (prev.residentialAreas && !newDisplaySettings.residentialAreas) {
      this.mobilityService.deselectLocations('residentialAreas');
    }

    if (prev.cityDistricts && !newDisplaySettings.cityDistricts) {
      this.mobilityService.deselectLocations('cityDistricts');
    }

    if (prev.parkingLots && !newDisplaySettings.parkingLots) {
      this.mobilityService.deselectLocations('parkingLots');
    }

    if (prev.parkingZones && !newDisplaySettings.parkingZones) {
      this.mobilityService.deselectLocations('parkingZones');
    }

    this.arcGisDisplaySettings = newDisplaySettings;
    this.tallinnArcGisMapService.updateDisplaySettings(newDisplaySettings);
  }

  onModalClose(): void {
    this.resetLocations();
  }

  private clearFeaturesStyles(): void {
    if (this.mobilityOlMapService.map) {
      this.mobilityOlMapService.map.clearFeatureStyles();
    }
  }

  private resetLocations(): void {
    this.clearFeaturesStyles();
    this.mobilityService.deselectRoadClosure();
    this.mobilityService.locationsSelectedSource.next(null);
    this.peopleTrafficService.selectPeopleTrafficCounter(null);
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
    if (this.olMapWrapper) {
      this.olMapWrapper.onRemove();
    }
    this.mobilityService.clear();
    this.peopleTrafficService.clear();
  }
}
