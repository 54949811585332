<div class="modal-header">
  <h5 class="modal-title" i18n>Select tenant</h5>
  <button type="button" class="close" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row col-md-12">
    <div class="md-form w-100">
      <select [(ngModel)]="tenantId" class="form-control browser-default" [ngClass]="{ 'is-invalid': false }" >
        <option [ngValue]="null" i18n>-Select-</option>
        <option *ngFor="let tenant of tenants" [value]="tenant.id">{{tenant.url}}</option>
      </select>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (click)="cancel()" i18n>Cancel</button>
  <button type="button" class="btn btn-primary" (click)="continue()" i18n>Continue</button>
</div>
