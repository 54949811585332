<div #modalContent class="jumbotron floating-jumbotron content-wrapper mt-5" [class.active]="objects.length > 0">
  <div *ngIf="objects && objects.length > 0" class="modal-content-wapper">
    <div class="modal-header">
      <h5 class="modal-title w-100 d-flex align-items-center">
        <modal-nav [size]="objects.length" [(current)]="currentIndex"></modal-nav>
      </h5>
      <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
        <i class="fal fa-times"></i>
      </button>
    </div>

    <div class="modal-body">
      <div class="object-wrapper" *ngFor="let series of objects; let i = index" [class.active]="i == currentIndex">
        <div class="object-modal-wrapper" *ngIf="objects[i].type == 'cityServiceOffices'" arc-gis-feature-fields [data]="objects[i].data" layerTitle="City service office" i18n-layerTitle></div>
        <div class="object-modal-wrapper" *ngIf="objects[i].type == 'residentialAreas'" arc-gis-feature-fields [data]="objects[i].data" layerTitle="Residential area" i18n-layerTitle></div>
        <div class="object-modal-wrapper" *ngIf="objects[i].type == 'cityDistricts'" arc-gis-feature-fields [data]="objects[i].data" layerTitle="City disctrict" i18n-layerTitle></div>
      </div>
    </div>
  </div>
</div>
