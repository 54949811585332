import { ChangeDetectorRef, Component, Inject, NgZone, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import {UnitPipe, WidgetDataService} from '@smartencity/core';
import { Subject } from 'rxjs/internal/Subject';
import { ServicesService } from '../../../../services/src/lib/services.service';
import { SMARTENCITY_BUILDINGS_CONFIG } from '../injection-tokens';
import { BuildingsConfig, BuildingsDisplaySettings } from '../buildings-config.model';
import { EecBuildingGmapMarker } from '../layers/eec-building-gmap-marker';
import { BuildingsGmapService } from '../service/buildings-gmap.service';
import { EecBuilding } from '../model/eec-building';
import {BuildingsService} from '../service/buildings.service';
import { BuildingsSearchService } from '../service/buildings-search.service';
import {Building} from '../model/building';
import {takeUntil} from 'rxjs/operators';
import {CrowdInsightsDemoDataService} from '../service/crowd-insights-demo-data.service';
import {CrowdInsightsDataService} from '../service/crowd-insights-data.service';

@Component({
  selector: 'gmap-buildings',
  templateUrl: './gmap-buildings.component.html',
  encapsulation: ViewEncapsulation.None
})
export class GmapBuildingsComponent implements OnInit, OnDestroy {
  private ngDestroy = new Subject<void>();

  @ViewChild('gmap', { static: true })
  gmapElement: any;

  map: google.maps.Map;

  public buildings: Building[] = [];

  public building: Building;

  public eecbuildings: EecBuilding[] = [];

  public markers = [];

  public typesSelectMap: any = {};

  updateTimeout: any = null;

  buildingsInit = true;

  public displaySettings: BuildingsDisplaySettings = <BuildingsDisplaySettings>{};
  public displayEnergyTypeSettings: Record<'eecBuildings', Record<string, boolean>> = {
    'eecBuildings': {
      'A': true,
      'B': true,
      'C': true,
      'D': true,
      'E': true,
      'F': true,
      'G': true,
      'H': true
    }
  };

  constructor(
    @Inject(SMARTENCITY_BUILDINGS_CONFIG) private config: BuildingsConfig,
    public buildingsService: BuildingsService,
    public buildingsMapService: BuildingsGmapService,
    public servicesService: ServicesService,
    private cd: ChangeDetectorRef,
    private zone: NgZone,
    private unitPipe: UnitPipe,
    private buildingsSearchService: BuildingsSearchService
  ) {
  }

  ngOnInit() {
    this.displaySettings = this.buildingsService.getDisplaySettings();
    this.map = this.buildingsMapService.createMap(this.gmapElement);
    this.buildingsSearchService.setUpdateDisplay(this.displaySettings);

    google.maps.event.addListenerOnce(this.map, 'idle', () => {
      this.zone.run(() => {
        this.buildingsService.seriesTypeMap$.pipe(takeUntil(this.ngDestroy)).subscribe((seriesTypeMap: any) => {
          this.typesSelectMap = seriesTypeMap;
        });

        this.buildingsService.buildings$.pipe(takeUntil(this.ngDestroy)).subscribe((buildings: Building[]) => {
          this.buildings = buildings;
        });

        this.buildingsService.displaySettings$.subscribe((displaySettings: BuildingsDisplaySettings) => {
          this.displaySettings = Object.create(displaySettings);
        });

        this.loadBuildings();
        this.scheduleUpdate();

        if (this.displaySettings.windTurbines) {
          this.buildingsService.loadWindTurbines();
        }

        if (this.displaySettings.eecBuildings) {
          this.toggleAllEnergyClasses('eecBuildings', true);
          this.initEecBuildings();
        }

        if (this.displaySettings.crowdInsights) {
          this.buildingsService.loadCrowdInsightAreas();
        }

        this.cd.markForCheck();
      });
    });
  }

  ngOnDestroy(): void {
    this.buildingsService.clear();
    this.buildingsMapService.reset();
    clearTimeout(this.updateTimeout);
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  scheduleUpdate() {
    this.updateTimeout = setTimeout(() => {
      this.loadBuildings();
      this.scheduleUpdate();
    }, 60000);
  }

  private loadBuildings(): void {
    this.buildingsService.loadBuildings();
  }

  showSeries(seriesType: string): void {
    this.buildingsService.updateSeriesType(seriesType);
    this.buildingsService.selectBuilding(null);
  }

  displayItemsChanged(key: string, active: boolean): void {
    let items = { [key]: { active: active } };

    const newDisplaySettings = Object.assign(this.displaySettings, items);
    this.buildingsService.updateDisplaySettings(newDisplaySettings);
    this.buildingsSearchService.setUpdateDisplay(newDisplaySettings);
  }

  initEecBuildings() {

    this.buildingsService.getEecBuildings().subscribe({
      next: (data: any) => {
        this.eecbuildings = data;

        const eecBuildingsToShow = this.buildingsService.energyType.filter(data => data.energyClass in this.displayEnergyTypeSettings.eecBuildings && this.displayEnergyTypeSettings.eecBuildings[data.energyClass]);

        if (this.markers && this.markers.length > 0) {
          for (const marker of this.markers) {
            marker.setMap(null);
          }
        }
        this.markers = [];

        if (this.eecbuildings) {
          for (const energyType of eecBuildingsToShow) {
            for (const eecbuilding of this.eecbuildings) {
              const buildingMarker = new EecBuildingGmapMarker(
                eecbuilding,
                new google.maps.LatLng(eecbuilding.lat, eecbuilding.lng),
                this.map,
                this.unitPipe,
                energyType.energyClass
              );
              this.markers.push(buildingMarker);
            }
          }
        }
      }
    });
  }

  toggleEecBuildingEnergyClass(energyClass: string): void {
    const isActive = (energyClass in this.displayEnergyTypeSettings.eecBuildings && this.displayEnergyTypeSettings.eecBuildings[energyClass]) || false;
    const currentItem = { [energyClass]: !isActive };
    const eecBuildings = { "eecBuildings": Object.assign(this.displayEnergyTypeSettings.eecBuildings, currentItem) };
    this.buildingsService.updateDisplaySettings(Object.assign(this.displaySettings, eecBuildings));
    this.initEecBuildings();
  }

  toggleAllEnergyClasses(key: string, active: boolean): void {
    let items = { [key]: { active: active } };
    this.buildingsService.updateDisplaySettings(Object.assign(this.displaySettings, items));

    if (this.displaySettings.eecBuildings?.active == false) {
      this.displayEnergyTypeSettings = { "eecBuildings": { "A": false, "B": false, "C": false, "D": false, "E": false, "F": false, "G": false, "H": false } };
      this.buildingsService.updateDisplaySettings(Object.assign(this.displaySettings, this.displayEnergyTypeSettings));
    } else {
      this.displayEnergyTypeSettings = { "eecBuildings": { "A": true, "B": true, "C": true, "D": true, "E": true, "F": true, "G": true, "H": true } };
    }
    this.initEecBuildings();
  }

}
