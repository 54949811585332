import {Component, Inject, Input, OnInit} from '@angular/core';
import {QuestionnaireFile, QuestionnaireReference, QuestionnaireResponseDetail} from '@smartencity/core';
import {MyDataConfig} from '../../../mydata-config.model';
import {HttpClient} from '@angular/common/http';
import {SMARTENCITY_MYDATA_CONFIG} from '../../../injection-tokens';

@Component({
  selector: 'mydata-view-files',
  templateUrl: './view-files.component.html',
  styleUrls: ['./view-files.component.css']
})
export class ViewFilesComponent implements OnInit {
  @Input()
  public questionnaire: QuestionnaireReference;

  constructor(
    @Inject(SMARTENCITY_MYDATA_CONFIG) private config: MyDataConfig,
    private http: HttpClient,
  ) { }

  ngOnInit(): void {
  }

  public downloadFile(q11eFile: QuestionnaireFile) {
    this.http.get(
      this.config.apiUrl + '/questionnaire-participation/' + this.questionnaire?.id + '/files/' + q11eFile.id,
      {
        responseType: 'arraybuffer'
      }
    ).subscribe((data) => {
      const blob = new Blob([data], { type: q11eFile.mimeType });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.download = q11eFile.name;
      anchor.href = url;
      anchor.click();
    });
  }
}
