import {Component, Inject, OnInit} from '@angular/core';
import {BaseMapType, CoreConfig, SMARTENCITY_CORE_CONFIG} from '@smartencity/core';
import {CrowdInsightsDataService} from '../service/crowd-insights-data.service';
import {CrowdInsightsDemoDataService} from '../service/crowd-insights-demo-data.service';

@Component({
  selector: 'buildings-main',
  templateUrl: './buildings.component.html',
  providers: [
    {provide: CrowdInsightsDataService, useClass: CrowdInsightsDemoDataService}
  ]
})
export class BuildingsComponent implements OnInit {

  mapType: BaseMapType;

  constructor(@Inject(SMARTENCITY_CORE_CONFIG) private config: CoreConfig) {
    this.mapType = (config.map.mapType ? config.map.mapType : 'gmap')
  }

  ngOnInit(): void {
  }
}
