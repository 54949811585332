import {Component, Inject, Input, OnDestroy, OnInit, Optional, TemplateRef, ViewChild} from '@angular/core';
import {
  ConsentService,
  CoreConfig,
  PersonSeries,
  SMARTENCITY_CORE_CONFIG,
  UserService,
  PersonRegistrationNumberValidator,
  LoggerService,
  Person,
  PersonSeriesPeriod,
  PreventCloseAware,
  ErrorResponseHelper, PersonSeriesApiService
} from '@smartencity/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {debounceTime, distinctUntilChanged, map, switchMap, takeUntil} from 'rxjs/operators';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {TypeaheadMatch} from 'ngx-bootstrap/typeahead';
import {Subject} from 'rxjs/internal/Subject';
import {ToastrService} from 'ngx-toastr';
import {CurrentDatapointService} from '../../../../services/current-datapoint.service';
import moment from 'moment';

@Component({
  selector: 'mydata-series-consents',
  templateUrl: './consent-modal.component.html',
})
export class ConsentModalComponent implements OnInit, OnDestroy, PreventCloseAware {
  private ngDestroy = new Subject<void>();
  public consents: any[];

  @Input()
  public lastDataOwnerPeriod?: PersonSeriesPeriod;

  public minDate: string;

  public isStartOfTime = false;

  private formData: any = {};

  public personSeries: PersonSeries;

  public consentForm: FormGroup;

  @ViewChild('regNoTemplate', {static: true})
  regNoTemplate: TemplateRef<any>;

  @ViewChild('cprDobTemplate', {static: true})
  cprDobTemplate: TemplateRef<any>;

  personTempalte = null;

  personTypeahead$ = new Subject<string>();
  personItems$ = this.personTypeahead$.pipe(
    takeUntil(this.ngDestroy),
    distinctUntilChanged(),
    debounceTime(250),
    switchMap((term: string) => this.userService.searchPersons(term)),
    map((persons: Person[]) => {
      return persons.map((e) => ({
        id: e.id,
        countryCode: e.countryCode,
        registrationNumber: e.registrationNumber,
        displayName: e.displayName,
        juridical: e.juridical,
        idPrivacyType: e.idPrivacyType,
        dob: e.dob
      }));
    })
  );

  public showValidation = false;

  public createPerson = (registrationNumber: string) => {
    return {
      id: null,
      countryCode: this.config.defaultCountryCode,
      registrationNumber: registrationNumber,
      displayName: null
    };
  };

  constructor(
    @Inject(SMARTENCITY_CORE_CONFIG) private config: CoreConfig,
    public modalRef: BsModalRef,
    public toastr: ToastrService,
    public consentService: ConsentService,
    private personSeriesService: PersonSeriesApiService,
    private userService: UserService,
    public personRegistrationNumberValidator: PersonRegistrationNumberValidator,
    private loggerService: LoggerService,
    @Optional() private currentDatapointService: CurrentDatapointService
  ) {
    this.personTempalte = this.regNoTemplate;
  }

  ngOnInit() {
    this.formData = {
      customStartAt: moment().startOf('day').toDate(),
      startFromLastDataOwnerPeriod: false
    }

    this.minDate = (this.lastDataOwnerPeriod ? this.lastDataOwnerPeriod.startAt : null);

    if (this.lastDataOwnerPeriod) {
      this.isStartOfTime = new Date(this.lastDataOwnerPeriod.startAt).getTime() === 0;
    }

    this.consentForm = new FormGroup({
      person: new FormControl(null, [Validators.required, this.personRegistrationNumberValidator.check.bind(this.personRegistrationNumberValidator)]),
      customStartAt: new FormControl(null),
      startFromLastDataOwnerPeriod: new FormControl(false)
    });

    this.consentForm.patchValue(this.formData);

    if (this.config.personRegNoTemplate === 'cpr-dob') {
      this.personTempalte = this.cprDobTemplate;
    }

    this.personTypeahead$.pipe(takeUntil(this.ngDestroy)).subscribe((value) => {
      if (value) {
        const person = this.createPerson(value);
        this.consentForm.get('person').setValue(person);
      }
    });

    this.consentForm.get('startFromLastDataOwnerPeriod').valueChanges.subscribe((value) => {
      if (value && this.lastDataOwnerPeriod && !this.isStartOfTime) {
        this.consentForm.get('customStartAt').setValue(this.lastDataOwnerPeriod.startAt);
      }
    });

    this.getConsents();
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  onSelect(event: TypeaheadMatch): void {
    this.consentForm.get('registrationNumber').setValue(event.item);
  }

  public getConsents() {
    this.personSeriesService.getSeriesConsents(this.personSeries.id).subscribe(
      (data: any[]) => {
        this.consents = data;
      },
      (err: any) => {
      }
    );
  }

  addConsent() {
    this.consentForm.markAsDirty();
    if (!this.consentForm.valid) {
      console.error('Form is invalid', this.consentForm);
      this.showValidation = true;
      return;
    }

    const customStartAt = this.consentForm.value.startFromLastDataOwnerPeriod && this.isStartOfTime
      ? this.lastDataOwnerPeriod.startAt
      : (this.consentForm.value.customStartAt ? this.consentForm.value.customStartAt : null);

    const data = {
      id: this.consentForm.value.person.id,
      countryCode: this.consentForm.value.person.countryCode ? this.consentForm.value.person.countryCode.trim() : this.config.defaultCountryCode,
      registrationNumber: this.consentForm.value.person.registrationNumber ? this.consentForm.value.person.registrationNumber.trim() : null,
      customStartAt: customStartAt
    };

    this.consentService.setConsent(this.personSeries.id, data).subscribe(
      (data: any) => {
        this.toastr.success($localize`Consent added`);
        this.consentForm.reset(this.formData);
        this.showValidation = false;
        this.getConsents();
      },
      (err: any) => {
        let errorMessage = ErrorResponseHelper.getErrorReason(err);
        if (errorMessage) {
          this.toastr.error(errorMessage);
        } else {
          this.loggerService.error(err);
          this.toastr.error($localize`Error saving consent!`);
        }
      });
  }

  endConsent(consent: any) {
    this.consentService.endConsent({
      personSeriesId: consent.personSeries.id,
      id: consent.id
    }).subscribe(
      (data: any) => {
        this.getConsents();
      },
      (err: any) => {
      }
    );
  }

  close() {
    if (this.currentDatapointService) {
      this.currentDatapointService.loadConsents();
    }
    this.modalRef.hide();
  }

  isPreventClose(): boolean {
    return this.consentForm && this.consentForm.dirty;
  }

}
