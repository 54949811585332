import {Injectable, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs/internal/Subject';
import {
  Widget,
  WidgetFromQuestionnaireResponse,
  WidgetFromQuestionnaireResponseBulk,
  WidgetPosition,
  WidgetType
} from './widget';
import {WidgetService} from './widget.service';
import {GridsterItem} from 'angular-gridster2';
import {debounceTime, startWith, takeUntil} from 'rxjs/operators';
import {HttpResponse} from '@angular/common/http';
import {saveAs} from 'file-saver';
import {FormControl} from '@angular/forms';
import {AuthService, Mandate, PreventCloseModalService} from '@smartencity/core';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {ReplaySubject} from 'rxjs/internal/ReplaySubject';
import {Moment} from 'moment';
import {ToastrService} from 'ngx-toastr';
import {DashboardFilter, OwnerType, PersonDashboard} from './dashboard';
import {Observable} from 'rxjs/internal/Observable';
import {forkJoin} from 'rxjs/internal/observable/forkJoin';
import { WidgetModalComponent } from './widget-modal/widget-modal.component';
import { WidgetApiService } from '../../http/widget-api.service';
import {ReportFileExtension} from './dashboard-report-types';

@Injectable()
export class DashboardService implements OnDestroy {
  private ngDestroy = new Subject<void>();
  public dashboardFilter: DashboardFilter;
  public dashboard$ = new ReplaySubject<Array<GridsterItem>>(1);
  public widgetItems: Array<GridsterItem> = [];
  public currentDashboard: PersonDashboard;

  public currentDashboardSource = new ReplaySubject<PersonDashboard>(1);
  public currentDashboard$ = this.currentDashboardSource.asObservable().pipe(takeUntil(this.ngDestroy));

  public rangeControl: FormControl = new FormControl(this.getDefaultRange());
  public range$ = this.rangeControl.valueChanges.pipe(takeUntil(this.ngDestroy), startWith(this.getDefaultRange()));
  public range: any = null;

  public aggregationGroupingType$: Subject<string> = new BehaviorSubject(null);

  public aggregationGroupingType: any = null;

  public excelInProgress = false;
  public pdfInProgress = false;

  private componentItems = new Map<string, any>();
  private componentItemsSubject = new Subject<Map<string, any>>();
  public componentItems$ = this.componentItemsSubject.asObservable();

  constructor(
    private authService: AuthService,
    private widgetService: WidgetService,
    private widgetApiService: WidgetApiService,
    private preventCloseModalService: PreventCloseModalService,
    private toastr: ToastrService,
  ) {
  }

  public initialize(){
    this.initListeners();
    this.authService.currentMandate$.pipe(takeUntil(this.ngDestroy)).subscribe((mandate: Mandate) => {
      this.reload();
    });
  }

  ngOnDestroy(): void {
    this.dashboard$.complete();
    this.aggregationGroupingType$.complete();
    this.componentItemsSubject.complete();
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  public reload(): void {
    this.widgetService.loadWidgets(this.dashboardFilter);
  }

  private getDefaultRange(): {from: Moment, to: Moment} {
    return {from: null, to: null};
  }

  addWidget() {
    const widget = new Widget();
    widget.position = this.getInitialPosition();
    this.preventCloseModalService.show(WidgetModalComponent, {
      ignoreBackdropClick: true,
      class: 'modal-xxl',
      initialState: {
        widget: widget,
        dashboardFilter: this.dashboardFilter,
        onHide: () => {
          this.reload();
        }
      }
    });
  }

  addFromQuestionnaireResponse(request: WidgetFromQuestionnaireResponse): Observable<void> {

    return new Observable<void>(observer => {
      this.widgetService.createFromWidgetTemplate({
        q11eResponseId: request.responseId,
        widgetTemplateId: request.templateId,
        dashboardType: request.dashboardType,
        position: this.getFromWidgetTemplatePosition()
      }).subscribe((widget: Widget) => {
        this.rearrangeFromTemplateWidgets(widget);
        observer.next();
        observer.complete();
      }, (err) => {
        observer.error(err);
      });
    });
  }

  addFromQuestionnaireResponseBulk(request: WidgetFromQuestionnaireResponseBulk): Observable<any> {
    const toForkJoin: Observable<void>[] = [];
    for (const widgetTemplateId of request.templateIds) {
      toForkJoin.push(this.addFromQuestionnaireResponse({
        templateId: widgetTemplateId,
        responseId: request.responseId,
        dashboardType: request.dashboardType
      }));
    }

    return forkJoin(toForkJoin).pipe(takeUntil(this.ngDestroy));
  }

  public getFromWidgetTemplatePosition(): WidgetPosition {
    const rows = 5, cols = 5;
    if (this.widgetItems.length === 0) {
      return new WidgetPosition(0, 0, cols, rows);
    }

    let y = 0;
    for (const i of this.widgetItems) {
      if (i.widget.component == 'advices' || i.widget.component == 'incoming-questionnaires') {
        const newY = i.y + i.rows;
        if (y < newY) {
          y = newY;
        }
      }
    }

    return new WidgetPosition(0, y, cols, rows);
  }

  private rearrangeFromTemplateWidgets(widget: Widget): void {

    for (const item of this.widgetItems) {
      if (item.widget.id === widget.id) {
        // already added
        return;
      }
    }

    const item = {
      cols: widget.position.cols,
      rows: widget.position.rows,
      y: widget.position.y,
      x: widget.position.x,
      itemType: 'DASHBOARD',
      componentTemplate: null,
      widget: widget,
      update: new Subject<void>()
    };

    const dashboardCopy = this.widgetItems.slice();
    this.widgetItems.length = 0;
    for (const i of dashboardCopy) {
      if (i.widget.component == 'advices' || i.widget.component == 'incoming-questionnaires') {
        this.widgetItems.push(i);
      } else {
        i.update.complete();
        const replacedItem = Object.assign({}, i, {y: i.y + item.rows, update: new Subject<void>()});
        replacedItem.update.pipe(takeUntil(this.ngDestroy), debounceTime(250)).subscribe(() => {
          this.updateWidget(replacedItem);
        });
        this.widgetItems.push(replacedItem);
      }
    }

    this.widgetItems.push(item);
    this.dashboard$.next(this.widgetItems);

    item.update.pipe(takeUntil(this.ngDestroy), debounceTime(250)).subscribe(() => {
      this.updateWidget(item);
    });
  }

  updateWidget(item) {
    item.widget.position = new WidgetPosition(item.x, item.y, item.cols, item.rows);
    if (item.widget.id) {
      this.widgetApiService.update(item.widget).pipe(takeUntil(this.ngDestroy)).subscribe();
    } else {
      this.widgetApiService.save(item.widget).pipe(takeUntil(this.ngDestroy)).subscribe((result) => {
        item.widget.id = result.id;
      });
    }
  }

  setComponentWidgetTemplate(compontent: string, componentTemplate: any) {
    let item = this.componentItems.get(compontent);
    if (!item) {
      const widget = new Widget();
      widget.type = WidgetType.COMPONENT;
      widget.component = compontent;
      item = {
        cols: 12,
        rows: 4,
        y: 0,
        x: 0,
        itemType: 'ENERGY_MANAGER',
        widget: widget,
        update: new Subject<void>(),
        componentTemplate: componentTemplate,
      };
      this.componentItems.set(compontent, item);
      this.addItemAsFirst(item);
      item.update.pipe(takeUntil(this.ngDestroy), debounceTime(250)).subscribe(() => {
        this.updateWidget(item);
      });
      this.componentItemsSubject.next(this.componentItems);
    } else {
      item.componentTemplate = componentTemplate;
    }
  }

  removeComponentWidget(component: string): void {
    const item = this.componentItems.get(component);
    this.componentItems.delete(component);
    if (item) {
      item.update.complete();
      this.widgetApiService.delete(item.widget).subscribe(() => {
        this.reload();
      }, (e: any) => {
        this.toastr.error(JSON.stringify(e));
        this.reload();
      });
    }
  }

  addItemAsFirst(item) {
    const widgetItems = this.widgetItems.slice();
    this.widgetItems.length = 0;
    for (const i of widgetItems) {
      i.update.complete();
      const replacedItem = Object.assign({}, i, {y: i.y + item.rows, update: new Subject<void>()});
      replacedItem.update.pipe(takeUntil(this.ngDestroy), debounceTime(250)).subscribe(() => {
        this.updateWidget(replacedItem);
      });
      this.widgetItems.push(replacedItem);
    }
    this.widgetItems.push(item);
    this.dashboard$.next(this.widgetItems);
  }

  downloadDashboard(extension: ReportFileExtension): Observable<void> {
    this.excelInProgress = true;

    return new Observable<void>((observer) => {
      this.widgetService.downloadDashboard({
        dashboardFilter: this.dashboardFilter,
        range: this.range,
        extension: extension,
        aggregationGroupingType: this.aggregationGroupingType != 'LIVE' ? this.aggregationGroupingType : null
      }).pipe(takeUntil(this.ngDestroy)).subscribe((response: HttpResponse<Blob>) => {
        //TODO: filename
        const fileName = 'dashboard.xlsx';
        const blob = new Blob([response.body], {type: 'application/octet-stream'});
        saveAs(blob, fileName);

        observer.next();
        observer.complete();
        this.excelInProgress = false;
      }, (error) => {
        observer.error(error);
        observer.complete();
        this.excelInProgress = false;
      });
    });



  }

  downloadWidget(widget: Widget, extension: ReportFileExtension) {
    this.widgetService.downloadWidget({
      widget: widget,
      dashboardFilter: this.dashboardFilter,
      range: this.range,
      extension: extension,
      aggregationGroupingType: this.aggregationGroupingType != 'LIVE' ? this.aggregationGroupingType : null
    }).pipe(takeUntil(this.ngDestroy)).subscribe((response: HttpResponse<Blob>) => {
      //TODO: filename
      const fileName = 'widget.xlsx';
      const blob = new Blob([response.body], {type: 'application/octet-stream'});
      saveAs(blob, fileName);
    }, (error) => {
    });
  }

  downloadPdf(): Observable<void> {

    return new Observable<void>((observer) => {
      this.pdfInProgress = true;
      const imagesMap = [];
      for (let i = 0; i < this.widgetItems.length; i++) {
        const widget = this.widgetItems[i]['widget'];
        if (widget.type != WidgetType.TIME_FRAME_CHART && widget.type != WidgetType.VALUE_CHART && widget.type != WidgetType.VALUE_CHART) {
          continue;
        }
        const canvasId =  'widget-canvas-' + widget.id;
        const canvas = document.getElementById(canvasId) as HTMLCanvasElement;
        if (canvas != null) {
          imagesMap.push({
            id: widget.id,
            image: canvas.toDataURL()
          });
        }
      }

      this.widgetService.downloadPdf(this.dashboardFilter, this.range, imagesMap, this.aggregationGroupingType).pipe(takeUntil(this.ngDestroy)).subscribe((response: HttpResponse<Blob>) => {
        const fileName = 'dashboard.pdf';
        const blob = new Blob([response.body], {type: 'application/octet-stream'});
        saveAs(blob, fileName);
        this.pdfInProgress = false;
        observer.next();
        observer.complete();
      }, (error) => {
        this.pdfInProgress = false;
        observer.error(error);
        observer.complete();
      });
    });
  }

  private getInitialPosition(): WidgetPosition {
    if (this.widgetItems.length === 0) {
      return new WidgetPosition(0, 0, 5, 5);
    }
    const last = this.widgetItems[this.widgetItems.length - 1];
    return new WidgetPosition(last.x, last.y, last.cols, last.rows);
  }

  private initListeners(): void {
    this.widgetService.widgets$.subscribe((widgets: Widget[]) => {
      for (const item of this.widgetItems) {
        item.update.complete();
      }
      this.widgetItems.length = 0;
      this.componentItems.clear();
      for (const widget of widgets) {
        const itemType = widget.type == WidgetType.TIME_FRAME_CHART || widget.type == WidgetType.VALUE_CHART || widget.type == WidgetType.VALUE_TABLE ? 'DASHBOARD' : 'ENERGY_MANAGER';
        let item;
        const ownerType: OwnerType = !this.currentDashboard ? OwnerType.OWNER : this.currentDashboard.ownerType;
        if (widget.position) {
          item = {
            cols: widget.position.cols,
            rows: widget.position.rows,
            y: widget.position.y,
            x: widget.position.x,
            itemType: itemType,
            componentTemplate: null,
            widget: widget,
            update: new Subject<void>(),
            ownerType: ownerType
          };
        } else {
          item = {
            cols: 3,
            rows: 3,
            y: -1,
            x: 0,
            itemType: itemType,
            componentTemplate: null,
            widget: widget,
            update: new Subject<void>()
          };
        }
        item.update.pipe(takeUntil(this.ngDestroy), debounceTime(250)).subscribe(() => {
          this.updateWidget(item);
        });
        this.widgetItems.push(item);
        if (itemType === 'ENERGY_MANAGER') {
          this.componentItems.set(widget.component, item);
        }
      }
      this.dashboard$.next(this.widgetItems);
      this.componentItemsSubject.next(this.componentItems);
    });

    this.range$.pipe(takeUntil(this.ngDestroy)).subscribe((range) => {
      this.range = range;
    });

    this.aggregationGroupingType$.pipe(takeUntil(this.ngDestroy)).subscribe((aggregationGroupingType) => {
      this.aggregationGroupingType = aggregationGroupingType;
    });
  }
}
