import {Component, Inject, LOCALE_ID, OnInit} from '@angular/core';
import {SMARTENCITY_CORE_CONFIG} from '../../injection-tokens';
import {CoreConfig} from '../../core-config.model';

@Component({
  selector: 'terms-of-service',
  templateUrl: './tos.component.html'
})
export class TosComponent implements OnInit {

  termsOfService: string = '';

  constructor(@Inject(SMARTENCITY_CORE_CONFIG) public config: CoreConfig,
              @Inject(LOCALE_ID) public locale: string) { }

  ngOnInit () {
    if (this.config.termsOfService) {
      if (this.config.termsOfService[this.locale]) {
        this.termsOfService = this.config.termsOfService[this.locale];
      } else {
        this.termsOfService = this.config.termsOfService['default'];
      }
    }
  }

}
