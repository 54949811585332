import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {combineLatest, Observable, ReplaySubject, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {DashboardService} from './dashboard.service';
import {DashboardFilter, DashboardParams, DashboardType, OwnerType, PersonDashboard} from './dashboard';
import {NavbarService} from '../navbar/navbar.service';
import {BsModalService} from 'ngx-bootstrap/modal';
import {DashboardManagementComponent} from './dashboard-management/dashboard-management.component';
import {ActivatedRoute, Router} from '@angular/router';
import {WidgetTemplateApiService} from '../../http/widget-template-api.service';
import {DashboardWidgetTemplate, WidgetFromQuestionnaireResponseBulk} from './widget';
import {
  DashboardWidgetTemplateListModalComponent
} from './dashboard-widget-template-list-modal/dashboard-widget-template-list-modal.component';
import {PreferencesService} from '@smartencity/core';
import { DashboardApiService } from '../../http/dashboard-api.service';

@Component({
  selector: 'mydata-dashboard-page',
  templateUrl: './dashboard-page.component.html',
  providers: [DashboardService]
})
export class DashboardPageComponent implements OnInit, OnDestroy {
  private ngDestroy = new Subject<void>();

  @ViewChild('navMenu', {static: true})
  public navTemplate: TemplateRef<any>;

  @ViewChild('incomingWidgetTemplates', {static: true})
  public incomingWidgetTemplatesTemplate: TemplateRef<any>;

  public personDashboards: PersonDashboard[] = [];
  public customDashboards: PersonDashboard[] = [];
  public widgetTemplates: DashboardWidgetTemplate[] = [];

  public preferences$ = this.preferencesServices.preferences$;
  public OwnerType = OwnerType;

  private dashboardsSource = new ReplaySubject<PersonDashboard[]>();
  public dashboards$: Observable<PersonDashboard[]> = this.dashboardsSource.asObservable().pipe(takeUntil(this.ngDestroy));

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private navbarService: NavbarService,
    private dashboardService: DashboardService,
    private modalService: BsModalService,
    private widgetTemplateApi: WidgetTemplateApiService,
    private preferencesServices: PreferencesService,
    private dashboardApiService: DashboardApiService
  ) {}

  initDashboards(): void {
    combineLatest([
      this.dashboards$,
      this.route.queryParams
    ]).subscribe(([dashboards, dashboardParams]: [PersonDashboard[], DashboardParams]) => {
      this.handleDashboards(dashboards);
      this.handleDashboardParams(dashboardParams);
    });
    this.loadDashboards();
  }

  private handleDashboards(personDashboards: PersonDashboard[]): void {
    this.customDashboards = personDashboards.filter(personDashboard => personDashboard.dashboard.type == DashboardType.CUSTOM);
    this.personDashboards = personDashboards;
  }

  private handleDashboardParams(dashboardParams: DashboardParams): void {
    const filter = new DashboardFilter(DashboardType.DEFAULT);

    if (dashboardParams.dashboard_id) {
      filter.dashboardType = DashboardType.CUSTOM;
      filter.dashboardId = dashboardParams.dashboard_id;
    }

    this.dashboardService.dashboardFilter = filter;
    const currentDashboard: PersonDashboard = this.personDashboards.find(personDashboard => filter.dashboardType == DashboardType.DEFAULT && personDashboard.dashboard.type == DashboardType.DEFAULT || personDashboard.dashboard.type == DashboardType.CUSTOM && personDashboard.dashboard.id == filter.dashboardId);

    if (!currentDashboard) {
      if (filter.dashboardType === DashboardType.CUSTOM) {
        this.router.navigate(['mydata', 'dashboard'], {queryParams: {dashboard_type: 'default'}});
        return;
      }
    } else {
      this.dashboardService.currentDashboard = currentDashboard;
      this.dashboardService.currentDashboardSource.next(currentDashboard);
    }

    this.dashboardService.initialize();
  }

  loadDashboards(): void {
    this.dashboardApiService.getDashboardOptions().subscribe((dashboards: PersonDashboard[]) => {
      this.dashboardsSource.next(dashboards);
    });
  }

  loadAvailableWidgetTemplates() {
    this.widgetTemplateApi.dashboardSearch('OTHER').subscribe((widgetTemplates: DashboardWidgetTemplate[]) => {
      this.widgetTemplates = widgetTemplates;
    });
  }

  ngOnInit() {
    this.initDashboards();
    this.loadAvailableWidgetTemplates();
    this.navbarService.setMenuTemplate(this.navTemplate);
    this.navbarService.setIncomingWidgetTemplatesTemplate(this.incomingWidgetTemplatesTemplate);
  }

  ngOnDestroy(): void {
    this.navbarService.setMenuTemplate(null);
    this.navbarService.setIncomingWidgetTemplatesTemplate(null);
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  editDashboards(){
    this.modalService.show(DashboardManagementComponent,
      {
        ignoreBackdropClick: true,
        class: 'modal-lg',
        initialState: {
          callback: (hasUpdates) => {
            if (hasUpdates) {
              this.loadDashboards();
            }
          }
        }
      });
  }

  public openDashboardWidgetTemplates() {
    const modalRef = this.modalService.show(DashboardWidgetTemplateListModalComponent, {
      ignoreBackdropClick: true,
      initialState: {
        widgetTemplates: this.widgetTemplates
      }
    });

    modalRef.content.result.subscribe((result: DashboardWidgetTemplate[]) => {

      //grupeerib vastuse ID järgi
      const groupedByResponse: Map<number, number[]> = new Map<number, number[]>();

      for (const dashboardWidgetTemplate of result) {
        if (!groupedByResponse.has(dashboardWidgetTemplate.response.id)) {
          groupedByResponse.set(dashboardWidgetTemplate.response.id, []);
        }
        if (groupedByResponse.get(dashboardWidgetTemplate.response.id).indexOf(dashboardWidgetTemplate.id) === -1) {
          groupedByResponse.get(dashboardWidgetTemplate.response.id).push(dashboardWidgetTemplate.id);
        }
      }

      for (const [responseId, templateIds] of groupedByResponse) {

        const request = new WidgetFromQuestionnaireResponseBulk();
        request.templateIds = templateIds;
        request.responseId = responseId;
        request.dashboardType = DashboardType.DEFAULT;
        this.dashboardService.addFromQuestionnaireResponseBulk(request).subscribe(() => {
          // added
        });
      }
    });
  }
}
