import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { LayerDisplaySettings } from 'projects/arc-gis/src/lib/layer-display-settings';
import { TallinnArcGisMapService } from 'projects/arc-gis/src/lib/tallinn-arc-gis-map.service';
import {DisplaySettings} from '../../model/display-settings';

@Component({
  selector: 'meteorology-layer-filters',
  templateUrl: './meteorology-layer-filters.component.html'
})
export class MeteorologyLayerFiltersComponent implements OnInit {

  private mapService: TallinnArcGisMapService;
  public displayArcGisSettings: LayerDisplaySettings = new LayerDisplaySettings();

  constructor(
    mapService: TallinnArcGisMapService
  ) {
    this.mapService = mapService;
  }

  @Input()
  displaySettings: DisplaySettings;

  @Output('displaySettingsChange')
  displaySettingsChange: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit(): void {
  }

  displayItemsChanged(items: any) {
    if (items.roadConditionPoints) {
      items.roadWeatherPoints = false;
    }

    if (items.roadWeatherPoints) {
      items.roadConditionPoints = false;
    }

    this.displaySettingsChange.next(items);
  }

  public toggleLayer(displaySettings: any): void {
    this.displayArcGisSettings = Object.assign(this.displayArcGisSettings, displaySettings);
    this.mapService.updateDisplaySettings(this.displayArcGisSettings);
  }
}
