import {Injectable, OnDestroy} from '@angular/core';
import {SCREEN_SIZE} from '../models/resize';
import {distinctUntilChanged, shareReplay} from 'rxjs/operators';
import {Subject} from 'rxjs/internal/Subject';

@Injectable()
export class ResizeService implements OnDestroy {
  private resizeSubject = new Subject<SCREEN_SIZE>();
  public onResize$ = this.resizeSubject.asObservable().pipe(distinctUntilChanged(), shareReplay(1));

  constructor() {
    this.onResize$.subscribe();
  }

  ngOnDestroy(): void {
    this.resizeSubject.complete();
  }

  onResize(size: SCREEN_SIZE) {
    this.resizeSubject.next(size);
  }
}
