import {OlMapWrapper, OlOverlayWrapper} from '@smartencity/core';
import {WaterTap} from '../model/water-tap';

export class WaterTapsOlMarkerWrapper extends OlOverlayWrapper {

  public selected = false;

  private waterTap: any;

  constructor(waterTap: WaterTap, map: OlMapWrapper) {
    super({
      title: waterTap.name,
      position: [waterTap.lng, waterTap.lat]
    });
    this.waterTap = waterTap;
    this.setMap(map.getMap());
  }

  public draw() {
    if (!this.div) {
      this.div = document.createElement('DIV');
      // this.div.title = this.waterTap.readableAddress;
      this.div.style.cursor = 'pointer';
      // this.div.innerHTML = '<div class="pointer-water-tap" data-trigger="hover" data-toggle="popover" data-placement="top" data-html="true" data-content="' + this.waterTap.name + '"></div>';
      this.div.innerHTML = '<div class="pointer-water-tap" data-trigger="hover" data-toggle="popover" data-placement="top" data-html="true" data-content="' + this.waterTap.name + '"></div>';
      $('.popover.show').remove();
      ($('[data-toggle="popover"]') as any).popover({
        trigger: 'hover'
      });
    }

    if (this.selected) {
      this.div.classList.add("active");
    } else {
      this.div.classList.remove("active");
    }

  }

}
