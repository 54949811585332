import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {DashboardWidgetTemplate} from '../widget';

@Component({
  selector: 'app-dashboard-widget-template-list-modal',
  templateUrl: './dashboard-widget-template-list-modal.component.html'
})
export class DashboardWidgetTemplateListModalComponent implements OnInit {

  @Input()
  public widgetTemplates: DashboardWidgetTemplate[];

  public selected: DashboardWidgetTemplate[] = [];

  @Output()
  result: EventEmitter<DashboardWidgetTemplate[]> = new EventEmitter<DashboardWidgetTemplate[]>();

  constructor(private modalRef: BsModalRef) { }

  ngOnInit(): void {
  }

  isSelected(widgetTemplate: DashboardWidgetTemplate): boolean {
    return this.selected.indexOf(widgetTemplate) > -1;
  }

  public toggleSelect(widgetTemplate: DashboardWidgetTemplate): void {
    if (this.selected.indexOf(widgetTemplate) > -1) {
      this.selected.splice(this.selected.indexOf(widgetTemplate), 1);
    } else {
      this.selected.push(widgetTemplate);
    }
  }

  public addAndClose(): void {
    this.result.emit(this.selected);
    this.modalRef.hide();
  }



  public close(): void {
    this.modalRef.hide();
  }
}
