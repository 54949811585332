<div class="jumbotron floating-jumbotron ps content-wrapper active crowd-insights-modal" [class.active]="!loading && gridArea">
  <div class="modal-content-wrapper">
    <div class="modal-header">
      <h5 class="modal-title w-100">{{gridArea?.name}}</h5>
      <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
        <i class="fal fa-times"></i>
      </button>
    </div>

    <div class="modal-body">
      <div class="object-wrapper wide active">
        <tabset type="pills" #staticTabs>
          <tab heading="Activities" i18n-heading>
            <div class="pt-3">
              <ci-grid-area-daily-activities [gridArea]="gridArea" [close]="close$"></ci-grid-area-daily-activities>
            </div>
          </tab>
          <tab heading="Incoming visits" i18n-heading>
            <div class="pt-3">
              <ci-grid-area-daily-visits [gridArea]="gridArea"></ci-grid-area-daily-visits>
            </div>
          </tab>
        </tabset>

      </div>
    </div>
  </div>
</div>
