<ng-template #noRootTemplate>
  <button  *ngIf="isOwner" (click)="this.dashboardService.addWidget()" type="button" class="btn btn-primary btn-sm ml-2 d-none d-md-inline-block">
    &nbsp;<ng-container i18n>Create widget</ng-container>
  </button>

  <div class="btn-floating-mobile-container">
    <button *ngIf="isOwner" (click)="this.dashboardService.addWidget()" type="button" class="btn btn-primary btn-sm btn-floating-mobile">
      <i class="fal fa-plus fa-2x"></i>
    </button>
  </div>
</ng-template>
