import {Toilet} from '../models/services.model';

export class ToiletsLayer extends google.maps.OverlayView {
  private div: any;

  public toilets: Toilet[];

  private popoverTimeout = null;

  constructor(toilets: Toilet[]) {
    super();
    this.toilets = toilets;
  }

  onAdd() {
    const panes = this.getPanes();
    if (panes == null) {
      return;
    }

    this.div = document.createElement('DIV');
    this.div.style.position = 'absolute';
    this.div.style.cursor = 'pointer';
    this.div.style.position = 'absolute';
    this.div.classList.add('custom-marker-layer');
    this.div.style.paddingLeft = '0px';
    panes.overlayMouseTarget.appendChild(this.div);
  }

  onRemove() {
    if (this.div) {
      this.div.parentNode.removeChild(this.div);
      this.div = null;
    }
  }

  draw() {
    if (!this.div) {
      return;
    }

    this.div.innerHTML = this.getLayerHtml();

    this.resetPopovers();
  }

  private resetPopovers() {
    if (this.popoverTimeout != null) {
      clearTimeout(this.popoverTimeout);
    }
    this.popoverTimeout = setTimeout(() => {
      $('.popover.show').remove();
      ($('[data-toggle="popover"]') as any).popover({
        trigger: 'hover'
      });

    }, 400);
  }

  private getLayerHtml() {
    let html = '';

    if (!this.toilets) {
      return html;
    }

    this.toilets.forEach(item => {
      const point = this.getProjection().fromLatLngToDivPixel(new google.maps.LatLng(item.lat, item.lng));
      const x = point.x - 12;
      const y = point.y - 39;
      const title = item.street + ', ' + item.zip;

      html += '<div class="pointer-toilet" style="left: ' + x + 'px; top: ' + y + 'px" data-trigger="hover" data-toggle="popover" data-placement="top" data-content="' + title + '"></div>';
    });

    return html;
  }

  setMap(map) {
    super.setMap(map);
  }
}
