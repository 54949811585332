import {Inject, Injectable, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs/internal/Subject';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import moment from 'moment';
import {
  ConfirmModalComponent,
  CustomFormValidators,
  FileRef,
  QuestionnaireDetail,
  QuestionnaireField, QuestionnaireParticipation,
  QuestionnaireResponseDetail
} from '@smartencity/core';
import {ReplaySubject} from 'rxjs/internal/ReplaySubject';
import {catchError, shareReplay, switchMap, takeUntil, tap} from 'rxjs/operators';
import {TenantSelectService} from '../../services/tenant-select.service';
import {Observable} from 'rxjs/internal/Observable';
import {of} from 'rxjs/internal/observable/of';
import {forkJoin} from 'rxjs/internal/observable/forkJoin';
import {MyDataConfig} from '../../mydata-config.model';
import {HttpClient} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';
import {BsModalService} from 'ngx-bootstrap/modal';
import {SMARTENCITY_MYDATA_CONFIG} from '../../injection-tokens';
import {Subscription} from 'rxjs/internal/Subscription';
import {throwError} from 'rxjs/internal/observable/throwError';
import {QuestionnaireResponseApiService} from '../../http/questionnaire-response-api.service';

export class InputFieldRow {
  symbol: string;
  field: QuestionnaireField;
  formGroup: FormGroup;
  destroy: Subject<void>;
}

@Injectable()
export class ManageService implements OnDestroy {
  private ngDestroy = new Subject<void>();

  public type = 'QUESTIONNAIRE';
  private questionnaireSubject = new ReplaySubject<QuestionnaireDetail>(1);
  private fieldsSubject = new ReplaySubject<QuestionnaireField[]>(1);
  questionneire$ = this.questionnaireSubject.asObservable();
  fields$ = this.fieldsSubject.asObservable();
  private currentField = null;
  private currentChildField = null;
  private fieldSubject = new Subject<QuestionnaireField>();
  field$ = this.fieldSubject.asObservable().pipe(shareReplay(1));
  questionnaire: QuestionnaireDetail;

  filesArray = new FormArray([]);
  symbolFieldMap = new Map<string, QuestionnaireField>();

  private questionnaireParticipationSubject = new ReplaySubject<QuestionnaireParticipation>(1);
  questionnaireParticipation$ = this.questionnaireParticipationSubject.asObservable();

  fields: QuestionnaireField[] = [];
  symbolFieldsMap = new Map<string, Set<any>>();
  fieldSymbolsMap = new Map<any, Set<string>>();
  fieldFormArray = new FormArray([], [Validators.required]);

  private publicParticipationSubject = new ReplaySubject<boolean>(1);
  publicParticipation$ = this.publicParticipationSubject.asObservable().pipe(shareReplay(1));

  formGroup: FormGroup = new FormGroup({
    tenantId: new FormControl(null),
    name: new FormControl('', [Validators.required]),
    typeEnergyManager: new FormControl(false, [Validators.required]),
    description: new FormControl('', []),
    files: this.filesArray
  });
  publicParticipationControl = new FormControl(false, [Validators.required]);

  private currentFieldFormGroup: FormGroup;
  private currentFieldFormGroupSubscription: Subscription = new Subscription();

  constructor(
    @Inject(SMARTENCITY_MYDATA_CONFIG) private config: MyDataConfig,
    private http: HttpClient,
    private tenantSelectService: TenantSelectService,
    private toastr: ToastrService,
    private modalService: BsModalService,
    private questionnaireResponseApi: QuestionnaireResponseApiService
  ) {

    this.questionneire$.subscribe((q) => {
      this.questionnaire = q;
      this.type = q.type;

      this.formGroup.patchValue({
        tenantId: q.tenantId,
        name: q.name,
        typeEnergyManager: q.type === 'ENERGY_MANAGER',
        description: q.description,
        files: []
      });

      this.publicParticipationControl.setValue(q.publicParticipation ? q.publicParticipation : false);

      while (this.filesArray.length !== 0) {
        this.filesArray.removeAt(0);
      }
      for (const q11eFile of this.questionnaire.files) {
        const fileRef: FileRef = new FileRef();
        fileRef.id = q11eFile.fileId;
        fileRef.mimeType = q11eFile.mimeType;
        fileRef.name = q11eFile.name;
        fileRef.isUploaded = true;
        fileRef.isLoaded = false;
        const fileControl = new FormGroup({
          id: new FormControl(q11eFile.id, []),
          fileRef: new FormControl(fileRef, [Validators.required]),
        });
        this.filesArray.push(fileControl);
      }

      this.fields = q.fields ? q.fields : [];
      this.fieldsSubject.next(this.fields);
      this.symbolFieldsMap.clear();

      // TODO get 1st field
      // this.fieldSubject.next(this.createField('QUESTION'));

      for (const field of this.fields) {
        let children = this.fieldSymbolsMap.get(field);
        if (!children) {
          children = new Set<string>();
          this.fieldSymbolsMap.set(field, children);
        }
        if (field.type === 'FORMULA') {
          for (const symbol of field.variables) {
            children.add(symbol);
            let parents = this.symbolFieldsMap.get(symbol);
            if (!parents) {
              parents = new Set<any>();
              this.symbolFieldsMap.set(symbol, parents);
            }
            parents.add(field);
          }
        }
      }

      this.symbolFieldMap.clear();
      for (const field of this.fields) {
        if (field.symbol) {
          this.symbolFieldMap.set(field.symbol, field);
        }
      }

      if (this.type !== 'FORMULA_SOURCE_TEMPLATE') {
        const disabled = q.id && q.status !== 'DRAFT';
        this.doSetField(this.createField(!disabled ? 'QUESTION' : null));
      } else {
        this.doSetField(this.createField('FORMULA', 'EXPRESSION'));
      }
    });

    this.field$.subscribe();

    this.publicParticipationControl.valueChanges.subscribe((value) => {
      this.publicParticipationSubject.next(value);
    });

  }

  ngOnDestroy(): void {
    this.questionnaireSubject.complete();
    this.fieldsSubject.complete();
    this.fieldSubject.complete();
    if (this.currentFieldFormGroupSubscription) {
      this.currentFieldFormGroupSubscription.unsubscribe();
    }
    this.publicParticipationSubject.complete();
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  public checkAndConfirmDiscard(): Observable<boolean> {
    if (this.formGroup?.dirty || this.currentFieldFormGroup?.dirty) {
      return new Observable<boolean>(subscriber => {
        let description = $localize`Current questionnaire contains unsaved changes. Are you sure you want to discard the changes?`;
        if (this.type === 'FORMULA_SOURCE_TEMPLATE') {
          description = $localize`Formula template contains unsaved changes. Are you sure you want to discard the changes?`;
        }
        const modalRef = this.modalService.show(ConfirmModalComponent, {
          ignoreBackdropClick: true,
          initialState: {
            description: description,
            callback: (confirm: boolean) => {
              console.log("callback");
              if (confirm) {
                subscriber.next(true);
              } else {
                console.log("false");
                subscriber.next(false);
              }
              subscriber.complete();
            }
          }
        });
      });
    } else {
      return of(true);
    }
  }

  public setQuestionnaire(questionnaire: QuestionnaireDetail): void {
    this.questionnaireSubject.next(questionnaire);
  }

  public setQuestionnaireParticipation(questionnaireParticipation: QuestionnaireParticipation): void {
    this.questionnaireParticipationSubject.next(questionnaireParticipation);
  }

  public getCurrentFiledFormGroup(): FormGroup {
    return this.currentFieldFormGroup;
  }

  public getCurrentChildField() {
    return this.currentChildField;
  }

  public async setField(field, childField?): Promise<any> {
    await this.checkAndConfirmFieldDiscard();
    this.doSetField(field, childField);
  }

  public async checkAndConfirmFieldDiscard(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      if (this.currentFieldFormGroup?.dirty) {
        let description = $localize`Current question contains unsaved changes. Are you sure you want to discard the changes and proceed?`;
        if (this.type === 'FORMULA_SOURCE_TEMPLATE') {
          description = $localize`Current field contains unsaved changes. Are you sure you want to discard the changes and proceed?`;
        }
        const modalRef = this.modalService.show(ConfirmModalComponent, {
          ignoreBackdropClick: true,
          initialState: {
            description: description,
            callback: (confirm: boolean) => {
              if (confirm) {
                resolve(true);
              } else {
                reject();
              }
            }
          }
        });
      } else {
        resolve(true);
      }
    });
  }

  private doSetField(field, childField?) {
    let formGroup = null;
    if (this.currentFieldFormGroupSubscription) {
      this.currentFieldFormGroupSubscription.unsubscribe();
    }
    this.currentFieldFormGroupSubscription = new Subscription();

    const disabled = this.questionnaire.type !== 'FORMULA_SOURCE_TEMPLATE' && this.questionnaire.status !== 'DRAFT' && field.id !== null;

    if (field?.type === 'QUESTION') {

      const selectOptionsArray = new FormArray([], [CustomFormValidators.uniqueOptions()]);
      if (field.valueType === 'SELECT') {
        for (const option of field.selectOptions) {
          let fg = this.createSelectOptionControl(option);
          if (disabled && option.status !== 'PENDING') {
            fg.get('name').disable();
          }
          selectOptionsArray.push(fg);
        }
      }

      formGroup = new FormGroup({
        responseType: new FormControl(field.responseType, [Validators.required]),
        valueType: new FormControl({ value: field.valueType, disabled: disabled }, [Validators.required]),
        name: new FormControl({ value: field.name, disabled: false }, [Validators.required]),
        description: new FormControl(field.description ? field.description : null, []),
        selectOptions: selectOptionsArray,
        unit: new FormControl({ value: field.unit, disabled: disabled }, []),
        value: new FormControl({ value: field.value, disabled: disabled }, []),
        personParameter: new FormControl(field.personParameter, []),
        personSeries: new FormControl(field.personSeries, []),
        windowPeriodType: new FormControl(field.windowPeriodType, []),
        windowOperationType: new FormControl(field.windowOperationType, []),
        periodCount: new FormControl(field.periodCount, []),
        optional: new FormControl({value: !field.required, disabled: disabled}, []),
        defaultValue: new FormControl(field.defaultValue/*, (!field.required) ? [Validators.required, Validators.pattern(/^\d+([\.\,]\d+)?$/)] : []*/), // TODO
      });

      this.currentFieldFormGroupSubscription.add(formGroup.get('valueType').valueChanges.pipe(takeUntil(this.ngDestroy)).subscribe(valueType => {
        if (valueType === 'SELECT') {
          if (selectOptionsArray.length < 1) {
            selectOptionsArray.push(this.createSelectOptionControl());
          }
          selectOptionsArray.setValidators([CustomFormValidators.uniqueOptions()]);
          selectOptionsArray.updateValueAndValidity();
        } else {
          selectOptionsArray.clear();
          selectOptionsArray.clearValidators();
          selectOptionsArray.updateValueAndValidity();
        }
      }));
    }

    if (field?.type === 'FORMULA') {
      formGroup = new FormGroup({
        id: new FormControl(field.id, []),
        symbol: new FormControl({ value: field.symbol, disabled: disabled }, [/*Validators.required*/]), // TODO validate symbol usage
        name: new FormControl({ value: field.name, disabled: false }, [Validators.required]),
        variables: new FormControl(field.variables, [Validators.required]),
        expression: new FormControl({ value: field.expression, disabled: disabled }, []),
        mapping: new FormControl(field.mapping, []),
        time: new FormControl({ value: moment(field.time).toDate(), disabled: disabled } , [Validators.required]),
        samplingType: new FormControl(field.samplingType, [Validators.required]),
        description: new FormControl(field.description ? field.description : null, []),
        unit: new FormControl({ value: field.unit ? field.unit : null, disabled: disabled }, [Validators.required])
      });
    }
    this.currentField = field;
    this.currentChildField = childField;
    this.currentFieldFormGroup = formGroup;
    this.fieldSubject.next(field);
  }

  public setFieldType(type: string, formulaType?: string): void {
    this.doSetField(Object.assign({}, this.currentField, this.createField(type, formulaType)));
    if (this.currentFieldFormGroup) {
      this.currentFieldFormGroup.markAsDirty();
    }
  }

  public async addField(type?, formulaType?) {
    await this.setField(this.createField(type, formulaType));
  }

  private createField(type: string, formulaType?: string, symbol?: string): QuestionnaireField {
    return {
      id: null,
      symbol: symbol ? symbol : null,
      name: '',
      description: null,
      type: type || this.type !== 'FORMULA_SOURCE_TEMPLATE' ? type : 'FORMULA',
      responseType: this.type !== 'FORMULA_SOURCE_TEMPLATE' ? 'RESPONSE' : 'COMMON',
      formulaType: type || this.type !== 'FORMULA_SOURCE_TEMPLATE' ? formulaType : 'EXPRESSION',
      valueType: null,
      selectOptions: [],
      unit: null,
      value: null,
      personParameter: null,
      personSeries: null,
      windowOperationType: null,
      windowPeriodType: null,
      periodCount: null,
      required: true,
      defaultValue: null,
      variables: [],
      expression: '',
      mapping: [],
      time: moment().toDate(),
      samplingType: 'HOURLY'
    };
  }

  public createSelectOptionControl(option?: any) {
    return new FormGroup({
      name: new FormControl(option ? option.name : '', [Validators.required]),
      value: new FormControl(option ? option.value : null),
      status: new FormControl(option ? (option.status ? option.status : 'SAVED') : 'PENDING')
    });
  }

  public getOrCreateSymbol(symbol: string): QuestionnaireField {
    if (!this.symbolFieldMap.has(symbol)) {
      return this.createField('FORMULA_INPUT', null, symbol);
    }

    return this.symbolFieldMap.get(symbol);
  }

  public saveField(field, value, inputFields?: []) {
    if (field.symbol !== value.symbol) {
      this.symbolFieldMap.delete(field.symbol);
    }

    const existingSymbolField = this.symbolFieldMap.get(value.symbol);
    if (existingSymbolField) {
      // TODO validation error
    }

    Object.assign(field, value);

    if (field.type === 'FORMULA') {
      let symbols = this.fieldSymbolsMap.get(field);
      if (!symbols) {
        symbols = new Set<string>();
        this.fieldSymbolsMap.set(field, symbols);
      }
      const removedSymbols = new Set<string>(symbols);
      const addedSymbols = new Set<string>();
      for (const symbol of field.variables) {
        if (removedSymbols.has(symbol)) {
          removedSymbols.delete(symbol);
        } else {
          addedSymbols.add(symbol);
        }
      }
      for (const symbol of removedSymbols) {
        let fields = this.symbolFieldsMap.get(symbol);
        if (!fields) {
          fields = new Set<any>();
          this.symbolFieldsMap.set(symbol, fields);
        }
        symbols.delete(symbol);
        fields.delete(field);
        if (!fields.size) {
          const inputFieldIndex = this.fields.findIndex(f => f.type === 'FORMULA_INPUT' && f.symbol === symbol);
          this.fields.splice(inputFieldIndex, 1);
        }
      }
      for (const symbol of addedSymbols) {
        let fields = this.symbolFieldsMap.get(symbol);
        if (!fields) {
          fields = new Set<any>();
          this.symbolFieldsMap.set(symbol, fields);
        }
        symbols.add(symbol);
        fields.add(field);
      }
    }

    const index = field ? this.fields.indexOf(field) : -1;
    if (index < 0) {
      this.fields.push(field);
    }

    if (field.symbol) {
      this.symbolFieldMap.set(field.symbol, field);
    }

    this.doSetField(this.createField(null));
  }

  validate(submit?: boolean): boolean {
    const validationResult = this.validateForm(submit);
    if (!validationResult) {
      this.toastr.error($localize`Form contains errors`);
      throw new Error('form-invalid');
    }
    return validationResult;
  }

  async save(): Promise<QuestionnaireDetail> {
    await this.checkAndConfirmFieldDiscard();
    await this.getTenantSelection();

    this.validate(true);

    const saveResult = this.saveQuestionnaire();
    if (!saveResult) {
      throw new Error('saving-failed');
    }

    return saveResult.pipe(
      takeUntil(this.ngDestroy),
      tap((response) => {
        this.formGroup.reset(this.formGroup.getRawValue());
        this.setQuestionnaire(response);
        if (this.type === 'FORMULA_SOURCE_TEMPLATE') {
          this.toastr.success($localize`Formula template saved`);
        } else {
          this.toastr.success($localize`Questionnaire saved`);
        }
      }),
      catchError(e => {
        if (this.type === 'FORMULA_SOURCE_TEMPLATE') {
          if (e.error && e.error.errorCode) {
            this.toastr.error($localize`Saving formula template failed (${e.error.errorCode})`);
          } else {
            this.toastr.error($localize`Saving formula template failed`);
          }
        } else {
          if (e.error && e.error.errorCode) {
            this.toastr.error($localize`Saving questionnaire failed (${e.error.errorCode})`);
          } else {
            this.toastr.error($localize`Saving questionnaire failed`);
          }
        }
        return throwError(e);
      })
    ).toPromise();
  }

  async respond(): Promise<QuestionnaireResponseDetail> {

    return this.questionnaireResponseApi.getResponse(this.questionnaire.id).pipe(
      takeUntil(this.ngDestroy),
      switchMap((questionnaireResponse: QuestionnaireResponseDetail) => {
        return this.askResponse(questionnaireResponse);
      }),
      switchMap((questionnaireResponse: QuestionnaireResponseDetail) => {
        const dataRequest = {
          name: null,
          questionnaireId: this.questionnaire.id,
          status: 'SUBMITTED',
          fields: []
        };
        if (questionnaireResponse && questionnaireResponse.id != null) {
          return this.questionnaireResponseApi.extendResponse(questionnaireResponse.id, dataRequest);
        }

        return this.questionnaireResponseApi.createResponse(dataRequest);
      }),
      catchError((e) => {
        if (e.error && e.error.errorCode) {
          this.toastr.error($localize`Creating formula(s) failed (${e.error.errorCode})`);
        } else {
          this.toastr.error($localize`Creating formula(s) failed`);
        }
        return e;
      }),
      tap((response: QuestionnaireResponseDetail) => {
        this.toastr.success($localize`Formula(s) saved`);
      })
    ).toPromise();
  }

  private askResponse(questionnaireResponse: QuestionnaireResponseDetail): Observable<QuestionnaireResponseDetail> {
    if (questionnaireResponse && questionnaireResponse.id) {
      return new Observable<QuestionnaireResponseDetail>(subscriber => {
        const modalRef = this.modalService.show(ConfirmModalComponent, {
          ignoreBackdropClick: true,
          initialState: {
            description: $localize`Current formulas has datapoints. Are you sure you want to create new?`,
            okLabel: $localize`Yes, create new`,
            cancelLabel: $localize`No, update existing ones`,
            callback: (confirm: boolean) => {
              subscriber.next(confirm ? null : questionnaireResponse);
              subscriber.complete();
            }
          }
        });
      });
    } else {
      return of(null);
    }
  }

  private async getTenantSelection(): Promise<void> {
    if (!this.formGroup.get('tenantId').value) {
      const tenant = await this.tenantSelectService.selectTenant().pipe(takeUntil(this.ngDestroy)).toPromise();
      this.formGroup.get('tenantId').setValue(tenant.id);
    }
  }

  public validateForm(submit?: boolean): boolean {
    if (submit) {
      this.formGroup.get('tenantId').setValidators([Validators.required]);
    }
    this.formGroup.markAllAsTouched();
    this.formGroup.updateValueAndValidity();

    this.formGroup.get('tenantId').setValidators([]);

    return !this.formGroup.invalid;
  }

  saveQuestionnaire(): Observable<QuestionnaireDetail> {
    if (!this.formGroup.touched) {
      return of(this.questionnaire);
    }

    const toForkJoin: Observable<any>[] = [];
    for (const fileControl of this.filesArray.controls) {
      const fileRef: FileRef = fileControl.get('fileRef').value;
      if (fileRef && fileRef.upload$) {
        toForkJoin.push(fileRef.upload$);
      }
    }

    forkJoin(toForkJoin.length ? toForkJoin : of([])).subscribe();

    const value = this.formGroup.getRawValue();

    const postDto = {
      tenantId: value.tenantId,
      type: this.type !== 'FORMULA_SOURCE_TEMPLATE' ? value.typeEnergyManager ? 'ENERGY_MANAGER' : 'OTHER' : 'FORMULA_SOURCE_TEMPLATE',
      name: value.name ? value.name.trim() : value.name,
      description: value.description ? value.description.trim() : value.description,
      publicParticipation: value.publicParticipation,
      files: value.files.map((e, i) => ({id: e.id, fileId: e.fileRef.id})),
      fields: this.fields.map((e, i) => (Object.assign({}, e, {
        sortOrder: i,
        responseType: e.responseType,
        value: e.value,
        selectOptions: e.valueType === 'SELECT' ? e.selectOptions : [],
        personParameterId: e.personParameter ? e.personParameter.id : null,
        personSeriesId: e.personSeries ? e.personSeries.id : null,
        windowOperationType: e.windowOperationType,
        windowPeriodType: e.windowOperationType === null ? null : e.windowPeriodType,
        periodCount: e.periodCount,
        required: e.required,
        defaultValue: e.required ? null : e.defaultValue
      })))
    };

    let ret: Observable<QuestionnaireDetail>;
    if (this.questionnaire && this.questionnaire.id) {
      ret = this.http.put<QuestionnaireDetail>(this.config.apiUrl + '/questionnaire/' + this.questionnaire.id, postDto);
    } else {
      ret = this.http.post<QuestionnaireDetail>(this.config.apiUrl + '/questionnaire', postDto);
    }

    return ret;
  }

  mapFieldFormValueToField(value: any): any {
    return Object.assign({}, value, {
      responseType: value.responseTypeCommon ? 'COMMON' : 'RESPONSE',
      required: !value.optional,
      value: value.value && typeof (value.value) === 'string' ? value.value.trim().replace(',', '.') : value.value,
      selectOptions: value.selectOptions ? value.selectOptions.map(e => ({
        name: typeof (e.name) === 'string' ? e.name.trim() : e.name,
        value: e.value,
        status: e.status
      })).filter(e => e.name || e.value) : value.selectOptions,
      windowOperationType: value.windowOperationType === 'LATEST' ? null : value.windowOperationType,
      periodCount: value.windowPeriodType === 'LATEST' ? null : value.periodCount && typeof (value.periodCount) === 'string' ? Number(value.periodCount.trim().replace(',', '.')) : value.periodCount
    });
  }

}
