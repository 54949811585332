import {Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {BsModalService} from 'ngx-bootstrap/modal';
import {ComponentLoaderFactory} from 'ngx-bootstrap/component-loader';
import {
  ConfirmModalComponent,
  Location,
  LocationService,
  PageResponse,
  Questionnaire,
  ResizeService,
  SCREEN_SIZE
} from '@smartencity/core';
import {Subject} from 'rxjs/internal/Subject';
import {HttpClient} from '@angular/common/http';
import {SMARTENCITY_MYDATA_CONFIG} from '../../injection-tokens';
import {MyDataConfig} from '../../mydata-config.model';
import {map, takeUntil} from 'rxjs/operators';
import {TenantSelectService} from '../../services/tenant-select.service';
import {ActivatedRoute, Router} from '@angular/router';
import {QuestionnairesListService} from './questionnaires-list.service';
import {ToastrService} from 'ngx-toastr';
import {NavbarService} from '../navbar/navbar.service';
import {ViewModalComponent} from './view-modal/view-modal.component';
import {DatatableComponent} from '@swimlane/ngx-datatable';
import {QuestionnaireService} from '../../services/questionnaire.service';

const selector = 'mydata-my-questionnaires';
let nextId = 0;

@Component({
  selector: selector,
  templateUrl: './my-questionnaires.component.html',
  providers: [BsModalService, ComponentLoaderFactory, QuestionnairesListService]
})
export class MyQuestionnairesComponent implements OnInit, OnDestroy {
  id = `${selector}-${nextId++}`;
  private ngDestroy = new Subject<void>();

  @ViewChild('navActions', {static: true})
  public actionsTemplate: TemplateRef<any>;

  @ViewChild('table') table: DatatableComponent;
  public columns = {
    detailToggler: true,
    name: true,
    results: true,
    status: true,
    updatedAt: true,
    actions: true
  };

  public filterForm = this.questionnairesListService.filterForm;

  public page: PageResponse<Questionnaire>;
  public defaultLimit = this.questionnairesListService.defaultLimit;
  public defaultSorts = this.questionnairesListService.defaultSorts;

  rows$ = this.questionnairesListService.questionnaires$.pipe(
    map((data: PageResponse<Questionnaire>) => this.mapPageResultsToRows(data)),
    takeUntil(this.ngDestroy)
  );

  rows: any[];

  public myQuestionnairesEnabled: boolean;

  constructor(
    @Inject(SMARTENCITY_MYDATA_CONFIG) private config: MyDataConfig,
    private navbarService: NavbarService,
    private http: HttpClient,
    private questionnairesListService: QuestionnairesListService,
    private modalService: BsModalService,
    private toastr: ToastrService,
    private tenantSelectService: TenantSelectService,
    private router: Router,
    private route: ActivatedRoute,
    private locationService: LocationService,
    private resizeService: ResizeService,
    private questionnaireService: QuestionnaireService
  ) {
    this.questionnaireService.canCreateQuestionnaire().subscribe((result: boolean) => {
      this.myQuestionnairesEnabled = result;
    });

    this.rows$.pipe(takeUntil(this.ngDestroy)).subscribe((rows) => {
      this.rows = rows;
    });

    this.resizeService.onResize$.pipe(takeUntil(this.ngDestroy)).subscribe(this.adjustColumns.bind(this));

    this.questionnairesListService.load();
  }

  ngOnDestroy(): void {
    this.navbarService.setActionsTemplate(null);
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  ngOnInit() {
    this.navbarService.setActionsTemplate(this.actionsTemplate);
  }

  mapPageResultsToRows(data: PageResponse<Questionnaire>) {
    this.page = data;
    const rows = [];
    for (const questionnaire of data.content) {
      let completedCount = 0;
      for (const participant of questionnaire.participants) {
        if (participant.status !== 'PENDING' && participant.status !== 'IN_PROGRESS') {
          completedCount++;
        }
      }
      rows.push({
        id: questionnaire.id,
        questionnaireId: questionnaire.id,
        questionnaire: questionnaire,
        participant: null,
        name: questionnaire.name,
        participantCount: questionnaire.participants.length + questionnaire.emailInvitations.filter(i => i.status === 'NO_USER').length,
        completedCount: completedCount,
        status: questionnaire.status,
        updatedAt: questionnaire.updatedAt,
        containsQuestions: this.containsQuestions(questionnaire),
        containsFormulas: this.containsFormulas(questionnaire)
      });
    }

    return rows;
  }

  setSorts(event: any) {
    this.questionnairesListService.sorts$.next(event.sorts);
  }

  openQuestionnaire(questionnaire: Questionnaire): void {
    if (questionnaire.status === 'DRAFT') {
      this.editQuestionnaire(questionnaire);
    } else {
      this.viewResults(questionnaire);
    }
  }

  editQuestionnaire(questionnaire: Questionnaire): void {
    this.router.navigate([questionnaire.id], { relativeTo: this.route });
  }

  copyQuestionnaire(questionnaire: Questionnaire): void {
    this.router.navigate(['add'], {relativeTo: this.route, state: {copyFromId: questionnaire.id}});
  }

  deleteQuestionnaire(questionnaire: Questionnaire): void {
    const modalRef = this.modalService.show(ConfirmModalComponent, {
      ignoreBackdropClick: true,
      initialState: {
        description: $localize`Are you sure you want to delete the questionnaire?`,
        callback: (confirm: boolean) => {
          if (confirm) {
            this.http
              .delete(this.config.apiUrl + '/questionnaire/' + questionnaire.id)
              .pipe(takeUntil(this.ngDestroy))
              .subscribe(() => {
                this.toastr.success($localize`Questionnaire deleted`);
                this.questionnairesListService.load();
              }, (error) => {
                this.toastr.error($localize`Questionnaire deletion failed`);
                console.error(error);
              });
          }
        }
      }
    });
  }

  public viewResults(questionnaire: Questionnaire) {
    const modalRef = this.modalService.show(ViewModalComponent, {
      ignoreBackdropClick: true,
      class: 'modal-xl',
      initialState: {
        questionnaireId: questionnaire.id
      }
    });
  }

  public containsQuestions(questionnaire: Questionnaire): boolean {
    for (const field of questionnaire.fields) {
      if (field.type === 'QUESTION') {
        return true;
      }
    }
    return false;
  }

  public containsFormulas(questionnaire: Questionnaire): boolean {
    for (const field of questionnaire.fields) {
      if (field.type === 'FORMULA' || field.type === 'FORMULA_INPUT') {
        return true;
      }
    }
    return false;
  }

  public viewDatapoints(questionnaire): void {
    if (!questionnaire.personSeriesGroup) {
      return;
    }

    this.locationService.navigate(Location.ofQ11e(questionnaire.personSeriesGroup, {
      id: questionnaire.id,
      type: questionnaire.type,
      name: questionnaire.name
    }));
  }

  toggleExpandRow(row) {
    this.table.rowDetail.toggleExpandRow(row);
  }

  clearSearch() {
    this.filterForm.get('query').setValue('');
  }

  edit(questionnaire: Questionnaire) {
    this.router.navigate([questionnaire.id], {relativeTo: this.route});
  }

  private adjustColumns(size): void {
    if (size != null && size < SCREEN_SIZE.MD) {
      this.columns.detailToggler = true;
      this.columns.name = true;
      this.columns.results = false;
      this.columns.status = false;
      this.columns.updatedAt = false;
      this.columns.actions = false;
    } else {
      this.columns.detailToggler = false;
      this.columns.name = true;
      this.columns.results = true;
      this.columns.status = true;
      this.columns.updatedAt = true;
      this.columns.actions = true;
      if (this.table) {
        this.table.rowDetail.collapseAllRows();
      }
    }
  }

}
