import {Component, OnInit, NgZone, Input, OnDestroy, OnChanges, SimpleChanges, ViewChild, TemplateRef} from '@angular/core';
import {NoiseLevelHeatmapService} from './noise-level-heatmap.service';
import {NoiseLevelsHistogram, NoiseLevel, NoiseLevelsPage} from './noise-level-heatmap.model';
import {DatePipe} from '@angular/common';
import MapsEventListener = google.maps.MapsEventListener;
import {NoiseLevelOverlayView} from '../../layers/noise-level-overlay-view';

@Component({
  selector: 'tallinn-noise-level-heatmap',
  templateUrl: './noise-level-heatmap.component.html',
  providers: [
    NoiseLevelHeatmapService,
    DatePipe
  ]
})
export class NoiseLevelHeatmapComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  map: google.maps.Map;

  @ViewChild('playbackTemplate', {static: true})
  public playbackTemplate: TemplateRef<any>;

  public noiseLevelLayer: NoiseLevelOverlayView;

  zoomMap: any = {
    1: 1, 2: 1, 3: 1,
    4: 2, 5: 2,
    6: 4, 7: 4,
    8: 5, 9: 5,
    10: 7, 11: 7,
    12: 9,
    13: 12, 14: 12,
    15: 15, 16: 15,
    17: 20, 18: 20,
    19: 38, 20: 40
  };

  heatmap: google.maps.visualization.HeatmapLayer;

  personSeriesList: NoiseLevel[] = [];

  public pageSize = 150;
  public currentPage;
  public totalPages;

  public histogram: NoiseLevelsHistogram = null;
  public playValue = 48;
  public isPlaying = false;

  public mapEventIdle: MapsEventListener;
  public mapEventZoom: MapsEventListener;

  constructor(
    public noiseLevelHeatmapService: NoiseLevelHeatmapService,
    public zone: NgZone
  ) {

  }

  ngOnInit() {
    const conf = this.noiseLevelHeatmapService.getMapsConfig();

    this.heatmap = new google.maps.visualization.HeatmapLayer({
      data: [],
      dissipating: true,
      radius: 0,
      maxIntensity: 155,
      map: this.map
    });

    this.loadHistogram();

    this.loadItems(0);
  }

  ngOnDestroy(): void {
    this.setMap(null);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.map) {
      this.setMap(this.map);
    }
  }

  setMap(map: google.maps.Map) {
    if (this.mapEventIdle) {
      this.mapEventIdle.remove();
    }
    if (this.mapEventZoom) {
      this.mapEventZoom.remove();
    }
    if (this.heatmap) {
      this.heatmap.setMap(map);
    }
    if (this.noiseLevelLayer) {
      this.noiseLevelLayer.setMap(map);
    }
  }

  loadItems(iPage: number) {
    this.noiseLevelHeatmapService.getNoiseLevels({size: this.pageSize, page: iPage, sort: 'personSeriesId'}).subscribe(
      (page: NoiseLevelsPage) => {
        this.totalPages = page.totalPages;
        this.currentPage = page.page;

        this.personSeriesList = this.personSeriesList.concat(page.content);

        if (this.totalPages > this.currentPage) {
          this.loadItems(this.currentPage + 1);
        } else {
          this.updateSensors();
          this.updateHeatmap();
        }
      },
      (err: any) => {
      }
    );
  }

  updateSensors() {
    if (this.noiseLevelLayer) {
      this.noiseLevelLayer.setMap(null);
    }
    this.noiseLevelLayer = new NoiseLevelOverlayView(this.personSeriesList);
    this.noiseLevelLayer.setMap(this.map);
  }

  updateHeatmap() {
    const heatmapData = this.personSeriesList.map((item: NoiseLevel) => {
      return {
        location: new google.maps.LatLng(item.lat, item.lng),
        weight: item.value,
        id: 1
      };
    });
    this.heatmap.setData(heatmapData);
    if (this.noiseLevelLayer) {
      this.noiseLevelLayer.draw();
    }
  }

  loadHistogram() {
    this.noiseLevelHeatmapService.getNoiseLevelsHistogram().subscribe(
      (data: NoiseLevelsHistogram) => {
        this.histogram = data;
      }
    );
  }

  togglePlay() {
    if (this.isPlaying === true) {
      this.isPlaying = false;
    } else {
      this.isPlaying = true;
      if (this.playValue === 48) {
        this.playValue = 0;
      }
      this.playFrame();
    }
  }

  playFrame() {
    this.loadFrame(this.playValue, () => {
      setTimeout(() => {
        if (this.isPlaying) {
          this.playValue = this.playValue + 1;
          if (this.playValue === 48) {
            this.isPlaying = false;
          } else {
            this.playFrame();
          }
        }
      }, 500);
    });
  }

  playChanged(index) {
    this.playValue = index;
      this.loadFrame(this.playValue, () => {
    });
  }

  loadFrame(index, cb) {
    const histItem = this.histogram.content[index];
    this.noiseLevelHeatmapService.getNoiseLevelState({date: histItem.date}).subscribe(stateResponse => {
      const state = stateResponse.state;

      for (const ps of this.personSeriesList) {
        ps.value = state[ps.id];
      }

      this.updateHeatmap();
      cb();
    });
  }

}
