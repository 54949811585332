<ng-template #navActions>
  <button type="button" class="btn btn-primary btn-sm d-none d-lg-inline-block" (click)="create()">
    <i class="fal fa-plus"></i>&nbsp;<ng-container i18n>Add new</ng-container>
  </button>
  <div class="btn-floating-mobile-container">
    <button type="button" class="btn btn-primary btn-sm btn-floating-mobile" (click)="create()">
      <i class="fal fa-plus fa-2x"></i>
    </button>
  </div>
</ng-template>

<div class="page-title-bar p-4">
  <div class="d-flex">
    <div class="co-auto pl-0">
      <a routerLink=".." class="text-nowrap"><i class="fal fa-angle-left"></i>&nbsp;<ng-container i18n>Back</ng-container></a>
    </div>
    <div class="col d-flex flex-column justify-content-center align-items-center">
      <h2 class="mb-1">{{location?.getName()}}<ng-container *ngIf="location?.getName()">&nbsp;<i class="fal fa-angle-right"></i>&nbsp;</ng-container><ng-container i18n>Parameters</ng-container></h2>
      <span class="text-light">
        <ng-container i18n>{totalElements, plural, =1 {{{totalElements}} parameter} other {{{totalElements}} parameters}}</ng-container>
      </span>
    </div>
    <div class="col-auto pr-0"></div>
  </div>
</div>

<div class="container-fluid p-4">
  <div class="row justify-content-center mb-3 mb-lg-5" [formGroup]="searchForm">
    <div class="col-sm-12 col-md-6 col-lg-4">
      <div class="input-group input-group-lg form-search">
        <input formControlName="query" type="text" class="form-control form-control-lg" placeholder="Type to search..." i18n-placeholder>
        <div class="input-group-append">
          <span class="clear-search" (click)="clearSearch()" [ngClass]="{'active': searchForm.get('query')?.value?.length > 0 }">
            <i class="fal fa-times"></i>
          </span>
          <!-- <div class="custom-dropdown filter-dropdown" dropdown [autoClose]="true" (click)="$event.stopImmediatePropagation();">
            <button type="button" class="btn dropdown-toggle" dropdownToggle i18n>Filter</button>
            <div class="dropdown-menu dropdown-menu-right" *dropdownMenu>
              <div class="accordion-group"  [ngClass]="'show'">
                <div class="accordion-header">
                  <ng-container i18n>Some filterable data?</ng-container>
                </div>
                <div class="accordion-content">
                </div>
              </div>
            </div>
          </div> -->
          <span class="input-group-text">
            <i class="fal fa-search"></i>
          </span>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <ngx-datatable
        #table
        class="material expandable"
        [draggable]="false"
        [columnMode]="'flex'"
        [headerHeight]="30"
        [footerHeight]="50"
        [reorderable]="false"
        rowHeight="auto"
        [scrollbarH]="false"
        [scrollbarV]="false"
        [rows]="rows"
        [externalPaging]="false"
        [externalSorting]="false"
      >
        <ngx-datatable-row-detail [rowHeight]="170" #detailRow>
          <ng-template
            let-row="row"
            let-value="row.value"
            let-unit="row.unit"
            let-updatedAt="row.updatedAt"
            ngx-datatable-row-detail-template
          >
            <div style="font-size:14px; padding: 0.7rem">
              <div class="row">
                <div class="col-6"><label i18n>Parameter Name</label></div>
                <div class="col-6">{{row.name}}</div>
              </div>

              <div class="row">
                <div class="col-6"><label i18n>Value</label></div>
                <div class="col-6">
                  <span class="text-danger font-italic" *ngIf="(row?.id && value == null) || row?.required" i18n>Missing value</span>
                  <ng-container *ngIf="value != null">{{value}}</ng-container>
                </div>
              </div>

              <div class="row">
                <div class="col-6"><label i18n>Unit</label></div>
                <div class="col-6">{{unit}}</div>
              </div>

              <div class="row">
                <div class="col-6"><label i18n>Last updated</label></div>
                <div class="col-6">{{updatedAt | date:'dd. MMMM yyyy'}}</div>
              </div>

              <div class="row pt-3">
                <button type="button" class="btn btn-sm btn-link no-caret" (click)="createForType(row?.type)" *ngIf="!row?.id">
                  <i class="fal fa-edit mr-2"></i>
                  <ng-container i18n>Edit</ng-container>
                </button>
                <button type="button" class="btn btn-sm btn-link no-caret" (click)="edit(row)" *ngIf="row?.id">
                  <i class="fal fa-edit mr-2"></i>
                  <ng-container i18n>Edit</ng-container>
                </button>
                <button type="button" class="btn btn-sm btn-link no-caret" (click)="delete(row)">
                  <i class="fal fa-trash mr-2"></i>
                  <ng-container i18n>Delete</ng-container>
                </button>
              </div>
            </div>
          </ng-template>
        </ngx-datatable-row-detail>

        <ngx-datatable-column *ngIf="columns.name" name="Parameter Name" i18n-name prop="name" [flexGrow]="1" [resizeable]="false" [sortable]="true">
          <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>{{value}}</ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column *ngIf="columns.value" name="Value" i18n-name prop="value" [flexGrow]="1" [resizeable]="false" [sortable]="false">
          <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
            <span class="text-danger font-italic" *ngIf="(row?.id && value == null) || row?.required" i18n>Missing value</span>
            <ng-container *ngIf="value != null">{{value}}</ng-container>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column *ngIf="columns.unit" name="Unit" i18n-name prop="unit" [flexGrow]="1" [resizeable]="false" [sortable]="false">
          <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>{{value}}</ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column *ngIf="columns.updatedAt" name="Last updated" i18n-name prop="updatedAt" [flexGrow]="1" [resizeable]="false" [sortable]="true">
          <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>{{value | date:'dd. MMMM yyyy'}}</ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column *ngIf="columns.actions" name="" [flexGrow]="1" [resizeable]="false" [sortable]="false" [cellClass]="'justify-content-end'">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <div class="btn-group btn-more" dropdown>
              <button type="button" class="btn btn-icon dropdown-toggle" dropdownToggle><i class="far fa-ellipsis-v"></i></button>
              <div class="dropdown-menu dropdown-menu-right" *dropdownMenu>
                <a class="dropdown-item" (click)="createForType(row?.type)" *ngIf="!row?.id"><i class="fal fa-edit"></i> <ng-container i18n>Edit</ng-container></a>
                <a class="dropdown-item" (click)="edit(row)" *ngIf="row?.id"><i class="fal fa-edit"></i> <ng-container i18n>Edit</ng-container></a>
                <a class="dropdown-item" (click)="delete(row)"><i class="fal fa-trash"></i> <ng-container i18n>Delete</ng-container></a>
              </div>
            </div>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          *ngIf="columns.detailToggler"
          [width]="50"
          [resizeable]="false"
          [sortable]="false"
          [draggable]="false"
          [canAutoResize]="false"
          headerClass="justify-content-end"
          cellClass="justify-content-end"
        >
          <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
            <a
              [routerLink]="null"
              [class.datatable-icon-right]="!expanded"
              [class.datatable-icon-down]="expanded"
              title="Expand/Collapse Row"
              (click)="toggleExpandRow(row)"
            >
            </a>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </div>
  </div>
</div>
