<header>
  <div id="sidebar" class="side-navbar collapse d-lg-flex">
    <div #sidebar class="sidebar-wrapper">
    <div class="modal-header">
      <button type="button" class="close pull-right" data-toggle="collapse" data-target="#sidebar" aria-controls="sidebar" aria-expanded="false" aria-label="Close">
        <span class="fal fa-times"></span>
      </button>
    </div>

    <ng-container *ngIf="!config.noLandingPageText">
      <div class="logo-wrapper">
        <a routerLink="/">
          <img [src]="logoImgSrc" class="img-fluid flex-center">
        </a>
      </div>

      <core-language-select *ngIf="config.theme != 'greengas'"></core-language-select>
    </ng-container>


    <!-- Side navigation links -->
    <ul class="list-unstyled nav">
      <ng-container *ngFor="let moduleName of config.modules.names">
        <li class="nav-item" routerLink="/tallinn" routerLinkActive="active" *ngIf="moduleName === 'tallinn'">
          <a class="nav-link">
            <i class="icon icon-environment"></i>
            <span>{{moduleName|moduleLabel:'Environment'}}</span>
          </a>
        </li>
        <li class="nav-item" routerLink="/buildings" routerLinkActive="active" *ngIf="moduleName === 'buildings'">
          <a class="nav-link">
            <i class="icon icon-building"></i>
            <span>{{moduleName|moduleLabel:'Buildings'}}</span>
          </a>
        </li>
        <li class="nav-item" routerLink="/gas" routerLinkActive="active" *ngIf="moduleName === 'gas'" (click)="resizeMap()">
          <a class="nav-link">
            <i class="icon icon-park"></i>
            <span>{{moduleName|moduleLabel:'All stations'}}</span>
          </a>
        </li>
        <li class="nav-item" routerLink="/services" routerLinkActive="active" *ngIf="moduleName === 'services'">
          <a class="nav-link">
            <i class="icon icon-city-items"></i>
            <span>{{moduleName|moduleLabel:'City services'}}</span>
          </a>
        </li>
        <li class="nav-item" routerLink="/mobility" routerLinkActive="active" *ngIf="moduleName === 'mobility'">
          <a class="nav-link">
            <i class="icon icon-mobility"></i>
            <span>{{moduleName|moduleLabel:'Mobility'}}</span>
          </a>
        </li>
        <li class="nav-item" routerLink="/lights" routerLinkActive="active" *ngIf="moduleName === 'lights'">
          <a class="nav-link">
            <i class="icon icon-lights"></i>
            <span>{{moduleName|moduleLabel:'Lights'}}</span>
          </a>
        </li>
        <li class="nav-item" routerLink="/weather" routerLinkActive="active" *ngIf="moduleName === 'weather'">
          <a class="nav-link">
            <i class="icon icon-weather"></i>
            <span>{{moduleName|moduleLabel:'Weather'}}</span>
          </a>
        </li>
        <li class="nav-item" routerLink="/people" routerLinkActive="active" *ngIf="moduleName === 'people'">
          <a class="nav-link">
            <i class="icon icon-people"></i>
            <span>{{moduleName|moduleLabel:'People'}}</span>
          </a>
        </li>
        <li class="nav-item" routerLink="/arcgis" routerLinkActive="active" *ngIf="moduleName === 'arcgis'">
          <a class="nav-link">
            <i class="icon icon-arcgis"></i>
            <span>ArcGIS</span>
          </a>
        </li> 
        <li class="nav-item" routerLink="/publicdata" routerLinkActive="active" *ngIf="moduleName === 'publicdata'">
          <a class="nav-link">
            <i class="icon icon-publicdata"></i>
            <span>{{moduleName|moduleLabel:'Public data'}}</span>
          </a>
        </li>        
        <li class="nav-item" routerLink="/meteorology" routerLinkActive="active" *ngIf="moduleName === 'meteorology'">
          <a class="nav-link">
            <i class="icon icon-weather"></i>
            <span>{{moduleName|moduleLabel:'Meteorology'}}</span>
          </a>
        </li>
      </ng-container>

      <li class="nav-item green-item" routerLink="/mydata" routerLinkActive="active">
        <a class="nav-link">
          <i class="icon icon-data"></i>
          <span>{{'my-data'|moduleLabel:'My data'}}</span>
        </a>
      </li>
    </ul>
  <!--/. Side navigation links -->
  </div>
  </div>
</header>

