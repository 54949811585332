<div class="container-fluid" id="city-portal-info" *ngIf="currentMandate">
  <div class="row">
    <div class="col-md-12 mt-4">
      <h3 class="section-title text-black mb-3" i18n>Your City Portal Information</h3>
      <div class="row mb-4">
        <div class="col-auto">
          <button class="btn btn-sm btn-danger" (click)="deleteAccount()" i18n>Delete My Account</button>
        </div>
        <div class="col">
          <p class="m-0 font-italic" i18n>Permanently delete your City Portal account and data</p>
        </div>
      </div>
      <div class="row">
        <div class="col-auto">
          <button class="btn btn-sm btn-outline-primary" (click)="restoreAccount()" i18n>Restore my data</button>
        </div>
        <div class="col">
          <p class="m-0 font-italic"  i18n>Use your restore token to restore previously deleted account data</p>
        </div>
      </div>
    </div>
  </div>