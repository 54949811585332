import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {GroupLocation, Location, LocationResponse} from '../models/location';
import {SMARTENCITY_CORE_CONFIG} from '../injection-tokens';
import {CoreConfig} from '../core-config.model';
import {map} from 'rxjs/operators';
import {Router} from '@angular/router';
import {LocationStrategy} from '@angular/common';
import {UrlParamsHelper} from '../helpers/url-params-helper';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  constructor(
    @Inject(SMARTENCITY_CORE_CONFIG) private config: CoreConfig,
    private http: HttpClient,
    private router: Router,
    private locationStrategy: LocationStrategy
  ) {

  }

  public getGroupLocations(params: any): Observable<GroupLocation[]> {

    return this.http.get<any[]>(this.config.apiUrl + '/group-location', {
      params: UrlParamsHelper.cleanParams(params)
    }).pipe(map((groupLocations: any[]) => {
      return groupLocations.map((groupLocation) => {
        let group = {
          id: null,
          name: null
        };

        if (groupLocation.id) {
          group = {
            id: groupLocation.id,
            name: groupLocation.name
          };
        }

        return new GroupLocation({
          group: group,
          type: groupLocation.type,
          groupOwnerType: groupLocation.groupOwnerType,
          personSeriesCount: groupLocation.personSeriesCount,
          ownerPersonSeriesCount: groupLocation.ownerPersonSeriesCount,
          sharedPersonSeriesCount: groupLocation.sharedPersonSeriesCount,
          createdByPerson: groupLocation.createdByPerson
        });
      });
    }));

  }

  public getCityPortalHost(): string {
    let url = window.location.protocol + "//" + window.location.host + this.locationStrategy.getBaseHref();
    url = url.replace(/\/$/, ""); //remove trailing slash

    return url;
  }

  getLocations(query?: any): Observable<Location[]>  {
    const params: any = {};
    if (query) {
      params.query = JSON.stringify(query);
    }
    return this.http.get<LocationResponse>(this.config.apiUrl + '/location', {
      params: params
    }).pipe(map((data) => data.content.map(l => new Location(l))));
  }

  getSubLocations(query?: any): Observable<Location[]>  {
    const params: any = {};
    if (query) {
      params.query = JSON.stringify(query);
    }
    return this.http.get<LocationResponse>(this.config.apiUrl + '/sub-location', {
      params: params
    }).pipe(map((data) => data.content.map(l => new Location(l))));
  }

  getLocationNavigateCommands(location: Location): any[] {
    const path = ['/mydata/datapoints'];
    if (location.group) {
      path.push('group');
      if (location.group.id) {
        path.push('' + location.group.id);
      }
    } else if (location.groupingTag != null) {
      path.push('grouping-tag');
      if (location.groupingTag) {
        path.push(location.groupingTag);
      }
    }

    switch (location.locationType) {
      case 'ADDRESS':
        path.push('address');
        path.push(location.address);
        if (location.room || location.apartment) {
          path.push(location.apartment);
        }
        if (location.room) {
          path.push(location.room);
        }
        break;
      case 'COORDS':
        path.push('coords');
        path.push('' + location.lat);
        path.push('' + location.lng);
        break;
      case 'UUID':
        path.push('uuid');
        path.push('' + location.uuid);
        break;
    }

    if (location.q11eId) {
      path.push('q11e');
      path.push('' + location.q11eId);
    }

    return path;
  }

  navigate(location: Location, state?: any): Promise<boolean> {
    const path = this.getLocationNavigateCommands(location);
    if (!state) {
      state = {};
    }
    state.location = location;
    this.router.navigated = false; // lae komponent uuesti samale route-ile navigeerides
    return this.router.navigate(path, {state: state});
  }

}
