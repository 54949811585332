import {Injectable, OnDestroy, TemplateRef} from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';
import {shareReplay} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MandateNavbarService implements OnDestroy {

  private showLogsActionSource = new ReplaySubject<TemplateRef<any>>(1);

  public showLogsAction$: Observable<TemplateRef<any>> = this.showLogsActionSource.asObservable().pipe(shareReplay(1));

  constructor() {
  }

  ngOnDestroy(): void {
    this.showLogsActionSource.complete();
  }

  public setShowLogsActionTemplate(template: TemplateRef<any>): void {
    this.showLogsActionSource.next(template);
  }

}
