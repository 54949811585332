<div class="d-flex">
  <div class="row d-flex no-gutters w-100 position-relative">
    <div id="tooltip"></div>
    <div class="map-container" style="width: 100%;">
      <div id="map" class="map"></div>
    </div>

    <div class="floating-wrapper flex-column">
      <div class="position-relative">
        <building-layer-filters></building-layer-filters>
        <buildings-objects-modal (modalClose)="onModalClose()"></buildings-objects-modal>
      </div>
    </div>
  </div>
</div>
