<div class="modal-header">
  <h5 class="modal-title w-100" i18n>Manage dashboards</h5>
  <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
    <i class="fal fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <div *ngIf="!loading">
    <div class="row">
      <div class="col text-right">
        <button (click)="addDashboard()" type="button" class="btn btn-sm btn-primary">
          <i class="fal fa-plus"></i>&nbsp;<span i18n>Add new dashboard</span>
        </button>
        <hr/>
      </div>
    </div>
  
    <table *ngIf="personDashboards && personDashboards.length > 0" class="table table-borderless">
      <thead>
        <tr>
          <td style="width: 50px;" *ngIf="config.modules.publicdata && isCityUser" class="p-0 pb-2 col-1 text-left">
            <label class="mb-0 mb-3" i18n>Is public</label>
          </td>
          <td style="width: 50px;" *ngIf="config.modules.publicdata && isCityUser" class="col-1 p-0 pb-2 mb-3 text-center">
            <label class="mb-0" i18n-tooltip tooltip="Allow downloads"><i class="fa fa-download"></i></label>
          </td>
          <td class="p-0 pb-2 mb-3">
            <label class="mb-0" i18n>Dashboard name</label>
          </td>
          <td>&nbsp;</td>
          <td class="col-2 p-0 pb-2 mb-3 text-right">
            <label class="mb-0" i18n>Actions</label>
          </td>
        </tr>
      </thead>
      <tbody>
        <tr app-dashboard-management-row *ngFor="let dashboard of personDashboards; let i = index"
                              [personDashboard]="dashboard"
                              (remove)="dashboardRemoved(dashboard)"
                              (save)="dashboardSaved(dashboard)"
                              [isCityUser]="isCityUser">
          </tr>
      </tbody>
    </table>
    <div *ngIf="(!personDashboards || personDashboards.length == 0) && !loading" class="alert alert-info mt-3" i18n>No dashboards created.</div>
  </div>
  <div *ngIf="loading" class="d-block w-100 text-center"><i class="fas fa-circle-notch fa-spin text-primary"></i></div>
</div>
