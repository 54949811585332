import {AbstractMapLayer, OlMapWrapper} from '@smartencity/core';
import {WaterTap} from '../model/water-tap';
import {WaterTapsOlMarker} from './water-taps-ol-marker';

export class WaterTapsOlLayer extends AbstractMapLayer<OlMapWrapper, WaterTap> {

  private waterTaps: WaterTap[];

  constructor(waterTaps: WaterTap[]) {
    super();
    this.waterTaps = waterTaps;
  }

  setMap(map: OlMapWrapper): void {
    super.setMap(map);
    this.resetMarkers();
    if (map) {
      this.createMarkers();
    }
  }

  protected resetMarkers(): void {
    let markers = this.getMarkers();
    if (markers.length > 0) {
      for (const marker of markers) {
        marker.setMap(null);
      }
    }
    this.clearMarkers();
  }

  protected createMarkers(): void {
    for (const waterTap of this.waterTaps) {
      this.addMarker(new WaterTapsOlMarker(waterTap, this.map));
    }
  }

}
