import {Inject, Injectable, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs/internal/Subject';
import {
  LatestValue,
  PersonSeries, PersonSeriesApiService,
  PersonSeriesPeriod,
  SourceInfo,
  SourceOwnerSeries,
  Threshold
} from '@smartencity/core';
import {map, shareReplay, takeUntil} from 'rxjs/operators';
import {MyDataConfig} from '../mydata-config.model';
import {HttpClient} from '@angular/common/http';
import {SMARTENCITY_MYDATA_CONFIG} from '../injection-tokens';

@Injectable()
export class CurrentDatapointService implements OnDestroy {
  private ngDestroy: Subject<void> = new Subject();

  private sourceOwnerSeries = new Subject<SourceOwnerSeries>();
  private thresholds = new Subject<Threshold[]>();
  public sourceOwnerSeries$ = this.sourceOwnerSeries.asObservable().pipe(takeUntil(this.ngDestroy), shareReplay(1));
  public thresholds$ = this.thresholds.asObservable().pipe(takeUntil(this.ngDestroy), shareReplay(1));
  public activeThresholds$ = this.thresholds$.pipe(map(d => d?.filter(t => t.enabled)), shareReplay(1));
  private consents = new Subject<any[]>();
  public consents$ = this.consents.asObservable().pipe(takeUntil(this.ngDestroy), shareReplay(1));
  public activeConsents$ = this.consents$.pipe(map(d => d?.filter(c => c.status === 'ACTIVE' || c.confirmationStatus === 'PENDING')), shareReplay(1));

  public personSeries: PersonSeries;
  public latestValue: LatestValue;
  public lastPeriod: PersonSeriesPeriod;
  public source: SourceInfo;
  isInComparison = false;

  constructor(
    @Inject(SMARTENCITY_MYDATA_CONFIG) private config: MyDataConfig,
    private http: HttpClient,
    private personSeriesService: PersonSeriesApiService
  ) {
    this.activeThresholds$.pipe(takeUntil(this.ngDestroy)).subscribe();
    this.activeConsents$.pipe(takeUntil(this.ngDestroy)).subscribe();
    this.sourceOwnerSeries$.pipe(takeUntil(this.ngDestroy)).subscribe((sos) => {
      this.personSeries = sos?.personSeries;
      this.latestValue = sos?.latestValue;
      this.lastPeriod = sos?.lastPeriod;
      this.thresholds.next(sos?.thresholds);
      this.consents.next(sos?.consents);
      this.source = sos?.source;
    });
  }

  ngOnDestroy(): void {
    this.sourceOwnerSeries.complete();
    this.thresholds.complete();
    this.consents.complete();
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  setSourceOwnerSeries(sos: SourceOwnerSeries): void {
    this.sourceOwnerSeries.next(sos);
  }

  loadThresholdAnnotations() {
    if (this.lastPeriod.ownerType === 'CONSENT') {
      return;
    }

    this.http.get(this.config.apiUrl + '/person-series/' + this.personSeries.id + '/thresholds').pipe(takeUntil(this.ngDestroy)).subscribe((thresholds: Threshold[]) => {
      this.thresholds.next(thresholds);
    });
  }

  loadConsents() {
    this.personSeriesService.getSeriesConsents(this.personSeries.id).subscribe(
      (data: any[]) => {
        this.consents.next(data);
      },
      (err: any) => {
        this.consents.next([]);
      }
    );
  }
}
