import {Subscription} from 'rxjs/internal/Subscription';
import {Observable} from 'rxjs/internal/Observable';

export class FileDto {
  id: number;
  name: string;
  mimeType: string;
  createdAt: string;
  updatedAt: string;
}

export class FileRef {
  id: number;
  mimeType: string;
  name: string;

  isLoaded: boolean;
  isUploaded: boolean;
  uploadProgress: number;

  dataUrl: string;

  file: File;

  upload$: Observable<any>;

  constructor(fileId?: number) { }
}
