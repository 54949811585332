import {Component, Inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, TemplateRef} from '@angular/core';
import {Subject} from 'rxjs/internal/Subject';
import {
  Location,
  PersonParameterResponse, PreventCloseAware, QuestionnaireField,
  QuestionnaireReference,
  QuestionnaireResponseDetail,
  QuestionnaireResponseFieldDetail,
  SourceOwnerSeriesResponse
} from '@smartencity/core';
import {FormArray, FormControl, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {debounceTime, distinctUntilChanged, filter, map, skip, startWith, switchMap, takeUntil, tap} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import {MyDataConfig} from '../../../mydata-config.model';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {SMARTENCITY_MYDATA_CONFIG} from '../../../injection-tokens';
import {PersonParameterApiService} from '../../../http/person-parameter-api.service';

const selector = 'mydata-response-fields';
let nextId = 0;

@Component({
  selector: selector,
  templateUrl: './response-fields.component.html',
  styleUrls: ['./response-fields.component.css']
})
export class ResponseFieldsComponent implements OnInit, OnDestroy, OnChanges, PreventCloseAware {
  id = `${selector}-${nextId++}`;
  private ngDestroy = new Subject<void>();

  @Input()
  showErrors: boolean;

  @Input()
  public response: QuestionnaireResponseDetail;
  public responseFields: QuestionnaireResponseFieldDetail[];
  public questionnaire: QuestionnaireReference;

  // nameControl = new FormControl(null); // Used for formula templates
  fields: any[] = [];
  fieldArray = new FormArray([]);

  conversionModalRef: BsModalRef;
  pow = Math.pow;

  constructor(
    @Inject(SMARTENCITY_MYDATA_CONFIG) private config: MyDataConfig,
    private http: HttpClient,
    private modalService: BsModalService,
    private personParameterApi: PersonParameterApiService
  ) { }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.response) {
      this.updateFields();
    }
  }

  control(name: string, formGroup: FormGroup) {
    return formGroup.get(name);
  }

  invalid(name: string, formGroup: FormGroup) {
    const control = formGroup.get(name);
    return control && control.invalid && (control.dirty || control.touched);
  }

  errors(name: string, formGroup: FormGroup): ValidationErrors {
    const control = formGroup.get(name);
    return control ? control.errors : null;
  }

  updateFields(): void {
    this.questionnaire = this.response?.questionnaire;
    this.responseFields = this.response?.responseFields;

    while (this.fieldArray.length !== 0) {
      if (this.fieldArray[0] && this.fieldArray[0].typeahead$) {
        this.fieldArray[0].typeahead$.complete();
      }
      if (this.fieldArray[0] && this.fieldArray[0].destroy$) {
        this.fieldArray[0].destroy$.next();
        this.fieldArray[0].destroy$.complete();
      }
      this.fieldArray.removeAt(0);
    }
    this.fields = [];

    if (!this.responseFields) {
      return;
    }

    for (const responseField of this.responseFields) {
      let group = null;
      if (responseField.field.type === 'FORMULA') {
        group = new FormGroup({
          id: new FormControl(responseField.id),
          fieldId: new FormControl(responseField.field.id, [Validators.required]),
          type: new FormControl(null, []),
          fieldType: new FormControl(responseField.field.type, []),
          responseType: new FormControl(responseField.field.responseType, []),
          unit: new FormControl(responseField.unit ? responseField.unit : null, []),
        });
      } else if (responseField.field.valueType === 'TEXT') {
        group = new FormGroup({
          id: new FormControl(responseField.id),
          fieldId: new FormControl(responseField.field.id, [Validators.required]),
          type: new FormControl('TEXT', [Validators.required]),
          fieldType: new FormControl(responseField.field.type, []),
          responseType: new FormControl(responseField.field.responseType, []),
          text: new FormControl(responseField.text, responseField.field.required ? [Validators.required] : []),
          value: new FormControl(null),
          personParameter: new FormControl(null),
          personSeries: new FormControl(null),
          option: new FormControl(null),
          unit: new FormControl(responseField.unit ? responseField.unit : null, []),
          coefficient: new FormControl(responseField.coefficient ? responseField.coefficient : 1, []),
          timeExponent: new FormControl(responseField.timeExponent ? responseField.timeExponent : 0, [Validators.required, Validators.pattern(/^\-?\d+([\.\,]\d+)?$/)]),
          deltaReading: new FormControl(responseField.deltaReading ? responseField.deltaReading : false, [])
        });
      } else if (responseField.field.valueType === 'VALUE') {
        group = new FormGroup({
          id: new FormControl(responseField.id),
          fieldId: new FormControl(responseField.field.id, [Validators.required]),
          type: new FormControl('VALUE', [Validators.required]),
          fieldType: new FormControl(responseField.field.type, []),
          responseType: new FormControl(responseField.field.responseType, []),
          text: new FormControl(null),
          value: new FormControl(responseField.field.responseType === 'COMMON' ? responseField.field.value : responseField.value, responseField.field.required ? [Validators.required, Validators.pattern(/^\-?\d+([\.\,]\d+)?$/)] : [Validators.pattern(/^\-?\d+([\.\,]\d+)?$/)]),
          personParameter: new FormControl(null),
          personSeries: new FormControl(null),
          option: new FormControl(null),
          unit: new FormControl(responseField.unit ? responseField.unit : null, []),
          coefficient: new FormControl(responseField.coefficient ? responseField.coefficient : 1, []),
          timeExponent: new FormControl(responseField.timeExponent ? responseField.timeExponent : 0, [Validators.required, Validators.pattern(/^\-?\d+([\.\,]\d+)?$/)]),
          deltaReading: new FormControl(responseField.deltaReading ? responseField.deltaReading : false, [])
        });
      } else if (responseField.field.valueType === 'PERSON_PARAMETER') {
        group = new FormGroup({
          id: new FormControl(responseField.id),
          fieldId: new FormControl(responseField.field.id, [Validators.required]),
          type: new FormControl('PERSON_PARAMETER', [Validators.required]),
          fieldType: new FormControl(responseField.field.type, []),
          responseType: new FormControl(responseField.field.responseType, []),
          text: new FormControl(null),
          value: new FormControl(null),
          personParameter: new FormControl(responseField.field.responseType === 'COMMON' ? responseField.field.personParameter : responseField.personParameter, responseField.field.required ? [Validators.required] : []),
          personSeries: new FormControl(null),
          option: new FormControl(null),
          unit: new FormControl(responseField.unit ? responseField.unit : null, []),
          coefficient: new FormControl(responseField.coefficient ? responseField.coefficient : 1, [Validators.required, Validators.pattern(/^\-?\d+([\.\,]\d+)?$/)]),
          timeExponent: new FormControl(responseField.timeExponent ? responseField.timeExponent : 0, [Validators.required, Validators.pattern(/^\-?\d+([\.\,]\d+)?$/)]),
          deltaReading: new FormControl(responseField.deltaReading ? responseField.deltaReading : false, [])
        });
      } else if (responseField.field.valueType === 'PERSON_SERIES') {
        group = new FormGroup({
          id: new FormControl(responseField.id),
          fieldId: new FormControl(responseField.field.id, [Validators.required]),
          type: new FormControl('PERSON_SERIES', [Validators.required]),
          fieldType: new FormControl(responseField.field.type, []),
          responseType: new FormControl(responseField.field.responseType, []),
          text: new FormControl(null),
          value: new FormControl(null),
          personParameter: new FormControl(null),
          personSeries: new FormControl(responseField.field.responseType === 'COMMON' ? responseField.field.personSeries : responseField.personSeries, responseField.field.required ? [Validators.required] : []),
          option: new FormControl(null),
          unit: new FormControl(responseField.unit ? responseField.unit : null, []),
          coefficient: new FormControl(responseField.coefficient ? responseField.coefficient : 1, [Validators.required, Validators.pattern(/^\-?\d+([\.\,]\d+)?$/)]),
          timeExponent: new FormControl(responseField.timeExponent ? responseField.timeExponent : 0, [Validators.required, Validators.pattern(/^\d+$/)]),
          deltaReading: new FormControl(responseField.deltaReading ? responseField.deltaReading : false, [Validators.required])
        });
      } else if (responseField.field.valueType === 'SELECT') {
        group = new FormControl(responseField.option, [Validators.required]);
        group = new FormGroup({
          id: new FormControl(responseField.id),
          fieldId: new FormControl(responseField.field.id, [Validators.required]),
          type: new FormControl('SELECT', [Validators.required]),
          fieldType: new FormControl(responseField.field.type, []),
          responseType: new FormControl(responseField.field.responseType, []),
          text: new FormControl(null),
          value: new FormControl(null),
          personParameter: new FormControl(null),
          personSeries: new FormControl(null),
          option: new FormControl(responseField.option, responseField.field.required ? [Validators.required] : []),
          unit: new FormControl(responseField.unit ? responseField.unit : null, []),
          coefficient: new FormControl(responseField.coefficient ? responseField.coefficient : 1, []),
          timeExponent: new FormControl(responseField.timeExponent ? responseField.timeExponent : 0, [Validators.required, Validators.pattern(/^\-?\d+([\.\,]\d+)?$/)]),
          deltaReading: new FormControl(responseField.deltaReading ? responseField.deltaReading : false, [])
        });
      }

      const field = {
        id: responseField.id,
        fieldId: responseField.field.id,
        fieldType: responseField.field.type,
        type: responseField.field.valueType,
        responseType: responseField.field.responseType,
        formulaType: responseField.field.formulaType,
        symbol: responseField.field.symbol,
        name: responseField.field.name,
        description: responseField.field.description,
        unit: responseField.field.unit,
        selectOptions: responseField.field.selectOptions,
        requestedUnit: responseField.field.unit,
        expression: responseField.field.expression,
        variables: responseField.field.variables,
        time: responseField.field.time,
        optional: !responseField.field.required,
        formGroup: group,
        location$: null,
        destroy$: new Subject<void>(),
        typeahead$: null,
        options$: null
      };

      if (field.type === 'PERSON_PARAMETER') {
        const typeahead$ = new Subject<string>();
        field.typeahead$ = typeahead$;
        field.options$ = typeahead$.pipe(
          takeUntil(this.ngDestroy),
          takeUntil(field.destroy$),
          startWith(''),
          distinctUntilChanged(),
          debounceTime(250),
          switchMap((term: string) => {
            if (!term) {
              return this.personParameterApi.getPersonParametersPage()
                .pipe(map((response: PersonParameterResponse) => response.content));
            }
            if (term.length < 4) {
              return of([]);
            }
            return this.personParameterApi.getPersonParametersPage({query: term})
              .pipe(map((response: PersonParameterResponse) => response.content));
          })
        );
        field.location$ = field.formGroup.get('personParameter').valueChanges.pipe(takeUntil(this.ngDestroy), map(pp => new Location(pp)));
      } else if (field.type === 'PERSON_SERIES') {
        const typeahead$ = new Subject<string>();
        field.typeahead$ = typeahead$;
        field.options$ = typeahead$.pipe(
          takeUntil(this.ngDestroy),
          takeUntil(field.destroy$),
          filter((value) => value && value.length >= 3),
          distinctUntilChanged(),
          debounceTime(250),
          switchMap((term: string) => {
            return this.http.get(this.config.apiUrl + '/data-owner-series', {params: {query: term, returnLatestValue: 'true'}})
              .pipe(map((response: SourceOwnerSeriesResponse) => response.content.map((e) => Object.assign({}, e.personSeries, {unit: e.latestValue ? e.latestValue.measurement ? e.latestValue.measurement.unit : null : null}))));
          }),
        );
        field.location$ = field.formGroup.get('personParameter').valueChanges.pipe(takeUntil(this.ngDestroy), map(ps => new Location(ps)));
      } else if (field.type === 'SELECT') {
        field.options$ = of(field.selectOptions.map((e) => ({value: e.name, label: e.name})));
      }

      if (group && field.fieldType !== 'FORMULA' && field.responseType !== 'COMMON') {
        group.get('personParameter').valueChanges.pipe(takeUntil(this.ngDestroy), takeUntil(field.destroy$), skip(1)).subscribe((value) => {
          group.get('unit').setValue(value && value.unit ? value.unit : null);
        });
        group.get('personSeries').valueChanges.pipe(takeUntil(this.ngDestroy), takeUntil(field.destroy$), skip(1)).subscribe((value) => {
          group.get('unit').setValue(value && value.unit ? value.unit : null);
          group.get('coefficient').setValue(1);
          group.get('timeExponent').setValue(0);
          if (value && value.deltaReading) {
            group.get('deltaReading').setValue(true);
          } else {
            group.get('deltaReading').setValue(false);
          }
        });
        group.get('deltaReading').valueChanges.pipe(takeUntil(this.ngDestroy), takeUntil(field.destroy$)).subscribe((value) => {
          if (value) {
            group.get('timeExponent').setValue(0);
          }
        });
      }

      this.fields.push(field);
      this.fieldArray.push(field.formGroup);
    }

    if (this.response?.status === 'SUBMITTED' || this.response?.status === 'DECLINED') {
      this.fieldArray.disable();
    } else {
      this.fieldArray.enable();
      for (const field of this.fields) {
        if (field.formGroup && field.responseType === 'COMMON') {
          field.formGroup.disable();
        }
      }
    }
  }

  public dismissErrors() {
    this.showErrors = false;
  }

  public openConversionModal(template: TemplateRef<any>) {
    this.conversionModalRef = this.modalService.show(template);
  }

  public getVariableList(formula: QuestionnaireField) {
    return (formula.variables ? formula.variables : []).join(', ');
  }

  async save(): Promise<any> {
    return this.doSave('IN_PROGRESS').toPromise();
  }

  async saveAsNewResponse(): Promise<any> {
    return this.doSave('IN_PROGRESS', true).toPromise();
  }

  async submit(): Promise<any> {
    this.fieldArray.markAllAsTouched();
    this.fieldArray.updateValueAndValidity();

    if (!this.fieldArray.valid) {
      console.log('form invalid', this.fieldArray);
      this.fieldArray.markAllAsTouched();
      this.showErrors = true;
      throw new Error('form-invalid');
    }

    const saveResult = this.doSave('SUBMITTED');
    return saveResult.pipe(takeUntil(this.ngDestroy)).toPromise();
  }

  doSave(status: string, createNew?: boolean): Observable<QuestionnaireResponseDetail> {
    const postDto = {
      id: createNew ? undefined : this.response.id,//not used on serverside
      // name: this.nameControl.value,
      questionnaireId: this.questionnaire?.id,
      status: status,
      fields: []
    };

    const formValue = this.fieldArray.getRawValue();

    for (const fieldValue of formValue) {
      if (fieldValue.responseType === 'COMMON' || fieldValue.fieldType === 'FORMULA') {
        continue;
      }
      postDto.fields.push({
        id: createNew ? undefined : fieldValue.id,
        fieldId: fieldValue.fieldId,
        type: fieldValue.type,
        text: fieldValue.type === 'TEXT' ? fieldValue.text : null,
        value: fieldValue.type === 'VALUE' ? fieldValue.value && typeof(fieldValue.value) === 'string' ? fieldValue.value.trim().replace(',', '.') : fieldValue.value : null,
        option: fieldValue.type === 'SELECT' ? fieldValue.option : null,
        personParameterId: fieldValue.type === 'PERSON_PARAMETER' && fieldValue.personParameter && fieldValue.personParameter.id ? fieldValue.personParameter.id : null,
        personSeriesId: fieldValue.type === 'PERSON_SERIES' && fieldValue.personSeries && fieldValue.personSeries.id ? fieldValue.personSeries.id : null,
        unit: fieldValue.unit,
        coefficient: fieldValue.coefficient != null ? fieldValue.coefficient : 1,
        timeExponent: fieldValue.timeExponent != null ? fieldValue.timeExponent : 1,
        deltaReading: fieldValue.deltaReading != null ? fieldValue.deltaReading : false
      });
    }

    let ret;
    if (postDto.id) {
      ret = this.http.put<QuestionnaireResponseDetail>(this.config.apiUrl + '/questionnaire-response/' + postDto.id, postDto);
    } else {
      ret = this.http.post<QuestionnaireResponseDetail>(this.config.apiUrl + '/questionnaire-response', postDto);
    }

    this.fieldArray.markAsPristine();

    return ret;
  }

  isPreventClose(): boolean {
    return this.fieldArray && this.fieldArray.dirty;
  }
}
