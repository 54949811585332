import {Component, EventEmitter, Inject, OnDestroy, OnInit, Output} from '@angular/core';
import {FormGroup, FormControl, Validators, FormArray, ValidationErrors, AbstractControl} from '@angular/forms';
import { ChangeDetectorRef } from '@angular/core';
import {
  EnumsService,
  Mandate,
  MandatePermission,
  MandateUser,
  UserService,
  SMARTENCITY_CORE_CONFIG,
  CoreConfig, PreventCloseAware
} from '@smartencity/core';
import {debounceTime, filter, switchMap, takeUntil, tap} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {Subject} from 'rxjs/internal/Subject';
import {PersonCodeValidator} from '../../../../../../core/src/lib/validators/person-code-validator';

const selector = 'mydata-add-mandate-modal';
let nextId = 0;

@Component({
    selector: selector,
    templateUrl: './add-mandate.component.html'
})
export class AddMandateComponent implements OnInit, OnDestroy, PreventCloseAware {
  id = `${selector}-${nextId++}`;
  private ngDestroy = new Subject<void>();
  public progress = false;

  public mandateForm: FormGroup;
  public showErrors = false;

  @Output('saved')
  savedEmitter: EventEmitter<any> = new EventEmitter<any>();

  public users = [];
  public userTypeahead = new EventEmitter<string>();
  public permissionsList: any[] = [];

  public errorCodes: any = {
    'Select user': 'Fill selections',
    'Target user is the owner': 'Not allowed selection'
  };
  public errorCode: string = null;

  public createUser = (personalId: string) => {
    return {
      countryCode: this.config.defaultCountryCode,
      personalId: personalId,
      displayName: null
    };
  };

  constructor (
    private userService: UserService,
    public toastr: ToastrService,
    private cd: ChangeDetectorRef,
    private enumService: EnumsService,
    private modalRef: BsModalRef,
    @Inject(SMARTENCITY_CORE_CONFIG) private config: CoreConfig,
  ) {

    const countryCode = this.config.defaultCountryCode;
    this.mandateForm = new FormGroup({
      user: new FormControl(null, [Validators.required, (control: AbstractControl): any => {
        const value = control.value;
        if (!value) {
          return;
        }

        let personalId = value.personalId;
        let cc = value.countryCode;
        if (!cc) {
          cc = this.config.defaultCountryCode;
        }

        let valid = PersonCodeValidator.validate(personalId, cc);

        console.log("valid " + valid);

        if (!valid) {
          return {
            invalidPersonCode: {
              value: control.value
            }
          }
        }

        return null;
      }])
    });

    this.initUserTypeahead();
    this.permissionsList = this.enumService.getOptionsList(['EDIT_MANDATES']);
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  ngOnInit () {
    this.reset();
  }

  public close(): void {
    this.modalRef.hide();
  }

  control(name?: string) {
    if (!name) {
      return this.mandateForm;
    }
    return this.mandateForm.get(name);
  }

  invalid(name: string, formGroup?) {
    if (!formGroup) {
      formGroup = this.mandateForm;
    }
    const control = formGroup.get(name);
    return control && control.invalid && (control.dirty || control.touched);
  }

  errors(name: string, formGroup?): ValidationErrors {
    if (!formGroup) {
      formGroup = this.mandateForm;
    }
    const control = formGroup.get(name);
    return control ? control.errors : null;
  }

  private reset() {
    const mandate = new Mandate();
    mandate.permissions = [];

    const formMandate: any = {};
    formMandate.user = mandate.user;
    this.mandateForm.reset(formMandate);

    const controls = this.permissionsList.map(permission => {
      let value = false;
      for (let i = 0; i < mandate.permissions.length; i++) {
        if (mandate.permissions[i].key == permission.value) {
          value = mandate.permissions[i].value;
        }
      }
      return value;
    });
    const permissionsArray = new FormArray(controls.map(value => new FormControl(value)));
    this.mandateForm.setControl('permissions', permissionsArray);
  }

  get permissions(): FormArray {
    return this.mandateForm.get('permissions') as FormArray;
  }

  saveMandate () {
    this.mandateForm.markAsTouched();
    this.markFormGroupTouched(this.mandateForm);
    this.mandateForm.updateValueAndValidity();

    if (this.mandateForm.invalid) {
      this.showErrors = true;
      return;
    }

    this.progress = true;

    const mandate = new Mandate();
    const formValue = this.mandateForm.value;
    const user = formValue.user;
    if (!user) {
      this.errorCode = 'Fill selections';
      return;
    }
    mandate.user = new MandateUser();
    mandate.user.personalId = user.personalId;
    mandate.user.countryCode = user.countryCode;
    mandate.permissions = [];
    for (let i = 0; i < formValue.permissions.length; i++) {
      mandate.permissions.push(new MandatePermission(this.permissionsList[i].value, formValue.permissions[i]));
    }

    this.userService.createMandate(mandate).pipe(takeUntil(this.ngDestroy)).subscribe(
      (mandate: any) => {
        this.errorCode = null;
        this.savedEmitter.emit();
        this.resetFormState();
        this.modalRef.hide();
      },
      (err: any) => {
        this.errorCode = err.error.message;
        this.progress = false;
      },
      () => {
        this.progress = false;
      }
    );
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  initUserTypeahead () {
    this.userTypeahead
      .pipe(
        filter((term) => term && term.length >= 4),
        debounceTime(200),
        switchMap(term => this.userService.searchUsers(term))
      ).pipe(takeUntil(this.ngDestroy))
      .subscribe(users => {
        this.users = users;
        this.cd.markForCheck();
      }, (err) => {
        this.users = [];
        this.cd.markForCheck();
      });
  }

  isPreventClose(): boolean {
    return this.mandateForm && this.mandateForm.dirty;
  }

  private resetFormState(): void {
    this.mandateForm.markAsUntouched();
    this.mandateForm.markAsPristine();
  }

}
