import {Inject, Pipe, PipeTransform} from '@angular/core';
import {SMARTENCITY_CORE_CONFIG} from '../injection-tokens';
import {CoreConfig} from '../core-config.model';
import {ModuleLabelHelper} from '../helpers/module-label-helper';

@Pipe({
  name: 'moduleLabel'
})
export class ModuleLabelPipe implements PipeTransform {

  constructor(@Inject(SMARTENCITY_CORE_CONFIG) public config: CoreConfig) {
  }

  transform(moduleName: string, defaultLabel: string): unknown {
    return ModuleLabelHelper.getLabel(this.config.locationName, moduleName, defaultLabel);
  }

}
