<ng-container>
  <div class="form-group row">
    <div class="col">
      <h3 class="m0 section-title" *ngIf="field?.name">{{field?.name}}</h3>
      <h3 class="m0 section-title " *ngIf="!field?.name && type !== 'FORMULA_SOURCE_TEMPLATE'" i18n>New question</h3>
      <h3 class="m0 section-title " *ngIf="!field?.name && type === 'FORMULA_SOURCE_TEMPLATE'" i18n>New formula</h3>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-md-3 col-form-label col-form-label-other" *ngIf="type !== 'FORMULA_SOURCE_TEMPLATE'" i18n>Question type</label>
    <label class="col-md-3 col-form-label col-form-label-other" *ngIf="type === 'FORMULA_SOURCE_TEMPLATE'" i18n>Formula type</label>
    <div class="col-md-9">
      <div class="btn-group btn-group-sm btn-separate" role="group">
        <button type="button" class="btn btn-outline-lighter" [class.active]="typeControl.value === 'QUESTION'"
                *ngIf="type !== 'FORMULA_SOURCE_TEMPLATE'" (click)="setType('QUESTION')" i18n [disabled]="partialEdit">Question</button>
        <button type="button" class="btn btn-outline-lighter" [class.active]="typeControl.value === 'EXPRESSION'"
                (click)="setType('EXPRESSION')" i18n [disabled]="partialEdit && type !== 'FORMULA_SOURCE_TEMPLATE'">Formula</button>
        <button type="button" class="btn tn-outline-lighter" [class.active]="typeControl.value === 'MAPPING'" (click)="setType('MAPPING')" *ngIf="false" i18n>Mapping</button><!-- TODO -->
      </div>
    </div>
  </div>

  <mydata-question-form *ngIf="field?.type === 'QUESTION'"></mydata-question-form>
  <mydata-formula-expression-form #formulaExpressionForm *ngIf="field?.type === 'FORMULA' && field?.formulaType === 'EXPRESSION'"
                                  [q11eType]="type" [questionnaire]="questionnaire" [partialEdit]="partialEdit"></mydata-formula-expression-form>
  <mydata-formula-mapping-form *ngIf="field?.type === 'FORMULA' && field?.formulaType === 'MAPPING'"></mydata-formula-mapping-form>
  <div class="form-group row" *ngIf="!field?.type">
    <div class="col">
      <p class="text-center help-text m0 font-italic" *ngIf="type !== 'FORMULA_SOURCE_TEMPLATE'" i18n>Select a question type</p>
      <p class="text-center help-text m0 font-italic" *ngIf="type === 'FORMULA_SOURCE_TEMPLATE'" i18n>Select a formula type</p>
    </div>
  </div>
</ng-container>
