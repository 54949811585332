import {Component, Inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {Subject} from 'rxjs/internal/Subject';
import {SMARTENCITY_MYDATA_CONFIG} from '../../../injection-tokens';
import {MyDataConfig} from '../../../mydata-config.model';
import {ValidationErrors} from '@angular/forms';

const selector = 'mydata-advice-row';
let nextId = 0;

@Component({
  selector: selector,
  templateUrl: './advice-row.component.html',
  providers: []
})
export class AdviceRowComponent implements OnInit, OnDestroy, OnChanges {
  id = `${selector}-${nextId++}`;

  private ngDestroy = new Subject<void>();

  @Input()
  public showErrors = false;

  @Input()
  public adviceGroup;

  public constructor(
    @Inject(SMARTENCITY_MYDATA_CONFIG) private config: MyDataConfig
  ) {

  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges): void {
  }

  control(name?: string) {
    if (!name) {
      return this.adviceGroup;
    }
    return this.adviceGroup.get(name);
  }

  invalid(name: string, formGroup?) {
    if (!formGroup) {
      formGroup = this.adviceGroup;
    }
    const control = formGroup.get(name);
    return control && control.invalid && (control.dirty || control.touched);
  }

  errors(name: string): ValidationErrors {
    const control = this.adviceGroup.get(name);
    return control ? control.errors : null;
  }

  public addDescription() {
    this.adviceGroup.get('description').setValue('', {onlySelf: false, emitEvent: true});
  }

  public removeDescription() {
    this.adviceGroup.get('description').setValue(null, {onlySelf: false, emitEvent: true});
  }
}
