<!-- mobile top nav start -->
<div class="top-navbar-mobile">
  <nav class="navbar navbar-purple">
    <button class="px-4 navbar-toggler" type="button" data-toggle="collapse" data-target="#sidebar" aria-controls="sidebar" aria-expanded="false" aria-label="Toggle navigation">
      <i class="far fa-bars fa-lg"></i>
    </button>
    <div class="py-3 d-flex justify-content-center align-items-center w-100">
      <a routerLink="/" class="d-block d-lg-none">
        <img [src]="logoImgSrc" class="img-fluid flex-center">
      </a>
    </div>
  </nav>
  <nav class="navbar navbar-light">
    <ul class="navbar-nav nav-icon flex-grow-1">
      <li class="nav-item">
        <ul class="navbar-nav">
          <li class="nav-item">
            <mydata-notifications-btn></mydata-notifications-btn>
          </li>
          <li class="nav-item dropdown" dropdown>
            <a class="nav-link dropdown-toggle text-uppercase no-caret" aria-haspopup="true" aria-expanded="false"
               dropdownToggle>
              <i class="fal fa-cog"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-right-mobile" *dropdownMenu>
              <a routerLink="/mydata/manage-advices" class="dropdown-item" i18n *ngxPermissionsOnly="['MANAGE_CITY']">Manage advices</a>
              <a routerLink="/mydata/services" class="dropdown-item" i18n="My data services@@mydata.services">My services</a>
              <a routerLink="/mydata/mandates" class="dropdown-item" i18n="My data delegations@@mydata.delegations">Delegations</a>
              <a routerLink="/mydata/settings" class="dropdown-item" i18n="My data settings@@mydata.settings">Settings</a>
            </div>
          </li>
          <li class="nav-item" *ngIf="feedbackEnabled">
            <a class="nav-link text-uppercase text-truncate no-caret" (click)="openFeedbackModal()"><i class="fal fa-comment"></i></a>
          </li>
        </ul>
      </li>
    </ul>
    <ul class="navbar-nav nav-icon nav-icon-border">
      <li class="nav-item dropdown" dropdown>
        <a class="nav-link dropdown-toggle text-uppercase no-caret text-truncate" aria-haspopup="true"
           aria-expanded="false" dropdownToggle>
          <i class="fal fa-user mr-3"></i> {{authService.profileData.mandate?.person.displayName}}
        </a>
        <div class="dropdown-menu dropdown-menu-right" *dropdownMenu>
          <!-- text-success -->
          <a [routerLink]="" class="dropdown-item" (click)="selectPerson(person)"
             *ngFor="let person of authService.profileData.availablePersons">{{person.displayName}}</a>
          <div *ngIf="authService.profileData.availableMandates.length > 0" class="dropdown-divider"></div>
          <a [routerLink]="" class="dropdown-item" (click)="selectPerson(mandate)"
             *ngFor="let mandate of authService.profileData.availableMandates">{{mandate.displayName}}</a>
        </div>
      </li>
      <li class="nav-item">
        <a class="nav-link sign-out" [routerLink]="" (click)="logout()">
          <i class="fal fa-sign-out"></i>
        </a>
      </li>
    </ul>
  </nav>
</div>

<div class="top-navbar">
  <nav class="navbar navbar-expand-lg navbar-light">
    <div class="collapse navbar-collapse d-none d-lg-flex" [id]="id + '-desktop'">
      <ul class="navbar-nav mr-auto navbar-mydata">
        <li class="nav-item" routerLink="/mydata/energy-manager" routerLinkActive="active" *ngIf="(preferences$|async)?.emDashboardEnabled">
          <a class="nav-link" (click)="onClick()">
            <i class="icon icon-energy-manager"></i>
            <span i18n>Energy manager</span>
          </a>
        </li>
        <li class="nav-item" routerLink="/mydata/dashboard" [queryParams]="{dashboard_type:'default'}" routerLinkActive="active">
          <a class="nav-link" (click)="onClick()">
            <i class="icon icon-dashboard"></i>
            <span i18n>Dashboard</span></a>
        </li>
        <li class="nav-item" routerLink="/mydata/datapoints" routerLinkActive="active">
          <a class="nav-link" (click)="onClick()">
            <i class="icon icon-datapoint"></i>
            <span i18n>Datapoints</span>
          </a>
        </li>
        <li class="nav-item" routerLink="/mydata/questionnaires" routerLinkActive="active" *ngIf="(preferences$|async)?.questionnairesEnabled">
          <a class="nav-link" (click)="onClick()">
            <i class="icon icon-questionnaire"></i>
            <span i18n>Questionnaires</span>
          </a>
        </li>
      </ul>

      <ul class="navbar-nav nav-icon">
        <li class="nav-item">
          <ul class="navbar-nav flex-row">
            <li class="nav-item">
              <mydata-notifications-btn></mydata-notifications-btn>
            </li>
            <li class="nav-item dropdown mr-3 mr-md-0 ml-3 ml-md-0" dropdown>
              <a class="nav-link dropdown-toggle text-uppercase no-caret" aria-haspopup="true"
                 aria-expanded="false" dropdownToggle>
                <i class="fal fa-cog"></i>
              </a>
              <div class="dropdown-menu dropdown-menu-right" *dropdownMenu>
                <a routerLink="/mydata/manage-advices" class="dropdown-item" i18n *ngxPermissionsOnly="['MANAGE_CITY']">Manage advices</a>
                <a routerLink="/mydata/services" class="dropdown-item" i18n="My data services@@mydata.services">My services</a>
                <a routerLink="/mydata/mandates" class="dropdown-item" i18n="My data delegations@@mydata.delegations">Delegations</a>
                <a routerLink="/mydata/settings" class="dropdown-item" i18n="My data settings@@mydata.settings">Settings</a>
              </div>
            </li>
            <li class="nav-item" *ngIf="feedbackEnabled">
              <a class="nav-link text-uppercase text-truncate no-caret" (click)="openFeedbackModal()"><i class="fal fa-comment"></i></a>
            </li>
          </ul>
        </li>
        <li class="nav-item dropdown" dropdown>
          <a class="nav-link dropdown-toggle text-uppercase no-caret text-truncate" aria-haspopup="true"
             aria-expanded="false" dropdownToggle>
            <i class="fal fa-user mr-3"></i> {{authService.profileData.mandate?.person.displayName}}
          </a>
          <div class="dropdown-menu dropdown-menu-right" *dropdownMenu>
            <!-- text-success -->
            <a [routerLink]="" class="dropdown-item" (click)="selectPerson(person)" *ngFor="let person of authService.profileData.availablePersons">{{person.displayName}}</a>
            <div *ngIf="authService.profileData.availableMandates.length > 0" class="dropdown-divider"></div>
            <a [routerLink]="" class="dropdown-item" (click)="selectPerson(mandate)" *ngFor="let mandate of authService.profileData.availableMandates">{{mandate.displayName}}</a>
          </div>
        </li>
        <li class="nav-item">
          <a class="nav-link sign-out" [routerLink]="" (click)="logout()">
            <i class="fal fa-sign-out"></i>
          </a>
        </li>
      </ul>
    </div>

  </nav>
  <div class="sub-bar">
    <span class="title">{{(breadcrumbs$ | async | slice:-1)[0]?.name$ | async}}</span>
    <ng-container *ngTemplateOutlet="menuComponent$ | async"></ng-container>
    <div class="mr-auto d-none d-lg-flex" *ngIf="!(menuComponent$ | async)"></div>
    <ng-container *ngIf="downloadDashboardData$ | async">
      <ng-container *ngTemplateOutlet="downloadDashboardData$ | async"></ng-container>
    </ng-container>
    <ng-container *ngIf="incomingWidgetTemplates$ | async">
      <ng-container *ngTemplateOutlet="incomingWidgetTemplates$ | async"></ng-container>
    </ng-container>
    <ng-container *ngIf="actionsComponent$ | async">
      <ng-container *ngTemplateOutlet="actionsComponent$ | async"></ng-container>
    </ng-container>

  </div>
</div>

<!-- mobile bottom nav start -->
<nav class="navbar bottom-navbar-mobile">
  <ul class="navbar-nav">
    <li class="nav-item" routerLink="/mydata/energy-manager" routerLinkActive="active">
      <a class="nav-link" (click)="onClick()">
        <i class="icon icon-energy-manager"></i>
        <span i18n>Energy manager</span>
      </a>
    </li>
    <li class="nav-item" routerLink="/mydata/dashboard"
        [queryParams]="{dashboard_type:'default'}"
        routerLinkActive="active">
      <a class="nav-link" (click)="onClick()">
        <i class="icon icon-dashboard"></i>
        <span i18n>Dashboard</span></a>
    </li>
    <li class="nav-item" routerLink="/mydata/datapoints" routerLinkActive="active">
      <a class="nav-link" (click)="onClick()">
        <i class="icon icon-datapoint"></i>
        <span i18n>Datapoints</span>
      </a>
    </li>
    <li class="nav-item" routerLink="/mydata/questionnaires" routerLinkActive="active">
      <a class="nav-link" (click)="onClick()">
        <i class="icon icon-questionnaire"></i>
        <span i18n>Questionnaires</span>
      </a>
    </li>
  </ul>
</nav>
<!-- mobile bottom nav end -->
