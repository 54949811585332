import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { SMARTENCITY_MYDATA_CONFIG } from '../injection-tokens';
import { MyDataConfig } from '../mydata-config.model';
import { DashboardFilter } from '../components/dashboard/dashboard';
import {Observable} from 'rxjs/internal/Observable';
import { CreateFromWidgetTemplateRequest, Widget, WidgetDataset } from '../components/dashboard/widget';
import { Dataset, PageResponse, Threshold } from '@smartencity/core';

@Injectable({
  providedIn: 'root'
})
export class WidgetApiService {

constructor(
  private http: HttpClient,
  @Inject(SMARTENCITY_MYDATA_CONFIG) private config: MyDataConfig,
) { }

  public getWidgets(dashboardFilter: DashboardFilter): Observable<Widget[]> {
    const params: any = {};
    if(dashboardFilter.dashboardType){
      params.dashboardType = dashboardFilter.dashboardType;
    }
    if(dashboardFilter.dashboardId){
      params.dashboardId = dashboardFilter.dashboardId;
    }
    return this.http.get<Widget[]>(this.config.apiUrl + '/widget', {params: params});
  }

  public save(widget: Widget): Observable<Widget> {
    return this.http.post<Widget>(this.config.apiUrl + '/widget', widget);
  }

  public update(widget: Widget): Observable<Widget> {
    return this.http.put<Widget>(this.config.apiUrl + '/widget/' + widget.id, widget);
  }

  public delete(widget: Widget): any {
    return this.http.delete(this.config.apiUrl + '/widget/' + widget.id);
  }

  public getWidgetSeriesThresholds(widget: Widget, widgetSeries: WidgetDataset): Observable<Threshold[]> {
    return this.http.get<Threshold[]>(this.config.apiUrl + '/widget/' + widget.id + '/widget-series/' + widgetSeries.id + '/thresholds');
  }

  public createFromWidgetTemplate(request: CreateFromWidgetTemplateRequest) {
    return this.http.post(this.config.apiUrl + "/widget/template", request);
  }

  public getWidgetDatasets(searchParams: any): Observable<PageResponse<Dataset>> {

    return this.http.get<PageResponse<Dataset>>(this.config.apiUrl + "/widget/dataset", {
      params: searchParams
    });
  }
}
