import {Inject, Injectable, OnDestroy} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, ReplaySubject} from 'rxjs';
import {CoreConfig, ListResponse, SMARTENCITY_CORE_CONFIG} from '@smartencity/core';
import {Subject} from 'rxjs/internal/Subject';
import {takeUntil} from 'rxjs/operators';
import {DisplaySettings} from '../model/display-settings';
import { WeatherStation } from '../model/weather-station';
import { forkJoin } from 'rxjs';
import { Observation } from '../model/road-weather-site-types';
import {DataPoint} from '@smartencity/core';
import moment from 'moment/moment';
import {RoadWeatherSite} from '../model/road-weather-site';

@Injectable()
export class MeteorologyService implements OnDestroy {
  private ngDestroy = new Subject<void>();

  public weatherStationPoints: WeatherStation[] = [];
  public roadConditionPoints: any[] = [];
  public roadWeatherPoints: any[] = [];

  private weatherStationsSource = new ReplaySubject<WeatherStation[]>();
  public weatherStationPoints$ = this.weatherStationsSource.asObservable();

  private weatherStationSelectedSource = new ReplaySubject<WeatherStation>();
  public weatherStationSelected$ = this.weatherStationSelectedSource.asObservable();

  private roadConditionSource = new ReplaySubject<any[]>();
  public roadConditionPoints$ = this.roadConditionSource.asObservable();

  private roadWeatherSource = new ReplaySubject<any[]>();
  public roadWeatherPoints$ = this.roadWeatherSource.asObservable();

  public roadWeatherSiteSelectedSource = new ReplaySubject<WeatherStation>();
  public roadWeatherSiteSelected$ = this.roadWeatherSiteSelectedSource.asObservable();

  public selectedClearedSource = new ReplaySubject();
  public selectedCleared$ = this.selectedClearedSource.asObservable();

  private locationsSelectedSource = new ReplaySubject<any[]>();
  public locationsSelected$ = this.locationsSelectedSource.asObservable();

  private roadConditionSelectedSource = new ReplaySubject<any>();
  public roadConditionSelected$ = this.roadConditionSelectedSource.asObservable();

  private roadWeatherSelectedSource = new ReplaySubject<any>();
  public roadWeatherSelected$ = this.roadWeatherSelectedSource.asObservable();

  public selectLocations(obj: any[]): void {
    this.locationsSelectedSource.next(obj);
  }

  displaySettingsSource = new ReplaySubject<DisplaySettings>();
  displaySettings$ = this.displaySettingsSource.asObservable();


  constructor(
    private http: HttpClient,
    @Inject(SMARTENCITY_CORE_CONFIG) private config: CoreConfig
  ) {
  }

  ngOnDestroy(): void {
    this.selectedClearedSource.complete();
    this.ngDestroy.complete();
  }

  getWeatherStations(): Observable<any> {
    return this.http.get(this.config.cityApiUrl + '/person-series', {
      params: {
        cityPortalType: 'WEATHER_STATION',
        size: '2000'
      }
    });
  }

  // current data
  getRoadWeatherSites(): Observable<ListResponse<RoadWeatherSite>>{
    let date = moment().subtract(24, 'hours');

    return this.http.get<ListResponse<RoadWeatherSite>>(this.config.cityApiUrl + '/person-series', {
      params: {
        cityPortalType: 'ROAD_WEATHER_SITE',
        size: '2000',
        lastUpdatedTo: date.toISOString()
      }
    });
  }

  // observations + forecasts
  getRoadWeatherSitesFull(): Observable<ListResponse<RoadWeatherSite>> {
    let date = moment().subtract(24, 'hours');

    return this.http.get<ListResponse<RoadWeatherSite>>(this.config.cityApiUrl + '/person-series', {
      params: {
        cityPortalType: 'ROAD_WEATHER_SITE_FULL',
        size: '2000',
        lastUpdatedTo: date.toISOString()
      }
    });
  }

  getPersonSeriesByPersonSeriesId(id: number): Observable<RoadWeatherSite> {
    return this.http.get<RoadWeatherSite>(this.config.cityApiUrl + '/person-series/' + id);
  }

  loadWeatherStations(): void {
    this.getWeatherStations().pipe(takeUntil(this.ngDestroy)).subscribe((data: any) => {
      this.weatherStationPoints = MeteorologyService.mapSeriesToWeatherStations(data.content);
      this.weatherStationsSource.next(this.weatherStationPoints);
    });
  }

  loadRoadWeatherSites(): void {
    //current data + prognosis/forecast
    forkJoin([this.getRoadWeatherSites(), this.getRoadWeatherSitesFull()]).pipe(takeUntil(this.ngDestroy)).subscribe(([data, fullData]: [ListResponse<RoadWeatherSite>, any]) => {

      const roadWeatherIndicators = ['road-condition', 'road-temp-minus-dew-point', 'road-temp', 'water-layer', 'visiblity', 'dew-point'];
      const weatherIndicators = ['air-temp', 'precip', 'precip-type', 'wind-speed', 'wind-speed-max', 'relative-humidity'];
      const roadConditionData = data.content.filter((i: any) => roadWeatherIndicators.find((j) => i.uuid.split('.')[1] === j));
      const roadWeatherData = data.content.filter((i: any) => weatherIndicators.find((j) => i.uuid.split('.')[1] === j));
      roadConditionData.push(...fullData.content);
      roadWeatherData.push(...fullData.content);

      this.roadConditionPoints = MeteorologyService.mapSeriesToRoadConditionData(roadConditionData);
      this.roadConditionSource.next(this.roadConditionPoints);

      this.roadWeatherPoints = MeteorologyService.mapSeriesToRoadWeatherData(roadWeatherData);
      this.roadWeatherSource.next(this.roadWeatherPoints);
    });
  }

  selectRoadCondition(roadCondition: RoadWeatherSite): void {
    this.roadConditionSelectedSource.next(roadCondition);
  }

  selectRoadWeather(roadWeather: RoadWeatherSite): void {
    this.roadWeatherSelectedSource.next(roadWeather);
  }

  selectWeatherStation(weatherStation: WeatherStation) {
    this.weatherStationSelectedSource.next(weatherStation);
  }

  private static mapSeriesToWeatherStations(data: any[]) {
    const weatherStationMap: any = {};
    const res = [];

    data.forEach(value => {
      const key: any = [value.lat, value.lng];

      if ( !weatherStationMap[key] ) {
        weatherStationMap[key] = {};
        weatherStationMap[key]['series'] = [];
        weatherStationMap[key]['id'] = key;
        weatherStationMap[key]['lat'] = value.lat;
        weatherStationMap[key]['lng'] = value.lng;
        weatherStationMap[key]['name'] = value.readableAddress;
        weatherStationMap[key]['updatedAt'] = value.updatedAt;
      }

      weatherStationMap[key]['series'].push(Object.assign(value, {
        type: value.uuid.split('.')[1]
      }));
    });

    for (const key of Object.keys(weatherStationMap)) {
      res.push(weatherStationMap[key]);
    }

    return res;
  }

  private static mapSeriesToRoadConditionData(data: RoadWeatherSite[]) {
    const roadWeatherMap: any = {};
    const res = [];

    data.forEach(value => {
      const key: any = [value.lat, value.lng];
      const indicator = value.uuid.split('.')[1];

      if ( !roadWeatherMap[key] ) {
        roadWeatherMap[key] = {};
        roadWeatherMap[key]['series'] = [];
        roadWeatherMap[key]['id'] = key;
        roadWeatherMap[key]['lat'] = value.lat;
        roadWeatherMap[key]['lng'] = value.lng;
        roadWeatherMap[key]['name'] = value.readableAddress;
        roadWeatherMap[key]['updatedAt'] = value.updatedAt;
      }

      if( indicator == 'forecasts-full' && !roadWeatherMap[key]['forecastsSeriesId']) {
        roadWeatherMap[key]['forecastsSeriesId'] = value.personSeriesId;
      }
      if( indicator == 'observations-full' && !roadWeatherMap[key]['observationsSeriesId']) {
        roadWeatherMap[key]['observationsSeriesId'] = value.personSeriesId;
      }

      roadWeatherMap[key]['series'].push(Object.assign(value, {
        type: indicator
      }));
    });

    for (const key of Object.keys(roadWeatherMap)) {
      res.push(roadWeatherMap[key]);
    }

    return res;
  }

  private static mapSeriesToRoadWeatherData(data: RoadWeatherSite[]) {
    const weatherMap: any = {};
    const res = [];

    data.forEach(value => {
      const key: any = [value.lat, value.lng];
      const indicator: any = value.uuid.split('.')[1];

      if ( !weatherMap[key] ) {
        weatherMap[key] = {};
        weatherMap[key]['series'] = [];
        weatherMap[key]['id'] = key;
        weatherMap[key]['lat'] = value.lat;
        weatherMap[key]['lng'] = value.lng;
        weatherMap[key]['name'] = value.readableAddress;
        weatherMap[key]['updatedAt'] = value.updatedAt;
      }

      if( indicator == 'forecasts-full' && !weatherMap[key]['forecastsSeriesId']) {
        weatherMap[key]['forecastsSeriesId'] = value.personSeriesId;
      }
      if( indicator == 'observations-full' && !weatherMap[key]['observationsSeriesId']) {
        weatherMap[key]['observationsSeriesId'] = value.personSeriesId;
      }

      weatherMap[key]['series'].push(Object.assign(value, {
        type: value.uuid.split('.')[1]
      }));
    });

    for (const key of Object.keys(weatherMap)) {
      res.push(weatherMap[key]);
    }

    return res;
  }
}
