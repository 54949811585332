<div [@transformPickerOverlay]="shouldAnimate" class="ngx-mat-drp-calendar-container">

  <div class="ngx-mat-drp-calendar-item">
    <calendar-wrapper
      [prefixLabel]="startDatePrefix"
      [fromDate]="fromDate"
      [toDate]="toDate"
      [displayDate]="fromDate"
      [activeDate]="fromActiveDate"
      [minDate]="fromMinDate"
      [maxDate]="fromMaxDate"
      (selectedDateChange)="selectDate($event, false)"
    ></calendar-wrapper>
  </div>
  <div class="ngx-mat-drp-calendar-item">
    <calendar-wrapper
      [prefixLabel]="endDatePrefix"
      [fromDate]="fromDate"
      [toDate]="toDate"
      [displayDate]="toDate"
      [activeDate]="toActiveDate"
      [minDate]="toMinDate"
      [maxDate]="toMaxDate"
      [name]="'dateToCalendar'"
      (selectedDateChange)="selectDate($event, false)"
    ></calendar-wrapper>
  </div>
  <div class="ngx-mat-drp-calendar-item">
    <div class="ngx-mat-drp-menu">
      <mat-drp-presets [presets]="presets" (presetChanged)="updateRangeByPreset($event)"></mat-drp-presets>
      <div class="ngx-mat-drp-controls">
        <button mat-button color="primary" (click)="applyNewDates($event)" [disabled]="!fromDate || !toDate">{{applyLabel}}</button>
        <button mat-button (click)="discardNewDates($event)">{{cancelLabel}}</button>
      </div>
    </div>
  </div>
</div>
