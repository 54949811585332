import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { MeteorologyService } from '../../service/meteorology.service';
import { forkJoin, of } from 'rxjs';
import { RoadWeatherSiteConstants } from '../../model/road-weather-site-constants';
import {DataTable, DataTableField, PersonSeries} from '../../model/road-weather-site-types';
import { RoadWeatherDataService } from '../../service/road-weather-data.service';
import { RoadConditionDataService } from '../../service/road-condition-data.service';
import moment from 'moment';
import {RoadWeatherSite} from '../../model/road-weather-site';

@Component({
  selector: '[road-condition-modal]',
  templateUrl: './road-condition-modal.component.html',
  providers: [
    { provide: RoadWeatherDataService, useClass: RoadConditionDataService },
  ]
})
export class RoadConditionModalComponent implements OnInit, OnChanges {

  @Input()
  public roadCondition: RoadWeatherSite;

  public roadConditionIndicators = RoadWeatherSiteConstants.roadConditionIndicators;
  public roadConditionCodeMap = RoadWeatherSiteConstants.roadConditionCodeMap;
  public loading = true;
  public data: DataTable = {rows: []};
  public updatedAt: Date;

  constructor(
    private personSeriesService: MeteorologyService,
    private roadWeatherDataService: RoadWeatherDataService
  ) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes.roadCondition) {

      const queries = [];

      if (this.roadCondition.observationsSeriesId) {
        queries.push(this.personSeriesService.getPersonSeriesByPersonSeriesId(this.roadCondition.observationsSeriesId));
      }

      if (this.roadCondition.forecastsSeriesId) {
        queries.push(this.personSeriesService.getPersonSeriesByPersonSeriesId(this.roadCondition.forecastsSeriesId));
      }

      const getFullInfo: Observable<any> = (queries.length > 0 ? forkJoin(queries) : of([]));

      getFullInfo.subscribe((personSeriesList: PersonSeries[]) => {
        this.updatedAt = this.getUpdatedAt(personSeriesList);
        this.data = this.roadWeatherDataService.mapRoadWeatherData(this.roadCondition, personSeriesList);
        for (let row of this.data.rows) {
          let rowData: Map<string, DataTableField> = new Map<string, DataTableField>();
          for (let field of row.fields) {
            if(field.value && !isNaN(field.value)) {
              field.value = Math.round(field.value * 10) / 10;
            }

            rowData.set(field.code, field);
          }
        }
        this.loading = false;
      });
    }
  }

  private getUpdatedAt(personSeriesList: PersonSeries[]): Date {
    let observationsUpdatedAt: Date = null;
    let forecastsUpdatedAt: Date = null;
    for (let ps of personSeriesList) {
      if (ps.personSeriesId == this.roadCondition.observationsSeriesId) {
        observationsUpdatedAt = this.getPersonSeriesUpdatedAt(ps);
        if (observationsUpdatedAt != null) {
          break;
        }
      }
    }
    for (let ps of personSeriesList) {
      if (ps.personSeriesId == this.roadCondition.forecastsSeriesId) {
        forecastsUpdatedAt = this.getPersonSeriesUpdatedAt(ps);
        if (forecastsUpdatedAt != null) {
          break;
        }
      }
    }

    // let updatedAt = null;
    // if (observationsUpdatedAt && forecastsUpdatedAt) {
    //   updatedAt = observationsUpdatedAt;
    //   if (observationsUpdatedAt < forecastsUpdatedAt) {
    //     updatedAt = forecastsUpdatedAt;
    //   }
    // } else {
    //   updatedAt = observationsUpdatedAt || forecastsUpdatedAt;
    // }

    return observationsUpdatedAt || forecastsUpdatedAt;
  }

  private getPersonSeriesUpdatedAt(personSeries: PersonSeries): Date {
    if (!personSeries.updatedAt) {
      return null;
    }

    const start = moment(personSeries.updatedAt);
    const minutes = Math.floor(start.minute() / 10) * 10; // rounding to 10th minute
    return moment(start).minute(minutes).toDate();
  }

}
