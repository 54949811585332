import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {Subject} from 'rxjs';
import moment, {Moment} from 'moment';
import {debounceTime, takeUntil} from 'rxjs/operators';
import { Chart } from 'chart.js';
import 'chartjs-plugin-colorschemes';
import {NgxMatDrpComponent} from '../../modules/ngx-mat-drp/ngx-mat-drp/ngx-mat-drp.component';
import {NgxDrpOptions, Range} from '../../modules/ngx-mat-drp/model/model';
import {DecimalPipe} from '@angular/common';
import {
  BuildingCompareItem,
  BuildingsCompareService,
  CompareItem,
  CompareService,
  EnumsService,
  LocationsCompareService,
  PersonSeriesCompareService
} from '@smartencity/core';
import {Building} from '@smartencity/buildings';

@Component({
  selector: 'core-compare-modal',
  templateUrl: './compare-modal.component.html',
  providers: [DecimalPipe]
})
export class CompareModalComponent implements OnInit, OnDestroy, AfterViewInit {
  private ngDestroy = new Subject<void>();

  @ViewChild('dateRangePicker', {static: true})
  public dateRangePicker: NgxMatDrpComponent;

  @ViewChild('chartCanvas', {static: false})
  public chartCanvas: ElementRef;

  public chart: any;

  public aggregationGroupingType$ = this.compareService.aggregationGroupingType$;
  public rangeControl = this.compareService.rangeControl;
  public loadingCount = 0;

  public locations: any[] = [];
  public locationsCompareItems = Array.from(this.locationsCompareService.itemTypeMap.values()).map((e) => e.compareItem);
  public buildings: any[] = [];

  public compareBuildings: BuildingCompareItem[] = [];

  public buildingsCompareItems = Object.values(this.buildingsCompareService.seriesTypeFilters).map((e) => e.compareItem);
  public psCompareItems = Array.from(this.personSeriesCompareSerivce.compareItemMap.values());
  public chartDatasets: Array<any> = this.compareService.chartDatasets;
  public chartYAxes: Array<any> = this.compareService.chartYAxes;

  footerTemplate: TemplateRef<any>;

  public chartXAxes: any = [{
    type: 'time',
    distribution: 'linear',
    ticks: {
      source: 'auto'
    },
    time: {
      displayFormats: {
        millisecond: 'HH:mm:ss.SSS',
        second: 'HH:mm:ss',
        minute: 'HH:mm',
        hour: 'HH:mm',
        /*dayhour: 'DD.MM.YYYY HH:mm',*/
        day: 'DD.MM.YYYY HH:mm',
        week: 'DD.MM.YYYY',
        month: 'MM.YYYY',
        quarter: '[Q]Q - YYYY',
        year: 'YYYY'
      },
      tooltipFormat: 'DD.MM.YYYY HH:mm:ss',
      unit: false
    },
    offset: true
  }];

  public chartOptions: any = {
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    legend: {
      onClick: (e) => e.stopPropagation()
    },
    scales: {
      xAxes: this.chartXAxes,
      yAxes: this.chartYAxes
    },
    plugins: {
      colorschemes: {
        scheme: 'tableau.HueCircle19'
      }
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => {
          if (isNaN(tooltipItem.yLabel)) {
            return tooltipItem;
          } else {
            return this.decimalPipe.transform(tooltipItem.yLabel, '1.0-4');
          }
        }
      }
    },
    chartArea: {
      backgroundColor: 'rgba(255, 255, 255, 1.0)'
    }
  };

  public graphTypeOptions = [
    { value: 'line', label: 'Line' },
    { value: 'bar', label: 'Bar' }
  ];

  drpOptions: NgxDrpOptions;

  public itemTypes = Array.from(this.locationsCompareService.itemTypeMap.values());

  public seriesTypes = Object.values(this.buildingsCompareService.seriesTypeFilters);

  public aggregationTypeOptions = this.enumService.getAggregationTypeOptions();

  public aggTypes = {
    'HOURLY': false,
    'DAILY': false,
    'MONTHLY': false,
    'YEARLY': false
  };

  constructor(
    private modalRef: BsModalRef,
    private compareService: CompareService,
    private buildingsCompareService: BuildingsCompareService,
    private locationsCompareService: LocationsCompareService,
    private personSeriesCompareSerivce: PersonSeriesCompareService,
    public enumService: EnumsService,
    private decimalPipe: DecimalPipe
  ) {
    this.locationsCompareItems = Array.from(this.locationsCompareService.itemTypeMap.values()).map((e) => e.compareItem);
    this.locations = Array.from(this.locationsCompareService.compareLocations.values()).map((e) => e.location);

    this.locationsCompareService.changes$.subscribe(() => {
      this.locationsCompareItems = Array.from(this.locationsCompareService.itemTypeMap.values()).map((e) => e.compareItem);
      this.locations = Array.from(this.locationsCompareService.compareLocations.values()).map((e) => e.location);
    });

    this.buildingsCompareItems = Object.values(this.buildingsCompareService.seriesTypeFilters).map((e) => e.compareItem);
    this.buildings = Array.from(this.buildingsCompareService.compareBuildings.values()).map((e) => e.building);

    this.compareBuildings = Array.from(this.buildingsCompareService.compareBuildings.values());

    this.buildingsCompareService.changes$.subscribe(() => {
      this.buildingsCompareItems = Object.values(this.buildingsCompareService.seriesTypeFilters).map((e) => e.compareItem);

      this.compareBuildings = Array.from(this.buildingsCompareService.compareBuildings.values());

      this.buildings = Array.from(this.buildingsCompareService.compareBuildings.values()).map((e) => e.building);
    });

    this.psCompareItems = Array.from(this.personSeriesCompareSerivce.compareItemMap.values());
    this.personSeriesCompareSerivce.changes$.subscribe(() => {
      this.psCompareItems = Array.from(this.personSeriesCompareSerivce.compareItemMap.values());
    });

    this.compareService.filters$.pipe(takeUntil(this.ngDestroy)).subscribe((filters: any) => {
      const aggregationGroupingType: string = filters.aggregationGroupingType;
      if (filters.aggregationGroupingType == null || aggregationGroupingType === 'HOURLY') {
        const diffTime = Math.abs(filters.range.to.toDate().getTime() - filters.range.from.toDate().getTime());
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        if (diffDays > 1) {
          this.chartXAxes[0].time.displayFormats.hour = 'DD.MM HH:mm';
        } else {
          this.chartXAxes[0].time.displayFormats.hour = 'HH:mm';
        }
        this.chartXAxes[0].time.unit = 'hour';
      } else {
        if (aggregationGroupingType === 'DAILY') {
          this.chartXAxes[0].time.unit = 'day';
        } else if (aggregationGroupingType === 'MONTHLY') {
          this.chartXAxes[0].time.unit = 'month';
        } else if (aggregationGroupingType === 'YEARLY') {
          this.chartXAxes[0].time.unit = 'year';
        }
      }
    });

    this.compareService.closeEvent$.pipe(takeUntil(this.ngDestroy)).subscribe(() => {
      this.close();
    });

    this.compareService.range$.pipe(takeUntil(this.ngDestroy)).subscribe((range) => {
      this.aggTypes = range.aggTypes;
    });

    this.compareService.loadingCount$.pipe(takeUntil(this.ngDestroy)).subscribe((loadingCount) => {
      this.loadingCount = loadingCount;
    });

    this.compareService.footerTemplate$.pipe(takeUntil(this.ngDestroy)).subscribe((template) => {
      this.footerTemplate = template;
    });
  }

  ngOnInit() {
    this.setupDrpOptions();
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  ngAfterViewInit(): void {
    this.compareService.chartChanges$.pipe(takeUntil(this.ngDestroy)).subscribe(() => {
      if (!this.chartDatasets.length) {
        this.modalRef.hide();
      }
    });
    this.compareService.chartChanges$.pipe(takeUntil(this.ngDestroy), debounceTime(250)).subscribe(() => {
      this.updateChart();
    });
    this.updateChart();
  }

  updateChart() {
    if (!this.chartCanvas) {
      return;
    }

    if (!this.chart) {
      const options = {
        type: 'bar',
        data: {
          labels: [],
          datasets: this.chartDatasets
        },
        options: this.chartOptions
      };

      this.chart = new Chart(this.chartCanvas.nativeElement, options);
    }

    this.chart.options.scales.xAxes = Chart.helpers.scaleMerge(Chart.defaults.scale, {xAxes: this.chartXAxes}).xAxes;
    this.chart.options.scales.yAxes = Chart.helpers.scaleMerge(Chart.defaults.scale, {yAxes: this.compareService.chartYAxes}).yAxes;
    this.chart.update();
  }

  close() {
    this.modalRef.hide();
  }

  aggregationGroupingType(aggregationGroupingType: string) {
    this.aggregationGroupingType$.next(aggregationGroupingType);
  }

  updateRange(range: Range) {
    if (this.rangeControl.value.from !== range.fromDate || this.rangeControl.value.to !== range.toDate) {
      const myRange: {from: Moment; to: Moment} = {
        from: range.fromDate.clone(),
        to: moment(range.toDate)
      };
      const to = moment().isBefore(moment(myRange.to)) ? moment() : moment(myRange.to).subtract(1);
      const aggTypes = {
        'HOURLY': !moment(myRange.from).isAfter(to.clone().startOf('hour')),
        'DAILY': !moment(myRange.from).isAfter(to.clone().startOf('day')),
        'MONTHLY': !moment(myRange.from).isAfter(to.clone().startOf('month')),
        'YEARLY': !moment(myRange.from).isAfter(to.clone().startOf('year'))
      };
      this.rangeControl.setValue({from: myRange.from, to: myRange.to, aggTypes: aggTypes});
    }
  }

  toggleItemType(itemType: any) {
    this.locationsCompareService.toggleItem(itemType);
  }

  toggleSeriesType(seriesType: any) {
    this.buildingsCompareService.toggleItem(seriesType);
  }

  toggleCompareBuilding(buildingCompareItem: BuildingCompareItem) {
    this.buildingsCompareService.toggleBuildingInComparison(buildingCompareItem);
  }

  removeLocation(location: any) {
    this.locationsCompareService.remove(location);
  }

  removeBuilding(compareBuilding: BuildingCompareItem) {
    if (!compareBuilding?.building) {
      return;
    }
    this.buildingsCompareService.removeBuildingCompareItemByBuilding(compareBuilding.building);
  }

  removePersonSeries(item: CompareItem) {
    this.compareService.deleteCompareItem(item);
  }

  setupDrpOptions() {
    const today = moment().startOf('day');

    const minus1 = today.clone().subtract(1, 'days');
    const minus7 = today.clone().subtract(7, 'days');
    const currMonthStart = today.clone().startOf('month');
    const currMonthEnd = today.clone().startOf('month').add(1, 'months').subtract(1, 'days');
    const lastMonthStart = today.clone().startOf('month').subtract(1, 'months');
    const lastMonthEnd = today.clone().startOf('month').subtract(1, 'days');
    const currYearStart = today.clone().startOf('year');
    const currYearEnd = today.clone().startOf('year').add(1, 'year').subtract(1, 'days');
    const lastYearStart = today.clone().startOf('year').subtract(1, 'year');
    const lastYearEnd = today.clone().startOf('year').subtract(1, 'days');

    this.drpOptions = {
      placeholder: 'Choose period',
      presets: [
        {
          presetLabel: $localize`Today`,
          range: {fromDate: today.clone(), toDate: today.clone()}
        },
        {
          presetLabel: $localize`Yesterday`,
          range: {fromDate: minus1.clone(), toDate: minus1.clone()}
        },
        {
          presetLabel: $localize`Last 7 Days`,
          range: {fromDate: minus7.clone(), toDate: today.clone().subtract(1, 'days')}
        },
        {
          presetLabel: $localize`This Month`,
          range: {fromDate: currMonthStart.clone(), toDate: currMonthEnd.clone()}
        },
        {
          presetLabel: $localize`Last Month`,
          range: {fromDate: lastMonthStart.clone(), toDate: lastMonthEnd.clone()}
        },
        {
          presetLabel: $localize`This year`,
          range: {fromDate: currYearStart.clone(), toDate: currYearEnd.clone()}
        },
        {
          presetLabel: $localize`Last year`,
          range: {fromDate: lastYearStart.clone(), toDate: lastYearEnd.clone()}
        }
      ],
      format: 'dd.MM.yyyy',
      range: {
        fromDate: this.rangeControl.value.from,
        toDate: this.rangeControl.value.to
      },
      /*fromMinMax: {fromDate: undefined, toDate: today},
      toMinMax: {fromDate: undefined, toDate: today},*/
      applyLabel: 'Apply',
      enforceToAfterFrom: true,
      calendarOverlayConfig: {
        shouldCloseOnBackdropClick: true,
        hasBackdrop: true
      },
      cancelLabel: 'Cancel'
    };
  }
}
