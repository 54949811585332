import {CrowdInsightsGridAreasGmapLayer} from './crowd-insights-grid-areas-gmap-layer';
import Feature = google.maps.Data.Feature;
import {MapsGridArea} from '../model/crowd-insights/maps-grid-area';
import LatLng = google.maps.LatLng;

export class CrowdInsightsGridAreaGmapMarker {

  private layer: CrowdInsightsGridAreasGmapLayer;

  public features: Feature[] = [];

  public area: MapsGridArea;

  public position: LatLng;

  constructor(layer: CrowdInsightsGridAreasGmapLayer, area: MapsGridArea, features: Feature[], position: LatLng) {
    this.layer = layer;
    this.area = area;
    this.features = features;
    this.position = position;
  }

}
