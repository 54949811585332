<div>

  <!-- <mat-divider></mat-divider> -->
  <span class="ngx-mat-drp-date-label">
    <label>{{prefixLabel}}</label>
    <label class="ngx-mat-drp-selected-date-label">{{displayDate | date:dateFormat}}</label>
  </span>
  <!-- <mat-divider></mat-divider> -->

  <mat-calendar
    [startAt]="activeDate"
    [minDate]="minDate"
    [maxDate]="maxDate"
    (selectedChange)="onSelectedChange($event)"
    (monthSelected)="onMonthSelected($event)"
    (yearSelected)="onYearSelected($event)"
    (_userSelection)="onUserSelection($event)"
    [dateFilter]="weekendFilter"
    [dateClass]="dateClass"
  >
  </mat-calendar>

</div>
