import {CacheService} from './cache.service';
import {LocalStoreCacheService} from './local-store-cache.service';

export let cacheServiceProvider = {
  provide: CacheService,
  useFactory: () => {
    return new LocalStoreCacheService();
  },
  deps: []
}
