<ng-container *ngIf="formGroup" [formGroup]="formGroup">
  <div class="form-group row">
    <label class="col-md-3 col-form-label" [attr.for]="id + '-name'"><ng-container i18n>Name</ng-container></label>
    <div [ngClass]="partialEdit ? 'col-md-8' : 'col-md-9'">
      <input type="text" class="form-control" [class.is-invalid]="invalid('name')" [id]="id + '-name'" formControlName="name" placeholder="Name" i18n-placeholder>
      <small *ngIf="invalid('name') && errors('name')?.required" class="invalid-feedback d-block" i18n>Required</small>
    </div>
    <button *ngIf="partialEdit" class="btn btn-tooltip"
            tooltip="Formula cannot be modified, because questionnaire is already confirmed. In order to make changes to formula, you must copy existing one." i18n-tooltip><i class="fa fa-info-circle"></i></button>
  </div>

  <div class="form-group row">
    <label class="col-md-3 col-form-label" [attr.for]="id + '-description'"><ng-container i18n>Description</ng-container><span class="optional" i18n>Optional</span></label>
    <div class="col-md-9">
      <textarea class="form-control" rows="3" [class.is-invalid]="invalid('description')" [id]="id + '-description'" formControlName="description" placeholder="Additional information for context" i18n-placeholder></textarea>
      <small *ngIf="invalid('description') && errors('description')?.required" class="invalid-feedback d-block" i18n>Required</small>
    </div>
  </div>

  <span class="separator"></span>

  <div class="form-group row">
    <ng-template #syntaxErrorTemplate><div [innerHtml]="control('expression')?.errors?.syntax"></div></ng-template>

    <label class="col-12 d-flex justify-content-center" [attr.for]="id + '-expression'" i18n>Formula expression</label>
    <div class="col-12" *ngIf="formGroup.get('symbol').value === null">
      <textarea class="form-control" [class.is-invalid]="invalid('expression')" [id]="id + '-expression'" rows="3" formControlName="expression" placeholder="Enter a formula expression here, such as A + B" i18n-placeholder></textarea>
      <small *ngIf="invalid('expression') && errors('expression')?.required" class="invalid-feedback d-block" i18n>Required</small>
      <small *ngIf="invalid('expression') && errors('expression')?.syntax" class="invalid-feedback d-block"><ng-container i18n>Syntax error</ng-container>: <button type="button" class="btn btn-xs btn-link m-0 p-0" style="text-decoration: none;" [popover]="syntaxErrorTemplate" i18n>Details</button></small>
    </div>
    <div class="d-flex w-100" *ngIf="formGroup.get('symbol').value !== null">
      <div class="col-2">
        <input class="form-control" formControlName="symbol" placeholder="Symbol" i18n-placeholder>
        <small *ngIf="invalid('symbol') && errors('symbol')?.required" class="invalid-feedback d-block" i18n>Required</small>
      </div>
      <div class="col-auto p-0 equal">
        <i class="fa fa-equals"></i>
      </div>
      <div class="col">
        <textarea class="form-control" [class.is-invalid]="invalid('expression')" [id]="id + '-expression'" rows="3" formControlName="expression" placeholder="Enter a formula expression here, such as A + B" i18n-placeholder></textarea>
        <small *ngIf="invalid('expression') && errors('expression')?.required" class="invalid-feedback d-block" i18n>Required</small>
        <small *ngIf="invalid('expression') && errors('expression')?.syntax" class="invalid-feedback d-block"><ng-container i18n>Syntax error</ng-container>: <button type="button" class="btn btn-xs btn-link m-0 p-0" style="text-decoration: none;" [popover]="syntaxErrorTemplate" i18n>Details</button></small>
      </div>
    </div>
    <div class="col-12 d-flex justify-content-end align-items-center" *ngIf="partialEdit">
      <span class="help-text" i18n>Assign a left-hand side symbol to the expression if you wish to use the result of the expression in other formulas.</span>
      <button class="btn btn-link btn-sm" (click)="toggleSymbol()" *ngIf="this.formGroup.get('symbol').value === null" i18n>Set symbol</button>
      <button class="btn btn-link btn-sm" (click)="toggleSymbol()" *ngIf="this.formGroup.get('symbol').value !== null" i18n>Remove symbol</button>
    </div>
  </div>

  <div class="form-group row">
    <label class="col-md-3 col-form-label" [attr.for]="id + '-unit'" i18n>Output unit</label>
    <div class="col-md-3">
      <input type="text" class="form-control" [class.is-invalid]="invalid('unit')" formControlName="unit" [id]="id + '-unit'" placeholder="Output unit" i18n-placeholder>
      <small *ngIf="invalid('unit') && errors('unit')?.required" class="invalid-feedback d-block" i18n>Required</small>
    </div>
  </div>

  <div class="form-group row">
    <label class="col-md-3 col-form-label" [attr.for]="id + '-time'" i18n>Start date</label>
    <div class="col-md-4">
      <datepicker-wrapper [id]="id + '-time'" [control]="this.formGroup.get('time')"></datepicker-wrapper>
      <button class="btn btn-tooltip" tooltip="Start date for the data requested and the date from which the historical values for the formula will be calculated." i18n-tooltip><i class="fa fa-info-circle"></i></button>
      <small *ngIf="invalid('time') && errors('time')?.required" class="invalid-feedback d-block" i18n>Required</small>
    </div>
  </div>

  <div class="form-group row">
    <label class="col-md-3 col-form-label" [attr.for]="id + '-sampling-frequency'" i18n>Sampling frequency</label>
    <div class="col-md-9">
      <div class="btn-group btn-group-sm btn-separate" role="group" btnRadioGroup formControlName="samplingType" [id]="id + '-sampling-frequency'">
        <button type="button" class="btn btn-outline-lighter" [btnRadio]="'MINUTELY_15'" i18n [disabled]="partialEdit">15 minutes</button>
        <button type="button" class="btn btn-outline-lighter" [btnRadio]="'MINUTELY_30'" i18n [disabled]="partialEdit">30 minutes</button>
        <button type="button" class="btn btn-outline-lighter" [btnRadio]="'HOURLY'" i18n [disabled]="partialEdit">Hourly</button>
        <button type="button" class="btn btn-outline-lighter" [btnRadio]="'DAILY'" i18n [disabled]="partialEdit">Daily</button>
        <button type="button" class="btn btn-outline-lighter" [btnRadio]="'MONTHLY'" i18n [disabled]="partialEdit">Monthly</button>
        <button type="button" class="btn btn-outline-lighter" [btnRadio]="'YEARLY'" i18n [disabled]="partialEdit">Yearly</button>
      </div>
      <small *ngIf="invalid('samplingType') && errors('samplingType')?.required" class="invalid-feedback d-block" i18n>Required</small>
    </div>
  </div>

  <mydata-formula-input-form *ngFor="let field of inputFields; let i = index" [q11eType]="q11eType"
                             [questionnaire]="questionnaire"
                             [partialEdit]="partialEdit"
                             [inputFieldRow]="field" [active]="isInputActive(field)"></mydata-formula-input-form>


  <div class="d-flex justify-content-center mt-4">
    <button type="button" class="btn btn-outline-secondary" (click)="cancel()" i18n>Cancel</button>
    <button type="button" class="btn btn-outline-primary ml-2" (click)="save()" i18n>Save</button>
  </div>
</ng-container>
