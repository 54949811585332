import {Person} from './person';
import {User} from './user';

export class AuditLog {
  type: AuditLogType;
  entityId: number;
  operation: AuditLogOperation;
  user: User;
  person: Person;
  targetUser: User;
  targetPerson: Person;
  loggedAt: string;
  data: any;
  meta: any;
}

export enum AuditLogType {
  MANDATE = 'MANDATE',
  CONSENT = 'CONSENT'
}

export enum AuditLogOperation {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}
