<ng-template #noRootTemplate>
  <div *ngIf="this.dashboardService.widgetItems.length > 0" dropdown class="btn-group">
    <button type="button" class="btn btn-sm btn-link dropdown-toggle no-caret d-none d-md-inline-block" dropdownToggle
            [disabled]="this.downloadInProgress">
      <i class="fal fa-download mr-2"></i>
      <ng-container i18n>Download</ng-container>
    </button>
    <div class="dropdown-menu dropdown-menu-right" *dropdownMenu>
      <a class="dropdown-item" (click)="this.downloadPdf()"><i class="fal fa-file-pdf" ></i><ng-container i18n>Pdf</ng-container></a>
      <a class="dropdown-item" (click)="this.downloadDashboard('xlsx')"><i class="fal fa-file-excel"></i><ng-container i18n>Excel</ng-container></a>
    </div>
  </div>

  <div class="btn-floating-mobile-container">
    <div *ngIf="this.dashboardService.widgetItems.length > 0" dropdown class="btn-group">
      <button type="button" class="btn btn-sm btn-link dropdown-toggle no-caret btn-floating-mobile bg-white" dropdownToggle [disabled]="this.downloadInProgress">
        <i class="fal fa-download fa-2x"></i>

      </button>
      <div class="dropdown-menu dropdown-menu-floating-btn" *dropdownMenu>
        <a class="dropdown-item" (click)="this.downloadPdf()"><i class="fal fa-file-pdf" ></i><ng-container i18n>Pdf</ng-container></a>
        <a class="dropdown-item" (click)="this.downloadDashboard('xlsx')"><i class="fal fa-file-excel"></i><ng-container i18n>Excel</ng-container></a>
      </div>
    </div>
    <button (click)="this.dashboardService.addWidget()" type="button" class="btn btn-primary btn-sm btn-floating-mobile">
      <i class="fal fa-plus fa-2x"></i>
    </button>
  </div>
</ng-template>
