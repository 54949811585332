import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SMARTENCITY_LIGHTS_CONFIG} from './injection-tokens';
import {ILightsConfig, MapsConfig} from './lights-config.model';
import {LightPage, LightsHistogram, LightsState} from './lights.model';
import {Observable} from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class LightsService {

  constructor(
    private http: HttpClient,
    @Inject(SMARTENCITY_LIGHTS_CONFIG) private config: ILightsConfig
  ) { }

  getMapsConfig(): MapsConfig {
    return this.config.map;
  }

  getLights(params: any): Observable<LightPage> {
    params.cityPortalType = 'STREET_LIGHTS';
    return new Observable(observer => {
      this.http.get(this.config.cityApiUrl + '/person-series', {
        params: params
      }).subscribe(
        (data: LightPage) => {
          return observer.next(data);
        }, err => {
          return observer.error(err);
        });
    });
  }

  getLightsState(params: any): Observable<LightsState> {
    params.cityPortalType = 'STREET_LIGHTS';
    return new Observable(observer => {
      this.http.get(this.config.cityApiUrl + '/person-series/state', {
        params: params
      }).subscribe(
        (data: LightsState) => {
          return observer.next(data);
        }, err => {
          return observer.error(err);
        });
    });
  }

  getLightsHistogram(): Observable<LightsHistogram> {
    return new Observable(observer => {
      this.http.get(this.config.cityApiUrl + '/person-series/light/histogram', {}).subscribe(
        (data: LightsHistogram) => {
          return observer.next(data);
        }, err => {
          return observer.error(err);
        });
    });
  }
}
