import {Position} from '@smartencity/core';

export class Building implements Position {
  id: string;
  series: any[];
  address: string;
  lat: number;
  lng: number;
  cityPortalType: string;
  readableAddress: string;

  constructor(props?: {
    id: string,
    series: any[],
    address: string;
    lat: number;
    lng: number;
    cityPortalType?: string;
    readableAddress?: string
  }) {
    if (!props) {
      return;
    }
    this.id = props.id;
    if (props.series) {
      this.series = props.series;
    }
    this.address = props.address;
    this.lat = props.lat;
    this.lng = props.lng;
    this.cityPortalType = props.cityPortalType;
    this.readableAddress = props.readableAddress;
  }
}

export class BuildingInComparison extends Building {
  active: boolean;
}