<div class="jumbotron floating-jumbotron content-wrapper" [class.active]="bus">
  <div class="modal-header modal-header-icon">
    <h5 class="modal-title w-100"><i class="bus-icon-primary"></i>{{bus?.route}} <small>- {{bus?.description}}</small></h5>
    <button type="button" class="close pull-right" aria-label="Close" (click)="clear()">
      <i class="fal fa-times"></i>
    </button>
  </div>
  <div class="modal-body p-3">
    <div class="timeline-main">
      <!-- Timeline Wrapper -->
      <ul class="stepper stepper-vertical timeline timeline-simple pl-0" *ngIf="trip">
        <ng-container *ngFor="let stop of tripStops; let i = index" >
          <ng-container *ngIf="showAllStops || (arrivals && arrivals.get(stop.id))" >

            <li [className]="activeStopId == stop.id ? 'active' : ''" (click)="selectStop(stop.id)">
              <a [routerLink]="">
                <span class="circle grey"></span>
              </a>
              <span class="label-text">{{stop.title}}</span>
              <span class="approaching-time">
                <span class="text-danger" *ngIf="minutesOffSchedule(stop) > 0">+&nbsp;{{minutesOffSchedule(stop)}}&nbsp;min</span>
                {{arrivalText(stop)}}
                <i *ngIf="arrivals.get(stop.id)" class="approaching-icon"></i></span>
            </li>
          </ng-container>
        </ng-container>
      </ul>
    </div>
  </div>
  <div class="modal-footer justify-content-center pt-0">
    <button type="button"  class="btn btn-primary btn-sm" (click)="showAll()" *ngIf="!showAllStops" i18n>Show all stops</button>
  </div>
</div>
