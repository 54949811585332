import {ChangeDetectorRef, Component, EventEmitter, OnInit, Output} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {FormControl, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {debounceTime, filter, switchMap, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs/internal/Subject';
import {PersonSeriesGroup, PreventCloseAware} from '@smartencity/core';
import {GroupingTagService} from '../../../services/grouping-tag.service';
import {PersonSeriesGroupApiService} from '../../../http/person-series-group-api.service';

const selector = 'datapoint-group-search-modal';
let nextId = 0;

@Component({
  selector: selector,
  templateUrl: './datapoint-group-select-modal.component.html'
})
export class DatapointGroupSelectModalComponent implements OnInit, PreventCloseAware {
  id = `${selector}-${nextId++}`;

  private ngDestroy = new Subject<void>();

  @Output('selected')
  selectedEmitter: EventEmitter<PersonSeriesGroup> = new EventEmitter<PersonSeriesGroup>();

  public users = [];
  public groupTypeahead = new EventEmitter<string>();

  form: FormGroup = new FormGroup({
    group: new FormControl(null, [Validators.required])
  });

  confirmed = false;
  groups: PersonSeriesGroup[];


  constructor(private modalRef: BsModalRef,
              private personSeriesGroupService: PersonSeriesGroupApiService,
              private groupingTagService: GroupingTagService,
              private cd: ChangeDetectorRef) {
    this.initSearchTypeahead();
  }

  ngOnInit(): void {
  }

  initSearchTypeahead () {
    this.groupTypeahead
      .pipe(
        filter((term) => term && term.length >= 3),
        debounceTime(200),
        switchMap(term => this.personSeriesGroupService.searchGroups({query: term, ownerTypes: ['OWNER']}))
      ).pipe(takeUntil(this.ngDestroy))
      .subscribe(groups => {
        this.groups = groups;
        this.cd.markForCheck();
      }, (err) => {
        this.users = [];
        this.cd.markForCheck();
      });
  }

  selectGroup(): void {
    this.confirmed = true;
    this.form.markAsDirty();
    if (!this.form.valid) {
      console.error('Form is invalid', this.form);
      return;
    }

    const formValue = this.form.getRawValue();
    this.selectedEmitter.next(formValue.group);

    this.resetFormState();

    this.modalRef.hide();
  }

  createGroup(name: any): any {
    return {
      name: name
    };
  }

  close(): void {
    this.modalRef.hide();
  }


  control(name?: string) {
    if (!name) {
      return this.form;
    }
    return this.form.get(name);
  }

  invalid(name: string, formGroup?) {
    if (!formGroup) {
      formGroup = this.form;
    }
    const control = formGroup.get(name);
    return control && control.invalid && (control.dirty || control.touched);
  }

  errors(name: string, formGroup?): ValidationErrors {
    if (!formGroup) {
      formGroup = this.form;
    }
    const control = formGroup.get(name);
    return control ? control.errors : null;
  }

  isPreventClose(): boolean {
    return this.form && this.form.dirty;
  }

  private resetFormState(): void {
    this.form.markAsUntouched();
    this.form.markAsPristine();
  }

}
