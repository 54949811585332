import {Inject, Injectable, OnDestroy} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Subject} from 'rxjs';
import {SMARTENCITY_CORE_CONFIG} from '../injection-tokens';
import {CoreConfig} from '../core-config.model';
import {Person} from '../models/person';
import {distinctUntilChanged, map, shareReplay, takeUntil} from 'rxjs/operators';
import {AuthService} from '../auth/auth.service';
import {Preferences} from '../models/preferences';
import {Observable} from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class PreferencesService implements OnDestroy {
  private ngDestroy = new Subject<void>();

  public role: Person;

  private _preferences = new Subject<any>();

  public preferences$ = this._preferences.asObservable().pipe(distinctUntilChanged(), shareReplay(1));

  constructor(
    @Inject(SMARTENCITY_CORE_CONFIG) private config: CoreConfig,
    private http: HttpClient,
    private authService: AuthService
  ) {
    this.authService.currentMandate$.pipe(takeUntil(this.ngDestroy)).subscribe(() => {
      this.loadPreferences();
    });

    this.loadPreferences();
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  loadPreferences() {
    this.getPreferences().pipe(takeUntil(this.ngDestroy)).subscribe((preferences: Preferences) => {
      this.setPreferences(preferences);
    });
  }

  setPreferences(preferences: Preferences) {
    this._preferences.next(preferences);
  }

  getPreferences(): Observable<Preferences> {
    return this.http.get<Preferences>(this.config.apiUrl + '/preferences').pipe(map((preferences: Preferences) => {
      const defaultPreferences = this.config.defaultPreferences;
      let defaultEmDashboardEnabled = true,
        defaultQuestionnairesEnabled = true;
      if (defaultPreferences) {
        defaultEmDashboardEnabled = defaultPreferences.emDashboardEnabled;
        defaultQuestionnairesEnabled = defaultPreferences.questionnairesEnabled;
      }

      if (preferences.emDashboardEnabled == null) {
        preferences.emDashboardEnabled = defaultEmDashboardEnabled;
      }

      if (preferences.questionnairesEnabled == null) {
        preferences.questionnairesEnabled = defaultQuestionnairesEnabled;
      }

      return preferences;
    }));
  }

  patchPreferences(preferences: any): Observable<Preferences> {
    return this.http.patch<Preferences>(this.config.apiUrl + '/preferences', preferences);
  }
}
