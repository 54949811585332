import {TallinnService} from '../service/tallinn.service';
import LatLng = google.maps.LatLng;
import Map = google.maps.Map;

export class AirQualityStationGmapMarker extends google.maps.OverlayView {

  airQualityStation: any;
  series: any;
  latlng: any;
  div: HTMLElement;
  selected = false;

  private tallinnService: TallinnService;

  constructor(airQualityStation, latlng: LatLng, map: Map, series, tallinnService: TallinnService) {
    super();

    this.airQualityStation = airQualityStation;
    this.series = series;
    this.latlng = latlng;
    this.tallinnService = tallinnService;
    this.setMap(map);
  }

  onAdd() {
    if (!this.div) {
      this.div = document.createElement('DIV');
      this.div.title = this.airQualityStation.readableAddress;
      this.div.style.cursor = 'pointer';
      this.div.classList.add('pointer-weather-station');

      let value = '-';
      let valueClass = '';
      if (this.series != null) {

        this.div.innerHTML = '<span class="value ' + valueClass + '" style="top:-40px;" title="' + this.airQualityStation.readableAddress + '">' + value + '</span>';
      }

      this.div.addEventListener('click', () => {
        this.tallinnService.airQualityStationSelectedSource.next(this.airQualityStation);
      });

      const panes = this.getPanes();
      panes.overlayMouseTarget.appendChild(this.div);
    }
  }

  onRemove() {
    this.div.parentNode.removeChild(this.div);
  }

  draw() {
    const point = this.getProjection().fromLatLngToDivPixel(this.latlng);
    if (point) {
      this.div.style.left = (point.x - 22) + 'px';
      this.div.style.top = (point.y - 45) + 'px';
    }

    if (this.selected) {
      this.div.classList.add("active");
    } else {
      this.div.classList.remove("active");
    }

  }

}
