export const environment = {
  production: false,
  logLevel: 'INFO',
  i18n: [
    { name: 'en', baseUrl: '/en-GB'},
    { name: 'et', baseUrl: '/et'},
    { name: 'da', baseUrl: '/da'}
  ],
  theme: 'telia',
  lazyLoadStyles: true
};
