import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import { LayerDisplaySettings } from 'projects/arc-gis/src/lib/layer-display-settings';
import { TallinnArcGisMapService } from 'projects/arc-gis/src/lib/tallinn-arc-gis-map.service';
import { DisplaySettings } from '../model/display-settings';
import { EnergyClass } from '../model/eec-building';
import { BuildingsOlMapService } from '../service/buildings-ol.service';
import { BuildingsService } from '../service/buildings.service';

@Component({
  selector: 'building-layer-filters',
  templateUrl: './building-layer-filters.component.html'
})
export class BuildingLayerFiltersComponent implements OnInit {

  private buildingsOlService: BuildingsOlMapService;
  private buildingsService: BuildingsService;
  private mapService: TallinnArcGisMapService;

  public displaySettings: DisplaySettings;
  public displayArcGisSettings: LayerDisplaySettings = new LayerDisplaySettings();
  public displayEnergyTypeSettings: Record<"eecBuildings", Record<string, boolean>> = {"eecBuildings": {"A": true, "B": true, "C": true, "D": true, "E": true, "F": true, "G": true, "H": true, "active": true}};

  @Output('displaySettingsChange')
  displaySettingsChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    buildingsOlService: BuildingsOlMapService,
    buildingsService: BuildingsService,
    mapService: TallinnArcGisMapService ) {
    this.buildingsOlService = buildingsOlService;
    this.buildingsService = buildingsService;
    this.displaySettings = this.buildingsService.getDisplaySettings();
    this.mapService = mapService;
  }

  ngOnInit(): void {
    this.toggleAllEnergyClasses(true);
  }

  public toggleEecBuildingEnergyClass(energyClass: string): void {
    const isActive = (energyClass in this.displayEnergyTypeSettings.eecBuildings && this.displayEnergyTypeSettings.eecBuildings[energyClass]) || false;
    const currentItem = {[energyClass] : !isActive};
    const eecBuildings = {"eecBuildings" : Object.assign(this.displayEnergyTypeSettings.eecBuildings, currentItem)};
    this.buildingsService.updateDisplaySettings(Object.assign(this.displaySettings, eecBuildings));
    this.buildingsOlService.updateDisplay();
  }

  public toggleAllEnergyClasses(visible?: boolean): void {
    const energyClassEnumValues = Object.values(EnergyClass).filter( (value) => typeof value === "string") as string[];
    const energyClassVisibilities = energyClassEnumValues.map((energyClass) => [
      energyClass,
      visible ?? !this.displaySettings.eecBuildings?.active,
    ]);

    this.displayEnergyTypeSettings = {eecBuildings: Object.fromEntries(energyClassVisibilities),};
    this.buildingsService.updateDisplaySettings(Object.assign(this.displaySettings, this.displayEnergyTypeSettings));
    this.buildingsOlService.updateDisplay();
  }

  public toggleLayer(displaySettings: any): void {
    this.displayArcGisSettings = Object.assign(this.displayArcGisSettings, displaySettings);
    this.mapService.updateDisplaySettings(this.displayArcGisSettings);
  }
}
