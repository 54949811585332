import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CoreModule} from '@smartencity/core';
import {NgxPermissionsModule} from 'ngx-permissions';
import {ModalModule} from 'ngx-bootstrap/modal';
import {PaginationModule} from 'ngx-bootstrap/pagination';
import {PopoverModule} from 'ngx-bootstrap/popover';
import {DatapointListManageComponent} from './datapoint-list-manage.component';
import {LaddaModule} from 'angular2-ladda';
import {LocationPersonSeriesListComponent} from './location-person-series-list.component';
import {LocationMapComponent} from './location-map/location-map.component';
import {SeriesEditModalComponent} from './edit-modal/series-edit-modal.component';
import {SeriesCreateModalComponent} from './create-modal/series-create-modal.component';
import {SeriesBulkEditModalComponent} from './bulk-edit-modal/series-bulk-edit-modal.component';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {NgSelectModule} from '@ng-select/ng-select';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {MyDataSharedModule} from '../../shared/my-data-shared.module';
import { DatapointTableComponent } from './datapoint-table/datapoint-table.component';

@NgModule({
  declarations: [
    DatapointListManageComponent,
    LocationPersonSeriesListComponent,
    LocationMapComponent,
    SeriesEditModalComponent,
    SeriesCreateModalComponent,
    SeriesBulkEditModalComponent,
    DatapointTableComponent
  ],
  providers: [

  ],
  imports: [
    CommonModule,
    RouterModule.forChild([]),
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    NgxPermissionsModule.forChild(),
    ModalModule,
    //TypeaheadModule,will make bugs or multiple implementation used
    PopoverModule,
    TooltipModule,
    PaginationModule,
    LaddaModule,
    NgxDatatableModule,
    BsDropdownModule,
    NgSelectModule,
    MyDataSharedModule
  ],
  exports: [
    DatapointTableComponent
  ]
})
export class DatapointListManageModule { }
