<table class="table table-responsive-md btn-table table-selectable table-sm table-with-subtable">
  <thead>
  <tr>
    <th class="w-150p">Advice group</th>
    <th class="w-150p">Category</th>
    <th class="">Description</th>
    <th class="w-150p text-right">Mark as done</th>
  </tr>
  </thead>
  <tbody>
  <ng-container *ngFor="let row of personAdviceGroupRows">
  <tr>
    <td><button class="btn btn-link btn-xs p-0 d-flex align-items-center text-left text-decoration-none" (click)="expandRow(row)"><i class="fa mr-2" [ngClass]="{'fa-plus': !row.expanded, 'fa-minus': row.expanded}"></i> {{row.personAdviceGroup.name}}</button></td>
    <td></td>
    <td>{{row.personAdviceGroup.description}}</td>
    <td></td>
  </tr>
  <tr class="subtable" [ngClass]="{'show': row.expanded}">
    <td colspan="4">
      <table class="table table-responsive-md btn-table table-selectable table-sm mt-0">
        <tbody>
        <tr *ngFor="let adviceRow of row.adviceRows">
          <td class="w-150p">{{adviceRow.personAdvice.name}}</td>
          <td class="w-150p">{{adviceRow.personAdvice.category}}</td>
          <td>{{adviceRow.personAdvice.description}}</td>
          <td class="w-150p text-right">
            <div class="checkbox">
              <input type="checkbox" [formGroup]="adviceRow.control.get('checked')">
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </td>
  </tr>
  </ng-container>
  </tbody>
</table>

<div class="col-12" *ngIf="!personAdviceGroupRows?.length">
  <div class="text-center" *ngIf="personAdviceGroupRows.length == 0">
    <i i18n>No data</i>
  </div>
</div>
