import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Subject } from 'rxjs';
import { Range } from '../model/model';
import {Moment} from 'moment';

export const DATE = new InjectionToken<Moment>('date');

@Injectable()
export class RangeStoreService {
  rangeUpdate$: Subject<Range> = new Subject<Range>();
  rangeError$: Subject<string> = new Subject<string>();

  constructor(
    @Inject(DATE) private _fromDate: Moment,
    @Inject(DATE) private _toDate: Moment
  ) {}

  get fromDate(): Moment {
    return this._fromDate;
  }

  get toDate(): Moment {
    return this._toDate;
  }

  clearRange() {
    this._fromDate = null;
    this._toDate = null;
    this.rangeUpdate$.next(null);
  }

  updateRange(fromDate: Moment = this._fromDate, toDate: Moment = this._toDate) {
    this._fromDate = fromDate;
    this._toDate = toDate;
    if (!this._fromDate || !this._toDate) {
      this.rangeUpdate$.next(null);
    } else {
      this.rangeUpdate$.next({ fromDate: this._fromDate ? this._fromDate.clone() : null, toDate: this._toDate ? this._toDate.clone() : null });
    }
  }

  rangeError(message: string) {
    this.rangeError$.next(message);
  }
}
