
<ngx-datatable
  #table
  class="material expandable"
  [draggable]="false"
  [columnMode]="'flex'"
  [headerHeight]="30"
  [footerHeight]="50"
  [reorderable]="false"
  rowHeight="auto"
  [scrollbarH]="false"
  [scrollbarV]="false"
  [rows]="rows"
  [externalPaging]="false"
  [externalSorting]="false"
  (treeAction)="onTreeAction($event)"
>
  <ngx-datatable-row-detail [rowHeight]="'auto'" #detailRow>
    <ng-template ngx-datatable-row-detail-template let-row="row">
      <mydata-view-response [questionnaireId]="questionnaire.id" [participantId]="row.id" [responseId]="row.selectedResponseId"></mydata-view-response>
    </ng-template>
  </ngx-datatable-row-detail>

  <ngx-datatable-column name="Name" i18n-name prop="person" [flexGrow]="1" [resizeable]="false" [sortable]="false">
    <ng-template let-value="value" let-row="row" let-expanded="expanded" let-treeStatus="treeStatus" let-onTreeAction="onTreeAction" ngx-datatable-cell-template>
      <div style="display: flex; align-items: center">
        <a class="btn btn-link btn-xs p-0" style="text-decoration: none" [routerLink]="null" (click)="onTreeAction()"
           [class.datatable-icon-right]="!expanded" [class.datatable-icon-down]="expanded" title="Expand/Collapse Row">
          {{value?.displayName}}
        </a>
      </div>
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column name="Updated" i18n-name prop="responses"  [flexGrow]="2" [resizeable]="true" [sortable]="true" [cellClass]="'versions-tabs'">
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
      <div class="versions" *ngIf="value">
        <ng-container *ngIf="value.length < 2">
          <div class="version-item">{{value[0].date | date: 'dd.MM.yyyy'}}</div>
        </ng-container>
        <ng-container *ngIf="value.length > 1">
          <div class="version-item" [ngClass]="response.id === row.selectedResponseId ? 'version-item active' : 'version-item'" *ngFor="let response of value; let i = index" (click)="viewResponse(row, i)">
            <span>Vastused {{value.length - i}}</span>
            <small class="text-muted">{{response.date | date: 'dd.MM.yyyy'}}</small>
          </div>
        </ng-container>
      </div>
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>

