import {ReplaySubject} from 'rxjs/internal/ReplaySubject';

export class Breadcrumb {
  name$ = new ReplaySubject<string>();

  constructor(name: string) {
    this.name$.next(name);
  }

  update(name: string) {
    this.name$.next(name);
  }
}
