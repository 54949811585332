import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormGroup, ValidationErrors} from '@angular/forms';
import {AddressService} from '../address.service';

const selector = 'core-address-street';
let nextId = 0;

@Component({
  selector: selector,
  templateUrl: './address-street.component.html',
  styles: [':host {display: block}']
})
export class AddressStreetComponent implements OnInit {
  // @HostBinding('class.address-input-group') classInputGroup = this.addressService.addressTypeahead != null;

  id = `${selector}-${nextId++}`;

  @Input()
  showErrors = true;

  form: FormGroup = this.addressService.getForm();
  formControl: AbstractControl = this.addressService.getAddressControl();
  searching$ = this.addressService.searching$;

  addressTypeahead = this.addressService.addressTypeahead;
  inputFormatter = this.addressService.inputFormatter;
  resultFormatter = this.addressService.resultFormatter;

  constructor(
    private addressService: AddressService
  ) { }

  ngOnInit() {
  }

  control(name?: string) {
    if (!name) {
      return this.form;
    }
    return this.form.get(name);
  }

  invalid(name: string, formGroup?) {
    if (!formGroup) {
      formGroup = this.form;
    }
    const control = formGroup.get(name);
    return control && control.invalid && (control.dirty || control.touched);
  }

  errors(name: string): ValidationErrors {
    const control = this.form.get(name);
    return control ? control.errors : null;
  }

  onItemSelect(addr): void {
    this.addressService.selectAddress(addr.item);
  }

}
