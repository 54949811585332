<div class="selectable-list">
  <div class="filter-bar filter-bar-min">
    <div class="row no-gutters">
      <div class="col">
        <h3 class="section-title" *ngIf="type !== 'FORMULA_SOURCE_TEMPLATE'" i18n>Questions</h3>
        <h3 class="section-title" *ngIf="type === 'FORMULA_SOURCE_TEMPLATE'" i18n>Formulas</h3>
      </div>
      <div class="col-auto text-right" *ngIf="!partialEdit"><button type="button" class=" btn btn-link btn-sm p-0"
                                               (click)="addField()"><i class="fal fa-plus"></i>&nbsp;<ng-container i18n>Add new</ng-container></button></div>
    </div>
  </div>
  <div class="custom-list" cdkDropList (cdkDropListDropped)="drop($event)">
    <div class="list-item draggable" cdkDrag [class.selected]="isSelected(field)" [style.pointer]="field.type !== 'FORMULA_INPUT'" *ngFor="let field of fields$ | async" (click)="selectField(field)" (mouseenter)="mouseEnter(field)" (mouseleave)="mouseLeave(field)">

<!--      <div class="form-row">-->
<!--        <div class="col-auto w-100">-->
<!--          <div class="d-flex">-->
<!--            <span class="title">{{personSeries.name}}</span>-->
<!--            <div class="ml-auto pl-3">-->
<!--              <div class="btn-remove-wrapper">-->
<!--                <button type="button" class="close" (click)="remove()" tooltip="Remove" i18n-tooltip>-->
<!--                  <span aria-hidden="true">&times;</span>-->
<!--                </button>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

      <div class="form-row">
          <div class="col-auto w-100">
            <div class="d-flex">
              <div>
                <span class="title" *ngIf="field.symbol"><ng-container *ngIf="field.symbol">{{field.symbol}}</ng-container><ng-container *ngIf="field.name && field.symbol !== field.name">:&nbsp;{{field.name}}</ng-container></span>
                <span class="title" *ngIf="!field.symbol">{{field.name}}</span>
              </div>
              <div class="ml-auto pl-3">
                <div class="btn-remove-wrapper" *ngIf="!partialEdit && field.type !== 'FORMULA_INPUT'">
                  <button type="button" class="close" (click)="removeField(field)" tooltip="Remove" i18n-tooltip placement="auto" >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
      </div>

      <div class="row w-100">
        <span class="col-auto">
          <label class="light mr-2 mb-0" i18n>Type</label>
          <span class="text-light" *ngIf="field.type === 'QUESTION'" i18n>Question</span>
          <span class="text-light" *ngIf="field.type === 'FORMULA' && field.formulaType === 'EXPRESSION'" i18n>Formula expression</span>
          <span class="text-light" *ngIf="field.type === 'FORMULA' && field.formulaType === 'MAPPING'" i18n>Mapping</span>
          <span class="text-light" *ngIf="field.type === 'FORMULA_INPUT'" i18n>Variable</span>
        </span>
        <span class="col-auto" *ngIf="field.type === 'FORMULA' && field.variables">
          <label class="light mr-2 mb-0" i18n>Variables</label>
          <span class="text-light">{{field.variables.join(', ')}}</span>
        </span>
        <span class="col-auto" *ngIf="field.type === 'QUESTIONNAIRE' || field.type === 'FORMULA_INPUT'">
          <label class="light mr-2 mb-0" i18n>Value type</label>
          <span class="text-light" *ngIf="field.valueType === 'VALUE'" i18n>Value</span>
          <span class="text-light" *ngIf="field.valueType === 'SELECT'" i18n>Multiple choice</span>
          <span class="text-light" *ngIf="field.valueType === 'PERSON_SERIES'" i18n>Datapoint</span>
          <span class="text-light" *ngIf="field.valueType === 'PERSON_PARAMETER'" i18n>Parameter</span>
        </span>
      </div>
      <span class="drag-handler" cdkDragHandle></span>

    </div>
  </div>
</div>
