import {OlMapWrapper, OlOverlayWrapper} from '@smartencity/core';
import {TallinnService} from '../service/tallinn.service';

export class AirQualityStationOlMarkerWrapper extends OlOverlayWrapper {

  public selected = false;

  private airQualityStation: any;

  private tallinnService: TallinnService;

  constructor(airQualityStation: any, map: OlMapWrapper, tallinnService: TallinnService) {
    super({
      title: airQualityStation.name,
      position: [airQualityStation.lng, airQualityStation.lat],
      className: 'air-quality-station-marker-wrapper',
      stopEvent: false
    });
    this.airQualityStation = airQualityStation;
    this.tallinnService = tallinnService;
    this.setMap(map.getMap());
  }


  public draw() {
    if (!this.div) {
      this.div = document.createElement('DIV');
      this.div.title = this.airQualityStation.readableAddress;
      this.div.style.cursor = 'pointer';
      this.div.classList.add('pointer-weather-station');

      this.div.addEventListener('click', () => {
        this.tallinnService.airQualityStationSelectedSource.next(this.airQualityStation);
      });
    }

    if (this.selected) {
      this.div.classList.add("active");
    } else {
      this.div.classList.remove("active");
    }

  }

}
