<div class="modal-header">
  <h5 class="modal-title w-100" *ngIf="!groupSource?.id" i18n>Create a new group</h5>
  <h5 class="modal-title w-100" *ngIf="groupSource?.id" i18n>Edit group</h5>
  <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
    <span class="fal fa-times"></span>
  </button>
</div>
<div class="modal-body" [formGroup]="form">
  <div *ngIf="showErrors && (form.dirty || form.touched) && !form.valid" class="alert alert-danger">
    <div i18n>Form is invalid</div>
  </div>

  <section>
    <div class="form-group">
      <label><b i18n>Group metadata</b></label>
      <div class="form-row">
        <div class="col-8">
          <label [attr.for]="id + '-name'" i18n>Group name</label>
          <input type="text" class="form-control" [class.is-invalid]="invalid('name')" formControlName="name" [id]="id + '-name'">
          <small *ngIf="invalid('name') && errors('name')?.required" class="invalid-feedback d-block" i18n>Required</small>
        </div>
        <div class="col-4">
          <label i18n [attr.for]="id + '-unit'">Unit</label>
          <input type="text" class="form-control" [class.is-invalid]="invalid('unit')" formControlName="unit" [id]="id + '-unit'">
          <small *ngIf="invalid('unit') && errors('unit')?.required" class="invalid-feedback d-block" i18n>Required</small>
        </div>
      </div>
    </div>
    <div class="form-group">
      <div class="form-row">
        <div class="col">
          <label i18n [attr.for]="id + '-grouping-tag'">Grouping tag</label>
          <input type="text" class="form-control" [class.is-invalid]="invalid('groupingTag')"
                 formControlName="groupingTag" autocomplete="off"
                 [typeahead]="groupingTags | async" [typeaheadMinLength]="0" core-uppercase [id]="id + '-grouping-tag'"
          >
          <small *ngIf="invalid('groupingTag') && errors('groupingTag')?.required" class="invalid-feedback d-block" i18n>Required</small>
        </div>
        <div class="col">
          <label i18n>Series type</label>
          <ng-select placeholder="Series type" i18n-placeholder [class.is-invalid]="invalid('seriesType')" [items]="seriesTypes" bindValue="value" bindLabel="label" formControlName="seriesType"></ng-select>
          <small *ngIf="invalid('seriesType') && errors('seriesType')?.required" class="invalid-feedback d-block" i18n>Required</small>
        </div>
      </div>
    </div>

    <div class="form-group">
      <label><b i18n>Location</b></label>
      <div class="form-group">
        <div class="custom-control custom-radio custom-control-inline">
          <input type="radio" value="ADDRESS" class="custom-control-input" [class.is-invalid]="invalid('locationType')" [id]="id + '-ltcheck-address'" formControlName="locationType">
          <label class="custom-control-label" [attr.for]="id + '-ltcheck-address'" i18n>Address</label>
        </div>
        <div class="custom-control custom-radio custom-control-inline">
          <input type="radio" value="UUID" class="custom-control-input" [class.is-invalid]="invalid('locationType')" [id]="id + '-ltcheck-uuid'" formControlName="locationType">
          <label class="custom-control-label" [attr.for]="id + '-ltcheck-uuid'" i18n>UUID</label>
        </div>
        <div class="custom-control custom-radio custom-control-inline">
          <input type="radio" value="COORDS" class="custom-control-input" [class.is-invalid]="invalid('locationType')" [id]="id + '-ltcheck-coords'" value="COORDS" formControlName="locationType">
          <label class="custom-control-label" [attr.for]="id + '-ltcheck-coords'" i18n>Coords</label>
        </div>
        <div *ngIf="form.get('locationType').invalid">
          <small *ngIf="invalid('locationType') && errors('locationType')?.required" class="invalid-feedback d-block" i18n>Required</small>
        </div>
        <ng-container *ngIf="form.get('locationType').value == 'ADDRESS'">
          <core-address [form]="form">
            <ng-template mydataAddressTempate>
              <div class="form-row">
                <div class="col">
                  <core-address-street class="m-2" [showErrors]="showErrors"></core-address-street>
                </div>
                <div class="col">
                  <core-address-apartment class="m-2" [showErrors]="showErrors"></core-address-apartment>
                </div>
                <div class="col">
                  <core-address-room class="m-2" [showErrors]="showErrors"></core-address-room>
                </div>
              </div>
            </ng-template>
          </core-address>
        </ng-container>
        <div class="form-row" [hidden]="form.get('locationType').value != 'UUID'">
          <div class="col">
            <label i18n [attr.for]="id + '-uuid'">UUID</label>
            <input type="text" class="form-control" [class.is-invalid]="invalid('uuid')" formControlName="uuid" [id]="id + '-uuid'">
            <small *ngIf="invalid('uuid') && errors('uuid')?.required" class="invalid-feedback d-block" i18n>Required</small>
          </div>
        </div>

        <div class="form-row" [hidden]="form.get('locationType').value != 'COORDS'">
          <div class="col">
            <label i18n [attr.for]="id + '-lat'">Lat</label>
            <input type="text" class="form-control" [class.is-invalid]="invalid('lat')" formControlName="lat" [id]="id + '-lat'">
            <small *ngIf="invalid('lat') && errors('lat')?.required" class="invalid-feedback d-block" i18n>Required</small>
            <small *ngIf="invalid('lat') && errors('lat')?.invalidLatitude" class="invalid-feedback d-block" i18n>Invalid latitude, must be between
              [{{errors('lat')?.invalidLatitude.min|number:'.1':'en'}}, {{errors('lat')?.invalidLatitude.max|number:'.1':'en'}}]</small>
          </div>
          <div class="col">
            <label i18n [attr.for]="id + '-lng'">Lng</label>
            <input type="text" class="form-control" [class.is-invalid]="invalid('lng')" formControlName="lng" [id]="id + '-lng'">
            <small *ngIf="invalid('lng') && errors('lng')?.required" class="invalid-feedback d-block" i18n>Required</small>
            <small *ngIf="invalid('lng') && errors('lng')?.invalidLongitude" class="invalid-feedback d-block" i18n>Invalid longitude, must be between
              [{{errors('lng')?.invalidLongitude.min|number:'.1':'en'}}, {{errors('lng')?.invalidLongitude.max|number:'.1':'en'}}]</small>
          </div>
        </div>
      </div>
    </div>

    <div class="form-group">
      <div class="form-row">
        <div class="col-sm-12 col-md-3">
          <div class="form-group" formControlName="aggregationType" btnRadioGroup>
            <label i18n>Aggregation type</label>
            <div class="d-block">
              <div class="btn-group btn-group-sm btn-separate" role="group">
                <button type="button" class="btn btn-outline-lighter" [btnRadio]="'SUM'" i18n>Sum</button>
                <button type="button" class="btn btn-outline-lighter" [btnRadio]="'AVERAGE'" i18n>Average</button>
              </div>
            </div>
            <small *ngIf="invalid('aggregationType') && errors('aggregationType')?.required" class="invalid-feedback d-block" i18n>Required</small>
          </div>
        </div>
        <div class="col-md-3 col-sm-12">
          <div class="form-group" btnRadioGroup>
            <label i18n>Generate history</label>
            <div class="checkbox">
              <input type="checkbox" formControlName="generateHistory" [id]="id + '-generate-history'">
              <label [attr.for]="id + '-generate-history'" i18n>Calculate historical values for this group</label>
            </div>
            <small *ngIf="invalid('generateHistory') && errors('generateHistory')?.required" class="invalid-feedback d-block" i18n>Required</small>
          </div>
        </div>
        <div class="col-md-6 col-sm-12">
          <label i18n>Sampling frequency</label>
          <ng-select placeholder="Sampling frequency" i18n-placeholder [class.is-invalid]="invalid('samplingType')" [items]="samplingTypeOptions" bindValue="value" bindLabel="label" formControlName="samplingType"></ng-select>
          <small *ngIf="invalid('samplingType') && errors('samplingType')?.required" class="invalid-feedback d-block" i18n>Required</small>
        </div>
      </div>
    </div>
  </section>

  <section>
    <h3 i18n>Group data</h3>
    <div class="alert alert-info" [hidden]="(targetUnit$ | async)" i18n>The selected datapoints have different units. You must specify coefficients for conversion to the common group unit.</div>
    <div class="selectable-list">
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="filter-bar">
            <div class="input-group form-search">
              <input type="text" class="form-control" [formControl]="searchControl" placeholder="Type to search..." i18n-placeholder>
              <div class="input-group-append">
                <span class="clear-search" (click)="clearSearch()" [ngClass]="{'active': searchControl?.value?.length > 0 }">
                  <i class="fal fa-times"></i>
                </span>
                <span class="input-group-text">
                  <i class="fal fa-search"></i>
                </span>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <button type="button" class="btn-sm btn-link p-0" (click)="addAll()" i18n>Add all</button>
              </div>
              <div class="col text-right">
                <span class="text-light" i18n>{pageResponse?.totalElements, plural, =1 {{{pageResponse?.totalElements}} result} other {{{pageResponse?.totalElements}} results}}</span>
              </div>
            </div>
          </div>
          <div class="custom-list">
            <div class="list-item" [class.selected]="isSelected(personSeries)" *ngFor="let personSeries of pageResponse?.content" (click)="addItem(personSeries)">
              <span class="title">{{personSeries.name}}</span>
              <div class="row">
                  <span class="col-auto">
                    <label class="light mr-2 mb-0" i18n>Series type</label>
                    <span class="text-light">{{personSeries.seriesType | label}}</span>
                  </span>
                <span class="col-auto">
                  <label class="light mr-2 mb-0">Asukoht</label>
                  <span class="text-light">
                    {{personSeries.location}}
                  </span>
                </span>
              </div>
            </div>
          </div>
          <div class="footer-bar">
            <div class="row">
              <div class="col">
                <pagination
                  class="pagination-borderless"
                  *ngIf="pageResponse?.totalPages > 1"
                  [totalItems]="pageResponse?.totalElements"
                  [itemsPerPage]="pageResponse?.size"
                  [maxSize]="5"
                  [rotate]="true"
                  [formControl]="pageControl"
                  previousText="&lsaquo;"
                  nextText="&rsaquo;"
                  firstText="&laquo;"
                  lastText="&raquo;"
                >
                </pagination>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-1 d-flex justify-content-center  p-0">
          <span class="exchange-icon">
            <i class="fa fa-exchange"></i>
          </span>
        </div>
        <div class="col-12 col-md-5">
          <div class="filter-bar">
            <h3 i18n>Selected data</h3>
            <div class="row">
              <div class="col">
                <button type="button" class="btn-sm btn-link p-0" *ngIf="rows.length" (click)="removeAll()" i18n>Remove all</button>
              </div>
              <div class="col text-right">
                <span class="text-light" i18n>{rows.length, plural, =1 {{{rows.length}} item} other {{{rows.length}} items}}</span>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <label class="light mr-2 mb-0" i18n>Period specification</label>
                <core-custom-dropdown-control [options]="windowOperationTypeOptions" [formControl]="windowOperationTypeFormControl" placeholder="Select timeframe type" i18n-placeholder></core-custom-dropdown-control>
              </div>
            </div>
          </div>
          <div class="custom-list" cdkDropList>
            <mydata-groups-person-series-row *ngFor="let row of rows; let i=index" [groupSourcePersonSeriesItem]="row" [commonUnit]="form.get('unit').value" (remove)="removeItem(row)"></mydata-groups-person-series-row>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary btn-sm" aria-label="Close" (click)="close()" i18n>Cancel</button>
  <button type="button" class="btn btn-primary btn-sm" (click)="save()" [ladda]="progress" i18n>Save</button>
</div>
