<div class="d-block d-md-none text-right" *ngIf="page?.totalElements">
  <span i18n>Total</span>: {{page?.totalElements}}
</div>
<ngx-datatable
      *ngIf="rows"
      #table
      class="material expandable"
      [draggable]="false"
      [columnMode]="'flex'"
      [headerHeight]="30"
      [footerHeight]="50"
      [reorderable]="false"
      rowHeight="auto"
      [scrollbarH]="false"
      [scrollbarV]="false"
      [rows]="rows"
      [externalPaging]="true"
      [externalSorting]="true"
      [limit]="page?.size"
      [count]="page?.totalElements"
      [sorts]="defaultSorts"
      [selectionType]="'checkbox'"
      [offset]="page?.page"
      (page)="setPage($event)"
      (sort)="setSorts($event)"
      (select)="onSelect($event)"
      [messages]="{emptyMessage: ''}"
    >
      <ngx-datatable-row-detail [rowHeight]="190" #detailRow>
        <ng-template
          let-row="row"
          let-ownerType="row.ownerType"
          let-ps="row.series.personSeries"
          let-confirmationStatus="row.confirmationStatus"
          let-expanded="expanded" ngx-datatable-row-detail-template>
          <div style="font-size:14px; padding: 0.7rem">

            <div class="row">
              <div class="col-6"><label i18n>Datapoint Name</label></div>
              <div class="col-6">{{row.name}}<span *ngIf="row?.description">&nbsp;<i class="fa fa-info-circle" [tooltip]="row?.description"></i></span></div>
            </div>

            <div class="row">
              <div class="col-6"><label i18n>Owner</label></div>
              <div class="col-6">
                <div>
                  <ng-container *ngIf="ownerType == 'OWNER' || ownerType == 'DATA_OWNER'" i18n>Myself</ng-container>
                  <ng-container *ngIf="ownerType != 'OWNER' && ownerType != 'DATA_OWNER'">
                    <span class="text-nowrap">{{ps?.dataOwnerPerson?.displayName}}</span>&nbsp;({{ps?.dataOwnerPerson | regNo}})
                  </ng-container>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-6"><label i18n>Activity Date</label></div>
              <div class="col-6">{{row.updatedAt | date:'dd. MMMM yyyy'}}</div>
            </div>

            <div class="row">
              <div class="col-6"><label i18n>Status</label></div>
              <div class="col-6">
                <span *ngIf="confirmationStatus == 'PENDING'" class="text-info" i18n>Pending</span>
                <span *ngIf="confirmationStatus == 'ACCEPTED'" class="text-success" i18n>Accepted</span>
                <span *ngIf="confirmationStatus == 'DECLINED'" class="text-warning" i18n>Declined</span>
                <span *ngIf="confirmationStatus == 'CANCELLED'" class="text-warning" i18n>Cancelled</span>
              </div>
            </div>
            <div class="row pt-3">
              <button type="button" class="btn btn-sm btn-link no-caret" (click)="editSeries(row.series)" *ngIf="row.confirmationStatus != 'PENDING'">
                <i class="fal fa-edit mr-2"></i>
                <ng-container i18n>Edit</ng-container>
              </button>
              <button type="button" class="btn btn-sm btn-link no-caret" (click)="declineOwnership(row.series)" *ngIf="row.confirmationStatus == 'PENDING'">
                <i class="fal fa-times-circle mr-2"></i>
                <ng-container i18n>Decline</ng-container>
              </button>
              <button type="button" class="btn btn-sm btn-link no-caret" (click)="acceptOwnership(row.series)" *ngIf="row.confirmationStatus == 'PENDING'">
                <i class="fal fa-check-circle mr-2"></i>
                <ng-container i18n>Accept</ng-container>
              </button>
              <button type="button" class="btn btn-sm btn-link no-caret" (click)="declineOwnership(row.series)" *ngIf="row.confirmationStatus == 'ACCEPTED'">
                <i class="fal fa-trash mr-2"></i>
                <ng-container i18n>Cancel</ng-container>
              </button>
              <button type="button" class="btn btn-sm btn-link no-caret" (click)="archiveSeries(row.series)" *ngIf="row.confirmationStatus == 'CANCELLED'">
                <i class="fal fa-trash mr-2"></i>
                <ng-container i18n>Acknowledge</ng-container>
              </button>
              <button type="button" class="btn btn-sm btn-link no-caret" (click)="archiveSeries(row.series)" *ngIf="row.confirmationStatus == 'DECLINED'">
                <i class="fal fa-trash mr-2"></i>
                <ng-container i18n>Archive</ng-container>
              </button>
            </div>
          </div>
        </ng-template>
      </ngx-datatable-row-detail>

      <ngx-datatable-column
        *ngIf="columns.check"
        [width]="50"
        [sortable]="false"
        [canAutoResize]="false"
        [draggable]="false"
        [resizeable]="false"
        >
        <ng-template
          ngx-datatable-header-template
          let-value="value"
          let-allRowsSelected="allRowsSelected"
          let-selectFn="selectFn"
        >
          <div class="checkbox position-absolute">
            <input type="checkbox" [ngModel]="allRowsSelected" (change)="selectFn(!allRowsSelected)"/>
          </div>
        </ng-template>
        <ng-template
          ngx-datatable-cell-template
          let-value="value"
          let-isSelected="isSelected"
          let-onCheckboxChangeFn="onCheckboxChangeFn"
        >
          <div class="checkbox">
            <input type="checkbox" [ngModel]="isSelected" (change)="onCheckboxChangeFn($event)"/>
          </div>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column *ngIf="columns.name" name="Datapoint Name" i18n-name prop="name" [flexGrow]="1" [resizeable]="false" [sortable]="true">
        <ng-template let-value="value" let-showOnCityPortal="row.showOnCityPortal" let-row="row" ngx-datatable-cell-template>
          <div>
            <span>{{value}}</span>
            <span *ngIf="isCityUser && showOnCityPortal">&nbsp;<i class="fa fa-map-marker-check"></i></span>
            <span *ngIf="row?.description">&nbsp;<i class="fa fa-info-circle" [tooltip]="row?.description"></i></span>
          </div>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column *ngIf="columns.address" name="Address" i18n-name [flexGrow]="1" [resizeable]="false" [sortable]="false">
        <ng-template let-ps="row.series.personSeries" ngx-datatable-cell-template>
          <ng-container *ngIf="ps.locationType == 'ADDRESS'">
            {{ps.address}}<ng-container *ngIf="ps.apartment"> - {{ps.apartment}}</ng-container>
          </ng-container>
          <ng-container *ngIf="ps.locationType == 'COORDS'">
            {{ps.lat}}-{{ps.lng}}
          </ng-container>
          <ng-container *ngIf="ps.locationType == 'UUID'">
            {{ps.uuid}}
          </ng-container>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column *ngIf="columns.owner" name="Owner" i18n-name [flexGrow]="1" [resizeable]="false" [sortable]="false">
        <ng-template let-ownerType="row.ownerType" let-series="row.series" ngx-datatable-cell-template>
          <ng-template #creatorPopover>
            <ng-container *ngIf="ownerType != 'CONSENT'">
              <h6 i18n>Created by</h6>
              {{series?.personSeries?.createdByPerson?.displayName}}
            </ng-container>
            <ng-container *ngIf="ownerType == 'CONSENT'">
              <h6 i18n>Shared by</h6>
              {{series?.dataOwnerPerson?.displayName}}&nbsp;({{series?.dataOwnerPerson | regNo}})
            </ng-container>
          </ng-template>
          <a [routerLink]="null" [popover]="creatorPopover" triggers="hover" container="body">
            <ng-container *ngIf="ownerType == 'OWNER' || ownerType == 'DATA_OWNER'" i18n>Myself</ng-container>
            <ng-container *ngIf="ownerType != 'OWNER' && ownerType != 'DATA_OWNER'">
              <span class="text-nowrap">{{series?.dataOwnerPerson?.displayName}}</span>&nbsp;({{series?.dataOwnerPerson | regNo}})
            </ng-container>
          </a>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column *ngIf="columns.activityDate" name="Activity Date" i18n-name prop="updatedAt" [flexGrow]="1" [resizeable]="false" [sortable]="true">
        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
          {{value | date:'dd. MMMM yyyy'}}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column *ngIf="columns.status" name="Status" i18n-name prop="confirmationStatus" [flexGrow]="1" [resizeable]="false" [sortable]="false">
        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
          <span *ngIf="value == 'PENDING'" class="badge badge-warning rounded-pill p-2" i18n>Pending</span>
          <span *ngIf="value == 'ACCEPTED'" class="badge badge-success rounded-pill p-2" i18n>Accepted</span>
          <span *ngIf="value == 'DECLINED'" class="badge badge-danger rounded-pill p-2" i18n>Declined</span>
          <span *ngIf="value == 'CANCELLED'" class="badge badge-dark rounded-pill p-2" i18n>Cancelled</span>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column *ngIf="columns.actions" name="" [flexGrow]="2" [resizeable]="false" [sortable]="false" headerClass="justify-content-end" cellClass="justify-content-end">
        <ng-template let-ownerType="row.ownerType" let-confirmationStatus="row.confirmationStatus" let-series="row.series" let-row="row" let-ps="row.series.personSeries" ngx-datatable-cell-template>
          <div class="d-flex flex-row justify-content-center align-middle">
              <div style="display: inline-block; position: relative;" class="align-self-center" *ngIf="isOwner(series)" (click)="showConsents(series)" tooltip="Shared data" i18n-tooltip>
                <share-person-item-list [persons]="getConsentPersons(series)"></share-person-item-list>
              </div>

              <div class="btn-group btn-more" dropdown>
                <button type="button" class="btn btn-icon dropdown-toggle" dropdownToggle><i class="far fa-ellipsis-v"></i></button>
                <div class="dropdown-menu dropdown-menu-right" *dropdownMenu>
                  <a class="dropdown-item" *ngIf="isOwner(series)" (click)="showConsents(series)"><i class="fal fa-share"></i>&nbsp;<ng-container i18n>Share data</ng-container></a>
                  <a class="dropdown-item" *ngIf="isOwner(series)" (click)="setRowDataOwner(series)"><i class="fal fa-user"></i>&nbsp;<ng-container i18n>Set new data owner</ng-container></a>
                  <a class="dropdown-item" *ngIf="!isGroupConsent(series) && confirmationStatus != 'PENDING'" (click)="editSeries(series)"><i class="fal fa-edit"></i>&nbsp;<ng-container i18n>Edit</ng-container></a>
                  <a *ngIf="confirmationStatus == 'PENDING'" class="dropdown-item" (click)="declineOwnership(series)"><i class="fal fa-times-circle"></i>&nbsp;<ng-container i18n>Decline</ng-container></a>
                  <a *ngIf="confirmationStatus == 'PENDING'" class="dropdown-item" (click)="acceptOwnership(series)"><i class="fal fa-check-circle"></i>&nbsp;<ng-container i18n>Accept</ng-container></a>
                  <a *ngIf="confirmationStatus == 'ACCEPTED'" class="dropdown-item " (click)="declineOwnership(series)"><i class="fal fa-trash"></i>&nbsp;<ng-container i18n>Cancel data</ng-container></a>
                  <a *ngIf="confirmationStatus == 'ACCEPTED'" class="dropdown-item " (click)="declineOwnershipAndArchive(series)"><i class="fal fa-trash"></i>&nbsp;<ng-container i18n>Cancel and archive all data</ng-container></a>
                  <a *ngIf="confirmationStatus == 'CANCELLED'" class="dropdown-item" (click)="archiveSeries(series)"><i class="fal fa-trash"></i>&nbsp;<ng-container i18n>Acknowledge</ng-container></a>
                  <a *ngIf="confirmationStatus == 'DECLINED'" class="dropdown-item" (click)="archiveSeries(series)"><i class="fal fa-trash"></i>&nbsp;<ng-container i18n>Archive</ng-container></a>
                  <ng-container *ngIf="isOwner(series) && (ps.sourceUrl || ps.targetUrl)">
                    <div class="dropdown-divider"></div>
                    <a *ngIf="ps.sourceUrl" href="{{ps.sourceUrl}}" target="_blank" class="dropdown-item" i18n><i class="fal fa-external-link-alt"></i> Source device</a>
                    <a *ngIf="ps.targetUrl" href="{{ps.targetUrl}}" target="_blank" class="dropdown-item" i18n><i class="fal fa-external-link-alt"></i> Target device</a>
                  </ng-container>
                </div>
              </div>
          </div>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column
        *ngIf="columns.detailToggler"
        [width]="50"
        [resizeable]="false"
        [sortable]="false"
        [draggable]="false"
        [canAutoResize]="false"
        headerClass="justify-content-end"
        cellClass="justify-content-end"
      >
        <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
          <a
            [routerLink]="null"
            [class.datatable-icon-right]="!expanded"
            [class.datatable-icon-down]="expanded"
            title="Expand/Collapse Row"
            (click)="toggleExpandRow(row)"
          >
          </a>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
