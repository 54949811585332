import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CoreModule} from '@smartencity/core';
import {NgxPermissionsModule} from 'ngx-permissions';
import {ResponseListComponent} from './response-list.component';
import {ModalModule} from 'ngx-bootstrap/modal';
import {PaginationModule} from 'ngx-bootstrap/pagination';
import {TypeaheadModule} from 'ngx-bootstrap/typeahead';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {TemplateListComponent} from './template-list.component';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import { FormulaUpdateErrorsModal } from './formula-update-errors-modal/formula-update-errors-modal.component';
import {LaddaModule} from 'angular2-ladda';

@NgModule({
  declarations: [
    ResponseListComponent,
    TemplateListComponent,
    FormulaUpdateErrorsModal
  ],
  entryComponents: [],
  providers: [

  ],
  imports: [
    CommonModule,
    RouterModule.forChild([]),
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    NgxPermissionsModule.forChild(),
    ModalModule,
    TypeaheadModule,
    PaginationModule,
    NgxDatatableModule,
    BsDropdownModule,
    TooltipModule,
    LaddaModule
  ],
  exports: [
    TemplateListComponent
  ]
})
export class FormulaModule { }
