import {Injectable, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs/internal/Subject';

@Injectable()
export class ResponseService implements OnDestroy {
  private ngDestroy = new Subject<void>();

  constructor() {

  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }
}
