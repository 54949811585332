import OverlayView = google.maps.OverlayView;
import MVCObject = google.maps.MVCObject;

export class CrowdInsightsAreaPopover extends OverlayView {

  private containerDiv_: HTMLElement;

  private contentDiv_: HTMLElement;

  private popoverDiv_: HTMLElement;

  private popoverTimeout: any;

  private open_ = false;

  constructor() {
    super();
    this.open_ = false;
    this.buildDom();
  }
  onAdd() {
    if (!this.containerDiv_) {
      this.buildDom();
    }

    let panes = this.getPanes();
    if (panes) {
      panes.overlayMouseTarget.appendChild(this.containerDiv_);
    }
  }

  isOpen(): boolean {
    return this.open_;
  }

  private buildDom(): void {
    let container = this.containerDiv_ = document.createElement('DIV');
    this.containerDiv_.style.position = 'absolute';
    this.containerDiv_.style.cursor = 'pointer';
    this.containerDiv_.style.position = 'absolute';
    this.containerDiv_.classList.add('custom-marker-layer');
    this.containerDiv_.style.paddingLeft = '0px';

    let content = this.contentDiv_ = document.createElement('DIV');
    content.style.position = 'relative';
    container.appendChild(content);
  }

  open(map: google.maps.Map, anchor: MVCObject): void {
    this.open_ = true;

    this.updateContent();

    if (map) {
      this.setMap(map);
    }

    if (anchor) {
      this.set('anchor', anchor);
      this.bindTo('position', anchor);
    }

    this.showContent();
    this.redraw();

    setTimeout(() => {
      this.applyPopover();
      this.panToView();
    });
  }

  private showContent(): void {
    this.containerDiv_.style.display = '';
  }

  private applyPopover(): void {
    if (this.popoverTimeout) {
      this.popoverTimeout = null;
    }

    let el = this.popoverDiv_;

    ($(el) as any).popover('dispose');
    ($(el) as any).popover({
      placement: 'top',
      animation: false,
      html: false,
      boundary: 'window'
    });
    ($(el) as any).popover('show');
  }

  private removePopover(): void {
    $('.popover.show').remove();
    if (this.popoverDiv_) {
      this.popoverDiv_.remove();
      this.popoverDiv_ = null;
    }

  }

  private hideContent(): void {
    this.containerDiv_.style.display = 'none';
  }

  private redraw(): void {
    this.draw();
  }

  private updateContent(): void {
    if (!this.contentDiv_) {
      return;
    }

    let content = this.get('content');
    if (content) {
      const popoverDiv = this.popoverDiv_ = this.htmlToDocumentFragment(`<div data-toggle="popover" data-placement="top" data-content="${content}"></div>`);
      this.contentDiv_.appendChild(popoverDiv);
    }

    this.redraw();
  }

  private htmlToDocumentFragment(htmlString: string): any {
    let tempDiv = document.createElement('DIV');
    tempDiv.innerHTML = htmlString;
    if (tempDiv.childNodes.length == 1) {
      return tempDiv.removeChild(tempDiv.firstChild);
    } else {
      let fragment = document.createDocumentFragment();
      fragment.appendChild(tempDiv.firstChild);

      return fragment;
    }



  }

  private panToView(): void {
    //TODO:
  }

  public draw() {
    const projection = this.getProjection();
    if (!projection) {
      return;
    }

    const latLng = this.get('position');
    if (!latLng) {
      return;
    }

    let pos = projection.fromLatLngToDivPixel(latLng);

    this.containerDiv_.style.top = pos.y + 'px';
    this.containerDiv_.style.left = pos.x + 'px';
  }


  onRemove(): void {
    // if (this.containerDiv_ && this.containerDiv_.parentNode) {
    //   this.containerDiv_.parentNode.removeChild(this.containerDiv_);
    // }
  }

  close(): void {
    if (this.containerDiv_) {
      this.removePopover();
      this.hideContent();

    }

    this.open_ = false;
  }
}
