<ng-template #navActions>
  <core-compare-button class="d-lg-inline-block"></core-compare-button>
  <button type="button" class="btn btn-sm btn-primary d-lg-inline-block d-none" (click)="createSeries()">
    <i class="fal fa-plus"></i>&nbsp;<span i18n>Create new datapoint</span>
  </button>

  <div class="btn-floating-mobile-container">
    <button type="button" class="btn btn-sm btn-primary btn-floating-mobile" (click)="createSeries()">
      <i class="fal fa-plus fa-2x"></i>
    </button>

    <core-compare-button></core-compare-button>
  </div>
</ng-template>

<ng-container *ngIf="view ==='chart-list'">
  <virtual-scroller #chartScroll
                    [items]="items"
                    [parentScroll]="chartScroll.window"
                    [scrollDebounceTime]="0"
                    [bufferAmount]="bufferRows * 2"
                    (vsChange)="chartVsChange($event)">
    <!-- <pagination
      #chartPaginationComponent
      class="pagination-borderless mb-3 mt-1"
      *ngIf="items.length && (pageResponse$ | async)?.totalPages > 1"
      [totalItems]="(pageResponse$ | async)?.totalElements"
      [itemsPerPage]="(pageResponse$ | async)?.size * pagesPerVsPage"
      (pageChanged)="pageChanged($event)"
      [maxSize]="10"
      [rotate]="true"
      previousText="&lsaquo;"
      nextText="&rsaquo;"
      firstText="&laquo;"
      lastText="&raquo;"
    >
    </pagination> -->
    <div class="chart-list" #container>
      <mydata-datapoint-chart [sourceOwnerSeries]="item"
                              [showOnMap]="isCityUser && item?.personSeries?.showOnCityPortal"
                              [range]="globalRange$ | async"
                              [globalAggregationGroupingType]="globalAggregationGroupingType$ | async"
                              *ngFor="let item of chartScroll.viewPortItems" (datapointChanged)="reloadDatapoint($event)"></mydata-datapoint-chart>
    </div>

  </virtual-scroller>
</ng-container>
<ng-container *ngIf="view ==='item-list'">
  <virtual-scroller #listScroll [items]="items" [parentScroll]="listScroll.window" [scrollDebounceTime]="0" [bufferAmount]="bufferRows * 2" (vsChange)="chartVsChange($event)">
    <!-- <pagination
      #listPaginationComponent
      class="pagination-borderless mb-3 mt-1"
      *ngIf="items.length && (pageResponse$ | async)?.totalPages > 1"
      [totalItems]="(pageResponse$ | async)?.totalElements"
      [itemsPerPage]="(pageResponse$ | async)?.size * pagesPerVsPage"
      (pageChanged)="pageChanged($event)"
      [maxSize]="10"
      [rotate]="true"
      previousText="&lsaquo;"
      nextText="&rsaquo;"
      firstText="&laquo;"
      lastText="&raquo;"
    >
    </pagination> -->
    <div class="list list-border" #container>
      <app-datapoint-item [sourceOwnerSeries]="item" [showOnMap]="isCityUser && item?.personSeries?.showOnCityPortal" *ngFor="let item of listScroll.viewPortItems"></app-datapoint-item>
    </div>

  </virtual-scroller>
</ng-container>

<div *ngIf="vsStartIndex > 0" style="position: fixed; right: 20px; bottom: 20px;">
  <button style="font-size: 2rem;" class="btn btn-scroll-top" tooltip="Scroll to top" i18n-tooltip (click)="scrollToTop()"><i class="fas fa-arrow-to-top"></i></button>
</div>

