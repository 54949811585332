import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {CoreConfig, SMARTENCITY_CORE_CONFIG} from '@smartencity/core';

@Injectable({
  providedIn: 'root'
})
export class CityServicesApiService {

  constructor(private http: HttpClient,
              @Inject(SMARTENCITY_CORE_CONFIG) private config: CoreConfig) {

  }

  public getCityServices(): Observable<any> {

    return this.http.get(this.config.cityApiUrl + '/city-services');
  }

}
