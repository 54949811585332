import {Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {
  UserService,
  AuthService,
  LocationService,
  PersonSeriesGroup, PreventCloseModalService
} from '@smartencity/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject} from 'rxjs/internal/Subject';
import {
  take,
  takeUntil
} from 'rxjs/operators';

import {TenantSelectService} from '../../services/tenant-select.service';
import {BsModalService} from 'ngx-bootstrap/modal';
import {MyQuestionnairesService} from '../questionnaires/my-questionnaires.service';
import {ToastrService} from 'ngx-toastr';
import {NavbarService} from '../navbar/navbar.service';
import {LocationListService} from '../../services/location-list.service';
import {SharedKeywordStore} from '../../services/shared-search-keyword-store.service';
import {
  EditDatapointGroupModalComponent
} from '../datapoint-groups-management/edit-datapoint-group-modal/edit-datapoint-group-modal.component';
import {DatapointGroupsIndexService} from './datapoint-groups-index.service';
import { SMARTENCITY_MYDATA_CONFIG } from '../../injection-tokens';
import { MyDataConfig } from '../../mydata-config.model';
import { Mandate } from '@smartencity/core';
import {PersonSeriesGroupApiService} from '../../http/person-series-group-api.service';

@Component({
  selector: 'mydata-location-list',
  templateUrl: './datapoint-groups-index.component.html'
})
export class DatapointGroupsIndex implements OnInit, OnDestroy {
  private ngDestroy = new Subject<void>();

  @ViewChild('navActions', {static: true})
  public navTemplate: TemplateRef<any>;

  public filterForm = this.datapointGroupsIndexService.filterForm;

  public isCityUser = false;

  view = 'datapoint-groups-list';

  constructor(
    @Inject(SMARTENCITY_MYDATA_CONFIG) private config: MyDataConfig,
    private locationService: LocationService,
    private locationListService: LocationListService,
    private authService: AuthService,
    private userService: UserService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    public router: Router,
    private modalService: BsModalService,
    private tenantSelectService: TenantSelectService,
    private myQuestionnairesService: MyQuestionnairesService,
    private navbarService: NavbarService,
    private sharedKeywordStore: SharedKeywordStore,
    private personSeriesGroupService: PersonSeriesGroupApiService,
    private datapointGroupsIndexService: DatapointGroupsIndexService,
    private preventCloseModalService: PreventCloseModalService,
  ) {
  }

  ngOnDestroy(): void {
    this.navbarService.setActionsTemplate(null);
    this.ngDestroy.next();
    this.ngDestroy.complete();
    this.datapointGroupsIndexService.ngOnDestroy();
  }

  ngOnInit(): void {
    this.navbarService.setActionsTemplate(this.navTemplate);

    this.authService.currentMandate$.pipe(takeUntil(this.ngDestroy)).subscribe((data: any) => {
      this.resetLists();
    });

    this.userService.confirmationListWatcher.pipe(takeUntil(this.ngDestroy)).subscribe((data: any) => {
      this.resetLists();
    });

    this.authService.currentMandate$.pipe(takeUntil(this.ngDestroy)).subscribe((mandate: Mandate) => {
      this.isCityUser = this.config.cityRegistrationCode === mandate.person.registrationNumber;
    });
  }

  private resetLists() {
    this.datapointGroupsIndexService.resetLists();
  }

  public setView(view: string) {
    this.view = view;
  }

  clearSearch() {
    this.filterForm.get('query').setValue('');
    this.sharedKeywordStore.setState('');
  }

  public createGroup(): void {

    const modalRef = this.preventCloseModalService.show(EditDatapointGroupModalComponent,
      {
        class: 'modal-lg',
        initialState: {
          isCreate: true
        }
      }
    );
    modalRef.content.saveEmitter.pipe(takeUntil(this.ngDestroy), take(1)).subscribe((group: PersonSeriesGroup) => {
      this.resetLists();
    });
  }

}
