<div *ngIf="showData()">
  <div class="series-type-title-wrapper">
    <div class="series-type-title">
      <h3 i18n>Keskkonnasääst</h3>
    </div>
  </div>

  <div class="series">
    <span *ngFor="let cityPortalType of seriesCityPortalTypes[location.type]">
      <span class="series-type" [ngClass]="cityPortalTypeData[cityPortalType].style"
            *ngIf="cityPortalTypeData[cityPortalType].series"
            [tooltip]="cityPortalTypeData[cityPortalType].series.description">
          <span class="icon" [ngClass]="cityPortalTypeData[cityPortalType].iconStyle"></span>
          <span>{{cityPortalTypeData[cityPortalType].series.value | number:'.0-0'}} {{cityPortalTypeData[cityPortalType].series.unit}}</span>
      </span>
    </span>
  </div>
</div>
