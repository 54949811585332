<ng-template #navMenu>
  <ul class="nav mr-auto nav-pills">
    <li class="nav-item">
      <a routerLink="/mydata/dashboard"
         [queryParams]="{dashboard_type:'default'}"
         routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"
         class="nav-link" i18n>Dashboard</a>
    </li>

    <li class="nav-item" *ngFor="let customDashboard of customDashboards">
      <a routerLink="/mydata/dashboard"
         [queryParams]="{dashboard_id:customDashboard.dashboard.id}"
         routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"
         class="nav-link d-flex align-items-center">
        <ng-container>
          <i *ngIf="customDashboard.dashboard.publicDashboard == true" style="margin-left: -5px;" class="fa-regular fa-globe mt-1 mr-1" tooltip="Public dashboard" i18n-tooltip></i> 
          <i *ngIf="customDashboard.ownerType === OwnerType.SHARED" class="fa-solid fa-user-group mt-1 mr-1" tooltip="Shared by {{customDashboard.ownerPerson?.displayName}}" i18n-tooltip></i>
          {{customDashboard.dashboard.name}}
        </ng-container>
      </a>
    </li>
    <li class="nav-item">
      <button (click)="editDashboards()" type="button" class="btn btn-icon">
        <i class="far fa-ellipsis-h"></i></button>
    </li>
  </ul>
</ng-template>

<ng-template #incomingWidgetTemplates>
  <ng-container *ngIf="(preferences$|async)?.questionnairesEnabled">
    <button type="button" class="btn btn-sm btn-outline-primary" *ngIf="widgetTemplates.length > 0" (click)="openDashboardWidgetTemplates()">
      <span i18n>Add questionnaire graph</span>
    </button>
  </ng-container>
</ng-template>

<div class="container-fluid p-4">
  <mydata-dashboard></mydata-dashboard>
</div>
