import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {PersonParameter, PersonParameterResponse} from '../../../../core/src/lib/models/parameter';
import {CoreConfig, SMARTENCITY_CORE_CONFIG} from '@smartencity/core';

@Injectable()
export class PersonParameterApiService {

  constructor(@Inject(SMARTENCITY_CORE_CONFIG) private config: CoreConfig,
              private http: HttpClient) {
  }

  public getPersonParametersPage(params?: any): Observable<PersonParameterResponse> {

    return this.http.get<PersonParameterResponse>(this.config.apiUrl + '/person-parameter', {params: params});
  }

  public getPersonParameter(id: number): Observable<PersonParameter> {

    return this.http.get<PersonParameter>(this.config.apiUrl + '/person-parameter/' + id);
  }


  public deletePersonParameter(id: number): Observable<any> {

    return this.http.delete(this.config.apiUrl + '/person-parameter/' + id);
  }

  public createPersonParameter(data: any): Observable<PersonParameter> {

    return this.http.post<PersonParameter>(this.config.apiUrl + '/person-parameter', data);
  }

  public updatePersonParameter(id: number, data: any): Observable<PersonParameter> {
    if (!data.id) {
      data.id = id;
    }
    return this.http.put<PersonParameter>(this.config.apiUrl + '/person-parameter/' + id, data);
  }

}
