import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import PerfectScrollbar from 'perfect-scrollbar';
import { TallinnArcGisMapService } from 'projects/arc-gis/src/lib/tallinn-arc-gis-map.service';
import { Subject } from 'rxjs/internal/Subject';
import { takeUntil } from 'rxjs/operators';
import { BuildingsOlMapService } from '../../service/buildings-ol.service';
import { BuildingsService } from '../../service/buildings.service';

@Component({
  selector: 'buildings-objects-modal',
  templateUrl: './buildings-objects-modal.component.html'
})
export class BuildingsObjectsModalComponent implements OnInit, AfterViewInit {
  private ngDestroy = new Subject<void>();

  @ViewChild('modalContent') modalContent: ElementRef;

  @Input()
  public objects: any[] = [];

  @Output('modalClose')
  modalClose: EventEmitter<void> = new EventEmitter<void>();

  @Output('arcGISLocationsReset')
  arcGISLocationsReset: EventEmitter<void> = new EventEmitter<void>();

  public currentIndex: number = 0;

  constructor(
    private buildingsService: BuildingsService,
    private tallinnArcGisMapService: TallinnArcGisMapService,
    private buildingsOlMapService: BuildingsOlMapService) {
  }

  ngOnInit() {
    this.buildingsService.locationsSelected$.pipe(takeUntil(this.ngDestroy)).subscribe((locations: any[]) => {
      this.resetIndex();
      let layerTypes = Array.from(this.tallinnArcGisMapService.layersMap.keys());
      let newLayerTypes = Array.from(this.buildingsOlMapService.arcGisMapLayers.keys());

      if (newLayerTypes.length > 0) {
        layerTypes = layerTypes.concat(newLayerTypes);
      }

      layerTypes.forEach((layerType) => {
        let idx = this.objects.findIndex((o) => o.type == layerType);
        if (idx > -1) {
          this.objects.splice(idx, 1);
        }
      });

      if (locations) {
        for (let location of locations) {
          this.objects.push({ type: location.layerName, data: location.data });
        }
      }
    });
  }

  resetIndex() {
    this.currentIndex = 0;
  }

  public resetArcGISLocations(): void {
    this.arcGISLocationsReset.next();
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  close() {
    this.objects = [];
    this.modalClose.next();
  }

  ngAfterViewInit() {
    new PerfectScrollbar(this.modalContent.nativeElement);
  }
}
