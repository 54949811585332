export class ErrorResponseHelper {

  public static getErrorReason(errorResponse: any): string {

    return (errorResponse.error && errorResponse.error.errorCode
      ? ErrorResponseHelper.mapErrorCodeToMessage(errorResponse.error.errorCode, errorResponse.error.sourceNames)
      : null);
  }

  private static mapErrorCodeToMessage(errorCode: string, sourceNames: string[] = []) {

    let message = "";
    switch (errorCode) {
      case 'PERSON_SERIES_IS_GROUP':
        message = $localize`Person series is a group`;
        break;
      case 'PERSON_SERIES_IS_FORMULA':
        message = $localize`Person series is a formula`;
        break;
      case 'PERSON_SERIES_IS_IN_QUESTIONNAIRE':
        message = $localize`Person series is included in the following questionnaire(s)`;
        message += ":<br />";
        if (sourceNames) {
          message += sourceNames.join(";<br /> ");
        }
        break;
      case 'PERSON_SERIES_IS_IN_FORMULA':
        message = $localize`Person series is included in the following formula(s)`;
        message += ":<br />";
        if (sourceNames) {
          message += sourceNames.join(";<br /> ");
        }
        break;
      case 'psp_exists':
        message = $localize`Consent already exists!`;
        break;
      case 'ACTIVE_CONSENT_EXISTS':
        message = $localize`Person already has an active consent`;
        break;
      case 'ACTIVE_DATA_OWNERSHIP_EXISTS':
        message = $localize`Person already has an active data ownership`;
        break;
      case 'TARGET_PERSON_IS_OWNER':
        message = $localize`Target person is owner`;
        break;
      case 'PERSON_SERIES_REFERENCED_BY_GROUP':
        message = $localize`Can't perform action: referenced by datapoints group`;
        break;
      case 'PERSON_SERIES_GROUP_ALREADY_SHARED':
        message = $localize`Person already has an active consent`;
        break;
    }

    return message;

  }
}
