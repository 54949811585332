import {ChangeDetectorRef, Component, Inject, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {GasConfig} from './config.model';
import {SMARTENCITY_GAS_CONFIG} from './injection-tokens';
import {GasService} from './gas.service';
import {take, takeUntil} from 'rxjs/operators';
import {forkJoin, Subject} from 'rxjs';
import {SolarPanelsLayer} from './layers/solar-panels-layer';
import {SeriesLocation} from './models/series-location';
import {SeriesLocationType} from './models/series-location-type.enum';
import LatLngBounds = google.maps.LatLngBounds;
import {LayersSettings} from './models/layers-settings';
import {GasMapStyles} from './gas-map-styles';
import LatLng = google.maps.LatLng;
import {GasMapService} from './gas-map.service';

export enum LayerGroups {
  SOLAR_PANELS = 'SOLAR_PANELS',
  CNG_STATIONS = 'CNG_STATIONS',
  BIOGAS_STATIONS = 'BIOGAS_STATIONS',
  GENERAL = 'GENERAL'
}

@Component({
  selector: 'lib-gas',
  templateUrl: './gas.component.html',
  styles: []
})
export class GasComponent implements OnInit, OnDestroy {
  private ngDestroy = new Subject<void>();

  @ViewChild('gmap', {static: true})
  gmapElement: any;
  map: google.maps.Map;
  // biogasStationsLayer: BiogasStationsLayer;
  // cngStationsLayer: CngStationsLayer;
  solarPanelsLayer: SolarPanelsLayer;
  currentItem = null;

  public generalTotals: SeriesLocation = null;
  public solarPanelsTotal: SeriesLocation = null;
  public location: SeriesLocation;
  public locations: SeriesLocation[];

  public defaultGeneralTotals = {
    type: SeriesLocationType.GENERAL,
    series: []
  } as SeriesLocation;

  public defaultSolarPanelsTotals = {
    type: SeriesLocationType.SOLAR_PANELS_TOTAL,
    series: []
  } as SeriesLocation;

  displaySettingsSource = new Subject<LayersSettings>();
  displaySettings$ = this.displaySettingsSource.asObservable();

  public markers = [];

  updateTimeout: any = null;

  public searchControl = this.gasService.searchControl;

  layersSettings: LayersSettings = new LayersSettings();
  layerGroupsEnum = LayerGroups;
  layersGroupActive: LayerGroups = LayerGroups.GENERAL;

  constructor(
    @Inject(SMARTENCITY_GAS_CONFIG) private config: GasConfig,
    private gasService: GasService,
    private cd: ChangeDetectorRef,
    private zone: NgZone,
    private gasMapService: GasMapService
  ) { }

  ngOnInit(): void {
    const conf = this.config.map;

    const mapProp = {
      center: conf.center,
      zoom: conf.zoom,
      mapTypeControl: false,
      fullscreenControl: false,
      clickableIcons: false,
      styles: GasMapStyles.styles
    };
    this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);

    if (this.solarPanelsLayer) {
      this.solarPanelsLayer.clearMarkers();
    }
    this.solarPanelsLayer = new SolarPanelsLayer(this.map, this.gasMapService);

    google.maps.event.addListenerOnce(this.map, 'idle', () => {
      this.zone.run(() => {
        this.initLocations();
        this.cd.markForCheck();
      });
    });

    google.maps.event.addListener(this.map, 'click', (e: any) => {
      this.zone.run(() => {
        if (e.series) {
          this.showLocation(e, true);
          this.cd.markForCheck();
        }
      });
    });

    this.currentItem = null;

    // this.gasService.services$.pipe(takeUntil(this.ngDestroy)).subscribe((services: Services) => {
    //   if (this.biogasStationsLayer) {
    //     this.biogasStationsLayer.setMap(null);
    //   }
    //   this.biogasStationsLayer = new BiogasStationsLayer(services.biogasStations);
    //   if (this.cngStationsLayer) {
    //     this.cngStationsLayer.setMap(null);
    //   }
    //   this.cngStationsLayer = new CngStationsLayer(services.cngStations);
    //   this.updateDisplay();
    // });

    this.gasService.locations$.subscribe((locations: SeriesLocation[]) => {
      this.locations = locations;
      this.solarPanelsLayer.updateLocations(locations);
      if (this.location && this.location.type === SeriesLocationType.LOCATION) {
        this.gasMapService.solarParkSelectedSource.next(locations.find(value => value.address === this.location.address && value.type === this.location.type));
      }
    });

    this.gasService.solarPanelTotals$.subscribe((totals: SeriesLocation) => {
      this.solarPanelsTotal = totals;
      if (this.location && this.location.type === SeriesLocationType.SOLAR_PANELS_TOTAL && totals) {
        this.gasMapService.solarParkSelectedSource.next(totals);
      }
    });

    // this.gasService.generalTotals$.subscribe((totals: SeriesLocation) => {
    //   this.generalTotals = totals;
    //   if (this.location && this.location.type === SeriesLocationType.GENERAL && totals) {
    //     this.location = totals;
    //   }
    // });

    this.displaySettings$.subscribe((display: LayersSettings) => {
      this.layersSettings = display;
      this.updateDisplay();
    });

    this.gasService.resizeMap$.subscribe(() => {
      // this.panMap(this.locations);
      this.map.setCenter(new LatLng(this.config.map.center.lat, this.config.map.center.lng));
      this.map.setZoom(this.config.map.zoom);
      if (!this.location || this.location.type != 'SOLAR_PANELS_TOTAL') {
        this.showSolarPanelsTotals();
      }
    });

    this.gasMapService.solarParkSelected$.subscribe((location: SeriesLocation) => {
      this.solarPanelsLayer.selectLocation(location);
    });

    this.updateDisplay();

  }

  updateDisplay(): void {
    // this.biogasStationsLayer.setMap(this.layersSettings.biogasStations ? this.map : null);
    // this.cngStationsLayer.setMap(this.layersSettings.cngStations ? this.map : null);
    if (!this.layersSettings.solarPanels) {
      this.solarPanelsLayer.clearMarkers();
    } else {
      this.solarPanelsLayer.showMarkers();
    }
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  initLocations(): void {
    forkJoin([
      this.gasService.getLocations(),
      // this.gasService.services$.pipe(take(1)),
      this.gasService.getSolarPanelsTotals(),
      this.gasService.getGeneralTotals()
    ]).pipe(takeUntil(this.ngDestroy))
      .subscribe(([locations, /*services,*/ solarPanelsTotals, generalTotals]) => {
        this.locations = locations;
        this.solarPanelsTotal = solarPanelsTotals;
        this.generalTotals = generalTotals;
        this.solarPanelsLayer.updateLocations(locations);
        // this.panMap([...locations, ...services.biogasStations, ...services.cngStations]);
        // this.panMap(locations);
        this.scheduleUpdate();
        // this.showGeneralTotals();
        this.showSolarPanelsTotals();
      });
    this.gasService.loadServices();
  }

  scheduleUpdate() {
    this.updateTimeout = setTimeout(() => {
      this.scheduleUpdate();
      this.gasService.loadLocations();
      this.gasService.loadSolarPanelsTotals();
    }, 60000);
  }

  panMap(locations) {
    let latMin = 180;
    let latMax = -180;
    let lngMin = 180;
    let lngMax = -180;
    for (const location of locations) {
      latMin = location.lat < latMin ? location.lat : latMin;
      latMax = location.lat > latMax ? location.lat : latMax;
      lngMin = location.lng < lngMin ? location.lng : lngMin;
      lngMax = location.lng > lngMax ? location.lng : lngMax;
    }
    this.map.fitBounds(new LatLngBounds(new google.maps.LatLng(latMin, lngMin), new google.maps.LatLng(latMax, lngMax)), 35);
  }

  showLocation(location: any, markerClick?: boolean) {
    // if (this.location !== location) {
    //   this.location = location;
    // } else {
    //   this.location = null;
    // }
    //
    this.gasMapService.solarParkSelectedSource.next(location);

    if (markerClick) {
      for (let marker of this.solarPanelsLayer.markers) {
        if (this.location != marker.building) {
          marker.div.classList.remove("active");
          marker.selected = false;
          marker.draw();
        }
      }
    }

  }

  toggleLayerGroup(layerGroup: LayerGroups): void {
    this.layersGroupActive = layerGroup;
    this.updateLayers();
  }

  updateLayers(): void {
    if (this.layersGroupActive === LayerGroups.GENERAL) {
      this.displaySettingsSource.next({
        solarPanels: true,
        cngStations: true,
        biogasStations: true
      });

      this.showGeneralTotals();
      return;
    }

    this.displaySettingsSource.next({
      solarPanels: this.layersGroupActive === LayerGroups.SOLAR_PANELS,
      cngStations: this.layersGroupActive === LayerGroups.CNG_STATIONS,
      biogasStations: this.layersGroupActive === LayerGroups.BIOGAS_STATIONS
    });

    if (this.layersGroupActive === LayerGroups.SOLAR_PANELS) {
      this.showSolarPanelsTotals();
    } else {
      this.location = null;
    }
  }

  // onLocationChange(location: any): void {
  //   if (!location) {
  //     for (let marker of this.solarPanelsLayer.markers) {
  //       marker.div.classList.remove("active");
  //     }
  //   }
  // }

  showGeneralTotals(): void {
    let totals = this.defaultGeneralTotals;
    if (this.generalTotals) {
      totals = this.generalTotals;
    }
    this.showLocation(totals);
  }

  showSolarPanelsTotals(): void {
    let totals = this.defaultSolarPanelsTotals;
    if (this.solarPanelsTotal) {
      totals = this.solarPanelsTotal;
    }
    this.showLocation(totals);
  }

}
