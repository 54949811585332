<canvas id="widget-canvas-{{widget.id}}" #chartCanvas [hidden]="!hasDatasets || !hasData"></canvas>
<div style="width: 100%;display: flex;justify-content: center;align-items: center;" *ngIf="hasDatasets && !hasData">
  <div class="text-center">
    <h3 class="text-light" i18n>Waiting for data...</h3>
    <div class="spinner-grow text-light" style="width: 3rem; height: 3rem;" role="status"></div>
  </div>
</div>
<div style="width: 100%;display: flex;justify-content: center;align-items: center;" *ngIf="!hasDatasets">
  <div class="text-center">
    <h3 class="text-light" i18n>Empty</h3>
  </div>
</div>
