<div class="list-item draggable" cdkDrag [formGroup]="groupSourcePersonSeriesItem.rowFormGroup">
  <div class="form-row">
    <div class="col-auto w-100">
      <div class="d-flex">
        <span class="title">{{groupSourcePersonSeriesItem.personSeries.name}}</span>
        <div class="ml-auto pl-3">
          <div class="btn-remove-wrapper">
            <button type="button" class="close" (click)="remove()" tooltip="Remove" i18n-tooltip  placement="auto">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <span class="col-auto">
      <label class="light mr-2 mb-0" i18n>Type</label>
      <span class="text-light">
        {{groupSourcePersonSeriesItem.personSeries.seriesType | label}}
      </span>
    </span>
    <span class="col-auto">
      <label class="light mr-2 mb-0" i18n>Location</label>
      <span class="text-light">{{groupSourcePersonSeriesItem.personSeries.location}}</span>
    </span>
  </div>
  <div class="form-row mt-2">
    <span class="col-auto" [hidden]="groupSourcePersonSeriesItem.rowFormGroup.get('unit').value !== commonUnit">
      <label class="light mr-2 mb-0" i18n>Unit</label>
      <span class="text-light">{{groupSourcePersonSeriesItem.rowFormGroup.get('unit').value}}</span>
    </span>
    <span class="col-auto" [hidden]="groupSourcePersonSeriesItem.rowFormGroup.get('unit').value === commonUnit">
      <label class="light mr-2 mb-0" i18n>Unit</label>
      <span class="text-light">{{commonUnit}}&nbsp;<i class="fa fa-arrow-right"></i>&nbsp;{{groupSourcePersonSeriesItem.rowFormGroup.get('unit').value}}</span>
    </span>
    <span class="col-auto" *ngIf="groupSourcePersonSeriesItem.rowFormGroup.get('unit').value === commonUnit">
      <label class="light mr-2 mb-0" i18n>Coefficient</label>
      <span class="text-light">{{groupSourcePersonSeriesItem.rowFormGroup.get('unit').value}}</span>
    </span>
    <span class="col-auto form-inline" *ngIf="groupSourcePersonSeriesItem.rowFormGroup.get('unit').value !== commonUnit">
      <label class="light mr-2 mb-0" i18n>Coefficient</label>
      <input type="text" class="form-control form-control-sm text-right" [class.is-invalid]="groupSourcePersonSeriesItem.rowFormGroup.get('coefficient').invalid" placeholder="Coefficient" i18n-placeholder
             formControlName="coefficient">
    </span>
  </div>
  <div class="form-row mt-2">
    <div class="col-auto">
      <label class="light mr-2 mb-0" i18n>Period specification</label>
      <core-custom-dropdown-control [options]="windowOperationTypeOptions" formControlName="windowOperationType" placeholder="Select timeframe type" i18n-placeholder></core-custom-dropdown-control>
      <small *ngIf="invalid('windowOperationType') && errors('windowOperationType')?.required"
             class="invalid-feedback d-block text-right" i18n>Required</small>
    </div>
  </div>
  <span class="drag-handler" cdkDragHandle></span>
</div>
