import {NgModule} from '@angular/core';
import {QuestionnairesPageComponent} from './questionnaires-page.component';
import {MyQuestionnairesComponent} from './my-questionnaires.component';
import {IncomingQuestionnairesComponent} from './incoming-questionnaires.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {ModalModule} from 'ngx-bootstrap/modal';
import {PopoverModule} from 'ngx-bootstrap/popover';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {ReactiveFormsModule} from '@angular/forms';
import {NgbTypeaheadModule} from '@ng-bootstrap/ng-bootstrap';
import {CoreModule} from '@smartencity/core';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {NgxPermissionsModule} from 'ngx-permissions';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { QuestionnaireComponent } from './questionnaire/questionnaire.component';
import { QuestionnaireInfoComponent } from './questionnaire-info/questionnaire-info.component';
import { QuestionnaireFieldsComponent } from './questionnaire-fields/questionnaire-fields.component';
import { QuestionnaireParticipantsComponent } from './questionnaire-participants/questionnaire-participants.component';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import { FieldListComponent } from './field-list/field-list.component';
import { FieldFormComponent } from './field-form/field-form.component';
import { FormulaInputFormComponent } from './formula-input-form/formula-input-form.component';
import {ButtonsModule} from 'ngx-bootstrap/buttons';
import { FormulaExpressionFormComponent } from './formula-expression-form/formula-expression-form.component';
import { FormulaMappingFormComponent } from './formula-mapping-form/formula-mapping-form.component';
import { QuestionFormComponent } from './question-form/question-form.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatButtonModule} from '@angular/material/button';
import { ViewFieldsComponent } from './view-fields/view-fields.component';
import { ViewFilesComponent } from './view-files/view-files.component';
import { ViewParticipantsComponent } from './view-participants/view-participants.component';
import { ViewResultsComponent } from './view-results/view-results.component';
import { ViewModalComponent } from './view-modal/view-modal.component';
import { ResponseModalComponent } from './response-modal/response-modal.component';
import { ResponseFieldsComponent } from './response-fields/response-fields.component';
import { ViewResultsIndividualComponent } from './view-results-individual/view-results-individual.component';
import { ViewResultsSummaryComponent } from './view-results-summary/view-results-summary.component';
import {CanDeactivateQuestionnaireGuard} from './can-deactivate-questionnaire.guard';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import { ViewResponseComponent } from './view-response/view-response.component';
import {DatepickerWrapperModule} from '../../../../../core/src/lib/components/datepicker/datepicker-wrapper.module';
import {NgxMatDrpModule} from '@smartencity/shared';

@NgModule({
  declarations: [
    QuestionnairesPageComponent,
    MyQuestionnairesComponent,
    IncomingQuestionnairesComponent,
    QuestionnaireComponent,
    QuestionnaireInfoComponent,
    QuestionnaireFieldsComponent,
    QuestionnaireParticipantsComponent,
    FieldListComponent,
    FieldFormComponent,
    FormulaInputFormComponent,
    FormulaExpressionFormComponent,
    FormulaMappingFormComponent,
    QuestionFormComponent,
    ViewFieldsComponent,
    ViewFilesComponent,
    ViewParticipantsComponent,
    ViewResultsComponent,
    ViewModalComponent,
    ResponseModalComponent,
    ResponseFieldsComponent,
    ViewResultsIndividualComponent,
    ViewResultsSummaryComponent,
    ViewResponseComponent
  ],
  entryComponents: [],
  providers: [CanDeactivateQuestionnaireGuard],
  imports: [
    CommonModule,
    RouterModule.forChild([]),
    ReactiveFormsModule,
    CoreModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    NgxMatDrpModule,
    ModalModule,
    NgbTypeaheadModule,
    NgSelectModule,
    NgxDatatableModule,
    PopoverModule,
    ButtonsModule,
    BsDropdownModule,
    DragDropModule,
    MatButtonModule,
    NgxPermissionsModule.forChild(),
    PopoverModule,
    TooltipModule,
    DatepickerWrapperModule
  ],
  exports: [
    QuestionnairesPageComponent,
    IncomingQuestionnairesComponent,
    ResponseModalComponent
  ]
})
export class QuestionnaireModule { }
