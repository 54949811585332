import {Component, Inject, OnInit} from '@angular/core';
import {BaseMapType, CoreConfig, SMARTENCITY_CORE_CONFIG} from '@smartencity/core';
import { MeteorologyGmapService } from '../service/meteorology-gmap.service';
import { MeteorologyOlMapService } from '../service/meteorology-ol-map.service';
import { MeteorologyService } from '../service/meteorology.service';

@Component({
  selector: 'meteorology-main',
  templateUrl: './meteorology.component.html',
  providers: [MeteorologyService, MeteorologyGmapService, MeteorologyOlMapService]
})
export class MeteorologyComponent implements OnInit {

  mapType: BaseMapType;

  constructor(@Inject(SMARTENCITY_CORE_CONFIG) private config: CoreConfig) {
    this.mapType = (config.map.mapType ? config.map.mapType : 'gmap')
  }

  ngOnInit(): void {}
}
