import {Inject, Injectable} from '@angular/core';
import {Subject} from "rxjs/internal/Subject";
import {debounceTime, distinctUntilChanged, map, startWith, switchMap, takeUntil} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {SMARTENCITY_MYDATA_CONFIG} from "../injection-tokens";
import {MyDataConfig} from "../mydata-config.model";

@Injectable({
  providedIn: 'root'
})
export class GroupingTagService {

  public ngDestroy = new Subject<void>();

  public groupingTagTypeahead$ = new Subject<string>();
  public groupingTag$ = this.groupingTagTypeahead$.pipe(
    takeUntil(this.ngDestroy),
    startWith(''),
    distinctUntilChanged(),
    debounceTime(250),
    switchMap((term: string) => this.http.get<string[]>(this.config.apiUrl + '/grouping-tag', {
      params:{term:term}
    })),
    map((tags: string[]) => {
      return tags.filter(value => value != "");
    })
  );

  constructor(private http: HttpClient,
              @Inject(SMARTENCITY_MYDATA_CONFIG) private config: MyDataConfig) {
  }
}
