import {ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {CoreConfig, EnumsService, Mandate, MandatePermission, MandateUser, SMARTENCITY_CORE_CONFIG, UserService} from '@smartencity/core';
import {MandateData} from '../mandate-tree.service';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {FormArray, FormControl, FormGroup, ValidationErrors} from '@angular/forms';
import {debounceTime, filter, switchMap, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs/internal/Subject';

const selector = 'edit-mandate-modal';
let nextId = 0;

@Component({
  selector: selector,
  templateUrl: './edit-mandate-modal.component.html'
})
export class EditMandateModal implements OnInit {
  id = `${selector}-${nextId++}`;

  private ngDestroy = new Subject<void>();

  @Input()
  mandateData: MandateData;

  @Input()
  currentUserMandate: Mandate;

  public mandate: Mandate;

  public permissionsList: any[] = [];

  public users = [];

  public mandateForm: FormGroup = EditMandateModal.formDefault();

  public userTypeahead = new EventEmitter<string>();

  public errorCodes: any = {
    'Select user': 'Fill selections',
    'Target user is the owner': 'Not allowed selection'
  };
  public errorCode: string = null;

  public showErrors = false;

  public progress = false;

  @Output('saved')
  savedEmitter: EventEmitter<any> = new EventEmitter<any>();

  @Output('cancelled')
  cancelledEmitter: EventEmitter<any> = new EventEmitter<any>();

  public static formDefault(): FormGroup {

    return new FormGroup({
      replaceMandate: new FormControl(false),
      user: new FormControl(),
      permissions: new FormArray([])
    });
  };

  constructor(private cd: ChangeDetectorRef,
              private enumService: EnumsService,
              private modalRef: BsModalRef,
              private userService: UserService,
              @Inject(SMARTENCITY_CORE_CONFIG) private config: CoreConfig) {
  }

  ngOnInit(): void {
    console.log(this.mandateData);
    this.mandate = this.mandateData.data.mandate;
    this.permissionsList = this.enumService.getOptionsList(['EDIT_MANDATES']);

    this.buildForm();
    this.initUserTypeahead();
  }

  get permissions(): FormArray {
    return this.mandateForm.get('permissions') as FormArray;
  }

  public createUser = (personalId: string) => {
    return {
      countryCode: this.config.defaultCountryCode,
      personalId: personalId,
      displayName: null
    };
  };


  buildForm(): void {
    const mandate = this.mandate;
    const formMandate: any = {};
    formMandate.user = mandate.user;
    this.mandateForm.reset(formMandate);

    const controls = this.permissionsList.map(permission => {
      let value = false;
      for (let i = 0; i < mandate.permissions.length; i++) {
        if (mandate.permissions[i].key == permission.value) {
          value = mandate.permissions[i].value;
        }
      }
      return value;
    });
    const permissionsArray = new FormArray(controls.map(value => new FormControl(value)));
    this.mandateForm.setControl('permissions', permissionsArray);
  }

  saveForm(): void {
    this.mandateForm.markAsTouched();
    this.markFormGroupTouched(this.mandateForm);
    this.mandateForm.updateValueAndValidity();

    if (this.mandateForm.invalid) {
      this.showErrors = true;
      return;
    }
    this.progress = true;

    const formValue = this.mandateForm.getRawValue();
    const user = formValue.user;
    if (!user) {
      this.errorCode = 'Fill selections';
      return;
    }

    const data: any = {
      personalId: this.mandate.user.personalId,
      countryCode: this.mandate.user.countryCode,
      user: {
        personalId: formValue.user.personalId,
        countryCode: formValue.user.countryCode
      },
      permissions: []
    };

    for (let i = 0; i < formValue.permissions.length; i++) {
      data.permissions.push(new MandatePermission(this.permissionsList[i].value, formValue.permissions[i]));
    }

    this.userService.saveMandate(data).pipe(takeUntil(this.ngDestroy)).subscribe(
      (mandate: any) => {
        this.errorCode = null;
        this.savedEmitter.emit();
        this.modalRef.hide();
      },
      (err: any) => {
        this.errorCode = err.error.message;
        this.progress = false;
      },
      () => {
        this.progress = false;
      }
    );

  }

  isCancelAllowed(): boolean {
    return this.mandate.type == 'MANDATE' && (this.currentUserMandate.type == 'OWNER' || this.currentUserMandate.permissions.filter(p => p.key == 'EDIT_MANDATES').length != 0);
  }

  isMandatePermissionsEditAllowed(): boolean {
    return this.mandate.type != 'OWNER';
  }

  cancelMandate(): void {
    this.userService.deleteMandate(this.mandate).pipe(takeUntil(this.ngDestroy)).subscribe((data: any) => {
        this.errorCode = null;
        this.cancelledEmitter.emit(this.mandate);
        this.modalRef.hide();
      },
      (err: any) => {
        this.errorCode = err.error.message;
        this.progress = false;
      },
      () => {
        this.progress = false;
      }
    );
  }

  control(name?: string) {
    if (!name) {
      return this.mandateForm;
    }
    return this.mandateForm.get(name);
  }

  invalid(name: string, formGroup?) {
    if (!formGroup) {
      formGroup = this.mandateForm;
    }
    const control = formGroup.get(name);
    return control && control.invalid && (control.dirty || control.touched);
  }

  errors(name: string, formGroup?): ValidationErrors {
    if (!formGroup) {
      formGroup = this.mandateForm;
    }
    const control = formGroup.get(name);
    return control ? control.errors : null;
  }

  close(): void {
    this.modalRef.hide();
  }

  initUserTypeahead() {
    this.userTypeahead
      .pipe(
        filter((term) => term && term.length >= 4),
        debounceTime(200),
        switchMap(term => this.userService.searchUsers(term))
      ).pipe(takeUntil(this.ngDestroy))
      .subscribe(users => {
        this.users = users;
        this.cd.markForCheck();
      }, (err) => {
        this.users = [];
        this.cd.markForCheck();
      });
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

}
