import { AbstractMapLayer, OlMapWrapper } from '@smartencity/core';
import { PeopleTrafficCounter } from '../model/people-traffic-counter';
import { PeopleTrafficOlMarker } from './people-traffic-ol-marker';
import {PeopleTrafficService} from '../service/people-traffic.service';

export class PeopleTrafficOlLayer extends AbstractMapLayer<OlMapWrapper, PeopleTrafficCounter>{

  private peopleTrafficPointers: PeopleTrafficCounter[] = [];
  private peopleTrafficService: PeopleTrafficService

  constructor(peopleTrafficPointers: PeopleTrafficCounter[], peopleTrafficService: PeopleTrafficService) {
    super();
    this.peopleTrafficPointers = peopleTrafficPointers;
    this.peopleTrafficService = peopleTrafficService;
  }

  setMap(map: OlMapWrapper): void {
    super.setMap(map);
    this.resetMarkers();
    if (map) {
      this.createMarkers();
    }
  }

  setPeopleTraffic(selected: PeopleTrafficCounter): void {
    this.getMarkers().forEach((marker: any) => {
      marker.setSelected(marker.getData() == selected);
      marker.draw();
    });
  }

  protected resetMarkers(): void {
    const markers = this.getMarkers();
    if (markers.length > 0) {
      for (const marker of markers) {
        marker.setMap(null);
      }
    }
    this.clearMarkers();
  }

  protected createMarkers(): void {
    if (this.peopleTrafficPointers) {
      for (const peopleTrafficPointers of this.peopleTrafficPointers) {
        this.addMarker(new PeopleTrafficOlMarker(peopleTrafficPointers, this.map, this.peopleTrafficService));
      }
    }
  }
}
