import {Inject, Injectable, OnDestroy} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Enums} from '../models/enums';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs/internal/Subject';
import {SMARTENCITY_CORE_CONFIG} from '../injection-tokens';
import {CoreConfig} from '../core-config.model';
import {GroupingTags, SeriesType} from '../models/series';
import {LabelPipe} from '../pipes/LabelPipe';

@Injectable({providedIn: 'root'})
export class EnumsService implements OnDestroy {
  private ngDestroy = new Subject<void>();

  private _enums: Enums;

  get enums(): Enums {
    return this._enums;
  }

  constructor(
    @Inject(SMARTENCITY_CORE_CONFIG) private config: CoreConfig,
    private http: HttpClient,
    private labelPipe: LabelPipe
  ) {
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  loadEnums(): void {
    SeriesType.types.splice(0, SeriesType.types.length);

    this.http.get<Enums>(this.config.apiUrl + '/enums').pipe(takeUntil(this.ngDestroy)).subscribe((enums: Enums) => {
      if (enums) {
        this._enums = enums;
        SeriesType.types.splice(0, SeriesType.types.length);
        SeriesType.types.push(...enums.seriesType);

        GroupingTags.tags.splice(0, GroupingTags.tags.length);
        GroupingTags.tags.push(...enums.predefinedGroupingTag);
      }
    });
  }

  public getAggregationTypeOptions():{value,label}[]  {
    return this.getOptionsList(['AVERAGE', 'SUM', 'MIN', 'MAX']);
  }

  public getOptionsList(keys: string[], prefix = ''):{value,label}[] {
    return keys.map((type: string) => ({
      value: type,
      label: this.labelPipe.transform(prefix + type)
    }));
  }
}
