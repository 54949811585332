import {Component, Inject, Input, LOCALE_ID, OnDestroy, OnInit} from '@angular/core';
import {
  Location,
  LatestValue,
  PersonSeries,
  PersonSeriesPeriod,
  SourceInfo,
  SourceOwnerSeries, Threshold, Consent, Person, PreventCloseModalService, PersonSeriesCompareService
} from '@smartencity/core';
import {Subject} from 'rxjs/internal/Subject';
import {ReplaySubject} from 'rxjs/internal/ReplaySubject';
import {CurrentDatapointService} from '../../../services/current-datapoint.service';
import {take, takeUntil} from 'rxjs/operators';
import {ThresholdsModalComponent} from '../../consumption/person-series/thresholds/thresholds-modal.component';
import {ConsentModalComponent} from '../../consumption/person-series/consent/consent-modal.component';
import {MyDataConfig} from '../../../mydata-config.model';
import {HttpClient} from '@angular/common/http';
import {BsModalService} from 'ngx-bootstrap/modal';
import {ComponentLoaderFactory} from 'ngx-bootstrap/component-loader';
import {SMARTENCITY_MYDATA_CONFIG} from '../../../injection-tokens';

@Component({
  selector: 'app-datapoint-item',
  templateUrl: './datapoint-item.component.html',
  styleUrls: ['./datapoint-item.component.css'],
  providers: [CurrentDatapointService, BsModalService, ComponentLoaderFactory]
})
export class DatapointItemComponent implements OnDestroy, OnInit {
  private ngDestroy: Subject<void> = new Subject();

  public sourceOwnerSeries = new ReplaySubject<SourceOwnerSeries>(1);
  private sourceOwnerSeriesRef: SourceOwnerSeries;
  sos$ = this.currentDatapointService.sourceOwnerSeries$.pipe(takeUntil(this.ngDestroy));

  public location: Location;

  @Input('sourceOwnerSeries')
  public set setSourceOwnerSeries(sourceOwnerSeries: SourceOwnerSeries) {
    this.sourceOwnerSeries.next(sourceOwnerSeries);
  }

  @Input('showOnMap')
  public showOnMap = false;

  public personSeries: PersonSeries;
  public latestValue: LatestValue;
  public lastPeriod: PersonSeriesPeriod;
  public source: SourceInfo;
  public activeThresholds: Threshold[];
  public activeConsents: Consent[];
  isInComparison = false;

  constructor(
    private currentDatapointService: CurrentDatapointService,
    private personSeriesCompareService: PersonSeriesCompareService,
    @Inject(SMARTENCITY_MYDATA_CONFIG) private config: MyDataConfig,
    private http: HttpClient,
    private modalService: BsModalService,
    private preventCloseModalService: PreventCloseModalService,
    @Inject(LOCALE_ID) public locale: string
  ) {
    this.sourceOwnerSeries.subscribe((sos) => {
      this.sourceOwnerSeriesRef = sos;
      this.location = sos ? new Location(sos.personSeries) : null;
      this.currentDatapointService.setSourceOwnerSeries(sos);
      this.personSeries = sos?.personSeries;
      this.latestValue = sos?.latestValue;
      this.lastPeriod = sos?.lastPeriod;
      this.source = sos?.source;
    });

    this.currentDatapointService.activeThresholds$.pipe(takeUntil(this.ngDestroy)).subscribe(at => {
      this.activeThresholds = at;
    });
    this.currentDatapointService.activeConsents$.pipe(takeUntil(this.ngDestroy)).subscribe(ac => {
      this.activeConsents = ac;
    });
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  ngOnInit(): void {
  }

  csv(): void {

  }

  pdf(): void {

  }

  addToComparison() {
    this.personSeriesCompareService.add(this.personSeries);
  }

  removeFromComparison() {
    this.personSeriesCompareService.remove(this.personSeries);
  }

  addData() {
    // TODO
    /*const modalRef = this.modalService.show(AddDataModalComponent, {
      ignoreBackdropClick: true,
      class: 'modal-lg',
      initialState: {
        sourceOwnerSeries: this.sourceOwnerSeries,
        callback: (result) => {
          if (result) {
            this.latestReload.next();
          }
        }
      }
    });*/
  }

  manageThresholds() {
    this.sos$.pipe(take(1)).subscribe((sourceOwnerSeries) => {
      const modalRef = this.modalService.show(ThresholdsModalComponent, {
        ignoreBackdropClick: true,
        class: 'modal-xl',
        initialState: {
          sourceOwnerSeries: sourceOwnerSeries,
          callback: (result) => {
            this.currentDatapointService.loadThresholdAnnotations();
          }
        }
      });
    });
  }

  showConsents() {
    const modalRef = this.preventCloseModalService.show(ConsentModalComponent, {
      class: 'modal-lg',
      initialState: {
        lastDataOwnerPeriod: this.sourceOwnerSeriesRef.lastDataOwnerPeriod,
        personSeries: this.personSeries
      }
    });
  }

  getConsentPersons(): Person[] {
    if (!this.sourceOwnerSeriesRef?.consents) {
      return [];
    }
    return this.sourceOwnerSeriesRef.consents.map(c => c.person);
  }

}
