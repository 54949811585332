import {Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChange, ViewChild} from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { AuthService, Mandate, PageResponse, Person, ResizeService, SCREEN_SIZE, SourceOwnerSeries, SourceOwnerSeriesListItem } from '@smartencity/core';
import {Subject} from 'rxjs/internal/Subject';
import { SMARTENCITY_MYDATA_CONFIG } from '../../../injection-tokens';
import { MyDataConfig } from '../../../mydata-config.model';
import { takeUntil } from 'rxjs/operators';

export class RowAction {
  type: RowActionType;
  data: SourceOwnerSeries;
}

export class TableAction {
  type: TableActionType;
  event: Event;
}

export enum RowActionType {
  EDIT_SERIES = 'EDIT_SERIES',
  DECLINE_OWNERSHIP = 'DECLINE_OWNERSHIP',
  ACCEPT_OWNERSHIP = 'ACCEPT_OWNERSHIP',
  ARCHIVE_SERIES = 'ARCHIVE_SERIES',
  DECLINE_AND_ARCHIVE = 'DECLINE_AND_ARCHIVE',
  SHOW_CONSENTS = 'SHOW_CONSENTS',
  SET_NEW_OWNER = 'SET_NEW_OWNER'
}
export enum TableActionType {
  SET_PAGE = 'SET_PAGE',
  SET_SORTS = 'SET_SORTS'
}

@Component({
  selector: 'datapoint-table',
  templateUrl: './datapoint-table.component.html',
  styleUrls: ['./datapoint-table.component.scss']
})
export class DatapointTableComponent implements OnInit, OnDestroy, OnChanges {

  private ngDestroy = new Subject<void>();

  @ViewChild('table') table: DatatableComponent;

  public columns = {
    detailToggler: false,
    check: true,
    name: true,
    address: true,
    owner: true,
    activityDate: true,
    status: true,
    actions: true
  };

  @Input()
  public rows: SourceOwnerSeriesListItem[];

  public selected: SourceOwnerSeriesListItem[] = [];

  @Input()
  public page: PageResponse<SourceOwnerSeries>;

  public defaultSorts = [{prop: 'name', dir: 'asc'}];

  @Output()
  readonly rowAction: EventEmitter<RowAction> = new EventEmitter<RowAction>();

  @Output()
  readonly tableAction: EventEmitter<TableAction> = new EventEmitter<TableAction>();

  @Output()
  readonly rowsSelect: EventEmitter<SourceOwnerSeriesListItem[]> = new EventEmitter<SourceOwnerSeriesListItem[]>();

  public page$: Subject<any> = new Subject<any>();
  public sorts$: Subject<any> = new Subject<any>();

  public isCityUser = false;

  constructor(
    @Inject(SMARTENCITY_MYDATA_CONFIG) private config: MyDataConfig,
    private authService: AuthService,
    private resizeService: ResizeService,
  ) { }

  ngOnInit() {
    this.resizeService.onResize$.pipe(takeUntil(this.ngDestroy)).subscribe(this.adjustColumns.bind(this));
    this.authService.currentMandate$.pipe(takeUntil(this.ngDestroy)).subscribe((mandate: Mandate) => {
      this.isCityUser = this.config.cityRegistrationCode === mandate.person.registrationNumber;
    });
  }

  ngOnDestroy() {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  ngOnChanges(changes: { [property: string]: SimpleChange }) {
    this.onSelect({ selected: [] });
    if (this.table) {
      this.table.selected = [];
    }
  }

  private adjustColumns(size): void {
    if (size != null && size < SCREEN_SIZE.MD) {
      this.columns.detailToggler = true;
      this.columns.check = false;
      this.columns.address = false;
      this.columns.owner = false;
      this.columns.activityDate = false;
      this.columns.status = false;
      this.columns.actions = false;
    } else {
      this.columns.detailToggler = false;
      this.columns.check = true;
      this.columns.address = true;
      this.columns.owner = true;
      this.columns.activityDate = true;
      this.columns.status = true;
      this.columns.actions = true;
      if (this.table) {
        this.table.rowDetail.collapseAllRows();
      }
    }
  }

  toggleExpandRow(row) {
    this.table.rowDetail.toggleExpandRow(row);
  }

  setPage(event: any) {
    this.tableAction.next({type: TableActionType.SET_PAGE, event: event });
  }

  setSorts(event: any) {
    this.tableAction.next({type: TableActionType.SET_SORTS, event: event });
  }

  public onSelect(data: {selected: SourceOwnerSeriesListItem[]}) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...data.selected);
    this.rowsSelect.next(this.selected);
  }

  public getConsentPersons(series: SourceOwnerSeries): Person[] {
    if(!series.consents) {
      return [];
    }
    return series.consents.map(c => c.person);
  }

  isGroupConsent(item:SourceOwnerSeries): boolean {
    return item?.lastPeriod?.ownerType == 'GROUP_CONSENT';
  }

  isOwner(series: SourceOwnerSeries): boolean {
    return series?.lastPeriod?.ownerType == 'OWNER' || series?.lastPeriod?.ownerType == 'MANAGER';
  }

  public editSeries(data: SourceOwnerSeries) {
    this.rowAction.next({type: RowActionType.EDIT_SERIES, data: data});
  }

  public declineOwnership(data: SourceOwnerSeries) {
    this.rowAction.next({type: RowActionType.DECLINE_OWNERSHIP, data: data});
  }

  public acceptOwnership(data: SourceOwnerSeries) {
    this.rowAction.next({type: RowActionType.ACCEPT_OWNERSHIP, data: data});
  }

  public archiveSeries(data: SourceOwnerSeries) {
    this.rowAction.next({type: RowActionType.ARCHIVE_SERIES, data: data});
  }

  public declineOwnershipAndArchive(data: SourceOwnerSeries) {
    this.rowAction.next({type: RowActionType.DECLINE_AND_ARCHIVE, data: data});
  }

  public showConsents(data: SourceOwnerSeries) {
    this.rowAction.next({type: RowActionType.SHOW_CONSENTS, data: data});
  }

  public setRowDataOwner(data: SourceOwnerSeries) {
    this.rowAction.next({type: RowActionType.SET_NEW_OWNER, data: data});
  }

}
