import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {CoreConfig, SMARTENCITY_CORE_CONFIG} from '@smartencity/core';

@Component({
  selector: 'bottom-nav',
  templateUrl: './bottom-nav.component.html',
  styleUrls: ['./bottom-nav.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BottomNavComponent implements OnInit {

  constructor(@Inject(SMARTENCITY_CORE_CONFIG) public config: CoreConfig) {
  }

  ngOnInit(): void {
  }

}
