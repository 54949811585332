import {Bus} from '../model/bus';
import {NgZone} from '@angular/core';
import {MobilityGmapService} from '../service/mobility-gmap.service';

export class BusOverlay extends google.maps.OverlayView {
  static maxUpdateDeltaTime = 30000;
  static defaultAnimationTime = 5000;
  static frameTime = 125;

  private tickTimeout: any = null;

  private isDrawn = false;

  private mobilityMapService: MobilityGmapService;
  private ngZone: NgZone;
  public bus: Bus;
  private div: any;
  public selected = false;
  public posPrev: { lat: number, lng: number } = null;
  public coursePrev: number = null;
  public tickStart: number = null;
  public tickNow: number = null;
  public animationTime: number = BusOverlay.defaultAnimationTime;
  public animationFrameRequest = null;

  constructor(bus: Bus,
              map,
              mobilityMapService: MobilityGmapService,
              ngZone: NgZone) {
    super();
    this.mobilityMapService = mobilityMapService;
    this.bus = bus;
    this.ngZone = ngZone;
    this.setMap(map);
    this.tickStart = performance.now();
  }

  interpolate(start: number, end: number): number {
    const deltaTime = this.tickNow - this.tickStart;
    if (deltaTime > this.animationTime) {
      return end;
    }
    const delta = end - start;
    return start + (delta / this.animationTime) * deltaTime;
  }

  interpolateModular(start: number, end: number, mod: number) {
    const deltaTime = this.tickNow - this.tickStart;
    if (deltaTime > this.animationTime) {
      return end;
    }
    let delta = end - start;
    if (delta > (mod / 2)) {
      delta -= mod;
    }
    if (delta < 0 - (mod / 2)) {
      delta += mod;
    }
    return (start + (delta / this.animationTime) * deltaTime) % mod;
  }

  onAdd() {
    this.div = document.createElement('DIV');
    this.div.style.border = 'none';
    this.div.style.position = 'absolute';
    this.div.style.paddingLeft = '0px';
    this.div.style.cursor = 'pointer';
    const panes = this.getPanes();
    panes.overlayMouseTarget.appendChild(this.div);

    this.isDrawn = false;

    this.div.addEventListener('click', () => {
      this.mobilityMapService.onBusClick(this.bus);
    });

  }

  onRemove() {
    if (this.div) {
      this.isDrawn = false;
      this.div.parentNode.removeChild(this.div);
      this.div = null;
    }
  }

  draw() {
    if (!this.div) {
      return;
    }
    let point;
    if (this.posPrev) {
      point = this.getProjection().fromLatLngToDivPixel(new google.maps.LatLng(
        this.interpolate(this.posPrev.lat, this.bus.lat),
        this.interpolate(this.posPrev.lng, this.bus.lng)));
    } else {
      point = this.getProjection().fromLatLngToDivPixel(new google.maps.LatLng(
        this.bus.lat,
        this.bus.lng));
    }
    if (point) {
      let x;
      let y;

      if (this.selected === true) {
        // 63*23
        x = point.x - 31.5;
        y = point.y - 11.5;
        this.div.classList.remove('pointer-bus-icon');
        this.div.classList.remove('pointer-bus-icon-on');

        this.div.classList.add('pointer-bus-icon-active');
      } else {
        // 34*14
        x = point.x - 17;
        y = point.y - 7;
        this.div.classList.remove('pointer-bus-icon-active');
        this.div.classList.remove('pointer-bus-icon-on');
        this.div.classList.add('pointer-bus-icon');
      }

      this.div.style.left = x + 'px';
      this.div.style.top = y + 'px';

      if (this.bus.isStopped === true) {
        this.div.classList.add('pointer-bus-icon-stop');
      } else {
        this.div.classList.remove('pointer-bus-icon-stop');
      }

      let rotation = this.bus.course - 90;
      if (this.coursePrev !== null) {
        rotation = this.interpolateModular(this.coursePrev, (this.bus.course - 90 + 360) % 360, 360);
      }
      this.div.style['-ms-transform'] = 'rotate(' + rotation + 'deg)';
      this.div.style['-webkit-transform'] = 'rotate(' + rotation + 'deg)';
      this.div.style['transform'] = 'rotate(' + rotation + 'deg)';
    }

    if (this.isDrawn === false) {
      ($(this.div) as any).popover({
        trigger: 'hover',
        content: this.bus.route + ' - ' + this.bus.description,
        placement: 'top'
      });
      this.isDrawn = true;
    }
  }

  update(bus: Bus) {
    if (bus.lat === this.bus.lat && bus.lng === this.bus.lng) {
      return;
    }

    const tickStart = performance.now();
    let updateDeltaTime = this.tickStart ? tickStart - this.tickStart : null;
    if (updateDeltaTime && updateDeltaTime > BusOverlay.maxUpdateDeltaTime) {
      updateDeltaTime = null;
    }

    if (updateDeltaTime === null) {
      if (this.animationFrameRequest) {
        window.cancelAnimationFrame(this.animationFrameRequest);
        this.animationFrameRequest = null;
      }
      this.posPrev = null;
      this.coursePrev = null;
    } else {
      if (this.animationFrameRequest) {
        this.posPrev = {
          lat: this.interpolate(this.posPrev.lat, this.bus.lat),
          lng: this.interpolate(this.posPrev.lng, this.bus.lng)
        };
        this.coursePrev = this.interpolateModular(this.coursePrev, (this.bus.course - 90 + 360) % 360, 360);
      } else {
        this.posPrev = {lat: this.bus.lat, lng: this.bus.lng};
        this.coursePrev = (this.bus.course - 90 + 360) % 360;
      }
    }
    this.bus = bus;
    this.tickStart = tickStart;
    this.animationTime = updateDeltaTime ? updateDeltaTime : BusOverlay.defaultAnimationTime;

    if (this.posPrev === null || this.coursePrev === null) {
      this.draw();
    } else {
      if (!this.animationFrameRequest) {
        this.ngZone.runOutsideAngular(() => {
          this.animationFrameRequest = window.requestAnimationFrame(this.tick.bind(this));
        });
      }
    }
  }

  tick(tickNow: number) {
    this.tickNow = tickNow;
    this.draw();
    if (this.tickNow - this.tickStart < this.animationTime) {
      this.tickTimeout = setTimeout(() => {
        this.animationFrameRequest = window.requestAnimationFrame(this.tick.bind(this));
      }, BusOverlay.frameTime);
    } else {
      this.animationFrameRequest = null;
    }
  }

  reposition() {
    if (this.tickTimeout) {
      clearTimeout(this.tickTimeout);
    }
    this.posPrev = {lat: this.bus.lat, lng: this.bus.lng};
    this.coursePrev = (this.bus.course - 90 + 360) % 360;
    this.draw();
  }
}
