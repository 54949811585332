<ng-container *ngIf="template">
  <ng-template [ngTemplateOutlet]="template"></ng-template>
</ng-container>
<ng-container *ngIf="!template">
  <core-address-street class="form-group" [showErrors]="showErrors"></core-address-street>
  <div class="form-row">
    <div class="col-6">
      <core-address-apartment class="form-group" [showErrors]="showErrors"></core-address-apartment>
    </div>
    <div class="col-6">
      <core-address-room class="form-group" [showErrors]="showErrors"></core-address-room>
    </div>
  </div>
</ng-container>
