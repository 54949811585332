import {Injectable, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs/internal/Subject';
import {CompareService, CompareItem} from './compare.service';
import {
  catchError,
  map,
  takeUntil
} from 'rxjs/operators';
import {PersonSeries} from '../models/series';
import {AuthService} from '../auth/auth.service';
import {PersonSeriesApiService} from '../http/person-series-api.service';
import {ReplaySubject} from 'rxjs/internal/ReplaySubject';
import {of} from 'rxjs/internal/observable/of';
import {ToastrService} from 'ngx-toastr';
import {CompareItemStoreService, StoredCompareItem} from './compare-item-store.service';

@Injectable({providedIn: 'root'})
export class PersonSeriesCompareService implements OnDestroy {
  private ngDestroy = new Subject<void>();

  public changes$ = new Subject<PersonSeries>();
  public count$ = new ReplaySubject<number>(1);
  public compareItemMap = new Map<number, CompareItem>();

  constructor(
    private authService: AuthService,
    private personSeriesService: PersonSeriesApiService,
    private compareService: CompareService,
    private toastr: ToastrService,
    private compareItemStore: CompareItemStoreService
  ) {
    this.authService.currentMandate$.pipe(takeUntil(this.ngDestroy)).subscribe((data) => {
      this.clear();
    });

    this.changes$.subscribe(() => {
      this.count$.next(this.compareItemMap.size);
    });

    this.count$.next(0);

    this.compareItemStore.getStoredCompareItemsByType('PERSON_SERIES').forEach((item: StoredCompareItem) => {
      this.personSeriesService.getPersonSeries(+item.id).subscribe((ps: PersonSeries) => {
        this.doAdd(ps);
      });
    });
  }

  ngOnDestroy(): void {
    this.changes$.complete();
    this.count$.complete();
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  public clear(): void {
    for (const item of Array.from(this.compareItemMap.values())) {
      this.compareService.deleteCompareItem(item);
    }
    this.compareItemMap.clear();
  }

  public contains(personSeries: PersonSeries) {
    return this.compareItemMap.has(personSeries.id);
  }

  public add(personSeries: PersonSeries): void {
    this.doAdd(personSeries);
    this.toastr.success($localize`Added to comparison`);
  }

  private doAdd(personSeries: PersonSeries): void {
    if (this.compareItemMap.has(personSeries.id)) {
      return;
    }

    {
      const max = 10;
      if (this.compareItemMap.size >= max) {
        this.toastr.error($localize`Maximum number of compare datapoints (${max}) exceeded.`);
        return;
      }
    }

    this.addCompareItemToStore(personSeries);

    const compareItem = this.compareService.createCompareItem(personSeries.name);
    this.compareItemMap.set(personSeries.id, compareItem);
    this.compareService.createCompareItemDataset(compareItem, personSeries.id, personSeries.name, true, (filters) => {
      return this.personSeriesService.getPersonSeriesHistoricalMeasurements(
        personSeries.id,
        filters.range.from.toISOString(),
        filters.range.to.toISOString(),
        filters.aggregationGroupingType
      ).pipe(
        catchError((e: any) => {
          console.error('Error loading historical data', e);
          return of({
            event: null,
            measurement: null
          });
        }),
        map((historicalResponse) => {
          return {
            filters: filters,
            historicalResponse: historicalResponse
          };
        })
      );
    });
    compareItem.destory$.pipe(takeUntil(this.ngDestroy)).subscribe(() => {
      this.compareItemMap.delete(personSeries.id);
      this.changes$.next(personSeries);
      this.toastr.success($localize`Removed from comparison`);
      this.removeCompareItemFromStore(personSeries);
    });
    this.changes$.next(personSeries);
  }

  public remove(personSeries: PersonSeries): void {
    const compareItem = this.compareItemMap.get(personSeries.id);
    if (!compareItem) {
      return;
    }
    this.compareService.deleteCompareItem(compareItem);
    this.removeCompareItemFromStore(personSeries);
  }

  private addCompareItemToStore(personSeries: PersonSeries): void {
    this.compareItemStore.addCompareItemToStore('PERSON_SERIES', String(personSeries.id));
  }

  private removeCompareItemFromStore(personSeries: PersonSeries): void {
    this.compareItemStore.removeCompareItemFromStore('PERSON_SERIES', String(personSeries.id));
  }

}
