import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'displayUnit'})
export class UnitPipe implements PipeTransform {

    private unitsMap = {
        "percent":"%",
        "temp_c":"°C"
    };

    public transform(value: string, exponent: string): string {
        if(this.unitsMap[value]){
            return this.unitsMap[value];
        } else if(value != null){
            return " " + value;
        }
        return "";
    }
}
