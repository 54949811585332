import {Person} from './person';
import {PersonSeries, PersonSeriesGroupRef, SourceOwnerSeries} from './/series';
import {PersonParameter} from './parameter';
import {WidgetTemplate} from '../../../../mydata/src/lib/components/dashboard/widget';

export class QuestionnaireSelectOption {
  name: string;
  value: number;
}

export class QuestionnaireFile {
  id: number;
  fileId: number;
  name: string;
  mimeType: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
}

export class QuestionnaireFormulaMappingRow {
  inputs: {[variable: string]: any};
  output: number;
}

export class QuestionnaireField {
  id: number;
  type: string;
  symbol: string;
  name: string;
  description: string;
  responseType: string;
  valueType: string;
  selectOptions: QuestionnaireSelectOption[];
  unit: string;
  value: number;
  personParameter: PersonParameter;
  personSeries: PersonSeries;
  windowPeriodType: string;
  windowOperationType: string;
  periodCount: number;
  required: boolean;
  defaultValue: number;

  formulaType: string;
  variables: string[];
  expression: string;
  mapping: QuestionnaireFormulaMappingRow[];
  time: string | Date | moment.Moment;
  samplingType: string;
}

export class QuestionnaireParticipantMeta {
  id: number;
  person: Person;
  status: string;
  sourceIds: number[];
  createdAt: string;
  updatedAt: string;
}

export class QuestionnaireEmailInvitationMeta {
  uuid: number;
  participant: QuestionnaireParticipant;
  status: string;
  email: string;
  createdAt: Date;
  updatedAt: Date;
}

export class QuestionnaireInvitationAcceptedDto {
  questionnaireId: number;
  participant: QuestionnaireParticipant;
  questionnaireType: string;
}

export class QuestionnaireParticipation {
  questionnaireId: number;
  publicParticipation: boolean;
  participants: QuestionnaireParticipantMeta[];
  emailInvites: QuestionnaireEmailInvitationMeta[];
}

export class QuestionnaireParticipant {
  id: number;
  questionnaire: Questionnaire;
  responses: ResponseRef[] = [];
  person: Person;
  status: string;
  createdAt: string;
  updatedAt: string;
  widgetTemplates: WidgetTemplate[];
}

export class ResponseRef {
  id: number
  date: string
}

export class QuestionnaireResults {
  submitCount = 0;
  questionnaire: Questionnaire;
  resultFields: QuestionnaireResultsField[] = [];
}

export class QuestionnaireResultsField {
  field: QuestionnaireField;
  responses: QuestionnaireResultsResponseField[];
  text: string;
  value: number;
  option: string;
  personParameter: PersonParameter;
  personSeries: PersonSeries;
}

export class QuestionnaireResultsResponseField {
  id: number;
  participantId: number;
  type: string;
  text: string;
  value: number;
  option: string;
  personParameter: PersonParameter;
  personSeries: PersonSeries;
  unit: string;
  coefficient: number;
  timeExponent: number;
  deltaReading: number;
}

export class QuestionnaireResponse {
  id: number;
  name: string;
}

export class QuestionnaireResponseDetail extends QuestionnaireResponse {
  questionnaire: QuestionnaireReference;
  responseFields: QuestionnaireResponseFieldDetail[];
  participationStatus: string;
  status: string;
  createdAt: string;
  updatedAt: string;
}

export class QuestionnaireResponseField {
  id: number;
  field: QuestionnaireField;
}

export class QuestionnaireResponseFieldDetail extends QuestionnaireResponseField{
  type: string;
  text: string;
  value: number;
  option: string;
  personParameter: PersonParameter;
  personSeries: PersonSeries;
  unit: string;
  coefficient: number;
  timeExponent: number;
  deltaReading: number;
}

export class Questionnaire {
  id: number;
  status: string;
  name: string;
  description: string;
  files: QuestionnaireFile[];
  fields: QuestionnaireField[];
  publicParticipation: boolean;
  participants: QuestionnaireParticipantMeta[];
  emailInvitations: QuestionnaireEmailInvitationMeta[];
  person: Person;
  updatedAt: string;
  formulaUpdateErrorCount: number = 0;
  personSeriesGroup: PersonSeriesGroupRef
}

export enum QuestionnaireTypes {
  OTHER = 'OTHER',
  ENERGY_MANAGER = 'ENERGY_MANAGER'
}

export class QuestionnaireDataOwnerSeries {
  id: number;
  type: string;
  name: string;
  description: string;
  formula: string;
  dataOwnerSeriesList: SourceOwnerSeries[];
}

export class QuestionnaireReference {
  id: number;
  type: string;
  name: string;
  description: string;
  files: QuestionnaireFile[];
  fields: QuestionnaireField[];
  createdAt: string;
  updatedAt: string;
}

export class QuestionnaireDetail {
  id: number;
  tenantId: number;
  type: string = 'QUESTIONNAIRE';
  status: string;
  name: string;
  description: string;
  files: QuestionnaireFile[] = [];
  fields: QuestionnaireField[] = [];
  publicParticipation: boolean = false;
  participants: QuestionnaireParticipantMeta[] = [];
  emailInvitations: QuestionnaireEmailInvitationMeta[] = [];
  person: Person;
  updatedAt: string;
  widgetTemplateId: number;
}

export class QuestionnairePage {
  content: Questionnaire[];
  page: number;
  size: number;
  totalElements: number;
  totalPages: number;
}

export class QuestionnaireParticipationPage {
  content: QuestionnaireParticipant[];
  page: number;
  size: number;
  totalElements: number;
  totalPages: number;
}

export class QuestionnaireDataOwnerSeriesPage {
  content: QuestionnaireDataOwnerSeries[];
  page: number;
  size: number;
  totalElements: number;
  totalPages: number;
}
