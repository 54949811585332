import { Inject, Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, Subject, ReplaySubject } from 'rxjs';
import { NotificationAddress, NotificationAddressesResponse, Person } from '../models/person';
import { ConsentPersonConsent, Mandate } from '../models/profile';
import { SMARTENCITY_CORE_CONFIG } from '../injection-tokens';
import { CoreConfig } from '../core-config.model';
import { PersonApplication } from '../models/application';
import { map } from 'rxjs/operators';
import { PageResponse } from '../models/page-response';
import { UrlParamsHelper } from '../helpers/url-params-helper';

@Injectable({
  providedIn: 'root'
})
export class UserService implements OnDestroy {
  private ngDestroy = new Subject<void>();

  private focus = new ReplaySubject<boolean>(1);
  public focus$ = this.focus.asObservable();

  private _confirmationList: Subject<void> = new Subject<void>();
  public confirmationListWatcher = this._confirmationList.asObservable();

  constructor(
    @Inject(SMARTENCITY_CORE_CONFIG) private config: CoreConfig,
    private http: HttpClient
  ) {

  }

  ngOnDestroy(): void {
    this.focus.complete();
    this._confirmationList.complete();
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  public setFocus(focus: boolean) {
    this.focus.next(focus);
  }

  public confirmationListChanged() {
    this._confirmationList.next();
  }

  searchUsers(term: string): Observable<any[]> {
    return this.http.get<PageResponse<any>>(this.config.apiUrl + '/user/search', { params: { term: term } }).pipe(map((r) => r.content));
  }

  searchPersons(term: string): Observable<Person[]> {
    return this.http.get<{ content: Person[] }>(this.config.apiUrl + '/person/search', { params: { term: term } }).pipe(map((r) => r.content));
  }

  getMandates(params?: any): Observable<Mandate[]> {
    if (!params) params = {};

    return this.http.get<PageResponse<Mandate>>(this.config.apiUrl + '/user/mandate', {
      params: UrlParamsHelper.cleanParams(params)
    }).pipe(map(r => r.content));
  }

  getConsentsByPerson(params): Observable<PageResponse<ConsentPersonConsent>> {

    return this.http.get<PageResponse<ConsentPersonConsent>>(this.config.apiUrl + '/user/mandate/consent', {
      params: UrlParamsHelper.cleanParams(params)
    });
  }

  createMandate(mandate: Mandate): Observable<Mandate> {
    return this.http.post<Mandate>(this.config.apiUrl + '/user/mandate', mandate);
  }

  saveMandate(data: any): Observable<Mandate> {
    return this.http.put<Mandate>(this.config.apiUrl + '/user/mandate', data);
  }

  deleteMandate(mandate: Mandate) {
    const options: any = {
      body: {
        countryCode: mandate.user.countryCode,
        personalId: mandate.user.personalId
      }
    }

    return this.http.delete(this.config.apiUrl + '/user/mandate', options);
  }

  getPendingApplications(): Observable<PersonApplication[]> {
    return this.http.get<PageResponse<PersonApplication>>(this.config.apiUrl + '/person/application').pipe(map(r => r.content.filter(value => value.confirmationStatus === 'PENDING')));
  }

  getApplications(): Observable<PersonApplication[]> {
    return this.http.get<PageResponse<PersonApplication>>(this.config.apiUrl + '/person/application').pipe(map(r => r.content));
  }

  updateApplicationConfirmation(personApplication: PersonApplication, status: string, token: string) {
    return this.http.put(this.config.apiUrl + '/person/application/' + personApplication.application.id + '/status', {
      confirmationStatus: status,
      confirmationToken: token
    });
  }

  deleteAccount() {
    return this.http.post(this.config.apiUrl + '/person/delete', null);
  }

  confirmAccountDeletion(input) {
    return this.http.put(this.config.apiUrl + '/person/delete', input);
  }

  restoreAccount(input) {
    return this.http.post(this.config.apiUrl + '/person/restore', input);
  }

  getNotificationAddresses(): Observable<NotificationAddressesResponse> {
    return this.http.get(this.config.apiUrl + '/person/notification-address') as Observable<NotificationAddressesResponse>;
  }

  addNotificationAddress(notificationAddress: NotificationAddress): Observable<NotificationAddress> {
    return this.http.post(this.config.apiUrl + '/person/notification-address', notificationAddress) as Observable<NotificationAddress>;
  }

  updateNotificationAddress(id: number, notificationAddress: NotificationAddress): Observable<NotificationAddress> {
    return this.http.put(this.config.apiUrl + '/person/notification-address/' + notificationAddress.id, notificationAddress) as Observable<NotificationAddress>;
  }

  deleteNotificationAddress(notificationAddress: NotificationAddress): Observable<any> {
    return this.http.delete(this.config.apiUrl + '/person/notification-address/' + notificationAddress.id) as Observable<any>;
  }

}
