import {Injectable, OnDestroy} from '@angular/core';
import {debounceTime, distinctUntilChanged, map, shareReplay, startWith, switchMap, takeUntil, withLatestFrom} from 'rxjs/operators';
import {Subject} from 'rxjs/internal/Subject';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';
import {merge} from 'rxjs/internal/observable/merge';
import {FormControl} from '@angular/forms';
import {PageResponse, PersonSeries} from '@smartencity/core';
import {Observable} from 'rxjs';
import {ReplaySubject} from 'rxjs/internal/ReplaySubject';
import {PersonSeriesGroupApiService} from '../../http/person-series-group-api.service';

@Injectable()
export class DatapointGroupSeriesListService implements OnDestroy {

  private ngDestroy = new Subject<void>();

  searchControl = new FormControl();
  pageControl = new FormControl(1);

  public defaultLimit = 10;
  public defaultSorts = [{prop: 'name', dir: 'asc'}];
  public filter$ = this.searchControl.valueChanges.pipe(
    map(value => ({query: value})),
    startWith({}));
  public page$ = this.pageControl.valueChanges.pipe(map(page => page - 1));
  // public sorts$: Subject<any> = new Subject<any>();
  public limit$: Subject<any> = new Subject<any>();
  //
  public filterSortsLimit$ = combineLatest([
    this.filter$,
    this.limit$.pipe(startWith(this.defaultLimit))
  ]).pipe(shareReplay(1));

  public queryParams$ = merge(
    this.filterSortsLimit$.pipe(map(([filter,  limit]: [any, number]) => [filter, limit, {offset: 0, limit: limit}])),
    this.page$.pipe(
      withLatestFrom(this.filterSortsLimit$),
      map(([page, [filter, limit]]: [any, [any, number]]) => [filter, limit, {offset: page, limit: limit}])
    )
  ).pipe(
    distinctUntilChanged(),
    debounceTime(250),
    map(([filter, limit, page]) => this.mapQueryParams(filter, limit, page)),
    takeUntil(this.ngDestroy),
    shareReplay(1)
  );

  public reload$ = new Subject();
  public pageResponse$ = combineLatest([this.queryParams$, this.reload$.pipe(startWith(null as void))]).pipe(
    switchMap(([queryParams, ]: [any, any]) => this.fetchPage(queryParams)),
    shareReplay(1)
  );

  public rows$ = new ReplaySubject<PersonSeries[]>(1);

  addedPersonSeriesList: PersonSeries[] = [];
  addedPersonSeriesMap: Map<number, PersonSeries> = new Map<number, PersonSeries>();

  constructor(private personSeriesGroupService: PersonSeriesGroupApiService) { }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  fetchPage(params: any): Observable<PageResponse<PersonSeries>> {
    return this.personSeriesGroupService.getGroupPersonSeriesPage(params);
  }

  mapQueryParams(filter: any, limit: number, page: any) {
    const queryParams: any = {};

    if (filter) {
      if (filter.query) {
        queryParams.query = filter.query;
      }
    }

    if (page) {
      queryParams.page = page.offset;
      queryParams.size = limit;
    }

    if (limit) {
      queryParams.size = limit;
    }

    return queryParams;
  }

  clearSearch(): void {
    this.searchControl.setValue('');
  }

  setItems(personSeriesList: PersonSeries[]): void {
    this.addedPersonSeriesMap.clear();
    this.addItems(personSeriesList);
  }

  addItem(personSeries: PersonSeries): void {
    this.addedPersonSeriesMap.set(personSeries.id, personSeries);
    this.rows$.next(Array.from(this.addedPersonSeriesMap.values()));
  }

  removeItem(personSeries: PersonSeries): void {
    let item: PersonSeries = this.addedPersonSeriesMap.get(personSeries.id);
    if (item) {
      this.addedPersonSeriesMap.delete(personSeries.id);
      this.rows$.next(Array.from(this.addedPersonSeriesMap.values()));
    }
  }

  isSelected(personSeries: PersonSeries): boolean {
    return this.addedPersonSeriesMap.get(personSeries.id) != null;
  }

  addItems(personSeriesList: PersonSeries[]): void {
    for (let personSeries of personSeriesList) {
      this.addedPersonSeriesMap.set(personSeries.id, personSeries);
    }
    this.rows$.next(Array.from(this.addedPersonSeriesMap.values()));
  }

  removeAll(): void {
    this.addedPersonSeriesMap.clear();
    this.rows$.next(Array.from(this.addedPersonSeriesMap.values()));
  }

}
