import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import {AddressService} from '../address.service';

const selector = 'core-address-room';
let nextId = 0;

@Component({
  selector: selector,
  templateUrl: './address-room.component.html',
  styles: [':host {display: block}']
})
export class AddressRoomComponent implements OnInit {
  id = `${selector}-${nextId++}`;

  @Input()
  showErrors = true;

  formControl: AbstractControl = this.addressService.getRoomControl();

  constructor(private addressService: AddressService) { }

  ngOnInit() {
  }
}
