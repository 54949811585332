<div class="modal-content">
  <div class="modal-header header-border flex-row d-flex p-3">
    <div>
      <h5 class="modal-title w-100">{{questionnaire?.name}}  <span class="small ml-2" *ngIf="questionnaire?.description" [innerHTML]="questionnaire?.description | htmlEscape | linky"></span></h5>
      <span class="help-text font-light">{{questionnaire?.updatedAt | date: 'dd. MMMM yyyy'}}</span>
      <!--p class="help-text mb-0 mt-3" *ngIf="questionnaire?.description" [innerHTML]="questionnaire?.description | htmlEscape | linky"></p-->
    </div>
    <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
      <span class="fal fa-times"></span>
    </button>
  </div>
  <div class="modal-body pt-2">
    <div class="modal-navigation mt-0 mb-3">
      <ul class="navbar-nav navbar-tabs mb-3 justify-content-start">
        <li class="nav-item pl-0">
          <a class="nav-link" [class.active]="view === 'fields'" (click)="setView('fields')"><ng-container i18n>Questions</ng-container>&nbsp;({{response?.responseFields.length}})</a>
        </li>
        <li class="nav-item" *ngIf="questionnaire?.files.length">
          <a class="nav-link" [class.active]="view === 'files'" (click)="setView('files')"><ng-container i18n>Files</ng-container>&nbsp;({{questionnaire?.files.length}})</a>
        </li>
      </ul>
      <div class="versions">
        <ng-container *ngFor="let responseRef of responses; let i = index">
          <div [ngClass]="(historyResponse && historyResponse.id == responseRef.id) || (!historyResponse && responseRef.id == responseId) ? 'version-item active' : 'version-item'"
          (click)="viewResponse(responseRef)">
          <span>Vastused {{responses.length - i}}</span>
          <small class="text-muted">{{responseRef.date | date: 'dd.MM.yyyy'}}</small>
        </div>
      </ng-container>
     </div>
    </div>
    <mydata-response-fields #fields [response]="response" [showErrors]="showErrors" [hidden]="historyResponse || view !== 'fields'"></mydata-response-fields>
    <mydata-response-fields *ngIf="historyResponse" [response]="historyResponse" [showErrors]="false" [hidden]="view !== 'fields'"></mydata-response-fields>
    <mydata-view-files [questionnaire]="questionnaire" [hidden]="view !== 'files'"></mydata-view-files>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light btn-sm" aria-label="Close" i18n (click)="close()">Close</button>
    <button type="button" class="btn btn-danger btn-sm" i18n (click)="delete()" [disabled]="progress && true" *ngIf="questionnaire?.type == 'FORMULA_SOURCE_TEMPLATE' && response?.participationStatus != 'COMPLETED' && response?.participationStatus != 'DECLINED' && response?.status !== 'SUBMITTED'">Delete</button>
    <button type="button" class="btn btn-danger btn-sm" i18n (click)="decline()" [disabled]="progress && true" *ngIf="questionnaire?.type !== 'FORMULA_SOURCE_TEMPLATE' && response?.participationStatus != 'COMPLETED' && response?.participationStatus != 'DECLINED' && response?.status !== 'SUBMITTED' && response?.status !== 'DECLINED'">Decline</button>
    <button type="button" class="btn btn-primary btn-sm" i18n (click)="save()" [disabled]="progress && true" *ngIf="response?.participationStatus != 'COMPLETED' && response?.participationStatus != 'DECLINED' && response?.status !== 'SUBMITTED' && response?.status !== 'DECLINED'">Save</button>
    <button type="button" class="btn btn-success btn-sm" i18n (click)="submit()" [disabled]="progress && true" *ngIf="response?.participationStatus != 'COMPLETED' && response?.participationStatus != 'DECLINED' && questionnaire?.type == 'FORMULA_SOURCE_TEMPLATE' && response?.status !== 'SUBMITTED' && response?.status !== 'DECLINED'">Create sources</button>
    <button type="button" class="btn btn-success btn-sm" i18n (click)="submit()" [disabled]="progress && true" *ngIf="response?.participationStatus != 'COMPLETED' && response?.participationStatus != 'DECLINED' && questionnaire?.type !== 'FORMULA_SOURCE_TEMPLATE' && response?.status !== 'SUBMITTED' && response?.status !== 'DECLINED'">Send</button>
    <button type="button" class="btn btn-success btn-sm" i18n (click)="saveAsNewResponse()" [disabled]="progress && true" *ngIf="response?.status !== null && response?.status !== 'PENDING' && response?.status !== 'IN_PROGRESS' && response?.status !== 'DECLINED'">Create new response</button>
  </div>
</div>
