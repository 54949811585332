import {Component, Inject, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {SourceOwnerSeries, Threshold} from '@smartencity/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {HttpClient} from '@angular/common/http';
import {SMARTENCITY_MYDATA_CONFIG} from '../../../../injection-tokens';
import {MyDataConfig} from '../../../../mydata-config.model';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'mydata-thresholds-modal',
  templateUrl: './thresholds-modal.component.html',
})
export class ThresholdsModalComponent implements OnInit, OnChanges {
  public thresholdsUpdated = false;

  @Input()
  public callback: (result: boolean) => void;

  @Input()
  public sourceOwnerSeries: SourceOwnerSeries;

  public rows: Threshold[] = [];

  constructor(
    private http: HttpClient,
    @Inject(SMARTENCITY_MYDATA_CONFIG) private config: MyDataConfig,
    public modalRef: BsModalRef,
    public toastr: ToastrService
  ) {
  }

  ngOnInit() {
    this.loadThresholds();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['sourceOwnerSeries']) {
      this.loadThresholds();
    }
  }

  loadThresholds() {
    this.http.get(this.config.apiUrl + '/person-series/' + this.sourceOwnerSeries.personSeries.id + '/thresholds').subscribe((result: Threshold[]) => {
      this.rows = result;
    }, (error) => {
      console.error(error);
      this.toastr.error($localize`Error loading thresholds`);
    });
  }

  close() {
    if (this.callback) {
      this.callback(this.thresholdsUpdated);
    }
    this.modalRef.hide();
  }

  addRow() {
    this.rows.push({
      id: null,
      personSeriesId: this.sourceOwnerSeries.personSeries.id,
      name: '',
      value: null,
      comparisonOperator: null,
      enabled: false,
      createdAt: null,
      updatedAt: null
    });
  }

  removeRow(i: number) {
    this.thresholdsUpdated = true;
    this.rows.splice(i, 1);
  }

  saveRow(i: number) {
    this.thresholdsUpdated = true;
  }
}
