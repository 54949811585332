import {Injectable} from '@angular/core';
import {filter} from 'rxjs/operators';
import {NavigationEnd, Router} from '@angular/router';
import {Location as CommonLocation} from '@angular/common';
import {SharedKeywordStore} from '../../services/shared-search-keyword-store.service';


@Injectable()
export class DatapointGroupsIndexNavbarService {

  private prevUrl: string = null;

  private groupRelatedUrlPath: RegExp = new RegExp('/mydata/datapoints/group*');

  constructor(private router: Router,
              private location: CommonLocation,
              private sharedKeyWordStore: SharedKeywordStore) {
    this.prevUrl = this.location.path();
    this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      let url = event.url;
      if (url !== '/mydata/datapoints' && !this.groupRelatedUrlPath.test(url)) {
        this.sharedKeyWordStore.setState('');
      }
      this.prevUrl = event.url;
    });

  }

}
