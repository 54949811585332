import {RedirectStrategy} from './redirect-strategy';
import {UriBuilder} from './uri-builder';

export abstract class AbstractRedirectStrategy implements RedirectStrategy {

  protected redirectUriBuilder: UriBuilder;

  setRedirectUriBuilder(redirectUriBuilder: UriBuilder): void {
    this.redirectUriBuilder = redirectUriBuilder;
  }

  getRedirectUriBuilder(): UriBuilder {
    return this.redirectUriBuilder;
  }

  redirect(params?: any): Promise<boolean> {
    this.redirectToUrl(this.redirectUriBuilder.build(params));

    return Promise.resolve(true);
  }

  redirectToUrl(url: string): void {
    window.location.href = url;
  }

}
