import {Inject, Injectable, LOCALE_ID, OnDestroy} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {SMARTENCITY_MYDATA_CONFIG} from '../../injection-tokens';
import {MyDataConfig} from '../../mydata-config.model';
import {forkJoin, Observable} from 'rxjs';
import {CreateFromWidgetTemplateRequest, Widget, WidgetDataset, WidgetDatasetValue, WidgetType} from './widget';
import {map, takeUntil} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import {
  ChartService,
  LatestValue,
  PersonParameter, PersonSeriesApiService,
  PersonSeriesHistoricalResponse,
  QuestionnaireResultsField,
  QuestionnaireResultsResponseField,
  Threshold
} from '@smartencity/core';
import {Subject} from 'rxjs/internal/Subject';
import moment, {Moment} from 'moment';
import {DashboardFilter} from "./dashboard";
import {WidgetPeriodHelper} from '../../../../../core/src/lib/helpers/widget-period-helper';
import {WidgetApiService } from '../../http/widget-api.service';
import {ReportFileExtension} from './dashboard-report-types';
import {PersonParameterApiService} from '../../http/person-parameter-api.service';

@Injectable({
  providedIn: 'root'
})
export class WidgetService implements OnDestroy {
  private ngDestroy = new Subject<void>();

  public widgets$ = new Subject<Widget[]>();

  public datasetTypeOptions: any[] = [
    {
      value: 'PERSON_SERIES',
      label: $localize`Datapoint`
    }, {
      value: 'PERSON_PARAMETER',
      label: $localize`Parameter`
    }, {
      value: 'Q11E_FIELD',
      label: $localize`Question field`
    }, {
      value: 'Q11E_RESPONSE_FIELD',
      label: $localize`Question response`
    }
  ];

  constructor(
    private http: HttpClient,
    @Inject(SMARTENCITY_MYDATA_CONFIG) private config: MyDataConfig,
    private personSeriesService: PersonSeriesApiService,
    private personParameterApi: PersonParameterApiService,
    private widgetApiService: WidgetApiService,
    @Inject(LOCALE_ID) public locale: string
  ) { }

  ngOnDestroy(): void {
    this.widgets$.complete();
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  public loadWidgets(dashboardFilter: DashboardFilter) {
    this.widgetApiService.getWidgets(dashboardFilter).pipe(takeUntil(this.ngDestroy)).subscribe((widgets: Widget[]) => {
      this.widgets$.next(widgets);
    });
  }

  public getWidgetPersonSeriesValue(widget: Widget, widgetDataset: WidgetDataset, rangeFrom?: Moment, rangeTo?: Moment, aggregationGroupingType?: string): Observable<WidgetDatasetValue> {
    if (!rangeFrom && !rangeTo && widget.type === WidgetType.TIME_FRAME_CHART) {
      rangeFrom = moment();
      rangeTo = rangeFrom.clone();
      aggregationGroupingType = widget.aggregationGroupingType;
      rangeFrom = WidgetPeriodHelper.calculateStartAt(rangeFrom, widget.periodType, widget.periodCount);
    }

    if (widget.type === WidgetType.TIME_FRAME_CHART) {
      return this.personSeriesService.getPersonSeriesHistoricalMeasurements(
        widgetDataset.personSeriesId,
        rangeFrom ? rangeFrom.toISOString() : rangeFrom,
        rangeTo ? rangeTo.toISOString() : rangeTo,
        aggregationGroupingType
      ).pipe(map((response: PersonSeriesHistoricalResponse) => {
        const wvs = new WidgetDatasetValue();
        wvs.widgetDataset = widgetDataset;
        wvs.personSeriesValue = {
          lastValue: null,
          periodLastValue: null,
          measurement: response.measurement,
          thresholds: []
        };
        return wvs;
      }));
    } else {
      return this.personSeriesService.getPersonSeriesLatestValue(
        widgetDataset.personSeriesId,
        rangeFrom ? rangeFrom.toISOString() : rangeFrom,
        rangeTo ? rangeTo.toISOString() : rangeTo,
        aggregationGroupingType
      ).pipe(map((response: LatestValue) => {
        const wvs = new WidgetDatasetValue();
        wvs.widgetDataset = widgetDataset;
        wvs.personSeriesValue = {
          lastValue: response,
          periodLastValue: response,
          measurement: null,
          thresholds: []
        };
        return wvs;
      }));
    }
  }

  public getWidgetPersonParameterValue(widget: Widget, widgetDataset: WidgetDataset, rangeFrom?: Moment, rangeTo?: Moment, aggregationGroupingType?: string): Observable<WidgetDatasetValue> {

    return this.personParameterApi.getPersonParameter(widgetDataset.personParameterId).pipe(map((personParameter: PersonParameter) => {
      const wvs = new WidgetDatasetValue();
      wvs.widgetDataset = widgetDataset;
      wvs.personParameterValue = personParameter;
      return wvs;
    }));
  }

  public getWidgetQ11eFieldValue(widget: Widget, widgetDataset: WidgetDataset, rangeFrom?: Moment, rangeTo?: Moment, aggregationGroupingType?: string): Observable<WidgetDatasetValue> {
    if (widget.type === WidgetType.TIME_FRAME_CHART) {
      const wvs = new WidgetDatasetValue();
      wvs.widgetDataset = widgetDataset;
      return of(wvs);
    } else {
      return this.http.get<QuestionnaireResultsField>(this.config.apiUrl + '/questionnaire/field/' + widgetDataset.q11eFieldId + '/results').pipe(map((resultsField: QuestionnaireResultsField) => {
        const wvs = new WidgetDatasetValue();
        wvs.widgetDataset = widgetDataset;
        wvs.q11eFieldValue = resultsField;
        return wvs;
      }));
    }
  }

  public getWidgetQ11eResponseFieldValue(widget: Widget, widgetDataset: WidgetDataset, rangeFrom?: Moment, rangeTo?: Moment, aggregationGroupingType?: string): Observable<WidgetDatasetValue> {
    return this.http.get<QuestionnaireResultsResponseField>(this.config.apiUrl + '/questionnaire-participation/response-field/' + widgetDataset.q11eResponseFieldId + '/results').pipe(map((resultsField: QuestionnaireResultsResponseField) => {
      const wvs = new WidgetDatasetValue();
      wvs.widgetDataset = widgetDataset;
      wvs.q11eResponseFieldValue = resultsField;
      return wvs;
    }));
  }

  public getWidgetValues(widget: Widget, rangeFrom?: Moment, rangeTo?: Moment, aggregationGroupingType?: string): Observable<WidgetDatasetValue[]> {
    const observables: Observable<WidgetDatasetValue>[] = widget.datasets.map((widgetDataset: WidgetDataset) => {
      switch (widgetDataset.type) {
        case 'PERSON_SERIES': {
          return this.getWidgetPersonSeriesValue(widget, widgetDataset, rangeFrom, rangeTo, aggregationGroupingType);
        } case 'PERSON_PARAMETER': {
          return this.getWidgetPersonParameterValue(widget, widgetDataset, rangeFrom, rangeTo, aggregationGroupingType);
        } case 'Q11E_FIELD': {
          return this.getWidgetQ11eFieldValue(widget, widgetDataset, rangeFrom, rangeTo, aggregationGroupingType);
        } case 'Q11E_RESPONSE_FIELD': {
          return this.getWidgetQ11eResponseFieldValue(widget, widgetDataset, rangeFrom, rangeTo, aggregationGroupingType);
        }
      }
    });
    return observables.length ? forkJoin(observables) : of([]);
  }

  public getWidgetThresholds(widget: Widget): Observable<Threshold[]> {
    const observables: Observable<Threshold[]>[] = widget.datasets.map((widgetSeries: WidgetDataset) => {
      return this.widgetApiService.getWidgetSeriesThresholds(widget, widgetSeries);
    });
    return observables.length ? forkJoin(observables).pipe(map((results: Threshold[][]) => {
      const flatResult = [];
      for (const items of results) {
        flatResult.push(...items);
      }
      return flatResult;
    })) : of([]);
  }

  public downloadDashboard(reportDownloadParams: {
    dashboardFilter: DashboardFilter,
    range: any,
    extension: ReportFileExtension,
    aggregationGroupingType?: string
  }): Observable<HttpResponse<Blob>> {
    const params = WidgetService.getDownloadRequestParams(reportDownloadParams.dashboardFilter, reportDownloadParams.range, reportDownloadParams.aggregationGroupingType);
    params.publicDownload = false;

    return this.http.get(this.config.apiUrl + '/dashboard/download/' + reportDownloadParams.extension, {
      observe: 'response',
      responseType: 'blob',
      params: params,
      headers: {
        'Accept-Language': this.locale.toLowerCase()
      }
    });
  }

  public downloadPdf(dashboardFilter: DashboardFilter, range, imagesMap: any[], aggregationGroupingType?: string): Observable<HttpResponse<Blob>> {
    const params = WidgetService.getDownloadRequestParams(dashboardFilter, range, aggregationGroupingType);
    params.images = imagesMap;
    params.publicDownload = false;

    return this.http.post(this.config.apiUrl + '/dashboard/pdf', params,{
      observe: 'response',
      responseType: 'blob',
      headers: {
        'Accept-Language': this.locale.toLowerCase()
      }
    });
  }

  public downloadWidget(downloadParams: {
    widget: Widget,
    dashboardFilter: DashboardFilter,
    range: any,
    extension: ReportFileExtension,
    aggregationGroupingType?: string
  }) :  Observable<HttpResponse<Blob>>{
    const params = WidgetService.getDownloadRequestParams(downloadParams.dashboardFilter, downloadParams.range, downloadParams.aggregationGroupingType);
    params.widgetId = downloadParams.widget.id;
    params.publicDownload = false;

    return this.http.get(this.config.apiUrl + '/dashboard/widget/download/xlsx', {
      observe: 'response',
      responseType: 'blob',
      params: params,
      headers: {
        'Accept-Language': this.locale.toLowerCase()
      }
    });
  }

  public downloadWidgetXls(widget: Widget, dashboardFilter: DashboardFilter, range, aggregationGroupingType?: string):  Observable<HttpResponse<Blob>> {
    const params = WidgetService.getDownloadRequestParams(dashboardFilter, range, aggregationGroupingType);
    params.widgetId = widget.id;
    params.publicDownload = false;

    return this.http.get(this.config.apiUrl + '/dashboard/widget/xls', {
      observe: 'response',
      responseType: 'blob',
      params: params,
      headers: {
        'Accept-Language': this.locale.toLowerCase()
      }
    });
  }


  public static getDownloadRequestParams(dashboardFilter: DashboardFilter, range, aggregationGroupingType?: string): any{
    const params: any = {};
    if(dashboardFilter.dashboardType){
      params.dashboardType = dashboardFilter.dashboardType;
    }
    if(dashboardFilter.dashboardId){
      params.dashboardId = dashboardFilter.dashboardId;
    }
    if (range && range.from && range.to) {
      params.dateFrom = range.from.toISOString();
      params.dateTo = moment(range.to).add(1, 'days').toISOString();
    }

    if (aggregationGroupingType) {
      params.aggregationGroupingType = aggregationGroupingType;
    }

    params.tzId = Intl.DateTimeFormat().resolvedOptions().timeZone;

    return params;
  }

  public downloadPng(widget: Widget, chart) {
    //TODO: puudu kontroll, kas on lubatud PNG allalaadimine avaliku töölaua puhul
    ChartService.downloadPng(chart, widget.name);
  }


  createFromWidgetTemplate(request: CreateFromWidgetTemplateRequest): Observable<any> {

    return this.widgetApiService.createFromWidgetTemplate(request);
  }
}
