<div class="container-fluid p-4">
  <ng-template #navActions>
    <div class="btn-group d-lg-inline-flex" dropdown>
      <button type="button" class="btn btn-primary btn-sm dropdown-toggle" dropdownToggle><span i18n>Lisa uus</span></button>
      <div class="dropdown-menu dropdown-menu-right" *dropdownMenu>
        <a class="dropdown-item" (click)="createSeries()" i18n>Datapoint</a>
        <a class="dropdown-item" (click)="createGroup()" i18n>Datapoint group</a>
        <a class="dropdown-item" (click)="createFormula()" i18n>Datapoint from formula</a>
      </div>
    </div>
    <div class="btn-floating-mobile-container">
      <div dropdown>
        <button type="button" class="btn btn-primary btn-sm btn-floating-mobile" dropdownToggle><i class="fal fa-plus fa-2x"></i></button>
        <div class="dropdown-menu dropdown-menu-floating-btn" *dropdownMenu>
          <a class="dropdown-item" (click)="createSeries()" i18n>Datapoint</a>
          <a class="dropdown-item" (click)="createGroup()" i18n>Datapoint group</a>
          <a class="dropdown-item" (click)="createFormula()" i18n>Datapoint from formula</a>
        </div>
      </div>
    </div>
  </ng-template>

  <div class="row justify-content-center mb-5">
    <div class="col-sm-12 col-md-6 col-lg-4">
      <div class="input-group input-group-lg form-search">
        <input type="text" class="form-control form-control-lg" placeholder="Type to search..." [formControl]="searchControl" i18n-placeholder>
        <div class="input-group-append">
          <span class="clear-search" (click)="clearSearch()" [ngClass]="{'active': searchControl?.value?.length > 0 }">
            <i class="fal fa-times"></i>
          </span>
          <!-- <div class="custom-dropdown filter-dropdown" dropdown [autoClose]="true" (click)="$event.stopImmediatePropagation();">
            <button type="button" class="btn dropdown-toggle" dropdownToggle i18n>Filter</button>
            <div class="dropdown-menu dropdown-menu-right" *dropdownMenu>
              <div class="accordion-group"  [ngClass]="'show'">
                <div class="accordion-header">
                  <ng-container i18n>Some filterable data?</ng-container>
                </div>
                <div class="accordion-content">
                </div>
              </div>
            </div>
          </div> -->
          <span class="input-group-text">
            <i class="fal fa-search"></i>
          </span>
        </div>
      </div>
    </div>
  </div>

  <section class="mb-4">
    <h2 class="section-title" i18n>Datapoint groups</h2>
    <div class="row">
      <div class="col-12">
        <mydata-groups></mydata-groups>
      </div>
    </div>
  </section>

  <section class="mb-4">
    <h2 class="section-title" i18n>Datapoint formulas</h2>
    <div class="row">
      <div class="col-12">
        <mydata-template-list></mydata-template-list>
      </div>
    </div>
  </section>

</div>
