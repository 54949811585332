import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild,} from '@angular/core';
import {Subject} from 'rxjs/internal/Subject';
import {EnumsService} from '@smartencity/core';
import {WidgetDatasetRow} from '../widget/widget-model';
import {FormControl, ValidationErrors} from '@angular/forms';
import {ChartType, WidgetType} from '../widget';
import {ColorPickerDirective} from 'ngx-color-picker';
import {Subscription} from 'rxjs/internal/Subscription';
import {startWith, takeUntil} from 'rxjs/operators';

const selector = 'mydata-widget-row';
let nextId = 0;

@Component({
  selector: selector,
  templateUrl: './widget-row.component.html',
  providers: []
})
export class WidgetRowComponent implements OnInit, OnChanges, OnDestroy {
  id = `${selector}-${nextId++}`;
  private ngDestroy = new Subject<void>();

  private subscription: Subscription = new Subscription();

  public widgetTypeType = WidgetType;

  public chartTypeType = ChartType;

  @ViewChild(ColorPickerDirective)
  public colorPicker: ColorPickerDirective;

  @Input()
  public widgetType: WidgetType;

  @Input()
  public chartType?: ChartType;

  @Input()
  public row: WidgetDatasetRow;

  @Input()
  public isFirst;

  @Input()
  public isLast;

  public color;

  @Output('removeWidgetDataset')
  removeWidgetDatasetEmitter: EventEmitter<any> = new EventEmitter<any>();

  @Output('up')
  upEmitter: EventEmitter<any> = new EventEmitter<any>();

  @Output('down')
  downEmitter: EventEmitter<any> = new EventEmitter<any>();

  public aggregationTypeOptions = this.enumService.getAggregationTypeOptions();

  public editName = false;

  stackControl: FormControl = new FormControl(false);

  public graphTypeOptions = [
    {value: 'line', label: $localize`Line`},
    {value: 'bar', label: $localize`Bar`},
    {value: 'stack', label: $localize`Stack`}
  ];

  public constructor(
    public enumService: EnumsService
  ) {

  }

  ngOnInit(): void {
    this.stackControl.setValue(this.row.control.get('graphType').value === 'stack');
    this.stackControl.valueChanges.pipe(takeUntil(this.ngDestroy)).subscribe((value) => {
      this.row.control.get('graphType').setValue((value ? 'stack' : 'bar'));
    });
  }

  updateStackControl(): void {
    this.stackControl.setValue(this.row.control.get('graphType').value === 'stack');
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.row) {
      this.subscription.unsubscribe();
      this.subscription = new Subscription();
      this.subscription.add(this.row.control.get('color').valueChanges.pipe(startWith(this.row.control.get('color').value)).subscribe(color => {
        this.color = color;
      }));
    }

    if (changes.widgetType) {
      if (this.widgetType === WidgetType.VALUE_CHART) {
        this.updateStackControl();
      }
    }
  }

  removeWidgetDataset(): void {
    this.removeWidgetDatasetEmitter.emit();
  }

  sortOrderUp(): void {
    this.upEmitter.emit();
  }

  sortOrderDown(): void {
    this.downEmitter.emit();
  }

  toggleEditName(): void {
    this.editName = !this.editName;
  }

  invalid(name: string, formGroup?) {
    if (!formGroup) {
      formGroup = this.row.control;
    }
    const control = formGroup.get(name);
    return control && control.invalid && (control.dirty || control.touched);
  }

  errors(name: string): ValidationErrors {
    const control = this.row.control.get(name);
    return control ? control.errors : null;
  }

  preventClose(event: MouseEvent) {
    event.stopImmediatePropagation();
  }

  colorPickerChange(event) {
    this.color = event;
    this.row.control.get('color').setValue(this.color);
  }

  clearCustomColor() {
    this.color = null;
    this.row.control.get('color').setValue(this.color);
  }

  setCustomColor() {
    this.color = '#ffffff';
    this.colorPicker.colorChanged(this.color);
    this.colorPicker.openDialog();
    this.row.control.get('color').setValue(this.color);
  }
}
