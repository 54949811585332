<ng-container *ngIf="item?.period?.confirmationStatus == 'PENDING' || item?.period?.confirmationStatus=='CANCELLED'">
  <div class="d-flex flex-grow-1 flex-column" i18n>{{assignerName}} shares data from <b>{{datapointName}}</b><br><small class="help-text">{{comment}}</small></div>
</ng-container>
<div class="action-buttons text-right">
  <ng-container *ngIf="item?.period?.confirmationStatus == 'PENDING'">
    <button type="button" class="btn btn-sm btn-danger ml-2 mb-2" (click)="decline(item)" [disabled]="progress || parentProgress" i18n>Decline</button>
    <button type="button" class="btn btn-sm btn-success ml-2 mb-2" (click)="accept(item)" [disabled]="progress || parentProgress" i18n>Accept</button>
  </ng-container>
  <ng-container *ngIf="item?.period?.confirmationStatus == 'CANCELLED'">
    <button type="button" class="btn btn-sm btn-outline-primary" (click)="archive(item)" [disabled]="progress || parentProgress" i18n>Mark as read</button>
    <p class="mb-0 help-text text-nowrap">(This request has been cancelled)</p>
  </ng-container>
</div>
