import {RoadClosure} from '../model/road-closure';
import {RoadClosuresGmapMarker} from './road-closures-gmap-marker';
import Feature = google.maps.Data.Feature;
import LatLng = google.maps.LatLng;
import LatLngBounds = google.maps.LatLngBounds;
import Data = google.maps.Data;
import {MobilityService} from '../service/mobility.service';

export class RoadClosuresGmapLayer {

  public roadClosures: RoadClosure[] = [];
  private selectedFeature: Feature = null;
  private markers: RoadClosuresGmapMarker[] = [];
  public map: google.maps.Map;
  private dataLayers: Data[] = [];
  public visible = false;
  private mobilityService: MobilityService;

  constructor(map: google.maps.Map, mobilityService: MobilityService, roadClosures: RoadClosure[]) {
    this.map = map;
    this.mobilityService = mobilityService;
    this.setRoadClosures(roadClosures);
  }

  public selectRoadClosure(roadClosure: RoadClosure): void {
    let currentFeature = null,
      currentDataLayer = null;
    this.dataLayers.forEach((dataLayer: Data) => {
      dataLayer.forEach((feature: Feature) => {
        if (feature.getProperty("roadClosure") === roadClosure) {
          currentFeature = feature;
          currentDataLayer = dataLayer;
        }
      });
    });

    if (this.selectedFeature) {
      this.dataLayers.forEach((dataLayer : Data) => {
        dataLayer.revertStyle(this.selectedFeature);
      });
    }

    if (roadClosure && currentFeature && currentDataLayer) {
      currentDataLayer.overrideStyle(currentFeature, { fillColor: 'red', strokeColor: 'red' });
    }

    this.markers.forEach((marker) => {
      marker.selected = (marker.feature === currentFeature);
    });

    this.selectedFeature = currentFeature;
  }

  public setRoadClosures(roadClosures: RoadClosure[]): void {
    if (this.visible) {
      return;
    }

    this.visible = true;
    let map = this.map;
    if (!roadClosures || roadClosures.length === 0) {
      return;
    }

    const now: Date = new Date();
    this.dataLayers = [];
    this.dataLayers.push(this.createDataLayer(roadClosures.filter((roadClosure: any) => {
      return this.getStartsAt(roadClosure) <= now;
    }), {
      fillColor: 'black',
      fillOpacity: 0.3,
      strokeColor: 'purple',
      strokeOpacity: 0.7,
      strokeWeight: 7
    }));

    this.dataLayers.push(this.createDataLayer(roadClosures.filter(roadClosure => {
      return this.getStartsAt(roadClosure) > now;
    }), {
      fillColor: 'black',
      fillOpacity: 0.3,
      strokeColor: '#917e69',
      strokeOpacity: 0.7,
      strokeWeight: 7
    }));

    this.drawMarkers();
  }

  private getStartsAt(roadClosure: any): Date {
    return new Date(roadClosure.startsAt);
  }

  public drawMarkers(): void {
    this.markers = [];
    this.dataLayers.forEach((dataLayer: Data) => {
      dataLayer.forEach((feature => {
        // if (feature.getGeometry() instanceof LineString && this.map.getZoom() > 17) {
        //   console.log("Linestring ?");
        //   console.log(feature.getGeometry());
        //   this.markers.push(new RoadClosureMarker(this, feature, Position.START));
        //   this.markers.push(new RoadClosureMarker(this, feature, Position.END));
        // } else {
        this.markers.push(new RoadClosuresGmapMarker(this, this.mobilityService, feature));
        // }
      }));
    });

  }

  public clear(): void {
    if (!this.visible) {
      return;
    }

    this.visible = false;

    for (const dataLayer of this.dataLayers) {
      dataLayer.setMap(null);
      // dataLayer.forEach((feature) => {
      //   dataLayer.remove(feature);
      // });
    }

    this.dataLayers = [];
    this.markers.forEach((marker: RoadClosuresGmapMarker) => {
      marker.setMap(null);
    });

  }

  private createDataLayer(roadClosures: RoadClosure[], styles: Data.StyleOptions): Data {
    let map = this.map;
    const layer = new Data({map: map});
    let features: Feature[] = [];
    for (let roadClosure of roadClosures) {
      const result: Feature[] = layer.addGeoJson({
        type: "Feature",
        geometry: roadClosure.geojson,
        properties: {
          roadClosure: roadClosure,
          selected: false
        }
      });
      features.push(...result);
    }

    if (features) {
      let bounds = new LatLngBounds();
      for (let feature of features) {
        feature.getGeometry().forEachLatLng((latLng: LatLng) => {
          bounds.extend(latLng);
        });
      }

      // map.fitBounds(bounds, 35);
    }

    layer.setStyle(styles);

    layer.addListener('mouseover', (event) => {
      layer.revertStyle(event.feature);
      layer.overrideStyle(event.feature, { fillColor: 'yellow', strokeColor: 'yellow' });
    });

    layer.addListener('mouseout', (event) => {
      if (event.feature == this.selectedFeature) {
        layer.overrideStyle(event.feature, { fillColor: 'red', strokeColor: 'red' });
      } else {
        layer.revertStyle(event.feature);
      }
    });

    layer.addListener('click', (event) => {
      this.mobilityService.selectedRoadClosureSource.next(event.feature.getProperty("roadClosure"));
    });

    return layer;
  }

}
