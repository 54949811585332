import {Inject, Injectable, OnDestroy} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {QuestionnaireDetail, QuestionnaireParticipation} from '../../../../core/src/lib/models/questionnaire';
import {Observable} from 'rxjs/internal/Observable';
import {SMARTENCITY_CORE_CONFIG} from '../../../../core/src/lib/injection-tokens';
import {CoreConfig} from '../../../../core/src/lib/core-config.model';

@Injectable()
export class QuestionnaireParticipationApiService {

  constructor(@Inject(SMARTENCITY_CORE_CONFIG) private config: CoreConfig,
              private http: HttpClient) {
  }

  public getByQuestionnaire(questionnaire: any): Observable<QuestionnaireParticipation> {

    return this.http.get<QuestionnaireParticipation>(this.config.apiUrl + '/questionnaire/' + questionnaire.id + '/participants');
  }

  public confirm(questionnaire: any, data: any): Observable<QuestionnaireParticipation> {

    return this.http.put<QuestionnaireParticipation>(this.config.apiUrl + '/questionnaire/' + questionnaire.id + '/participants', data);
  }

  public save(questionnaire: any, data: any): Observable<QuestionnaireParticipation> {

    return this.http.post<QuestionnaireParticipation>(this.config.apiUrl + '/questionnaire/' + questionnaire.id + '/participants', data);
  }


}
