<div class="d-flex">
  <div class="row d-flex no-gutters w-100 position-relative">
    <div class="map-container" style="width: 100%;">
      <div class="citymap map" #gmap style="height: 100%;">
      </div>
    </div>

    <div class="floating-wrapper flex-column">
      <div class="position-relative">
        <div air-quality-station-modal [airQualityStation]="airQualityStation" (modalClose)="onModalClose()">
      </div>

      <tallinn-layer-filters [displaySettings]="displaySettings" (displaySettingsChange)="displayItemsChanged($event)"></tallinn-layer-filters>
<!--      <ng-container *ngIf="displaySettings.noiseLevel">-->
<!--        <div class="break"></div>-->
<!--          <ng-template *ngTemplateOutlet="playbackTemplate"></ng-template>-->
<!--      </ng-container>-->
    </div>
    <tallinn-noise-level-heatmap [map]="map" *ngIf="displaySettings.noiseLevel" #noiseLevelHeatmapComponent></tallinn-noise-level-heatmap>
  </div>
</div>
