<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title w-100" i18n>Add dashboard from templates</h5>
    <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
      <span class="fal fa-times"></span>
    </button>
  </div>
  <div class="modal-body">
    <div class="selectable-list">
      <div class="row">
        <div class="col-12">
          <div class="custom-list">
            <div class="list-item" [class.selected]="isSelected(template)" *ngFor="let template of widgetTemplates" (click)="toggleSelect(template)">
              <span class="title">{{template.name}}</span>
              <div class="row">
                <span class="col-auto">
                    <label class="light mr-2 mb-0" i18n>Questionnaire</label>
                    <span class="text-light">
                      {{template.q11e.name}}
                    </span>
                  </span>
                <span class="col-auto">
                    <label class="light mr-2 mb-0" i18n>Questionnaire answered at</label>
                    <span class="text-light">
                      {{template.response.date|date:'dd.MM.yyyy HH:mm'}}
                    </span>
                  </span>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-light btn-sm" aria-label="Close" (click)="close()" i18n>Cancel</button>
    <button type="button" class="btn btn-success btn-sm" (click)="addAndClose()" [disabled]="!selected.length" i18n>Add</button>
  </div>
</div>
