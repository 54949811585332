<div class="jumbotron floating-jumbotron content-wrapper" [class.active]="busStop">
  <div class="modal-header modal-header-icon">
    <h5 class="modal-title w-100"><i class="bus-stop-icon"></i>
      {{busStop?.title}} <ng-container *ngIf="busStop?.description"><small> - {{busStop?.description}}</small></ng-container></h5>
    <button type="button" class="close pull-right" aria-label="Close" (click)="clear()">
      <i class="fal fa-times"></i>
    </button>
  </div>
  <div class="modal-body p-3">
    <table class="table table-borderless table-hover busstop-timetable">
      <tr *ngFor="let stopTime of stopTimes" (mouseover)="showShape(stopTime)" (mouseout)="hideShape(stopTime)" style="cursor: pointer">
        <td class="no-wrap">
          <span class="bus-number"><i class="bus-icon"></i> {{stopTime.routeShortName}}</span>
        </td>
        <td>
          <span class="bus-line">{{stopTime.routeLongName}}</span>
        </td>
        <td class="no-wrap">
          <span *ngIf="arrivals.get(stopTime.tripId)" class="approaching-time">{{arrivalText(stopTime)}} <i class="approaching-icon"></i></span>
          <span *ngIf="!arrivals.get(stopTime.tripId)" class="approaching-time">{{arrivalText(stopTime)}} </span>
        </td>
      </tr>
    </table>
  </div>
</div>
