import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { RoadClosuresResponse } from '../model/road-closures-response';
import { CoreConfig, ListResponse, SMARTENCITY_CORE_CONFIG } from '@smartencity/core';
import { StopResponse, StopTime } from '../model/stop';
import { VehicleStopArrival } from '../model/vehicle';
import { Trip } from '../model/trip';
import { EvChargersResponse } from '../model/ev-charger';
import { BusResponse } from '../model/bus';
import { ThroughputResponse } from '../model/traffic';
import { PeopleTrafficCounter } from '../model/people-traffic-counter';

@Injectable()
export class MobilityApiService {

  constructor(private http: HttpClient, @Inject(SMARTENCITY_CORE_CONFIG) private config: CoreConfig) {
  }

  getBicycleStations(params?: any): Observable<any> {
    if (!params) params = {};
    params.cityPortalType = 'BICYCLE_STATIONS';
    params.size = '2000';

    return this.http.get(this.config.cityApiUrl + '/person-series', {
      params: params
    });
  }

  getStopTimes(id: string): Observable<StopTime[]> {

    return this.http.get<StopTime[]>(this.config.cityApiUrl + '/mobility/stops/' + id + '/times');
  }


  getStopArrivals(id: string): Observable<VehicleStopArrival[]> {

    return this.http.get<VehicleStopArrival[]>(this.config.cityApiUrl + '/mobility/stops/' + id + '/arrivals');
  }

  getTrip(tripId: string): Observable<Trip> {

    return this.http.get<Trip>(this.config.cityApiUrl + '/mobility/trips/' + tripId);
  }


  getVehicleArrivals(vehilceId: number): Observable<VehicleStopArrival[]> {

    return this.http.get<VehicleStopArrival[]>(this.config.cityApiUrl + '/mobility/vehicles/' + vehilceId + '/arrivals');
  }

  getRoadClosures(): Observable<RoadClosuresResponse> {

    return this.http.get<RoadClosuresResponse>(this.config.cityApiUrl + '/mobility/road-closures');
  }

  getEvChargers(): Observable<EvChargersResponse> {

    return this.http.get<EvChargersResponse>(this.config.cityApiUrl + '/person-series', {
      params: {
        cityPortalType: 'EV_CHARGERS',
        size: '200'
      }
    });
  }

  getBuses(): Observable<BusResponse> {

    return this.http.get<BusResponse>(this.config.cityApiUrl + '/mobility/buses');
  }

  getStops(): Observable<StopResponse> {

    return this.http.get<StopResponse>(this.config.cityApiUrl + '/mobility/stops');
  }

  getTrafficThroughput(): Observable<ThroughputResponse> {

    return this.http.get<ThroughputResponse>(this.config.cityApiUrl + '/person-series', {
      params: {
        cityPortalType: 'TRAFFIC_THROUGHPUT',
        size: '200'
      }
    });
  }

}
