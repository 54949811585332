import {Inject, Injectable, OnDestroy} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import {Subject} from 'rxjs/internal/Subject';
import {SMARTENCITY_CORE_CONFIG} from '../injection-tokens';
import {CompareService} from './compare.service';
import {CityApiService} from '../http/city-api/city-api.service';
import {CoreConfig} from '../core-config.model';
import {ReplaySubject} from 'rxjs/internal/ReplaySubject';
import {of} from 'rxjs/internal/observable/of';
import {ToastrService} from 'ngx-toastr';
import {throwError} from 'rxjs/internal/observable/throwError';

@Injectable({
  providedIn: 'root'
})
export class LocationsCompareService implements OnDestroy {
  private ngDestroy = new Subject<void>();

  public changes$ = new Subject<any>();
  public count$ = new ReplaySubject<number>(1);
  public compareLocations = new Map<string, {location: any, datasets: any[]}>();

  public itemTypeMap = new Map<string, any>();

  constructor(
    @Inject(SMARTENCITY_CORE_CONFIG) private config: CoreConfig,
    private http: HttpClient,
    private compareService: CompareService,
    private cityService: CityApiService,
    private toastr: ToastrService
  ) {
    this.changes$.subscribe(() => {
      this.count$.next(this.compareLocations.size);
    });

    this.count$.next(0);
  }

  ngOnDestroy(): void {
    this.changes$.complete();
    this.count$.complete();
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  public toggleItem(itemType: any) {
    if (itemType.compareItem) {
      this.compareService.toggleItem(itemType.compareItem);
      itemType.active = itemType.compareItem.active;
    }
  }

  public clear(): void {
    for (const compareLocation of Array.from(this.compareLocations.values())) {
      for (const dataset of compareLocation.datasets) {
        this.compareService.deleteCompareItemDataset(dataset);
      }
    }
    this.compareLocations.clear();
  }

  public addItem(series: any) {
    let itemKey = series.cityPortalType;
    let item = this.itemTypeMap.get(itemKey);
    if (item && item.compareItem) {
      if (series.description && !item.name) {
        item.name = series.description;
      }
      return item;
    }

    const compareItem = this.compareService.createCompareItem(itemKey);
    compareItem.unit = series.unit;
    compareItem.displayUnit = compareItem.unit;

    item = {
      key: itemKey,
      name: (series.description ? series.description : null),
      compareItem: compareItem,
      active: compareItem.active
    };
    this.itemTypeMap.set(itemKey, item);

    return item;
  }

  public contains(location: any) {
    if (!location) {
      return false;
    }
    return this.compareLocations.has(location.key);
  }

  public add(location: any): boolean {
    if (this.compareLocations.has(location.key)) {
      return false;
    }

    const max = 3;
    if (this.compareLocations.size >= max) {
      this.toastr.error($localize`Maximum number of compare locations (${max}) exceeded.`);
      return false;
    }

    const datasets: any[] = [];
    this.compareLocations.set(location.key, {location: location, datasets: datasets});

    for (const series of location.series) {
      let item = this.addItem(series);
      let name = series.description ? series.description : item.key;

      const compareItemDataset = this.compareService.createCompareItemDataset(item.compareItem, series.personSeriesId, name + ' (' + location.key + ')', item.active, (filters) => {
        let result;
        if (!filters.range.from || !filters.range.to) {
          result = throwError($localize`No period specified`);
        } else {
          result = this.cityService.getPersonSeriesHistoricalMeasurements(
            series.personSeriesId,
            filters.range.from.toISOString(),
            filters.range.to.toISOString(),
            filters.aggregationGroupingType
          );
        }
        return result.pipe(
          catchError((e: any) => {
            console.error('Error loading historical data', e);
            return of({
              event: null,
              measurement: null
            });
          }),
          map((historicalResponse) => {
            return {
              filters: filters,
              historicalResponse: historicalResponse
            };
          })
        );
      });

      datasets.push(compareItemDataset);
    }

    this.changes$.next(location);

    return true;
  }

  public remove(location: any): void {
    const compareLocation = this.compareLocations.get(location.key);
    if (!compareLocation) {
      return;
    }
    this.compareLocations.delete(location.key);
    for (const dataset of compareLocation.datasets) {
      this.compareService.deleteCompareItemDataset(dataset);
    }
    this.changes$.next(location);
  }
}
