<div class="vision-overlay" [ngClass]="{'show': show}">
  <div class="modal">
    <div class="modal-header">
      <div class="w-100"></div>
      <button type="button" class="close pull-right" (click)="close()">
        <i class="fal fa-times"></i>
      </button>
    </div>
    <div class="modal-body">
      <div class="container">
        <div class="modal-body__content h-100">
          <div class="modal-content__title">
            <p i18n><span class="modal-content__title__highlight">Tark Tallinn</span> is a platform that provides data about mobility, environment, buildings and weather collected from sensors and devices located in Tallinn city.</p>
          </div>
          <div class="modal-content__grid h-100">
            <div class="modal-content__grid-block h-100" routerLink="/mobility">
              <div class="modal-content__grid-block-icon">
                <icon-mobility></icon-mobility>
              </div>
              <div class="modal-content__grid-block-text" i18n>
                Road closures, bicycle stations and health trail locations can be seen in the mobility section.
              </div>
            </div>
            <div class="modal-content__grid-block h-100" routerLink="/tallinn">
              <div class="modal-content__grid-block-icon">
                <icon-tallinn></icon-tallinn>
              </div>
              <div class="modal-content__grid-block-text" i18n>
                 Information about air quality monitoring stations, public wifi access points and water tap locations can be found in the environment section.
              </div>
            </div>
            <div class="modal-content__grid-block h-100" routerLink="/buildings">
              <div class="modal-content__grid-block-icon">
                <icon-buildings></icon-buildings>
              </div>
              <div class="modal-content__grid-block-text" i18n>
                City (energy efficient) buildings and services can be seen in buildings section.
              </div>
            </div>
            <div class="modal-content__grid-block h-100" routerLink="/meteorology">
              <div class="modal-content__grid-block-icon">
                <icon-weather></icon-weather>
              </div>
              <div class="modal-content__grid-block-text" i18n>
                Information about road conditions, weather observations and forecasts is gathered in the weather section.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
