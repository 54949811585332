import {Component, Inject, Input, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subject} from 'rxjs/internal/Subject';
import {FormControl, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {CoreConfig, SMARTENCITY_CORE_CONFIG} from '@smartencity/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {ToastrService} from 'ngx-toastr';
import {take, takeUntil} from 'rxjs/operators';
import {SupportService} from '../../http/support.service';
import {CdkTextareaAutosize} from '@angular/cdk/text-field';

const selector = 'mydata-feedback-modal';
let nextId = 0;

@Component({
  selector: selector,
  templateUrl: './feedback-modal.component.html',
  styleUrls: ['./feedback-modal.component.css']
})
export class FeedbackModalComponent implements OnInit, OnDestroy {
  id = `${selector}-${nextId++}`;
  private ngDestroy = new Subject<void>();

  @Input()
  public callback: (result: boolean) => void;

  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  public progress = false;
  public showErrors = false;

  pageUrl = window.location.href;

  formGroup = new FormGroup({
    message: new FormControl('', [Validators.required])
  });


  constructor(
    @Inject(SMARTENCITY_CORE_CONFIG) private config: CoreConfig,
    private modalRef: BsModalRef,
    private toastr: ToastrService,
    private supportService: SupportService,
    private _ngZone: NgZone
  ) {
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  ngOnInit() {
    this.triggerResize();
  }

  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable.pipe(take(1)).subscribe(() => this.autosize.resizeToFitContent(true));
  }

  invalid(name: string, formGroup?) {
    if (!formGroup) {
      formGroup = this.formGroup;
    }
    const control = formGroup.get(name);
    return control && control.invalid && (control.dirty || control.touched);
  }

  close() {
    if (this.callback) {
      this.callback(false);
    }
    this.modalRef.hide();
  }

  send() {
    this.formGroup.markAllAsTouched();
    this.formGroup.updateValueAndValidity();
    if (!this.formGroup.valid) {
      console.error('form invalid', this.formGroup);
      return;
    }

    const value = this.formGroup.getRawValue();

    const feedback = Object.assign(value, {
      pageUrl: this.pageUrl,
      cityPortalPerson: {
        registrationNumber: this.config.cityRegistrationCode,
        countryCode: this.config.defaultCountryCode
      }
    });

    this.supportService.sendFeedback(feedback).pipe(takeUntil(this.ngDestroy)).subscribe(() => {
      this.modalRef.hide();
      this.progress = false;
      this.toastr.success($localize`Feedback submitted`);
    }, (error) => {
      this.progress = false;
      this.toastr.error($localize`Submitting feedback failed`);
    }, () => this.progress = false);

  }
}
