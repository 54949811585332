import {Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {AdviceGroup, AuthService, UserService, PersonParameter, ConfirmModalComponent} from '@smartencity/core';
import {Subject} from 'rxjs/internal/Subject';
import {HttpClient} from '@angular/common/http';
import {SMARTENCITY_MYDATA_CONFIG} from '../../injection-tokens';
import {MyDataConfig} from '../../mydata-config.model';
import {take, takeUntil} from 'rxjs/operators';
import {BsModalService} from 'ngx-bootstrap/modal';
import {AdviceEditModalComponent} from './edit-modal/advice-edit-modal.component';
import {ToastrService} from 'ngx-toastr';
import {NavbarService} from '../navbar/navbar.service';

const selector = 'mydata-manage-advices';
let nextId = 0;

@Component({
  selector: selector,
  templateUrl: './advice-management.component.html'
})
export class AdviceManagementComponent implements OnInit, OnDestroy {
  id = `${selector}-${nextId++}`;
  private ngDestroy = new Subject<void>();

  @ViewChild('navActions', {static: true})
  public navTempalte: TemplateRef<any>;

  public rows: AdviceGroup[] = [];

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private navbarService: NavbarService,
    private toastr: ToastrService,
    private http: HttpClient,
    @Inject(SMARTENCITY_MYDATA_CONFIG) private config: MyDataConfig,
    private modalService: BsModalService
  ) {
  }

  ngOnInit() {
    this.loadAdviceGroups();
    this.navbarService.setActionsTemplate(this.navTempalte);
  }

  ngOnDestroy(): void {
    this.navbarService.setActionsTemplate(null);
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  loadAdviceGroups(): void {
    this.http.get<AdviceGroup[]>(this.config.apiUrl + '/advice').pipe(takeUntil(this.ngDestroy)).subscribe(
      (data: AdviceGroup[]) => {
        this.rows = data;
      }, err => {
        return err;
      }
    );
  }

  create() {
    this.edit(null);
  }

  edit(adviceGroup: AdviceGroup): void {
    const modalRef = this.modalService.show(AdviceEditModalComponent, {
      ignoreBackdropClick: true,
      class: 'modal-xl',
      initialState: {
        adviceGroup: adviceGroup
      }
    });

    modalRef.content.savedEmitter.pipe(takeUntil(this.ngDestroy), take(1)).subscribe((personParameter: PersonParameter) => {
      this.loadAdviceGroups();
    });
  }

  delete(row: PersonParameter) {
    const modalRef = this.modalService.show(ConfirmModalComponent, {
      ignoreBackdropClick: true,
      initialState: {
        description: $localize`Are you sure you want to delete the advice group?`,
        callback: (confirm: boolean) => {
          if (confirm) {
            this.http.delete(this.config.apiUrl + '/advice/' + row.id).subscribe((data: any) => {
              this.toastr.success($localize`Advice group deleted`);
              this.loadAdviceGroups();
            });
          }
        }
      }
    });
  }
}
