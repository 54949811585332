import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {Subject} from 'rxjs/internal/Subject';
import {QuestionnaireDetail, QuestionnaireField} from '@smartencity/core';

const selector = 'mydata-view-fields';
let nextId = 0;

@Component({
  selector: selector,
  templateUrl: './view-fields.component.html',
  styleUrls: ['./view-fields.component.css']
})
export class ViewFieldsComponent implements OnInit, OnDestroy, OnChanges {
  id = `${selector}-${nextId++}`;
  private ngDestroy = new Subject<void>();

  @Input()
  questionnaire: QuestionnaireDetail;

  q11eFields: any[] = [];
  fields: any[] = [];

  constructor() { }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateFields();
  }

  updateFields(): void {
    this.q11eFields = this.questionnaire?.fields;
    this.fields = [];

    if (!this.q11eFields) {
      return;
    }

    for (const field of this.q11eFields) {
      const row = {
        fieldId: field.id,
        fieldType: field.type,
        type: field.valueType,
        responseType: field.responseType,
        formulaType: field.formulaType,
        symbol: field.symbol,
        name: field.name,
        description: field.description,
        unit: field.unit,
        selectOptions: field.selectOptions,
        requestedUnit: field.unit,
        expression: field.expression,
        variables: field.variables,
        time: field.time,
        optional: !field.required,
        windowOperationType: field.windowOperationType,
        windowPeriodType: field.windowPeriodType,
        periodCount: field.periodCount,
        selectedOptions: field.selectOptions
      };

      this.fields.push(row);
    }
  }

  public getVariableList(formula: QuestionnaireField) {
    return (formula.variables ? formula.variables : []).join(', ');
  }
}
