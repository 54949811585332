import {Component, OnInit, ViewChild, NgZone} from '@angular/core';
import {LightsService} from './lights.service';
import {Light, LightPage, LightsHistogram} from './lights.model';
import {DatePipe} from '@angular/common';
import {MyLocationButton} from "@smartencity/core";

declare namespace gmaps {
  let ags: any;
}

@Component({
  selector: 'lights-main',
  templateUrl: './lights.component.html',
  providers: [
    LightsService,
    DatePipe
  ]
})
export class LightsComponent implements OnInit {

  @ViewChild('gmap', {static: true})
  gmapElement: any;
  map: google.maps.Map;

  zoomMap: any = {
    1: 1, 2: 1, 3: 1,
    4: 2, 5: 2,
    6: 4, 7: 4,
    8: 5, 9: 5,
    10: 7, 11: 7,
    12: 9,
    13: 12, 14: 12,
    15: 15, 16: 15,
    17: 20, 18: 20,
    19: 38, 20: 40
  };

  heatmap: google.maps.visualization.HeatmapLayer;

  lights: Light[] = [];

  public pageSize = 150;
  public currentPage;
  public totalPages;

  public histogram: LightsHistogram = null;
  public playValue = 48;
  public isPlaying = false;

  constructor(
    public lightsService: LightsService,
    public zone: NgZone,
    public datePipe: DatePipe
  ) {
  }

  ngOnInit() {

    const conf = this.lightsService.getMapsConfig();

    const mapProp = {
      center: conf.center,
      zoom: conf.zoom,
      mapTypeControl: false,
      fullscreenControl: false
    };
    this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);

    this.heatmap = new google.maps.visualization.HeatmapLayer({
      data: [],
      radius: 14,
      maxIntensity: 100,
      map: this.map
    });
    this.setHeatmapRadius();

    const style = [
      {
        stylers: [
          {saturation: -99}
        ]
      }
    ];

    const svc = new gmaps.ags.MapService('https://gis.tartulv.ee/arcgis/rest/services/Tanavavalgustus/TV_avalik/MapServer');
    google.maps.event.addListener(svc, 'load', () => {
      const ov = new gmaps.ags.MapType(svc, {
        opacity: 0.5
      });
      this.map.overlayMapTypes.insertAt(0, ov);
    });

    google.maps.event.addListenerOnce(this.map, 'idle', () => {
      setTimeout(() => {
        this.loadLights(0);
      }, 300);
    });


    google.maps.event.addListener(this.map, 'zoom_changed', () => {
      this.zone.run(() => {
        this.setHeatmapRadius();
      });
    });

    this.map.mapTypes.set('map-style', new google.maps.StyledMapType(style, {}));
    this.map.setMapTypeId('map-style');
    new MyLocationButton(this.map);
    this.loadHistogram();
  }

  setHeatmapRadius() {
    const zoom = this.map.getZoom();
    this.heatmap.set('radius', this.zoomMap[zoom]);
  }


  loadLights(page: number) {
    this.lightsService.getLights({size: this.pageSize, page: page, sort: 'personSeriesId'}).subscribe(
      (page: LightPage) => {
        this.totalPages = page.totalPages;
        this.currentPage = page.page;

        this.lights = this.lights.concat(page.content);
        this.updateHeatmap();

        if (this.totalPages > this.currentPage) {
          this.loadLights(this.currentPage + 1);
        }
      },
      (err: any) => {
      }
    );
  }

  updateHeatmap() {
    const lightsWithValues = this.lights.filter(value => {
      return value.value != null && value.value > 0;
    });
    const heatmapData = lightsWithValues.map(light => {
      return {
        location: new google.maps.LatLng(light.lat, light.lng),
        weight: light.value,
        id: 1
      };
    });

    this.heatmap.setData(heatmapData);
  }

  loadHistogram() {
    this.lightsService.getLightsHistogram().subscribe(
      (data: LightsHistogram) => {
        this.histogram = data;
      }
    );
  }


  togglePlay() {
    if (this.isPlaying == true) {
      this.isPlaying = false;
    } else {
      this.isPlaying = true;
      if (this.playValue == 48) {
        this.playValue = 0;
      }
      this.playFrame();
    }
  }

  playFrame() {
    this.loadFrame(this.playValue, () => {
      setTimeout(() => {
        if (this.isPlaying) {
          this.playValue = this.playValue + 1;
          if (this.playValue == 48) {
            this.isPlaying = false;
          } else {
            this.playFrame();
          }
        }
      }, 500);
    });
  }

  playChanged(index) {
    this.playValue = index;
    this.loadFrame(this.playValue, () => {});
  }

  loadFrame(index, cb) {
    const histItem = this.histogram.content[index];
    this.lightsService.getLightsState({date: histItem.date}).subscribe(stateResponse => {
      const state = stateResponse.state;
      this.lights.forEach(light => {
        light.value = state[light.id];
      });
      this.updateHeatmap();
      cb();
    });
  }

}
