export class EecBuilding {
    energyClass: string;
    address: string;
    lat: number;
    lng: number;
    div: HTMLElement;
  }
  
export enum EnergyClass {
  active,
  A,
  B,
  C,
  D,
  E,
  F,
  G,
  H,
}