import {NumberFormatConstants} from '@smartencity/core';

export class ChartDefaults {

  public locale: string = 'et';

  public xAxis: any = {
    type: 'time',
    distribution: 'linear',
    ticks: {
      source: 'auto',
      autoSkip: true
    },
    time: {
      displayFormats: {
        millisecond: 'HH:mm:ss.SSS',
        second: 'HH:mm:ss',
        minute: 'HH:mm',
        hour: 'DD.MM.YYYY HH:mm',
        day: 'DD.MM.YYYY',
        week: 'DD.MM.YYYY',
        month: 'MM.YYYY',
        quarter: '[Q]Q - YYYY',
        year: 'YYYY'
      },
      tooltipFormat: 'DD.MM.YYYY HH:mm:ss'
    }
  };

  public yAxis: any = {
    afterDataLimits: (axis) => {
      if (axis.end !== undefined) {
        let max = -Infinity;
        for (const annotation of this.chartAnnotations) {
          if (annotation.value > max) {
            max = annotation.value;
          }
        }
        if (max >= axis.end) {
          axis.max = axis.end + (axis.end - axis.start) / 10000;
        }
      }
    },
    ticks: {
      beginAtZero: true,
      precision: 4,
      callback: (value, index, values) => {
        return new Intl.NumberFormat(this.locale, {
          maximumFractionDigits: NumberFormatConstants.DEFAULT_NUMBER_FORMAT.defaultFractionDigits,
          useGrouping: false
        }).format(value);
      }
    }
  };

  public chartAnnotations: any[] = [];

  constructor(locale: string = 'et') {
    this.locale = locale;
  }

}
