import { NgModule } from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {NgSelectModule} from '@ng-select/ng-select';
import {RouterModule} from '@angular/router';
import {MatSliderModule} from '@angular/material/slider';
import {CoreModule} from '@smartencity/core';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import { ArcGISModule } from 'projects/arc-gis/src/public-api';
import { TallinnModule } from 'projects/tallinn/src/public-api';
import { MeteorologyComponent } from '../public-api';
import { GmapMeteorologyComponent } from './component/gmap-meteorology.component';
import { OlMeteorologyComponent } from './component/ol-meteorology.component';
import { MeteorologyLayerFiltersComponent } from './component/meteorology-layer-filters/meteorology-layer-filters.component';
import { MeteorologyObjectsModalComponent } from './component/meteorology-objects-modal/meteorology-objects-modal.component';
import { MeteorologyRoutingModule } from './meteorology-routing.module';
import { WeatherStationModalComponent } from './component/weather-station-modal/weather-station-modal.component';
import { RoadConditionModalComponent } from './component/road-condition-modal/road-condition-modal.component';
import { RoadWeatherModalComponent } from './component/road-weather-modal/road-weather-modal.component';
import { RoadConditionDataService } from './service/road-condition-data.service';
import { RoadWeatherConditionDataService } from './service/road-weather-condition-data.service';

@NgModule({
  imports: [
    MeteorologyRoutingModule,
    CommonModule,
    RouterModule,
    FormsModule,
    CoreModule,
    NgSelectModule,
    MatSliderModule,
    TooltipModule,
    ArcGISModule,
    TallinnModule
  ],
  declarations: [
    MeteorologyComponent,
    GmapMeteorologyComponent,
    OlMeteorologyComponent,
    MeteorologyLayerFiltersComponent,
    MeteorologyObjectsModalComponent,
    WeatherStationModalComponent,
    WeatherStationModalComponent,
    RoadConditionModalComponent,
    RoadWeatherModalComponent
  ],
  providers: [
    DatePipe,
    RoadConditionDataService,
    RoadWeatherConditionDataService
  ],
  exports: [MeteorologyComponent],
})
export class MeteorologyModule { }
