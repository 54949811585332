import {AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Location} from '@angular/common';
import {ManageService} from '../manage.service';
import {
  QuestionnaireDetail,
  QuestionnaireParticipation
} from '@smartencity/core';
import {Subject} from 'rxjs/internal/Subject';
import {filter, map, pluck, switchMap, takeUntil, tap} from 'rxjs/operators';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {MyDataConfig} from '../../../mydata-config.model';
import {HttpClient} from '@angular/common/http';
import {FormGroup} from '@angular/forms';
import {QuestionnaireParticipantsComponent} from '../questionnaire-participants/questionnaire-participants.component';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';
import {of} from 'rxjs/internal/observable/of';
import {CanDeactivateQuestionnaireGuard} from '../can-deactivate-questionnaire.guard';
import {Observable} from 'rxjs/internal/Observable';
import {SMARTENCITY_MYDATA_CONFIG} from '../../../injection-tokens';
import {QuestionnaireFieldsComponent} from '../questionnaire-fields/questionnaire-fields.component';
import {BsModalService} from 'ngx-bootstrap/modal';
import {WidgetTemplateModalComponent} from '../../dashboard/widget-template-modal/widget-template-modal.component';
import {WidgetTemplate} from '../../dashboard/widget';
import {WidgetTemplateApiService} from '../../../http/widget-template-api.service';

@Component({
  selector: 'app-questionnaire',
  templateUrl: './questionnaire.component.html',
  styleUrls: ['./questionnaire.component.css'],
  providers: [ManageService, CanDeactivateQuestionnaireGuard]
})
export class QuestionnaireComponent implements OnInit, OnDestroy {
  private ngDestroy = new Subject<void>();

  public type;

  @ViewChild('participants')
  participantsComponent: QuestionnaireParticipantsComponent;

  @ViewChild('fields')
  fieldsComponent: QuestionnaireFieldsComponent;

  public participantConfirmEnabled = false;

  public view = 'info';
  public questionnaire: QuestionnaireDetail;
  public partialEdit = false;
  public formGroup: FormGroup;

  constructor(
    @Inject(SMARTENCITY_MYDATA_CONFIG) private config: MyDataConfig,
    private http: HttpClient,
    public manageService: ManageService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private modalService: BsModalService,
    private widgetTemplateApi: WidgetTemplateApiService
  ) {
    const copyFromId$ = this.router.events.pipe(filter(e => e instanceof NavigationEnd), map(e => this.router.getCurrentNavigation().extras?.state?.copyFromId));
    combineLatest([this.route.params.pipe(pluck('id')), copyFromId$, this.route.data.pipe(pluck('type'))]).pipe(takeUntil(this.ngDestroy), switchMap(([id, copyFromId, type]) => {
      if (id) {
        return this.http.get<QuestionnaireDetail>(this.config.apiUrl + '/questionnaire/' + id);
      } else if (copyFromId) {
        return this.http.get<QuestionnaireDetail>(this.config.apiUrl + '/questionnaire/' + copyFromId).pipe(map(q => Object.assign(q, {id: null, name: q.name + ' (copy)', participants: []})));
      } else {
        const q = new QuestionnaireDetail();
        q.type = type;
        return of(q);
      }
    })).pipe(takeUntil(this.ngDestroy)).subscribe(q => {
      this.manageService.setQuestionnaire(q);
    });
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  ngOnInit(): void {
    this.manageService.questionneire$
      .pipe(takeUntil(this.ngDestroy))
      .subscribe((q) => {
        this.questionnaire = q;
        this.partialEdit = q.status !== 'DRAFT' && q.type === 'OTHER';
        this.type = q?.type;
      });

    combineLatest([
      this.manageService.questionnaireParticipation$,
      this.manageService.publicParticipation$
    ])
      .pipe(takeUntil(this.ngDestroy))
      .subscribe(([participation, publicParticipation]: [QuestionnaireParticipation, boolean]) => {
        this.participantConfirmEnabled = participation?.participants.length > 0 || participation?.emailInvites.length > 0 || publicParticipation;
      });
  }

  public checkAndConirmDiscard(): Observable<boolean> {
    return this.manageService.checkAndConfirmDiscard();
  }

  async setView(view: string) {
    try {
      if (this.type !== 'FORMULA_SOURCE_TEMPLATE') {
        await this.manageService.addField();
      } else {
        await this.manageService.addField('FORMULA', 'EXPRESSION');
      }
      if (this.manageService.validate()) {
        this.view = view;
      }
    } catch (e) {
      console.log('Navigation prevented');
    }
  }

  async saveAndContinue() {
    try {
      if (this.view === 'info') {
        await this.manageService.addField();
        await this.manageService.save();
        const path = this.router.url.split('/');
        path.pop();
        path.push('' + this.questionnaire.id);
        this.location.replaceState(path.join('/'));
        this.setView('fields');
      } else if (this.view === 'fields') {
        // await this.fieldsComponent.save();
        // await this.manageService.addField();
        // await this.manageService.save();
        if (this.type !== 'FORMULA_SOURCE_TEMPLATE') {
          this.setView('participants');
        }
      }
    } catch (e) {
      console.log('Navigation prevented');
    }

  }

  async save() {
    if (this.view === 'fields') {
      await this.fieldsComponent.save();
    }
    //salvestuse teeb juba fieldsComponent
    // await this.manageService.save();
  }

  async saveParticipants() {
    if (this.view === 'participants') {
      await this.participantsComponent.save(false);
    }
  }

  async confirmParticipants() {
    if (this.view === 'participants') {
      await this.participantsComponent.save(true);
    }
  }

  async saveAndRespond() {
    await this.fieldsComponent.save();
    //salvestuse teeb juba fieldsComponent
    // await this.manageService.save();
    await this.manageService.respond();
  }

  createWidgetTemplate(): void {
    const widgetTemplate = new WidgetTemplate();
    const modalRef = this.modalService.show(WidgetTemplateModalComponent, {
      ignoreBackdropClick: true,
      class: 'modal-xxl',
      initialState: {
        widget: widgetTemplate,
        q11e: this.questionnaire,
        onHide: (widget: WidgetTemplate) => {
          this.questionnaire.widgetTemplateId = widget.id;
        }
      }
    });
  }

  editWidgetTemplate(id: number) {

    this.widgetTemplateApi.getWidgetTemplate(id).subscribe((widgetTemplate: WidgetTemplate) => {

      const modalRef = this.modalService.show(WidgetTemplateModalComponent, {
        ignoreBackdropClick: true,
        class: 'modal-xxl',
        initialState: {
          widget: widgetTemplate,
          q11e: this.questionnaire,
          onHide: (widget: WidgetTemplate) => {
            this.questionnaire.widgetTemplateId = widget.id;
          }
        }
      });

    });

  }

  openWidgetTemplateModal(): void {
    this.widgetTemplateApi.getWidgetTemplatesByQ11eId(this.questionnaire.id).subscribe((widgetTemplates: WidgetTemplate[]) => {
      const modalRef = this.modalService.show(WidgetTemplateModalComponent, {
        ignoreBackdropClick: true,
        class: 'modal-xxl',
        initialState: {
          widgets: widgetTemplates,
          q11e: this.questionnaire,
          onHide: (widget: WidgetTemplate) => {
            this.questionnaire.widgetTemplateId = widget.id;
          }
        }
      });


    });
  }

}
