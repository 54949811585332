import { OlMapWrapper, OlOverlayWrapper } from "@smartencity/core";
import { WeatherStation } from "../model/weather-station";
import {MeteorologyService} from '../service/meteorology.service';
import {WeatherStationConstants} from '../model/weather-station-constants';

export class WeatherStationOlMarkerWrapper extends OlOverlayWrapper {
  public selected = false;
  private weatherStation: WeatherStation;
  public div: any;
  private airTemp = null;
  private windSpeed = null;
  private windDirection = null;

  private meteorologyService: MeteorologyService;

  constructor(weatherStation: any, map: OlMapWrapper, meteorologyService: MeteorologyService) {
    super({
      title: weatherStation.name,
      position: [weatherStation.lng, weatherStation.lat],
      className: "weather-station-marker-wrapper",
      stopEvent: false,
    });
    this.weatherStation = weatherStation;
    this.setMap(map.getMap());

    this.meteorologyService = meteorologyService;
  }

  public draw() {
    if (!this.div) {
      this.div = document.createElement("DIV");
      this.div.title = this.weatherStation.name;
      this.div.style.cursor = "pointer";
      this.div.classList.add("pointer-weather-station-indicator");

      let i = 0;
      for (let indicator of WeatherStationConstants.weatherStationIndicators) {
        let seriesMatch = null;
        for (let series of this.weatherStation.series) {
          if (series.type === indicator.key) {
            i = i + 1;
            seriesMatch = series;
          }
        }

        if (!seriesMatch) {
          continue;
        }

        switch (seriesMatch.type) {
          case "air-temperature":
            this.airTemp = seriesMatch.value + " " + seriesMatch.unit;
            break;
          case "wind-speed":
            this.windSpeed = seriesMatch.value + " " + seriesMatch.unit;
            break;
          case "wind-direction":
            this.windDirection = seriesMatch.value;
            break;
          default:
            break;
        }
      }

      if (this.windDirection != null) {
        this.div.innerHTML +=
          '<span class="weather-station-wind-direction"><div class="icon-wind-speed-indicator" style="transform: rotate(-' +
          this.windDirection +
          'deg);"></div></span>';
      }
      this.div.innerHTML +=
        '<span class="weather-station-name">' +
        this.weatherStation.name +
        "</span>";
        if (this.airTemp != null) {
        this.div.innerHTML +=
          '<span class="weather-station-temp">' + this.airTemp + "</span>";
      }
      if (this.windSpeed != null) {
        this.div.innerHTML +=
          '<span class="weather-station-wind-speed">' +
          this.windSpeed +
          "</span>";
      }
      this.div.innerHTML += '<div class="weather-station-marker-bg"></div>';

      this.div.addEventListener('click', (e) => {
        this.meteorologyService.selectWeatherStation(this.weatherStation);
      });
    }
  }

}
