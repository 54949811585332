import {Inject, Injectable} from '@angular/core';
import {SMARTENCITY_CORE_CONFIG} from '../injection-tokens';
import {CoreConfig} from '../core-config.model';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {GroupSource, GroupSourcePersonSeries} from '../models/source';
import {UrlParamsHelper} from '../helpers/url-params-helper';

@Injectable({
  providedIn: 'root'
})
export class GroupSourceDataService {

  constructor(@Inject(SMARTENCITY_CORE_CONFIG) private config: CoreConfig,
              private http: HttpClient) {
  }

  public getGroupSource(id: number): Observable<GroupSource> {

    return this.http.get<GroupSource>(this.config.apiUrl + "/person-series-group-source/" + id);
  }

  public createGroupSource(groupSource: GroupSource): Observable<GroupSource> {

    return this.http.post<GroupSource>(this.config.apiUrl + "/person-series-group-source", groupSource);
  }

  public saveGroupSource(id: number, groupSource: GroupSource): Observable<GroupSource> {

    return this.http.put<GroupSource>(this.config.apiUrl + "/person-series-group-source/" + id, groupSource);
  }

  public getGroupSourcePersonSeries(id: number, params?: any): Observable<GroupSourcePersonSeries[]> {
    if (!params) params = {};

    return this.http.get<GroupSourcePersonSeries[]>(this.config.apiUrl + "/person-series-group-source/" + id + "/person-series", {
      params: UrlParamsHelper.cleanParams(params)
    });
  }

  public deleteGroupSource(group: GroupSource): Observable<void> {

    return this.http.delete<void>(this.config.apiUrl + '/person-series-group-source/' + group.id);
  }

}
