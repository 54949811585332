import { Injectable } from '@angular/core';
import {BsModalService} from 'ngx-bootstrap/modal';
import {
  CityApiService, ConfirmModalComponent,
  OwnerType,
  PersonSeries, PersonSeriesApiService, PersonSeriesCompareService,
  SourceOwnerSeries,
  Tenant
} from '@smartencity/core';
import {Observable} from 'rxjs';
import {TenantSelectService} from './tenant-select.service';
import {forkJoin} from 'rxjs/internal/observable/forkJoin';

@Injectable()
export class PersonSeriesManageService {

  constructor(private modalService: BsModalService,
              private personSeriesService: PersonSeriesApiService,
              private personSeriesCompareService: PersonSeriesCompareService,
              private tenantSelectService: TenantSelectService,
              private cityService: CityApiService) { }

  public acceptOwnerShip(series: SourceOwnerSeries): Observable<any> {

    return new Observable<any>(observer => {
      const acceptStatus = 'ACCEPTED'

      if (series.lastPeriod.ownerType === OwnerType.OWNER) {
        this.tenantSelectService.selectTenant().subscribe((tenant: Tenant) => {
          this.personSeriesService.updateStatus(series.personSeries, acceptStatus, tenant).subscribe((data: any) => {
            observer.next(data);
          }, (error) => {
            observer.error(error);
          });
        });
      } else {
        this.personSeriesService.updateStatus(series.personSeries, acceptStatus, null).subscribe((data: any) => {
          observer.next(data);
        }, (error) => {
          observer.error(error);
        });
      }

    });
  }

  public declineOwnerShip(personSeries: PersonSeries): Observable<boolean> {

    return new Observable<boolean>((observer) => {
      const modalRef = this.modalService.show(ConfirmModalComponent, {
        ignoreBackdropClick: true,
        initialState: {
          description: $localize`Are you sure you want to decline the datapoint?`,
          callback: (confirm: boolean) => {
            if (!confirm) {
              observer.next(false);
              observer.complete();
              return;
            }

            this.personSeriesService.updateStatus(personSeries, 'DECLINED', null).subscribe((data: any) => {
              observer.next(true);
              observer.complete();
            }, (error) => {
              observer.error(error);
            });

          }
        }
      });
    });
  }

  public declineOwnerShipAndArchive(personSeries: PersonSeries): Observable<boolean> {

    return new Observable((observer) => {

      const modalRef = this.modalService.show(ConfirmModalComponent, {
        ignoreBackdropClick: true,
        initialState: {
          description: $localize`Are you sure you want to cancel and archive the datapoint?`,
          callback: (confirm: boolean) => {
            if (!confirm) {
              observer.next(false);
              observer.complete();
              return;
            }

            this.doArchivePersonSeries(personSeries).subscribe((results) => {
              this.personSeriesCompareService.remove(personSeries);
              observer.next(true);
              observer.complete();
            }, (err) => {
              observer.error(err);
            });

          }
        }
      });

    });

  }

  public archivePersonSeries(personSeries: PersonSeries): Observable<boolean> {

    return new Observable((observer) => {

      const modalRef = this.modalService.show(ConfirmModalComponent, {
        ignoreBackdropClick: true,
        initialState: {
          description: $localize`Are you sure you want to archive the datapoint?`,
          callback: (confirm: boolean) => {
            if (!confirm) {
              observer.next(false);
              observer.complete();
              return;
            }

            if (confirm) {
              this.doArchivePersonSeries(personSeries).subscribe((results) => {
                this.personSeriesCompareService.remove(personSeries);
                observer.next(true);
                observer.complete();
              }, (err) => {
                observer.error(err);
              });

            }
          }
        }
      });
    });
  }

  private doArchivePersonSeries(personSeries: PersonSeries): Observable<any> {
    const queries = [];
    if (personSeries.showOnCityPortal) {
      queries.push(this.cityService.updateShowCityPersonSeriesOnCityPortal(personSeries.id, false));
    }
    queries.push(this.personSeriesService.deletePeriods(personSeries));

    return forkJoin(queries);
  }


}
