import {Component, OnInit, ViewChild, NgZone} from '@angular/core';
import {PeopleService} from './people.service';
import {TowersHistogram, Tower, TowerPage} from './people.model';
import {DatePipe} from '@angular/common';
import {MyLocationButton} from '@smartencity/core';

@Component({
  selector: 'people-main',
  templateUrl: './people.component.html',
  providers: [
    PeopleService,
    DatePipe
  ]
})
export class PeopleComponent implements OnInit {

  @ViewChild('gmap', {static: true})
  gmapElement: any;
  map: google.maps.Map;

  zoomMap: any = {
    1: 1, 2: 1, 3: 1,
    4: 2, 5: 2,
    6: 4, 7: 4,
    8: 5, 9: 5,
    10: 7, 11: 7,
    12: 9,
    13: 12, 14: 12,
    15: 15, 16: 15,
    17: 20, 18: 20,
    19: 38, 20: 40
  };

  heatmap: google.maps.visualization.HeatmapLayer;

  towers: Tower[] = [];

  public pageSize = 150;
  public currentPage;
  public totalPages;

  public histogram: TowersHistogram = null;
  public playValue = 48;
  public isPlaying = false;

  constructor(
    public peopleService: PeopleService,
    public zone: NgZone,
    public datePipe: DatePipe
  ) {
  }

  ngOnInit() {
    const conf = this.peopleService.getMapsConfig();

    const mapProp = {
      center: conf.center,
      zoom: conf.zoom,
      mapTypeControl: false,
      fullscreenControl: false
    };
    this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);

    this.heatmap = new google.maps.visualization.HeatmapLayer({
      data: [],
      dissipating: false,
      radius: 0.01,
      maxIntensity: 1000,
      map: this.map
    });

    const style = [
      {
        stylers: [
          {saturation: -99}
        ]
      }
    ];

    google.maps.event.addListenerOnce(this.map, 'idle', () => {
      setTimeout(() => {
        this.loadTowers(0);
      }, 300);
    });

    this.map.mapTypes.set('map-style', new google.maps.StyledMapType(style, {}));
    this.map.setMapTypeId('map-style');
    new MyLocationButton(this.map);
    this.loadHistogram();
  }

  loadTowers(page: number) {
    this.peopleService.getTowers({size: this.pageSize, page: page, sort: 'personSeriesId'}).subscribe(
      (page: TowerPage) => {
        this.totalPages = page.totalPages;
        this.currentPage = page.page;

        this.towers = this.towers.concat(page.content);

        if (this.totalPages > this.currentPage) {
          this.loadTowers(this.currentPage + 1);
        } else {
          console.log('updateHeatmap');
          this.updateHeatmap();
        }
      },
      (err: any) => {
      }
    );
  }

  updateHeatmap() {
    const towersWithValues = this.towers.filter(value => {
      return value.value != null && value.value > 0;
    });

    let maxValue = 0;
    const heatmapData = towersWithValues.map(tower => {
      if (maxValue < tower.value) {
        maxValue = tower.value;
      }
      return {
        location: new google.maps.LatLng(tower.lat, tower.lng),
        weight: tower.value,
        id: 1
      };
    });

    this.heatmap.set('maxIntensity', maxValue * 0.8);
    this.heatmap.setData(heatmapData);
  }

  loadHistogram() {
    this.peopleService.getTowersHistogram().subscribe(
      (data: TowersHistogram) => {
        this.histogram = data;
      }
    );
  }

  togglePlay() {
    console.log('Toggle ' + this.isPlaying);

    if (this.isPlaying === true) {
      this.isPlaying = false;
    } else {
      this.isPlaying = true;
      if (this.playValue == 48) {
        this.playValue = 0;
      }
      this.playFrame();
    }
  }

  playFrame() {
    this.loadFrame(this.playValue, () => {
      setTimeout(() => {
        if (this.isPlaying) {
          this.playValue = this.playValue + 1;
          if (this.playValue == 48) {
            this.isPlaying = false;
          } else {
            this.playFrame();
          }
        }
      }, 500);
    });
  }

  playChanged(index) {
    this.playValue = index;
    this.loadFrame(this.playValue, () => {
    });
  }

  loadFrame(index, cb) {
    const histItem = this.histogram.content[index];
    this.peopleService.getTowersState({date: histItem.date}).subscribe(stateResponse => {
      const state = stateResponse.state;
      this.towers.forEach(tower => {
        tower.value = state[tower.id];
      });
      this.updateHeatmap();
      cb();
    });
  }

}
