import {Component, Input, OnInit} from '@angular/core';
import {Person} from '@smartencity/core';

@Component({
  selector: 'share-person-item-list',
  templateUrl: './share-person-item-list.component.html'
})
export class SharePersonItemListComponent implements OnInit {

  @Input()
  persons: Person[];

  displayItemsLength: number = 5;

  constructor() { }

  ngOnInit(): void {
  }

  //TODO: move to Person class, needs refactoring, because JSON.stringify() provides object without functions, though we need them to exist!
  // otherwise "not a function" error
  getInitials = (person: Person): string => {
    const nameParts: string[] = person.displayName.split(" ");
    const initials = nameParts.reduce((acc, curr, index) => {
      if (index === 0 || index === nameParts.length -1) {
        acc = `${acc}${curr.charAt(0).toUpperCase()}`;
      }
      return acc;
    }, '');

    return initials ?? 'N/A';
  }

}
