import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import {
  AuthService, ConfirmModalComponent, OwnerType, PageResponse, PendingPersonSeriesGroup,
  PendingSeries,
  PendingSeriesMeta, PersonSeriesApiService,
  SeriesTargetError,
  Tenant,
  UserService
} from '@smartencity/core';
import { takeUntil } from 'rxjs/operators';
import { of } from 'rxjs/internal/observable/of';
import { NotificationService } from '../notification.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs/internal/Observable';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { TenantSelectService } from '../../../services/tenant-select.service';
import { PendingPersonSeriesCancellation } from '@smartencity/core';
import { ScreenSizeConstants } from 'projects/core/src/lib/constants/screen-size-constants';

@Component({
  selector: 'mydata-notifications-modal',
  templateUrl: './notifications-modal.component.html',
  styleUrls: ['./notifications-modal.component.css']
})
export class NotificationsModalComponent implements OnInit, OnDestroy {
  private ngDestroy = new Subject<void>();
  public availableViews = new Set<string>();
  public view = 'person-series';
  public psProgress: string = null;
  public psMetaProgress: string = null;
  public errorProgress: string = null;

  public pendingSeries: PendingSeries[] = [];
  public pendingSeriesMeta: PendingSeriesMeta[] = [];
  public pendingCancellations: PendingPersonSeriesCancellation[] = [];
  public pendingErrors: SeriesTargetError[] = [];
  public pendingSeriesGroups: PendingPersonSeriesGroup[] = [];
  public tenant: Tenant = null;
  public totalCount = 0;
  public showNotification = false;
  public personSeriesStats: {
    pendingCount: number;
    cancelledCount: number;
    totalCount: number;
  } = { cancelledCount: 0, pendingCount: 0, totalCount: 0 };

  public personSeriesGroupStats: {
    pendingCount: number;
    cancelledCount: number;
    totalCount: number;
  } = { cancelledCount: 0, pendingCount: 0, totalCount: 0 };
  public metaStats: { count: number; } = { count: 0 };
  public pendingCancellationsStats: { count: number; } = { count: 0 };
  public errorStats: { count: number; } = { count: 0 };

  public lastPendingPersonSeriesIds = new Set<number>();
  public lastPendingMetaPersonSeriesIds = new Set<number>();

  public pendingPersonSeriesPage: PageResponse<PendingSeries>;

  public isMobile: boolean;

  public hasCancelledPersonSeries: boolean;

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private personSeriesService: PersonSeriesApiService,
    private notificationService: NotificationService,
    private router: Router,
    private tenantSelectService: TenantSelectService,
    private modalRef: BsModalRef,
    private modalService: BsModalService,
    private toastr: ToastrService
  ) {

    if (window.innerWidth < ScreenSizeConstants.SMALL_DEVICE_SCREEN_WIDTH_MAX) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }

    this.notificationService.pendingSeries$.pipe(takeUntil(this.ngDestroy)).subscribe((page: PageResponse<PendingSeries>) => {
      // Uuendab nimekirja ainult siis, kui andmepunktide arv on muutunud pärast värskendust
      if (this.pendingPersonSeriesPage == null || this.pendingPersonSeriesPage.totalElements != page.totalElements && page.page == 0
        || (this.pendingSeries.length < ((this.pendingPersonSeriesPage.page + 1) * this.pendingPersonSeriesPage.size))) {
        this.pendingPersonSeriesPage = page;
        this.pendingSeries.splice(0, this.pendingSeries.length);
        this.pendingSeries.push(...page.content);
      }

      if (this.pendingPersonSeriesPage.page < page.page) {
        this.pendingPersonSeriesPage = page;
        this.pendingSeries.push(...page.content);
      }

      this.personSeriesStats.totalCount = page.totalElements;

      for (let pendingPersonSeries of this.pendingSeries) {
        if(pendingPersonSeries.period?.confirmationStatus == 'CANCELLED') {
          this.hasCancelledPersonSeries = true;
          break;
        }
        this.hasCancelledPersonSeries = false;
      }
      this.updateView();

      for (const pendingPersonSeries of page.content) {
        if (!this.lastPendingPersonSeriesIds.has(pendingPersonSeries.personSeries.id)) {
          this.showNotification = true;
          break;
        }
      }
    });

    this.notificationService.pendingSeriesMeta$.pipe(takeUntil(this.ngDestroy)).subscribe((data) => {
      this.pendingSeriesMeta = data;
      this.metaStats.count = this.pendingSeriesMeta.length;
      this.updateView();

      for (const pendingPersonSeries of data) {
        if (!this.lastPendingMetaPersonSeriesIds.has(pendingPersonSeries.personSeries.id)) {
          this.showNotification = true;
          break;
        }
      }
    });

    this.notificationService.pendingCancellations$.pipe(takeUntil(this.ngDestroy)).subscribe((data) => {
      this.pendingCancellations = data;
      this.pendingCancellationsStats.count = this.pendingCancellations.length;
      this.updateView();
    });

    this.notificationService.pendingSeriesTargetErrors$.pipe(takeUntil(this.ngDestroy)).subscribe((data) => {
      this.pendingErrors = data;
      this.errorStats.count = this.pendingErrors.length;
      this.updateView();
    });

    this.notificationService.pendingSeriesRemove$.subscribe((pendingSeries: PendingSeries) => {
      this.removePendingPersonSeries(pendingSeries);
    });

    this.notificationService.pendingSeriesGroups$.subscribe((page: PageResponse<PendingPersonSeriesGroup>) => {
      this.pendingSeriesGroups = page.content;
      this.personSeriesGroupStats.totalCount = page.totalElements;
      this.updateView();
    });

    this.notificationService.updateRequests();
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  close(): void {
    if (this.modalRef) {
      this.modalRef.hide();
    }

  }

  setView(view: string) {
    this.view = view;
  }

  onShown() {
    this.dismissNotification();
  }

  updateView() {
    this.totalCount = this.personSeriesGroupStats.totalCount + this.personSeriesStats.totalCount + this.metaStats.count + this.errorStats.count;
    this.selectView();

    if (this.totalCount < 1) {
      this.close();
    }
  }

  selectView() {
    this.availableViews.clear();
    if (this.personSeriesStats.totalCount > 0) {
      this.availableViews.add('person-series');
    }
    if (this.personSeriesGroupStats.totalCount > 0) {
      this.availableViews.add('person-series-groups');
    }
    if (this.metaStats.count > 0) {
      this.availableViews.add('person-series-meta');
    }
    if (this.errorStats.count > 0) {
      this.availableViews.add('errors');
    }
    if (this.pendingCancellationsStats.count > 0) {
      this.availableViews.add('cancellations');
    }
    // console.log(this.availableViews);
    // if (!this.availableViews.size && this.modalRef) {
    //   console.log("hide?");
    //   this.modalRef.hide();
    //   return;
    // }

    if (!this.availableViews.has(this.view)) {
      this.view = this.availableViews.values().next().value;
    }
  }

  dismissNotification() {
    const newPendingPersonSeriesIds = new Set<number>();
    const newPendingMetaPersonSeriesIds = new Set<number>();
    for (const pendingPersonSeries of this.pendingSeries) {
      newPendingPersonSeriesIds.add(pendingPersonSeries.personSeries.id);
    }
    for (const pendingPersonSeries of this.pendingSeriesMeta) {
      newPendingMetaPersonSeriesIds.add(pendingPersonSeries.personSeries.id);
    }
    this.lastPendingPersonSeriesIds = newPendingPersonSeriesIds;
    this.lastPendingMetaPersonSeriesIds = newPendingMetaPersonSeriesIds;
    this.showNotification = false;
  }

  private stateChanged() {
    this.notificationService.updateRequests();
    this.userService.confirmationListChanged();
  }

  acceptAllPersonSeries() {
    this.modalService.show(ConfirmModalComponent, {
      initialState: {
        description: $localize`Are you sure you want to accept all person series?`,
        callback: (result: boolean) => {
          if (result) {
            this.psProgress = 'accept-all';
            const optional = !this.needTenantSelection();
            this.tenantSelectService.selectTenant(optional).subscribe((tenant: Tenant) => {
              this.tenant = tenant;
              this.submitAllPendingPersonSeriesStatus('ACCEPTED');
            }, (error) => {
              this.psProgress = null;
              console.error(error);
              this.toastr.error($localize`Tenant selection error`);
            });
          }
        }
      }
    });

  }

  declineAllPersonSeries() {
    this.modalService.show(ConfirmModalComponent, {
      initialState: {
        description: $localize`Are you sure you want to decline all person series?`,
        callback: (result: boolean) => {
          if (result) {
            this.psProgress = 'decline-all';
            this.submitAllPendingPersonSeriesStatus('DECLINED');
          }
        }
      }
    });
  }

  acknowledgeAllPersonSeries() {
    this.modalService.show(ConfirmModalComponent, {
      initialState: {
        description: $localize`Are you sure you want to acknowledge all person series?`,
        callback: (result: boolean) => {
          if (result) {
            this.psProgress = 'archive-all';
            const toForkJoin: Observable<any>[] = this.pendingSeries
              .filter(e => e.period.confirmationStatus === 'CANCELLED' || e.period.confirmationStatus === 'DECLINED')
              .map(r => {
                if (r.period.confirmationStatus === 'CANCELLED') {
                  return this.personSeriesService.acknowledgePeriodCancel(r.personSeries, r.period);
                }

                return this.personSeriesService.deletePeriod(r.personSeries, r.period);
              });

            forkJoin(toForkJoin.length ? toForkJoin : of([])).subscribe((data: any[]) => {
              this.psProgress = null;
              this.toastr.success($localize`Datapoints archived`);
              this.stateChanged();
            },
              (error) => {
                this.psProgress = null;
                console.error(error);
                this.toastr.error($localize`Error archiving datapoints`);
              }
            );
          }
        }
      }
    });
  }

  needTenantSelection(): boolean {
    const ownerSeries = this.pendingSeries.find((value: PendingSeries) => {
      return value.period.ownerType == OwnerType.OWNER;

    });
    return ownerSeries != null;
  }

  submitAllPendingPersonSeriesStatus(status: string) {
    this.personSeriesService.updatePendingStatusBulk(status, this.tenant).subscribe(
      (data: any) => this.stateChanged(),
      (error) => {
        this.psProgress = null;
        console.error(error);
        this.toastr.error($localize`Error submitting confirmation`);
      }, () => this.psProgress = null);
  }

  acceptAllPersonSeriesMeta() {
    this.modalService.show(ConfirmModalComponent, {
      initialState: {
        description: $localize`Are you sure you want to accept all person series meta?`,
        callback: (result: boolean) => {
          if (result) {
            this.psMetaProgress = 'accept-all';
            this.submitAllPersonSeriesMeta('ACCEPTED');
          }
        }
      }
    });
  }

  declineAllPersonSeriesMeta() {
    this.modalService.show(ConfirmModalComponent, {
      initialState: {
        description: $localize`Are you sure you want to decline all person series meta?`,
        callback: (result: boolean) => {
          if (result) {
            this.psMetaProgress = 'decline-all';
            this.submitAllPersonSeriesMeta('DECLINED');
          }
        }
      }
    });
  }

  submitAllPersonSeriesMeta(status: string) {
    this.personSeriesService.updateMetaBulk(this.pendingSeriesMeta.map(value => value.personSeriesMeta), status).subscribe(
      (data: any) => {
        this.stateChanged();
      }, (error) => {
        this.psMetaProgress = null;
        console.error(error);
        this.toastr.error($localize`Error submitting confirmation`);
      }, () => this.psMetaProgress = null
    );
  }

  acknowledgeAllErrors() {
    this.modalService.show(ConfirmModalComponent, {
      initialState: {
        description: $localize`Are you sure you want to acknowledge all errors?`,
        callback: (result: boolean) => {
          if (result) {
            this.errorProgress = 'archive-all';
            const toForkJoin: Observable<any>[] = this.pendingErrors.map(r => {
              return this.personSeriesService.acknowledgeError(r);
            });

            forkJoin(toForkJoin.length ? toForkJoin : of([])).subscribe((data: any[]) => {
              this.errorProgress = null;
              this.toastr.success($localize`Errors archived`);
              this.stateChanged();
            },
              (error) => {
                this.errorProgress = null;
                console.error(error);
                this.toastr.error($localize`Error archiving errors`);
              }
            );
          }
        }
      }
    });
  }

  private removePendingPersonSeries(pendingPersonSeries: PendingSeries): void {
    this.pendingSeries.splice(this.pendingSeries.indexOf(pendingPersonSeries), 1);
    this.personSeriesStats.totalCount--;
    this.pendingPersonSeriesPage.totalElements--;
    this.pendingPersonSeriesPage.size--;

    if (this.personSeriesStats.totalCount == 0) {
      this.stateChanged();
    }
  }

  loadMorePendingSeries() {
    this.notificationService.loadPendingPendingSeries(this.pendingPersonSeriesPage.page + 1);
  }

  acceptAllPendingCancellations() {
    this.modalService.show(ConfirmModalComponent, {
      initialState: {
        description: $localize`Are you sure you want to accept all pending cancellations?`,
        callback: (result: boolean) => {
          if (result) {
            this.psProgress = 'accept-all';
            this.updateAllPendingCancellationStatus('ACCEPTED');
          }
        }
      }
    });
  }

  declineAllPendingCancellations() {
    this.modalService.show(ConfirmModalComponent, {
      initialState: {
        description: $localize`Are you sure you want to decline all pending cancellations?`,
        callback: (result: boolean) => {
          if (result) {
            this.psProgress = 'decline-all';
            this.updateAllPendingCancellationStatus('REJECTED');
          }
        }
      }
    });
  }

  updateAllPendingCancellationStatus(status: string) {
    this.personSeriesService.updateCancellationStatusBulk(this.pendingCancellations, status).subscribe(
      (data: any) => {
        this.stateChanged();
      }, (error) => {
        this.psProgress = null;
        console.error(error);
        this.toastr.error($localize`Error submitting cancellation confirmation`);
      }, () => this.psProgress = null
    );
  }
}
