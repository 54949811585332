import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs/internal/Subject';
import {Application} from '@smartencity/core';

const selector = 'core-cpr-modal';
let nextId = 0;

@Component({
  selector: selector,
  templateUrl: './application-token-modal.component.html',
})
export class ApplicationTokenModalComponent implements OnInit, OnDestroy {
  id = `${selector}-${nextId++}`;
  private ngDestroy = new Subject<void>();
  public showErrors = false;
  public progress = false;

  form = new FormGroup({
    'token': new FormControl('', Validators.required)
  });

  @Input()
  public application: Application;

  @Input()
  public callback: (token: string) => void;

  constructor (
    public modalRef: BsModalRef
  ) { }

  ngOnInit () { }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  close() {
    if (this.callback) {
      this.callback(null);
    }
    this.modalRef.hide();
  }

  save() {
    this.form.markAsDirty();

    if (!this.form.valid) {
      this.showErrors = true;
      return;
    }

    const value = this.form.getRawValue();

    if (this.callback) {
      this.callback(value.token);
    }
    this.modalRef.hide();
  }
}
