
import {Building} from '../model/building';
import {BuildingsGmapService} from '../service/buildings-gmap.service';
import {BuildingsGmapLayer} from './buildings-gmap-layer';
import {BuildingsCompareService} from '@smartencity/core';


const seriesTypeStyleMap = {
  TEMPERATURE: 'value-heat',
  HEAT: 'value-heat',
  WATER: 'value-cold-water',
  ENERGY_CONSUMPTION: 'value-electricity',
  ENERGY_PRODUCTION: 'value-solar-panel'
}

export class BuildingGmapMarker extends google.maps.OverlayView {
  layer: BuildingsGmapLayer;
  building: Building;
  series: any;
  seriesType: string;
  div: HTMLElement;
  selected = false;

  private buildingsMapService: BuildingsGmapService;

  private readonly formatSeriesValue: (series: any) => string;

  constructor(layer: BuildingsGmapLayer,
              building: Building,
              series: any,
              seriesType: string,
              buildingsMapService: BuildingsGmapService,
              seriesValueFormatFn: (series: any) => string,
              public buildingsCompareService: BuildingsCompareService) {
    super();
    this.layer = layer;
    this.building = building;
    this.series = series;
    this.seriesType = seriesType;
    this.formatSeriesValue = seriesValueFormatFn;
    this.buildingsMapService = buildingsMapService;

    this.buildingsCompareService.changes$.subscribe(() => {
      this.buildingIsInComparison(this.building);
    });
  }

  setMap(map: google.maps.Map) {
    super.setMap(map);
  }

  onAdd() {
    this.div = document.createElement('DIV');
    this.div.title = this.building.address;
    this.div.style.cursor = 'pointer';
    if (this.building.cityPortalType === 'POWER_STATION') {
      this.div.classList.add('pointer-power-station');
    } else {
      this.div.classList.add('pointer-house');
    }

    this.div.addEventListener('click', (event) => {
      this.buildingsMapService.onBuildingClick(this.building);
    });

    this.layer.div.appendChild(this.div);
  }


  onRemove() {
    this.div.parentNode.removeChild(this.div);
  }

  draw() {
    if (!this.div) {
      return;
    }

    this.div.innerHTML = this.getMarkerHtml(this.building, this.series, this.seriesType);

    const point = this.getProjection().fromLatLngToDivPixel(new google.maps.LatLng(this.building.lat, this.building.lng));
    if (point) {
      this.div.style.left = (point.x - 22) + 'px';
      this.div.style.top = (point.y - 45) + 'px';
    }

    if (this.selected) {
      this.div.classList.add('active');
    } else {
      this.div.classList.remove('active');
    }

    this.buildingIsInComparison(this.building);
  }

  private getMarkerHtml(building: Building, series: any, seriesType: string): string {
    if (series != null && series.value != null) {
      const value = this.formatSeriesValue(series);
      const valueClass = (seriesTypeStyleMap[seriesType] ?? '');

      return '<span class="value ' + valueClass + '" style="top:-20px;" title="' + building.address + '">' + value + '</span>';
    }

    return '';
  }

  setSelected(selected: boolean): void {
    this.selected = selected;
  }

  buildingIsInComparison(building: Building) {
    if (!this.div) {
      return;
    }

    if(this.buildingsCompareService.contains(building)) {
      this.div.classList.add('in-comparison');
    } else {
      this.div.classList.remove('in-comparison');
    }
  }
}
