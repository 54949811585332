import {MapLayerMarker} from './map-layer-marker';

export abstract class AbstractMapLayerMarker<T, U> implements MapLayerMarker<T, U>{

  protected map: T;

  protected data: U;

  constructor(data: U) {
    this.data = data;
  }

  getData(): U {
    return this.data;
  }

  abstract setSelected(selected: boolean): void;

  abstract isSelected(): boolean;

  setMap(map: T) {
    this.map = map;
  }

  getMap(): T {
    return this.map;
  }

  abstract draw();

}
