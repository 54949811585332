import {Inject, Injectable, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs/internal/Subject';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {GroupsEditModalComponent} from './edit-modal/groups-edit-modal.component';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {RequireUuid} from '../../validators/require-uuid';
import {RequireCoords} from '../../validators/require-coords';
import {RequireAddress} from '../../validators/require-address';
import {
  GroupSource,
  GroupSourcePersonSeries,
  GroupSourceDataService,
  PersonSeries,
  PersonSeriesMetaProperties,
  CustomFormValidators
} from '@smartencity/core';

import {HttpClient} from '@angular/common/http';
import {SMARTENCITY_MYDATA_CONFIG} from '../../injection-tokens';
import {MyDataConfig} from '../../mydata-config.model';
import {take, takeUntil} from 'rxjs/operators';
import {ReplaySubject} from 'rxjs/internal/ReplaySubject';
import {TenantSelectService} from '../../services/tenant-select.service';
import {ToastrService} from 'ngx-toastr';
import {Observable} from "rxjs/internal/Observable";
import {ConfirmModalComponent} from "../../../../../core/src/lib/components/confirm-modal/confirm-modal.component";

export class GroupSourcePersonSeriesItem {
  id: number;
  personSeries: PersonSeries;
  rowFormGroup: FormGroup;
}

@Injectable()
export class GroupsEditService implements OnDestroy {
  private ngDestroy = new Subject<void>();
  private modalRef: BsModalRef;

  public showErrors$ = new Subject<boolean>();
  public groupSource: GroupSource;
  public gspsItemMap = new Map<number, GroupSourcePersonSeriesItem>();
  public itemsList$ = new ReplaySubject<GroupSourcePersonSeriesItem[]>(1);

  public form: FormGroup = new FormGroup({
    id: new FormControl(null, []),
    tenantId: new FormControl(null, [Validators.required]),
    name: new FormControl('', [Validators.required]),
    groupingTag: new FormControl(''),
    seriesType: new FormControl(null, [Validators.required]),
    locationType: new FormControl('ADDRESS', [Validators.required]),
    uuid: new FormControl('', [RequireUuid]),
    lat: new FormControl('', [RequireCoords, CustomFormValidators.latitude]),
    lng: new FormControl('', [RequireCoords, CustomFormValidators.longitude]),
    adsOid: new FormControl(''),
    address: new FormControl('', [RequireAddress]),
    apartment: new FormControl(''),
    room: new FormControl(''),
    unit: new FormControl('', [Validators.required]),
    aggregationType: new FormControl('AVERAGE', [Validators.required]),
    samplingType: new FormControl('HOURLY', [Validators.required]),
    generateHistory: new FormControl(true, [Validators.required]),
  });

  public targetUnit$ = new ReplaySubject<string>(1);

  public commonValue: any = {
    name: null,
    groupingTag: null,
    seriesType: null,
    locationType: null,
    lat: null,
    lng: null,
    adsOid: null,
    address: null,
    apartment: null,
    room: null,
    unit: null
  };

  constructor(
    private modalService: BsModalService,
    private toastrService: ToastrService,
    private http: HttpClient,
    @Inject(SMARTENCITY_MYDATA_CONFIG) private config: MyDataConfig,
    private tenantSelectService: TenantSelectService,
    private groupSourceDataService: GroupSourceDataService
  ) {
    this.form.get('locationType').valueChanges.pipe(takeUntil(this.ngDestroy)).subscribe((locationType: string) => {
      this.form.get('address').updateValueAndValidity();
      this.form.get('uuid').updateValueAndValidity();
      this.form.get('lat').updateValueAndValidity();
      this.form.get('lng').updateValueAndValidity();
    });

    this.form.get('unit').valueChanges.pipe(takeUntil(this.ngDestroy)).subscribe((unit: string) => {
      this.targetUnit$.next(unit);
    });
  }

  ngOnDestroy(): void {
    this.showErrors$.complete();
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  private updateCommonValue(itemList: GroupSourcePersonSeriesItem[]) {
    if (this.form.untouched) {
      if (!itemList.length) {
        this.commonValue = null;
      } else {
        this.commonValue = {
          name: itemList[0].personSeries.name,
          groupingTag: itemList[0].personSeries.groupingTag,
          seriesType: itemList[0].personSeries.seriesType,
          locationType: itemList[0].personSeries.locationType,
          lat: itemList[0].personSeries.lat,
          lng: itemList[0].personSeries.lng,
          adsOid: itemList[0].personSeries.adsOid,
          address: itemList[0].personSeries.address,
          apartment: itemList[0].personSeries.apartment,
          room: itemList[0].personSeries.room,
          unit: itemList[0].personSeries.latestValue && itemList[0].personSeries.latestValue.measurement ? itemList[0].personSeries.latestValue.measurement.unit : null
        };
        for (let i = 1; i < itemList.length; i++) {
          const item = itemList[i];
          const ps: PersonSeries = item.personSeries;
          const latestValue = ps.latestValue;
          const unit = latestValue && latestValue.measurement ? latestValue.measurement.unit : null;
          if (this.commonValue.name !== ps.name) {
            this.commonValue.name = null;
          }
          if (this.commonValue.groupingTag !== ps.groupingTag) {
            this.commonValue.groupingTag = null;
          }
          if (this.commonValue.seriesType !== ps.seriesType) {
            this.commonValue.seriesType = null;
          }
          if (this.commonValue.locationType !== ps.locationType) {
            this.commonValue.locationType = null;
          }
          if (this.commonValue.lat !== ps.lat) {
            this.commonValue.lat = null;
          }
          if (this.commonValue.lng !== ps.lng) {
            this.commonValue.lng = null;
          }
          if (this.commonValue.adsOid !== ps.adsOid) {
            this.commonValue.adsOid = null;
          }
          if (this.commonValue.address !== ps.address) {
            this.commonValue.address = null;
          }
          if (this.commonValue.apartment !== ps.apartment) {
            this.commonValue.apartment = null;
          }
          if (this.commonValue.room !== ps.room) {
            this.commonValue.room = null;
          }
          if (this.commonValue.unit !== unit) {
            this.commonValue.unit = null;
          }
        }

        const formValue = this.form.getRawValue();
        if (formValue.name) {
          delete this.commonValue.name;
        }
        if (formValue.groupingTag) {
          delete this.commonValue.groupingTag;
        }
        if (formValue.seriesType) {
          delete this.commonValue.seriesType;
        }
        if (formValue.locationType) {
          delete this.commonValue.locationType;
        }
        if (formValue.lat) {
          delete this.commonValue.lat;
        }
        if (formValue.lng) {
          delete this.commonValue.lng;
        }
        if (formValue.adsOid) {
          delete this.commonValue.adsOid;
        }
        if (formValue.address) {
          delete this.commonValue.address;
        }
        if (formValue.apartment) {
          delete this.commonValue.apartment;
        }
        if (formValue.room) {
          delete this.commonValue.room;
        }
        if (formValue.unit) {
          delete this.commonValue.unit;
        } else {
          this.targetUnit$.next(this.commonValue.unit);
        }
        this.form.patchValue({
          id: this.form.get('id').value,
          tenantId: this.form.get('tenantId').value,
          unit: this.commonValue?.unit ? this.commonValue?.unit : this.form.get('unit').value
        });
      }
    }
  }

  public setGroupSource(groupSource: GroupSource): void {
    this.groupSource = groupSource;
    if (groupSource) {
      this.form.patchValue({
        id: groupSource.id,
        tenantId: groupSource.targetTenantId,
        name: groupSource.metaProperties.name,
        groupingTag: groupSource.metaProperties.groupingTag,
        seriesType: groupSource.metaProperties.seriesType,
        locationType: groupSource.metaProperties.locationType,
        uuid: groupSource.metaProperties.uuid,
        lat: groupSource.metaProperties.lat,
        lng: groupSource.metaProperties.lng,
        adsOid: groupSource.metaProperties.adsOid,
        address: groupSource.metaProperties.address,
        apartment: groupSource.metaProperties.apartment,
        room: groupSource.metaProperties.room,
        unit: groupSource.unit,
        aggregationType: groupSource.aggregationType,
        samplingType: groupSource.samplingType,
        generateHistory: groupSource.generateHistory
      });

      this.form.get('generateHistory').disable();

      this.groupSourceDataService.getGroupSourcePersonSeries(groupSource.id).subscribe((personSeriesList: GroupSourcePersonSeries[]) => {
        this.setItems(personSeriesList.map((gsPs) => ({
          id: gsPs.id,
          personSeries: gsPs.personSeries as PersonSeries,
          rowFormGroup: this.rowFormGroupFromGroupSourcePersonSeries(gsPs)
        })));
      });

    } else {
      this.form.setValue({
        id: null,
        tenantId: null,
        name: null,
        groupingTag: null,
        seriesType: null,
        locationType: null,
        uuid: null,
        lat: null,
        lng: null,
        adsOid: null,
        address: null,
        apartment: null,
        room: null,
        unit: null,
        aggregationType: 'AVERAGE',
        samplingType: 'HOURLY',
        generateHistory: true
      });

      this.setItems([]);
    }
  }

  private setItems(items: GroupSourcePersonSeriesItem[]) {
    this.gspsItemMap.clear();
    for (const item of items) {
      if (!item.personSeries || !item.personSeries.id) {
        continue;
      }
      this.gspsItemMap.set(item.personSeries.id, item);
    }
    const itemList = Array.from(this.gspsItemMap.values());

    this.updateCommonValue(itemList);

    this.itemsList$.next(itemList);
  }

  addItems(personSeriesList: PersonSeries[]) {
    for (const ps of personSeriesList) {
      const item: GroupSourcePersonSeriesItem = this.gspsItemMap.get(ps.id);
      if (item) {
        item.personSeries = ps;
      } else {
        this.gspsItemMap.set(ps.id, {
          id: null,
          personSeries: ps,
          rowFormGroup: this.rowFormGroupFromPersonSeries(ps)
        });
      }
    }
    const itemList = Array.from(this.gspsItemMap.values());

    this.updateCommonValue(itemList);

    this.itemsList$.next(itemList);
  }

  removeItem(personSeriesId: number) {
    const item: GroupSourcePersonSeriesItem = this.gspsItemMap.get(personSeriesId);
    if (item) {
      this.gspsItemMap.delete(personSeriesId);
      this.itemsList$.next(Array.from(this.gspsItemMap.values()));
    }
  }

  removeItems(personSeriesList: PersonSeries[]) {
    for (const ps of personSeriesList) {
      const item: GroupSourcePersonSeriesItem = this.gspsItemMap.get(ps.id);
      if (item) {
        this.gspsItemMap.delete(ps.id);
        this.itemsList$.next(Array.from(this.gspsItemMap.values()));
      }
    }
  }

  removeAllItems() {
    this.gspsItemMap.clear();
    this.itemsList$.next(Array.from(this.gspsItemMap.values()));
  }

  containsItem(personSeriesId: number) {
    return this.gspsItemMap.get(personSeriesId) != null;
  }

  updateItemsWindowOperationType(windowOperationType: string): void {

    this.gspsItemMap.forEach((item: GroupSourcePersonSeriesItem, i: number) => {
      item.rowFormGroup.get('windowOperationType').setValue(windowOperationType);
    });
  }

  create() {
    this.modalRef = this.modalService.show(GroupsEditModalComponent, {
      ignoreBackdropClick: true,
      class: 'modal-xxl'
    });
  }

  public dismissErrors() {
    this.showErrors$.next(false);
  }

  public async save(): Promise<void> {
    if (!this.form.get('tenantId').value) {
      const tenant = await this.tenantSelectService.selectTenant().toPromise();
      this.form.get('tenantId').setValue(tenant.id);
    }

    const itemList = await this.itemsList$.pipe(takeUntil(this.ngDestroy), take(1)).toPromise();

    this.form.markAllAsTouched();
    this.form.updateValueAndValidity({onlySelf: false, emitEvent: true});
    let isValid = this.form.valid;

    for (const item of itemList) {
      item.rowFormGroup.markAllAsTouched();
      item.rowFormGroup.updateValueAndValidity({
        onlySelf: false,
        emitEvent: true
      });

      isValid = isValid && item.rowFormGroup.valid;
    }

    if (!isValid || !itemList.length) {
      this.showErrors$.next(true);
      console.log('form invalid', this.form, itemList);
      let err = new Error('Invalid');
      if (!itemList.length) {
        err.name = 'DATAPOINT_LIST_EMPTY'
      }
      throw err;
    }

    this.showErrors$.next(false);

    const value = this.form.getRawValue();

    const groupSource: GroupSource = new GroupSource();
    groupSource.metaProperties = new PersonSeriesMetaProperties();
    groupSource.metaProperties.name = value.name;
    groupSource.metaProperties.groupingTag = value.groupingTag;
    groupSource.metaProperties.seriesType = value.seriesType;
    groupSource.metaProperties.locationType = value.locationType;
    groupSource.metaProperties.uuid = value.uuid;
    groupSource.metaProperties.lat = value.lat;
    groupSource.metaProperties.lng = value.lng;
    groupSource.metaProperties.adsOid = value.adsOid;
    groupSource.metaProperties.address = value.address;
    groupSource.metaProperties.apartment = value.apartment;
    groupSource.metaProperties.room = value.room;

    groupSource.id = value.id;
    groupSource.unit = value.unit;
    groupSource.targetTenantId = value.tenantId;
    groupSource.aggregationType = value.aggregationType;
    groupSource.samplingType = value.samplingType;
    groupSource.generateHistory = value.generateHistory;

    groupSource.personSeriesList = [];

    for (const item of itemList) {
      groupSource.personSeriesList.push(this.createGroupPersonSeriesFromItem(item));
    }

    if (groupSource.id) {
      await this.doSaveConfirmAction(groupSource);
    } else {
      await this.groupSourceDataService.createGroupSource(groupSource).toPromise();
    }
  }

  private createGroupPersonSeriesFromItem(item: GroupSourcePersonSeriesItem): GroupSourcePersonSeries {
    const rowFormGroupValue = item.rowFormGroup.getRawValue();
    const groupSourcePersonSeries = new GroupSourcePersonSeries();
    groupSourcePersonSeries.id = item.id;
    groupSourcePersonSeries.personSeries = {id: item.personSeries.id} as PersonSeries;
    groupSourcePersonSeries.unit = rowFormGroupValue.unit;
    groupSourcePersonSeries.coefficient = rowFormGroupValue.coefficient && typeof (rowFormGroupValue.coefficient) === 'string'
      ? rowFormGroupValue.coefficient.trim().replace(',', '.')
      : rowFormGroupValue.coefficient;
    groupSourcePersonSeries.windowOperationType = rowFormGroupValue.windowOperationType;

    return groupSourcePersonSeries;
  }

  private async doSaveConfirmAction(groupSource: GroupSource) {
    return new Observable(subscriber => {
      this.askUpdateConfirmation().subscribe((confirmation) => {

        if (confirmation === true) {
          this.groupSourceDataService.saveGroupSource(groupSource.id, groupSource).subscribe(value => {
            subscriber.next();
            subscriber.complete();
          }, (err) => {
            subscriber.error(err);
            subscriber.complete();
          });
        } else {
          groupSource.id = null;
          groupSource.personSeriesList.forEach(value => value.id = null);

          this.groupSourceDataService.createGroupSource(groupSource).subscribe(value => {
            subscriber.next();
            subscriber.complete();
          }, (err) => {
            subscriber.error(err);
            subscriber.complete();
          });

        }
      });
    }).toPromise();
  }

  private askUpdateConfirmation(): Observable<boolean> {

    return new Observable<boolean>(subscriber => {
      this.modalService.show(ConfirmModalComponent, {
        ignoreBackdropClick: true,
        initialState: {
          description: $localize`Current group is saved before. Are you sure you want to update?`,
          okLabel: $localize`Yes, update`,
          cancelLabel: $localize`No, create new group`,
          callback: (confirm: boolean) => {
            subscriber.next(confirm);
            subscriber.complete();
          }
        }
      });
    });
  }

  private rowFormGroupFromPersonSeries(ps: PersonSeries): FormGroup {
    return new FormGroup({
      unit:  new FormControl(ps.latestValue ? ps.latestValue.measurement ? ps.latestValue.measurement.unit : null : null),
      coefficient: new FormControl(1.0, [Validators.pattern(/^\-?\d+([\.\,]\d+)?$/)]),
      windowOperationType: new FormControl(null, [Validators.required])
    });
  }

  private rowFormGroupFromGroupSourcePersonSeries(gsPs: GroupSourcePersonSeries): FormGroup {
    return new FormGroup({
      unit: new FormControl(gsPs.unit),
      coefficient: new FormControl(gsPs.coefficient, [Validators.required, Validators.pattern(/^\-?\d+([\.\,]\d+)?$/)]),
      windowOperationType: new FormControl(gsPs.windowOperationType ?? 'OFFSET', [Validators.required])
    });
  }

}
