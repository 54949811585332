import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PersonSeries} from '@smartencity/core';

@Component({
  selector: 'datapoint-group-row',
  templateUrl: './datapoint-group-row.component.html'
})
export class DatapointGroupRowComponent implements OnInit {

  @Input()
  public personSeries: PersonSeries;

  @Output('remove')
  removeEmitter = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void {
  }

  public remove(): void {
    this.removeEmitter.next();
  }

}
