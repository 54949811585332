import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditDatapointGroupModalComponent } from './edit-datapoint-group-modal/edit-datapoint-group-modal.component';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CoreModule, DatepickerWrapperModule} from '@smartencity/core';
import {NgxPermissionsModule} from 'ngx-permissions';
import {ModalModule} from 'ngx-bootstrap/modal';
import {PopoverModule} from 'ngx-bootstrap/popover';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {PaginationModule} from 'ngx-bootstrap/pagination';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {NgSelectModule} from '@ng-select/ng-select';
import { DatapointGroupShareModalComponent } from './datapoint-group-share-modal/datapoint-group-share-modal.component';
import { DatapointGroupRowComponent } from './datapoint-group-row/datapoint-group-row.component';
import { DatapointGroupSelectModalComponent } from './datapoint-group-select-modal/datapoint-group-select-modal.component';
import { DeleteDatapointGroupConfirmModalComponent } from './delete-datapoint-group-confirm-modal/delete-datapoint-group-confirm-modal.component';
import { DatapointGroupConsentsModalComponent } from './datapoint-group-consents-modal/datapoint-group-consents-modal.component';
import {VirtualScrollerModule} from 'ngx-virtual-scroller';
import { DatapointGroupSharePersonModalComponent } from './datapoint-group-share-person-modal/datapoint-group-share-person-modal.component';
import {LaddaModule} from 'angular2-ladda';



@NgModule({
  declarations: [
    EditDatapointGroupModalComponent,
    DatapointGroupShareModalComponent,
    DatapointGroupRowComponent,
    DatapointGroupSelectModalComponent,
    DeleteDatapointGroupConfirmModalComponent,
    DatapointGroupConsentsModalComponent,
    DatapointGroupSharePersonModalComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild([]),
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    NgxPermissionsModule.forChild(),
    ModalModule,
    PopoverModule,
    TooltipModule,
    PaginationModule,
    NgxDatatableModule,
    BsDropdownModule,
    NgSelectModule,
    VirtualScrollerModule,
    DatepickerWrapperModule,
    LaddaModule
  ]
})
export class DatapointGroupsManagementModule { }
