<div class="selectable-list" [formGroup]="formGroup">
  <div class="row mb-2">
    <div class="col-12 col-md-6">
      <h3 class="section-title mb-2" i18n>Add participants</h3>
      <span class="separator mt-0 mb-3"></span>
    </div>
  </div>
  <div class="row mb-5">
    <div class="col-12 col-md-6">
      <div class="form-group row" [formGroup]="formGroup" *ngIf="type != 'FORMULA_SOURCE_TEMPLATE' && juridicalPerson">
        <label i18n class="col-md-3 col-form-label col-form-label-other">Settings</label>
        <div class="col-md-9">
          <div class="custom-control custom-switch mb-2">
            <input type="checkbox" class="custom-control-input" [id]="id + '-check-public'" [formControl]="publicParticipationControl">
            <label class="custom-control-label mr-4" [attr.for]="id + '-check-public'" i18n>Enable public participation</label>
            <span class="help-text" i18n>Anyone can submit responses to this questionnaire</span>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-md-3 col-form-label" [attr.for]="id + '-name'"><ng-container i18n>Add participants</ng-container></label>
        <div class="col-md-9">
          <ng-select
            class="custom"
            [clearable]="true"
            [searchable]="true"
            placeholder="Personal identification or registration code or e-mail"
            i18n-placeholder
            appendTo="body"
            formControlName="participants"
            [typeahead]="personTypeahead$"
            [items]="personItems$ | async"
            [selectOnTab]="true"
            [multiple]="true"
            [addTag]="true"
            addTagText="Add e-mails"
            (add)="participantsAdded($event)"
            i18n-addTagText
          >
            <ng-template ng-label-tmp let-item="item">
              <span *ngIf="!isString(item)">{{(item.displayName ? '' + item.displayName + ' ' : '') + '(' + (item | regNo) + ')'}}</span>
              <span *ngIf="isString(item)">{{item}}</span>
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
              <span *ngIf="!isString(item)">{{(item.displayName ? '' + item.displayName + ' ' : '') + '(' + (item | regNo) + ')'}}</span>
              <span *ngIf="isString(item)">{{item}}</span>
            </ng-template>
          </ng-select>
          <div class="float-right">
            <button type="button" class="btn btn-sm btn-secondary my-1" style="white-space: nowrap;" (click)="addParticipants()">
              <ng-container i18n>Add</ng-container>&nbsp;<i class="fal fa-arrow-alt-right"></i></button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-1 d-flex justify-content-center py-2 px-0 p-md-0">
      <span class="exchange-icon">
        <i class="fa fa-exchange"></i>
      </span>
    </div>
    <div class="col-12 col-md-5">
      <div class="filter-bar">
        <h4 class="text-muted" i18n>Selected participants</h4>
        <div class="row">
          <div class="col">
            <button type="button" class="btn-sm btn-link p-0" (click)="removeAll()" i18n>Remove all</button>
          </div>
          <div class="col text-right">
            <span class="text-light" i18n>{itemCount, plural, =1 {{{itemCount}} item} other {{{itemCount}} items}}</span>
          </div>
        </div>
      </div>
      <div class="custom-list">
        <div class="list-item" *ngFor="let participant of participants">
          <span class="title">{{participant.person.displayName}}</span>
          <div class="row">
            <span class="col-auto">
              <label class="light mr-2 mb-0" i18n>Registration number</label>
              <span class="text-light">{{participant.person | regNo}}</span>
            </span>
          </div>
          <span class="remove-button">


            <button type="button" class="close" (click)="remove(participant)" tooltip="Remove" i18n-tooltip placement="auto">
              <span aria-hidden="true">&times;</span>
            </button>
          </span>
        </div>
        <div class="list-item" *ngFor="let invitation of emailInvites">
          <span class="title">{{invitation.email}}</span>
          <div class="row">
            <span class="col-auto">
              <label class="light mr-2 mb-0" i18n>Invite by e-mail</label>
            </span>
          </div>
          <span class="remove-button">
                    <button type="button" class="close" (click)="removeEmailInvitation(invitation)" tooltip="Remove" i18n-tooltip placement="auto">
              <span aria-hidden="true">&times;</span>
            </button>
          </span>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12 col-md-6">
      <h3 class="section-title mb-2" i18n>Create widget based on this questionnaire</h3>
      <span class="separator mt-0 mb-3"></span>
      <div class="filter-bar">
        <h4 class="text-muted" i18n>Created widgets</h4>
        <div class="row">
          <div class="col">
            <button type="button" class="btn-sm btn-link p-0" (click)="removeAllWidgets()" i18n>Remove all</button>
          </div>
<!--          <div class="col text-right">-->
<!--            <span class="text-light" i18n>{itemCount, plural, =1 {{{itemCount}} item} other {{{itemCount}} items}}</span>-->
<!--          </div>-->
        </div>
      </div>

      <div class="custom-list mb-3">
        <div class="list-item" *ngFor="let widgetTemplate of widgetTemplates">
          <span class="title"> {{widgetTemplate.name}}</span>
<!--          <div class="row">-->
<!--            <span class="col-auto">-->
<!--              <label class="light mr-2 mb-0" i18n>Registration number</label>-->
<!--              <span class="text-light">{{participant.person | regNo}}</span>-->
<!--            </span>-->
<!--          </div>-->
          <span class="remove-button">
            <button type="button" class="close" (click)="removeWidget(widgetTemplate)" tooltip="Remove" i18n-tooltip placement="auto">
              <span aria-hidden="true">&times;</span>
            </button>
          </span>
        </div>
      </div>

      <button class="btn btn-sm btn-primary m-md-auto" type="button" (click)="openWidgetTemplate()">
        <span i18n>Widget management</span>
      </button>
    </div>
  </div>

</div>
