<div class="modal-header">
  <div style="display: flex; align-items: stretch; flex-direction: column;">
    <h5 class="modal-title w-100" i18n>Shared history</h5>
  </div>
  <button type="button" class="close pull-right" i18n-aria-label aria-label="Close" (click)="close()">
    <i class="fal fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <div class="col-12">
    <table class="table table-responsive-md btn-table modal-table">
      <thead>
        <tr>
          <td><span i18n>Person</span></td>
          <td><span i18n>Assigned at</span></td>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let tokenPerson of tokenPersons">
          <td>
            <span *ngIf="tokenPerson.assignType === 'EMAIL'">{{tokenPerson.email}}</span>
            <span *ngIf="tokenPerson.assignType === 'REG_NO'">{{tokenPerson.registrationNumber}}</span>
            <span *ngIf="tokenPerson.assignType === 'PERSON'">{{tokenPerson.person.displayName}} ({{tokenPerson.person.registrationNumber}})</span>
          </td>
          <td>
            <span *ngIf="tokenPerson.assignedAt">{{tokenPerson.assignedAt|date:'dd.MM.yyyy'}}</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary btn-sm" i18n (click)="close()">Close</button>
</div>
