<div class="floating-jumbotron layer-filter mr-3 ml-0 mb-3">
  <label class="layer-filter-toggle" (click)="toggleLayer({ cityDistricts: !displayArcGisSettings.cityDistricts })"
    [class.active]="displayArcGisSettings.cityDistricts"
    tooltip="Show city districts"
         data-filter-name="city-district"
    i18n-tooltip>
    <i class="icon-s icon-city-disctrict"></i>
  </label>
  <label class="layer-filter-toggle" (click)="toggleLayer({ residentialAreas: !displayArcGisSettings.residentialAreas })"
    [class.active]="displayArcGisSettings.residentialAreas"
    tooltip="Show residential areas"
         data-filter-name="residential-area"
    i18n-tooltip>
    <i class="icon-s icon-city-disctrict"></i>
  </label>
  <label class="layer-filter-toggle" (click)="displayItemsChanged({ weatherStations: !displaySettings.weatherStations })"
    [class.active]="displaySettings.weatherStations"
    tooltip="Show weather stations"
         data-filter-name="weather-station"
    i18n-tooltip>
    <i class="icon-s icon-weather-station"></i>
  </label>
  <label class="layer-filter-toggle"
    (click)="
      displayItemsChanged({
        roadConditionPoints: !displaySettings.roadConditionPoints
      })
    "
    [class.active]="displaySettings.roadConditionPoints"
    tooltip="Show road conditions"
         data-filter-name="road-condition"
    i18n-tooltip
  >
    <i class="icon-s icon-road-condition"></i>
  </label>
  <label class="layer-filter-toggle"
    (click)="
      displayItemsChanged({
        roadWeatherPoints: !displaySettings.roadWeatherPoints
      })
    "
    [class.active]="displaySettings.roadWeatherPoints"
    tooltip="Show road weathers"
         data-filter-name="road-weather"
    i18n-tooltip
  >
    <i class="icon-s icon-road-weather"></i>
  </label>
</div>
