import { OlMapWrapper, OlOverlayWrapper } from "@smartencity/core";
import {MeteorologyService} from '../service/meteorology.service';
import {RoadWeatherSite} from '../model/road-weather-site';

export class RoadConditionOlMarkerWrapper extends OlOverlayWrapper {
  public selected = false;
  private roadCondition: RoadWeatherSite;
  public div: any;
  private meteorologyService: MeteorologyService;

  constructor(roadCondition: RoadWeatherSite, map: OlMapWrapper, meteorologyService: MeteorologyService) {
    super({
      title: roadCondition.name,
      position: [roadCondition.lng, roadCondition.lat],
      className: "weather-station-marker-wrapper",
      stopEvent: false,
    });
    this.roadCondition = roadCondition;
    this.meteorologyService = meteorologyService;
    this.setMap(map.getMap());
  }

  public draw() {
    if (!this.div) {
      this.div = document.createElement("DIV");
      this.div.title = this.roadCondition.name;
      this.div.style.cursor = "pointer";
      this.div.classList.add("pointer-road-condition");

      this.div.addEventListener('click', () => {
        this.meteorologyService.selectRoadCondition(this.roadCondition);
      });
    }

    if (this.selected) {
      this.div.classList.add("active");
    } else {
      this.div.classList.remove("active");
    }
  }

}
