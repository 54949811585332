import {Inject, Injectable, LOCALE_ID} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {SMARTENCITY_CORE_CONFIG} from '../injection-tokens';
import {CoreConfig} from '../core-config.model';
import {HttpClient} from '@angular/common/http';
import {PersonSeriesPeriod, SourceOwnerSeries} from '../models/series';

@Injectable({
  providedIn: 'root'
})
export class SeriesService {

  constructor(
    @Inject(SMARTENCITY_CORE_CONFIG) private config: CoreConfig,
    private http: HttpClient,
  ) { }

  public cancelOwnerShip(series: SourceOwnerSeries): Observable<void> {

    return this.http.delete<void>(this.config.apiUrl + '/series/' + series.id + '/data-owner');
  }

  public getPeriods(series: SourceOwnerSeries): Observable<PersonSeriesPeriod[]> {

    return this.http.get<PersonSeriesPeriod[]>(this.config.apiUrl + '/series/' + series.id + '/data-owner');
  }


}
