import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import {AfterLoginRedirectStateService, AuthService, PreferencesService, UserService} from '@smartencity/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { NavigationEnd, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { from } from 'rxjs';
import { NavbarService } from './navbar.service';
import { MyDataConfig } from '../../mydata-config.model';
import { BsModalService } from 'ngx-bootstrap/modal';
import { FeedbackModalComponent } from '../feedback-modal/feedback-modal.component';
import { SMARTENCITY_MYDATA_CONFIG } from '../../injection-tokens';
import { SMARTENCITY_CORE_CONFIG } from '@smartencity/core';
import { CoreConfig } from '@smartencity/core';

const selector = 'mydata-navbar';
let nextId = 0;

@Component({
  selector: selector,
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class NavbarComponent implements OnInit {
  id = `${selector}-${nextId++}`;

  public logoImgSrc = 'assets/img/city_portal_logo_h.svg';

  public menuComponent$ = this.navbarService.menuTemplate$;
  public actionsComponent$ = this.navbarService.actionsTemplate$;
  public downloadDashboardData$ = this.navbarService.downloadDashboardData$;
  public breadcrumbs$ = this.navbarService.breadcrumbs$;
  public incomingWidgetTemplates$ = this.navbarService.incomingWidgetTemplates$;
  public preferences$ = this.preferencesService.preferences$;
  public feedbackEnabled: boolean;
  navbarExpand = false;

  constructor(
    @Inject(SMARTENCITY_MYDATA_CONFIG) private config: MyDataConfig,
    @Inject(SMARTENCITY_CORE_CONFIG) private coreConfig: CoreConfig,
    public authService: AuthService,
    public userService: UserService,
    private permissionsService: NgxPermissionsService,
    private preferencesService: PreferencesService,
    private router: Router,
    private toastr: ToastrService,
    private navbarService: NavbarService,
    private modalService: BsModalService,
    private afterLoginRedirectStateService: AfterLoginRedirectStateService
  ) {
    this.feedbackEnabled = config.feedbackEnabled;
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.closeNavbar();
      }
    });
    if (coreConfig.theme === 'tartu') {
      this.logoImgSrc = 'assets/img/tartu/tartu_logo.svg';
    } if (coreConfig.theme === 'greengas') {
      this.logoImgSrc = 'assets/img/gaas/eesti_gaas_logo.svg';
    } else if (coreConfig.theme === 'tallinn') {
      this.logoImgSrc = 'assets/img/tallinn/tallinn_logo.svg';
    }
  }

  ngOnInit(): void {
  }

  onClick(): void {

  }

  selectPerson(person) {
    from(this.authService.selectRole(person)).subscribe(value => {
      this.afterLoginRedirectStateService.doAfterLoginRedirect = true;
      this.router.navigate(['/mydata']);
    }, (err: any) => {
      this.toastr.error(err, 'Error on role update!');
    });
  }

  login() {
    this.authService.authenticate({ refresh: true });
  }

  logout() {
    this.authService.logout();
  }

  openFeedbackModal() {
    this.modalService.show(FeedbackModalComponent, {
      ignoreBackdropClick: true
    });
  }

  closeNavbar(): void {
    if (this.navbarExpand) {
      (<any>$('#' + this.id + '-mobile')).collapse('hide');
      this.navbarExpand = !this.navbarExpand;
    }
  }
}
