import {Component, HostListener, Inject, LOCALE_ID, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {CoreConfig, SMARTENCITY_CORE_CONFIG} from '@smartencity/core';

@Component({
  selector: 'tallinn-bottom-nav',
  templateUrl: './tallinn-bottom-nav.component.html',
  styleUrls: ['./tallinn-bottom-nav.component.scss']
})
export class TallinnBottomNavComponent implements OnInit {

  i18n: {name: string; baseUrl: string}[] = this.config.i18n;

  showModal = false;

  private resizeTimeout = null;

  constructor(
    public router: Router,
    @Inject(LOCALE_ID) public locale: string,
    @Inject(SMARTENCITY_CORE_CONFIG) public config: CoreConfig) { }


  ngOnInit(): void {
    console.log(this.locale);
    console.log(this.config.i18n);
  }

  modalHide(): void {
    this.showModal = false;
  }

  toggleShowModal() {
    this.showModal = !this.showModal;
  }

  @HostListener('window:resize', [])
  private onResize() {
    // In order to stop transition while resizing window, i.e assigning "top" value
    let els = Array.from(document.getElementsByClassName("vision-overlay") );
    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);
      this.resizeTimeout = null;
    }

    for (let el of els) {
      el.classList.add('disable-transition');
    }
    this.resizeTimeout = setTimeout(() => {
      for (let el of els) {
        el.classList.remove('disable-transition');
      }
    }, 100);
  }

}
