import {Observable} from 'rxjs';
import {SourceUpdateError} from '../models/source-update-error';

export abstract class SourceUpdateErrorService {

  abstract loadErrors(sourceId: number): Observable<SourceUpdateError[]>;

  abstract resetErrors(sourceId: number): Observable<void>;

}
