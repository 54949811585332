import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mydata-formula-mapping-form',
  templateUrl: './formula-mapping-form.component.html',
  styleUrls: ['./formula-mapping-form.component.css']
})
export class FormulaMappingFormComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
