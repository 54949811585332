import {Component, OnInit, OnDestroy, ViewChild, NgZone, AfterViewInit, ChangeDetectorRef, Inject} from '@angular/core';
import {TallinnService} from '../service/tallinn.service';
import {TallinnGmapService} from '../service/tallinn-gmap.service';
import {DisplaySettings} from '../model/display-settings';;
import {NoiseLevelHeatmapComponent} from './noise-level-heatmap/noise-level-heatmap.component';
import {RoadClosure} from '../../../../mobility/src/lib/model/road-closure';
import {CoreConfig, SMARTENCITY_CORE_CONFIG} from '@smartencity/core';

@Component({
  selector: 'gmap-tallinn',
  templateUrl: './gmap-tallinn.component.html'
})
export class GmapTallinnComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('gmap', {static: false})
  gmapElement: any;

  @ViewChild('noiseLevelHeatmapComponent', {static: false})
  public noiseLevelHeatmapComponent: NoiseLevelHeatmapComponent;

  public playbackTemplate = null;

  map: google.maps.Map;

  public airQualityStation;

  public markers = [];

  public displaySettings = new DisplaySettings();

  roadClosure: RoadClosure;

  constructor(
    public tallinnService: TallinnService,
    public tallinnMapService: TallinnGmapService,
    private cd: ChangeDetectorRef,
    public zone: NgZone,
    @Inject(SMARTENCITY_CORE_CONFIG) private config: CoreConfig
  ) {
  }

  ngOnInit() {
    this.cd.detectChanges();
    this.map = this.tallinnMapService.createMap(this.gmapElement);

    this.tallinnService.airQualityStationSelected$.subscribe((station) => {
      this.airQualityStation = station;
    });
  }

  ngAfterViewInit(): void {

  }

  ngOnDestroy(): void {

  }

  onModalClose(): void {
    this.tallinnService.airQualityStationSelectedSource.next(null);
  }

  displayItemsChanged(items: any) {
    this.tallinnService.displaySettingsSource.next(Object.assign(this.displaySettings, items));
  }

}
