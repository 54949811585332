import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subject} from 'rxjs/internal/Subject';
import {DashboardService} from '../dashboard/dashboard.service';
import {takeUntil} from 'rxjs/operators';
import {DashboardComponent} from '../dashboard/dashboard.component';
import {DashboardFilter, DashboardType} from "../dashboard/dashboard";
import {AuthService} from '@smartencity/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AfterLoginRedirectStateService} from '../../../../../core/src/lib/services/after-login-redirect-state.service';

@Component({
  selector: 'mydata-energy-manager',
  templateUrl: './energy-manager.component.html',
  providers: [DashboardService]
})
export class EnergyManagerComponent implements OnInit, OnDestroy {
  private ngDestroy = new Subject<void>();

  /*@ViewChild('advices', {static: true})
  public advicesTemplate: TemplateRef<any>;*/
  public advicesVisible = false;

  /*@ViewChild('incomingQuestionnaires', {static: true})
  public incomingQuestionnairesTemplate: TemplateRef<any>;*/
  public incomingQuestionnairesVisible = false;

  private isDashboardRedirect = false;

  @ViewChild('dashboard', {static: true})
  public dashboardComponent: DashboardComponent;

  public constructor(
    private dashboardService: DashboardService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private afterLoginRedirectStateService: AfterLoginRedirectStateService
  ) {

    // console.log("IS LOGIN ? " + this.authService.doAfterLoginRedirect);
    this.dashboardService.dashboardFilter = new DashboardFilter(DashboardType.ENERGY_MANAGER);
    this.dashboardService.initialize();

    this.dashboardService.dashboard$.pipe(takeUntil(this.ngDestroy)).subscribe((items) => {
      for (const item of items) {
        if (item.itemType === 'ENERGY_MANAGER' && item.widget.component === 'advices') {
          this.showAdvices();
        }
        if (item.itemType === 'ENERGY_MANAGER' && item.widget.component === 'incoming-questionnaires') {
          this.showIncomingQuestionnaires();
        }
      }

      if (this.dashboardService.dashboardFilter.dashboardType == DashboardType.ENERGY_MANAGER && !items.length && this.afterLoginRedirectStateService.doAfterLoginRedirect) {
        this.isDashboardRedirect = true;
        this.router.navigate(['mydata', 'dashboard'], {queryParams: {dashboard_type: 'default'}});
        return;
      }

      this.afterLoginRedirectStateService.doAfterLoginRedirect = false;
    });

    this.dashboardService.componentItems$.pipe(takeUntil(this.ngDestroy)).subscribe((componentItems: Map<string, any>) => {
      this.advicesVisible = componentItems.has('advices');
      this.incomingQuestionnairesVisible = componentItems.has('incoming-questionnaires');
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.afterLoginRedirectStateService.doAfterLoginRedirect = this.isDashboardRedirect;
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  showAdvices(): void {
    this.advicesVisible = true;
    this.dashboardComponent.showAdvices();
    // this.dashboardService.setComponentWidgetTemplate('advices', this.advicesTemplate);
  }

  hideAdvices(): void {
    this.advicesVisible = false;
    this.dashboardComponent.hideAdvices();
    // this.dashboardService.removeComponentWidget('advices');
  }

  showIncomingQuestionnaires(): void {
    // this.incomingQuestionnairesVisible = true;
    this.dashboardComponent.showIncomingQuestionnaires();
    // this.dashboardService.setComponentWidgetTemplate('incoming-questionnaires', this.incomingQuestionnairesTemplate);
  }

  hideIncomingQuestionnaires(): void {
    // this.incomingQuestionnairesVisible = false;
    this.dashboardComponent.hideIncomingQuestionnaires();
    // this.dashboardService.removeComponentWidget('incoming-questionnaires');
  }
}
