import { Injectable } from '@angular/core';
import { RoadWeatherSiteConstants, RoadWeatherSiteIndicator } from '../model/road-weather-site-constants';
import { RoadWeatherDataService } from './road-weather-data.service';

@Injectable()
export class RoadConditionDataService extends RoadWeatherDataService {

    constructor() { 
        super();
    }
    
    getIndicators(): RoadWeatherSiteIndicator[] {
        return RoadWeatherSiteConstants.roadConditionIndicators;
    }

}
