import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {NavbarService} from '../navbar/navbar.service';
import {ComponentsConfigService} from '../../services/components-config.service';

@Component({
  selector: 'mydata-datapoints',
  templateUrl: './datapoint-list-root.component.html',
  styleUrls: ['./datapoint-list-root.component.css']
})
export class DatapointListRoot implements OnInit, OnDestroy {
  @ViewChild('navMenu', {static: true})
  public navTempalte: TemplateRef<any>;

  constructor(private navbarService: NavbarService, public componentsConfig: ComponentsConfigService) { }

  ngOnDestroy(): void {
    this.navbarService.setMenuTemplate(null);
  }

  ngOnInit(): void {
    this.navbarService.setMenuTemplate(this.navTempalte);
  }
}
