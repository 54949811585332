import { AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { TallinnArcGisMapService } from 'projects/arc-gis/src/lib/tallinn-arc-gis-map.service';
import { Subject } from 'rxjs/internal/Subject';
import { MeteorologyService } from '../../service/meteorology.service';
import { takeUntil } from 'rxjs/operators';
import { MeteorologyOlMapService } from '../../service/meteorology-ol-map.service';
import { WeatherStation } from '../../model/weather-station';
import PerfectScrollbar from 'perfect-scrollbar';
import {RoadWeatherSite} from '../../model/road-weather-site';

@Component({
  selector: 'meteorology-objects-modal',
  templateUrl: './meteorology-objects-modal.component.html',
})
export class MeteorologyObjectsModalComponent implements OnInit, AfterViewInit {

  private ngDestroy = new Subject<void>();

  @ViewChild('modalContent') modalContent: ElementRef;

  public objects: any[] = [];

  weatherStation: WeatherStation;
  roadCondition: RoadWeatherSite;
  roadWeather: RoadWeatherSite;

  public isWide = false;

  @Output('modalClose')
  modalClose: EventEmitter<void> = new EventEmitter<void>();

  @Output('arcGISLocationsReset')
  arcGISLocationsReset: EventEmitter<void> = new EventEmitter<void>();

  public currentIndex: number = 0;

  constructor(
    private tallinnArcGisMapService: TallinnArcGisMapService,
    private meteorologyMapService: MeteorologyOlMapService,
    public meteorologyService: MeteorologyService) {
  }

  ngOnInit() {
    this.meteorologyService.weatherStationSelected$.subscribe((station: WeatherStation) => {
      this.weatherStation = station;
      this.clearObjects();
      if (this.weatherStation !== null) {
        this.objects.push({ type: 'weatherStation', data: this.weatherStation });
        this.resetArcGISLocations();
      }
    });

    this.meteorologyService.locationsSelected$.pipe(takeUntil(this.ngDestroy)).subscribe((locations: any[]) => {
      let layerTypes = Array.from(this.tallinnArcGisMapService.layersMap.keys());

      layerTypes.forEach((layerType) => {
        let idx = this.objects.findIndex((o) => o.type == layerType);
        if (idx > -1) {
          this.objects.splice(idx, 1);
        }
      });

      if (locations) {
        for (let location of locations) {
          this.objects.push({ type: location.layerName, data: location.data });
        }
      }
      this.resetIndex();
      this.isWide = true;
    });

    this.meteorologyService.roadConditionSelected$.subscribe((roadCondition: RoadWeatherSite) => {
      this.roadCondition = roadCondition;
      this.clearObjects();
      if (this.roadCondition !== null) {
        this.objects.push({ type: 'roadCondition', data: this.roadCondition });
        this.resetArcGISLocations();
      }
      this.isWide = true;
    });

    this.meteorologyService.roadWeatherSelected$.subscribe((roadWeather: RoadWeatherSite) => {
      this.roadWeather = roadWeather;
      this.clearObjects();
      if (this.roadWeather !== null) {
        this.objects.push({ type: 'roadWeather', data: this.roadWeather });
        this.resetArcGISLocations();
      }
    });
  }

  private clearObjects() {
    this.objects = [];
    this.resetIndex();
  }

  resetIndex() {
    this.currentIndex = 0;
  }

  public resetArcGISLocations(): void {
    this.arcGISLocationsReset.next();
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  close() {
    this.isWide = false;
    this.modalClose.next();
  }

  ngAfterViewInit() {
    new PerfectScrollbar(this.modalContent.nativeElement);
  }
}
