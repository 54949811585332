import {Inject, Injectable} from '@angular/core';
import {CookieOptions, CookieService} from 'ngx-cookie';
import moment from 'moment';
import {ReplaySubject} from 'rxjs';
import {CookieConstants} from './cookie-constants';
import {CookieSettings} from './cookie-settings';
import {SMARTENCITY_CORE_CONFIG} from '../../injection-tokens';

@Injectable({
  providedIn: 'root'
})
export class CookieConsentService {

  private serializeVersion: number = 1663847745;

  public cookieConsentSource = new ReplaySubject<void>();

  public cookieConsent$ = this.cookieConsentSource.asObservable()

  constructor(private cookieService: CookieService,
              @Inject(SMARTENCITY_CORE_CONFIG) private coreConfig) { }

  public isAnalyticsCookieConfirmationRequired(): boolean {

    return !this.isCookieConsentGiven();
  }

  public isCookieConsentGiven(): boolean {
    let cookieConsent: CookieSettings = <CookieSettings>this.cookieService.getObject(CookieConstants.COOKIE_CONSENT_COOKIE_NAME);
    if (cookieConsent != null && cookieConsent.version !== this.serializeVersion) {
      this.cookieService.remove(CookieConstants.COOKIE_CONSENT_COOKIE_NAME);
      cookieConsent = null;
    }

    return cookieConsent != null;
  }

  public isAnalyticsAllowed(): boolean {
    let cookieConsent: CookieSettings = <CookieSettings>this.cookieService.getObject(CookieConstants.COOKIE_CONSENT_COOKIE_NAME);

    return cookieConsent != null && cookieConsent.analytics;
  }

  public allowAnalytics(): void {

    this.cookieService.putObject(CookieConstants.COOKIE_CONSENT_COOKIE_NAME, new CookieSettings({
      analytics: true,
      version: this.serializeVersion
    }), CookieConsentService.getCookieOptions());

    this.cookieConsentSource.next();
  }

  public declineAnalytics(): void {
    this.cookieService.putObject(CookieConstants.COOKIE_CONSENT_COOKIE_NAME, new CookieSettings({
      analytics: false,
      version: this.serializeVersion
    }), CookieConsentService.getCookieOptions());

    this.cookieConsentSource.next();
  }

  private static getCookieOptions(): CookieOptions {

    return {
      secure: true,
      expires: moment().add(1, 'year').toDate()
    }
  }

}
