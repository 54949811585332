import {UriBuilder} from '../../redirect/uri-builder';
import {TaraAuthLogoutUriBuilder} from './tara-auth-logout-uri-builder';
import {AbstractRedirectStrategy} from '../../redirect/abstract-redirect-strategy';
import {CoreConfig} from '@smartencity/core';

export class TaraLogoutRedirectStrategy extends AbstractRedirectStrategy {

  constructor(private config: CoreConfig) {
    super();
    let redirectUriBuilder = new TaraAuthLogoutUriBuilder(config.apiUrl);
    this.setRedirectUriBuilder(redirectUriBuilder);
  }

  getRedirectUriBuilder(): UriBuilder {
    return this.redirectUriBuilder;
  }

}
