import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {
  ConfirmModalComponent,
  ConsentPersonConsent, MandateConsent,
  PageResponse,
  UserService
} from '@smartencity/core';
import {FormControl, FormGroup} from '@angular/forms';
import {debounceTime, distinctUntilChanged, map, shareReplay, startWith, switchMap, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs/internal/Subject';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';
import {VirtualScrollerComponent} from 'ngx-virtual-scroller';
import {PaginationComponent} from 'ngx-bootstrap/pagination';
import {IPageInfo} from 'ngx-virtual-scroller/virtual-scroller';
import {Observable} from 'rxjs/internal/Observable';
import { ConsentService, PersonSeries } from '@smartencity/core';
import {ToastrService} from 'ngx-toastr';
import {forkJoin} from 'rxjs/internal/observable/forkJoin';
import {PersonSeriesGroupApiService} from '../../../http/person-series-group-api.service';

export class ConsentItem {
  checked: FormControl;
  consent: ConsentPersonConsent;

  constructor(consent: ConsentPersonConsent, checked: boolean, cb: (item: ConsentItem, value: boolean) => void) {
    this.consent = consent;
    this.checked = new FormControl(checked);

    this.checked.valueChanges.subscribe((value) => {
      cb(this, value);
    });
  }
}

@Component({
  selector: 'app-mandate-person-consents-modal',
  templateUrl: './mandate-person-consents-modal.component.html'
})
export class MandatePersonConsentsModalComponent implements OnInit {
  public consents: any[];
  public personSeries: PersonSeries;

  private ngDestroy = new Subject<void>();

  @ViewChild('listScroll')
  private listScroll: VirtualScrollerComponent;

  @ViewChild('listPaginationComponent')
  private listPaginationComponent: PaginationComponent

  @Input()
  public consent: MandateConsent;

  public items: ConsentItem[] = [];

  private pageSize = 50;

  bufferRows = 5;

  private fetchedPages = new Set<number>();

  public filterForm: FormGroup = new FormGroup({
    query: new FormControl(null)
  });

  public filter$ = this.filterForm.valueChanges.pipe(distinctUntilChanged(), debounceTime(250), startWith({}));

  public reloadSubject: Subject<void> = new Subject<void>();
  public reload$ = this.reloadSubject.asObservable();

  public pageSubject: Subject<number> = new Subject<number>();
  public page$: Subject<any> = new Subject<any>();
  public limit$: Subject<any> = new Subject<any>();

  public queryParams$ = combineLatest([
    this.reload$.pipe(startWith(null as void)),
    this.filter$,
    this.page$.asObservable().pipe(startWith(0)),
    this.limit$.asObservable().pipe(startWith(this.pageSize))
  ]).pipe(
    map(([reload, filter, pageNumber, limit]) => this.mapQueryParams(filter, pageNumber, limit)),
    takeUntil(this.ngDestroy)
  );

  public pageResponse$ = this.queryParams$.pipe(switchMap(queryParams => {
      return this.fetchPage(queryParams);
    }),
    shareReplay(1),
    takeUntil(this.ngDestroy)
  );

  private mapQueryParams(filter: any, pageNumber: number, limit: number) {
    const params: any = {
      page: pageNumber,
      size: limit,
      query: null,
      personId: this.consent.person.id,
      countryCode: this.consent.createdByUser.countryCode,
      personalId: this.consent.createdByUser.personalId
    };

    if (filter.query) {
      params.query = filter.query;
    }

    return params;
  }

  private reloadInProgress: boolean = false;

  @Output('noResultClose')
  noResultClose: EventEmitter<void> = new EventEmitter<void>();

  pageResponse: PageResponse<ConsentPersonConsent>;

  allChecked = false;
  checkAllControlIndeterminate = false;
  checkAllControl: FormControl = new FormControl(this.allChecked);

  @ViewChild('checkAllEl', {static: true})
  checkAllEl: ElementRef<any>;

  affectedRowsMap: Map<number, ConsentPersonConsent> = new Map<number, ConsentPersonConsent>();
  cancelCount = 0;
  allCount = 0;

  constructor(private modalRef: BsModalRef,
              private userService: UserService,
              public consentService: ConsentService,
              public personSeriesGroupService: PersonSeriesGroupApiService,
              private toastr: ToastrService,
              public modalService: BsModalService) { }

  ngOnInit(): void {
    this.pageResponse$.pipe(takeUntil(this.ngDestroy)).subscribe((pageResponse: PageResponse<ConsentPersonConsent>) => {
      this.pageResponse = pageResponse;
      this.allCount = pageResponse.totalElements;
      if(this.pageResponse.content.length > 0) {
        this.reloadInProgress = false;
      }
      if (!this.items || this.items.length != pageResponse.totalElements) {
        this.items = Array.from({length:pageResponse.totalElements});
      }
      this.items.splice(pageResponse.page * pageResponse.size, pageResponse.size, ...pageResponse.content.map((cpc) =>
        new ConsentItem(cpc, this.allChecked != this.affectedRowsMap.has(cpc.entryId), this.onItemCheckChange.bind(this))));
      if (!this.fetchedPages) {
        this.fetchedPages = new Set<number>();
      }

      if (pageResponse.totalPages < this.fetchedPages.size) {
        this.fetchedPages.clear();
      }
      this.fetchedPages.add(pageResponse.page);

      if(this.reloadInProgress) {
        this.close();
        this.noResultClose.next();
      }
    });

    this.checkAllControl.valueChanges.pipe(takeUntil(this.ngDestroy)).subscribe((value) => {
      this.toggleAll(value);
    });
  }

  toggleAll(value: boolean) {
    this.allChecked = value;
    for (let item of this.items) {
      if (item && item.checked) {
        item.checked.setValue(value);
      }
    }

    this.affectedRowsMap.clear();
    this.updateCounts();
  }

  onItemCheckChange(item: ConsentItem, value: boolean): void {

    if (value && !this.allChecked || !value && this.allChecked) {
      this.addToAffectedPersonSeriesMap(item.consent);
    } else {
      this.removeFromExcludeSeriesMap(item.consent);
    }

    this.checkAllControlIndeterminate = this.affectedRowsMap.size > 0;
  }

  private removeFromExcludeSeriesMap(consent: ConsentPersonConsent): void {
    if (this.affectedRowsMap.has(consent.entryId)) {
      this.affectedRowsMap.delete(consent.entryId);
    }
    this.updateCounts();
  }

  private addToAffectedPersonSeriesMap(consent: ConsentPersonConsent): void {
    if (!this.affectedRowsMap.has(consent.entryId)) {
      this.affectedRowsMap.set(consent.entryId, consent);
    }
    this.updateCounts();
  }

  chartVsChange(event: IPageInfo) {
    if (event.startIndex < 0 || event.endIndex < 0) {
      return;
    }

    if (event.startIndex <= 1) {
      if (this.listPaginationComponent) {
        this.listPaginationComponent.selectPage(0);
      }
    }

    let startPage = Math.floor((event.startIndex / this.pageSize));
    let endPage = Math.floor((event.endIndex / this.pageSize));
    if (startPage < 0) {
      startPage = 0;
    }
    if (endPage < 0) {
      endPage = 0;
    }

    for (let i = startPage; i <= endPage; i++) {
      if (this.fetchedPages.has(i)) {
        continue;
      }

      this.page$.next(i);
    }
  }

  endConsent(item: any): void {
    this.modalService.show(ConfirmModalComponent, {
      ignoreBackdropClick: true,
      initialState: {
        description: $localize`Are you sure you want to decline the datapoint?`,
        callback: (confirm: boolean) => {
          if (!confirm) {
            return;
          }
          this.doEndConsent(item);
        }
      }
    });
  }

  private doEndConsent(item: any) {
    if (item.type == 'CONSENT') {
      return this.consentService.endConsent({
        personSeriesId: item.entryId,
        id: item.periodId
      }).subscribe(() => {
        this.toastr.success($localize`Declined`);
        this.reloadPage();
      });
    } else {
      return this.personSeriesGroupService.endConsent(item.entryId, item.periodId).subscribe(() => {
        this.toastr.success($localize`Data group declined`);
        this.reloadPage();
      });
    }
  }

  private reloadPage(): void {
    this.reloadInProgress = true;
    this.reloadSubject.next();
  }
  private fetchPage(params: any): Observable<PageResponse<ConsentPersonConsent>> {
    return this.userService.getConsentsByPerson(params);
  }

  close() {
    this.modalRef.hide();
  }

  endSelectedConsents() {

      let consents = Array.from(this.affectedRowsMap.values()).filter((item) => item.type == 'CONSENT')
          .map((item) => {
            return {
              id: item.entryId,
              periodId: item.periodId
            }
        });
      let consentGroups = Array.from(this.affectedRowsMap.values()).filter((item) => item.type == 'GROUP_CONSENT')
          .map((item) => {
            return {
              id: item.entryId,
              periodId: item.periodId
            }
        });

      if (consents.length > 0 || consentGroups.length > 0 || this.allChecked) {
        this.modalService.show(ConfirmModalComponent, {
          ignoreBackdropClick: true,
          initialState: {
            description: $localize`Are you sure you want to end selected consents?`,
            callback: (confirm: boolean) => {
              if (!confirm) {
                return;
              }

              forkJoin([
                this.consentService.endBulkDatapointConsents({
                  mandate: this.consent,
                  consents: consents,
                  isExcludeIds: this.allChecked
                }),
                this.personSeriesGroupService.endBulkDatagroupConsents({
                  mandate: this.consent,
                  groups: consentGroups,
                  isExcludeIds: this.allChecked
                })
              ]).subscribe(() => {
                this.reloadPage();
              });
            }
          }
        });
      }

  }

  private updateCounts(): void {
    if (this.affectedRowsMap.size > 0) {
      this.cancelCount = (!this.allChecked ? this.affectedRowsMap.size : this.allCount - this.affectedRowsMap.size);
    } else {
      this.cancelCount = (this.allChecked ? this.allCount : 0);
    }
  }
}
