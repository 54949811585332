import { Injectable } from '@angular/core';

declare var gtag;
declare var dataLayer;

@Injectable({
  providedIn: 'root'
})
export class GtagService {

  constructor() { }

  public init(config: { gaTrackingId: string }): void {
    const gTagManagerScript = document.createElement('script');
    gTagManagerScript.async = true;
    gTagManagerScript.src = `https://www.googletagmanager.com/gtag/js?id=${config.gaTrackingId}`;
    document.head.appendChild(gTagManagerScript);

    // esmasel konfigreerimisel ei saada page_view eventi, muidu topelt hit
    gtag('config', config.gaTrackingId, {send_page_view: false});
  }

  public sendEvent(name: string, data: any): void {
    dataLayer.push({
      event: name,
      ...data
    });
  }

}
