<i class="icon icon-people">
  <svg width="115" height="115" viewBox="-20 -27.5 115 115" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" id="el_31bf1pRi2">
    <g id="el_NDP6YifLgP" fill-rule="evenodd">
      <g id="el_57H042-v27">
        <g id="el_QRmL1Je7Se">
          <path d="M30.6298,19.3269 C28.9378,19.3269 27.5668,20.6979 27.5668,22.3899 C27.5668,24.0829 28.9378,25.4539 30.6298,25.4539 C32.3208,25.4539 33.6928,24.0829 33.6928,22.3899 C33.6928,20.6979 32.3208,19.3269 30.6298,19.3269" id="el_QHHp651P3B"/>
          <path d="M30.6328,9.8405 C27.4278,9.8375 24.2018,11.0705 21.7578,13.5165 C20.8368,14.4365 20.8368,15.9285 21.7568,16.8495 C22.6768,17.7705 24.1688,17.7705 25.0898,16.8505 C26.6258,15.3155 28.6188,14.5555 30.6328,14.5535 C32.6458,14.5555 34.6348,15.3135 36.1698,16.8485 C37.0898,17.7685 38.5818,17.7705 39.5028,16.8505 C40.4228,15.9305 40.4238,14.4395 39.5038,13.5185 C37.0618,11.0695 33.8358,9.8375 30.6328,9.8405" id="el_AEcXMpBTZW"/>
          <path d="M46.1699,6.8532 C41.8859,2.5622 36.2489,0.4102 30.6359,0.4142 C25.0169,0.4102 19.3769,2.5652 15.0919,6.8522 C14.1719,7.7722 14.1719,9.2652 15.0919,10.1852 C16.0119,11.1052 17.5039,11.1052 18.4239,10.1852 C21.8029,6.8082 26.2089,5.1282 30.6359,5.1252 C35.0589,5.1282 39.4599,6.8062 42.8369,10.1842 C43.7559,11.1052 45.2479,11.1052 46.1689,10.1862 C47.0889,9.2662 47.0899,7.7742 46.1699,6.8532" id="el_vFtYkzpV4s"/>
          <g id="el_4Xd7m9UqQJ">
            <path d="M24.5673,27.1237 C24.4463,26.6357 24.3833,26.1307 24.3833,25.6177 C24.3833,22.1667 27.1853,19.3587 30.6293,19.3587 C30.7773,19.3587 30.9213,19.3707 31.0683,19.3807 C29.6723,17.7527 27.3113,16.2837 23.2893,15.4057 C25.3803,13.9527 26.7543,11.5377 26.7543,8.7997 C26.7543,4.3587 23.1523,0.7577 18.7123,0.7577 C14.2703,0.7577 10.6703,4.3587 10.6703,8.7997 C10.6703,11.5377 12.0433,13.9527 14.1333,15.4057 C10.1113,16.2837 7.7503,17.7527 6.3533,19.3807 C6.4993,19.3707 6.6423,19.3587 6.7903,19.3587 C10.2353,19.3587 13.0383,22.1667 13.0383,25.6177 C13.0383,26.1307 12.9743,26.6367 12.8533,27.1237 C13.2463,27.3907 13.5923,27.6897 13.9063,28.0157 C15.2893,28.0607 16.8703,28.0887 18.7123,28.0887 C20.5523,28.0887 22.1313,28.0607 23.5133,28.0157 C23.8273,27.6897 24.1743,27.3907 24.5673,27.1237" id="el_hYeG5c6bs8"/>
            <path d="M6.79,22.1706 C8.688,22.1706 10.227,23.7156 10.227,25.6166 C10.227,26.7946 9.641,27.8296 8.747,28.4536 C12.667,29.3146 12.897,31.4946 12.952,32.7906 C12.964,33.0676 12.761,33.3996 12.493,33.4696 C11.816,33.6476 10.455,33.8946 6.79,33.8946 C3.127,33.8946 1.766,33.6476 1.089,33.4696 C0.822,33.3996 0.619,33.0676 0.631,32.7906 C0.687,31.4946 0.916,29.3146 4.835,28.4536 C3.942,27.8296 3.356,26.7946 3.356,25.6166 C3.356,23.7156 4.893,22.1706 6.79,22.1706" id="el_uwaCPqcbAX"/>
            <path d="M30.6289,22.1706 C32.5259,22.1706 34.0669,23.7156 34.0669,25.6166 C34.0669,26.7946 33.4799,27.8296 32.5859,28.4536 C36.5059,29.3146 36.7359,31.4946 36.7899,32.7906 C36.8039,33.0676 36.5999,33.3996 36.3319,33.4696 C35.6549,33.6476 34.2949,33.8946 30.6289,33.8946 C26.9659,33.8946 25.6049,33.6476 24.9269,33.4696 C24.6599,33.3996 24.4579,33.0676 24.4699,32.7906 C24.5249,31.4946 24.7559,29.3146 28.6739,28.4536 C27.7809,27.8296 27.1939,26.7946 27.1939,25.6166 C27.1939,23.7156 28.7329,22.1706 30.6289,22.1706" id="el_ftNgT_UZK-"/>
            <path d="M48.4072,27.1237 C48.2862,26.6357 48.2222,26.1307 48.2222,25.6177 C48.2222,22.1667 51.0242,19.3587 54.4692,19.3587 C54.6172,19.3587 54.7612,19.3707 54.9082,19.3807 C53.5112,17.7527 51.1502,16.2837 47.1282,15.4057 C49.2192,13.9527 50.5932,11.5377 50.5932,8.7997 C50.5932,4.3587 46.9912,0.7577 42.5512,0.7577 C38.1092,0.7577 34.5102,4.3587 34.5102,8.7997 C34.5102,11.5377 35.8832,13.9527 37.9732,15.4057 C33.9502,16.2837 31.5902,17.7527 30.1932,19.3807 C30.3392,19.3707 30.4812,19.3587 30.6302,19.3587 C34.0742,19.3587 36.8772,22.1667 36.8772,25.6177 C36.8772,26.1307 36.8132,26.6367 36.6922,27.1237 C37.0862,27.3907 37.4322,27.6897 37.7452,28.0157 C39.1292,28.0607 40.7092,28.0887 42.5512,28.0887 C44.3912,28.0887 45.9702,28.0607 47.3532,28.0157 C47.6662,27.6897 48.0132,27.3907 48.4072,27.1237" id="el_xuiWMlNf8L"/>
            <path d="M54.4687,22.1706 C56.3657,22.1706 57.9057,23.7156 57.9057,25.6166 C57.9057,26.7946 57.3197,27.8296 56.4247,28.4536 C60.3447,29.3146 60.5747,31.4946 60.6297,32.7906 C60.6427,33.0676 60.4387,33.3996 60.1707,33.4696 C59.4937,33.6476 58.1347,33.8946 54.4687,33.8946 C50.8047,33.8946 49.4437,33.6476 48.7657,33.4696 C48.4987,33.3996 48.2967,33.0676 48.3087,32.7906 C48.3647,31.4946 48.5947,29.3146 52.5127,28.4536 C51.6197,27.8296 51.0327,26.7946 51.0327,25.6166 C51.0327,23.7156 52.5727,22.1706 54.4687,22.1706" id="el_630y8ujcoS"/>
          </g>
        </g>
      </g>
    </g>
  </svg>
</i>
