import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormGroup} from '@angular/forms';
import 'chartjs-plugin-colorschemes';
import {Subject} from 'rxjs/internal/Subject';
import {Widget} from '../../widget';

@Component({
  selector: 'mydata-widget-preview',
  templateUrl: './widget-preview.component.html'
})
export class WidgetPreviewComponent implements OnInit, OnDestroy {
  private ngDestroy = new Subject<void>();

  @Input()
  public widget: Widget;

  @Input()
  public form: FormGroup;

  @ViewChild('chartCanvas')
  public chartCanvas: ElementRef;

  public chart: any;

  constructor() {
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }
}
