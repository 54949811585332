import {ActivatedRouteSnapshot, CanActivate, CanLoad, Route, RouterStateSnapshot, UrlSegment, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {ComponentsConfigService} from '../services/components-config.service';

@Injectable()
export class ComponentsGuard implements CanActivate {

  constructor(private componentsConfig: ComponentsConfigService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

    return this.componentsConfig.canLoad(route.data.componentsPath);
  }

}
