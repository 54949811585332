<h5>{{roadCondition.name}}</h5>

<table class="table table-hover road-weather-site-table">
  <thead>
    <tr>
      <td>&nbsp;</td>
      <ng-container *ngFor="let indicator of roadConditionIndicators; let i = index;">
        <td class="text-center">{{indicator.label}}</td>
      </ng-container>
    </tr>
  </thead>
  <tbody>
    <tr class="w-100" *ngFor="let row of data.rows">
      <ng-container *ngFor="let field of row.fields; let i = index;">
        <td class="text-center">
          <span *ngIf="field.value != null && field.code == 'road-condition'">
            <span *ngIf="roadConditionCodeMap[field.value] != null">
                 <span [ngClass]="roadConditionCodeMap[field.value].color" class="condition-dot mr-2"></span> {{roadConditionCodeMap[field.value].label}}
            </span>
            <span *ngIf="roadConditionCodeMap[field.value] == null">-</span>
          </span>
          <span *ngIf="field.value != null && field.code != 'road-condition'">{{field.value}} {{field.unit}}</span>
          <span *ngIf="field.value == null">-</span>
        </td>
      </ng-container>
    </tr>
  </tbody>
  <tfoot>
    <tr>
      <td colspan=3 class="text-left"><span *ngIf="updatedAt"><span i18n>Updated at</span>: {{this.updatedAt|date:'dd.MM.yyyy HH:mm'}}</span></td>
      <td colspan=2 class="text-right"><span i18n>Data from: </span> <a href="https://tik.teeilm.ee/" target="_BLANK"title="TIK">https://tik.teeilm.ee/</a></td>
    </tr>
    <tr>
      <td colspan=5>
        <div class="legend d-flex">
          <div class="legend-item mr-2"><span class="condition-dot green"></span> <span i18n>Good</span></div>
          <div class="legend-item mr-2"><span class="condition-dot yellow"></span> <span i18n>Medium</span></div>
          <div class="legend-item"><span class="condition-dot red"></span> <span i18n>Bad</span></div>
        </div>
      </td>
    </tr>
  </tfoot>
</table>


