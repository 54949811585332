import {Component, ElementRef, Input, OnInit} from '@angular/core';
import { UrlHelper } from 'projects/core/src/public-api';

@Component({
  selector: '[arc-gis-feature-fields]',
  templateUrl: './arc-gis-feature-fields.component.html'
})
export class ArcGisFeatureFieldsComponent implements OnInit {

  constructor(private el: ElementRef) { }

  @Input()
  data: any;  
  
  @Input()
  layerTitle: string;

  ngOnInit(): void {}

  isUrl(value): boolean {
    return UrlHelper.isUrl(value);
  }

  close(): void {
    this.data = null;
  }
}
