import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'icon-mydata',
  templateUrl: './icon-mydata.component.html',
  styles: [
    `
      :host {
        display: flex;
        min-width: 100%;
      }
  `
  ]
})
export class IconMydataComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
