<div [hidden]="!(fieldArray.dirty || fieldArray.touched) || fieldArray.valid || !showErrors" class="alert alert-danger alert-dismissible fade show mt-2" role="alert">
  <div i18n>Form contains errors</div>
  <button type="button" class="close" aria-label="Close" (click)="dismissErrors()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="box" *ngFor="let field of fields;let i = index" [formGroup]="field.formGroup" [ngSwitch]="field.type">
  <ng-container *ngSwitchCase="'TEXT'">
    <label class="question" [attr.for]="id + '-' + i + '-text'"><ng-container *ngIf="field.symbol">{{field.symbol}}:&nbsp;</ng-container>{{field.name}}<ng-container *ngIf="field.unit">&nbsp;({{field.unit}})</ng-container><span *ngIf="field.optional" class="text-light">&nbsp;(<ng-container i18n>Optional</ng-container>)</span></label>
    <p class="small-text" *ngIf="field?.description" [innerHTML]="field?.description | htmlEscape | linky"></p>
    <input type="text" class="form-control" [class.is-invalid]="invalid('text', field.formGroup)" [id]="id + '-' + i + '-text'" formControlName="text" placeholder="Your answer" i18n-placeholder>
    <small *ngIf="invalid('text', field.formGroup) && errors('text', field.formGroup)?.required" class="invalid-feedback d-block" i18n>Required</small>
  </ng-container>
  <ng-container *ngSwitchCase="'VALUE'">
    <label class="question" [attr.for]="id + '-' + i + '-value'"><ng-container *ngIf="field.symbol">{{field.symbol}}:&nbsp;</ng-container>{{field.name}}<ng-container *ngIf="field.unit">&nbsp;({{field.unit}})</ng-container><span *ngIf="field.optional" class="text-light">&nbsp;(<ng-container i18n>Optional</ng-container>)</span></label>
    <p class="small-text" *ngIf="field?.description" [innerHTML]="field?.description | htmlEscape | linky"></p>
    <input type="text" class="form-control" [class.is-invalid]="invalid('value', field.formGroup)" [id]="id + '-' + i + '-value'" formControlName="value" placeholder="Numeric value" i18n-placeholder>
    <small *ngIf="invalid('value', field.formGroup) && errors('value', field.formGroup)?.required" class="invalid-feedback d-block" i18n>Required</small>
  </ng-container>
  <ng-container *ngSwitchCase="'SELECT'">
    <label class="question"><ng-container *ngIf="field.symbol">{{field.symbol}}:&nbsp;</ng-container>{{field.name}}<ng-container *ngIf="field.unit">&nbsp;({{field.unit}})</ng-container><span *ngIf="field.optional" class="text-light">&nbsp;(<ng-container i18n>Optional</ng-container>)</span></label>
    <p class="small-text" *ngIf="field?.description" [innerHTML]="field?.description | htmlEscape | linky"></p>
    <div class="custom-control custom-radio mb-1" *ngFor="let option of field.options$ | async; let j = index">
      <input class="custom-control-input" [class.is-invalid]="invalid('option', field.formGroup)" type="radio" formControlName="option" [id]="id + '-' + i + '-option-' + j" [value]="option.value">
      <label class="custom-control-label" [attr.for]="id + '-' + i + '-option-' + j">{{option.label}}</label>
    </div>
    <small *ngIf="invalid('option', field.formGroup) && errors('option', field.formGroup)?.required" class="invalid-feedback d-block" i18n>Required</small>
  </ng-container>
  <ng-container *ngSwitchCase="'PERSON_PARAMETER'">
    <label class="question" [attr.for]="id + '-' + i + '-person-parameter'"><ng-container *ngIf="field.symbol">{{field.symbol}}:&nbsp;</ng-container>{{field.name}}<ng-container *ngIf="field.unit">&nbsp;({{field.unit}})</ng-container><span *ngIf="field.optional" class="text-light">&nbsp;(<ng-container i18n>Optional</ng-container>)</span></label>
    <p class="small-text" *ngIf="field?.description" [innerHTML]="field?.description | htmlEscape | linky"></p>
    <ng-select
      [class.is-invalid]="invalid('personParameter', field.formGroup)"
      [id]="id + '-' + i + '-person-parameter'"
      [typeahead]="field.typeahead$"
      [items]="field.options$ | async"
      bindLabel="name"
      [clearable]="true"
      [searchable]="true"
      placeholder="Select parameter"
      i18n-placeholder
      appendTo="body"
      formControlName="personParameter"
    >
      <ng-template ng-label-tmp let-item="item">
        <span>{{ item.name + (item.locationRoot != '-' ? (' - ' + item.locationRoot) : '') }}</span>
      </ng-template>
      <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
        <span >{{ item.name + (item.locationRoot != '-' ? (' - ' + item.locationRoot) : '') }}</span>
      </ng-template>
    </ng-select>
    <small *ngIf="invalid('personParameter', field.formGroup) && errors('personParameter', field.formGroup)?.required" class="invalid-feedback d-block" i18n>Required</small>
    <div class="variable view p-0 col-md-8 mx-auto" *ngIf="field.formGroup.get('personParameter').value && field.formGroup.get('unit').value != field.requestedUnit">
      <div class="header flex-wrap flex-md-nowrap justify-content-end justify-content-md-unset">
        <div class="col-auto d-flex">
          <div class="d-flex align-items-center">
            <i class="fal fa-info-circle mr-2 tooltip-icon tooltip-lg" i18n-popover popover="Selected parameter's unit differs from the requested unit, it should be converted." placement="top" triggers="mouseenter:mouseleave" ></i>
          </div>
          <div class="d-flex flex-column">
            <p class="m-0">{{field.formGroup.get('personParameter').value.name}}</p>
            <span class="small-text help-text">{{(field.location$ | async)?.getName()}}</span>
          </div>
        </div>
        <div class="col-auto ml-auto">
          <label class="m-0 mr-2" i18n>Parameter unit</label>
          <span>{{'' + field.formGroup.get('unit').value}}</span>
        </div>
        <div class="col-auto">
          <label class="m-0 mr-2" i18n>Requested unit</label>
          <span>{{'' + field.requestedUnit}}</span>
        </div>
      </div>
      <div class="content text-center">
        <div class="form-group mb-4">
          <label class="d-block mb-2" i18n>Unit conversion</label>
          <div class="row justify-content-center">
            <div class="col-auto d-flex align-items-center">
              <label class="m-0 mr-2" i18n>Set coefficient</label>
              <input type="text" class="form-control w-100p text-right" placeholder="Coefficient" i18n-placeholder formControlName="coefficient">
              <small *ngIf="invalid('coefficient', field.formGroup) && errors('value', field.formGroup)?.required" class="invalid-feedback d-block" i18n>Required</small>
              <small *ngIf="invalid('coefficient', field.formGroup) && errors('value', field.formGroup)?.pattern" class="invalid-feedback d-block" i18n>Invalid</small>
            </div>
            <div class="col-auto d-flex align-items-center">
              <span class="text-light">1 {{field.formGroup.get('unit').value}} * Coefficient = 1 {{field.requestedUnit}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'PERSON_SERIES'">
    <label class="question" [attr.for]="id + '-' + i + '-person-parameter'"><ng-container *ngIf="field.symbol">{{field.symbol}}:&nbsp;</ng-container>{{field.name}}<ng-container *ngIf="field.unit">&nbsp;({{field.unit}})</ng-container><span *ngIf="field.optional" class="text-light">&nbsp;(<ng-container i18n>Optional</ng-container>)</span></label>
    <p class="small-text" *ngIf="field?.description" [innerHTML]="field?.description | htmlEscape | linky"></p>
    <ng-select
      [class.is-invalid]="invalid('personSeries', field.formGroup)"
      [id]="id + '-' + i + '-person-series'"
      [typeahead]="field.typeahead$"
      [items]="field.options$ | async"
      bindLabel="name"
      [clearable]="true"
      [searchable]="true"
      placeholder="Select datapoint"
      i18n-placeholder
      appendTo="body"
      formControlName="personSeries"
    >
      <ng-template ng-label-tmp let-item="item">
        <span>{{ item.name + (item.locationRoot != '-' ? (' - ' + item.locationRoot) : '') }}</span>
      </ng-template>
      <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
        <span >{{ item.name + (item.locationRoot != '-' ? (' - ' + item.locationRoot) : '') }}</span>
      </ng-template>
    </ng-select>
    <small *ngIf="invalid('personSeries', field.formGroup) && errors('personSeries', field.formGroup)?.required" class="invalid-feedback d-block" i18n>Required</small>

    <div class="variable view p-0 col-md-8 mx-auto" *ngIf="field.formGroup.get('personSeries').value && field.formGroup.get('unit').value != field.requestedUnit">
      <div class="header flex-wrap flex-md-nowrap justify-content-end justify-content-md-unset">
        <div class="col-auto d-flex">
          <div class="d-flex align-items-center">
            <i class="fal fa-info-circle mr-2 tooltip-icon tooltip-lg" popover="Data point contains different unit, it should be converted." placement="top" triggers="mouseenter:mouseleave" ></i>
          </div>
          <div class="d-flex flex-column">
            <p class="m-0">{{field.formGroup.get('personSeries').value.name}}</p>
            <span class="small-text help-text">{{(field.location$ | async)?.getName()}}</span>
          </div>
        </div>
        <div class="col-auto ml-auto">
          <label class="m-0 mr-2" i18n>Datapoint unit</label>
          <span>{{'' + field.formGroup.get('unit').value}}</span>
        </div>
        <div class="col-auto">
          <label class="m-0 mr-2" i18n>Requested unit</label>
          <span>{{'' + field.requestedUnit}}</span>
        </div>
      </div>
      <div class="content text-center">
        <div class="form-group mb-4">
          <label class="d-block mb-2" i18n>Specify the datapoint value type</label>
          <div class="custom-control-inline custom-control custom-radio">
            <input class="custom-control-input" type="radio" formControlName="deltaReading" [value]="false" [id]="id + '-' + i + '-delta-false'">
            <label class="custom-control-label" [attr.for]="id + '-' + i + '-delta-false'"><ng-container i18n>Cumulative measurement</ng-container>/<ng-container i18n>Reading</ng-container></label>
          </div>
          <div class="custom-control-inline custom-control custom-radio">
            <input class="custom-control-input" type="radio" formControlName="deltaReading" [value]="true" [id]="id + '-' + i + '-delta-true'">
            <label class="custom-control-label" [attr.for]="id + '-' + i + '-delta-true'" i18n>Differentiated values</label>
          </div>
        </div>

        <div class="form-group mb-4">
          <label class="d-block mb-2" i18n>Unit conversion</label>
          <div class="row justify-content-center" *ngIf="!field.formGroup.get('deltaReading').value">
            <div class="align-items-center unit-example mb-3">
              <div class="w-100">
                <strong>1&nbsp;{{(field.formGroup.get('deltaReading').value ? '&Delta;' : '') + field.formGroup.get('unit').value}}</strong>&nbsp;*&nbsp;<strong>coefficient</strong>&nbsp;*&nbsp;time<sup><strong>timeExponent</strong></sup>&nbsp;=&nbsp;<strong>1&nbsp;{{field.requestedUnit}}</strong>
              </div>
              <a [routerLink]="null" class="mt-2 btn-link btn-sm btn" (click)="openConversionModal(conversionModalTemplate)" i18n>View examples</a>
            </div>
          </div>
          <div class="row justify-content-center" *ngIf="field.formGroup.get('deltaReading').value">
            <div class="align-items-center unit-example mb-3">
              <strong>1&nbsp;{{(field.formGroup.get('deltaReading').value ? '&Delta;' : '') + field.formGroup.get('unit').value}}</strong>&nbsp;*&nbsp;<strong>coefficient</strong>&nbsp;=&nbsp;<strong>1&nbsp;{{field.requestedUnit}}</strong>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-auto d-flex align-items-center mb-2">
              <label class="m-0 mr-2" i18n>Set coefficient</label>
              <input type="text" class="form-control w-100p text-right" placeholder="Coefficient" i18n-placeholder formControlName="coefficient">
              <small *ngIf="invalid('coefficient', field.formGroup) && errors('value', field.formGroup)?.required" class="invalid-feedback d-block" i18n>Required</small>
              <small *ngIf="invalid('coefficient', field.formGroup) && errors('value', field.formGroup)?.pattern" class="invalid-feedback d-block" i18n>Invalid</small>
            </div>
            <div class="col-auto d-flex align-items-center" *ngIf="!field.formGroup.get('deltaReading').value">
              <label class="m-0 mr-2" i18n>Set time exponent</label>
              <input type="text" class="form-control w-100p text-right" placeholder="Time exponent" i18n-placeholder formControlName="timeExponent">
              <small *ngIf="invalid('timeExponent', field.formGroup) && errors('value', field.formGroup)?.required" class="invalid-feedback d-block" i18n>Required</small>
              <small *ngIf="invalid('timeExponent', field.formGroup) && errors('value', field.formGroup)?.pattern" class="invalid-feedback d-block" i18n>Invalid</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <ng-container [ngSwitch]="field.fieldType">
      <ng-container *ngSwitchCase="'FORMULA'">
        <label class="question" [attr.for]="id + '-' + i + '-text'"><ng-container *ngIf="field.symbol">{{field.symbol}}:&nbsp;</ng-container>{{field.name}}<ng-container *ngIf="field.unit">&nbsp;({{field.unit}})</ng-container><span *ngIf="field.optional" class="text-light">&nbsp;(<ng-container i18n>Optional</ng-container>)</span></label>
        <p class="small-text" *ngIf="field?.description" [innerHTML]="field?.description | htmlEscape | linky"></p>

        <div class="row" *ngIf="field.formulaType == 'EXPRESSION'">
          <div class="col-auto">
            <label class="m-0 mr-2" i18n>Formula expression</label>
            <button class="btn btn-sm btn-link p-0"><ng-container *ngIf="field?.symbol">{{field.symbol}}&nbsp;=&nbsp;</ng-container>{{field?.expression}}</button>
          </div>
          <div class="col-auto">
            <label class="m-0 mr-2" i18n>Unit</label>
            <span>{{'' + field?.unit}}</span>
          </div>
          <div class="col-auto">
            <label class="m-0 mr-2" i18n>Start date</label>
            <span>{{field?.time | date:'dd. MMMM yyyy'}} <i class="fal fa-info-circle ml-2 tooltip-icon" popover="Start date for the data requested and the date from which the historical values for the formula will be calculated." placement="top" triggers="mouseenter:mouseleave" ></i></span>
          </div>
        </div>

        <div class="row" *ngIf="field.formulaType == 'MAPPING'">
          <div class="col-auto">
            <label class="m-0 mr-2" i18n>Mapping</label>
            <button class="btn btn-sm btn-link p-0"><ng-container *ngIf="field?.symbol">{{field.symbol}}&nbsp;=&nbsp;</ng-container>&fnof;({{getVariableList(field)}})</button>
          </div>
          <div class="col-auto">
            <label class="m-0 mr-2" i18n>Unit</label>
            <span>{{'' + field?.unit}}</span>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</div>

<ng-template #conversionModalTemplate>
  <div class="modal-header">
    <h5 class="modal-title pull-left" i18n>Unit conversion examples</h5>
    <button type="button" class="close pull-right" aria-label="Close" (click)="conversionModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="card shadow-none">
      <div class="card-body">
        <h5 class="card-title">Wh to kWh</h5>
        <div class="card-text">
          <div>time = 28800 seconds (8h)</div>
          <div>coefficient = 0.001 (1 Wh = 0.001 kWh)</div>
          <div>timeExponent = 0 (1 kWh = 1 kWh * s<sup>0</sup> = 1 kWh * 1)</div>
          <div class="strong">1 Wh * coefficient * time<sup>timeExponent</sup> = {{1 * (1/1000) * pow(28800, 0)}}kWh</div>
        </div>
      </div>
    </div>
    <div class="card shadow-none">
      <div class="card-body">
        <h5 class="card-title">W to Wh</h5>
        <div class="card-text">
          <div>time = 28800 seconds (8h)</div>
          <div>coefficient = 0.0002777778 (1 hour = 3600 seconds => 1/3600)</div>
          <div>timeExponent = 1 (1 Wh = 1 W * s<sup>1</sup>)</div>
          <div class="strong">1W * coefficient * time<sup>timeExponent</sup> = {{1 * (1/3600) * pow(28800, 1)}}Wh</div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">kWh to W</h5>
        <div class="card-text">
          <div>time = 28800 seconds (8h)</div>
          <div>coefficient = 3600 (1 hour = 3600 seconds => 3600)</div>
          <div>timeExponent = -1 (W = Wh * s<sup>-1</sup>)</div>
          <div class="strong">1 kWh * coefficient * time<sup>timeExponent</sup> = {{1 * 3600 * pow(28800, -1)}}W</div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">m/s<sup>2</sup> to km</h5>
        <div class="card-text">
          <div>time = 28800 seconds (8h)</div>
          <div>coefficient = 0,001 (1km = 1000m => 1/1000)</div>
          <div>timeExponent = 2 (m = m/s<sup>2</sup> * s<sup>2</sup>)</div>
          <div class="strong">1 m/s<sup>2</sup> * coefficient * time<sup>timeExponent</sup> = {{1 * 1/1000 * pow(28800, 2)}}km</div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
