import { MeteorologyService } from "../service/meteorology.service";
import { AbstractMapLayerMarker, OlMapWrapper } from "@smartencity/core";
import { WeatherStationOlMarkerWrapper } from "./weather-station-ol-marker-wrapper";
import { WeatherStation } from "../model/weather-station";

export class WeatherStationOlMarker extends AbstractMapLayerMarker<
  OlMapWrapper,
  WeatherStation
> {
  private selected = false;

  private meteorologyService: MeteorologyService;

  private marker: WeatherStationOlMarkerWrapper = null;

  constructor(
    weatherStation: WeatherStation,
    map: OlMapWrapper,
    meteorologyService: MeteorologyService
  ) {
    super(weatherStation);
    this.meteorologyService = meteorologyService;
    this.marker = new WeatherStationOlMarkerWrapper(weatherStation, map, meteorologyService);
  }

  setMap(map: OlMapWrapper) {
    super.setMap(map);
    const olMap = map ? map.getMap() : null;
    this.marker.setMap(olMap);
  }

  setSelected(selected: boolean): void {
    this.marker.selected = selected;
  }

  draw() {
    this.marker.draw();
  }

  isSelected(): boolean {
    return this.selected;
  }
}
