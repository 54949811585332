<div class="floating-jumbotron layer-filter mr-3 ml-0 mb-3">
    <label class="layer-filter-toggle" (click)="toggleLayer({cityDistricts: !displayArcGisSettings.cityDistricts})" [class.active]="displayArcGisSettings.cityDistricts" tooltip="Show city districts" i18n-tooltip data-filter-name="city-district">
        <i class="icon-s icon-city-disctrict"></i>
    </label>
    <label class="layer-filter-toggle" (click)="toggleLayer({residentialAreas: !displayArcGisSettings.residentialAreas})" [class.active]="displayArcGisSettings.residentialAreas" tooltip="Show residential areas" i18n-tooltip data-filter-name="residential-area">
      <i class="icon-s icon-city-disctrict"></i>
    </label>
    <label class="layer-filter-toggle" (click)="displayItemsChanged({bicycleParkingStation: !displaySettings.bicycleParkingStation})" [class.active]="displaySettings.bicycleParkingStation" tooltip="Show bicycle stations" i18n-tooltip data-filter-name="bicycle-parking-station">
        <i class="icon-s icon-bicycle-parking-station"></i>
    </label>
    <label class="layer-filter-toggle" (click)="displayItemsChanged({roadClosures: !displaySettings.roadClosures})" [class.active]="displaySettings.roadClosures" tooltip="Show road closures" i18n-tooltip data-filter-name="road-closure">
        <i class="icon-s icon-road-closure"></i>
    </label>
<!--    <label class="layer-filter-toggle" (click)="toggleLayer({parkingLots: !displayArcGisSettings.parkingLots})" [class.active]="displayArcGisSettings.parkingLots" tooltip="Show parking lots" i18n-tooltip data-filter-name="parking-lot">-->
<!--        <i class="icon-s icon-parking-lot"></i>-->
<!--    </label>-->
    <label class="layer-filter-toggle" (click)="toggleLayer({parkingZones: !displayArcGisSettings.parkingZones})" [class.active]="displayArcGisSettings.parkingZones" tooltip="Show parking zones" i18n-tooltip data-filter-name="parking-zone">
        <i class="icon-s icon-parking-zone"></i>
    </label>
    <label  class="layer-filter-toggle" (click)="toggleLayer({healthTrails: !displayArcGisSettings.healthTrails})" [class.active]="displayArcGisSettings.healthTrails" tooltip="Show health trails" i18n-tooltip data-filter-name="health-trail">
        <i class="icon-s icon-health-trail"></i>
    </label>
    <label class="layer-filter-toggle" (click)="displayItemsChanged({peopleTraffic: !displaySettings.peopleTraffic})" [class.active]="displaySettings.peopleTraffic" tooltip="Show people traffic" i18n-tooltip data-filter-name="people-traffic">
      <i class="icon-s icon-people-traffic"></i>
  </label>
</div>
