import { Component, OnInit } from '@angular/core';
import {AuthService, LoggerService, QuestionnaireInvitationAcceptedDto, QuestionnaireTypes} from '@smartencity/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MyQuestionnairesService} from '../questionnaires/my-questionnaires.service';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'mydata-invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.css']
})
export class InviteComponent implements OnInit {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private myQuestionnairesService: MyQuestionnairesService,
    public toastr: ToastrService,
    private loggerService: LoggerService
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      const inviteId = params.get('inviteuuid');
      if (inviteId != null) {
        this.myQuestionnairesService.acceptInvite(inviteId).subscribe((response: QuestionnaireInvitationAcceptedDto) => {
          let route = '';
          switch (response.questionnaireType) {
            case QuestionnaireTypes.ENERGY_MANAGER: {
              route = '/mydata/energy-manager';
              break;
            } case QuestionnaireTypes.OTHER: {
              route = '/mydata/questionnaires';
              break;
            }
          }
          const questionnaire = response?.participant?.questionnaire?.name;
          this.toastr.success($localize`You have accepted the invitation to participate in ${questionnaire}`);
          this.router.navigate([route]);
        }, (err: any) => {
          const errorCode = err.error.errorCode;
          if (errorCode === 'participant_already_exists') {
            this.toastr.error($localize`Participant already exists`);
          } else {
            this.loggerService.error(err);
            this.toastr.error($localize`Error confirming questionnaire invitation`);
          }
          this.router.navigate(['/mydata']);
        });
      }
    });
  }
}
