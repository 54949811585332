import {Component, Inject, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {Subject} from 'rxjs/internal/Subject';
import {Profile} from '../../models/profile';
import {CoreConfig} from '../../core-config.model';
import {SMARTENCITY_CORE_CONFIG} from '../../injection-tokens';
import {ToastrService} from 'ngx-toastr';

const selector = 'core-cpr-modal';
let nextId = 0;

@Component({
  selector: selector,
  templateUrl: './cpr-modal.component.html'
})
export class CprModalComponent implements OnInit, OnDestroy {
  id = `${selector}-${nextId++}`;
  private ngDestroy = new Subject<void>();
  public showErrors = false;
  public progress = false;

  @Input()
  public signedData: string;

  @Input()
  public challenge: string;

  @Output()
  public profile$ = new Subject<Profile>();

  form = new FormGroup({
    'cpr': new FormControl('', [Validators.required, Validators.pattern(/^\d{10}$/)])
  });

  constructor (
    @Inject(SMARTENCITY_CORE_CONFIG) private config: CoreConfig,
    private http: HttpClient,
    private toastr: ToastrService,
    public modalRef: BsModalRef
  ) { }

  ngOnInit () {}

  ngOnDestroy(): void {
    if (!this.profile$.isStopped) {
      this.profile$.error('CANCEL');
      this.profile$.complete();
    }
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  close () {
    this.profile$.error('CANCEL');
    this.profile$.complete();

    this.modalRef.hide();
  }

  async save () {
    this.form.markAsDirty();

    if (!this.form.valid) {
      this.showErrors = true;
      return;
    }

    const value = this.form.getRawValue();

    this.progress = true;
    await this.http.post<Profile>(this.config.apiUrl + '/nem-id/login', {
      signedData: this.signedData,
      challenge: this.challenge,
      cpr: value.cpr
    }).subscribe((profile: Profile) => {
      this.profile$.next(profile);
      this.profile$.complete();

      this.modalRef.hide();
    }, (error) => {
      this.progress = false;
      this.toastr.error('CPR check failed');
    }, () => this.progress = false);
  }
}
