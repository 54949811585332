import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DalApiTokenComponent } from './dal-api-token/dal-api-token.component';
import {SettingsComponent} from './settings.component';
import {NotificationAddressEditModalComponent} from './notification-addresses/edit-modal/notification-address-edit-modal.component';
import {NotificationAddressesComponent} from './notification-addresses/notification-addresses.component';
import {DeleteModalComponent} from './delete-modal/delete-modal.component';
import {RestoreModalComponent} from './restore-modal/restore-modal.component';
import {PreferencesComponent} from './preferences/preferences.component';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ModalModule} from 'ngx-bootstrap/modal';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {CoreModule} from '@smartencity/core';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {PopoverModule} from 'ngx-bootstrap/popover';
import { TokenPersonsModalComponent } from './dal-api-token/token-persons-modal/token-persons-modal.component';
import { CityPortalInfoComponent } from './city-portal-info/city-portal-info/city-portal-info.component';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import { TokenPersonHistoryModalComponent } from './dal-api-token/token-person-history-modal/token-person-history-modal.component';



@NgModule({
  declarations: [
    SettingsComponent,
    DalApiTokenComponent,
    NotificationAddressesComponent,
    NotificationAddressEditModalComponent,
    PreferencesComponent,
    DeleteModalComponent,
    RestoreModalComponent,
    TokenPersonsModalComponent,
    CityPortalInfoComponent,
    TokenPersonHistoryModalComponent
  ],
    imports: [
        CommonModule,
        RouterModule.forChild([]),
        // AdviceRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        CoreModule,
        ModalModule,
        NgxDatatableModule,
        BsDropdownModule,
        PopoverModule,
        TooltipModule
    ]
})
export class SettingsModule { }
