import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {Subject} from 'rxjs/internal/Subject';

@Component({
  selector: '[air-quality-station-modal]',
  templateUrl: './air-quality-station-modal.component.html'
})
export class AirQualityStationModalComponent implements OnInit, OnChanges, OnDestroy {
  private ngDestroy = new Subject<void>();

  @Input()
  airQualityStation: any;

  @Output('modalClose')
  modalClose: EventEmitter<void> = new EventEmitter<void>();

  constructor() {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  close() {
    this.modalClose.next();
  }

}
