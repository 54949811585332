<div class="modal-header">
  <h5 class="modal-title w-100" i18n>Formula update errors</h5>
  <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
    <span class="fal fa-times"></span>
  </button>
</div>
<div class="modal-body" >
  <div class="row" *ngFor="let error of errors">
    <div class="col-6" >{{error.time|date:'dd.MM.yyyy HH:mm'}}</div>
    <div class="col-6">{{error.message}}</div>
  </div>
  <div class="row" *ngIf="errors.length < total">
    <div class="col">
      +{{total - errors.length}} <span i18n>more</span>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-light btn-sm" aria-label="Close" (click)="close()" i18n>Close</button>
  <button type="button" class="btn btn-primary btn-sm" (click)="resetErrors();" [ladda]="processing" [disabled]="processing" i18n>Reset updates having errors</button>
</div>
