import {Component, Inject, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {PageResponse, Questionnaire, QuestionnaireParticipant} from '@smartencity/core';
import {SMARTENCITY_MYDATA_CONFIG} from '../../../injection-tokens';
import {MyDataConfig} from '../../../mydata-config.model';
import {HttpClient} from '@angular/common/http';
import {Subject} from 'rxjs/internal/Subject';
import {takeUntil} from 'rxjs/operators';
import {DatatableComponent} from '@swimlane/ngx-datatable';

@Component({
  selector: 'mydata-view-results-individual',
  templateUrl: './view-results-individual.component.html',
  styleUrls: ['./view-results-individual.component.css']
})
export class ViewResultsIndividualComponent implements OnInit, OnDestroy {
  private ngDestroy = new Subject<void>();

  @ViewChild('table') table: DatatableComponent;

  @Input()
  questionnaire: Questionnaire;

  rows = [];

  constructor(
    @Inject(SMARTENCITY_MYDATA_CONFIG) private config: MyDataConfig,
    private http: HttpClient
  ) { }

  ngOnInit(): void {
    this.loadResults();
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  loadResults() {
    if (this.questionnaire) {
      this.http.get(this.config.apiUrl + '/questionnaire/' + this.questionnaire.id + '/participation-results', {}).pipe(takeUntil(this.ngDestroy)).subscribe((data: PageResponse<QuestionnaireParticipant>) => {
        this.rows = data.content.map(e => ({...e, selectedResponseId: e.responses?.length ? e.responses[0].id : null}));
      });
    }
  }

  onTreeAction(event: any): void {
    const index = event.rowIndex;
    const row = event.row;
    if (row.treeStatus === 'collapsed') {
      row.treeStatus = 'expanded';
    } else {
      row.treeStatus = 'collapsed';
    }
    this.table.rowDetail.toggleExpandRow(row);
    this.rows = [...this.rows];
  }

  public viewResponse(row: any, index: number) {
    row.selectedResponseId = row.responses[index].id;
  }
}
