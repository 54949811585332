import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {
  EnumsService,
  PageResponse,
  PersonSeries, PersonSeriesApiService,
  PersonSeriesGroup,
  PreventCloseAware
} from '@smartencity/core';
import {FormControl, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {Subject} from 'rxjs/internal/Subject';
import {Observable} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import {PersonSeriesManageService} from '../../../services/person-series-manage.service';
import {DatapointGroupSeriesListService} from '../datapoint-group-series-list.service';
import {map, switchMap, takeUntil} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import {PersonSeriesGroupApiService} from '../../../http/person-series-group-api.service';

const selector = 'mydata-edit-datapoint-group-modal';
let nextId = 0;

@Component({
  selector: selector,
  templateUrl: './edit-datapoint-group-modal.component.html',
  providers: [DatapointGroupSeriesListService]
})
export class EditDatapointGroupModalComponent implements OnInit, OnDestroy, PreventCloseAware {
  id = `${selector}-${nextId++}`;
  private ngDestroy = new Subject<void>();

  public confirmed = false;

  @Input()
  public group?: PersonSeriesGroup;

  @Input()
  public isCreate = false;

  @Input()
  public groupingTag?: string;

  @Input()
  public selectedPersonSeriesList: PersonSeries[] = [];

  searchControl = this.datapointGroupSeriesList.searchControl;

  pageControl = this.datapointGroupSeriesList.pageControl;

  public pageResponse$ = this.datapointGroupSeriesList.pageResponse$;

  public rows$ = this.datapointGroupSeriesList.rows$;

  pageResponse: PageResponse<PersonSeries>;

  form: FormGroup;

  addedPersonSeriesList: PersonSeries[] = [];

  groupTypeOptions: any[] = [];

  saveInProgress = false;

  rowsInitialLoad = true;

  public static formDefault(): FormGroup {
    return new FormGroup({
      name: new FormControl('', [Validators.required]),
      type: new FormControl(''),
      groupingTag: new FormControl('')
    });
  }

  constructor(private modalRef: BsModalRef,
              private personSeriesGroupService: PersonSeriesGroupApiService,
              private personSeriesService: PersonSeriesApiService,
              private personSeriesManageService: PersonSeriesManageService,
              private toastr: ToastrService,
              private datapointGroupSeriesList: DatapointGroupSeriesListService,
              private enumsService: EnumsService) { }

  ngOnInit(): void {
    this.buildForm(this.group, this.groupingTag);
    this.pageResponse$.pipe(takeUntil(this.ngDestroy)).subscribe((pageResponse) => {
      this.pageResponse = pageResponse;
    });

    this.rows$.pipe(takeUntil(this.ngDestroy)).subscribe((items) => {
      this.addedPersonSeriesList = items;

      if (!this.rowsInitialLoad) {
        this.form.markAsDirty();
      }

      if (this.rowsInitialLoad) {
        this.rowsInitialLoad = false;
      }
    });

    this.loadPersonSeriesList();

    this.groupTypeOptions = this.enumsService.getOptionsList(this.enumsService.enums.groupType).map((option) => {
      return {
        iconClass: this.groupTypeIconMap.get(option.value),
        value: option.value,
        label: option.label
      }
    });
  }

  ngOnDestroy() {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  @Output('save')
  saveEmitter: EventEmitter<any> = new EventEmitter<any>();

  private buildForm(group?: PersonSeriesGroup, groupingTag?: string): void {
    this.form = EditDatapointGroupModalComponent.formDefault();
    if (group) {
      this.form.patchValue(group);
    } else if (groupingTag) {
      this.form.patchValue({
        name: groupingTag,
        groupingTag: groupingTag
      });
    }
  }

  public saveForm(): void {
    if (this.saveInProgress) {
      return;
    }

    this.form.markAsTouched();
    this.markFormGroupTouched(this.form);
    this.form.updateValueAndValidity();
    if (!this.form.valid) {
      console.error('form invalid', this.form);
      return;
    }

    this.confirmed = true;
    this.saveInProgress = true;

    const data = this.prepareFormValue(this.form);
    this.saveData(data).subscribe((result: PersonSeriesGroup) => {
      this.saveEmitter.next(result);
      this.toastr.success($localize`Data group saved`);

      this.form.markAsPristine();
      this.modalRef.hide();
    }, (err) => {
      let errorMessage = $localize`Something went wrong!`;
      if (err.error && err.error.errorCode === 'PERSON_SERIES_GROUP_EXISTS') {
        errorMessage = $localize`Data group with the same name already exists. Please choose a different name.`;
      }

      this.toastr.error(errorMessage);
      console.error(err);
      this.saveInProgress = false;
    });

  }

  private saveData(data: any): Observable<PersonSeriesGroup> {

    return new Observable<PersonSeriesGroup>((observer) => {
      if (this.group?.id) {
        this.personSeriesGroupService.updateGroup(this.group.id, data).subscribe((result: PersonSeriesGroup) => {
          observer.next(result);
        }, (err) => {
          observer.error(err);
        });
      } else {
        this.personSeriesGroupService.createGroup(data).subscribe((result: PersonSeriesGroup) => {
          observer.next(result);
        }, (err) => {
          observer.error(err);
        });
      }
    });
  }


  public close(): void {
    this.modalRef.hide();
  }

  control(name?: string) {
    if (!name) {
      return this.form;
    }
    return this.form.get(name);
  }

  invalid(name: string, formGroup?) {
    if (!formGroup) {
      formGroup = this.form;
    }
    const control = formGroup.get(name);
    return control && control.invalid && (control.dirty || control.touched);
  }

  errors(name: string): ValidationErrors {
    const control = this.form.get(name);
    return control ? control.errors : null;
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  private prepareFormValue(form: FormGroup): any {
    let data = form.getRawValue();
    if (!data.groupingTag) {
      data.groupingTag = data.name;
    }
    if (data.type === '') {
      data.type = null;
    }
    data.personSeriesIds = this.addedPersonSeriesList.map(s => s.id);

    return data;
  }

  addItem(personSeries: PersonSeries): void {
    this.datapointGroupSeriesList.addItem(personSeries);
  }

  removeItem(personSeries: PersonSeries): void {
    this.datapointGroupSeriesList.removeItem(personSeries);
  }

  isSelected(personSeries: PersonSeries): boolean {
    return this.datapointGroupSeriesList.isSelected(personSeries);
  }

  removeAll(): void {
    this.datapointGroupSeriesList.removeAll();
  }

  fetchPage(params: any): Observable<PageResponse<PersonSeries>> {
    return this.personSeriesGroupService.getGroupPersonSeriesPage(params);
  }

  mapQueryParams(filter: any, limit: number, page: any) {
    const queryParams: any = {};

    if (filter) {
      if (filter.query) {
        queryParams.query = filter.query;
      }
    }

    if (page) {
      queryParams.page = page.offset;
      queryParams.size = limit;
    }

    if (limit) {
      queryParams.size = limit;
    }

    return queryParams;
  }

  clearSearch(): void {
    this.searchControl.setValue('');
  }

  addAll(): void {
    this.datapointGroupSeriesList.addItems(this.pageResponse.content);
  }

  toggleType(type: string): void {
    let currentType = this.form.get('type').value;
    this.form.get('type').setValue(type != currentType ? type : null);
    this.form.get('type').updateValueAndValidity();
    this.form.markAsDirty();
  }

  private groupTypeIconMap: Map<String, String> = new Map<String, String>([
    ['BUILDINGS', 'icon-building'],
    ['MOBILITY', 'icon-mobility'],
    ['LIGHTS', 'icon-lights'],
    ['WEATHER', 'icon-weather'],
    ['PEOPLE', 'icon-people'],
    ['DATA', 'icon-data'],
    ['ELECTRICITY_METER', 'icon-electric-meter'],
    ['ELECTRIC_VEHICLE_CHARGER', 'icon-electric-vehicle-charger'],
    ['ELECTRIC_BICYCLE_STATION', 'icon-electric-bicycle-station'],
    ['STATISTICS', 'icon-statistics'],
    ['OTHER', 'icon-other']
  ]);

  isPreventClose(): boolean {
    return this.form && this.form.dirty;
  }

  private loadPersonSeriesList(): void {

    this.getGroupPersonSeriesList(this.group).subscribe((result: PersonSeries[]) => {
      let personSeriesList = Object.assign([], result);
      if (this.selectedPersonSeriesList && this.selectedPersonSeriesList.length > 0) {
        personSeriesList.push(...this.selectedPersonSeriesList);
      }

      this.datapointGroupSeriesList.setItems(personSeriesList);
    })
  }

  private getGroupPersonSeriesList(group: PersonSeriesGroup): Observable<PersonSeries[]> {
    if (!group || !group.id) {
      return of([]);
    }

    let params: any = {
      groupId: group.id
    };

    return this.personSeriesGroupService.getGroupPersonSeriesPage(params).pipe(
      map((pageResponse: PageResponse<PersonSeries>) => {
        return pageResponse.content;
      })
    );
  }

}
