import { Component, Inject, Input, OnInit, SimpleChanges } from '@angular/core';
import {
  AuthService, OwnerType, PendingPersonSeriesGroup,
  Tenant,
  UserService, ConfirmModalComponent, PersonSeriesApiService
} from '@smartencity/core';
import { SMARTENCITY_MYDATA_CONFIG } from '../../../injection-tokens';
import { MyDataConfig } from '../../../mydata-config.model';
import { TenantSelectService } from '../../../services/tenant-select.service';
import { NotificationService } from '../notification.service';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs/internal/Subject';
import { BsModalService } from 'ngx-bootstrap/modal';
import {PersonSeriesGroupApiService} from '../../../http/person-series-group-api.service';

@Component({
  selector: 'mydata-pending-group-consent-row',
  templateUrl: './pending-group-consent-row.component.html'
})
export class PendingGroupConsentRow implements OnInit {
  private ngDestroy = new Subject<void>();

  @Input()
  item: PendingPersonSeriesGroup;

  @Input()
  public parentProgress: string = null;

  public assignerName;
  public datapointName;
  public location;
  public comment;

  public tenant: Tenant = null;
  public progress: string = null;

  constructor(
    @Inject(SMARTENCITY_MYDATA_CONFIG) private config: MyDataConfig,
    public authService: AuthService,
    public personSeriesService: PersonSeriesApiService,
    public personSeriesGroupService: PersonSeriesGroupApiService,
    public userService: UserService,
    public tenantSelectService: TenantSelectService,
    public notificationService: NotificationService,
    private toastr: ToastrService,
    private modalService: BsModalService
  ) {
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.item) {
      this.assignerName = this.item.assignerPerson.displayName;
      this.datapointName = this.item.personSeriesGroup.name;
      // this.location = this.personSeriesLocationLabel.transform(this.item.personSeries);
      this.comment = this.item.assignerComment;
    }
  }

  accept(item: PendingPersonSeriesGroup) {
    const optional = !(item.period.ownerType === OwnerType.OWNER);

    this.progress = 'accept';

    this.tenantSelectService.selectTenant(optional).subscribe((tenant: Tenant) => {
      this.modalService.show(ConfirmModalComponent, {
        initialState: {
          description: $localize`Are you sure you want to accept pending group consent?`,
          callback: (result: boolean) => {
            if (result) {
              this.tenant = tenant;
              this.submitPendingGroupStatus(item, 'ACCEPTED');
            } else {
              this.progress = null;
            }
          }
        }
      });
    }, (error) => {
      this.progress = null;
      console.error(error);
      this.toastr.error($localize`Tenant selection error`);
    }, () => {});
  }

  decline(item: PendingPersonSeriesGroup) {
    this.modalService.show(ConfirmModalComponent, {
      initialState: {
        description: $localize`Are you sure you want to decline pending group consent?`,
        callback: (result: boolean) => {
          if (result) {
            this.progress = 'decline';
            this.submitPendingGroupStatus(item, 'DECLINED');
          }
        }
      }
    });
  }

  archive(item: PendingPersonSeriesGroup) {
    this.progress = 'archive';
    this.personSeriesGroupService.deletePendingGroupConsent(item.personSeriesGroup.id, item.period.id).subscribe(
      (data: any) => {
        this.toastr.success($localize`Acknowledged`);
        this.stateChanged();
      }, (error) => {
        this.progress = null;
        console.error(error);
        this.toastr.error($localize`Error archiving series`);
      }, () => this.progress = null
    );
  }

  submitPendingGroupStatus(item: PendingPersonSeriesGroup, status: string) {
    this.personSeriesGroupService.updateConsentStatus(item.personSeriesGroup.id, status).subscribe(
      (data: any) => {
        this.stateChanged();
      }, (error) => {
        this.progress = null;
        console.error(error);
        this.toastr.error($localize`Error submitting confirmation`);
      }, () => {
        this.progress = null;
      }
    );
  }

  private stateChanged() {
    this.notificationService.loadPendingSeriesGroups();
    this.userService.confirmationListChanged();
  }

}
