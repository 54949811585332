import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CoreModule} from '@smartencity/core';
import {NgxPermissionsModule} from 'ngx-permissions';
import {ModalModule} from 'ngx-bootstrap/modal';
import {PaginationModule} from 'ngx-bootstrap/pagination';
import {DatapointListParameters} from './datapoint-list-parameters.component';
import {ParametersEditModalComponent} from './edit-modal/parameters-edit-modal.component';
import {ParametersService} from '../../services/parameters.service';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {NgSelectModule} from '@ng-select/ng-select';
import { GlobalParametersComponent } from './global-parameters.component';

@NgModule({
  declarations: [
    DatapointListParameters,
    ParametersEditModalComponent,
    GlobalParametersComponent
  ],
  entryComponents: [],
  providers: [
    ParametersService
  ],
  imports: [
    CommonModule,
    RouterModule.forChild([]),
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    NgxPermissionsModule.forChild(),
    ModalModule,
    //TypeaheadModule,will cause conflict with NgSelectModule
    PaginationModule,
    BsDropdownModule,
    NgSelectModule,
    NgxDatatableModule
  ],
  exports: [

  ]
})
export class ParametersModule { }
