import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {NavbarService} from '../navbar/navbar.service';

@Component({
  selector: 'app-mandate-root',
  templateUrl: './mandate-root.component.html'
})
export class MandateRootComponent implements OnInit {

  @ViewChild('navMenu', {static: true})
  public navTempalte: TemplateRef<any>;

  constructor(private navbarService: NavbarService) { }

  ngOnDestroy(): void {
    this.navbarService.setMenuTemplate(null);
  }

  ngOnInit(): void {
    this.navbarService.setMenuTemplate(this.navTempalte);
  }

}
