import {Component, Input, OnInit, Output} from '@angular/core';
import {Widget} from '../widget';
import {WidgetModalComponent} from '../widget-modal/widget-modal.component';
import {Subject} from 'rxjs';
import {Moment} from 'moment';
import {ToastrService} from 'ngx-toastr';
import {PreventCloseModalService} from '@smartencity/core';
import { WidgetApiService } from '../../../http/widget-api.service';
import {BsModalService} from 'ngx-bootstrap/modal';

@Component({
  selector: 'mydata-dashboard-widget',
  templateUrl: './widget.component.html'
})
export class WidgetComponent implements OnInit {
  @Input()
  public widget: Widget;

  @Input()
  public rangeFrom: Moment;

  @Input()
  public rangeTo: Moment;

  @Input()
  public aggregationGroupingType: string;

  @Input()
  public reloadWidgets: Subject<void>;

  @Input()
  public isPublic = false;

  @Input()
  public isAllowedDownloads = false;

  @Input()
  public isOwner = true;

  @Output()

  public pngEnabled = false;

  public xlsEnabled = false;

  public loadPng = new Subject<void>();

  @Output()
  public loadXls = new Subject<void>();

  constructor(
    public widgetApiService: WidgetApiService,
    private preventCloseModalService: PreventCloseModalService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {}

  deleteWidget() {
    this.widgetApiService.delete(this.widget).subscribe(() => {
      this.reloadWidgets.next();
    }, (e: any) => {
      this.toastr.error(JSON.stringify(e));
      this.reloadWidgets.next();
    });
  }

  editWidget() {
    const copy = Object.assign({}, this.widget) ;
    this.preventCloseModalService.show(WidgetModalComponent, {
      class: 'modal-xxl',
      initialState: {
        widget: copy,
        onHide: () => {
          this.reloadWidgets.next();
        }
      }
    });
  }

  widgetTypeViewReady(ready: boolean) {
    if (ready) {
      if (this.widget.type === 'TIME_FRAME_CHART' || this.widget.type === 'VALUE_CHART') {
        this.pngEnabled = true;
      } else {
        this.pngEnabled = false;
      }
      //this.xlsEnabled = this.widget.type === 'VALUE_TABLE';
      this.xlsEnabled = true;
    } else {
      this.pngEnabled = false;
    }
  }

  downloadPng() {
    this.loadPng.next();
  }

  downloadXls() {
    this.loadXls.next();
  }
}
