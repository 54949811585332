import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'icon-weather-template',
  templateUrl: './icon-weather-template.component.svg'
})
export class IconWeatherTemplateComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
