export class SourceUpdateOptions {

  public static windowOperationTypeOptions = [
    {value: 'LATEST', label: $localize`Latest value`},
    {value: 'OFFSET', label: $localize`Historical value`},
    {value: 'SUM', label: $localize`Latest timeframe sum`},
    {value: 'AVERAGE', label: $localize`Latest timeframe average`}
  ];

  public static groupSourceWindowOperationTypeOptions = [
    {value: 'OFFSET', label: $localize`Latest value`},
    {value: 'SUM', label: $localize`Latest timeframe sum`},
  ]

  public static windowPeriodTypeOptions = [
    {value: 'HOUR', label: $localize`Hour`},
    {value: 'DAY', label: $localize`Day`},
    {value: 'MONTH', label: $localize`Month`},
    {value: 'YEAR', label: $localize`Year`}
  ];

}
