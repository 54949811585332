import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs/internal/Subject';
import {ManageService} from '../manage.service';
import {moveItemInArray} from '@angular/cdk/drag-drop';
import {takeUntil} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {QuestionnaireDetail} from '@smartencity/core';

@Component({
  selector: 'mydata-field-list',
  templateUrl: './field-list.component.html',
  styleUrls: ['./field-list.component.css']
})
export class FieldListComponent implements OnInit, OnDestroy {
  private ngDestroy = new Subject<void>();

  @Input()
  public type: string;

  @Input()
  public questionnaire: QuestionnaireDetail;

  @Input()
  public partialEdit = false;

  activeField;
  hoverField;
  fields$ = this.manageService.fields$;

  constructor(
    private manageService: ManageService,
    private router: Router,
    private route: ActivatedRoute
  ) {
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  ngOnInit(): void {
    this.manageService.field$.pipe(takeUntil(this.ngDestroy)).subscribe(f => {
      this.activeField = f;
    });
  }

  removeField(field) {
    if (field.type === 'FORMULA') {
      for (let symbol of field.variables) {
        let inputField = this.manageService.symbolFieldMap.get(symbol);
        this.doRemoveField(inputField);
      }
    }
    this.doRemoveField(field);
    this.manageService.save();
  }

  private doRemoveField(field) {
    const index = this.manageService.fields.indexOf(field);
    if (index >= 0) {
      this.manageService.fields.splice(index, 1);
    }
  }

  async selectField(field) {
    try {
      if (field.type !== 'FORMULA_INPUT') {
        await this.manageService.setField(field);
      } else {
        const parents = this.manageService.symbolFieldsMap.get(field.symbol);
        if (parents && parents.size === 1) {
          const parent = parents.values().next().value;
          await this.manageService.setField(parent, field);
        }
      }
    } catch (e) {
      console.log('Navigation prevented');
    }

  }

  isSelected(field) {
    if (field.type !== 'FORMULA_INPUT' && field === this.activeField) {
      return true;
    }
    if (this.hoverField) {
      return this.manageService.symbolFieldsMap.get(field.symbol)?.has(this.hoverField);
    } else if (this.activeField) {
      return this.manageService.symbolFieldsMap.get(field.symbol)?.has(this.activeField);
    }
  }

  mouseEnter(field) {
    this.hoverField = field;
  }

  mouseLeave(field) {
    if (this.hoverField === field) {
      this.hoverField = null;
    }
  }

  async addField() {
    try {
      await this.manageService.addField();
    } catch (e) {
      console.log('Navigation prevented');
    }
  }

  drop(event) {
    moveItemInArray(this.manageService.fields, event.previousIndex, event.currentIndex);
  }
}
