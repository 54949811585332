import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'icon-buildings-template',
  templateUrl: './icon-buildings-template.component.html',
})
export class IconBuildingsTemplateComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
