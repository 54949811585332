import { ThroughputPoint } from '../model/traffic';
import {MobilityService} from '../service/mobility.service';

export class TrafficThroughputLayer extends google.maps.OverlayView {

  private mobilityService: MobilityService;
  private div: any;
  public throughputPoints: ThroughputPoint[] = [];

  private isDrawn = false;
  private popoverTimeout = null;

  private selected: string | null;

  constructor(map, mobilityService: MobilityService) {
    super();
    this.mobilityService = mobilityService;
    this.setMap(map);
  }

  onAdd() {
    const panes = this.getPanes();
    if (panes == null) {
      return;
    }

    this.div = document.createElement('DIV');
    this.div.style.position = 'absolute';
    this.div.style.cursor = 'pointer';
    this.div.style.position = 'absolute';
    this.div.classList.add('traffic-layer');
    this.div.style.paddingLeft = '0px';
    panes.overlayMouseTarget.appendChild(this.div);

    this.isDrawn = false;
  }

  onRemove() {
    if (this.div) {
      this.isDrawn = false;
      this.div.parentNode.removeChild(this.div);
      this.div = null;
    }
  }

  draw() {
    if (!this.div) {
      return;
    }

    this.div.innerHTML = this.getThroughputHtml();
    this.resetPopovers();
  }

  private getThroughputHtml() {
    let html = '';
    if (!this.throughputPoints) {
      return html;
    }
    this.throughputPoints.forEach(throughputPoint => {
      const point = this.getProjection().fromLatLngToDivPixel(new google.maps.LatLng(throughputPoint.lat, throughputPoint.lng));
      let className;
      const x = point.x - 16;
      const y = point.y - 16;
      className = 'pointer blue';
      const isSelected = this.selected == throughputPoint.id.toString() ? ' active' : '';
      const popoverContent = '<strong>' + throughputPoint.name + '</strong><br />' + throughputPoint.value + ' ' + throughputPoint.unit;
      html += '<div class="' + className + isSelected + '" style="left: ' + x + 'px; top: ' + y + 'px" data-toggle="popover" data-trigger="hover" data-html="true" data-content="' + popoverContent + '">' + throughputPoint.value + '</div>';
    });
    return html;
  }

  setMap(map) {
    this.throughputPoints = this.mobilityService.throughputPoints;
    super.setMap(map);
  }

  remove() {
    this.setMap(null);
    if (this.div && this.div.parentNode) {
      $('.custom-marker-layer .custom-marker').off('click');
      this.div.parentNode.removeChild(this.div);
      this.div = null;
    }
  }

  showPoints(throughputPoints: ThroughputPoint[]) {
    if (!this.throughputPoints.length) {
      this.throughputPoints = throughputPoints;
      this.draw();
    }
  }

  private resetPopovers() {
    $('.popover.show').remove();
    if (this.popoverTimeout != null) {
      clearTimeout(this.popoverTimeout);
    }
    this.popoverTimeout = setTimeout(() => {
      ($(this.div).find('[data-toggle="popover"]') as any).popover({
        trigger: 'hover'
      });
      ($('.active', '.traffic-layer') as any).popover('show');
    }, 400);
  }

  setSelected(id: string | null): void {
    this.selected = id;
  }
}
