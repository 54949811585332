import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {environment} from '../environments/environment';
import {map, switchMap} from 'rxjs/operators';
import {SMARTENCITY_APP_CONFIG} from './injection-tokens';
import {SMARTENCITY_CORE_CONFIG} from '@smartencity/core';
import {SMARTENCITY_MOBILITY_CONFIG} from '@smartencity/mobility';
import {SMARTENCITY_BUILDINGS_CONFIG} from '@smartencity/buildings';
import {SMARTENCITY_LIGHTS_CONFIG} from '@smartencity/lights';
import {SMARTENCITY_PEOPLE_CONFIG} from '@smartencity/people';
import {SMARTENCITY_MYDATA_CONFIG} from '@smartencity/mydata';
import {SMARTENCITY_SERVICES_CONFIG} from '@smartencity/services';
import {SMARTENCITY_GAS_CONFIG} from '@smartencity/gas';
import {SMARTENCITY_TALLINN_CONFIG} from '@smartencity/tallinn';
import {SMARTENCITY_METEOROLOGY_CONFIG} from '@smartencity/meteorology';
import {DOCUMENT} from '@angular/common';
import {forkJoin} from 'rxjs/internal/observable/forkJoin';
import {of} from 'rxjs/internal/observable/of';
import {CookieConsentService} from '../../projects/core/src/lib/services/cookie/cookie-consent.service';
import {GtagService} from '../../projects/core/src/lib/services/gtag/gtag.service';

declare var gtag

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private config: any;

  constructor(
    private http: HttpClient,
    @Inject(DOCUMENT) private document: Document,
    @Inject(SMARTENCITY_APP_CONFIG) private app,
    @Inject(SMARTENCITY_CORE_CONFIG) private core,
    @Inject(SMARTENCITY_MOBILITY_CONFIG) private mobility,
    @Inject(SMARTENCITY_BUILDINGS_CONFIG) private buildings,
    @Inject(SMARTENCITY_LIGHTS_CONFIG) private lights,
    @Inject(SMARTENCITY_PEOPLE_CONFIG) private people,
    @Inject(SMARTENCITY_MYDATA_CONFIG) private mydata,
    @Inject(SMARTENCITY_SERVICES_CONFIG) private services,
    @Inject(SMARTENCITY_GAS_CONFIG) private gas,
    @Inject(SMARTENCITY_TALLINN_CONFIG) private tallinn,
    @Inject(SMARTENCITY_METEOROLOGY_CONFIG) private meteorology,
    private cookieConsentService: CookieConsentService,
    private gtagService: GtagService
  ) {}

  static mapCore(cfg: any) {
    return {
      i18n: cfg.i18n,
      production: cfg.production,
      apiUrl: cfg.apiUrl,
      ssoApi: cfg.ssoApi,
      cityApiUrl: cfg.cityApiUrl,
      theme: cfg?.theme,
      appName: cfg?.appName,
      nemIdServerUrlPrefix: cfg.nemIdServerUrlPrefix,
      loginMode: cfg.loginMode,
      tokenLogin: cfg.tokenLogin,
      serviceName: cfg.serviceName,
      locationName: cfg.locationName,
      defaultCountryCode: cfg.defaultCountryCode,
      modules: cfg.modules,
      map: cfg.map,
      components: cfg.components,
      noLandingPageText: cfg.noLandingPageText,
      addressControl: cfg.addressControl,
      personRegNoTemplate: cfg.personRegNoTemplate,
      cityRegistrationCode: cfg.cityRegistrationCode,
      weatherService: cfg.weatherService,
      tosContentEnabled: cfg.tosContentEnabled,
      lazyLoadStyles: cfg.lazyLoadStyles,
      title: cfg.title,
      termsOfService: cfg.termsOfService,
      gaTrackingId: cfg.gaTrackingId,
      defaultPreferences: cfg.defaultPreferences,
      idpProviders: cfg.idpProviders
    };
  }

  static mapMobility(cfg: any) {
    return {
      cityApiUrl: cfg.cityApiUrl,
      map: cfg.map,
      displaySettings: cfg.mobility?.displaySettings
    };
  }

  static mapBuildings(cfg: any) {
    return Object.assign({
      cityApiUrl: cfg.cityApiUrl,
      map: cfg.map
    }, cfg.modules?.buildings, {
      displaySettings: cfg.buildings
    });
  }

  static mapLights(cfg: any) {
    return {
      cityApiUrl: cfg.cityApiUrl,
      map: cfg.map
    };
  }

  static mapPeople(cfg: any) {
    return {
      cityApiUrl: cfg.cityApiUrl,
      map: cfg.map
    };
  }

  static mapMyData(cfg: any) {
    return {
      production: cfg.production,
      apiUrl: cfg.apiUrl,
      ssoApi: cfg.ssoApi,
      cityApiUrl: cfg.cityApiUrl,
      defaultCountryCode: cfg.defaultCountryCode,
      cityRegistrationCode: cfg.cityRegistrationCode,
      cityPortalTypes: cfg.cityPortalTypes,
      map: cfg.map,
      feedbackEnabled: cfg.feedbackEnabled,
      defaultPreferences: cfg.defaultPreferences,
      dataMappingUrl: cfg.dataMappingUrl,
    };
  }

  static mapServices(cfg: any) {
    return {
      cityApiUrl: cfg.cityApiUrl,
      map: cfg.map
    };
  }

  static mapGas(cfg: any) {
    return {
      cityApiUrl: cfg.cityApiUrl,
      map: cfg.map
    };
  }

  static mapTallinn(cfg: any) {
    return {
      cityApiUrl: cfg.cityApiUrl,
      map: cfg.map
    };
  }

  static mapMeteorology(cfg: any) {
    return {
      cityApiUrl: cfg.cityApiUrl,
      map: cfg.map
    };
  }

  loadConfig() {
    return this.http
      .get<any>('./config.json?v=' + Math.floor((Math.random() * Math.pow(2, 32))))
      .pipe(
        map((config: any) => Object.assign({}, environment, config)),
        switchMap((config) => {
          this.config = config;
          this.updateConfigurations(config);
          this.initGaTracking();
          return this.initTheme().pipe(map(() => config));
        })
      );
  }

  private updateConfigurations(config: any): void {
    Object.assign(this.app, config);
    Object.assign(this.core, ConfigService.mapCore(config));
    Object.assign(this.mobility, ConfigService.mapMobility(config));
    Object.assign(this.buildings, ConfigService.mapBuildings(config));
    Object.assign(this.lights, ConfigService.mapLights(config));
    Object.assign(this.people, ConfigService.mapPeople(config));
    Object.assign(this.mydata, ConfigService.mapMyData(config));
    Object.assign(this.services, ConfigService.mapServices(config));
    Object.assign(this.gas, ConfigService.mapGas(config));
    Object.assign(this.tallinn, ConfigService.mapTallinn(config));
  }

  initTheme() {
    const themeName = this.app?.theme;
    const theme = (themeName ? themeName : 'telia') + '-theme';
    const body = this.document.getElementsByTagName('body')[0];
    body.classList.add(theme);

    const linkElements = document.querySelectorAll('link[ rel ~= \'icon\' i]');

    for (const linkElement of Array.from(linkElements)) {
      linkElement.parentNode.removeChild(linkElement);
    }

    switch (themeName) {
      case 'telia':
      case 'sonderborg': {
        const linkElement = document.createElement('link');
        linkElement.setAttribute('rel', 'icon');
        linkElement.setAttribute('type', 'image/x-icon');
        linkElement.setAttribute('href', 'favicon.ico');
        document.head.appendChild(linkElement);
        break;
      }
      case 'tartu': {
        const linkElement = document.createElement('link');
        linkElement.setAttribute('rel', 'icon');
        linkElement.setAttribute('type', 'image/x-icon');
        linkElement.setAttribute('href', 'assets/img/tartu/favicon.png?v=2');
        document.head.appendChild(linkElement);
        break;
      }
      case 'greengas': {
        const linkElement = document.createElement('link');
        linkElement.setAttribute('rel', 'icon');
        linkElement.setAttribute('type', 'image/png');
        linkElement.setAttribute('href', 'assets/img/gaas/favicon.png?v=2');
        document.head.appendChild(linkElement);
        break;
      }
      case 'tallinn': {
        const linkElement = document.createElement('link');
        linkElement.setAttribute('rel', 'icon');
        linkElement.setAttribute('type', 'image/png');
        linkElement.setAttribute('href', 'assets/img/tallinn/favicon.png?v=2');
        document.head.appendChild(linkElement);
        break;
      }
      case 'mainor': {
        const linkElement = document.createElement('link');
        linkElement.setAttribute('rel', 'icon');
        linkElement.setAttribute('type', 'image/png');
        linkElement.setAttribute('href', 'assets/img/mainor/ylemiste-favicon-black.png?v=2');
        document.head.appendChild(linkElement);
        break;
      }
      case 'elering': {
        const linkElement = document.createElement('link');
        linkElement.setAttribute('rel', 'icon');
        linkElement.setAttribute('type', 'image/png');
        linkElement.setAttribute('href', 'assets/img/elering/favicon.png?v=2');
        document.head.appendChild(linkElement);
        break;
      }
    }

    if (!this.core.lazyLoadStyles) {
      return of(true);
    }

    const rnd = Math.floor(Math.random() * Math.pow(2, 32));
    if (themeName) {
      // cache stylesheets on init
      for (let i = 0; i < this.document.styleSheets.length; i++) {
        const stylesheet = this.document.styleSheets[i];
        if (stylesheet.href) {
          const hrefParts = stylesheet.href.split('/');
          const filename = hrefParts[hrefParts.length - 1];
          if (filename.startsWith('mat-theme.')) {
            stylesheet.disabled = true;
            continue;
          }
        }
        if (stylesheet.href) {
          const hrefParts = stylesheet.href.split('/');
          const filename = hrefParts[hrefParts.length - 1];
          if (filename.startsWith('styles.')) {
            stylesheet.disabled = true;
          }
        }
      }

      const preload = forkJoin([
        this.http.get('mat-theme-' + themeName + '.css?' + rnd, {responseType: 'text'}),
        this.http.get('mat-theme-' + themeName + '.css.map?' + rnd, {responseType: 'text'}),
        this.http.get('styles-' + themeName + '.css?' + rnd, {responseType: 'text'}),
        this.http.get('styles-' + themeName + '.css.map?' + rnd, {responseType: 'text'}),
      ]).pipe(map(() => true));
      this.loadStyle('mat-theme', 'mat-theme-' + themeName + '.css?' + rnd);
      this.loadStyle('styles-theme', 'styles-' + themeName + '.css?' + rnd);
      return preload;

      /*for (const checkTheme of ['telia', 'greengas', 'tallinn']) {
        if (checkTheme === themeName) {
          continue;
        }
        for (let i = 0; i < this.document.styleSheets.length; i++) {
          const stylesheet = this.document.styleSheets[i];
          if (stylesheet.href.endsWith('mat-theme-' + checkTheme + '.css')) {
            console.log('remove', 'mat-theme-' + checkTheme + '.css');
            stylesheet.disabled = true;
            continue;
          }
          if (stylesheet.href.endsWith('styles-' + checkTheme + '.css')) {
            stylesheet.disabled = true;
            continue;
          }
        }
      }
      return of(true);*/
    } else {
      // cache stylesheets on init
      const preload = forkJoin([
        this.http.get('mat-theme.css?' + rnd, {responseType: 'text'}),
        this.http.get('styles.css?' + rnd, {responseType: 'text'})
      ]).pipe(map(() => true));
      this.loadStyle('mat-theme', 'mat-theme.css?' + rnd);
      this.loadStyle('styles-theme', 'styles.css?' + rnd);
      return preload;
      // return of(true);
    }
  }

  loadStyle(stylesheetId: string, stylesheetName: string) {
    const head = this.document.getElementsByTagName('head')[0];

    const themeLink = this.document.getElementById(stylesheetId) as HTMLLinkElement;
    if (themeLink) {
      themeLink.href = stylesheetName;
    } else {
      const style = this.document.createElement('link');
      style.id = stylesheetId;
      style.rel = 'stylesheet';
      style.href = `${stylesheetName}`;

      head.appendChild(style);
    }
  }

  initGaTracking() {
    this.enableGaTracking();

    this.cookieConsentService.cookieConsent$.subscribe(() => {
      this.enableGaTracking();
    });
  }

  private enableGaTracking(): void {
    let allowed = this.cookieConsentService.isAnalyticsAllowed();
    if (!allowed) {
      return;
    }

    if (this.config?.gaTrackingId) {
      this.gtagService.init(this.config);
    }

  }

}
