import {Injectable, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs/internal/Subject';

@Injectable()
export class GroupsService implements OnDestroy {
  private ngDestroy = new Subject<void>();

  public constructor() {

  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  reload() {

  }
}
