import {Inject, Injectable} from '@angular/core';
import {CoreConfig, SMARTENCITY_CORE_CONFIG} from '@smartencity/core';
import {ArcGisRestService, OlMapWrapper, OlMapWrapperOptions} from '@smartencity/core';
import { BuildingsService } from './buildings.service';
import { EecBuildingsOlLayer } from '../layers/eec-buildings-ol-layer';
import { EecBuilding } from '../model/eec-building';
import { DisplaySettings } from '../model/display-settings';
import { TallinnArcGisMapService } from 'projects/arc-gis/src/lib/tallinn-arc-gis-map.service';
import { createStyleFunctionFromUrl } from "ol-esri-style";

@Injectable()
export class BuildingsOlMapService {

  public map: OlMapWrapper;

  public eecBuildingsLayer: EecBuildingsOlLayer = null;
  public displaySettings: DisplaySettings = this.buildingsService.getDisplaySettings();
  public eecBuildings: EecBuilding[];

  public arcGisMapLayers: Map<string, any> = new Map<string, any>([
    [
      "cityServiceOffices",
      {
        layerId: 6,
        name: "cityServiceOffices",
        getFeatureStyle: (url, layerId): Promise<Function> => {
          return createStyleFunctionFromUrl(url + "/" + layerId);
        },
      },
    ],
  ]);

  constructor(
    @Inject(SMARTENCITY_CORE_CONFIG) private config: CoreConfig,
    public buildingsService: BuildingsService,
    private arcGisClient: ArcGisRestService,
    private tallinnArcGisMapService: TallinnArcGisMapService,
  ) {
  }

  createMap(): void {
    const baseUrl = this.config.cityApiUrl + "/tallinn/map-server-proxy";
    const opts = new OlMapWrapperOptions();
    opts.baseMapServerUrl = baseUrl + '/rest/services/aluskaart_yldine/MapServer';

    this.map = new OlMapWrapper(this.arcGisClient, opts);
    this.tallinnArcGisMapService.addCityLayers(this.map);

    this.map.addFeatureServer(baseUrl + "/rest/services/Andmed_Tallinn", {
      layers: Array.from(this.arcGisMapLayers.values()),
      zIndexOffset: 500,
    });

    this.map.mapLoaded$.subscribe(() => {

      this.buildingsService.eecBuildings$.subscribe((items: EecBuilding[]) => {
        this.eecBuildings = items;
        if (this.eecBuildingsLayer) {
          this.eecBuildingsLayer.setMap(null);
        }
        this.eecBuildingsLayer = new EecBuildingsOlLayer(items);
        if (this.displaySettings.eecBuildings) {
          this.eecBuildingsLayer.setMap(this.map);
        }
      });
    });

    this.buildingsService.displaySettings$.subscribe(value => {
      this.displaySettings = value;
    });

    this.buildingsService.loadEecBuildings();
  }


  public setCenter(lat: number, lng: number) {
    // TODO: openlayers panto
    // this.map.panTo(new google.maps.LatLng(lat, lng));

  }

  updateDisplay() {
    if(this.eecBuildingsLayer) {
      let activeBuildings = [];
      for(let eecBuilding of this.eecBuildings) {
        if( this.displaySettings.eecBuildings[eecBuilding.energyClass] ) {
          activeBuildings.push(eecBuilding);
        }
      }
      this.eecBuildingsLayer.setEecBuildings(activeBuildings);
    }
  }
}
