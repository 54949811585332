<div class="modal-header">
  <h5 class="modal-title w-100" i18n>Notification address</h5>
  <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
    <span class="fal fa-times"></span>
  </button>
</div>
<div class="modal-body" [formGroup]="form">
  <div *ngIf="!form.valid && showErrors" class="alert alert-danger">
    <div i18n>Form is invalid</div>
  </div>

  <div class="form-group">
    <div class="form-row">
      <div class="col">
        <label [attr.for]="id + '-name'">Name</label>
        <input type="text" class="form-control" [class.is-invalid]="invalid('name')" [id]="id + '-name'" formControlName="name" placeholder="Name" i18n-placeholder>
        <small *ngIf="invalid('name') && errors('name')?.required" class="invalid-feedback d-block" i18n>Required</small>
      </div>
    </div>
  </div>

  <div class="form-group">
    <div class="form-row">
      <div class="col">
        <label [attr.for]="id + '-email'" i18n>E-mail</label>
        <input type="text" class="form-control" [class.is-invalid]="invalid('email')" [id]="id + '-email'" formControlName="email" placeholder="E-mail" i18n-placeholder>
        <small *ngIf="invalid('email') && errors('email')?.required" class="invalid-feedback d-block" i18n>Required</small>
      </div>
    </div>
  </div>

</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary btn-sm" aria-label="Close" (click)="close()" i18n>Close</button>
  <button type="button" class="btn btn-primary btn-sm" (click)="save()" i18n>Save</button>
</div>
