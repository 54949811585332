import {Inject, Injectable} from '@angular/core';
import {SMARTENCITY_CORE_CONFIG} from '../../../../core/src/lib/injection-tokens';
import {CoreConfig} from '../../../../core/src/lib/core-config.model';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {SourceOwnerSeries} from '../../../../core/src/lib/models/series';

@Injectable()
export class SourceApiService {

  constructor(@Inject(SMARTENCITY_CORE_CONFIG) private config: CoreConfig,
              private http: HttpClient) {
  }

  public create(data: any): Observable<SourceOwnerSeries> {

    return this.http.post<SourceOwnerSeries>(this.config.apiUrl + '/source/meta/person', data);
  }

  public addData(sourceId: number, data: {values: any[]}): Observable<void> {

    return this.http.post<void>(this.config.apiUrl + '/source/' + sourceId + '/data', data);
  }

}
