import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs/internal/Subject';
import {FormArray, FormGroup, ValidationErrors} from '@angular/forms';
import {ManageService} from '../manage.service';
import {takeUntil} from 'rxjs/operators';
import {QuestionnaireDetail} from '@smartencity/core';

const selector = 'mydata-question-form';
let nextId = 0;

@Component({
  selector: 'mydata-question-form',
  templateUrl: './question-form.component.html',
  styleUrls: ['./question-form.component.css']
})
export class QuestionFormComponent implements OnInit, OnDestroy {
  private ngDestroy = new Subject<void>();
  id = `${selector}-${nextId++}`;

  field;
  field$ = this.manageService.field$;
  questionnaire$ = this.manageService.questionneire$;
  questionnaire: QuestionnaireDetail;

  public formGroup: FormGroup;

  constructor(
    private manageService: ManageService
  ) {
  }

  ngOnInit(): void {
    this.questionnaire$.pipe(takeUntil(this.ngDestroy)).subscribe((q11e: QuestionnaireDetail) => {
      this.questionnaire = q11e;
    });
    this.field$.pipe(takeUntil(this.ngDestroy)).subscribe(field => {
      if (field.type === 'QUESTION') {
        this.field = field;
        this.formGroup = this.manageService.getCurrentFiledFormGroup();
      } else {
        this.field = null;
        this.formGroup = null;
      }
    });
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  control(name?: string) {
    if (!name) {
      return this.formGroup;
    }
    return this.formGroup.get(name);
  }

  invalid(name: string, formGroup?) {
    if (!formGroup) {
      formGroup = this.formGroup;
    }
    const control = formGroup.get(name);
    return control && control.invalid && (control.dirty || control.touched);
  }

  errors(name: string, formGroup?): ValidationErrors {
    if (!formGroup) {
      formGroup = this.formGroup;
    }
    const control = formGroup.get(name);
    return control ? control.errors : null;
  }

  public addSelectOption(selectOptions: FormArray) {
    selectOptions.push(this.manageService.createSelectOptionControl());
  }

  public removeSelectOption(selectOptions: FormArray, index: number) {
    selectOptions.removeAt(index);
    selectOptions.markAsTouched();
    if (selectOptions.length < 1) {
      this.addSelectOption((selectOptions));
    }
  }

  save(): void {
    this.formGroup.markAllAsTouched();
    this.formGroup.updateValueAndValidity();

    if (this.formGroup.invalid) {
      console.error('invalid', this.formGroup);
      return;
    }

    const value = this.formGroup.getRawValue();

    this.manageService.saveField(this.field, Object.assign(
      {
        type: 'QUESTION'
      },
      this.manageService.mapFieldFormValueToField(value)
    ));

    this.manageService.save();
  }

  async cancel() {
    try {
      await this.manageService.addField();
    } catch (e) {
      // ignore
    }
  }
}
