import { Injectable } from '@angular/core';
import {PeopleTrafficCounter} from '../model/people-traffic-counter';
import {Observable, ReplaySubject, takeUntil} from 'rxjs';
import {Subject} from 'rxjs/internal/Subject';
import {PeopleTrafficDataService} from './people-traffic-data.service';
import {ListResponse} from '@smartencity/core';

@Injectable()
export class PeopleTrafficService {

  protected finalize$ = new Subject<void>();

  public peopleTrafficCounters: PeopleTrafficCounter[] = [];

  protected peopleTrafficSource = new ReplaySubject<PeopleTrafficCounter[]>(1);
  public peopleTraffic$ = this.peopleTrafficSource.asObservable().pipe(takeUntil(this.finalize$));

  protected selectedPeopleTrafficCounterSource = new ReplaySubject<PeopleTrafficCounter>(1);
  public selectedPeopleTrafficCounter$ = this.selectedPeopleTrafficCounterSource.asObservable().pipe(takeUntil(this.finalize$));

  constructor(private peopleTrafficDataService: PeopleTrafficDataService) {
  }

  loadPeopleTraffic(): void {

    this.peopleTrafficDataService.getPeopleTraffic().pipe(takeUntil(this.finalize$)).subscribe((data: ListResponse<PeopleTrafficCounter>) => {
      this.peopleTrafficCounters = data.content;
      this.peopleTrafficSource.next(data.content);
    });
  }

  selectPeopleTrafficCounter(counter: PeopleTrafficCounter): void {
    this.selectedPeopleTrafficCounterSource.next(counter);
  }

  loadSeries(counter: PeopleTrafficCounter, params?: any): Observable<any> {
    return this.peopleTrafficDataService.loadSeries(counter, params);
  }

  clear(): void {
    this.finalize$.next();
    this.finalize$.complete();
    this.clearSelected();
  }

  public clearSelected() {
    this.peopleTrafficSource.next(null);
  }

}
