import {IPerson, Person} from './person';
import {User} from './user';

export interface IProfile {
  mandate: Mandate;
  availablePersons: MandatePerson[];
  availableMandates: MandatePerson[];
  token: string;
  user: AuthUser;
}

export class Profile implements IProfile {
  mandate: Mandate;
  availablePersons: MandatePerson[];
  availableMandates: MandatePerson[];
  token: string;
  user: AuthUser;

  // static fromPlainObject<T extends Profile>(this: new () => T, o: Object) {
  //   return Object.assign(new this(), o);
  // }

  constructor(props?: IProfile) {
    if (!props) return;
    Object.assign(this, props);
    // if (props.availableMandates) {
    //   this.availableMandates = [];
    //   props.availableMandates.forEach(m => {
    //     this.availableMandates.push(new MandatePerson(m));
    //   });
    // }
  }

}

export class Mandate {
  id: number;
  type: string;
  user: MandateUser;
  person: MandatePerson;
  permissions: MandatePermission[];
  createdByUser: Person;
  updatedByUser: Person;
  startAt: string;
  endAt: string;
  consents: MandateConsent[] = [];
}

export class ConsentPersonConsent {
  person: Person;
  type: string;
  name: string;
  personSeriesCount: number;
  startAt: string;
  createdAt: string;
  entryId: number;
  periodId: number;
}


export class MandateConsent {
  id: number;
  person: Person;
  createdByPerson: Person;
  createdByUser: MandateUser;
}

export class MandateUser extends User {
  countryCode: string;
  personalId: string;
  displayName: string;
  juridical: boolean;
  idPrivacyType: string;
  dob: string;
}

export interface IMandatePerson extends IPerson {
  email: string;
}

export class MandatePerson extends Person {
  email: string;

  blaa(): string {
    return this.email;
  }

  constructor(props?: IMandatePerson) {
    super(props);
    if (!props) return;
    if (props.email) {
      this.email = props.email;
    }
  }

}

export class MandatePermission {
  key: string;
  value: boolean;

  constructor (key: string, value: boolean) {
    this.key = key;
    this.value = value;
  }
}

export class AuthUser {
  countryCode: string;
  displayName: string;
  personalId: string;
  termsAccepted: boolean;
}
