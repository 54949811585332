
<h5 class="mb-0">{{locationName}}</h5>
<div class="pointer-info mt-3 pb-3">
  <div class="timeline">
    <div class="graph graph-bar">
      <div class="title" i18n>People count per hour</div>
      <div class="grid">
        <div class="bar" [ngStyle]="{'height': value.height + 'px'}" [tooltip]="value.title" *ngFor="let value of chartValues">
          <span class="bar-title smaller">{{value.time}}</span>
          <span class="bar-value smaller">{{value.peopleCount}}</span>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="d-flex justify-content-end">
  <span class="smaller" *ngIf="lastUpdatedAt" i18n>Last updated at: {{lastUpdatedAt|date:'dd.MM.yyyy HH:mm'}}</span>
</div>
