import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {DatapointGroupsListComponent} from './datapoint-groups-list/datapoint-groups-list.component';
import {DatapointGroupsSeriesListComponent} from './datapoint-groups-series-list/datapoint-groups-series-list.component';
import {DatapointGroupsIndexService} from './datapoint-groups-index.service';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {DatapointGroupsIndex} from './datapoint-groups-index.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CoreModule} from '@smartencity/core';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {PopoverModule} from 'ngx-bootstrap/popover';
import {RouterModule} from '@angular/router';
import { DatapointListManageModule } from '../datapoint-list-manage/datapoint-list-manage.module';
import { CurrentLocationService } from '../../services/current-location.service';
import { PersonSeriesListService } from '../../services/person-series-list.service';
import {DatapointGroupsSeriesListService} from '../../services/datapoints-groups-series-list/datapoint-groups-series-list.service';
import {DatapointGroupsIndexNavbarService} from './datapoint-groups-index-navbar.service';


@NgModule({
  declarations: [
    DatapointGroupsIndex,
    DatapointGroupsListComponent,
    DatapointGroupsSeriesListComponent
  ],
  providers: [
    DatapointGroupsIndexService,
    CurrentLocationService,
    PersonSeriesListService,
    DatapointGroupsSeriesListService,
    DatapointGroupsIndexNavbarService
  ],
    imports: [
        CoreModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        NgxDatatableModule,
        TooltipModule,
        BsDropdownModule,
        PopoverModule,
        RouterModule,
        DatapointListManageModule
    ]
})
export class DatapointGroupsIndexModule { }
