import { Injectable } from '@angular/core';

export interface StoredCompareItem {
  type: string,
  id: string
}
@Injectable({
  providedIn: 'root'
})
export class CompareItemStoreService {

  private compareItemsStoreKey = 'compare_items';

  constructor() { }

  public getStoredCompareItemsByType(type: string): StoredCompareItem[] {
    let storedCompareItemsAsString = localStorage.getItem(this.compareItemsStoreKey);
    let storedCompareItems: StoredCompareItem[] = [];
    if (storedCompareItemsAsString) {
      storedCompareItems = JSON.parse(storedCompareItemsAsString);
    }
    return storedCompareItems.filter((item: StoredCompareItem) => item.type === type);
  }

  private getStoredCompareItems(): StoredCompareItem[] {
    let storedCompareItemsAsString = localStorage.getItem(this.compareItemsStoreKey);
    let storedCompareItems: StoredCompareItem[] = [];
    if (storedCompareItemsAsString) {
      storedCompareItems = JSON.parse(storedCompareItemsAsString);
    }
    return storedCompareItems;
  }

  public addCompareItemToStore(type: string, id: string): void {
    let storedCompareItems = this.getStoredCompareItems();
    const idx = storedCompareItems.findIndex((item: StoredCompareItem) => item.type === type && item.id == id);
    if (idx === -1) {
      storedCompareItems.push({
        type: type,
        id: String(id)
      });
      localStorage.setItem(this.compareItemsStoreKey, JSON.stringify(storedCompareItems));
    }
  }

  public removeCompareItemFromStore(type: string, id: string): void {
    let storedCompareItems = this.getStoredCompareItems();
    const idx = storedCompareItems.findIndex((item: StoredCompareItem) => item.type === type && item.id == id);
    if (idx > -1) {
      storedCompareItems.splice(idx, 1);
      localStorage.setItem(this.compareItemsStoreKey, JSON.stringify(storedCompareItems));
    }
  }


}
