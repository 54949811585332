<div class="page-title-bar p-4">
  <div class="d-flex">
    <div class="col-auto pl-0 align-self-center">
      <a routerLink="/mydata/datapoints" class="text-nowrap"><i class="fal fa-angle-left"></i>&nbsp;<ng-container i18n>Back</ng-container></a>
    </div>
    <div class="col d-flex flex-column justify-content-center align-items-center">
      <h2 class="mb-1 d-flex align-items-center justify-content-center flex-wrap flex-md-nowrap text-break">
        <ng-container *ngFor="let l of locationPath">
          <a class="section-title" [routerLink]="null" (click)="navigate(l)">{{l?.getLocalName()}}</a>
          &nbsp;<span class="text-light px-3"><i class="fa fa-chevron-right"></i></span>
        </ng-container>
        <span>{{location?.getLocalName()}}</span>
      </h2>
      <span class="help-text">
        <ng-container i18n>{totalElements, plural, =1 {{{totalElements}} datapoint} other {{{totalElements}} datapoints}}</ng-container>
      </span>
    </div>
    <div class="col-auto pr-0 d-flex flex-row justify-content-center align-items-center">
        <div *ngIf="group && group.lastPeriod.ownerType == 'OWNER'" class="align-self-center" (click)="showGroupConsents()" container="body" tooltip="Shared data" i18n-tooltip>
          <share-person-item-list [persons]="groupConsentPersons"></share-person-item-list>
        </div>
        <div class="d-flex flex-row ml-5">
          <button *ngIf="group && group.lastPeriod.ownerType == 'OWNER'" type="button" class="btn btn-icon icon-md p-0 mr-2" (click)="shareGroup()" tooltip="Share group" placement="auto" container="body" i18n-tooltip><i class="fal fa-share"></i></button>
          <div class="nav-item dropdown" dropdown *ngIf="group && group.lastPeriod.ownerType == 'OWNER'">
            <button class="dropdown-toggle btn btn-icon icon-md p-0 mr-2 no-caret" aria-haspopup="true" aria-expanded="false" dropdownToggle>
              <i class="fal fa-edit"></i>
            </button>
            <div class="dropdown-menu dropdown-menu-right" *dropdownMenu>
              <a type="button" class="dropdown-item" routerLink="manage"><i class="fal fa-edit"></i><span i18n>Edit datapoints</span></a>
              <a type="button" class="dropdown-item" (click)="editGroup()"><i class="fal fa-edit"></i><span i18n>Edit group</span></a>
              <a type="button" class="dropdown-item" routerLink="parameters" *ngIf="location?.locationType !== 'Q11E'"><i class="fal fa-bell"></i><span i18n>Edit parameters</span></a>
            </div>
          </div>
        </div>
    </div>
  </div>
</div>
<div class="container-fluid p-4" #fixedNav [ngClass]="{'sticky': sticky, 'is-mobile': isMobile }">

  <div class="form-row align-items-center">
    <div class="col-12 mb-md-0 col-md-auto">
      <div role="group" class="btn-group">
        <button type="button" class="btn btn-outline-secondary btn-filter" [class.active]="view === 'chart-list'" (click)="setView('chart-list')"><i class="fa fa-chart-area"></i></button>
        <button type="button" class="btn btn-outline-secondary btn-filter" [class.active]="view === 'item-list'" (click)="setView('item-list')"><i class="fa fa-list"></i></button>
        <button type="button" class="btn btn-outline-secondary btn-filter" *ngIf="(subLocations$ | async)?.length" [class.active]="view === 'locations'" (click)="setView('locations')"><i class="fa fa-home"></i></button>
      </div>
    </div>
    <div class="col-lg-auto text-center col-sm-12" *ngIf="view === 'chart-list'">
      <ngx-mat-drp (selectedDateRangeChanged)="updateRange($event)" [options]="dateRangePickerOptions" #dateRangePicker></ngx-mat-drp>
    </div>

    <div class="col-auto d-flex align-items-center graph-filter" *ngIf="view === 'chart-list'">
      <label class="mr-2 mb-0 d-flex"><i class="fal fa-chart-area"></i></label>
      <ul class="nav nav-links nav-links-small justify-content-end">
        <li class="nav-item"><a class="nav-link" [class.active]="currentAggregationGroupingType == 'LIVE'" (click)="toggleAggregationGroupType('LIVE')" i18n>Live</a></li>
        <li class="nav-item separator"><span></span></li>
        <li class="nav-item"><a class="nav-link active" [class.active]="currentAggregationGroupingType == 'HOURLY'" (click)="toggleAggregationGroupType('HOURLY')" i18n>Hourly</a></li>
        <li class="nav-item separator"><span></span></li>
        <li class="nav-item"><a class="nav-link" [class.active]="currentAggregationGroupingType == 'DAILY'" (click)="toggleAggregationGroupType('DAILY')" i18n>Daily</a></li>
        <li class="nav-item separator"><span></span></li>
        <li class="nav-item"><a class="nav-link" [class.active]="currentAggregationGroupingType == 'MONTHLY'" (click)="toggleAggregationGroupType('MONTHLY')" i18n>Monthly</a></li>
        <li class="nav-item separator"><span></span></li>
        <li class="nav-item"><a class="nav-link disabled" [class.active]="currentAggregationGroupingType == 'YEARLY'" (click)="toggleAggregationGroupType('YEARLY')" i18n>Yearly</a></li>
      </ul>
    </div>

    <div class="col"></div>
    <div class="col-12 col-md-3 mt-2 mt-md-0">
      <div class="input-group form-search">
        <input type="text" class="form-control form-control-lg" placeholder="Type to search..." i18n-placeholder [formControl]="searchControl">
        <div class="input-group-append">
          <span class="clear-search" (click)="clearSearch()" [ngClass]="{'active': searchControl?.value?.length > 0 }">
            <i class="fal fa-times"></i>
          </span>
          <div class="custom-dropdown filter-dropdown" dropdown [autoClose]="true" (click)="$event.stopImmediatePropagation();">
            <button type="button" class="btn dropdown-toggle" dropdownToggle i18n>Filter</button>
            <div class="dropdown-menu dropdown-menu-right" *dropdownMenu [formGroup]="filterForm">
              <div class="accordion-group"  [ngClass]="'show'">
                <div class="accordion-header">
                  <!-- <i class="fal fa-angle-down"></i> -->
                  <ng-container i18n>Series type</ng-container>
                </div>
                <div class="accordion-content" *ngFor="let type of seriesTypeOptions; let i = index">
                    <div class="checkbox">
                      <input type="checkbox" [checked]="isTypeFiltered(type.value)" (change)="seriesTypeCheckboxChanged(type.value, $event)">
                      <label>{{type.label}}</label>
                    </div>
                </div>
              </div>
              <div class="accordion-group"  [ngClass]="'show'">
                <div class="accordion-header" *ngIf="isCityUser">
                  <i class="fal fa-angle-down"></i>
                  <ng-container i18n>Show on city portal</ng-container>
                </div>
                <div class="accordion-content" *ngIf="isCityUser">
                  <div class="checkbox">
                    <input type="checkbox" formControlName="showOnCityPortalTrue">
                    <label i18n>Yes</label>
                  </div>
                  <div class="checkbox">
                    <input type="checkbox" formControlName="showOnCityPortalFalse">
                    <label i18n>No</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <span class="input-group-text">
            <i class="fal fa-search"></i>
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="form-row mb-2" *ngIf="filterForm.get('seriesTypes').value?.length">
    <div class="d-flex justify-content-center flex-wrap flex-md-nowrap">
      <label i18n>Filter</label>
      <span class="filter-tag" *ngFor="let st of filterForm.get('seriesTypes').value">{{st | label}} <i class="fal fa-times" (click)="clearSeriesTypeFilter(st)"></i></span>
    </div>
  </div>
</div>
<div class="container-fluid">
  <location-datapoints-list [view]="view" *ngIf="view === 'chart-list' || view === 'item-list'"></location-datapoints-list>
  <location-datapoints-locations *ngIf="view === 'locations'"></location-datapoints-locations>
</div>
