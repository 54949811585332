import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Location} from '@smartencity/core';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {MyDataConfig} from '../../mydata-config.model';
import {SMARTENCITY_MYDATA_CONFIG} from '../../injection-tokens';
import {Subject} from 'rxjs/internal/Subject';
import {CurrentLocationService} from '../../services/current-location.service';
import {takeUntil} from 'rxjs/operators';
import {PersonSeriesListService} from '../../services/person-series-list.service';

@Component({
  selector: 'mydata-location-manage-series',
  templateUrl: './datapoint-list-manage.component.html',
  providers: [CurrentLocationService, PersonSeriesListService]
})
export class DatapointListManageComponent implements OnInit, OnDestroy {
  private ngDestroy = new Subject<void>();

  location: Location = null;
  pageResponse$ = this.personSeriesService.pageResponse$;
  datapointCount = 0;

  constructor(
    private http: HttpClient,
    private router: Router,
    @Inject(SMARTENCITY_MYDATA_CONFIG) private config: MyDataConfig,
    private currentLocationService: CurrentLocationService,
    private personSeriesService: PersonSeriesListService
  ) {
    this.currentLocationService.currentLocation$.pipe(takeUntil(this.ngDestroy)).subscribe((location: Location) => {
      if (!location) {
        this.router.navigate(['/mydata']);
      }

      this.location = location;
    });
    this.pageResponse$.pipe(takeUntil(this.ngDestroy)).subscribe(page => {
      this.datapointCount = page.totalElements;
    });
  }

  ngOnDestroy() {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  ngOnInit() {

  }
}
