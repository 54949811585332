import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BsModalRef} from 'ngx-bootstrap/modal';
import { IWidgetDataset, Widget, WidgetDataset, WidgetType} from '../widget';
import {WidgetService} from '../widget.service';
import {Dataset} from '@smartencity/core';
import {ToastrService} from 'ngx-toastr';
import {moveItemInArray} from '@angular/cdk/drag-drop';
import {WidgetDataService} from '../../../../../../core/src/lib/services/widget-data.service';
import {WidgetRandomDataService} from '../widget-random-data.service';
import {DashboardFilter, OwnerType} from "../dashboard";
import {AbstractWidgetModal} from '../abstract-widget-modal';
import {Observable} from 'rxjs/internal/Observable';
import { WidgetApiService } from '../../../http/widget-api.service';
import { DashboardApiService } from '../../../http/dashboard-api.service';

const selector = 'mydata-widget-modal';
let nextId = 0;
@Component({
  selector: selector,
  templateUrl: './widget-modal.component.html',
  styles: [':host {display: flex; flex-direction: column; min-height: 100vh;}'],
  providers: [
    { provide: WidgetDataService, useClass: WidgetRandomDataService },
  ]
})
export class WidgetModalComponent extends AbstractWidgetModal implements OnInit, OnDestroy {

  id = `${selector}-${nextId++}`;

  public widgetTypeType = WidgetType;

  @Input()
  public widget: Widget;

  @Input()
  public dashboardFilter: DashboardFilter;

  @Input()
  public template = false;

  public onHide: () => void;

  public validated = false;

  public OwnerType = OwnerType;

  private datasetsInitialLoad = true;

  constructor(
    protected http: HttpClient,
    protected toastr: ToastrService,
    private modalRef: BsModalRef,
    public dashboardApiService: DashboardApiService,
    protected widgetApiService: WidgetApiService,
    protected widgetService: WidgetService
  ) {
    super(widgetService, toastr, widgetApiService, dashboardApiService);
  }

  ngOnInit(): void {
    this.buildForm();
  }

  addData() {
    this.mode = 'add-datasets';
  }

  editData() {
    this.mode = 'edit-datasets';
  }

  addCurrentPageDatasets(): void {
    if (!this.page) {
      return;
    }

    for (const dataset of this.page.content) {
      this.addDataSet(dataset);
    }
  }

  createWidgetDataset(dataset: Dataset): IWidgetDataset {
    const wd = new WidgetDataset(dataset);
    wd.groupName = 'default';
    wd.sortOrder = this.widget.datasets.length + 1;
    wd.q11eFieldId = dataset.q11eFieldId;
    wd.q11eResponseFieldId = dataset.q11eResponseFieldId;

    return wd;
  }

  public close(): void {
    this.modalRef.hide();
    this.onHide();
  }

  public saveWidget(widget: any): Observable<any> {
    return this.widgetApiService.update(widget);
  }

  public createWidget(widget: any): Observable<any> {
    return this.widgetApiService.save(widget);
  }

  public mapFormValueToWidget(value: any) {

    let aggregationGroupingType = value.aggregationGroupingType,
      periodType = value.periodType,
      periodCount = value.periodCount;

    if (value.timeframeType === 'DATE_RANGE') {
      aggregationGroupingType = null;
      periodType = null;
      periodCount = null;
    }

    return {
      name: value.name,
      type: value.type,
      dashboard: {id: value.dashboardId},
      component: null,
      chartType: value.chartType,
      showLegend: value.showLegend,
      showYAxes: value.showYAxes,
      showAverageLine: value.showAverageLine,
      showDataLabels: value.showDataLabels,
      aggregationGroupingType: aggregationGroupingType,
      chartColorScheme: value.chartColorScheme,
      periodType: periodType,
      periodCount: periodCount,
      differentiate: value.differentiate,
      timeframeType: value.timeframeType,
      periodStartAt: value.periodStartAt,
      periodEndAt: value.periodEndAt
    };
  }

  drop(event) {
    moveItemInArray(this.rows, event.previousIndex, event.currentIndex);
    moveItemInArray(this.widgetDatasetArray.controls, event.previousIndex, event.currentIndex);
  }

  filterSelectedDatasetTypes(selected: string[]): string[] {
    return selected;
  }

  preparePageFilter(filter: any): void {
  }

  public afterUpdateSelectedSeriesIdSet(): void {
    if (!this.datasetsInitialLoad) {
      this.form.markAsDirty();
    }

    if (this.datasetsInitialLoad) {
      this.datasetsInitialLoad = false;
    }
  }
}
