import {BusOverlay} from './bus-overlay';
import {Bus} from '../model/bus';
import {NgZone} from '@angular/core';
import {MobilityService} from '../service/mobility.service';
import {MobilityGmapService} from '../service/mobility-gmap.service';

export class BusesLayer {
  private mobilityService: MobilityService;
  private mobilityMapService: MobilityGmapService;
  private map: google.maps.Map;
  private ngZone: NgZone;

  private selectedBus: Bus = null;
  public busMarkers: BusOverlay[] = [];

  constructor(map: google.maps.Map,
              mobilityService: MobilityService,
              mobilityMapService: MobilityGmapService,
              ngZone: NgZone) {
    this.mobilityService = mobilityService;
    this.mobilityMapService = mobilityMapService;
    this.map = map;
    this.ngZone = ngZone;
  }

  setMap(map: any) {
    this.map = map;

    this.busMarkers.forEach(value => {
      value.setMap(this.map);
    });
  }

  public clearSelection() {
    this.selectedBus = null;
    this.busMarkers.forEach(value => {
      value.selected = false;
    });
  }

  public selectBus(bus: Bus) {
    this.selectedBus = bus;
    this.busMarkers.forEach(value => {
      value.selected = value.bus.id === (bus ? bus.id : null);
    });
  }

  repositionBuses() {
    this.busMarkers.forEach((overlay: BusOverlay) => {
      overlay.reposition();
    });
  }

  showBuses(ids?: number[]) {
    let fullList = this.mobilityService.buses;
    if (fullList == null) {
      fullList = [];
    }
    const buses = [];
    fullList.forEach(value => {
      if (ids == null || ids.indexOf(value.id) > -1) {
        buses.push(value);
      }
    });

    const oldList = this.busMarkers.map(value => value);
    this.busMarkers.length = 0;
    for (const marker of oldList) {
      let bus = null;
      for (const b of buses) {
        if (marker.bus.id === b.id) {
          bus = b;
        }
      }
      // update
      if (bus != null) {
        marker.update(bus);
        this.busMarkers.push(marker);
      }
      // bus not found => delete
      if (bus == null) {
        marker.setMap(null);
      }
    }
    // add
    for (const bus of buses) {
      let oldMarker = null;

      for (const marker of oldList) {
        if (marker.bus.id === bus.id) {
          oldMarker = marker;
        }
      }
      if (oldMarker == null) {
        const marker = new BusOverlay(bus, this.map, this.mobilityMapService, this.ngZone);
        if (this.selectedBus && this.selectedBus.id === bus.id) {
          marker.selected = true;
        }
        this.busMarkers.push(marker);
      }
    }

  }
}
