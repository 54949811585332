import { Inject, Injectable } from '@angular/core';
import { takeUntil, ReplaySubject } from 'rxjs';
import { CityServicesApiService, KeywordMatcher, ListResponse } from '@smartencity/core';
import { Subject } from 'rxjs/internal/Subject';
import {
  RoadClosure,
  RoadClosureClickEvent,
  RoadClosureSelectType
} from '../model/road-closure';
import { BicycleStation, BicycleStationsPage } from '../component/bicycle-stations-heatmap/bicylce-stations.model';
import { Bus, BusResponse } from '../model/bus';
import { Stop, StopResponse } from '../model/stop';
import { ThroughputPoint, ThroughputResponse } from '../model/traffic';
import { EvCharger, EvChargerResponse, EvChargersResponse } from '../model/ev-charger';
import { EvChargerDk } from '../model/ev-charger-dk';
import { MobilityConfig, MobilityDisplaySettings } from '../mobility-config.model';
import { RoadClosuresResponse } from '../../../../tallinn/src/lib/service/tallinn.service';
import { SMARTENCITY_MOBILITY_CONFIG } from '../injection-tokens';
import { MobilityApiService } from '../http/mobility-api.service';
import { PeopleTrafficCounter } from '../model/people-traffic-counter';

@Injectable()
export class MobilityService {
  private finalize$ = new Subject<void>();

  public buses: Bus[] = [];
  public stops: Stop[] = [];
  public throughputPoints: ThroughputPoint[] = [];
  public evChargers: EvCharger[] = [];
  public evChargersDk: EvChargerDk[] = [];
  public bicycleStations: BicycleStation[] = [];
  public allBicycleStations: BicycleStation[] = [];
  public roadClosures: RoadClosure[] = [];


  private stopsSource = new ReplaySubject<Stop[]>(1);
  public stops$ = this.stopsSource.asObservable().pipe(takeUntil(this.finalize$));

  private busesSource = new ReplaySubject<Bus[]>(1);
  public buses$ = this.busesSource.asObservable().pipe(takeUntil(this.finalize$));

  private selectedBusSource = new ReplaySubject<Bus>(1);
  public selectedBus$ = this.selectedBusSource.asObservable().pipe(takeUntil(this.finalize$));

  private selectedStopSource = new ReplaySubject<Stop>(1);
  public selectedStop$ = this.selectedStopSource.asObservable().pipe(takeUntil(this.finalize$));

  private selectedEvChargerSource = new ReplaySubject<EvCharger>(1);
  public selectedEvCharger$ = this.selectedEvChargerSource.asObservable().pipe(takeUntil(this.finalize$));

  private selectedEvChargerDkSource = new ReplaySubject<EvChargerDk>(1);
  public selectedEvChargerDk$ = this.selectedEvChargerDkSource.asObservable().pipe(takeUntil(this.finalize$));

  private selectedThroughputPointSource = new ReplaySubject<ThroughputPoint>(1);
  public selectedThroughputPoint$ = this.selectedThroughputPointSource.asObservable().pipe(takeUntil(this.finalize$));

  private selectedBicycleStationSource = new ReplaySubject<BicycleStation>(1);
  public selectedBicycleStation$ = this.selectedBicycleStationSource.asObservable().pipe(takeUntil(this.finalize$));

  public selectedRoadClosureSource = new ReplaySubject<RoadClosure>(1);
  public selectedRoadClosure$ = this.selectedRoadClosureSource.asObservable().pipe(takeUntil(this.finalize$));

  public roadClosuresSource = new ReplaySubject<RoadClosure[]>(0);
  public roadClosures$ = this.roadClosuresSource.asObservable().pipe(takeUntil(this.finalize$));

  public bicycleStationsSource = new ReplaySubject<any[]>(1);
  public bicycleStations$ = this.bicycleStationsSource.asObservable().pipe(takeUntil(this.finalize$));

  private throughputPointsSource = new ReplaySubject<ThroughputPoint[]>(1);
  public throughputPoints$ = this.throughputPointsSource.asObservable().pipe(takeUntil(this.finalize$));

  public roadClosuresSelectedSource = new ReplaySubject<RoadClosure>(1);
  public roadClosuresSelected$ = this.roadClosuresSelectedSource.asObservable().pipe(takeUntil(this.finalize$));

  private roadClosureClickEventSource = new ReplaySubject<RoadClosureClickEvent>(1);
  public roadClosuresClickEvent$ = this.roadClosureClickEventSource.asObservable().pipe(takeUntil(this.finalize$));

  public selectedClearedSource = new ReplaySubject<void>(1);
  public selectedCleared$ = this.selectedClearedSource.asObservable().pipe(takeUntil(this.finalize$));

  public locationsSelectedSource = new ReplaySubject<any[]>(1);
  public locationsSelected$ = this.locationsSelectedSource.asObservable().pipe(takeUntil(this.finalize$));

  public locationDeselectedSource = new ReplaySubject<string>(1);
  public locationsDeselected$ = this.locationDeselectedSource.asObservable().pipe(takeUntil(this.finalize$));

  public visibleBusesSource = new ReplaySubject<number[]>(1);
  public visibleBuses$ = this.visibleBusesSource.asObservable().pipe(takeUntil(this.finalize$));

  public visibleStopsSource = new ReplaySubject<string[]>(1);
  public visibleStops$ = this.visibleStopsSource.asObservable().pipe(takeUntil(this.finalize$));

  public evChargersDkSource = new ReplaySubject<EvChargerDk[]>(1);
  public evChargersDk$ = this.evChargersDkSource.asObservable().pipe(takeUntil(this.finalize$));

  public evChargersSource = new ReplaySubject<EvCharger[]>(1);
  public evChargers$ = this.evChargersSource.asObservable().pipe(takeUntil(this.finalize$));

  public allBicycleStationsSource = new ReplaySubject<BicycleStation[]>(1)
  public allBicycleStations$ = this.allBicycleStationsSource.asObservable().pipe(takeUntil(this.finalize$));

  private stopsClearSource = new ReplaySubject<void>(1);
  public stopsClear$ = this.stopsClearSource.asObservable().pipe(takeUntil(this.finalize$));

  private busesClearSource = new ReplaySubject<void>(1);
  public busesClear$ = this.busesClearSource.asObservable().pipe(takeUntil(this.finalize$));

  private displaySettings: MobilityDisplaySettings = <MobilityDisplaySettings>{};
  private displaySettingsSource = new ReplaySubject<MobilityDisplaySettings>(1);
  displaySettings$ = this.displaySettingsSource.asObservable().pipe(takeUntil(this.finalize$));

  trafficThroughputLoaded = false;
  evChargersDkLoaded = false;
  roadClorusersLoaded = false;

  constructor(
    @Inject(SMARTENCITY_MOBILITY_CONFIG) private config: MobilityConfig,
    private mobilityApiService: MobilityApiService,
    private cityServiceApiService: CityServicesApiService
  ) { }

  getDisplaySettings(): MobilityDisplaySettings {
    if (!this.config.displaySettings) {
      return this.displaySettings;
    }

    const displaySettings = Object.create(this.config.displaySettings);
    this.displaySettingsSource.next(displaySettings);
    this.displaySettings = displaySettings;

    return this.displaySettings;
  }

  updateDisplaySettings(displaySettings: MobilityDisplaySettings): void {
    this.displaySettings = displaySettings;
    this.displaySettingsSource.next(this.displaySettings);
  }

  selectBus(bus: Bus): void {
    this.selectedBusSource.next(bus);
  }

  selectStop(stop: Stop): void {
    this.selectedStopSource.next(stop);
  }

  selectEvCharger(evCharger: EvCharger): void {
    this.selectedEvChargerSource.next(evCharger);
  }

  selectEvChargerDk(evCharger: EvChargerDk): void {
    this.selectedEvChargerDkSource.next(evCharger);
  }

  selectThroughputPoint(point: ThroughputPoint): void {
    this.selectedThroughputPointSource.next(point);
  }

  selectBicycleStation(station: BicycleStation): void {
    this.selectedBicycleStationSource.next(station);
  }

  selectRoadClosure(roadClosure: RoadClosure, type: RoadClosureSelectType): void {
    this.roadClosureClickEventSource.next({
      roadClosure: roadClosure,
      type: type
    });
  }

  deselectRoadClosure(): void {
    this.roadClosureClickEventSource.next({
      type: 'deselect'
    });
  }

  deselectLocations(locationType: string): void {
    this.locationDeselectedSource.next(locationType);
  }

  loadAllBicycleStations(): void {

    this.mobilityApiService.getBicycleStations({ size: 2000, page: 0, sort: 'personSeriesId' }).subscribe((data: BicycleStationsPage) => {
      this.allBicycleStations = data.content;
      this.allBicycleStationsSource.next(data.content);
    });
  }

  loadBicycleStations(): void {
    this.mobilityApiService.getBicycleStations().subscribe(
      (data: any) => {
        this.bicycleStations = data.content;
        this.bicycleStationsSource.next(data.content);
      },
      (err: any) => { }
    );
  }

  loadRoadClosures(): void {
    //FIXME: mingil põhjusel emititakse road clsoures topelt, ajutine workaround
    if (this.roadClorusersLoaded) return;
    this.roadClorusersLoaded = true;

    this.mobilityApiService.getRoadClosures().pipe(takeUntil(this.finalize$)).subscribe((data: RoadClosuresResponse) => {
      this.roadClosures = data.roadClosures;
      this.roadClosuresSource.next(data.roadClosures);
    });
  }

  loadElectricVehicleChargers(): void {
    this.mobilityApiService.getEvChargers().subscribe((data: EvChargersResponse) => {
      this.evChargers = this.mapEvChargers(data.content);
      this.evChargersSource.next(this.evChargers);
    });
  }

  loadEvChargersDk(): void {
    if (this.evChargersDkLoaded) return;
    this.evChargersDkLoaded = true;

    this.cityServiceApiService.getCityServices().subscribe((data: any) => {
      this.evChargersDk = data.evChargers.map((e: any) => ({
        id: e.id,
        uuid: e.uuid,
        lat: e.lat,
        lng: e.lng,
        address: e.address,
        name: e.name,
        status: e.status,
        nConnectors: e.connectors ? e.connectors.length : 0,
        nAvailable: e.connectors ? e.connectors.reduce((a: number, v: any) => a + (v.status === 'AVAILABLE' ? 1 : 0), 0) : 0
      }));
      this.evChargersDkSource.next(this.evChargersDk);
    });
  }


  loadBuses(cb?: (data: BusResponse) => void): void {

    this.mobilityApiService.getBuses().pipe(takeUntil(this.finalize$)).subscribe((data: BusResponse) => {
      this.buses = data.buses;
      this.busesSource.next(data.buses);

      if (cb) {
        cb(data);
      }
    });
  }

  loadStops(): void {
    this.mobilityApiService.getStops().subscribe((data: StopResponse) => {
      this.stops = data.stops;
      this.stopsSource.next(this.stops);
    });
  }

  loadTrafficThroughput(): void {
    if (this.trafficThroughputLoaded) return;
    this.trafficThroughputLoaded = true;

    this.mobilityApiService.getTrafficThroughput().subscribe((data: ThroughputResponse) => {
      this.throughputPoints = data.content;
      this.throughputPointsSource.next(data.content);
    });
  }


  public searchBusesByKeyword(keyword: string): Bus[] {
    const filtered: Bus[] = [];
    this.buses.forEach(bus => {
      if (this.keywordMatch(bus.description, keyword) || this.keywordMatch(bus.route, keyword)) {
        filtered.push(bus);
      }
    });

    return filtered;
  }

  public searchStopsByKeyword(keyword: string): Stop[] {
    const filtered: Stop[] = [];
    this.stops.forEach(stop => {
      if (this.keywordMatch(stop.title, keyword)) {
        filtered.push(stop);
      }
    });

    return filtered;
  }

  public searchTrafficPointsByKeyword(keyword: string): ThroughputPoint[] {
    const filtered: ThroughputPoint[] = [];
    this.throughputPoints.forEach(throughputPoint => {
      if (this.keywordMatch(throughputPoint.name, keyword)) {
        filtered.push(throughputPoint);
      }
    });
    return filtered;
  }

  public searchEvChargersByKeyword(keyword: string): EvCharger[] {
    let filtered: EvCharger[] = [];
    this.evChargers.forEach(evCharger => {
      if (this.keywordMatch(evCharger.name, keyword)) {
        filtered.push(evCharger);
      }
    });

    return filtered;
  }

  public searchEvChargersDkByKeyword(keyword: string): EvChargerDk[] {
    let filtered: EvChargerDk[] = [];
    this.evChargersDk.forEach(evCharger => {
      if (this.keywordMatch(evCharger.name, keyword)) {
        filtered.push(evCharger);
      }
    });

    return filtered;
  }

  public searchBicycleStationsByKeyword(keyword: string): BicycleStation[] {
    let filtered: BicycleStation[] = [];
    this.allBicycleStations.forEach(station => {
      if (this.keywordMatch(station.address, keyword)) {
        filtered.push(station);
      }
    });

    return filtered;
  }

  private keywordMatch(source: string, target: string): boolean {
    return KeywordMatcher.matches(source, target);
  }

  mapEvChargers(data: EvChargerResponse[]): EvCharger[] {
    const evChargersMap: any = {};

    data.forEach((value: EvChargerResponse, i) => {
      const key = value.lat + '-' + value.lng;
      let evCharger = evChargersMap[key];
      if (!evCharger) {
        evCharger = new EvCharger();
        evCharger.lat = value.lat;
        evCharger.lng = value.lng;
        evCharger.name = value.name;
        evCharger.updatedAt = value.updatedAt;
        evChargersMap[key] = evCharger;
      }

      if (value.readableAddress) {
        evCharger.name = value.readableAddress;
      }

      evCharger.series.push({
        value: value.value,
        unit: value.unit
      });
    });

    const result: EvCharger[] = [];
    for (const key of Object.keys(evChargersMap)) {
      result.push(evChargersMap[key]);
    }

    return result;
  }

  clear(): void {
    this.finalize$.next();
    this.finalize$.complete();
    this.clearSelected();
  }

  hideBuses(): void {
    this.visibleBusesSource.next(null);
    this.busesClearSource.next();
  }

  hideStops(): void {
    this.visibleStopsSource.next(null);
    this.visibleBusesSource.next(null); // Näitab kõiki busse???
    this.stopsClearSource.next();
  }

  clearSelected(): void {
    this.selectedEvChargerSource.next(null);
    this.selectedEvChargerDkSource.next(null);
    this.selectedStopSource.next(null);
    this.selectedBusSource.next(null);
    this.selectedClearedSource.next(null);
  }

}
