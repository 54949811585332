import Marker = google.maps.Marker;

export class MyLocationButton {

  map: google.maps.Map;
  animationInterval: any;
  public marker: Marker = null;

  constructor(map) {

    this.map = map;

    var controlDiv = document.createElement('div');

    var firstChild = document.createElement('button');
    firstChild.style.backgroundColor = '#fff';
    firstChild.style.border = 'none';
    firstChild.style.outline = 'none';
    firstChild.style.width = '40px';
    firstChild.style.height = '40px';
    firstChild.style.borderRadius = '2px';
    firstChild.style.boxShadow = '0 1px 4px rgba(0,0,0,0.3)';
    firstChild.style.cursor = 'pointer';
    firstChild.style.marginRight = '10px';
    firstChild.style.padding = '0';
    firstChild.title = 'Your Location';
    controlDiv.appendChild(firstChild);

    var secondChild = document.createElement('div');
    secondChild.style.margin = '10px';
    secondChild.style.width = '18px';
    secondChild.style.height = '18px';
    secondChild.style.backgroundImage = 'url(https://maps.gstatic.com/tactile/mylocation/mylocation-sprite-2x.png)';
    secondChild.style.backgroundSize = '180px 18px';
    secondChild.style.backgroundPosition = '0 0';
    secondChild.style.backgroundRepeat = 'no-repeat';
    firstChild.appendChild(secondChild);

    google.maps.event.addListener(this.map, 'center_changed', () => {
      secondChild.style['background-position'] = '0 0';
    });

    firstChild.addEventListener('click', () => {

      if (this.animationInterval) {
        clearInterval(this.animationInterval);
      }

      var imgX = 0;
      this.animationInterval = setInterval(() => {
        imgX = -imgX - 18;
        secondChild.style['background-position'] = imgX + 'px 0';
      }, 500);

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
            var latlng = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
            console.log(latlng);
            this.map.setZoom(22);
            this.map.setCenter(latlng);

            if (this.marker == null) {
              this.marker = new Marker();
              this.marker.setMap(this.map);
            }
            this.marker.setPosition(latlng);

            clearInterval(this.animationInterval);
            secondChild.style['background-position'] = '-144px 0';
          }, (error: GeolocationPositionError) => {
            if (error.code == error.POSITION_UNAVAILABLE) {
              console.error("POSITION_UNAVAILABLE")
            } else if (error.code == error.PERMISSION_DENIED) {
              console.error("PERMISSION_DENIED")
            } else if (error.code == error.TIMEOUT) {
              console.error("TIMEOUT")
            }
            clearInterval(this.animationInterval);
            secondChild.style['background-position'] = -18 + 'px 0';
          }
        );
      } else {
        console.error("No navigator.geolocation");
        clearInterval(this.animationInterval);
        secondChild.style['background-position'] = '0 0';
      }
    });
    this.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(controlDiv);
  }
}
