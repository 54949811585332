import {Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output} from '@angular/core';

import {HttpClient, HttpXhrBackend} from '@angular/common/http';
import {SMARTENCITY_MYDATA_CONFIG} from '../../../injection-tokens';
import {MyDataConfig} from '../../../mydata-config.model';
import {FormControl, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {
  CityApiService,
  Mandate,
  Person,
  PersonSeries,
  SeriesType,
  SourceOwnerSeries,
  AuthService,
  EnumsService,
  PageResponse,
  LocationType,
  SMARTENCITY_CORE_CONFIG,
  PersonSeriesGroup,
  CustomFormValidators,
  ShowCityPortalRequest, NumberFormatConstants, MandatePerson, PreventCloseAware, ConfirmModalComponent, PersonSeriesApiService
} from '@smartencity/core';
import {map, startWith, switchMap, takeUntil} from 'rxjs/operators';
import {RequireCoords} from '../../../validators/require-coords';
import {RequireUuid} from '../../../validators/require-uuid';
import {RequireAddress} from '../../../validators/require-address';
import {LocationMapComponent} from '../location-map/location-map.component';
import {Subject} from 'rxjs/internal/Subject';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Observable} from 'rxjs/internal/Observable';
import {ToastrService} from 'ngx-toastr';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';
import {DataOwnerSeriesService} from '../../../../../../core/src/lib/services/data-owner-series.service';
import {forkJoin} from 'rxjs';
import {of} from 'rxjs/internal/observable/of';
import {GroupingTagService} from "../../../services/grouping-tag.service";
import {GoogleMapsApiService} from '../../../../../../core/src/lib/services/google-maps-api.service';

const selector = 'mydata-series-edit-modal';
let nextId = 0;

@Component({
  selector: selector,
  templateUrl: './series-edit-modal.component.html',
  providers: [GroupingTagService],
})
export class SeriesEditModalComponent implements OnInit, OnDestroy, PreventCloseAware {
  id = `${selector}-${nextId++}`;
  private ngDestroy = new Subject<void>();
  private currentPerson: MandatePerson;

  public isCityUser = false;

  public progress = false;
  public showErrors = false;
  public cityPortalCoordsChanged = false;
  private personSeriesOnSameCoords: PersonSeries[] = [];
  public form: FormGroup = null;

  @Output('saved')
  savedEmitter: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  sourceOwnerSeries: SourceOwnerSeries;

  @Input()
  group?: PersonSeriesGroup;

  editMaxFractionDigits = false;

  defaultNumberFormat = NumberFormatConstants.DEFAULT_NUMBER_FORMAT;

  public static formDefault(): FormGroup {
    return new FormGroup({
      name: new FormControl('', [Validators.required]),
      description: new FormControl(''),
      seriesType: new FormControl('OTHER', [Validators.required]),
      numberFormat: new FormGroup({
        maxFractionDigits: new FormControl(NumberFormatConstants.DEFAULT_NUMBER_FORMAT.defaultFractionDigits, [
            Validators.required,
            Validators.min(NumberFormatConstants.DEFAULT_NUMBER_FORMAT.minFractionDigits),
            Validators.max(NumberFormatConstants.DEFAULT_NUMBER_FORMAT.maxFractionDigits)
          ]),
      }),
      locationType: new FormControl('ADDRESS', [Validators.required]),
      uuid: new FormControl('', [RequireUuid, CustomFormValidators.uuidMinLength(3)]),
      lat: new FormControl('', [RequireCoords, CustomFormValidators.latitude]),
      lng: new FormControl('', [RequireCoords, CustomFormValidators.longitude]),
      adsOid: new FormControl(''),
      address: new FormControl('', [RequireAddress]),
      apartment: new FormControl(''),
      room: new FormControl(''),
      showOnCityPortal: new FormControl(false),
      cityPortalType: new FormControl(null),
      readableAddress: new FormControl(''),
      aggregationGroupingType: new FormControl(null),
      differentiate: new FormControl(null),
      bulkEditAgreement: new FormControl(null),
      groups: new FormControl([], [Validators.required])
    });
  }

  public seriesTypes: any[] = [];
  public cityPortalTypes: string[] = [];

  public aggregationGroupingTypeOptions = [
    {value: null, label: $localize`Live`},
    {value: 'HOURLY', label: $localize`Hourly`},
    {value: 'DAILY', label: $localize`Daily`},
    {value: 'MONTHLY', label: $localize`Monthly`},
    {value: 'YEARLY', label: $localize`Yearly`}
  ];

  constructor(
    private http: HttpClient,
    @Inject(SMARTENCITY_MYDATA_CONFIG) private config: MyDataConfig,
    @Inject(SMARTENCITY_CORE_CONFIG) private coreConfig: MyDataConfig,
    private toastr: ToastrService,
    private modalService: BsModalService,
    private ngbModalService: NgbModal,
    public authService: AuthService,
    public cityService: CityApiService,
    public modalRef: BsModalRef,
    private enumsService: EnumsService,
    private dataOwnerSeriesService: DataOwnerSeriesService,
    private personSeriesService: PersonSeriesApiService,
    public groupingTagService:GroupingTagService,
    private googleMapsApi: GoogleMapsApiService
  ) {
    const showOnCityPortal = this.coreConfig.defaultPreferences ? this.coreConfig.defaultPreferences.showOnCityPortal : true;
    this.authService.currentMandate$.pipe(takeUntil(this.ngDestroy)).subscribe((mandate: Mandate) => {
      this.currentPerson = mandate.person;
      this.isCityUser = showOnCityPortal && this.config.cityRegistrationCode === this.currentPerson.registrationNumber;
    });

    this.seriesTypes = this.enumsService.getOptionsList(SeriesType.types);
    this.cityPortalTypes = this.config.cityPortalTypes;
  }

  ngOnInit(): void {
    this.buildForm();
  }

  private buildForm(): void {
    this.form = SeriesEditModalComponent.formDefault();
    this.form.patchValue(Object.assign({}, this.sourceOwnerSeries.personSeries, {
      groups: this.sourceOwnerSeries.groups
    }));

    //TODO: lae teised sama asukohaga andmepunktid
    // koordinaatide muutmine laiendada üldiselt asukoha-groupingtag muutmisele ?
    // this.loadPersonSeriesOnSameLocation() ...
    this.loadPersonSeriesOnSameCoords().pipe(takeUntil(this.ngDestroy)).subscribe((result: PersonSeries[]) => {
      this.personSeriesOnSameCoords = result;
      this.applyFormListeners();
    });
  }

  private loadPersonSeriesOnSameCoords(): Observable<PersonSeries[]> {
    if (this.sourceOwnerSeries.personSeries.lat != null || this.sourceOwnerSeries.personSeries.lng != null) {
      return this.dataOwnerSeriesService.getDataOwnerSeriesPage({
        showOnCityPortal: this.sourceOwnerSeries.personSeries.showOnCityPortal,
        cityPortalType: this.sourceOwnerSeries.personSeries.cityPortalType,
        lat: this.sourceOwnerSeries.personSeries.lat,
        lng: this.sourceOwnerSeries.personSeries.lng
      }).pipe(map((response: PageResponse<SourceOwnerSeries>) => response.content.map(series => series.personSeries)));
    } else {
      return of([]);
    }
  }

  // private loadPersonSeriesOnSameLocation(): Observable<PersonSeries[]> {
  //
  //   const personSeries = this.sourceOwnerSeries.personSeries;
  //   return this.dataOwnerSeriesService.getDataOwnerSeriesPage({
  //     lat: personSeries.lat,
  //     lng: personSeries.lng,
  //     address: personSeries.address,
  //     apartment: personSeries.apartment,
  //     room: personSeries.room,
  //     groupingTags: personSeries.groupingTags,
  //     uuid: personSeries.uuid,
  //     locationType: personSeries.locationType
  //   }).pipe(map((response: PageResponse<SourceOwnerSeries>) => response.content.map(series => series.personSeries)));
  //
  // }

  private applyFormListeners(): void {

    let latFormControl = this.form.get('lat'),
      lngFormControl = this.form.get('lng'),
      showOnCityPortalFormControl = this.form.get('showOnCityPortal'),
      cityPortalTypeFormControl = this.form.get('cityPortalType'),
      readableAddressFormControl = this.form.get('readableAddress');

    this.form.get('locationType').valueChanges.pipe(takeUntil(this.ngDestroy)).subscribe(() => {
      this.form.get('address').updateValueAndValidity();
      this.form.get('apartment').updateValueAndValidity();
      latFormControl.updateValueAndValidity();
      lngFormControl.updateValueAndValidity();
      this.form.get('uuid').updateValueAndValidity();
    });

    if (this.isCityUser) {
      showOnCityPortalFormControl.valueChanges.pipe(takeUntil(this.ngDestroy)).subscribe(value => {
        if (value) {
          cityPortalTypeFormControl.setValidators([Validators.required]);
          if (!readableAddressFormControl.value) {
            readableAddressFormControl.setValue(this.form.get('address').value);
          }
        } else {
          cityPortalTypeFormControl.clearValidators();
        }
        cityPortalTypeFormControl.updateValueAndValidity();
        readableAddressFormControl.updateValueAndValidity();

        this.registerPersonSeriesChanged(value, {
          lat: latFormControl.value,
          lng: lngFormControl.value,
          readableAddress: readableAddressFormControl.value
        });
      });

      this.cityService.getCityPersonSeries(this.sourceOwnerSeries.personSeries.id).subscribe((cityPersonSeries: any) => {
        showOnCityPortalFormControl.setValue(cityPersonSeries.showOnCityPortal);
        cityPortalTypeFormControl.setValue(cityPersonSeries.cityPortalType);
        this.form.get('aggregationGroupingType').setValue(cityPersonSeries.aggregationGroupingType);
        this.form.get('differentiate').setValue(cityPersonSeries.differentiate);
        this.form.get('readableAddress').setValue(cityPersonSeries.readableAddress);
      });

      combineLatest([
        latFormControl.valueChanges.pipe(startWith(<number>latFormControl.value)),
        lngFormControl.valueChanges.pipe(startWith(<number>lngFormControl.value)),
        readableAddressFormControl.valueChanges.pipe(startWith(<string>readableAddressFormControl.value))
      ]).pipe(map(([lat, lng, readableAddress]) => {
        return {
          lat: lat,
          lng: lng,
          readableAddress: readableAddress
        }
      })).subscribe((data: any) => {
        this.registerPersonSeriesChanged(showOnCityPortalFormControl.value, data);
      });
    }
  }

  private registerPersonSeriesChanged(showOnCityPortal: boolean, data: any): void {
    this.cityPortalCoordsChanged = this.personSeriesOnSameCoords.length > 1
      && showOnCityPortal
      && (this.sourceOwnerSeries.personSeries.lat != data.lat || this.sourceOwnerSeries.personSeries.lng != data.lng ||
      this.sourceOwnerSeries.personSeries.readableAddress != data.readableAddress);
    let bulkEditAgreementFormControl = this.form.get('bulkEditAgreement');
    if (this.cityPortalCoordsChanged) {
      bulkEditAgreementFormControl.setValidators([Validators.required]);
    } else {
      bulkEditAgreementFormControl.clearValidators();
    }
    bulkEditAgreementFormControl.updateValueAndValidity();
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
    this.groupingTagService.ngDestroy.next();
  }

  control(name?: string) {
    if (!name) {
      return this.form;
    }
    return this.form.get(name);
  }

  invalid(name: string, formGroup?) {
    if (!formGroup) {
      formGroup = this.form;
    }
    const control = formGroup.get(name);
    return control && control.invalid && (control.dirty || control.touched);
  }

  errors(name: string): ValidationErrors {
    const control = this.form.get(name);
    return control ? control.errors : null;
  }

  displayValidation(name: string) {
    const control = this.control(name);
    if (!control) {
      return null;
    }
    return this.showErrors && (control.dirty || control.touched);
  }

  public showOnCityPortal(): void {
    const showOnCityPortal = !this.form.get('showOnCityPortal').value;
    if (showOnCityPortal && (this.form.get('lat').value == null || this.form.get('lat').value === '' || this.form.get('lng').value == null || this.form.get('lng').value === '')) {

      this.googleMapsApi.getAddressCoordinates(this.form.get('address').value)
        .pipe(takeUntil(this.ngDestroy))
        .subscribe((result) => {
          if (result) {
            this.form.get('lat').setValue(result.lat, {onlySelf: true});
            this.form.get('lng').setValue(result.lng, {onlySelf: true});
          } else {
            this.toastr.warning($localize`Geocoding failed. Please specify a location on the map.`);
            this.showMap();
          }
          this.form.get('showOnCityPortal').setValue(showOnCityPortal);
        });
    } else {
      this.form.get('showOnCityPortal').setValue(showOnCityPortal);
    }
  }

  public showMap() {
    const modalRef = this.modalService.show(LocationMapComponent, {
      id: +this.modalRef.id + 1,
      class: 'modal-lg',
      initialState: {
        personSeries: this.sourceOwnerSeries.personSeries,
        coords: {
          lat: this.form.get('lat').value,
          lng: this.form.get('lng').value,
        },
        callback: (data) => {
          if (data) {
            this.form.get('lat').setValue(data.lat);
            this.form.get('lng').setValue(data.lng);
          }
        }
      }
    });
  }

  close() {
    this.modalRef.hide();
  }

  save() {
    this.form.markAsTouched();
    this.markFormGroupTouched(this.form);
    this.form.updateValueAndValidity();
    if (!this.form.valid) {
      console.error('form invalid', this.form);
      this.showErrors = true;
      return;
    }

    if (this.isCoordsRequired()) {
      const modalRef = this.modalService.show(ConfirmModalComponent, {
        ignoreBackdropClick: true,
        initialState: {
          description: $localize`Coordinates are missing. Are you sure you want to show on city portal?`,
          callback: (confirm: boolean) => {
            if (confirm) {
              this.doSave();
            }
          }
        }
      });
    } else {
      this.doSave();
    }
  }

  private isCoordsRequired(): boolean {
    const showOnCityPortal = this.form.get('showOnCityPortal').value;
    const coordsMissing = (this.form.get('lat').value == null || this.form.get('lat').value === '' || this.form.get('lng').value == null || this.form.get('lng').value === '');
    const locationType = this.form.get('locationType').value;

    return locationType != LocationType.UUID && showOnCityPortal && coordsMissing;
  }

  private doSave(): void {
    if (this.progress) {
      return;
    }

    const value = this.form.value;
    this.progress = true;

    let groups = (value.groups ?? []);

    this.personSeriesService.updatePersonSeries(this.sourceOwnerSeries.personSeries.id, {
      name: value.name ? value.name.trim() : value.name,
      description: value.description ? value.description.trim() : value.description,
      groupingTags: groups.map(group => group.name.trim().toUpperCase()),
      groups: groups,
      seriesType: value.seriesType ? value.seriesType.trim() : value.seriesType,
      locationType: value.locationType ? value.locationType.trim() : value.locationType,
      adsOid: value.adsOid ? value.adsOid.trim() : value.adsOid,
      address: value.address ? value.address.trim() : value.address,
      apartment: value.apartment ? value.apartment.trim() : value.apartment,
      room: value.room ? value.room.trim() : value.room,
      uuid: value.uuid ? value.uuid.trim() : value.uuid,
      lat: value.lat,
      lng: value.lng,
      showOnCityPortal: value.showOnCityPortal,
      cityPortalType: value.cityPortalType,
      readableAddress: value.readableAddress ? value.readableAddress.trim() : value.readableAddress,
      numberFormat: value.numberFormat
    }).subscribe((personSeries: PersonSeries) => {
      const otherCoordsUpdatePersonSeriesIds = value.bulkEditAgreement ? this.personSeriesOnSameCoords
        .filter(series => series.id !== this.sourceOwnerSeries.personSeries.id)
        .map(series => series.id) : [];

      const toForkJoin = [];
      if (this.isCityUser) {
        toForkJoin.push(this.cityService.setShowCityPersonSeriesOnCityPortal(personSeries.id, new ShowCityPortalRequest({
          showOnCityPortal: value.showOnCityPortal,
          cityPortalType: value.cityPortalType,
          aggregationGroupingType: value.aggregationGroupingType,
          differentiate: value.differentiate,
          readableAddress: value.readableAddress
        })));
      }

      if (otherCoordsUpdatePersonSeriesIds.length > 0) {
        toForkJoin.push(this.personSeriesService.updateCoordsBulk({
          ids: otherCoordsUpdatePersonSeriesIds,
          lat: personSeries.lat,
          lng: personSeries.lng,
          readableAddress: personSeries.readableAddress
        }).pipe(takeUntil(this.ngDestroy), switchMap(() => forkJoin(otherCoordsUpdatePersonSeriesIds.map(e => this.cityService.updateCityPersonSeriesMeta(e))))));
      }

      if (toForkJoin.length > 0) {
        forkJoin(toForkJoin).pipe(takeUntil(this.ngDestroy)).subscribe(() => {
          this.afterSuccessfulUpdate(personSeries);
        }, () => {
          this.afterUnsuccessfulUpdate();
        });
      } else {
        this.afterSuccessfulUpdate(personSeries);
      }
    }, (error) => {
      this.afterUnsuccessfulUpdate();
    }, () => this.progress = false);
  }

  private afterSuccessfulUpdate(personSeries: PersonSeries): void {
    this.sourceOwnerSeries.personSeries.name = personSeries.name;
    this.sourceOwnerSeries.personSeries.description = personSeries.description;
    this.sourceOwnerSeries.personSeries.groupingTags = personSeries.groupingTags;
    this.sourceOwnerSeries.personSeries.seriesType = personSeries.seriesType;
    this.sourceOwnerSeries.personSeries.locationType = personSeries.locationType;
    this.sourceOwnerSeries.personSeries.adsOid = personSeries.adsOid;
    this.sourceOwnerSeries.personSeries.address = personSeries.address;
    this.sourceOwnerSeries.personSeries.apartment = personSeries.apartment;
    this.sourceOwnerSeries.personSeries.room = personSeries.room;
    this.sourceOwnerSeries.personSeries.uuid = personSeries.uuid;
    this.sourceOwnerSeries.personSeries.lat = personSeries.lat;
    this.sourceOwnerSeries.personSeries.lng = personSeries.lng;
    this.sourceOwnerSeries.personSeries.readableAddress = personSeries.readableAddress;
    this.sourceOwnerSeries.personSeries.numberFormat = personSeries.numberFormat;
    this.sourceOwnerSeries = null;

    this.form.markAsPristine();
    this.modalRef.hide();
    this.savedEmitter.emit();
    this.toastr.success($localize`Datapoint saved`);
  }

  private afterUnsuccessfulUpdate(): void {
    this.progress = false;
    this.toastr.error($localize`Saving datapoint failed`);
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  isPreventClose(): boolean {
    return this.form && this.form.dirty;
  }

}
