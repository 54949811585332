import {Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {BsModalService} from 'ngx-bootstrap/modal';
import {ComponentLoaderFactory} from 'ngx-bootstrap/component-loader';
import {Subject} from 'rxjs/internal/Subject';
import {HttpClient} from '@angular/common/http';
import {SMARTENCITY_MYDATA_CONFIG} from '../../injection-tokens';
import {MyDataConfig} from '../../mydata-config.model';
import {debounceTime, distinctUntilChanged, map, shareReplay, startWith, switchMap, take, takeUntil} from 'rxjs/operators';
import {
  AuthService,
  PageResponse, PreventCloseModalService,
  QuestionnaireParticipant,
  QuestionnaireResponseDetail,
  ResizeService,
  SCREEN_SIZE
} from '@smartencity/core';
import {FormControl, FormGroup} from '@angular/forms';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';
import {ResponseModalComponent} from './response-modal/response-modal.component';
import {DatatableComponent} from '@swimlane/ngx-datatable';
import {DashboardService} from '../dashboard/dashboard.service';
import {DashboardType} from '../dashboard/dashboard';
import {Router} from '@angular/router';

@Component({
  selector: 'mydata-incoming-questionnaires',
  templateUrl: './incoming-questionnaires.component.html',
  providers: [BsModalService, ComponentLoaderFactory]
})
export class IncomingQuestionnairesComponent implements OnInit, OnDestroy, OnChanges {
  private ngDestroy = new Subject<void>();

  @ViewChild('tableActive', {static: true}) tableActive: DatatableComponent;
  @ViewChild('tableInactive', {static: true}) tableInactive: DatatableComponent;
  public columns = {
    detailToggler: true,
    name: true,
    status: true,
    createdAt: true,
    updatedAt: true,
    actions: true
  };

  public filterForm = new FormGroup({
    query: new FormControl(''),
    statusPending: new FormControl(false),
    statusInProgress: new FormControl(false),
    statusCompleted: new FormControl(false),
    statusDeclined: new FormControl(false),
    personQuery: new FormControl('')
  });
  public filter$ = this.filterForm.valueChanges.pipe(
    distinctUntilChanged(),
    debounceTime(250),
    startWith({}),
    shareReplay(1)
  );
  public queryParams$ = this.filter$.pipe(
    map((filter) => this.mapQueryParams(filter)),
    shareReplay(1)
  );

  public reload = new Subject<void>();
  public pageResponse$ = combineLatest([this.queryParams$, this.reload.pipe(startWith(null as void))]).pipe(
    switchMap(([queryParams, ]: [any, any]) => this.fetchPage(queryParams)),
    shareReplay(1),
    takeUntil(this.ngDestroy)
  );
  public rows$ = this.pageResponse$.pipe(
    map((data: PageResponse<QuestionnaireParticipant>) => this.mapPageResultsToRows(data)),
    shareReplay(1)
  );

  public rows: QuestionnaireParticipant[] = [];

  @Input()
  public q11eType = 'OTHER';

  @Output('saved')
  savedEmitter: EventEmitter<any> = new EventEmitter<any>();

  activeRows$ = this.rows$.pipe(map((participations) => participations.filter(p => p.status !== 'COMPLETED' && p.status !== 'DECLINED')));
  inactiveRows$ = this.rows$.pipe(map((participations) => participations.filter(p => !(p.status !== 'COMPLETED' && p.status !== 'DECLINED'))));

  constructor(
    private http: HttpClient,
    @Inject(SMARTENCITY_MYDATA_CONFIG) private config: MyDataConfig,
    private authService: AuthService,
    private modalService: BsModalService,
    private preventCloseModalService: PreventCloseModalService,
    private resizeService: ResizeService,
    private dashboardService: DashboardService,
    private router: Router
  ) {
    this.rows$.pipe(takeUntil(this.ngDestroy)).subscribe((participations) => {
      this.rows = participations;
    });

    this.authService.currentMandate$.pipe(takeUntil(this.ngDestroy)).subscribe(() => {
      this.reload.next();
    });

    this.resizeService.onResize$.pipe(takeUntil(this.ngDestroy)).subscribe(this.adjustColumns.bind(this));
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['q11eType']) {
      this.reload.next();
    }
  }

  ngOnInit() {

  }

  clearSearch() {
    this.filterForm.get('query').setValue('');
  }

  public viewResponse(participation: QuestionnaireParticipant) {

    let lastResponseId = participation.responses.length>0 ? participation.responses[0].id : null;

    const modalRef = this.preventCloseModalService.show(ResponseModalComponent, {
      class: 'modal-xl',
      initialState: {
        questionnaireId: participation.questionnaire.id,
        responseId: lastResponseId,
        responses: participation.responses
      }
    });

    modalRef.content.savedEmitter.pipe(takeUntil(this.ngDestroy), take(1)).subscribe((saveResult: QuestionnaireResponseDetail) => {

      if (saveResult && saveResult.id !== lastResponseId) {

        participation.responses.unshift({id: saveResult.id, date: saveResult.createdAt});

        const modalRef2 = this.modalService.show(ResponseModalComponent, {
          ignoreBackdropClick: true,
          class: 'modal-xl',
          initialState: {
            questionnaireId: participation.questionnaire.id,
            responseId: saveResult.id,
            responses: participation.responses
          }
        });

        modalRef2.content.savedEmitter.pipe(takeUntil(this.ngDestroy), take(1)).subscribe((saveResult2) => {
          this.savedEmitter.emit(saveResult2);
          this.reload.next();
        });
      }
      this.savedEmitter.emit(saveResult);
      this.reload.next();
    });
  }

  fetchPage(queryParams: any) {
    const params = Object.assign({}, queryParams);
    for (const i of Object.keys(params)) {
      if (params[i] == null) {
        delete params[i];
      }
    }
    return this.http.get<PageResponse<QuestionnaireParticipant>>(this.config.apiUrl + '/questionnaire-participation', {params: params});
  }

  mapQueryParams(filter: any) {
    const queryParams: any = {
      q11eType: this.q11eType
    };
    if (filter) {
      const statuses = [];
      if (filter.statusPending) {
        statuses.push('PENDING');
      }
      if (filter.statusInProgress) {
        statuses.push('IN_PROGRESS');
      }
      if (filter.statusCompleted) {
        statuses.push('COMPLETED');
      }
      if (filter.statusDeclined) {
        statuses.push('DECLINED');
      }
      if (filter.statusEdited) {
        statuses.push('EDITED');
      }

      if (statuses.length) {
        queryParams.statuses = statuses.join(',');
      }
      if (filter.query) {
        queryParams.query = filter.query;
      }
      if (filter.personQuery) {
        queryParams.personQuery = filter.personQuery;
      }
    }

    return queryParams;
  }

  mapPageResultsToRows(data: PageResponse<QuestionnaireParticipant>) {
    return data.content;
  }

  toggleExpandRow(table, row) {
    table.rowDetail.toggleExpandRow(row);
  }

  addQuestonnaireGraphsToDashboard(row): void {
    this.dashboardService.addFromQuestionnaireResponseBulk({
      templateIds: row.widgetTemplates.map(template => {
        return template.id;
      }),
      responseId: row.responses[0].id,
      dashboardType: DashboardType.DEFAULT
    }).subscribe(() => {
      this.router.navigate(['mydata', 'dashboard'], {queryParams: {dashboard_type: 'default'}});
    }, (err) => {
      console.error(err);
    });
  }


  private adjustColumns(size): void {
    if (size != null && size < SCREEN_SIZE.MD) {
      this.columns.detailToggler = true;
      this.columns.name = true;
      this.columns.status = false;
      this.columns.createdAt = false;
      this.columns.updatedAt = false;
      this.columns.actions = false;
    } else {
      this.columns.detailToggler = false;
      this.columns.name = true;
      this.columns.status = true;
      this.columns.createdAt = true;
      this.columns.updatedAt = true;
      this.columns.actions = true;
      if (this.tableActive) {
        this.tableActive.rowDetail.collapseAllRows();
      }
      if (this.tableInactive) {
        this.tableInactive.rowDetail.collapseAllRows();
      }
    }
  }

}
