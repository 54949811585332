import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {ReplaySubject} from 'rxjs/internal/ReplaySubject';
import {map} from 'rxjs/operators';
import {AuthService, Mandate,  PersonalIdPipe, UserService} from '@smartencity/core';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';

export interface MandateData {
  id: string;
  parentId: string;
  title: string;
  data: any;
  expanded: boolean;
  hidden: boolean;
  level: number;
  type: string;
  position: string;
  children: MandateData[];
  representative: boolean;
  self: boolean;
  root: boolean;
}

@Injectable()
export class MandateTreeService {

  // private myDataMap: any = {
  //   '0-1': {
  //     nodeId: '0-1',
  //     name: 'Urmas Klaas',
  //     expanded: false
  //   },
  //   '0-2': {
  //     nodeId: '0-2',
  //     parentNodeId: '0-1',
  //     name: 'Kaspar Alev',
  //     expanded: false
  //   },
  //   '0-3': {
  //     nodeId: '0-3',
  //     parentNodeId: '0-1',
  //     name: 'Juri Molder',
  //     expanded: false
  //   },
  //   '0-4': {
  //     nodeId: '0-4',
  //     parentNodeId: '0-1',
  //     name: 'Kati Tamm',
  //     expanded: false
  //   },
  //   '0-5': {
  //     nodeId: '0-5',
  //     parentNodeId: '0-2',
  //     name: 'Jaanus Tamm',
  //     expanded: false
  //   },
  //   '0-6':  {
  //     nodeId: '0-6',
  //     parentNodeId: '0-3',
  //     name: 'Kalle Kusta',
  //     expanded: false
  //   },
  //   '0-7': {
  //     nodeId: '0-7',
  //     parentNodeId: '0-2',
  //     name: 'Madis Oona',
  //     expanded: false
  //   },
  //   '0-8':  {
  //     nodeId: '0-8',
  //     parentNodeId: '0-2',
  //     name: 'Enn Ööpik',
  //     expanded: false
  //   },
  //   '0-9': {
  //     nodeId: '0-9',
  //     parentNodeId: '0-2',
  //     name: 'Tõnis Harju',
  //     expanded: false
  //   }
  // }

  private myDataMap: any = {};

  public treeMap: Map<string, MandateData> = new Map<string, MandateData>();

  public nodeSelectedSource: ReplaySubject<any> = new ReplaySubject<any>();

  public nodeSelected$: Observable<any> = this.nodeSelectedSource.asObservable();

  constructor(private userService: UserService,
              private authService: AuthService,
              private personalId: PersonalIdPipe) { }

  public getTree(): Observable<MandateData> {
    return combineLatest([this.authService.currentMandate$, this.userService.getMandates()]).pipe(map(([currentMandate, mandates]: [Mandate, Mandate[]]) => {
      // const mapData: any = {
      //   'ROOT': {
      //     nodeId: 'ROOT',
      //     name: currentMandate.person.displayName,
      //     expanded: true,
      //     level: 1
      //   }
      // };
      //
      // for (let mandate of mandates) {
      //
      //   const id = mandate.user.personalId;
      //   const parentId = mandate.createdByUser ? mandate.createdByUser.registrationNumber : null;
      //
      //   if (id === parentId || !parentId) {
      //     mapData[id] = {
      //       nodeId: id,
      //       parentNodeId: 'ROOT',
      //       name: mandate.user.displayName,
      //       expanded: true,
      //       level: 2,
      //       createdAt: mandate.startAt
      //     };
      //   }
      //
      //   if (!mapData[id]) {
      //     mapData[id] = {
      //       nodeId: id,
      //       parentNodeId: parentId,
      //       name: mandate.user.displayName,
      //       expanded: true,
      //       level: 3,
      //       createdAt: mandate.startAt,
      //       createdByUser: mandate.createdByUser
      //     }
      //   }
      // }
      //
      // this.myDataMap = mapData;
      // return Object.values(mapData);

      return this.mapToTree(currentMandate, mandates);
    }));
  }

  public selecteNode(nodeId: string) {
    let value: any = this.myDataMap[nodeId];
    if (!value) {
      return;
    }

    this.nodeSelectedSource.next(value);
  }

  public deselectNode() {
    this.nodeSelectedSource.next(null);
  }

  public getNode(id: string): MandateData {
    return this.treeMap.get(id);
  }

  public mapToTree(currentMandate: Mandate, mandates: Mandate[]): MandateData {
    this.treeMap.clear();
    let rootNode: MandateData = <MandateData>{
      id: 'ROOT',
      type: 'ROOT',
      position: currentMandate.person.registrationNumber,
      title: currentMandate.person.displayName,
      expanded: true,
      level: 1,
      data: {
        mandate: currentMandate
      },
      children: [],
      root: true,
      hidden: false
    };
    this.treeMap.set('ROOT', rootNode);
    for (let mandate of mandates) {
      const id = this.getNodeId(mandate.user.countryCode, mandate.user.personalId);
      const parentId = mandate.createdByUser ? this.getNodeId(mandate.createdByUser.countryCode, mandate.createdByUser.registrationNumber) : null;

      let mySelf: boolean = this.getNodeId(currentMandate.user.countryCode, currentMandate.user.personalId) === id;

      let childNode: MandateData = this.treeMap.get(id);
      if (!childNode) {
        childNode = <MandateData>{
          id: id,
          parentId: parentId,
          children: []
        };
      }

      let type: string = 'NODE';
      if (id === parentId || !parentId) {
        type = 'SUB-ROOT';
        rootNode.children.push(childNode);
      } else {
        // Sellel hetkel ei pruugi olla treemapis parent node-i
        let parentNode: MandateData = this.treeMap.get(parentId);
        if (!parentNode) {
          parentNode = <MandateData>{
            id: parentId,
            children: []
          };
          this.treeMap.set(parentId, parentNode);
        }
        parentNode.children.push(childNode);
      }

      Object.assign(childNode, {
        title: mandate.user.displayName,
        position: this.personalId.transform(mandate.user),
        expanded: true,
        type: type,
        data: {
          mandate: mandate
        },
        self: mySelf,
        hidden: false
      });

      this.treeMap.set(id, childNode);


      for (let consent of mandate.consents) {

        let consentId = "c" + id + "-" + consent.person.countryCode + consent.person.registrationNumber;
        let consentNode: MandateData = this.treeMap.get(consentId);
        if (!consentNode) {
          consentNode = <MandateData>{
            id: consentId,
            parentId: id,
            children: []
          };
        }

        Object.assign(consentNode, {
          title: consent.person.displayName,
          position: '',
          expanded: true,
          type: 'CONSENT',
          data: {
            consent: consent
          },
          self: false,
          hidden: false
        });

        childNode.children.push(consentNode);
        this.treeMap.set(consentId, consentNode);


      }
    }

    return rootNode;
  }

  private getNodeId(countryCode: string, personalId: string): string {
    return countryCode + personalId;
  }

}
