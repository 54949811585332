import {Directive, HostBinding, Input, OnChanges, OnDestroy, OnInit, Self, SimpleChanges} from '@angular/core';
import {AbstractControl, NgControl} from '@angular/forms';
import {Subject} from 'rxjs/internal/Subject';
import {distinctUntilChanged, startWith, takeUntil} from 'rxjs/operators';
import {Subscription} from 'rxjs/internal/Subscription';

@Directive({
  selector: '[coreValidate]',
})
export class CoreValidateDirective implements OnInit, OnDestroy, OnChanges {
  private ngDestroy = new Subject<void>();

  @Input('coreValidate')
  private validate = true;

  @Input('coreValidateControl')
  private validateControl: AbstractControl;

  @HostBinding('class.ng-valid')
  @HostBinding('class.validate-success')
  validateSuccess = false;

  @HostBinding('class.ng-invalid')
  @HostBinding('class.validate-error')
  validateError = false;

  private statusSubscription: Subscription;

  constructor(@Self() private ngControl: NgControl) { }

  ngOnInit() {
    if (!this.validateControl) {
      this.validateControl = this.ngControl.control;
      this.listenStatusChanges();
    }
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['validate']) {
      this.updateClasses();
    }
    if (changes['validateControl']) {
      const change = changes['validateControl'];
      if (!this.validateControl) {
        this.validateControl = this.ngControl.control;
      }
      if (this.validateControl !== change.previousValue) {
        this.listenStatusChanges();
      }
    }
  }

  listenStatusChanges() {
    if (this.statusSubscription) {
      this.statusSubscription.unsubscribe();
    }
    this.statusSubscription = this.validateControl.statusChanges.pipe(
      startWith(this.validateControl.status),
      distinctUntilChanged(),
      takeUntil(this.ngDestroy)
    ).subscribe((status) => this.updateClasses());
  }

  updateClasses() {
    if (this.validate && this.validateControl) {
      this.validateSuccess = null;
      this.validateError = null;
      if (this.validateControl.status === 'VALID') {
        this.validateSuccess = true;
        this.validateError = false;
      } else if (this.validateControl.status === 'INVALID') {
        this.validateSuccess = false;
        this.validateError = true;
      }
    } else {
      this.validateSuccess = false;
      this.validateError = false;
    }
  }
}
