import { Inject, Injectable } from "@angular/core";
import { DisplaySettings } from "../model/display-settings";
import { MeteorologyService } from "./meteorology.service";
import {
  ArcGisRestService,
  OlMapWrapper,
  OlMapWrapperOptions,
} from "@smartencity/core";
import { WeatherStationOlLayer } from "../layers/weather-station-ol-layer";
import { TallinnArcGisMapService } from "projects/arc-gis/src/lib/tallinn-arc-gis-map.service";
import {CoreConfig, SMARTENCITY_CORE_CONFIG} from '@smartencity/core';
import { RoadConditionOlLayer } from "../layers/road-condition-ol-layer";
import { RoadWeatherOlLayer } from "../layers/road-weather-ol-layer";
import {RoadWeatherSite} from '../model/road-weather-site';
import {WeatherStation} from '../model/weather-station';

@Injectable()
export class MeteorologyOlMapService {
  public map: OlMapWrapper;

  private weatherStationsLayer: WeatherStationOlLayer = null;
  private roadConditionLayer: RoadConditionOlLayer = null;
  private roadWeatherLayer: RoadWeatherOlLayer = null;

  displaySettings = new DisplaySettings();


  constructor(
    @Inject(SMARTENCITY_CORE_CONFIG) private config: CoreConfig,
    public meteorologyService: MeteorologyService,
    private arcGisClient: ArcGisRestService,
    private tallinnArcGisMapService: TallinnArcGisMapService
  ) {}

  createMap(): void {
    const baseUrl = this.config.cityApiUrl + "/tallinn/map-server-proxy";
    const opts = new OlMapWrapperOptions();
    opts.baseMapServerUrl =
      baseUrl + "/rest/services/aluskaart_yldine/MapServer";
    opts.center = [this.config.map.center.lng, this.config.map.center.lat];

    this.map = new OlMapWrapper(this.arcGisClient, opts);
    this.tallinnArcGisMapService.addCityLayers(this.map);

    this.map.mapLoaded$.subscribe(() => {
      this.meteorologyService.weatherStationPoints$.subscribe(
        (weatherStations: any[]) => {
          if (this.weatherStationsLayer) {
            this.weatherStationsLayer.setMap(null);
          }

          this.weatherStationsLayer = new WeatherStationOlLayer(
            weatherStations,
            this.meteorologyService
          );
          if (this.displaySettings.weatherStations) {
            this.weatherStationsLayer.setMap(this.map);
          }
        }
      );

      this.meteorologyService.roadConditionPoints$.subscribe(
        (roadConditionPoints: any[]) => {
          if (this.roadConditionLayer) {
            this.weatherStationsLayer.setMap(null);
          }

          this.roadConditionLayer = new RoadConditionOlLayer(
            roadConditionPoints,
            this.meteorologyService
          );
          if (this.displaySettings.roadConditionPoints) {
            this.roadConditionLayer.setMap(this.map);
          }
        }
      );

      this.meteorologyService.roadWeatherPoints$.subscribe(
        (roadWeatherPoints: any[]) => {
          if (this.roadWeatherLayer) {
            this.weatherStationsLayer.setMap(null);
          }

          this.roadWeatherLayer = new RoadWeatherOlLayer(
            roadWeatherPoints,
            this.meteorologyService
          );
          if (this.displaySettings.roadConditionPoints) {
            this.roadWeatherLayer.setMap(this.map);
          }
        }
      );

      this.meteorologyService.roadConditionSelected$.subscribe((roadCondition: RoadWeatherSite) => {
        if (this.roadConditionLayer) {
          this.roadConditionLayer.setRoadCondition(roadCondition);
        }
      });

      this.meteorologyService.roadWeatherSelected$.subscribe((roadWeather: RoadWeatherSite) => {
        if (this.roadWeatherLayer) {
          this.roadWeatherLayer.setRoadWeather(roadWeather);
        }
      });

      this.meteorologyService.weatherStationSelected$.subscribe((weatherStation: WeatherStation) => {
        if (this.weatherStationsLayer) {
          this.weatherStationsLayer.setWeatherStation(weatherStation);
        }
      });

      this.tallinnArcGisMapService.afterMapLoaded();
    });

    this.meteorologyService.displaySettings$.subscribe((value) => {
      this.displaySettings = value;
      this.updateDisplay();
    });

    this.meteorologyService.loadWeatherStations();
    this.meteorologyService.loadRoadWeatherSites();
  }

  updateDisplay() {
    this.weatherStationsLayer.setMap(
      this.displaySettings.weatherStations ? this.map : null
    );

    this.roadConditionLayer.setMap(
      this.displaySettings.roadConditionPoints ? this.map : null
    );

    this.roadWeatherLayer.setMap(
      this.displaySettings.roadWeatherPoints ? this.map : null
    );
  }
}
