import {TallinnService} from '../service/tallinn.service';
import {AirQualityStationGmapMarker} from './air-quality-station-gmap-marker';
import Map = google.maps.Map;
import {AirQualityStation} from '../model/air-quality-station';
import {AbstractMapLayerMarker} from '@smartencity/core';

export class AirQualityStationGmapMarkerWrapper extends AbstractMapLayerMarker<Map, AirQualityStation>{

  private marker: AirQualityStationGmapMarker = null;

  constructor(airQualityStation: AirQualityStation, map: Map, series, tallinnService: TallinnService) {
    super(airQualityStation);
    this.marker = new AirQualityStationGmapMarker(airQualityStation, new google.maps.LatLng(airQualityStation.lat, airQualityStation.lng), map, series, tallinnService);
  }

  setMap(map: google.maps.Map) {
    super.setMap(map);
    this.marker.setMap(map);
  }

  draw() {
    this.marker.draw();
  }

  setSelected(selected: boolean): void {
    this.marker.selected = selected;
  }

  isSelected(): boolean {
    return this.marker.selected;
  }

}
