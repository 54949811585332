import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter, Inject,
  Input, LOCALE_ID,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {Widget, WidgetDatasetValue} from '../../widget';
import {WidgetService} from '../../widget.service';
import {Chart} from 'chart.js';
import 'chartjs-plugin-colorschemes';
import * as ChartAnnotation from 'chartjs-plugin-annotation';
import {SCREEN_SIZE, ResizeService, NumberFormatConstants, WidgetDataService, ChartDataService} from '@smartencity/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {DecimalPipe} from '@angular/common';
import {Moment} from 'moment';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {ToastrService} from 'ngx-toastr';

class GroupInfo {
  name: string;
  unit: string;
  min: number;
  max: number;
  yAx: any;
}

@Component({
  selector: 'mydata-widget-time-frame-chart',
  templateUrl: './widget-time-frame-chart.component.html',
  styles: [':host {height: 100%}'],
  providers: [DecimalPipe]
})
export class WidgetTimeFrameChartComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  private ngDestroy = new Subject<void>();

  @Input()
  public widget: Widget;

  @Input()
  public rangeFrom: Moment;

  @Input()
  public rangeTo: Moment;

  @Input()
  public aggregationGroupingType: string;

  public nextPointStyleIndex = 0;
  public nextYAxisId = 0;
  public chartLabels: Array<any> = [];
  public chartDatasets: Array<any> = [];
  public chartYAxes: Array<any> = [];
  public groups = new Map<string, GroupInfo>(); // key = name + unit

  @ViewChild('chartCanvas')
  public chartCanvas: ElementRef;

  public chart: any;

  @Input()
  public loadPng: Subject<void>;

  @Output()
  public viewReady = new EventEmitter<boolean>();

  public previousSizeLtBpValue = null;
  public showLegendOverride = null;
  public showAxesOverride = null;

  public hasDatasets = false;
  public hasData = false;

  public chartXAxes: any = [{
    type: 'time',
    distribution: 'linear',
    ticks: {
      source: 'auto'
    },
    time: {
      displayFormats: {
        millisecond: 'HH:mm:ss.SSS',
        second: 'HH:mm:ss',
        minute: 'HH:mm',
        hour: 'HH:mm',
        day: 'DD.MM.YYYY',
        week: 'DD.MM.YYYY',
        month: 'MM.YYYY',
        quarter: '[Q]Q - YYYY',
        year: 'YYYY'
      },
      tooltipFormat: 'DD.MM.YYYY HH:mm:ss'
    },
    stacked: true,
    offset: true
  }];

  public chartAnnotations: any = [];
  public chartOptions: any = {
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    legend: {
      onClick: (e) => e.stopPropagation()
    },
    scales: {
      xAxes: this.chartXAxes,
      yAxes: this.chartYAxes
    },
    plugins: {
      colorschemes: {
        scheme: 'brewer.RdPu7'
      }
    },
    annotation: {
      annotations: this.chartAnnotations
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => {
          if (isNaN(tooltipItem.yLabel)) {
            return tooltipItem;
          } else {
            const maxFractionDigits = (data.datasets[tooltipItem.datasetIndex].mapping.numberFormat?.maxFractionDigits ?? NumberFormatConstants.DEFAULT_NUMBER_FORMAT.defaultFractionDigits);
            return new Intl.NumberFormat(this.locale, {
              maximumFractionDigits: maxFractionDigits,
              useGrouping: false
            }).format(tooltipItem.yLabel);
          }
        },
        title: (tooltipItems, data) => {
          return tooltipItems.map(e => {
            return data.datasets[e.datasetIndex].label;
          }).join(', ');
        }
      }
    },
    chartArea: {
      backgroundColor: 'rgba(255, 255, 255, 1.0)'
    }
  };

  constructor(
    public widgetService: WidgetService,
    public widgetDataService: WidgetDataService,
    public chartDataService: ChartDataService,
    private decimalPipe: DecimalPipe,
    private resizeService: ResizeService,
    private toastr: ToastrService,
    @Inject(LOCALE_ID) public locale: string
  ) {
    this.resizeService.onResize$.pipe(takeUntil(this.ngDestroy)).subscribe(this.updateWidgetOnResize);
  }

  ngOnInit() {
    if (this.loadPng) {
      this.loadPng.pipe(takeUntil(this.ngDestroy)).subscribe(value => {
        if (!this.chart) {
          this.toastr.info($localize`No data`);
          return;
        }
        this.widgetService.downloadPng(this.widget, this.chart);
      });
    }
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();

    if(this.chart){
      this.chart.destroy();
    }
  }

  ngAfterViewInit(): void {
    this.updateChart();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.widget || changes.rangeFrom || changes.rangeTo || changes.aggregationGroupingType) {
      this.widgetDataService.getWidgetValues(this.widget, this.rangeFrom, this.rangeTo, this.aggregationGroupingType)
        .pipe(takeUntil(this.ngDestroy)).subscribe((values: WidgetDatasetValue[]) => {
        this.updateChartDatasets(values);
      });
    }
  }

  updateChartDatasets(values: WidgetDatasetValue[]) {
    if (values.length) {
      this.hasDatasets = true;
    } else {
      this.hasDatasets = false;
    }
    let hasValues = false;

    this.groups.clear();
    this.chartLabels.splice(0, this.chartLabels.length);
    this.chartDatasets.splice(0, this.chartDatasets.length);
    this.chartYAxes.splice(0, this.chartYAxes.length);
    this.chartAnnotations.splice(0, this.chartAnnotations.length);

    this.nextPointStyleIndex = 0;
    this.nextYAxisId = 0;

    let tMin = null;
    let tMax = null;

    for (let i = 0; i < values.length; i++) {
      const wdv: WidgetDatasetValue = values[i];

      switch (wdv.widgetDataset.type) {
        case 'PERSON_SERIES': {
          if (wdv.personSeriesValue.measurement && wdv.personSeriesValue.measurement.series) {
            hasValues = true;
            if (wdv.personSeriesValue.measurement.series?.truncated) {
              this.toastr.warning($localize`Displayed period for data point (${wdv.widgetDataset?.personSeries?.name}) was truncated to 5000 values. Please limit time period.`);
            }
          }

          const mapping = this.chartDataService.mapChartData(wdv.personSeriesValue, wdv.widgetDataset.aggregationType, this.widget.aggregationGroupingType, wdv.widgetDataset.numberFormat, wdv.widgetDataset.differentiate);
          if (mapping.data && mapping.data.length && (tMin === null || tMin > mapping.data[0].t)) {
            tMin = mapping.data[0].t;
          }
          if (mapping.data && mapping.data.length && (tMax === null || tMax < mapping.data[mapping.data.length - 1].t)) {
            tMax = mapping.data[mapping.data.length - 1].t;
          }
          this.createChartDataset(mapping, wdv);
          break;
        } case 'PERSON_PARAMETER': {
          hasValues = true;

          const mapping = {
            data: [],
            unit: wdv.personParameterValue.unit,
            min: wdv.personParameterValue.value,
            max: wdv.personParameterValue.value,
            numberFormat: wdv.widgetDataset.numberFormat,
          };
          const dataset = this.createChartDataset(mapping, wdv);
          this.chartAnnotations.push({
            drawTime: 'afterDatasetsDraw',
            type: 'line',
            mode: 'horizontal',
            scaleID: dataset.yAxisID,
            value: wdv.personParameterValue.value,
            borderColor: 'red',
            borderWidth: 1,
            label: {
              enabled: true,
              content: wdv.widgetDataset.name,
              backgroundColor: 'rgba(0,0,0,0.0)',
              fontSize: 11,
              cornerRadius: 0,
              yAdjust: -6,
              fontStyle: 'normal',
              fontColor: '#444',
              position: 'left'
            }
          });
          break;
        } case 'Q11E_FIELD': {
          break;
        } case 'Q11E_RESPONSE_FIELD': {
          if (wdv.q11eResponseFieldValue.type === 'VALUE') {
            hasValues = true;

            const mapping = {
              data: [],
              unit: wdv.q11eResponseFieldValue.unit,
              min: wdv.q11eResponseFieldValue.value,
              max: wdv.q11eResponseFieldValue.value
            };
            const dataset = this.createChartDataset(mapping, wdv);
            this.chartAnnotations.push({
              drawTime: 'afterDatasetsDraw',
              type: 'line',
              mode: 'horizontal',
              scaleID: dataset.yAxisID,
              value: wdv.q11eResponseFieldValue.value,
              borderColor: 'red',
              borderWidth: 1,
              label: {
                enabled: true,
                content: wdv.widgetDataset.name,
                backgroundColor: 'rgba(0,0,0,0.0)',
                fontSize: 11,
                cornerRadius: 0,
                yAdjust: -6,
                fontStyle: 'normal',
                fontColor: '#444',
                position: 'left'
              }
            });
          }
          break;
        }
      }
    }

    if (tMin && tMax && tMin < tMax) {
      if (this.widget.aggregationGroupingType == null || this.widget.aggregationGroupingType === 'HOURLY') {
        const diffTime = Math.abs(tMax - tMin);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        if (diffDays > 1) {
          this.chartXAxes[0].time.displayFormats.hour = 'DD.MM HH:mm';
        } else {
          this.chartXAxes[0].time.displayFormats.hour = 'HH:mm';
        }
        this.chartXAxes[0].time.unit = 'hour';
      } else {
        if (this.widget.aggregationGroupingType === 'DAILY') {
          this.chartXAxes[0].time.unit = 'day';
        } else if (this.widget.aggregationGroupingType === 'MONTHLY') {
          this.chartXAxes[0].time.unit = 'month';
        } else if (this.widget.aggregationGroupingType === 'YEARLY') {
          this.chartXAxes[0].time.unit = 'year';
        }
      }
    }

    this.hasData = this.hasData || hasValues;

    this.updateChart();
  }

  createChartDataset(mapping: any, wdv: WidgetDatasetValue): any {
    const color = wdv.widgetDataset.color;
    let yAx;
    let yAxId;
    let stackId;

    if (wdv.widgetDataset.groupName) {
      const groupKey = wdv.widgetDataset.groupName + '-' + mapping.unit;
      let groupInfo = this.groups.get(groupKey);
      if (groupInfo == null) {
        yAx = this.createYAxis(mapping.unit ? wdv.widgetDataset.groupName + ', ' + mapping.unit : wdv.widgetDataset.groupName);

        groupInfo = {
          name: wdv.widgetDataset.groupName,
          unit: mapping.unit,
          min: null,
          max: null,
          yAx: yAx
        };
        this.groups.set(groupKey, groupInfo);
      } else {
        yAx = groupInfo.yAx;
      }
      yAxId = yAx.id;
      stackId = wdv.widgetDataset.graphType === 'stack' ? 'stack-' + groupKey : 'nostack-' + wdv.widgetDataset.id;

      if (groupInfo.min === null || mapping.min < groupInfo.min) {
        groupInfo.min = mapping.min;
        yAx.ticks['suggestedMin'] = groupInfo.min;
      }
      if (groupInfo.max === null || mapping.max > groupInfo.max) {
        groupInfo.max = mapping.max;
        yAx.ticks['suggestedMax'] = groupInfo.max;
      }
    } else {
      yAx = this.createYAxis(wdv.widgetDataset.name);
      yAxId = yAx.id;
      stackId = 'nostack-' + wdv.widgetDataset.id;

      if (mapping.min !== null) {
        yAx.ticks['suggestedMin'] = mapping.min;
      }
      if (mapping.max !== null) {
        yAx.ticks['suggestedMax'] = mapping.max;
      }
    }

    if (wdv.widgetDataset.scaleMin != null && !isNaN(wdv.widgetDataset.scaleMin)) {
      if (yAx.ticks['min'] == null || wdv.widgetDataset.scaleMin < yAx.ticks['min']) {
        yAx.ticks['min'] = wdv.widgetDataset.scaleMin;
      }
    }
    if (wdv.widgetDataset.scaleMax != null && !isNaN(wdv.widgetDataset.scaleMax)) {
      if (yAx.ticks['max'] == null || wdv.widgetDataset.scaleMax > yAx.ticks['max']) {
        yAx.ticks['max'] = wdv.widgetDataset.scaleMax;
      }
    }

    if (!isNaN(yAx.ticks.suggestedMin) && yAx.ticks.suggestedMin === yAx.ticks.suggestedMax) {
      if (yAx.ticks.suggestedMin > 0) {
        yAx.ticks.suggestedMin = 0;
      } else {
        yAx.ticks.suggestedMax = 0;
      }
    }

    const dataset: any = {
      mapping: mapping,
      data: mapping.data.length ? mapping.data : null,
      label: wdv.widgetDataset.name,
      type: wdv.widgetDataset.graphType ? mapping.data.length > 1 ? wdv.widgetDataset.graphType !== 'stack' ? wdv.widgetDataset.graphType : 'bar' : 'bar' : 'line',
      fill: false,
      yAxisID: yAxId,
      lineTension: 0,
      pointStyle: this.chartDataService.pointStyles[this.nextPointStyleIndex++],
      stack: stackId,
      minBarLength: 5
    };
    if (color != null) {
      dataset.borderColor = color;
      dataset.backgroundColor = color;
    }
    this.chartDatasets.push(dataset);

    if (wdv.thresholds && wdv.thresholds.length) {
      for (const threshold of wdv.thresholds) {
        this.chartAnnotations.push({
          drawTime: 'afterDatasetsDraw',
          type: 'line',
          mode: 'horizontal',
          scaleID: yAxId,
          value: threshold.value,
          borderColor: 'red',
          borderWidth: 1,
          label: {
            enabled: threshold.enabled,
            content: threshold.name,
            backgroundColor: 'rgba(0,0,0,0.0)',
            fontSize: 11,
            cornerRadius: 0,
            yAdjust: -6,
            fontStyle: 'normal',
            fontColor: '#444',
            position: 'left'
          }
        });
      }
    }

    return dataset;
  }

  createYAxis(labelString: string): any {
    const yAxId = 'y-axis-ci-' + this.nextYAxisId++;

    const yAx = {
      id: yAxId,
      scaleLabel: {
        display: 'auto',
        labelString: labelString ? labelString : '',
        labels: {
          show: true
        }
      },
      display: this.widget.showYAxes ? 'auto' : false,
      ticks: {},
      position: 'left',
      gridLines: {
        drawOnChartArea: false
      },
      offset: false,
      //stacked: true //Ei ole vajalik, joondiagrammil ka stackis, mis on vale; eraldi saab öelda dataseti juures "stack"
    };

    this.chartYAxes.push(yAx);

    return yAx;
  }

  updateChart() {
    if (!this.chartCanvas) {
      return;
    }

    if (this.chartDatasets.length) {
      if (!this.chart || true) {

        if (this.widget.chartColorScheme) {
          this.chartOptions.plugins.colorschemes.scheme = this.widget.chartColorScheme;
        }
        this.chartOptions.legend.display = this.widget.showLegend;

        const options = {
          type: 'bar',
          data: {
           labels: this.chartLabels,
            datasets: this.chartDatasets
          },
         plugins: [ChartAnnotation],
          options: this.chartOptions
        };

        if (this.widget.showDataLabels) {
          options.options.plugins['datalabels'] = {
            anchor: 'end',
            align: 'end',
            display: 'auto',
            formatter: (value, context) => {
              if (isNaN(value?.y)) {
                return '';
              } else {
                return this.decimalPipe.transform(value?.y, '1.0-2');
              }
            }
          };
          options.plugins.push(ChartDataLabels);
        }

        this.chart = new Chart(this.chartCanvas.nativeElement, options);
      }

      for (const yAx of this.chartYAxes) {
        yAx.display = this.showLegendOverride != null ? this.showLegendOverride : this.widget.showYAxes ? 'auto' : false;
      }
      this.chart.options.legend.display = this.showLegendOverride != null ? this.showLegendOverride : this.widget.showLegend;
      this.chart.options.annotation = Chart.helpers.configMerge((Chart as any).Annotation.defaults, Chart.helpers.configMerge((Chart as any).Annotation.defaults, {annotations: this.chartAnnotations}));
      const scaleMerge = Chart.helpers.scaleMerge(Chart.defaults.scale, {xAxes: this.chartXAxes, yAxes: this.chartYAxes});
      this.chart.options.scales.xAxes = scaleMerge.xAxes;
      this.chart.options.scales.yAxes = scaleMerge.yAxes;
      this.chart.update();

      this.viewReady.emit(true);
    } else {
      if (this.chart) {
        this.chart.destroy();
        this.chart = null;
      }
      this.viewReady.emit(false);
    }
  }

  private updateWidgetOnResize(size): void {
    const sizeLtBpValue = size != null && size < SCREEN_SIZE.MD;
    if (sizeLtBpValue) {
      this.showLegendOverride = false;
      this.showAxesOverride = false;
    } else {
      this.showLegendOverride = null;
      this.showAxesOverride = null;
    }
    if (this.previousSizeLtBpValue != null && this.previousSizeLtBpValue !== sizeLtBpValue) {
      this.updateChart();
    }
    this.previousSizeLtBpValue = sizeLtBpValue;
  }

}
