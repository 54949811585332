import {Inject, Injectable, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs/internal/Subject';
import {catchError, debounceTime, map, shareReplay, startWith, switchMap, takeUntil, tap, withLatestFrom} from 'rxjs/operators';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';
import {merge} from 'rxjs/internal/observable/merge';
import {Observable} from 'rxjs/internal/Observable';
import {PageResponse, SourceOwnerSeries, SourceOwnerSeriesListItem} from '@smartencity/core';
import {throwError} from 'rxjs/internal/observable/throwError';
import {HttpClient} from '@angular/common/http';
import {SMARTENCITY_MYDATA_CONFIG} from '../../injection-tokens';
import {MyDataConfig} from '../../mydata-config.model';
import {FormGroup} from '@angular/forms';
import {DatapointGroupsIndexService} from '../../components/datapoints-groups-index/datapoint-groups-index.service';

@Injectable()
export class DatapointGroupsSeriesListService implements OnDestroy{

  private ngDestroy = new Subject<void>();

  public filterForm: FormGroup = this.datapointGroupsIndexService.filterForm;

  private page: PageResponse<SourceOwnerSeries>;

  public defaultLimit = 25;
  public defaultSorts = [{prop: 'name', dir: 'asc'}];
  public page$: Subject<any> = new Subject<any>();
  public sorts$: Subject<any> = new Subject<any>();
  public limit$: Subject<any> = new Subject<any>();
  public reloadSubject = new Subject<void>();
  private reload$ = this.reloadSubject.asObservable();

  public filter$ = this.filterForm.valueChanges.pipe(debounceTime(500), startWith(this.filterForm.getRawValue())).pipe(tap((filter) => {
    // this.sharedKeywordStore.setState(filter.query);
    this.page = null;
    return filter;
  }));

  public filterSortsLimit$ = combineLatest([
    this.filter$,
    this.sorts$.pipe(startWith(this.defaultSorts)),
    this.limit$.pipe(startWith(this.defaultLimit))
  ]).pipe(shareReplay(1));

  public queryParams$ = merge(
    this.filterSortsLimit$.pipe(map(([filter, sorts, limit]: [any, any[], number]) => [filter, sorts, limit, {offset: this.page ? this.page.page : 0, limit: limit}])),
    this.page$.pipe(
      withLatestFrom(this.filterSortsLimit$),
      map(([page, [filter, sorts, limit]]: [any, [any, any[], number]]) => [filter, sorts, limit, page])
    )
  ).pipe(
    map(([filter, sorts, limit, page]) => this.mapQueryParams(filter, sorts, limit, page)),
    takeUntil(this.ngDestroy),
    shareReplay(1)
  );

  public pageResponse$ = combineLatest([this.queryParams$, this.reload$.pipe(startWith(null))]).pipe(
    takeUntil(this.ngDestroy),
    switchMap(([queryParams,]: [any, any]) => this.fetchPage(queryParams)),
    shareReplay(1)
  );

  public rows$: Observable<SourceOwnerSeriesListItem[]> = this.pageResponse$.pipe(
    map((data: PageResponse<SourceOwnerSeries>) => {
      return this.mapPageResultsToRows(data);
    }),
    shareReplay(1)
  );

  constructor(
    @Inject(SMARTENCITY_MYDATA_CONFIG) private config: MyDataConfig,
    private datapointGroupsIndexService: DatapointGroupsIndexService,
    private http: HttpClient
  ) {
  }

  ngOnDestroy() {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  public isLoading(): boolean {
    return false;
  }

  public reload() {
    this.reloadSubject.next();
  }

  fetchPage(queryParams: any): Observable<PageResponse<SourceOwnerSeries>> {
    const params = Object.assign({}, queryParams);

    for (const i of Object.keys(params)) {
      if (params[i] == null) {
        delete params[i];
      }
    }

    return this.http.get<PageResponse<SourceOwnerSeries>>(this.config.apiUrl + '/data-owner-series', {
      params: params
    }).pipe(catchError((err) => {
      return throwError(err);
    }));
  }


  mapQueryParams(filter: any, sorts: any[], limit: number, page: any) {
    const queryParams: any = {
      returnDataOwnerPerson: true,
      returnConsents: true,
      returnGroups: true
    };

    if (filter) {
      const confirmationStatuses = [];
      if (filter.confirmationStatusPending) {
        confirmationStatuses.push('PENDING');
      }
      if (filter.confirmationStatusAccepted) {
        confirmationStatuses.push('ACCEPTED');
      }
      if (filter.confirmationStatusDeclined) {
        confirmationStatuses.push('DECLINED');
      }
      if (confirmationStatuses.length) {
        queryParams.confirmationStatuses = confirmationStatuses;
      }

      if (filter.confirmationStatuses) {
        queryParams.confirmationStatuses = filter.confirmationStatuses.join(',');
      }
      if (filter.showOnCityPortalTrue && !filter.showOnCityPortalFalse) {
        queryParams.showOnCityPortal = true;
      }
      if (!filter.showOnCityPortalTrue && filter.showOnCityPortalFalse) {
        queryParams.showOnCityPortal = false;
      }
      if (filter.query) {
        queryParams.query = filter.query;
      }
    }
    if (page) {
      queryParams.page = page.offset;
      queryParams.size = limit;
    }
    if (limit) {
      queryParams.size = limit;
    }
    if (sorts && sorts.length > 0) {
      queryParams.sortFields = sorts.reduce((accumulator, currentValue) => {
        if (currentValue.dir === 'desc') {
          return accumulator + encodeURIComponent('-' + currentValue.prop);
        } else {
          return accumulator + '+' + currentValue.prop;
        }
      }, '');
    }

    return queryParams;
  }

  mapPageResultsToRows(data: PageResponse<SourceOwnerSeries>): SourceOwnerSeriesListItem[] {
    this.page = data;
    const rows = [];
    for (const series of data.content) {
      rows.push((<SourceOwnerSeriesListItem>{
        id: series.id,
        series: series,
        name: series.personSeries.name,
        description: series.personSeries.description,
        updatedAt: series.personSeries.updatedAt,
        ownerType: series.lastPeriod.ownerType,
        confirmationStatus: series.lastPeriod.confirmationStatus,
        showOnCityPortal: series.personSeries.showOnCityPortal,
        sourceType: series.source?.type
      }));
    }

    return rows;
  }

}
