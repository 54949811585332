import {Component, ElementRef, Inject, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {Subject} from 'rxjs/internal/Subject';
import {FileDto, FileRef} from '@smartencity/core';
import {ManageService} from '../manage.service';
import {MyDataConfig} from '../../../mydata-config.model';
import {HttpClient, HttpEventType} from '@angular/common/http';
import {distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {SMARTENCITY_MYDATA_CONFIG} from '../../../injection-tokens';

const selector = 'app-questionnaire-info';
let nextId = 0;

@Component({
  selector: selector,
  templateUrl: './questionnaire-info.component.html',
  styleUrls: ['./questionnaire-info.component.css']
})
export class QuestionnaireInfoComponent implements OnDestroy, OnInit {
  id = `${selector}-${nextId++}`;
  private ngDestroy = new Subject<void>();

  @Input()
  public type: string;

  @ViewChild('uploadInput')
  uploadInput: ElementRef;

  filesArray = this.manageService.filesArray;
  formGroup: FormGroup = this.manageService.formGroup;
/*  publicParticipationControl = this.manageService.publicParticipationControl;*/

/*  questionnaireQ11eTypes = [
    {value: 'ENERGY_MANAGER', label: $localize`Energy manager`},
    {value: 'OTHER', label: $localize`Other`}
  ];

  formulaSourceTemplateQ11eTypes = [
    {value: 'FORMULA_SOURCE_TEMPLATE', label: $localize`Formula template`}
  ];

  q11eTypes = [
    {value: 'ENERGY_MANAGER', label: $localize`Energy manager`},
    {value: 'OTHER', label: $localize`Other`},
    {value: 'FORMULA_SOURCE_TEMPLATE', label: $localize`Formula template`}
  ];*/

  constructor(
    @Inject(SMARTENCITY_MYDATA_CONFIG) private config: MyDataConfig,
    private http: HttpClient,
    private manageService: ManageService,
    private toastr: ToastrService
  ) {
    this.manageService.questionneire$.pipe(takeUntil(this.ngDestroy)).subscribe(q => {
      this.type = q.type;
    });
    this.filesArray.valueChanges.pipe(takeUntil(this.ngDestroy), distinctUntilChanged()).subscribe((filesValue: any) => {
      filesValue.forEach((value: any) => {
        const fileRef = value.fileRef;
        if (!fileRef.isUploaded && !fileRef.upload$) {
          const formData: FormData = new FormData();
          formData.append('file', fileRef.file, fileRef.name);
          fileRef.upload$ = this.http.post(this.config.apiUrl + '/files/upload', formData, {
            reportProgress: true,
            observe: 'events'
          });

          fileRef.upload$.pipe(takeUntil(this.ngDestroy)).subscribe(
            (event) => {
              if (event.type === HttpEventType.Response) {
                if (event.status === 200) {
                  fileRef.isUploaded = true;
                  fileRef.uploadProgress = 1;

                  const response: FileDto = event.body as FileDto;
                  fileRef.id = response.id;
                  fileRef.mimeType = response.mimeType;
                  fileRef.name = response.name;
                }
              } else if (event.type === HttpEventType.UploadProgress) {
                fileRef.uploadProgress = event.loaded / event.total;
              }
            },
            (error) => {
              console.error('Error uploading file', error);
              this.toastr.error('Error uploading file');
            }
          );
        }
      });
    });
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  ngOnInit(): void {
  }

  control(name?: string) {
    if (!name) {
      return this.formGroup;
    }
    return this.formGroup.get(name);
  }

  invalid(name: string, formGroup?) {
    if (!formGroup) {
      formGroup = this.formGroup;
    }
    const control = formGroup.get(name);
    return control && control.invalid && (control.dirty || control.touched);
  }

  errors(name: string): ValidationErrors {
    const control = this.formGroup.get(name);
    return control ? control.errors : null;
  }

  uploadFile(event: any) {
    const inputEl = this.uploadInput.nativeElement;
    if (inputEl.files.length === 0) {
      return;
    }

    const files: FileList = inputEl.files;

    for (let i = 0; i < files.length; i++) {
      this.processFileUpload(files[i]);
    }

    inputEl.value = null;
  }

  processFileUpload(entry: any) {
    const uploadFileRef: FileRef = new FileRef();

    uploadFileRef.isLoaded = false;
    uploadFileRef.file = entry;
    uploadFileRef.mimeType = entry.type;

    {
      uploadFileRef.dataUrl = URL.createObjectURL(new Blob([uploadFileRef.file], {
        type: 'application/octet-stream'
      }));
      uploadFileRef.isLoaded = true;
    }

    this.filesArray.push(this.createFile(uploadFileRef));
    this.filesArray.markAsTouched();
  }

  private createFile(fileRef: FileRef) {
    const fileGroup = new FormGroup({
      id: new FormControl(null, []),
      fileRef: new FormControl(fileRef, [Validators.required]),
    });

    return fileGroup;
  }

  public downloadFile(index: number) {
    const control = this.filesArray.controls[index];
    const fileRef: FileRef = control.get('fileRef').value;
    this.http.get(
      this.config.apiUrl + '/questionnaire/' + this.manageService.questionnaire?.id + '/files/' + control.get('id').value,
      {
        responseType: 'arraybuffer'
      }
    ).subscribe((data) => {
      const blob = new Blob([data], { type: fileRef.mimeType });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.download = fileRef.name;
      anchor.href = url;
      anchor.click();
    });
  }

  public removeFile(index: number) {
    this.filesArray.removeAt(index);
    this.filesArray.markAsTouched();
  }
}
