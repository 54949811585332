<button type="button" class="btn btn-legend">
  <i class="fa fa-bus"></i>
</button>

<div id="floating-legend">
  <div class="row mb-2">
    <div class="col-12">
      <strong i18n>Legend</strong>
      <hr/>
    </div>
  </div>
  <div class="row mb-2">
    <div class="col-4">
      <img [src]="movingBusIcon" />
    </div>
    <div class="col-8">
      <span i18n>Moving bus</span>
    </div>
  </div>
  <div class="row mb-2">
    <div class="col-4">
      <img [src]="idleBusIcon" />
    </div>
    <div class="col-8">
      <span i18n>Bus at stop</span>
    </div>
  </div>
  <div class="row">
    <div class="col-4">
      <img [src]="selectedBusIcon" />
    </div>
    <div class="col-8">
      <span i18n>Selected bus</span>
    </div>
  </div>
</div>