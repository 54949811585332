<div class="accordion" [ngClass]="{'shared': lastPeriod?.ownerType == 'CONSENT' || lastPeriod?.ownerType == 'GROUP_CONSENT'}">
  <div class="accordion-header">
    <div class="row">
      <div class="col-md col-12 d-flex align-items-center">
         <ng-template #sharedByInfo>
            <span i18n>Shared by {{personSeries?.createdByPerson?.displayName}}</span>
          </ng-template>
        <div class="group-icon shared mr-3" *ngIf="lastPeriod?.ownerType === 'CONSENT' || lastPeriod?.ownerType == 'GROUP_CONSENT'" [tooltip]="sharedByInfo" placement="right">
          <i class="icon-s icon-shared"></i>
        </div>
        <div>
          <h3 class="mb-1">{{personSeries?.name}}<ng-container *ngIf="showOnMap">&nbsp;<i class="fa fa-map-marker-check"></i></ng-container>
            <span class="value" [hidden]="latestValue == null || latestValue.measurement == null">{{latestValue?.measurement?.value|numberFormat:personSeries?.numberFormat:locale}}
            <span [innerHTML]="latestValue?.measurement?.unit| displayUnit:'10'| safeHtml"></span></span> <span *ngIf="personSeries?.description">&nbsp;<i class="fa fa-info-circle" [tooltip]="personSeries?.description"></i></span></h3>
          <span class="text-light text-uppercase">{{location?.getLocalName()}}</span>
        </div>
      </div>
      <div class="col-md-auto col-12 text-center">
        <div class="d-flex flex-row justify-content-center align-middle">
          <div class="align-self-center" *ngIf="lastPeriod?.ownerType != 'CONSENT' && lastPeriod?.ownerType != 'GROUP_CONSENT'" (click)="showConsents()" tooltip="Shared data" i18n-tooltip>
            <share-person-item-list [persons]="getConsentPersons()"></share-person-item-list>
          </div>

          <div class="ml-3 align-self-center d-flex" dropdown>
            <button class="btn btn-icon icon-md" tooltip="Add data" i18n-tooltip *ngIf="sourceOwnerSeries?.source && sourceOwnerSeries?.source.type == 'PERSON_DATA'" (click)="addData()"><i class="fal fa-database"></i></button>
            <button class="btn btn-icon icon-md" tooltip="Manage thresholds" i18n-tooltip *ngIf="lastPeriod?.ownerType != 'CONSENT'" [class.active]="activeThresholds?.length" (click)="manageThresholds()"><i class="fal fa-bell"></i></button>
            <button class="btn btn-icon icon-md" [class.active]="isInComparison" tooltip="Add to comparison" i18n-tooltip (click)="addToComparison()" *ngIf="latestValue?.measurement != null && !isInComparison"><i class="fal fa-retweet"></i></button>
            <button class="btn btn-icon icon-md" [class.active]="isInComparison" tooltip="Remove from comparison" i18n-tooltip (click)="removeFromComparison()" *ngIf="latestValue?.measurement != null && isInComparison"><i class="fal fa-retweet"></i></button>
            <div class="btn-group button-more" dropdown *ngIf="latestValue?.measurement != null">
              <button type="button" class="btn btn-icon icon-md dropdown-toggle no-caret" tooltip="Download" i18n-tooltip dropdownToggle><i class="fal fa-download"></i></button>
              <div class="dropdown-menu dropdown-menu-right" *dropdownMenu>
                <a class="dropdown-item" (click)="png()"><i class="fal fa-file-pdf"></i> <ng-container i18n>Download png</ng-container></a>
                <a class="dropdown-item" (click)="csv()"><i class="fal fa-file-csv"></i> <ng-container i18n>Download csv</ng-container></a>
              </div>
            </div>
            <button class="btn btn-icon icon-md" tooltip="Manage consents" i18n-tooltip *ngIf="lastPeriod?.ownerType != 'CONSENT' && lastPeriod?.ownerType != 'GROUP_CONSENT'"
                    [class.active]="activeConsents?.length" (click)="showConsents()"><i class="fal fa-share"></i></button>
            <div class="btn-group button-more" *ngIf="lastPeriod?.ownerType != 'CONSENT' && lastPeriod?.ownerType != 'GROUP_CONSENT' && (personSeries?.sourceUrl || personSeries?.targetUrl)">
              <button type="button" class="btn btn-icon icon-md dropdown-toggle no-caret" dropdownToggle><i class="fal fa-ellipsis-v-alt"></i></button>
              <div class="dropdown-menu dropdown-menu-right" *dropdownMenu>
                <a *ngIf="personSeries.sourceUrl" href="{{personSeries.sourceUrl}}" target="_blank" class="dropdown-item" i18n><i class="fal fa-external-link-alt"></i> Source device</a>
                <a *ngIf="personSeries.targetUrl" href="{{personSeries.targetUrl}}" target="_blank" class="dropdown-item" i18n><i class="fal fa-external-link-alt"></i> Target device</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="accordion-content">
    <div class="row justify-content-center mb-3" [style.visibility]="latestValue ? null : 'hidden'">
      <div class="col-auto graph-filter">
        <ngx-mat-drp class="clean-datepicker" (selectedDateRangeChanged)="updateRange($event)" [options]="dateRangePickerOptions" #dateRangePicker></ngx-mat-drp>
      </div>
      <div class="col-auto d-flex align-items-center" *ngIf="!latestValue?.event">
        <span class="d-flex align-items-center graph-filter" *ngIf="(aggregationGroupingType$ | async)">
          <label class="mr-2 mb-0 d-flex"><i class="fal fa-cubes"></i></label>
          <core-custom-dropdown-control [options]="aggregationTypeOptions" [formControl]="aggregationTypeControl" placeholder="Aggregation type" i18n-placeholder></core-custom-dropdown-control>
        </span>
      </div>
      <div class="col-auto d-flex align-items-center" *ngIf="!latestValue?.event">
        <div class="checkbox mt-1">
          <input type="checkbox" [formControl]="differentiateControl" [id]="id + '-differentiate-check'">
          <label [attr.for]="id + '-differentiate-check'" i18n>Differentiate</label>
        </div>
      </div>
      <div class="col-auto d-flex align-items-center graph-filter" *ngIf="differentiateControl.value">
        <b i18n>Total:&nbsp;</b>{{valueTotal | number:'1.0-2' }}{{ latestValue?.measurement?.unit | displayUnit:'10' }}
      </div>
      <div class="col-auto d-flex align-items-center graph-filter" *ngIf="!latestValue?.event">
        <label class="mr-2 mb-0 d-flex"><i class="fal fa-chart-area"></i></label>
        <ul class="nav nav-links nav-links-small justify-content-end">
          <li class="nav-item"><a class="nav-link" [class.active]="!(aggregationGroupingType$ | async)" (click)="setAggregationGroupingType(null)" i18n>Live</a></li>
          <li class="nav-item separator"><span></span></li>
          <li class="nav-item"><a class="nav-link active" [class.active]="(aggregationGroupingType$ | async) == 'HOURLY'" (click)="setAggregationGroupingType('HOURLY')" [class.disabled]="!aggTypes['HOURLY']" i18n>Hourly</a></li>
          <li class="nav-item separator"><span></span></li>
          <li class="nav-item"><a class="nav-link" [class.active]="(aggregationGroupingType$ | async) == 'DAILY'" (click)="setAggregationGroupingType('DAILY')" [class.disabled]="!aggTypes['DAILY']" i18n>Daily</a></li>
          <li class="nav-item separator"><span></span></li>
          <li class="nav-item"><a class="nav-link" [class.active]="(aggregationGroupingType$ | async) == 'MONTHLY'" (click)="setAggregationGroupingType('MONTHLY')" [class.disabled]="!aggTypes['MONTHLY']" i18n>Monthly</a></li>
          <li class="nav-item separator"><span></span></li>
          <li class="nav-item"><a class="nav-link disabled" [class.active]="(aggregationGroupingType$ | async) == 'YEARLY'" (click)="setAggregationGroupingType('YEARLY')" [class.disabled]="!aggTypes['YEARLY']" i18n>Yearly</a></li>
        </ul>
      </div>
    </div>
    <div style="width: 100%; height: 300px; background: #fff;">
      <ng-container *ngIf="latestValue">
        <canvas *ngIf="latestValue.measurement" #chartCanvas></canvas>
        <div class="d-flex flex-row w-100 h-100" *ngIf="latestValue.event">
          <div class="col d-flex flex-column justify-content-center align-items-center">
            <div class="mb-1 d-flex flex-grow align-items-center justify-content-center flex-wrap flex-md-nowrap">
              <button class="btn btn-primary" type="button" (click)="viewLatestEventValue()"><span i18n>View last event</span></button>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="!latestValue">
        <div class="d-flex flex-row w-100 h-100">
          <div class="col d-flex flex-column justify-content-center align-items-center">
            <div class="mb-1 d-flex flex-grow align-items-center justify-content-center flex-wrap flex-md-nowrap">
              <span class="help-text" i18n>No data</span>
            </div>
          </div>
        </div>
      </ng-container>

    </div>
  </div>
</div>
