<div class="modal-header">
  <h5 class="modal-title w-100" i18n>Delete My Account</h5>
</div>
<div class="modal-body" [formGroup]="deleteForm">
  <p class="text-danger"><strong i18n>This will delete your account and anonymize all data related to you ({{deletionData?.personRegistrationNumber}}).</strong></p>

  <p i18n>To restore your data in the future, you will be provided a <strong>restore token</strong> which can be used to deanonymize your data. Make sure to write this down and store in secure location.</p>
  <h5  i18n>Restore token</h5>
  <pre>{{deletionData?.restoreToken}}</pre>

  <p i18n>To confirm account deletion, enter your personal code and the restore token as confirmation.</p>
  <div class="md-form">
    <input type="text" class="form-control" formControlName="personalCode" [id]="id + '-personal-code'">
    <label i18n [attr.for]="id + '-personal-code'">My personal code</label>
  </div>
  <div class="md-form">
    <input type="text" class="form-control" formControlName="restoreToken" [id]="id + '-restore-token'">
    <label i18n [attr.for]="id + '-restore-token'">My restore token</label>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-light btn-sm" (click)="close()" i18n>Cancel</button>
  <button type="button" class="btn btn-primary btn-sm" (click)="confirmAccountDeletion()" i18n>Yes, Delete my account</button>
</div>
