import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpXhrBackend} from '@angular/common/http';
import {SMARTENCITY_CORE_CONFIG} from '../injection-tokens';
import {CoreConfig, MapsConfig} from '../core-config.model';
import GeocoderResult = google.maps.GeocoderResult;
import {Observable} from 'rxjs/internal/Observable';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GoogleMapsApiService {

  private geocodeClient: HttpClient = new HttpClient(this.httpBackend);

  constructor(private httpBackend: HttpXhrBackend, @Inject(SMARTENCITY_CORE_CONFIG) private config: CoreConfig) {
  }

  public getMapsConf(): MapsConfig {
    return this.config.map;
  }

  public searchByAddress(address: string): Observable<{results: GeocoderResult[]}> {

    return this.geocodeClient
      .get<{results: GeocoderResult[]}>('https://maps.google.com/maps/api/geocode/json' +
        '?address=' + address + '' +
        '&components=locality:' + this.getMapsConf().geocode.locality + '|country:' + this.getMapsConf().geocode.country + '' +
        '&sensor=false' +
        '&key=AIzaSyDGwkpkCfUP6JxfPwcYq0wF5_ifDQm3dtE&libraries', {withCredentials: false});
  }

  public getAddressCoordinates(address: string): Observable<{lat: any, lng: any}> {

    return this.searchByAddress(address).pipe(map(response => {
      const results = response.results;
      if (results && results.length && results[0] && results[0].geometry && results[0].geometry.location) {
        return {
          lat: results[0].geometry.location.lat,
          lng: results[0].geometry.location.lng
        };
      }

      return null;
    }));
  }
}
