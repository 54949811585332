import {Component, OnInit} from '@angular/core';
import {Bus} from '../../model/bus';
import {Trip, TripStop} from '../../model/trip';
import moment from 'moment';
import {DatePipe} from '@angular/common';
import {VehicleStopArrival} from '../../model/vehicle';
import {MobilityService} from '../../service/mobility.service';
import {MobilityApiService} from '../../http/mobility-api.service';

@Component({
  selector: 'mobility-bus-info-modal',
  templateUrl: './bus-info-modal.component.html'
})
export class BusInfoModalComponent implements OnInit {
  public bus: Bus;
  public trip: Trip;
  public tripStops: TripStop[];
  public arrivals: Map<string, VehicleStopArrival>;
  public activeStopId: string;
  public showAllStops = false;

  constructor(
    private mobilityService: MobilityService,
    private mobilityApiService: MobilityApiService,
    private datePipe: DatePipe
  ) {
    this.mobilityService.selectedBus$.subscribe((bus: any) => {
      this.bus = bus;
      this.trip = null;
      this.showAllStops = false;
      if (bus) {
        this.loadBusTrip();
      }
    });
  }

  ngOnInit() {}

  hide() {
    this.bus = null;
  }

  clear() {
    this.mobilityService.selectBus(null);
    this.hide();
  }

  arrivalText(stop: TripStop) {
    return this.datePipe.transform(moment().startOf('day').add(stop.arrivalTime, 'seconds').toDate(), 'HH:mm');
  }

  minutesOffSchedule(stop: TripStop) {
    const arrival = this.arrivals.get(stop.id);
    const now = moment();
    if (arrival) {
      const eta = moment(arrival.updatedAt).add(arrival.seconds, 'seconds');
      return Math.floor(eta.diff(now.clone().startOf('day').add(stop.arrivalTime, 'seconds'), 'seconds') / 60);
    }
    return 0;
  }

  loadBusTrip() {
    this.mobilityApiService.getTrip(this.bus.tripId).subscribe((trip: Trip) => {
      this.trip = trip;
      this.tripStops = trip.stops.sort((a: TripStop, b: TripStop) => {
        return a.sequence - b.sequence;
      });
    });
    this.mobilityApiService.getVehicleArrivals(this.bus.id).subscribe((arrivals: VehicleStopArrival[]) => {
      const arrivalsSorted = arrivals
        .sort((a, b) => {
          return a.seconds - b.seconds;
        });
      this.arrivals = new Map<string, VehicleStopArrival>(arrivalsSorted.map((arrival) => [arrival.stopId, arrival] as [string, VehicleStopArrival]));
      if (arrivals.length) {
        this.activeStopId = arrivalsSorted[0].stopId;
      } else {
        this.activeStopId = null;
      }
    });
  }

  showAll() {
    this.showAllStops = true;
  }

  selectStop(id: string) {
    this.mobilityService.selectStop(this.mobilityService.stops.find(value => value.id === id));
  }
}
