import {OlMapWrapper, OlOverlayWrapper} from '@smartencity/core';
import {WifiAccessPoint} from '../model/wifi-access-point';

export class WifiAccessPointsOlMarkerWrapper extends OlOverlayWrapper {

  public selected = false;

  private wifiAccessPoint: any;

  constructor(wifiAccessPoint: WifiAccessPoint, map: OlMapWrapper) {
    super({
      title: wifiAccessPoint.name,
      position: [wifiAccessPoint.lng, wifiAccessPoint.lat]
    });
    this.wifiAccessPoint = wifiAccessPoint;
    this.setMap(map.getMap());
  }

  public draw() {
    if (!this.div) {
      this.div = document.createElement('DIV');
      this.div.title = this.wifiAccessPoint.name;
      this.div.style.cursor = 'pointer';
      
      let item = this.wifiAccessPoint;
      let title: string = "<span>" + item.name;
      if (item.connectedCount != null) {
        title += "<br />";
        title += $localize`Connections: ${item.connectedCount}`;
      } else {
        title += "<br />";
        title += $localize`No connections`;
      }
      title += "</span>"
      this.div.innerHTML = '<div class="pointer-wifi-ap" style="-40px;" data-trigger="hover" data-toggle="popover" data-placement="top" data-html="true" data-content="' + title + '"></div>';
      $('.popover.show').remove();
      ($('[data-toggle="popover"]') as any).popover({
        trigger: 'hover'
      });
    }

    if (this.selected) {
      this.div.classList.add("active");
    } else {
      this.div.classList.remove("active");
    }

  }

}
