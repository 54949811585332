import {HttpParams} from '@angular/common/http';

export class UrlParamsHelper {

  public static cleanParams(params: any): HttpParams {
    let queryParams = new HttpParams();
    for (const key in params) {
      if (params.hasOwnProperty(key) && params[key] !== '' && params[key] != null) {
        if (params[key] instanceof Array) {
          params[key].forEach(v => queryParams = queryParams.append(key, v));
        } else {
          queryParams = queryParams.set(key, params[key]);
        }
      }
    }

    return queryParams;
  }

}
