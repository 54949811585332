import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {WidgetService} from './widget.service';
import {Subject} from 'rxjs';
import {CompactType, GridsterComponent, GridsterConfig, GridsterItem, GridType} from 'angular-gridster2';
import {takeUntil} from 'rxjs/operators';
import {AfterLoginRedirectStateService, AuthService, WidgetDataService} from '@smartencity/core';
import {FormControl} from '@angular/forms';
import {DashboardService} from './dashboard.service';
import moment, {Moment} from 'moment';
import {NavbarService} from '../navbar/navbar.service';
import {WidgetApiDataService} from './widget-api-data.service';
import {Router} from '@angular/router';
import {DashboardType, OwnerType} from './dashboard';
import {Widget} from './widget';
import {NgxDrpOptions, NgxMatDrpComponent, Range} from '@smartencity/shared';

@Component({
  selector: 'mydata-dashboard',
  templateUrl: './dashboard.component.html',
  providers: [
    { provide: WidgetDataService, useClass: WidgetApiDataService },
  ]
})
export class DashboardComponent implements OnInit, OnDestroy {
  private ngDestroy = new Subject<void>();

  @ViewChild('navActions', {static: true})
  public navTemplate: TemplateRef<any>;

  @ViewChild('dashboardDownload', {static: true})
  public dashboardDownloadTemplate: TemplateRef<any>;

  @ViewChild('dateRangePicker', {static: true})
  public dateRangePicker: NgxMatDrpComponent;

  @ViewChild('grid')
  public grid: GridsterComponent;

  @ViewChild('advices', {static: true})
  public advicesTemplate: TemplateRef<any>;

  @ViewChild('incomingQuestionnaires', {static: true})
  public incomingQuestionnairesTemplate: TemplateRef<any>;

  public rangeControl: FormControl = this.dashboardService.rangeControl;
  public range$ = this.dashboardService.range$;

  public loading = true;

  public OwnerType = OwnerType;

  private currentAggregationType: string;

  dateRangePickerOptions: NgxDrpOptions;
  aggTypes = {
    'HOURLY': false,
    'DAILY': false,
    'MONTHLY': false,
    'YEARLY': false
  };
  public aggregationGroupingType$: Subject<string> = this.dashboardService.aggregationGroupingType$;

  options: GridsterConfig;
  dashboard: Array<GridsterItem> = [];

  public advicesVisible = false;
  public incomingQuestionnairesVisible = false;

  public reloadWidgets = new Subject<void>();

  constructor(
    private dashboardService: DashboardService,
    public widgetService: WidgetService,
    private navbarService: NavbarService,
    private authService: AuthService,
    private afterLoginRedirectStateService: AfterLoginRedirectStateService,
    private router: Router
  ) {
    this.range$.pipe(takeUntil(this.ngDestroy)).subscribe((range) => {
      const to = range.to ? moment().isBefore(moment(range.to)) ? moment() : moment(range.to).subtract(1) : null;
      this.aggTypes = {
        'HOURLY': range.from && to && !moment(range.from).isAfter(to.clone().startOf('hour')),
        'DAILY': range.from && to && !moment(range.from).isAfter(to.clone().startOf('day')),
        'MONTHLY': range.from && to && !moment(range.from).isAfter(to.clone().startOf('month')),
        'YEARLY': range.from && to && !moment(range.from).isAfter(to.clone().startOf('year'))
      };
    });

    this.dashboardService.dashboard$.pipe(takeUntil(this.ngDestroy)).subscribe((items) => {
      if (this.dashboardService.dashboardFilter.dashboardType != DashboardType.ENERGY_MANAGER && !items.length && this.afterLoginRedirectStateService.doAfterLoginRedirect) {
        this.router.navigate(['mydata', 'datapoints']);
        return;
      }

      for (const item of items) {

        if (item.itemType === 'ENERGY_MANAGER' && item.widget.component === 'advices') {
          this.showAdvices();
        }
        if (item.itemType === 'ENERGY_MANAGER' && item.widget.component === 'incoming-questionnaires') {
          this.showIncomingQuestionnaires();
        }
      }
      this.dashboard = items;
      this.loading = false;
    });

    this.dashboardService.componentItems$.pipe(takeUntil(this.ngDestroy)).subscribe((items: Map<string, any>) => {
      this.advicesVisible = items.has('advices');
      this.incomingQuestionnairesVisible = items.has('incoming-questionnaires');
    });

    this.reloadWidgets.subscribe(value => {
      this.dashboardService.reload();
    });

    this.dashboardService.aggregationGroupingType$.pipe(takeUntil(this.ngDestroy)).subscribe((aggregationType) => {
      this.currentAggregationType = aggregationType;
    });

  }

  ngOnInit() {
    this.navbarService.setDownloadDashboardDataTemplate(this.dashboardDownloadTemplate);
    this.navbarService.setActionsTemplate(this.navTemplate);
    this.options = {
      itemChangeCallback: (item, itemComponent) => {
        this.update(item);
      },
      itemResizeCallback: (item, itemComponent) => {
        this.update(item);
      },
      keepFixedHeightInMobile: true,
      gridType: GridType.VerticalFixed,
      setGridSize: true,
      fixedRowHeight: 150,
      compactType: CompactType.CompactLeftAndUp,
      minCols: 6,
      maxCols: 12,
      minRows: 1,
      minItemCols: 1,
      minItemRows: 2,
      maxItemCols: 12,
      maxItemRows: 12,
      margin: 20,
      displayGrid: 'onDrag&Resize',
      pushItems: true,
      draggable: {
        enabled: true
      },
      resizable: {
        enabled: true
      }
    };

    this.setupDrpOptions();
  }

  ngOnDestroy(): void {
    this.navbarService.setActionsTemplate(null);
    this.navbarService.setDownloadDashboardDataTemplate(null);
    this.afterLoginRedirectStateService.doAfterLoginRedirect = false;
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  setupDrpOptions() {
    const today = moment().startOf('day');

    const minus1 = today.clone().subtract(1, 'days');
    const minus7 = today.clone().subtract(7, 'days');
    const currMonthStart = today.clone().startOf('month');
    const currMonthEnd = today.clone().startOf('month').add(1, 'months').subtract(1, 'days');
    const lastMonthStart = today.clone().startOf('month').subtract(1, 'months');
    const lastMonthEnd = today.clone().startOf('month').subtract(1, 'days');

    this.dateRangePickerOptions = {
      placeholder: $localize`Choose period`,
      presets: [
        {
          presetLabel: $localize`Today`,
          range: {fromDate: today.clone(), toDate: today.clone()}
        },
        {
          presetLabel: $localize`Yesterday`,
          range: {fromDate: minus1.clone(), toDate: minus1.clone()}
        },
        {
          presetLabel: $localize`Last 7 Days`,
          range: {fromDate: minus7.clone(), toDate: today.clone().subtract(1, 'days')}
        },
        {
          presetLabel: $localize`This Month`,
          range: {fromDate: currMonthStart.clone(), toDate: currMonthEnd.clone()}
        },
        {
          presetLabel: $localize`Last Month`,
          range: {fromDate: lastMonthStart.clone(), toDate: lastMonthEnd.clone()}
        }
      ],
      format: 'dd.MM.yyyy',
      range: {
        fromDate: this.rangeControl.value.from,
        toDate: this.rangeControl.value.to ? moment(this.rangeControl.value.to).subtract(1, 'days') : null
      },
      applyLabel: $localize`Apply`,
      enforceToAfterFrom: true,
      calendarOverlayConfig: {
        shouldCloseOnBackdropClick: true,
        hasBackdrop: true
      },
      cancelLabel: $localize`Cancel`,
      clearable: true
    };
  }

  public updateRange(range: Range) {
    const myRange: {from: Moment, to: Moment} = {
      from: range && range.fromDate ? range.fromDate.clone() : null,
      to: range && range.toDate ? moment(range.toDate) : null
    };
    this.rangeControl.setValue({from: myRange.from, to: myRange.to});
  }

  update(item) {
    item.update.next();
  }

  public toggleAggregationType(aggregationGroupingType: string) {
    if (this.currentAggregationType != null && this.currentAggregationType == aggregationGroupingType) {
      aggregationGroupingType = null;
    }

    this.aggregationGroupingType$.next(aggregationGroupingType);
  }

  showAdvices(): void {
    this.dashboardService.setComponentWidgetTemplate('advices', this.advicesTemplate);
    window.scroll(0, 0);
  }

  hideAdvices(): void {
    this.dashboardService.removeComponentWidget('advices');
  }

  showIncomingQuestionnaires(): void {
    this.dashboardService.setComponentWidgetTemplate('incoming-questionnaires', this.incomingQuestionnairesTemplate);
    window.scroll(0, 0);
  }

  hideIncomingQuestionnaires(): void {
    this.dashboardService.removeComponentWidget('incoming-questionnaires');
  }

  loadXls(widget: Widget) {
    this.dashboardService.downloadWidget(widget, 'xlsx');
  }
}
