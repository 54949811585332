import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import {TenantService} from './services/tenant.service';
import {map, take} from 'rxjs/operators';

@Injectable()
export class TenantGuard implements CanActivate {
  constructor(private tenantService: TenantService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    return this.tenantService.tenants$.pipe(take(1), map((tenants) => tenants && tenants.length > 0)).toPromise();
  }
}
