<div class="jumbotron floating-jumbotron search-wrapper">
  <div class="input-group input-group-lg">
    <input [formControl]="searchControl" type="text" class="form-control">
    <div class="input-group-append">

      <span (click)="clear()" class="clear-search" [ngClass]="{'active': searchControl?.value?.length > 0 }">
        <i class="fal fa-times"></i>
      </span>

      <span class="input-group-text">
        <i class="fal fa-search"></i>
      </span>
    </div>
  </div>
</div>

<div class="jumbotron floating-jumbotron content-wrapper" id="search-results" [ngClass]="{'active': (results$ | async)}">
  <ul class="list-group" *ngIf="results$ | async">
    <ng-container *ngIf="(results$ | async)?.toilets?.length > 0">
      <li class="list-group-item title" i18n>Public toilets</li>
      <li *ngFor="let item of (results$ | async)?.toilets" class="list-group-item" (click)="selectMarker(item)">
        {{item.street}}, {{item.zip}}
      </li>
    </ng-container>

    <ng-container *ngIf="(results$ | async)?.parcelTerminals?.length > 0">
      <li class="list-group-item title" i18n>Parcel terminals</li>
      <li *ngFor="let item of (results$ | async)?.parcelTerminals" class="list-group-item" (click)="selectMarker(item)">
        {{item.name}}, {{item.address1}}
      </li>
    </ng-container>

    <ng-container *ngIf="(results$ | async)?.evChargers?.length > 0">
      <li class="list-group-item title" i18n>Ev chargers</li>
      <li *ngFor="let item of (results$ | async)?.evChargers" class="list-group-item" (click)="selectMarker(item)">
        {{item.id}}, {{item.owner}}
      </li>
    </ng-container>

    <ng-container *ngIf="(results$ | async)?.windTurbines?.length > 0">
      <li class="list-group-item title" i18n>Wind turbines</li>
      <li *ngFor="let item of (results$ | async)?.windTurbines" class="list-group-item" (click)="selectMarker(item)">
        {{item.id}}, {{item.owner}}
      </li>
    </ng-container>
  </ul>
</div>
