import {AbstractMapLayerMarker, OlMapWrapper} from '@smartencity/core';
import {WaterTap} from '../model/water-tap';
import {WaterTapsOlMarkerWrapper} from './water-taps-ol-marker-wrapper';

export class WaterTapsOlMarker extends AbstractMapLayerMarker<OlMapWrapper, WaterTap>{

  private marker: WaterTapsOlMarkerWrapper;

  private selected = false;

  constructor(waterTap: WaterTap, map: OlMapWrapper) {
    super(waterTap);
    this.marker = new WaterTapsOlMarkerWrapper(waterTap, map);
  }

  draw() {
    this.marker.draw();
  }

  setMap(map: OlMapWrapper) {
    super.setMap(map);
    const olMap = (map ? map.getMap() : null);
    this.marker.setMap(olMap);
  }

  isSelected(): boolean {
    return this.selected;
  }


  setSelected(selected: boolean): void {
    this.selected = selected;
  }

}
