import { Inject, Injectable } from '@angular/core';
import { CoreConfig, SMARTENCITY_CORE_CONFIG, UuidHelper } from '@smartencity/core';
import { ArcGisRestService, OlMapWrapper, OlMapWrapperOptions } from '@smartencity/core';
import { TallinnArcGisMapService } from 'projects/arc-gis/src/lib/tallinn-arc-gis-map.service';
import { BicycleStationCounts } from '../component/bicycle-stations-heatmap/bicylce-stations.model';
import { BicycleStationOlLayer } from '../layers/bicycle-stations-ol-layer';
import { RoadClosuresOlLayer } from '../layers/road-closures-ol-layer';
import { RoadClosure, RoadClosureClickEvent } from '../model/road-closure';
import { MobilityService } from './mobility.service';
import { createStyleFunctionFromUrl, createStyleFunction } from 'ol-esri-style';
import { Fill, Stroke, Style } from 'ol/style';
import { MobilityDisplaySettings } from '../mobility-config.model';
import { Observable } from 'rxjs';
import { PeopleTrafficCounter } from '../model/people-traffic-counter';
import { PeopleTrafficOlLayer } from '../layers/people-traffic-ol-layer';
import {PeopleTrafficService} from './people-traffic.service';

@Injectable()
export class MobilityOlMapService {

  public displaySettings: MobilityDisplaySettings = <MobilityDisplaySettings>{};
  public bicycleStationsLayer: BicycleStationOlLayer = null;
  public roadClosuresLayer: RoadClosuresOlLayer = null;
  public peopleTrafficLayer: PeopleTrafficOlLayer = null;
  public stationCountsMap;

  public map: OlMapWrapper;

  public highlightStyle: Style = new Style({
    stroke: new Stroke({
      color: '#00FFFF',
      width: 3
    }),
    fill: new Fill({
      color: 'rgba(0, 255, 255, 0.3)',
    }),
    zIndex: 1001
  });

  public arcGisMapLayers: Map<string, any> = new Map<string, any>([
    // [
    //   "parkingLots",
    //   {
    //     layerId: 2,
    //     name: "parkingLots",
    //     getFeatureStyle: (url, layerId): Promise<Function> => {
    //       return createStyleFunctionFromUrl(url + "/" + layerId);
    //     },
    //   },
    // ],
    [
      "parkingZones",
      {
        layerId: 3,
        name: "parkingZones",
        getFeatureStyle: ((url, layerId): Promise<Function> => {
          const opacity = 0.2;
          return createStyleFunction({
            drawingInfo: {
              "renderer": {
                "type": "uniqueValue",
                "field1": "nimetus",
                "uniqueValueInfos": [
                  {
                    "symbol": {
                      "type": "esriSFS",
                      "style": "esriSFSSolid",
                      "color": [
                        255,
                        115,
                        223,
                        opacity
                      ],
                      "outline": {
                        "type": "esriSLS",
                        "style": "esriSLSSolid",
                        "color": [
                          255,
                          0,
                          197,
                          255
                        ],
                        "width": 1.5
                      }
                    },
                    "value": "Vanalinna parkimisala",
                    "label": "Vanalinna parkimisala"
                  },
                  {
                    "symbol": {
                      "type": "esriSFS",
                      "style": "esriSFSSolid",
                      "color": [
                        255,
                        255,
                        115,
                        opacity
                      ],
                      "outline": {
                        "type": "esriSLS",
                        "style": "esriSLSSolid",
                        "color": [
                          255,
                          255,
                          0,
                          255
                        ],
                        "width": 1.5
                      }
                    },
                    "value": "Südalinna parkimisala ",
                    "label": "Südalinna parkimisala "
                  },
                  {
                    "symbol": {
                      "type": "esriSFS",
                      "style": "esriSFSSolid",
                      "color": [
                        115,
                        255,
                        223,
                        opacity
                      ],
                      "outline": {
                        "type": "esriSLS",
                        "style": "esriSLSSolid",
                        "color": [
                          0,
                          255,
                          197,
                          255
                        ],
                        "width": 1.5
                      }
                    },
                    "value": "Kesklinna parkimisala ",
                    "label": "Kesklinna parkimisala "
                  },
                  {
                    "symbol": {
                      "type": "esriSFS",
                      "style": "esriSFSSolid",
                      "color": [
                        209,
                        255,
                        115,
                        opacity
                      ],
                      "outline": {
                        "type": "esriSLS",
                        "style": "esriSLSSolid",
                        "color": [
                          170,
                          255,
                          0,
                          255
                        ],
                        "width": 1.5
                      }
                    },
                    "value": "Pirita parkimisala",
                    "label": "Pirita parkimisala"
                  }
                ],
                "fieldDelimiter": ","
              }
            }
          });
        }),
        getHighLightStyle: this.highlightStyle
      },
    ],
    [
      "healthTrails",
      {
        layerId: 5,
        name: "healthTrails",
        getFeatureStyle: (url, layerId): Promise<Function> => {
          return createStyleFunctionFromUrl(url + "/" + layerId);
        }
      },
    ],
  ]);

  constructor(
    @Inject(SMARTENCITY_CORE_CONFIG) private config: CoreConfig,
    private arcGisClient: ArcGisRestService,
    private mobilityService: MobilityService,
    private tallinnArcGisMapService: TallinnArcGisMapService,
    private peopleTrafficService: PeopleTrafficService
  ) {
    this.mobilityService.displaySettings$.subscribe(value => {
      this.displaySettings = value;
      this.updateDisplay();
    });

    this.mobilityService.roadClosuresClickEvent$.subscribe((clickEvent: RoadClosureClickEvent) => {
      if (this.roadClosuresLayer) {
        this.roadClosuresLayer.setRoadClosure(clickEvent.roadClosure);
      }
    });
  }

  createMap(): Observable<OlMapWrapper> {

    return new Observable<OlMapWrapper>(observer => {

      const baseUrl = this.config.cityApiUrl + "/tallinn/map-server-proxy";
      const opts = new OlMapWrapperOptions();
      opts.baseMapServerUrl = baseUrl + '/rest/services/aluskaart_yldine/MapServer';

      this.map = new OlMapWrapper(this.arcGisClient, opts);
      this.map.mapLoaded$.subscribe(() => {
        this.tallinnArcGisMapService.addCityLayers(this.map);

        this.map.addFeatureServer(baseUrl + "/rest/services/Andmed_Tallinn", {
          layers: Array.from(this.arcGisMapLayers.values()),
          zIndexOffset: 500,
        });

        observer.next(this.map);
        observer.complete();
      });
    });
  }

  showRoadClosures(roadClosures: RoadClosure[]): void {
    if (this.roadClosuresLayer) {
      this.roadClosuresLayer.setMap(null);
    }
    this.roadClosuresLayer = new RoadClosuresOlLayer(roadClosures, this.mobilityService);
    if (this.roadClosuresLayer) {
      this.roadClosuresLayer.setMap(this.map);
    }
  }

  updateParkingStations(bicycleStations) {
    this.stationCountsMap = new Map<string, BicycleStationCounts>();
    for (const ps of bicycleStations) {
      let uuid: string;
      let key: string;
      if (ps.uuid.endsWith('primary-locked-cycle-count')) {
        key = 'primary-locked-cycle-count';
        uuid = UuidHelper.extractFromStart(ps.uuid);
      } else if (ps.uuid.endsWith('free-docks-count')) {
        key = 'free-docks-count';
        uuid = UuidHelper.extractFromStart(ps.uuid);
      }

      if (uuid) {
        let stationCounts: BicycleStationCounts = this.stationCountsMap.get(uuid);
        if (!stationCounts) {
          const nameParts: string[] = ps.name.split(' - ');
          stationCounts = new BicycleStationCounts();
          stationCounts.name = nameParts.length > 1 ? nameParts.slice(0, -1).join(' - ') : nameParts[0];
          stationCounts.lat = ps.lat;
          stationCounts.lng = ps.lng;
          stationCounts.primaryLockedCount = 0;
          stationCounts.freeDocksCount = 0;
          this.stationCountsMap.set(uuid, stationCounts);
        }

        if (key === 'primary-locked-cycle-count') {
          stationCounts.primaryLockedCyclePsId = ps.id;
          stationCounts.primaryLockedCount = ps.value;
        } else if (key === 'free-docks-count') {
          stationCounts.freeDocksPsId = ps.id;
          stationCounts.freeDocksCount = ps.value;
        }
      }
    }

    if (this.bicycleStationsLayer) {
      this.bicycleStationsLayer.setMap(null);
    }
    this.bicycleStationsLayer = new BicycleStationOlLayer(Array.from(this.stationCountsMap.values()));
    this.bicycleStationsLayer.setMap(this.map);
  }

  updatePeopleTraffic(counters: PeopleTrafficCounter[]): void {
    if (this.peopleTrafficLayer) {
      this.peopleTrafficLayer.setMap(null);
    }
    this.peopleTrafficLayer = new PeopleTrafficOlLayer(counters, this.peopleTrafficService);
    if (this.peopleTrafficLayer) {
      this.peopleTrafficLayer.setMap(this.map);
    }

    this.peopleTrafficService.selectedPeopleTrafficCounter$.subscribe((peopleTraffic: PeopleTrafficCounter) => {
      this.peopleTrafficLayer.setPeopleTraffic(peopleTraffic);
    });
  }


  public setCenter(lat: number, lng: number) {
    // TODO: openlayers panto
    // this.map.panTo(new google.maps.LatLng(lat, lng));

  }

  updateDisplay() {
    if (this.bicycleStationsLayer) {
      this.bicycleStationsLayer.setMap(this.displaySettings.bicycleParkingStation ? this.map : null);
    }

    if (this.roadClosuresLayer) {
      this.roadClosuresLayer.setMap(this.displaySettings.roadClosures ? this.map : null);
    }

    if (this.peopleTrafficLayer) {
      this.peopleTrafficLayer.setMap(this.displaySettings.peopleTraffic ? this.map : null);
    }

    if (!this.displaySettings.peopleTraffic) {
      this.peopleTrafficService.selectPeopleTrafficCounter(null);
    }

    if (!this.displaySettings.roadClosures) {
      this.mobilityService.deselectRoadClosure();
    }

  }
}
