import {AfterViewInit, Component, ElementRef, Inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {
  PersonParameterResponse,
  QuestionnaireDetail,
  QuestionnaireField,
  SourceOwnerSeriesResponse
} from '@smartencity/core';
import {AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {Subject} from 'rxjs/internal/Subject';
import {debounceTime, distinctUntilChanged, map, startWith, switchMap, takeUntil} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import {MyDataConfig} from '../../../mydata-config.model';
import {HttpClient} from '@angular/common/http';
import {SMARTENCITY_MYDATA_CONFIG} from '../../../injection-tokens';
import {SourceUpdateOptions} from '../../../models/source-update-options';
import {PersonParameterApiService} from '../../../http/person-parameter-api.service';

const selector = 'mydata-formula-input-form';
let nextId = 0;

@Component({
  selector: selector,
  templateUrl: './formula-input-form.component.html',
  styleUrls: ['./formula-input-form.component.css']
})
export class FormulaInputFormComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  private ngDestroy = new Subject<void>();
  id = `${selector}-${nextId++}`;

  @ViewChild('anchor', { read: ElementRef })
  anchor: ElementRef;

  @Input()
  questionnaire: QuestionnaireDetail;

  @Input()
  partialEdit = false;

  @Input()
  q11eType: string;

  @Input()
  inputFieldRow: any;

  @Input()
  active: boolean;

  isViewInitialized = false;

  field: QuestionnaireField;
  formGroup: FormGroup;

  personParameterTypeahead$ = new Subject<string>();
  personParameterItems$ = this.personParameterTypeahead$.pipe(
    takeUntil(this.ngDestroy),
    startWith(''),
    distinctUntilChanged(),
    debounceTime(250),
    switchMap((term: string) => {
      if (!term) {
        return this.personParameterApi.getPersonParametersPage({size: '40'})
          .pipe(map((response: PersonParameterResponse) => response.content));
      }
      if (term.length < 4) {
        return of([]);
      }
      return this.personParameterApi.getPersonParametersPage({query: term})
        .pipe(map((response: PersonParameterResponse) => response.content));
    })
  );

  personSeriesTypeahead$ = new Subject<string>();
  personSeriesItems$ = this.personSeriesTypeahead$.pipe(
    takeUntil(this.ngDestroy),
    distinctUntilChanged(),
    switchMap((term: string) => {
      return this.http.get(this.config.apiUrl + '/data-owner-series', {params: {query: term}})
        .pipe(map((response: SourceOwnerSeriesResponse) => response.content.map((e) => e.personSeries)));
    })
  );

  public windowOperationTypeOptions = SourceUpdateOptions.windowOperationTypeOptions;

  public windowPeriodTypeOptions = SourceUpdateOptions.windowPeriodTypeOptions;

  constructor(
    @Inject(SMARTENCITY_MYDATA_CONFIG) private config: MyDataConfig,
    private http: HttpClient,
    private personParameterApi: PersonParameterApiService
  ) {}

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.inputFieldRow) {
      this.field = this.inputFieldRow.field;
      this.formGroup = this.inputFieldRow.formGroup;
    }
    if (changes.active) {
      this.scrollToMeIfActive();
    }
  }

  ngAfterViewInit(): void {
    this.isViewInitialized = true;
    this.scrollToMeIfActive();
  }

  scrollToMeIfActive(): void {
    if (!this.isViewInitialized) {
      return;
    }
    if (this.active) {
      this.anchor.nativeElement.scrollIntoView({behavior: 'smooth'});
    }
  }

  control(name?: string) {
    if (!name) {
      return this.formGroup;
    }
    return this.formGroup.get(name);
  }

  invalid(name: string, formGroup?) {
    if (!formGroup) {
      formGroup = this.formGroup;
    }
    const control = formGroup.get(name);
    return control && control.invalid && (control.dirty || control.touched);
  }

  errors(name: string, formGroup?): ValidationErrors {
    if (!formGroup) {
      formGroup = this.formGroup;
    }
    const control = formGroup.get(name);
    return control ? control.errors : null;
  }

  public toggleDescription() {
    if (this.formGroup.get('description').value === null) {
      this.formGroup.get('description').setValue('');
    } else {
      this.formGroup.get('description').setValue(null);
    }
  }

  public addSelectOption(selectOptions: AbstractControl) {
    const optionsArray = selectOptions as FormArray;
    optionsArray.push(this.createSelectOption(optionsArray));
  }

  public removeSelectOption(selectOptions: AbstractControl, index: number) {
    const optionsArray = selectOptions as FormArray;
    optionsArray.removeAt(index);
    if (optionsArray.length < 1) {
      this.addSelectOption((optionsArray));
    }
  }

  private createSelectOption(selectOptions: FormArray, name?: string, value?: number) {
    return new FormGroup({
      name: new FormControl(name, [Validators.required]),
      value: new FormControl(value, [Validators.required, Validators.pattern(/^\d+([\.\,]\d+)?$/)])
    });
  }

}
