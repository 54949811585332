import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {MapsGridArea} from '../../../model/crowd-insights/maps-grid-area';
import {CrowdInsightsDataService} from '../../../service/crowd-insights-data.service';
import {VisitDaily} from '../../../model/crowd-insights/visit-daily';
import {ActivityTypeFilterOption} from '../activity-type-filter/activity-type-filter-option';
import {VisitDailyFilter} from '../../../model/crowd-insights/visit-daily-filter';
import {ActivityType} from '../../../model/crowd-insights/activity-type';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'ci-grid-area-daily-visits',
  templateUrl: './grid-area-daily-visits.component.html'
})
export class GridAreaDailyVisitsComponent implements OnInit, OnChanges {

  @Input()
  gridArea: MapsGridArea;

  visits: VisitDaily[] = [];

  filterOptions: ActivityTypeFilterOption[] = [
    ActivityTypeFilterOption.HOME,
    ActivityTypeFilterOption.WORK
  ]

  defaultActivityType: ActivityType = 'WORK';
  defaultStartDate: Date = new Date('2023-04-10');

  defaultBatchDate: Date = new Date('2023-04-10');

  defaultEndDate: Date = new Date('2023-04-17');

  dateOptions: Date[] = [];

  filter: VisitDailyFilter = {
    aohAreaCode: null,
    activityType: this.defaultActivityType,
    batchDate: this.defaultBatchDate
  }


  visitDate: Date = this.defaultBatchDate;

  filterByDate(date: Date): void {
    this.filter.batchDate = date;
    this.loadVisits();
  }

  constructor(private crowdInsightsDataService: CrowdInsightsDataService) { }

  ngOnInit(): void {
    let runningDate = new Date(this.defaultStartDate.getTime()),
      endDate = new Date(this.defaultEndDate.getTime());
    let dates: Date[] = [];
    while (runningDate.getTime() < endDate.getTime()) {
      dates.push(new Date(runningDate.getTime()));
      runningDate.setDate(runningDate.getDate() + 1);
    }

    this.dateOptions = dates;
    // order descending
    // this.dateOptions = dates.sort((a: Date, b: Date) => {
    //   if (a.getTime() < b.getTime()) return 1;
    //   if (a.getTime() > b.getTime()) return -1;
    //
    //   return 0;
    // });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.gridArea?.currentValue) {
      this.filter.aohAreaCode = changes.gridArea.currentValue.areaCode;
      this.loadVisits();
    }
  }

  activityTypeOptionSelected(option: ActivityTypeFilterOption): void {
    this.filter.activityType = option.value;
    this.loadVisits();
  }

  private loadVisits(): void {

    this.crowdInsightsDataService.getDailyVisits(this.filter).subscribe((visits: VisitDaily[]) => {
      this.visits = visits;
    });

  }

  compareDateFn(first: Date, second: Date): boolean {
    return first && second && first.getTime() === second.getTime();
  }
}
