import {Component, Inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {Subject} from 'rxjs/internal/Subject';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {
  QuestionnaireDetail,
  QuestionnaireEmailInvitationMeta,
  QuestionnaireParticipantMeta,
  QuestionnaireParticipation
} from '@smartencity/core';
import {takeUntil} from 'rxjs/operators';
import {MyDataConfig} from '../../../mydata-config.model';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {SMARTENCITY_MYDATA_CONFIG} from '../../../injection-tokens';

@Component({
  selector: 'mydata-view-modal',
  templateUrl: './view-modal.component.html',
  styleUrls: ['./view-modal.component.css']
})
export class ViewModalComponent implements OnInit, OnDestroy, OnChanges {
  private ngDestroy = new Subject<void>();

  public view = 'fields';

  @Input()
  public questionnaireId;

  public questionnaire: QuestionnaireDetail;
  participants: QuestionnaireParticipantMeta[] = [];
  emailInvites: QuestionnaireEmailInvitationMeta[] = [];
  participantCount: number;

  constructor(
    @Inject(SMARTENCITY_MYDATA_CONFIG) private config: MyDataConfig,
    private http: HttpClient,
    private modalRef: BsModalRef,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.init();
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.questionnaireId) {
      this.init();
    }
  }

  init(): void {
    if (this.questionnaireId) {
      this.http.get<QuestionnaireDetail>(this.config.apiUrl + '/questionnaire/' + this.questionnaireId).pipe(takeUntil(this.ngDestroy)).subscribe((questionnaire) => {
        this.questionnaire = questionnaire;
      });
      this.http.get(this.config.apiUrl + '/questionnaire/' + this.questionnaireId + '/participants').subscribe((participation: QuestionnaireParticipation) => {
        this.participants = participation.participants;
        this.emailInvites = participation.emailInvites;
        this.participantCount = this.participants.length + this.emailInvites.filter(i => i.status === 'NO_USER').length;
      });
    }
  }

  close(): void {
    this.modalRef.hide();
  }

  setView(view: string) {
    this.view = view;
  }

  edit() {
    this.modalRef.hide();
    this.router.navigate([this.questionnaireId], {relativeTo: this.route});
  }
}
