import { Pipe, PipeTransform } from '@angular/core';
import {NumberFormatConstants} from '@smartencity/core';

@Pipe({
  name: 'numberFormat'
})
export class NumberFormatPipe implements PipeTransform {

  transform(value: number, opts?: {
    minFractionDigits?: number,
    maxFractionDigits?: number
  }, locale?: string): string {

    return new Intl.NumberFormat(locale, {
      minimumFractionDigits: (opts?.minFractionDigits != null ? opts.minFractionDigits : 0),
      maximumFractionDigits: (opts?.maxFractionDigits != null ? opts.maxFractionDigits : NumberFormatConstants.DEFAULT_NUMBER_FORMAT.defaultFractionDigits)
    }).format(value);
  }

}
