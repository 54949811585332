import {RedirectStrategy} from './redirect-strategy';
import {UriBuilder} from './uri-builder';
import {NoopRedirectUriBuilder} from './noop-redirect-uri-builder';

export class NoopRedirectStrategy implements RedirectStrategy {

  private builder: UriBuilder = new NoopRedirectUriBuilder();

  redirect(params?: any): Promise<boolean> {
    this.redirectToUrl(this.builder.build(params));
    return Promise.resolve(true);
  }

  redirectToUrl(url: string): void {
    console.log("No-op redirect to " + url);
  }

  setRedirectUriBuilder(builder: UriBuilder): void {

  }

  getRedirectUriBuilder(): UriBuilder {
    return this.builder;
  }

}
