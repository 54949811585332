import { WindTurbine } from "../model/wind-turbine";

export class WindTurbinesGmapLayer extends google.maps.OverlayView {
  private div: any;

  public items: WindTurbine[];
  private selectedWindTurbine: WindTurbine;

  constructor(items: WindTurbine[]) {
    super();
    this.items = items;
  }

  onAdd() {
    const panes = this.getPanes();
    if (panes == null) {
      return;
    }

    if (!this.div) {
      this.div = document.createElement('DIV');
      this.div.style.position = 'absolute';
      this.div.style.cursor = 'pointer';
      this.div.style.position = 'absolute';
      this.div.classList.add('wind-turbines-layer');
      this.div.style.paddingLeft = '0px';
      panes.overlayMouseTarget.appendChild(this.div);
    }

  }

  onRemove() {
    if (this.div) {
      this.div.parentNode.removeChild(this.div);
      this.div = null;
    }
  }
  resetSelected(): void {
    this.selectedWindTurbine = null;
  }

  draw() {
    if (!this.div) {
      return;
    }

    this.div.innerHTML = this.getLayerHtml();

    this.resetPopovers();
  }

  private resetPopovers() {
    $('.popover').remove();
    ($(this.div).find('[data-toggle="popover"]') as any)
      .popover('dispose')
      .popover({trigger: 'hover'});
  }

  private getLayerHtml() {
    let html = '';

    if (!this.items) {
      return html;
    }

    this.items.forEach((item: WindTurbine) => {
      const point = this.getProjection().fromLatLngToDivPixel(new google.maps.LatLng(item.lat, item.lng));
      const x = point.x - 12;
      const y = point.y - 39;

      html += this.getWindTurbinePointerHtml(item, x, y, (this.selectedWindTurbine === item));
    });

    return html;
  }

  setMap(map) {
    super.setMap(map);
    if (map == null) {
      this.resetSelected();
    }
  }

  showWindTurbine(windTurbine: WindTurbine): void {
    this.selectedWindTurbine = windTurbine;
    this.draw();
  }

  setSelected(windTurbine: WindTurbine): void {
    this.selectedWindTurbine = windTurbine;
  }

  private getWindTurbinePointerHtml(windTurbine: WindTurbine, x: number, y: number, selected = false): string {
    const className = 'pointer-wind' + (selected ? ' active' : '');
    const title = windTurbine.id + ' (' + windTurbine.owner + ')'

    return `<div class="${className}" style="left: ${x}px; top: ${y}px" data-trigger="hover" data-toggle="popover" data-placement="top" data-html="true" data-content="${title}"></div>`;
  }

}
