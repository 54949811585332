import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, AbstractControlDirective} from '@angular/forms';

@Component({
  selector: 'form-field-errors',
  templateUrl: './form-field-errors.component.html',
})
export class FormFieldErrorsComponent implements OnInit {

  errors: string[] = [];

  public errorMessageMap = {
    'required': (params) => $localize`Required`,
    'minlength': (params) => $localize`Must be more than ${params.requiredLength} characters`,
    'maxlength': (params) => $localize`Must be less than ${params.requiredLength} characters`,
    'min': (params) => $localize`Minimum value is ${params.min}`,
    'max': (params) => $localize`Maximum value is ${params.max}`,
    'email': (params) => $localize`Invalid e-mail`,
    'default': (params) => $localize`This field has errors`
  };

  @Input()
  control: AbstractControl | AbstractControlDirective;

  constructor() {
  }

  ngOnInit(): void {
  }

  ngOnChanges(): void {

  }

  listErrors(): string[] {
    if (!this.control) return [];
    let result: string[] = [];
    if (this.control.errors && (this.control.touched || this.control.dirty)) {
      Object.keys(this.control.errors).map((error) => {
        let errorFn = (this.errorMessageMap[error] ?? this.errorMessageMap['default']);
        result.push(errorFn(this.control.errors[error]));
      });
    }

    return result;
  }

}
