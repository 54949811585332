<div class="modal-header">
  <h5 class="modal-title w-100" i18n>Application token for "{{application?.name}}"</h5>
</div>
<div class="modal-body" [formGroup]="form">
  <p i18n>Please provide connection token for connecting to "{{application?.name}}".</p>

  <div class="md-form">
    <input
      type="text"
      class="form-control"
      formControlName="token"
       [coreValidate]="true" [id]="id + '-token'">
    <label i18n [attr.for]="id + '-token'">Token</label>
     <!-- validation error -->
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-light btn-sm" aria-label="Cancel" (click)="close()" i18n>Cancel</button>
  <button type="button" class="btn btn-primary btn-sm" (click)="save()">Save</button>
</div>
