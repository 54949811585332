import {Inject, Injectable, OnDestroy} from '@angular/core';
import {FormControl} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {MapsConfig} from '@smartencity/core';
import {GasConfig} from './config.model';
import {SMARTENCITY_GAS_CONFIG} from './injection-tokens';
import {Subject, ReplaySubject, Observable} from 'rxjs';
import {Services} from './models/services.model';
import {SeriesLocation} from './models/series-location';
import {SeriesLocationType} from './models/series-location-type.enum';

@Injectable({
  providedIn: 'root'
})
export class GasService implements OnDestroy {
  private ngDestroy = new Subject<void>();
  public services$ = new ReplaySubject<Services>(1);
  public locations$ = new Subject<SeriesLocation[]>();
  public solarPanelTotals$ = new Subject<SeriesLocation>();
  public generalTotals$ = new Subject<SeriesLocation>();
  public searchControl = new FormControl('');

  public resizeMap$ = new Subject<void>();

  private static locationKey(value): string {
    return '' + value.lat + '-' + value.lng;
  }

  constructor(
    @Inject(SMARTENCITY_GAS_CONFIG) private config: GasConfig,
    private http: HttpClient
  ) {
  }

  ngOnDestroy(): void {
    this.services$.complete();
    this.locations$.complete();
    this.solarPanelTotals$.complete();
    this.generalTotals$.complete();
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  getMapsConfig(): MapsConfig {
    return this.config.map;
  }

  getLocations(): Observable<SeriesLocation[]> {
    return this.http.get(this.config.cityApiUrl + '/person-series', {
      params: {
        cityPortalTypes: [
          'SOLAR_PANELS_ACTIVE',
          'SOLAR_PANELS_CO2',
          'SOLAR_PANELS_KM',
          'SOLAR_PANELS_KW',
          'SOLAR_PANELS_PERCENT',
          'SOLAR_PANELS_RADIANCE',
          'SOLAR_PANELS_TEMP',
          'SOLAR_PANELS_TREES',
          'SOLAR_PANELS_ENERGY'
        ],
        size: '2000'
      }
    }).pipe(map((data: any) => {
      return this.mapSeriesToLocations(data.content);
    }));
  }

  loadLocations(): void {
    this.getLocations().subscribe(((locations: SeriesLocation[]) => {
      this.locations$.next(locations);
    }));
  }

  loadGeneralTotals(): void {
    this.getGeneralTotals().subscribe((totals: SeriesLocation) => {
      this.generalTotals$.next(totals);
    });
  }

  getGeneralTotals(): Observable<SeriesLocation> {
    return this.http.get(this.config.cityApiUrl + '/person-series', {
      params: {
        cityPortalTypes: [
          'SOLAR_PANELS_TREES_ESTONIA',
          'SOLAR_PANELS_CO2_ESTONIA',
          'SOLAR_PANELS_KM_ESTONIA'
        ],
        size: '2000'
      }
    }).pipe(map((data: any) => {
      return this.mapSeriesToTotals(SeriesLocationType.GENERAL, data.content);
    }));
  }

  getSolarPanelsTotals(): Observable<SeriesLocation> {
    return this.http.get(this.config.cityApiUrl + '/person-series', {
      params: {
        cityPortalTypes: [
          'SOLAR_PANELS_TREES_TOTAL',
          'SOLAR_PANELS_CO2_TOTAL',
          'SOLAR_PANELS_KM_TOTAL',
          'SOLAR_PANELS_PERCENT_TOTAL',
          'SOLAR_PANELS_ENERGY_TOTAL'
        ],
        size: '2000'
      }
    }).pipe(map((data: any) => {
      return this.mapSeriesToTotals(SeriesLocationType.SOLAR_PANELS_TOTAL, data.content);
    }));
  }

  loadSolarPanelsTotals(): void {
    this.getSolarPanelsTotals().subscribe((totals: SeriesLocation) => {
      this.solarPanelTotals$.next(totals);
    });
  }

  loadServices() {
    this.http.get(this.config.cityApiUrl + '/greengas-services').subscribe(
      (data: any) => {
        const services: Services = {
          biogasStations: data.biogasStations,
          cngStations: data.cngStations
        };
        this.services$.next(services);
      });
  }

  private mapSeriesToLocations(data: any[]): SeriesLocation[] {
    const locationMap: any = {};

    for (const value of data) {
      const key = GasService.locationKey(value);
      if (!locationMap[key]) {
        locationMap[key] = {
          key: key,
          series: [],
          address: value.address,
          lat: value.lat,
          lng: value.lng,
          type: 'LOCATION',
          readableAddress: value.readableAddress
        } as SeriesLocation;
      }

      if (!locationMap[key]['readableAddress'] && value.readableAddress) {
        locationMap[key]['readableAddress'] = value.readableAddress
      }

      locationMap[key]['series'].push(value);
    }

    const res = [];
    for (const key of Object.keys(locationMap)) {
      res.push(locationMap[key]);
    }
    return res;
  }

  private mapSeriesToTotals(seriesLocationType: SeriesLocationType, data: any[]): SeriesLocation {
    let singleResult: SeriesLocation = null;
    for (const value of data) {
      if (!singleResult) {
        singleResult = {
          key: seriesLocationType,
          series: [],
          address: value.address,
          lat: value.lat,
          lng: value.lng,
          type: seriesLocationType
        } as SeriesLocation;
      }
      singleResult.series.push(value);
    }

    return singleResult;
  }

}
