import {Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SMARTENCITY_MYDATA_CONFIG} from '../../../injection-tokens';
import {MyDataConfig} from '../../../mydata-config.model';
import {Subject} from 'rxjs/internal/Subject';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {AdviceGroup, EnumsService, Location} from '@smartencity/core';
import {FormArray, FormControl, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {takeUntil} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';

const selector = 'mydata-parameters-edit-modal';
let nextId = 0;

@Component({
  selector: selector,
  templateUrl: './advice-edit-modal.component.html',
  styles: [':host {display: flex; flex-direction: column; height: 100vh;}'],
  providers: []
})
export class AdviceEditModalComponent implements OnInit, OnDestroy, OnChanges {
  id = `${selector}-${nextId++}`;
  private ngDestroy = new Subject<void>();

  public progress = false;

  public location: Location;

  public showErrors = false;

  @Input()
  adviceGroup: AdviceGroup;

  @Output('saved')
  savedEmitter: EventEmitter<any> = new EventEmitter<any>();

  adviceArray = new FormArray([this.createAdvice()]);

  form: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
    description: new FormControl(null, [Validators.required]),
    published: new FormControl(false, [Validators.required]),
    adviceList: this.adviceArray
  });

  constructor(
    private enumsService: EnumsService,
    private http: HttpClient,
    @Inject(SMARTENCITY_MYDATA_CONFIG) private config: MyDataConfig,
    private toastr: ToastrService,
    private modalRef: BsModalRef
  ) {

  }

  ngOnInit(): void {
    if (this.adviceGroup) {
      this.reset();
    }
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['adviceGroup']) {
      this.reset();
    }
  }

  control(name?: string) {
    if (!name) {
      return this.form;
    }
    return this.form.get(name);
  }

  invalid(name: string, formGroup?) {
    if (!formGroup) {
      formGroup = this.form;
    }
    const control = formGroup.get(name);
    return control && control.invalid && (control.dirty || control.touched);
  }

  errors(name: string): ValidationErrors {
    const control = this.form.get(name);
    return control ? control.errors : null;
  }

  private reset() {
    if (this.adviceGroup) {
      this.form.patchValue({
        name: this.adviceGroup ? this.adviceGroup.name : '',
        description: this.adviceGroup ? this.adviceGroup.description : null,
        published: this.adviceGroup && this.adviceGroup.published ? this.adviceGroup.published : false,
      }, {onlySelf: true, emitEvent: false});
      while (this.adviceArray.length !== 0) {
        this.adviceArray.removeAt(0);
      }
      for (const advice of this.adviceGroup.adviceList) {
        const adviceGroup = new FormGroup({
          id: new FormControl(advice.id, []),
          category: new FormControl(advice.category, []),
          name: new FormControl(advice.name, [Validators.required]),
          description: new FormControl(advice.description, [])
        });
        this.adviceArray.push(adviceGroup);
      }
    }
  }

  public close(): void {
    this.modalRef.hide();
  }

  save() {
    this.form.markAsTouched();
    this.markFormGroupTouched(this.form);
    this.form.updateValueAndValidity();

    if (!this.form.valid) {
      console.log('form invalid', this.form);
      this.showErrors = true;
      return;
    }

    const value = this.form.getRawValue();

    this.progress = true;

    const postDto = {
      id: this.adviceGroup && this.adviceGroup.id,
      name: value.name ? value.name.trim() : value.name,
      description: value.description ? value.description.trim() : value.description,
      published: value.published ? value.published : false,
      adviceList: value.adviceList.map((e, i) => (Object.assign({}, e, {
        sortOrder: i
      })))
    };

    if (this.adviceGroup && this.adviceGroup.id) {
      this.http.put<AdviceGroup>(this.config.apiUrl + '/advice/' + this.adviceGroup.id, postDto).pipe(takeUntil(this.ngDestroy)).subscribe((adviceGroup: AdviceGroup) => {
        this.modalRef.hide();
        this.savedEmitter.emit(adviceGroup);
        this.toastr.success($localize`Advice group saved`);
      }, (error) => {
        this.progress = false;
        this.toastr.error($localize`Saving advice group failed`);
      }, () => this.progress = false);
    } else {
      this.http.post(this.config.apiUrl + '/advice', postDto).pipe(takeUntil(this.ngDestroy)).subscribe((adviceGroup: AdviceGroup) => {
        this.modalRef.hide();
        this.savedEmitter.emit(adviceGroup);
        this.toastr.success($localize`Advice group saved`);
      }, (error) => {
        this.progress = false;
        this.toastr.error($localize`Saving advice group failed`);
      }, () => this.progress = false);
    }
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  public sortOrderUp(index: number) {
    if (index > 0) {
      const tmp = this.adviceArray.at(index);
      this.adviceArray.removeAt(index);
      this.adviceArray.insert(index - 1, tmp);
      this.adviceArray.markAsTouched();
    }
  }

  public sortOrderDown(index: number) {
    if (index < this.adviceArray.length - 1) {
      const tmp = this.adviceArray.at(index);
      this.adviceArray.removeAt(index);
      this.adviceArray.insert(index + 1, tmp);
      this.adviceArray.markAsTouched();
    }
  }

  public addAdvice() {
    this.adviceArray.push(this.createAdvice());
  }

  public removeField(index: number) {
    this.adviceArray.removeAt(index);
    this.adviceArray.markAsTouched();
  }

  private createAdvice() {
    const selectOptionsArray = new FormArray([]);

    const adviceGroup = new FormGroup({
      id: new FormControl(null, []),
      category: new FormControl('', []),
      name: new FormControl('', [Validators.required]),
      description: new FormControl(null, [])
    });

    return adviceGroup;
  }
}
