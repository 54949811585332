import {Component, OnInit} from '@angular/core';
import {Stop, StopTime} from '../../model/stop';
import moment from 'moment';
import {DatePipe} from '@angular/common';
import {VehicleStopArrival} from '../../model/vehicle';
import { MobilityGmapService } from '../../service/mobility-gmap.service';
import {MobilityService} from '../../service/mobility.service';

@Component({
  selector: 'mobility-stop-info-modal',
  templateUrl: './stop-info-modal.component.html'
})
export class StopInfoModalComponent implements OnInit {
  public busStop: Stop;
  public stopTimes: StopTime[] = [];
  public arrivals: Map<string, VehicleStopArrival>;

  constructor(
    private mobilityService: MobilityService,
    private mobilityMapService: MobilityGmapService,
    private datePipe: DatePipe
  ) {
    this.mobilityService.selectedStop$.subscribe((busStop: any) => {
      this.busStop = busStop;
      this.stopTimes.length = 0;
    });
  }

  ngOnInit() { }

  hide() {
    this.busStop = null;
  }

  clear() {
    this.mobilityService.selectStop(null);
    this.hide();
  }

  isApproaching(stopTime: StopTime) {
    const arrival = this.arrivals.get(stopTime.tripId);
    if (!arrival) {
      return false;
    }
    return true;
  }

  arrivalText(stopTime: StopTime) {
    const arrival = this.arrivals.get(stopTime.tripId);
    const now = moment();
    if (!arrival) {
      return this.datePipe.transform(moment().startOf('day').add(stopTime.arrivalTime, 'seconds').toDate(), 'HH:mm');
    }
    const delaySeconds = arrival.seconds - moment().diff(moment(arrival.updatedAt), 'seconds');
    const seconds = moment().startOf('day').add(stopTime.arrivalTime, 'seconds').diff(moment(), 'seconds') + delaySeconds;
    const minutes: number = Math.floor(seconds / 60);
    if (minutes <= 0) {
      return '< 1 min';
    } else {
      return minutes + ' min';
    }
  }

  arrivalTime(stopTime: StopTime) {
    const arrival = this.arrivals.get(stopTime.tripId);
    const now = moment();
    if (!arrival) {
      return now.startOf('day').add(stopTime.arrivalTime, 'seconds');
    }
    return now.add(now.diff(moment(arrival.updatedAt), 'seconds')).add(arrival.seconds);
  }

  setStopTimes(stopTimes: StopTime[]) {
    this.stopTimes = stopTimes;
  }

  setStopArrivals(arrivals: VehicleStopArrival[]) {
    this.arrivals = new Map<string, VehicleStopArrival>(arrivals.map((arrival) => [arrival.tripId, arrival] as [string, VehicleStopArrival]));
  }

  showShape(stopTime: StopTime) {
    this.mobilityMapService.highlightShape(stopTime.shapeId);
  }

  hideShape(stopTime: StopTime) {
    this.mobilityMapService.resetShapes();
  }
}
