<div class="bottom-navbar" style="z-index: 3;">
  <div class="menu">
    <div class="links">
      <ul>
        <li>
          <a href="https://www.tallinn.ee/et/andmekaitsetingimused" target="_blank"><span i18n class="mr-1">Processing of personal data</span> <i class="fa fa-external-link"></i></a>
        </li>
        <li>
          <div class="divider-wrapper">
            <div class="divider"></div>
          </div>
        </li>
        <li>
          <a href="#" (click)="toggleShowModal()" i18n>Introduction</a>
        </li>
      </ul>
    </div>
    <div class="language-select">
      <ul>
        <li *ngFor="let l of i18n">
          <a [href]="l?.baseUrl + router?.url" [ngClass]="{'active': l.name === locale || l.name === 'en' && locale === 'en-GB'}">{{l.name}}</a>
        </li>
      </ul>
    </div>
  </div>
</div>

<tallinn-vision-modal [show]="showModal" (onModalHide)="modalHide()"></tallinn-vision-modal>
