import {Component, Input, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {RoadWeatherSiteConstants, RoadWeatherSiteIndicator} from '../../model/road-weather-site-constants';
import { MeteorologyService } from '../../service/meteorology.service';
import { forkJoin, of } from 'rxjs';
import { Observable } from 'rxjs';
import {DataTable, DataTableField, DataTableRow, PersonSeries} from '../../model/road-weather-site-types';
import { RoadWeatherDataService } from '../../service/road-weather-data.service';
import { RoadWeatherConditionDataService } from '../../service/road-weather-condition-data.service';
import moment from 'moment/moment';
import {Subject} from 'rxjs/internal/Subject';
import {takeUntil} from 'rxjs/operators';
import {RoadWeatherSite} from '../../model/road-weather-site';


export interface RowsData {
  rows: RowData[]
}

export interface RowData {
  fields: Map<string, DataTableField>;
}

@Component({
  selector: '[road-weather-modal]',
  templateUrl: './road-weather-modal.component.html',
  providers: [
    { provide: RoadWeatherDataService, useClass: RoadWeatherConditionDataService },
  ]
})
export class RoadWeatherModalComponent implements OnInit, OnDestroy {

  private ngDestroy = new Subject<void>();

  private filteredColumns: string[] = ['precip-type', 'wind-speed-max'];
  @Input()
  public roadWeather: RoadWeatherSite;

  public roadWeatherIndicators: RoadWeatherSiteIndicator[] = [];

  public weatherConditionCodeMap = RoadWeatherSiteConstants.weatherConditionCodeMap;

  public loading = true;
  public data: DataTable = {rows: []};
  public updatedAt: Date;

  public rowsFieldsMappings: Map<string, DataTableField>[] = [];

  constructor(
    private personSeriesService: MeteorologyService,
    private roadWeatherDataService: RoadWeatherDataService
  ) {
  }

  ngOnInit(): void {
    this.roadWeatherIndicators = RoadWeatherSiteConstants.roadWeatherIndicators
      .filter(indicator => this.filteredColumns.indexOf(indicator.key) == -1);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.roadWeather) {

      const queries = [];
      if (this.roadWeather.observationsSeriesId) {
        queries.push(this.personSeriesService.getPersonSeriesByPersonSeriesId(this.roadWeather.observationsSeriesId));
      }

      if (this.roadWeather.forecastsSeriesId) {
        queries.push(this.personSeriesService.getPersonSeriesByPersonSeriesId(this.roadWeather.forecastsSeriesId));
      }

      const getFullInfo: Observable<any> = (queries.length > 0 ? forkJoin(queries) : of([]));

      this.rowsFieldsMappings = [];

      getFullInfo.pipe(takeUntil(this.ngDestroy)).subscribe((personSeriesList: PersonSeries[]) => {
        this.updatedAt = this.getUpdatedAt(personSeriesList);
        let data = this.roadWeatherDataService.mapRoadWeatherData(this.roadWeather, personSeriesList);

        for (let row of data.rows) {
          let rowData: Map<string, DataTableField> = new Map<string, DataTableField>();
          for (let field of row.fields) {
            if(field.value && !isNaN(field.value)) {
              field.value = Math.round(field.value * 10) / 10;
            }

            rowData.set(field.code, field);
          }
          this.rowsFieldsMappings.push(rowData);
        }

        this.data = data;
        this.loading = false;
      });
    }
  }

  public getKeys(fieldsMap: Map<string, DataTableField>): string[] {
    return Array.from(fieldsMap.keys());
  }
  public filterFields(fields: DataTableField[]): DataTableField[] {
    return fields.filter(field => this.filteredColumns.indexOf(field.code) == -1);
  }

  public getRowFieldByCode(row: DataTableRow, code: string): DataTableField {
    if (!row) return;

    return row.fields.find(field => field.code === code);
  }

  private getUpdatedAt(personSeriesList: PersonSeries[]): Date {
    let observationsUpdatedAt: Date = null;
    let forecastsUpdatedAt: Date = null;
    for (let ps of personSeriesList) {
      if (ps.personSeriesId == this.roadWeather.observationsSeriesId) {
        observationsUpdatedAt = this.getPersonSeriesUpdatedAt(ps);
        if (observationsUpdatedAt != null) {
          break;
        }
      }
    }

    if (observationsUpdatedAt == null) {
      for (let ps of personSeriesList) {
        if (ps.personSeriesId == this.roadWeather.forecastsSeriesId) {
          forecastsUpdatedAt = this.getPersonSeriesUpdatedAt(ps);
          if (forecastsUpdatedAt != null) {
            break;
          }
        }
      }
    }

    return observationsUpdatedAt || forecastsUpdatedAt;
  }

  private getPersonSeriesUpdatedAt(personSeries: PersonSeries): Date {
    if (!personSeries.updatedAt) {
      return null;
    }

    const start = moment(personSeries.updatedAt);
    const minutes = Math.floor(start.minute() / 10) * 10; // rounding to 10th minute
    return moment(start).minute(minutes).toDate();
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

}
