import {LocationMarker} from './location-marker';
import {SeriesLocation} from '../models/series-location';
import {GasMapService} from '../gas-map.service';
import {SeriesLocationType} from '../models/series-location-type.enum';
import LatLng = google.maps.LatLng;

export class SolarPanelsLayer {

  public markers: LocationMarker[] = [];
  private cityPortalType: string;
  private map: google.maps.Map;
  private gasMapService: GasMapService;

  constructor(map: google.maps.Map, gasMapService: GasMapService) {
    this.map = map;
    this.gasMapService = gasMapService;
  }

  updateLocations(items: SeriesLocation[]): void {
    this.clearMarkers();

    let lastValueSeriesCityPortalType = 'SOLAR_PANELS_KW';
    this.markers = [];
    items.forEach((item: SeriesLocation) => {
      let marker = new LocationMarker(
        item,
        new google.maps.LatLng(item.lat, item.lng),
        this.map,
        this.getSeriesByCityPortalType(item, lastValueSeriesCityPortalType),
        lastValueSeriesCityPortalType,
        this.gasMapService
      );
      this.markers.push(marker);
    });
  }

  selectLocation(location: SeriesLocation): void {
    this.markers.forEach((marker: LocationMarker) => {
      marker.selected = marker.building === location;
    });

    // if (location && location.type === SeriesLocationType.LOCATION) {
    //   this.map.panTo(new LatLng(location.lat, location.lng));
    // }
    this.map.panBy(0, 0);
  }

  getSeriesByCityPortalType(location: SeriesLocation, cityPortalType) {
    if (!location.series || location.series.length === 0) {
      return null;
    }
    for (const series of location.series) {
      if (series.cityPortalType === cityPortalType) {
        return series;
      }
    }
    return null;
  }

  showMarkers(): void {
    if (this.markers && this.markers.length > 0) {
      this.markers.forEach(value => {
        value.setMap(this.map);
      });
    }
  }

  clearMarkers(): void {
    if (this.markers && this.markers.length > 0) {
      this.markers.forEach(value => {
        value.setMap(null);
      });
    }
  }

}
