import {Component, Inject, LOCALE_ID, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Subject} from 'rxjs/internal/Subject';
import {DatatableComponent} from '@swimlane/ngx-datatable';
import {
  ConfirmModalComponent,
  ConstantParams,
  Location,
  LocationService,
  ParameterType,
  PersonParameter,
  PersonParameterResponse, PreventCloseModalService,
  ResizeService,
  SCREEN_SIZE
} from '@smartencity/core';
import {FormControl, FormGroup} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {SMARTENCITY_MYDATA_CONFIG} from '../../injection-tokens';
import {MyDataConfig} from '../../mydata-config.model';
import {NavbarService} from '../navbar/navbar.service';
import {CurrentLocationService} from '../../services/current-location.service';
import {BsModalService} from 'ngx-bootstrap/modal';
import {ToastrService} from 'ngx-toastr';
import {ParametersService} from '../../services/parameters.service';
import {Router} from '@angular/router';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';
import {debounceTime, startWith, take, takeUntil} from 'rxjs/operators';
import {ParametersEditModalComponent} from './edit-modal/parameters-edit-modal.component';
import {ComponentLoaderFactory} from 'ngx-bootstrap/component-loader';
import {PersonParameterApiService} from '../../http/person-parameter-api.service';

const selector = 'mydata-parameters';
let nextId = 0;

@Component({
  selector: 'app-parameters-list',
  templateUrl: './global-parameters.component.html',
  providers: [CurrentLocationService, BsModalService, ComponentLoaderFactory]
})
export class GlobalParametersComponent implements OnInit {

  id = `${selector}-${nextId++}`;
  private ngDestroy = new Subject<void>();

  @ViewChild('navActions', {static: true})
  public navTempalte: TemplateRef<any>;

  @ViewChild('table') table: DatatableComponent;
  public columns = {
    detailToggler: false,
    name: true,
    value: true,
    unit: true,
    updatedAt: true,
    actions: true
  };

  public location: Location;

  public rows = [];
  public totalElements = 0;
  public personParameters: PersonParameter[] = [];

  public spinnersCount = 0;

  public searchForm: FormGroup = new FormGroup({
    query: new FormControl()
  });

  public missingParameters: any[] = [];

  constructor(
    private http: HttpClient,
    @Inject(SMARTENCITY_MYDATA_CONFIG) private config: MyDataConfig,
    private navbarService: NavbarService,
    private currentLocationService: CurrentLocationService,
    private modalService: BsModalService,
    private preventCloseModalService: PreventCloseModalService,
    private toastr: ToastrService,
    private parameterService: ParametersService,
    private resizeService: ResizeService,
    private router: Router,
    private locationService: LocationService,
    private personParameterApi: PersonParameterApiService,
    @Inject(LOCALE_ID) public locale: string,
  ) {
  }

  ngOnInit() {
    combineLatest([
      this.currentLocationService.currentLocation$,
      this.searchForm.valueChanges.pipe(debounceTime(250), startWith({query: ''}))
    ]).pipe(takeUntil(this.ngDestroy)).subscribe(([location, filterValue]: [Location, any]) => {
      this.location = location;
      this.loadParameters(filterValue);
    });

    this.resizeService.onResize$.pipe(takeUntil(this.ngDestroy)).subscribe(this.adjustColumns.bind(this));

    this.navbarService.setActionsTemplate(this.navTempalte);
  }

  ngOnDestroy(): void {
    this.navbarService.setActionsTemplate(null);
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  loadParameters(filterParams: any): void {
    const params: any = this.getCommonQueryParams();
    params.query = filterParams.query;
    this.personParameterApi.getPersonParametersPage(params).subscribe(
      (data: PersonParameterResponse) => {
        this.personParameters = data.content;
        this.updateMissingParameters();
      }, err => {
        return err;
      }
    );
  }

  private getCommonQueryParams() {

    return this.location && (this.location.locationType || this.location.group) ? {
      // groupingTag: this.location.groupingTag ? this.location.groupingTag : '',
      groupId: this.location.group ? this.location.group.id : null,
      locationType: this.location.locationType ? this.location.locationType : '',
      address: this.location.address ? this.location.address : '',
      room: this.location.room ? this.location.room : '',
      apartment: this.location.apartment ? this.location.apartment : '',
      lat: this.location.lat ? this.location.lat + '' : '',
      lng: this.location.lng ? this.location.lng + '' : '',
      uuid: this.location.uuid ? this.location.uuid : ''
    } : {
      groupingTag: '',
      locationType: '',
      withoutSeriesGroup: true
    };
  }

  create(): void {
    this.createForType(null);
  }

  createForType(type: ParameterType): void {
    const personParameter = new PersonParameter();
    if (type !== null && type !== undefined) {
      personParameter.name = this.parameterService.getTypeLabel(type);
      personParameter.type = type;
    }
    this.edit(personParameter);
  }

  edit(pParameter: PersonParameter): void {
    const modalRef = this.preventCloseModalService.show(ParametersEditModalComponent, {
      initialState: {
        personParameter: pParameter,
        location: this.location
      }
    });

    modalRef.content.savedEmitter.pipe(takeUntil(this.ngDestroy), take(1)).subscribe((personParameter: PersonParameter) => {
      let location: any;
      if (personParameter.groups && personParameter.groups.length > 0 || personParameter.locationType) {
        location = {};
        location.group = personParameter.groups && personParameter.groups.length > 0 ? personParameter.groups[0]: null;
        location.locationType = personParameter.locationType;
        location.address = personParameter.address;
        location.apartment = personParameter.apartment;
        location.room = personParameter.room;
        location.adsOid = personParameter.adsOid;
        location.lat = personParameter.lat;
        location.lng = personParameter.lng;
        location.uuid = personParameter.uuid;
      }
      location = new Location(location);
      let navigate = null;
      if (location) {
        navigate = this.router.navigate([...this.locationService.getLocationNavigateCommands(location), 'parameters']);
      } else {
        navigate = this.router.navigate(['mydata', 'datapoints', 'parameters']);
      }
      if (navigate) {
        navigate.then((result) => {
          if (!result) {
            this.currentLocationService.setLocation(location);
          }
        });
      } else {
        this.currentLocationService.setLocation(location);
      }
    });
  }

  delete(row: PersonParameter) {
    const modalRef = this.modalService.show(ConfirmModalComponent, {
      ignoreBackdropClick: true,
      initialState: {
        description: $localize`Are you sure you want to delete the parameter?`,
        callback: (confirm: boolean) => {
          if (confirm) {
            this.personParameterApi.deletePersonParameter(row.id).subscribe((data: any) => {
              this.toastr.success($localize`Parameter deleted`);
              this.currentLocationService.reloadLocation();
            });
          }
        }
      }
    });
  }

  private updateMissingParameters() {
    this.missingParameters.length = 0;
    if (this.location && this.location.locationType === 'ADDRESS' && !this.location.apartment) {
      const keys = ConstantParams.buildingParams;
      for (const key of Object.keys(keys)) {
        const type = keys[key];
        const label = this.parameterService.getTypeLabel(type);

        const query = this.searchForm.get('query').value;
        if (query && !label.toLowerCase().includes(query.toLowerCase())) {
          continue;
        }

        if (this.personParameters.findIndex(value => value.type === type) === -1) {
          const params: any = this.getCommonQueryParams();
          params.type = type;
          this.personParameterApi.getPersonParametersPage(params).subscribe(
            (data: PersonParameterResponse) => {
              if (data.content.length === 0) {
                this.missingParameters.push({
                  type: type,
                  label: label,
                  required: ConstantParams.isRequiredBuildingParam(type)
                });
              }
            }, err => {
              return err;
            }
          );

        }
      }
    }
    this.updateRows();
  }

  private updateRows() {
    this.rows = this.missingParameters.map((mp) => ({
      name: mp.label,
      required: mp.required,
      type: mp.type
    } as any)).concat(this.personParameters);
    this.totalElements = this.rows.length;
  }

  clearSearch() {
    this.searchForm.get('query').setValue('');
  }

  toggleExpandRow(row) {
    this.table.rowDetail.toggleExpandRow(row);
  }

  private adjustColumns(size): void {
    if (size != null && size < SCREEN_SIZE.MD) {
      this.columns.detailToggler = true;
      this.columns.name = true;
      this.columns.value = false;
      this.columns.unit = false;
      this.columns.updatedAt = false;
      this.columns.actions = false;
    } else {
      this.columns.detailToggler = false;
      this.columns.name = true;
      this.columns.value = true;
      this.columns.unit = true;
      this.columns.updatedAt = true;
      this.columns.actions = true;
      if (this.table) {
        this.table.rowDetail.collapseAllRows();
      }
    }
  }

}
