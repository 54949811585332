<div class="modal-header">
  <h5 *ngIf="!widget.id" class="modal-title w-100" i18n>Create widget</h5>
  <h5 *ngIf="widget.id" class="modal-title w-100" i18n>Edit widget</h5>
  <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
    <span class="fal fa-times"></span>
  </button>
</div>
<div class="modal-body">
  <div *ngIf="!form.valid && validated" class="alert alert-danger">
    <div i18n>Form is invalid</div>
  </div>

  <div [formGroup]="form">
    <section>
      <div class="row">
        <div class="col-md-5 col-sm-12">
          <h3 i18n>Widget info</h3>
          <div class="form-group">
            <label [attr.for]="id + '-name'" i18n>Name</label>
            <input type="text" class="form-control" [class.is-invalid]="invalid('name')" [id]="id + '-name'" formControlName="name" placeholder="Widget name" i18n-placeholder>
            <small *ngIf="invalid('name') && errors('name')?.required" class="invalid-feedback d-block" i18n>Required</small>
          </div>

          <div *ngIf="dashboardOptions" class="form-group" formControlName="dashboardId" btnRadioGroup>
            <label i18n>Dashboard</label>
            <div class="d-block">
              <div class="btn-group btn-group-sm btn-separate" role="group">
                <ng-container *ngFor="let dashboardOption of dashboardOptions">
                  <button *ngIf="dashboardOption.ownerType === OwnerType.OWNER" type="button" class="btn btn-outline-lighter" [btnRadio]="dashboardOption.dashboard.id">
                    <ng-container *ngIf="dashboardOption.dashboard.type == 'DEFAULT'" i18n>Dashboard</ng-container>
                    <ng-container *ngIf="dashboardOption.dashboard.type == 'ENERGY_MANAGER'" i18n>Energy manager</ng-container>
                    <ng-container *ngIf="dashboardOption.dashboard.type == 'CUSTOM'">{{dashboardOption.dashboard.name}}</ng-container>
                  </button>
                </ng-container>
              </div>
            </div>
            <small *ngIf="invalid('dashboardId') && errors('dashboardId')?.required" class="invalid-feedback d-block" i18n>Required</small>
          </div>

          <div class="form-group" formControlName="type" btnRadioGroup>
            <label i18n>Type</label>
            <div class="d-block">
              <div class="btn-group btn-group-sm btn-separate" role="group">
                <button type="button" class="btn btn-outline-lighter" [btnRadio]="widgetTypeType.TIME_FRAME_CHART" i18n>Time frame chart</button>
                <button type="button" class="btn btn-outline-lighter" [btnRadio]="widgetTypeType.VALUE_CHART" i18n>Value chart</button>
                <button type="button" class="btn btn-outline-lighter" [btnRadio]="widgetTypeType.VALUE_TABLE" i18n>Value table</button>
              </div>
            </div>
            <small *ngIf="invalid('type') && errors('type')?.required" class="invalid-feedback d-block" i18n>Required</small>
          </div>


          <div *ngIf="form.get('type').value === 'VALUE_TABLE'">
            <div class="form-group" formControlName="timeframeType" btnRadioGroup>
              <label i18n>Timeframe type</label>
              <div class="d-block">
                <div class="btn-group btn-group-sm btn-separate" role="group">
                  <button *ngFor="let option of timeframeTypeOptions" type="button" class="btn btn-outline-lighter" [btnRadio]="option.value" i18n>{{option.label}}</button>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group" *ngIf="form.get('type').value === 'TIME_FRAME_CHART' || form.get('type').value === 'VALUE_TABLE'">
            <div class="row">
              <ng-container *ngIf="form.get('timeframeType').value === 'PERIOD'">
                <div class="col">
                  <label i18n>Period</label>
                  <ng-select placeholder="Period" i18n-placeholder [class.is-invalid]="invalid('periodType')" formControlName="periodType" [items]="periodTypeOptions" bindValue="value" bindLabel="label"></ng-select>
                  <small *ngIf="invalid('periodType') && errors('periodType')?.required" class="invalid-feedback d-block">Required</small>
                </div>
                <div class="col">
                  <label [attr.for]="id + '-period-count'" i18n>Period count</label>
                  <input type="text" class="form-control text-right" placeholder="Period count" i18n-placeholder [class.is-invalid]="invalid('periodCount')" [id]="id + '-period-count'" formControlName="periodCount">
                  <small *ngIf="invalid('periodCount') && errors('periodCount')?.required" class="invalid-feedback d-block" i18n>Required</small>
                </div>
                <div class="col">
                  <label i18n>Aggregation type</label>
                  <ng-select placeholder="Aggregation type" i18n-placeholder [class.is-invalid]="invalid('aggregationGroupingType')" [items]="aggregationGroupingTypeOptions" bindValue="value" bindLabel="label" formControlName="aggregationGroupingType"></ng-select>
                  <small *ngIf="invalid('aggregationGroupingType') && errors('aggregationGroupingType')?.required" class="invalid-feedback d-block" i18n>Required</small>
                </div>
              </ng-container>

              <ng-container *ngIf="form.get('timeframeType').value === 'DATE_RANGE'">
                <div class="col-auto">
                  <ngx-mat-drp class="rounded" (selectedDateRangeChanged)="updateRange($event)" [options]="dateRangePickerOptions" #dateRangePicker></ngx-mat-drp>
                </div>
              </ng-container>
            </div>
          </div>
          <div class="form-group" *ngIf="form.get('type').value === 'VALUE_CHART'">
            <div class="row">
              <div class="col">
                <label i18n>Chart type</label>
                <ng-select placeholder="Chart type" i18n-placeholder [class.is-invalid]="invalid('periodType')" formControlName="chartType" [items]="chartTypeOptions" bindValue="value" bindLabel="label"></ng-select>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-auto col-md-2 p-0 d-none d-md-block"><div style="width: 28px;"></div></div>
        <div class="col-md-5 col-sm-12">
          <div class="d-flex flex-column justify-content-center align-items-center">
            <ng-select class="btn-outline-primary mb-3" formControlName="chartColorScheme" [items]="chartColorSchemeOptions" bindValue="value" bindLabel="value" label="Chart color scheme" i18n-label placeholder="Chart color scheme" i18n-placeholder>
              <ng-template ng-label-tmp let-item="item">
                <span *ngFor="let color of item.colors" [ngStyle]="{'background-color': color}" style="border: 1px solid #ccc; margin: 1px; display: inline-block;height: 15px;width: 15px;"></span>
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-index="index">
                <div class="row">
                  <div class="col-6">{{item.value}}</div>
                  <div class="col-6 text-right">
                    <span *ngFor="let color of item.colors" [ngStyle]="{'background-color': color}" style="border: 1px solid #ccc; margin: 1px; display: inline-block;height: 15px;width: 15px;"></span>
                  </div>
                </div>
              </ng-template>
            </ng-select>
            <div class="w-100">
              <span i18n>Example preview:</span>
              <mydata-widget-preview [widget]="previewWidget" [form]="form"></mydata-widget-preview>
            </div>
            <div class="d-flex justify-content-center  mt-3">
              <ng-container *ngIf="form.get('type').value === 'VALUE_CHART' || form.get('type').value === 'TIME_FRAME_CHART'">
                <div class="checkbox mx-2">
                  <input type="checkbox" formControlName="showLegend">
                  <label i18n>Show legend</label>
                </div>
                <div class="checkbox mx-2">
                  <input type="checkbox" formControlName="showDataLabels">
                  <label i18n>Show data labels</label>
                </div>
                <div class="checkbox mx-2">
                  <input type="checkbox" formControlName="showAverageLine">
                  <label i18n>Show average line</label>
                </div>
              </ng-container>
              <div class="checkbox mx-2" *ngIf="form.get('type').value === 'VALUE_CHART' || form.get('type').value === 'TIME_FRAME_CHART'">
                <input type="checkbox" formControlName="showYAxes">
                <label i18n>Show Y axes</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <h3 i18n>Widget data</h3>
      <div class="selectable-list">
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="filter-bar">
              <div class="input-group form-search">
                <input type="text" class="form-control" [formControl]="searchControl" placeholder="Type to search..." i18n-placeholder>
                <div class="input-group-append">
                  <span class="clear-search" (click)="clearSearch()" [ngClass]="{'active': searchControl?.value?.length > 0 }">
                    <i class="fal fa-times"></i>
                  </span>
                  <div class="custom-dropdown filter-dropdown" dropdown [autoClose]="true" (click)="$event.stopImmediatePropagation();">
                    <button type="button" class="btn dropdown-toggle" dropdownToggle i18n>Filter</button>
                    <div class="dropdown-menu dropdown-menu-right" *dropdownMenu [formGroup]="filterForm">
                      <div class="accordion-group"  [ngClass]="'show'">
                        <div class="accordion-header">
                          <!-- <i class="fal fa-angle-down"></i> -->
                          <ng-container i18n>Dataset type</ng-container>
                        </div>
                        <div class="accordion-content" *ngFor="let datasetType of widgetService.datasetTypeOptions; let i = index">
                          <div class="checkbox">
                            <input type="checkbox" [checked]="isTypeFiltered(datasetType.value)" (change)="datasetTypeCheckboxChanged(datasetType.value, $event)">
                            <label>{{datasetType.label}}</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <span class="input-group-text">
                    <i class="fal fa-search"></i>
                  </span>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <button type="button" (click)="addCurrentPageDatasets()" class="btn-sm btn-link p-0" i18n>Select all</button>
                </div>
                <div class="col text-right">
                  <span class="text-light" i18n>{page?.totalElements, plural, =1 {{{page?.totalElements}} result} other {{{page?.totalElements}} results}}</span>
                </div>
              </div>
            </div>
            <div class="custom-list">
              <div class="list-item" [class.selected]="isSelected(dataset)" *ngFor="let dataset of page?.content" (click)="toggleDataSetSelect(dataset)">
                <span class="title">{{dataset.name}}</span>
                <div class="row">
                  <span class="col-auto">
                    <label class="light mr-2 mb-0" i18n>Type</label>
                    <span class="text-light">
                      <ng-container *ngIf="dataset.type == 'PERSON_SERIES'" i18n>Datapoint</ng-container>
                      <ng-container *ngIf="dataset.type == 'PERSON_PARAMETER'" i18n>Parameter</ng-container>
                      <ng-container *ngIf="dataset.type == 'Q11E_FIELD'" i18n>Question field</ng-container>
                      <ng-container *ngIf="dataset.type == 'Q11E_RESPONSE_FIELD'" i18n>Question response</ng-container>
                    </span>
                  </span>
                  <span class="col-auto">
                    <label class="light mr-2 mb-0" i18n>Location</label>
                    <span class="text-light">
                      <ng-container *ngIf="dataset.type == 'PERSON_SERIES'">{{dataset.location}}</ng-container>
                      <ng-container *ngIf="dataset.type == 'Q11E_FIELD' || dataset.type == 'Q11E_RESPONSE_FIELD'">{{dataset.q11eName}}</ng-container>
                    </span>
                  </span>
                  <span class="col-auto">
<!--                    <span *ngIf="!dataset.lastValueReceivedAt">-->
                         <label class="light mr-2 mb-0" i18n>Created time</label>
                        <span class="text-light">
                          <ng-container>{{dataset.createdAt|date:'dd.MM.yyyy HH:mm'}}</ng-container>
                      </span>
<!--                    </span>-->
<!--                    <span *ngIf="dataset.lastValueReceivedAt">-->
<!--                         <label class="light mr-2 mb-0" i18n>Last value time</label>-->
<!--                        <span class="text-light">-->
<!--                          <ng-container>{{dataset.lastValueReceivedAt|date:'dd.MM.yyyy HH:mm'}}</ng-container>-->
<!--                      </span>-->
<!--                    </span>-->
                  </span>
                </div>
              </div>
            </div>
            <div class="footer-bar">
              <div class="row">
                <div class="col">
                  <pagination
                    class="pagination-borderless"
                    *ngIf="page?.totalPages > 1"
                    (pageChanged)="pageChanged($event)"
                    [totalItems]="page.totalElements"
                    [itemsPerPage]="page.size"
                    [maxSize]="5"
                    [rotate]="true"
                    [formControl]="pageControl"
                    previousText="&lsaquo;"
                    nextText="&rsaquo;"
                    firstText="&laquo;"
                    lastText="&raquo;"
                  >
                  </pagination>
                </div>
<!--                <div class="col-auto">-->
<!--                  <button type="button" class="btn btn-sm btn-primary">Lisa valitud</button>-->
<!--                </div>-->
              </div>
            </div>
          </div>
          <div class="col-12 col-md-1 d-flex justify-content-center p-0">
            <span class="exchange-icon">
              <i class="fa fa-exchange"></i>
            </span>
          </div>
          <div class="col-12 col-md-5">
            <div class="filter-bar">
              <h3 i18n>Selected data</h3>
              <div class="row">
                <div class="col">
                  <button type="button" class="btn-sm btn-link p-0" *ngIf="rows.length" (click)="removeAll()" i18n>Remove all</button>
                </div>
                <div class="col text-right">
                  <span class="text-light" i18n>{rows.length, plural, =1 {{{rows.length}} item} other {{{rows.length}} items}}</span>
                </div>
              </div>
            </div>
            <div class="custom-list" cdkDropList (cdkDropListDropped)="drop($event)">
              <mydata-widget-row *ngFor="let row of rows; let i=index"
                                 [widgetType]="form.get('type').value"
                                 [chartType]="form.get('chartType').value"
                                 [row]="row" [isFirst]="i < 1" [isLast]="i >= rows.length - 1" (removeWidgetDataset)="removeWidgetDatasetRow(row)"></mydata-widget-row>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-light btn-sm" aria-label="Close" (click)="close()" i18n>Cancel</button>
  <button type="button" class="btn btn-success btn-sm" (click)="save()" [disabled]="isLoading" i18n>Save</button>
</div>
