<core-navbar></core-navbar>
<div *ngIf="weather" class="weather container-fluid">
  <div class="row my-5">
    <div class="col justify-content-center text-center">
      <div class="weather-location">
        <h1 class="location"><span>{{location?.name}}</span></h1>
        <span class="last-updated">
          <ng-container *ngIf="lastUpdated">
            <span class="help-text" i18n>Last updated {{lastUpdated|date:'dd.MM.yyyy HH:mm'}}</span>
            <span class="help-text ml-2 mr-2">&bull;</span>
          </ng-container>
          <span class="help-text"><a class="help-text" target="_blank" href="https://weatherstack.com">weatherstack.com</a></span>
        </span>

      </div>
      <div class="temp">
        <span class="current-temp">{{weather.temperature?.value}} <sup>o</sup>C</span>
        <span class="feels-temp">{{weather.temperatureFeelsLike?.value}} <sup>o</sup>C 
          <span class="help-text"><small i18n>Perceived temperature</small></span>
        </span>
      </div>
    </div>
  </div>
  <div class="row weather-tabs">
    <div class="col d-flex justify-content-evenly mx-auto">
      <div class="weather-item col-xs-4">
        <strong>{{weather.visibility?.value}} km</strong>
        <span class="weather-icon icon-visibility"></span>
        <span class="label" i18n>Visibility</span>
      </div>
      <div class="weather-item col-xs-4">
        <strong>{{weather.humidity?.value}} %</strong>
        <span class="weather-icon icon-humidity"></span>
        <span class="label" i18n>Humidity</span>
      </div>
      <div class="weather-item col-xs-4">
        <strong>{{weather.airPressure?.value}} mbar</strong>
        <span class="weather-icon icon-pressure"></span>
        <span class="label" i18n>Barometric pressure</span>
      </div>
      <div class="weather-item col-xs-4">
        <strong>{{weather.cloudy?.value}} %</strong>
        <span class="weather-icon icon-cloudy"></span>
        <span class="label" i18n>Cloudy</span>
      </div>
      <div class="weather-item col-xs-4">
        <strong>{{weather.precipitation?.value}} mm</strong>
        <span class="weather-icon icon-rain"></span>
        <span class="label" i18n>Precipitation</span>
      </div>
      <div class="weather-item col-xs-4">
        <strong>{{weather.windSpeed?.value}} km/h</strong>
        <span class="weather-icon icon-wind"></span>
        <span class="label" i18n>Wind</span>
      </div>
      <div class="weather-item col-xs-4">
        <strong>{{weather.windDegree?.value}} <sup>o</sup></strong>
        <span class="weather-icon icon-wind-direction"></span>
        <span class="label" i18n>Wind-direction</span>
      </div>
      <div class="weather-item col-xs-4">
        <strong>{{weather.temperature?.value}} <sup>o</sup>C</strong>
        <span class="weather-icon icon-temp"></span>
        <span class="label" i18n>Temperature</span>
      </div>
      <div class="weather-item col-xs-4">
        <strong>{{weather.temperatureFeelsLike?.value}} <sup>o</sup>C</strong>
        <span class="weather-icon icon-feelslike"></span>
        <span class="label" i18n>Perceived temperature</span>
      </div>
    </div>
  </div>
  <!--
  <h2 *ngIf="location">{{ location.name }}</h2>
  <div class="d-flex mb-5" *ngIf="current">
    <div class="d-flex align-items-center pr-5">
      <span weatherIcon="{{current.cloudcover}}" precip="current.precip"></span>
      <h1 class="m-0" *ngIf="optionsList.TEMPERATURE">{{current.temperature}}°</h1>
    </div>

    <div>
      <span *ngIf="optionsList.PRECIPITATION && current.precip != null">Precipitation: {{current.precip}}mm</span><br>
      <span *ngIf="optionsList.WIND && current.wind_speed != null"> Wind: {{current.wind_speed | kphToMs:'10'}} m/s</span><br>
      <span *ngIf="current.temperatureTime">Updated: {{current.temperatureTime | date:'HH:mm dd. MMMM yyyy'}}</span>
    </div>
  </div>-->
</div>
