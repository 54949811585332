import {ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl} from '@angular/forms';
import {Component, forwardRef, Injector, Input, OnInit} from '@angular/core';
import {noop} from 'rxjs';

@Component({
  selector: 'core-custom-dropdown-control',
  templateUrl: './custom-dropdown-control.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomDropdownControlComponent),
      multi: true
    }
  ]
})
export class CustomDropdownControlComponent implements ControlValueAccessor, OnInit {
  @Input()
  options: {value: any; label: string}[] = [];

  @Input()
  disabled = false;

  @Input()
  placeholder = $localize`Select one`;

  private value: any;
  public label: string;

  private onTouched: () => void = noop;
  private onChange: (_: any) => void = noop;

  public ngControl: NgControl;

  constructor(private inj: Injector) {
  }

  ngOnInit(): void {
    this.ngControl = this.inj.get(NgControl);
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  writeValue(value: any) {
    this.value = value;
    this.onChange(value);
    this.onTouched();
    this.updateSelectedLabel();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  select(value: any) {
    this.writeValue(value);
  }

  updateSelectedLabel() {
    const index = this.options.findIndex((e) => e.value === this.value);
    if (index < 0) {
      this.label = this.placeholder;
    } else {
      this.label = this.options[index].label;
    }
  }

}
