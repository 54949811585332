<div class="modal-header">
  <h5 class="modal-title w-100" i18n>Deleting</h5>
  <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
    <span class="fal fa-times"></span>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col">
      <p i18n><b>Are you sure you wish to delete data group {{this.group.name}}?</b></p>
      <p i18n>You can delete only data group or data group with data points inside.</p>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" (click)="confirm(true)" [ladda]="progress && deleteWithDataGroupsProgress" [disabled]="progress" class="btn btn-outline-secondary btn-sm" i18n>Delete with data points</button>
  <button type="submit" (click)="confirm()" [ladda]="progress && !deleteWithDataGroupsProgress" [disabled]="progress" class="btn btn-primary btn-sm" i18n>Delete only group</button>
</div>
