export interface IPerson {
  id: number;
  countryCode: string;
  registrationNumber: string;
  displayName: string;
  juridical: boolean;
  idPrivacyType: string;
  dob: string;
}

export class Person {
  id: number;
  countryCode: string;
  registrationNumber: string;
  displayName: string;
  juridical: boolean;
  idPrivacyType: string;
  dob: string;

  constructor(props?: IPerson) {
    if (!props) return;
    Object.assign(this, props);
  }

}

export class NotificationAddressesResponse {
  content: NotificationAddress[] = [];
}

export class NotificationAddress {
  id: number;
  name: string;
  email: string;
}

export class Token {
  id: number;
  primary = false;
  name: string;
  value: string;
  tokenPersons: TokenPerson[] = [];
}

export type TokePersonType = 'PERSON' | 'EMAIL' | 'REG_NO';

export class TokenPerson {
  id: number;
  assignType: TokePersonType;
  assignedAt: Date;
  email: string;
  countryCode: string;
  registrationNumber: string;
  person: Person;
}

export class TokenPersonHistory {
  persons: TokenPersonHistoryPerson[] = [];
}

export class TokenPersonHistoryPerson {
   person: TokenPerson;
   revokedAt: Date;
}
