<div class="modal-header">
  <h5 class="modal-title w-100" i18n>Create datapoint</h5>
  <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
    <span class="fal fa-times"></span>
  </button>
</div>

<div class="modal-body" [formGroup]="form">
  <div *ngIf="form.invalid && confirmed" class="alert alert-danger">
    <div i18n>Form is invalid</div>
  </div>

  <div class="form-group">
    <label i18n [attr.for]="id + '-name'">Name</label>
    <input type="text" class="form-control" [class.is-invalid]="invalid('name')" formControlName="name" [id]="id + '-name'">
    <small *ngIf="invalid('name') && errors('name')?.required" class="invalid-feedback d-block" i18n>Required</small>
  </div>

  <div class="form-group">
    <person-series-group-select formControlName="groups"></person-series-group-select>
    <small *ngIf="invalid('groups') && errors('groups')?.required" class="invalid-feedback d-block" i18n>Required</small>
  </div>

  <div class="form-group">
    <label><b i18n>Location type</b></label>
    <div class="form-row">
      <div class="custom-control custom-radio custom-control-inline">
        <input type="radio" class="custom-control-input" [class.is-invalid]="invalid('locationType')" [id]="id + '-ltcheck-address'" value="ADDRESS" formControlName="locationType">
        <label class="custom-control-label" [attr.for]="id + '-ltcheck-address'" i18n>Address</label>
      </div>

      <div class="custom-control custom-radio custom-control-inline">
        <input type="radio" class="custom-control-input" [class.is-invalid]="invalid('locationType')" [id]="id + '-ltcheck-uuid'" value="UUID" formControlName="locationType">
        <label class="custom-control-label" [attr.for]="id + '-ltcheck-uuid'" i18n>UUID</label>
      </div>

      <div class="custom-control custom-radio custom-control-inline">
        <input type="radio" class="custom-control-input" [class.is-invalid]="invalid('locationType')" [id]="id + '-ltcheck-coords'" value="COORDS" formControlName="locationType">
        <label class="custom-control-label" [attr.for]="id + '-ltcheck-coords'" i18n>Coords</label>
      </div>
    </div>
    <small *ngIf="invalid('locationType') && errors('locationType')?.required" class="invalid-feedback d-block" i18n>Required</small>
  </div>

  <div class="form-group" [hidden]="form.get('locationType').value != 'UUID'">
    <div class="form-row">
      <div class="col">
        <label i18n [attr.for]="id + '-uuid'">UUID</label>
        <input type="text" class="form-control" [class.is-invalid]="invalid('uuid')" formControlName="uuid" [id]="id + '-uuid'">
        <small *ngIf="invalid('uuid') && errors('uuid')?.required" class="invalid-feedback d-block" i18n>Required</small>
      </div>
    </div>
  </div>
  <div class="form-group" [hidden]="form.get('locationType').value != 'COORDS'">
    <div class="form-row">
      <div class="col">
        <label i18n [attr.for]="id + '-lat'">Lat</label>
        <input type="text" class="form-control" [class.is-invalid]="invalid('lat')" formControlName="lat" [id]="id + '-lat'">
        <small *ngIf="invalid('lat') && errors('lat')?.required" class="invalid-feedback d-block" i18n>Required</small>
      </div>
    </div>
  </div>
  <div class="form-group" [hidden]="form.get('locationType').value != 'COORDS'">
    <div class="form-row">
      <div class="col">
        <label i18n [attr.for]="id + '-lng'">Lng</label>
        <input type="text" class="form-control" [class.is-invalid]="invalid('lng')" formControlName="lng" [id]="id + '-lng'">
        <small *ngIf="invalid('lng') && errors('lng')?.required" class="invalid-feedback d-block" i18n>Required</small>
      </div>
    </div>
  </div>

  <ng-container *ngIf="form.get('locationType').value == 'ADDRESS'">
    <core-address [form]="form" [showErrors]="showErrors"></core-address>
  </ng-container>

  <div class="form-group">
    <div class="form-row">
      <div class="col-6">
        <label i18n [attr.for]="id + '-unit'">Data unit</label>
        <input type="text" class="form-control" [class.is-invalid]="invalid('unit')" formControlName="unit" [id]="id + '-unit'">
      </div>
      <small *ngIf="invalid('unit') && errors('unit')?.required" class="invalid-feedback d-block" i18n>Required</small>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary btn-sm" (click)="save(false)" [ladda]="progress && !addDataProgress" [disabled]="progress" i18n>Save</button>
  <button type="button" class="btn btn-success btn-sm" (click)="save(true)" [ladda]="progress && addDataProgress" [disabled]="progress" i18n>Add data</button>
</div>
