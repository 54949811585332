import {Inject, Injectable, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs/internal/Subject';
import {PageResponse, Questionnaire, QuestionnaireTypes} from '@smartencity/core';
import {SMARTENCITY_MYDATA_CONFIG} from '../../injection-tokens';
import {MyDataConfig} from '../../mydata-config.model';
import {HttpClient} from '@angular/common/http';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  shareReplay,
  startWith,
  switchMap,
  takeUntil
} from 'rxjs/operators';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';
import {FormControl, FormGroup} from '@angular/forms';
import {throwError} from 'rxjs/internal/observable/throwError';

@Injectable()
export class QuestionnairesListService implements OnDestroy {
  private ngDestroy = new Subject<void>();

  public q11eTypes: string[] = Object.values(QuestionnaireTypes);

  public page: PageResponse<Questionnaire>;
  public defaultLimit = 40;
  public defaultSorts = [{prop: 'createdAt', dir: 'desc'}];

  public filterForm: FormGroup = new FormGroup({
    query: new FormControl(),
    statusDraft: new FormControl(false),
    statusSent: new FormControl(false),
    statusCompleted: new FormControl(false),
    statusEdited: new FormControl(false)
//    statuses: new FormControl()
  });

  public filter$ = this.filterForm.valueChanges.pipe(distinctUntilChanged(), debounceTime(250), startWith({}));
  public sorts$: Subject<any> = new Subject<any>();

  public filterSorts$ = combineLatest([
    this.filter$,
    this.sorts$.pipe(startWith(this.defaultSorts))
  ]);

  public queryParams$ = this.filterSorts$.pipe(
    map(([filter, sorts]) => this.mapQueryParams(filter, sorts)),
    takeUntil(this.ngDestroy)
  );
  public load$: Subject<void> = new Subject();
  public questionnaires$ = combineLatest([this.queryParams$, this.load$]).pipe(
    switchMap(([queryParams,]: [any, any]) => {
      return this.fetchPage(queryParams);
    }),
    shareReplay(1),
    takeUntil(this.ngDestroy)
  );

  public constructor(
    @Inject(SMARTENCITY_MYDATA_CONFIG) private config: MyDataConfig,
    private http: HttpClient
  ) {
    this.questionnaires$.subscribe((data: PageResponse<Questionnaire>) => {
      this.page = data;
    });
  }

  ngOnDestroy(): void {
    this.load$.complete();
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  mapQueryParams(filter: any, sorts: any[]) {
    const queryParams: any = {};
    if (filter) {
      if (filter.seriesTypes) {
        queryParams.seriesTypes = filter.seriesTypes.join(',');
      }
    }
    if (filter.query) {
      queryParams.query = filter.query;
    }
//    const statuses = filter.statuses ? filter.statuses : [];
    const statuses = [];
    if (filter.statusDraft && statuses.indexOf('DRAFT') == -1) {
      statuses.push('DRAFT');
    }
    if (filter.statusSent && statuses.indexOf('SENT') == -1) {
      statuses.push('SENT');
    }
    if (filter.statusCompleted && statuses.indexOf('COMPLETED') == -1) {
      statuses.push('COMPLETED');
    }
    if (filter.statusEdited && statuses.indexOf('EDITED') == -1) {
      statuses.push('EDITED');
    }

    if (statuses.length) {
      queryParams.statuses = statuses.join(',');
    }

    if (sorts && sorts.length > 0) {
      queryParams.sortFields = sorts.reduce((accumulator, currentValue) => {
        if (currentValue.dir === 'desc') {
          return accumulator + encodeURIComponent('-' + currentValue.prop);
        } else {
          return accumulator + '+' + currentValue.prop;
        }
      }, '');
    }

    return queryParams;
  }

  fetchPage(queryParams: any) {
    const params = Object.assign({types: this.q11eTypes}, queryParams);
    for (const i of Object.keys(params)) {
      if (params[i] == null) {
        delete params[i];
      }
    }

    return this.http.get<PageResponse<Questionnaire>>(this.config.apiUrl + '/questionnaire', {
      params: params
    }).pipe(catchError((err) => {
      return throwError(err);
    }));
  }

  public setTypes(q11eTypes: string[]) {
    this.q11eTypes = q11eTypes;
  }

  public load() {
    this.load$.next();
  }
}
