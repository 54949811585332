import {AbstractMapLayer, OlMapWrapper} from '@smartencity/core';
import {WifiAccessPointsOlMarker} from './wifi-access-points-ol-marker';
import {WifiAccessPoint} from '../model/wifi-access-point';

export class WifiAccessPointsOlLayer extends AbstractMapLayer<OlMapWrapper, WifiAccessPoint>{

  private wifiAccessPoints: WifiAccessPoint[];

  constructor(wifiAccessPoints: WifiAccessPoint[]) {
    super();
    this.wifiAccessPoints = wifiAccessPoints;
  }

  setMap(map: OlMapWrapper): void {
    super.setMap(map);
    this.resetMarkers();
    if (map) {
      this.createMarkers();
    }
  }

  protected resetMarkers(): void {
    let markers = this.getMarkers();
    if (markers.length > 0) {
      for (const marker of markers) {
        marker.setMap(null);
      }
    }
    this.clearMarkers();
  }

  protected createMarkers(): void {
    for (const wifiAccessPoint of this.wifiAccessPoints) {
      this.addMarker(new WifiAccessPointsOlMarker(wifiAccessPoint, this.map));
    }
  }

}
