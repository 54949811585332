import {AbstractMapLayer, OlMapWrapper} from '@smartencity/core';
import {NoiseLevel} from '../component/noise-level-heatmap/noise-level-heatmap.model';
import {NoiseLevelOlMarker} from './noise-level-ol-marker';

export class NoiseLevelOlLayer extends AbstractMapLayer<OlMapWrapper, NoiseLevel>{

  private noiseLevels: NoiseLevel[];

  constructor(noiseLevels: NoiseLevel[]) {
    super();
    this.noiseLevels = noiseLevels;
  }

  setMap(map: OlMapWrapper): void {
    super.setMap(map);
    this.resetMarkers();
    if (map) {
      this.createMarkers();
    }
  }

  protected resetMarkers(): void {
    let markers = this.getMarkers();
    if (markers.length > 0) {
      for (const marker of markers) {
        marker.setMap(null);
      }
    }
    this.clearMarkers();
  }

  protected createMarkers(): void {
    for (const point of this.noiseLevels) {
      this.addMarker(new NoiseLevelOlMarker(point, this.map));
    }
  }

}
