import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {
  ConsentService,
  CoreConfig, ErrorResponseHelper, LoggerService,
  Person, PersonRegistrationNumberValidator, PersonSeriesApiService, PreventCloseAware,
  SMARTENCITY_CORE_CONFIG,
  SourceOwnerSeries,
  UserService
} from '@smartencity/core';
import {Subject} from 'rxjs/internal/Subject';
import {catchError, debounceTime, distinctUntilChanged, map, switchMap, takeUntil} from 'rxjs/operators';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {ToastrService} from 'ngx-toastr';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import moment from 'moment';
import {Observable} from 'rxjs/internal/Observable';
import {of} from 'rxjs/internal/observable/of';
import {forkJoin} from 'rxjs/internal/observable/forkJoin';

@Component({
  selector: 'app-create-consent-bulk-modal',
  templateUrl: './create-consent-bulk-modal.component.html'
})
export class CreateConsentBulkModalComponent implements OnInit, PreventCloseAware {
  private ngDestroy = new Subject<void>();

  seriesList: SourceOwnerSeries[] = [];

  @Output('saved')
  savedEmitter: EventEmitter<any> = new EventEmitter<any>();

  personTypeahead$ = new Subject<string>();

  personItems$ = this.personTypeahead$.pipe(
    takeUntil(this.ngDestroy),
    distinctUntilChanged(),
    debounceTime(250),
    switchMap((term: string) => this.userService.searchPersons(term)),
    map((persons: Person[]) => {
      this.consentForm.markAsDirty();

      return persons.map((e) => ({
        id: e.id,
        countryCode: e.countryCode,
        registrationNumber: e.registrationNumber,
        displayName: e.displayName,
        juridical: e.juridical,
        idPrivacyType: e.idPrivacyType,
        dob: e.dob
      }));
    })
  );

  public showValidation = false;

  public consentForm: FormGroup;

  public errors: string[] = [];

  public createPerson = (registrationNumber: string) => {
    return {
      id: null,
      countryCode: this.config.defaultCountryCode,
      registrationNumber: registrationNumber,
      displayName: null
    };
  };

  constructor(
    @Inject(SMARTENCITY_CORE_CONFIG) private config: CoreConfig,
    public modalRef: BsModalRef,
    public toastr: ToastrService,
    public consentService: ConsentService,
    private personSeriesService: PersonSeriesApiService,
    public personRegistrationNumberValidator: PersonRegistrationNumberValidator,
    private loggerService: LoggerService,
    private userService: UserService) { }

  ngOnInit(): void {

    this.consentForm = new FormGroup({
      person: new FormControl(null, [Validators.required, this.personRegistrationNumberValidator.check.bind(this.personRegistrationNumberValidator)]),
      customStartAt: new FormControl(moment().startOf('day').toDate(), [Validators.required])
    });

    this.personTypeahead$.pipe(takeUntil(this.ngDestroy)).subscribe((value) => {
      if (value) {
        const person = this.createPerson(value);
        this.consentForm.get('person').setValue(person);
      }
    });

  }

  createConsents(): void {
    this.consentForm.markAsDirty();
    if (!this.consentForm.valid) {
      console.error('Form is invalid', this.consentForm);
      this.showValidation = true;
      return;
    }

    const data = {
      id: this.consentForm.value.person.id,
      countryCode: this.consentForm.value.person.countryCode ? this.consentForm.value.person.countryCode.trim() : this.config.defaultCountryCode,
      registrationNumber: this.consentForm.value.person.registrationNumber ? this.consentForm.value.person.registrationNumber.trim() : null,
      customStartAt: this.consentForm.value.customStartAt
    };

    const toForkJoin: Observable<any>[] = [];
    for (let series of this.seriesList) {
      toForkJoin.push(this.consentService.setConsent(series.personSeries.id, data).pipe(catchError((error: any) => {
        return of({error: error, personSeries: series.personSeries});
      })));
    }

    this.errors = [];
    forkJoin(toForkJoin.length ? toForkJoin : of([])).subscribe((data: any[]) => {


      for (let item of data) {
        if (item.error) {
          let reason = ErrorResponseHelper.getErrorReason(item.error);
          this.errors.push(item.personSeries.name + " - " + reason);
        }
      }

      if (this.errors.length > 0) {
        let message = $localize`Sharing of some or all datapoints failed.`;
        this.toastr.error(message);
        return;
      }

      this.resetFormState();

      this.modalRef.hide();
      this.savedEmitter.emit();
      this.toastr.success($localize`Datapoints shared`);
    }, (error) => {
      // console.log(error);
      // let reason = ErrorResponseHelper.getErrorReason(error);
      // console.log(reason);
      // console.log("ERROR");
      // let message = $localize`Sharing of some or all datapoints failed. Reason: ${reason}`;
      // this.toastr.error(message);
    }, () => {

    });
  }

  close(): void {
    this.modalRef.hide();
  }

  isPreventClose(): boolean {
    return this.consentForm && this.consentForm.dirty;
  }

  private resetFormState(): void {
    this.consentForm.markAsUntouched();
    this.consentForm.markAsPristine();
  }

}
