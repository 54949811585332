<!--<div class="row">-->
<!--  <div class="col">-->
<!--    <h3 class="section-title">2 vastust</h3>-->
<!--  </div>-->
<!--  <div class="col-auto">-->
<!--    <button class="btn btn-sm btn-link p-0">Lae tulemused alla</button>-->
<!--  </div>-->
<!--</div>-->

<div class="d-flex mb-2">
  <label class="mr-3 col-form-label-other" i18n>View</label>
  <div class="d-block">
    <div class="btn-group btn-group-sm btn-separate">
      <button type="button" class="btn btn-outline-lighter" [class.active]="view === 'summary'" (click)="setView('summary')" i18n>Summary</button>
      <button type="button" class="btn btn-outline-lighter" [class.active]="view === 'individual'" (click)="setView('individual')" i18n>Individual</button>
    </div>
  </div>
</div>

<mydata-view-results-summary *ngIf="view == 'summary'" [questionnaire]="questionnaire"></mydata-view-results-summary>
<mydata-view-results-individual *ngIf="view == 'individual'" [questionnaire]="questionnaire"></mydata-view-results-individual>
