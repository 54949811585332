import {Component, OnDestroy, OnInit} from '@angular/core';
import {NotificationAddress, NotificationAddressesResponse, UserService} from "@smartencity/core";
import {take, takeUntil} from "rxjs/operators";
import {BsModalService} from "ngx-bootstrap/modal";
import {NotificationAddressEditModalComponent} from "./edit-modal/notification-address-edit-modal.component";
import {Subject} from "rxjs";

@Component({
  selector: 'app-notification-addresses',
  templateUrl: './notification-addresses.component.html'
})
export class NotificationAddressesComponent implements OnInit, OnDestroy {
  private ngDestroy = new Subject<void>();

  public notificationAddresses: NotificationAddress[];

  constructor(public userService: UserService, private modalService: BsModalService) {}

  ngOnInit() {
    this.reload();
  }

  private reload(){
    this.userService.getNotificationAddresses().subscribe((value: NotificationAddressesResponse) => {
      this.notificationAddresses = value.content;
    });
  }

  add(){
    this.edit(new NotificationAddress());
  }

  edit( notificationAddress: NotificationAddress){
    const modalRef = this.modalService.show(NotificationAddressEditModalComponent, {
      ignoreBackdropClick: true,
      initialState: {
        notificationAddress: notificationAddress
      }
    });

    modalRef.content.savedEmitter.pipe(takeUntil(this.ngDestroy), take(1)).subscribe((saved) => {
      this.reload();
    });
  }

  delete(notificationAddress: NotificationAddress){
    this.userService.deleteNotificationAddress(notificationAddress).subscribe(value => {
      this.reload();
    });
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }
}
