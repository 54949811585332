import {Component, Inject, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {SMARTENCITY_CORE_CONFIG, CoreConfig, CityApiService} from '@smartencity/core';
import moment, {Moment} from 'moment';


class Weather {
  airPressure: Measurement;
  cloudy: Measurement;
  humidity: Measurement;
  precipitation: Measurement;
  temperature: Measurement;
  temperatureFeelsLike: Measurement;
  visibility: Measurement;
  windDegree: Measurement;
  windSpeed: Measurement;
}

class Measurement {
  time: string;
  unit: string;
  value: number|string;
}


@Component({
  selector: 'weather-main',
  templateUrl: './weather.component.html',
  encapsulation: ViewEncapsulation.None
})
export class WeatherComponent implements OnInit, OnDestroy {
  public optionsList = {
    TEMPERATURE: true,
    PRECIPITATION: true,
    WIND: true
  };

  public location: any;
  public current: any;
  public weather: Weather = <Weather>{
    airPressure: {
      value: '-'
    },
    humidity: {
      value: '-'
    },
    cloudy: {
      value: '-'
    },
    precipitation: {
      value: '-'
    },
    temperature: {
      value: '-'
    },
    temperatureFeelsLike: {
      value: '-'
    },
    visibility: {
      value: '-'
    },
    windDegree: {
      value: '-'
    },
    windSpeed: {
      value: '-'
    },
  };

  lastUpdated: Date;

  constructor(
    @Inject(SMARTENCITY_CORE_CONFIG) public config: CoreConfig,
    private cityService: CityApiService
  ) {

  }

  ngOnInit() {
    this.loadForecast();
  }

  ngOnDestroy() { }

  private loadForecast() {
    if (this.config.weatherService === 'weather') {
      this.cityService.getWeather().subscribe(
        (data: Weather) => {
          this.weather = Object.assign(this.weather, data);
          this.location = {name: this.config.locationName};
          if (this.weather.temperature != null && this.weather.temperature.value != null && this.weather.temperature.time) {
            this.lastUpdated = this.formatToLastUpdatedDate(this.weather.temperature.time);
          }
        },
        (err: any) => {
        }
      );
    } else {
      this.cityService.getForecast().subscribe(
        (data: any) => {
          this.current = data?.current;
          this.location = data?.location;

          if (data && data.current) {
            this.mapForecastResultToWeather(data.current);

            if (data.time) {
              this.lastUpdated = this.formatToLastUpdatedDate(data.time);
            }
          }


        },
        (err: any) => {}
      );
    }
  }

  private formatToLastUpdatedDate(time: string): Date {

    return moment(time).add(30, 'minutes').startOf('hour').toDate();
  }

  private mapForecastResultToWeather(data: any): void {
    const weather: Weather = {
      temperature: data.temperature !== undefined ? { value: data.temperature, unit: '', time: data.time } : null,
      temperatureFeelsLike: data.feelslike !== undefined ? { value: data.feelslike, unit: '', time: data.time } : null,
      airPressure: data.pressure !== undefined ? { value: data.pressure, unit: '', time: data.time } : null,
      humidity: data.humidity !== undefined ? { value: data.humidity, unit: '', time: data.time } : null,
      visibility: data.visibility !== undefined ? { value: data.visibility, unit: '', time: data.time } : null,
      windSpeed: data.wind_speed !== undefined? { value: data.wind_speed, unit: '', time: data.time } : null,
      windDegree: data.wind_degree !== undefined ? { value: data.wind_degree, unit: '', time: data.time } : null,
      cloudy: data.cloudcover !== undefined ? { value: data.cloudcover, unit: '', time: data.time } : null,
      precipitation: data.precip !== undefined ? { value: data.cloudcover, unit: '', time: data.time } : null,
    };

    this.weather = Object.assign({}, this.weather, weather);
  }

}
