import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {CoreConfig, SMARTENCITY_CORE_CONFIG} from '@smartencity/core';

@Injectable()
export class ComponentsConfigService {

  constructor(@Inject(SMARTENCITY_CORE_CONFIG) public config: CoreConfig) {
  }

  public canLoad(componentPath: string): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      if (!componentPath) {
        observer.next(true);
        return;
      }

      if (componentPath === "managed-sources") {
        const appName = this.config.appName;
        const enabled = !appName || !appName.includes("nuti");
        return observer.next(enabled);
      }

      const componentsConfig = this.config.components;
      let val = true;
      if (componentsConfig) {
        const parts = componentPath.split(".");
        val = this.getValue(parts, componentsConfig, 0);
      }
      observer.next(val);
    })
  }

  private getValue(parts: string[], config: any, level: number): boolean {
    const prop = parts[level];
    let result = true;
    if (config.hasOwnProperty(prop)) {
      let val = config[prop];
      if (typeof val === 'object') {
        level++;
        result = this.getValue(parts, val, level);
      } else {
        result = config[prop];
      }
    }
    return result;
  }

}
