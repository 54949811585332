import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {TenantService} from '../../services/tenant.service';
import {ContractInfo, LoggerService, Tenant} from '@smartencity/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {Subject} from 'rxjs/internal/Subject';
import {takeUntil} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {TenantContractRequest} from '../../models/tenant/tenant-contract-request';


@Component({
  selector: 'mydata-tenant-contract',
  templateUrl: './tenant-contract.component.html'
})
export class TenantContractComponent implements OnInit, OnDestroy {
  private ngDestroy = new Subject<void>();

  public errorCode: any;
  public loading = false;

  public contractInfo: any = null;

  @Input()
  public callback: (result: Tenant) => void;

  contractForm: FormGroup = new FormGroup({
    email: new FormControl(null, [Validators.required, Validators.email])
  });

  constructor (
    public modalRef: BsModalRef,
    public tenantService: TenantService,
    public toastr: ToastrService,
    public loggerService: LoggerService
  ) {
    this.tenantService.getContractInfo().pipe(takeUntil(this.ngDestroy)).subscribe((contractInfo: ContractInfo) => {
      this.contractInfo = contractInfo;
    });
  }

  ngOnInit () { }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  cancel () {
    if (this.callback) {
      this.callback(null);
    }
    this.modalRef.hide();
  }

  submitOrder(): void {
    this.contractForm.markAllAsTouched();
    if (!this.contractForm.valid) {
      console.error("Form invalid", this.contractForm);
      return;
    }

    const formValue = this.contractForm.getRawValue();
    this.confirmTenantOrder(new TenantContractRequest({
      email: formValue.email
    }));
  }

  private confirmTenantOrder(request: TenantContractRequest) {
    this.loading = true;

    this.tenantService.createContractTenant(request).pipe(takeUntil(this.ngDestroy)).subscribe((tenant: Tenant) => {
      this.loading = false;

      this.toastr.success('Personal tenant created');

      if (this.callback) {
        this.callback(tenant);
      }

      this.modalRef.hide();
    }, (err: any) => {
      this.loggerService.error(err);
      this.toastr.error('Personal tenant creation failed');
      this.loading = false;
      this.errorCode = err;
    });
  }

  invalid(name: string, formGroup?) {
    if (!formGroup) {
      formGroup = this.contractForm;
    }
    const control = formGroup.get(name);
    return control && control.invalid && (control.dirty || control.touched);
  }

}
