import {Inject, Injectable, Injector, LOCALE_ID, NgZone, OnDestroy} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Subject} from 'rxjs';
import {TermsModalComponent} from '../old-components/terms/terms-modal.component';
import {NgxPermissionsService} from 'ngx-permissions';
import {Mandate, MandatePerson, Profile} from '../models/profile';
import {SMARTENCITY_CORE_CONFIG} from '../injection-tokens';
import {CoreConfig} from '../core-config.model';
import {Person} from '../models/person';
import {catchError, distinctUntilChanged, map, share, shareReplay, take, takeUntil, tap} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import {CprModalComponent} from '../old-components/cpr-modal/cpr-modal.component';
import {BsModalService} from 'ngx-bootstrap/modal';
import {throwError} from 'rxjs/internal/observable/throwError';
import {LoggerService} from '../services/logger.service';
import {AfterLoginRedirectStateService} from '../services/after-login-redirect-state.service';
import {LocationService} from '../services/location.service';
import {TaraAuthService} from './tara/tara-auth.service';
import {UrlBuilder} from '../utils/url-builder';
import {Observable} from 'rxjs/internal/Observable';
import {PkceUtil} from '../utils/pkce-util';
import {NemLogInAuthService} from './nem-log-in/nem-log-in-auth.service';
import {CookieService} from 'ngx-cookie';
import {CookieConstants} from '../services/cookie/cookie-constants';
import {CacheService} from '../cache/cache.service';
import {AuthConstants} from './auth-constants';
import {GlobalBroadcastService} from '../services/global-broadcast.service';
import {GlobalBroadcastEventType} from '../models/global-broadcast-event-type';
import {GlobalBroadcastEvent} from '../models/global-broadcast-event';
import {RandomStringGenerator} from '../utils/random-string-generator';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {IdpAuthService} from './idp-auth.service';
import {DemoAuthService} from './demo/demo-auth.service';

export class UrlAuthResult {
  result: boolean;
  reason: any;
  skipRedirect? = false;
  redirectTo?: string;
}

export class AuthParams {
  targetUrlPath?: string;
  currentUrlPath?: string;
  refresh? = false;
  token?: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {
  private ngDestroy = new Subject<void>();

  private isInitialStateLoaded = false;

  public isAuthenticated = false;

  public authModalRef = null;

  public token: string;
  public profileData: any;

  private loginMode = this.config.loginMode;
  public tokenKey = 'x-auth-token';
  public roleKey = 'user_person';
  public loginModeKey = 'login_mode';
  public role: MandatePerson;

  public profileKey = 'current_session_state';

  private _currentMandate = new BehaviorSubject<any>(null);

  public currentMandate$ = this._currentMandate.asObservable().pipe(distinctUntilChanged(), shareReplay(1));

  private roleChangeSource = new Subject<any>();

  public roleChange$ = this.roleChangeSource.asObservable().pipe(distinctUntilChanged(), shareReplay(1));

  // Tracks sso onLogin -> ssoApiLogin/nemIdApiLogin results
  private loginResult$ = new Subject<boolean>();

  // Tracks successful logins and logouts by user (ex. sso modal close)
  private authenticationResultSource = new Subject<UrlAuthResult>();
  public authenticationResult$ = this.authenticationResultSource.asObservable().pipe(takeUntil(this.ngDestroy), share());

  private urlAuthResultSource = new Subject<UrlAuthResult>();
  public urlAuthResult$ = this.urlAuthResultSource.asObservable().pipe(takeUntil(this.ngDestroy));

  private nemLogInAuthResultSource = new Subject<UrlAuthResult>();
  public nemLogInAuthResult$ = this.nemLogInAuthResultSource.asObservable().pipe(takeUntil(this.ngDestroy));

  nemIdMessageHandler = null;
  nemIdParams = null;
  taraMessageHandler = null;

  private authInit = false;

  private logoutInProgress = false;

  private sourceTab: string;

  private idpAuthService: IdpAuthService;

  constructor(
    @Inject(SMARTENCITY_CORE_CONFIG) private config: CoreConfig,
    private globalBroadcastService: GlobalBroadcastService,
    private http: HttpClient,
    private injector: Injector,
    private permissionsService: NgxPermissionsService,
    private loggerService: LoggerService,
    private afterLoginRedirectStateService: AfterLoginRedirectStateService,
    private locationService: LocationService,
    private taraAuthService: TaraAuthService,
    private nemLogInAuthService: NemLogInAuthService,
    private demoAuthService: DemoAuthService,
    @Inject(LOCALE_ID) public locale: string,
    private cookieService: CookieService,
    private cacheService: CacheService,
    private zone: NgZone
  ) {
    this.init();
  }

  public getIdpProviders(): string[] {
    return this.config.idpProviders;
  }

  /**
   *
   * loggedInUser.asObservable();
   * user$ = loggedInUser.asObservable(); observable
   *
   * ja siis isLoggedIn$ = $user.pipe(filter(user => !!user)) ??
   *
   *
   */

  // checkIsLoggedIn(): Observable<any> {
  //
  //   return timer(0, 5000).pipe(
  //     switchMap(() => this.getUserProfile()),
  //     filter(isLoggedIn => {
  //       console.log("isLoggedIn", isLoggedIn);
  //       return false;
  //     }),
  //     take(1)
  //   );
  // }
  //
  // private getUserProfile(): Observable<any> {
  //   return this.http.get<any>(`${this.config.apiUrl}/user/profile`, {
  //     observe: 'response'
  //   });
  // }

  private init(): void {
    this.sourceTab = RandomStringGenerator.randomString(10); //TODO tabservice?

    this.authenticationResult$.subscribe((result) => {
      this.isAuthenticated = result.result;
    });

    this.registerUserInfoBroadCastListener();
    this.registerLogoutListener();
  }

  private registerUserInfoBroadCastListener(): void {
    this.globalBroadcastService.subscribeToEvent(GlobalBroadcastEventType.SWITCH_PERSON, this.handleUserInfoMessage.bind(this));
  }

  private registerLogoutListener(): void {
    this.globalBroadcastService.subscribeToEvent(GlobalBroadcastEventType.LOGOUT, (event) => {
      if (this.logoutInProgress) {
        return;
      }

      if (event.data.source == this.sourceTab) {
        return;
      }

      this.doLogout();
    });
  }

  private async handleUserInfoMessage(message: MessageEvent<GlobalBroadcastEvent>): Promise<void> {
    const currentUrl = window.location.protocol + "//" + window.location.host;
    if (message.origin !== currentUrl) {
      return;
    }

    let event = message.data;
    if (!event.data) {
      return;
    }

    if (event.source === this.sourceTab) {
      return;
    }

    let changed = message.data && this.role && event.data.registrationNumber != this.role.registrationNumber;
    if (changed) {
      await this.updateRole(event.data);
      this.roleChangeSource.next(message.data);
    }

  }

  ngOnDestroy(): void {
    if (this.nemIdMessageHandler) {
      window.removeEventListener('message', this.nemIdMessageHandler);
      this.nemIdMessageHandler = null;
    }

    if (this.taraMessageHandler) {
      window.removeEventListener('message', this.taraMessageHandler);
      this.taraMessageHandler = null;
    }

    this._currentMandate.complete();
    this.loginResult$.complete();
    this.authenticationResultSource.complete();

    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  private isLoginModeEnabled(loginMode: string) {
    let idpProviders = this.getIdpProviders();

    return idpProviders.indexOf(loginMode) > -1;
  }

  public getStoredLoginMode(): string {
    return this.cacheService.getValue(AuthConstants.LOGIN_MODE_KEY);
  }

  public setLoginMode(loginMode: string, override?: boolean): void {
    if (!this.isLoginModeEnabled(loginMode)) {
      console.log("is not enabled", loginMode);
      return;
    }

    const storedLoginMode = this.getStoredLoginMode();
    if (!storedLoginMode || override) {
      this.cacheService.setValue(AuthConstants.LOGIN_MODE_KEY, loginMode);
    }

    this.loginMode = this.cacheService.getValue(AuthConstants.LOGIN_MODE_KEY);

    switch (this.loginMode) {
      case 'tara':
        this.idpAuthService = this.taraAuthService;
        break;
      case 'nem-log-in':
      case 'nem-id':
        this.idpAuthService = this.nemLogInAuthService;
        break;
      case 'demo':
        this.idpAuthService = this.demoAuthService;
        break;
    }
  }

  public getLoginMode(): string {
    return this.loginMode;
  }

  public async onLogin(ssoData): Promise<void> {
    if (!ssoData.auth) {
      this.clearAuthentication();
      this.loginResult$.next(false);
      return;
    }

    if (!ssoData.auth.person.personalCode) {
      this.clearAuthentication();
      this.loggerService.error('onLogin: NO_PERSONAL_CODE_ERROR');
      console.error('onLogin: NO_PERSONAL_CODE_ERROR');
      if (this.authModalRef) {
        this.authModalRef.componentInstance.errorcode = 'NO_PERSONAL_CODE_ERROR';
      }
      this.loginResult$.next(false);
      return;
    }

    try {
      await this.ssoApiLogin(ssoData);
    } catch (e) {
      console.error(e);
      this.loggerService.error(e);
      this.loginResult$.next(false);
      this.authenticationResultSource.next({result: false, reason: e});
      if (this.authModalRef) {
        this.authModalRef.content.dismissWithError(e);
        this.authModalRef = null;
      }
    }
  }

  public async getAuthenticationStatus(authParams: AuthParams): Promise<boolean> {
    if (this.isAuthenticated) {
      return true;
    }

    return this.authenticate(authParams);
  }

  public async authenticate(authParams: AuthParams): Promise<boolean> {
    if (this.authInit) {
      // already started auth
      return false;
    }

    if (!this.authInit) {
      this.authInit = true;
    }

    if (authParams.refresh) {
      this.token = null;
      this.cacheService.removeValue(AuthConstants.TOKEN_KEY);
    }

    if (await this.getLoginState()) {
      this.authInit = false;
      if (this.isResetLoginState()) {
        return this.resetLoginState();
      }

      return true;
    }

    this.afterLoginRedirectStateService.doAfterLoginRedirect = true;

    return this.defaultLogin(authParams);
  }

  private isResetLoginState(): boolean {
    let tabOpen = sessionStorage.getItem("tab_open");
    let currentLoginMode = this.loginMode || this.getStoredLoginMode();

    if (!tabOpen) {
      sessionStorage.setItem("tab_open", "t");
    }

    return !tabOpen && currentLoginMode === 'demo';
  }

  public async resetLoginState(): Promise<boolean> {
    this.clearSession();
    this.authInit = false;
    this.authenticationResultSource.next({result: false, reason: "DEMO user logout"});

    return false;
  }

  public async getLoginState(): Promise<boolean> {
    return this.getTokenLoginState();
    /*
    const ret = this.loginResult$.pipe(takeUntil(this.ngDestroy), take(1)).toPromise();
    this.loginResult$.next(false);
    return ret;
    */
  }

  public async getTokenLoginState(): Promise<boolean> {
    const token = this.cacheService.getValue(AuthConstants.TOKEN_KEY);
    const profileStr = this.cacheService.getValue(AuthConstants.PROFILE_KEY);
    const profile = profileStr ? new Profile(JSON.parse(profileStr)) : null;

    if (token && profile) {
      this.token = token;
      this.profileData = profile;

      try {
        await this.profileLogin(profile);
        this.loginResult$.next(true);
        return true;
      } catch (e) {
        this.clearAuthentication();
        this.loginResult$.next(false);
        return false;
      }
    }

    this.loginResult$.next(false);

    return false;
  }

  public async defaultLogin(authParams: AuthParams): Promise<boolean> {
    if (authParams.token && this.config.tokenLogin) {
      return this.tokenLogin(authParams.token);
    // } else if (!this.authModalRef) {
      //this.modalLogin(authParams);
    } else {
      this.redirectLogin(authParams);
    }

    return this.authenticationResult$.pipe(takeUntil(this.ngDestroy), take(1), map((result) => {
      this.authInit = false;
      if (!result.result) {
        this.resetLoginMode();
      }
      return result.result;
    })).toPromise();
  }

  private redirectLogin(authParams: AuthParams): void {

    this.idpAuthService.login(authParams).then(() => {
      if (this.loginMode === 'demo') {
        this.ssoApiLogin({
          token: 'DEMO'
        }).catch((err) => {
          console.error("DEMO ERR", err);
        });

      }
    });

  }

  public handleAuthorization(urlParams?: any): void {
    if (this.loginMode == 'tara') {
      this.handleTaraAuthorization(urlParams);
    } else if (this.loginMode == 'nem-log-in') {
      this.initNemLogInUrlAuth(urlParams);
    }
  }

  private handleTaraAuthorization(urlParams?: any): void {
    let params = {};
    if (urlParams) {
      params = Object.assign(params, urlParams);
    }

    console.log('AUTH PARAMS', params);

    window.location.href = UrlBuilder.build(this.config.apiUrl, '/tara/login', params);
  }

  private initNemLogInUrlAuth(urlParams: any): void {
    let params = {
      code: urlParams.code,
      codeVerifier: PkceUtil.getCodeVerifier(),
      state: PkceUtil.getState(),
      clientId: this.config.serviceName ? this.config.serviceName : 'default',
      redirectUrl: urlParams.next
    };

    this.requestToken(params).subscribe((result) => this.onNemLogInLogin(result));
  }

  private async onNemLogInLogin(result: any): Promise<void> {
    if (!result.token) {
      return;
    }

    try {
      await this.doNemLogInLogin(result.token);
      this.urlAuthResultSource.next({redirectTo: result.redirectUrl, result: true, reason: null});
    } catch (e) {
      this.loggerService.error(e);
      this.urlAuthResultSource.next({redirectTo: result.redirectUrl, result: false, reason: e});
    }
  }

  public requestToken(params: any): Observable<any> {
    return this.http.post(this.config.apiUrl + '/nem-log-in/token', {}, {
      params: params
    });
  }

  private async doNemLogInLogin(token: string): Promise<void> {
    this.token = token;
    const profile = await this.reloadProfile();
    await this.profileLogin(profile);
  }

  logout(): void {
    this.doLogout();
    this.logoutOtherTabs();
  }

  private doLogout() {
    this.idpAuthService.logout({
      token: this.token
    }).then(() => {
      this.clearSession();
    });

    // if (loginMode === 'tara') {
    //   this.taraAuthService.logout({
    //     token: this.token
    //   });
    //   this.clearSession();
    //   this.authenticationResultSource.next({result: false, skipRedirect: true, reason: 'logout'});
    // } else if (loginMode === 'nem-log-in') {
    //   this.nemLogInAuthService.logout();
    //   this.clearSession();
    //   this.authenticationResultSource.next({result: false, skipRedirect: true, reason: 'logout'});
    // } else {
    //   this.clearAuthentication();
    //   this.authenticationResultSource.next({result: false, reason: 'logout'});
    // }
  }

  clearAuthentication() {
    this.token = null;
    this.profileData = null;
    this.setRole(null);
    this.cacheService.removeValue(AuthConstants.TOKEN_KEY);
    this.cacheService.removeValue(AuthConstants.PROFILE_KEY);

  }

  clearSession(): void {
    this.cacheService.removeValue(AuthConstants.TOKEN_KEY);
    this.cacheService.removeValue(AuthConstants.PROFILE_KEY);
    this.cacheService.removeValue(AuthConstants.ROLE_KEY);
    this.setLoginMode(this.config.loginMode, true);
    for (let key of Object.keys(this.cookieService.getAll())) {
      if (key === CookieConstants.COOKIE_CONSENT_COOKIE_NAME) {
        continue;
      }
      this.cookieService.remove(key);
    }
  }

  private resetLoginMode() {
    this.setLoginMode(this.config.loginMode, true);
  }

  public getToken(): string {
    if (this.token != null) {
      return this.token;
    }
    this.token = this.cacheService.getValue(AuthConstants.TOKEN_KEY);
    return this.token;
  }

  public getRole(): MandatePerson {
    if (this.role != null) {
      return this.role;
    }

    this.role = new MandatePerson(JSON.parse(this.cacheService.getValue(AuthConstants.ROLE_KEY)));
    if (!this.role) {
      this.setRole(null);
    }
    return this.role;
  }

  public setRole(role: MandatePerson) {
    if (!role) {
      this.role = null;
      this.cacheService.removeValue(AuthConstants.ROLE_KEY);
      return;
    }

    this.role = role;
    this.cacheService.setValue(AuthConstants.ROLE_KEY, JSON.stringify(role));
  }

  private async updateRole(person: MandatePerson): Promise<void> {
    const mandate: any = await this.http.put(this.config.apiUrl + '/user/role', person).toPromise();
    this.setCurrentMandate(mandate);
  }

  async selectRole(person: MandatePerson): Promise<void> {
    await this.updateRole(person);

    this.updateUserCredentialOtherTabs(person);
  }

  public setCurrentMandate(data: Mandate) {
    this.setRole(data.person);

    this.profileData.mandate = data;
    this.cacheService.setValue(AuthConstants.PROFILE_KEY, JSON.stringify(this.profileData));
    this.loadPermissions();
  }

  public async ssoApiLogin(ssoData): Promise<any> {
    const profile: Profile = await this.http.post<Profile>(this.config.apiUrl + '/login', {
      token: ssoData.token,
      personRegistrationNumber: ssoData.mandate ? ssoData.mandate.person.registrationNumber : null,
      personCountryCode: ssoData.mandate ? ssoData.mandate.person.countryCode : null
    }).toPromise();

    await this.profileLogin(profile);
  }

  private async tokenLogin(token): Promise<boolean> {
    try {
      this.token = token;
      const profile = await this.reloadProfile();
      await this.profileLogin(profile);

      return true;
    } catch (e) {
      this.authenticationResultSource.next({result: false, reason: e});
    }

    return false;
  }

  public async nemIdApiLogin(signedData: string, challenge: any): Promise<any> {
    const modalService = this.injector.get(BsModalService);

    const profile: Profile = await this.http.post<Profile>(this.config.apiUrl + '/nem-id/login', {
      signedData: signedData,
      challenge: challenge
    }).pipe(catchError((e) => {
      this.loggerService.error(e);
      console.error(e);
      if (e instanceof HttpErrorResponse && e.error && e.error.message === 'NO_CPR') {
        const modalRef = modalService.show(CprModalComponent, {
          class: 'login-zindex',
          initialState: {
            signedData: signedData,
            challenge: challenge
          }
        });
        return modalRef.content.profile$ as Subject<Profile>;
      }
      return throwError(e);
    })).toPromise();

    await this.profileLogin(profile);
  }

  public async reloadProfile() {
    const profile: Profile = await this.http.get<Profile>(this.config.apiUrl + '/user/profile').toPromise();
    this.setProfile(profile);

    const lastRole: Person = this.getRole();
    let role: Person = null;
    if (lastRole) {
      role = this.profileData.availablePersons.find((e) => {
        return e.registrationNumber === lastRole.registrationNumber && e.countryCode === lastRole.countryCode;
      });
      if (!role) {
        role = this.profileData.availableMandates.find((e) => {
          return e.registrationNumber === lastRole.registrationNumber && e.countryCode === lastRole.countryCode;
        });
      }
    }
    return profile;
  }

  private async profileLogin(profile: Profile) {
    if (!profile.user.termsAccepted) {
      this.clearAuthentication();
      await this.acceptTerms(profile);
    }

    this.setProfile(profile);

    const lastRole: MandatePerson = this.getRole();
    let role: MandatePerson = null;
    if (lastRole) {
      role = this.profileData.availablePersons.find((e) => {
        return e.registrationNumber === lastRole.registrationNumber && e.countryCode === lastRole.countryCode;
      });
      if (!role) {
        role = this.profileData.availableMandates.find((e) => {
          return e.registrationNumber === lastRole.registrationNumber && e.countryCode === lastRole.countryCode;
        });
      }
    }

    if (!role) {
      role = profile.mandate.person;
    }
    await this.updateRole(role);

    this.authenticationResultSource.next({result: true, reason: null});

    this.loginResult$.next(true);
    if (this.authModalRef) {
      this.authModalRef.content.dismissWithSuccess();
      this.authModalRef = null;
    }
  }

  private setProfile(profile: Profile) {
    this.profileData = profile;
    this.token = profile.token;

    this.cacheService.setValue(AuthConstants.TOKEN_KEY, this.token);
    this.cacheService.setValue(AuthConstants.PROFILE_KEY, JSON.stringify(this.profileData));
    //this.cacheService.setValue(AuthConstants.LOGIN_MODE_KEY, this.loginMode);
  }

  loadPermissions() {
    if (this.profileData) {
      const permissions: string[] = [];
      if (this.profileData.mandate.type === 'MANDATE') {
        permissions.push.apply(permissions, this.profileData.mandate.permissions.map((item) => {
          return item.key;
        }));
      } else {
        permissions.push('OWNER');
        permissions.push.apply(permissions, this.profileData.mandate.permissions.map((item) => {
          return item.key;
        }));
      }
      if (this.profileData.mandate.person.juridical) {
        permissions.push('JURIDICAL_PERSON');
      }
      this.permissionsService.loadPermissions(permissions);
    } else {
      this.clearAuthentication();
      this.permissionsService.loadPermissions([]);
    }

    this._currentMandate.next(this.profileData ? this.profileData.mandate : null);
  }

  async acceptTerms(profile: Profile): Promise<void> {
    if (this.authModalRef) {
      this.authModalRef.content.dismissWithSuccess();
      this.authModalRef = null;
    }

    if (this.config.tosContentEnabled) {
      const modalService = this.injector.get(BsModalService);

      const modalRef = modalService.show(TermsModalComponent, {
        class: 'modal-lg'
      });

      modalRef.onHide.subscribe((event: string | any) => {
        if (event === 'backdrop-click') {
          this.onTermsDismissed();
        }
      });

      const resultData = await modalRef.content.result$.pipe(catchError((error) => {
        return of(false);
      })).toPromise();

      if (!resultData) {
        this.onTermsDismissed();
      }
    }

    await this.http.put(this.config.apiUrl + '/user/terms/accept', {}, {
      headers: {'X-Auth-token': profile.token},
      withCredentials: true
    }).pipe(take(1), tap(() => {
      profile.user.termsAccepted = true;
    })).toPromise();
  }

  private onTermsDismissed(): void {
    this.authenticationResultSource.next({result: false, reason: 'Terms dismissed'});
  }

  async setApiToken(token: string) {
    this.isAuthenticated = true;
    this.token = token;
    const profile = await this.reloadProfile();
    await this.updateRole(profile ? profile.mandate.person : null);
  }

  private updateUserCredentialOtherTabs(person: MandatePerson): void {
    this.globalBroadcastService.sendEvent(GlobalBroadcastEventType.SWITCH_PERSON, {
      source: this.sourceTab,
      data: person
    });
  }

  private logoutOtherTabs(): void {
    this.globalBroadcastService.sendEvent(GlobalBroadcastEventType.LOGOUT, {
      source: this.sourceTab
    });
  }

}
