import { Inject, Injectable } from '@angular/core';
import {
  OlMapWrapper,
  CoreConfig,
  SMARTENCITY_CORE_CONFIG
} from '@smartencity/core';
import {FeatureLike} from 'ol/Feature';
import { Fill, Stroke, Style } from 'ol/style';
import { createStyleFunctionFromUrl, createStyleFunction } from 'ol-esri-style';
import { LayerDisplaySettings } from './layer-display-settings';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Overlay from 'ol/Overlay';
import * as Extent from 'ol/extent';

@Injectable({
  providedIn: 'root'
})
export class TallinnArcGisMapService {

  public highlightStyle: Style = new Style({
    fill: new Fill({
      color: 'rgba(255, 255, 0, 0)'
    }),
    stroke: new Stroke({
      color: '#00FFFF',
      width: 3
    }),
    zIndex: 1001
  });

  protected displaySettings: LayerDisplaySettings;

  public layersMap: Map<string, any> = new Map<string, any>(
    [
      ['residentialAreas', {
        layerId: 5,
        name: 'residentialAreas',
        getFeatureStyle: ((url, layerId): Promise<Function> => {
          return createStyleFunction({
            drawingInfo: {
              'renderer': {
                'type': 'simple',
                'symbol': {
                  'type': 'esriSFS',
                  'style': 'esriSFSSolid',
                  'color': [
                    212,
                    252,
                    239,
                    0
                  ],
                  'outline': {
                    'type': 'esriSLS',
                    'style': 'esriSLSSolid',
                    'color': [
                      0,
                      112,
                      255,
                      255
                    ],
                    'width': 1
                  }
                }
              },
              'scaleSymbols': true,
              'transparency': 0,
              'labelingInfo': null
            }
          });
        }),
        getHighLightStyle: this.highlightStyle
      }],
      ['cityDistricts', {
        layerId: 6,
        name: 'cityDistricts',
        getFeatureStyle: ((url, layerId): Promise<Function> => {
          return createStyleFunctionFromUrl(url + '/' + layerId);
        }),
        getHighLightStyle: this.highlightStyle
      }]
    ]
  );

  private olMap: OlMapWrapper;

constructor( @Inject(SMARTENCITY_CORE_CONFIG) private config: CoreConfig, ) { }

  public addCityLayers(olMap: OlMapWrapper) {
    this.olMap = olMap;
    const baseUrl = this.config.cityApiUrl + "/tallinn/map-server-proxy";

    olMap.addFeatureServer(baseUrl + '/rest/services/andmed_tallinn2', {
      layers: Array.from(this.layersMap.values()),
      zIndexOffset: 100,
    });
  }

  afterMapLoaded(): void {
    let map = this.olMap.getMap();
    const tooltipOverlay = new Overlay({
      element: document.getElementById('tooltip')
    });

    const popoverOverlay = new Overlay({
      element: document.getElementById('cityDistrictPopover')
    });

    map.addOverlay(tooltipOverlay);
    map.addOverlay(popoverOverlay);

    const displayTooltip = (evt) => {
      const el = tooltipOverlay.getElement();
      const pixel = evt.pixel;
      let featureLayer = null;
      const feature = map.forEachFeatureAtPixel(pixel, (feature, layer) => {
        featureLayer = layer;
        return feature;
      }, {
        layerFilter: (l) => {
          return l == this.olMap.getFeatureLayersMapping().get('toilets');
        }
      });

      ($(el) as any).popover('dispose');
      if (feature && featureLayer) {
        let center = Extent.getCenter(feature.getGeometry().getExtent());
        tooltipOverlay.setPosition(center);

        ($(el) as any).popover({
          placement: 'top',
          animation: false,
          container: 'body',
          html: true,
          content: `${this.getObjectName(feature, featureLayer)}`
        });

        ($(el) as any).popover('show');
      }
    };
    map.on('pointermove', displayTooltip);
  }

  public onDisplaySettingsChange(): void {
    if (!this.olMap) {
      return;
    }
    
    // let layerNames = Array.from(this.layersMap.keys());
    let layerNames = Array.from(this.olMap.getFeatureLayersMapping().keys());
    for (let key in this.displaySettings) {
      let layers = layerNames.find((val: string) => val.includes(key));
      this.olMap.setVisibility(layers, this.displaySettings[key]);
    }

    // if (!this.displaySettings.cityDistricts) {
    //   this.selectLocations(null);
    //   this.select.getFeatures().clear();
    // }
  }

  public updateDisplaySettings(displaySettings: LayerDisplaySettings) {
    this.displaySettings = displaySettings;
    this.onDisplaySettingsChange();
  };

  public getObjectName(feature: FeatureLike, layer: VectorLayer<VectorSource>) {
    let nameProp = 'name';
    if (layer.get("layerInfo").id == 0) {
      nameProp = 'nimi';
    }
    return feature.get(nameProp);
  }
}
