import {NgModule} from '@angular/core';
import {CommonModule, DecimalPipe} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {
  CoreModule,
  PersonalIdPipe,
  PersonSeriesLocationLabel,
  RegNoPipe,
  DatepickerWrapperModule, PersonSeriesApiService
} from '@smartencity/core';
import {MydataComponent} from './mydata.component';
import {MydataRoutingModule} from './mydata-routing.module';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatOptionModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatTreeModule} from '@angular/material/tree';
import {TenantContractComponent} from './components/modal/tenant-contract.component';
import {SelectTenantComponent} from './components/modal/select-tenant.component';
import {ManagedSeriesListComponent} from './components/managed-sources/managed-series-list.component';
import {HttpClientJsonpModule} from '@angular/common/http';
import {NgxPermissionsModule} from 'ngx-permissions';
import {AcceptApplicationsComponent} from './components/modal/accept-applications.component';
import {TenantService} from './services/tenant.service';
import {ApplicationService} from './services/application.service';
import {TenantSelectService} from './services/tenant-select.service';
import {PersonSeriesLocationPipe} from './pipes/person-series-location.pipe';
import {BsModalService} from 'ngx-bootstrap/modal';
import {ComponentLoaderFactory} from 'ngx-bootstrap/component-loader';
import {PaginationModule} from 'ngx-bootstrap/pagination';
import {PopoverModule} from 'ngx-bootstrap/popover';
import {TabsModule} from 'ngx-bootstrap/tabs';
import {TenantGuard} from './tenant.guard';
import {QuestionnaireModule} from './components/questionnaires/questionnaire.module';
import {NgSelectModule} from '@ng-select/ng-select';
import {LaddaModule} from 'angular2-ladda';
import {MyQuestionnairesService} from './components/questionnaires/my-questionnaires.service';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {ServicesComponent} from './components/services/services.component';
import {ApplicationTokenModalComponent} from './components/services/application-token-modal.component';
import {ManagedSourcesComponent} from './components/managed-sources/managed-sources.component';
import {MandateModule} from './components/mandate/mandate.module';
import {DashboardModule} from './components/dashboard/dashboard.module';
import {EnergyManagerModule} from './components/energy-manager/energy-manager.module';
import {AdviceModule} from './components/advice/advice.module';
import {GroupsModule} from './components/groups/groups.module';
import {ParametersModule} from './components/parameters/parameters.module';
import {DatapointListManageModule} from './components/datapoint-list-manage/datapoint-list-manage.module';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {FormulaModule} from './components/formula-template/formula.module';
import {NavbarComponent} from './components/navbar/navbar.component';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {CreateSourcesComponent} from './components/create-sources/create-sources.component';
import {NavbarService} from './components/navbar/navbar.service';
import {GroupDatapointListComponent} from './components/group-datapoint-list/group-datapoint-list.component';
import {SetOwnerModalComponent} from './components/managed-sources/set-owner-modal/set-owner-modal.component';
import {ButtonsModule} from 'ngx-bootstrap/buttons';
import {DatapointListComponent} from './components/datapoint-list/datapoint-list.component';
import {DatapointsLocationsComponent} from './components/datapoints-locations/datapoints-locations.component';
import {DatapointChartComponent} from './components/datapoint-list/datapoint-chart/datapoint-chart.component';
import {ConsentModalComponent} from './components/consumption/person-series/consent/consent-modal.component';
import {ThresholdsModalComponent} from './components/consumption/person-series/thresholds/thresholds-modal.component';
import {ThresholdRowComponent} from './components/consumption/person-series/thresholds/threshold-row.component';
import { DatapointItemComponent } from './components/datapoint-list/datapoint-item/datapoint-item.component';
import {AddDataModalComponent} from './components/consumption/person-series/add-data/add-data-modal.component';
import {AddDataMatDatepickerComponent} from './components/consumption/person-series/add-data/add-data-mat-datepicker/add-data-mat-datepicker.component';
import { FeedbackModalComponent } from './components/feedback-modal/feedback-modal.component';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {NotificationModule} from './components/notification/notification.module';
import {OwnerHistoryModalComponent} from './components/managed-sources/owner-history-modal.component';
import { InviteComponent } from './components/invite/invite.component';
import {VirtualScrollerModule} from 'ngx-virtual-scroller';
import {LocationListService} from './services/location-list.service';
import {SettingsModule} from './components/settings/settings.module';
import {PersonSeriesEventModalComponent} from './components/modal/person-series-event-modal.component';
import {QuestionnaireService} from './services/questionnaire.service';
import {CanCreateQuestionnaireGuard} from './components/questionnaires/can-create-questionnaire.guard';
import {CreateSourcesService} from './services/create-sources.service';
import {DashboardService} from './components/dashboard/dashboard.service';
import {ComponentsGuard} from './guards/components.guard';
import {ComponentsConfigService} from './services/components-config.service';
import {ComponentsEnabledGuard} from './guards/component-enabled.guard';
import {DatapointGroupsManagementModule} from './components/datapoint-groups-management/datapoint-groups-management.module';
import {PersonSeriesManageService} from './services/person-series-manage.service';
import {ManagePersonSeriesModule} from './components/manage-person-series/manage-person-series.module';
import { CreateConsentBulkModalComponent } from './components/consumption/person-series/create-consent-bulk-modal/create-consent-bulk-modal.component';
import { MandateRootComponent } from './components/mandate-root/mandate-root.component';
import {MyDataSharedModule} from './shared/my-data-shared.module';
import {DatapointGroupsIndexModule} from './components/datapoints-groups-index/datapoint-groups-index.module';
import {CreateSeriesModalService} from './services/create-series-modal.service';
import {SupportService} from './http/support.service';
import {DatapointListRoot} from './components/datapoint-list-root/datapoint-list-root.component';
import {PersonTokenApiService} from './http/person-token-api.service';
import {NgxMatDrpModule, SharedModule} from '@smartencity/shared';
import {QuestionnaireParticipationApiService} from './http/questionnaire-participation-api.service';
import {QuestionnaireResponseApiService} from './http/questionnaire-response-api.service';
import {SourceApiService} from './http/source-api.service';
import {WidgetTemplateApiService} from './http/widget-template-api.service';
import {PersonSeriesGroupApiService} from './http/person-series-group-api.service';

@NgModule({
  declarations: [
    MydataComponent,
    TenantContractComponent,
    SelectTenantComponent,
    AcceptApplicationsComponent,
    ManagedSeriesListComponent,
    PersonSeriesLocationPipe,
    ServicesComponent,
    ApplicationTokenModalComponent,
    ManagedSourcesComponent,
    NavbarComponent,
    CreateSourcesComponent,
    DatapointListRoot,
    GroupDatapointListComponent,
    SetOwnerModalComponent,
    DatapointListComponent,
    DatapointsLocationsComponent,
    DatapointChartComponent,
    ThresholdsModalComponent,
    ThresholdRowComponent,
    ConsentModalComponent,
    DatapointItemComponent,
    AddDataModalComponent,
    AddDataMatDatepickerComponent,
    FeedbackModalComponent,
    OwnerHistoryModalComponent,
    InviteComponent,
    PersonSeriesEventModalComponent,
    CreateConsentBulkModalComponent,
    MandateRootComponent
  ],
  entryComponents: [],
  providers: [
    BsModalService,
    ComponentLoaderFactory,
    TenantService,
    TenantSelectService,
    ApplicationService,
    TenantGuard,
    MyQuestionnairesService,
    DecimalPipe,
    RegNoPipe,
    PersonalIdPipe,
    PersonSeriesLocationLabel,
    NavbarService,
    LocationListService,
    QuestionnaireService,
    CanCreateQuestionnaireGuard,
    CreateSourcesService,
    DashboardService,
    ComponentsGuard,
    ComponentsConfigService,
    ComponentsEnabledGuard,
    PersonSeriesManageService,
    CreateSeriesModalService,
    SupportService,
    PersonTokenApiService,
    QuestionnaireParticipationApiService,
    QuestionnaireResponseApiService,
    SourceApiService,
    WidgetTemplateApiService,
    PersonSeriesApiService,
    PersonSeriesGroupApiService
  ],
  imports: [
    CommonModule,
    MydataRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientJsonpModule,
    CoreModule,
    GroupsModule,
    QuestionnaireModule,
    NotificationModule,
    NgSelectModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatOptionModule,
    MatMomentDateModule,
    NgxPermissionsModule.forChild(),
    NgxMatDrpModule,
    PaginationModule.forRoot(),
    MatTreeModule,
    MatCheckboxModule,
    MatIconModule,
    MatButtonModule,
    PopoverModule,
    TooltipModule,
    TabsModule,
    BsDropdownModule,
    LaddaModule,
    NgxDatatableModule,
    DatapointListManageModule,
    ManagePersonSeriesModule,
    GroupsModule,
    MandateModule,
    ParametersModule,
    DashboardModule,
    AdviceModule,
    EnergyManagerModule,
    FormulaModule,
    ButtonsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatOptionModule,
    MatMomentDateModule,
    VirtualScrollerModule,
    SettingsModule,
    DatepickerWrapperModule,
    DatapointGroupsManagementModule,
    MyDataSharedModule,
    DatapointGroupsIndexModule,
    SharedModule
  ]
})
export class MydataModule {
}
