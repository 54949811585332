import {AbstractMapLayerMarker, OlMapWrapper} from '@smartencity/core';
import {WifiAccessPoint} from '../model/wifi-access-point';
import {WifiAccessPointsOlMarkerWrapper} from './wifi-access-points-ol-marker-wrapper';

export class WifiAccessPointsOlMarker extends AbstractMapLayerMarker<OlMapWrapper, WifiAccessPoint> {

  private marker: WifiAccessPointsOlMarkerWrapper;

  private selected = false;

  constructor(wifiAccessPoint: WifiAccessPoint, map: OlMapWrapper) {
    super(wifiAccessPoint);
    this.marker = new WifiAccessPointsOlMarkerWrapper(wifiAccessPoint, map);
  }

  draw() {
    this.marker.draw();
  }

  setMap(map: OlMapWrapper) {
    super.setMap(map);
    const olMap = (map ? map.getMap() : null);
    this.marker.setMap(olMap);
  }

  isSelected(): boolean {
    return this.selected;
  }

  setSelected(selected: boolean): void {
    this.selected = selected;
  }

}
