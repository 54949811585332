import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {Subject} from 'rxjs/internal/Subject';
import {QuestionnaireEmailInvitationMeta, QuestionnaireParticipantMeta, RegNoPipe} from '@smartencity/core';

@Component({
  selector: 'mydata-view-participants',
  templateUrl: './view-participants.component.html',
  styleUrls: ['./view-participants.component.css']
})
export class ViewParticipantsComponent implements OnInit, OnDestroy, OnChanges {
  private ngDestroy = new Subject<void>();

  @Input()
  participants: QuestionnaireParticipantMeta[] = [];

  @Input()
  emailInvites: QuestionnaireEmailInvitationMeta[] = [];

  totalCount: number;
  completedCount: number;
  rows: any[] = [];

  constructor(
    private regNoPipe: RegNoPipe
  ) { }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.participants || changes.emailInvites) {
      this.updateRowsAndStats();
    }
  }

  updateRowsAndStats(): void {
    this.rows = [];
    const emailInvitesWithParticipant = this.emailInvites.filter(item => item.status === 'USER_ASSOCIATED');
    const emailInvitesWithoutParticipant = this.emailInvites.filter(item => item.status === 'NO_USER');

    for (const participant of this.participants) {
      const correspondingInvitation = emailInvitesWithParticipant.find(value => value.participant.id === participant.id);
      this.rows.push({
        participant: participant,
        invitation: null,
        person: participant.person,
        inviteEmail: correspondingInvitation?.email,
        status: participant.status,
        createdAt: participant.createdAt,
        updatedAt: participant.updatedAt
      });
    }

    for (const invitation of emailInvitesWithoutParticipant) {
      this.rows.push({
        participant: null,
        invitation: invitation,
        inviteEmail: invitation?.email,
        status: invitation.participant?.status,
        createdAt: invitation.participant ? invitation.participant.createdAt : invitation.createdAt,
        updatedAt: invitation.participant ? invitation.participant.updatedAt : invitation.updatedAt
      });
    }
    this.totalCount = this.participants.length + this.emailInvites.filter(i => i.status === 'NO_USER').length;
    this.completedCount = this.participants.filter(p => p.status === 'COMPLETED').length;
  }

}
