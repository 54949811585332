import {CanActivate, CanDeactivate} from '@angular/router';
import {ManageService} from './manage.service';
import {Injectable} from '@angular/core';
import {QuestionnaireComponent} from './questionnaire/questionnaire.component';
import {Observable} from 'rxjs/internal/Observable';

@Injectable()
export class CanDeactivateQuestionnaireGuard implements CanDeactivate<QuestionnaireComponent> {
  constructor() {}

  canDeactivate(component: QuestionnaireComponent): boolean | Observable<boolean> {
    return component.checkAndConirmDiscard();
  }
}
