<div class="modal-header">
  <h5 class="modal-title w-100" i18n>Add delegation</h5>
  <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
    <span class="fal fa-times"></span>
  </button>
</div>
<div [formGroup]="mandateForm" class="modal-body">

  <div *ngIf="errorCode" class="alert alert-danger" role="alert">
    {{ errorCodes[errorCode] ? errorCodes[errorCode] : errorCode}}
  </div>

  <div class="form-group">
    <div class="form-row">
      <div class="col">
        <label i18n [attr.for]="id + '-user'">Person</label>
        <ng-select
          [id]="id + '-user'"
          [class.is-invalid]="invalid('user')"
          [clearable]="true"
          [searchable]="true"
          appendTo="body"
          placeholder="Personal identification code"
          i18n-placeholder
          addTagText="New person with registration number"
          i18n-addTagText
          [typeahead]="userTypeahead"
          [items]="users"
          [addTag]="createUser"
          [selectOnTab]="true"
          formControlName="user"
          id="personal-identification"
          class="custom"
          autofocus
        >
          <ng-template ng-label-tmp let-item="item">
            <span>{{(item.displayName ? '' + item.displayName + ' ' : '') + '(' + (item | personalId) + ')'}}</span>
          </ng-template>
          <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
            <span>{{(item.displayName ? '' + item.displayName + ' ' : '') + '(' + (item | personalId) + ')'}}</span>
          </ng-template>
        </ng-select>
        <small *ngIf="invalid('user') && errors('user')?.required" class="invalid-feedback d-block" i18n>Required</small>
        <small *ngIf="invalid('user') && errors('user')?.invalidPersonCode" class="invalid-feedback d-block" i18n>Invalid person code</small>
      </div>
    </div>
  </div>

  <div class="form-group row" *ngFor="let permission of permissions.controls; let i=index">
    <div class="col">
      <div class="custom-control custom-switch mt-2">
        <input type="checkbox" class="custom-control-input" [id]="id + '-check-' + i" [formControl]="permission" [value]="permissionsList[i].value">
        <label class="custom-control-label mr-4" [attr.for]="id + '-check-' + i">{{permissionsList[i].label}}</label>
      </div>
    </div>
  </div>

</div>
<div class="modal-footer">
  <button type="button" class="btn btn-light btn-sm" aria-label="Close" (click)="close()" i18n>Close</button>
  <button type="button" class="btn btn-primary btn-sm" (click)="saveMandate()" [ladda]="progress" i18n>Save</button>
</div>
