<div class="box" *ngFor="let field of fields;let i = index" [ngSwitch]="field.type">
  <ng-container *ngSwitchCase="'TEXT'">
    <label class="question" [attr.for]="id + '-' + i + '-text'"><ng-container *ngIf="field.symbol">{{field.symbol}}:&nbsp;</ng-container>{{field.name}}<ng-container *ngIf="field.unit">&nbsp;({{field.unit}})</ng-container><span *ngIf="field.optional" class="text-light">&nbsp;(<ng-container i18n>Optional</ng-container>)</span></label>
    <div class="row mb-2">
      <div class="col-auto">
        <label class="m-0 mr-2" i18n>Value type</label>
        <span class="font-weight-bolder small-text help-text" i18n>Text</span>
      </div>
    </div>
    <p class="small-text" *ngIf="field?.description" [innerHTML]="field?.description | htmlEscape | linky"></p>
  </ng-container>
  <ng-container *ngSwitchCase="'VALUE'">
    <label class="question" [attr.for]="id + '-' + i + '-value'"><ng-container *ngIf="field.symbol">{{field.symbol}}:&nbsp;</ng-container>{{field.name}}<ng-container *ngIf="field.unit">&nbsp;({{field.unit}})</ng-container><span *ngIf="field.optional" class="text-light">&nbsp;(<ng-container i18n>Optional</ng-container>)</span></label>
    <div class="row mb-2">
      <div class="col-auto">
        <label class="m-0 mr-2" i18n>Value type</label>
        <span class="font-weight-bolder small-text help-text" i18n>Numeric value</span>
      </div>
    </div>
    <p class="small-text" *ngIf="field?.description" [innerHTML]="field?.description | htmlEscape | linky"></p>
  </ng-container>
  <ng-container *ngSwitchCase="'SELECT'">
    <label class="question"><ng-container *ngIf="field.symbol">{{field.symbol}}:&nbsp;</ng-container>{{field.name}}<ng-container *ngIf="field.unit">&nbsp;({{field.unit}})</ng-container><span *ngIf="field.optional" class="text-light">&nbsp;(<ng-container i18n>Optional</ng-container>)</span></label>
    <div class="row mb-2">
      <div class="col-auto">
        <label class="m-0 mr-2" i18n>Value type</label>
        <span class="font-weight-bolder small-text help-text" i18n>Multiple choice</span>
      </div>
    </div>
    <div class="custom-control custom-radio mb-1" *ngFor="let option of field.selectOptions; let j = index">
      <input class="custom-control-input" type="radio" [id]="id + '-' + i + '-option-' + j" [disabled]="true" [value]="option.value">
      <label class="custom-control-label" [attr.for]="id + '-' + i + '-option-' + j">{{option.name}}</label>
    </div>
    <p class="small-text" *ngIf="field?.description" [innerHTML]="field?.description | htmlEscape | linky"></p>
  </ng-container>
  <ng-container *ngSwitchCase="'PERSON_PARAMETER'">
    <label class="question" [attr.for]="id + '-' + i + '-person-parameter'"><ng-container *ngIf="field.symbol">{{field.symbol}}:&nbsp;</ng-container>{{field.name}}<ng-container *ngIf="field.unit">&nbsp;({{field.unit}})</ng-container><span *ngIf="field.optional" class="text-light">&nbsp;(<ng-container i18n>Optional</ng-container>)</span></label>
    <div class="row mb-2">
      <div class="col-auto">
        <label class="m-0 mr-2" i18n>Value type</label>
        <span class="font-weight-bolder small-text help-text" i18n>Parameter</span>
      </div>
    </div>
    <p class="small-text" *ngIf="field?.description" [innerHTML]="field?.description | htmlEscape | linky"></p>
    <div class="variable col-md-6 p-0">
      <div class="header">
        <div class="col-auto d-flex align-items-center">
          <span class="marker mr-2">{{field.symbol}}</span>
        </div>
        <div class="col-auto d-flex align-items-center">
          <label class="m-0 mr-2" i18n>Unit</label>
          <button class="btn btn-link p-0">{{field?.unit}}</button>
        </div>
        <div class="col d-flex justify-content-end align-items-center">
          <span class="small-text" i18n>Set value</span>
          <button class="btn btn-icon tooltip-icon"><i class="fal fal-info"></i></button>
          <div class="custom-control custom-switch">
            <input type="checkbox" class="custom-control-input" [id]="id + '-type-response'" [disabled]="true" [attr.checked]="field.responseType === 'COMMON'">
            <label class="custom-control-label" [attr.for]="id + '-type-response'"></label>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="form-group row">
          <label class="col-md-3 col-form-label" i18n>Value type</label>
          <div class="col-md-9" i18n>Parameter</div>
        </div>
        <div class="form-group row">
          <label class="col-md-3 col-form-label" i18n>Unit</label>
          <div class="col-md-9">{{field.unit}}</div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'PERSON_SERIES'">
    <label class="question" [attr.for]="id + '-' + i + '-person-parameter'"><ng-container *ngIf="field.symbol">{{field.symbol}}:&nbsp;</ng-container>{{field.name}}<ng-container *ngIf="field.unit">&nbsp;({{field.unit}})</ng-container><span *ngIf="field.optional" class="text-light">&nbsp;(<ng-container i18n>Optional</ng-container>)</span></label>
    <div class="row mb-2">
      <div class="col-auto">
        <label class="m-0 mr-2" i18n>Value type</label>
        <span class="font-weight-bolder small-text help-text" i18n>Datapoint</span>
      </div>
    </div>
    <p class="small-text" *ngIf="field?.description" [innerHTML]="field?.description | htmlEscape | linky"></p>
    <div class="variable col-md-6 p-0">
      <div class="header">
        <div class="col-auto d-flex align-items-center">
          <span class="marker mr-2">{{field.symbol}}</span>
        </div>
        <div class="col-auto d-flex align-items-center">
          <label class="m-0 mr-2" i18n>Unit</label>
          <button class="btn btn-link p-0">{{field?.unit}}</button>
        </div>
        <div class="col d-flex justify-content-end align-items-center">
          <span class="small-text" i18n>Set value</span>
          <button class="btn btn-icon tooltip-icon"><i class="fal fal-info"></i></button>
          <div class="custom-control custom-switch">
            <input type="checkbox" class="custom-control-input" [id]="id + '-type-response'" [disabled]="true" [attr.checked]="field.responseType === 'COMMON'">
            <label class="custom-control-label" [attr.for]="id + '-type-response'"></label>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="form-group row">
          <label class="col-md-3 col-form-label" i18n>Value type</label>
          <div class="col-md-9" i18n>Datapoint</div>
        </div>

        <div class="form-group row">
          <label class="col-md-3 col-form-label" i18n>Timeframe type</label>
          <div class="col-md-9" *ngIf="!field.windowOperationType" i18n>Latest value</div>
          <div class="col-md-9" *ngIf="field.windowOperationType === 'OFFSET'" i18n>Historical value</div>
          <div class="col-md-9" *ngIf="field.windowOperationType === 'SUM'" i18n>Timeframe sum</div>
          <div class="col-md-9" *ngIf="field.windowOperationType === 'AVERAGE'" i18n>Timeframe average</div>
        </div>

        <div class="form-group row" *ngIf="field.windowOperationType">
          <label class="col-md-3 col-form-label" i18n>Timeframe period</label>
          <div class="col-md-9" *ngIf="field.windowPeriodType === 'HOUR'" i18n>Hour</div>
          <div class="col-md-9" *ngIf="field.windowPeriodType === 'DAY'" i18n>Day</div>
          <div class="col-md-9" *ngIf="field.windowPeriodType === 'MONTH'" i18n>Month</div>
          <div class="col-md-9" *ngIf="field.windowPeriodType === 'YEAR'" i18n>Year</div>
        </div>

        <div class="form-group row" *ngIf="field.windowOperationType">
          <label class="col-md-3 col-form-label" i18n>Period count</label>
          <div class="col-md-9">{{field.periodCount}}</div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <ng-container [ngSwitch]="field.fieldType">
      <ng-container *ngSwitchCase="'FORMULA'">
        <label class="question" [attr.for]="id + '-' + i + '-text'"><ng-container *ngIf="field.symbol">{{field.symbol}}:&nbsp;</ng-container>{{field.name}}<ng-container *ngIf="field.unit">&nbsp;({{field.unit}})</ng-container><span *ngIf="field.optional" class="text-light">&nbsp;(<ng-container i18n>Optional</ng-container>)</span></label>
        <p class="small-text" *ngIf="field?.description" [innerHTML]="field?.description | htmlEscape | linky"></p>

        <div class="row" *ngIf="field.formulaType == 'EXPRESSION'">
          <div class="col-auto">
            <label class="m-0 mr-2" i18n>Formula expression</label>
            <button class="btn btn-sm btn-link p-0"><ng-container *ngIf="field?.symbol">{{field.symbol}}&nbsp;=&nbsp;</ng-container>{{field?.expression}}</button>
          </div>
          <div class="col-auto">
            <label class="m-0 mr-2" i18n>Unit</label>
            <span>{{'' + field?.unit}}</span>
          </div>
          <div class="col-auto">
            <label class="m-0 mr-2" i18n>Start date</label>
            <span>{{field?.time | date:'dd. MMMM yyyy'}} <button class="btn btn-icon icon-sm" tooltip="Start date for the data requested and the date from which the historical values for the formula will be calculated." i18n-tooltip><i class="fa fa-info-circle ml-2 mt-2 btn-tooltip"></i></button></span>
          </div>
        </div>

        <div class="row" *ngIf="field.formulaType == 'MAPPING'">
          <div class="col-auto">
            <label class="m-0 mr-2" i18n>Mapping</label>
            <button class="btn btn-sm btn-link p-0"><ng-container *ngIf="field?.symbol">{{field.symbol}}&nbsp;=&nbsp;</ng-container>&fnof;({{getVariableList(field)}})</button>
          </div>
          <div class="col-auto">
            <label class="m-0 mr-2" i18n>Unit</label>
            <span>{{'' + field?.unit}}</span>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
