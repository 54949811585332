import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {SMARTENCITY_MYDATA_CONFIG} from '../../injection-tokens';
import {MyDataConfig} from '../../mydata-config.model';
import {CurrentLocationService} from '../../services/current-location.service';
import {PersonSeriesListService} from '../../services/person-series-list.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs/internal/Subject';
import {ManagePersonSeriesService} from './manage-person-series.service';

@Component({
  selector: 'app-manage-person-series',
  templateUrl: './manage-person-series.component.html',
  providers: [CurrentLocationService, ManagePersonSeriesService]
})
export class ManagePersonSeriesComponent implements OnInit, OnDestroy {

  private ngDestroy = new Subject<void>();

  pageResponse$ = this.managePersonSeriesService.pageResponse$;
  datapointCount = 0;

  constructor(
    private http: HttpClient,
    private router: Router,
    @Inject(SMARTENCITY_MYDATA_CONFIG) private config: MyDataConfig,
    private currentLocationService: CurrentLocationService,
    private managePersonSeriesService: ManagePersonSeriesService
  ) {
    // this.currentLocationService.currentLocation$.pipe(takeUntil(this.ngDestroy)).subscribe((location: Location) => {
    //   if (!location) {
    //     this.router.navigate(['/mydata']);
    //   }
    //
    //   console.log('Current', location);
    //
    //   this.location = location;
    // });
    this.pageResponse$.pipe(takeUntil(this.ngDestroy)).subscribe(page => {
      this.datapointCount = page.totalElements;
    });
  }

  ngOnDestroy() {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  ngOnInit(): void {
  }

}
