import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {CoreConfig, GroupSource, SMARTENCITY_CORE_CONFIG} from '@smartencity/core';
import {HttpClient} from '@angular/common/http';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {ToastrService} from 'ngx-toastr';
import {SourceUpdateErrorService} from '../../../services/source-update-error.service';
import {SourceUpdateError} from '../../../models/source-update-error';

@Component({
  selector: 'app-group-source-update-errors-modal',
  templateUrl: './datapoint-group-update-errors-modal.component.html',
})
export class DatapointGroupUpdateErrorsModalComponent implements OnInit {

  @Input()
  public groupSource: GroupSource;

  @Output('saved')
  savedEmitter: EventEmitter<any> = new EventEmitter<any>();

  errors: SourceUpdateError[] = [];

  total: number = 0;

  processing = false;

  constructor(private http: HttpClient,
              private sourceUpdateErrorService: SourceUpdateErrorService,
              @Inject(SMARTENCITY_CORE_CONFIG) private config: CoreConfig,
              private modalRef: BsModalRef,
              private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.loadErrors();
  }

  resetErrors(): void {
    this.processing = true;
    this.sourceUpdateErrorService.resetErrors(this.groupSource.id).subscribe(() => {
      this.toastr.success($localize`Resetting updates was successful`);
      this.savedEmitter.emit();
      this.modalRef.hide();
    }, (error) => {
      this.processing = false;
      this.toastr.error($localize`Resetting updates failed`);
    }, () => this.processing = false);

  }

  close() {
    this.modalRef.hide();
  }

  private loadErrors() {

    this.sourceUpdateErrorService.loadErrors(this.groupSource.id).subscribe((result: SourceUpdateError[]) => {
      this.total = result.length;
      this.errors = result.slice(0, 10);
    });

  }


}
