import {OlMapWrapper, OlOverlayWrapper} from '@smartencity/core';
import {NoiseLevel} from '../component/noise-level-heatmap/noise-level-heatmap.model';

export class NoiseLevelOlMarkerWrapper extends OlOverlayWrapper {

  public selected = false;

  private noiseLevel: any;

  constructor(noiseLevel: NoiseLevel, map: OlMapWrapper) {
    super( {
      title: noiseLevel.name,
      position: [noiseLevel.lng, noiseLevel.lat]
    });
    this.noiseLevel = noiseLevel;
    this.setMap(map.getMap());
  }

  public draw() {
    if (!this.div) {
      this.div = document.createElement('DIV');
      this.div.title = this.noiseLevel.readableAddress;
      this.div.style.cursor = 'pointer';

      const classNames = [
        'pointer',
        'heatmap-value'
      ];
      if (this.noiseLevel.value >= 75) {
        classNames.push('threshold-indicator');
      }

      const className = classNames.join(' ');
      const popoverContent = '<strong>' + this.noiseLevel.name + '</strong><br />' + this.noiseLevel.value + ' dB';
      this.div.innerHTML = '<div class="' + className + '" data-content="' + popoverContent + '">' + (this.noiseLevel.value != null ? this.noiseLevel.value : '') + '</div>';
      $('.popover.show').remove();
      ($('[data-toggle="popover"]') as any).popover({
        trigger: 'hover'
      });
    }

    if (this.selected) {
      this.div.classList.add("active");
    } else {
      this.div.classList.remove("active");
    }

  }

}
