<core-navbar></core-navbar>
<div class="d-flex">
  <div class="row d-flex no-gutters w-100 position-relative">
    <div class="map-container" style="width: 100%;">
      <div id="citymapContainer" class="citymap map" #gmap style="height: 100%;"></div>
    </div>

    <div class="floating-wrapper">
      <div class="position-relative">
<!--        <location-search [locations]="locations" [location]="location" (searchResultSelected)="showLocation($event)"></location-search>-->
        <div gas-item-modal></div>
      </div>

<!--      <div class="floating-jumbotron layer-filter mr-3">-->
<!--        <label class="solarparks" (click)="toggleLayerGroup(layerGroupsEnum.SOLAR_PANELS)" [class.active]="this.layersGroupActive === layerGroupsEnum.SOLAR_PANELS" tooltip="Show all stations" i18n-tooltip></label>-->
<!--        <label class="cng-stations" (click)="toggleLayerGroup(layerGroupsEnum.CNG_STATIONS)" [class.active]="this.layersGroupActive === layerGroupsEnum.CNG_STATIONS" tooltip="Show CNG stations" i18n-tooltip></label>-->
<!--        <label class="biogas-stations" (click)="toggleLayerGroup(layerGroupsEnum.BIOGAS_STATIONS)" [class.active]="this.layersGroupActive === layerGroupsEnum.BIOGAS_STATIONS" tooltip="Show biogas stations" i18n-tooltip></label>-->
<!--        <label class="general" (click)="toggleLayerGroup(layerGroupsEnum.GENERAL)" [class.active]="this.layersGroupActive === layerGroupsEnum.GENERAL" tooltip="Show general data" i18n-tooltip></label>-->
<!--      </div>-->

<!--      <core-compare-button></core-compare-button>-->
    </div>
  </div>
</div>

