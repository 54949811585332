import {Inject, Injectable, OnDestroy} from '@angular/core';
import {Location, LocationService, PersonSeriesGroupRef} from '@smartencity/core';
import {ReplaySubject} from 'rxjs/internal/ReplaySubject';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Subject} from 'rxjs/internal/Subject';
import {filter, map, shareReplay, switchMap, takeUntil} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';
import {of} from 'rxjs/internal/observable/of';
import {MyDataConfig} from '../mydata-config.model';
import {HttpClient} from '@angular/common/http';
import {SMARTENCITY_MYDATA_CONFIG} from '../injection-tokens';

/**
 *
 * !!! Provide always as Component provider, because when accessing ActiveRoute outside of router outlet then router data is empty !!!
 *
 */
@Injectable()
export class CurrentLocationService implements OnDestroy {
  private ngDestroy = new Subject<void>();
  private currentLocation: Location;
  private currentLocationSource = new ReplaySubject<any>(1);
  public currentLocation$ = this.currentLocationSource.asObservable();

  public subLocations$ = this.currentLocation$.pipe(
    switchMap((location) => {
      return this.locationService.getSubLocations({
        parent: location
      });
    }),
    takeUntil(this.ngDestroy),
    shareReplay(1)
  );

  constructor(
    @Inject(SMARTENCITY_MYDATA_CONFIG) private config: MyDataConfig,
    private http: HttpClient,
    private locationService: LocationService,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    const originalLocation$ = this.router.events.pipe(filter(e => e instanceof NavigationEnd), map(e => this.router.getCurrentNavigation().extras?.state?.location));
    combineLatest([this.route.data, this.route.params, originalLocation$]).pipe(
      switchMap(([data, params, originalLocation]) => {

        const locationType = data.locationType;
        const groupingTag = (params.groupingTag ? params.groupingTag : data.groupingTag);
        let group: PersonSeriesGroupRef = (data.group ? ({id: data.group.id, name: data.group.name} as PersonSeriesGroupRef) : null);
        if (locationType === 'ADDRESS') {
          return of(Location.ofAddress(group, groupingTag, params.address, params.apartment, params.room));
        } else if (locationType === 'COORDS' && params.lat && params.lng) {
          return of(Location.ofCoords(group, groupingTag, params.lat, params.lng));
        } else if (locationType === 'UUID' && params.uuid) {
          return of(Location.ofUuid(group, groupingTag, params.uuid));
        } else if (locationType === 'Q11E') {

          return this.http.get(this.config.apiUrl + '/questionnaire/' + params?.q11eId, {
            params: {
              fullInfo: false
            }
          }).pipe(switchMap((q11e: any) => {

            return of(Location.ofQ11e(group, {
              type: q11e.type,
              id: q11e.id,
              name: q11e.name
            }));
          }));


         /* if (originalLocation?.q11eName) {
            return of(Location.ofQ11e({
              id: params?.q11eId,
              type: params?.q11eType,
              name: originalLocation?.q11eName
            }));
          } else if (params?.q11eId) {
            return this.http.get(this.config.apiUrl + '/location/q11e/' + params?.q11eId).pipe(map((l: Location) => {
              let location = new Location({
                q11eType: l.q11eType,
                q11eId: l.q11eId,
                q11eName: l.q11eName
              });
              location.parent = {q11eType: l.q11eType} as Location
              return of(location);
            }));
          } else {
            return of(Location.ofQ11e({
              type: params?.q11eType
            }));
          }*/
        } else if (group) {
          return of(Location.ofGroup(group));
        } else if (groupingTag != null) {
          return of(Location.ofGroupingTag(groupingTag));
        } else {
          return of(null);
        }
      }),
      takeUntil(this.ngDestroy)
    ).subscribe(
      (location) => {
        this.setLocation(new Location(location));
      }
    );
  }

  ngOnDestroy(): void {
    this.currentLocationSource.complete();
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  public setLocation(location: Location) {
    this.currentLocation = location;
    //console.log('Set location', location);
    this.currentLocationSource.next(location);
  }

  public reloadLocation() {
    this.currentLocationSource.next(this.currentLocation);
  }
}
