import {Component, Inject, Input, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Subject} from 'rxjs/internal/Subject';
import {MyDataConfig} from '../../mydata-config.model';
import {
  Location,
  SourceOwnerSeries,
  Tenant,
  LocationService,
  AuthService,
  Mandate, PersonSeriesGroup,
} from '@smartencity/core';
import {
  map,
  switchMap,
  take,
  takeUntil
} from 'rxjs/operators';
import {CurrentLocationService} from '../../services/current-location.service';
import {DatapointListService} from '../../services/datapoint-list.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {SMARTENCITY_MYDATA_CONFIG} from '../../injection-tokens';
import {TenantSelectService} from '../../services/tenant-select.service';
import {BsModalService} from 'ngx-bootstrap/modal';
import {NavbarService} from '../navbar/navbar.service';
import {VirtualScrollerComponent} from "ngx-virtual-scroller";
import {IPageInfo} from "ngx-virtual-scroller/virtual-scroller";
import {PaginationComponent} from "ngx-bootstrap/pagination";
import {CreateSeriesModalService} from '../../services/create-series-modal.service';
@Component({
  selector: 'location-datapoints-list',
  templateUrl: './datapoint-list.component.html',
  styleUrls: ['./datapoint-list.component.css']
})
export class DatapointListComponent implements OnDestroy, OnInit {
  private ngDestroy: Subject<void> = new Subject();

  @ViewChild('navActions', {static: true})
  public navTempalte: TemplateRef<any>;

  @Input()
  public view = 'chart-list';

  @ViewChild('chartPaginationComponent')
  private chartPaginationComponent: PaginationComponent
  @ViewChild('listPaginationComponent')
  private listPaginationComponent: PaginationComponent

  @ViewChild('chartScroll')
  private chartScroll: VirtualScrollerComponent;
  @ViewChild('listScroll')
  private listScroll: VirtualScrollerComponent;

  public isCityUser = false;

  public globalRange$ = this.datapointListService.range$;

  public globalAggregationGroupingType$ = this.datapointListService.globalApplicationGroupingType$;

  public rows$ = this.datapointListService.rows$;
  public pageResponse$ = this.datapointListService.pageResponse$;
  private filterSortsLimit$ = this.datapointListService.filterSortsLimit$;

  public items: SourceOwnerSeries[] = [];

  public location: Location;

  public sourceOwnerSeries: SourceOwnerSeries[] = []; // FIXME remove

  private fetchedPages = new Set<number>();
  public vsStartIndex = 0;

  pagesPerVsPage = 5;
  bufferRows = 5;

  private group: PersonSeriesGroup;

  constructor(
    @Inject(SMARTENCITY_MYDATA_CONFIG) private config: MyDataConfig,
    private authService: AuthService,
    private currentLocationService: CurrentLocationService,
    private datapointListService: DatapointListService,
    private router: Router,
    private http: HttpClient,
    private toastr: ToastrService,
    private tenantSelectService: TenantSelectService,
    private modalService: BsModalService,
    private locationService: LocationService,
    private navbarService: NavbarService,
    private route: ActivatedRoute,
    private createSeriesModalService: CreateSeriesModalService
  ) {
    this.group = <PersonSeriesGroup>this.route.snapshot.data.group;

    this.authService.currentMandate$.pipe(takeUntil(this.ngDestroy)).subscribe((mandate: Mandate) => {
      this.isCityUser = this.config.cityRegistrationCode === mandate.person.registrationNumber;
    });

    this.pageResponse$.pipe(takeUntil(this.ngDestroy)).subscribe((response) => {
      this.items = Array.from({length:response.totalElements});
      this.items.splice(0, response.size, ...response.content);
      this.fetchedPages = new Set<number>();
      this.fetchedPages.add(0);
    });

    this.currentLocationService.currentLocation$.pipe(takeUntil(this.ngDestroy)).subscribe((location: Location) => {
      if (!location) {
        this.router.navigate(['/mydata']);
      }

      this.location = location;

      if (this.location.groupingTag == null) {
        this.loadLocationChildrenSeriesTypes();
      }
    }, (err: any) => {
      this.toastr.error(JSON.stringify(err), 'Error loading datapoints');
    });

    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
  }

  ngOnDestroy(): void {
    this.navbarService.setActionsTemplate(null);
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  ngOnInit(): void {
    this.navbarService.setActionsTemplate(this.navTempalte);
  }

  loadLocationChildrenSeriesTypes() {
    const params: any = {};

    if (this.location.groupingTag) {
      params.groupingTag = this.location.groupingTag;
    } else {
      if (this.location.locationType) {
        params.locationType = this.location.locationType;
        if (this.location.locationType === 'ADDRESS') {
          params.hasApartment = true;
        }
      }
      if (this.location.address) {
        params.address = this.location.address;
      }
      if (this.location.lat) {
        params.lat = this.location.lat;
      }
      if (this.location.lng) {
        params.lng = this.location.lng;
      }
      if (this.location.uuid) {
        params.uuid = this.location.uuid;
      }
    }
  }

  chartVsChange(event: IPageInfo) {
    if (event.startIndex < 0 || event.endIndex < 0) {
      return;
    }

    this.vsStartIndex = event.startIndex;

    if(event.startIndex <= 1){
      if(this.chartPaginationComponent){
        this.chartPaginationComponent.selectPage(0);
      }
      if(this.listPaginationComponent){
        this.listPaginationComponent.selectPage(0);
      }
    }

    let startPage = Math.floor((event.startIndex - this.bufferRows) / 10);
    let endPage = Math.floor((event.endIndex + this.bufferRows - 1) / 10);
    if (startPage < 0) {
      startPage = 0;
    }
    if (endPage < 0) {
      endPage = 0;
    }

    for (let i = startPage; i <= endPage; i++) {
      if (this.fetchedPages.has(i)) {
        continue;
      }
      this.fetchedPages.add(i);
      this.datapointListService.filterSortsLimit$.pipe(
        map(([filter, sorts, limit]: [any, any[], number]) => [filter, sorts, limit, {page: i, limit: limit}]),
        takeUntil(this.ngDestroy),
        take(1),
        map(([filter, sorts, limit, page]) => [this.datapointListService.mapQueryParams(filter, sorts, limit, page), limit]),
        switchMap(([queryParams, limit]) => this.datapointListService.fetchPage(queryParams).pipe(map(r => [r, limit])))
      ).subscribe(([response, limit]) => {
        this.items.splice(i * limit, response.size, ...response.content);
        this.fetchedPages.add(i);
      });
    }
  }

  pageChanged(event: any) {
    if(event.page == 1){
      return;
    }
    let indexToScroll = (event.page - 1) * this.pagesPerVsPage * 10;
    this.doScroll(indexToScroll);
  }

  private doScroll(indexToScroll: number){
    if(this.chartScroll){
      this.chartScroll.scrollToPosition(indexToScroll * 461, 0);
    }
    if(this.listScroll){
      this.listScroll.scrollToPosition(indexToScroll * 71, 0);
    }
  }

  scrollToTop() {
    window.scrollTo({left:0,top:0});
  }

  public createSeries() {
    this.createSeriesModalService.createSeriesAndNavigate(this.group);
  }

  reloadDatapoint(item: SourceOwnerSeries): void {
    //lae andmepunktid uuesti
    this.datapointListService.load();
  }

}
