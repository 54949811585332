import {MapsGridArea} from './maps-grid-area';
import {ActivityType} from './activity-type';
import {DurationGroupType} from './duration-group-type';

export class ActivityDaily {
  batchDate: Date;
  areaCode: number
  gridName: string;
  people: number;
  activityType: ActivityType;
  durationGroupType: DurationGroupType;

  constructor(props?: {
    areaCode: number,
    gridName: string;
    batchDate: Date,
    people: number;
    activityType: ActivityType,
    durationGroupType: DurationGroupType
  }) {
    if (!props) return;
    this.areaCode = props.areaCode;
    this.gridName = props.gridName;
    this.batchDate = props.batchDate;
    this.people = props.people;
    this.activityType = props.activityType;
    this.durationGroupType = props.durationGroupType;
  }
}
