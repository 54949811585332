import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {Subject} from 'rxjs/internal/Subject';
import {ManageService} from '../manage.service';
import {takeUntil} from 'rxjs/operators';
import {QuestionnaireDetail, QuestionnaireField} from '@smartencity/core';
import {FormulaExpressionFormComponent} from '../formula-expression-form/formula-expression-form.component';

const selector = 'mydata-field-form';
let nextId = 0;

@Component({
  selector: 'mydata-field-form',
  templateUrl: './field-form.component.html',
  styleUrls: ['./field-form.component.css']
})
export class FieldFormComponent implements OnInit, OnDestroy {
  private ngDestroy = new Subject<void>();
  id = `${selector}-${nextId++}`;

  @Input()
  public questionnaire: QuestionnaireDetail;

  @Input()
  public type: string;

  @Input()
  public partialEdit = false;

  @ViewChild('formulaExpressionForm')
  formulaExpressionForm: FormulaExpressionFormComponent;

  field: QuestionnaireField;
  typeControl = new FormControl(null, [Validators.required]);
  constructor(
    private manageService: ManageService
  ) {
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  ngOnInit(): void {
    this.manageService.field$.pipe(takeUntil(this.ngDestroy)).subscribe(field => {
      this.field = field;
      if (field.type === 'QUESTION') {
        this.typeControl.setValue('QUESTION', {onlySelf: true, emitEvent: false});
      } else if (field.type === 'FORMULA') {
        if (field.formulaType === 'EXPRESSION') {
          this.typeControl.setValue('EXPRESSION', {onlySelf: true, emitEvent: false});
        } else if (field.formulaType === 'MAPPING') {
          this.typeControl.setValue('MAPPING', {onlySelf: true, emitEvent: false});
        } else {
          this.typeControl.setValue(null, {onlySelf: true, emitEvent: false});
        }
      } else if (field.type === 'FORMUlA_INPUT') {
        this.typeControl.setValue('FORMULA_INPUT', {onlySelf: true, emitEvent: false});
      } else {
        this.typeControl.setValue(null, {onlySelf: true, emitEvent: false});
      }
    });
  }

  setType(type): void {
    if (type === 'EXPRESSION') {
      this.manageService.setFieldType('FORMULA', 'EXPRESSION');
    } else if (type === 'MAPPING') {
      this.manageService.setFieldType('FORMULA', 'MAPPING');
    } else {
      this.manageService.setFieldType(type);
    }
  }

  async save(): Promise<void> {
    if (this.field.type === 'FORMULA' && this.field.formulaType === 'EXPRESSION') {
      await this.formulaExpressionForm.save();
    }
  }
}
