import {Component, Inject, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Subject} from 'rxjs/internal/Subject';
import {SeriesEditModalComponent} from '../datapoint-list-manage/edit-modal/series-edit-modal.component';
import {DatatableComponent} from '@swimlane/ngx-datatable';
import {
  AuthService, ConfirmModalComponent, ErrorResponseHelper, Location, LocationService, Mandate,
  PageResponse,
  Person, PersonSeriesApiService, PersonSeriesCompareService, PersonSeriesGroup, PreventCloseModalService,
  ResizeService, SCREEN_SIZE, SeriesService,
  SourceOwnerSeries, SourceOwnerSeriesListItem
} from '@smartencity/core';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {SMARTENCITY_MYDATA_CONFIG} from '../../injection-tokens';
import {MyDataConfig} from '../../mydata-config.model';
import {TenantSelectService} from '../../services/tenant-select.service';
import {ToastrService} from 'ngx-toastr';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {CurrentLocationService} from '../../services/current-location.service';
import {SharedKeywordStore} from '../../services/shared-search-keyword-store.service';
import {PersonSeriesManageService} from '../../services/person-series-manage.service';
import {take, takeUntil} from 'rxjs/operators';
import {SeriesBulkEditModalComponent} from '../datapoint-list-manage/bulk-edit-modal/series-bulk-edit-modal.component';
import {Observable} from 'rxjs/internal/Observable';
import {forkJoin} from 'rxjs/internal/observable/forkJoin';
import {of} from 'rxjs/internal/observable/of';
import {
  DatapointGroupSelectModalComponent
} from '../datapoint-groups-management/datapoint-group-select-modal/datapoint-group-select-modal.component';
import {
  EditDatapointGroupModalComponent
} from '../datapoint-groups-management/edit-datapoint-group-modal/edit-datapoint-group-modal.component';
import {SetOwnerModalComponent} from '../managed-sources/set-owner-modal/set-owner-modal.component';
import {FormControl} from '@angular/forms';
import {ConsentModalComponent} from '../consumption/person-series/consent/consent-modal.component';
import {CreateConsentBulkModalComponent} from '../consumption/person-series/create-consent-bulk-modal/create-consent-bulk-modal.component';
import {NavbarService} from '../navbar/navbar.service';
import {ManagePersonSeriesService} from './manage-person-series.service';
import {CreateSeriesModalService} from '../../services/create-series-modal.service';

const selector = 'mydata-person-series-list';
let nextId = 0;

@Component({
  selector: selector,
  templateUrl: './person-series-list.component.html'
})
export class PersonSeriesListComponent implements OnInit {

  id = `${selector}-${nextId++}`;
  private ngDestroy = new Subject<void>();

  @ViewChild('navActions', {static: true})
  public navTempalte: TemplateRef<any>;

  @ViewChild('editSeriesModal', {static: true}) editSeriesModal: SeriesEditModalComponent;
  @ViewChild('table') table: DatatableComponent;
  public columns = {
    detailToggler: false,
    check: true,
    name: true,
    address: true,
    owner: true,
    activityDate: true,
    status: true,
    actions: true
  };

  public isCityUser = false;
  public me: Person;

  public page: PageResponse<SourceOwnerSeries>;
  public defaultLimit = this.managePersonSeriesService.defaultLimit;
  public defaultSorts = this.managePersonSeriesService.defaultSorts;

  public filterForm = this.managePersonSeriesService.filterForm;

  public reload$ = this.managePersonSeriesService.reload$;
  public sorts$ = this.managePersonSeriesService.sorts$;
  public page$ = this.managePersonSeriesService.page$;
  public pageResponse$ = this.managePersonSeriesService.pageResponse$;
  public rows$ = this.managePersonSeriesService.rows$;

  public rows: SourceOwnerSeriesListItem[];
  public selected: SourceOwnerSeriesListItem[] = [];
  public selectedEditable: SourceOwnerSeriesListItem[] = [];
  public selectedDeclineable: SourceOwnerSeriesListItem[] = [];
  public selectedArchivable: SourceOwnerSeriesListItem[] = [];
  public selectedShareable: SourceOwnerSeriesListItem[] = [];

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    @Inject(SMARTENCITY_MYDATA_CONFIG) private config: MyDataConfig,
    private tenantSelectService: TenantSelectService,
    private toastr: ToastrService,
    private modalService: BsModalService,
    private preventCloseModalService: PreventCloseModalService,
    private authService: AuthService,
    private currentLocationService: CurrentLocationService,
    private personSeriesService: PersonSeriesApiService,
    private resizeService: ResizeService,
    private personSeriesCompareService: PersonSeriesCompareService,
    private sharedKeywordStore: SharedKeywordStore,
    private personSeriesManageService: PersonSeriesManageService,
    private locationService: LocationService,
    private seriesService: SeriesService,
    private navbarService: NavbarService,
    private managePersonSeriesService: ManagePersonSeriesService,
    private createSeriesModalService: CreateSeriesModalService
  ) {
    this.pageResponse$.pipe(takeUntil(this.ngDestroy)).subscribe((response: PageResponse<SourceOwnerSeries>) => {
      this.page = response;
      this.selected = [];
      this.selectedEditable = [];
      this.selectedDeclineable = [];
      this.selectedArchivable = [];
    });

    this.rows$.pipe(takeUntil(this.ngDestroy)).subscribe((rows) => {
      this.rows = rows;
    });

    this.resizeService.onResize$.pipe(takeUntil(this.ngDestroy)).subscribe(this.adjustColumns.bind(this));

    this.authService.currentMandate$.pipe(takeUntil(this.ngDestroy)).subscribe((mandate: Mandate) => {
      this.isCityUser = this.config.cityRegistrationCode === mandate.person.registrationNumber;
    });
  }


  ngOnInit() {
    this.navbarService.setActionsTemplate(this.navTempalte);
    this.me = this.authService.profileData.mandate.person;

    this.managePersonSeriesService.resetResults();
  }

  ngOnDestroy() {
    this.managePersonSeriesService.ngOnDestroy();
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  createSeries(): void {
    this.createSeriesModalService.createSeriesAndNavigate();
  }

  searchGroup(): void {
    const modalRef = this.modalService.show(DatapointGroupSelectModalComponent, {
      ignoreBackdropClick: true,
    });
    modalRef.content.selectedEmitter.pipe(takeUntil(this.ngDestroy), take(1)).subscribe((personSeriesGroup: PersonSeriesGroup) => {
      this.openPersonSeriesGroupModal(personSeriesGroup);
    });
  }

  getConsentPersons(series: SourceOwnerSeries): Person[] {
    return series.consents.map(c => c.person);
  }

  createConsents(): void {
    if (this.selectedShareable.length == 0) {
      return;
    }
    const modalRef = this.modalService.show(CreateConsentBulkModalComponent, {
      ignoreBackdropClick: true,
      class: 'modal-xl',
      initialState: {
        seriesList: this.selectedShareable.map(r => r.series)
      }
    });
  }

  openPersonSeriesGroupModal(personSeriesGroup: PersonSeriesGroup): void {

    const modalRef = this.preventCloseModalService.show(EditDatapointGroupModalComponent, {
      class: 'modal-lg',
      initialState: {
        group: personSeriesGroup,
        isCreate: !personSeriesGroup.id,
        selectedPersonSeriesList: this.selectedEditable.map(s => s.series.personSeries)
      }
    });

    modalRef.content.saveEmitter.pipe(takeUntil(this.ngDestroy), take(1)).subscribe((group: PersonSeriesGroup) => {
      this.locationService.navigate(new Location({
        groupingTag: group.groupingTag
      }));
    });
  }

  clearSearch() {
    this.filterForm.get('query').setValue('');
  }

  checkedCount(): number {
    return this.selected.length;
  }

  checkedEditableCount(): number {
    return this.selectedEditable.length;
  }

  checkedDeclinableCount(): number {
    return this.selectedDeclineable.length;
  }

  checkedArchivableCount(): number {
    return this.selectedArchivable.length;
  }

  setPage(event: any) {
    this.page$.next(event);
  }

  setSorts(event: any) {
    this.sorts$.next(event.sorts);
  }

  onSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
    this.selectedEditable = this.selected.filter(r => r.confirmationStatus !== 'PENDING');
    this.selectedDeclineable = this.selected.filter(r => r.confirmationStatus === 'ACCEPTED' || r.confirmationStatus === 'PENDING');
    this.selectedArchivable = this.selected.filter(r => r.confirmationStatus === 'DECLINED' || r.confirmationStatus === 'CANCELLED');

    this.selectedShareable = this.selected.filter(r => {
      return this.isOwner(r.series) && r.confirmationStatus === 'ACCEPTED';
    });
  }

  editSeries(item: SourceOwnerSeries) {
    const modalRef: BsModalRef = this.preventCloseModalService.show(SeriesEditModalComponent, {
      initialState: {
        sourceOwnerSeries: item,
      }
    });
    modalRef.content.savedEmitter.pipe(takeUntil(this.ngDestroy), take(1)).subscribe((saved) => {
      this.currentLocationService.reloadLocation();
    });
  }

  bulkEdit() {
    const modalRef: BsModalRef = this.preventCloseModalService.show(SeriesBulkEditModalComponent, {
      initialState: {
        rows: this.selected
      }
    });
    modalRef.content.savedEmitter.pipe(takeUntil(this.ngDestroy), take(1)).subscribe((saved) => {
      this.currentLocationService.reloadLocation();
    });
  }

  bulkDecline() {
    if (!this.selectedDeclineable.length) {
      return;
    }
    const modalRef = this.modalService.show(ConfirmModalComponent, {
      ignoreBackdropClick: true,
      initialState: {
        description: $localize`Are you sure you want to decline the selected datapoints?`,
        callback: (confirm: boolean) => {
          if (confirm) {
            this.personSeriesService.updateStatusBulk(
              this.selectedDeclineable.map(value => {
                  this.personSeriesCompareService.remove(value.series.personSeries);
                  return value.series.personSeries
                }
              ),
              'DECLINED',
              null
            ).subscribe((data: any) => {
                this.toastr.success($localize`Datapoints declined`);
                this.currentLocationService.reloadLocation();
                // this.notificationService.loadPendingPendingSeries();
              },
              (error) => {
                console.error(error);
                this.toastr.error($localize`Error updating datapoints`);
              }
            );
          }
        }
      }
    });
  }

  bulkArchive() {
    if (!this.selectedArchivable.length) {
      return;
    }

    const modalRef = this.modalService.show(ConfirmModalComponent, {
      ignoreBackdropClick: true,
      initialState: {
        description: $localize`Are you sure you want to archive the selected datapoints?`,
        callback: (confirm: boolean) => {
          if (confirm) {
            const toForkJoin: Observable<any>[] = this.selectedArchivable.map(r => {
              this.personSeriesCompareService.remove(r.series.personSeries);
              return this.personSeriesService.deletePeriods(r.series.personSeries);
            });

            forkJoin(toForkJoin.length ? toForkJoin : of([])).subscribe((data: any[]) => {
                this.toastr.success($localize`Datapoints archived`);
                this.currentLocationService.reloadLocation();
                // this.notificationService.loadPendingPendingSeries();
              },
              (error) => {
                console.error(error);
                let msg = $localize`Error archiving datapoints`;
                const errorReason = ErrorResponseHelper.getErrorReason(error);
                if (errorReason) {
                  msg += '<br />' + errorReason;
                }
                this.toastr.error(msg, null, {  enableHtml: true, disableTimeOut: true });
              }
            );
          }
        }
      }
    });
  }

  acceptOwnership(series: SourceOwnerSeries) {
    this.personSeriesManageService.acceptOwnerShip(series).subscribe((data) => {
      this.toastr.success($localize`Ownership accepted`);
      // this.currentLocationService.reloadLocation();
      this.managePersonSeriesService.resetLists();
    }, (error) => {
      const msg = $localize`Error accepting ownership`;
      this.handlePersonSeriesManageError(error, msg);
    });
  }

  declineOwnership(series: SourceOwnerSeries) {
    this.personSeriesManageService.declineOwnerShip(series.personSeries).subscribe((confirm) => {
      if (confirm) {
        this.toastr.success($localize`Ownership declined`);
        // this.currentLocationService.reloadLocation();
        // this.notificationService.loadPendingPendingSeries();
        this.managePersonSeriesService.resetLists();
      }
    }, (error) => {
      const msg = $localize`Error declining ownership`;
      this.handlePersonSeriesManageError(error, msg);
    });
  }

  declineOwnershipAndArchive(series: SourceOwnerSeries) {
    this.personSeriesManageService.declineOwnerShipAndArchive(series.personSeries).subscribe((confirm) => {
      if (confirm) {
        this.toastr.success($localize`Datapoint archived`);
        // this.currentLocationService.reloadLocation();
        // this.notificationService.loadPendingPendingSeries();
        this.managePersonSeriesService.resetLists();
      }
    }, (error) => {
      const msg = $localize`Error archiving series`;
      this.handlePersonSeriesManageError(error, msg);
    });
  }

  archiveSeries(series: SourceOwnerSeries) {
    this.personSeriesManageService.archivePersonSeries(series.personSeries).subscribe((confirm) => {
      if (confirm) {
        this.toastr.success($localize`Datapoint archived`);
        // this.currentLocationService.reloadLocation();
        this.managePersonSeriesService.resetLists();
      }
    }, (error) => {
      const msg = $localize`Error archiving series`;
      this.handlePersonSeriesManageError(error, msg);
    });
  }

  toggleExpandRow(row) {
    this.table.rowDetail.toggleExpandRow(row);
  }

  showConsents(series: SourceOwnerSeries): void {
    const modalRef = this.preventCloseModalService.show(ConsentModalComponent, {
      class: 'modal-lg',
      initialState: {
        lastDataOwnerPeriod: series.lastDataOwnerPeriod,
        personSeries: series.personSeries
      }
    });
  }

  setRowDataOwner(series: SourceOwnerSeries): void {
    const modalRef = this.modalService.show(SetOwnerModalComponent, {
      initialState: {
        rows: [{
          checkControl: new FormControl(true),
          series: series
        }]
      }
    });
    modalRef.content.savedEmitter.pipe(takeUntil(this.ngDestroy)).subscribe(() => {
      this.managePersonSeriesService.resetLists();
    });
  }

  cancelOwnership(series: SourceOwnerSeries): void {
    this.seriesService.cancelOwnerShip(series).pipe(takeUntil(this.ngDestroy)).subscribe(() => {
      this.toastr.success('Cancelled');
      this.managePersonSeriesService.resetLists();
    });
  }

  isOwner(series: SourceOwnerSeries): boolean {
    return series?.lastPeriod?.ownerType == 'OWNER' || series?.lastPeriod?.ownerType == 'MANAGER';
  }

  private handlePersonSeriesManageError(error: any, msg: string): void {
    console.error(error);
    const errorReason = ErrorResponseHelper.getErrorReason(error);
    if (errorReason) {
      msg += '<br />' + errorReason;
    }
    this.toastr.error(msg, null, {  enableHtml: true, disableTimeOut: true });
  }


  private adjustColumns(size): void {
    if (size < SCREEN_SIZE.MD) {
      this.columns.detailToggler = true;
      this.columns.check = false;
      this.columns.address = false;
      this.columns.owner = false;
      this.columns.activityDate = false;
      this.columns.status = false;
      this.columns.actions = false;
    } else {
      this.columns.detailToggler = false;
      this.columns.check = true;
      this.columns.address = true;
      this.columns.owner = true;
      this.columns.activityDate = true;
      this.columns.status = true;
      this.columns.actions = true;
      if (this.table) {
        this.table.rowDetail.collapseAllRows();
      }
    }
  }

}
