import {Component, OnInit} from '@angular/core';
import {DisplaySettings} from '../model/display-settings';
import {MeteorologyOlMapService} from '../service/meteorology-ol-map.service';
import {MeteorologyService} from '../service/meteorology.service';
import {TallinnArcGisMapService} from 'projects/arc-gis/src/lib/tallinn-arc-gis-map.service';
import {FeatureSelectEvent, OlMapWrapper} from 'projects/core/src/public-api';
import {WeatherStation} from '../model/weather-station';

@Component({
  selector: 'ol-meteorology',
  templateUrl: './ol-meteorology.component.html',
  styles: [
    `
      .map {
        width: 100%;
        height: 100vh;
      }
    `,
  ],
})
export class OlMeteorologyComponent implements OnInit {
  displaySettings: DisplaySettings = new DisplaySettings();
  weatherStation: WeatherStation;
  map: OlMapWrapper;

  public objects: any[] = [];

  constructor(
    public meteorologyService: MeteorologyService,
    private meteorologyMapService: MeteorologyOlMapService,
    private tallinnArcGisMapService: TallinnArcGisMapService
  ) {
  }

  ngOnInit(): void {
    this.onModalClose();
    this.meteorologyMapService.createMap();

    this.meteorologyMapService.map.mapLoaded$.subscribe(() => {
      this.meteorologyMapService.map.getMap().on('click', () => {
        this.resetLocations();
      });

      this.meteorologyMapService.map.featureSelect$.subscribe(
        (o: FeatureSelectEvent) => {
          let newLocations = [];
          for (let f of o.selected) {
            if (f.feature.get('name') == 'toilets') {
              continue;
            }

            newLocations.push({
              layerName: f.layer.get('layerName'),
              data: this.meteorologyMapService.map.getFeatureProperties(f.feature),
            });
            f.feature.setStyle(this.tallinnArcGisMapService.highlightStyle);
          }

          for (let f of o.deselected) {
            f.feature.setStyle(null);
          }

          const layerPosition = newLocations.findIndex((o) => o.layerName == 'cityDistricts');
          const lastLayer = newLocations.splice(layerPosition, 1);
          newLocations = newLocations.concat(lastLayer);

          this.meteorologyService.selectLocations(newLocations);
        }
      );
    });
  }

  displayItemsChanged(items: any) {
    this.meteorologyService.displaySettingsSource.next(
      Object.assign(this.displaySettings, items)
    );
  }

  onModalClose(): void {
    this.resetLocations();
  }

  onArcGISLocationsReset(): void {
    this.clearFeaturesStyles();
  }

  private clearFeaturesStyles(): void {
    if (this.meteorologyMapService.map) {
      this.meteorologyMapService.map.clearFeatureStyles();
    }
  }

  private resetLocations(): void {
    this.clearFeaturesStyles();
    this.meteorologyService.selectLocations(null);
    this.meteorologyService.selectWeatherStation(null);
    this.meteorologyService.selectRoadCondition(null);
    this.meteorologyService.selectRoadWeather(null);
  }

}
