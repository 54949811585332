import {AbstractMapLayer, OlMapWrapper} from '@smartencity/core';
import VectorSource from 'ol/source/Vector';
import GeoJSON from 'ol/format/GeoJSON';
import VectorLayer from 'ol/layer/Vector';
import { Fill, Stroke, Style } from 'ol/style';
import { RoadClosure, RoadClosureClickEvent } from '../model/road-closure';
import { RoadClosureOlMarker } from './road-closures-ol-marker';
import { MobilityService } from '../service/mobility.service';
import { Select } from 'ol/interaction';
import { SelectEvent } from 'ol/interaction/Select';

export class RoadClosuresOlLayer extends AbstractMapLayer<OlMapWrapper, RoadClosure>{

  private roadClosures: RoadClosure[];
  private olMap: OlMapWrapper;
  private vectorLayer;
  private mobilityService: MobilityService;
  private select: Select;

  constructor(roadClosures: RoadClosure[], mobilityService: MobilityService) {
    super();
    this.roadClosures = roadClosures;
    this.mobilityService = mobilityService;
  }

  setRoadClosure(selectedRoadClosure: RoadClosure): void {
    this.getMarkers().forEach((marker: RoadClosureOlMarker) => {
      marker.setSelected(marker.getData() == selectedRoadClosure);
      marker.draw();
    });
  }

  setMap(map: OlMapWrapper): void {
    super.setMap(map);
    this.resetMarkers();
    if (map) {
      this.olMap = map;
      this.drawMarkers();
      this.setRoadClosureLayer();
    }
  }

  protected resetMarkers(): void {
    if (this.vectorLayer) {
      let markers = this.getMarkers();
      if (markers.length > 0) {
        for (const marker of markers) {
          marker.setMap(null);
        }
      }
      this.clearMarkers();
      this.vectorLayer.setVisible(false);
    }
  }

  protected setRoadClosureLayer(): void {
    if (this.vectorLayer) {
      this.vectorLayer.setVisible(true);
      return;
    }

    let data = {
      'type': 'FeatureCollection',
      'class': 'road-closure-layer',
      'crs': {
        'type': 'name',
        'properties': {
          'name': 'EPSG:4326',
        },
      },
      'features': this.roadClosures.map((roadClosure: RoadClosure) => {
        return {
          'type': 'Feature',
          'geometry': roadClosure.geojson,
          'properties': {
            procedureId: roadClosure.procedureId
          }
        };
      })
    };

    const vectorSource = new VectorSource({
      features: new GeoJSON({
        featureProjection: 'EPSG:3301'
      }).readFeatures(data, {
        featureProjection: 'EPSG:3301'
      }),
    });

    this.vectorLayer = new VectorLayer({
      className: 'road-closures',
      source: vectorSource,
      style: this.styleFunction,
      visible: true,
    });

    if (this.vectorLayer) {
      this.selectFeature();
      this.olMap.getMap().addLayer(this.vectorLayer);
    }
  }

  selectFeature(): void {
    this.select = new Select({
      style: this.selectedStyleFunction,
      filter: (feature) => {
        return true;
      }
    });

    this.select.on('select', (e: SelectEvent) => {
      let newFeatures = e.selected;
        let selectedRoadClosure = null;
        for(let newFeature of newFeatures) {
          let selectedProcedureId = newFeature.get('procedureId');
          for (let roadClosure of this.roadClosures) {
            if (roadClosure.procedureId == selectedProcedureId) {
              selectedRoadClosure = roadClosure;
              break;
            }
          }
        }
        if (selectedRoadClosure) {
          this.mobilityService.selectRoadClosure(selectedRoadClosure, 'select');
        }
    });

    this.mobilityService.roadClosuresClickEvent$.subscribe((clickEvent: RoadClosureClickEvent) => {
      if (clickEvent.type == 'marker-select' || clickEvent.type == 'deselect') {
        this.select.getFeatures().clear();
        const selectedRoadClosure = clickEvent.roadClosure;
        if (selectedRoadClosure) {
          for (let feature of this.vectorLayer.getSource().getFeatures()) {
            if (feature.get('procedureId') == selectedRoadClosure.procedureId) {
              this.select.getFeatures().push(feature);
            }
          }
        }
      }
    });

    // this.olMap.getMap().addInteraction(this.select);
  }

  private selectedStyleFunction(feature: any) {
    return new Style({
      stroke: new Stroke({
        color: '#00FFFF',
        width: 3
      }),
    });
  }

  private styleFunction(feature: any) {
    return new Style({
      stroke: new Stroke({
        color: 'rgba(112, 41, 99, 0.7)',
        width: 7
      }),
      fill: new Fill({
        color: 'rgba(0, 0, 0, 0.3)',
      }),
    })
  }

  protected drawMarkers(): void {
    for (const roadClosure of this.roadClosures) {
      this.addMarker(new RoadClosureOlMarker(roadClosure, this.map, this.mobilityService));
    }
  }
}
