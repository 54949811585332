<label i18n [attr.for]="id + '-groups-select'">Data group</label>
<ng-select
  class="custom"
  [class.is-invalid]="isInvalid()"
  placeholder="Group"
  i18n-placeholder
  appendTo="body"
  [typeahead]="groupTypeahead$"
  [items]="groups$ | async"
  [selectOnTab]="true"
  [multiple]="true"
  [addTag]="true"
  bindLabel="name"
  [(ngModel)]="_value"
  (change)="_onChange($event)"
  (focus)="_onTouched($event)"
  [hideSelected]="true"
  [trackByFn]="trackByFn"
  [loading]="groupsLoading"
  [compareWith]="compareGroups"
  addTagText="Add new group"
  i18n-addTagText
  [id]="id + '-groups-select'"
>
  <ng-template ng-label-tmp let-item="item" let-clear="clear">
    <span class="ng-value-icon left" (click)="clear(item)" aria-hidden="true" *ngIf="!isDisabled(item)">×</span>
    <span class="ng-value-label">{{item.name}} <span *ngIf="item.lastPeriod?.ownerType == 'CONSENT'" i18n>[Consent]</span></span>
  </ng-template>
  <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index" >
    <span>{{item.name}} <span *ngIf="item.lastPeriod?.ownerType == 'CONSENT'">[Consent]</span></span>
  </ng-template>
</ng-select>
