import {Pipe, PipeTransform} from '@angular/core';
import {LocationType} from "../models/series";

@Pipe({name: 'label'})
export class LabelPipe implements PipeTransform {

  private labelMap: any;

  constructor() {

    this.labelMap = {
      HEAT: $localize`Heat`, // series types
      HEAT_METER: $localize`Heat meter`,
      WATER: $localize`Water`,
      HOT_WATER: $localize`Hot water`,
      GAS: $localize`Gas`,
      GAS_METER: $localize`Gas meter`,
      HUMIDITY: $localize`Humidity`,
      LIGHT: $localize`Light`,
      TEMPERATURE: $localize`Temperature`,
      ELECTRICITY: $localize`Electricity`,
      ELECTRICITY_METER: $localize`Electricity meter`,
      SMOKE_DETECTOR: $localize`Smoke detector`,
      ILLUMINATION: $localize`Illumination`,
      OTHER: $localize`Other`,
      ENERGY_CONSUMPTION: $localize`Energy consumption`,
      ENERGY_PRODUCTION: $localize`Energy production`,
      POWER_STATION: $localize`Power station`,

      EDIT_MANDATES: $localize`Edit delegation`, // user permissions

      // aggregation type options
      AVERAGE: $localize`Average`,
      SUM: $localize`Sum`,
      MIN: $localize`Minimum`,
      MAX: $localize`Maximum`,

      // Status types
      ACCEPTED: $localize`Accepted`,
      PENDING: $localize`Pending`,
      CANCELLED: $localize`Cancelled`,
      DECLINED: $localize`Declined`,
      IN_PROGRESS: $localize`In progress`,
      COMPLETED: $localize`Completed`,
      EDITED: $localize`Edited`,
      SENT: $localize`Sent`,
      DRAFT: $localize`Draft`,

      ACTION_UPDATE: $localize`Editing`,
      ACTION_CREATE: $localize`Creating`,
      ACTION_DELETE: $localize`Deleting`,

      CONSENT_LOG_TYPE_PERSON_SERIES: $localize`Data point`,
      CONSENT_LOG_TYPE_PERSON_SERIES_GROUP: $localize`Data point group`,
      CONSENT_TYPE_GROUP_CONSENT: $localize`Group consent`,
      CONSENT_TYPE_CONSENT: $localize`Consent`,

      LOG_QUERY_CRITERIA_NAME: $localize`Name`,
      LOG_QUERY_CRITERIA_PERSON: $localize`Person`,
      LOG_QUERY_CRITERIA_TARGET_PERSON: $localize`To whom`,
      // ...
    };
  }

  transform(value: string, classifier?: string): string {
    if (this.labelMap[value]) {
      return this.labelMap[value];
    } else {
      return value;
    }
  }
}

@Pipe({name: 'dateDay'})
export class DateDayPipe implements PipeTransform {

  private dayMap = {
    0: "Sun",
    1: "Mon",
    2: "Tue",
    3: "Wed",
    4: "Thu",
    5: "Fri",
    6: "Sat"
  };

  transform(value: string, exponent: string): string {
    let parts = value.split('-');
    let mydate = new Date(Number(parts[0]), Number(parts[1]) - 1, Number(parts[2]));
    const day = mydate.getDay();
    return this.dayMap[day];
  }
}

@Pipe({name: 'personSeriesLocationLabel'})
export class PersonSeriesLocationLabel implements PipeTransform {
  transform(personSeries: any): string {

    if (personSeries.locationType == LocationType.ADDRESS) {

      let address = personSeries.address;
      if (personSeries.apartment) {
        address += ' - ' + personSeries.apartment;
      }
      if (personSeries.room) {
        address += ', ' + personSeries.room;
      }
      return address;
    }
    if (personSeries.locationType == LocationType.COORDS) {
      return personSeries.lat + ', ' + personSeries.lng;
    }
    if (personSeries.locationType == LocationType.UUID) {
      return personSeries.uuid;
    }
  }
}
