<div class="top-navbar-mobile">
  <nav class="navbar navbar-purple">
    <button class="px-4 navbar-toggler" type="button" data-toggle="collapse" data-target="#sidebar" aria-controls="sidebar" aria-expanded="false" aria-label="Toggle navigation">
      <i class="far fa-bars fa-lg"></i>
    </button>
    <div class="d-flex justify-content-center align-items-center w-100">
      <a routerLink="/" class="d-block d-lg-none">
        <img [src]="logoImgSrc" class="img-fluid">
      </a>
    </div>
  </nav>
</div>
