import {Inject, Injectable, OnDestroy} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, ReplaySubject} from 'rxjs';
import {SearchResultDto} from '../model/search-result-dto';
import {SMARTENCITY_TALLINN_CONFIG} from '../injection-tokens';
import {TallinnConfig} from '../tallinn-config.model';
import {ThroughputPoint, ThroughputResponse} from '../model/traffic';
import {Subject} from 'rxjs/internal/Subject';
import {takeUntil} from 'rxjs/operators';
import {RoadClosure} from '../../../../mobility/src/lib/model/road-closure';
import {DataObjects} from '../../../../core/src/lib/models/data-objects';
import {WaterTap} from '../model/water-tap';
import {WifiAccessPoint} from '../model/wifi-access-point';
import {AirQualityStation} from '../model/air-quality-station';
import {DisplaySettings} from '../model/display-settings';
import {NoiseLevel, NoiseLevelsPage} from '../component/noise-level-heatmap/noise-level-heatmap.model';

export interface RoadClosuresResponse {
  roadClosures: RoadClosure[]
}

@Injectable()
export class TallinnService implements OnDestroy {
  private ngDestroy = new Subject<void>();

  public throughputPoints: ThroughputPoint[] = [];
  public waterTaps: WaterTap[] = [];
  public wifiAccessPoints: WifiAccessPoint[] = [];
  public airQualityStationPoints: AirQualityStation[] = [];
  private noiseLevels: NoiseLevel[] = [];

  public waterTapsSource = new ReplaySubject<WaterTap[]>();
  public waterTaps$ = this.waterTapsSource.asObservable();

  public wifiAccessPointsSource = new ReplaySubject<WifiAccessPoint[]>();
  public wifiAccessPoints$ = this.wifiAccessPointsSource.asObservable();

  public throughputPointsSource = new ReplaySubject<ThroughputPoint[]>();
  public throughputPoints$ = this.throughputPointsSource.asObservable();

  public airQualityStationsSource = new ReplaySubject<AirQualityStation[]>();
  public airQualityStationPoints$ = this.airQualityStationsSource.asObservable();

  public noiseLevelsSource = new ReplaySubject<NoiseLevel[]>();
  public noiseLevels$ = this.noiseLevelsSource.asObservable();

  public airQualityStationSelectedSource = new ReplaySubject<AirQualityStation>();
  public airQualityStationSelected$ = this.airQualityStationSelectedSource.asObservable();

  public selectedClearedSource = new ReplaySubject();
  public selectedCleared$ = this.selectedClearedSource.asObservable();

  public locationsSelectedSource = new ReplaySubject<any[]>();
  public locationsSelected$ = this.locationsSelectedSource.asObservable();

  public selectLocations(obj: any[]): void {
    this.locationsSelectedSource.next(obj);
  }

  displaySettingsSource = new ReplaySubject<DisplaySettings>();
  displaySettings$ = this.displaySettingsSource.asObservable();


  constructor(
    private http: HttpClient,
    @Inject(SMARTENCITY_TALLINN_CONFIG) private config: TallinnConfig
  ) {
  }

  ngOnDestroy(): void {
    this.selectedClearedSource.complete();
    this.ngDestroy.complete();
  }

  getWaterTaps(): Observable<any> {
    return this.http.get<DataObjects<WaterTap>>(this.config.cityApiUrl + '/water-taps');
  }

  loadWaterTaps(): void {
    this.getWaterTaps().pipe(takeUntil(this.ngDestroy)).subscribe((response: DataObjects<WaterTap>) => {
      this.waterTapsSource.next(response.data);
    });

  }

  getTrafficThroughPut(): Observable<ThroughputResponse> {
    return this.http.get<ThroughputResponse>(this.config.cityApiUrl + '/person-series', {
      params: {
        cityPortalType: 'TRAFFIC_THROUGHPUT',
        size: '200'
      }
    });
  }

  loadTrafficThroughPut(): void {
    this.getTrafficThroughPut().pipe(takeUntil(this.ngDestroy)).subscribe(((data: ThroughputResponse) => {
      this.throughputPoints = data.content;
      this.throughputPointsSource.next(data.content);
    }));
  }

  getWifiAccessPoints(): Observable<any> {
    return this.http.get(this.config.cityApiUrl + '/person-series', {
      params: {
        cityPortalType: 'WIFI_ACCESS_POINT',
        size: '2000'
      }
    });
  }

  loadWifiAccessPoints(): void {
    this.getWifiAccessPoints().pipe(takeUntil(this.ngDestroy)).subscribe((data: any) => {
      this.wifiAccessPoints = TallinnService.mapSeriesToWifiAccessPoints(data.content);
      this.wifiAccessPointsSource.next(this.wifiAccessPoints);
    });
  }

  getAirQualityStations(): Observable<any> {
    return this.http.get(this.config.cityApiUrl + '/person-series', {
      params: {
        cityPortalType: 'AIR_QUALITY_MONITORING_STATION',
        size: '2000'
      }
    });
  }

  loadAirQualityStations(): void {
    this.getAirQualityStations().pipe(takeUntil(this.ngDestroy)).subscribe((data: any) => {
      this.airQualityStationPoints = TallinnService.mapSeriesToAirQualityStations(data.content);
      this.airQualityStationsSource.next(this.airQualityStationPoints);
    });
  }


  getNoiseLevels(params?: any): Observable<NoiseLevelsPage> {
    if (!params) params = {};
    params.cityPortalType = 'NOISE_LEVEL';

    return new Observable(observer => {
      this.http.get(this.config.cityApiUrl + '/person-series', {
        params: params
      }).subscribe(
        (data: NoiseLevelsPage) => {
          return observer.next(data);
        }, err => {
          return observer.error(err);
        });
    });
  }

  loadNoiseLevels(params?: any): void {
    this.getNoiseLevels(params).pipe(takeUntil(this.ngDestroy)).subscribe((data: NoiseLevelsPage) => {
      this.noiseLevels = this.noiseLevels.concat(data.content);
      if (params && data.totalPages > params.page) {
        params.page = params.page + 1;
        this.loadNoiseLevels(params);
      } else {
        this.noiseLevelsSource.next(this.noiseLevels);
      }
    });
  }


  searchTallinnObjects(keyword: string ): Observable<SearchResultDto> {
    keyword = keyword.toUpperCase();
    keyword = keyword.trim();

    return new Observable(observer => {
      const res = new SearchResultDto();
      return observer.next(res);
    });
  }

  private static mapSeriesToWifiAccessPoints(data: any[]): WifiAccessPoint[] {
    let result: WifiAccessPoint[] = [];
    for (let item of data) {
      let ap: WifiAccessPoint = new WifiAccessPoint();
      ap.name = item.address;
      ap.address = item.address;
      ap.lat = item.lat;
      ap.lng = item.lng;
      ap.connectedCount = item.value;
      result.push(ap);
    }

    return result;
  }

  private static mapSeriesToAirQualityStations(data: any[]) {
    const airQualityStationMap: any = {};
    const res = [];

    data.forEach(value => {
      const key = value.readableAddress;

      if ( !airQualityStationMap[key] ) {
        airQualityStationMap[key] = {};
        airQualityStationMap[key]['series'] = [];
        airQualityStationMap[key]['id'] = key;
        airQualityStationMap[key]['lat'] = value.lat;
        airQualityStationMap[key]['lng'] = value.lng;
        airQualityStationMap[key]['address'] = value.address;
      }

      if (!airQualityStationMap[key]['readableAddress'] && value.readableAddress) {
        airQualityStationMap[key]['readableAddress'] = value.readableAddress
      }

      airQualityStationMap[key]['series'].push(value);

      for (const series of airQualityStationMap[key]['series']) {
        series.indicator = series.location.split('- ')[1].split(' ')[0];
      }
    });

    for (const key of Object.keys(airQualityStationMap)) {
      res.push(airQualityStationMap[key]);
    }

    return res;
  }
}
