import {
  DataTable,
  DataTableRow,
  Observation,
  PersonSeries,
  Series
} from '../model/road-weather-site-types';
import { RoadWeatherSiteConstants, RoadWeatherSiteIndicator } from "../model/road-weather-site-constants";
import moment from 'moment';
import {RoadWeatherSite} from '../model/road-weather-site';

export abstract class RoadWeatherDataService {

  abstract getIndicators(): RoadWeatherSiteIndicator[];

  public mapRoadWeatherData(roadWeatherData: RoadWeatherSite, personSeriesList: PersonSeries[]): DataTable {
    let data: DataTable = {rows: []};
    let observation: Observation = null;
    let forecasts: Series[] = [];

    for (let personSeries of personSeriesList) {
      if (!personSeries.data) {
        continue;
      }

      if (personSeries.personSeriesId === roadWeatherData.observationsSeriesId) {
        observation = personSeries.data;
      }

      if (personSeries.personSeriesId === roadWeatherData.forecastsSeriesId) {
        forecasts = personSeries.data.series as Series[];
      }
    }

    data.rows.push(this.mapRoadWeatherDataToObservation(observation));
    data.rows.push(...this.mapRoadWeatherDataToForecast(roadWeatherData, forecasts));

    return data;
  }

  private mapRoadWeatherDataToObservation(observation: Observation): DataTableRow {
    const current = RoadWeatherSiteConstants.timeTableIndicators['current'];
    const row: DataTableRow = {fields: [{code: current.key, value: current.label, unit: null}]};

    for (let indicator of this.getIndicators()) {
      let observationMatch = {code: indicator.key, value: null, unit: null};
      if (observation) {
        for (let series of observation.series) {
          if (series.dataSourceId == indicator.key) {
            observationMatch = {code: indicator.key, value: series.value, unit: series.unit};
            break;
          }
        }
      }

      row.fields.push(observationMatch);
    }

    return row;
  }

  private mapRoadWeatherDataToForecast(roadWeatherData: RoadWeatherSite, forecasts: Series[]): DataTableRow[] {
    let result: DataTableRow[] = [];
    const currentDatetime = moment(roadWeatherData.updatedAt);

    if (!forecasts) {
      forecasts = [];
    }

    const forecastsMap = {
      'plus-1-hour': {
        range: {start: this.getStartDateTime(1, currentDatetime), end: this.getEndDateTime(3, currentDatetime)},
        data: [],
        latestValues: {}
      },
      'plus-3-hours': {
        range: {start: this.getStartDateTime(3, currentDatetime), end: this.getEndDateTime(6, currentDatetime)},
        data: [],
        latestValues: {}
      },
      'plus-6-hours': {
        range: {start: this.getStartDateTime(6, currentDatetime), end: this.getEndDateTime(12, currentDatetime)},
        data: [],
        latestValues: {}
      },
      'plus-12-hours': {
        range: {start: this.getStartDateTime(12, currentDatetime), end: this.getEndDateTime(17, currentDatetime)},
        data: [],
        latestValues: {}
      },
    };

    for (const entry of Object.entries(forecastsMap)) {
      const range = entry[1].range;
      const indicatorKey = entry[0];
      const mapItem = forecastsMap[indicatorKey];

      for (let indicator of this.getIndicators()) {
        let forecastMatch = null;
        for (const forecast of forecasts) {

          const isBetween = moment(forecast.time).isBetween(range.start, range.end);

          if (indicator.key == forecast.dataSourceId && isBetween) {
            forecastMatch = forecast;
            break;
          }
        }

        if (forecastMatch) {
          if (mapItem.latestValues[indicator.key] == null || mapItem.latestValues[indicator.key].start < forecastMatch.start) {
            mapItem.latestValues[indicator.key] = forecastMatch;
          }
          let existing = mapItem.data.find((value) => value.code == forecastMatch.dataSourceId);

          if (existing) {
            continue;
          }

          mapItem.data.push({code: forecastMatch.dataSourceId, value: forecastMatch.value, unit: forecastMatch.unit});
        } else {
          let lastItem = null;
          if (indicatorKey == 'plus-12-hours' && forecastsMap['plus-6-hours'].latestValues[indicator.key] != null) {
            lastItem = forecastsMap['plus-6-hours'].latestValues[indicator.key];
          }
          if (lastItem) {
            mapItem.data.push({code: lastItem.dataSourceId, value: lastItem.value, unit: lastItem.unit});
          } else {
            mapItem.data.push({code: indicator.key, value: null, unit: null});
          }
        }
      }
    }

    for (let [key, entry] of Object.entries(forecastsMap)) {
      let timeslotValue = (RoadWeatherSiteConstants.timeTableIndicators[key] ?
        RoadWeatherSiteConstants.timeTableIndicators[key].label : 'N/A');
      const row: DataTableRow = {fields: [{code: key, value: timeslotValue, unit: null}]};

      for (let forecastItem of entry.data) {
        row.fields.push(forecastItem);
      }

      result.push(row);
    }

    return result;
  }

  getHoursInFuture(hours: number, currentDateTime: moment.Moment) {
    return moment(this.roundToNearestMinutes(currentDateTime, 30)).add(hours, 'hours');
  }

  getStartDateTime(hours: number, currentDatetime: moment.Moment) {
    return this.getHoursInFuture(hours, currentDatetime);
  }

  getEndDateTime(hours: number, currentDatetime: moment.Moment) {
    return this.getHoursInFuture(hours, currentDatetime);
  }

  roundToNearestMinutes(datetime: moment.Moment, minutes: number) {
    return datetime.add(datetime.minute() > minutes && 1, 'hours').minutes(datetime.minute() <= minutes ? minutes : 0);
  }
}
