<div class="container-fluid" *ngIf="currentMandate">
  <div class="row">
    <div class="col-md-12 mt-4">
      <ng-template #sharedPopover let-token="token">
        <div>
          <span *ngIf="isMyToken(token)" i18n>Shared by me</span>
          <span *ngIf="!isMyToken(token)" i18n>Shared to me</span>
        </div>
      </ng-template>

      <div class="block mb-5" *ngIf="items && items.length">
        <div class="row">
          <div class="col-12">
            <h3 class="section-title text-black mb-3" i18n>DAL API Keys</h3>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="block mt-4">
              <p i18n>You can start using an API key to make web service requests. Take a look at the <a href="{{documentationUrl}}" target="_blank">API documentation</a></p>
              <h6 i18n>Authentication</h6>
              <p i18n>The API uses header authentication with an API key. The format of the header should be: </p>
              <code>X-Auth-Token: {{items[0].token.value}}</code>
            </div>
            <div class="row">
              <div class="col-12 mb-3" *ngIf="config.dataMappingUrl">
                <hr/>
                <a href="{{config.dataMappingUrl}}" target="_blank" class="btn btn-sm btn-primary" i18n>API Keys management</a>
              </div>
            </div>
          </div>
          <div class="col-md-8" >
            <ngx-datatable
              class="material"
              [draggable]="false"
              [columnMode]="'flex'"
              [headerHeight]="30"
              [footerHeight]="50"
              [reorderable]="false"
              rowHeight="auto"
              [scrollbarH]="false"
              [scrollbarV]="false"
              [rows]="items"
              [externalPaging]="false"
              [externalSorting]="false"
            >
              <ngx-datatable-column name="Name" i18n-name prop="token.name" [flexGrow]="1" [resizeable]="false" [sortable]="false">
                <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                  <span *ngIf="row.token.primary" tooltip="My personal key" i18n-tooltip><i class="fa-solid fa-key"></i></span>
                  <span *ngIf="row.token.assigned" [popover]="sharedPopover" placement="right" [popoverContext]="{ token: row.token }" triggers="mouseenter:mouseleave">
                    <i class="fa-solid fa-arrow-down-left" *ngIf="!isMyToken(row.token)"></i>
                    <i class="fa-solid fa-arrow-up-right" *ngIf="isMyToken(row.token)"></i>
                  </span> {{value}}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Shared persons" i18n-name prop="tokenPersons" [flexGrow]="1" [resizeable]="false" [sortable]="false">
                <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                  <span *ngIf="isMyToken(row.token) && row.token.tokenPersons?.length">
                    <share-person-item-list (click)="showTokenPersons(row.token)" [persons]="mapTokenPersonToPersons(row.token.tokenPersons)"></share-person-item-list>
                  </span>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Shared history" i18n-name [flexGrow]="1" [resizeable]="false" [sortable]="false">
                <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                  <span *ngIf="isMyToken(row.token) && row.history?.persons.length > 0">
                    <button type="button" class="btn btn-sm btn-light" i18n (click)="showHistory(row)">Show</button>
                  </span>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Value" i18n-name prop="token.value" [flexGrow]="1" [resizeable]="false" [sortable]="false">
                <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>{{value}}</ng-template>
              </ngx-datatable-column>
            </ngx-datatable>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
