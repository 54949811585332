<div class="cookie-banner" *ngIf="cookieConfirmationRequired">
  <div class="cookie-banner-body">
    <div class="content">
      <p i18n>This website needs some cookies and similar means to function. If you permit us, we will use Google Analytics to collect data on your visits for aggregated statistics to improve our service.</p>
    </div>
    <div class="buttons">
      <button type="button" class="btn btn-primary btn-lg btn-bordered" (click)="allowCookies()"><span i18n>Enable analytics cookies</span></button>
      <button type="button" class="btn btn-primary btn-lg btn-bordered" (click)="declineCookies()"><span i18n>Decline</span></button>
    </div>
  </div>
</div>
