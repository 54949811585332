<ng-template #dataPointsInfo
             let-row="row"
             let-value="value">

  <ng-template #hasOverduePersonSeries>
    <span i18n>Group contains person series having latest value overdue</span>
  </ng-template>

  <ng-template #tooltip>
    <ng-container *ngIf="row.latestValue?.event">
      <span i18n>Latest value is event</span>
    </ng-container>
    <ng-container *ngIf="row.latestValue?.measurement">
      <span i18n>Latest value:</span>
      {{row.latestValue?.measurement.value}}{{row.latestValue?.measurement.unit}} {{row.latestValue?.measurement.time|date: 'long' }}
    </ng-container>
    <ng-container *ngIf="!row.latestValue">
      <span i18n>Latest value not found</span>
    </ng-container>
  </ng-template>

  <span>
    <span *ngIf="row.group && row.group.latestValueOverdueCount > 0" [tooltip]="hasOverduePersonSeries">
      <i class="fa fa-info-circle btn-tooltip text-warning"></i>
    </span>
    {{value}}
  </span>

  <ng-container *ngIf="!row.group">

    <button class="btn btn-icon mr-1" [tooltip]="tooltip">
      <i class="fa fa-info-circle btn-tooltip" [ngClass]="{ 'text-warning' : row.latestValueOverdue} "></i>
    </button>
  </ng-container>
</ng-template>

<loading-spinner *ngIf="!rows"></loading-spinner>
<ngx-datatable
  *ngIf="rows"
  #table
  class="material expandable"
  [columnMode]="'flex'"
  [headerHeight]="30"
  [footerHeight]="50"
  [reorderable]="false"
  rowHeight="auto"
  [treeFromRelation]="'parentRowId'"
  [treeToRelation]="'rowId'"
  [rows]="rows"
  [rowClass]="getRowClass"
  (treeAction)="onTreeAction($event)"
  [externalPaging]="true"
  [externalSorting]="true"
  [count]="treePageCount"
  [limit]="treePageLimit"
  [offset]="page?.page"
  [sorts]="defaultSorts"
  (page)="setPage($event)"
  (sort)="setSorts($event)"
  [messages]="{emptyMessage: ''}"
>
  <ngx-datatable-row-detail [rowHeight]="'auto'" #detailRow>
    <ng-template
      let-row="row"
      let-value="value"
      let-name="row.name"
      let-location="row.location"
      let-type="row.type"
      let-unit="row.unit"
      let-datapoints="row.datapoints"
      let-createdAt="row.createdAt"
      ngx-datatable-row-detail-template
    >
      <div style="font-size:14px; padding: 0.7rem; background: inherit;">
        <div class="row">
          <div class="col-6" *ngIf="row.group">
            <label i18n>Group name</label>
          </div>
          <div class="col-6" *ngIf="!row.group">
            <label i18n>Name</label>
          </div>
          <div class="col-6">{{name}}</div>
        </div>
        <div class="row">
          <div class="col-6"><label i18n>Location</label></div>
          <div class="col-6">{{location}}</div>
        </div>

        <div class="row">
          <div class="col-6"><label i18n>Type</label></div>
          <div class="col-6">{{type}}</div>
        </div>

        <div class="row">
          <div class="col-6"><label i18n>Unit</label></div>
          <div class="col-6">{{unit}}</div>
        </div>

        <div class="row">
          <div class="col-6"><label i18n>Datapoints</label></div>
          <div class="col-6">
            <span *ngIf="row.group">{{datapoints}}</span>
            <span *ngIf="!row.group">
               <ng-template *ngTemplateOutlet="dataPointsInfo; context: { row: row, value : value }"></ng-template>
            </span>
          </div>
        </div>

        <div class="row" *ngIf="row.group">
          <div class="col-6"><label i18n>Created</label></div>
          <div class="col-6">{{createdAt | date:'dd. MMMM yyyy'}}</div>
        </div>

        <div class="row pt-3">
          <button type="button" class="btn btn-sm btn-link no-caret" (click)="edit(row.group)" *ngIf="row.group">
            <i class="fal fa-edit mr-2"></i>
            <ng-container i18n>Edit</ng-container>
          </button>
          <button type="button" class="btn btn-sm btn-link no-caret" (click)="delete(row.group)" *ngIf="row.group">
            <i class="fal fa-trash mr-2"></i>
            <ng-container i18n>Delete</ng-container>
          </button>
        </div>
      </div>
    </ng-template>
  </ngx-datatable-row-detail>

  <ngx-datatable-column *ngIf="columns.name" name="Group name" i18n-name prop="name" [flexGrow]="2" [resizeable]="false" [sortable]="true">
    <ng-template let-value="value" let-row="row" let-treeStatus="treeStatus" let-onTreeAction="onTreeAction" ngx-datatable-cell-template>
      <a class="btn btn-link btn-xs p-0" (click)="onTreeAction()" *ngIf="row.group && treeStatus == 'collapsed'"><i class="fal fa-plus mr-2"></i>&nbsp;{{value}}</a>
      <a class="btn btn-link btn-xs p-0" (click)="onTreeAction()" *ngIf="row.group && (treeStatus == 'expanded' || treeStatus == 'loading')"><i class="fal fa-minus mr-2"></i>&nbsp;{{value}}</a>
      <ng-container *ngIf="!row.group">
        <span>{{value}}</span>
      </ng-container>
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column *ngIf="columns.location" name="Location" i18n-name prop="location" [flexGrow]="1" [resizeable]="false" [sortable]="false">
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>{{value}}</ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column *ngIf="columns.type" name="Type" i18n-name prop="type" [flexGrow]="1" [resizeable]="false" [sortable]="false">
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>{{value}}</ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column *ngIf="columns.unit" name="Unit" i18n-name prop="unit" [flexGrow]="1" [resizeable]="false" [sortable]="false">
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>{{value}}</ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column *ngIf="columns.datapoints" name="Datapoints" i18n-name prop="datapoints" [flexGrow]="1" [resizeable]="false" [sortable]="false">
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
      <ng-template *ngTemplateOutlet="dataPointsInfo; context: { row: row, value : value }"></ng-template>
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column *ngIf="columns.createdAt" name="Created" i18n-name prop="createdAt" [flexGrow]="1" [resizeable]="false" [sortable]="true">
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>{{value | date: 'dd. MMMM yyyy'}}</ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column *ngIf="columns.errors" name="Datapoint group update errors" i18n-name prop="updateErrorCount" [flexGrow]="1" [resizeable]="false" [sortable]="true">
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>

      <ng-container *ngIf="value > 0">
        <ng-template #tooltip>
          <span i18n>Number of errors: {{value}}</span>
        </ng-template>
        <button class="btn btn-icon text-warning mr-1" [tooltip]="tooltip" (click)="showUpdateErrors(row.group)">
          <i class="fa fa-info-circle"></i>
        </button>
      </ng-container>
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column *ngIf="columns.actions" [resizeable]="false" [sortable]="false" [flexGrow]="1" [cellClass]="'justify-content-end'">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <div *ngIf="row.group" class="btn-group btn-more" dropdown>
        <button type="button" class="btn btn-icon dropdown-toggle" dropdownToggle><i class="far fa-ellipsis-v"></i></button>
        <div class="dropdown-menu dropdown-menu-right" *dropdownMenu>
          <a class="dropdown-item" (click)="edit(row.group)"><i class="fal fa-edit"></i> <ng-container i18n>Edit</ng-container></a>
          <a class="dropdown-item" (click)="delete(row.group)"><i class="fal fa-trash"></i> <ng-container i18n>Delete</ng-container></a>
        </div>
      </div>
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column
    *ngIf="columns.detailToggler"
    [width]="50"
    [resizeable]="false"
    [sortable]="false"
    [draggable]="false"
    [canAutoResize]="false"
    headerClass="justify-content-end"
    cellClass="justify-content-end"
  >
    <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
      <a
        [routerLink]="null"
        [class.datatable-icon-right]="!expanded"
        [class.datatable-icon-down]="expanded"
        title="Expand/Collapse Row"
        (click)="toggleExpandRow(row)"
      >
      </a>
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-footer>
    <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize" let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset">
      <datatable-pager
        [pagerLeftArrowIcon]="'datatable-icon-left'"
        [pagerRightArrowIcon]="'datatable-icon-right'"
        [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'"
        [page]="page?.page + 1" [size]="page?.size" [count]="page?.totalElements"
        [hidden]="!((page?.totalElements / page?.size) > 1)" (change)="setPage($event)">
      </datatable-pager>
    </ng-template>
  </ngx-datatable-footer>
</ngx-datatable>
