<div class="jumbotron floating-jumbotron search-wrapper">
  <div class="input-group input-group-lg">
    <input [formControl]="keywordControl"  type="text" class="form-control">
    <div class="input-group-append">
      <span (click)="clear()" class="clear-search" [ngClass]="{'active': keywordControl.value?.length > 0 }">
        <i class="fal fa-times"></i>
      </span>
      <span class="input-group-text">
        <i class="fal fa-search"></i>
      </span>
    </div>
  </div>
</div>

<div class="jumbotron floating-jumbotron content-wrapper" id="search-results"
  [ngClass]="{'active': stops.length > 0 ||
                        buses.length > 0 ||
                        evChargers.length > 0 ||
                        evChargersDk.length > 0 ||
                        bicycleStations.length > 0 ||
                        throughputPoints.length > 0
  }">
  <ul class="list-group">
    <ng-container *ngIf="stops.length > 0">
      <li class="list-group-item title" i18n>Bus stop</li>
      <li *ngFor="let stop of stops" class="list-group-item" (click)="selectStop(stop)">
        {{stop.title}}<ng-container *ngIf="stop.description"> - {{stop.description}}</ng-container>
      </li>
    </ng-container>
    <ng-container *ngIf="buses.length > 0">
      <li class="list-group-item title"><i class="bus-icon-primary"></i><ng-container i18n>Bus</ng-container></li>
      <li class="list-group-item" *ngFor="let bus of buses" (click)="selectBus(bus)">{{bus.route}} - {{bus.description}}</li>
    </ng-container>
    <ng-container *ngIf="evChargers.length > 0">
      <li class="list-group-item title"><ng-container i18n>Electric vehicle charger</ng-container></li>
      <li class="list-group-item" *ngFor="let evCharger of evChargers" (click)="selectEvCharger(evCharger)">{{evCharger.name}}</li>
    </ng-container>
    <ng-container *ngIf="throughputPoints.length > 0">
      <li class="list-group-item title"><ng-container i18n>Traffic</ng-container></li>
      <li class="list-group-item" *ngFor="let throughputPoint of throughputPoints" (click)="selectThroughputPoint(throughputPoint)">{{throughputPoint.name}}</li>
    </ng-container>
    <ng-container *ngIf="bicycleStations.length > 0">
      <li class="list-group-item title"><ng-container i18n>Bicycle station</ng-container></li>
      <li class="list-group-item" *ngFor="let bicycleStation of bicycleStations" (click)="selectBicycleStation(bicycleStation)">{{bicycleStation.name}}</li>
    </ng-container>
    <ng-container *ngIf="evChargersDk.length > 0">
      <li class="list-group-item title"><ng-container i18n>Electric vehicle charger</ng-container></li>
      <li class="list-group-item" *ngFor="let evCharger of evChargersDk" (click)="selectEvChargerDk(evCharger)">{{evCharger.name}}</li>
    </ng-container>
  </ul>
</div>
