import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {BaseMapType, CoreConfig, SMARTENCITY_CORE_CONFIG} from '@smartencity/core';
import { MobilityService } from '../service/mobility.service';

@Component({
  selector: 'mobility-main',
  templateUrl: './mobility.component.html'
})
export class MobilityComponent implements OnInit {

  mapType: BaseMapType;
  mobilityService: MobilityService;

  constructor(@Inject(SMARTENCITY_CORE_CONFIG) private config: CoreConfig) {
    this.mapType = (config.map.mapType ? config.map.mapType : 'gmap')
  }

  ngOnInit(): void {
  }
}
