import {ChangeDetectorRef, Component, Input, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {CityApiService, PersonSeries} from '@smartencity/core';
import {Subject} from 'rxjs/internal/Subject';
import {MyLocationButton} from '@smartencity/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import LatLngLiteral = google.maps.LatLngLiteral;

@Component({
  selector: 'mydata-location-map',
  templateUrl: './location-map.component.html'
})
export class LocationMapComponent implements OnInit, OnDestroy {
  private ngDestroy = new Subject<void>();

  @ViewChild('gmap', {static: true})
  gmapElement: any;

  @ViewChild('pacinput', {static: true})
  pacinput: any;

  @Input()
  public callback: (result: LatLngLiteral) => void;

  map: google.maps.Map;
  marker: google.maps.Marker;

  public personSeries: PersonSeries;
  public coords: {lat: number, lng: number};

  constructor (
    public modalRef: BsModalRef,
    public cityService: CityApiService,
    private zone: NgZone,
    private cd: ChangeDetectorRef
  ) {

  }

  ngOnInit () {
    const conf = this.cityService.getMapsConfig();

    const mapProp = {
      center: conf.center,
      zoom: conf.zoom
    };
    this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);

    const style = [
      {
        stylers: [
          {saturation: -99}
        ]
      }
    ];


    this.marker = new google.maps.Marker({
      map: this.map,
      title: this.personSeries ? this.personSeries.name : 'Location',
      draggable: true
    });


    google.maps.event.addListenerOnce(this.map, 'idle', () => {
      this.zone.run(() => {
        const latLng = {
          lat: conf.center.lat,
          lng: conf.center.lng
        };

        if (this.coords.lat && this.coords.lng) {
          latLng.lat = this.coords.lat;
          latLng.lng = this.coords.lng;
        }

        this.marker.setPosition(latLng);

        this.map.setCenter(latLng);
        this.cd.markForCheck();

      });
    });


    this.map.mapTypes.set('map-style', new google.maps.StyledMapType(style, {}));
    this.map.setMapTypeId('map-style');


    const searchBox = new google.maps.places.SearchBox(this.pacinput.nativeElement);
    this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(this.pacinput.nativeElement);
    this.map.addListener('bounds_changed', () => {
      searchBox.setBounds(this.map.getBounds());
    });

    // Listen for the event fired when the user selects a prediction and retrieve
    // more details for that place.
    searchBox.addListener('places_changed', () => {
      const places = searchBox.getPlaces();

      if (places.length === 0) {
        return;
      }

      this.marker.setMap(null);
      // For each place, get the icon, name and location.
      const bounds = new google.maps.LatLngBounds();
      places.forEach((place) => {
        if (!place.geometry) {
          console.log('Returned place contains no geometry');
          return;
        }

        this.marker.setPosition(place.geometry.location);
        this.marker.setTitle(place.name);
        this.marker.setMap(this.map);

        if (place.geometry.viewport) {
          // Only geocodes have viewport.
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
      });
      this.map.fitBounds(bounds);
    });

    const myLoc = new MyLocationButton(this.map);
    myLoc.marker = this.marker;
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  close () {
    this.callback(null);
    this.modalRef.hide();
  }

  confirm() {
    this.callback(this.marker.getPosition().toJSON());
    this.modalRef.hide();
  }
}
