<div class="modal-header d-flex">
  <div style="display: flex; align-items: stretch; flex-direction: column;">
    <h5 class="modal-title w-100" i18n>Data sharing</h5>
    <small i18n>{{group.name}} data group</small>
  </div>
  <button type="button" class="close pull-right" i18n-aria-label aria-label="Close" (click)="close()">
    <i class="fal fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <div [formGroup]="consentForm">
    <div class="row">
      <div class="col">
        <div class="alert alert-light">
          <span>Sharing <span><b>{{shareData.sharedCount}}</b></span> out of {{shareData.count}} data point(s).</span>
        </div>
      </div>
    </div>

    <div class="d-block">
      <div *ngIf="consentForm.get('person').invalid && showValidation" class="alert alert-danger">
        <div *ngIf="consentForm.get('person').errors['required']">
          <span i18n>Personal identification or registration code</span>&nbsp;<span i18n>is required</span>
        </div>
        <div *ngIf="consentForm.get('person').errors['personRegistrationNumber']">
          <span i18n>Personal identification or registration code</span>&nbsp;<span i18n>is invalid</span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 col-sm-12">
        <div class="form-group">
          <ng-select
            class="custom"
            [clearable]="true"
            [searchable]="true"
            placeholder="Personal identification or registration code"
            i18n-placeholder
            appendTo=".modal"
            formControlName="person"
            [typeahead]="personTypeahead$"
            [items]="personItems$ | async"
            [addTag]="createPerson"
            [selectOnTab]="true"
            addTagText="New person with registration number"
            i18n-addTagText>
            <ng-template ng-label-tmp let-item="item">
              <span>{{(item.displayName ? '' + item.displayName + ' ' : '') + '(' + (item | regNo) + ')'}}</span>
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
              <span>{{(item.displayName ? '' + item.displayName + ' ' : '') + '(' + (item | regNo) + ')'}}</span>
            </ng-template>
          </ng-select>
        </div>
      </div>
    </div>
    <div class="row my-2">
      <ng-container>
        <div class="col-md-5 col-sm-12">
          <div class="checkbox">
            <input id="last-data-owner-start" type="checkbox" formControlName="startFromLastDataOwnerPeriod" />
            <label [attr.for]="'last-data-owner-start'" i18n>From the last data owner period start</label>
          </div>
        </div>
        <div class="col-md-4 col-sm-6" *ngIf="!consentForm.get('startFromLastDataOwnerPeriod').value">
          <datepicker-wrapper [id]="'last-data-owner-start'"
                              [control]="consentForm.get('customStartAt')"></datepicker-wrapper>
        </div>
      </ng-container>
      <div class="col-md-auto col-sm-6 d-flex align-items-center mt-2 mt-md-0">
        <button class="btn btn-sm btn-primary" (click)="addConsent()" [ladda]="addConsentProgress">
          <i class="fal fa-share"></i>&nbsp;<ng-container i18n>Share</ng-container>
        </button>
      </div>
    </div>
  </div>

</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary btn-sm" i18n (click)="close()">Close</button>
</div>


