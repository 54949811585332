import {Injectable} from '@angular/core';
import {AddressService} from './address.service';
import {AbstractControl, FormControl, FormGroup} from '@angular/forms';
import {HttpClient, HttpXhrBackend} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {debounceTime, distinctUntilChanged, map, switchMap, tap} from 'rxjs/operators';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {Subject} from 'rxjs/internal/Subject';

@Injectable()
export class DawaAddressService implements AddressService {
  private httpClient: HttpClient;
  public form: FormGroup;
  public dawaAddress = new FormControl();
  public searching$ = new BehaviorSubject<boolean>(false);
  public addressSelect$ = new Subject<any>();
  public apartmentTypeahead = undefined;

  inputFormatter = (input: any) => {
    if ( input.tekst !== undefined ) {
      return input.tekst.trim();
    } else {
      return input;
    }
  };
  resultFormatter = (result: any) => result.tekst.trim();

  constructor(
    private httpBackend: HttpXhrBackend,
  ) {
    this.httpClient = new HttpClient(httpBackend);
    this.addressTypeahead = this.addressTypeahead.bind(this);

    this.dawaAddress.markAsTouched();
  }

  public initForm(form: FormGroup): void {
    this.form = form;

    this.dawaAddress.valueChanges.subscribe((address) => {
      if (address && address.tekst && address.data) {
        this.form.get('address').setValue(address.tekst);
      } else {
        this.form.get('address').setValue(address);
      }
    });

    const formValue = form.getRawValue();
    if (formValue) {
      if (formValue.adsOid) {
        this.dawaAddress.setValue({
          tekst: formValue.address
        });
      } else {
        this.dawaAddress.setValue(formValue.address);
      }
    }
  }

  public getForm(): FormGroup {
    return this.form;
  }

  public getAddressControl(): AbstractControl {
    return this.dawaAddress;
  }

  public getApartmentControl(): AbstractControl {
    return this.form.get('apartment');
  }

  public getRoomControl(): AbstractControl {
    return this.form.get('room');
  }

  public addressTypeahead(text$: Observable<string>) {
    return text$.pipe(
      debounceTime(350),
      distinctUntilChanged(),
      tap(() => this.searching$.next(true)),
      switchMap((text: string) => {
        return this.httpClient.get('https://dawa.aws.dk/autocomplete?fuzzy=true&q=' + encodeURI(text), {withCredentials: false});
      }),
      map((results: any) => {
        if (!results) {
          return [];
        }
        return results;
      }),
      tap(() => this.searching$.next(false))
    );
  }

  public selectAddress(addressItem: any) {
    this.addressSelect$.next(addressItem && addressItem.tekst ? addressItem.tekst : null);
  }

}
