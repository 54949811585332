import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SMARTENCITY_MOBILITY_CONFIG} from './../../injection-tokens';
import {MobilityConfig, MapsConfig} from './../../mobility-config.model';
import {BicycleStationsPage, BicycleStationsHistogram, BicycleStationState} from './bicylce-stations.model';
import {Observable} from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class BicycleStationsService {

  constructor(
    private http: HttpClient,
    @Inject(SMARTENCITY_MOBILITY_CONFIG) private config: MobilityConfig
  ) { }

  getMapsConfig(): MapsConfig {
    return this.config.map;
  }

  getBicycleStationsState(params: any): Observable<BicycleStationState> {
    params.cityPortalType = 'BICYCLE_STATIONS';
    return new Observable(observer => {
      this.http.get(this.config.cityApiUrl + '/person-series/state', {
        params: params
      }).subscribe(
        (data: BicycleStationState) => {
          return observer.next(data);
        }, err => {
          return observer.error(err);
        });
    });
  }

  getBicycleStationsHistogram(): Observable<BicycleStationsHistogram> {
    return new Observable(observer => {
      this.http.get(this.config.cityApiUrl + '/person-series/bicycle-stations/histogram', {
        params: {}
      }).subscribe(
        (data: BicycleStationsHistogram) => {
          return observer.next(data);
        }, err => {
          return observer.error(err);
        });
    });
  }

  getBicycleParkingStationsHistogram(): Observable<BicycleStationsHistogram> {
    //TODO: kas neid peaks iga portaali jaoks eristama v hetkel eristab Tallinn (parking) vs muu (rental)

    return this.getBicycleStationsHistogram();
    // return new Observable(observer => {
    //   this.http.get(this.config.cityApiUrl + '/person-series/bicycle-stations/histogram', {
    //     params: {}
    //   }).subscribe(
    //     (data: BicycleStationsHistogram) => {
    //       return observer.next(data);
    //     }, err => {
    //       return observer.error(err);
    //     });
    // });
  }
}
