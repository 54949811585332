import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  LOCALE_ID,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {Subject} from 'rxjs/internal/Subject';
import {
  AuthService, ChartDataService, ChartService, Consent, CsvDataService,
  EnumsService,
  LatestValue, Location, NumberFormatConstants, Person,
  PersonSeries, PersonSeriesApiService, PersonSeriesCompareService,
  PersonSeriesHistoricalResponse, PersonSeriesPeriod, PreventCloseModalService, SamplingType,
  SourceInfo, SourceOwnerSeries, Threshold
} from '@smartencity/core';
import {FormControl} from '@angular/forms';
import {debounceTime, distinctUntilChanged, shareReplay, startWith, take, takeUntil, withLatestFrom} from 'rxjs/operators';
import {ReplaySubject, combineLatest, BehaviorSubject} from 'rxjs';
import moment from 'moment';
import {Moment} from 'moment-timezone';
import {DecimalPipe} from '@angular/common';
import {BsModalService} from 'ngx-bootstrap/modal';

import {Chart} from 'chart.js';
import 'chartjs-plugin-colorschemes';
import * as ChartAnnotation from 'chartjs-plugin-annotation';
import {ToastrService} from 'ngx-toastr';
import {HttpClient} from '@angular/common/http';
import {CurrentDatapointService} from '../../../services/current-datapoint.service';
import {ThresholdsModalComponent} from '../../consumption/person-series/thresholds/thresholds-modal.component';
import {ConsentModalComponent} from '../../consumption/person-series/consent/consent-modal.component';
import {AddDataModalComponent} from '../../consumption/person-series/add-data/add-data-modal.component';
import {ComponentLoaderFactory} from 'ngx-bootstrap/component-loader';
import {SMARTENCITY_MYDATA_CONFIG} from '../../../injection-tokens';
import {PersonSeriesEventModalComponent} from '../../modal/person-series-event-modal.component';
import {MyDataConfig} from '../../../mydata-config.model';
import {ChartDefaults} from './chart-defaults';
import {
  NgxDrpOptions,
  NgxMatDrpComponent,
  Range
} from '@smartencity/shared';

const selector = 'mydata-datapoint-chart';
let nextId = 0;

export interface Filters {
  range: {from: Moment, to: Moment, aggTypes: any};
  aggregationGroupingType: string;
}

@Component({
  selector: selector,
  templateUrl: './datapoint-chart.component.html',
  providers: [
    CurrentDatapointService,
    BsModalService,
    ComponentLoaderFactory
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatapointChartComponent implements OnDestroy, OnInit, OnChanges {
  id = `${selector}-${nextId++}`;
  private ngDestroy = new Subject<void>();

  @ViewChild('dateRangePicker', {static: false})
  public dateRangePicker: NgxMatDrpComponent;

  @ViewChild('chartCanvas', {static: false})
  public chartCanvas: ElementRef;

  public differentiateControl = new FormControl(false);

  public defaultRangeSource = new ReplaySubject<{from: Moment, to: Moment}>(1);

  public sourceOwnerSeriesSource = new ReplaySubject<SourceOwnerSeries>(1);

  public sos$ = this.sourceOwnerSeriesSource.asObservable().pipe(debounceTime(250));

  private reloadSubject = new Subject<void>();
  public reload$ = this.reloadSubject.asObservable();

  public defaultRange = null;
  public location: Location;

  private selectedFilters: Filters = null;

  @Input('sourceOwnerSeries')
  public set setSourceOwnerSeries(sourceOwnerSeries: SourceOwnerSeries) {
    this.sourceOwnerSeriesSource.next(sourceOwnerSeries);
  }

  @Input('globalAggregationGroupingType')
  public set setGlobalAggregationGroupingType(aggregationType: string) {
    if (aggregationType != null) {
      this.setAggregationGroupingType(aggregationType !== 'LIVE' ? aggregationType : null);
    }
  }

  @Input('showOnMap')
  public showOnMap = false;

  @Input('range')
  public set setGlobalRange(range: {from: Moment, to: Moment}) {
    this.defaultRangeSource.next(range);
  }

  @Output("datapointChanged")
  dataPointChangeEmitter: EventEmitter<SourceOwnerSeries> = new EventEmitter<SourceOwnerSeries>();

  public sourceOwnerSeries: SourceOwnerSeries;
  public personSeries: PersonSeries;
  public latestValue: LatestValue;
  public lastPeriod: PersonSeriesPeriod;

  public source: SourceInfo;

  public activeThresholds: Threshold[];

  public activeConsents: Consent[];

  public isInComparison = false;

  public isDataOwner = false;

  public rangeControl: FormControl = new FormControl();
  public range$ = this.rangeControl.valueChanges.pipe(distinctUntilChanged(), takeUntil(this.ngDestroy));
  public aggregationTypeControl = new FormControl('AVERAGE');
  public aggregationType$ = this.aggregationTypeControl.valueChanges.pipe(startWith('AVERAGE'));

  public aggregationGroupingType$: Subject<string> =  new BehaviorSubject('HOURLY');

  public filtersSubject = new Subject<Filters>();

  public filters$ = this.filtersSubject.asObservable().pipe(takeUntil(this.ngDestroy), shareReplay(1));

  public data: PersonSeriesHistoricalResponse = null;
  public dataAggregationGroupingType;
  public dataAggregationType;
  public valueTotal = null;

  private chartDefaults: ChartDefaults = null;
  public chartXAxes: any[] = [];
  public chartYAxes: any[] = [];
  public chartAnnotations: any[] = [];
  public chartDatasets: Array<any> = [];
  public chartLabels: Array<any> = [];
  public chartColors: Array<any> = [
    {
      backgroundColor: 'rgba(0,0,220,0.01)',
      borderColor: 'rgba(0,0,220,0.8)',
      borderWidth: 2,
      pointBackgroundColor: 'rgba(0,0,220,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(0,0,220,1)'
    }
  ];

  private initChartOptions(): void {
    this.chartDefaults = new ChartDefaults(this.locale);
    this.chartXAxes = [Object.create(this.chartDefaults.xAxis)];
    this.chartYAxes = [this.chartDefaults.yAxis];
    this.chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      animation: false,
      scales: {
        xAxes: this.chartXAxes,
        yAxes: this.chartYAxes
      },
      annotation: {
        annotations: this.chartAnnotations
      },
      tooltips: {
        callbacks: {
          label: (tooltipItem, data) => {
            let label = '';
            if (isNaN(tooltipItem.yLabel)) {
              label = tooltipItem;
            } else {
              let maxFractionDigits = (this.sourceOwnerSeries.personSeries.numberFormat?.maxFractionDigits ?? NumberFormatConstants.DEFAULT_NUMBER_FORMAT.defaultFractionDigits);
              label = new Intl.NumberFormat(this.locale, {
                maximumFractionDigits: maxFractionDigits,
                useGrouping: false
              }).format(tooltipItem.yLabel);
            }

            return label;
          }
        }
      },
      chartArea: {
        backgroundColor: 'rgba(255, 255, 255, 1.0)'
      },
      locale: new Intl.NumberFormat(this.locale, {
        useGrouping: false
      })
    }
  }

  public chartOptions: any = { };

  public chart: any;

  dateRangePickerOptions: NgxDrpOptions;

  public aggregationTypeOptions = this.enumsService.getAggregationTypeOptions();

  public aggTypes = {
    'HOURLY': false,
    'DAILY': false,
    'MONTHLY': false,
    'YEARLY': false
  };

  public initialLoadCompleted = false;

  constructor(
    @Inject(SMARTENCITY_MYDATA_CONFIG) private config: MyDataConfig,
    private authService: AuthService,
    private enumsService: EnumsService,
    private personSeriesService: PersonSeriesApiService,
    private personSeriesCompareService: PersonSeriesCompareService,
    private currentDatapointService: CurrentDatapointService,
    private http: HttpClient,
    private decimalPipe: DecimalPipe,
    private toastr: ToastrService,
    private modalService: BsModalService,
    private preventCloseModalService: PreventCloseModalService,
    private chartDataService: ChartDataService,
    @Inject(LOCALE_ID) public locale: string
  ) {

    this.initChartOptions();

    this.sourceOwnerSeriesSource.subscribe((sos) => {
      this.sourceOwnerSeries = sos;
      this.currentDatapointService.setSourceOwnerSeries(sos);
      this.personSeries = sos?.personSeries;
      this.latestValue = sos?.latestValue;
      this.lastPeriod = sos?.lastPeriod;
      this.source = sos?.source;
      this.location = new Location(sos?.personSeries);
    });

    this.defaultRangeSource.pipe(withLatestFrom(this.sourceOwnerSeriesSource)).subscribe(([defaultRange, ]) => {
      this.defaultRange = defaultRange;
      if (!this.dateRangePickerOptions) {
        this.setupDrpOptions();
      } else {
        this.dateRangePicker.resetDates({fromDate: moment(defaultRange.from), toDate: moment(defaultRange.to).subtract(1, 'days')});
      }
    });

    this.currentDatapointService.activeThresholds$.pipe(takeUntil(this.ngDestroy)).subscribe(at => {
      this.activeThresholds = at;
      if (!this.activeThresholds) {
        this.activeThresholds = [];
      }


      if (this.chartAnnotations.length) {
        this.chartAnnotations.splice(0, this.chartAnnotations.length);
      }

      let chartAnnotations = [];
      if (this.activeThresholds.length) {
        for (const threshold of this.activeThresholds) {
          chartAnnotations.push({
            drawTime: 'afterDatasetsDraw',
            type: 'line',
            mode: 'horizontal',
            scaleID: 'y-axis-0',
            value: threshold.value,
            borderColor: 'red',
            borderWidth: 1,
            label: {
              enabled: threshold.enabled,
              content: threshold.name,
              backgroundColor: 'rgba(0,0,0,0.0)',
              fontSize: 11,
              cornerRadius: 0,
              yAdjust: -6,
              fontStyle: 'normal',
              fontColor: '#444',
              position: 'left'
            }
          });
        }
      }

      this.chartAnnotations = Object.assign(this.chartDefaults.chartAnnotations, chartAnnotations);
      this.updateChart();

    });
    this.currentDatapointService.activeConsents$.pipe(takeUntil(this.ngDestroy)).subscribe(ac => {
      this.activeConsents = ac;
    });

    this.filters$.pipe(takeUntil(this.ngDestroy)).subscribe((filters: Filters) => {
      this.selectedFilters = filters;
      const aggregationGroupingType: string = filters.aggregationGroupingType;
      if (filters.aggregationGroupingType == null || aggregationGroupingType === 'HOURLY') {
        const diffTime = Math.abs(filters.range.to.toDate().getTime() - filters.range.from.toDate().getTime());
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        if (diffDays > 1) {
          this.chartXAxes[0].time.displayFormats.hour = 'DD.MM HH:mm';
        } else {
          this.chartXAxes[0].time.displayFormats.hour = 'HH:mm';
        }
        this.chartXAxes[0].time.unit = 'hour';
      } else {
        this.chartXAxes[0].time.unit = ChartDataService.agt2MomentUnit(aggregationGroupingType);
      }
    });

    combineLatest([
      this.range$,
      this.aggregationGroupingType$.pipe(distinctUntilChanged())
    ]).pipe(
      takeUntil(this.ngDestroy),
      distinctUntilChanged()
    ).subscribe(([range, aggregationGroupingType]: [{from: Moment, to: Moment, aggTypes: any}, string]) => {
      const origGroupingType = aggregationGroupingType;

      if (aggregationGroupingType === 'YEARLY' && !range.aggTypes['YEARLY']) {
        aggregationGroupingType = 'MONTHLY';
      }
      if (aggregationGroupingType === 'MONTHLY' && !range.aggTypes['MONTHLY']) {
        aggregationGroupingType = 'DAILY';
      }
      if (aggregationGroupingType === 'DAILY' && !range.aggTypes['DAILY']) {
        aggregationGroupingType = 'HOURLY';
      }
      if (aggregationGroupingType === 'HOURLY' && !range.aggTypes['HOURLY']) {
        aggregationGroupingType = null;
      }

      if (aggregationGroupingType !== origGroupingType) {
        // setTimeout() vajalik selleks, et change detection toimuks
        setTimeout(() => {
          this.setAggregationGroupingType(aggregationGroupingType);
        });

      } else {
        this.filtersSubject.next({
          range: range,
          aggregationGroupingType: aggregationGroupingType
        });
      }
    });

    this.rangeControl.valueChanges.pipe(takeUntil(this.ngDestroy)).subscribe((range) => {
      this.aggTypes = range.aggTypes;
    });

    this.personSeriesCompareService.changes$.pipe(takeUntil(this.ngDestroy)).subscribe(() => {
      if (this.personSeries) {
        this.isInComparison = this.personSeriesCompareService.contains(this.personSeries);
      }
    });

    combineLatest([
      this.filters$,
      this.aggregationType$,
      this.sos$,
      this.reload$.pipe(startWith(null as void)),
      this.differentiateControl.valueChanges.pipe(startWith(false))
    ]).pipe(
      distinctUntilChanged(),
      takeUntil(this.ngDestroy)
    ).subscribe(([filters, aggregationType, sourceOwnerSeries, reload, differentiate]: [Filters, string, any, void, boolean]) => {
      if (!filters.aggregationGroupingType) {
        aggregationType = null;
      }
      let fromDate = filters.range.from;
      if (differentiate) {
        switch (filters.aggregationGroupingType) {
          case 'MONTHLY':
            fromDate = moment(filters.range.from).subtract(1, 'month');
            break;
          case 'YEARLY':
            fromDate = moment(filters.range.from).subtract(1, 'year');
            break;
          default:
            fromDate = moment(filters.range.from).subtract(1, 'days');
            break;
        }
      }
      this.getHistoricalValues(fromDate, filters.range.to, aggregationType, filters.aggregationGroupingType);
    });

    let aggregationGroupingType = 'HOURLY';
    if (this.source) {
      switch (this.source.samplingType) {
        case SamplingType.MONTHLY: {
          aggregationGroupingType = 'MONTHLY';
          break;
        }
        case SamplingType.YEARLY: {
          aggregationGroupingType = 'YEARLY';
          break;
        }
        default: {
          aggregationGroupingType = 'HOURLY';
        }
      }
    }

    this.setAggregationGroupingType(aggregationGroupingType);
  }

  public setAggregationGroupingType(aggregationGroupingType: string): void {
    this.aggregationGroupingType$.next(aggregationGroupingType);
  }

  ngOnDestroy(): void {
    this.sourceOwnerSeriesSource.complete();
    this.defaultRangeSource.complete();
    this.reloadSubject.complete();
    this.ngDestroy.next();
    this.ngDestroy.complete();
    this.dataPointChangeEmitter.complete();
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.latestValue) {

    }
    if (changes.range) {

    }
  }

  private getDefaultRange(): {from: Moment, to: Moment, aggTypes: any} {
    if (this.defaultRange) {
      return this.defaultRange;
    }
    let start = moment();
    if (this.latestValue && this.latestValue.measurement) {
      start = moment(this.latestValue.measurement.time);
    }
    let momentUnit: moment.unitOfTime.Base = 'day';
    if (this.source) {
      switch (this.source.samplingType) {
        case SamplingType.MONTHLY: {
          momentUnit = 'month';
          break;
        }
        case SamplingType.YEARLY: {
          momentUnit = 'year';
          break;
        }
        default: {
          momentUnit = 'day';
        }
      }
    }
    const myRange = {
      from: start.clone().startOf(momentUnit).subtract(2, momentUnit),
      to: start.clone().startOf(momentUnit).add(1, momentUnit)
    };
    return {from: myRange.from, to: myRange.to, aggTypes: this.getRangeAggTypes(myRange)};
  }

  private getRangeAggTypes(range: {from: Moment, to: Moment}) {
    const to = moment().isBefore(moment(range.to)) ? moment() : moment(range.to).subtract(1);
    return {
      'HOURLY': !moment(range.from).isAfter(to.clone().startOf('hour')),
      'DAILY': !moment(range.from).isAfter(to.clone().startOf('day')),
      'MONTHLY': !moment(range.from).isAfter(to.clone().startOf('month')),
      'YEARLY': !moment(range.from).isAfter(to.clone().startOf('year'))
    };
  }

  updateChart() {
    if (!this.chartCanvas) {
      return;
    }

    if (this.chartDatasets.length) {
      if (!this.chart) {
        const options = {
          type: 'line',
          data: {
            labels: this.chartLabels,
            datasets: this.chartDatasets
          },
          plugins: [ChartAnnotation],
          options: this.chartOptions
        };

        this.chart = new Chart(this.chartCanvas.nativeElement, options);
      }

      this.chart.options.scales.yAxes = Chart.helpers.scaleMerge(Chart.defaults.scale, {yAxes: this.chartYAxes}).yAxes;
      this.chart.options.scales.xAxes = Chart.helpers.scaleMerge(Chart.defaults.scale, {xAxes: this.chartXAxes}).xAxes;
      this.chart.options.annotation = Chart.helpers.configMerge((Chart as any).Annotation.defaults, Chart.helpers.configMerge((Chart as any).Annotation.defaults, {annotations: this.chartAnnotations}));
      this.chart.update();
    } else {
      if (this.chart) {
        this.chart.destroy();
        this.chart = null;
      }
    }
  }

  setConsentButton() {
    if (this.authService.profileData) {
      this.isDataOwner = this.personSeries.person.countryCode === this.authService.profileData.mandate.person.countryCode
        && this.personSeries.person.registrationNumber === this.authService.profileData.mandate.person.registrationNumber;
    }
  }

  getHistoricalValues(rangeFrom: Moment, rangeTo: Moment, aggregationType: string, aggregationGroupingType: string) {
    if (this.latestValue && this.latestValue.measurement != null) {
      this.personSeriesService.getPersonSeriesHistoricalMeasurements(
        this.personSeries.id,
        rangeFrom.toISOString(),
        rangeTo.toISOString(),
        aggregationGroupingType
      ).pipe(takeUntil(this.ngDestroy)).subscribe(
        (data: PersonSeriesHistoricalResponse) => {
          if (data.measurement.series?.truncated) {
            this.toastr.warning($localize`Displayed period for data point (${this.personSeries?.name}) was truncated to 5000 values. Please limit time period.`);
          }
          this.data = data;
          this.dataAggregationType = aggregationType;
          this.dataAggregationGroupingType = aggregationGroupingType;
          this.updateChartData();
          if (aggregationGroupingType && !this.initialLoadCompleted && !(this.data.measurement.series && Object.keys(this.data.measurement.series.values).length)) {
            this.setAggregationGroupingType(null);
          }
          this.initialLoadCompleted = true;
        },
        (err: any) => {
          this.toastr.error('Loading historical measurements failed');
        }
      );
    }
  }

  viewLatestEventValue() {
    this.personSeriesService.getPersonSeriesLatestEvent(this.personSeries.id,
      this.selectedFilters.range.from.toISOString(),
      this.selectedFilters.range.to.toISOString()).subscribe((result) => {
      const modalRef = this.modalService.show(PersonSeriesEventModalComponent, {
        ignoreBackdropClick: true,
        initialState: {
          name: this.personSeries.name,
          event: result ? result.event : null
        }
      });
    }, () => {
      this.toastr.error('Midagi läks valesti!');
    });
  }

  updateChartData() {
    this.mapChartData(this.data, this.dataAggregationType, this.dataAggregationGroupingType, this.differentiateControl.value);
    this.updateChart();
  }

  private mapChartData(data: any, aggregationType: string, aggregationGroupingType: string, differentiate?: boolean) {
    if (!data.measurement || !data.measurement.series) {
      this.chartDatasets.splice(0, this.chartDatasets.length);
      this.chartDatasets.push({data: [], label: '', lineTension: 0});
      return;
    }
    const mappedData = this.chartDataService.mapChartData(data, aggregationType, aggregationGroupingType, null, differentiate);
    const datasetValues = mappedData.data;
    let unit = mappedData.unit;
    if (this.latestValue && this.latestValue.measurement) {
      unit = this.latestValue.measurement.unit;
    }

    this.valueTotal = mappedData.valueTotal;
    this.chartDatasets.splice(0, this.chartDatasets.length);
    if (datasetValues.length === 1) {
      const tickMin = moment(datasetValues[0].t);
      const tickMax = moment(datasetValues[0].t);
      const momentUnit: moment.unitOfTime.DurationConstructor = ChartDataService.agt2MomentUnit(aggregationGroupingType);
      tickMin.subtract(1, momentUnit);
      tickMax.add(1, momentUnit);

      this.chartXAxes[0].ticks = {
        source: 'data',
        autoSkip: false,
        min: tickMin.valueOf(),
        max: tickMax.valueOf()
      }

      this.chartDatasets.push({data: datasetValues, label: unit, lineTension: 0, type: 'bar', maxBarThickness: 100});
    } else {
      // Reset when not single dataset value
      this.chartXAxes[0] = {...this.chartDefaults.xAxis};
      this.chartDatasets.push({data: datasetValues, label: unit, lineTension: 0, type: 'line'});
    }
  }

  public csv() {
    const list = this.personSeriesService.mapToFlatList(this.data);
    CsvDataService.exportToCsv('data', list);
  }

  public png() {
    ChartService.downloadPng(this.chart, this.personSeries.name);
  }

  updateRange(range: Range) {
    const myRange = {
      from: range.fromDate.clone(),
      to: moment(range.toDate).add(1, 'days')
    };
    this.rangeControl.setValue({from: myRange.from, to: myRange.to, aggTypes: this.getRangeAggTypes(myRange)});
  }

  setupDrpOptions() {
    const defaultRange = this.getDefaultRange();

    const today = moment().startOf('day');

    const minus1 = today.clone().subtract(1, 'days');
    const minus7 = today.clone().subtract(7, 'days');
    const currMonthStart = today.clone().startOf('month');
    const currMonthEnd = today.clone().startOf('month').add(1, 'months').subtract(1, 'days');
    const lastMonthStart = today.clone().startOf('month').subtract(1, 'months');
    const lastMonthEnd = today.clone().startOf('month').subtract(1, 'days');
    const currYearStart = today.clone().startOf('year');
    const currYearEnd = today.clone().startOf('year').add(1, 'year').subtract(1, 'days');
    const lastYearStart = today.clone().startOf('year').subtract(1, 'year');
    const lastYearEnd = today.clone().startOf('year').subtract(1, 'days');

    this.dateRangePickerOptions = {
      placeholder: $localize`Choose period`,
      presets: [
        {
          presetLabel: $localize`Today`,
          range: {fromDate: today.clone(), toDate: today.clone()}
        },
        {
          presetLabel: $localize`Yesterday`,
          range: {fromDate: minus1.clone(), toDate: minus1.clone()}
        },
        {
          presetLabel: $localize`Last 7 Days`,
          range: {fromDate: minus7.clone(), toDate: today.clone().subtract(1, 'days')}
        },
        {
          presetLabel: $localize`This Month`,
          range: {fromDate: currMonthStart.clone(), toDate: currMonthEnd.clone()}
        },
        {
          presetLabel: $localize`Last Month`,
          range: {fromDate: lastMonthStart.clone(), toDate: lastMonthEnd.clone()}
        },
        {
          presetLabel: $localize`This year`,
          range: {fromDate: currYearStart.clone(), toDate: currYearEnd.clone()}
        },
        {
          presetLabel: $localize`Last year`,
          range: {fromDate: lastYearStart.clone(), toDate: lastYearEnd.clone()}
        }
      ],
      format: 'dd.MM.yyyy',
      range: {
        fromDate: defaultRange.from,
        toDate: moment(defaultRange.to).subtract(1, 'days')
      },
      /*fromMinMax: {fromDate: undefined, toDate: today},
      toMinMax: {fromDate: undefined, toDate: today},*/
      applyLabel: $localize`Apply`,
      enforceToAfterFrom: true,
      calendarOverlayConfig: {
        shouldCloseOnBackdropClick: true,
        hasBackdrop: true
      },
      cancelLabel: $localize`Cancel`
    };
  }

  // Controls
  addToComparison() {
    this.personSeriesCompareService.add(this.personSeries);
  }

  removeFromComparison() {
    this.personSeriesCompareService.remove(this.personSeries);
  }

  addData() {
    const modalRef = this.modalService.show(AddDataModalComponent, {
      ignoreBackdropClick: true,
      class: 'modal-lg',
      initialState: {
        sourceOwnerSeries: this.sourceOwnerSeries,
        callback: (result) => {
          //this.reloadSubject.next();
          this.dataPointChangeEmitter.next(this.sourceOwnerSeries);
        }
      }
    });
  }

  manageThresholds() {
    this.sos$.pipe(take(1)).subscribe((sourceOwnerSeries) => {
      const modalRef = this.modalService.show(ThresholdsModalComponent, {
        ignoreBackdropClick: true,
        class: 'modal-lg',
        initialState: {
          sourceOwnerSeries: sourceOwnerSeries,
          callback: (result) => {
            if (result) {
              this.currentDatapointService.loadThresholdAnnotations();
            }
          }
        }
      });
    });
  }

  showConsents() {
    const modalRef = this.preventCloseModalService.show(ConsentModalComponent, {
      class: 'modal-lg',
      initialState: {
        personSeries: this.personSeries,
        lastDataOwnerPeriod: this.sourceOwnerSeries.lastDataOwnerPeriod
      }
    });
  }

  getConsentPersons(): Person[] {
    if (!this.sourceOwnerSeries?.consents) {
      return [];
    }

    return this.sourceOwnerSeries.consents.map(c => c.person);
  }

}
